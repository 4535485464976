<div class="h-100 d-flex flex-column">
  <div class="highcharts-dashboards-dark" style="flex: 1">
    <highcharts-chart
      *ngIf="show"
      [Highcharts]="highcharts"
      [options]="chartOptions"
      style="width: 100%; height: 100%; display: block"
    >
    </highcharts-chart>
  </div>
  <div class="px-2 pb-1">
    <app-aqi-color-legend
      *ngIf="
        aqiIndexColor && limitsObj[selectedField?.key ?? '']?.range?.length
      "
      [aqiIndexColor]="{
        labels: aqiIndexColor.labels,
        color: aqiIndexColor.color,
        range: limitsObj[selectedField?.key ?? ''].range
      }"
    ></app-aqi-color-legend>
  </div>
</div>
