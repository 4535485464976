<app-form-container
  [tableTitle]="formTitle"
  [submitBtn]="buttonValue"
  [isValid]="downloadForm.valid"
  (submit)="submitForm()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [cancelBtn]="'Cancel'"
>
  <form class="row gy-4" [formGroup]="downloadForm">
    <!--options-->
    <div class="col-12">
      <mat-radio-group
        aria-label="Select an option"
        class="d-flex justify-content-around"
        formControlName="selectedType"
      >
        <mat-radio-button color="primary" value="csv"
          >CSV</mat-radio-button
        >
        <mat-radio-button color="primary" value="report"
          >PDF Report</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <!--select devices-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Device Name</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          placeholder="Device Name"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : downloadForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>

        <button
          type="button"
          *ngIf="downloadForm.get('deviceSearch')?.value"
          mat-icon-button
          matSuffix
          aria-label="clear"
          style="margin-right: -18px; z-index: 1"
          (click)="clearDeviceName(); $event.stopPropagation()"
          matTooltip="Close"
        >
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
        <button
          type="button"
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
          class=""
          style="z-index: 0"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!--select parameters-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Select Parameter</mat-label>
        <mat-select formControlName="parameters" multiple>
          <ng-container
            *ngIf="
              keysOfSelectedDevice ?? {}
                | gasLevel : fieldsOfCurrentDeviceType : limitsOfFields;
              let keysOfSelectedDeviceTransformed
            "
          >
            <app-multiple-select-dropdown
              [options]="keysOfSelectedDeviceTransformed"
              [control]="paramsControl"
            ></app-multiple-select-dropdown>
            <ng-container *ngIf="areKeysAvailable">
              <mat-option
                *ngFor="
                  let keyOfSelectedDeviceT of keysOfSelectedDeviceTransformed
                "
                [value]="keyOfSelectedDeviceT"
              >
                <!-- {{ keyOfSelectedDeviceT?.label }} -->
                <span>
                  {{ keyOfSelectedDeviceT?.label }}
                </span>
                <span
                  *ngIf="keyOfSelectedDeviceT?.unit"
                  style="font-size: 12px; padding-left: 2px"
                >
                  ({{ keyOfSelectedDeviceT?.unit }})
                </span>
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <!--select average-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Select Average</mat-label>
        <mat-select formControlName="average">
          <mat-option
            *ngFor="let allAverage of allAverages"
            [value]="allAverage"
            >{{ allAverage.label }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <!--Date-->
    <ng-container *ngIf="isCSV">
      <div class="col-12 col-lg-6">
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              [max]="maxDate"
              [min]="minDate"
              formControlName="startDate"
              [matDatepicker]="startDatePicker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="startDatePicker"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="col-12 col-lg-6">
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Start Time</mat-label>
            <div class="d-flex">
              <input
                [ngxTimepicker]="startTimePicker"
                [disableClick]="true"
                readonly
                matInput
                formControlName="startTime"
                [format]="usersTimeFormat"
                [max]="maxTime"
              />
              <ngx-material-timepicker
                #startTimePicker
                [theme]="clockTheme"
              ></ngx-material-timepicker>
              <mat-icon (click)="startTimePicker.open()">schedule</mat-icon>
            </div>
          </mat-form-field>
        </div>
      </div> -->
      <div class="col-12 col-lg-6">
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              matInput
              [max]="maxDate"
              [min]="minDate"
              formControlName="endDate"
              [matDatepicker]="endDatePicker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="endDatePicker"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="col-12 col-lg-6">
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>End Time</mat-label>
            <div class="d-flex">
              <input
                [ngxTimepicker]="endTimePicker"
                [disableClick]="true"
                readonly
                matInput
                formControlName="endTime"
                [format]="usersTimeFormat"
                [max]="maxTime"
              />
              <ngx-material-timepicker
                #endTimePicker
                [theme]="clockTheme"
              ></ngx-material-timepicker>
              <mat-icon (click)="endTimePicker.open()">schedule</mat-icon>
            </div>
          </mat-form-field>
        </div>
      </div> -->

      <div class="col-12" *ngIf="isCSV && showEmailField">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Enter upto 5 Emails</mat-label>
          <mat-chip-grid
            #chipGrid
            aria-label="Enter Email"
            class="example-chip-list"
          >
            <mat-chip-row
              *ngFor="let email of emails"
              (removed)="removeEmail(email)"
              [editable]="true"
              [removable]="true"
              (edited)="editEmail(email, $event)"
            >
              {{ email.name }}
              <button matChipRemove matTooltip="Cancel">
                <mat-icon class="material-symbols-outlined">cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input
              *ngIf="countEmail < 5"
              placeholder="Add Email..."
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addEmail($event)"
              formArrayName="email"
            />
          </mat-chip-grid>
        </mat-form-field>
        <mat-error class="text-center" *ngIf="countEmail === 5"
          >Only 5 Emails Allowed</mat-error
        >
        <mat-error class="text-center" *ngIf="!isEmailValid"
          >Please Enter a Valid Email</mat-error
        >
      </div>
    </ng-container>

    <!--select multiple devices-->
    <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>
          Select Device ({{ selectedDevices?.length }} / {{ maxDevices }})
        </mat-label>
        <mat-select formControlName="deviceSearch" multiple>
          <!-- <app-multiple-select-dropdown
            [options]="devices"
            [control]="itemsControl"
          ></app-multiple-select-dropdown> -->
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="let device of devices | deviceType : deviceType.key"
              [value]="device"
              [disabled]="isDeviceSelected(device)"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <small class="grey">
        <mat-hint>You can select maximum 10 devices.</mat-hint>
      </small>
    </div>

    <!--select report type-->
    <!-- <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Report Type</mat-label>
        <mat-select formControlName="reportType">
          <mat-option
            *ngFor="let reportType of reportTypes"
            [value]="reportType"
            >{{ reportType.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div> -->

    <!--select report frequency-->
    <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Type</mat-label>
        <mat-select formControlName="reportFrequency">
          <mat-option
            *ngFor="let reportFrequency of reportFrequencies"
            [value]="reportFrequency"
            >{{ reportFrequency.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Start Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="startDate"
          [max]="maxDate"
          [min]="minDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</app-form-container>
