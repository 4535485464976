import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatStepper } from '@angular/material/stepper';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { filter, indexOf } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { UserMobileNumbers } from 'src/app/shared/models/internal-use-front-end/country-wise-ph-code';
import { LoginData } from 'src/app/shared/models/login-data';
import { ModuleCheckbox } from 'src/app/shared/models/module-checkbox';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { Project } from 'src/app/shared/models/project/project';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  @Input() deviceSelector = new SelectionModel<any>(true, []);
  @Input() moduleSelector = new SelectionModel<any>(true, []);

  loadTable: Subject<boolean> = new BehaviorSubject(false);
  projectsOrgInfo?: any;
  smsCount?: number;
  apiCalls?: number;
  seatCount?: number;
  newRegisterUserId: string = '';
  public stepOneRegisterForm!: FormGroup;
  public stepTwoDeviceForm!: FormGroup;
  public stepThreeModuleForm!: FormGroup;
  public adminUserDetails!: any[];
  public companyRegisterDetails!: any[];
  public adminUserLoginDetail!: any[];
  public userRegister?: Project.Add;
  public userUpdate?: Project.Update;
  public isNextStep: boolean = false;
  public cancelBtn: string = 'Cancel';
  public submitBtn: string = 'Next';
  currentDate = new Date();
  deviceDataFromDate?: any;
  public isEditMode!: boolean;
  rawData: boolean = false;
  public isFormVisible: boolean | undefined = undefined;
  showType: boolean = true;
  moduleExpiry = {};
  modules!: ModuleCheckbox[];
  userId!: number;
  user!: LoginData;
  units? = {};
  plan!: number;
  settings!: {};
  defaultModuleExp = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER
  ).moduleExpiry;
  defaultModules: any[] = [1001, 1002, 1003, 1008];
  userModules?: any;
  usersAllDevices: DeviceDetails[] = [];
  selectedDevices: any = [];
  selectedModules: any = [];
  patchProjectData: any;
  userData?: Project.Users;
  orgData?: Project.Details;
  terminalModuleList: any = [];
  adminModulesList: any = [];
  truffleModulesList: any = [];
  displayedColumnsDevices: string[] = [];
  displayedColumnsModules: string[] = [];
  public subscriptions: Subscription[] = [];
  public existingMobileNumbers: string[] = [];

  defaultColumnsDevices: any[] = [
    {
      columnDef: 'deviceId',
      header: 'Device ID',
      cell: (element: DeviceDetails) => `${element.deviceId}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'label',
      header: 'Label',
      cell: (element: DeviceDetails) => `${element.label}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'deviceType',
      header: 'Device Type',
      cell: (element: DeviceDetails) => `${element.deviceType}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'loc',
      header: 'Location',
      cell: (element: DeviceDetails) => `${element.loc}`,
      parameter: false,
      selected: false,
    },
  ];

  defaultColumnsModules: any[] = [
    {
      columnDef: 'name',
      header: 'Module',
      cell: (element: ModuleCheckbox) => `${element.name}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'expiryDate',
      header: 'Valid Till',
      cell: (element: ModuleCheckbox) =>
        this.defaultModuleExp[element.moduleId]
          ? this.giveMeExpiry(this.defaultModuleExp[element.moduleId])
          : '--',
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'dateSelector',
      header: 'Set Expiry',
      parameter: false,
      selected: false,
      min: this.currentDate,
      max: (element: ModuleCheckbox) =>
        !this.defaultModuleExp
          ? ''
          : this.dateHelper(this.defaultModuleExp[element.moduleId]),
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private projectsService: ProjectsService,
    private loadrService: LoadrService,
    private deviceService: DeviceService,
    private notificationService: NotificationService,
    private customMomentService: CustomMomentService,
    private clipboard: Clipboard,
    private loginService: LoginService,
    @Inject(MTX_DRAWER_DATA)
    { userData, orgData }: { userData: Project.Users; orgData: Project.Details }
  ) {
    this.userData = userData;
    this.orgData = orgData;
    this.moduleSelector.compareWith = (a: ModuleCheckbox, b: ModuleCheckbox) =>
      a && b && a.moduleId === b.moduleId;
    this.deviceSelector.compareWith = (a: DeviceDetails, b: DeviceDetails) =>
      a && b && a.deviceId === b.deviceId;
  }
  stepperTitle!: string;
  remainingSeats: any;
  remainingApis: any;
  remainingSms: any;

  ngOnInit(): void {
    this.rawData = this.userData?.settings?.raw ?? false;
    this.stepperTitle = !!this.userData
      ? 'Edit and Update User'
      : 'Add New Project';
    this.isEditMode = !!this.userData;

    this.user = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    this.userId = this.user.userId;
    this.units = this.user.units;
    this.plan = this.user.info.plan!;
    this.settings = this.user.settings;
    this.loadData();
    this.getModulesData();

    if (this.isEditMode && this.userData?.contact) {
      this.existingMobileNumbers.push(this.userData?.contact);
    }

    this.buildForm(this.isEditMode);
    this.displayedColumnsDevices = [
      'select',
      ...this.defaultColumnsDevices.map((c) => c.columnDef),
    ];
    this.displayedColumnsModules = [
      'select',
      ...this.defaultColumnsModules.map((c) => c.columnDef),
    ];

    let form = this.stepTwoDeviceForm
      ?.get('selectedDate')
      ?.valueChanges.subscribe((res) => {
        if (res) {
          this.deviceDataFromDate =
            this.stepTwoDeviceForm?.get('selectedDate')?.value;
        }
      })!;

    this.subscriptions.push(form);
  }

  epochTimeToDateMoment(value: number) {
    return this.customMomentService.moment.unix(value);
  }

  dateHelper(timestamp: any) {
    const dateObj = new Date(+timestamp * 1000);
    return new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate()
    );
  }

  loadData(): void {
    this.loadTable.next(false);
    let shared = this.projectsService.sharedSeats$.subscribe((res: any) => {
      this.projectsOrgInfo = res;
      this.smsCount =
        this.projectsOrgInfo?.max_sms_count -
        this.projectsOrgInfo?.used_sms_count;
      this.apiCalls =
        this.projectsOrgInfo?.max_api_calls -
        this.projectsOrgInfo?.used_api_calls;
      this.seatCount =
        this.projectsOrgInfo?.max_seats - this.projectsOrgInfo?.used_seats;
      this.remainingSeats = this.isEditMode
        ? (this.projectsService.masterOrgInfo?.max_seats ?? 1) -
          (this.projectsService.masterOrgInfo?.used_seats ?? 0)
        : this.seatCount;
      this.remainingApis = this.isEditMode
        ? (this.projectsService.masterOrgInfo?.max_api_calls ?? 0) -
          (this.projectsService.masterOrgInfo?.used_api_calls ?? 0)
        : this.apiCalls;
      this.remainingSms = this.isEditMode
        ? (this.projectsService.masterOrgInfo?.max_sms_count ?? 0) -
          (this.projectsService.masterOrgInfo?.max_sms_count ?? 0)
        : this.smsCount;

      // if (res) {
      this.setProperties();
      // }
    });
    this.subscriptions.push(shared);
    this.usersAllDevices = this.deviceService.registeredDevicesWithVibration!;
  }

  getModulesData() {
    this.modules = filter(
      this.localStorageService.getParsedValue(LocalStorageConstants.MODULES),
      (module) => {
        if (indexOf([1001, 1002, 1003, 1008, 1012], module.moduleId) < 0) {
          if (
            indexOf(
              this.localStorageService.getParsedValue(
                LocalStorageConstants.MODULE_ACCESS
              ),
              module.moduleId
            ) > -1
          ) {
            if (
              indexOf(
                this.localStorageService.getParsedValue(
                  LocalStorageConstants.OZ_USER
                ).modules,
                module.moduleId
              ) > -1
            ) {
              return module;
            }
          }
        }
      }
    );

    let moduleExpiry: { [key: string]: any } = {};
    if (this.isEditMode) {
      this.userData?.moduleExpiry?.map((expiry: any) => {
        moduleExpiry[expiry.moduleId] = this.customMomentService.moment.unix(
          expiry.expiry_date
        );
      });
    }

    this.modules.forEach((module) => {
      if (
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .moduleExpiry
      ) {
        let date = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        ).moduleExpiry[module['moduleId']!];
        if (moduleExpiry[module.moduleId]) {
          module['expiryDate'] = moduleExpiry[module.moduleId];
        } else {
          module['expiryDate'] = this.epochTimeToDateMoment(date);
        }
        module['maxDate'] = this.epochTimeToDateMoment(date);
        module['enabled'] = false;
      }
    });
  }

  buildForm(isEditMode: boolean = false) {
    this.stepOneRegisterForm = this.formBuilder.group({
      companyRegisterForm: this.formBuilder.group({
        orgId: [
          { value: '', disabled: isEditMode },
          [
            Validators.required,
            Validators.pattern(AppConstants.NUM_ALPH_REGEX),
          ], // Project Id :- number and alphabets allowed
        ],
        org_name: [
          '',
          [
            Validators.required,
            Validators.minLength(1),
            Validators.pattern(AppConstants.USERNAME_REGEX),
          ],
        ],
        org_addr: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.pattern(AppConstants.LOCATION_ADDRESS_REGEX),
          ],
        ],
        seat_count: [
          '1',
          [
            Validators.required,
            Validators.min(isEditMode ? this.orgData?.info.used_seats ?? 1 : 1),
            Validators.max(
              isEditMode
                ? this.remainingSeats! + this.orgData?.info.max_seats!
                : this.remainingSeats!
            ),
            Validators.pattern(AppConstants.NUMBER_REGEX),
          ],
        ],
        sms_count: [
          '0',
          [
            Validators.required,
            Validators.min(
              isEditMode ? this.orgData?.info.used_sms_count ?? 0 : 0
            ),
            Validators.max(
              isEditMode
                ? this.remainingSms! + this.orgData?.info.max_sms_count!
                : this.remainingSms!
            ),
            Validators.pattern(AppConstants.NUMBER_REGEX),
          ],
        ],
        api_calls: [
          '0',
          [
            Validators.required,
            Validators.min(
              isEditMode ? this.orgData?.info.used_api_calls ?? 0 : 0
            ),
            Validators.max(
              isEditMode
                ? this.remainingApis! + this.orgData?.info.max_api_calls!
                : this.remainingApis!
            ),
            Validators.pattern(AppConstants.NUMBER_REGEX),
          ],
        ],
        logo_url: ['', Validators.pattern(AppConstants.URL_REGEX)],
        rawdata: [this.rawData],
        clientId: [{ value: '', disabled: isEditMode }],
        clientSecret: [{ value: '', disabled: isEditMode }],
        contactNum: [''],
        contactName: [''],
      }),

      adminUserDetailsForm: this.formBuilder.group({
        name: [
          '',
          [
            Validators.required,
            Validators.pattern(AppConstants.USERNAME_REGEX),
          ],
        ],
        phoneNo: ['', Validators.required],
        location: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.pattern(AppConstants.LOCATION_ADDRESS_REGEX),
          ],
        ],
        city: [
          '',
          [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
        ],
        state: [
          '',
          [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
        ],
        country: [
          '',
          [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
        ],
      }),

      adminUserLoginDetailForm: this.formBuilder.group({
        email: [
          { value: '', disabled: isEditMode },
          [Validators.required, Validators.email],
        ],
        password: [
          '',
          [Validators.required],
          [this.passwordPatternCheck(), this.Passwordmatch()],
        ],
        confirmPassword: [
          '',
          [Validators.required],
          [this.confirmPasswordmatch()],
        ],
      }),
    });

    this.stepTwoDeviceForm = this.formBuilder.group({
      selectedDate: ['', Validators.min(this.usersAllDevices[0].dataFrom!)],
      numberOfDevices: ['', Validators.required],
    });

    this.stepThreeModuleForm = this.formBuilder.group({
      numberOfModule: '',
    });
    this.setProperties();
    if (isEditMode) {
      if (this.orgData?.archived) this.stepOneRegisterForm.disable();
      if (this.orgData?.archived) this.stepTwoDeviceForm.disable();
      if (this.orgData?.archived) this.stepThreeModuleForm.disable();

      this.setValuesInForm();
      this.patchDevicesAndModules();
      this.isNextStep = this.stepOneRegisterForm.valid;
    }
    this.onChangeSteponeForm();
  }

  setValuesInForm() {
    let companyRegisterFormGroup = this.stepOneRegisterForm?.get(
      'companyRegisterForm'
    ) as FormGroup;
    companyRegisterFormGroup.get('orgId')?.patchValue(this.userData?.org);
    companyRegisterFormGroup.get('org_name')?.patchValue(this.orgData?.orgName);
    companyRegisterFormGroup.get('org_addr')?.patchValue(this.orgData?.orgAddr);
    companyRegisterFormGroup
      .get('seat_count')
      ?.patchValue(this.orgData?.info.max_seats);
    companyRegisterFormGroup
      .get('api_calls')
      ?.patchValue(this.orgData?.info.max_api_calls);
    companyRegisterFormGroup
      .get('sms_count')
      ?.patchValue(this.orgData?.info.max_sms_count);
    companyRegisterFormGroup
      .get('logo_url')
      ?.patchValue(this.orgData?.info.logo_url);
    companyRegisterFormGroup
      .get('clientId')
      ?.patchValue(this.userData?.clientId);
    companyRegisterFormGroup
      .get('clientSecret')
      ?.patchValue(this.userData?.clientSecret);
    companyRegisterFormGroup
      .get('contactNum')
      ?.patchValue(this.orgData?.contactNum);
    companyRegisterFormGroup
      .get('contactName')
      ?.patchValue(this.orgData?.contactName);
    companyRegisterFormGroup
      .get('rawdata')
      ?.patchValue(this.userData?.settings?.raw);
    let adminUserDetailsFormGroup = this.stepOneRegisterForm?.get(
      'adminUserDetailsForm'
    ) as FormGroup;
    adminUserDetailsFormGroup.get('name')?.patchValue(this.userData?.name);
    adminUserDetailsFormGroup
      .get('phoneNo')
      ?.patchValue(this.userData?.contact);
    adminUserDetailsFormGroup
      .get('location')
      ?.patchValue(this.userData?.location);
    adminUserDetailsFormGroup.get('city')?.patchValue(this.userData?.city);
    adminUserDetailsFormGroup.get('state')?.patchValue(this.userData?.state);
    adminUserDetailsFormGroup
      .get('country')
      ?.patchValue(this.userData?.country);

    let adminUserLoginDetailFormGroup = this.stepOneRegisterForm?.get(
      'adminUserLoginDetailForm'
    ) as FormGroup;
    if (this.orgData?.archived) adminUserLoginDetailFormGroup.disable();
    adminUserLoginDetailFormGroup
      .get('email')
      ?.patchValue(this.userData?.email);
    adminUserLoginDetailFormGroup.removeControl('password');
    adminUserLoginDetailFormGroup.removeControl('confirmPassword');
    this.deviceDataFromDate = this.userData?.devices![0]?.dataFrom!
      ? this.customMomentService.moment.unix(
          this.userData?.devices![0]?.dataFrom!
        )
      : '';

    this.stepOneRegisterForm.markAllAsTouched();

    this.stepTwoDeviceForm
      ?.get('selectedDate')
      ?.patchValue(this.deviceDataFromDate);
  }
  giveMeExpiry(timestamp: any) {
    return moment.unix(timestamp).format('DD/MM/YYYY');
  }

  dateToEpochMoment(date: Date): number {
    return moment(date).unix();
  }

  setProperties() {
    this.companyRegisterDetails = [
      {
        class: 'col-12 col-lg-6',
        label: 'Project ID',
        type: 'text',
        controlName: 'orgId',
        hint: 'No special characters allowed',
        isEdit: 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'Project Name',
        type: 'text',
        controlName: 'org_name',
        hint: 'No special characters allowed',
        isEdit: 'true',
      },
      {
        class: 'col-12 ',
        label: 'Company Address',
        type: 'text',
        controlName: 'org_addr',
        hint: 'No special characters allowed',
        isEdit: 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'Seat Count',
        type: 'number',
        controlName: 'seat_count',
        hint: this.isEditMode
          ? 'Total available : ' +
            (this.remainingSeats + this.orgData?.info?.max_seats!) +
            ' | Allocated to this project ' +
            this.orgData?.info?.max_seats!
          : 'Total available : ' +
            this.remainingSeats +
            ' | Enter the number of Seats to be assigned to this project',
        error: this.isEditMode
          ? 'Must be between ' +
            (this.orgData?.info?.used_seats ?? 1) +
            ' and ' +
            (this.remainingSeats + this.orgData?.info?.max_seats!)
          : 'Must be between 1 and ' + this.remainingSeats,
        // ((this.seatCount as number) + this.orgData?.info?.max_seats!)
        isEdit: 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'SMS Count',
        type: 'number',
        controlName: 'sms_count',
        hint: this.isEditMode
          ? 'Total available : ' +
            (this.remainingSms! + this.orgData?.info?.max_sms_count!) +
            ' | Allocated to this project ' +
            this.orgData?.info.max_sms_count!
          : 'Total available : ' +
            this.remainingSms +
            ' | Enter the number of SMS to be assigned to this project, if enabling Alerts module.',
        error: this.isEditMode
          ? 'Must be between ' +
            (this.orgData?.info.used_sms_count ?? 0) +
            ' and ' +
            (this.remainingSms! + this.orgData?.info?.max_sms_count!)
          : 'Must be between 0 and ' + this.remainingSms,
        // (this.smsCount! + this.orgData?.info.max_sms_count!)
        isEdit: 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'API Calls',
        type: 'number',
        controlName: 'api_calls',
        hint: this.isEditMode
          ? 'Total available : ' +
            (this.remainingApis! + this.orgData?.info.max_api_calls!) +
            ' | Allocated to this project ' +
            this.orgData?.info.max_api_calls
          : 'Total available :' +
            this.remainingApis +
            ' | Enter the number of APIs to be assigned to this project, if enabling Integration module.',
        error: this.isEditMode
          ? 'Must be between ' +
            (this.orgData?.info.used_api_calls ?? 0) +
            ' and ' +
            (this.remainingApis! + this.orgData?.info.max_api_calls!)
          : 'Must be between 0 and ' + this.remainingApis,
        // (this.apiCalls! + this.orgData?.info.max_api_calls!)
        isEdit: 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'Web Logo',
        type: 'url',
        controlName: 'logo_url',
        hint: 'Add a valid URL',
        error: 'Add your valid hosted logo URL here',
        isEdit: 'true',
      },
      // {
      //   label: 'Raw Data',
      //   type: 'slider',
      //   controlName: 'rawdata',
      //   isEdit: 'true',
      // },
      {
        class: 'col-12 col-lg-6',
        label: 'Client Id',
        type: 'text',
        controlName: 'clientId',
        isEdit: this.isEditMode ? 'true' : 'false',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'Client Secret',
        type: 'text',
        controlName: 'clientSecret',
        isEdit: this.isEditMode ? 'true' : 'false',
      },
    ];

    this.adminUserDetails = [
      {
        label: 'Name',
        controlName: 'name',
        hint: 'No special characters allowed',
        error: 'Please enter valid name',
      },
      {
        label: 'Contact',
        controlName: 'phoneNo',
        hint: 'please provide country code',
        error: 'Please enter valid contact number',
      },
      {
        label: 'Location',
        controlName: 'location',
        hint: 'No special characters allowed',
        error: 'Please enter a valid location name',
      },
      {
        label: 'City',
        controlName: 'city',
        hint: 'No special characters allowed',
        error: 'Please enter a valid city name',
      },
      {
        label: 'State',
        controlName: 'state',
        hint: 'No special characters allowed',
        error: 'Please enter a valid state name',
      },
      {
        label: 'Country',
        controlName: 'country',
        hint: 'No special characters allowed',
        error: 'Please enter a valid country name',
      },
    ];

    this.adminUserLoginDetail = [
      {
        class: 'col-12 col-lg-6',
        label: 'Email ID',
        controlName: 'email',
        type: 'email',
        hint: 'Please enter a valid email address.',
        isEdit: 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'Password',
        controlName: 'password',
        hint: 'Your password must contain at least one uppercase, lowercase, special character and number',
        isEdit: this.isEditMode ? 'false' : 'true',
      },
      {
        class: 'col-12 col-lg-6',
        label: 'Confirm Password',
        controlName: 'confirmPassword',
        hint: 'Enter same password as above Field',
        isEdit: this.isEditMode ? 'false' : 'true',
      },
    ];
  }

  passwordPatternCheck(): AsyncValidatorFn {
    return (
      control: AbstractControl<any, any>
    ): Observable<{ [key: string]: any } | null> => {
      const pattern = AppConstants.PASSWORD_REGEX;
      if (pattern.test(control.value)) {
        return of(null);
      } else {
        return of({ customError: true });
      }
    };
  }

  confirmPasswordmatch(): AsyncValidatorFn {
    return (
      control: AbstractControl<any, any>
    ): Observable<{ [key: string]: any } | null> => {
      if (
        control.value ===
        this.stepOneRegisterForm
          ?.get('adminUserLoginDetailForm')
          ?.get('password')?.value
      ) {
        const confirmPasswordDetail = this.adminUserLoginDetail.find(
          (detail) => detail.label === 'Confirm Password'
        );
        if (confirmPasswordDetail) {
          confirmPasswordDetail.hint = 'Enter same password as above Field';
        }
        return of(null);
      } else {
        const confirmPasswordDetail = this.adminUserLoginDetail.find(
          (detail) => detail.label === 'Confirm Password'
        );
        if (confirmPasswordDetail) {
          confirmPasswordDetail.hint = 'Password does not match';
        }
        return of({ confirmpasswordmismatch: true });
      }
    };
  }

  Passwordmatch(): AsyncValidatorFn {
    return (
      control: AbstractControl<any, any>
    ): Observable<{ [key: string]: any } | null> => {
      if (
        control.value ===
          this.stepOneRegisterForm
            ?.get('adminUserLoginDetailForm')
            ?.get('confirmPassword')?.value ||
        this.stepOneRegisterForm
          ?.get('adminUserLoginDetailForm')
          ?.get('confirmPassword')?.value === ''
      ) {
        return of(null);
      } else {
        return of({ passwordmismatch: true });
      }
    };
  }

  toggleTypeofPassword() {
    this.showType = !this.showType;
  }

  onChangeSteponeForm() {
    let form = this.stepOneRegisterForm.valueChanges.subscribe(() => {
      if (this.stepOneRegisterForm.valid) {
        this.isNextStep = true;
      } else {
        this.isNextStep = false;
      }
    });
    this.subscriptions.push(form);
  }

  onStepChange(event: any) {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      this.previous(event);
    } else if (event.selectedIndex > event.previouslySelectedIndex) {
      this.submitAndNext(event);
    }
  }

  previous(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (!this.isEditMode) {
      if (this.stepper.selectedIndex === 0) {
        this.closeForm();
      } else if (this.stepper.selectedIndex === 1) {
        if (event) this.stepper.previous();
        this.isNextStep = this.stepOneRegisterForm.valid;
      } else if (this.stepper.selectedIndex === 2) {
        this.submitBtn = 'Next';
        if (event) this.stepper.previous();
        this.isNextStep = this.stepTwoDeviceForm.valid;
      }
    } else {
      if (this.stepper.selectedIndex === 0) {
        this.closeForm();
      } else if (this.stepper.selectedIndex === 1) {
        if (event) this.stepper.previous();
        this.isNextStep =
          this.orgData?.archived || this.stepOneRegisterForm.valid;
      } else if (this.stepper.selectedIndex === 2) {
        this.submitBtn = 'Next';
        if (event) this.stepper.previous();
        this.isNextStep =
          this.orgData?.archived || this.stepTwoDeviceForm.valid;
      }
    }
  }

  submitAndNext(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (!this.isEditMode) {
      if (stepperLength === 3) {
        if (
          this.stepper.selectedIndex === 0 &&
          this.stepOneRegisterForm.valid
        ) {
          if (event) this.stepper.next();
          this.cancelBtn = 'Back';
          this.isNextStep = this.stepTwoDeviceForm.valid;
        } else if (
          this.stepper.selectedIndex === 1 &&
          this.stepTwoDeviceForm.valid
        ) {
          if (event) this.stepper.next();
          this.submitBtn = 'Submit';
          this.isNextStep = this.stepThreeModuleForm.valid;
        } else if (
          this.stepper.selectedIndex === 2 &&
          this.stepThreeModuleForm.valid
        ) {
          if (event) this.submitForm();
        }
      }
    } else {
      if (stepperLength === 3) {
        if (
          this.stepper.selectedIndex === 0 &&
          (this.orgData?.archived || this.stepOneRegisterForm.valid)
        ) {
          if (event) this.stepper.next();
          this.cancelBtn = 'Back';
          this.isNextStep =
            this.orgData?.archived || this.stepTwoDeviceForm.valid;
        } else if (
          this.stepper.selectedIndex === 1 &&
          (this.orgData?.archived || this.stepTwoDeviceForm.valid)
        ) {
          if (event) this.stepper.next();
          this.submitBtn = 'Update';
          this.isNextStep = this.orgData?.archived ? false : true;
        } else if (
          this.stepper.selectedIndex === 2 &&
          this.stepThreeModuleForm.valid
        ) {
          if (event) this.updateProject();
        }
      }
    }
  }

  generatePayload() {
    const moduleWithExpiryDate: RequestData.SetModuleExpiry[] =
      this.moduleSelector.selected.map((item: ModuleCheckbox) => {
        return {
          moduleId: item.moduleId!,
          expiry_date: this.dateToEpochMoment(item.expiryDate),
        };
      });

    let companyDetails = this.stepOneRegisterForm.get(
      'companyRegisterForm'
    )?.value;
    let adminUserDetails = this.stepOneRegisterForm.get(
      'adminUserDetailsForm'
    )?.value;
    let adminUserLoginDetails = this.stepOneRegisterForm.get(
      'adminUserLoginDetailForm'
    )?.value;
    const settings = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).settings;
    this.userModules = new Set(this.defaultModules);
    this.selectedModules.forEach((module: any) => {
      this.userModules.add(module.moduleId);
    });
    this.selectedDevices = this.selectedDevices.map((device: any) => {
      return {
        deviceId: device.deviceId,
        dataFrom: this.deviceDataFromDate
          ? this.deviceDataFromDate?.unix()
          : device.dataFrom,
      };
    });

    const password = this.loginService.getEncryptedPassword(
      adminUserLoginDetails.password,
      this.localStorageService.getValue(LocalStorageConstants.ENCRYPTION_KEY)
    );
    if (!this.isEditMode) {
      this.userRegister = {
        data: {
          orgId: companyDetails.orgId,
          info: {
            // used_api_calls: companyDetails.api_calls ?? 0,
            max_api_calls: companyDetails.api_calls ?? 0,
            // used_sms_count: companyDetails.sms_count ?? 0,
            max_sms_count: companyDetails.sms_count ?? 0,
            // used_seats: 1,
            max_seats: companyDetails.seat_count ?? 1,
            licence: null,
            logo_url: companyDetails.logo_url,
            plan: null,
            expiry: 86400,
          },
          contactNum: adminUserDetails.phoneNo,
          contactName: adminUserDetails.name,
          orgName: companyDetails.org_name,
          orgAddr: companyDetails.org_addr,
          clientId: null,
          clientSecret: null,
        },

        userData: {
          email: adminUserLoginDetails.email,
          password: password,
          name: adminUserDetails.name,
          company: companyDetails.org_name,
          contact: adminUserDetails.phoneNo,
          location: adminUserDetails.location,
          city: adminUserDetails.city,
          state: adminUserDetails.state,
          country: adminUserDetails.country,
          modules: Array.from(this.userModules),
          thumb: null,
          units: this.units,
          aqi_index_id: JSON.parse(
            this.localStorageService.getValue(LocalStorageConstants.OZ_USER)
          ).aqi_index_id,
          settings: {
            raw: this.rawData,
            time_format: settings.time_format,
            timezone: settings.timezone,
            language: '',
            isAlertMigrated: true,
            isReportMigrated: true,
          },
        },

        setDevices: this.selectedDevices,
        setModuleExpiry: moduleWithExpiryDate,
      };
    } else {
      this.userUpdate = {
        targetUserId: this.userData?.userId!,
        update: {
          info: {
            licence: null,
            logo_url: companyDetails.logo_url,
            plan: this.orgData?.info.plan!,
            expiry: this.orgData?.expireOn!,
            //setting: this.orgData?.info.setting!,
            // used_api_calls: companyDetails.api_calls ?? 0,
            max_api_calls: companyDetails.api_calls ?? 0,
            // used_sms_count: companyDetails.sms_count ?? 0,
            max_sms_count: companyDetails.sms_count ?? 0,
            // used_seats: this.orgData?.info.used_seats!,
            max_seats: companyDetails.seat_count ?? 1,
          },
          contact_num: companyDetails.contactNum,
          contact_name: adminUserDetails.name,
          org_name: companyDetails.org_name,
          org_addr: companyDetails.org_addr,
        },
        updateUser: {
          name: adminUserDetails.name,
          company: adminUserDetails.name,
          contact: adminUserDetails.phoneNo,
          location: adminUserDetails.location,
          city: adminUserDetails.city,
          state: adminUserDetails.state,
          country: adminUserDetails.country,
          modules: Array.from(this.userModules),
          thumb: this.userData?.thumb!,
          units: this.userData?.units!,
          aqi_index_id: this.userData?.aqi_index_id!,
          settings: { ...this.userData?.settings!, raw: this.rawData },
        },
        setDevices: this.selectedDevices,
        updateModuleExpiry: moduleWithExpiryDate,
      };
    }
  }

  submitForm() {
    this.generatePayload();
    this.loadrService.showLoader();
    this.projectsService.addProject(this.userId, this.userRegister).subscribe({
      next: (res: any) => {
        this.projectsService.refreshProject(true);
        this.loadrService.removeLoader();
        this.formsService.closeForm();
        this.notificationService.showSnackBar(res, 'success');
      },
      error: (err: any) => {
        this.notificationService.showSnackBar(err, 'error');
        this.loadrService.removeLoader();
        console.info('Error:', err);
      },
    });
  }

  updateProject() {
    this.generatePayload();
    this.loadrService.showLoader();
    this.projectsService
      .updateProject(this.userId!, this.userData?.org!, this.userUpdate)
      .subscribe({
        next: (res: any) => {
          this.loadrService.removeLoader();
          this.formsService.closeForm();
          this.notificationService.showSnackBar(res, 'success');
          this.projectsService.refreshProject(true);
        },
        error: (err: any) => {
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar(err, 'error');

          console.info('Error:', err);
        },
      });
  }

  onDeviceSelection() {
    if (this.deviceSelector.selected.length > 0) {
      this.isNextStep = true;
      this.stepTwoDeviceForm
        .get('numberOfDevices')
        ?.setValue(this.deviceSelector.selected.length);
    } else {
      this.isNextStep = false;
      this.stepTwoDeviceForm.get('numberOfDevices')?.setValue('');
    }

    this.selectedDevices = this.deviceSelector.selected;
  }

  onModuleSelection() {
    this.selectedModules = this.modules
      .filter((module: ModuleCheckbox) => {
        return this.moduleSelector.selected.some(
          (resModule: ModuleCheckbox) => module.moduleId === resModule.moduleId
        );
      })
      .map((m) => {
        return {
          moduleId: m.moduleId,
          expiry_date: this.dateToEpochMoment(m.expiryDate),
        };
      });

    this.modules.forEach((module: ModuleCheckbox) => {
      module.enabled = this.selectedModules.some(
        (m: any) => m.moduleId === module.moduleId
      );
    });
  }

  onDateSelection(value: any) {
    this.moduleSelector.selected.forEach((obj: any) => {
      if (obj.moduleId === value.row.moduleId) {
        obj.expiryDate = value.changedDate;
      }
    });
  }

  patchDevicesAndModules() {
    let selectedDevices = this.usersAllDevices?.filter(
      (device: DeviceDetails) => {
        return this.userData?.devices!.some(
          (resDevice: DeviceDetails) => device.deviceId === resDevice.deviceId
        );
      }
    );

    this.deviceSelector.select(...selectedDevices);
    this.onDeviceSelection();

    let selectedModules = this.modules?.filter((module: ModuleCheckbox) =>
      this.userData?.modules?.includes(module.moduleId!)
    );
    this.moduleSelector.select(...selectedModules);
    this.onModuleSelection();
  }

  copyText(value: any) {
    let copyText = document.createElement('textarea');
    copyText.value = value.toString();
    this.clipboard.copy(copyText.value);
    this.notificationService.showSnackBar('Copied to clipboard', 'info');
  }

  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onToggleChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.rawData = true;
    } else {
      this.rawData = false;
    }
  }

  isControlDisabled(controlName: string): boolean {
    const companyControl = this.stepOneRegisterForm
      ?.get('companyRegisterForm')
      ?.get(controlName);
    const adminDetailsControl = this.stepOneRegisterForm
      ?.get('adminUserDetailsForm')
      ?.get(controlName);
    const adminLoginControl = this.stepOneRegisterForm
      ?.get('adminUserLoginDetailForm')
      ?.get(controlName);
    const isDisabled =
      companyControl?.status === 'DISABLED' ||
      adminDetailsControl?.status === 'DISABLED' ||
      adminLoginControl?.status === 'DISABLED';
    return isDisabled;
  }

  onNumberAdd(numbers: UserMobileNumbers) {
    if (numbers.validNumbers.length) {
      this.stepOneRegisterForm
        .get('adminUserDetailsForm')
        ?.get('phoneNo')
        ?.setValue(numbers.validNumbers[0]);
    } else {
      this.stepOneRegisterForm
        .get('adminUserDetailsForm')
        ?.get('phoneNo')
        ?.setValue(null);
    }
  }
}
