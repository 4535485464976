import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { UnitsManagement } from 'src/app/shared/models/units-management';
import { Unit } from 'src/app/shared/models/units/unit';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-limit-form',
  templateUrl: './limit-form.component.html',
  styleUrls: ['./limit-form.component.scss'],
})
export class LimitFormComponent implements OnInit {
  public limitForm!: FormGroup;
  public unitsOfGas!: Unit.Get;
  public limitColors!: String[];
  public selectedUnit!: number;
  public formTitle!: string;
  public currentDeviceType!: number;
  public allUnits!: Record<number, Unit.Data[]>;
  public isNegativeLimit: boolean = false;
  public isAscendingLimit: boolean = true;
  public defaultUnit!: Unit.Data;

  constructor(
    @Inject(MTX_DRAWER_DATA) public data: UnitsManagement,
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.userService.selectedDeviceTypeForUnits$.subscribe((res) => {
      if (res) {
        this.currentDeviceType = res;
      }
    });

    this.userService.unitsForAllParameters$.subscribe((res) => {
      if (res) {
        this.allUnits = res;
      }
    });
    this.unitsOfGas = this.data.availableUnits;
    this.defaultUnit = this.unitsOfGas.find((gas) => gas.c_factore === 1)!;
    this.selectedUnit = this.data.checkedUnit;
    this.formTitle = this.data.keyLabel;
    this.buildForm();
    this.getSelectedAQI();
    this.patchLimitValue();
    this.onUnitChange();
  }

  asFormArray(input: AbstractControl) {
    return input as FormArray;
  }
  asFormGroup(input: AbstractControl) {
    return input as FormGroup;
  }

  buildForm() {
    this.limitForm = this.formBuilder.group({
      unit: [this.selectedUnit, Validators.required],
      limits: this.formBuilder.array([], this.hasAllNonEmptyValidator()),
    });
  }

  addFormGroup() {
    this.limitColors.forEach((limitColor: any, index: number) => {
      if (limitColor) {
        const limits = this.limitForm.get('limits') as FormArray;
        limits.push(this.createFormGroup(index));
      }
    });
  }

  createFormGroup(index: number): FormGroup {
    return new FormGroup({
      limitValue: new FormControl(''),
    });
  }

  getSelectedAQI() {
    let allAQI = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    if (allAQI) {
      if (allAQI[this.data.deviceTypeId]?.id) {
        this.limitColors = allAQI[this.data.deviceTypeId].index.colors;
      } else {
        this.limitColors = allAQI[1001].index.colors;
      }
    }
    this.addFormGroup();
  }

  closeForm(): void {
    this.formsService.closeForm();
  }

  submitForm() {
    let payload: RequestData.UpdateUser = this.generatePayload();
    this.loadrService.showLoader();
    this.userService.updateUnitsAndLimits(payload).subscribe({
      next: (res) => {
        if (res) {
          // let user = this.localStorageService.getParsedValue(
          //   LocalStorageConstants.OZ_USER
          // );
          // user.units = payload.update.units;
          // this.localStorageService.saveValue(
          //   LocalStorageConstants.OZ_USER,
          //   JSON.stringify(user)
          // );

          //getting the updated user profile after updating the units
          this.userService.getUserProfile().subscribe({
            next: () => {
              this.loadrService.removeLoader();
              this.formsService.closeForm();
              // this.userService.afterUpdateUnitOrLimit();
              this.notificationService.showSnackBar(
                'Parameter Updated Successfully',
                'success'
              );
              let copy = JSON.parse(
                JSON.stringify(this.deviceService.rawCopyOfregisteredDevices)
              );
              this.deviceService.modifyDeviceDetails(copy, false, false);
            },
            error: (err) => {
              this.loadrService.removeLoader();
              this.formsService.closeForm();
              this.notificationService.showSnackBar('Please refresh this page','error');
            },
          });
        }
      },
      error: (err) => {
        console.info("Error:", err);
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar(err.error,'error');
      },
    });
  }

  generatePayload(): RequestData.UpdateUser {
    let newLimit: number[] = [];
    let newUnit!: Unit.Data;
    const controls = (this.limitForm.controls.limits as any)
      .controls as AbstractControl[];
    let units = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    let isAllValueBlankOrNull = controls.every(
      (control) =>
        control.value.limitValue === '' || control.value.limitValue === null
    );
    if (!isAllValueBlankOrNull) {
      this.limitForm.value.limits.forEach((limit: any) => {
        newLimit.push(limit.limitValue);
      });
    }
    this.allUnits[this.currentDeviceType].map((res: Unit.Data) => {
      if (res.id === this.limitForm.value.unit) {
        newUnit = res;
      }
    });

    units[this.currentDeviceType][newUnit.key] = newUnit;
    units[this.currentDeviceType][newUnit.key].limit = newLimit;

    let payload: RequestData.UpdateUser = {
      update: {
        units: units,
      },
      resetUnits: false,
    };

    return payload;
  }

  onUnitChange() {
    this.limitForm.get('unit')?.valueChanges.subscribe((res: number) => {
      this.selectedUnit = res;
      this.patchLimitValue();
    });
  }

  patchLimitValue() {
    let unit = this.unitsOfGas.find((unit: Unit.Data) => {
      return unit.id === this.selectedUnit;
    })!;

    let limit: number[] = [];

    //if the selected unit have limit than showing it
    if (unit?.limit?.length) {
      limit = unit.limit;
    }

    //if the selected unit doesnot have limit than calculating it through c_factore
    if (!unit?.limit?.length && this.defaultUnit.default_limit) {
      limit = [];
      this.defaultUnit.default_limit?.forEach((_, index) => {
        let l =
          (this.defaultUnit.default_limit![index] /
            this.defaultUnit.c_factore) *
          unit.c_factore;
        limit.push(parseFloat(l.toFixed(2)));
      });
    }

    const formArray = this.limitForm.get('limits') as FormArray;

    if (limit.length) {
      formArray.controls.forEach((control, index) => {
        control.patchValue({
          limitValue: limit[index],
        });
      });
    } else {
      formArray.controls.forEach((control, index) => {
        control.patchValue({
          limitValue: '',
        });
      });
    }

    // if (unit.limit?.length > 0) {
    //   formArray.controls.forEach((control, index) => {
    //     control.patchValue({
    //       limitValue: limit[index],
    //     });
    //   });
    // } else {
    //   formArray.controls.forEach((control) => {
    //     control.patchValue({
    //       limitValue: '',
    //     });
    //   });
    // }
  }

  hasAllNonEmptyValidator(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      const controls = (formArray as any).controls as AbstractControl[];

      const isAnyLimitFilled = controls.some(
        (control) =>
          control.value.limitValue !== '' && control.value.limitValue !== null
      );
      let hasAllNonEmpty: boolean = true;

      if (isAnyLimitFilled) {
        hasAllNonEmpty = controls.every((control) => {
          return (
            control?.value.limitValue > -1 &&
            control?.value.limitValue !== '' &&
            control?.value.limitValue !== null
          );
        });
      }

      //will be used when user is entering limits for aqi parameter (limits for aqi parameter cannot be empty)
      const isEveryLimitFilled = controls.every(
        (control) =>
          control.value.limitValue !== '' &&
          control.value.limitValue !== null &&
          control.value.limitValue > -1
      );

      //to show error if user enters value other than positive number
      const hasNegativeLimit = controls.some((control: any) => {
        if (control?.value.limitValue === '') return false; // Skip empty values
        return control?.value.limitValue < 0;
      });
      this.isNegativeLimit = hasNegativeLimit;

      // Check if the values are in ascending order
      if (isAnyLimitFilled) {
        this.isAscendingLimit = controls.every((control, index, array) => {
          return (
            index === 0 ||
            array[index - 1]?.value?.limitValue <= control?.value.limitValue
          );
        });
      }

      let condition: boolean;

      if (this.defaultUnit.default_limit) {
        condition = isEveryLimitFilled && this.isAscendingLimit;
      } else {
        condition = hasAllNonEmpty && this.isAscendingLimit;
      }

      return condition ? null : { allValuesNotEntered: true };
    };
  }
}
