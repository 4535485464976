<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field
        class="col-12 col-lg-3"
        *ngIf="isTableView() || isAQIView()"
      >
        <mat-label>Device Type</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Device Type'"
          matInput
          formControlName="deviceType"
          [matAutocomplete]="deviceType"
        />
        <mat-autocomplete
          #deviceType="matAutocomplete"
          [displayWith]="getDeviceType"
        >
          <mat-option
            *ngFor="
              let deviceType of deviceTypes
                | search : headerForm.get('deviceType')?.value : 'label'
            "
            [value]="deviceType"
          >
            {{ deviceType.label }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field
        class="col-12 col-lg-3 device-form-field"
        *ngIf="isMapView()"
      >
        <mat-label>Device</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Device'"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : headerForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <button
          *ngIf="headerForm.get('deviceSearch')?.value"
          mat-icon-button
          matSuffix
          matTooltip="Close"
          aria-label="clear"
          style="margin-right: -18px; z-index: 1"
          (click)="clearDeviceName(); $event.stopPropagation()"
        >
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
          class=""
          style="z-index: 0"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
      <div
        class="col-12 col-lg-8 flex-grow-0 flex-shrink-1"
        *ngIf="isMapView() && !headerForm.get('deviceSearch')?.value"
      >
        Select a device to view parameter details.
      </div>
      <mat-form-field
        class="col-12 col-lg-3"
        appearance="outline"
        *ngIf="isAQIView()"
      >
        <mat-label>Select Date</mat-label>
        <input
          matInput
          [max]="maxDate"
          formControlName="startDate"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        class="col-12 col-lg-3"
        appearance="outline"
        *ngIf="isAQIView()"
      >
        <mat-label>Select Time</mat-label>
        <div class="d-flex">
          <input
            [ngxTimepicker]="toggleTimepicker"
            [disableClick]="true"
            readonly
            matInput
            formControlName="selectedTime"
            [format]="usersTimeFormat"
            [max]="maxTime"
          />
          <ngx-material-timepicker
            #toggleTimepicker
            [theme]="clockTheme"
            [minutesGap]="30"
          ></ngx-material-timepicker>
          <mat-icon (click)="toggleTimepicker.open()">schedule</mat-icon>

          <!-- <input
            [ngxTimepicker]="toggleTimepicker"
            [disableClick]="true"
            readonly
            matInput
            formControlName="selectedTime"
            [format]="usersTimeFormat"
          />
          <ngx-material-timepicker-toggle
            [for]="toggleTimepicker"
          ></ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            #toggleTimepicker
            [theme]="clockTheme"
            [minutesGap]="30"
          ></ngx-material-timepicker> -->
        </div>

        <!-- <input
          matInput
          nbInput
          [nbTimepicker]="timepicker"
          formControlName="selectedTime"
        />
        <mat-datepicker-toggle
          matIconSuffix
          tabindex="-1"
        ></mat-datepicker-toggle>
        <nb-timepicker
          #timepicker="nbTimepicker"
          singleColumn
          twelveHoursFormat
          [step]="30"
        ></nb-timepicker> -->
      </mat-form-field>
      <div class="col col-lg-auto col-12 col-lg-2 apply" *ngIf="isAQIView()">
        <button
          matTooltip="Apply"
          (click)="onApply()"
          mat-raised-button
          color="primary"
          class="w-100 w-lg-auto"
        >
          Apply
        </button>
      </div>
      <div
        class="col-12 col-lg d-flex gx-3 gy-3 gy-lg-0"
        [ngClass]="
          isAQIView()
            ? 'justify-content-lg-end col-lg-9'
            : 'justify-content-lg-end align-items-lg-end'
        "
        *ngIf="isMapView()"
      >
        <button
          class="refresh"
          mat-mini-fab
          color="primary"
          (click)="onRefresh()"
          matTooltip="Refresh"
        >
          <mat-icon matSuffix class="material-symbols-outlined">loop</mat-icon>
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
