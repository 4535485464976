<mat-form-field class="w-100" appearance="outline">
  <mat-label>Enter Tags</mat-label>
  <mat-chip-grid #tagChipGrid>
    <mat-chip-row
      class="badge"
      *ngFor="let tag of selectedTags; let i = index"
      (removed)="remove(tag, i)"
    >
      <button
        matChipRemove
        matTooltip="Cancel"
        class="tag-remove"
      >
        <mat-icon class="material-symbols-outlined" 
          >close</mat-icon
        >
      </button>
      {{ tag }}
    </mat-chip-row>
    <input
      placeholder="Add Device Tag..."
      [matChipInputFor]="tagChipGrid"
      [matChipInputSeparatorKeyCodes]="seperatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      [matAutocomplete]="auto"
      [formControl]="tagInputFormControl"
      #trigger="matAutocompleteTrigger"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
    >
      <mat-option *ngFor="let tag of filteredTags" [value]="tag">
        <span class="badgeDropdown">{{ tag }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
