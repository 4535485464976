import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Overview',
    icon: { icon: 'overview', pack: 'envizom-module' },
    link: '/overview',
    home: true,
    pathMatch: 'prefix',
  },
  {
    title: 'Dashboard',
    icon: { icon: 'dashboard', pack: 'envizom-module' },
    link: '/dashboard',
    pathMatch: 'prefix',
  },
  {
    title: 'Cluster',
    icon: { icon: 'cluster', pack: 'envizom-module' },
    link: '/cluster',
    pathMatch: 'prefix',
  },
  {
    title: 'Reports',
    icon: { icon: 'reports', pack: 'envizom-module' },
    link: '/reports',
    pathMatch: 'prefix',
  },
  {
    title: 'Analytics',
    icon: { icon: 'analytics', pack: 'envizom-module' },
    link: '/analytics',
    pathMatch: 'prefix',
  },
  {
    title: 'Display',
    icon: { icon: 'display', pack: 'envizom-module' },
    link: '/display',
    pathMatch: 'prefix',
  },
  {
    title: 'Integration',
    icon: { icon: 'api', pack: 'envizom-module' },
    link: '/api',
    pathMatch: 'prefix',
  },
  {
    title: 'Alerts',
    icon: { icon: 'alerts', pack: 'envizom-module' },
    link: '/alerts',
    pathMatch: 'prefix',
  },
  {
    title: 'Automation',
    icon: { icon: 'automation', pack: 'envizom-module' },
    link: '/automation',
    pathMatch: 'prefix',
  },
  {
    title: 'Vibration',
    icon: { icon: 'vibration', pack: 'envizom-module' },
    link: '/vibration',
    pathMatch: 'prefix',
  },
  {
    title: 'Configuration',
    icon: { icon: 'configuration', pack: 'envizom-module' },
    link: '/configuration',
    pathMatch: 'prefix',
  },
  {
    title: 'Heatmap',
    icon: { icon: 'heatmap', pack: 'envizom-module' },
    link: '/heatmap',
    pathMatch: 'prefix',
  },
  {
    title: 'Complain',
    icon: { icon: 'complain', pack: 'envizom-module' },
    link: '/complain',
    pathMatch: 'prefix',
  },
  {
    title: 'Data-Flagging',
    icon: { icon: 'dataFlagging', pack: 'envizom-module' },
    link: '/data-flagging',
    pathMatch: 'prefix',
  },
  {
    title: 'Projects',
    icon: { icon: 'projects', pack: 'envizom-module' },
    link: '/projects',
    pathMatch: 'prefix',
  },
  {
    title: 'Devices',
    icon: { icon: 'devices', pack: 'envizom-module' },
    link: '/devices',
    pathMatch: 'prefix',
  },
  {
    title: 'User',
    icon: { icon: 'user', pack: 'envizom-module' },
    link: '/user',
    pathMatch: 'prefix',
  },
];

export const HEADER_MENU = [
  {
    title: 'Light Mode',
  },
  {
    title: 'What\'s New',
  },
  {
    title: 'Support',
  },
  {
    title: 'Notification',
  },
  {
    title: 'Download',
  },
  {
    title: 'Logout',
  }
  ];

export const OVERVIEW_MENU = [
  {
    name: 'mapViewTitle',
    url: 'map',
    value: 'Map View',
  },
  {
    name: 'tableViewTitle',
    url: 'table',
    value: 'Table View',
  },
  {
    name: 'aqiViewTitle',
    url: 'aqi',
    value: 'AQI View',
  },
];

export const CONFIGURATION_MENU = [
  {
    name: 'sensor',
    url: 'sensor',
    value: 'Sensor',
  },
  {
    name: 'connectivity',
    url: 'connectivity',
    value: 'Connectivity',
  },
];

export const DASHBOARD_MENU = [
  {
    name: 'widgetViewTitle',
    url: 'widget',
    value: 'Widget View',
  },
  {
    name: 'tableViewTitle',
    url: 'table',
    value: 'Table View',
  },
];

export const CLUSTER_MENU = [
  {
    name: 'mapViewTitle',
    url: 'map',
    value: 'Map View',
  },
  {
    name: 'tableViewTitle',
    url: 'table',
    value: 'Table View',
  },
  {
    name: 'dataViewTitle',
    url: 'data',
    value: 'Data View',
  },
];
export const USER_MENU = [
  {
    name: 'modulesViewTitle',
    url: 'profile',
    value: 'Profile',
  },
  {
    name: 'subUsersViewTitle',
    url: 'sub-users',
    value: 'Sub-Users',
  },
  {
    name: 'aqiViewTitle',
    url: 'aqi',
    value: 'Index',
  },
  {
    name: 'unitsViewTitle',
    url: 'units',
    value: 'Units',
  },
  {
    name: 'modulesViewTitle',
    url: 'modules',
    value: 'Modules',
  },
];

export const COMPLAIN_MENU = [
  {
    name: 'listViewTitle',
    url: 'list',
    value: 'List View',
  },
  {
    name: 'mapViewTitle',
    url: 'map',
    value: 'Map View',
  },
];

export const ALERT_MENU = [
  {
    name: 'alertViewTitle',
    url: 'data-alerts',
    value: 'Data Alerts',
  },
  {
    name: 'listViewTitle',
    url: 'alert-list',
    value: 'Configurations',
  },
];

export const HEATMAP_MENU = [
  {
    name: 'heatmapViewTitle',
    url: 'map',
    value: 'Map View',
  },
  {
    name: 'tableViewTitle',
    url: 'table',
    value: 'Table View',
  }
];

export const DEVICE_MENU = [
  {
    name: 'deviceListViewTitle',
    url: 'devices-list',
    value: 'Device List',
  },
  {
    name: 'logBookViewTitle',
    url: 'log-book',
    value: 'Logbook',
  },
];
