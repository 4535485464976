import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment-timezone';
import { Subscription } from 'rxjs';
import { ClusterFormComponent } from 'src/app/layouts/terminal/pages/cluster/componets/cluster-form/cluster-form.component';
import { CLUSTER_MENU } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Cluster } from 'src/app/shared/models/cluster.ts/cluster';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { ClusterService } from 'src/app/shared/services/cluster.services';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss'],
})
export class ClusterComponent {
  deviceTypes!: Array<DeviceType>;
  menu: SubMenu[] = CLUSTER_MENU;
  cluster_docs: any = {};
  headerForm!: FormGroup;
  private subscriptions: Array<Subscription | undefined> = [];
  intervalArray: any[] = [
    { val: 1, label: '1 hour avg' },
    { val: 24, label: '24 hour avg' },
  ];
  selectedInterval: number = 1;
  selectedCluster: any;
  clusterData: Cluster[] = [];
  clusterDataList: Cluster[] = [];
  filteredClusters: Cluster[] = [];
  maxDate: Moment;
  loaderStates!: boolean;
  public currentDeviceType: string = '';
  public currentDeviceName: any = '';
  public currentClusterName: any = '';
  fetchClusterID: any;
  fetchedSelectedClusterID: any;
  public devices!: DeviceDetails[];

  constructor(
    private commonService: CommonService,
    public router: Router,
    private fb: FormBuilder,
    public deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private clusterService: ClusterService,
    private formsService: FormsService,
    private route: ActivatedRoute,
    private customMomentService: CustomMomentService
  ) {
    this.maxDate = customMomentService.moment();
  }

  ngOnInit(): void {
    this.maxDate = this.customMomentService.moment();
    this.subscriptions.push(
      this.route.firstChild?.params.subscribe((params) => {
        if (params['selectedClusterID']) {
          this.fetchedSelectedClusterID = params['selectedClusterID'];
        }
      })
    );

    this.subscriptions.push(
      this.clusterService.getLoaderState().subscribe((state) => {
        this.loaderStates = state;
      })
    );

    // this.clusterData = this.clusterService.clusterData;
    this.clusterService.getCluster().subscribe((response) => {
      this.clusterData = this.clusterService.clusterData;
    });
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.devices = this.deviceService.registeredDevices!;

    this.subscriptions.push(
      this.clusterService.isGetData$.subscribe((res) => {
        if (res) {
          this.clusterData = this.clusterService.clusterData;
        }
      })
    );

    let endDate = this.customMomentService.moment();
    let startDate = this.customMomentService
      .moment()
      .subtract(1, 'day')
      .startOf('day');

    let deviceType = this.deviceService.setUpDeviceTypeForModule(
      this.deviceTypes,
      this.devices
    );

    this.headerForm = this.fb.group({
      clusterSearch: [''],
      clusterName: [''],
      deviceType: [deviceType || ''],
      interval: [this.intervalArray[0].val],
      startDate: [startDate],
      endDate: [endDate],
    });
    this.addSubscription();
    this.clusterService.fetchedCurrentId.subscribe((clusterId) => {
      this.fetchClusterID = clusterId;
      this.clusterService.setSelectedDeviceName(this.fetchClusterID);
      if (this.fetchClusterID) {
        this.clusterService.selectedDeviceName$.subscribe((type: any) => {
          this.currentDeviceName = type;
          if (this.currentDeviceName) {
            this.headerForm
              .get('clusterName')
              ?.setValue(this.currentDeviceName);
          }
        });
      }
    });

    this.clusterService.setStartDate(this.headerForm.get('startDate')?.value);
    this.clusterService.setEndDate(this.headerForm.get('endDate')?.value);
    this.clusterService.setAvg(this.headerForm.get('interval')?.value);
    if (this.fetchedSelectedClusterID) {
      this.applyFilter();
    }
  }

  isClusterView(): any {
    if (this.router.url === '/cluster/map') {
      return true;
    }
  }
  addSubscription() {
    this.subscriptions.push(
      this.headerForm.get('clusterSearch')?.valueChanges.subscribe((res) => {
        if (res?.label) {
          this.clusterService.setSelectedCluster(res);
        }
      })
    );

    this.subscriptions.push(
      this.headerForm
        .get('deviceType')
        ?.valueChanges.subscribe((deviceType) => {
          if (deviceType?.key !== this.deviceService.currentDeviceType.key) {
            const allAqis: any = this.localStorageService.getParsedValue(
              LocalStorageConstants.OZ_ALL_AQIS
            );
            const allUnits: any = this.localStorageService.getParsedValue(
              LocalStorageConstants.OZ_ALL_UNITS
            );
            const devices = this.localStorageService.getParsedValue(
              LocalStorageConstants.OZ_USER_DEVICE_TYPE
            );
            this.deviceService.setUpDeviceTypeGlobally(
              deviceType?.key,
              devices,
              allAqis,
              allUnits
            );
          }
        })
    );

    this.subscriptions.push(
      this.clusterService.selectedCluster$.subscribe((res) => {
        if (this.headerForm.get('clusterSearch')?.value != res) {
          this.headerForm.controls.clusterSearch.setValue(res);
        }
        if (this.headerForm.get('clusterName')?.value != res) {
          this.headerForm.controls.clusterName.setValue(res);
        }
      })
    );

    this.subscriptions.push(
      this.headerForm?.get('startDate')?.valueChanges.subscribe((startDate) => {
        this.clusterService.setStartDate(startDate);
      })
    );

    this.subscriptions.push(
      this.headerForm?.get('endDate')?.valueChanges.subscribe((endDate) => {
        this.clusterService.setEndDate(endDate);
      })
    );

    this.subscriptions.push(
      this.headerForm?.get('interval')?.valueChanges.subscribe((avgVal) => {
        this.clusterService.setAvg(avgVal);
      })
    );

    this.subscriptions.push(
      this.deviceService.localStorageDeviceUpdate$.subscribe((res) => {
        let deviceType = this.deviceTypes.find(
          (deviceType) => deviceType.deviceTypeId === res.deviceTypeId
        );
        this.headerForm.patchValue({
          deviceType: deviceType,
        });
      })
    );
  }

  filterClusters(searchTerm: string): void {
    this.clusterData = this.clusterData.filter((cluster) =>
      cluster.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  getClusterLabel(cluster: Cluster): any {
    return cluster ? cluster.label : '';
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  isTableView(): boolean {
    return this.router.url.includes('table');
  }
  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  getDeviceType(deviceType: DeviceType): any {
    return deviceType ? deviceType.label.trim() : '';
  }

  getClusterName(cluster: Cluster): string {
    return cluster ? cluster.label.trim() : '';
  }

  isMapView(): boolean {
    return this.router.url.includes('map');
  }

  isDATAView(): boolean {
    return this.router.url.includes('data');
  }

  addCluster() {
    this.formsService
      .openForm(ClusterFormComponent, '', 'lg')
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  applyFilter() {
    const formValue = {
      selectedCluster: this.headerForm.get('clusterName')?.value,
      startDate: this.headerForm?.get('startDate')?.value,
      endDate: this.headerForm?.get('endDate')?.value,
      average: this.headerForm.get('interval')?.value
        ? this.headerForm.get('interval')?.value
        : this.intervalArray[0].val,
    };

    let deviceType = DeviceUtil.getDeviceTypeByKeyOrId(
      formValue?.selectedCluster?.deviceTypeId,
      this.deviceTypes
    );
    if (deviceType?.key !== this.deviceService.currentDeviceType.key) {
      const allAqis: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).aqiIndex;
      const allUnits: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_UNITS
      );
      const devices = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER_DEVICE_TYPE
      );
      this.deviceService.setUpDeviceTypeGlobally(
        deviceType?.key,
        devices,
        allAqis,
        allUnits
      );
    }
    this.clusterService.setSelectedCluster(formValue.selectedCluster);
    this.updateUrl(formValue?.selectedCluster?.clusterId);
    this.clusterService.clusterFilterData.next(formValue);
  }

  ngOnDestroy() {
    this.clusterService.clusterFilterData.next({});
  }
  updateUrl(selectedClusterID: string): void {
    this.router?.navigate(['data', selectedClusterID], {
      relativeTo: this.route,
    });
  }
}
