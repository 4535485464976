<ng-container *ngIf="data?.length">
  <div [ngClass]="{ 'd-none': !showTableHeader }" class="table-header px-4">
    <div class="table-header__title col">
      {{ !isAccordionOpen ? tableTitle : "Parameter Chart" }}
    </div>
    <div class="table-filter" *ngIf="selectedFilters?.length">
      <button mat-icon-button type="button" (click)="scrollLeft()">
        <mat-icon class="material-symbols-outlined">
          keyboard_arrow_left
        </mat-icon>
      </button>
      <div class="table-filter__values" #filter>
        <mat-chip-listbox>
          <mat-chip *ngFor="let filter of selectedFilters">
            <span [innerHTML]="filter.label"></span>
            <button
              matChipRemove
              (click)="removeFilter(filter)"
              matTooltip="Close"
            >
              <mat-icon class="material-symbols-outlined">close</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <button mat-icon-button type="button" (click)="scrollRight()">
        <mat-icon class="material-symbols-outlined">
          keyboard_arrow_right
        </mat-icon>
      </button>
    </div>
    <div class="table-header__icons" *ngIf="showSearchInHeader">
      <div
        class="d-none d-lg-flex px-0"
        [ngClass]="
          forceSearchOpen
            ? 'table-header__icons__searchbar__expanded'
            : searchText
            ? 'table-header__icons__searchbar__expanded'
            : 'table-header__icons__searchbar'
        "
      >
        <mat-form-field
          class="table-header-search-input"
          [ngClass]="
            forceSearchOpen
              ? 'table-header__icons__searchbar__input__expanded'
              : searchText
              ? 'table-header__icons__searchbar__input__expanded'
              : 'table-header__icons__searchbar__input'
          "
        >
          <input matInput placeholder="Search" [formControl]="searchTerm" />
        </mat-form-field>
        <button
          mat-icon-button
          type="button"
          [matTooltip]="forceSearchOpen ? '' : 'Search'"
        >
          <mat-icon class="material-symbols-outlined">search</mat-icon>
        </button>
      </div>
      <button
        mat-icon-button
        (click)="downloadReport()"
        type="button"
        [hidden]="!showDownloadInHeader"
        [disabled]="dataSource.data.length === 0"
        matTooltip="{{
          tableTitle === 'Data Flagging Table' || tableTitle === 'Complains'
            ? 'Download PDF'
            : ' Download Data'
        }}"
      >
        <mat-icon
          style="transform: scale(1.1)"
          class="material-symbols-outlined"
        >
          download
        </mat-icon>
      </button>
      <button
        class="d-none d-lg-inline-block"
        *ngIf="showFilterInHeader"
        mat-icon-button
        type="button"
        [matMenuTriggerFor]="filterMenu"
        (click)="openFilterOptions()"
        matTooltip="Filter On Table"
      >
        <mat-icon class="material-symbols-outlined">filter_alt</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ !isAccordionOpen ? 'View Graph' : 'View Table' }}"
        (click)="toggleAccordion(); $event.stopPropagation()"
        [hidden]="!isChartVisible"
      >
        <mat-icon class="material-symbols-outlined"
          >{{ !isAccordionOpen ? "show_chart" : "table_view" }}
        </mat-icon>
      </button>
      <mat-menu #filterMenu="matMenu">
        <ng-template matMenuContent>
          <div (click)="$event.stopPropagation()">
            <app-filter-options
              [data]="filterOptions"
              (apply)="closeFilterOptions($event)"
            ></app-filter-options>
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
  <div class="table-container flex-grow-1 px-0 overflow-y-auto">
    <table
      *ngIf="!isAccordionOpen"
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
      class="map-table-container"
    >
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            [disabled]="row?.disableRow"
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="selectData($event, row)"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="arrow">
        <th mat-header-cell *matHeaderCellDef>
          <span>Select Row</span>
        </th>
        <td mat-cell *matCellDef="let row; let rowIndex = index">
          <button mat-icon-button (click)="handleRowClick(row, rowIndex)">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let column of columns; index as i"
        [matColumnDef]="column.columnDef"
        [sticky]="
          column.columnDef === 'deviceId' ||
          stickyColumns.includes(column.columnDef)
        "
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngStyle]="{ width: column.width ? column.width : 'auto' }"
        >
          <div class="d-flex flex-row pe-auto">
            <div *ngIf="!column?.enableSelect" mat-sort-header>
              <strong [innerHTML]="column?.header"></strong>
              <mat-icon
                class="ps-2 material-symbols-outlined icon-overflow"
                [matTooltip]="column?.toolTip"
              >
                {{ column?.headerIcon }}
              </mat-icon>
              <span class="sort-header"></span>
            </div>
            <div *ngIf="column?.enableSelect">
              <strong [innerHTML]="column?.header"></strong>
            </div>
            <button
              class="downErrow"
              mat-icon-button
              type="button"
              (click)="handleColumnClick(column)"
              *ngIf="column?.enableSelect"
            >
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </th>
        <ng-container [ngSwitch]="column.columnDef">
          <div *ngSwitchCase="'status'">
            <td mat-cell *matCellDef="let row" class="text-center">
              <mat-icon
                class="material-symbols-outlined"
                [style.background]="row?.cellValues?.[column?.columnDef]?.color"
                style="transform: scale(0.8)"
                [style.color]="whiteColor"
                [style.border-radius]="borderRadius"
                [matTooltip]="row?.cellValues?.[column?.columnDef]?.msg"
              >
                {{ row?.cellValues?.[column?.columnDef]?.icon }}
              </mat-icon>
              <mat-icon
                *ngIf="row?.cellValues?.[column?.columnDef]?.chargingOn"
                class="material-symbols-outlined"
                [matTooltip]="'Chargin On'"
              >
                power
              </mat-icon>
              <mat-icon
                *ngIf="!row?.cellValues?.[column?.columnDef]?.chargingOn"
                class="material-symbols-outlined"
                [matTooltip]="'Chargin Off'"
              >
                power_off
              </mat-icon>
              <mat-icon
                *ngIf="row?.cellValues?.[column?.columnDef]?.isOutOfNetwork"
                class="material-symbols-outlined"
                [matTooltip]="'Network Issue'"
              >
                wifi_off
              </mat-icon>
            </td>
          </div>
          <div *ngSwitchCase="'action'">
            <td mat-cell *matCellDef="let row" title="">
              <mat-icon
                class="material-symbols-outlined cursor-pointer"
                (click)="column.action(row)"
              >
                {{ column.icon }}
              </mat-icon>
            </td>
          </div>
          <div *ngSwitchCase="'actions'">
            <td mat-cell *matCellDef="let row" title="">
              <mat-icon
                class="material-symbols-outlined cursor-pointer"
                mat-icon-button
                [matMenuTriggerFor]="actionsMenu"
                *ngIf="!row?.cellValues?.[column?.columnDef]?.hide"
              >
                {{ column.icon }}
              </mat-icon>
              <mat-menu #actionsMenu="matMenu">
                <ng-template matMenuContent>
                  <div>
                    <ng-container
                      *ngFor="let option of row?.cellValues?.[column?.columnDef]?.options"
                    >
                      <mat-option
                        [innerHTML]="option?.label"
                        (click)="option?.action(row)"
                        [disabled]="option?.disable"
                      >
                      </mat-option>
                    </ng-container>
                  </div>
                </ng-template>
              </mat-menu>
            </td>
          </div>

          <div *ngSwitchCase="'anotherAction'">
            <td mat-cell *matCellDef="let row" title="" class="text-center">
              <mat-icon
                class="material-symbols-outlined cursor-pointer"
                (click)="column.action(row)"
              >
                {{ column.icon }}
              </mat-icon>
            </td>
          </div>
          <div *ngSwitchCase="'toggle'">
            <td mat-cell *matCellDef="let row" title="">
              <mat-slide-toggle
                [checked]="row?.cellValues?.[column?.columnDef]?.cell || false"
                class="ml-20"
                (change)="column.changeToggle($event, row)"
              ></mat-slide-toggle>
            </td>
          </div>
          <div *ngSwitchCase="'editaction'">
            <td mat-cell *matCellDef="let row" title="">
              <mat-icon
                class="material-symbols-outlined cursor-pointer"
                (click)="column.action(row)"
              >
                {{ column.icon }}
              </mat-icon>
            </td>
          </div>
          <div *ngSwitchCase="'Status'">
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <span
                *ngIf="
                  row?.cellValues?.[column?.columnDef]?.cell == '0' && row?.cellValues?.[column?.columnDef]?.cell != 'undefined'
                "
                [ngStyle]="{
                  color: '#FFFFFF',
                  background: '#008000',
                  'border-radius': '10px',
                  'padding-top': '3px',
                  'padding-left': '5px',
                  'padding-bottom': '3px',
                  'padding-right': '5px'
                }"
              >
                Pending
              </span>
              <span
                *ngIf="
                  row?.cellValues?.[column?.columnDef]?.cell == '1' && row?.cellValues?.[column?.columnDef]?.cell != 'undefined'
                "
                [ngStyle]="{
                  color: '#FFFFFF',
                  background: '#daa520',
                  'border-radius': '12px',
                  'padding-top': '3px',
                  'padding-left': '5px',
                  'padding-bottom': '3px',
                  'padding-right': '5px'
                }"
              >
                In progress
              </span>
              <span
                *ngIf="
                  row?.cellValues?.[column?.columnDef]?.cell == '2' && row?.cellValues?.[column?.columnDef]?.cell != 'undefined'
                "
                [ngStyle]="{
                  color: '#FFFFFF',
                  background: '#666a6d',
                  'border-radius': '12px',
                  'padding-top': '3px',
                  'padding-left': '5px',
                  'padding-bottom': '3px',
                  'padding-right': '5px'
                }"
              >
                Resolved
              </span>
            </td>
          </div>
          <div *ngSwitchCase="'priority'">
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <span
                [innerHTML]="
                  row?.cellValues?.[column?.columnDef]?.cell != 'undefined' && row?.cellValues?.[column?.columnDef]?.cell == 'LOW'
                    ? 'Low'
                    : ''
                "
                [style.color]="'#0076CE'"
              ></span>
              <span
                [innerHTML]="
                  row?.cellValues?.[column?.columnDef]?.cell != 'undefined' &&
                  row?.cellValues?.[column?.columnDef]?.cell == 'MEDIUM'
                    ? 'Medium'
                    : ''
                "
                [style.color]="'#ff6347'"
              ></span>
              <span
                [innerHTML]="
                  row?.cellValues?.[column?.columnDef]?.cell != 'undefined' &&
                  row?.cellValues?.[column?.columnDef]?.cell == 'HIGH'
                    ? 'High'
                    : ''
                "
                [style.color]="'#FF0000'"
              ></span>
            </td>
          </div>
          <div *ngSwitchCase="'slno'">
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <span [innerHTML]="index + 1"></span>
            </td>
          </div>
          <div *ngSwitchCase="'image_url'">
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <img
                [src]="
                  row?.cellValues?.[column?.columnDef]?.cell != 'undefined' ? row?.cellValues?.[column?.columnDef]?.cell : ''
                "
                matTooltip="Photo is not there"
                height="25px"
                width="25px"
                (click)="openComponentOverlay(row?.cellValues?.[column?.columnDef]?.cell)"
              />
            </td>
          </div>
          <div *ngSwitchCase="'dynamicIconAction'">
            <td mat-cell *matCellDef="let row" title="">
              <button
                class="action_btn"
                type="button"
                (click)="column.action(row)"
                [disabled]="row?.cellValues?.[column?.columnDef]?.disable"
              >
                <mat-icon
                  [matTooltip]="(stripHtml(row?.cellValues?.[column?.columnDef]?.cell)) == 'true' ? 'Click to unarchive project' : 'Click to archive project' 
                  "
                  class="material-symbols-outlined cursor-pointer"
                  matToolTipPosition="left"
                >
                  {{ row?.cellValues?.[column?.columnDef]?.icon }}
                </mat-icon>
              </button>
            </td>
          </div>
          <div *ngSwitchCase="'viewAction'">
            <td mat-cell *matCellDef="let row" title="" class="text-center">
              <mat-icon
                class="material-symbols-outlined cursor-pointer"
                (click)="column.action(row)"
              >
                {{ column.icon }}
              </mat-icon>
            </td>
          </div>
          <div *ngSwitchCase="'dateSelector'">
            <td mat-cell *matCellDef="let row" title="">
              <mat-form-field appearance="fill" *ngIf="!row?.disableRow">
                <mat-label>Date</mat-label>
                <input
                  [disabled]="!selection.isSelected(row)"
                  matInput
                  [max]="row.maxDate"
                  [value]="selection.isSelected(row) ? row.expiryDate : null"
                  [matDatepicker]="picker"
                  (dateChange)="onDateSelected($event, row)"
                />
                <mat-datepicker-toggle
                  [disabled]="!selection.isSelected(row)"
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <div *ngIf="row.disableRow">-</div>
            </td>
          </div>
          <div *ngSwitchCase="'iconToggles'">
            <td mat-cell *matCellDef="let row" title="">
              <button
                *ngFor="let item of row?.cellValues?.[column?.columnDef]?.cell"
                mat-icon-button
                type="button"
                class="accessButton"
                [matTooltip]="
                  item.active ? 'Hide ' + item.title : 'Show ' + item.title
                "
                matTooltipPosition="below"
                aria-label="Button that displays a tooltip when focused or hovered over"
                (click)="column.onToggle(row, item)"
                style="width: 40px"
                [disabled]="!selection.isSelected(row) || item?.disableStatus"
                [ngClass]="{
                  active: item.active && selection.isSelected(row),
                  disabled: !selection.isSelected(row)
                }"
              >
                <mat-icon
                  class="material-symbols-outlined icon-size"
                  [ngClass]="{
                    'mat-primary': item.active,
                    'inactive-icon':
                      selection.isSelected(row) &&
                      !item.active &&
                      !item?.disableStatus
                  }"
                >
                  {{ item.icon }}
                </mat-icon>
              </button>
            </td>
          </div>
          <div *ngSwitchCase="'configNameDesc'">
            <td
              mat-cell
              *matCellDef="let row"
              class="config-name-desc-cell"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <span class="d-flex justify-content-start align-items-center">
                <span
                  class="config-name-text"
                  [innerHTML]="
                    row?.cellValues?.[column?.columnDef]?.cell != 'undefined' ? row?.cellValues?.[column?.columnDef]?.cell : ''
                  "
                >
                </span>
                <button
                  mat-icon-button
                  type="button"
                  class="config-name-button"
                  [matTooltip]="row?.cellValues?.[column?.columnDef]?.isConfigDesc"
                  matTooltipPosition="right"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon class="material-symbols-outlined icon-size">
                    info
                  </mat-icon>
                </button>
              </span>
            </td>
          </div>
          <div *ngSwitchCase="'button'">
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <ng-container
                *ngIf="row?.cellValues?.[column?.columnDef]?.label === 'Add'"
              >
                <!-- [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''" -->
                <button
                  [color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
                  type="button"
                  mat-flat-button
                  (click)="column.action(row)"
                >
                  {{ row?.cellValues?.[column?.columnDef]?.label }}
                </button>
              </ng-container>
              <ng-container
                *ngIf="row?.cellValues?.[column?.columnDef]?.label === 'Edit'"
              >
                <button
                  mat-stroked-button
                  type="button"
                  (click)="column.action(row)"
                >
                  {{ row?.cellValues?.[column?.columnDef]?.label }}
                </button>
              </ng-container>
            </td>
          </div>
          <div *ngSwitchCase="'colorLegend'">
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
            >
              <app-aqi-color-legend
                *ngIf="row?.cellValues?.[column?.columnDef]?.visible && row?.cellValues?.[column?.columnDef]?.cell"
                [aqiIndexColor]="{
                  color: row?.cellValues?.[column?.columnDef]?.cell?.color,
                  labels: row?.cellValues?.[column?.columnDef]?.cell?.labels,
                  range: row?.cellValues?.[column?.columnDef]?.cell?.range
                }"
              ></app-aqi-color-legend>
            </td>
          </div>

          <div *ngSwitchCase="'longTextMessage'">
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
              class="py-1"
            >
              <span
                class="long-text-message"
                [innerHTML]="
              row?.cellValues?.[column?.columnDef]?.cell != 'undefined' ? row?.cellValues?.[column?.columnDef]?.cell : ''
            "
              ></span>
            </td>
          </div>

          <div *ngSwitchDefault>
            <td
              mat-cell
              *matCellDef="let row; let rowIndex = index"
              [style.backgroundColor]="row?.cellValues?.[column.columnDef]?.backgroundColor ?? ''"
              [style.color]="column?.color ? row?.cellValues?.[column?.columnDef]?.color : ''"
              (click)="handleCellClick(row, column, rowIndex)"
            >
              <ng-container [ngSwitch]="column.columnType">
                <ng-container *ngSwitchCase="'chip'">
                  <mat-chip-listbox
                    class="py-2"
                    [ngClass]="{
                      'data-table-chip-list-tags': column.columnDef === 'tags',
                      'data-table-chip-list': column.columnDef !== 'tags'
                    }"
                  >
                    <ng-container
                      *ngIf="row?.cellValues?.[column?.columnDef]?.cell?.length < 2; else showOthers"
                    >
                      <div
                        *ngFor="let item of row?.cellValues?.[column?.columnDef]?.cell"
                        class="d-flex column"
                      >
                        <mat-chip
                          [matTooltip]="item"
                          [ngClass]="{ badge: column.columnDef === 'tags' }"
                        >
                          {{ item }}
                        </mat-chip>
                      </div>
                    </ng-container>
                    <ng-template #showOthers>
                      <div
                        class="d-flex flex-column"
                        [ngClass]="{
                          'data-table-chip-list-tags':
                            column.columnDef === 'tags',
                          'data-table-chip-list': column.columnDef !== 'tags'
                        }"
                      >
                        <div
                          *ngFor="let item of [row?.cellValues?.[column?.columnDef]?.cell[0]]"
                        >
                          <mat-chip
                            [matTooltip]="item"
                            [ngClass]="{ badge: column.columnDef === 'tags' }"
                          >
                            {{ item }}
                          </mat-chip>
                        </div>
                        <span
                          (click)="$event.stopPropagation()"
                          [matMenuTriggerFor]="chipList"
                          class="d-flex align-items-center ps-3 text-primary text-decoration-underline cursor-pointer"
                        >
                          View +{{ row?.cellValues?.[column?.columnDef]?.cell.length - 1 }}
                          more
                        </span>
                      </div>
                      <mat-menu
                        #chipList="matMenu"
                        yPosition="above"
                        class="data-table-chip-list"
                      >
                        <ng-template matMenuContent>
                          <div (click)="$event.stopPropagation()">
                            <div
                              *ngFor="let item of row?.cellValues?.[column?.columnDef]?.cell"
                              class="px-3 my-1 d-flex column test"
                              [ngClass]="{
                                'data-table-chip-list-tags':
                                  column.columnDef === 'tags',
                                'data-table-chip-list':
                                  column.columnDef !== 'tags'
                              }"
                            >
                              <mat-chip
                                matTooltip="{{ item }}"
                                [ngClass]="{
                                  badge: column.columnDef === 'tags'
                                }"
                              >
                                {{ item }}
                              </mat-chip>
                            </div>
                          </div>
                        </ng-template>
                      </mat-menu>
                    </ng-template>
                  </mat-chip-listbox>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <span
                    [innerHTML]="
                      row?.cellValues?.[column?.columnDef]?.cell != 'undefined' ? row?.cellValues?.[column?.columnDef]?.cell : ''
                    "
                  ></span>
                </ng-container>
              </ng-container>
            </td>
          </div>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'row-selected': selection.isSelected(row) }"
      ></tr>
    </table>
    <div *ngIf="isAccordionOpen">
      <app-graph-accordion
        [columns]="columns"
        [deviceData]="deviceData"
        [filterOptions]="filterOptions"
      ></app-graph-accordion>
    </div>
  </div>
  <mat-paginator
    *ngIf="!isAccordionOpen"
    [ngClass]="{ 'd-none': !showPaginator }"
    [pageSize]="showPaginator ? pageSize : dataSource.data.length"
    [pageSizeOptions]="[30, 40, 50, 100]"
    showFirstLastButtons
  >
  </mat-paginator>
</ng-container>
<ng-container>
  <div class="w-100 h-100" [ngClass]="{ 'd-none': !!data.length }">
    <ng-template #noData></ng-template>
  </div>
</ng-container>
