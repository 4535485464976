<div class="row h-100 justify-content-center loader position-relative">
  <div
    class="col-12 col-md-9 col-lg-8 col-xl-7 my-5 align-self-center"
    *ngIf="(loadTable | async) == true"
  >
    <div class="d-flex align-items-center logo-wrapper">
      <div class="logo-container">
        <img
          class="logo my-2"
          src="./../../../assets/envizom-logo.png"
          alt="envizom"
        />
      </div>
      <img src="/assets/Envizom-Dark-Mode-Logo.png" class="envizom-logo" />
    </div>

    <ng-container *ngIf="!isLinkSent">
      <h3>{{ labelConfig.title }}</h3>
      <span>{{ labelConfig.subTitle }}</span>

      <form [formGroup]="loginForm" class="row mt-5">
        <mat-form-field class="col-12 mb-4" appearance="outline">
          <mat-label>Email ID</mat-label>
          <input matInput placeholder="Email ID" formControlName="emailId" />
        </mat-form-field>
        <ng-container *ngIf="currentView === 'login'">
          <mat-form-field class="col-12 mb-2" appearance="outline">
            <mat-label>Password</mat-label>
            <input
              type="password"
              matInput
              placeholder="Password"
              formControlName="password"
              [type]="hide ? 'text' : 'password'"
            />
            <mat-icon
              matSuffix
              (click)="hide = !hide"
              [style.cursor]="'pointer'"
              >{{ hide ? "visibility" : "visibility_off" }}
            </mat-icon>
          </mat-form-field>
          <mat-checkbox
            [color]="'primary'"
            class="col-12 mb-4 terms"
            formControlName="termsAndConditions"
            >I accept the
            <a [routerLink]="" (click)="openTermsAndConditionModal()"
              ><u>Terms and Conditions</u></a
            >
          </mat-checkbox>
        </ng-container>
        <div class="col-12">
          <button
            [matTooltip]="labelConfig.buttonTooltip"
            class="col-12"
            mat-raised-button
            [color]="'primary'"
            [disabled]="loginForm.invalid || !termsAndConditions || loader"
            (click)="submit()"
          >
            {{ labelConfig.buttonText }}
            <!-- {{ loader ? "Logging In..." : "LOG IN" }} -->
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="isLinkSent">
      <img src="./../../../assets/images/login/email.svg" />

      <h3 style="padding-top: 30px; padding-bottom: 10px">Check your mail!</h3>
      <span
        >We have sent you the password recovery link. Click on it and reset your
        password.</span
      >
    </ng-container>

    <ng-container *ngIf="currentView === 'login'">
      <div
        class="w-100 pt-3"
        style="display: flex !important; justify-content: start"
      >
        <p
          style="cursor: pointer"
          class="text-decoration-underline"
          (click)="toggleLoginAndForgotPswd()"
        >
          Forgot Password?
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="currentView === 'forgotPswd'">
      <div
        class="w-100 pt-3"
        style="display: flex !important; justify-content: end"
      >
        <ng-container *ngIf="!isLinkSent">
          <p
            style="cursor: pointer"
            class="text-decoration-underline"
            (click)="toggleLoginAndForgotPswd()"
          >
            Login
          </p>
        </ng-container>

        <ng-container *ngIf="isLinkSent">
          <button
            mat-button
            (click)="toggleLoginAndForgotPswd()"
            matTooltip="Back To Login"
          >
            BACK TO LOGIN
            <mat-icon iconPositionEnd>arrow_forward</mat-icon>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="py-5" *ngIf="(loadTable | async) == false">
    <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
  </div>
</div>
