import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NbThemeService } from '@nebular/theme';
import {
  Subject,
  Subscription,
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { WidgetService } from '../../services/widget.service';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import { WindDataService } from 'src/app/shared/services/wind-data.service';

@Component({
  selector: 'app-location-widget',
  templateUrl: './location-widget.component.html',
  styleUrls: ['./location-widget.component.scss'],
})
export class LocationWidgetComponent
  implements IWidgetComponent, OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() public widgetInfo: WidgetInfo = {} as WidgetInfo;

  device?: DeviceDetails;
  deviceData: DeviceDetails[] = [];

  @ViewChild(GoogleMap) public googleMap!: GoogleMap;
  @ViewChildren(MapMarker) public mapMarkers!: QueryList<MapMarker>;
  @ViewChildren('deviceInfoWindow')
  public deviceInfoWindows!: QueryList<MapInfoWindow>;

  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  private subscriptions: Subscription[] = [];

  private destroy$: Subject<void> = new Subject<void>();
  public windData: any;
  width!: number;
  height!: number;

   @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      try {
        this.width = (event.target as Window).innerWidth;
        this.height = (event.target as Window).innerHeight;
      } catch (e) {}
    }

  constructor(
    public googleMapsService: GoogleMapsService,
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef,
    private themeService: NbThemeService,
    private windDataService: WindDataService
  ) {}

  ngOnInit(): void {
    this.device = this.widgetService.device;
    this.deviceData = this.widgetService.realTimeDeviceData;

    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'device': {
              this.device = this.widgetService.device;
              break;
            }
            case 'realTimeDeviceData': {
              this.deviceData = this.widgetService.realTimeDeviceData;
              break;
            }
            default: {
              // console.info('location-widget -> key for changed value:', res);
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );

    this.subscriptions.push(
      this.googleMapsService.isApiLoaded
        .pipe(
          filter(Boolean),
          delay(1),
          switchMap(() => this.googleMap?.idle),
          take(1),
          tap(() => this.mapReady(this.googleMap?.googleMap as unknown as Map)),
          switchMap(() => this.googleMap!.zoomChanged)
        )
        .subscribe(() => {})
    );

    // const windData = this.windDataService.getWindDataForOverview();
    // windData ? windData[2].magnitude = 50 : windData;
    // this.windData = windData?.filter((data) => (data.direction && data.magnitude && data.lat && data.lon) ? true : false);
  }

  ngOnChanges() {
    setTimeout(() => {}, 1000);
  }

  ngAfterViewInit(): void {
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName == 'material-dark') {
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };
        if (firstTimeLoaded) {
          setTimeout(() => {
            this.fitBounds(this.googleMap.googleMap!);
          });
        }
        firstTimeLoaded = true;
      });
  }

  mapReady(map: Map): void {
    this.deviceInfoWindows
      .toArray()[0]
      .open(this.mapMarkers.toArray()[0], false);
  }

  fitBounds(map: Map): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers = [this.device]!.map((device) => {
      let lat: number = device?.payload?.d?.lat || device?.latitude || 0;
      let lon: number = device?.payload?.d?.lon || device?.longitude || 0;

      return {
        lat: lat! - 20,
        lng: lon!,
      };
    });

    markers.forEach((marker) => {
      latLngBounds.extend(marker);
    });

    map.fitBounds(latLngBounds);
  }

  onMapOptionsUpdated(options: MapOptions) {
    this.options = { ...options };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
