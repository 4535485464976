<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu" [configureButton]="true">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center justify-content-between gx-2 gy-2 gy-lg-0 mx-0"
    >
      <ng-container *ngIf="isListView()">
        <mat-form-field class="col-12 col-lg-3">
          <mat-label>Device Type</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Device Type'"
            matInput
            formControlName="deviceType"
            [matAutocomplete]="deviceType"
          />
          <mat-autocomplete
            #deviceType="matAutocomplete"
            [displayWith]="getDeviceType"
          >
            <mat-option
              *ngFor="
                let deviceType of deviceTypes
                  | search : headerForm.get('deviceType')?.value : 'label'
              "
              [value]="deviceType"
            >
              {{ deviceType.label }}
              <ng-container *ngIf="deviceType.count">
                ({{ deviceType.count }})
              </ng-container>
            </mat-option>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            matSuffix
            mat-icon-button
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined"
              >arrow_drop_down</mat-icon
            >
          </button>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="isLogView()">
        <div class="col-12 col-lg-10 d-flex flex-wrap align-items-center gap-2">
          <!-- <mat-form-field class="col-12 col-lg-3">
            <mat-label>Select Device</mat-label>
            <mat-select formControlName="deviceId" multiple>
              <app-multiple-select-dropdown
                [options]="allDeviceIds"
                [control]="paramsControl"
              ></app-multiple-select-dropdown>
              <mat-optgroup
                *ngFor="let deviceType of deviceTypes"
                [label]="deviceType.label"
              >
                <mat-option
                  *ngFor="
                    let device of devices ?? [] | deviceType : deviceType.key
                  "
                  [value]="device.deviceId"
                >
                  {{ device.label }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field> -->

          <div
            class="col-12 col-md-10 device-form-field gap-2"
            [ngClass]="!deviceTags.length ? 'col-lg-3' : 'col-lg-5'"
          >
            <app-tag-and-devicetype-dropwdown
              [isMultipleDeviceSelection]="true"
              [tags]="deviceTags"
              [deviceTypes]="deviceTypes"
              [devices]="devices"
              [patchDevice]="devices"
              [showButton]="false"
              (selectedDevice)="onDeviceSelectionInLogBook($event)"
            >
            </app-tag-and-devicetype-dropwdown>
          </div>

          <mat-form-field class="col-12 col-lg-3" style="margin-left: 8px">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                formControlName="startDate"
                [placeholder]="'Start Date'"
              />
              <input
                matEndDate
                formControlName="endDate"
                [placeholder]="'End Date'"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error
              *ngIf="
                headerForm?.controls?.startDate?.hasError('matStartDateInvalid')
              "
            >
              Invalid start date
            </mat-error>
            <mat-error
              *ngIf="
                headerForm?.controls?.endDate?.hasError('matEndDateInvalid')
              "
            >
              Invalid end date
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-12 col-lg-2">
            <mat-label>Select Activity</mat-label>
            <mat-select formControlName="logType" multiple>
              <mat-option
                *ngFor="let type of availableLogTypes"
                [value]="type.value"
              >
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-12 col-lg-auto">
            <button
              mat-raised-button
              color="primary"
              class="w-100 w-lg-auto"
              matTooltip="Apply"
              [disabled]="!headerForm.valid"
              (click)="onApply()"
            >
              Apply
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-auto justify-content-end">
          <button
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
            matTooltip="Add Log"
            (click)="openLogForm()"
          >
            Add Log
            <mat-icon matSuffix class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
