import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NavigationEnd, Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import { Moment } from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { map, Subject, Subscription, takeUntil } from 'rxjs';
import { OVERVIEW_MENU } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { OverviewService } from 'src/app/shared/services/overview.service';
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  private destroy$: Subject<void> = new Subject<void>();
  @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter();
  deviceTypes!: Array<DeviceType>;
  menu: SubMenu[] = OVERVIEW_MENU;
  headerForm!: FormGroup;
  devices: DeviceDetails[] = [];
  requestedDeviceId!: string;
  subscriptions: Subscription[] = [];
  currentUrl: string = '';
  formValues: any;
  currentDeviceType!: DeviceType;
  latestDocSnapshotTimestamp?: string;
  isInitialLoad: boolean = true;
  maxDate = this.customMomentService.moment();
  maxTime!: string;
  usersTimeFormat: number = this.commonService.getTimeFormat();
  public clockTheme!: NgxMaterialTimepickerTheme;
  public deviceTags: string[] = this.deviceService.getUniqueDeviceTags(
    this.deviceService.registeredDevicesWithVibration || []
  );
  public deviceForMap!: DeviceDetails | undefined;

  constructor(
    private commonService: CommonService,
    public router: Router,
    private fb: FormBuilder,
    public deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private overviewService: OverviewService,
    private customMomentService: CustomMomentService,
    private themeService: NbThemeService
  ) {}

  ngOnInit(): void {
    this.maxDate = this.customMomentService.moment();
    this.devices = this.deviceService.sortDevices(
      this.deviceService.registeredDevicesWithVibration!,
      'deviceId'
    );
    this.deviceTypes = this.commonService.getUserDeviceTypesWithVibration();

    //building the form
    this.buildForm();
    //passing the form value to aqi component, so that both components are sync properly
    this.onApply();
    this.subscribeToFormControls();
    this.getCurrentUrl();
    this.addSubscriptions();
    this.onThemeChange();
  }

  buildForm() {
    const todayDate = this.customMomentService.moment();
    const time = todayDate.startOf('hour').format('hh:mm A').toLowerCase();

    this.headerForm = this.fb.group({
      deviceSearch: [this.deviceService?.currentDevice || ''],
      device: [''],
      deviceType: [this.deviceTypes[0] || ''],
      deviceName: [''],
      startDate: this.customMomentService.moment(),
      selectedTime: time,
    });

    this.setMaxTime(todayDate);

    let d = this.headerForm.get('startDate')?.valueChanges.subscribe((res) => {
      this.setMaxTime(res);
    });
    if (d) this.subscriptions.push(d);
  }

  setMaxTime(inputTime: Moment) {
    const todayDate = this.customMomentService.moment().format('DD/MM/yyyy');
    const incomingDate = inputTime.format('DD/MM/yyyy');
    // Check if the incoming date is today
    if (todayDate === incomingDate) {
      // Set max time to the start of the current hour
      this.maxTime = this.customMomentService
        .moment()
        .startOf('hour')
        .format('hh:mm A')
        .toLowerCase();
    } else {
      // Set max time to the end of the day (11:59 PM) for any other date
      this.maxTime = '11:59 pm';
    }
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  addSubscriptions(): void {
    const localStorageDeviceUpdate: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe(
        (deviceType: any) => {
          this.headerForm.controls.deviceType.setValue(
            this.deviceService.currentDeviceType,
            { emitEvent: false }
          );
          if (this.deviceService.currentDevice) {
            this.headerForm.controls.deviceSearch.setValue(
              this.deviceService.currentDevice,
              { emitEvent: false }
            );
          } else {
            this.headerForm.controls.deviceSearch.setValue(undefined, {
              emitEvent: false,
            });
          }
        }
      );

    // Get the device ID when the user clicks on a map pin to select device
    const device = this.deviceService.localStorageDeviceUpdate$.subscribe(
      () => {
        this.deviceForMap = this.deviceService.currentDevice;
      }
    );

    this.subscriptions.push(localStorageDeviceUpdate);
    this.subscriptions.push(device);
  }

  ngAfterViewInit(): void {}

  ngAfterContentChecked(): void {
    if (
      !this.localStorageService
        .getParsedValue(LocalStorageConstants.MODULE_ACCESS)
        .includes(1009)
    ) {
      this.menu = OVERVIEW_MENU.filter((menu) => menu.url !== 'map');
    }
  }

  subscribeToFormControls(): void {
    this.headerForm.controls.deviceType.valueChanges.subscribe((type) => {
      this.currentDeviceType = type;
      if (type && !this.isAQIView()) {
        let devices =
          this.deviceService.registeredDevicesWithVibration?.filter(
            (device) => device.deviceType === type.key
          ) || [];
        if (devices && devices.length) {
          this.deviceService.setupDeviceIdGlobally(devices[0]?.deviceId);
        }
      }
    });
    this.headerForm.controls.deviceSearch.valueChanges.subscribe((device) => {
      if (device && device?.deviceId) {
        this.deviceService.setupDeviceIdGlobally(device?.deviceId);
      }
    });
  }

  clearDeviceType(): void {
    this.headerForm.controls.deviceType.setValue(undefined);
  }

  clearDeviceName(): void {
    this.headerForm.controls.deviceSearch.setValue(undefined);
    this.deviceService.removeDeviceGlobally();
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  getDeviceType(deviceType: DeviceType): string {
    return deviceType ? deviceType.label.trim() : '';
  }

  isMapView(): boolean {
    return this.router.url.includes('map');
  }

  isTableView(): boolean {
    return this.router.url.includes('table');
  }

  isAQIView(): boolean {
    return this.router.url.includes('aqi');
  }

  onApply() {
    let hours = 0;
    let minutes = 0;
    let time = this.headerForm.get('selectedTime')?.value.split(' ');

    if (time.length > 0) {
      const hrsMins = time[0].split(':');
      const hour = Number(hrsMins[0]);
      const minute = Number(hrsMins[1]);

      //if user time format is 12 hour
      if (this.usersTimeFormat === 12) {
        if (time[1] === 'PM') {
          hours = hour === 12 ? 12 : hour + 12;
        } else {
          hours = hour === 12 ? 0 : hour;
        }
        minutes = minute;
      } else {
        hours = hour;
        minutes = minute;
      }
    }

    let newDate;
    if (isNaN(hours) || isNaN(minutes)) {
      newDate = this.customMomentService
        .moment(this.headerForm.get('startDate')?.value)
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });
    } else {
      newDate = this.customMomentService
        .moment(this.headerForm.get('startDate')?.value)
        .set({
          hours: hours,
          minutes: minutes,
          seconds: 0,
          milliseconds: 0,
        });
    }
    let lte = newDate.unix();
    let gte = newDate.subtract(1, 'day').unix();
    // const todayDate = this.customMomentService.moment().date();
    // const todayMonth = this.customMomentService.moment().month();
    // const startDate = this.customMomentService.formatDate({
    //   date: this.customMomentService.moment(
    //     this.headerForm.get('startDate')?.value
    //   ),
    // });
    if (this.currentDeviceType) {
      let devices =
        this.deviceService.registeredDevicesWithVibration?.filter(
          (device) => device.deviceType === this.currentDeviceType.key
        ) || [];
      if (devices && devices.length) {
        this.deviceService.setupDeviceIdGlobally(devices[0]?.deviceId);
      }
    }

    const formValue = {
      deviceType: this.headerForm.get('deviceType')?.value,
      gte: gte,
      lte: lte,
      avg: 86400,
    };
    this.formValues = formValue;
    this.overviewService.overviewForm.next(formValue);
  }

  getCurrentUrl() {
    if (window.location.href.split('/').includes('aqi')) {
      this.deviceTypeForAQIView();
    } else if (window.location.href.split('/').includes('table')) {
      this.deviceTypeForTableView();
    } else {
      this.deviceTypeForMapView();
    }
    const router: Subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        if (this.currentUrl.split('/').includes('aqi')) {
          this.deviceTypeForAQIView();
        } else if (this.currentUrl.split('/').includes('table')) {
          this.deviceTypeForTableView();
        } else {
          this.deviceTypeForMapView();
        }
      }
    });

    this.subscriptions.push(router);
  }

  deviceTypeForAQIView() {
    this.deviceTypes = this.deviceTypes.filter(
      (deviceType) => deviceType.index
    );
    const isGlobalDeviceTypeValid = this.deviceTypes.find(
      (deviceType) =>
        deviceType.deviceTypeId ===
        this.deviceService.currentDeviceType.deviceTypeId
    );

    if (!isGlobalDeviceTypeValid) {
      let devices =
        this.deviceService.registeredDevicesWithVibration?.filter(
          (device) => device.deviceType === this.deviceTypes[0].key
        ) || [];
      if (devices && devices.length) {
        this.deviceService.setupDeviceIdGlobally(devices[0]?.deviceId);
      }
    }
    this.headerForm.patchValue({
      deviceType: this.deviceTypes.find(
        (dt) => dt.key === this.deviceService.currentDeviceType.key
      ),
    });
  }

  deviceTypeForTableView() {
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    const isGlobalDeviceTypeValid = this.deviceTypes.find(
      (deviceType) =>
        deviceType.deviceTypeId ===
        this.deviceService.currentDeviceType.deviceTypeId
    );

    if (!isGlobalDeviceTypeValid) {
      let devices =
        this.deviceService.registeredDevicesWithVibration?.filter(
          (device) => device.deviceType === this.deviceTypes[0].key
        ) || [];
      if (devices && devices.length) {
        this.deviceService.setupDeviceIdGlobally(devices[0]?.deviceId);
      }
    }

    this.headerForm.patchValue({
      deviceType: this.deviceTypes.find(
        (dt) => dt.key === this.deviceService.currentDeviceType.key
      ),
    });
  }

  deviceTypeForMapView() {
    this.deviceTypes = this.commonService.getUserDeviceTypesWithVibration();
    this.clearDeviceName();
  }

  onRefresh() {
    this.refreshEvent.emit(true);
    this.deviceService.setupDeviceDetails(false).subscribe({
      next: (deviceDetails) => {
        this.getCurrentUrl();
        this.refreshEvent.emit(false);
      },
      error: (error) => {
        this.refreshEvent.emit(false);
        console.info('Error fetching device details', error);
      },
    });
  }

  onActivate(component: any): void {
    if (component.refreshEvent) {
      component.refreshEvent = this.refreshEvent;
    }
  }

  onThemeChange() {
    let theme = this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        try {
          this.setClockTheme(themeName);
        } catch (e) {}
      });
    this.subscriptions.push(theme);
  }

  setClockTheme(currentTheme: string) {
    if (currentTheme === 'material-dark') {
      this.clockTheme = {
        container: {
          bodyBackgroundColor: '#080909',
          buttonColor: '#00B3A6',
        },
        dial: {
          dialBackgroundColor: '#00B3A6',
        },
        clockFace: {
          clockFaceTimeActiveColor: '#191D22',
          clockFaceBackgroundColor: '#4B5055',
          clockHandColor: '#00B3A6',
          clockFaceTimeInactiveColor: '#fff',
        },
      };
    } else {
      this.clockTheme = {
        container: {
          bodyBackgroundColor: '#fff',
          buttonColor: '#00B3A6',
        },
        dial: {
          dialBackgroundColor: '#00B3A6',
        },
        clockFace: {
          clockFaceTimeActiveColor: '#fff',
          clockFaceBackgroundColor: '#f0f0f0',
          clockHandColor: '#00B3A6',
          clockFaceTimeInactiveColor: '#191D22',
        },
      };
    }
  }

  // Sets the selected device globally when the user clicks on a device in the header dropdown
  onDeviceSelectionInMap(device: DeviceDetails) {
    if (device && device?.deviceId) {
      this.deviceService.setupDeviceIdGlobally(device?.deviceId);
    } else {
      this.clearDeviceName();
    }
  }

  // Emits the updated device list when the user selects or deselects tags from the header dropdown
  onTagSelection(devices: DeviceDetails[]) {
    this.overviewService.onHeaderTagChange.next(devices);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
