import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Subscription } from 'rxjs';
import { DeviceDetails } from '../../models/device/device-details';
import { CommonMapService } from '../../services/common-map.service';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../config/material.module';
import { WindLayerComponent } from '../map-components/wind-layer/wind-layer.component';
import { WindData } from '../../types/wind';
import { WindDataService } from '../../services/wind-data.service';

type MapOptions = google.maps.MapOptions;

@Component({
  selector: 'app-common-map-controls',
  templateUrl: './common-map-controls.component.html',
  styleUrls: ['./common-map-controls.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, WindLayerComponent],
  // providers: [CommonMapService],
})
export class CommonMapControlsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  componentInstanceId: number = parseInt(
    Math.random().toString(36).substring(7)
  );

  private _googleMap: GoogleMap | undefined;
  public get googleMap(): GoogleMap | undefined {
    return this._googleMap;
  }
  @Input() public set googleMap(v: GoogleMap | undefined) {
    if (v) {
      this._googleMap = v;
      setTimeout(() => {
        this.attachControlsToMap(v);
      });

      this.mapService.onMapLoaded(v);
    }
  }

  @Input() public options!: MapOptions;

  @Input() public showFullscreenControl: boolean = true;
  @Input() public showZoomControl: boolean = true;
  @Input() public showMapTypeControl: boolean = true;
  @Input() public showComplainsLayerOption: boolean = true;
  @Input() public showTiltControl: boolean = false;
  @Input() latLngBounds: google.maps.LatLngBounds | null = null;
  @Input() public showWindLayerOption: boolean = true;
  @Input() public devices: DeviceDetails[] = [];
  @Output() mapOptionsUpdated: EventEmitter<MapOptions> = new EventEmitter();
  @Output() layerToggled: EventEmitter<{
    state: boolean;
    name: string;
  }> = new EventEmitter();

  @ViewChild('fullscreenControl') fullscreenControl: any;
  @ViewChild('zoomControl') zoomControl: any;
  @ViewChild('mapTypesControl') mapTypesControl: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
      this.height = (event.target as Window).innerHeight;
    } catch (e) {}
  }

  public controlsAttachedToMap: boolean = false;

  private subscriptions: Subscription[] = [];
  public windLayerEnabled: boolean = false;
  windData: WindData[] | any = [];
  width!: number;
  height!: number;

  constructor(
    public mapService: CommonMapService,
    public windDataService: WindDataService
  ) {
    this.width = window.innerWidth;
    this.height = window.innerHeight
  }

  ngOnInit() {
    this.subscriptions.push(
      this.mapService.optionsUpdate$.subscribe((options: MapOptions) => {
        this.options = options;
        this.mapOptionsUpdated.emit(options);
      })
    );

    this.subscriptions.push(
      this.mapService.mapLayerToggled$.subscribe((layerUpdate) => {
        this.layerToggled.emit(layerUpdate);
        if(layerUpdate.name === 'wind'){
          this.windLayerEnabled = layerUpdate.state
          if(this.windLayerEnabled){
            const windData = this.windDataService.getWindDataForOverview();
            this.windData = windData?.filter((data) =>
              data.direction && data.magnitude && data.lat && data.lon ? true : false
            );
          }
          else{
            this.windData = []
          }
        }
      })
    );

    this.componentInstanceId = parseInt((this as any).__ngContext__);
    // const windData = this.windDataService.getWindDataForOverview();
    // this.windData = windData?.filter((data) =>
    //   data.direction && data.magnitude && data.lat && data.lon ? true : false
    // );
    // windData && windData.length > 0 ? 
    // this.googleMap?.googleMap?. 
  }

  ngAfterViewInit(): void {
    if (this.googleMap) {
      this.attachControlsToMap(this.googleMap);
    }
  }
  ngOnChanges(changes: any) {
    if (changes.latLngBounds){
      this.latLngBounds = changes.latLngBounds.currentValue;
      // if(this.latLngBounds && this.windData && this.windData.length > 0 && this.windLayerEnabled){
      if(this.latLngBounds && this.windData && this.windData.length > 0){
      }
      this.mapOptionsUpdated.emit(this.options);
      this.latLngBounds && this.googleMap?.googleMap?.fitBounds(this.latLngBounds);
      this.options.minZoom = this.googleMap?.googleMap?.getZoom();
      this.latLngBounds && this.googleMap?.googleMap?.fitBounds(this.latLngBounds);
    }
    
  }
  
  attachControlsToMap(googleMap: GoogleMap) {
    let zoomControl =
      // this.zoomControl?.nativeElement ||
      document.getElementById(`zoom-control-${this.componentInstanceId}`);
    let fullscreenControl =
      // this.fullscreenControl?.nativeElement ||
      document.getElementById(`fullscreen-control-${this.componentInstanceId}`);
    let mapTypesControl =
      // this.mapTypesControl?.nativeElement ||
      document.getElementById(`map-types-control-${this.componentInstanceId}`);

    let tiltControl = document.getElementById(
      `tilt-control-${this.componentInstanceId}`
    );

    if (zoomControl || fullscreenControl || mapTypesControl) {
      googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        zoomControl!
      );
      googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        tiltControl!
      );
      googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        fullscreenControl!
      );
      googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(
        mapTypesControl!
      );

      let tilesLoadedListner = googleMap.googleMap?.addListener(
        'tilesloaded',
        () => {
          if (!this.controlsAttachedToMap) {
            setTimeout(() => {
              this.controlsAttachedToMap = true;
            });
          }
          tilesLoadedListner?.remove();
        }
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });

    // this.mapService.destroy();
  }
}
