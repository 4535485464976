<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-2">
        <mat-label>Device</mat-label>
        <input
          type="text"
          [placeholder]="'Device'"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : headerForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <button mat-icon-button matSuffix tabindex="-1">
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="col-12 col-lg-2" appearance="outline">
        <mat-label>Select Date</mat-label>
        <input
          matInput
          [min]="minDate"
          [max]="maxDate"
          formControlName="startDate"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div class="col col-lg-auto apply">
        <button
          class="w-100 w-lg-auto"
          mat-raised-button
          color="primary"
          matTooltip="Apply"
          (click)="onApply()"
        >
          Apply
        </button>
      </div>

      <div class="col col-lg-auto apply">
        <button
          class="w-100 w-lg-auto"
          mat-raised-button
          color="primary"
          matTooltip="Download csv"
          (click)="exportTableDataToCSV()"
        >
          <mat-icon class="material-symbols-outlined m-0">download</mat-icon>
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
