<ng-container *ngIf="heatmapProjectId">
  <mat-sidenav-container
    class="h-100"
    autosize
    [ngClass]="{ 'd-none': loading !== 0 }"
    *ngIf="themeChange === 0"
  >
    <mat-sidenav
      [position]="'end'"
      [mode]="'side'"
      [opened]="chartLoading || deviceData?.length"
      style="max-width: 600px; width: 40%; min-width: 300px"
    >
      <div class="device">
        <div class="d-flex align-items-center p-3">
          <div class="col">Parameter Information</div>
          <div class="col-auto">
            <mat-icon
              class="material-symbols-outlined cursor-pointer"
              (click)="closeChartsView()"
            >
              close
            </mat-icon>
          </div>
        </div>
        <div
          class="p-3"
          style="background-color: var(--layout-background-color)"
          *ngIf="chartLoading === 0 && deviceData?.length"
        >
          <ng-container *ngFor="let param of parameters; let i = index">
            <div class="card-container">
              <nb-card
                id="1001"
                style="max-height: 100%; height: 350px; min-height: 250px"
              >
                <nb-card-header>
                  <span class="title" [innerHTML]="param?.flabel ?? ''"></span>
                  <span
                    class="ps-1 small fw-normal"
                    [innerHTML]="param?.label ?? ''"
                  ></span>
                </nb-card-header>
                <nb-card-body>
                  <app-heatmap-chart-widget
                    [selectedField]="param"
                    [deviceInfo]="device"
                    [fields]="fields"
                    [limits]="limits"
                    [chartType]="'line'"
                    [aqiIndexColor]="aqiIndexColor"
                    [show]="true"
                    [deviceData]="deviceData"
                    [selectedTimestamp]="heatmapData?.[currentHeatmapDataIndex]?.payload?.d?.t ?? undefined"
                  >
                  </app-heatmap-chart-widget>
                </nb-card-body>
              </nb-card>
            </div>
          </ng-container>
        </div>

        <div class="py-5" *ngIf="chartLoading !== 0">
          <mat-spinner
            class="mx-auto"
            [diameter]="50"
            color="primary"
          ></mat-spinner>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="d-flex flex-column h-100">
        <div class="p-2 m-1">
          <div class="d-flex align-items-center">
            <button
              mat-raised-button
              [matMenuTriggerFor]="parameterMenu"
              class="d-flex flex-row-reverse align-items-center justify-content-around p-2"
              matTooltip="Select Parameter"
              [color]="'primary'"
              [disabled]="!selectedParameter"
            >
              <div class="text-center">
                {{ selectedParameter?.flabel ?? "" }}
              </div>
              <mat-icon class="m-0">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #parameterMenu="matMenu" style="padding: 0" class="">
              <button
                mat-menu-item
                *ngFor="let parameter of parameters; let i = index"
                (click)="selectParameter(parameter)"
                [value]="parameter"
              >
                {{ parameter.flabel }}
              </button>
            </mat-menu>
            <div
              class="mx-4 flex-grow-1 d-flex align-items-center"
              (mouseenter)="showSelectedTime()"
              (mouseleave)="hideSelectedTime()"
            >
              <button
                mat-icon-button
                [disabled]="!selectedParameter"
                (click)="playPauseHeatMap()"
                class="mx-2"
              >
                <mat-icon [color]="'primary'">
                  {{ isPlaying ? "pause" : "play_arrow" }}
                </mat-icon>
              </button>
              <div class="flex-grow-1 position-relative">
                <mat-slider
                  class="w-100 mx-0"
                  [disabled]="sliderConfig.disabled"
                  [max]="sliderConfig.max"
                  [min]="sliderConfig.min"
                  [step]="1"
                  [discrete]="false"
                  [showTickMarks]="true"
                >
                  <input
                    matSliderThumb
                    [(ngModel)]="currentHeatmapDataIndex"
                    (input)="changeMapOverlay()"
                  />
                </mat-slider>
                <span
                  class="selected-time-container position-absolute text-center p-2 mt-3 rounded-2 border border-1"
                  [ngClass]="{
                    'd-none': (!selectedTime.length || !shouldShowSelectedTime) && removeSelectedTime,
                    show: !(!selectedTime.length || !shouldShowSelectedTime),
                    hide: !selectedTime.length || !shouldShowSelectedTime,
                  }"
                  [ngStyle]="selectedTimeStyles"
                  [innerHTML]="selectedTime"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 flex-grow-1">
          <ng-container *ngIf="googleMapsService.isApiLoaded | async">
            <google-map
              class="col-12 px-0"
              width="100%"
              height="100%"
              [options]="options"
            >
            </google-map>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-container *ngIf="loading === 0">
    <div id="fullscreen-control" (click)="switchToFullScreen()">
      <mat-icon class="material-symbols-outlined">{{
        fullScreenMode
      }}</mat-icon>
    </div>

    <div id="zoom-control">
      <div class="d-flex flex-column">
        <mat-icon
          class="material-symbols-outlined text-basic-color"
          (click)="zoomIn()"
          >zoom_in</mat-icon
        >
        <hr class="control-divider" />
        <mat-icon
          class="material-symbols-outlined text-basic-color"
          (click)="zoomOut()"
          >zoom_out</mat-icon
        >
      </div>
    </div>

    <div id="map-types-control">
      <div class="map-types">
        <div class="map-type" (click)="changeMapType()">
          <img
            *ngIf="currentMapType === 'roadmap'"
            src="./../../../../../../../assets/images/map/default-map-type.png"
          />
          <img
            *ngIf="currentMapType === 'hybrid'"
            src="./../../../../../../../assets/images/map/satellite-map-type.png"
          />
        </div>
        <div class="text-center">
          {{ currentMapType === "roadmap" ? "Default" : "Satellite" }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="py-5" *ngIf="loading !== 0">
    <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!heatmapProjectId">
  <app-content-unavailable
    [majorText]="noData.majorText"
    [svgImage]="noData.svgImage"
    [minorText]="noData.minorText"
  ></app-content-unavailable>
</ng-container>
