<app-form-container
  [tableTitle]="formTitle"
  (cancelled)="previous()"
  [submitBtn]="submitBtn"
  [isValid]="isNextStep"
  (close)="closeForm()"
  [cancelBtn]="cancelButtonTitle"
  (submit)="submitAndNext()"
>
  <mat-stepper
    [linear]="true"
    #stepper
    class="h-100 custom-stepper"
    (selectionChange)="onStepChange($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step
      [stepControl]="typeForm"
      [label]="'Select Analytics Type'"
      [editable]="!isEditMode"
    >
      <div class="w-100">
        <app-selection-tile
          [tileDetails]="cardDetails"
          (onSelection)="onAnalyticSelection($event)"
        >
        </app-selection-tile>
      </div>
      <!-- </di v> -->
    </mat-step>
    <mat-step [label]="'Configure Analytics'">
      <ng-container *ngIf="this.selectedAnalyticType !== 0">
        <ng-container *ngIf="this.selectedAnalyticType !== 101 && isSelected">
          <div class="row py-2 gy-4" [formGroup]="form">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Analytics label</mat-label>
                <input
                  type="text"
                  [placeholder]="'Analytics label'"
                  matInput
                  formControlName="analyticName"
                />
              </mat-form-field>
            </div>

            <!--inter device comparison-->
            <ng-container *ngIf="selectedAnalyticType === 5">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Device</mat-label>
                  <mat-select formControlName="deviceId" multiple>
                    <mat-optgroup
                      *ngFor="let deviceType of deviceTypes"
                      [label]="deviceType.label"
                    >
                      <mat-option
                        *ngFor="
                          let device of devices | deviceType : deviceType.key
                        "
                        [value]="device.deviceId"
                      >
                        {{ device.label }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Parameter Type</mat-label>
                  <mat-select
                    multiple
                    formControlName="parameter"
                    [multiple]="isMultipleParameter"
                  >
                    <ng-container *ngIf="this.realTimeDeviceData.length > 0">
                      <mat-option
                        *ngFor="
                          let commonKey of commonKeys
                            | gasLevel
                              : fieldsOfCurrentDeviceType
                              : limitsOfFields
                        "
                        [value]="commonKey?.name"
                      >
                        <span>
                          {{ commonKey?.label }}
                        </span>
                        <span
                          *ngIf="commonKey?.unit"
                          style="font-size: 12px; padding-left: 2px"
                        >
                          ({{ commonKey.unit }})
                        </span>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <!--device comparison-->
            <ng-container
              *ngIf="
                selectedAnalyticType === 2 ||
                selectedAnalyticType === 102 ||
                selectedAnalyticType === 103
              "
            >
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Device Type</mat-label>
                  <mat-select formControlName="deviceType">
                    <mat-option
                      *ngFor="let deviceType of deviceTypes"
                      [value]="deviceType.key"
                    >
                      {{ deviceType.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field class="w-100" *ngIf="selectedAnalyticType !== 102 && selectedAnalyticType !== 103">
                  <mat-label>
                    Select Device
                  </mat-label>
                  <mat-select multiple formControlName="deviceId">
                    <mat-option
                      *ngFor="
                        let device of devices | deviceType : currentDeviceType
                      "
                      [value]="device.deviceId"
                    >
                      {{ device.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100" *ngIf="selectedAnalyticType === 102 || selectedAnalyticType === 103">
                  <mat-label>
                    Select Device
                  </mat-label>
                  <!-- <mat-label>
                    Select Device ({{ selectedDevices?.length }} / {{ maxDeviceCount }})
                  </mat-label> -->
                  <mat-select multiple formControlName="deviceId" [maxlength]="this.selectedAnalyticType === 102 || this.selectedAnalyticType === 103 ? maxDeviceCount : devices.length">
                    <mat-option
                      *ngFor="
                        let device of devices | deviceType : currentDeviceType
                      "
                      [disabled]="isDeviceOptionDisabled(device)"
                      [value]="device.deviceId"
                    >
                      {{ device.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('deviceId')?.hasError('maxlength')">
                    {{ this.selectedAnalyticType === 102 || this.selectedAnalyticType === 103 ? 'Maximum' + maxDeviceCount  + 'devices can be selected' : '' }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Parameter</mat-label>
                  <mat-select
                    [multiple]="isMultipleParameter"
                    formControlName="parameter"
                  >
                    <ng-container *ngIf="this.realTimeDeviceData.length > 0">
                      <mat-option
                        *ngFor="
                          let commonKey of commonKeys
                            | gasLevel
                              : fieldsOfCurrentDeviceType
                              : limitsOfFields
                        "
                        [value]="commonKey?.name"
                      >
                        <span>
                          {{ commonKey?.label }}
                        </span>
                        <span
                          *ngIf="commonKey?.unit"
                          style="font-size: 12px; padding-left: 2px"
                        >
                          ({{ commonKey.unit }})
                        </span>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container
              *ngIf="selectedAnalyticType === 1 || selectedAnalyticType === 4"
            >
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Device</mat-label>
                  <mat-select
                    formControlName="deviceId"
                    [multiple]="isMultipleDevice"
                  >
                    <mat-optgroup
                      *ngFor="let deviceType of deviceTypes"
                      [label]="deviceType.label"
                    >
                      <mat-option
                        *ngFor="
                          let device of devices | deviceType : deviceType.key
                        "
                        [value]="device.deviceId"
                      >
                        {{ device.label }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Parameter Type</mat-label>
                  <mat-select
                    multiple
                    formControlName="parameter"
                    [multiple]="isMultipleParameter"
                  >
                    <ng-container *ngIf="this.realTimeDeviceData.length > 0">
                      <mat-option
                        *ngFor="
                          let field of realTimeDeviceData[0]?.payload?.d
                            | gasLevel
                              : fieldsOfCurrentDeviceType
                              : limitsOfFields
                        "
                        [value]="field?.name"
                      >
                        <span>
                          {{ field?.label }}
                        </span>
                        <span
                          *ngIf="field?.unit"
                          style="font-size: 12px; padding-left: 2px"
                        >
                          ({{ field.unit }})
                        </span>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <!-- <ng-container *ngIf="!isEditMode && (selectedAnalyticType !== 102 && selectedAnalyticType !== 103)"> -->
            <ng-container *ngIf="(!isEditMode && (selectedAnalyticType !== 102)) || selectedAnalyticType === 103">
            <!-- <ng-container *ngIf="!isEditMode"> -->
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Average</mat-label>
                  <mat-select formControlName="average">
                    <mat-option
                      *ngFor="let reqAvg of requiredAverages"
                      [value]="reqAvg"
                      >{{ reqAvg.label }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
            <!-- <ng-container *ngIf="(selectedAnalyticType === 102 || selectedAnalyticType === 103)"> -->
            <ng-container *ngIf="selectedAnalyticType === 102">
            <!-- <ng-container *ngIf="!isEditMode"> -->
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Distribution</mat-label>
                  <mat-select formControlName="dataDistributionType">
                    <mat-option
                      *ngFor="let distributionType of dataDistributionTypes"
                      [value]="distributionType.avgValue"
                      >{{ distributionType.label }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>



            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input
                  [rangePicker]="picker"
                  [max]="maxDate"
                  [min]="minDate"
                >
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="startDate"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="endDate"
                  />
                </mat-date-range-input>
                <mat-hint>DD/MM/YY – DD/MM/YY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <!-- 
            <ng-container *ngIf="this.realTimeDeviceData.length > 0">
              <mat-option
                *ngFor="
                  let commonKey of commonKeys
                    | gasLevel : fieldsOfCurrentDeviceType : limitsOfFields
                "
                [value]="commonKey?.name"
              >
                <span>
                  {{ commonKey?.label }}
                </span>
                <span
                  *ngIf="commonKey.unit"
                  style="font-size: 12px; padding-left: 2px"
                >
                  ({{ commonKey.unit }})
                </span>
              </mat-option>
            </ng-container> -->

            <ng-container
              *ngIf="
                (selectedAnalyticType === 1 ||
                  selectedAnalyticType === 2 ||
                  selectedAnalyticType === 5) &&
                !isEditMode
              "
            >
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Select Chart Type</mat-label>
                  <mat-select formControlName="chartType">
                    <mat-option
                      *ngFor="let chartType of chartTypes"
                      [value]="chartType"
                    >
                      <img class="card-image" [src]="chartType.url" />
                      {{ chartType.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </mat-step>
  </mat-stepper>
</app-form-container>
