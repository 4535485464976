import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { COMPLAIN_MENU } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ComplainFormComponent } from './components/complain-form/complain-form.component';

@Component({
  selector: 'app-complain',
  templateUrl: './complain.component.html',
  styleUrls: ['./complain.component.scss'],
})
export class ComplainComponent {
  menu: SubMenu[] = COMPLAIN_MENU;
  categories?: any;
  selectedOrg?: any[];
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  orgId = this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
    .org;
  headerForm!: FormGroup;
  maxDate = this.customMomentService.moment();
  defaultStartDate!: moment.Moment;
  defaultEndDate!: moment.Moment;

  constructor(
    private formsService: FormsService,
    private complainsService: ComplainsService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    public router: Router,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.maxDate = this.customMomentService.moment();
    this.defaultEndDate = this.customMomentService.moment().endOf('day');
    this.defaultStartDate = this.customMomentService
      .moment()
      .subtract(1, 'years')
      .startOf('day');

    this.complainsService.setGte(this.defaultStartDate.unix());
    this.complainsService.setLte(this.defaultEndDate.unix());

    this.headerForm = this.fb.group({
      startDate: this.defaultStartDate,
      endDate: this.defaultEndDate,
    });

    (this.headerForm.get('endDate') as FormControl).valueChanges.subscribe(
      (res) => {
        res = { ...this.headerForm.value, endDate: res };
        if (res.endDate && res.startDate) {
          const sDate = res.startDate;
          const eDate = res.endDate.endOf('day');
          this.complainsService.setGte(sDate.unix());
          this.complainsService.setLte(eDate.unix());
          this.tableDataUpdate(sDate, eDate);
        }
      }
    );
  }

  openComplainForm() {
    this.formsService
      .openForm(
        ComplainFormComponent,
        {
          data: undefined,
          selectedOrg: this.selectedOrg,
          categories: this.categories,
        },
        'lg'
      )
      .subscribe((result) => {});
  }

  addComplain(): void {
    if (
      this.complainsService.selectedOrg.length &&
      this.complainsService.categories.length
    ) {
      this.selectedOrg = this.complainsService.selectedOrg;
      this.categories = this.complainsService.categories;
      this.openComplainForm();
    } else if (
      !this.complainsService.selectedOrg.length &&
      this.complainsService.categories.length
    ) {
      this.categories = this.complainsService.categories;
      this.complainsService
        .getOrgById(
          this.orgId,
          this.defaultStartDate.unix(),
          this.defaultEndDate.unix()
        )
        .subscribe({
          next: (res) => {
            this.complainsService.selectedOrg = res;
            this.selectedOrg = this.complainsService.selectedOrg;
            this.openComplainForm();
          },
        });
      return;
    } else if (
      this.complainsService.selectedOrg.length &&
      !this.complainsService.categories.length
    ) {
      this.selectedOrg = this.complainsService.selectedOrg;
      this.complainsService.getCategories().subscribe({
        next: (res) => {
          this.complainsService.categories = res;
          this.categories = this.complainsService.categories;
          this.openComplainForm();
        },
      });
      return;
    } else {
      this.complainsService.getCategories().subscribe({
        next: (res) => {
          this.complainsService.categories = res;
          this.categories = this.complainsService.categories;
          this.complainsService
            .getOrgById(
              this.orgId,
              this.defaultStartDate.unix(),
              this.defaultEndDate.unix()
            )
            .subscribe({
              next: (res) => {
                this.complainsService.selectedOrg = res;
                this.selectedOrg = this.complainsService.selectedOrg;
                this.openComplainForm();
              },
            });
          return;
        },
      });
      return;
    }
  }

  tableDataUpdate(sDate: Moment, eDate: Moment) {
    const gte = sDate.unix();
    const lte = eDate.unix();
    if (sDate && eDate !== undefined) {
      this.loadTable.next(false);
      this.complainsService
        .getComplainsByOrganization(
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            .org,
          gte,
          lte
        )
        .subscribe((data) => {
          this.loadTable.next(true);
          this.complainsService.emitData();
        });
    }
  }
  isListView(): boolean {
    return this.router.url.includes('list');
  }
}
