export class WindMapType implements google.maps.MapType {
    tileSize: google.maps.Size;
    alt: string | null = null;
    maxZoom: number = 18;
    minZoom: number = 2.5;
    name: string | null = null;
    projection: google.maps.Projection | null = null;
    radius: number = 6378137;
  
    constructor(
      tileSize: google.maps.Size,
      private weatherMapProvider: string,
      private weatherApiKey: string
    ) {
      this.tileSize = tileSize;
    }
  
    getTile(
      coord: google.maps.Point,
      zoom: number,
      ownerDocument: Document
    ): HTMLElement {
      const div = ownerDocument.createElement('div');
      div.style.width = this.tileSize.width + 'px';
      div.style.height = this.tileSize.height + 'px';
      div.style.fontSize = '10';
      div.style.background = `url(${this.weatherMapProvider}${zoom}/${coord.x}/${coord.y}.png?appid=${this.weatherApiKey})`;
      return div;
    }
  
    releaseTile(tile: Element): void {}
  }