import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HEATMAP_MENU } from 'src/app/shared/constants/app-menu';
import { Heatmap } from 'src/app/shared/models/heatmap/heatmap';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { HeatmapService } from 'src/app/shared/services/heatmap.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { HeatmapFormComponent } from './components/heatmap-form/heatmap-form.component';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnInit {
  menu: SubMenu[] = HEATMAP_MENU;
  loaderStates!: boolean;
  public selectedMenuTab: SubMenu | undefined;
  heatmaps: Heatmap[] = [];
  headerForm!: FormGroup;
  maxDate = this.customMomentService.moment();
  minDate = this.customMomentService.moment();

  fields: DeviceField[] = [];
  fileError: string | null = null;
  selectedFileName: string | null = null;
  moduleAccess: any;
  get fieldsObj() {
    return Object.fromEntries(this.fields.map((field) => [field.fkey, field]));
  }

  limits: FieldLimit[] = [];
  get limitsObj() {
    let heatmapId = this.heatmaps[0].heatmapId;
    let units =
      this.commonService.getAllUnits()[
        this.heatmaps.find((h) => h.heatmapId === heatmapId)!.deviceTypeId
      ];
    return Object.fromEntries(
      this.deviceService
        .fetchLimits(units, false)
        .map((field) => [field.fkey, field])
    );
  }
  devices?: DeviceDetails[];
  subscriptions: Subscription[] = [];
  fetchedHeatmapId: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private heatmapService: HeatmapService,
    private customMomentService: CustomMomentService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {
    const fallbackDate = this.customMomentService.moment('2015-01-01');
    this.minDate = this.customMomentService.moment.unix(fallbackDate.unix());
    this.maxDate = this.customMomentService.moment().startOf('day');
  }

  ngOnInit(): void {
    this.heatmapService.showLoader(true);
    this.heatmapService.getUserHeatmaps().subscribe((heatmaps) => {
      this.heatmapService.showLoader(false);
      this.heatmaps = heatmaps;
      if (this.heatmaps?.length) {
        this.route.firstChild?.params.subscribe((params) => {
          if (params['heatmapId']) {
            this.fetchedHeatmapId = params['heatmapId'];
          } else {
            this.fetchedHeatmapId = heatmaps.length
              ? heatmaps[0]?.heatmapId
              : '';
          }
          const param = `/heatmap/map/${this.fetchedHeatmapId}`;
          this.router.navigateByUrl(param);
          if (this.fetchedHeatmapId) {
            this.headerForm.patchValue({ heatmap: this.fetchedHeatmapId });
          }
        });
      }
      setTimeout(() => {
        this.onApply();
      });
    });
    this.subscriptions.push(
      this.heatmapService.getLoaderState().subscribe((state) => {
        this.loaderStates = state;
      })
    );
    this.headerForm = this.buildHeaderForm();
    this.devices = this.deviceService.registeredDevices
      ? [...this.deviceService.registeredDevices!]
      : [];
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;

    this.subscriptions.push(
      this.heatmapService.heatmaps$.subscribe((heatmaps) => {
        this.heatmaps = heatmaps;
      })
    );

    this.subscriptions.push(
      this.heatmapService.heatmapFilters$.subscribe({
        next: ({ heatmap, startDate, endDate }) => {
          let patchValue: any = {};
          if (heatmap?.heatmapId) {
            patchValue.heatmap = heatmap?.heatmapId;
          }
          if (startDate) {
            patchValue.startDate = startDate;
          }
          if (endDate) {
            patchValue.endDate = endDate;
          }

          this.headerForm.patchValue(patchValue);
        },
      })
    );
    this.moduleAccess = this.commonService.moduleAccessibility(1010);
    this.headerForm.get('startDate')?.valueChanges.subscribe((res) => {
      if (res) {
        // const a = res.clone();
        this.maxDate = this.customMomentService.moment.min(
          this.customMomentService.moment(res).add(6, 'days'),
          this.customMomentService.moment()
        );
      }
    });
  }
  public onTabChange(selectedMenuTab: SubMenu | undefined) {
    if (!selectedMenuTab) {
      return;
    }
    this.selectedMenuTab = selectedMenuTab;
    if (this.selectedMenuTab?.url === 'map') {
      this.onApply();
    }
  }
  private buildHeaderForm(): FormGroup {
    let defaultFilters =
      this.heatmapService.getDefaultValuesForHeatmapFilters();
    return this.formBuilder.group({
      heatmap: [
        this.fetchedHeatmapId
          ? this.fetchedHeatmapId
          : this.heatmaps
          ? this.heatmaps[0]?.heatmapId
          : '',
        ,
      ],
      startDate: [defaultFilters.startDate],
      endDate: [defaultFilters.endDate],
      average: [defaultFilters.average],
    });
  }

  addHeatmap() {
    this.formsService
      .openForm(HeatmapFormComponent, '', 'lg')
      .subscribe((result) => {
        this.heatmapService.getUserHeatmaps().subscribe({
          next: (res) => {
            if(res?.length > 1){
              let newlyAddedHeatmap = res[0];
              let latestCreatedOn = this.customMomentService
                .moment(newlyAddedHeatmap?.created_on)
                .valueOf();
              // Iterate through the heatmaps to find the one with the most recent createdOn timestamp
              for (let i = 1; i < res.length; i++) {
                const heatmapCreatedOn = this.customMomentService
                  .moment(res[i]?.created_on)
                  .valueOf();
                if (heatmapCreatedOn > latestCreatedOn) {
                  newlyAddedHeatmap = res[i];
                  latestCreatedOn = heatmapCreatedOn;
                }
              }
              // Set the newly added heatmap as the selected heatmap
              if (newlyAddedHeatmap.heatmapId) {
                this.headerForm.patchValue({
                  heatmap: newlyAddedHeatmap.heatmapId,
                });
                this.onApply();
              }
            }
            else{
              this.headerForm.patchValue({
                heatmap: res[0].heatmapId,
              });
              this.onApply();
            }
          },
        });
      });
  }

  getHeatmapModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  onApply() {
    if (this.headerForm.valid) {
      let values = this.headerForm.value;
      let selectedHeatmap = this.heatmaps.find(
        (heatmap) => heatmap.heatmapId === values.heatmap
      );

      if (!selectedHeatmap) {
        return;
      }
      let heatmapId = selectedHeatmap.heatmapId;
      let units =
        this.commonService.getAllUnits()[
          this.heatmaps.find((h) => h.heatmapId === heatmapId)!.deviceTypeId
        ];
      let limitsObj = Object.fromEntries(
        this.deviceService
          .fetchLimits(units, false)
          .map((field) => [field.fkey, field])
      );
      let parameters = CommonUtil.getCommonDeviceParametersKeysForHeatmap(
        this.devices!.filter((d) =>
          selectedHeatmap?.devices.includes(d.deviceId)
        )
      );
      let keys = parameters
        .filter(
          (param) =>
            !['t', 'temp', 'ws', 'wd', 'bs', 'hum'].includes(param) &&
            !param.endsWith('aqi') &&
            limitsObj[param]?.range?.length > 0
        )
        .join(',');
      this.heatmapService.updateHeatmapFilters({
        ...values,
        heatmap: selectedHeatmap,
        keys,
      });
    }
  }

  isMapView() {
    return this.route.snapshot.firstChild?.routeConfig?.path?.startsWith('map');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      (subscription) =>
        !subscription || subscription.closed || subscription.unsubscribe()
    );
    this.heatmapService.heatmaps = [];
    this.heatmapService.clearHeatmapFilters(
      this.heatmapService.getDefaultValuesForHeatmapFilters()
    );
  }
}
