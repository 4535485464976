<div class="h-100 d-flex flex-column" *ngIf="!noLiveData; else noDataTemplate">
  <div class="widget-fields-chip-wrapper d-flex flex-row">
    <div
      class="arrow-left-wrapper d-flex h-100 align-self-center cursor-pointer"
      (click)="scrollLeft()"
    >
      <mat-icon class="material-symbols-outlined align-self-center arrow-icon">
        arrow_back_ios_new
      </mat-icon>
    </div>
    <div
      id="widget-fields-chip-container-live-data"
      class="widget-fields-chip-container w-100 px-2 py-2"
    >
      <ng-container *ngFor="let field of deviceFields">
        <div
          class="widget-field-chip"
          (click)="changeField(field)"
          [ngClass]="{ active: selectedField?.fkey === field.fkey }"
        >
          {{ field.label }}
          <ng-container *ngIf="field.unit"> ({{ field.unit }}) </ng-container>
        </div>
      </ng-container>
    </div>
    <div
      class="arrow-right-wrapper d-flex h-100 align-self-center cursor-pointer"
      (click)="scrollRight()"
    >
      <mat-icon class="material-symbols-outlined align-self-center arrow-icon">
        arrow_forward_ios
      </mat-icon>
    </div>
  </div>
  <div class="highcharts-dashboards-dark" style="flex: 1">
    <highcharts-chart
      *ngIf="show"
      [Highcharts]="highcharts"
      [options]="chartOptions"
      style="width: 100%; height: 100%; display: block"
    >
    </highcharts-chart>
  </div>
  <div class="px-2 pb-1">
    <app-aqi-color-legend
      *ngIf="limitsObj[selectedField?.fkey ?? '']?.range?.length"
      [aqiIndexColor]="{
        labels: aqiIndexColor.labels,
        color: aqiIndexColor.color,
        range: limitsObj[selectedField?.fkey ?? ''].range
      }"
    ></app-aqi-color-legend>
  </div>
</div>

<ng-template #noDataTemplate>
  <div
    class="position-relative d-flex align-items-center justify-content-center m-0 w-100 h-100"
  >
    <div>
      <app-content-unavailable
        [majorText]="noData.majorText"
        [svgImage]="noData.svgImage"
        [minorText]="noData.minorText"
      ></app-content-unavailable>
    </div>
  </div>
</ng-template>
