import { Component } from '@angular/core';
import { FormsService } from 'src/app/shared/services/forms.service';
import { DisplayFormComponent } from './components/display-form/display-form.component';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent {
  moduleAccess: any;
  constructor(
    private formsService: FormsService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.moduleAccess = this.commonService.moduleAccessibility(1006);
  }

  addDisplay() {
    this.formsService
      .openForm(DisplayFormComponent, undefined, 'xl')
      .subscribe({
        next: (res) => {
          // console.log(res);
        },
        error: (err: any) => {
          console.info('Error: ', err);
        },
      });
  }

  getDisplayModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }
}
