import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AutomationData } from '../models/automation/automation-data';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AutomationService extends BaseAPIService<AutomationData.Get> {
  tempSubscription: Subscription | undefined;
  public devices: DeviceDetails[] = this.deviceService.registeredDevices!;
  public automationData!: AutomationData.Get;
  public isAutomationData = new BehaviorSubject<boolean>(false);
  isAutomationData$ = this.isAutomationData.asObservable();

  public currentDeviceType = new BehaviorSubject<number>(0);
  currentDeviceType$ = this.currentDeviceType.asObservable();

  public currentDevice = new BehaviorSubject<string>('');
  currentDevice$ = this.currentDevice.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService
  ) {
    super(http, cookieService);
  }

  getAutomationData(deviceId: string) {
    this.isAutomationData.next(false);
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    if (this.tempSubscription && !this.tempSubscription.closed) {
      this.tempSubscription.unsubscribe();
    }
    this.tempSubscription = this.get<AutomationData.Get>(
      APIConstants.GET_AUTOMATION_DATA(deviceId),
      headers
    ).subscribe((res: AutomationData.Get) => {
      if (res) {
        this.automationData = res;
        this.currentDevice.next(res.deviceId);
        this.currentDeviceType.next(
          DeviceUtil.getDeviceTypeId(
            res.deviceType,
            this.commonService.getUserDeviceTypes()
          )!
        );
        this.isAutomationData.next(true);
      }
    });
  }

  addAutomationData(payload: AutomationData.Put, deviceId: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.put<AutomationData.Put>(
      APIConstants.ADD_NEW_CONFIG(deviceId),
      payload,
      headers
    );
  }

  getKeyFromField(param: string): string {
    if (typeof param === 'string') {
      const foundValue = this.localStorageService
        .getParsedValue(LocalStorageConstants.OZ_FIELDS)
        .find(
          (field: any) => param.toLowerCase() === field.label.toLowerCase()
        );
      if (foundValue) {
        return foundValue.fkey;
      }
    }
    return '';
  }

  generateFinalPayload() {
    let finalPayload: AutomationData.Put = {
      userId: this.localStorageService.getValue(LocalStorageConstants.USER_ID),
      update_note: undefined,
      data: {
        config: this.automationData.config,
        deviceType: this.automationData.deviceType,
        configId: this.automationData.configId,
        init: this.automationData.init,
        macId: this.automationData.macId,
        serial: this.automationData.serial,
        soft: this.automationData.soft,
        type: this.automationData.type,
      },
    };
    return finalPayload;
  }
}
