<h1 mat-dialog-title class="confirmation-popup-header icon-material">
  <mat-icon class="material-symbols-outlined">globe_asia</mat-icon>Select
  Timezone
</h1>

<div mat-dialog-content>
  <div
    class="img-dialog-content d-flex justify-content-center align-items-center w-100 h-100"
    *ngIf="data?.image || data?.message"
  >
    <img *ngIf="data?.image" [src]="data.image" class="confirmation-img" />
    <span class="message-text-color" [innerHTML]="data.message"></span>
  </div>
  <div
    *ngIf="!data?.image"
    [formGroup]="form"
    class="d-flex justify-content-center align-items-center w-100 h-100"
  >
    <mat-form-field class="col-12 col-lg-6" appearance="outline">
      <mat-label>Time Zone</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        matInput
        formControlName="timeZone"
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let time of filteredOptions | async"
          [value]="time.nameOffSet"
        >
          {{ time.nameOffSet }}
        </mat-option>
      </mat-autocomplete>
      <button
        (click)="selectionMade($event, trigger)"
        mat-icon-button
        matSuffix
        tabindex="-1"
      >
        <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
<mat-dialog-actions align="end" class="me-2">
  <button
    class="me-3"
    color="primary"
    mat-raised-button
    [disabled]="!form.valid || data.disableUpdateButton"
    (click)="updateUserSettings()"
  >
    Update
  </button>
</mat-dialog-actions>
