<ngx-gauge
  [style.color]="color"
  [min]="range.min"
  [max]="range.max"
  [type]="'arch'"
  [value]="aqi.aqi"
  [thick]="12"
  [size]="120"
  [foregroundColor]="color"
  class="align-items-center"
></ngx-gauge>
<div class="msg" [style.color]="color">
  <strong>{{ aqi.aqiMessage }}</strong>
</div>
