<div
  *ngIf="(loadTable | async) && isRelayOutputAvailable"
  class="d-flex flex-column h-100"
>
  <app-data-table
    tableTitle="Configuration List"
    [data]="updatedConfigData"
    [columns]="defaultColumns"
    [displayedColumns]="displayedColumns"
    [noDataViewConfig]="noData"
    [showFilterInHeader]="false"
    [isChartVisible]="false"
    [showDownloadInHeader]="getAutomationModuleOptions('download')"
  >
  </app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>

<div
  class="h-100 w-100 overflow-hidden"
  *ngIf="(loadTable | async) == true && !isRelayOutputAvailable"
>
  <div
    class="d-flex flex-column align-items-center justify-content-center w-100 h-100"
  >
    <img src="{{ relayUnavailableImage }}" />
    <h5 class="text-center m-0 mt-3">Relay Output Not Available For Device</h5>
  </div>
</div>
