import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { AnalyticsInternal } from 'src/app/shared/models/internal-use-front-end/analytics-data';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AnalyticFormComponent } from '../../components/analytic-form/analytic-form.component';
@Component({
  selector: 'app-analytics-list',
  templateUrl: './analytics-list.component.html',
  styleUrls: ['./analytics-list.component.scss'],
})
export class AnalyticsListComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private formsService: FormsService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    private router: Router,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private customMomentService: CustomMomentService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog
  ) {}

  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public analyticsList!: AnalyticsInternal[];
  public displayedColumns: string[] = [];
  public currentDeviceType!: number;
  public headerForm!: FormGroup;
  public deviceTypes!: DeviceType[];
  public dialogCompInstance!: ConfirmationPopupComponent;

  public noData: ContentUnavailable = {
    isConfiguration: true,
    majorText: 'Start with your first analytics report.',
    linkText: 'Configure Analytics',
    minorText: 'and Proceed',
    createFunc: () => this.addAnalytic(),
    svgImage: AppConstants.EMPTY_ANALYTICS_LIST,
  };

  defaultColumns: any[] = [
    {
      columnDef: 'actions',
      header: '',
      cell: (element: AnalyticsInternal) => `${element.analyticsId}`,
      parameter: false,
      selected: false,
      icon: 'more_vert',
      options: [
        {
          label: `<span class="material-symbols-outlined">visibility</span>&nbsp View`,
          action: (analytic: AnalyticsInternal) =>
            this.viewAnalyticsDirectly(analytic),
        },
        {
          label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
          action: (analytic: AnalyticsInternal) =>
            this.openDialog(analytic.analyticsId!),
        },
      ],
    },
    {
      columnDef: 'analyticsName',
      header: 'Analytics Name',
      cell: (element: AnalyticsInternal) => `${element.analyticName}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'analyticsType',
      header: 'Analytics Type',
      cell: (element: AnalyticsInternal) =>
        `${this.analyticsService.getAnalyticsType(element.analyticType)}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'createdOn',
      header: 'Updated On',
      cell: (element: AnalyticsInternal) =>
        `${this.customMomentService.formatDatetime({
          epoch: element?.updatedOn,
          format: this.commonService.getDateTimeFormat(),
        })}`,
      parameter: false,
      selected: false,
    },
  ];

  ngOnInit() {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.getAnalyticsList();
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    const devices: DeviceDetails[] = this.deviceService.registeredDevices!;

    const deviceType: DeviceType | undefined =
      this.deviceService.setUpDeviceTypeForModule(this.deviceTypes, devices);
    this.currentDeviceType = deviceType!.deviceTypeId;

    const dType = this.deviceTypes.find(
      (dt) => dt.deviceTypeId === this.currentDeviceType
    );

    this.headerForm = this.formBuilder.group({
      deviceType: dType,
    });
    this.onChangeDeviceType();
  }

  loadData(): void {
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  //executes when user click on eye icon of any anaytic
  viewAnalyticsDirectly(analytic: AnalyticsInternal) {
    const data = JSON.parse(JSON.stringify(analytic));
    delete data.cellValues;
    this.router.navigateByUrl(
      'analytics/' + analytic?.analyticsId?.toString(),
      { state: data }
    );
  }

  //use to fetch list of the analytic list of the user(api call is being done in this function)
  getAnalyticsList() {
    this.analyticsService
      .getAnalyticList(
        this.localStorageService.getValue(LocalStorageConstants.USER_ID)
      )
      .subscribe((res: AnalyticsInternal[]) => {
        this.analyticsList = res;
        this.loadData();
      });
  }

  //executes when user click on delete icon of any analytic
  deleteAnalytic(analyticId: number) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.analyticsService.deleteAnalyticsData(analyticId).subscribe({
      next: (res: any) => {
        if (res) {
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Analytics Deleted Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.getAnalyticsList();
            this.loadTable.next(false);
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Deleting Analytic. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  getDeviceType(deviceType: DeviceType): string {
    return deviceType ? deviceType.label.trim() : '';
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  onChangeDeviceType() {
    this.headerForm.get('deviceType')?.valueChanges.subscribe((res) => {
      if (res) {
        const deviceType = this.deviceTypes.find(
          (dt) => dt.deviceTypeId === res.deviceTypeId
        );

        const devices = this.deviceService.registeredDevices;
        const device = devices?.find(
          (device) => device.deviceType === deviceType?.key
        )!;

        this.deviceService.setupDeviceGlobally(device);
        this.currentDeviceType = deviceType?.deviceTypeId!;
      }
    });
  }

  addAnalytic() {
    this.formsService.openForm(AnalyticFormComponent).subscribe((res) => {});
  }

  openDialog(analyticId: number): void {
    const dynamicData = {
      title: 'Delete Analytics',
      message: 'Are you sure you want to delete this Analytics?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteAnalytic(analyticId);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }
}
