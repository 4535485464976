<app-form-container
  [tableTitle]="stepperTitle"
  (cancelled)="previous()"
  (close)="closeForm()"
  (submit)="submitAndNext()"
  [submitBtn]="submitBtn"
  [cancelBtn]="cancelBtn"
  [isValid]="isNextStep"
>
  <mat-stepper
    #stepper
    [linear]="true"
    class="h-100 custom-stepper"
    (selectionChange)="onStepChange($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step
      [stepControl]="stepOneRegisterForm"
      class="firststepper"
      label="{{ isEditMode ? 'Edit' : 'Add' }} Project"
    >
      <form [formGroup]="stepOneRegisterForm">
        <div
          formGroupName="companyRegisterForm"
          *ngIf="projectsOrgInfo?.length !== '0'"
        >
          <div class="gx-4 gy-3 row pt-4">
            <ng-container *ngFor="let details of companyRegisterDetails">
              <ng-container *ngIf="details?.isEdit == 'true'">
                <div [class]="details?.class">
                  <mat-form-field class="w-100">
                    <mat-label>{{ details.label }}</mat-label>
                    <input
                      [type]="details.type"
                      matInput
                      [formControlName]="details?.controlName"
                      autocomplete="new-password"
                    />
                    <button
                      *ngIf="
                        details?.controlName === 'clientId' ||
                        details?.controlName === 'clientSecret'
                      "
                      mat-icon-button
                      matSuffix
                      color="accent"
                      (click)="
                        $event.stopPropagation();
                        $event.preventDefault();
                        copyText(
                          stepOneRegisterForm
                            .get('companyRegisterForm')
                            ?.get(details?.controlName)?.value
                        )
                      "
                      matTooltip="Copy to clipboard"
                      matTooltipPosition="below"
                      class="sharebtn"
                      [disabled]="
                        (details?.controlName === 'clientId' &&
                          !this.userData?.clientId) ||
                        (details?.controlName === 'clientSecret' &&
                          !this.userData?.clientSecret)
                      "
                    >
                      <mat-icon class="material-symbols-outlined"
                        >content_copy</mat-icon
                      >
                    </button>
                    <mat-error
                      *ngIf="
                        stepOneRegisterForm
                          .get('companyRegisterForm')
                          ?.get(details.controlName)
                          ?.hasError('min') ||
                        stepOneRegisterForm
                          .get('companyRegisterForm')
                          ?.get(details.controlName)
                          ?.hasError('max') ||
                        stepOneRegisterForm
                          .get('companyRegisterForm')
                          ?.get(details.controlName)
                          ?.hasError('pattern')
                      "
                      >{{ details.error }}</mat-error
                    >
                  </mat-form-field>

                  <small>
                    <mat-hint
                      *ngIf="
                        details.hint && !isControlDisabled(details?.controlName)
                      "
                      class="labelstyle"
                      >{{ details.hint }}</mat-hint
                    >
                  </small>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="col-lg-12 d-flex flex-direction-row gap-3">
            <span class="rawData">Raw Data:</span>
            <mat-slide-toggle
              class="pt-2"
              [checked]="rawData"
              (change)="onToggleChange($event)"
            ></mat-slide-toggle>
          </div>
        </div>

        <div formGroupName="adminUserDetailsForm" style="margin-top: 20px">
          <span class="medium">Admin User Details</span>
          <div class="gx-4 gy-3 row pt-3">
            <div
              *ngFor="let details of adminUserDetails"
              class="col-12 col-lg-6"
            >
              <ng-container *ngIf="details.label === 'Contact'; else other">
                <app-mobile-number-input
                  [patchNumbers]="existingMobileNumbers"
                  [isMultiple]="false"
                  (phoneNumberAction)="onNumberAdd($event)"
                >
                </app-mobile-number-input>
              </ng-container>

              <ng-template #other>
                <mat-form-field class="w-100">
                  <mat-label>{{ details.label }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControlName]="details.controlName"
                  />
                  <mat-error
                    *ngIf="
                      stepOneRegisterForm
                        .get('adminUserDetailsForm')
                        ?.get(details.controlName)
                        ?.hasError('pattern')
                    "
                    >{{ details.error }}</mat-error
                  >
                </mat-form-field>
                <small>
                  <mat-hint
                    *ngIf="
                      details.hint && !isControlDisabled(details?.controlName)
                    "
                    class="labelstyle"
                    >{{ details?.hint }}</mat-hint
                  >
                </small>
              </ng-template>
            </div>
          </div>
        </div>

        <div formGroupName="adminUserLoginDetailForm" style="margin-top: 20px">
          <span class="medium">Admin User Login Details</span>
          <div class="gx-4 gy-3 row pt-3">
            <div
              *ngFor="let details of adminUserLoginDetail"
              class="col-12 col-lg-6"
            >
              <div *ngIf="details.isEdit == 'true'">
                <mat-form-field class="w-100">
                  <mat-label>{{ details.label }}</mat-label>
                  <input
                    [type]="
                      details.type
                        ? details.type
                        : showType
                        ? 'password'
                        : 'text'
                    "
                    matInput
                    [formControlName]="details.controlName"
                    autocomplete="new-password"
                  />
                  <mat-icon
                    matSuffix
                    class="material-symbols-outlined"
                    *ngIf="details.controlName === 'password'"
                    (click)="toggleTypeofPassword()"
                    >visibility_off</mat-icon
                  >
                </mat-form-field>
                <small>
                  <mat-error
                    *ngIf="
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('email') ||
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('customError') ||
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('confirmpasswordmismatch') ||
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('passwordmismatch')
                    "
                    >{{
                      !isControlDisabled(details?.controlName)
                        ? details.hint
                        : ""
                    }}</mat-error
                  >
                </small>
                <small>
                  <mat-hint
                    class="labelstyle"
                    *ngIf="
                      !(
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('email') ||
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('customError') ||
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('confirmpasswordmismatch') ||
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('passwordmismatch')
                      )
                    "
                  >
                    {{
                      !isControlDisabled(details?.controlName)
                        ? details.hint
                        : ""
                    }}
                  </mat-hint>
                </small>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step
      [stepControl]="stepTwoDeviceForm"
      class="secondstepper"
      label="Select Device"
    >
      <form [formGroup]="stepTwoDeviceForm">
        <div class="row align-items-center">
          <div class="col-12 pt-3 pb-3 d-flex flex-column">
            <mat-form-field class="col-lg-3 col-12">
              <mat-label>Select Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="selectedDate"
              />
              <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-hint class="col-lg-auto col-12"
              >Specify a start date from which you want the user to access the
              device data
            </mat-hint>
          </div>
        </div>
        <app-data-table
          [data]="usersAllDevices"
          [columns]="defaultColumnsDevices"
          [displayedColumns]="displayedColumnsDevices"
          [selection]="deviceSelector"
          (onSelect)="onDeviceSelection()"
          [showTableHeader]="true"
          [showPaginator]="false"
          [isChartVisible]="false"
          [showDownloadInHeader]="false"
          [showFilterInHeader]="false"
        ></app-data-table>
      </form>
    </mat-step>
    <mat-step
      [stepControl]="stepThreeModuleForm"
      class="thirdstepper"
      label="Select Module"
    >
      <app-data-table
        [data]="modules"
        [columns]="defaultColumnsModules"
        [displayedColumns]="displayedColumnsModules"
        (onSelect)="onModuleSelection()"
        (dateSelected)="onDateSelection($event)"
        [showTableHeader]="false"
        [showPaginator]="false"
        [selection]="moduleSelector"
        [isChartVisible]="false"
      ></app-data-table>
    </mat-step>
  </mat-stepper>
</app-form-container>
