import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceDetails } from '../../models/device/device-details';

@Component({
  selector: 'app-device-info-window',
  templateUrl: './device-info-window.component.html',
  styleUrls: ['./device-info-window.component.scss'],
})
export class DeviceInfoWindowComponent {
  @Input() device?: DeviceDetails;
  @Input() deviceData?: DeviceDetails[];
  @Input() showImage: boolean = true;

  constructor(private router: Router) {}

  getBatteryIcon(battery: string): string {
    return CommonUtil.getBatteryIcon(battery);
  }

  onGoToButton(modulePath: string) {
    this.router.navigateByUrl(modulePath);
  }
}
