import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SubMenu } from '../../models/menu/sub-menu';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-terminal-page-header',
  templateUrl: './terminal-page-header.component.html',
  styleUrls: ['./terminal-page-header.component.scss'],
})
export class TerminalPageHeaderComponent implements OnInit {
  @Input() menu!: SubMenu[];
  @Input() configureButton: boolean = true; // Flag for configure button in mobile screens only
  @Input() selectedSubMenu: SubMenu | undefined;
  width!: number;

  @Output() tabChange: EventEmitter<SubMenu>;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }

  constructor() {
    this.tabChange = new EventEmitter();
  }

  ngOnInit(): void {
    this.width = window.innerWidth;
  }

  onTabChange(matButtonToggleChange: MatButtonToggleChange) {
    let selectedSubMenu = matButtonToggleChange.value;
    if (!selectedSubMenu) {
      return;
    }
    this.selectedSubMenu = selectedSubMenu;
    setTimeout(() => {
      this.tabChange.emit(selectedSubMenu);
    });
  }
}
