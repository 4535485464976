<mat-sidenav-container class="h-100" autosize>
  <mat-sidenav
    [position]="'end'"
    [mode]="width > 991 ? 'side' : 'over'"
    [opened]="selectedDevice ? true : false"
  >
    <div class="device p-3">
      <div class="row">
        <div class="col">Device Information</div>
        <div class="col-auto">
          <mat-icon
            class="material-symbols-outlined cursor-pointer"
            (click)="clearDevice()"
            >close</mat-icon
          >
        </div>
        <div class="last-updated">
          Last Updated: {{ getFormattedDate(selectedDevice?.lastUpdated) }}
        </div>
      </div>
      <mat-card class="mt-2 mb-4 p-3 pb-0 card-info">
        <div *ngIf="selectedDevice?.url" class="device__actual-image">
          <img [src]="selectedDevice?.url" />
        </div>
        <div *ngIf="!selectedDevice?.url" class="device__image">
          <img
            height="131"
            src="./../../../../../../../assets/images/device/default-device-image.jpg"
          />
        </div>
        <div class="device__details mt-3">
          <div class="d-flex justify-content-between py-1">
            <span>Device ID</span>
            <span class="text-end">{{ selectedDevice?.deviceId }}</span>
          </div>
          <hr class="device__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Location</span>
            <span class="text-end ps-2 ps-lg-3">{{ selectedDevice?.loc }}</span>
          </div>
          <hr class="device__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Battery</span>
            <div class="text-end">
              <span *ngIf="selectedDevice?.payload?.d?.bs">{{
                selectedDevice?.payload?.d?.bs + "%"
              }}</span>
            </div>
          </div>
          <hr class="device__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Device Status</span>
            <span
              class="text-end"
              [style.color]="selectedDevice?.status?.color"
              >{{ selectedDevice?.status?.msg }}</span
            >
          </div>
          <hr class="device__details__divider" />
          <div class="mb-2 d-flex justify-content-between align-items-center">
            <span class="text-primary more-insights">More Insights:</span>
            <div class="device__details__more-actions row">
              <button
                mat-icon-button
                tabindex="-1"
                class="col"
                matTooltip="Dashboard"
                (click)="
                  navigateToAnotherModule('dashboard', selectedDevice?.deviceId)
                "
              >
                <nb-icon
                  class="w-auto h-auto"
                  icon="dashboard"
                  pack="envizom-module"
                ></nb-icon>
              </button>
              <button
                mat-icon-button
                tabindex="-1"
                class="col"
                matTooltip="Analytics"
                (click)="navigateToAnotherModule('analytics')"
              >
                <nb-icon
                  class="w-auto h-auto"
                  icon="analytics"
                  pack="envizom-module"
                ></nb-icon>
              </button>
              <button
                mat-icon-button
                tabindex="-1"
                class="col"
                matTooltip="Reports"
                (click)="navigateToAnotherModule('reports')"
              >
                <nb-icon
                  class="w-auto h-auto"
                  icon="reports"
                  pack="envizom-module"
                ></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="aqi-details p-3 card-info">
        <div class="mb-2">Parameters</div>
        <mat-accordion>
          <ng-container *ngFor="let gasLevel of gasLevels; let index = index">
            <mat-expansion-panel
              #parameterPanel="matExpansionPanel"
              class="parameters-aqi-india mat-elevation-z0 m-0"
              [hideToggle]="false"
              (click)="
                parameterPanel.hideToggle
                  ? (parameterPanel.expanded = false)
                  : null
              "
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="align-self-center">
                  <div class="d-flex justify-content-between">
                    <span>
                      {{
                        gasLevel?.name === "aqi" ? rawAqiLabel : gasLevel?.label
                      }}
                    </span>
                    <div
                      [style.color]="
                        paramColor[gasLevel?.name]
                          ? paramColor[gasLevel?.name]
                          : 'inherit'
                      "
                    >
                      <span>{{ gasLevel?.value }}</span>
                      <span> {{ gasLevel?.unit }}</span>
                    </div>
                  </div>
                </mat-panel-title>
                <hr />
              </mat-expansion-panel-header>
              <app-parameter-details
                *ngIf="selectedDevice"
                [isRawAqi]="gasLevel?.name === 'aqi'"
                [parameter]="gasLevel?.name"
                [parameterValue]="gasLevel?.value"
                [isVisible]="false"
                [deviceType]="selectedDevice.deviceType!"
                (noScaleFound)="parameterPanel.hideToggle = true"
              ></app-parameter-details>
              <div class="mb-4"></div>
            </mat-expansion-panel>
            <hr *ngIf="index + 1 !== gasLevels.length" class="my-0" />
          </ng-container>
        </mat-accordion>
      </mat-card>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngIf="googleMapsService.isApiLoaded | async">
      <div class="row mx-0 h-100">
        <google-map
          class="col-12 px-0"
          width="100%"
          height="100%"
          [options]="options"
        >
          <map-marker
            *ngFor="let device of devices; index as i"
            #marker="mapMarker"
            [options]="{
              zIndex: device?.deviceId == selectedDevice?.deviceId ? 0 : 1,
              icon: {
                url:
                  device?.deviceId == selectedDevice?.deviceId
                    ? device?.otherInfo?.iconUrl?.selectedPin
                    : device?.otherInfo?.iconUrl?.defaultPin,
                anchor:
                  device?.deviceId == selectedDevice?.deviceId
                    ? selectedAnchor
                    : anchor,
                size:
                  device?.deviceId == selectedDevice?.deviceId
                    ? selectedIconSize
                    : iconSize
              }
            }"
            [position]="{
              lat: device.payload.d.lat
                ? device.payload.d.lat!
                : device.latitude,
              lng: device.payload.d.lon
                ? device.payload.d.lon!
                : device.longitude
            }"
            (mapClick)="openInfoWindow(marker, i, 'device', device)"
          >
            <map-info-window
              #deviceInfoWindow="mapInfoWindow"
              (closeclick)="closeInfoWindows()"
            >
              <app-device-info-window
                [device]="device"
              ></app-device-info-window>
            </map-info-window>
          </map-marker>

          <div *ngIf="hasComplainsAccess && complainLayerEnabled">
            <map-marker
              *ngFor="let complain of complains; index as i"
              #marker="mapMarker"
              [options]="{ icon: complain?.iconUrl }"
              [position]="{ lat: complain.latitude, lng: complain.longitude }"
              (mapClick)="
                openInfoWindow(marker, i, 'complain', undefined, complain)
              "
            >
              <map-info-window
                #complainInfoWindow="mapInfoWindow"
                (closeclick)="closeInfoWindows()"
              >
                <div class="complain">
                  <div class="complain__name label-text-color">
                    {{ complain?.title }}
                  </div>
                  <div class="complain__image">
                    <img src="{{ complain?.image_url }}" />
                  </div>
                  <div class="complain__details">
                    <span class="label-text-color">
                      <span class="complain__details__label">Complain: </span>
                      {{ " " + complain?.complainId }}
                    </span>
                    <span class="label-text-color">
                      <span class="complain__details__label">Location: </span>
                      {{ " " + complain?.location }}
                    </span>
                    <span class="label-text-color">
                      <span class="complain__details__label">Priority: </span>
                      {{ " " + complain?.priority }}
                    </span>
                  </div>
                </div>
              </map-info-window>
            </map-marker>
          </div>

          <div *ngIf="industryLayerEnabled === true">
            <map-marker
              *ngFor="let industry of industries; index as i"
              #marker="mapMarker"
              [options]="{ icon: 'assets/images/pins/industry.png' }"
              [position]="{
                lat: industry.geometry.location.lat(),
                lng: industry.geometry.location.lng()
              }"
              (mapClick)="
                openInfoWindow(marker, i, 'industry', undefined, undefined)
              "
            >
              <map-info-window
                #industryInfoWindow="mapInfoWindow"
                (closeclick)="closeInfoWindows()"
              >
                <div class="industry">
                  <div class="industry__close">
                    <mat-icon
                      class="material-symbols-outlined icon"
                      (click)="closeInfoWindows()"
                    >
                      close
                    </mat-icon>
                  </div>
                  <div class="industry__name">{{ industry?.name }}</div>
                  <div class="industry__image">
                    <img src="{{ industry?.photo }}" />
                  </div>
                  <div class="industry__details">
                    <span>
                      <span class="industry__details__label">Location: </span>
                      {{ " " + industry?.address }}
                    </span>
                    <span>
                      <span class="industry__details__label">Rating: </span>
                      {{ " " + industry?.rating }}
                    </span>
                  </div>
                </div>
              </map-info-window>
            </map-marker>
          </div>
        </google-map>
      </div>
      <app-common-map-controls
        [googleMap]="googleMap"
        [options]="options"
        [devices]="devices"
        (layerToggled)="onLayerToggle($event)"
        (mapOptionsUpdated)="onMapOptionsUpdated($event)"
        [latLngBounds]="latLngBounds"
      ></app-common-map-controls>
      <!-- <app-wind-layer
        [windLayerEnabled]="windLayerEnabled"
        [windData]="windData"
        [width]="width"
        [height]="height"
        [cellSize]="10"
        [particleCount]="500"
        [particleLifetime]="50"
        [particleColor]="'rgba(255, 255, 255, 0.8)'"
        [curlFactor]="0.2"
        [interval]="50"
        [googleMap]="googleMap"
      ></app-wind-layer> -->
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
