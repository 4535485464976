import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComplainResolver {
  constructor(private complainService: ComplainsService) {}
  resolve(): Observable<boolean> {
    return this.complainService.getComplains();
  }
}
