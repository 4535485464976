<app-form-container
  [tableTitle]="formTitle"
  [submitBtn]="'Update'"
  (submit)="submitForm()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [isValid]="limitForm.valid && thresholdForm.valid"
>
  <div>
    <form [formGroup]="limitForm" class="row gy-3">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Unit</mat-label>
          <mat-select formControlName="unit">
            <mat-option
              *ngFor="let unitOfGas of unitsOfGas"
              [value]="unitOfGas.id"
            >
              {{ unitOfGas.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container
        *ngFor="
          let limit of asFormArray(limitForm.get('limits')!).controls;
          let i = index
        "
      >
        <div class="col-12" [formGroup]="asFormGroup(limit)">
          <mat-form-field class="w-100" appearance="outline">
            <div class="d-flex flex-row gap-3 align-items-center">
              <span
                class="aqi-color-circle"
                [style.background]="limitColors[i]"
              ></span>
              <span> > </span>
              <input
                matInput
                [placeholder]="'Limit ' + i"
                type="number"
                formControlName="limitValue"
              />
            </div>
          </mat-form-field>
        </div>
      </ng-container>
      <mat-error *ngIf="isNegativeLimit"
        >Only positive number allowed</mat-error
      >
      <mat-error *ngIf="!isAscendingLimit"
        >Limits must be in Ascending Order</mat-error
      >
    </form>
  </div>

  <!-- <div
    class="col-12 mt-4"
    *ngIf="baseLimitArray?.length || showThresholdLimits"
  > -->
  <div class="col-12 mt-4">
    <div class="d-flex flex-row gap-1 align-items-center mb-3">
      <p class="m-0 fw-bold">Threshold for Reference Line</p>
      <mat-icon
        class="material-symbols-outlined"
        [matTooltip]="
          'Users can enter threshold limits as per their standards , this threshold will be shown as a reference data line in Dashboard widget , Analytics and Reports.'
        "
        >info</mat-icon
      >
    </div>

    <div class="d-flex flex-column gap-3" [formGroup]="thresholdForm">
      <ng-container *ngFor="let threshold of allThresholds">
        <div class="d-flex flex-row" class="w-100">
          <mat-form-field class="w-100">
            <mat-label> {{ threshold.inputLabel }} </mat-label>
            <input
              [formControlName]="threshold.formControl"
              matInput
              [placeholder]="'Max Value ' + maxThresholdValue"
            />
            <!-- <input [formControlName]="threshold.formControl" matInput /> -->
            <mat-error
              *ngIf="thresholdForm.get(threshold.formControl)?.errors?.pattern"
            >
              Only number value allowed
            </mat-error>
            <mat-error
              *ngIf="thresholdForm.get(threshold.formControl)?.errors?.max"
            >
              Value must not be greater than {{ maxThresholdValue }}
            </mat-error>

            <mat-error
              *ngIf="thresholdForm.get(threshold.formControl)?.errors?.min"
            >
              Value must not be less than {{ minThresholdValue }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- <mat-form-field class="w-100">
    <div class="d-flex flex-row">
      <div class="alert-box">
        <mat-form-field class="w-100" appearance="outline">
          <mat-select>
            <mat-option
              *ngFor="
                let number of ['filteredListOfNumbers', 'hello1', 'hello2']
              "
              [value]="number"
              class="d-flex justify-content-start justify-content-xl-center"
            >
              {{ number }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex-grow-1 alert-box">
        <mat-form-field class="w-50" appearance="outline">
          <input matInput formControlName="number" />
        </mat-form-field>
      </div>

      <div
        class="d-flex align-items-center justify-content-center flex-shrink-0"
      >
        <button mat-mini-fab color="primary" matTooltip="Add Number">
          <mat-icon class="material-symbols-outlined">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-form-field> -->
</app-form-container>
