import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { Language } from 'src/app/shared/models/internal-use-front-end/languages';
import { LoginData } from 'src/app/shared/models/login-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { ProfileEditFormComponent } from './components/profile-edit-form/profile-edit-form.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public userProfileData!: LoginData;
  public userData: any;
  public subsriptions: Subscription[] = [];
  public isEditLogo: boolean = false;
  public isLogoAvailable: boolean = false;
  public darkModeLogoUrl!: string | undefined;
  public defaultModeLogoUrl!: string | undefined;

  constructor(
    private formsService: FormsService,
    private userService: UserService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.createUserProfileData();
    this.addSubscriptions();
  }

  changePassword() {
    this.formsService.openForm(PasswordFormComponent, undefined, 'sm');
  }

  editUserInformation() {
    this.formsService.openForm(
      ProfileEditFormComponent,
      this.userProfileData,
      'xl'
    );
  }

  createUserProfileData() {
    this.userProfileData = this.commonService.getUser();
    const info = this.userProfileData.organization.info;

    //check logo availability
    this.isLogoAvailable =
      Boolean(info?.dark_mode_logo) || Boolean(info?.logo_url);

    if (this.isLogoAvailable) {
      this.darkModeLogoUrl = info?.dark_mode_logo
        ? info?.dark_mode_logo
        : undefined;
      this.defaultModeLogoUrl = info?.logo_url ? info?.logo_url : undefined;
    }

    let allLanguages: Language[] = AppConstants.LANGUAGES;
    let timeZone: any = this.userProfileData.settings?.timezone;
    let timeFormat: any = this.userProfileData.settings.time_format;
    let language: Language | undefined = allLanguages.find(
      (language) => language.key === this.userProfileData.settings.language
    );

    this.userData = [
      {
        key: 'Name',
        value: this.userProfileData.name,
      },
      {
        key: 'Company Name',
        value: this.userProfileData.company,
      },
      {
        key: 'Email',
        value: this.userProfileData.email,
      },
      {
        key: 'Organization Name',
        value: this.userProfileData.org,
      },
      {
        key: 'Contact',
        value: this.userProfileData.contact,
      },
      {
        key: 'Location',
        value: this.userProfileData.location,
      },
      {
        key: 'City',
        value: this.userProfileData.city,
      },
      {
        key: 'State',
        value: this.userProfileData.state,
      },
      {
        key: 'Country',
        value: this.userProfileData.country,
      },
      {
        key: 'Timezone',
        value:
          timeZone?.gmtOffset && timeZone?.name
            ? '(UTC' + timeZone?.gmtOffset + ') ' + timeZone?.name
            : 'Not Set',
      },
      {
        key: 'Time Format',
        value: timeFormat
          ? `${timeFormat} hours`
          : `${this.commonService.getTimeFormat()} hours (Default)`,
      },
      {
        key: 'Language',
        value: language?.label ?? 'English (Default)',
      },
    ];
  }

  addSubscriptions() {
    let refresh = this.userService.userProfileUpdate$.subscribe(
      (res: boolean) => {
        if (res) this.createUserProfileData();
      }
    );

    this.subsriptions.push(refresh);
  }

  ngOnDestroy() {
    this.subsriptions.forEach((subsription) => subsription.unsubscribe());
  }
}
