import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NbIconLibraries } from '@nebular/theme';
import { interval } from 'rxjs';
import { AppConstants } from './shared/constants/app-constants';
import { LocalStorageConstants } from './shared/constants/local-storage.constant';
import { ConfirmDialogOptions } from './shared/models/confirm-dialog-options';
import { ConfirmDialogService } from './shared/services/confirm-dialog.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { NotificationService } from './shared/services/notification.service';
import { TranslationService } from './shared/services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  svgIcons = AppConstants.SVG_ICONS;

  constructor(
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private nbIconLibraries: NbIconLibraries,
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private confirmDialogService: ConfirmDialogService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient
  ) {
    this.notificationService.requestPermission();

    this.localStorageService.setupDefaultConfig().subscribe({
      next: (response) => {
      },
      error: (err) => {
        console.info("Error:", err);
        this.notificationService.showNotification(
          AppConstants.CONFIG_ERROR_MESSAGE,
          AppConstants.CLOSE,
          'top',
          'center',
          'error'
        );
      },
    });

    this.checkForAppUpdates();
  }

  ngOnInit(): void {
    const userLoggedIn = localStorage?.getItem(LocalStorageConstants.USER_ID)
      ? true
      : false;
    const moduleAccess =
      JSON.parse(
        this.localStorageService.getValue(LocalStorageConstants.MODULE_ACCESS)
      ) &&
      JSON.parse(
        this.localStorageService.getValue(LocalStorageConstants.MODULE_ACCESS)
      ).includes(1009)
        ? true
        : false;
    (window as any).userLoggedIn = userLoggedIn;
    (window as any).moduleAccess = moduleAccess;
    this.updateApplication();
    this.registerIcons();
  }

  ngAfterViewInit() {
    let language =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.language ?? 'en';
    this.translationService.switchLanguage(language);
  }

  registerAppIcons(): void {}

  registerIcons() {
    let svgPack: any = {};
    let index = 0;
    this.iconRegistry.registerFontClassAlias(
      'material-symbols',
      'material-symbols-outlined'
    );
    for (let icon of this.svgIcons) {
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.svg)
      );
      this.httpClient
        .get(icon.svg, { responseType: 'text' })
        .subscribe((value) => {
          value = value
            .replaceAll('stroke="white"', 'stroke="currentColor"')
            .replaceAll('fill="#fff"', 'fill="currentColor"')
            .replaceAll('stroke="#fff"', 'stroke="currentColor"')
            .replaceAll('fill="none"', 'fill="currentColor"')
            .replaceAll('fill="white"', 'fill="currentColor"');
          let sanitizedValue: any =
            this.sanitizer.bypassSecurityTrustHtml(value);
          svgPack[icon.name] =
            sanitizedValue['changingThisBreaksApplicationSecurity'];
          if (index == this.svgIcons.length - 1) {
            this.registerSvgPack(svgPack);
          } else {
            index++;
          }
        });
    }
  }

  registerSvgPack(svgPack: any) {
    this.nbIconLibraries.registerSvgPack('envizom-module', svgPack);
  }

  checkForAppUpdates(): void {
    this.applicationRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(24 * 60 * 60 * 1000);
        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => {});
        });
      }
    });
  }

  updateApplication(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          const verisonEvent = event as VersionReadyEvent;

          const options: ConfirmDialogOptions = {
            title: 'Update Available',
            message:
              'A new version of the application is available. Load new version?',
            cancelText: 'Cancel',
            confirmText: 'Ok',
          };

          this.confirmDialogService.open(options);
          this.confirmDialogService.confirmed().subscribe((confirmed) => {
            if (confirmed) {
              this.swUpdate
                .activateUpdate()
                .then(() => window.location.reload());
            }
          });
        }
      });
    }
  }
}
