import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './analytics.component';
import { AnalyticsListComponent } from './pages/analytics-list/analytics-list.component';
import { GraphViewComponent } from './pages/graph-view/graph-view.component';

const routes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
    children: [
          {
            path: '',
            redirectTo: 'analytics-list',
            pathMatch: 'full',
          },
          {
            path: 'analytics-list',
            component: AnalyticsListComponent,
          },
          {
            path: ':analyticsId',
            component: GraphViewComponent,
          },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
