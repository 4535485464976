import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { delay, filter, Subscription, switchMap, take, tap } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Device } from 'src/app/shared/models/device/device-info';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss'],
})
export class DeviceFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(GoogleMap) public googleMap!: GoogleMap;
  public options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;
  public mapInitialized: boolean = false;
  public selectedIconSize: any = null;
  public selectedAnchor: any = null;
  public deviceInfo!: Device.Info;
  public markers: Array<GoogleMapsService> = [];
  public currentLatitude!: number;
  public currentLongitude!: number;
  public form!: FormGroup;
  public imageForm!: FormGroup;
  public windAndIntervalform!: FormGroup;
  public deviceDetailform!: FormGroup;
  public isEditMode: boolean;
  public display: FormControl = new FormControl('', Validators.required);
  public file_store: File | null;
  public isImageSelected: boolean;
  public updateImage: any;
  public img_url!: any;
  public showLoader: boolean;
  public isAdmin!: boolean;
  public isMasterOrgUser!: boolean;
  public deviceStatus = AppConstants.DEVICE_STATUS;
  public submitBtn: string = 'Next';
  public backBtn: string = 'Cancel';
  public timeIntervals: any = AppConstants.TIME_INTERVALS;
  public selectedInterval: string = '';
  public isNextStep: boolean = false;
  public subscriptions: Subscription[] = [];
  public currentDeviceTypeId!: number;
  public deviceTags: any[] = [];
  public availableTags = this.deviceService.getUniqueDeviceTags(
    this.deviceService.registeredDevicesWithVibration || []
  );

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private loadrService: LoadrService,
    public googleMapsService: GoogleMapsService,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private commonService: CommonService,
    @Inject(MTX_DRAWER_DATA) public data: Device.Info | undefined
  ) {
    this.isEditMode = false;
    this.file_store = null;
    this.isImageSelected = false;
    this.showLoader = false;
    this.isMasterOrgUser = this.commonService.IsMasterOrgUser();
    this.isAdmin =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        .role === 1
        ? true
        : false;
  }

  ngOnInit(): void {
    this.addExistingTags();
    let map = this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(0.5),
        switchMap(() => this.googleMap?.idle),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as unknown as Map))
      )
      .subscribe(() => {
        (this.googleMap?.googleMap as unknown as Map).addListener(
          'click',
          (event: any) => {
            this.onMapClick(event.latLng);
          }
        );
      });
    this.subscriptions.push(map);

    this.currentDeviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      this.commonService.getUserDeviceTypesWithVibration(),
      this.deviceService.registeredDevicesWithVibration!,
      this.data?.deviceId!
    )!;
    this.isEditMode = !!this.data;
    this.deviceInfo = this.data!;
    this.currentLatitude = this.deviceInfo.latitude;
    this.currentLongitude = this.deviceInfo.longitude;
    this.buildForm(this.isEditMode);
    this.addFormSubscription();
  }

  //add the existing tags
  addExistingTags() {
    this.deviceTags = this.data?.options?.deviceTags || [];
  }

  //this function fetches the name of the image from url
  getImageUrl() {
    this.img_url = this.data?.url;
    if (this.img_url !== undefined || null) {
      this.isImageSelected = true;
      return this.img_url;
    } else {
      this.isImageSelected = false;
      return undefined;
    }
  }

  //this function builds the form based on condition:- 1) user is editing device or 2) user is adding device
  buildForm(isEditMode: boolean = false) {
    this.deviceDetailform = this.formBuilder.group({
      deviceId: [{ value: '', disabled: isEditMode }, Validators.required],
      deviceType: [{ value: '', disabled: isEditMode }, Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      loc: ['', Validators.required],
      label: ['', Validators.required],
      status: ['', Validators.required],
    });

    this.windAndIntervalform = this.formBuilder.group({
      deviceImage: [this.getImageUrl() ? this.getImageUrl() : null],
      interval: ['', Validators.required],
      windDataOutside: [false, Validators.required],
      updateForSubUser: [false, Validators.required],
    });

    if (isEditMode) {
      this.deviceDetailform.patchValue({
        ...this.data!,
        status: this.data?.isOnline,
      });

      if (!this.isAdmin) {
        this.deviceDetailform.removeControl('status');
        this.windAndIntervalform.removeControl('interval');
        this.windAndIntervalform.removeControl('windDataOutside');
        this.windAndIntervalform.removeControl('updateForSubUser');
      }

      //disable time interval field if device type is 1006(3rd Party)
      if (this.currentDeviceTypeId == 1006) {
        this.windAndIntervalform
          .get('interval')
          ?.removeValidators(Validators.required);
        this.windAndIntervalform.get('interval')?.disable();
      }

      if (this.isAdmin) {
        let tInterval = this.timeIntervals.find(
          (res: any) => res.value === this.data?.interval.toString()
        );
        this.windAndIntervalform.patchValue({
          interval: tInterval.value,
        });

        this.windAndIntervalform.patchValue({
          windDataOutside: Boolean(this.data?.weather),
        });
      }

      this.isNextStep = this.deviceDetailform.valid;
      // this.img_url = this.data?.url;
      // this.isImageSelected = !!this.data?.url;
    }
  }

  handleImageSelected(files: FileList | File): void {
    if (files instanceof File) {
      this.file_store = files;
      this.windAndIntervalform.controls.deviceImage.patchValue(files.name);
      this.isImageSelected = true;
      this.onUpdateImg(files);
    }
  }

  //executes when user wants to remove the selected image from the form while updating the form
  removeFile() {
    this.file_store = null;
    this.windAndIntervalform.controls.deviceImage.patchValue(null);
    this.img_url = '';
    this.isImageSelected = false;
    if (this.isAdmin) {
      this.windAndIntervalform.controls.updateForSubUser.setValue(false);
    }
  }

  updateDevice() {
    this.stepper.next();
    let deviceDetails: Device.Update = this.generatePayload();

    this.loadrService.showLoader();
    if (this.file_store) {
      this.deviceService.updateImageURL(this.file_store).subscribe({
        next: (res: any) => {
          deviceDetails = {
            ...deviceDetails,
            data: { ...deviceDetails.data, url: res.url },
          };
          this.update(deviceDetails);
        },
        error: (err) => {
          console.info('Error:', err);
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar('Error! Try again', 'error');
        },
      });
    } else {
      // this.loadrService.showLoader();
      this.update(deviceDetails);
    }
  }

  update(deviceDetails: Device.Update) {
    this.deviceService
      .updateDevice(this.data?.deviceId, deviceDetails)
      .subscribe({
        next: (res: string) => {
          this.loadrService.removeLoader();
          this.closeForm();
          this.deviceService.refreshDeviceList.next(true);
          this.notificationService.showSnackBar(res, 'success');
          //overview call to get updated device info (if user has added or removed device image)
          this.callOverview();
        },
        error: (err) => {
          console.info('Error:', err);
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar(
            'Error while updating device info',
            'error'
          );
        },
      });
  }

  generatePayload() {
    let option = {
      deviceTags: this.deviceTags,
    };

    if (this.deviceInfo?.options) {
      option = { ...this.deviceInfo?.options, ...option };
    }

    let payload: Device.Update = {
      data: {
        deviceType: this.deviceInfo.deviceType,
        latitude: this.currentLatitude,
        longitude: this.currentLongitude,
        loc: this.deviceDetailform.value.loc,
        label: this.deviceDetailform.value.label,
        city: this.deviceInfo.city,
        country: this.deviceInfo.country,
        url: this.windAndIntervalform.value.deviceImage,
        options: option,
      },
      forEveryone: this.windAndIntervalform.value?.updateForSubUser,
      enabled: this.deviceDetailform.value?.status == 0 ? false : true,
      interval: Number(this.windAndIntervalform.value?.interval),
      weather: this.windAndIntervalform.value?.windDataOutside,
    };

    if (!this.isAdmin) {
      delete payload.interval;
      delete payload.forEveryone;
      delete payload.enabled;
      delete payload.weather;
    }

    //remove the time interval key from payload if device type is 1006(3rd Party)
    if (this.currentDeviceTypeId == 1006) delete payload.interval;

    return payload;
  }

  //executes when user clicks on cancel button
  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  //to show the preview when user selects image
  onUpdateImg(image: any) {
    var reader = new FileReader();
    try {
      let formData: FormData = new FormData();
      this.updateImage = null;
      this.updateImage = <Array<File>>image;
      reader.readAsDataURL(this.updateImage);
      reader.onload = (e) => {
        this.img_url = null;
        this.img_url = reader.result;
        //if user upload a device for image by default tick the 'updateForSubUser' checkbox (for better ux)
        if (this.isAdmin) {
          this.windAndIntervalform.controls.updateForSubUser.setValue(true);
        }
      };
    } catch (e) {
      console.info(e);
    }
  }

  openTermsAndConditionModal(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'hello, world!',
        icon: 'find_replace',
      },
      maxHeight: '80vh',
      maxWidth: '50vw',
    });
  }

  onMapClick(latLng: any): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        if (results?.[0]) {
          this.currentLatitude = latLng.lat();
          this.currentLongitude = latLng.lng();
          this.cd.detectChanges();
          this.deviceDetailform.get('latitude')?.setValue(this.currentLatitude);
          this.deviceDetailform
            .get('longitude')
            ?.setValue(this.currentLongitude);
          this.deviceDetailform
            .get('loc')
            ?.setValue(results[0].formatted_address);
        } else {
          console.info('No results found');
        }
      } else {
        console.info('Geocoder failed due to: ' + status);
      }
    });
  }

  mapReady(map: Map): void {
    this.mapInitialized = true;

    if (this.currentLatitude && this.currentLatitude) {
      this.fitBounds(map);
      map.setZoom(16);
    }

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('zoom-control')!
    );

    this.selectedIconSize = new google.maps.Size(
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.width,
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.height
    );

    this.selectedAnchor = new google.maps.Point(
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.x,
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.y
    );
  }

  fitBounds(map: Map): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers = {
      lat: this.currentLatitude,
      lng: this.currentLongitude,
    };
    latLngBounds.extend(markers);
    map.fitBounds(latLngBounds);
  }

  //to control the zoom in of the map manually
  zoomIn(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! + 1 <= this.options.maxZoom!
          ? this.googleMap.getZoom()! + 1
          : this.options.maxZoom,
    };
  }

  //to control the zoom out of the map manually
  zoomOut(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! - 1 >= this.options.minZoom!
          ? this.googleMap.getZoom()! - 1
          : this.options.minZoom,
    };
  }

  onStepChange(event: any) {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      this.previous(event);
    } else if (event.selectedIndex > event.previouslySelectedIndex) {
      this.submitAndNext(event);
    }
  }

  submitAndNext(event?: any) {
    event = !event;
    if (this.stepper.selectedIndex === 0 && this.deviceDetailform.valid) {
      if (event) this.stepper.next();
      this.submitBtn = 'Submit';
      this.backBtn = 'Back';
      this.isNextStep = this.windAndIntervalform.valid;
    } else if (
      this.stepper.selectedIndex === 1 &&
      this.windAndIntervalform.valid
    ) {
      if (event) this.updateDevice();
    }
  }

  //it executes when user click on back button of the form
  previous(event?: any) {
    event = !event;
    if (this.stepper.selectedIndex === 1) {
      if (event) this.stepper.previous();
      this.submitBtn = 'Next';
      this.backBtn = 'Cancel';
      this.isNextStep = this.deviceDetailform.valid;
    } else if (this.stepper.selectedIndex === 0) {
      this.formsService.closeForm();
    }
  }

  updateImageUrl(newImageUrl: any): void {
    this.img_url = newImageUrl;
  }

  addFormSubscription() {
    let lat = this.deviceDetailform
      .get('latitude')
      ?.valueChanges.subscribe((res) => {
        if (res) {
          this.currentLatitude = parseFloat(res);
          if (this.googleMap?.googleMap) {
            this.getAddressFromLatLong();
          }
        }
      })!;

    let long = this.deviceDetailform
      .get('longitude')
      ?.valueChanges.subscribe((res) => {
        if (res) {
          this.currentLongitude = parseFloat(res);
          if (this.googleMap?.googleMap) {
            this.getAddressFromLatLong();
          }
        }
      })!;

    let deviceForm = this.deviceDetailform.valueChanges.subscribe((res) => {
      this.isNextStep = this.deviceDetailform.valid;
    });

    let windForm = this.windAndIntervalform.valueChanges.subscribe((res) => {
      this.isNextStep = this.windAndIntervalform.valid;
    });

    this.subscriptions.push(lat);
    this.subscriptions.push(long);
    this.subscriptions.push(deviceForm);
    this.subscriptions.push(windForm);
  }

  getAddressFromLatLong() {
    this.fitBounds(this.googleMap?.googleMap as unknown as Map);
    let latLng = {
      lat: this.currentLatitude,
      lng: this.currentLongitude,
    };
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        if (results?.[0]) {
          this.deviceDetailform
            .get('loc')
            ?.setValue(results[0].formatted_address);
        }
      }
    });
  }

  callOverview() {
    this.deviceService.setupDeviceDetails(false, false).subscribe({
      next: (res) => {
        return;
      },
      error: (err) => {
        console.info(err, 'Error while calling overview');
      },
    });
  }

  //listen to the event emitted from tag input component
  onTagChange(event: any[]) {
    this.deviceTags = event;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
