import { WindData, Vector } from "../types/wind";

export class VectorField {
  private field: Vector[][];
  private width: number;
  private height: number;
  public cellSize: number;
  private interpolator: WindInterpolator;
  private projection: google.maps.MapCanvasProjection | null;


  constructor(
    width: number,
    height: number,
    cellSize: number,
    windData: WindData[],
    projection: google.maps.MapCanvasProjection | null = null
  ) {
    this.width = width;
    this.height = height;
    this.cellSize = cellSize;
    this.interpolator = new WindInterpolator(windData);
    this.projection = projection;
    this.field = this.generateField();
  }

  private generateField(): Vector[][] {
    const cols = Math.ceil(this.width / this.cellSize);
    const rows = Math.ceil(this.height / this.cellSize);
    const field: Vector[][] = [];
    if (!this.projection) {
      return field;
    }
  
    for (let y = 0; y < rows; y++) {
      field[y] = [];
      for (let x = 0; x < cols; x++) {
        // Create a point based on the pixel coordinates
        const point = new google.maps.Point(x * this.cellSize, y * this.cellSize);
        
        // Ensure projection is available
        if (this.projection) {
          const latLng = this.projection.fromDivPixelToLatLng(point);
  
          if (latLng) {
            const { direction, magnitude } = this.interpolator.interpolate(latLng.lat(), latLng.lng());
            const radians = (direction * Math.PI) / 180;
  
            field[y][x] = {
              x: Math.cos(radians) * magnitude,
              y: Math.sin(radians) * magnitude
            };
          } else {
            console.warn('latLng is undefined for point:', point);
          }
        } else {
          console.error('Projection is not available.');
        }
      }
    }
  
    return field;
  }
  

  getVector(x: number, y: number): Vector {
    const col = Math.floor(x / this.cellSize);
    const row = Math.floor(y / this.cellSize);
    
    if (row >= 0 && row < this.field.length && col >= 0 && col < this.field[0].length) {
      return this.field[row][col];
    }
    
    return { x: 0, y: 0 };
  }

  setDimensions(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }
}

export class WindInterpolator {
  private data: WindData[];
  private gridSize: number;

  constructor(data: WindData[], gridSize: number = 25) {
    this.data = data;
    this.gridSize = gridSize;
  }

  interpolate(lat: number, lon: number): { direction: number; magnitude: number } {
    // Using Inverse Distance Weighting (IDW) interpolation
    let totalWeight = 0;
    let directionX = 0;
    let directionY = 0;
    let totalMagnitude = 0;

    for (const point of this.data) {
      const distance = this.calculateDistance(lat, lon, point.lat, point.lon);
      if (distance === 0) {
        return { direction: point.direction, magnitude: point.magnitude };
      }

      const weight = 1 / (distance * distance);
      totalWeight += weight;

      // Convert direction to vector components to properly interpolate circular values
      const radians = (point.direction * Math.PI) / 180;
      directionX += Math.cos(radians) * weight;
      directionY += Math.sin(radians) * weight;
      totalMagnitude += point.magnitude * weight;
    }

    const interpolatedDirection = (Math.atan2(directionY, directionX) * 180) / Math.PI;
    const normalizedDirection = interpolatedDirection < 0 ? interpolatedDirection + 360 : interpolatedDirection;
    const interpolatedMagnitude = totalMagnitude / totalWeight;

    return {
      direction: normalizedDirection,
      magnitude: interpolatedMagnitude
    };
  }

  private calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    // Simple Euclidean distance - for more accuracy, consider using Haversine formula
    return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lon2 - lon1, 2));
  }
}