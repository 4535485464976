<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <div
      [formGroup]="headerForm"
      class="d-flex flex-wrap h-100 align-items-center justify-content-between gap-2 gap-lg-0"
    >
      <div
        class="d-flex flex-wrap col-12 col-lg-8 gap-2"
        *ngIf="isConfigurationsView()"
      >
        <mat-form-field class="col-12 col-lg-3">
          <mat-label>Device Type</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Device Type'"
            matInput
            formControlName="deviceType"
            [matAutocomplete]="deviceType"
          />
          <mat-autocomplete
            #deviceType="matAutocomplete"
            [displayWith]="getDeviceType"
          >
            <mat-option
              *ngFor="
                let deviceType of deviceTypes
                  | search : headerForm.get('deviceType')?.value : 'label'
              "
              [value]="deviceType"
            >
              {{ deviceType.label }}
            </mat-option>
          </mat-autocomplete>
          <button
            [disabled]="isNoAlertCreated"
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">
              arrow_drop_down
            </mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-form-field class="col-12 col-lg-3" *ngIf="isDataAlertsView()">
        <mat-label>Select Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="startDate"
            [placeholder]="'Start Date'"
          />
          <input
            matEndDate
            formControlName="endDate"
            [placeholder]="'End Date'"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error
          *ngIf="
            headerForm?.controls?.startDate?.hasError('matStartDateInvalid')
          "
        >
          Invalid start date
        </mat-error>
        <mat-error
          *ngIf="headerForm?.controls?.endDate?.hasError('matEndDateInvalid')"
        >
          Invalid end date
        </mat-error>
      </mat-form-field>

      <div class="d-flex col-12 col-lg-4 justify-content-end gap-3">
        <div
          *ngIf="!userInfo.settings?.isAlertMigrated && isConfigurationsView()"
          class="col-12 col-lg-auto"
        >
          <button
            mat-raised-button
            color="primary"
            (click)="openDialog()"
            class="w-100 w-lg-auto"
            matTooltip="Migrate Alert"
          >
            Migrate Alert
            <mat-icon matSuffix class="material-symbols-outlined"
              >trending_down</mat-icon
            >
          </button>
        </div>
        <div class="col-12 col-lg-auto">
          <button
            mat-raised-button
            color="primary"
            (click)="addAlert()"
            class="w-100 w-lg-auto"
            matTooltip="Add Alert"
          >
            Add Alert
            <mat-icon matSuffix class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
        <div class="d-flex align-items-center gap-1" matTooltip="Remaining SMS">
          <button
            mat-stroked-button
            color="primary"
            class="w-100 w-lg-auto point pe-none"
          >
            <mat-icon class="material-symbols-outlined"> textsms </mat-icon>
            <span> {{ remainingSms }} </span>
          </button>
        </div>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
