import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { ImageViewPopupComponent } from '../image-view-popup/image-view-popup.component';
@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent {
  @ViewChild('f_input') f_input!: ElementRef<HTMLInputElement>;

  imageViewOverlayRef!: OverlayRef;
  imageViewOverlayComponentRef!: ComponentRef<ImageViewPopupComponent>;

  @Input() noImageMessage: string = 'No Image Uploaded!';
  @Input() fileAcceptExtensions: string = '.jpg, .jpeg, .png';
  @Input() fileLabel: string = 'image'
  @Input() isEditMode!: boolean;
  @Input() isDisabled!: boolean;
  @Input() imageUrl: any;
  @Input() form!: FormGroup;
  @Input() isSubmit: boolean = false;
  @Output() imageSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageRemoved: EventEmitter<void> = new EventEmitter<void>();
  @Output() imageUrlUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() logoRemoved: EventEmitter<void> = new EventEmitter<void>();
  isImageSelected!: boolean;

  constructor(
    private notificationService: NotificationService,
    private overlay: Overlay
  ) {}
  ngOnInit(): void {
    this.isImageSelected = this.imageUrl ? true : false;
  }

  ngOnChanges() {
    this.isImageSelected = this.imageUrl ? true : false;
  }

  handleFileInputChange(files: FileList): void {
    const maxSizeBytes = 2097152; // 2MB in bytes (1mb = 1024kb and 1kb = 1024 bytes)
    if (files.length > 0) {
      if (files[0].size <= maxSizeBytes) {
        const file = files[0];
        this.imageUrl = URL.createObjectURL(file);
        this.imageSelected.emit(file);
        this.isImageSelected = true;
        this.imageUrlUpdated.emit(this.imageUrl);
      } else {
        // alert('File size exceeds the maximum allowed size (2 MB).');
        this.notificationService.showNotification(
          'File size exceeds the maximum allowed size (2 MB).',
          'Close',
          'bottom',
          'right',
          'warning'
        );
        return;
      }
    }
  }

  selectFile(): void {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  removeFile(): void {
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl);
    }
    this.isImageSelected = false;
    this.imageUrl = ''; // Clear the image URL
    this.f_input.nativeElement.value = ''; // Clear the file input
    this.imageRemoved.emit();
  }

  submitFile(): void {
    this.fileSubmitted.emit();
  }

  removeLogo(): void {
    this.logoRemoved.emit();
    this.removeFile();
  }

  zoomImage(): void {
    this.imageViewOverlayRef = this.overlay.create({
      hasBackdrop: true,
      width: '100%',
      height: '100%',
    });

    const popupComponentPortal = new ComponentPortal(ImageViewPopupComponent);
    this.imageViewOverlayComponentRef =
      this.imageViewOverlayRef.attach(popupComponentPortal);

    this.imageViewOverlayComponentRef.instance.imageUrl = this.imageUrl;

    this.imageViewOverlayRef.backdropClick().subscribe(() => {
      this.imageViewOverlayRef.dispose();
    });

    this.closeOverlay();
  }

  openComponentOverlay() {
    this.imageViewOverlayRef = this.overlay.create({
      hasBackdrop: true,
      width: '100%',
      height: '100%',
    });

    const popupComponentPortal = new ComponentPortal(ImageViewPopupComponent);
    this.imageViewOverlayComponentRef =
      this.imageViewOverlayRef.attach(popupComponentPortal);

    this.imageViewOverlayComponentRef.instance.imageUrl = this.imageUrl;

    this.imageViewOverlayRef.backdropClick().subscribe(() => {
      this.imageViewOverlayRef.dispose();
    });

    this.closeOverlay();
  }

  closeOverlay(): void {
    this.imageViewOverlayComponentRef.instance.closeEvent.subscribe(() => {
      this.imageViewOverlayRef.detach();
    });
  }
}
