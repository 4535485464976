import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canAccessModule } from 'src/app/shared/auth-guard/can-access-module.guard';
import { DeviceDetailsResolver } from 'src/app/shared/resolvers/device-details.resolver';
import { AlertsModule } from './pages/alerts/alerts.module';
import { AnalyticsModule } from './pages/analytics/analytics.module';
import { AutomationModule } from './pages/automation/automation.module';
import { ClusterModule } from './pages/cluster/cluster.module';
import { ComplainModule } from './pages/complain/complain.module';
import { ConfigurationModule } from './pages/configuration/configuration.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DataFlaggingModule } from './pages/data-flagging/data-flagging.module';
import { DevicesModule } from './pages/devices/devices.module';
import { DisplayModule } from './pages/display/display.module';
import { HeatmapModule } from './pages/heatmap/heatmap.module';
import { IntegrationModule } from './pages/integration/integration.module';
import { ModuleLockScreenModule } from './pages/module-lock-screen/module-lock-screen.module';
import { OverviewModule } from './pages/overview/overview.module';
import { ProjectsModule } from './pages/projects/projects.module';
import { ReportsModule } from './pages/reports/reports.module';
import { UserModule } from './pages/user/user.module';
import { VibrationModule } from './pages/vibration/vibration.module';
import { TerminalComponent } from './terminal.component';

const routes: Routes = [
  {
    path: '',
    component: TerminalComponent,
    resolve: {
      deviceDataLoaded: () => inject(DeviceDetailsResolver).resolve(),
    },
    children: [
      {
        path: 'overview',
        loadChildren: () => OverviewModule,
      },
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule,
      },
      {
        path: 'devices',
        loadChildren: () => DevicesModule,
      },
      {
        path: 'api',
        loadChildren: () => IntegrationModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'user',
        loadChildren: () => UserModule,
      },
      {
        path: 'alerts',
        loadChildren: () => AlertsModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'analytics',
        loadChildren: () => AnalyticsModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'cluster',
        loadChildren: () => ClusterModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'automation',
        loadChildren: () => AutomationModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'complain',
        loadChildren: () => ComplainModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'display',
        loadChildren: () => DisplayModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'heatmap',
        loadChildren: () => HeatmapModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'data-flagging',
        loadChildren: () => DataFlaggingModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'projects',
        loadChildren: () => ProjectsModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'reports',
        loadChildren: () => ReportsModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'configuration',
        loadChildren: () => ConfigurationModule,
        canActivate: [canAccessModule],
      },
      {
        path: 'lock-screen/:modulePath',
        loadChildren: () => ModuleLockScreenModule,
      },
      {
        path: 'vibration',
        loadChildren: () => VibrationModule,
        canActivate: [canAccessModule],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TerminalRoutingModule {}
