import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, map, Subject, switchMap } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceDetails } from '../models/device/device-details';
import { Project } from '../models/project/project';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends BaseAPIService<Project.Details> {
  projects: Project.Details[] = [];
  allDevivetypes = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_ALL_DEV_TYPE
  );
  allAqisuser = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_ALL_AQIS
  );
  allAqi = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_ALL_AQI
  );
  aqi = this.localStorageService.getParsedValue(LocalStorageConstants.OZ_AQI);
  oz_current_device_type: any;
  devices_all: DeviceDetails[] = [];
  readonly toShowRawDataAQI: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    http: HttpClient,
    cookieService: CookieService
  ) {
    super(http, cookieService);
  }

  private refreshProjects = new Subject<any>();
  refreshProjects$ = this.refreshProjects.asObservable();

  private sharedArchiveDataSubject = new BehaviorSubject<any>(null);
  sharedArchiveData$ = this.sharedArchiveDataSubject.asObservable();

  public sharedSeatsSubject = new BehaviorSubject<any>(null);
  sharedSeats$ = this.sharedSeatsSubject.asObservable();

  masterOrgInfo: any;
  refreshProject(data: any) {
    this.refreshProjects.next(data);
  }

  updateArchiveData(data: any) {
    this.sharedArchiveDataSubject.next(data);
  }

  updateSeats(data: any) {
    this.sharedSeatsSubject.next(data);
  }

  getUsersAllOrgsInfo(userId: number, targetOrgId?: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let params: HttpParams = new HttpParams();

    if (targetOrgId) {
      params = params.append('targetOrgId', targetOrgId);
    }

    return this.get<Array<any>>(
      APIConstants.GET_USERS_ALLORGS(userId),
      headers,
      params
    ).pipe(
      map((data: any) => {
        let masterOrgInfo = this.masterOrgInfo || {};
        const updatedData = data.map((item: any) => ({
          ...item,
          masterOrgInfo,
        }));
        this.projects = updatedData;
        return updatedData;
      })
    );
  }

  getMasterOrgInfo(userId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<any>>(
      APIConstants.GET_MASTER_ORG(userId),
      headers
    ).pipe(
      map((data: any) => {
        this.masterOrgInfo = data?.info;
        // this.masterOrgInfo = data?.info;
        // this.masterOrgInfo.expiry = this.customMomentService.formatDatetime({
        //   epoch: this.masterOrgInfo.expiry,
        //   format: 'DD/MM/YYYY',
        // });
        return data;
      })
    );
  }

  setOrgArchive(userId: number, orgId: string, payload: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post(
      APIConstants.SET_ORG_ARCHIVE(userId, orgId),
      payload,
      headers
    );
  }

  updateProject(userId: number, targetOrgId: string, payload: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch(
      APIConstants.UPDATE_PROJECT(userId, targetOrgId),
      payload,
      headers
    ).pipe(
      map((res) => {
        this.getMasterOrgInfo(userId).subscribe();
        return res;
      })
    );
  }

  addProject(userId: number, payload: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post(APIConstants.ADD_PROJECT(userId), payload, headers);
  }

  getOrgUsers(userId: number, targetOrgId: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll({
      need_devices: true,
      need_moduleExpiry: true,
    });

    // return this.get<Array<any>>(
    //   APIConstants.GET_ORG_USERS(userId, targetOrgId),
    //   headers,
    //   params
    // ).pipe(
    //   map((data: any) => {
    //     return data
    //   })
    // );
    return this.getUsersAllOrgsInfo(userId, targetOrgId).pipe(
      switchMap(() => {
        return this.get<Array<any>>(
          APIConstants.GET_ORG_USERS(userId, targetOrgId),
          headers,
          params
        );
      }),
      map((data: any) => {
        let masterOrgInfo = this.masterOrgInfo;
        const dataArray = Array.isArray(data) ? data : Object.values(data);
        const orgData = this.projects?.find(
          (proj) => proj.orgId === targetOrgId
        );
        const orgName = this.projects?.find(
          (proj) => proj.orgId === targetOrgId
        )?.orgName;
        return dataArray.map((user: any) => ({
          ...user,
          orgData,
          orgName,
          masterOrgInfo,
        }));
      })
    );
  }

  deleteUser(userId: number, targetUserId: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.delete<Array<any>>(
      APIConstants.DELETE_USER(userId, targetUserId),
      headers
    );
  }

  getDefaultDialogConfig() {
    const defaultDialogConfig: MatDialogConfig = {
      disableClose: true, // Disable closing by clicking outside
      autoFocus: true, // Automatically focus on the first input
      panelClass: 'default-dialog-panel', // Apply a default panel class
      backdropClass: 'default-backdrop', // Apply a default backdrop class
    };

    // Setting static properties for different screen sizes
    if (window.innerWidth >= 1024) {
      defaultDialogConfig.width = '30%';
    } else if (window.innerWidth >= 768) {
      defaultDialogConfig.width = '50%';
    } else if (window.innerWidth >= 576) {
      defaultDialogConfig.width = '60%';
    } else {
      defaultDialogConfig.width = '100%';
    }

    return defaultDialogConfig;
  }

  getDialogRef(dialogRef: any) {
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
      return result;
    });
  }

  sendUpgradeEmail(userId: number, type: string, payload: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<Array<any>>(
      APIConstants.UPGRADE_PLAN(userId),
      payload,
      headers
    );
  }
}
