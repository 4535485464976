import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(
    values: Array<any>,
    search: any = '',
    key?: string,
    exactMatch: boolean = false
  ): Array<any> {
    if (!search || typeof search !== 'string') {
      return values;
    }

    if (key) {
      if (exactMatch) {
        return (
          values?.filter(
            (value) =>
              value[key].toString().toLowerCase() ==
              search.toString().toLowerCase()
          ) || []
        );
      }
      return (
        values?.filter((value) =>
          value[key]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        ) || []
      );
    } else {
      return (
        values?.filter(function (value) {
          return JSON.stringify(value)
            .toLowerCase()
            .includes(search.toString().toLowerCase());
        }) || []
      );
    }
    return [];
  }
}
