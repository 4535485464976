import { Component } from '@angular/core';

@Component({
  selector: 'app-terminal-page-body',
  templateUrl: './terminal-page-body.component.html',
  styleUrls: ['./terminal-page-body.component.scss']
})
export class TerminalPageBodyComponent {

}
