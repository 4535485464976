<div
  class="h-100 d-flex flex-column overflow-hidden"
  *ngIf="!isWsDataEmpty; else noDataTemplate"
>
  <div
    class="highcharts-dashboards-dark flex align-content-center justify-content-center"
    style="flex: 1"
  >
    <ng-container>
      <highcharts-chart
        *ngIf="show"
        [Highcharts]="highcharts"
        [options]="chartOptions"
        style="width: 100%; height: 99%; display: block"
      >
      </highcharts-chart>
    </ng-container>
  </div>
</div>

<ng-template #noDataTemplate>
  <div
    class="position-relative d-flex align-items-center justify-content-center m-0 w-100 h-100"
  >
    <div>
      <app-content-unavailable
        [majorText]="noData.majorText"
        [svgImage]="noData.svgImage"
        [minorText]="noData.minorText"
      ></app-content-unavailable>
    </div>
  </div>
</ng-template>
