import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDataResponse } from 'src/app/shared/models/device/device-data';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { OverviewService } from 'src/app/shared/services/overview.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

type DevicePayloadDetails = {
  parameters: { [key: string]: any };
  lastUpdated: any;
  deviceId: string;
  deviceType: string;
  label?: string;
  payload: { [key: string]: any };
};
type DevicesDetails = {
  deviceId: string;
  deviceType: string;
  label?: string;
  payload: DevicePayloadDetails[];
};
@Component({
  selector: 'app-aqi',
  templateUrl: './aqi.component.html',
  styleUrls: ['./aqi.component.scss'],
})
export class AqiComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() refreshEvent!: EventEmitter<boolean>;
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  subscriptions: Subscription[] = [];
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  indeDataNotAvailable: boolean = false;
  devices?: DevicesDetails[];
  allParameters: Array<any> = [];
  allFieldsOfAllDevices: DeviceField[] = [];
  displayedColumns: string[] = [];
  columns: any[] = [];
  currentDate!: number;
  lte!: number;
  gte!: number;

  defaultColumns: any[] = [
    {
      columnDef: 'deviceId',
      header: 'Device Id',
      cell: (element: DeviceDetails) => `${element.deviceId}`,
      selected: false,
      filter: false,
      parameter: false,
    },
    {
      columnDef: 'deviceName',
      header: 'Device Name',
      cell: (element: DeviceDetails) => `${element.label}`,
      selected: false,
      filter: false,
      parameter: false,
    },
    {
      columnDef: 'time',
      header: 'Time',
      cell: (element: DeviceDataResponse) =>
        `${
          element.payload[0]
            ? this.customMomentService.formatDatetime({
                epoch: element.payload[0].lastUpdated,
                format: this.commonService.getDateTimeFormat(),
              })
            : 'Device Offline'
        }`,
      selected: false,
      filter: false,
      parameter: false,
    },
  ];

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };
  moduleAccess: any;

  constructor(
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private overviewService: OverviewService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    // if (this.refreshEvent) {
    //   this.refreshEvent.subscribe((isLoading: boolean) => {
    //     this.loadTable.next(!isLoading);
    //   });
    // }
    this.moduleAccess = this.commonService.moduleAccessibility(1001);
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;
  }

  ngAfterViewInit(): void {
    this.addSubscriptions();
  }

  initializeData(): void {
    if (!CommonUtil.isEmpty(this.deviceService.currentDeviceType)) {
      this.loadIndexData(this.deviceService.currentDeviceType);
    }
  }

  addSubscriptions(): void {
    const getFields: Subscription = this.deviceService.getFields$.subscribe(
      () => {
        this.fields = this.deviceService.fields;
      }
    );

    const getLimits: Subscription = this.deviceService.getLimits$.subscribe(
      () => {
        this.limits = this.deviceService.limits;
      }
    );

    // this.initializeData();
    const newFormData: Subscription =
      this.overviewService.overviewForm$.subscribe((res) => {
        if (!CommonUtil.isEmpty(res)) {
          if (!isNaN(res.lte) && !isNaN(res.gte)) {
            this.lte = res.lte;
            this.gte = res.gte;
          }
          this.loadIndexData(this.deviceService.currentDeviceType);
        }
      });

    this.subscriptions.push(newFormData);
    this.subscriptions.push(getFields);
    this.subscriptions.push(getLimits);
  }

  loadIndexData(deviceType: any): void {
    if (!deviceType) {
      deviceType = this.deviceService.currentDeviceType;
    }
    if (this.deviceService?.registeredDevicesWithVibration && deviceType?.index) {
      let calculateLteForTodayDate = this.customMomentService
        .moment()
        .startOf('hour')
        .unix();
      calculateLteForTodayDate -= calculateLteForTodayDate % 1800;
      this.indeDataNotAvailable = false;
      const lte: number = this.lte ?? calculateLteForTodayDate;
      const gte: number = this.gte ?? lte - 86400;
      const avg: number = 86400;
      if (
        this.deviceService.devicesHourlyData[deviceType.key] &&
        this.deviceService.devicesHourlyData[deviceType.key].length > 0 &&
        this.deviceService.devicesHourlyDataUpdates[deviceType.key] &&
        this.deviceService.devicesHourlyDataUpdates[deviceType.key] === lte
      ) {
        this.notificationService.showNotification(
          'Data is Already Updated',
          'Close',
          'bottom',
          'right',
          'warning',
          3000
        );
        this.devices =
          this.deviceService.devicesHourlyData[
            this.deviceService.currentDeviceType.key
          ];
        this.loadAQIDetails();
      } else {
        this.loadTable.next(false);
        this.deviceService.devicesHourlyDataUpdates[deviceType.key] = lte;

        const deviceIds: Array<string> = [];

        this.deviceService.registeredDevicesWithVibration.forEach((device) => {
          if (device.deviceType === deviceType.key) {
            deviceIds.push(device.deviceId);
          }
        });

        this.deviceService
          .getIndexData(gte, lte, avg, deviceIds, deviceType)
          .subscribe((loaded) => {
            if (loaded) {
              this.devices =
                this.deviceService.devicesHourlyData[
                  this.deviceService.currentDeviceType.key
                ];
              this.loadAQIDetails();
            }
          });
      }
    } else {
      this.loadTable.next(false);
      this.devices = [];
      setTimeout(() => {
        this.loadTable.next(true);
        this.notificationService.showNotification(
          'Index not available for selected Device Type',
          'Close',
          'bottom',
          'right',
          'error',
          3000
        );
      }, 1500);
      this.indeDataNotAvailable = true;
    }
  }

  isDataEmpty() {
    return this.devices?.every((device) => device.payload.length === 0);
  }

  loadAQIDetails(): void {
    this.currentDate = this.customMomentService.moment().unix();

    this.allFieldsOfAllDevices = [];
    this.allParameters = [];

    if (!this.devices?.length) {
      this.loadTable.next(true);
      return;
    }

    if (this.isDataEmpty()) {
      this.devices = [];
      this.loadTable.next(true);
      return;
    }

    const allAqi: any = this.commonService.getAllAQI();
    const allAqis: any = this.commonService.getAllAQIs();

    let allKeysOfAllDevices = [];
    for (let device of this.devices) {
      for (let dev of device.payload) {
        for (let param in dev.payload.d) {
          allKeysOfAllDevices.push(param);
        }
      }
    }
    allKeysOfAllDevices = Array.from(new Set(allKeysOfAllDevices));
    allKeysOfAllDevices = allKeysOfAllDevices.filter(
      (param: string) => param !== 't'
    );

    this.allFieldsOfAllDevices = CommonUtil.setParameterSequence(
      allKeysOfAllDevices
        .map((key: string) => {
          let field: DeviceField = this.fields.find((f) => f?.fkey == key)!;
          return field;
        })
        .filter((field: DeviceField) => field && field.isVisible)
    );

    let devicesObj = Object.fromEntries(
      this.deviceService.registeredDevicesWithVibration!.map((d) => {
        return [d.deviceId, d];
      })
    );
    let userInfo = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const deviceTypeId = DeviceUtil.getDeviceTypeId(
      this.deviceService.currentDeviceType.key,
      deviceTypes
    );
    this.devices
      .filter((d) => devicesObj[d.deviceId])
      .forEach((device) => {
        if (Array.isArray(device.payload)) {
          device.label = devicesObj[device.deviceId].label;
          device.payload.forEach((device) => {
            if (device.payload?.d?.t) {
              device.lastUpdated = device.payload.d.t + 1;
              device.parameters = {};

              this.allFieldsOfAllDevices.forEach((key) => {
                const fieldIndex = this.allParameters.findIndex(
                  (param) => param.name === key.fkey
                );
                const field = this.fields
                  .filter((field) => field.isVisible)
                  .find((field) => field.fkey === key.fkey);

                if (
                  field?.isVisible === true &&
                  fieldIndex < 0 &&
                  key.fkey !== 't'
                ) {
                  this.allParameters.push({
                    name: key.fkey,
                    label: field.label,
                    units: field.unit,
                    color: (device: DevicePayloadDetails) => {
                      return DeviceUtil.getParamColor(
                        DeviceUtil.getLimitClass(
                          key.fkey,
                          device.payload?.[0]?.payload.d?.[key.fkey],
                          this.limits
                        ),
                        deviceTypes,
                        allAqi,
                        allAqis,
                        userInfo.aqiIndex[
                          this.deviceService.currentDeviceType.deviceTypeId
                        ].id,
                        this.deviceService.currentDeviceType.key
                      );
                    },
                  });
                }

                if (field?.isVisible === true && key.fkey !== 't') {
                  device.parameters[key.fkey] = {
                    key: key.fkey,
                    label: field.label,
                    units: field.unit,
                    color: DeviceUtil.getParamColor(
                      DeviceUtil.getLimitClass(
                        key.fkey,
                        device.payload?.d?.[key.fkey],
                        this.limits
                      ),
                      deviceTypes,
                      allAqi,
                      allAqis,
                      userInfo.aqiIndex[
                        this.deviceService.currentDeviceType.deviceTypeId
                      ].id,
                      this.deviceService.currentDeviceType.key
                    ),
                    value: device.payload.d[key.fkey],
                  };
                }
              });
            }
          });
        }
      });

    this.columns = [];
    this.displayedColumns = [];
    this.columns = [...this.defaultColumns];
    this.displayedColumns = [...this.columns.map((c) => c.columnDef)];

    this.generateCustomColumns();
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  generateCustomColumns(): void {
    this.allParameters.forEach((param) => {
      this.columns.push({
        columnDef: param.name,
        header: Boolean(param.units?.replaceAll(' ', ''))
          ? `${param.label} <br><small>${param.units}</small>`
          : `${param.label}`,
        cell: (element: DeviceDataResponse) => {
          return `${
            element.payload[0]
              ? element.payload[0]?.parameters[param.name]?.value || ''
              : ''
          }`;
        },
        selected: false,
        filter: true,
        color: param?.color,
        parameter: true,
        sortStructure: 'aqiParameters'
      });
      this.displayedColumns.push(param.name);
    });
  }
  exportTableDataToCSV(): void {
    const csvRows = [];
    // const headers = this.columns.map((field) => field.header);
    const headers = this.columns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));

    const data = this.devices?.map((device) => {
      const parameters = device.payload.reduce((acc: any, payloadItem: any) => {
        return { ...acc, ...payloadItem.parameters };
      }, {});
      return { ...device, parameters };
    });

    data?.forEach((item) => {
      const values = this.columns.map((field) => {
        const cellValue = field.cell(item);
        return `"${cellValue || ''}"`;
      });
      csvRows.push(values.join(','));
    });

    const fileName =
      this.localStorageService.getValue('u-org-name') +
      '_' +
      this.customMomentService.formatDatetime({ format: 'DD_MM_YYYY_hh_mm_A' });
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  getOverviewModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
