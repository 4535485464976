// cluster-device.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

interface LatLngLiteral {
  lat: any;
  lng: any;
}

@Pipe({
  name: 'clusterDevices',
})
export class ClusterDevicesPipe implements PipeTransform {
  transform(deviceIds: string[], all_devices: any[]): any {
    if (!all_devices || !deviceIds) {
      return [];
    }

    const cluster_devices = all_devices.filter((device) => deviceIds.includes(device.deviceId));

    return cluster_devices ?? [];
  }
}
