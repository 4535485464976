import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceType } from '../models/device-type/device-type';
import { CommonService } from '../services/common.service';
import { DeviceService } from '../services/device.service';
import { LocalStorageService } from '../services/local-storage.service';
import { DeviceUtil } from '../utils/device-utils';
@Component({
  selector: 'app-aqi-guage',
  templateUrl: './aqi-guage.component.html',
  styleUrls: ['./aqi-guage.component.scss'],
})
export class AqiGuageComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() aqi: any;
  /** aqi-index */
  @Input() aqiIndex: any;
  @Input() deviceType: any;

  thresholdConfig = {};
  color: string = '';
  message: string = '';
  range = { min: 0, max: 500 };
  deviceTypes!: Array<DeviceType>;
  currentDeviceTypeId!: number;

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.currentDeviceTypeId =
      this.deviceService.currentDeviceType.deviceTypeId;
    this.range.min =
      this.commonService.getAllUnits()[this.currentDeviceTypeId]['aqi'].l_limit;
    this.range.max =
      this.commonService.getAllUnits()[this.currentDeviceTypeId]['aqi'].h_limit;
  }
  ngAfterViewInit() {}

  ngOnChanges() {
    const devices = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );

    const all_aqis = this.commonService.getAllAQIs();
    const all_aqi = this.commonService.getAllAQI();
    if (this.deviceType) {
      let deviceTypeId: number | undefined = DeviceUtil.getDeviceTypeId(
        this.deviceType,
        devices
      );
      if (deviceTypeId != undefined) {
        this.range.min =
          this.commonService.getAllUnits()[deviceTypeId]['aqi'].l_limit;
        this.range.max =
          this.commonService.getAllUnits()[deviceTypeId]['aqi'].h_limit;
      }
    } else {
      this.range.min =
        this.commonService.getAllUnits()[
          this.deviceService.currentDeviceType.deviceTypeId
        ]['aqi'].l_limit;
      this.range.max =
        this.commonService.getAllUnits()[
          this.deviceService.currentDeviceType.deviceTypeId
        ]['aqi'].h_limit;
    }

    let j = -1;
    let colorIndex;
    this.color = '#6ecc58';
    if (this.aqi && this.aqi.aqi) {
      let color_arr = DeviceUtil.aqiColorArray(
        devices,
        all_aqi,
        all_aqis,
        this.aqiIndex,
        this.deviceType
      );
      if (this.aqi.aqi < color_arr.limits[color_arr.limits.length - 1]) {
        for (let i = 0; i < color_arr.limits.length - 1; i++) {
          if (
            color_arr.limits[i] <= this.aqi.aqi &&
            this.aqi.aqi < color_arr.limits[i + 1]
          ) {
            this.color = color_arr.color[i];
          }
        }
      } else {
        this.color = color_arr.color[color_arr.color.length - 1];
      }
    }
  }
}
