<nb-layout class="terminal-page-layout" [withScroll]="true">
  <nb-layout-header class="terminal-page-header" clipped>
    <ng-content select="app-terminal-page-header"></ng-content>
  </nb-layout-header>
  <nb-layout-column class="terminal-page-body p-0">
    <ng-content select="app-terminal-page-body"></ng-content>
  </nb-layout-column>
  <nb-layout-footer
    class="terminal-footer d-block d-lg-none"
    *ngIf="menu?.length"
  >
    <mat-button-toggle-group class="row w-100 h-100">
      <ng-container *ngFor="let page of menu">
        <mat-button-toggle
          class="col"
          [routerLink]="page?.url"
          [routerLinkActive]="'mat-button-toggle-checked'"
          [value]="page?.url"
        >
          <span>{{ page?.value }}</span>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </nb-layout-footer>
</nb-layout>
