import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { debounceTime, filter, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { WidgetService } from '../../services/widget.service';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/windbarb')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);

@Component({
  selector: 'app-wind-rose-widget',
  templateUrl: './wind-rose-widget.component.html',
  styleUrls: ['./wind-rose-widget.component.scss'],
})
export class WindRoseWidgetComponent
  implements IWidgetComponent, OnInit, OnChanges, OnDestroy
{
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this.direction = v.directionConfig?.selectedValue ?? 'from';
    this.show = v.show;
    this._widgetInfo = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  deviceInfo: DeviceDetails | undefined;

  private _windRoseData: {
    to: Record<string, any>;
    from: Record<string, any>;
  } = {
    to: {},
    from: {},
  };
  public get windRoseData(): typeof this._windRoseData {
    return this._windRoseData;
  }
  public set windRoseData(v: typeof this._windRoseData) {
    this._windRoseData.from = v.from;
    this._windRoseData.to = v.to;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  private _aqiIndexColor!: AqiIndexColorArray;
  public get aqiIndexColor(): AqiIndexColorArray {
    return this._aqiIndexColor;
  }
  public set aqiIndexColor(v: AqiIndexColorArray) {
    this._aqiIndexColor = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  private _direction: 'to' | 'from' = 'from';
  public get direction(): 'to' | 'from' {
    return this._direction;
  }
  public set direction(v: 'to' | 'from') {
    this._direction = v;
  }

  public show: boolean = false;

  highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  isWsDataEmpty: boolean = false;

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.widgetService.device;
    this.windRoseData = this.widgetService.windRoseData;
    this.aqiIndexColor = this.widgetService.aqiIndexColor;

    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'device': {
              this.deviceInfo = this.widgetService.device;
              break;
            }
            case 'windRoseData': {
              this.windRoseData = this.widgetService.windRoseData;
              break;
            }
            case 'aqiIndexColor': {
              this.aqiIndexColor = this.widgetService.aqiIndexColor;
              break;
            }
            default: {
              // console.info('wind-rose-widget -> key for changed value:', res);
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );

    this.subscriptions.push(
      this.widgetService.redrawWidget$
        .pipe(
          filter((widgetId: number) => this.widgetInfo.widgetId === widgetId),
          debounceTime(300)
        )
        .subscribe({
          next: (widgetId: number) => {
            if (this.widgetInfo.widgetId === widgetId) {
              this.show = false;
              setTimeout(() => {
                this.show = true;
              });
            }
          },
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupConfig();
  }

  private setupConfig() {
    if (this.windRoseData?.[this.direction]?.ws) {
      this.isWsDataEmpty = this.windRoseData[this.direction].ws.every(
        (w: any) => w.data.length === 0
      );
      this.setupDataPoints();
    }
  }

  private setupDataPoints() {
    if (!this.windRoseData?.[this.direction]?.ws) {
      return;
    }
    this.show = false;
    let xAxisOptions: Highcharts.XAxisOptions = {
      tickmarkPlacement: 'on',
      type: 'category',
      plotLines: [
        {
          ...AppConstants.ROSE_CHART_RESULTANT_LINE_CONFIG,
          value:
            this.windRoseData?.[this.direction]?.resultant?.ws
              ?.directionCategory ?? 0,
        },
      ],
    };
    let yAxisOptions: Highcharts.AxisOptions = {
      title: { text: null },
      labels: {
        formatter: function () {
          return this.value + '%';
        },
      },
      reversedStacks: true,
      showLastLabel: true,
      endOnTick: false,
      min: 0,
    };

    this.chartOptions = {
      title: undefined,
      subtitle: undefined,
      pane: { size: '85%' },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        y: 50,
        layout: 'vertical',
      },
      xAxis: xAxisOptions,
      yAxis: yAxisOptions,
      chart: {
        type: 'column',
        polar: true,
        reflow: true,
        animation: true,
      },
      credits: { enabled: false },
      tooltip: {
        valueSuffix: '%',
        followPointer: true,
      },
      exporting: {
        filename: `${
          this.deviceInfo?.label ? `${this.deviceInfo?.label}_` : ''
        }wind_rose_chart`,
        chartOptions: {
          title: {
            text: `${
              this.deviceInfo?.label
                ? `${this.deviceInfo?.label} device's `
                : ''
            }Wind Rose Chart`,
          },
          subtitle: {
            text:
              this.direction === 'to'
                ? `Direction blowing to`
                : `Direction blowing from`,
          },
        },
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          shadow: false,
          pointPlacement: 'on',
        },
      },
    };

    this.chartOptions.series = this.getWindRoseData(
      this?.windRoseData?.[this.direction]?.ws
    ) as Highcharts.SeriesOptionsType[];
    setTimeout(() => {
      this.show = true;
    });
  }

  getWindRoseData(ws: any) {
    if (ws) {
      for (let wd of ws) {
        if (wd) {
          wd.data = this.getWindDirectionData(wd?.data);
        }
      }
      return ws;
    }
    return [];
  }

  getWindDirectionData(wd: any) {
    let windDir = [];
    for (let row of wd) {
      if (row) {
        windDir[0] = ['N', 0];
        switch (row[0]) {
          case 'N':
            windDir[1] = row;
            break;
          case 'NNE':
            windDir[2] = row;
            break;
          case 'NE':
            windDir[3] = row;
            break;
          case 'ENE':
            windDir[4] = row;
            break;
          case 'E':
            windDir[5] = row;
            break;
          case 'ESE':
            windDir[6] = row;
            break;
          case 'SE':
            windDir[7] = row;
            break;
          case 'SSE':
            windDir[8] = row;
            break;
          case 'S':
            windDir[9] = row;
            break;
          case 'SSW':
            windDir[10] = row;
            break;
          case 'SW':
            windDir[11] = row;
            break;
          case 'WSW':
            windDir[12] = row;
            break;
          case 'W':
            windDir[13] = row;
            break;
          case 'WNW':
            windDir[14] = row;
            break;
          case 'NW':
            windDir[15] = row;
            break;
          case 'NNW':
            windDir[16] = row;
            break;
        }
      }
    }
    return windDir;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
