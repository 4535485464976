import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadrService {
  public loading: boolean;

  constructor() {
    this.loading = false;
  }

  public showLoader() {
    this.loading = true;
  }

  public removeLoader() {
    this.loading = false;
  }

  public setLoading(loading: boolean) {
    this.loading = loading;
  }
}
