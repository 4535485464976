import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertsComponent } from './alerts.component';
import { ConfigurationListComponent } from './pages/configuration-list/configuration-list.component';
import { DataAlertsComponent } from './pages/data-alerts/data-alerts.component';

const routes: Routes = [
  {
    path: '',
    component: AlertsComponent,

    children: [
      {
        path: '',
        redirectTo: 'data-alerts',
        pathMatch: 'full',
      },
      {
        path: 'data-alerts',
        component: DataAlertsComponent,
      },
      {
        path: 'alert-list',
        component: ConfigurationListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlertsRoutingModule {}
