import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { sortList } from 'src/app/shared/utils/array-utils';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  loadTable: Subject<boolean> = new BehaviorSubject(false);
  deviceData: DeviceDetails[] = [];

  average!: AverageHour | null;
  startDate!: Moment | null;
  endDate!: Moment | null;

  columns: any[] = [];
  displayedColumns: string[] = [];
  defaultColumns: any[] = [];

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };

  private userTimeFormat: number;

  private averageHours: AverageHour[] = CommonUtil.getAverageHours({
    valueInSeconds: true,
    includeMovingAvg: true,
    includeRaw: true,
  });

  subscriptions: Subscription[] = [];

  constructor(
    private dashboardService: DashboardService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private _cdr: ChangeDetectorRef
  ) {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;

    this.defaultColumns = this.generateDefaultColumnsConfig();
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.defaultColumns = this.generateDefaultColumnsConfig();

    this.subscriptions.push(
      this.dashboardService.tableFiltersUpdated$.subscribe({
        next: ({ startDate, endDate, average }: any) => {
          this.startDate = startDate.moment;
          this.endDate = endDate.moment;
          this.average = average;
          this.setCurrentDevice();
        },
      })
    );

    this.endDate =
      this.dashboardService.tableFilters?.endDate?.moment ??
      this.customMomentService.moment().startOf('hour');
    this.startDate =
      this.dashboardService.tableFilters?.startDate?.moment ??
      this.endDate?.clone();
    if (!this.dashboardService.tableFilters?.startDate?.moment) {
      this.startDate?.subtract(1, 'day').startOf('day');
    }
    let average = this.averageHours.find(
      (averageHour: AverageHour) =>
        !averageHour.isMoving &&
        averageHour.value === this.dashboardService.tableFilters?.average?.value
    );
    if (!average) {
      average = this.averageHours.find(
        (averageHour: AverageHour) =>
          !averageHour.isMoving && averageHour.value === 3600
      );
    }
    if (average) {
      this.average = average;
    }
    this.setCurrentDevice();
  }

  setCurrentDevice() {
    if (this.deviceService.currentDevice) {
      this._cdr.detectChanges();

      setTimeout(() => {
        this.setupData();
      });
    }
  }

  private generateDefaultColumnsConfig() {
    return [
      {
        columnDef: 'time',
        header: 'Time',
        color: (element: DeviceDetails) => `${'inherit'}`,
        cell: (element: DeviceDetails) =>
          `${
            element?.payload?.d?.t
              ? this.customMomentService.formatDatetime({
                  epoch: element?.payload?.d?.t,
                  format:
                    this.userTimeFormat === 12
                      ? 'hh:mm A, DD/MM/YY'
                      : 'HH:mm, DD/MM/YY',
                })
              : '-'
          }`,
        parameter: false,
        selected: false,
        filter: false,
      },
    ];
  }

  setupData() {
    if (
      this.deviceService.currentDevice &&
      this.startDate &&
      this.endDate &&
      this.average
    ) {
      const deviceTypes: DeviceType[] = this.commonService.getAllDeviceTypes();
      const allAqi: any = this.commonService.getAllAQI();
      const allAqis: any = this.commonService.getAllAQIs();
      this.loadTable.next(false);
      this.subscriptions.push(
        this.dashboardService
          .fetchTableData({
            device: this.deviceService.currentDevice,
            startDate: {
              moment: this.startDate,
            },
            endDate: {
              moment: this.endDate,
            },
            average: this.average,
          })
          .subscribe({
            next: (deviceDetails: DeviceDetails[]) => {
              this.deviceData = deviceDetails || [];
              // Sorts the data in descending order based on time
              deviceDetails = sortList(deviceDetails, 'DESC', 'payload.d.t');

              if (this.deviceData.length) {
                let allKeys = [];
                let deviceFields: any[] = [];
                this.deviceData.forEach((device) => {
                  deviceFields.push(...Object.keys(device?.payload?.d));
                });
                let deviceFieldsArray = [...new Set(deviceFields)];
                for (let df of deviceFieldsArray) {
                  let field: DeviceField = this.deviceService.fields.find(
                    (f) => f?.fkey == df
                  )!;
                  if (field && field.fkey !== 't') {
                    allKeys.push(field!);
                  }
                }
                allKeys = CommonUtil.setParameterSequence(allKeys);
                this.columns = [];
                this.displayedColumns = [];
                this.columns = [...this.defaultColumns];
                this.displayedColumns = [
                  ...this.columns.map((c) => c.columnDef),
                ];

                for (let field of allKeys) {
                  if (!field.isVisible) continue;
                  this.columns.push({
                    columnDef: field.fkey,
                    header: Boolean(field?.unit?.replaceAll(' ', ''))
                      ? `${field?.label} <br><small>${field?.unit}</small>`
                      : `${field?.label}`,
                    cell: (element: DeviceDetails) =>
                      `${element.payload.d[field.fkey]}`,
                    color: (element: DeviceDetails) =>
                      `${DeviceUtil.getParamColor(
                        DeviceUtil.getLimitClass(
                          field.fkey,
                          element.payload.d[field.fkey],
                          this.deviceService.limits
                        ),
                        deviceTypes,
                        allAqi,
                        allAqis,
                        undefined,
                        this.deviceService.currentDevice?.deviceType
                      )}`,
                    parameter: true,
                    selected: false,
                    filter: true,
                  });
                  this.displayedColumns.push(field.fkey);
                }
              }
              this.loadTable.next(true);
            },
            error: (err) => {
              this.loadTable.next(true);
            },
          })
      );
    } else {
    }
  }

  exportTableDataToCSV(): void {
    if (!this.deviceData || !this.deviceData.length) {
      console.info('No data available to export');
      return;
    }
    const csvRows = [];
    const headers = this.columns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));

    this.deviceData.forEach((device) => {
      const values = this.columns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;
        if (columnKey === 'time') {
          cellValue = device?.payload?.d?.t
            ? this.customMomentService.formatDatetime({
                epoch: device.payload.d.t,
                format:
                  this.userTimeFormat === 12
                    ? 'hh:mm A, DD/MM/YY'
                    : 'HH:mm, DD/MM/YY',
              })
            : '-';
        } else {
          cellValue = device.payload.d[columnKey];
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });
      csvRows.push(values.join(','));
    });

    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}.csv`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
