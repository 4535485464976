import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NbThemeService } from '@nebular/theme';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import * as turf from '@turf/turf';
import {
  EMPTY,
  Subject,
  Subscription,
  delay,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { Heatmap } from 'src/app/shared/models/heatmap/heatmap';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { HeatmapService } from 'src/app/shared/services/heatmap.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { environment } from 'src/environments/environment';

import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

type LatLngLiteral = google.maps.LatLngLiteral;

@Component({
  selector: 'app-heatmap-form',
  templateUrl: './heatmap-form.component.html',
  styleUrls: ['./heatmap-form.component.scss'],
})
export class HeatmapFormComponent {
  @ViewChild(GoogleMap) public googleMap!: GoogleMap;

  @ViewChildren('deviceInfoWindows2')
  public deviceInfoWindows2!: QueryList<MapInfoWindow>;

  @ViewChildren(MapMarker) public mapMarkers!: QueryList<MapMarker>;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

  public heatmapForm!: FormGroup;
  public mapDetailForm!: FormGroup;
  public deviceTypes!: Array<DeviceType>;

  public currentDeviceType: string = '';
  public isSelected: boolean = true;

  public parameters: Array<any> = [];

  public parametersList: Array<number> = [1];
  public isEmailValid = true;
  public isNew: boolean = true;
  mapInitialized: boolean = false;
  iconSize: any = null;
  anchor: any = null;
  selectedIconSize: any = null;
  selectedAnchor: any = null;
  fields: DeviceField[] = [];
  fileError: string | null = null;
  selectedFileName: string | null = null;
  get fieldsObj() {
    return Object.fromEntries(this.fields.map((field) => [field.fkey, field]));
  }

  limits: FieldLimit[] = [];
  get limitsObj() {
    return Object.fromEntries(this.limits.map((field) => [field.fkey, field]));
  }
  CronJob = require('cron').CronJob;
  currentTimeStamp: number = this.customMomentService.moment().unix();
  rawAqiLabel: string = AppConstants.RAW_AQI_LABEL;
  ozMapPinColor: string = AppConstants.DEFAULT_APP_COLOR;
  showAqiGauge: boolean = AppConstants.SHOW_RAW_DATA_AQI;
  isMapPinFixed: boolean = false;
  selectedDevice?: DeviceDetails;
  isComplainModuleIncluded: boolean = false;
  isDeviceCentered: boolean = false;
  doc: any;
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;
  temperature?: number;
  humidity?: number;
  windSpeed?: number;
  batteryStatus?: number;
  noiseVal: number[] = [0, 0, 0, 0, 0];
  gasLevels: any[] = [];
  @Input() ariaLabel: any;
  paramColor: any = {};
  subscriptions: Subscription[] = [];
  devices?: DeviceDetails[];
  openedForm: any = '';
  geojson: any = {};
  selectedAqi: any;
  selectedDevices!: any;
  filteredSelectedDevices!: any;
  heatmapLabel: string = '';
  heatmapDescription: string = '';
  selectedDevicesLabels: any = [];
  checkboxValue: any;
  allDevices: any[] = [];
  ismapReady: any;
  isMapLoaded: boolean = false;
  markers: Array<GoogleMapsService> = [];
  infoWindowPosition: LatLngLiteral = { lat: 23, lng: 72 };
  zoomGoogleMap: any = 4;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 0, lng: 0 },
    zoom: 10,
  };
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  allAqiList: any;
  circle: any = { lat: null, lng: null, radius: 0 };
  latLngNumber: LatLngLiteral = { lat: 0, lng: 0 };
  drawnPolygon: google.maps.Polygon | null = null;
  radiusNumber: any = 0;
  aqi: any;
  submitBtn: string = 'Submit';
  backBtn: string = 'Cancel';
  selectedDeviceTypeId!: any;
  selectedDevicesControl: any;
  map: any;
  isNextStep: boolean = false;
  selectedIndex: number = -1;
  isStepReadOnly: boolean = true;
  errorMsgForSelectedDev: boolean = false;
  heatmapDeviceTypes: any;
  filteredAllDevType: any;
  allAqisList: any;
  filteredAllAqisList: any;
  ozAllDevType: any;
  filteredOZAllDevType: any;
  AqiKeys: any;
  isEdit: any;
  private destroy$: Subject<void> = new Subject<void>();
  public circleOptions: google.maps.CircleOptions = {};
  public polygonOptions: google.maps.PolygonOptions = {};
  public currentTheme: string = '';

  constructor(
    private formsService: FormsService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    public googleMapsService: GoogleMapsService,
    private localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
    private themeService: NbThemeService,
    private heatmapService: HeatmapService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    @Inject(MTX_DRAWER_DATA) public data: any
  ) {}

  ngOnInit() {
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.isEdit = !!(this.data && this.data.heatmapId?.length);
    this.currentTimeStamp = this.customMomentService.moment().unix();
    this.buildForm();
    this.mapDetailForm.valueChanges.subscribe(() => {
      this.isNextStep =
        this.mapDetailForm.valid &&
        this.mapDetailForm.value.selectedDevicesControl;
    });

    this.devices = this.deviceService.registeredDevices
      ? [...this.deviceService.registeredDevices!]
      : [];
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;
    this.allAqiList = this.commonService.getAllAQI();
    this.allAqisList = this.commonService.getAllAQIs();
    this.ozAllDevType = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    this.filteredOZAllDevType = this.ozAllDevType.reduce(
      (item: any, value: any) => {
        item[value.deviceTypeId] = {
          deviceTypeId: value.deviceTypeId,
          calibration_config: value.calibration_config,
          config: value.config,
          description: value.description,
          icon: value.icon,
          index: value.index,
          indexLabel: value.indexLabel,
          key: value.key,
          label: value.label,
          updated_on: value.updated_on,
          widgets: value.widgets,
        };
        return item;
      },
      {}
    );
    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() =>
          this.googleMap?.idle ? of(this.googleMap?.idle) : EMPTY
        ),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as any as Map)),
        switchMap(() =>
          this.googleMap!.zoomChanged ? of(this.googleMap!.zoomChanged) : EMPTY
        )
      )
      .subscribe(() => {});
  }

  ngAfterViewInit(): void {
    this.addSubscriptions();
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName == 'material-dark') {
          this.currentTheme = themeName;
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
          this.circleOptions =
            AppConstants.DARK_MODE_GOOGLE_MAPS_CIRCLE_OPTIONS;
          this.polygonOptions =
            AppConstants.DARK_MODE_GOOGLE_MAPS_POLYGON_OPTIONS;
        } else {
          this.currentTheme = themeName;
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
          this.circleOptions = {}; //pass empty so that it take default values
          this.polygonOptions = {};
        }
        this.options = { ...options };
        if (this.selectedDevice) {
          setTimeout(() => {
            this.focusSelectedDevice();
          });
        } else if (firstTimeLoaded) {
          setTimeout(() => {
            this.fitBounds(this.googleMap.googleMap!);
          });
        }
        firstTimeLoaded = true;
      });
  }

  onFileSelection(e: any): void {
    this.fileError = null; // Reset any previous errors
    const file = e.target.files[0] as File;
    if (!file) return;
    // Check file extension
    if (!file.name.endsWith('.geojson') && !file.name.endsWith('.json') && !file.name.endsWith('.GeoJSON') && !file.name.endsWith('.Geojson')) {
      // if (!file.name.endsWith('.geojson')) {
      this.fileError = 'Only GeoJSON files are allowed.';
      // this.mapDetailForm.value.geojsonFileInput = null;
      return;
    }

    const reader = new FileReader();
    const that = this;

    reader.onload = function (event) {
      try {
        const geojson = JSON.parse(event.target?.result?.toString() ?? '{}');
        if (CommonUtil.isSinglePolygon(geojson)) {
          // that.mapDetailForm.value.geojsonFileInput = file;/
          that.selectedFileName = file.name;
          if (that.googleMap?.googleMap) {
            that.initDrawingManager(
              that.googleMap.googleMap,
              CommonUtil.getBoundaryCoordinates(geojson)
            );
          }
        } else {
          that.fileError = 'The GeoJSON file must contain exactly one polygon.';
          // that.mapDetailForm.value.geojsonFileInput = null;
        }
      } catch (error) {
        that.fileError = 'Invalid GeoJSON file.';
        // that.mapDetailForm.value.geojsonFileInput = null;
      }
    };
    reader.readAsText(file); // Read file as text
  }

  buildForm() {
    this.mapDetailForm = this.formBuilder.group({
      selectedDevicesControl: [
        this.isEdit ? this.data.devices : [],
        [Validators.required, Validators.minLength(5)],
      ],
      heatmapName: [this.isEdit ? this.data.label : '', Validators.required],
      deviceOption: [
        this.isEdit ? (this.data?.config?.geojson ? '2' : '1') : '',
        Validators.required,
      ],
      // geojsonFileInput: [null],
      deviceType: [
        this.isEdit
          ? DeviceUtil.getDeviceTypeIdByDeviceId(
              this.deviceTypes,
              this.deviceService.registeredDevicesWithVibration ?? [],
              this.data.devices[0]
            )
          : '',
        Validators.required,
      ],
      // parameters: [
      //   this.isEdit ? Object.keys(this.data.keyColorLimits) : [],
      //   [Validators.required],
      // ],
    });
  }

  addParameterField() {
    this.parametersList.push(1);
  }

  removeParameterField(index: any) {
    const halfBeforeTheUnwantedElement = this.parametersList.slice(0, index);
    const halfAfterTheUnwantedElement = this.parametersList.slice(index + 1);
    const copyWithoutUnwantedElement = halfBeforeTheUnwantedElement.concat(
      halfAfterTheUnwantedElement
    );
    // Update the original array with the modified one
    this.parametersList = copyWithoutUnwantedElement;
  }

  closeForm(data: boolean = false) {
    this.formsService.closeForm(data);
  }

  initializeData(): void {
    this.initJobs();
    this.latestDocumentSnapshot();
    this.cd.detectChanges();
  }

  initJobs(): void {
    const device_Types: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.commonService.getAllAQI();
    const allAqis: any = this.commonService.getAllAQIs();
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );

    let that = this;
    if (this.devices) {
      new this.CronJob(
        '0/5 * * * * *',
        () => {
          that.currentTimeStamp = this.customMomentService.moment().unix();
          that.devices?.forEach((device) => {
            const deviceType = DeviceUtil.findDeviceType(
              device.deviceType,
              that.commonService.getUserDeviceTypes()
            );
          });
        },
        null,
        true
      );
    }
  }

  mapReady(map: Map): void {
    this.mapInitialized = true;

    this.initializeData();
    if (this.devices && this.devices.length > 0) {
      this.fitBounds(map);
    }
    this.setSelectedDevice();

    this.iconSize = new google.maps.Size(
      DeviceConstants.MAP_MARKER_SIZE_CLUSTER.width,
      DeviceConstants.MAP_MARKER_SIZE_CLUSTER.height
    );
    this.anchor = new google.maps.Point(
      DeviceConstants.MAP_MARKER_ANCHOR.x,
      DeviceConstants.MAP_MARKER_ANCHOR.y
    );

    this.selectedIconSize = new google.maps.Size(
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.width,
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.height
    );
    this.selectedAnchor = new google.maps.Point(
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.x,
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.y
    );

    if (this.isEdit) {
      this.onDeviceTypeChange(this.data.deviceTypeId);
    }
    this.initDrawingManager(map);
  }

  startDrawingPolygon() {
    if (!this.drawingManager) {
      console.info('Drawing manager not available');
      return;
    }
    this.resetForm('clicked');
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  }

  stopDrawing() {
    if (this.drawingManager) {
      this.drawingManager.setDrawingMode(null);
    }
  }

  initDrawingManager(map: google.maps.Map | undefined, coordinates?: any) {
    if (!map) {
      console.info('Map instance not available');
      return;
    }

    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }

    const options = {
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        draggable: false,
        editable: false,
        ...this.polygonOptions,
      },
      drawingMode: coordinates?.length
        ? null
        : google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    if (coordinates?.length) {
      this.drawnPolygon = new google.maps.Polygon({
        paths: coordinates,
        map,
        editable: false,
        draggable: false,
        ...this.polygonOptions,
      });

      google.maps.event.addListener(
        this.drawnPolygon.getPath(),
        'set_at',
        () => {
          this.handlePolygonEdit();
        }
      );
      google.maps.event.addListener(
        this.drawnPolygon.getPath(),
        'insert_at',
        () => {
          this.handlePolygonEdit();
        }
      );
      const coordinatesArray = this.drawnPolygon
        .getPath()
        .getArray()
        .map((latLng: google.maps.LatLng) => [latLng.lng(), latLng.lat()]);
      if (this.allDevices?.length) {
        this.checkAllDevices(coordinatesArray);
      }
    }

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: any) => {
        this.drawnPolygon = event.overlay;
        this.drawingManager?.setOptions({ drawingControl: false });
        this.drawingManager?.setDrawingMode(null);
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const coordinates = event.overlay
            .getPath()
            .getArray()
            .map((latLng: google.maps.LatLng) => {
              return [latLng.lng(), latLng.lat()];
            });
          this.checkAllDevices(coordinates);
        }
      }
    );

    const drawingControlDiv = document.getElementById('drawing-control');
    if (
      drawingControlDiv &&
      !map.controls[google.maps.ControlPosition.TOP_LEFT]
        .getArray()
        .some((c: any) => c.id === 'drawing-control')
    ) {
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(
        drawingControlDiv
      );
    }
  }

  handlePolygonEdit() {
    const updatedCoordinates = this.drawnPolygon
      ?.getPath()
      ?.getArray()
      ?.map((latLng: google.maps.LatLng) => [latLng.lng(), latLng.lat()]);
    this.checkAllDevices(updatedCoordinates);
  }

  onMapOptionsUpdated(options: MapOptions) {
    this.options = { ...options };
  }

  checkAllDevices(ltLg: any) {
    let latLng = [];
    latLng = ltLg.map((crd: any) => [crd[0], crd[1]]);
    latLng.push(latLng[0]);
    let poly: turf.Feature<turf.Polygon> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [latLng],
      },
    };
    let allDevs = [];
    for (let device of this.allDevices) {
      let pnt = turf.point([device.longitude, device.latitude]);
      let isInside = turf.inside(pnt, poly);
      if (isInside === true) {
        allDevs.push(device.deviceId);
      }
    }

    if (allDevs.length > 0) {
      this.addDevice(allDevs);
      this.geojson = poly;
    } else {
      console.log('No Device found in region.');

      this.notificationService.showNotification(
        'No Device found in region.',
        'Close',
        'bottom',
        'center',
        'warning',
        3000
      );
    }
  }

  fitBounds(map: Map, deviceType: string | undefined = undefined): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers =
      this.devices
        ?.filter((device) =>
          deviceType ? device.deviceType === deviceType : true
        )!
        .map((device) => {
          if (
            device.latitude &&
            device.longitude &&
            device.latitude !== 0 &&
            device.longitude !== 0
          ) {
            return {
              lat: device.latitude,
              lng: device.longitude,
            };
          } else {
            return {
              lat: device.latitude,
              lng: device.longitude,
            };
          }
        }) ?? [];
    markers.forEach((marker) => {
      latLngBounds.extend(marker);
    });
    map.fitBounds(latLngBounds);
  }

  addSubscriptions(): void {
    const localStorageDeviceUpdate: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe((res) => {
        if (this.deviceService.currentDevice) {
          if (
            this.selectedDevices?.deviceId !==
            this.deviceService.currentDevice?.deviceId
          ) {
            this.setSelectedDevice();
          }
        } else {
          this.selectedDevices = undefined;
          this.latestDocumentSnapshot();
          this.closeInfoWindows();
          this.fitBounds(this.googleMap.googleMap!);
        }
      });
    const captureLatestDocSnapshot: Subscription =
      this.deviceService.mqttDocsSnapshot$.subscribe((doc: any) => {
        if (this.deviceService.mqttDocs) {
          this.latestDocumentSnapshot(doc.deviceId);
        }
      });

    const getDevices: Subscription = this.deviceService.getDevices$.subscribe(
      () => {
        this.devices = [...this.deviceService.registeredDevices!];
        if (
          this.deviceService.mqttDocs &&
          this.devices &&
          this.devices.length !== 0 &&
          this.fields
        ) {
          this.latestDocumentSnapshot();
          this.fitBounds(this.googleMap.googleMap!);
        }
      }
    );

    const getFields: Subscription = this.deviceService.getFields$.subscribe(
      () => {
        this.fields = this.deviceService.fields;
      }
    );

    const getLimits: Subscription = this.deviceService.getLimits$.subscribe(
      () => {
        this.limits = this.deviceService.limits;
      }
    );

    this.subscriptions.push(getDevices);
    this.subscriptions.push(getFields);
    this.subscriptions.push(getLimits);
    this.subscriptions.push(captureLatestDocSnapshot);
    this.subscriptions.push(localStorageDeviceUpdate);
  }

  setSelectedDevice() {
    this.selectedDevices = this.deviceService.currentDevice || undefined;
    if (
      this.selectedDevices?.deviceId &&
      this.deviceService.mqttDocs &&
      this.devices
    ) {
      this.latestDocumentSnapshot();
      this.focusSelectedDevice();
    } else {
      this.closeInfoWindows();
    }
  }

  latestDocumentSnapshot(deviceId?: string): void {
    if (
      this.deviceService.mqttDocs &&
      this.devices &&
      this.devices.length > 0 &&
      this.fields
    ) {
      this.isDeviceCentered = false;
      this.doc = this.selectedDevices?.deviceId
        ? this.deviceService.mqttDocs[this.selectedDevices?.deviceId]
        : undefined;
      if (this.doc !== undefined && this.doc.latitude && this.doc.longitude) {
        const latLngBounds = new google.maps.LatLng(
          parseFloat(this.doc.latitude),
          parseFloat(this.doc.longitude)
        );
        if (this.mapInitialized) {
          this.googleMap.center = latLngBounds;
        }
      }

      this.closeInfoWindows();
      const parameters: any[] = [];
      const filteredArray = [];

      if (deviceId) {
        this.setUpMapData(
          this.devices.find((device) => device.deviceId === deviceId)!,
          this.selectedDevices?.deviceId == deviceId
        );
      } else {
        this.devices.forEach((device) =>
          this.setUpMapData(
            device,
            this.selectedDevices?.deviceId == device?.deviceId
          )
        );
      }

      if (this.doc !== undefined) {
        const device_Types: DeviceType[] =
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_DEV_TYPE
          );
        if (this.doc.payload && this.doc.payload.d) {
          this.temperature = this.doc.payload.d.temp
            ? Math.round(this.doc.payload.d.temp)
            : undefined;
          this.humidity =
            this.doc.payload.d.hum || this.doc.payload.d.hum >= 0
              ? Math.round(this.doc.payload.d.hum)
              : undefined;
          this.windSpeed =
            this.doc.payload.d.ws || this.doc.payload.d.ws >= 0
              ? Math.round(this.doc.payload.d.ws)
              : undefined;
          this.batteryStatus =
            this.doc.payload.d.bs || this.doc.payload.d.bs >= 0
              ? Math.round(this.doc.payload.d.bs)
              : undefined;
          this.noiseVal = this.doc.payload.d.noise
            ? this.doc.payload.d.noise
            : this.doc.payload.d.leq
            ? [
                this.doc.payload.d.leq,
                this.doc.payload.d.lmax,
                this.doc.payload.d.lmin,
                this.doc.payload.d.l90,
                this.doc.payload.d.l10,
              ]
            : undefined;
          const allKeys = Object.keys(this.doc.payload.d);
          const allUnits: any = this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_UNITS
          );

          DeviceConstants.MAP_FIELD_ARRAY.forEach((field) => {
            const fieldIndex = parameters.findIndex(
              (param) => param.name === field
            );
            const isValidField = allKeys.find((key) => key === field);
            const availableField = this.fields
              .filter((field) => field.isVisible)
              .find((f) => f.fkey === field);

            if (
              availableField?.isVisible === true &&
              isValidField &&
              fieldIndex < 0
            ) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                device_Types
              );

              parameters.push({
                name: field,
                value: this.doc.payload.d[field],
                label: DeviceUtil.getFieldName(field, this.fields),
                unit: DeviceUtil.getFieldUnit(
                  field,
                  undefined,
                  allUnits[deviceTypeId!]
                ),
              });
            }
          });

          allKeys.forEach((key) => {
            const fieldIndex = parameters.findIndex(
              (param) => param.name === key
            );
            const field = this.fields
              .filter((field) => field.isVisible)
              .find((field) => field.fkey);

            if (field?.isVisible === true && fieldIndex < 0) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                device_Types
              );
              parameters.push({
                name: key,
                value: this.doc.payload.d[key],
                label: DeviceUtil.getFieldName(key, this.fields),
                unit: DeviceUtil.getFieldUnit(
                  key,
                  undefined,
                  allUnits[deviceTypeId!]
                ),
              });
              filteredArray.push(key);
            }
          });
        }

        this.gasLevels = [...new Set(parameters)];
        this.paramColor = {};

        const allAqi: any = this.commonService.getAllAQI();
        const allAqis: any = this.commonService.getAllAQIs();

        this.gasLevels.forEach((gasLevel) => {
          this.paramColor[gasLevel.name] = DeviceUtil.getParamColor(
            DeviceUtil.getLimitClass(
              gasLevel.name,
              gasLevel.value,
              this.deviceService.limits
            ),
            device_Types,
            allAqi,
            allAqis
          );
        });
      }
    }
  }

  focusSelectedDevice() {
    let map: Map = this.googleMap?.googleMap!;
    if (map) {
      const latLngBounds = new google.maps.LatLngBounds();
      const markers = [this.selectedDevices!].map((device) => {
        if (
          device.latitude &&
          device.longitude &&
          device.latitude !== 0 &&
          device.longitude !== 0
        ) {
          return {
            lat: device.latitude,
            lng: device.longitude,
          };
        } else {
          return {
            lat: device.latitude,
            lng: device.longitude,
          };
        }
      });

      markers.forEach((marker) => {
        latLngBounds.extend(marker);
      });

      map.fitBounds(latLngBounds);
      map.setZoom(12);
    }
  }

  closeInfoWindows(): void {
    this.deviceInfoWindows2.toArray().forEach((iw) => iw.close());
  }

  setUpMapData(device: DeviceDetails, pinSelected: boolean = false) {
    if (this.deviceService.mqttDocs[device?.deviceId]) {
      const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
      const allAqi: any = this.commonService.getAllAQI();
      const allAqis: any = this.commonService.getAllAQIs();
      const aqi: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_AQI
      );
      device.aqi = this.deviceService.mqttDocs[device?.deviceId].aqi;
      device.payload = this.deviceService.mqttDocs[device?.deviceId].payload;
      device.lastUpdated = this.deviceService.mqttDocs[device?.deviceId].time;

      const deviceType = DeviceUtil.findDeviceType(
        device.deviceType,
        this.commonService.getUserDeviceTypes()
      );
      DeviceUtil.getColorForPinCluster(
        device,
        deviceType,
        false,
        this.deviceService.mqttDocs,
        this.currentTimeStamp,
        false,
        this.ozMapPinColor,
        this.isMapPinFixed,
        deviceTypes,
        allAqi,
        allAqis,
        aqi,
        pinSelected
      );
    }
  }

  formTypeChange(value: any) {
    this.openedForm = '';
    this.openedForm = value;

    if (this.openedForm == '2') {
      this.resetForm('');
      // if (!this.isEdit) {
      this.notificationService.showNotification(
        'Start Drawing on Map.',
        'Close',
        'bottom',
        'center',
        'info',
        3000
      );
      // }
      this.initDrawingManager(
        this.googleMap?.googleMap,
        this.data?.boundary ?? []
      );
      setTimeout(() => {
        if (this.isEdit) {
          this.stopDrawing();
        }
      });

      if (this.allDevices?.length && this.data?.boundary) {
        this.checkAllDevices(
          this.data?.boundary?.map((b: any) => [b.lng, b.lat])
        );
      }
    } else if (this.openedForm == '1') {
      this.resetForm('');
      this.notificationService.showNotification(
        'Upload GeoJson file.',
        'Close',
        'bottom',
        'center',
        'info',
        3000
      );
      this.drawingManager?.setOptions({
        drawingControl: false,
        drawingMode: null,
      });
      if (this.drawnPolygon) {
        this.drawnPolygon.setMap(null);
        this.drawnPolygon = null;
      }
    }

    if (this.googleMap?.googleMap) {
      this.fitBounds(
        this.googleMap?.googleMap,
        DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
          this.deviceTypes,
          this.mapDetailForm?.get('deviceType')?.value
        )
      );
    }
  }

  resetForm(value: string) {
    this.geojson = {};
    this.selectedDevices = [];
    this.selectedDevicesLabels = [];
    this.circle = { lat: null, lng: null, radius: null };
    this.latLngNumber = { lat: 0, lng: 0 };
    if (this.drawnPolygon) {
      this.drawnPolygon.setMap(null);
      this.drawnPolygon = null;
    }
    if (value == 'clicked') {
      if (this.openedForm == 2) {
        this.notificationService.showNotification(
          'Start Drawing on Map.',
          'Close',
          'bottom',
          'center',
          'info',
          3000
        );
        this.initDrawingManager(this.googleMap?.googleMap);
      }
    }
    this.addDevice(this.selectedDevices);
  }

  getDevices(deviceTypeId: any) {
    const device_Types: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    this.allDevices = [];
    if (deviceTypeId) {
      this.allDevices =
        this.devices?.filter(
          (device) =>
            DeviceUtil.getDeviceTypeId(device.deviceType, this.deviceTypes) ===
            deviceTypeId
        ) ?? [];
    } else {
      this.allDevices = JSON.parse(JSON.stringify(this.devices));
    }
    for (let device of this.allDevices) {
      device.iconUrl = '/assets/images/pins/3rd_party_device_pin_modal.svg';
      device.labelOptions = {};
      device.labelOptions.color = '#000000';
      device.labelOptions.fontFamily = 'monospace';
      device.labelOptions.fontSize = '25px';
      device.labelOptions.fontWeight = 'bolder';
      device.labelOptions.text = ' ';
    }
    this.allDevices.forEach((device) =>
      this.setUpMapData(
        device,
        this.selectedDevices?.deviceId == device?.deviceId
      )
    );
  }

  addDeviceToHeatmap(deviceId: any) {
    let filteredDevice = `['${deviceId}']`;
    if (this.selectedDevices.includes(deviceId)) {
      let ind = this.selectedDevices.indexOf(filteredDevice as never);
      this.selectedDevices.splice(ind, 1);
    } else {
      this.selectedDevices.push(deviceId as never);
      this.addDevice(this.selectedDevices);
    }
  }

  markerOptions(device: any): google.maps.MarkerOptions {
    let filteredDeviceID = `['${device.deviceId}']`;
    return {
      zIndex: this.selectedDevices == filteredDeviceID ? 0 : 1,
      icon: {
        url: device.iconUrl,
        anchor:
          this.selectedDevices &&
          this.selectedDevices.deviceId === device.deviceId
            ? this.selectedAnchor
            : this.anchor,
        size:
          this.selectedDevices &&
          this.selectedDevices.deviceId === device.deviceId
            ? this.selectedIconSize
            : this.iconSize,
      },
    };
  }

  openInfoWindow(
    marker: MapMarker,
    index: number,
    type: string,
    device?: DeviceDetails,
    selectDevice: boolean = true
  ): void {
    this.closeInfoWindows();
  }
  openInfo(marker: MapMarker, index: number) {
    this.deviceInfoWindows2.forEach((infoWindow: MapInfoWindow) => {
      infoWindow.close();
    });

    this.deviceInfoWindows2.get(index)?.open(marker);
  }

  removeDeviceFromHeatmap(removeDeviceId: any) {
    const index = this.selectedDevices.indexOf(removeDeviceId);
    this.selectedDevices.splice(index, 1);
    if (index !== -1) {
      if (this.selectedDevices.length == 0) {
        this.selectedDevices = [];
        this.selectedDevicesLabels = [];
        this.openedForm = '';
        this.geojson = {};
        this.circle = { lat: null, lng: null, radius: null };
        this.latLngNumber = { lat: 0, lng: 0 };
        if (this.drawnPolygon) {
          this.drawnPolygon.setMap(null);
          this.drawnPolygon = null;
        }
      }
      this.addDevice(this.selectedDevices);
    }
  }

  addDevice(value: any) {
    this.selectedDevices = [];
    let d: any = [];
    let turfPoint: any = [];
    this.selectedDevices = value.length >= 0 ? value : value.value;
    if (this.selectedDevices?.length < 5) {
      this.errorMsgForSelectedDev = true;
    } else {
      this.errorMsgForSelectedDev = false;
    }
    const selectedDevicesControl = this.mapDetailForm.get(
      'selectedDevicesControl'
    );
    if (selectedDevicesControl) {
      selectedDevicesControl.setValue(this.selectedDevices);
    }
    let limitsObj = this.limitsObj;
    // get parameters from selected devices
    this.parameters = Array.from(
      (this.selectedDevices as DeviceDetails[])
        .map((device: any) => {
          return this.allDevices.find((dev: any) => dev.deviceId === device);
        })
        .map((device: any) => {
          return device?.payload?.d ? Object.keys(device.payload.d) : [];
        })
        .reduce((acc: Set<string>, keys: string[]): Set<string> => {
          keys.forEach((key) => {
            acc.add(key);
          });

          return acc;
        }, new Set<string>())
    ).filter(
      (param) =>
        !['t', 'temp'].includes(param) && limitsObj[param]?.range?.length > 0
    );
    this.allDevices.forEach((device: any) => {
      if (this.selectedDevices.includes(device.deviceId as never)) {
        device.labelOptions = {};
        device.labelOptions.color =
          this.currentTheme === 'material-dark' ? '#ffffff' : '#000000';
        device.labelOptions.fontFamily = 'monospace';
        device.labelOptions.fontSize = '25px';
        device.labelOptions.fontWeight = 'bolder';
        device.labelOptions.text = '✔';
        d.push({ label: device.label, deviceId: device.deviceId, device });
        turfPoint.push(turf.point([device.latitude, device.longitude]));
      } else {
        device.labelOptions = {};
        device.labelOptions.color = '#000000';
        device.labelOptions.fontFamily = 'monospace';
        device.labelOptions.fontSize = '25px';
        device.labelOptions.fontWeight = 'bolder';
        device.labelOptions.text = ' ';
      }
    });
    this.selectedDevicesLabels = d;
    this.selectedDevicesControl = this.selectedDevicesLabels.deviceId;

    if (turfPoint.length > 0 && this.selectedDevices.length > 4) {
      let features = turf.featureCollection(turfPoint);
      let cent = turf.center(features);
      this.circle.lat = cent.geometry.coordinates[0];
      this.circle.lng = cent.geometry.coordinates[1];
      let radius: any = [];
      this.allDevices.forEach((device: any) => {
        if (this.selectedDevices.indexOf(device.deviceId as never) > -1) {
          let from = turf.point([this.circle.lng, this.circle.lat]);
          let to = turf.point([device.longitude, device.latitude]);
          let distance = turf.distance(from, to, { units: 'meters' });
          radius.push(distance);
        }
      });
      if (radius.length > 0) {
        this.circle.radius = Math.max(...radius) * 1.2;
        this.latLngNumber = {
          lat: this.circle.lat,
          lng: this.circle.lng,
        };
      } else {
        this.latLngNumber = { lat: 0, lng: 0 };
        this.circle.radius = null;
      }
    } else {
      this.latLngNumber = { lat: 0, lng: 0 };
      this.circle.lat = null;
      this.circle.lng = null;
      this.circle.radius = null;
    }
  }

  onDeviceTypeChange(event: any) {
    // this.geojson = {};
    // this.formTypeChange("2");
    this.filteredAllDevType = [];
    this.selectedDevices = [];
    this.selectedDevicesLabels = [];
    this.selectedAqi = undefined;
    this.circle = { lat: null, lng: null, radius: null };
    this.latLngNumber = { lat: 0, lng: 0 };
    this.allDevices = [];
    this.aqi = this.allAqisList[this.mapDetailForm?.get('deviceType')?.value];
    this.selectedAqi = this.aqi ? this.aqi.id : '';
    this.heatmapDeviceTypes =
      this.filteredOZAllDevType[this.mapDetailForm?.get('deviceType')?.value];
    if (this.drawnPolygon) {
      this.drawnPolygon.setMap(null);
      this.drawnPolygon = null;
    }
    this.drawingManager?.setOptions({
      drawingControl: false,
      drawingMode: null,
    });
    this.fields = this.deviceService.fetchFields(
      this.mapDetailForm?.get('deviceType')?.value,
      this.commonService.getAllUnits(),
      false
    );

    this.limits = this.deviceService.fetchLimits(
      this.commonService.getAllUnits()[
        this.mapDetailForm?.get('deviceType')?.value
      ],
      false
    );

    this.filteredAllDevType = this.localStorageService
      .getParsedValue(LocalStorageConstants.OZ_ALL_DEV_TYPE)
      ?.filter(
        (type: any) =>
          type.deviceTypeId === this.mapDetailForm?.get('deviceType')?.value
      )[0];
    if (event?.value?.config?.deviceId) {
      this.fitBounds(
        this.googleMap?.googleMap!,
        DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
          this.deviceTypes,
          this.mapDetailForm?.get('deviceType')?.value
        )
      );
    }
    this.getDevices(this.mapDetailForm?.get('deviceType')?.value);
    if (this.allDevices && this.data.boundary?.length) {
      this.checkAllDevices(this.data.boundary.map((b: any) => [b.lng, b.lat]));
    }
    this.getAqiKeys();
  }

  selectAllDevices(event: any, devices: any) {
    if (event.checked === true) {
      this.selectedDevices = [];
      for (let device of devices) {
        let allselectedDev = JSON.parse(JSON.stringify(this.allDevices));
        const index = allselectedDev.indexOf(device.deviceId);
        if (index >= 0) {
          allselectedDev.splice(index, 1);
        }
        this.selectedDevices.push(device.deviceId);
        let a = this.selectedDevices.indexOf(device.deviceId);
        if (a < 0) {
          this.selectedDevices.push(device.deviceId);
        }
      }
    } else {
      this.selectedDevices = [];
    }
    this.addDevice(this.selectedDevices);
  }

  onSubmit() {
    if (this.mapDetailForm.get('selectedDevicesControl')?.value?.length < 5) {
      this.errorMsgForSelectedDev = true;
      return;
    } else {
      if (
        this.mapDetailForm.valid &&
        !CommonUtil.isEmpty(this.geojson) &&
        this.geojson.geometry.coordinates?.[0]?.length > 5
      ) {
        const payloadName = this.isEdit ? 'update' : 'data';
        let limitsObj = this.limitsObj;
        let fieldsObj = this.fieldsObj;
        let aqiColorArray =
          this.allAqisList[this.mapDetailForm.get('deviceType')?.value]?.index
            ?.colors;

        const payload = {
          [payloadName]: {
            devices: this.mapDetailForm.get('selectedDevicesControl')?.value,
            label: this.mapDetailForm.get('heatmapName')?.value,
            // keyColorLimits: Object.fromEntries(
            //   this.mapDetailForm.get('parameters')?.value?.map((param: any) => {
            //     return [
            //       param,
            //       limitsObj[param].range.map((range: any, index: number) => ({
            //         color: aqiColorArray[index],
            //         limit: range / fieldsObj[param].cFactore,
            //       })),
            //     ];
            //   })
            // ),
            options: {},
            boundary: this.geojson.geometry.coordinates[0].map(
              (point: any) => ({
                lat: point[1],
                lon: point[0],
              })
            ),
            deviceTypeId: this.mapDetailForm?.get('deviceType')?.value,
          },
        };

        if (this.isEdit && this.data?.heatmapId?.length) {
          // Edit heatmap
          (payload.update as any).heatmapId = this.data.heatmapId;
          this.heatmapService
            .updateHeatmap(payload as { update: Heatmap })
            .subscribe({
              next: (res: any) => {
                this.closeForm(true);
              this.notificationService.showSnackBar(res, 'success');
              },
              error: (err: any) => {
                this.notificationService.showNotification(
                  'Failed to create heatmap.',
                  'Close',
                  'bottom',
                  'center',
                  'error',
                  3000
                );
              },
              complete: () => {},
            });
        } else {
          this.heatmapService
            .addHeatmap(payload as { data: Heatmap })
            .subscribe({
              next: (res: any) => {
                this.closeForm(true);
                this.notificationService.showSnackBar(res, 'success');
              },
              error: (err: any) => {
                this.notificationService.showNotification(
                  'Failed to create heatmap.',
                  'Close',
                  'bottom',
                  'center',
                  'error',
                  3000
                );
              },
              complete: () => {},
            });
        }
      } else {
        this.mapDetailForm.markAllAsTouched();
        this.mapDetailForm.updateValueAndValidity();
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getAqiKeys() {
    const deviceTypeId = this.mapDetailForm?.get('deviceType')?.value;
    if (deviceTypeId && this.allAqiList[deviceTypeId]) {
      this.AqiKeys = Object.keys(this.allAqiList[deviceTypeId]);
    } else {
      this.AqiKeys = [];
    }
  }
}
