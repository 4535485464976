import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HashLocationStrategy,
  KeyValuePipe,
  LocationStrategy,
  TitleCasePipe,
} from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NbDialogModule, NbMenuModule, NbSidebarModule } from '@nebular/theme';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './layouts/auth/auth.module';
import { TerminalModule } from './layouts/terminal/terminal.module';
import {
  appInitializerFactory,
  httpTranslateLoader,
} from './shared/core/transalate-factory';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    SharedModule.forRoot(),
    NbDialogModule.forRoot(),
    ToastrModule.forRoot(),
    AuthModule,
    TerminalModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    GoogleMapsModule,
    // AgmCoreModule,
    // AgmJsMarkerClustererModule,
  ],
  providers: [
    TitleCasePipe,
    KeyValuePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}