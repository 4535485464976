import { Component, Input, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-tabular-analytic',
  templateUrl: './tabular-analytic.component.html',
  styleUrls: ['./tabular-analytic.component.scss'],
})
export class TabularAnalyticComponent {
  @Input() dataForAnalytics: any;
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = [];
  public uniqueKeysForColumn: string[] = [];
  defaultColumns: any = [];
  public fields: any;

  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataForAnalytics'].currentValue) {
      this.dataForAnalytics = this.formatData(
        changes['dataForAnalytics'].currentValue
      );
      this.generateTableColumns();
      this.displayedColumns = [
        ...this.defaultColumns.map((c: any) => c.columnDef),
      ];
      this.loadTable.next(true);
    }
  }

  generateTableColumns() {
    this.uniqueKeysForColumn.forEach((col) => {
      let column = {};

      let label = DeviceUtil.getFieldName(col, this.fields) ?? '';
      let unit = 'N/A';
      if (col !== 't') {
        unit = DeviceUtil.getFieldUnit(col, this.fields);
      }
      if (col === 'deviceId') {
        column = {
          columnDef: 'deviceId',
          header: 'Device Name',
          cell: (element: any) => `${element?.deviceId}`,
          parameter: false,
          selected: false,
        };
      } else {
        column = {
          columnDef: col,
          header:
            unit !== 'N/A' ? `${label} <br><small>${unit}</small>` : `${label}`,
          cell: (element: any) => `${element[col].value}`,
          parameter: false,
          selected: false,
        };
      }
      this.defaultColumns.push(column);
    });
  }

  formatData(data: any) {
    let formattedData: Record<any, any>[] = [];
    let uniqueKey: Set<string> = new Set();
    const devices = this.deviceService.registeredDevices;
    const deviceTypes = this.commonService.getUserDeviceTypes();
    let units = this.commonService.getAllUnits();
    if (!devices) {
      return [];
    }

    let deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      deviceTypes,
      devices,
      data[0].deviceId
    );

    if (!deviceTypeId) return [];
    this.fields = this.deviceService.fetchFields(deviceTypeId!, units, false);

    data.forEach((d: any, index: number) => {
      if (!formattedData[index]) {
        formattedData[index] = {}; // Initialize an empty object at the index
      }
      formattedData[index]['deviceId'] = d.deviceId;

      const convertedPayload = DeviceUtil.convertUnits(
        d.payload[0].payload,
        units[deviceTypeId as number]
      );

      Object.keys(convertedPayload.d).forEach((param) => {
        formattedData[index][param] = {
          value: convertedPayload.d[param],
        };
      });

      formattedData[index] = {
        ...formattedData[index],
      };

      Object.keys(formattedData[index]).forEach((k) => uniqueKey.add(k));
    });

    this.uniqueKeysForColumn = Array.from(uniqueKey);
    return formattedData;
  }
}
