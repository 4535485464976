<ng-container *ngIf="(loadTable | async) == true">
  <app-form-container
    [tableTitle]="'Set Project Expiry'"
    (cancelled)="closeForm()"
    (close)="closeForm()"
    (submit)="submit()"
    [submitBtn]="'Update'"
    [cancelBtn]="'Close'"
    [isValid]="projectExpiryDate.value"
  >
    <mat-form-field class="col-12 col-lg-12">
      <mat-label>Select Date</mat-label>
      <input
        matInput
        [formControl]="projectExpiryDate"
        [matDatepicker]="picker"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
        tabindex="-1"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker> </mat-form-field
  ></app-form-container>
</ng-container>

<ng-container class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner
    class="mx-auto circle"
    [diameter]="30"
    color="primary"
  ></mat-spinner>
</ng-container>
