import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportFormComponent } from './components/report-form/report-form.component';
import { ReportListComponent } from './pages/report-list/report-list.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';

@NgModule({
  declarations: [ReportsComponent, ReportListComponent, ReportFormComponent],
  imports: [
    TranslateModule.forChild({}),
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
  ],
})
export class ReportsModule {}
