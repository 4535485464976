import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { UnitsManagement } from 'src/app/shared/models/units-management';
import { Unit } from 'src/app/shared/models/units/unit';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-limit-form',
  templateUrl: './limit-form.component.html',
  styleUrls: ['./limit-form.component.scss'],
})
export class LimitFormComponent implements OnInit {
  public limitForm!: FormGroup;
  public thresholdForm!: FormGroup;
  public unitsOfGas!: Unit.Get;
  public limitColors!: String[];
  public selectedUnit!: number;
  public formTitle!: string;
  public currentDeviceType!: number;
  public allUnits!: Record<number, Unit.Data[]>;
  public isNegativeLimit: boolean = false;
  public isAscendingLimit: boolean = true;
  // public defaultUnit!: Unit.Data;
  public isParamTemprature: boolean = false;
  // public refLimits: number[] | null | undefined = [];
  isAQIParam: boolean = false;
  public baseLimitArray: number[] | null | undefined = [];
  public allThresholds: any[] = [];
  // public showThresholdLimits: boolean = true;
  public subscriptions: Subscription[] = [];
  public maxThresholdValue: string = '';
  public minThresholdValue: string = '';

  constructor(
    @Inject(MTX_DRAWER_DATA) public data: UnitsManagement,
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.formTitle = this.data.keyLabel;
    this.addSubscriptions();
    this.allThresholds = this.getThresholdLimitFormJson();
    this.unitsOfGas = this.data.availableUnits;
    this.selectedUnit = this.data.checkedUnit;
    this.isParamTemprature = this.unitsOfGas.every((gas) => gas.key === 'temp');
    this.calculateLimits();
    this.buildForm();
    this.addFormSubscriptions();
    this.getSelectedAQI();
    this.patchLimitValue();
    this.setMinMaxThresholdValue();
    // this.onUnitChange();
  }

  addFormSubscriptions() {
    // const limit = this.limitForm
    //   .get('limits')
    //   ?.valueChanges.subscribe((res) => {
    //     this.showThresholdLimits = res.every(
    //       (r: any) => r.limitValue !== null && r.limitValue !== ''
    //     );

    //     const tempNumber = res[res.length - 1].limitValue;

    //     if (tempNumber !== null && tempNumber !== '') {
    //       this.maxThresholdValue = tempNumber;
    //     } else {
    //       this.maxThresholdValue = '';
    //     }

    //     this.updateMaxThresholdValueInFormValidator(this.maxThresholdValue);
    //   });

    const units = this.limitForm
      .get('unit')
      ?.valueChanges.subscribe((res: number) => {
        this.selectedUnit = res;
        this.patchLimitValue();
        this.patchRefLimitValue();
        this.setMinMaxThresholdValue();
      });

    if (units) this.subscriptions.push(units);

    // if (limit) this.subscriptions.push(limit);
  }

  setMinMaxThresholdValue() {
    let unit = this.unitsOfGas.find((res) => res.id == this.selectedUnit);
    if (unit) {
      this.maxThresholdValue = String(unit.h_limit);
      this.minThresholdValue = String(unit.l_limit);
      Object.keys(this.thresholdForm.value).forEach((key) => {
        this.thresholdForm.controls[key].setValidators([
          Validators.max(Number(this.maxThresholdValue)),
          Validators.min(Number(this.minThresholdValue)),
          Validators.pattern(
            // this.isParamTemprature
            //   ? AppConstants.NEGATIVE_POSITIVE_NUMBER_REGEX_WITH_DECIMAL
            //   :
            // AppConstants.NUMBER_REGEX_WITH_DECIMAL

            AppConstants.NEGATIVE_POSITIVE_NUMBER_REGEX_WITH_DECIMAL
          ),
        ]);
      });

      // this.updateMaxThresholdValueInFormValidator(this.maxThresholdValue);
    }
  }

  patchRefLimitValue() {
    const fields = this.allThresholds.map((threshold) => threshold.formControl);
    const existingLims = this.data.unitOptions?.refLineLimits;
    let unit = this.unitsOfGas.find((unit: Unit.Data) => {
      return unit.id === this.selectedUnit;
    })!;

    let dataObj: Record<any, any> = {};
    //if refLineLimits object doesnot exist in options than return
    if (!existingLims) return;

    fields.forEach((field) => {
      //if value inside any field is null, than set value as empty string
      if (existingLims?.[field] === null) {
        dataObj[field] = '';
        return;
      }

      let value;
      //convert the value according to c factore
      if (unit.c_factore === -1) {
        value = ((existingLims?.[field] * 1.8 + 32) * 1e2) / 1e2;
      } else {
        value = existingLims?.[field] * unit.c_factore;
      }

      //manage decimal precision
      let finalValue = value.toFixed(2);
      if (finalValue.split('.')[1] == '00') {
        finalValue = String(Math.round(Number(finalValue)));
      }
      dataObj[field] = finalValue ?? '';
    });

    this.thresholdForm.patchValue(dataObj);
  }

  addSubscriptions() {
    const dt = this.userService.selectedDeviceTypeForUnits$.subscribe((res) => {
      if (res) {
        this.currentDeviceType = res;
      }
    });

    const unit = this.userService.unitsForAllParameters$.subscribe((res) => {
      if (res) {
        this.allUnits = res;
      }
    });

    this.subscriptions.push(dt);
    this.subscriptions.push(unit);
  }

  isAqiParameter(param: string) {
    const allAQI = this.commonService.getAllAQIs();
    const selectedAQIOfDeviceType = allAQI[this.data.deviceTypeId];
    let paramsOfAqi = ['ws']; //Limits for Wind Speed cannot be empty
    if (selectedAQIOfDeviceType) {
      paramsOfAqi = [
        ...paramsOfAqi,
        ...Object.keys(selectedAQIOfDeviceType.breakpoints),
      ];
    }
    return paramsOfAqi.includes(param);
  }

  calculateLimits() {
    this.baseLimitArray = this.unitsOfGas.find(
      (gas) => gas.id === this.selectedUnit
    )?.limit;

    const cFactor = this.unitsOfGas.find(
      (gas) => gas.id === this.selectedUnit
    )?.c_factore;

    if (cFactor === -1) {
      this.baseLimitArray = this.baseLimitArray?.map(
        (res) => ((res - 32) * 5) / 9
      );
      return;
    }

    if (cFactor)
      this.baseLimitArray = this.baseLimitArray?.map((res) => res / cFactor);
  }

  asFormArray(input: AbstractControl) {
    return input as FormArray;
  }
  asFormGroup(input: AbstractControl) {
    return input as FormGroup;
  }

  buildForm() {
    this.limitForm = this.formBuilder.group({
      unit: [this.selectedUnit, Validators.required],
      limits: this.formBuilder.array([], this.hasAllNonEmptyValidator()),
    });

    this.thresholdForm = this.formBuilder.group({});
    const fields = this.allThresholds.map((threshold) => threshold.formControl);
    fields.forEach((field) =>
      this.thresholdForm.addControl(field, this.formBuilder.control(''))
    );

    //patch existing threshold limit or ref line limits
    if (this.data.unitOptions?.refLineLimits) {
      const existingLimit = this.data.unitOptions?.refLineLimits;
      let tempObj: Record<string, any> = {};
      fields.forEach(
        (field: string) => (tempObj[field] = existingLimit?.[field] ?? '')
      );

      this.thresholdForm.patchValue(tempObj);
    }
  }

  addFormGroup() {
    this.limitColors.forEach((limitColor: any, index: number) => {
      if (limitColor) {
        const limits = this.limitForm.get('limits') as FormArray;
        limits.push(this.createFormGroup(index));
      }
    });
  }

  updateMaxThresholdValueInFormValidator(newMaxValue: string) {
    // this.allThresholds.map((threshold) => {
    //   const control: AbstractControl | null = this.thresholdForm.get(
    //     threshold.formControl
    //   );
    //   if (!control) return;
    //   control?.setValidators(Validators.max(Number(newMaxValue)));
    //   control?.updateValueAndValidity();
    // });
  }

  createFormGroup(index: number): FormGroup {
    return new FormGroup({
      limitValue: new FormControl(''),
    });
  }

  getSelectedAQI() {
    let allAQI = this.commonService.getAllAQIs();
    if (allAQI) {
      if (allAQI[this.data.deviceTypeId]?.id) {
        this.limitColors = allAQI[this.data.deviceTypeId].index.colors;
      } else {
        this.limitColors = allAQI[1001].index.colors;
      }
    }
    this.addFormGroup();
  }

  closeForm(): void {
    this.formsService.closeForm();
  }

  submitForm() {
    let payload: RequestData.UpdateUser = this.generatePayload();
    this.loadrService.showLoader();
    this.userService.updateUnitsAndLimits(payload).subscribe({
      next: (res) => {
        if (res) {
          // let user = this.localStorageService.getParsedValue(
          //   LocalStorageConstants.OZ_USER
          // );
          // user.units = payload.update.units;
          // this.localStorageService.saveValue(
          //   LocalStorageConstants.OZ_USER,
          //   JSON.stringify(user)
          // );

          //getting the updated user profile after updating the units
          this.userService.getUserProfile().subscribe({
            next: () => {
              this.loadrService.removeLoader();
              this.formsService.closeForm();
              // this.userService.afterUpdateUnitOrLimit();
              this.notificationService.showSnackBar(
                'Parameter Updated Successfully',
                'success'
              );
              let copy = JSON.parse(
                JSON.stringify(this.deviceService.rawCopyOfregisteredDevices)
              );
              this.deviceService.modifyDeviceDetails(copy, false, false);
            },
            error: (err) => {
              this.loadrService.removeLoader();
              this.formsService.closeForm();
              this.notificationService.showSnackBar(
                'Please refresh this page',
                'error'
              );
            },
          });
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar(err.error, 'error');
      },
    });
  }

  generatePayload(): RequestData.UpdateUser {
    let newLimit: number[] = [];
    let newUnit!: Unit.Data;
    const controls = (this.limitForm.controls.limits as any)
      .controls as AbstractControl[];
    let units = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    let isAllValueBlankOrNull = controls.every(
      (control) =>
        control.value.limitValue === '' || control.value.limitValue === null
    );
    if (!isAllValueBlankOrNull) {
      this.limitForm.value.limits.forEach((limit: any) => {
        newLimit.push(limit.limitValue);
      });
    }
    this.allUnits[this.currentDeviceType].map((res: Unit.Data) => {
      if (res.id === this.limitForm.value.unit) {
        newUnit = res;
      }
    });

    units[this.currentDeviceType][newUnit.key] = newUnit;
    units[this.currentDeviceType][newUnit.key].limit = newLimit;

    let existingOptions = units[this.currentDeviceType][newUnit.key].options;
    if (!existingOptions) {
      units[this.currentDeviceType][newUnit.key].options = {
        refLineLimits: this.getThresholdValues(),
      };
    } else {
      units[this.currentDeviceType][newUnit.key].options = {
        ...existingOptions,
        refLineLimits: this.getThresholdValues(),
      };
    }

    let payload: RequestData.UpdateUser = {
      update: {
        units: units,
      },
      resetUnits: false,
    };

    return payload;
  }

  getThresholdValues() {
    let refLim = {
      hr:
        this.thresholdForm.get('hr')?.value !== ''
          ? this.thresholdForm.get('hr')?.value
          : null,
      '8hr':
        this.thresholdForm.get('8hr')?.value !== ''
          ? this.thresholdForm.get('8hr')?.value
          : null,
      daily:
        this.thresholdForm.get('daily')?.value !== ''
          ? this.thresholdForm.get('daily')?.value
          : null,
    };

    return refLim;
  }

  // onUnitChange() {}

  patchLimitValue() {
    let unit = this.unitsOfGas.find((unit: Unit.Data) => {
      return unit.id === this.selectedUnit;
    })!;

    let limit: number[] = [];

    //if the selected unit have limit than showing it
    if (unit?.limit?.length) {
      limit = unit.limit;
    }

    //if the selected unit doesnot have limit than calculating it through c_factore
    if (!unit?.limit?.length) {
      limit = [];
      this.baseLimitArray?.forEach((_, index) => {
        let l;
        //for temprature calculate limit differently
        if (unit.c_factore === -1) {
          l = ((this.baseLimitArray![index] * 1.8 + 32) * 1e2) / 1e2;
        } else {
          l = this.baseLimitArray![index] * unit.c_factore;
        }
        limit.push(parseFloat(l.toFixed(2)));
      });
    }

    const formArray = this.limitForm.get('limits') as FormArray;

    if (limit.length) {
      formArray.controls.forEach((control, index) => {
        control.patchValue({
          limitValue: limit[index],
        });
      });
    } else {
      formArray.controls.forEach((control, index) => {
        control.patchValue({
          limitValue: '',
        });
      });
    }

    // if (unit.limit?.length > 0) {
    //   formArray.controls.forEach((control, index) => {
    //     control.patchValue({
    //       limitValue: limit[index],
    //     });
    //   });
    // } else {
    //   formArray.controls.forEach((control) => {
    //     control.patchValue({
    //       limitValue: '',
    //     });
    //   });
    // }
  }

  hasAllNonEmptyValidator(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      const controls = (formArray as any).controls as AbstractControl[];

      const isAnyLimitFilled = controls.some(
        (control) =>
          control.value.limitValue !== '' && control.value.limitValue !== null
      );
      let hasAllNonEmpty: boolean = true;

      if (isAnyLimitFilled) {
        hasAllNonEmpty = controls.every((control) => {
          return (
            (this.isParamTemprature ? true : control?.value.limitValue > -1) && // if the current parameter is temprature then allow negative values
            control?.value.limitValue !== '' &&
            control?.value.limitValue !== null
          );
        });
      }

      //will be used when user is entering limits for aqi parameter (limits for aqi parameter cannot be empty)
      const isEveryLimitFilled = controls.every(
        (control) =>
          control.value.limitValue !== '' &&
          control.value.limitValue !== null &&
          control.value.limitValue > -1
      );

      //to show error if user enters value other than positive number
      const hasNegativeLimit = controls.some((control: any) => {
        if (this.isParamTemprature) return false; //if parameter is temprature than allow negative values
        if (control?.value.limitValue === '') return false; // Skip empty values
        return control?.value.limitValue < 0;
      });
      this.isNegativeLimit = hasNegativeLimit;

      // Check if the values are in ascending order
      if (isAnyLimitFilled) {
        this.isAscendingLimit = controls.every((control, index, array) => {
          return (
            index === 0 ||
            array[index - 1]?.value?.limitValue <= control?.value.limitValue
          );
        });
      } else {
        this.isAscendingLimit = true;
      }

      let condition: boolean;

      if (this.isAqiParameter(this.data.availableUnits[0].key)) {
        condition = isEveryLimitFilled && this.isAscendingLimit;
      } else {
        condition = hasAllNonEmpty && this.isAscendingLimit;
      }

      return condition ? null : { allValuesNotEntered: true };
    };
  }

  getThresholdLimitFormJson() {
    const formJson = [
      {
        inputLabel: 'For 1 hour',
        formControl: 'hr',
      },
      {
        inputLabel: 'For 8 hour',
        formControl: '8hr',
      },
      {
        inputLabel: 'For 24 hour',
        formControl: 'daily',
      },
    ];
    return formJson;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
