import { COMMA, ENTER, P, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { saveAs } from 'file-saver';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ReportData } from 'src/app/shared/models/reports/report-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
export interface EmailList {
  name: string;
}

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent implements OnInit {
  public deviceTypes!: Array<DeviceType>;
  public reportForm!: FormGroup;
  public quickForm!: FormGroup;
  public reportTypes: string[] = ['Automated Report', 'Quick Report'];
  public selectedReportType: string = '';
  public countEmail: number = 1;
  public devices: DeviceDetails[] = [];
  public isAutomated: boolean = true;
  public isQuick: boolean = false;
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public emails: EmailList[] = [];
  public minDate!: moment.Moment;
  public maxDate!: moment.Moment;
  public isEmailValid: boolean = true;
  public buttonValue: string = 'Add Report';
  public isEditMode: boolean = false;
  public isNextStep: boolean = false;
  public subscriptions: Subscription[] = [];

  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private reportsService: ReportsService,
    private loadrService: LoadrService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    @Inject(MTX_DRAWER_DATA) public data: ReportData.Get | undefined
  ) {}

  ngOnInit() {
    this.isEditMode = !!this.data;
    this.buildForm(this.isEditMode);
    this.addSubscriptions();
  }

  buildForm(isEditMode: boolean) {
    this.devices = this.deviceService.registeredDevices || [];
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.reportForm = this.formBuilder.group({
      reportType: ['automated'],
      emailId: this.formBuilder.array([], Validators.required),
      duration: this.formBuilder.array(
        [
          new FormControl(false),
          new FormControl(false),
          new FormControl(false),
        ],
        [Validators.required, this.customValidatorForSingleCheckBox()]
      ),
      deviceSearch: [{ value: '', disabled: isEditMode }, Validators.required],
      deviceName: [''],
      status: [''],
    });

    this.quickForm = this.formBuilder.group({
      reportType: ['quick'],
      dateSelected: [, Validators.required],
      receivingStyle: ['downloadpdf'],
      duration: this.formBuilder.array([
        new FormControl(true),
        new FormControl(false),
        new FormControl(false),
      ]),
      deviceSearch: ['', Validators.required],
      deviceName: [''],
    });

    //if it is not edit mode than pushing current user's email address in form and emails array
    if (!isEditMode) {
      (<FormArray>this.reportForm.get('emailId')).push(
        new FormControl(this.currentUserEmail, [Validators.email])
      );
      this.emails.push({ name: this.currentUserEmail });
    }

    if (isEditMode) {
      this.buttonValue = 'Update Report';
      for (let i = 0; i < this.data?.email?.split(',').length!; i++) {
        this.addEmail(this.data?.email.split(',')[i]!);
      }

      let device = this.devices.find(
        (res) => res.deviceId === this.data?.deviceId
      );
      this.reportForm.patchValue({
        deviceSearch: device,
        duration: this.selectedReport(
          AppConstants.reverseFormatPeriod[this.data!.period],
          this.reportForm
        ),
        status: this.data?.enable,
      });
      this.reportForm.get('duration')?.disable();
    }
  }

  addEmail(input: MatChipInputEvent | string): void {
    if (typeof input === 'object') {
      const value = (input.value || '').trim();

      if (input.value !== '')
        this.isEmailValid = this.validateEmail((input.value || '').trim());

      if (this.isEmailValid) {
        if (value && this.countEmail < 6) {
          this.countEmail++;
          if (this.isAutomated) {
            (<FormArray>this.reportForm.get('emailId')).push(
              new FormControl(value, [Validators.email])
            );
          }
          if (this.isQuick) {
            (<FormArray>this.quickForm.get('emailId')).push(
              new FormControl(value, [Validators.email])
            );
          }
          this.emails.push({ name: value });
          this.isEmailValid = true;
        }
        input.chipInput!.clear();
      }
    } else if (typeof input === 'string') {
      if (input !== '')
        this.isEmailValid = this.validateEmail((input || '').trim());
      if (this.isEmailValid) {
        if (input && this.countEmail < 6) {
          this.countEmail++;
          if (this.isAutomated) {
            (<FormArray>this.reportForm.get('emailId')).push(
              new FormControl(input, [Validators.email])
            );
          }
          if (this.isQuick) {
            (<FormArray>this.quickForm.get('emailId')).push(
              new FormControl(input, [Validators.email])
            );
          }
          this.emails.push({ name: input });
          this.isEmailValid = true;
        }
      }
    }
  }

  removeEmail(email: EmailList): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.countEmail--;
      const emailIdControlArray = this.isAutomated
        ? (this.reportForm.get('emailId') as FormArray)
        : (this.quickForm.get('emailId') as FormArray);
      emailIdControlArray.removeAt(index);
      //index because it will have deafult email address
      this.emails.splice(index, 1);
    }
  }

  editEmail(email: EmailList, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.removeEmail(email);
      return;
    }
    let formEmailArray = this.isAutomated
      ? (this.reportForm.get('emailId') as FormArray)
      : (this.quickForm.get('emailId') as FormArray);

    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails[index].name = value;
      formEmailArray.controls[index].patchValue(value);
    }
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  clearDeviceName(): void {
    this.reportForm.controls.deviceSearch.setValue(undefined);
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  clearEmailArray() {
    while (this.emails.length > 0) {
      this.emails.pop();
    }
    if (this.isAutomated) {
      this.emails.push({ name: this.currentUserEmail });
      this.countEmail = 1;
    } else if (this.isQuick) {
      this.countEmail = 0;
    }
  }

  submitForm() {
    if (this.isAutomated) {
      let payload = this.generatePayloadAutomatedReport();
      if (!this.isEditMode) {
        this.submitAutomatedReport(payload);
      } else if (this.isEditMode) {
        let payloadForEdit: any = {
          update: {
            ...payload.dataList[0],
            enable: this.reportForm.value.status,
          },
        };
        this.updateAutomatedReport(payloadForEdit);
      }
    } else if (this.isQuick) {
      this.quickForm.value.receivingStyle === 'downloadpdf'
        ? this.downloadQuickReportPdf(
            this.generatePayloadQuickReport() as ReportData.DownloadQuickReport
          )
        : this.emailQuickReportPdf(
            this.generatePayloadQuickReport() as ReportData.EmailQuickReport
          );
    }
  }

  closeForm(data: any = false) {
    if (this.isAutomated === true) {
    } else if (this.isQuick === true) {
    }
    this.formsService.closeForm(data);
  }

  customValidatorForSingleCheckBox(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let count = (control as FormArray).value.reduce(
        (prev: number, curr: boolean) => prev + Number(curr),
        0
      );
      return count <= 0 ? { required: true } : null;
    };
  }

  selectedReport(index: number, selectedForm: FormGroup) {
    let form = selectedForm.get('duration') as FormArray;

    form.controls.map((con, i) => {
      con.setValue(index === i);
    });
  }

  customEmailValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.quickForm.controls['receivingStyle'].value === 'emailpdf') {
        let isEmailEmpty = this.countEmail <= 0 ? true : false;
        return isEmailEmpty ? { requiredEmail: true } : null;
      }
      return null;
    };
  }

  countLTE(date: moment.Moment, reportDuration: number) {
    if (reportDuration === 0) {
      const dateIs = this.customMomentService.moment(date).add(1, 'day');

      return dateIs.valueOf();
    } else if (reportDuration === 1) {
      const dateIs = this.customMomentService.moment(date).add(7, 'days');

      return dateIs.valueOf();
    } else if (reportDuration === 2) {
      const dateIs = this.customMomentService.moment(date).add(30, 'days');
      return dateIs.valueOf();
    }
    return null;
  }

  validateEmail(emailId: string) {
    return String(emailId)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
      ? true
      : false;
  }

  //when clicked on submit button this function will be called if user wants to generate quick report
  downloadQuickReportPdf(reportDetails: ReportData.DownloadQuickReport) {
    this.loadrService.showLoader();
    this.isNextStep = false;
    this.reportsService.downloadPDF(reportDetails).subscribe({
      next: (blobData: Blob) => {
        const pdfUrl = URL.createObjectURL(blobData);
        window.open(pdfUrl, '_blank');
        saveAs(blobData, reportDetails.devices?.[0].label + '.pdf');
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar('Report downloaded successfully.','success');
        this.formsService.closeForm();
      },
      error: (err: Error) => {
        console.info("Error:", err);
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar('Error downloading report, Try Again!','error');
      },
    });
  }

  emailQuickReportPdf(reportDetails: ReportData.EmailQuickReport) {
    this.loadrService.showLoader();
    this.reportsService.emailPdf(reportDetails).subscribe({
      next: (res) => {
        if (res) {
          this.loadrService.removeLoader();
          this.formsService.closeForm();
          this.notificationService.showSnackBar('Email sent successfully.','success');
        }
      },
      error: (err) => {
        this.loadrService.removeLoader();
        console.info("Error:", err);
        this.notificationService.showSnackBar(err.message,'error');
      },
    });
  }

  submitAutomatedReport(reportDetails: ReportData.Add) {
    this.loadrService.showLoader();
    this.reportsService.submitAutomatedReport(reportDetails).subscribe({
      next: (res) => {
        if (res) {
          this.reportsService.getReportDetails();
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar(res,'success');
          this.formsService.closeForm();
        }
      },
      error: (err) => {
        console.info("Error:", err);
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar(err.error,'error');
      },
    });
  }

  updateAutomatedReport(reportDetails: ReportData.Update) {
    this.loadrService.showLoader();
    this.reportsService
      .updateAutomatedReport(reportDetails, this.data?.reportId!)
      .subscribe({
        next: (res) => {
          if (res) {
            this.reportsService.getReportDetails();
            this.loadrService.removeLoader();
            this.notificationService.showSnackBar(res,'success');
            this.formsService.closeForm();
          }
        },
        error: (err) => {
          console.info("Error:", err);
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar(err.error,'error');
        },
      });
  }

  generatePayloadQuickReport() {
    let tempForm = this.quickForm.value;
    let date = this.customMomentService
      .moment(tempForm.dateSelected)
      .startOf('day');

    let deviceIds = [];
    deviceIds.push(tempForm.deviceSearch.deviceId);

    let quickReportPayload:
      | ReportData.DownloadQuickReport
      | ReportData.EmailQuickReport = {
      devices: [
        {
          deviceId: tempForm.deviceSearch.deviceId,
          label: tempForm.deviceSearch.label,
          loc: tempForm.deviceSearch.loc,
        },
      ],
      userId: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).userId,
      org: this.localStorageService.getValue(LocalStorageConstants.ORG_NAME),
      gte: date.unix(),
      lte:
        this.countLTE(
          tempForm.dateSelected,
          tempForm.duration[0] ? 0 : tempForm.duration[1] ? 1 : 2
        )! /
          1000 -
        60,
      logo_url: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).organization.logoUrl,
      isTwelveHourFormat:
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .settings.time_format === 12
          ? true
          : false,
      timezone: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).settings.timezone.name,
      processType: 'avg',
      avg: tempForm.duration[0] ? 3600 : 86400,
      deviceIds: deviceIds,
    };

    if (tempForm.receivingStyle === 'downloadpdf') {
      return quickReportPayload;
    } else {
      let emails = this.emails.map((email) => {
        return email.name;
      });
      let tempPayload: ReportData.EmailQuickReport = {
        ...quickReportPayload,
        email: emails,
      };
      quickReportPayload = tempPayload;
    }

    return quickReportPayload;
  }

  generatePayloadAutomatedReport() {
    let tempForm = this.reportForm.value;
    if (this.isEditMode) {
      if (this.data?.period === 'daily')
        tempForm['duration'] = [true, false, false];
      if (this.data?.period === 'weekly')
        tempForm['duration'] = [false, true, false];
      if (this.data?.period === 'monthly')
        tempForm['duration'] = [false, false, true];
    }
    let payload: ReportData.Add = { dataList: [] };
    payload.dataList = tempForm.duration
      .map((d: boolean, i: number) => [d, i])
      .filter((di: [boolean, number]) => di[0])
      .map((di: [boolean, number], i: number) => {
        let arrayForPayload = {
          email: Boolean(this.reportForm.value.emailId.toString())
            ? this.reportForm.value.emailId.toString()
            : null,
          label: this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_USER
          ).company,
          period: AppConstants.formatPeriod[di[1]],
          deviceId: this.isEditMode
            ? this.data?.deviceId
            : this.reportForm.value.deviceSearch?.deviceId,
        };
        return arrayForPayload;
      });
    return payload;
  }

  addSubscriptions() {
    let reportType = this.reportForm
      .get('reportType')
      ?.valueChanges.subscribe((res) => {
        this.isQuick = res === 'quick';
        if (this.isQuick === true) {
          this.buttonValue = 'Generate Report';
          this.isAutomated = false;
          this.isQuick = true;
          this.quickForm.patchValue({
            reportType: 'quick',
          });
        }
        this.clearEmailArray();
      })!;

    this.subscriptions.push(reportType);

    let report = this.quickForm
      .get('reportType')
      ?.valueChanges.subscribe((res) => {
        this.isAutomated = res === 'automated';
        if (this.isAutomated === true) {
          this.buttonValue = 'Add Report';
          this.isQuick = false;
          this.reportForm.patchValue({
            reportType: 'automated',
          });
        }
        this.clearEmailArray();
      })!;
    this.subscriptions.push(report);

    let receiving = this.quickForm
      .get('receivingStyle')
      ?.valueChanges.subscribe((res) => {
        if (res === 'downloadpdf') {
          this.quickForm.removeControl('emailId');
          while (this.emails.length > 0) {
            this.emails.pop();
          }
          this.countEmail = 0;
          this.buttonValue = 'Generate Report';
        } else if (res === 'emailpdf') {
          this.quickForm.addControl('emailId', this.formBuilder.array([]));
          this.buttonValue = 'Email Report';
          this.countEmail = 1;
          (<FormArray>this.quickForm.get('emailId')).push(
            new FormControl(this.currentUserEmail, [Validators.email])
          );
          this.emails.push({ name: this.currentUserEmail });
        }
      })!;

    this.subscriptions.push(receiving);

    let deviceSearch = this.quickForm
      .get('deviceSearch')
      ?.valueChanges.subscribe((res) => {
        if (res) {
          this.minDate = this.deviceService.getMinDateOfDevice(res);
        }
      })!;

    this.subscriptions.push(deviceSearch);

    let duration = this.quickForm
      .get('duration')
      ?.valueChanges.subscribe((res) => {
        let response = null;
        for (let i = 0; i < res.length; i++) {
          if (res[i] === true) {
            let date = this.customMomentService.moment().startOf('day');
            this.maxDate = date;
            response = i;
            switch (response) {
              case 0:
                this.maxDate.subtract(1, 'day');
                break;
              case 1:
                this.maxDate.subtract(7, 'days');

                break;
              case 2:
                this.maxDate.subtract(30, 'days');

                break;
            }
          }
        }
      })!;

    this.subscriptions.push(duration);

    let reportform = this.reportForm.valueChanges.subscribe((res) => {
      if (this.isAutomated) {
        this.isNextStep = this.reportForm.valid;
      }
    });
    this.subscriptions.push(reportform);

    let quickForm = this.quickForm.valueChanges.subscribe((res) => {
      if (this.isQuick) {
        if (this.quickForm.value.receivingStyle === 'emailpdf') {
          this.isNextStep = this.quickForm.valid && this.countEmail > 0;
        } else if (this.quickForm.value.receivingStyle === 'downloadpdf') {
          this.isNextStep = this.quickForm.valid;
        }
      }
    });
    this.subscriptions.push(quickForm);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
