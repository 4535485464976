<div
  *ngIf="isMultiple"
  class="d-flex flex-column gap-3 multiple-mobile-input"
  [formGroup]="numberForm"
>
  <!-- <div
    class="w-100 input-container position-relative"
    style="
      border: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.25) !important;
      padding: 10px;
      border-radius: 10px;
    "
  > -->

  <mat-form-field>
    <mat-label>Mobile Number</mat-label>
    <input matInput class="d-none" formControlName="mainInput" />
    <div class="d-flex flex-row">
      <!-- <div class="alert-box">
        <mat-form-field class="w-100" appearance="outline">
          <mat-select formControlName="code">
            <mat-select-trigger *ngIf="numberForm.get('code')">
              <img
                width="23px"
                height="auto"
                [src]="numberForm.value.code.image"
              />
              <span>(+ {{ numberForm.value.code.phone_code }})</span>
            </mat-select-trigger>

            <mat-option
              *ngFor="let number of numberList; let i = index"
              [value]="number"
              class="d-flex justify-content-start justify-content-xl-center"
            >
              <span class="fs-6">
                <img [src]="number.image" width="25px" height="auto" />
                (+{{ number.phone_code }})
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="alert-box">
        <mat-form-field class="w-100" appearance="outline">
          <input
            matInput
            formControlName="code"
            [matAutocomplete]="auto"
            #trigger="matAutocompleteTrigger"
          />

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let number of filteredListOfNumbers"
              [value]="number"
              class="d-flex justify-content-start justify-content-xl-center"
            >
              <span class="fs-6">
                <img [src]="number.image" width="25px" height="auto" />
                (+{{ number.phone_code }})
              </span>
            </mat-option>
          </mat-autocomplete>
          <button
            type="button"
            mat-icon-button
            matSuffix
            tabindex="-1"
            (click)="selectionMade($event, trigger)"
          >
            <mat-icon class="material-symbols-outlined">
              arrow_drop_down
            </mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="flex-grow-1 alert-box">
        <mat-form-field class="w-100" appearance="outline">
          <input matInput formControlName="number" />
          <mat-error *ngIf="numberForm.get('number')?.hasError('invalidNumber')"
            >Enter a valid number</mat-error
          >
          <mat-error *ngIf="numberForm.get('number')?.hasError('numberExist')"
            >Number already exist</mat-error
          >
        </mat-form-field>
      </div>

      <div
        class="d-flex align-items-center justify-content-center flex-shrink-0"
      >
        <button
          *ngIf="!currentIndexForEdit"
          mat-mini-fab
          color="primary"
          matTooltip="Add Number"
          (click)="
            addMobileNumber(); $event.preventDefault(); $event.stopPropagation()
          "
        >
          <mat-icon class="material-symbols-outlined">add</mat-icon>
        </button>

        <button
          *ngIf="currentIndexForEdit"
          mat-mini-fab
          color="primary"
          matTooltip="Edit Number"
          (click)="
            editButton(); $event.preventDefault(); $event.stopPropagation()
          "
        >
          <mat-icon class="material-symbols-outlined">check</mat-icon>
        </button>
      </div>
    </div>
  </mat-form-field>
  <!-- </div> -->
  <div
    class="col-12"
    *ngIf="
      allMobileNumbers.validNumbers.length ||
      allMobileNumbers.invalidNumbers.length
    "
  >
    <mat-form-field class="w-100 chip-input" appearance="outline">
      <mat-chip-grid #mobileChipGrid class="example-chip-list">
        <ng-container
          *ngFor="let number of allMobileNumbers.validNumbers; let i = index"
        >
          <mat-chip-row
            [ngClass]="{
              'selected-chip-border': currentIndexForEdit! - 1 === i
            }"
          >
            <div
              class="d-flex"
              (click)="
                editMobileNumber(number, i);
                $event.preventDefault();
                $event.stopPropagation()
              "
            >
              {{ number }}
              <button
                matChipRemove
                matTooltip="Remove Number"
                (click)="removeNumber(true, i)"
              >
                <mat-icon class="material-symbols-outlined">cancel</mat-icon>
              </button>
            </div>
          </mat-chip-row>
        </ng-container>

        <ng-container
          *ngFor="let number of allMobileNumbers.invalidNumbers; let i = index"
        >
          <mat-chip-row class="invalid-mobile-number-border">
            <div class="d-flex">
              {{ number }}
              <button
                matChipRemove
                matTooltip="Remove Number"
                (click)="removeNumber(false, i)"
              >
                <mat-icon class="material-symbols-outlined">cancel</mat-icon>
              </button>
            </div>
          </mat-chip-row>
        </ng-container>
        <input
          class="d-none"
          [matChipInputFor]="mobileChipGrid"
          formControlName="mobileNumberChipList"
        />
      </mat-chip-grid>
    </mat-form-field>
    <mat-error *ngIf="allMobileNumbers.invalidNumbers.length > 0">
      Please remove invalid numbers
    </mat-error>
  </div>
</div>

<div *ngIf="!isMultiple" [formGroup]="numberForm" class="single-mobile-input">
  <!-- <mat-form-field appearance="outline" class="d-flex w-100">
    <div class="country-code-select" (click)="openCountryCodeDropdown()">
      <mat-select #countrySelect formControlName="code" required>
        <mat-select-trigger *ngIf="numberForm.get('code')">
          <img width="23px" height="auto" [src]="numberForm.value.code.image" />
          <span>(+ {{ numberForm.value.code.phone_code }})</span>
        </mat-select-trigger>

        <mat-option
          *ngFor="let number of numberList; let i = index"
          [value]="number"
          class="d-flex justify-content-start justify-content-xl-center"
        >
          <span class="fs-6">
            <img [src]="number.image" width="25px" height="auto" />
            (+{{ number.phone_code }})
          </span>
        </mat-option>
      </mat-select>
    </div>

    <mat-label>Phone Number</mat-label>
    <input
      (click)="$event.preventDefault(); $event.stopPropagation()"
      matInput
      formControlName="number"
      placeholder="Enter phone number"
      required
    />
  </mat-form-field> -->

  <mat-form-field appearance="outline" class="d-flex w-100">
    <div class="country-code-select d-flex">
      <input
        matInput
        formControlName="code"
        [matAutocomplete]="auto"
        required
        (click)="$event.preventDefault(); $event.stopPropagation()"
        #trigger="matAutocompleteTrigger"
      />

      <!-- Autocomplete dropdown -->
      <mat-autocomplete
        #auto="matAutocomplete"
        [class]="'single-mobile-input'"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let number of filteredListOfNumbers; let i = index"
          [value]="number"
          class="d-flex justify-content-start justify-content-xl-center"
        >
          <span class="fs-6">
            <img [src]="number.image" width="25px" height="auto" />
            (+{{ number.phone_code }})
          </span>
        </mat-option>
      </mat-autocomplete>
      <button
        type="button"
        mat-icon-button
        matSuffix
        tabindex="-1"
        (click)="selectionMade($event, trigger)"
      >
        <mat-icon class="material-symbols-outlined"> arrow_drop_down </mat-icon>
      </button>
    </div>
    <mat-label>{{ placeHolder }}</mat-label>

    <input
      matInput
      formControlName="number"
      placeholder="Enter phone number"
      required
      (click)="$event.preventDefault(); $event.stopPropagation()"
    />
  </mat-form-field>
  <mat-error *ngIf="numberForm.get('number')?.hasError('invalidNumber')"
    >Enter a valid number</mat-error
  >
</div>
