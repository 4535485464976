import { Component, OnInit } from '@angular/core';
import { filter, indexOf } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ModuleCheckbox } from 'src/app/shared/models/module-checkbox';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss'],
})
export class ModulesComponent implements OnInit {
  public defaultModules = this.commonService.IsMasterOrgUser()
    ? [1022, 1001, 1002, 1003, 1008, 1012]
    : [1022, 1001, 1002, 1003, 1008];
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public userRole: number = this.userService.getLocaluser().role;
  public modules!: ModuleCheckbox[];
  public displayedColumnsModules: string[] = [];
  public defaultColumnsModules: any[] = [
    {
      columnDef: 'module',
      header: 'Module',
      cell: (element: ModuleCheckbox) => `${element.name}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'date',
      header: 'Valid Till',
      cell: (element: ModuleCheckbox) => `${element.expiryDate}`,
      parameter: false,
      selected: false,
    },
  ];

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.displayedColumnsModules = [
      ...this.defaultColumnsModules.map((c) => c.columnDef),
    ];
    this.modules = filter(
      JSON.parse(
        this.localStorageService.getValue(LocalStorageConstants.MODULES)
      ),
      (module) => {
        if (
          this.localStorageService
            .getParsedValue(LocalStorageConstants.MODULE_ACCESS)
            .includes(module.moduleId)
        ) {
          if (
            indexOf(
              this.localStorageService.getParsedValue(
                LocalStorageConstants.OZ_USER
              ).modules,
              module.moduleId
            ) > -1
          ) {
            return module;
          }
        }
      }
    );

    this.modules.forEach((module) => {
      if (
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .moduleExpiry
      ) {
        let date = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        ).moduleExpiry[module['moduleId']!];
        module['expiryDate'] =
          date && !this.defaultModules.includes(module.moduleId)
            ? this.epochTimeToDDMMYY(date)
            : '--';
      }
    });

    CommonUtil.sortModules(this.modules, this.defaultModules);
    this.loadTable.next(true);
  }

  epochTimeToDDMMYY(value: number) {
    return this.customMomentService.formatDatetime({
      epoch: value,
      format: 'DD/MM/YYYY',
    });
  }

  // sortModulesInCustomOrder(data: ModuleCheckbox[]) {
  //   let defaultModules =
  //     this.userRole === 1
  //       ? [1001, 1002, 1003, 1008, 1012]
  //       : [1001, 1002, 1003, 1008];

  //   return data.sort((a, b) => {
  //     let indexA = defaultModules.indexOf(a.moduleId!);
  //     let indexB = defaultModules.indexOf(b.moduleId!);

  //     if (indexA === -1 && indexB === -1) {
  //       return 0;
  //     } else if (indexA === -1) {
  //       return 1;
  //     } else if (indexB === -1) {
  //       return -1;
  //     } else {
  //       return indexA - indexB;
  //     }
  //   });
  // }
}
