import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HeatmapService extends BaseAPIService<any> {
  private dateRangeUpdated: Subject<any> = new Subject();
  public dateRangeUpdated$: Observable<any> =
    this.dateRangeUpdated.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    http: HttpClient,
    cookieService: CookieService
  ) {
    super(http, cookieService);
  }

  public updateDateRange(dateRange: any) {
    this.dateRangeUpdated.next(dateRange);
  }

  getBoundingBox(projectId: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll({
      projectId: projectId,
    });

    return this.get<any>(
      APIConstants.GET_BOUNDING_BOX(projectId),
      headers,
      params
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getHeatMap(projectId: string, gte: number, lte: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll({
      projectId: projectId,
      gte: gte,
      lte: lte,
    });

    return this.get<any>(
      APIConstants.GET_LAYERS(projectId),
      headers,
      params
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPolygonData(
    projectId: string,
    lat: number,
    lon: number,
    gte: number,
    lte: number
  ) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll({
      projectId: projectId,
      gte: gte,
      lte: lte,
      lat: lat,
      lon: lon,
    });

    return this.get<any>(
      APIConstants.GET_POLYGON_DATA(projectId),
      headers,
      params
    ).pipe(
      map((deviceDetailRes: Array<any>) => {
        deviceDetailRes = deviceDetailRes.map((dp) => {
          let d = dp.payload.d;
          delete dp.payload.d;
          return {
            ...dp,
            payload: { d },
          };
        });
        deviceDetailRes = [
          {
            deviceId: deviceDetailRes[0].deviceId ?? 'heatmap',
            deviceType: 'POLLUDRON_PRO',
            payload: deviceDetailRes,
          },
        ];
        let deviceDetails = deviceDetailRes[0].payload;
        deviceDetails = this.updateDeviceDetails(deviceDetails);

        return deviceDetails;
      })
    );
  }

  updateDeviceDetails(
    deviceDetails: Array<DeviceDetails>,
    calculateAQI: boolean = true
  ) {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const units: any =
      this.commonService.getAllUnits()[
        this.deviceService.currentDeviceType.deviceTypeId
      ];

    deviceDetails.forEach((deviceDetail) => {
      if (DeviceUtil.hasValidIndexForDeviceType(deviceDetail, deviceTypes)) {
        const aqiIndex = DeviceUtil.calculateAQI(
          deviceDetail.payload,
          DeviceUtil.getBreakpoints(
            undefined,
            deviceDetail.deviceType,
            deviceTypes,
            allAqi,
            allAqis,
            aqi
          )
        );

        if (calculateAQI && deviceDetail.payload !== undefined) {
          deviceDetail.aqi = aqiIndex.aqi;
          deviceDetail.aqiKey = aqiIndex.aqiKey;
          if (aqiIndex.aqi !== null && aqiIndex.aqi !== undefined) {
            deviceDetail.payload.d['aqi'] = aqiIndex.aqi;
            deviceDetail.payload.d['aqiMessage'] = aqiIndex.aqiMessage;
          }
        }
      }
      deviceDetail.payload = DeviceUtil.convertUnits(
        deviceDetail.payload,
        units!
      );
    });

    return deviceDetails;
  }
}
