<app-data-table
  [data]="modules"
  [columns]="defaultColumnsModules"
  [displayedColumns]="displayedColumnsModules"
  (onSelect)="onModuleSelection()"
  (dateSelected)="onDateSelection($event)"
  [showTableHeader]="false"
  [showPaginator]="false"
  [selection]="moduleSelector"
  [isChartVisible]="false"
>
</app-data-table>
