import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import exportingOption from 'highcharts/modules/exporting';
import offlineOption from 'highcharts/modules/offline-exporting';
import { debounceTime, filter, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { WidgetService } from '../../services/widget.service';
import { WidgetInfo } from '../../widget.component.interface';

require('highcharts/modules/heatmap')(Highcharts);
// require('highcharts/modules/exporting')(Highcharts);
// require('highcharts/modules/offline-exporting')(Highcharts);

exportingOption(Highcharts);
offlineOption(Highcharts);

@Component({
  selector: 'app-aqi-trends-widget',
  templateUrl: './aqi-trends-widget.component.html',
  styleUrls: ['./aqi-trends-widget.component.scss'],
})
export class AqiTrendsWidgetComponent implements OnInit, OnDestroy {
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this.show = v.show;
    this._widgetInfo = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }
  highChartsRef!: any;
  deviceInfo: DeviceDetails | undefined;

  private _deviceData: DeviceDetails[] = [];
  public get deviceData(): DeviceDetails[] {
    return this._deviceData;
  }
  public set deviceData(v: DeviceDetails[]) {
    this._deviceData = v;
    if (v) {
      this.setupConfig();
    }
  }

  fields: DeviceField[] = [];

  private _limits: FieldLimit[] = [];
  public limitsObj: { [key: string]: FieldLimit } = {};
  public get limits(): FieldLimit[] {
    return this._limits;
  }
  public set limits(v: FieldLimit[]) {
    this._limits = v;
    this.limitsObj = Object.fromEntries([
      ...v.map((limit) => [limit.fkey, limit]),
    ]);
    this.setupConfig();
  }

  private _aqiIndexColor!: AqiIndexColorArray;
  public get aqiIndexColor(): AqiIndexColorArray {
    return this._aqiIndexColor;
  }
  public set aqiIndexColor(v: AqiIndexColorArray) {
    this._aqiIndexColor = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  autoRange: boolean = true;
  show = false;

  @Output() parameterSelectionChange: EventEmitter<string>;

  highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  private userTimeFormat: number = 24;

  public deviceFields: DeviceField[] = [];

  private _selectedField: DeviceField | undefined;
  public get selectedField(): DeviceField | undefined {
    return this._selectedField;
  }
  public set selectedField(v: DeviceField | undefined) {
    if (v) {
      this._selectedField = v;
      this.parameterSelectionChange.emit(v.label);
    }
  }

  @Output() graphDataEmpty: EventEmitter<Record<string, any>> =
    new EventEmitter<Record<string, any>>();
  private _noAqiData: boolean = true;
  get noAqiData(): boolean {
    return this._noAqiData;
  }

  set noAqiData(value: boolean) {
    this.graphDataEmpty.emit({
      widgetId: this.widgetInfo.widgetId,
      disableStatus: value,
    });
    this._noAqiData = value;
  }

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef
  ) {
    this.parameterSelectionChange = new EventEmitter<string>();
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;

    this.deviceInfo = this.widgetService.device;
    this.deviceData = this.widgetService.aqiTrendsData;

    this.fields = this.widgetService.fields;
    this.limits = this.widgetService.limits;
    this.aqiIndexColor = this.widgetService.aqiIndexColor;

    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'device': {
              this.deviceInfo = this.widgetService.device;
              break;
            }
            case 'aqiTrendsData': {
              this.deviceData = this.widgetService.aqiTrendsData;
              break;
            }
            case 'fields': {
              this.fields = this.widgetService.fields;
              break;
            }
            case 'limits': {
              this.limits = this.widgetService.limits;
              break;
            }
            case 'aqiIndexColor': {
              this.aqiIndexColor = this.widgetService.aqiIndexColor;
              break;
            }
            default: {
              // console.info('aqi-trend-widget -> key for changed value:', res);
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );

    this.subscriptions.push(
      this.widgetService.redrawWidget$
        .pipe(
          filter((widgetId: number) => this.widgetInfo.widgetId === widgetId),
          debounceTime(300)
        )
        .subscribe({
          next: (widgetId: number) => {
            if (this.widgetInfo.widgetId === widgetId) {
              this.show = false;
              setTimeout(() => {
                this.show = true;
              });
            }
          },
        })
    );

    if (this.selectedField?.label) {
      this.parameterSelectionChange.emit(this.selectedField?.label);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupConfig();
  }

  setupConfig() {
    if (this.deviceData?.length && this.limits?.length && this.aqiIndexColor) {
      this.noAqiData = false;
      this.deviceFields = CommonUtil.getDeviceFields(
        this.deviceData,
        this.fields,
        this.limits,
        true
      ).filter((field) => field.fkey !== 't' && field.fkey !== 'aqiMessage');
      if (this.deviceFields.length) {
        if (
          !this.selectedField?.fkey ||
          !this.deviceFields.find((df) => df.fkey == this.selectedField?.fkey)
        ) {
          this.selectedField = this.deviceFields[0];
        }
        this.setupDataPoints();
      }
    } else {
      this.noAqiData = true;
    }
  }

  setupDataPoints() {
    this.show = false;

    const that = this;

    let xAxisOptions: Highcharts.XAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatTime({
            date: that.customMomentService.moment(this.value, 'H'),
            format: that.userTimeFormat === 12 ? 'hh:mm A' : 'HH:mm',
          });
        },
      },
    };

    let yAxisOptions: Highcharts.AxisOptions = {};
    yAxisOptions.categories = [];

    let dataPoints = [];
    for (let data of this.deviceData) {
      let date: string = this.customMomentService.formatDate({
        epoch: parseInt(data?.payload?.d?.t, 10),
        format: 'D MMM',
      });

      let categoryIndex: number = yAxisOptions.categories.indexOf(date);
      if (categoryIndex < 0) {
        yAxisOptions.categories.push(date);
        categoryIndex = yAxisOptions.categories.indexOf(date);
      }
      let aqi: any | null =
        data.payload.d?.[this.selectedField?.fkey ?? 'aqi'] !== undefined
          ? parseFloat(
              '' + data.payload.d?.[this.selectedField?.fkey ?? 'aqi']!
            )
          : null;
      // let aqi: number = parseFloat(
      //   '' + data.payload.d?.[this.selectedField?.fkey ?? 'aqi']!
      // );
      let j: number = 0;
      for (
        j = 0;
        j < this.limitsObj[this.selectedField?.fkey ?? 'aqi'].range.length;
        j++
      ) {
        if (
          Math.min(
            aqi,
            this.limitsObj[this.selectedField?.fkey ?? 'aqi'].range[j]
          ) === aqi
        ) {
          break;
        }
      }
      j--;
      // if(aqi){
      if (aqi !== null) {
        let colorIndex = aqi === 0 ? 0 : j;
        let dataPoint = {
          x: this.customMomentService.moment
            .unix(parseInt(data?.payload?.d?.t, 10))
            .hour(),
          y: categoryIndex,
          value: aqi,
          color:
            colorIndex <
            this.limitsObj[this.selectedField?.fkey ?? 'aqi'].range.length
              ? this.aqiIndexColor.color[colorIndex]
              : this.aqiIndexColor.outOfRangeColor,
        };
        dataPoints.push(dataPoint);
      }
    }

    this.chartOptions.tooltip = {
      formatter: function () {
        let point: any = this.point || {};
        let value = (point['value'] !== null && point['value'] !== undefined) ? point['value'] : ''; // Check for null or undefined
        return (
          value +
          (that.selectedField?.fkey
            ? (that.selectedField?.unit?.length
                ? ' ' + that.selectedField?.unit + ' '
                : '') +
              ' ' +
              that.selectedField?.label +
              ' at '
            : ' AQI at ') +
          this.point.x +
          `-00 hrs o'clock on ` +
          this.series.yAxis.categories[point['y']]
        );
      },
    };

    this.chartOptions.exporting = {
      // chartOptions: {
      //   title: { text: 'Heat Map chart of Last week' },
      // },
      // buttons: {
      //   contextButton: {
      //     menuItems: [
      //       'printChart',
      //       'separator',
      //       'downloadPNG',
      //       'downloadJPEG',
      //       'downloadPDF',
      //       'downloadSVG',
      //     ],
      //   },
      // },
    };

    this.chartOptions.title = null!;
    this.chartOptions.subtitle = null!;
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.legend = { enabled: false };

    this.chartOptions.exporting = {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      filename: `${
        this.deviceInfo?.label ? `${this.deviceInfo?.label}_` : ''
      }heatmap`,
      chartOptions: {
        title: {
          text: `${
            this.deviceInfo?.label ? `${this.deviceInfo?.label} device's ` : ''
          }Heat Map chart of Last Week`,
        },
        subtitle: {
          text: `${this.selectedField?.label}${
            this.selectedField?.unit?.length
              ? ` (${this.selectedField.unit})`
              : ''
          }`,
        },
        legend: {
          enabled: true,
        } as Highcharts.LegendOptions,
        yAxis: {
          title: {
            text: 'Day',
          },
        },
        xAxis: {
          title: {
            text: 'Time',
          },
        },
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOptions;

    if (!this.chartOptions.chart) {
      this.chartOptions.chart = {};
    }
    this.chartOptions.chart.type = 'heatmap';
    this.chartOptions.chart.events = {
      load: function () {
        that.highChartsRef = this as Highcharts.Chart;
      },
    };
    this.chartOptions.series = [];
    this.chartOptions.series[0] = {
      name: 'AQI Trend',
      data: dataPoints,
      borderWidth: 0.1,
      borderColor: '#37474F',
    } as Highcharts.SeriesOptionsType;
    setTimeout(() => {
      this.show = true;
    });
  }

  changeField(field: DeviceField) {
    this.selectedField = field;
    this.setupDataPoints();
  }

  scrollLeft() {
    let element = document.getElementById(
      'widget-fields-chip-container-live-data'
    );
    element?.scrollTo({ left: element.scrollLeft - element.clientWidth / 1.8 });
  }

  scrollRight() {
    let element = document.getElementById(
      'widget-fields-chip-container-live-data'
    );
    element?.scrollTo({ left: element.scrollLeft + element.clientWidth / 1.8 });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
