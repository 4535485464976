<app-form-container
  [tableTitle]="'Change Password'"
  [submitBtn]="'Update'"
  [cancelBtn]="'Cancel'"
  (submit)="submitForm()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [isValid]="this.form.valid && isConfirmPasswordCorrect"
>
  <div class="row gy-4" [formGroup]="form">
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Current Password</mat-label>
        <input
          type="text"
          [placeholder]="'Current Password'"
          matInput
          formControlName="password"
          autocomplete="new-password"
        />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>New Password</mat-label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          [placeholder]="'New Password'"
          matInput
          formControlName="newPassword"
          autocomplete="new-password"
        />
        <button
          mat-icon-button
          matSuffix
          tabindex="-1"
          (click)="togglePasswordVisibility()"
          matTooltip="{{ showPassword ? 'View' : 'Hide' }}"
        >
          <mat-icon class="material-symbols-outlined">{{
            showPassword ? "visibility" : "visibility_off"
          }}</mat-icon>
        </button>
      </mat-form-field>

      <mat-error
        *ngIf="form.controls['newPassword'].touched && !isNewPasswordCorrect"
        >Password must contain at least one uppercase, lowercase, special
        character and number</mat-error
      >
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Confirm Password</mat-label>
        <input
          type="password"
          [placeholder]="'Confirm Password'"
          matInput
          formControlName="confirmPassword"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          form.controls['confirmPassword'].touched && !isConfirmPasswordCorrect
        "
        >Confirm Password does not match with New Password</mat-error
      >
    </div>
  </div>
</app-form-container>
