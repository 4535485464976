<ng-container *ngIf="!isNonAqiParams">
  <ng-container *ngIf="aqiDetails && aqiData && headers && indexLabel">
    <ng-container *ngIf="tableTitle">
      <div class="pt-3 pb-2">
        <span class="fw-bold fs-5">{{ tableTitle }}</span>
      </div>
    </ng-container>
    <div style="overflow-x: auto">
      <table
        class="w-100 px-3 table-borderless align-items-center justify-content-center"
      >
        <tr class="col-lg-12">
          <th class="table-header justify-content-center align-items-center">
            <span class="p-2 col-lg-3 text-start">
              {{ indexLabel }}
            </span>
          </th>
          <ng-container *ngIf="headers.length > 0">
            <th *ngFor="let header of headers" class="table-header text-center">
              <div [innerHTML]="header?.field"></div>
              <div [innerHTML]="header?.unit"></div>
            </th>
          </ng-container>
        </tr>
        <ng-container>
          <tr
            class="table-data col-lg-12"
            *ngFor="let row of aqiData?.names; let id = index"
            [style.backgroundColor]="aqiDetails.backColor[id]"
          >
            <td class="table-aqi p-2 col-lg-3">
              <b
                ><span class="aqi-text">{{ aqiDetails.rangeName[id] }}</span></b
              >
            </td>
            <td
              *ngFor="let gas of headers"
              class="table-td text-center col-lg-1 p-2"
            >
              <b>
                <span
                  *ngIf="
                    aqiDetails.limit &&
                    aqiDetails.limit[id] &&
                    aqiDetails.limit[id][gas.key]
                  "
                  >{{ aqiDetails.limit[id][gas.key] }}</span
                >
              </b>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="aqiData?.description">
    <tr class="justify-content-center">
      <td>
        <div><span [innerHTML]="aqiData.description"></span></div>
      </td>
    </tr>
  </ng-container>
</ng-container>

<ng-container *ngIf="isNonAqiParams">
  <ng-container *ngIf="aqiDetails && aqiData && headers && indexLabel">
    <ng-container *ngIf="tableTitle">
      <div class="pt-3 pb-2">
        <span class="fw-bold fs-5">{{ tableTitle }}</span>
      </div>
    </ng-container>
    <div style="overflow-x: auto">
      <table
        class="w-100 px-3 table-borderless align-items-center justify-content-center"
      >
        <tr class="col-lg-12">
          <th class="table-header justify-content-center align-items-center">
            <span class="p-2 col-lg-3 text-start">
              {{ indexLabel }}
            </span>
          </th>
          <ng-container *ngIf="headers.length > 0">
            <th *ngFor="let header of headers" class="table-header text-center">
              <div [innerHTML]="header?.field"></div>
              <div [innerHTML]="header?.unit"></div>
            </th>
          </ng-container>
        </tr>
        <ng-container>
          <tr
            class="table-data col-lg-12"
            *ngFor="let row of aqiData?.names; let id = index"
            [style.backgroundColor]="aqiDetails.backColor[id]"
          >
            <td class="table-aqi p-2 col-lg-3">
              <b
                ><span class="aqi-text">{{ aqiData.names[id] }}</span></b
              >
            </td>
            <td
              *ngFor="let gas of headers"
              class="table-td text-center col-lg-1 p-2"
            >
              <b>
                <span
                  *ngIf="
                    aqiDetails.limit &&
                    aqiDetails.limit[id] &&
                    aqiDetails.limit[id][gas.key]
                  "
                  >{{ aqiDetails.limit[id][gas.key] }}</span
                >
              </b>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>
</ng-container>
