import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import {
  Subject,
  Subscription,
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AnnouncementComponent } from 'src/app/shared/components/announcement/announcement.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Complain } from 'src/app/shared/models/complain';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { LatestVersion } from 'src/app/shared/models/latest-version';
import { CommonService } from 'src/app/shared/services/common.service';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { OverviewService } from 'src/app/shared/services/overview.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { WindData } from 'src/app/shared/types/wind';
import { WindDataService } from 'src/app/shared/services/wind-data.service';
import { getWindData } from 'src/app/shared/services/wind-particle-system.service';
import { WindLayerComponent } from 'src/app/shared/components/map-components/wind-layer/wind-layer.component';



import GMap = google.maps.Map;
import MapOptions = google.maps.MapOptions;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GoogleMap) public googleMap!: GoogleMap;
  @ViewChildren('deviceInfoWindow')
  public deviceInfoWindows!: QueryList<MapInfoWindow>;
  @ViewChildren('complainInfoWindow')
  public complainInfoWindows!: QueryList<MapInfoWindow>;
  @ViewChildren('industryInfoWindow')
  public industryInfoWindows!: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) public mapMarkers!: QueryList<MapMarker>;

  popupCounter: number = 0;
  rawAqiLabel: string = AppConstants.RAW_AQI_LABEL;
  ozMapPinColor: string = AppConstants.DEFAULT_APP_COLOR;
  showAqiGauge: boolean = AppConstants.SHOW_RAW_DATA_AQI;
  aqiIndexLabel!: string;
  // devices?: DeviceDetails[];

  private _devices: DeviceDetails[] = [];
  public get devices(): DeviceDetails[] {
    return this._devices;
  }
  public set devices(v: DeviceDetails[]) {
    this._devices = this.processDeviceDetails(v);
  }
  public latLngBounds: google.maps.LatLngBounds | null = null;
  industries: any[] = [];
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  CronJob = require('cron').CronJob;
  currentTimeStamp: number = new Date().getTime() / 1000;
  isComplainModuleIncluded: boolean = false;
  isDeviceCentered: boolean = false;
  isMapPinFixed: boolean = false;
  doc: any;
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;

  subscriptions: Subscription[] = [];
  mapInitialized: boolean = false;
  openPanel: boolean = false;

  temperature?: number;
  humidity?: number;
  windSpeed?: number;
  batteryStatus?: number;
  noiseVal: number[] = [0, 0, 0, 0, 0];
  gasLevels: any[] = [];
  paramColor: any = {};
  complains?: Array<Complain.Get>;
  hasComplainsAccess: boolean = false;
  showWhatsNewPopupAccess: boolean = false;
  selectedDevice?: DeviceDetails;

  complainLayerEnabled: boolean = false;
  industryLayerEnabled: boolean = false;

  iconSize: any = null;
  anchor: any = null;

  selectedIconSize: any = null;
  selectedAnchor: any = null;

  width!: number;
  height!: number;
  private destroy$: Subject<void> = new Subject<void>();
  
  windData: WindData[] | any = [];
  mapInstance: google.maps.Map | null = null
  windLayerEnabled: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
      this.height = (event.target as Window).innerHeight;
    } catch (e) {}
  }

  constructor(
    private localStorageService: LocalStorageService,
    public deviceService: DeviceService,
    private commonService: CommonService,
    public googleMapsService: GoogleMapsService,
    private cd: ChangeDetectorRef,
    private complainsService: ComplainsService,
    private dialog: MatDialog,
    private themeService: NbThemeService,
    private customMomentService: CustomMomentService,
    private overviewService: OverviewService,
    private router: Router,
    private windDataService: WindDataService
  ) {
    this.width = window.innerWidth;
  }

  ngOnInit(): void {
    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() => this.googleMap?.idle),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as unknown as GMap)),
        switchMap(() => this.googleMap!.zoomChanged)
      )
      .subscribe(() => {});

    this.initPopupCounter();
    this.showWhatsNewPopupAccess = this.localStorageService
      .getParsedValue(LocalStorageConstants.POPUP_TYPES)
      .includes(1002);
    this.aqiIndexLabel =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_AQI)
        ?.label || '';

    // const windData = getWindData(20);
    // const windData = this.windDataService.getWindDataForOverview();
    // this.windData = windData?.filter((data) => (data.direction && data.magnitude && data.lat && data.lon) ? true : false);
  }

  ngAfterViewInit(): void {
    this.addSubscriptions();
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName === 'material-dark') {
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };
        if (this.selectedDevice) {
          setTimeout(() => {
            this.focusSelectedDevice();
          });
        } else if (firstTimeLoaded) {
          setTimeout(() => {
            this.fitBounds(this.googleMap.googleMap!);
          });
        }
        firstTimeLoaded = true;
      });
  }

  onMapOptionsUpdated(options: MapOptions) {
    this.options = { ...options };
    this.cd.detectChanges();
  }

  initializeData(map: GMap): void {
    this.initJobs(map);

    this.devices = this.deviceService.registeredDevicesWithVibration
      ? [...this.deviceService.registeredDevicesWithVibration!]
      : [];
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;

    this.latestDocumentSnapshot();
    if (this.devices && this.devices.length > 0) {
      this.fitBounds(this.googleMap.googleMap!);
    }

    this.cd.detectChanges();
  }

  mapReady(map: GMap): void {
    this.mapInitialized = true;
    this.mapInstance = map;

    this.initializeData(map);

    if (this.devices && this.devices.length > 0) {
      this.fitBounds(map);
    }

    this.setSelectedDevice();

    if (
      this.localStorageService
        .getParsedValue(LocalStorageConstants.MODULE_ACCESS)
        .indexOf(2015) >= 0
    ) {
      this.hasComplainsAccess = true;
      this.complainLayerEnabled = false;
    }

    if (this.showWhatsNewPopupAccess === true && this.popupCounter === 0) {
      this.overviewService.getNewFeaturesInformation().subscribe({
        next: (res: Array<LatestVersion.Info>) => {
          if (res) this.showWhatsNewPopUp(res);
        },
        error: (err) => {
          console.info('Error:', err);
        },
      });
    }

    this.iconSize = new google.maps.Size(
      DeviceConstants.MAP_MARKER_SIZE.width,
      DeviceConstants.MAP_MARKER_SIZE.height
    );
    this.anchor = new google.maps.Point(
      DeviceConstants.MAP_MARKER_ANCHOR.x,
      DeviceConstants.MAP_MARKER_ANCHOR.y
    );

    this.selectedIconSize = new google.maps.Size(
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.width,
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.height
    );
    this.selectedAnchor = new google.maps.Point(
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.x,
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.y
    );
  }

  showWhatsNewPopUp(res: Array<LatestVersion.Info>): void {
    let config = {};
    if (window.innerWidth < 768) {
      config = {
        data: res,
        width: '80%',
        height: '75%',
      };
    } else if (window.innerWidth <= 1024) {
      config = {
        data: res,
        width: '75%',
        height: '75%',
      };
    } else if (window.innerWidth <= 1280) {
      config = {
        data: res,
        width: '65%',
        height: '75%',
      };
    } else {
      config = {
        data: res,
        width: '50%',
        height: '75%',
      };
    }

    this.dialog
      .open(AnnouncementComponent, config)
      .afterClosed()
      .subscribe((result) => {
        this.localStorageService.saveValue(
          LocalStorageConstants.POPUP_COUNTER,
          this.localStorageService.getParsedValue(
            LocalStorageConstants.POPUP_COUNTER
          ) + 1
        );
      });
  }

  fitBounds(
    map: GMap,
    bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds()
  ): void {
    const latLngBounds = new google.maps.LatLngBounds();
    latLngBounds.union(bounds);
    const markers = this.devices!.map((device) => {
      if (
        device.payload.d.lat &&
        device.payload.d.lon &&
        device.payload.d.lat !== 0 &&
        device.payload.d.lon !== 0
      ) {
        return {
          lat: device.payload.d.lat,
          lng: device.payload.d.lon,
        };
      } else {
        return {
          lat: device.latitude,
          lng: device.longitude,
        };
      }
    });

    markers.forEach((marker) => {
      latLngBounds.extend(marker);
    });
    this.latLngBounds = latLngBounds;
    map.fitBounds(latLngBounds);
  }

  addSubscriptions(): void {
    const localStorageDeviceUpdate: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe((res) => {
        if (this.deviceService.currentDevice) {
          if (
            this.selectedDevice?.deviceId !==
            this.deviceService.currentDevice?.deviceId
          ) {
            this.setSelectedDevice();
          }
        } else {
          this.selectedDevice = undefined;
          this.latestDocumentSnapshot();
          this.closeInfoWindows();
          this.fitBounds(this.googleMap.googleMap!);
        }
      });
    const captureLatestDocSnapshot: Subscription =
      this.deviceService.mqttDocsSnapshot$.subscribe((doc: any) => {
        if (this.deviceService.mqttDocs) {
          this.latestDocumentSnapshot(doc.deviceId);
        }
      });

    const getDevices: Subscription = this.deviceService.getDevices$.subscribe(
      () => {
        this.devices = [...this.deviceService.registeredDevicesWithVibration!];
        if (
          this.deviceService.mqttDocs &&
          this.devices &&
          this.devices.length !== 0 &&
          this.fields
        ) {
          this.latestDocumentSnapshot();
          this.fitBounds(this.googleMap.googleMap!);
        }
      }
    );

    const getFields: Subscription = this.deviceService.getFields$.subscribe(
      () => {
        this.fields = this.deviceService.fields;
      }
    );

    const getLimits: Subscription = this.deviceService.getLimits$.subscribe(
      () => {
        this.limits = this.deviceService.limits;
      }
    );

    //Set the updated list of device when user changes tag from header dropdown
    const updatedDevices = this.overviewService.onHeaderTagChange$.subscribe(
      (deviceList) => {
        this.devices = deviceList;
      }
    );

    // const device = this.deviceService.localStorageDeviceUpdate$.subscribe(
    //   () => {
    //     this.selectedDevice = this.deviceService.currentDevice;
    //   }
    // );

    // this.subscriptions.push(device);
    this.subscriptions.push(updatedDevices);
    this.subscriptions.push(getDevices);
    this.subscriptions.push(getFields);
    this.subscriptions.push(getLimits);
    this.subscriptions.push(captureLatestDocSnapshot);
    this.subscriptions.push(localStorageDeviceUpdate);
  }

  setSelectedDevice() {
    this.selectedDevice = this.deviceService.currentDevice || undefined;
    if (
      this.selectedDevice?.deviceId &&
      this.deviceService.mqttDocs &&
      this.devices
    ) {
      this.latestDocumentSnapshot();
      this.focusSelectedDevice();
    } else {
      this.closeInfoWindows();
    }
  }

  focusSelectedDevice() {
    let map: GMap = this.googleMap.googleMap!;
    if (map) {
      const latLngBounds = new google.maps.LatLngBounds();
      const markers = [this.selectedDevice!].map((device) => {
        if (
          device.payload.d.lat &&
          device.payload.d.lon &&
          device.payload.d.lat !== 0 &&
          device.payload.d.lon !== 0
        ) {
          return {
            lat: device.payload.d.lat,
            lng: device.payload.d.lon,
          };
        } else {
          return {
            lat: device.latitude,
            lng: device.longitude,
          };
        }
      });

      markers.forEach((marker) => {
        latLngBounds.extend(marker);
      });

      map.fitBounds(latLngBounds);
    }
  }

  initPopupCounter(): void {
    if (
      !this.localStorageService.getParsedValue(
        LocalStorageConstants.POPUP_COUNTER
      )
    ) {
      this.localStorageService.saveValue(
        LocalStorageConstants.POPUP_COUNTER,
        '0'
      );
    }
    this.popupCounter = this.localStorageService.getParsedValue(
      LocalStorageConstants.POPUP_COUNTER
    );
  }

  initJobs(map: GMap): void {
    // const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_ALL_DEV_TYPE
    // );
    // const allAqi: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_ALL_AQI
    // );
    // const allAqis: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_ALL_AQIS
    // );
    // const aqi: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_AQI
    // );

    let that = this;
    if (this.devices) {
      new this.CronJob(
        '0/5 * * * * *',
        () => {
          that.currentTimeStamp = this.customMomentService.moment().unix();
          that.devices?.forEach((device) => {
            const deviceType = DeviceUtil.findDeviceType(
              device.deviceType,
              that.commonService.getUserDeviceTypes()
            );
            // DeviceUtil.getColorForPin(
            //   device,
            //   deviceType,
            //   deviceType ? deviceType.index : false,
            //   this.deviceService.mqttDocs,
            //   this.currentTimeStamp,
            //   this.showAqiGauge,
            //   this.ozMapPinColor,
            //   this.isMapPinFixed,
            //   deviceTypes,
            //   allAqi,
            //   allAqis,
            //   aqi,
            //   this.selectedDevice?.deviceId == device.deviceId
            // );
          });
        },
        null,
        true
      );
    }
  }

  latestDocumentSnapshot(deviceId?: string): void {
    if (
      this.deviceService.mqttDocs &&
      this.devices &&
      this.devices.length > 0 &&
      this.fields
    ) {
      this.isDeviceCentered = false;

      this.doc = this.selectedDevice?.deviceId
        ? this.deviceService.mqttDocs[this.selectedDevice?.deviceId]
        : undefined;
      if (this.doc !== undefined && this.doc.latitude && this.doc.longitude) {
        const latLngBounds = new google.maps.LatLng(
          parseFloat(this.doc.latitude),
          parseFloat(this.doc.longitude)
        );
        if (this.mapInitialized) {
          this.googleMap.center = latLngBounds;
        }
      }

      this.closeInfoWindows();

      const parameters: any[] = [];
      const filteredArray = [];

      if (deviceId) {
        this.setUpMapData(
          this.devices.find((device) => device.deviceId === deviceId)!
        );
      } else {
        this.devices.forEach((device) => this.setUpMapData(device));
      }

      // this.devices = [...(this.devices ?? [])];

      if (this.doc !== undefined) {
        const deviceTypes: DeviceType[] =
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_DEV_TYPE
          );

        if (this.doc.payload && this.doc.payload.d) {
          this.temperature = this.doc.payload.d.temp
            ? Math.round(this.doc.payload.d.temp)
            : undefined;
          this.humidity =
            this.doc.payload.d.hum || this.doc.payload.d.hum >= 0
              ? Math.round(this.doc.payload.d.hum)
              : undefined;
          this.windSpeed =
            this.doc.payload.d.ws || this.doc.payload.d.ws >= 0
              ? Math.round(this.doc.payload.d.ws)
              : undefined;
          this.batteryStatus =
            this.doc.payload.d.bs || this.doc.payload.d.bs >= 0
              ? Math.round(this.doc.payload.d.bs)
              : undefined;
          this.noiseVal = this.doc.payload.d.noise
            ? this.doc.payload.d.noise
            : this.doc.payload.d.leq
            ? [
                this.doc.payload.d.leq,
                this.doc.payload.d.lmax,
                this.doc.payload.d.lmin,
                this.doc.payload.d.l90,
                this.doc.payload.d.l10,
              ]
            : undefined;
          const allKeys = Object.keys(this.doc.payload.d);
          const allUnits: any = this.commonService.getAllUnits();
          // merged the above two loops into single loop
          const existingParams = new Set(parameters.map((param) => param.name));

          const allFields = new Set([
            ...DeviceConstants.MAP_FIELD_ARRAY,
            ...allKeys,
          ]);

          allFields.forEach((field) => {
            if (existingParams.has(field)) return;

            const isValidField = allKeys.includes(field);
            const availableField = this.fields
              .filter((f) => f.isVisible)
              .find((f) => f.fkey === field);

            if (availableField?.isVisible === true && isValidField) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                deviceTypes
              );

              parameters.push({
                name: field,
                value: this.doc.payload.d[field],
                label: DeviceUtil.getFieldName(field, this.fields),
                unit: DeviceUtil.getFieldUnit(
                  field,
                  undefined,
                  allUnits[deviceTypeId!]
                ),
              });
              existingParams.add(field);
            }
          });
        }

        this.gasLevels = [
          ...new Set(
            parameters.filter(
              (param) => param.label !== 'T' && param.label !== 'Battery'
              // &&
              // !param.name.endsWith('aqi')
            )
          ),
        ];
        this.paramColor = {};

        const allAqi: any = this.commonService.getAllAQI();
        const allAqis: any = this.commonService.getAllAQIs();

        //get the device type id of device
        let deviceTypeId: number;
        if (this.deviceService.registeredDevicesWithVibration) {
          const dId = this.selectedDevice?.deviceId ?? deviceId;
          if (dId) {
            const typeId = DeviceUtil.getDeviceTypeIdByDeviceId(
              deviceTypes,
              this.deviceService.registeredDevicesWithVibration!,
              dId
            );

            if (typeId) deviceTypeId = typeId;
            else {
              console.info('getDeviceTypeIdByDeviceId() returned undefined');
            }
          }
        }

        this.gasLevels.forEach((gasLevel) => {
          this.paramColor[gasLevel.name] = DeviceUtil.getParamColor(
            DeviceUtil.getLimitClass(
              gasLevel.name,
              gasLevel.value,
              this.deviceService.limits
            ),
            deviceTypes,
            allAqi,
            allAqis,
            undefined,
            deviceTypeId
          );
        });
      }
    }
  }

  fetchComplains(): void {
    this.isComplainModuleIncluded = this.localStorageService
      .getParsedValue(LocalStorageConstants.OZ_USER)
      .modules.includes(2015);
    if (
      this.localStorageService
        .getParsedValue(LocalStorageConstants.MODULE_ACCESS)
        .includes(2015) &&
      this.isComplainModuleIncluded === true
    ) {
      const orgId = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).org;
      this.complainsService
        .getComplainsByOrganization(orgId, undefined, undefined)
        .subscribe((complains) => {
          if (complains.length > 0) {
            complains.forEach((complain) => {
              if (complain.priority === 'HIGH') {
                complain.iconUrl = 'assets/images/pins/complain-red.png';
              }
              if (complain.priority === 'MEDIUM') {
                complain.iconUrl = 'assets/images/pins/complain-yellow.png';
              }
              if (complain.priority === 'LOW') {
                complain.iconUrl =
                  'assets/images/pins/complain-pin-neutral.png';
              }
            });
            this.complains = complains;
            this.fitBounds(this.googleMap.googleMap!);
          }
        });
    }
  }

  setUpMapData(device: DeviceDetails) {
    // const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_ALL_DEV_TYPE
    // );
    // const allAqi: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_ALL_AQI
    // );
    // const allAqis: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_ALL_AQIS
    // );
    // const aqi: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_AQI
    // );

    device.aqi = this.deviceService.mqttDocs[device?.deviceId]?.aqi;
    device.payload = this.deviceService.mqttDocs[device?.deviceId]?.payload;
    device.lastUpdated = this.deviceService.mqttDocs[device?.deviceId]?.time;

    // const deviceType = DeviceUtil.findDeviceType(
    //   device.deviceType,
    //   this.commonService.getUserDeviceTypes()
    // );

    // DeviceUtil.getColorForPin(
    //   device,
    //   deviceType,
    //   deviceType ? deviceType.index : false,
    //   this.deviceService.mqttDocs,
    //   this.currentTimeStamp,
    //   this.showAqiGauge,
    //   this.ozMapPinColor,
    //   this.isMapPinFixed,
    //   deviceTypes,
    //   allAqi,
    //   allAqis,
    //   aqi,
    //   pinSelected
    // );
    // }
  }

  openInfoWindow(
    marker: MapMarker,
    index: number,
    type: string,
    device?: DeviceDetails,
    complain?: Complain.Get,
    selectDevice: boolean = true
  ): void {
    this.closeInfoWindows();
    switch (type) {
      case 'device':
        this.openPanel = true;
        if (device && selectDevice) {
          this.deviceService.setupDeviceIdGlobally(device.deviceId);
        }
        break;
      case 'complain':
        if (navigator.geolocation) {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            { location: { lat: complain!.latitude, lng: complain!.longitude } },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results) {
                  const result = results[0];
                  result.address_components[1]
                    ? (complain!.location =
                        result.address_components[1].short_name)
                    : (complain!.location =
                        result.address_components[0].short_name);
                }
              } else if (
                status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT
              ) {
                complain!.location = 'N/A';
              }
            }
          );
        }
        this.complainInfoWindows.toArray()[index].open(marker, false);
        break;
      case 'industry':
        this.industryInfoWindows.toArray()[index].open(marker, false);
        break;
    }
  }

  clearDevice() {
    this.deviceService.removeDeviceGlobally();
  }

  closeInfoWindows(): void {
    this.openPanel = false;
    this.deviceInfoWindows.toArray().forEach((iw) => iw.close());
    this.complainInfoWindows.toArray().forEach((iw) => iw.close());
    this.industryInfoWindows.toArray().forEach((iw) => iw.close());
  }

  getBatteryIcon(battery: string): string {
    return CommonUtil.getBatteryIcon(battery);
  }

  onLayerToggle({ state, name }: { state: boolean; name: string }) {
    if (name === 'industry') {
      this.toggleIndustryLayer(state);
    } else if (name === 'complain') {
      this.complainLayerEnabled = state;
      if (state) {
        this.fetchComplains();
      }
    }
  }

  toggleIndustryLayer(state: boolean) {
    this.industryLayerEnabled = state;
    if (this.industryLayerEnabled) {
      const request = {
        location: { lat: 23.0225, lng: 72.5714 },
        query: 'find industries near me',
        keyword: 'industry',
        types: ['name', 'geometry'],
        radius: 5000000,
      };

      const service = new google.maps.places.PlacesService(
        this.googleMap.googleMap!
      );

      if (this.industries.length <= 0) {
        service.nearbySearch(request, (results, status, pagination) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (pagination?.hasNextPage) {
              pagination.nextPage();
            }

            results?.forEach((result) => {
              const place = {
                name: result.name,
                geometry: result.geometry,
                address: result.vicinity,
                rating: result.rating,
                photo: this.getIndustryImage(result),
              };
              this.industries.push(place);
            });
            this.industries = [...this.industries];
          } else if (
            status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
          ) {
            console.info('API limit exceeded. Please contact developer.');
          }
        });
      }
    }
  }

  getIndustryImage(result: google.maps.places.PlaceResult): string | undefined {
    return typeof result.photos !== 'undefined'
      ? result.photos[0].getUrl({ maxWidth: 150, maxHeight: 150 })
      : result.icon;
  }

  getFormattedDate(time: number | undefined): string {
    let date = '';
    date = this.customMomentService.formatDatetime({
      epoch: time,
      format: this.commonService.getDateTimeFormat(),
    });
    return date;
  }

  navigateToAnotherModule(routeName: string, deviceId?: string) {
    switch (routeName) {
      case 'dashboard':
        this.routeTo('dashboard/widget/' + deviceId);
        break;
      case 'analytics':
        this.routeTo(routeName);
        break;
      case 'reports':
        this.routeTo(routeName);
        break;
    }
  }

  routeTo(moduleName: string) {
    this.router.navigate([`/${moduleName}`]);
  }

  //set up pin in the device whenever devices are set in the device array
  private processDeviceDetails(v: DeviceDetails[]): DeviceDetails[] {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.commonService.getAllAQI();
    const allAqis: any = this.commonService.getAllAQIs();
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );

    return v.map((res) => {
      const deviceType = DeviceUtil.findDeviceType(
        res.deviceType,
        this.commonService.getUserDeviceTypes()
      );
      res.otherInfo = DeviceUtil.getColorForDevicePin(
        res,
        deviceType,
        deviceType ? deviceType.index : false,
        this.deviceService.mqttDocs,
        this.currentTimeStamp,
        this.showAqiGauge,
        this.ozMapPinColor,
        this.isMapPinFixed,
        deviceTypes,
        allAqi,
        allAqis,
        aqi
      );
      return res;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
