<div class="h-100 w-100 card-container">
  <nb-card [id]="widgetInfo.widgetId" class="h-100 rounded-3">
    <nb-card-header
      [ngStyle]="{
        paddingRight: widgetInfo.optionsMenu?.options ? '0.25rem' : '1rem'
      }"
    >
      <div class="d-flex align-items-center w-100">
        <span class="title">{{ widgetInfo.widgetTitle }}</span>
        <span class="ps-1 small fw-normal">
          {{ widgetInfo.widgetSubTitle }}
        </span>
        <div class="flex-grow-1"></div>
        <div class="d-flex align-items-center gap-2">
          <ng-container *ngIf="widgetInfo?.chartTypeConfig">
            <div
              class="flex-grow-1 d-flex justify-content-end align-items-center"
            >
              <mat-button-toggle-group
                [(ngModel)]="chartType"
                class="chart-type-button-toggle-group"
              >
                <ng-container
                  *ngFor="
                    let option of widgetInfo.chartTypeConfig?.options ?? []
                  "
                >
                  <mat-button-toggle
                    [value]="option.value"
                    [matTooltip]="option.label"
                  >
                    <mat-icon>{{ option?.icon }}</mat-icon>
                  </mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </div>
          </ng-container>
          <ng-container *ngIf="widgetInfo?.directionConfig">
            <div
              class="flex-grow-1 d-flex justify-content-end align-items-center"
            >
              <button
                mat-raised-button
                color="primary"
                [matMenuTriggerFor]="menuWindRose"
                class="mat-direction-button-dropdown"
              >
                <span
                  class="d-flex align-items-center justify-content-between h-100"
                >
                  {{
                    widgetInfo.directionConfig?.selectedValue === "to"
                      ? "Direction To"
                      : "Direction From"
                  }}
                  <mat-icon>arrow_drop_down</mat-icon>
                </span>
              </button>
              <mat-menu #menuWindRose="matMenu">
                <ng-container
                  *ngFor="
                    let option of widgetInfo.directionConfig?.options ?? []
                  "
                >
                  <button mat-menu-item (click)="selectDirection(option)">
                    <span>
                      {{ option.label }}
                    </span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </ng-container>
          <ng-container *ngIf="widgetInfo?.optionsMenu">
            <div
              class="flex-grow-1 d-flex justify-content-end align-items-center"
            >
              <button
                mat-icon-button
                color="primary"
                [matMenuTriggerFor]="optionsMenu"
                class="mat-more-options-icon-button"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #optionsMenu="matMenu">
                <ng-container
                  *ngFor="
                    let option of widgetInfo.optionsMenu?.options ?? [];
                    let i = index
                  "
                >
                  <button mat-menu-item (click)="onOptionClick(option, i)">
                    <ng-container *ngIf="option.showCheckbox">
                      <mat-checkbox
                        color="primary"
                        [value]="option.value"
                        [checked]="option.state"
                      >
                        {{ option.label }}
                      </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="!option.showCheckbox">
                      <span>
                        {{ option.label }}
                      </span>
                    </ng-container>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </ng-container>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <ng-container #container></ng-container>
    </nb-card-body>
  </nb-card>
</div>
