import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() refreshEvent!: EventEmitter<boolean>;
  rawAqiLabel: string = AppConstants.RAW_AQI_LABEL;
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  subscriptions: Subscription[] = [];
  devices!: DeviceDetails[];
  currentDate!: number;
  allParameters!: any[];
  displayedColumns: string[] = [];
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  columns: any[] = [];
  allKeysOfAllDevices: DeviceField[] = [];

  defaultColumns: any[] = [
    {
      columnDef: 'deviceId',
      header: 'Device Id',
      cell: (element: DeviceDetails) => `${element.deviceId}`,
      parameter: false,
      selected: false,
      filter: false,
    },
    {
      columnDef: 'label',
      header: 'Device Name',
      cell: (element: DeviceDetails) => `${element.label}`,
      parameter: false,
      selected: false,
      filter: false,
    },
    {
      columnDef: 'loc',
      header: 'Location',
      cell: (element: DeviceDetails) => `${element.loc}`,
      parameter: false,
      selected: false,
      filter: false,
    },
    {
      columnDef: 'status',
      header: 'Status',
      icon: (element: DeviceDetails) => `${element.status.icon}`,
      chargingOn: (element: DeviceDetails) => {
        return element?.chargingOn ? true : false;
      },
      isOutOfNetwork: (element: DeviceDetails) => {
        return element?.isOutOfNetwork ? true : false;
      },
      color: (element: DeviceDetails) => `${element.status.color}`,
      msg: (element: DeviceDetails) => `${element.status.msg}`,
      parameter: false,
      selected: false,
      filter: false,
    },
    {
      columnDef: 'time',
      header: 'Time',
      cell: (element: DeviceDetails) =>
        `${
          element.lastUpdated
            ? this.customMomentService.formatDatetime({
                epoch: element.lastUpdated,
                format: this.commonService.getDateTimeFormat(),
              })
            : 'Device Offline'
        }`,
      parameter: false,
      selected: false,
      filter: false,
    },
  ];

  constructor(
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private customMomentService: CustomMomentService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.refreshEvent) {
      this.refreshEvent.subscribe((isLoading: boolean) => {
        this.loadTable.next(!isLoading);
        this.loadData(this.deviceService.currentDeviceType);
      });
    } else {
      this.initializeData();
    }
  }

  ngAfterViewInit(): void {
    this.addSubscriptions();
  }

  initializeData(): void {
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;
    if (!CommonUtil.isEmpty(this.deviceService.currentDeviceType)) {
      this.loadData(this.deviceService.currentDeviceType);
    }
  }

  addSubscriptions(): void {
    const captureLatestDocSnapshot: Subscription =
      this.deviceService.mqttDocsSnapshot$
        .pipe(
          debounceTime(5000) // Wait for 5 seconds of inactivity after a burst
        )
        .subscribe((doc: any) => {
          if (this.deviceService.mqttDocs) {
            this.loadData(this.deviceService.currentDeviceType);
          }
        });

    const getFields: Subscription = this.deviceService.getFields$.subscribe(
      () => {
        this.fields = this.deviceService.fields;
      }
    );

    const getLimits: Subscription = this.deviceService.getLimits$.subscribe(
      () => {
        this.limits = this.deviceService.limits;
      }
    );

    const localStorageDeviceUpdate: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe((deviceType) => {
        this.loadData(deviceType);
      });

    this.subscriptions.push(getFields);
    this.subscriptions.push(getLimits);
    this.subscriptions.push(localStorageDeviceUpdate);
    this.subscriptions.push(captureLatestDocSnapshot);
  }

  loadData(deviceType: any): void {
    this.loadTable.next(false);
    this.currentDate = this.customMomentService.moment().unix();
    if (
      this.deviceService.mqttDocs &&
      this.deviceService.registeredDevicesWithVibration
    ) {
      this.devices = [];

      [...this.deviceService.registeredDevicesWithVibration].forEach(
        (device) => {
          if (device.payload.d.t > this.currentDate - 60 * 60) {
            if (device.payload.d.current > -50) {
              device.chargingOn = true;
            }
            device.status = {
              color: '#4CAF50',
              msg: DeviceConstants.ONLINE_STATUS,
              icon: 'done',
              index: 1,
            };
          }
          if (
            device.payload.d.t < this.currentDate - 60 * 60 &&
            device.payload.d.t > this.currentDate - 24 * 3600
          ) {
            device.status = {
              color: '#fcdc01',
              msg: DeviceConstants.NOT_CONNECTED_STATUS,
              icon: 'exclamation',
              index: 1,
            };
          }
          if (device.payload.d.t < this.currentDate - 24 * 3600) {
            device.status = {
              color: '#D32F2F',
              msg: DeviceConstants.OFFLINE_STATUS,
              icon: 'exclamation',
              index: 2,
            };
          }
          if (device.payload.d.t > this.currentDate - 60 * 60) {
            if (device.payload.d.current < -50) {
              device.chargingOn = false;
            }
          }
          if (device.payload.d.t < this.currentDate - 4 * 3600) {
            if (device.payload.d.bs > 45) {
              device.isOutOfNetwork = true;
            }
          }
          if (device.deviceType === deviceType.key) {
            this.devices.push(device);
          }
        }
      );
      this.latestDocumentSnapshot();
    }
  }

  latestDocumentSnapshot(): void {
    this.allParameters = [];
    this.allKeysOfAllDevices = [];
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const allUnits: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );

    this.devices.forEach((device) => {
      const mqttDocs = this.deviceService.mqttDocs[device.deviceId];

      if (mqttDocs) {
        device.aqi = mqttDocs.aqi;
        device.payload = mqttDocs.payload;
        device.lastUpdated = mqttDocs.time;
        if (
          device.payload &&
          device.payload.d &&
          device.payload.d.t !== undefined
        ) {
          device.lastUpdated = device.payload.d.t;
          device.parameters = {};

          const allKeys = Object.keys(device.payload.d);
          for (let df of allKeys) {
            let field: DeviceField = this.fields.find((f) => f?.fkey == df)!;
            if (field && field.fkey !== 't') {
              this.allKeysOfAllDevices.push(field!);
            }
          }
        }
      }
    });

    this.allKeysOfAllDevices = CommonUtil.setParameterSequence(
      this.allKeysOfAllDevices
    );

    this.allKeysOfAllDevices.forEach((key) => {
      const fieldIndex = this.allParameters.findIndex(
        (param) => param.name === key.fkey
      );
      const field = this.fields
        .filter((field) => field.isVisible)
        .find((field) => field.fkey === key.fkey);
      const deviceTypeId = DeviceUtil.getDeviceTypeId(
        this.deviceService.currentDeviceType.key,
        deviceTypes
      );

      if (field?.isVisible === true && fieldIndex < 0 && key.fkey !== 't') {
        this.allParameters.push({
          name: key.fkey,
          label: DeviceUtil.getFieldName(key.fkey, this.fields),
          units: DeviceUtil.getFieldUnit(
            key.fkey,
            undefined,
            allUnits[deviceTypeId!]
          ),
          colors: (device: DeviceDetails) =>
            DeviceUtil.getParamColor(
              DeviceUtil.getLimitClass(
                key.fkey,
                device.payload.d[key.fkey],
                this.deviceService.limits
              ),
              deviceTypes,
              allAqi,
              allAqis
            ),
        });
      }

      this.devices.forEach((device) => {
        const mqttDocs = this.deviceService.mqttDocs[device.deviceId];

        if (mqttDocs) {
          device.aqi = mqttDocs.aqi;
          device.payload = mqttDocs.payload;
          device.lastUpdated = mqttDocs.time;

          device.parameters[key.fkey] = {
            key: key.fkey,
            label: DeviceUtil.getFieldName(key.fkey, this.fields),
            units: DeviceUtil.getFieldUnit(
              key.fkey,
              undefined,
              allUnits[deviceTypeId!]
            ),
            color: DeviceUtil.getParamColor(
              DeviceUtil.getLimitClass(
                key.fkey,
                device.payload.d[key.fkey],
                this.deviceService.limits
              ),
              deviceTypes,
              allAqi,
              allAqis
            ),
            value: device.payload.d[key.fkey],
          };
        }
      });
    });

    this.columns = [];
    this.displayedColumns = [];
    this.columns = [...this.defaultColumns];

    this.displayedColumns = [...this.columns.map((c) => c.columnDef)];

    this.generateCustomColumns();
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  generateCustomColumns(): void {
    this.allParameters.forEach((param) => {
      this.columns.push({
        columnDef: param.name,
        header: Boolean(param.units?.replaceAll(' ', ''))
          ? `${param.label} <br><small>${param.units}</small>`
          : `${param.label}`,
        cell: (element: DeviceDetails) =>
          `${element.parameters[param.name]?.value ?? ''}`,
        parameter: true,
        selected: false,
        filter: true,
        color: param.colors,
      });
      this.displayedColumns.push(param.name);
    });
  }
  exportTableDataToCSV(): void {
    const csvRows = [];
    // const headers = this.columns.map((field) => field.header);
    const headers = this.columns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));
    let data: string[] = [];
    this.devices.forEach((device) => {
      data = [
        device.deviceId,
        device.label,
        `"${device.loc}"`,
        device.status.msg,
        `"${this.customMomentService.formatDatetime({
          epoch: device.lastUpdated,
          format: 'hh:mm A, DD/MM/YY',
        })}"`,
      ];

      this.allParameters.forEach((param) => {
        if (param.units) {
          data.push(device.parameters[param.name]?.value || '');
        }
      });
      csvRows.push(data.join(','));
    });
    const fileName =
      this.localStorageService.getValue('u-org-name') +
      '_' +
      this.customMomentService.formatDatetime({ format: 'DD_MM_YYYY_hh_mm_A' });
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
