import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationComponent } from './configuration.component';
import { SensorListComponent } from './pages/sensor-list/sensor-list.component';
import { LANListComponent } from './pages/lan-list/LAN-list.component';

const routes: Routes = [
  { path: '', 
  component: ConfigurationComponent , 
  children: [
    {
      path: '',
      redirectTo: 'sensor',
      pathMatch: 'full',
    },
    {
      path: 'sensor',
      component: SensorListComponent,
    },
    {
      path: 'connectivity',
      component: LANListComponent,
    },
  ],
  
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
