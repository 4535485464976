import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DEVICE_MENU } from 'src/app/shared/constants/app-menu';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LogBookFormComponent } from './pages/log-book/components/log-book-form/log-book-form.component';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  menu = DEVICE_MENU;
  deviceTypes!: Array<DeviceType>;
  headerForm!: FormGroup;
  public subscriptions: Subscription[] = [];
  public availableLogTypes = AppConstants.DEVICE_LOG_TYPES;
  public devices = this.deviceService.registeredDevicesWithVibration!;
  public allDeviceIds: string[] = [];
  public deviceTags: string[] = [];
  currentlySelectedDevice!: DeviceDetails;

  constructor(
    private formsService: FormsService,
    private commonService: CommonService,
    private fb: FormBuilder,
    public deviceService: DeviceService,
    private customMomentService: CustomMomentService
  ) {}

  ngOnInit(): void {
    this.allDeviceIds = this.devices.map((res) => res.deviceId);
    this.deviceTypes = this.commonService.getUserDeviceTypesWithVibration();
    this.deviceTags = this.deviceService.getUniqueDeviceTags(this.devices);
    this.currentlySelectedDevice = this.devices[0];
    this.buildForm();
    this.subscribeToFormControls();
    this.addSubscriptions();
  }

  buildForm() {
    this.headerForm = this.fb.group({
      deviceType: [this.deviceService?.currentDeviceType || ''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      logType: ['', Validators.required],
      deviceId: ['', Validators.required],
    });

    const endDate = this.customMomentService.moment();
    const startDate = this.customMomentService
      .moment()
      .subtract(1, 'day')
      .startOf('day');
    const selectedTypes = this.availableLogTypes.map((res) => res.value);
    const devices = this.deviceService.registeredDevicesWithVibration?.map(
      (res) => res.deviceId
    );

    this.headerForm.patchValue({
      deviceId: devices,
      startDate: startDate,
      endDate: endDate,
      logType: selectedTypes,
    });

    this.onApply();
  }

  // Sets the selected device globally when the user clicks on a device in the header dropdown
  onDeviceSelectionInLogBook(devices: DeviceDetails[]) {
    if (devices.length) {
      this.headerForm
        .get('deviceId')
        ?.setValue(devices.map((device) => device.deviceId));
    } else {
      this.headerForm.get('deviceId')?.setValue(undefined);
    }
  }

  // Emits the updated device list when the user selects or deselects tags from the header dropdown
  // onTagSelection(devices: DeviceDetails[]) {
  //   this.deviceService.onHeaderTagChange.next(devices);
  // }

  subscribeToFormControls() {
    let devicetype = this.headerForm.controls.deviceType.valueChanges.subscribe(
      (type) => {
        if (type) {
          let devices =
            this.deviceService.registeredDevicesWithVibration?.filter(
              (device) => device.deviceType === type.key
            ) || [];
          if (devices && devices.length) {
            this.deviceService.setupDeviceIdGlobally(devices[0]?.deviceId);
          }
        }
      }
    );

    this.subscriptions.push(devicetype);
  }

  addSubscriptions() {
    let dt = this.deviceService.deviceCountOfEachDeviceType$.subscribe(
      (res) => {
        if (Object.keys(res).length) {
          Object.keys(res).forEach((deviceTypeKey) => {
            let deviceType = this.deviceTypes.find(
              (dt) => dt.key === deviceTypeKey
            );

            if (deviceType) {
              deviceType['count'] = res[deviceTypeKey];
            }
          });
        }
      }
    );
    this.subscriptions.push(dt);
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  getDeviceType(deviceType: DeviceType): string {
    return deviceType ? deviceType?.label?.trim() : '';
  }

  get paramsControl(): FormControl {
    return this.headerForm.get('deviceId') as FormControl;
  }

  onApply() {
    this.deviceService.formData.next(this.headerForm.value);
  }

  openLogForm() {
    this.formsService.openForm(LogBookFormComponent, undefined, 'sm');
  }

  isListView() {
    return window.location.href.split('/').includes('devices-list');
  }

  isLogView() {
    return window.location.href.split('/').includes('log-book');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
