function f() {
  return class extends google?.maps?.OverlayView {
    private _baseFrameInterval: number = 500;
    private get baseFrameInterval(): number {
      return this._baseFrameInterval;
    }
    private set baseFrameInterval(v: number) {
      this._baseFrameInterval = v;
      this.transitionDuration = this.baseFrameInterval * 0.3;
    }

    private transitionDuration = this.baseFrameInterval * 0.3; // Duration of transition in ms
    private layerOpacity = 0.4; // Opacity of the overlay layer

    private image: HTMLImageElement;
    private div: HTMLDivElement | null = null;
    private currentImg: HTMLImageElement | null = null;
    private nextImg: HTMLImageElement | null = null;
    private bounds: google.maps.LatLngBounds;
    private isTransitioning: boolean = false;

    constructor(
      bounds: google.maps.LatLngBounds,
      image: HTMLImageElement,
      baseFrameInterval: number = 500
    ) {
      super();
      this.bounds = bounds;
      this.image = image;
      this.baseFrameInterval = baseFrameInterval;
    }

    override onAdd() {
      this.div = document.createElement('div');
      this.div.style.borderStyle = 'none';
      this.div.style.borderWidth = '0px';
      this.div.style.position = 'absolute';

      // Create and set up the current image
      this.currentImg = document.createElement('img');
      this.currentImg.src = this.image.src;
      this.setupImageStyles(this.currentImg);
      this.currentImg.style.opacity = this.layerOpacity.toString();

      // Create and set up the next image (initially hidden)
      this.nextImg = document.createElement('img');
      this.setupImageStyles(this.nextImg);
      this.nextImg.style.opacity = '0';

      // Add both images to the container
      this.div.appendChild(this.currentImg);
      this.div.appendChild(this.nextImg);

      const panes = this.getPanes();
      panes?.overlayLayer.appendChild(this.div);
    }

    private setupImageStyles(img: HTMLImageElement) {
      img.style.width = '100%';
      img.style.height = '100%';
      img.style.position = 'absolute';
      img.style.left = '0';
      img.style.top = '0';
      img.style.transition = `opacity ${this.transitionDuration}ms`;
    }

    override draw() {
      if (!this.div) return;

      const overlayProjection = this.getProjection();
      const sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      );
      const ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      );

      if (sw && ne) {
        this.div.style.left = sw.x + 'px';
        this.div.style.top = ne.y + 'px';
        this.div.style.width = ne.x - sw.x + 'px';
        this.div.style.height = sw.y - ne.y + 'px';
      }
    }

    override onRemove() {
      if (this.div) {
        this.div.parentNode?.removeChild(this.div);
        this.div = null;
        this.currentImg = null;
        this.nextImg = null;
      }
    }

    updateImage(newImage: HTMLImageElement) {
      if (
        !this.div ||
        !this.currentImg ||
        !this.nextImg ||
        this.isTransitioning
      )
        return;

      this.isTransitioning = true;

      // Set the new image to the next image element (currently hidden)
      this.nextImg.src = newImage.src;
      this.nextImg.style.transition = `opacity ${this.transitionDuration}ms`;
      this.currentImg.style.transition = `opacity ${
        this.transitionDuration * 1.2
      }ms`;

      // Start transition after a brief delay to ensure the new image is loaded
      setTimeout(() => {
        if (!this.currentImg || !this.nextImg) return;

        // Fade in the next image
        this.nextImg.style.opacity = this.layerOpacity.toString();
        // Fade out the current image
        this.currentImg.style.opacity = '0';

        // After transition completes, swap the images and reset
        setTimeout(() => {
          if (!this.currentImg || !this.nextImg) return;

          // Swap the references
          const temp = this.currentImg;
          this.currentImg = this.nextImg;
          this.nextImg = temp;

          // Reset the next image
          this.nextImg.style.opacity = '0';
          this.isTransitioning = false;
        }, this.transitionDuration);
      }, 50);
    }
  };
}

export const customMapImageLayerFactory = f;