import {
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { NbThemeService } from '@nebular/theme';
import {
  Subject,
  delay,
  filter,
  forkJoin,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AnnouncementComponent } from 'src/app/shared/components/announcement/announcement.component';
import { WindMapType } from 'src/app/shared/components/map-components/wind-map-type';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Complain } from 'src/app/shared/models/complain';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild(GoogleMap) public googleMap!: GoogleMap;
  @ViewChildren(MapMarker) public mapMarkers!: QueryList<MapMarker>;
  @ViewChildren('complainInfoWindow')
  public complainInfoWindows!: QueryList<MapInfoWindow>;

  industries: any[] = [];
  mapLayers!: number[];
  width!: number;
  popupCounter: number = 0;
  complains?: Complain.Get[];
  categories: any = {};
  newComplain?: any;
  mapInitialized: boolean = false;
  hasLayer5000: boolean = false;
  hasLayer5001: boolean = false;
  hasLayer5002: boolean = false;
  fullScreenMode: string = 'zoom_out_map';
  currentMapType: string = 'roadmap';
  complainLayerEnabled: boolean = false;
  trafficLayerEnabled: boolean = false;
  windLayerEnabled: boolean = false;
  industryLayerEnabled: boolean = false;
  showWhatsNewPopupAccess: boolean = false;
  openPanel: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  trafficLayer?: google.maps.TrafficLayer;
  selectedWeatherLayer: any[] = [];
  weatherApiKey: string = environment.openWeatherAPI;
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;
  gte: any;
  lte: any;

  constructor(
    public googleMapsService: GoogleMapsService,
    private localStorageService: LocalStorageService,
    private complainsService: ComplainsService,
    private dialog: MatDialog,
    private themeService: NbThemeService,
    private customMomentService: CustomMomentService
  ) {
    this.width = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }

  ngOnInit(): void {
    this.gte = this.complainsService.getGte();
    this.lte = this.complainsService.getLte();

    forkJoin({
      category: this.complainsService.getCategories(),
      complain: this.complainsService.getComplainsByOrganization(
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .org,
        this.gte,
        this.lte
      ),
    }).subscribe(({ category, complain }) => {
      this.categories = category.reduce((prevValue: any, currValue: any) => {
        prevValue[currValue.categoryId] = currValue;
        return prevValue;
      }, {});
      this.complains = complain;
      this.initializeData();
    });
    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() => this.googleMap?.idle),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as unknown as Map)),
        switchMap(() => this.googleMap!.zoomChanged)
      )
      .subscribe(() => {});

    this.complainsService.isGetData$.subscribe({
      next: (res: boolean) => {
        this.complains = [...this.complainsService.complains];
        this.initializeData();
      },
    });

    this.intiPopupCounter();
    this.showWhatsNewPopupAccess = this.localStorageService
      .getParsedValue(LocalStorageConstants.POPUP_TYPES)
      .includes(1002);

    this.mapLayers = this.localStorageService.getParsedValue(
      LocalStorageConstants.MAP_LAYER
    );
    this.hasLayer5000 = this.mapLayers.includes(5000);
    this.hasLayer5001 = this.mapLayers.includes(5001);
    this.hasLayer5002 = this.mapLayers.includes(5002);
  }

  ngAfterViewInit(): void {
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName == 'material-dark') {
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };
        if (this.openPanel) {
          setTimeout(() => {
            this.focusSelectedComplain();
          });
        } else if (firstTimeLoaded) {
          setTimeout(() => {
            this.fitBounds(this.googleMap.googleMap!);
          });
        }
        firstTimeLoaded = true;
      });
  }

  mapReady(map: Map): void {
    this.mapInitialized = true;
    this.initializeData();

    if (this.showWhatsNewPopupAccess === true && this.popupCounter === 0) {
      this.showWhatsNewPopUp();
    }
  }

  public onMapOptionsUpdated(options: MapOptions) {
    this.options = { ...options };
  }

  showWhatsNewPopUp(): void {
    this.dialog
      .open(AnnouncementComponent, { data: {} })
      .afterClosed()
      .subscribe((result) => {
        this.localStorageService.saveValue(
          LocalStorageConstants.POPUP_COUNTER,
          this.localStorageService.getParsedValue(
            LocalStorageConstants.POPUP_COUNTER
          ) + 1
        );
      });
  }

  initializeData() {
    if (this.complains && this.complains.length > 0) {
      this.complains!.forEach((complain) => {
        if (complain.priority === 'HIGH') {
          complain.iconUrl = 'assets/images/pins/complain-red.png';
        }
        if (complain.priority === 'MEDIUM') {
          complain.iconUrl = 'assets/images/pins/complain-yellow.png';
        }
        if (complain.priority === 'LOW') {
          complain.iconUrl = 'assets/images/pins/complain-pin-neutral.png';
        }
        complain.categoryId = this.categories[complain.categoryId]?.label;
      });
    }

    if (this.mapInitialized && this.complains && this.complains.length > 0) {
      this.fitBounds(this.googleMap.googleMap!);
    }
  }

  async fetchComplains() {
    return new Promise((resolve, reject) => {
      this.complainsService
        .getComplainsByOrganization(
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            .org,
          undefined,
          undefined
        )
        .subscribe({
          next: (res) => {
            resolve(true);
            this.initializeData();
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  fitBounds(map: Map): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers = this.complains!.map((complain) => {
      if (
        complain.latitude &&
        complain.longitude &&
        complain.latitude !== 0 &&
        complain.longitude !== 0
      ) {
        return {
          lat: complain.latitude,
          lng: complain.longitude,
        };
      } else {
        return 0;
      }
    });
    markers.forEach((marker) => {
      if (marker) {
        latLngBounds.extend(marker);
      }
    });

    map.fitBounds(latLngBounds);
  }

  focusSelectedComplain() {
    let map: Map = this.googleMap.googleMap!;
    if (map) {
      const latLngBounds = new google.maps.LatLngBounds();
      const markers = [this.newComplain!].map((complain) => {
        if (
          complain.latitude &&
          complain.longitude &&
          complain.latitude !== 0 &&
          complain.longitude !== 0
        ) {
          return {
            lat: complain.latitude,
            lng: complain.longitude,
          };
        } else {
          return 0;
        }
      });

      markers.forEach((marker) => {
        if (marker) latLngBounds.extend(marker);
      });

      map.fitBounds(latLngBounds);
      map.setCenter({
        lat: this.newComplain.latitude,
        lng: this.newComplain.longitude,
      });
      map.setZoom(12);
    }
  }

  intiPopupCounter(): void {
    if (
      !this.localStorageService.getParsedValue(
        LocalStorageConstants.POPUP_COUNTER
      )
    ) {
      this.localStorageService.saveValue(
        LocalStorageConstants.POPUP_COUNTER,
        '0'
      );
    }
    this.popupCounter = this.localStorageService.getParsedValue(
      LocalStorageConstants.POPUP_COUNTER
    );
  }

  openInfoWindow(
    marker: MapMarker,
    index: number,
    complain?: Complain.Get
  ): void {
    this.closeInfoWindows();
    this.openPanel = true;
    this.newComplain = complain;
    this.complainInfoWindows.toArray()[index]?.open(marker, false);
  }

  closeInfoWindows(): void {
    this.openPanel = false;
    this.complainInfoWindows.toArray().forEach((iw) => iw.close());
  }

  switchToFullScreen(): void {
    const map = this.googleMap.googleMap?.getDiv().firstChild as HTMLElement;
    if (this.isFullscreen(map)) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen(map);
    }
  }

  isFullscreen(element: HTMLElement): boolean {
    return document.fullscreenElement == element;
  }

  requestFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      this.fullScreenMode = 'zoom_in_map';
      element.requestFullscreen();
    }
  }

  exitFullscreen(): void {
    if (document.exitFullscreen) {
      this.fullScreenMode = 'zoom_out_map';
      document.exitFullscreen();
    }
  }

  zoomIn(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! + 1 <= this.options.maxZoom!
          ? this.googleMap.getZoom()! + 1
          : this.options.maxZoom,
    };
  }

  zoomOut(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! - 1 >= this.options.minZoom!
          ? this.googleMap.getZoom()! - 1
          : this.options.minZoom,
    };
  }

  changeMapType(): void {
    if (this.currentMapType === 'roadmap') {
      this.currentMapType = 'hybrid';
    } else {
      this.currentMapType = 'roadmap';
    }

    this.options = {
      ...this.options,
      mapTypeId: this.currentMapType,
      center: this.googleMap.getCenter(),
      zoom: this.googleMap.getZoom(),
    };
  }

  toggleTrafficLayer(): void {
    this.trafficLayerEnabled = !this.trafficLayerEnabled;

    if (
      this.trafficLayer === undefined ||
      this.trafficLayer.getMap() == undefined ||
      this.trafficLayer.getMap() === null
    ) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.googleMap.googleMap!);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  toggleWindLayer(): void {
    this.windLayerEnabled = !this.windLayerEnabled;

    if (this.windLayerEnabled) {
      this.selectedWeatherLayer.push(AppConstants.MAP_WEATHER_LAYERS[0]);
      this.addWindLayer();
    } else {
      this.removeWindLayer();
    }
  }

  addWindLayer() {
    const weatherMapProvider = `https://tile.openweathermap.org/map/${this.selectedWeatherLayer[0]['layer_option']['layer_value']}/`;
    this.googleMap.overlayMapTypes.clear();
    this.googleMap.overlayMapTypes.insertAt(
      0,
      new WindMapType(
        new google.maps.Size(256, 256, 'px', 'px'),
        weatherMapProvider,
        this.weatherApiKey
      )
    );
  }

  removeWindLayer() {
    this.googleMap.overlayMapTypes.clear();
    this.selectedWeatherLayer = [];
  }

  toggleIndustryLayer() {
    this.industryLayerEnabled = !this.industryLayerEnabled;

    if (this.industryLayerEnabled) {
      const request = {
        location: { lat: 23.0225, lng: 72.5714 },
        query: 'find industries near me',
        keyword: 'industry',
        types: ['name', 'geometry'],
        radius: 5000000,
      };

      const service = new google.maps.places.PlacesService(
        this.googleMap.googleMap!
      );

      if (this.industries.length <= 0) {
        service.nearbySearch(request, (results, status, pagination) => {
          this.industryLayerEnabled = false;
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (pagination?.hasNextPage) {
              pagination.nextPage();
            }
            results?.forEach((result) => {
              const place = {
                name: result.name,
                geometry: result.geometry,
                address: result.vicinity,
                rating: result.rating,
                photo: this.getIndustryImage(result),
              };
              this.industries.push(place);
            });
          } else if (
            status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
          ) {
            console.info('API limit exceeded. Please contact developer.');
          }
        });
      }
    }
  }

  getIndustryImage(result: google.maps.places.PlaceResult): string | undefined {
    return typeof result.photos !== 'undefined'
      ? result.photos[0].getUrl({ maxWidth: 150, maxHeight: 150 })
      : result.icon;
  }

  public formatDate(epoch: number) {
    return this.customMomentService.formatDate({ epoch });
  }
}
