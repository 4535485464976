import { SelectionModel } from '@angular/cdk/collections';
import { Overlay } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { filter, indexOf } from 'lodash';
import * as moment from 'moment';
import { LocalStorageConstants } from '../../constants/local-storage.constant';
import { ModuleCheckbox } from '../../models/module-checkbox';
import { Project } from '../../models/project/project';
import { CommonService } from '../../services/common.service';
import { CustomMomentService } from '../../services/custom-moment.service';
import { DeviceService } from '../../services/device.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { CommonUtil } from '../../utils/common-utils';

@Component({
  selector: 'app-module-list-admin',
  templateUrl: './module-list-admin.component.html',
  styleUrls: ['./module-list-admin.component.scss'],
})
export default class ModuleListAdminComponent<T>
  implements OnInit, AfterViewInit
{
  @Output() moduleSelectoreEventEmmiter = new EventEmitter();
  @Input() moduleSelector = new SelectionModel<any>(true, []);

  defaultModules: any[] = [1022, 1001, 1002, 1003, 1008, 1012];
  modules!: ModuleCheckbox[];
  userData?: Project.Users;
  isEditMode!: boolean;
  defaultModuleExp = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER
  ).moduleExpiry;
  selectedModules!: any[];
  displayedColumnsModules: string[] = [];
  currentDate = new Date();
  defaultColumnsModules: any[] = [];

  defaultAccessColumnsModules: any[] = [
    'select',
    {
      columnDef: 'action',
      header: 'Action',
      cell: (element: any) => `${element.action}`,
      parameter: false,
      selected: false,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private overlay: Overlay,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    @Inject(MTX_DRAWER_DATA)
    { userData, orgData }: { userData: Project.Users; orgData: Project.Details }
  ) {
    this.userData = userData;
    this.moduleSelector.compareWith = (a: ModuleCheckbox, b: ModuleCheckbox) =>
      a && b && a.moduleId === b.moduleId;
    this.defaultColumnsModules = this.generateDefaultModulesColumns();
  }

  ngOnInit(): void {
    this.isEditMode = !!this.userData;
    this.getModuleData();
    this.defaultColumnsModules = this.generateDefaultModulesColumns();
    this.displayedColumnsModules = [
      'select',
      ...this.defaultColumnsModules.map((c) => c.columnDef),
    ];
    this.patchModules();
  }

  ngAfterViewInit(): void {}

  dateHelper(timestamp: any) {
    const dateObj = new Date(+timestamp * 1000);
    return new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate()
    );
  }

  generateDefaultModulesColumns() {
    return [
      {
        columnDef: 'name',
        header: 'Module',
        cell: (element: ModuleCheckbox) => `${element.name}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'expiryDate',
        header: 'Valid Till',
        cell: (element: ModuleCheckbox) => 
          {
          const date = this.userData?.moduleExpiry?.find((m:any)=> !this.defaultModules.includes(m.moduleId) && m.moduleId === element.moduleId);
          return date ? this.giveMeExpiry(date.expiry_date) : '--'
        },
          // this.userData?.orgData?.info?.expiry
          //   ? this.giveMeExpiry(this.userData?.orgData?.info?.expiry)
          //   : this.defaultModuleExp[element.moduleId] &&
          //     !this.defaultModules.includes(element.moduleId)
          //   ? this.giveMeExpiry(this.defaultModuleExp[element.moduleId])
          //   : '--',
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'iconToggles',
        header: 'Access',
        cell: (element: ModuleCheckbox) => {
          const options = this.getOptions(element);
          const accessOptions = [
            {
              id: 'add',
              title: 'Add',
              icon: 'add',
              active: options.add,
            },
            {
              id: 'edit',
              title: 'Edit',
              icon: 'edit',
              active: options.edit,
            },
            {
              id: 'download',
              title: 'Download',
              icon: 'download',
              active: options.download,
            },
            {
              id: 'delete',
              title: 'Delete',
              icon: 'delete',
              active: options.delete,
            },
          ];
          return accessOptions;
        },
        onToggle: (element: ModuleCheckbox, accessItem: any) => {
          accessItem.active = !accessItem.active;
          if (!element.options) {
            element.options = {};
          }
          element.options = {
            ...element?.options,
            [accessItem.id]: accessItem.active,
          };
          const currentModuleIndex = this.modules?.findIndex(
            (module) => module?.moduleId === element?.moduleId
          );
          const updatedModules = [...this.modules];
          updatedModules[currentModuleIndex] = { ...element };
          this.modules = updatedModules;
          this.moduleSelectoreEventEmmiter.emit(this.modules);
        },
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'dateSelector',
        header: 'Set Expiry',
        parameter: false,
        selected: false,
        min: this.currentDate,
        width: '32%',
        max: (element: ModuleCheckbox) =>
          !this.defaultModuleExp
            ? ''
            : this.dateHelper(this.defaultModuleExp[element.moduleId]),
      },
    ];
  }
  getModuleData() {
    this.modules = filter(
      this.localStorageService.getParsedValue(LocalStorageConstants.MODULES),
      (module) => {
        if (
          !String(module.moduleId).startsWith('1') &&
          module.moduleId !== 2015
        ) {
          return false;
        }
        return (
          indexOf(
            this.localStorageService.getParsedValue(
              LocalStorageConstants.OZ_USER
            ).modules,
            module.moduleId
          ) > -1
        );
      }
    );

    let moduleExpiry: { [key: string]: any } = {};
    if (this.isEditMode) {
      this.userData?.moduleExpiry?.map((expiry: any) => {
        moduleExpiry[expiry.moduleId] = this.customMomentService.moment.unix(
          expiry.expiry_date
        );
      });
    }
    if (this.userData) {
      this.modules.forEach((module) => {
        if (this.userData?.moduleExpiry) {
          if (this.defaultModules.includes(module.moduleId))
            module['disableRow'] = true;
          else {
            let date = this.userData?.orgData?.info?.expiry
              ? this.userData?.orgData?.info?.expiry
              : this.localStorageService.getParsedValue(
                  LocalStorageConstants.OZ_USER
                ).moduleExpiry[module['moduleId']!];
            if (moduleExpiry[module.moduleId]) {
              module['expiryDate'] = moduleExpiry[module.moduleId];
            } else {
              module['expiryDate'] = this.epochTimeToDateMoment(date);
            }
            module['enabled'] = false;
          }
        }

        const userDataModule = this.userData?.moduleExpiry?.find(
          (userModule: any) => {
            return userModule.moduleId === module.moduleId;
          }
        );

        const masterOrgModuleOptions = this.commonService.moduleAccessibility(
          module.moduleId
        );

        let masterOptions = {};

        // Merge master options if available
        if (masterOrgModuleOptions) {
          masterOptions = { ...masterOrgModuleOptions };
        }

        if (userDataModule) {
          // Merge userDataModule options with masterOptions
          let options: any = { ...masterOptions, ...userDataModule?.options };

          // Set default values if options don't exist
          options = {
            add: options.add ?? true,
            download: options.download ?? true,
            edit: options.edit ?? true,
            delete: options.delete ?? true,
            ...options,
          };

          // Assign options to the module
          module['options'] = options;
        }
      });
    }    
    CommonUtil.sortModules(this.modules, this.defaultModules);
  }

  getOptions(row: any) {
    const module = this.modules.find(
      (module) => module.moduleId === row.moduleId
    );
    return (
      module?.options || {
        add: false,
        edit: false,
        download: false,
        delete: false,
      }
    );
  }

  epochTimeToDateMoment(value: number) {
    return this.customMomentService.moment.unix(value);
  }

  patchModules() {
    let selectedModules = this.modules?.filter((module) =>
      this.userData?.modules?.includes(module.moduleId!)
    );
    this.moduleSelector.select(...selectedModules);
    this.onModuleSelection();
  }

  onModuleSelection(event?: any) {
    this.moduleSelector.select(
      ...this.modules.filter((module: ModuleCheckbox) =>
        this.defaultModules.includes(module.moduleId!)
      )
    );
    this.selectedModules = this.modules
      .filter((module: ModuleCheckbox) => {
        return this.moduleSelector.selected.some(
          (resModule: ModuleCheckbox) => module.moduleId === resModule.moduleId
        );
      })
      .map((m) => {
        return {
          moduleId: m.moduleId,
          expiry_date: this.dateToEpochMoment(m.expiryDate),
        };
      });

    this.modules = [
      ...this.modules.map((module: ModuleCheckbox) => {
        module.enabled = this.selectedModules.some(
          (m: any) => m.moduleId === module.moduleId
        );
        if (module.enabled) {
          if (!module?.options) {
            const masterOrgModuleOptions =
              this.commonService.moduleAccessibility(module.moduleId);

            let masterOptions = {};

            // Merge master options if available
            if (masterOrgModuleOptions) {
              masterOptions = { ...masterOrgModuleOptions };
            }

            // Merge userDataModule options with masterOptions
            let options: any = { ...masterOptions };

            // Set default values if options don't exist
            options = {
              add: options.add ?? true,
              download: options.download ?? true,
              edit: options.edit ?? true,
              delete: options.delete ?? true,
              ...options,
            };
            module.options = options;
          }
        } else {
          delete module?.options;
        }
        return module;
      }),
    ];
    this.moduleSelectoreEventEmmiter.emit(this.moduleSelector.selected);
  }

  dateToEpochMoment(date: Date): number {
    return moment(date).unix();
  }

  giveMeExpiry(timestamp: any) {
    return this.customMomentService.formatDatetime({epoch:timestamp, format:'DD/MM/YYYY'})
  }

  onDateSelection(value: any) {
    this.moduleSelector.selected.forEach((obj: any) => {
      if (obj.moduleId === value.row.moduleId) {
        obj.expiryDate = value.changedDate;
      }
    });
    this.moduleSelectoreEventEmmiter.emit(this.moduleSelector.selected);
  }

  ngOnChanges() {}
}
