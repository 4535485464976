import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FilterOptions } from '../../models/filter-options';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterValue } from '../../models/filter-value';
import { FilterColumn } from '../../models/filter-column';
import { ComplainsService } from 'src/app/shared/services/complains.service';
@Component({
  selector: 'app-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss'],
})
export class FilterOptionsComponent implements OnInit {
  categories: any[] = [];
  createdFilterDd = [
    { label: 'Yesterday', value: 86400 },
    { label: 'This Week', value: 604800 },
    { label: 'This Month', value: 2629743 },
    { label: 'Last 6 Months', value: 15780000 },
  ];
  statuses = ['Resolved', 'In Progress', 'Pending'];
  priorites = ['LOW', 'MEDIUM', 'HIGH'];
  @Input() data!: FilterOptions;
  @Output() apply: EventEmitter<any> = new EventEmitter();
  filterForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private complainservice: ComplainsService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      filterKey: ['', Validators.required],
      filterValue: [''],
      filterBy: [''],
      status: [''],
      time: [''],
    });
    this.complainservice.getCategories().subscribe(() => {
      this.categories = this.complainservice.categories;
    });

    this.filterForm.controls.filterKey.valueChanges.subscribe((column) => {
      if (
        column.key !== 'status' &&
        column.key !== 'time' &&
        !column.parameter
      ) {
        this.filterForm.controls.filterValue.setValidators(Validators.required);
        this.filterForm.controls.status.removeValidators(Validators.required);
        this.filterForm.controls.time.removeValidators(Validators.required);
        this.filterForm.controls.filterBy.removeValidators(Validators.required);
        this.filterForm.controls.filterValue.setValue('');
        this.filterForm.controls.status.reset();
        this.filterForm.controls.time.reset();
        this.filterForm.controls.filterBy.reset();
      } else if (column.key === 'status') {
        this.filterForm.controls.status.setValidators(Validators.required);
        this.filterForm.controls.filterValue.removeValidators(
          Validators.required
        );
        this.filterForm.controls.time.removeValidators(Validators.required);
        this.filterForm.controls.filterBy.removeValidators(Validators.required);
        this.filterForm.controls.filterValue.reset();
        this.filterForm.controls.time.reset();
        this.filterForm.controls.filterBy.reset();
        this.filterForm.controls.status.setValue('');
      } else if (column.key === 'time') {
        this.filterForm.controls.time.setValidators(Validators.required);
        this.filterForm.controls.filterValue.removeValidators(
          Validators.required
        );
        this.filterForm.controls.status.removeValidators(Validators.required);
        this.filterForm.controls.filterBy.setValidators(Validators.required);
        this.filterForm.controls.filterValue.reset();
        this.filterForm.controls.status.reset();
        this.filterForm.controls.filterBy.setValue('');
        this.filterForm.controls.time.setValue('');
      } else if (column.parameter) {
        this.filterForm.controls.time.removeValidators(Validators.required);
        this.filterForm.controls.status.removeValidators(Validators.required);
        this.filterForm.controls.filterBy.setValidators(Validators.required);
        this.filterForm.controls.filterValue.setValidators(Validators.required);
        this.filterForm.controls.time.reset();
        this.filterForm.controls.status.reset();
        this.filterForm.controls.filterBy.setValue('');
        this.filterForm.controls.filterValue.setValue('');
      }
    });
  }

  applyFilter(): void {
    const data = this.filterForm.controls.filterKey.value as FilterColumn;
    let value = '';

    if (!this.isStatus()) {
      value = this.filterForm.controls.filterValue.value;
    } else if (this.isStatus()) {
      value = this.filterForm.controls.Status.value;
    } else if (this.isTime()) {
      value = this.filterForm.controls.time.value;
    }

    const filter: FilterValue = {
      label: data.value,
      key: data.key,
      value: value,
      parameter: data.parameter,
      filterBy: this.filterForm.controls.filterBy.value,
    };
    this.apply.emit(filter);
  }

  isTime(): boolean {
    return this.filterForm.controls.filterKey?.value?.key === 'time';
  }

  isStatus(): boolean {
    return this.filterForm.controls.filterKey?.value?.key === 'status';
  }

  isParameter(): boolean {
    return this.filterForm.controls.filterKey?.value?.parameter;
  }
  isColumnFilterValid(column: any): boolean {
    return !('filter' in column) || column.filter;
  }
}
