<div class="row mx-0 w-100 header-container">
  <div class="logo-sidebar d-none d-md-block">
    <img
      class="header-logo w-auto"
      [src]="headerLogo"
      alt="envizom"
      matTooltip="{{ tooltipText }}"
      (click)="homepageNavigation()"
    />
  </div>
  <div class="col col-lg-auto col-xl-4 logo-container ps-md-4">
    <a
      href="#"
      class="sidebar-toggle ms-2 me-4 mx-md-3"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon
        class="text-basic-color"
        [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"
      ></nb-icon>
    </a>
    <a class="logo">{{ title }}</a>
  </div>
  <div class="col-auto col-lg col-xl align-self-center">
    <div
      class="d-flex flex-row align-items-center justify-content-end"
      *ngIf="width > 991"
    >
      <div
        class="mx-2 d-none d-lg-block plan-box-size"
        *ngIf="planInfo && planInfo.plan >= 0 && isPartner"
      >
        <div
          class="d-flex flex-column p-1 plan-info color-{{
            planInfo.plan_name
          }}"
          style="color: black"
        >
          <span class="text-center small"> Current Plan </span>
          <span class="text-center fw-bold text-uppercase">
            {{ planInfo.plan_name }} {{ planInfo.max_seats }}
          </span>
        </div>
      </div>
      <!-- <div class="px-2 d-none d-sm-block" style="width: 170px">
        <mat-form-field class="w-100">
          <mat-label>Language</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Language'"
            matInput
            [matAutocomplete]="language"
            [(ngModel)]="selectedLanguage"
            (ngModelChange)="updateLanguage()"
          />
          <mat-autocomplete
            #language="matAutocomplete"
            [displayWith]="getLanguageLabel"
          >
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language.label }}
            </mat-option>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            mat-icon-button
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">
              arrow_drop_down
            </mat-icon>
          </button>
        </mat-form-field>
      </div> -->
      <div class="encirlce mx-2 d-none d-lg-block">
        <mat-icon
          class="material-symbols-outlined cursor-pointer"
          matTooltip="Dark Mode"
          (click)="changeTheme('material-dark')"
          *ngIf="currentTheme == 'material-light'"
        >
          dark_mode
        </mat-icon>
        <mat-icon
          class="material-symbols-outlined cursor-pointer"
          matTooltip="Light Mode"
          (click)="changeTheme('material-light')"
          *ngIf="currentTheme == 'material-dark'"
        >
          light_mode
        </mat-icon>
      </div>

      <div class="encirlce mx-2 d-none d-lg-block">
        <div (click)="showWhatsNewPopUp()" matTooltip="What's New">
          <mat-icon class="material-symbols-outlined"> campaign </mat-icon>
        </div>
      </div>

      <div class="encirlce mx-2 d-none d-lg-block">
        <div (click)="openIntercom()" matTooltip="Support">
          <mat-icon class="material-symbols-outlined"> support_agent </mat-icon>
        </div>
      </div>

      <div
        class="encirlce mx-2 d-none d-lg-block"
        [class.disabled]="isNotificationDisabled"
      >
        <div (click)="openNotification()" matTooltip="Notifications">
          <mat-icon
            class="material-symbols-outlined"
            *ngIf="!isNewNotificationAvailable"
          >
            notifications_none
          </mat-icon>

          <div
            *ngIf="isNewNotificationAvailable"
            [ngClass]="{
              'notification-badge': isNewNotificationAvailable
            }"
          >
            <mat-icon
              class="material-symbols-outlined"
              matBadge="15"
              matBadgeColor="warn"
              >notifications_none</mat-icon
            >
          </div>
        </div>
      </div>
      <div class="encirlce mx-2 d-none d-lg-block">
        <div matTooltip="Download CSV" (click)="downloadCsvAndPdfReport()">
          <mat-icon class="material-symbols-outlined">get_app</mat-icon>
        </div>
      </div>
      <div class="encirlce ms-2 d-none d-lg-block">
        <div matTooltip="Logout" (click)="openLogoutDialog()">
          <mat-icon class="material-symbols-outlined">logout</mat-icon>
        </div>
      </div>
    </div>
    <!--mobile view -->
    <div
      class="d-flex flex-row align-items-center justify-content-end"
      *ngIf="width <= 991 && routingCompleted"
    >
      <button
        mat-icon-button
        [matMenuTriggerFor]="mobileMenu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #mobileMenu="matMenu">
        <div
          matMenuContent
          (click)="$event.stopPropagation(); $event.preventDefault()"
        >
          <!-- <div
              *ngIf="planInfo && planInfo.plan >= 0 && isPartner"
              class="mx-3 plan-box-size"
            >
              <div class="d-flex flex-column plan-info color-{{
                planInfo.plan_name
              }} text-center">
                <span class="small">Current Plan</span>
                <span class="fw-bold text-uppercase">
                  {{ planInfo.plan_name }} {{ planInfo.max_seats }}
                </span>
              </div>
            </div> -->
          <!-- <button
            mat-menu-item
            (click)="changeTheme('material-dark')"
            *ngIf="currentTheme == 'material-light'"
          >
            <mat-icon>dark_mode</mat-icon>
            <span>Dark Mode</span>
          </button>
          <button
            mat-menu-item
            (click)="changeTheme('material-light')"
            *ngIf="currentTheme == 'material-dark'"
          >
            <mat-icon>light_mode</mat-icon>
            <span>Light Mode</span>
          </button> -->
          <!-- <button mat-menu-item (click)="showWhatsNewPopUp('isMobileView')">
            <mat-icon>campaign</mat-icon>
            <span>What's New</span>
          </button>
          <button mat-menu-item (click)="openIntercom()">
            <mat-icon>support_agent</mat-icon>
            <span>Support</span>
          </button> -->
          <button mat-menu-item (click)="openNotification()">
            <mat-icon>notifications_none</mat-icon>
            <span>Notifications</span>
          </button>
          <button mat-menu-item (click)="downloadCsvAndPdfReport()">
            <mat-icon>get_app</mat-icon>
            <span>Download CSV</span>
          </button>
          <button mat-menu-item (click)="openLogoutDialog()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
