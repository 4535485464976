// Taken from @panth977/node-backend package;

import { DefaultSplitChar, getInnerProps, KeyPath } from './object-utils';

export function sortList<
  //
  T,
  K extends KeyPath<T, S>,
  S extends string = DefaultSplitChar
>(rows: T[], mode: 'ASC' | 'DESC', keyPath: K, split: S = '.' as never): T[] {
  if (rows.length < 2) return rows;
  return rows
    .map((r) => ({ r, v: getInnerProps(r as T, keyPath, split) }))
    .sort((a, b) => {
      if (typeof a.v === 'string' && typeof b.v === 'string') {
        return mode === 'ASC' ? a.v.localeCompare(b.v) : b.v.localeCompare(a.v);
      }
      // Handle numbers
      const aNum = Number(a.v);
      const bNum = Number(b.v);
      return mode === 'ASC' ? aNum - bNum : bNum - aNum;
    })
    .map((x) => x.r);
}
