import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';

import * as Highcharts from 'highcharts';
import { debounceTime, filter, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { WidgetService } from '../../services/widget.service';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';

@Component({
  selector: 'app-prediction-widget',
  templateUrl: './prediction-widget.component.html',
  styleUrls: ['./prediction-widget.component.scss'],
})
export class PredictionWidgetComponent
  implements IWidgetComponent, OnInit, OnChanges, OnDestroy
{
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this.chartType = v.chartTypeConfig?.selectedValue ?? 'column';
    this.show = v.show;
    this.autoRange = !(
      Boolean(
        v.optionsMenu?.options.find(
          (option) => option.value === 'static_y_axis'
        )?.state
      ) ?? true
    );
    this._widgetInfo = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  private _widgetData: any;
  public get widgetData(): any {
    return this._widgetData;
  }
  @Input() public set widgetData(v: any) {
    this.deviceInfo = { ...v.deviceInfo };
    this.deviceData = [...v.deviceData];
    this.fields = [...v.fields];
    this.limits = [...v.limits];
    this.aqiIndexColor = { ...v.aqiIndexColor };
    this.chartType = v.chartType;
    this.show = Boolean(v.show);
    this._widgetData = v;
    this._cdr.detectChanges();

    this.setupConfig();
  }

  deviceInfo: DeviceDetails | undefined;

  private _deviceData: DeviceDetails[] = [];
  public get deviceData(): DeviceDetails[] {
    return this._deviceData;
  }
  public set deviceData(v: DeviceDetails[]) {
    this._deviceData = v;
    if (v) {
      this.setupConfig();
    }
  }

  fields: DeviceField[] = [];

  private _limits: FieldLimit[] = [];
  public limitsObj: { [key: string]: FieldLimit } = {};
  public get limits(): FieldLimit[] {
    return this._limits;
  }
  public set limits(v: FieldLimit[]) {
    this._limits = v;
    this.limitsObj = Object.fromEntries([
      ...v.map((limit) => [limit.fkey, limit]),
    ]);
    this.setupConfig();
  }

  private _aqiIndexColor!: AqiIndexColorArray;
  public get aqiIndexColor(): AqiIndexColorArray {
    return this._aqiIndexColor;
  }
  public set aqiIndexColor(v: AqiIndexColorArray) {
    this._aqiIndexColor = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  autoRange: boolean = true;
  private chartType: string = 'column';
  public show = false;

  @Output() parameterSelectionChange: EventEmitter<string>;

  highcharts: typeof Highcharts = Highcharts;

  defaultColor: string = '#00c4b4';
  deviceFields: DeviceField[] = [];

  private _selectedField: DeviceField | undefined;
  public get selectedField(): DeviceField | undefined {
    return this._selectedField;
  }
  public set selectedField(v: DeviceField | undefined) {
    if (v) {
      this._selectedField = v;
      this.parameterSelectionChange.emit(v.label);
    }
  }

  public noPredictionData: boolean = true;
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  chartOptions: Highcharts.Options = {};

  userTimeFormat: number;

  subscriptions: Subscription[] = [];

  constructor(
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef
  ) {
    this.parameterSelectionChange = new EventEmitter<string>();
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.deviceInfo = this.widgetService.device;
    this.deviceData = this.widgetService.predictionData;

    this.fields = this.widgetService.fields;
    this.limits = this.widgetService.limits;
    this.aqiIndexColor = this.widgetService.aqiIndexColor;

    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'device': {
              this.deviceInfo = this.widgetService.device;
              break;
            }
            case 'predictionData': {
              this.deviceData = this.widgetService.predictionData;
              break;
            }
            case 'fields': {
              this.fields = this.widgetService.fields;
              break;
            }
            case 'limits': {
              this.limits = this.widgetService.limits;
              break;
            }
            case 'aqiIndexColor': {
              this.aqiIndexColor = this.widgetService.aqiIndexColor;
              break;
            }
            default: {
              // console.info(
              //   'prediction-data-widget -> key for changed value:',
              //   res
              // );
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );

    this.subscriptions.push(
      this.widgetService.redrawWidget$
        .pipe(
          filter((widgetId: number) => this.widgetInfo.widgetId === widgetId),
          debounceTime(300)
        )
        .subscribe({
          next: (widgetId: number) => {
            if (this.widgetInfo.widgetId === widgetId) {
              this.show = false;
              setTimeout(() => {
                this.show = true;
              });
            }
          },
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupConfig();
  }

  setupConfig() {
    if (this.deviceData?.length && this.limits?.length && this.aqiIndexColor) {
      this.noPredictionData = false;
      this.deviceFields = CommonUtil.getDeviceFields(
        this.deviceData,
        this.fields,
        this.limits
      ).filter((field) => field.fkey !== 't' && field.fkey !== 'aqiMessage');
      if (this.deviceFields.length) {
        if (
          !this.selectedField ||
          !this.deviceFields.find((df) => df.fkey == this.selectedField?.fkey)
        ) {
          this.selectedField = this.deviceFields[0];
        }
        this.setupDataPoints();
      }
    } else {
      this.noPredictionData = true;
    }
  }

  setupDataPoints() {
    this.show = false;

    let dataPoints = [];

    let rangeIndex = this.limits.findIndex(
      (x: any) => this.selectedField?.fkey === x.fkey
    );

    const that = this;

    let xAxisOptions: Highcharts.XAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatTime({
            epochMS: parseInt('' + this.value),
            format: that.userTimeFormat === 12 ? 'hh:mm A' : 'HH:mm',
          });
        },
      },
    };
    let yAxisOptions: Highcharts.AxisOptions = {
      title: { text: null },
    };

    if (!this.autoRange) {
      yAxisOptions['tickPositions'] =
        this.limits[rangeIndex].range?.length > 0
          ? this.limits[rangeIndex].range!
          : [
              this.limits[rangeIndex].lowestLimit,
              this.limits[rangeIndex].highestLimit,
            ]!;
    }

    for (let data of this.deviceData) {
      let value: number = parseFloat(
        data?.payload?.d[this.selectedField?.fkey ?? 'aqi']
      );
      let dataPoint = {
        x: +this.customMomentService.moment.unix(data?.payload?.d?.t),
        y: value,
        color: '',
      };

      if (this.limits.length && this.limits[rangeIndex]) {
        let i: number;
        for (i = 0; i < this.limits[rangeIndex].range?.length; i++) {
          if (Math.min(value, this.limits[rangeIndex].range[i]) === value) {
            break;
          }
        }

        if (this.aqiIndexColor.limits.length > --i) {
          dataPoint.color = this.aqiIndexColor.color[i] || this.defaultColor;
        } else {
          dataPoint.color =
            this.aqiIndexColor.outOfRangeColor || this.defaultColor;
        }
      } else {
        dataPoint.color = this.defaultColor;
      }

      dataPoints.push(dataPoint);
    }

    this.chartOptions.title = null!;
    this.chartOptions.subtitle = null!;
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.legend = { enabled: false };

    this.chartOptions.chart = {
      type: this.chartType,
    };

    this.chartOptions.exporting = {
      filename: `${
        this.deviceInfo?.label ? `${this.deviceInfo?.label}_` : ''
      }prediction_data`,
      chartOptions: {
        title: {
          text: `${
            this.deviceInfo?.label ? `${this.deviceInfo?.label} device's ` : ''
          }24 Hours Forecast`,
        },
        legend: {
          enabled: true,
        } as Highcharts.LegendOptions,
        yAxis: {
          title: {
            text: `${this.selectedField?.label}${
              this.selectedField?.unit?.length
                ? ` (${this.selectedField.unit})`
                : ''
            }`,
          },
        },
        xAxis: {
          title: {
            text: 'Time',
          },
        },
      },
    };

    this.chartOptions.tooltip = {
      shared: true,
      formatter: function () {
        return (
          that.customMomentService.formatDatetime({
            epochMS: parseInt('' + this.x),
            format:
              that.userTimeFormat === 12
                ? 'dddd, MMM DD, hh:mm A'
                : 'dddd, MMM DD, HH:mm',
          }) +
          '</br>' +
          `<span style="color: ${this.point.color}">\u25CF</span>` +
          '<b>' +
          this.series.name +
          '</b>' +
          ' : ' +
          this.y
        );
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOptions;

    let zones = [
      {
        color: this.defaultColor,
        value: this.limits[rangeIndex].lowestLimit,
      },
    ];

    let fieldLimits = this.limits?.[rangeIndex];
    if (fieldLimits?.range?.length) {
      for (let i = 0; i < fieldLimits.range.length; i++) {
        let color = this.aqiIndexColor.color?.[i] || this.defaultColor;
        let value = fieldLimits.range[i + 1];
        if (value && color?.length) {
          zones.push({
            value,
            color,
          });
        }
      }
      zones.push({
        color: this.aqiIndexColor.outOfRangeColor,
        value: Number.POSITIVE_INFINITY,
      });
    }

    this.chartOptions.series = [];
    let fieldName = this.selectedField?.label ?? 'AQI';
    let fieldUnit = this.selectedField?.unit ?? '';
    fieldUnit = fieldUnit.replaceAll(' ', '');
    this.chartOptions.series[0] = {
      name: fieldName + (fieldUnit ? ' (' + fieldUnit + ')' : ''),
      data: dataPoints,
      zones,
    } as Highcharts.SeriesOptionsType;

    setTimeout(() => {
      this.show = true;
    });
  }

  changeField(field: DeviceField) {
    this.selectedField = field;
    this.setupDataPoints();
  }

  scrollLeft() {
    let element = document.getElementById(
      'widget-fields-chip-container-prediction-data'
    );
    element?.scrollTo({ left: element.scrollLeft - element.clientWidth / 1.8 });
  }

  scrollRight() {
    let element = document.getElementById(
      'widget-fields-chip-container-prediction-data'
    );
    element?.scrollTo({ left: element.scrollLeft + element.clientWidth / 1.8 });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
