import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-view-popup',
  templateUrl: './image-view-popup.component.html',
  styleUrls: ['./image-view-popup.component.scss'],
})
export class ImageViewPopupComponent {
  @Input() imageUrl!: string;

  constructor() {}

  @Output() closeEvent: EventEmitter<Event> = new EventEmitter<Event>();

  closeOverlay() {
    this.closeEvent.emit();
  }
}
