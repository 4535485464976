import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import { Moment } from 'moment-timezone';
import {
  delay,
  filter,
  map,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { Heatmap, HeatmapData } from 'src/app/shared/models/heatmap/heatmap';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { Unit } from 'src/app/shared/models/unit';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { HeatmapService } from 'src/app/shared/services/heatmap.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { customMapImageLayerFactory } from './custom-map-image-layer';

import GMap = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { FormsService } from 'src/app/shared/services/forms.service';
import { HeatmapFormComponent } from '../../components/heatmap-form/heatmap-form.component';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

const GEOJSON_DATA_LAYER_STYLES = {
  fillColor: 'transparent',
  strokeColor: 'transparent',
  strokeWeight: 0.5,
};

const BASE_FRAME_INTERVAL = 500; // Base interval between frames in ms

interface HeatmapPlayer {
  isPlaying: boolean;
  currentIndex: number;
  speed: number;
  animationFrameId?: number;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private _googleMap!: GoogleMap;
  public get googleMap(): GoogleMap {
    return this._googleMap;
  }
  @ViewChild(GoogleMap) public set googleMap(v: GoogleMap) {
    if (
      this.resizeObserver &&
      (this._googleMap as any)?._elementRef?.nativeElement
    ) {
      this.resizeObserver.unobserve(
        (this._googleMap as any)._elementRef.nativeElement
      );
    }
    this._googleMap = v;
    if (this._googleMap) {
      this.resizeObserver.observe(
        (this._googleMap as any)._elementRef.nativeElement
      );
    }
  }

  public options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;

  public loading: number = 0;
  public themeChange: number = 0;
  public chartLoading: number = 0;

  public heatmapImageLayersData: HeatmapData[] = [];
  public noContentAvailable: boolean = false;

  private heatmapOverlayLayer: any | undefined;
  private dataLayer: google.maps.Data | undefined;
  public selectedTimeStyles: any = {
    maxWidth: '70px',
    top: '100%',
    left: '0',
    zIndex: 9999999,
    fontSize: '12px',
    lineHeight: '1',
    borderColor: 'var(--border-basic-color-4) !important',
    boxShadow: 'var(--shadow)', //'2px 2px 5px var(--background-basic-color-2)',
    backgroundColor: 'var(--background-basic-color-1)',
    transform: 'translate(-50%, -50%)',
  };

  public get currentHeatmapDataIndex(): number {
    return this.player.currentIndex;
  }
  public set currentHeatmapDataIndex(value: number) {
    this.player.currentIndex = value;
    this.updateHeatmap();
  }

  public selectedParameter: Unit | undefined;
  public parameters: Unit[] = [];

  private selectedLatLngMarker: google.maps.Marker | undefined;
  private selectedHeatmap: Heatmap | undefined;

  public selectedTime: string = '';
  public shouldShowSelectedTime: boolean = false;
  public removeSelectedTime: boolean = false;
  private hiddingSelectedTime: NodeJS.Timeout | null = null;
  private removingSelectedTime: NodeJS.Timeout | null = null;

  public deviceData: any;
  public device: any = {
    label: 'Heatmap',
  };

  private readonly heatmapClickEvent = {
    name: 'click',
    callback: (eve: any) => {
      if (this.chartLoading) {
        this.notificationService.showSnackBar(
          'Wait till data is being fetched!',
          'info'
        );
        return;
      }
      this.selectedLatLng = {
        lat: eve.latLng.lat(),
        lng: eve.latLng.lng(),
      };
      this.setLocationMarker(this.selectedLatLng.lat, this.selectedLatLng.lng);
      this.fetchHeatmapLatLngData(
        this.selectedLatLng.lat,
        this.selectedLatLng.lng
      );
    },
  };

  public fields: DeviceField[] = [];
  public limits: FieldLimit[] = [];
  private selectedLatLng: { lat: number; lng: number } | undefined;

  public sliderConfig: any = {
    min: 0,
    max: 0,
    disabled: true,
  };
  public noData: ContentUnavailable = {
    isConfiguration: true,
    svgImage: AppConstants.EMPTY_REPORT_LIST,
    majorText: 'You have No Heatmap Configured.',
    linkText: 'Add Heatmap',
    minorText: 'and Proceed',
    createFunc: () => this.addHeatmap(),
  };

  private userTimeformat: number = 24;

  private subscriptions: Subscription[] = [];

  public aqiIndexColor!: AqiIndexColorArray;

  private destroy$: Subject<void> = new Subject<void>();

  private player: HeatmapPlayer = {
    isPlaying: false,
    currentIndex: 0,
    speed: 1,
  };

  private resizeObserver: ResizeObserver = new ResizeObserver(() => {});
  devices?: DeviceDetails[];
  iconDataUrl: string = '';

  constructor(
    public googleMapsService: GoogleMapsService,
    public customMomentService: CustomMomentService,
    public heatmapService: HeatmapService,
    public deviceService: DeviceService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private themeService: NbThemeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formsService : FormsService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    
    // this.loading++;
    // this.themeChange++;
    // this.chartLoading++;
    // setTimeout(() => {
    //   this.loading--;
    //   this.themeChange--;
    //   this.chartLoading--;
    // });
    let heatmapList = this.heatmapService.heatmaps;
    this.heatmapService.clearHeatmapFilters(
      this.heatmapService.getDefaultValuesForHeatmapFilters()
    );
     this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() => this.googleMap?.idle),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as unknown as GMap)),
        switchMap(() => this.googleMap!.zoomChanged)
      )
      .subscribe(() => {});
    // this.subscriptions.push(
    //   this.heatmapService.heatmaps$.subscribe((heatmaps) => {
    //     if (this.heatmapService.heatmapFilters.heatmap?.heatmapId) {
    //       let selectedHeatmapId =
    //         this.heatmapService.heatmapFilters.heatmap.heatmapId;
    //       let selectedHeatmap = heatmaps.find(
    //         (heatmap) => heatmap.heatmapId === selectedHeatmapId
    //       );
    //       this.heatmapService.updateHeatmapFilters({
    //         heatmap: selectedHeatmap ?? {
    //           heatmapId: selectedHeatmapId,
    //         },
    //       });
    //     } else {
    //       this.heatmapService.updateHeatmapFilters({
    //         heatmap: heatmaps[0],
    //       });
    //     }
    //   })
    // );
    this.subscriptions.push(
      this.heatmapService.heatmapFilters$.subscribe({
        next: ({ heatmap, startDate, endDate, average }) => {
          this.selectedHeatmap = heatmap;
          this.deviceData = undefined;
          if (this.heatmapOverlayLayer) {
            this.heatmapOverlayLayer.setMap(null);
            this.heatmapOverlayLayer = null;
            this.selectedParameter = undefined;
          }
          
          if (heatmap?.heatmapId) {
            this.router.navigateByUrl('/heatmap/map/' + heatmap.heatmapId);
            // if (
            //   this.activatedRoute.snapshot.params['heatmapId'] !==
            //   heatmap.heatmapId
            // ) {
            //   this.router.navigateByUrl('/heatmap/map/' + heatmap.heatmapId);
            // }
            const deviceTypeId =
              DeviceUtil.getDeviceTypeIdByDeviceId(
                this.commonService.getAllDeviceTypes(),
                this.deviceService.registeredDevicesWithVibration ?? [],
                this.heatmapService.heatmapFilters.heatmap?.devices?.[0] ?? ''
              ) ?? 1001;
            const units = this.commonService.getAllUnits()[deviceTypeId];

            this.fields = this.deviceService.fetchFields(units, false);
            this.limits = this.deviceService.fetchLimits(units, false);

            let deviceTypes = this.commonService.getAllDeviceTypes();
            let allAqi = this.commonService.getAllAQI();
            let allAqis = this.commonService.getAllAQIs();

            this.aqiIndexColor = DeviceUtil.aqiColorArray(
              deviceTypes,
              allAqi,
              allAqis,
              undefined,
              deviceTypes.find(
                (deviceType: DeviceType) =>
                  deviceType.deviceTypeId === deviceTypeId
              )?.key
            );
            if(this.selectedLatLngMarker){
              this.selectedLatLngMarker?.setMap(null);
            }
            if (startDate && endDate && average) {
              this.fetchHeatmapImageLayers(
                heatmap as Heatmap,
                startDate,
                endDate,
                average,
              );
            }
          }
        },
      })
    );

    let selectedHeatmapId = this.activatedRoute.snapshot.params['heatmapId'];
    if (selectedHeatmapId) {
      this.heatmapService.updateHeatmapFilters({
        heatmap: { heatmapId: selectedHeatmapId },
      });
    }else if (this.heatmapService.heatmapFilters.heatmap?.heatmapId) {
      this.heatmapService.updateHeatmapFilters({
        heatmap: {
          heatmapId: this.heatmapService.heatmapFilters.heatmap.heatmapId,
        },
      });
    } else if (this.heatmapService.heatmaps.length) {
      this.heatmapService.updateHeatmapFilters({
        heatmap: this.heatmapService.heatmaps[0],
      });
    }
    // this.subscriptions.push(map);
    if(heatmapList?.length){
      this.noContentAvailable = false;
    }else{
      this.noContentAvailable = true;
    }
  }

  ngAfterViewInit(): void {
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        this.loading++;
        this.themeChange++;
        this.chartLoading++;
        setTimeout(() => {
          this.loading--;
          this.themeChange--;
          this.chartLoading--;
        });
        let options = this.options;
        if (themeName == 'material-dark') {
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };
        // setTimeout is used because, fitBounds function should run after the map is re-rendered after the theme change.
        setTimeout(() => {
          if (
            this.googleMap?.googleMap &&
            this.heatmapService.heatmapFilters.heatmap?.heatmapId
          ) {
            this.fitBounds(
              this.googleMap?.googleMap,
              this.getLatLngBounds(
                this.heatmapService.heatmapFilters.heatmap.boundary ?? []
              )
            );
            this.heatmapOverlayLayer = null;
            this.updateHeatmap();
          }
        });
      });
  }

  /**
   * @description set controls and update state after the map is ready
   * @param map Map object to set the controls
   * @functionsUsed `initializeData`
   * @variablesUsed `heatmapProjectId`, `boundingBox`, `googleMap`
   * @modifies `mapInitialized`
   */
  public mapReady(map: GMap): void {
    this.ImageTransitionMapOverlay = customMapImageLayerFactory();

    if (
      this.heatmapService.heatmapFilters.heatmap?.heatmapId &&
      this.heatmapImageLayersData.length &&
      this.googleMap?.googleMap
    ) {
      this.updateHeatmap();
    }
  }

  private getLatLngBounds(boundary: google.maps.LatLngLiteral[]) {
    let bounds = new google.maps.LatLngBounds();
    boundary.forEach((b) => {
      bounds.extend(b);
    });
    return bounds;
  }

  private fetchHeatmapImageLayers(
    heatmap: Heatmap,
    startDate: Moment,
    endDate: Moment,
    average: AverageHour,
  ) {
    this.heatmapImageLayersData = [];
    this.updateSliderConfig();
    this.loading++;
    this.heatmapService
      .fetchHeatmapImageLayers({
        heatmapId: heatmap.heatmapId,
        startDate: { moment: startDate },
        endDate: { moment: endDate },
        average,
      })
      .subscribe({
        next: (heatmapImageData: any[]) => {
          this.heatmapImageLayersData = heatmapImageData;
          this.parameters = this.getParameters(this.heatmapImageLayersData);
          this.selectParameter(this.selectedParameter ?? this.parameters[0]);

          let bounds = new google.maps.LatLngBounds();
          if (heatmap.boundary) {
            bounds = this.getLatLngBounds(heatmap.boundary);
          }
          // if (this.googleMap?.googleMap) {
          //   this.fitBounds(this.googleMap.googleMap, bounds);
          // }
          if (
            this.heatmapService.heatmapFilters.heatmap?.heatmapId &&
            this.heatmapImageLayersData &&
            this.googleMap?.googleMap
          ) {
            if (this.heatmapService.heatmapFilters.heatmap?.geojson) {
              if (this.dataLayer) {
                this.dataLayer.setMap(null);
                this.dataLayer = undefined;
                this.googleMap.googleMap.overlayMapTypes.clear();
                this.dataLayer = undefined;
              }
              this.dataLayer = this.createAndSetGeoJSONDataLayer(
                this.googleMap?.googleMap,
                this.heatmapService.heatmapFilters.heatmap?.geojson,
                GEOJSON_DATA_LAYER_STYLES,
                [this.heatmapClickEvent]
              );
            }
            // if (this.googleMap?.googleMap) {
            //   this.fitBounds(this.googleMap.googleMap, bounds);
            // }
            this.updateHeatmap();

            this.updateSliderConfig(this.heatmapImageLayersData.length);
          }
        },
        error: (err) => {
          this.loading--;
          this.notificationService.showNotification(
            err.error,
            AppConstants.CLOSE,
            'bottom',
            'center',
            'error',
            5000
          );
        },
        complete: () => {
          this.loading--;
        },
      });
  }

  private fetchHeatmapDataByLatLng(
    heatmap: Heatmap,
    startDate: Moment,
    endDate: Moment,
    position: {
      lat: number;
      lng: number;
    },
    average: AverageHour,
  ) {
    this.chartLoading++;
    let deviceTypes = this.commonService.getAllDeviceTypes();
    this.heatmapService
      .fetchHeatmapDataByLatLng(
        {
          heatmapId: heatmap.heatmapId,
          startDate: { moment: startDate },
          endDate: { moment: endDate },
          position,
          average,
        },
        DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
          deviceTypes,
          DeviceUtil.getDeviceTypeIdByDeviceId(
            deviceTypes,
            this.deviceService.registeredDevicesWithVibration ?? [],
            this.heatmapService.heatmapFilters.heatmap?.devices?.[0] ?? ''
          ) ?? 1001
        )
      )
      .subscribe({
        next: (heatmapLatLngData: any[]) => {
          this.deviceData = heatmapLatLngData;

          let bounds = this.getLatLngBounds([position]);

          if (this.googleMap?.googleMap) {
            this.fitBounds(this.googleMap.googleMap, bounds);
          }
        },
        error: (err) => {
          this.chartLoading--;
          this.notificationService.showNotification(
            err.error,
            AppConstants.CLOSE,
            'bottom',
            'center',
            'error',
            5000
          );
        },
        complete: () => {
          this.chartLoading--;
        },
      });
  }

  private fitBounds(map: GMap, latLngBounds: google.maps.LatLngBounds) {
    map.fitBounds(latLngBounds);
  }

  /**
   * @description creates a data layer from the geoJSON, also sets the layer on a map, apply the styles if passed
   * @param map google map object to add the created data layer
   * @param geoJSON geoJSON for the layer to be added
   * @param layerStyles styles for the layer to be added, default *null*
   * @returns {google.maps.Data} data layer with the
   */
  private createAndSetGeoJSONDataLayer(
    map: GMap,
    geoJSON: any,
    layerStyles:
      | google.maps.Data.StylingFunction
      | google.maps.Data.StyleOptions
      | null = null,
    events: {
      name: string;
      callback: Function;
    }[]
  ) {
    let dataLayer = new google.maps.Data();

    dataLayer.addGeoJson(geoJSON);

    dataLayer.setStyle(layerStyles);

    events.forEach((event) => {
      dataLayer.addListener(event.name, event.callback);
    });

    dataLayer.setMap(map);

    return dataLayer;
  }

  private updateSliderConfig(dataLength: number = -1) {
    this.currentHeatmapDataIndex = 0;
    this.sliderConfig.min = 0;
    this.sliderConfig.max = dataLength;
    this.sliderConfig.disabled = dataLength === -1;

    this.sliderConfig = { ...this.sliderConfig };
  }

  /**
   * @description From the heatmap layers data, gets the units object for all the parameters
   * @param heatmapData heatmap layers data
   * @dependsOn `commonService`
   * @functionsUsed `commonService`.`getAllUnits`
   * @returns list of all the params for the heatmap
   */
  private getParameters(heatmapData: HeatmapData[]): Unit[] {
    let params: string[] = (
      Array.from(
        heatmapData.reduce((prev: Set<string>, curr: HeatmapData) => {
          for (let key of Object.keys(curr)) {
            prev.add(key);
          }
          return prev;
        }, new Set<string>()) ?? []
      ) as string[]
    ).filter((p: string) => !['t', 'ctx'].includes(p));
    const units =
      this.commonService.getAllUnits()[
        DeviceUtil.getDeviceTypeIdByDeviceId(
          this.commonService.getAllDeviceTypes(),
          this.deviceService.registeredDevicesWithVibration ?? [],
          this.heatmapService.heatmapFilters.heatmap?.devices?.[0] ?? ''
        ) ?? 1001
      ];
    return params
      .map((p) => {
        return units[p];
      })
      .filter((p) => p);
  }

  private setLocationMarker(lat: number, lng: number) {
    if (this.selectedLatLngMarker) {
      this.selectedLatLngMarker.setMap(null);
    }
    const deviceTypeId = this.selectedHeatmap?.deviceTypeId ?? 1001;
    this.iconDataUrl = '';
    if(deviceTypeId === 1006){
      this.iconDataUrl = 'assets/images/pins/3rd_party_device_pin.svg';
    }
    else{
      const deviceType = DeviceUtil.getDeviceTypeByKeyOrId(
        deviceTypeId,
        this.commonService.getAllDeviceTypes()
      );
      this.iconDataUrl = DeviceUtil.getIconUrlForHeatmapPin(deviceType!);
    }
    this.selectedLatLngMarker = new google.maps.Marker({
      position: { lat, lng },
      icon: this.iconDataUrl,
    });
    if (this.googleMap?.googleMap) {
      this.selectedLatLngMarker.setMap(this.googleMap.googleMap);
    }
  }

  private fetchHeatmapLatLngData(lat: number, lng: number) {
    this.deviceData = undefined;

    let { heatmap, startDate, endDate, average } =
      this.heatmapService.heatmapFilters;

    if (heatmap?.heatmapId?.length && startDate && endDate && average) {
      // fetch and update data
      this.fetchHeatmapDataByLatLng(
        heatmap as Heatmap,
        startDate,
        endDate,
        { lat, lng },
        average,
      );
    }
  }

  public selectParameter(param: Unit): void {
    this.selectedParameter = param;
    if (this.isPlaying) {
      this.stopAnimation();
    }
    this.updateHeatmap();
    this.currentHeatmapDataIndex = 0;

  }

  public get isPlaying(): boolean {
    return this.player.isPlaying;
  }

  /**
   * Handles play/pause functionality for the heatmap animation
   */
  public playPauseHeatMap(): void {
    this.player.isPlaying = !this.player.isPlaying;

    if (this.player.isPlaying) {
      this.startAnimation();
    } else {
      this.stopAnimation();
    }
  }

  private startAnimation(): void {
    // Cancel any existing animation
    this.stopAnimation();

    let lastFrameTime = performance.now();

    const animate = (currentTime: number) => {
      if (!this.player.isPlaying) return;

      const elapsed = currentTime - lastFrameTime;
      const frameInterval = BASE_FRAME_INTERVAL / this.player.speed;

      if (elapsed >= frameInterval) {
        lastFrameTime = currentTime;

        // Update frame
        this.player.currentIndex =
          (this.player.currentIndex + 1) % (this.sliderConfig.max + 1);
        this.updateHeatmap();
      }

      this.player.animationFrameId = requestAnimationFrame(animate);
    };

    this.player.animationFrameId = requestAnimationFrame(animate);
  }

  private stopAnimation(): void {
    if (this.player.animationFrameId) {
      cancelAnimationFrame(this.player.animationFrameId);
      this.player.animationFrameId = undefined;
    }
  }

  /**
   * Updates the heatmap display with current frame data
   */
  public async updateHeatmap(): Promise<void> {
    
    if (!this.selectedParameter || !this.heatmapImageLayersData.length) return;

    const currentFrame = this.heatmapImageLayersData[this.player.currentIndex];
    if (!currentFrame) return;

    this.setSelectedTime(currentFrame['t']);

    const imageData = currentFrame[this.selectedParameter.key];
    if (!imageData) return;
    try {
      await this.updateOverlayWithTransition(
        `${imageData}`,
        this.heatmapService.heatmapFilters.heatmap as Heatmap
      );
    } catch (error) {
      console.error('Error updating heatmap:', error);
    }
  }

  private async loadImage(
    dataUrl: string,
    geojson: any
  ): Promise<HTMLImageElement> {
    dataUrl = await this.heatmapService.getMaskedImage(geojson, dataUrl);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = dataUrl;
    });
  }

  // Usage in your component
  private async updateOverlayWithTransition(url: string, heatmap: Heatmap) {
    const image = await this.loadImage(url, heatmap?.geojson);
    if (this.heatmapOverlayLayer) {
      this.heatmapOverlayLayer.updateImage(image);
    } else {
      if (!this.ImageTransitionMapOverlay) {
        this.ImageTransitionMapOverlay = customMapImageLayerFactory();
      }
      this.heatmapOverlayLayer = new this.ImageTransitionMapOverlay(
        this.getLatLngBounds(heatmap.boundary),
        image,
        // this.googleMap?.googleMap as GMap,
      );
      if (this.googleMap?.googleMap) {
        this.heatmapOverlayLayer.setMap(this.googleMap.googleMap);
        this.fitBounds(
          this.googleMap?.googleMap,
          this.getLatLngBounds(
            this.heatmapService?.heatmapFilters?.heatmap?.boundary ?? []
          )
        );
      }
    }
  }

  private setSelectedTime(epoch: number) {
    this.selectedTime = this.customMomentService.formatDatetime({
      epoch,
      format:
        this.userTimeformat === 12
          ? 'DD/MM/YY<br/>hh:mm A'
          : 'DD/MM/YY<br/>HH:mm',
    });
    this.selectedTimeStyles.left = `${
      (this.currentHeatmapDataIndex / this.sliderConfig.max) * 100
    }%`;
  }

  public onMapOptionsUpdated(options: MapOptions) {
    this.options = { ...options };
  }

  public showSelectedTime() {
    if (this.hiddingSelectedTime) {
      clearTimeout(this.hiddingSelectedTime);
      if (this.removingSelectedTime) {
        clearTimeout(this.removingSelectedTime);
        this.removingSelectedTime = null;
      }
      this.hiddingSelectedTime = null;
    }
    this.shouldShowSelectedTime = true;
    this.removeSelectedTime = false;
  }

  public hideSelectedTime() {
    if (this.hiddingSelectedTime) {
      clearTimeout(this.hiddingSelectedTime);
      this.hiddingSelectedTime = null;
    }
    this.shouldShowSelectedTime = true;
    this.hiddingSelectedTime = setTimeout(() => {
      this.shouldShowSelectedTime = false;

      this.removeSelectedTime = false;
      if (this.removingSelectedTime) {
        clearTimeout(this.removingSelectedTime);
        this.removingSelectedTime = null;
      }
      this.removingSelectedTime = setTimeout(() => {
        this.removeSelectedTime = true;

        this.removingSelectedTime = null;
      }, 1000);

      this.hiddingSelectedTime = null;
    }, 500);
  }

  public closeChartsView() {
    // if (this.selectedPolygonLayer) {
    //   this.selectedPolygonLayer.setMap(null);
    // }
    // this.selectedPolygonLayer = undefined;
    this.selectedLatLngMarker?.setMap(null);
    if (
      this.googleMap?.googleMap &&
      this.heatmapService.heatmapFilters.heatmap?.boundary
    ) {
      this.googleMap?.googleMap.fitBounds(
        this.getLatLngBounds(
          this.heatmapService.heatmapFilters.heatmap?.boundary
        )
      );
    }
    this.deviceData = undefined;
  }

  addHeatmap() {
    this.formsService.openForm(HeatmapFormComponent, '', 'lg');
  }

  ngOnDestroy() {
    this.stopAnimation();

    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription.closed) subscription.unsubscribe();
    });
    this.heatmapService.clearHeatmapFilters(
      this.heatmapService.getDefaultValuesForHeatmapFilters()
    );

    this.resizeObserver.disconnect();

    if (this.heatmapOverlayLayer) {
      this.heatmapOverlayLayer.setMap(null);
    }
  }
  private ImageTransitionMapOverlay: any = null;
}
