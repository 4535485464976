import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { ReportData } from 'src/app/shared/models/reports/report-data';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { ReportFormComponent } from '../../components/report-form/report-form.component';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public allReports: ReportData.Get[];
  public devices: DeviceDetails[] = [];
  public displayedColumns: string[] = [];
  public isAdmin: boolean = false;
  public currentDeviceType!: number;
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public dialogComponentInstanceForDeleteReport!: ConfirmationPopupComponent;
  public subscriptions: Subscription[] = [];

  defaultColumns: any[] = [
    {
      columnDef: 'actions',
      header: '',
      cell: (element: DeviceDetails) => `${element.deviceId}`,
      parameter: false,
      selected: false,
      icon: 'more_vert',
      options: [
        {
          label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
          action: (report: ReportData.Get) => this.updateReportDetails(report),
        },
        {
          label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
          action: (report: ReportData.Get) => this.openDialog(report),
        },
      ],
    },
    {
      columnDef: 'deviceId',
      header: 'Device ID',
      cell: (element: ReportData.Get) => `${element.deviceId}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'deviceLabel',
      header: 'Device Label',
      cell: (element: ReportData.Get) =>
        `${this.getDeviceName(element.deviceId)}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: ReportData.Get) => {
        return element?.email?.split(',') || [];
      },
      parameter: false,
      selected: false,
      columnType: 'chip',
    },
    {
      columnDef: 'duration',
      header: 'Duration',
      cell: (element: ReportData.Get) =>
        `${AppConstants.formatPeriodForList[element?.period]}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'alertStatus',
      header: 'Status',
      cell: (element: ReportData.Get) => {
        return element?.enable ? 'Enabled' : 'Disabled';
      },
      color: (element: ReportData.Get) => {
        return element?.enable ? '#008000' : '#FF0000';
      },
      parameter: false,
      selected: false,
    },
  ];

  public noData: ContentUnavailable = {
    isConfiguration: true,
    svgImage: AppConstants.EMPTY_REPORT_LIST,
    majorText: 'You have No Reports Configured for this Device Type.',
    linkText: 'Add Reports',
    minorText: 'and Proceed',
    createFunc: () => this.updateReportDetails(undefined),
  };

  constructor(
    private reportsService: ReportsService,
    // private userService: UserService,
    private deviceService: DeviceService,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog
  ) {
    this.allReports = [];
  }

  ngOnInit(): void {
    if (this.deviceService.registeredDevices)
      this.devices = this.deviceService.registeredDevices;
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.reportsService.getReportDetails();
    this.addSubscriptions();
  }

  loadData(): void {
    this.loadTable.next(false);
    // let user = this.userService.getLocaluser();
    // if (user && user?.userId && user?.role == 1) {
    //   this.isAdmin = true;
    //   this.deviceService.getAdminDevices().subscribe({
    //     next: (res: any[]) => {
    //       this.devices = res;
    //       this.loadTable.next(true);
    //     },
    //     error: (err) => {
    //       this.loadTable.next(true);
    //     },
    //   });
    // } else {
    //   this.isAdmin = false;
    //   if (this.deviceService.registeredDevices) {
    //     this.devices = this.deviceService.registeredDevices;
    //   }
    //   setTimeout(() => {
    //     this.loadTable.next(true);
    //   });
    // }
    setTimeout(() => {
      this.loadTable.next(true);
    }, 1000);
  }

  getDeviceName(deviceId: string) {
    return this.devices.find((device: DeviceDetails) => {
      return device.deviceId === deviceId;
    })?.label;
  }

  updateReportDetails(device: ReportData.Get | undefined): void {
    this.formsService.openForm(ReportFormComponent, device, 'md');
  }

  openDialog(report: ReportData.Get): void {
    const dynamicData = {
      title: 'Delete Report',
      message: 'Are you sure you want to delete this report?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteReport(report);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRefForDeleteReport = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogComponentInstanceForDeleteReport =
      dialogRefForDeleteReport.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRefForDeleteReport);
  }

  deleteReport(report: ReportData.Get) {
    this.dialogComponentInstanceForDeleteReport.dialogConfig.image =
      AppConstants.LOADING;
    this.dialogComponentInstanceForDeleteReport.dialogConfig.message =
      'Please Wait...';
    this.dialogComponentInstanceForDeleteReport.dialogConfig.hideActionButtons =
      true;
    this.reportsService.deleteReport(report).subscribe({
      next: (res) => {
        if (res) {
          this.dialogComponentInstanceForDeleteReport.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogComponentInstanceForDeleteReport.dialogConfig.message =
            'Report Deleted Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.reportsService.getReportDetails();
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info("Error:", err);
        this.dialogComponentInstanceForDeleteReport.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogComponentInstanceForDeleteReport.dialogConfig.message =
          'Error While Deleting Report. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
          this.reportsService.getReportDetails();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  addSubscriptions() {
    let report = this.reportsService.isReportListAvailable$.subscribe(
      (res: any) => {
        if (res) {
          this.allReports = this.reportsService.reportList;
          this.loadData();
        }
      }
    );
    this.subscriptions.push(report);

    let deviceType = this.reportsService.currentDeviceType$.subscribe(
      (res: number) => {
        if (res) {
          this.currentDeviceType = res;
        }
      }
    );

    this.subscriptions.push(deviceType);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
