<app-terminal-page-layout [menu]="[]">
  <app-terminal-page-header [menu]="[]" [configureButton]="false">
    <div class="d-flex align-items-center justify-content-end">
      <button
        mat-raised-button
        color="primary"
        class="w-100 w-lg-auto"
        (click)="addDisplay()"
        matTooltip="Add Display"
      >
      <mat-icon class="material-symbols-outlined">add</mat-icon> Add Display
      </button>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
