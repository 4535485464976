import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';
import { RealTimeWidgetComponent } from '../real-time-widget/real-time-widget.component';

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: ['./widget-wrapper.component.scss'],
})
export class WidgetWrapperComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this._widgetInfo = v;
    this._cdr.detectChanges();
  }

  private _chartType: 'line' | 'column' = 'line';
  public get chartType(): 'line' | 'column' {
    return this._chartType;
  }
  public set chartType(v: 'line' | 'column') {
    this._chartType = v ?? 'line';
    if (this.widgetComponentInstance) {
      if (this.widgetComponentInstance.instance.widgetInfo?.chartTypeConfig) {
        this.widgetComponentInstance.instance.widgetInfo.chartTypeConfig.selectedValue =
          this._chartType;

        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetComponentInstance.instance.widgetInfo,
        };
      }
    }
  }

  private _direction: 'to' | 'from' = 'to';
  public get direction(): 'to' | 'from' {
    return this._direction;
  }
  public set direction(v: 'to' | 'from') {
    this._direction = v ?? undefined;
    if (this.widgetComponentInstance) {
      if (this.widgetComponentInstance.instance.widgetInfo?.directionConfig) {
        this.widgetComponentInstance.instance.widgetInfo.directionConfig.selectedValue =
          this._direction;

        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetComponentInstance.instance.widgetInfo,
        };
      }
    }
  }

  @Input() widgetComponent: Type<IWidgetComponent> = RealTimeWidgetComponent;

  @Output() noDataTrigger: EventEmitter<any> = new EventEmitter();

  @ViewChild('container', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  private widgetComponentInstance: ComponentRef<IWidgetComponent> | undefined;

  private subscriptions: Array<Subscription> = [];

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.widgetComponent && this.widgetInfo) {
      this.widgetComponentInstance = this.viewContainerRef.createComponent(
        this.widgetComponent
      );
      if (this.widgetComponentInstance) {
        this.subscriptions.push(
          this.widgetComponentInstance.instance.noDataTrigger?.subscribe?.({
            next: () => {
              this.noDataTrigger.emit(this.widgetInfo.widgetId);
            },
          })
        );
        this.widgetComponentInstance.instance.styles = [
          `
          display: 'block',
          width: '100%',
          height: '100%',
        `,
        ];
        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetInfo,
        };
        this.widgetComponentInstance.instance.parameterSelectionChange?.subscribe?.(
          (param: any) => {
            this.widgetInfo.widgetTitle = param;
          }
        );
        this.chartType =
          this.widgetInfo?.chartTypeConfig?.selectedValue ?? 'column';
        this.direction =
          this.widgetInfo?.directionConfig?.selectedValue ?? 'from';
      }
    }
  }

  public selectChartType(option: any) {
    this.chartType = option.value;
  }

  public selectDirection(option: any) {
    this.direction = option.value;
  }

  public onOptionClick(option: any, optionIndex: number) {
    option.state = !option.state;
    if (this.widgetComponentInstance) {
      this.widgetComponentInstance.instance.widgetInfo = {
        ...this.widgetComponentInstance.instance.widgetInfo,
      };
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription?.closed) {
        subscription?.unsubscribe();
      }
    });
  }
}
