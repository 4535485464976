export const environment = {
  production: false,
  openWeatherAPI: '09dea27793930ca6dc1161d3ab99e995',
  URL: 'https://envdevapi.oizom.com/',
  config: {
    DBURL: 'https://envdevapi.oizom.com/',
    DBURL_VERSION: 'https://envdevapi.oizom.com/',
    WSURL: 'https://livedata.oizom.com/',
    ORG_URL: 'assets/oizom-about_org.png',
    LOGO_URL: 'assets/oizom-about.png',
    LOCAL: false,
    MODULE_ACCESS: [
      1001, 1002, 1009, 1004, 1005, 1007, 1006, 1011, 1014, 2015, 1020, 1012,
      1008, 1003,
    ],
    TOOLBAR_ACCESS: [4002, 4003, 4004, 4005, 4007],
    IS_MULTI_LANG: true,
    MAP_LAYER: [5001, 5002],
    XUSERS: [2494, 2431],
    ISSECURE: true,
    RESTRICTED_USER: [2161, 2163, 2303, 2586],
    SHOWCAPTCHA: false,
    POPUP_TYPES: [1002],
    ENCRYPTION_KEY: 'JNyG68b3FXsxDh2PmECX6e5GRVQgFFf5',
    CAPTCHASITEKEY: '6LcalsIZAAAAABYIZoNzG9G-pjCmR3oap7cR_Qsy',
    GA_MEASURE_ID: 'G-NFN0D9JJ46',
    THEME: 'light-theme',
    LICENCE: [
      {
        name: 'Software Owner Details',
        value: 'Oizom Instruments Pvt Ltd',
      },
      {
        name: 'Product Description',
        value: 'Smart Solutions Software - Environmental Sensors',
      },
      {
        name: 'Software License Quantity',
        value: '1',
      },
      {
        name: 'Software License Period Validity',
        value: 'Perpetual',
      },
      {
        name: 'Device Node Support',
        value: '12',
      },
      {
        name: 'Reseller Partner',
        value: 'Oizom',
      },
    ],
  },
};
