import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { sortBy } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { SelectionTile } from 'src/app/shared/models/internal-use-front-end/selection-tile';
import { Widget } from 'src/app/shared/models/widget/widget';
import { CommonService } from 'src/app/shared/services/common.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

type WidgetOption = {
  id: number;
  label: string;
  previewImage: string;
};

@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.scss'],
})
export class WidgetFormComponent implements OnInit, OnDestroy {
  displayTitle: string = 'Add or Remove Widgets';
  public submitBtn: string = 'Submit';
  public backBtn: string = 'Cancel';
  public isNextStep: boolean = true;
  loadCardSelection: Subject<boolean> = new BehaviorSubject(false);

  private _deviceTypes: DeviceType[] = [];
  public get deviceTypes(): DeviceType[] {
    return this._deviceTypes;
  }
  public get deviceTypesObj(): { [key: string]: DeviceType } {
    return Object.fromEntries(this._deviceTypes.map((dt) => [dt.key, dt]));
  }
  public set deviceTypes(v: DeviceType[]) {
    this._deviceTypes = v;
  }

  private widgets: Widget[] = [];

  private _widgetOptions: WidgetOption[] = [];
  public get widgetOptions(): WidgetOption[] {
    return this._widgetOptions;
  }
  public set widgetOptions(v: WidgetOption[]) {
    this._widgetOptions = v;
  }

  public deviceTypeId: number | undefined;
  public selectedWidgets: number[];
  public widgetsToAdd: SelectionTile[] = [];

  public cardDetails: SelectionTile[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private formsService: FormsService,
    @Inject(MTX_DRAWER_DATA)
    public data:
      | {
          deviceTypeId: number;
          selectedWidgets: number[];
        }
      | undefined
  ) {
    this.deviceTypeId = data?.deviceTypeId;
    this.selectedWidgets = data?.selectedWidgets ?? [];
  }

  ngOnInit(): void {
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.dashboardService.fetchWidgets().subscribe({
      next: (widgets: Widget[]) => {
        this.widgets = widgets;
        if (this.deviceTypeId) {
          let deviceType = DeviceUtil.findDeviceType(
            this.deviceTypeId,
            this.deviceTypes
          );
          this.cardDetails = sortBy(
            this.widgets
              .filter((widget: Widget) =>
                deviceType.widgets.includes(widget.widgetId)
              )
              .map((widget: Widget): SelectionTile => {
                return {
                  id: widget.widgetId,
                  title: widget.label,
                  lightModeImageUrl: `assets/images/dashboard/widgets/light/${widget.widgetId}.png`,
                  darkModeImageUrl: `assets/images/dashboard/widgets/dark/${widget.widgetId}.png`,
                  iconTooltip: widget.label,
                  icon: '',
                  isDefaultSelected:
                    this.data?.selectedWidgets.includes(widget.widgetId) ??
                    false,
                  disabled: this.data?.selectedWidgets.includes(
                    widget.widgetId
                  ),
                };
              }),
            'ASC',
            'disabled'
          );
          this.loadCardSelection.next(true);
        }
      },
    });
  }

  public onWidgetSelection(selectedWidget: SelectionTile) {
    this.widgetsToAdd = JSON.parse(JSON.stringify(selectedWidget));
  }

  public submitAndNext(event?: any) {
    event = !event;
    this.submitBtn = 'Submit';
    this.backBtn = 'Back';
    this.dashboardService.addNewWidgets(this.widgetsToAdd);
    this.closeForm();
  }

  //it executes when user click on back button of the form
  public previous(event?: any) {
    event = !event;
    this.formsService.closeForm();
  }

  //executes when user clicks on cancel button
  public closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  public widgetOptionTrackby(index: number, option: WidgetOption) {
    return option.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}
