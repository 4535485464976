import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from 'angular-gridster2';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { WidgetFormComponent } from './components/widget-form/widget-form.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { TableComponent } from './pages/table/table.component';
import { AqiTrendsWidgetComponent } from './pages/widget/components/aqi-trends-widget/aqi-trends-widget.component';
import { HourlyDataWidgetComponent } from './pages/widget/components/hourly-data-widget/hourly-data-widget.component';
import { LiveDataWidgetComponent } from './pages/widget/components/live-data-widget/live-data-widget.component';
import { LocationWidgetComponent } from './pages/widget/components/location-widget/location-widget.component';
import { NoiseLevelWidgetComponent } from './pages/widget/components/noise-level-widget/noise-level-widget.component';
import { PollutionRoseWidgetComponent } from './pages/widget/components/pollution-rose-widget/pollution-rose-widget.component';
import { PredictionWidgetComponent } from './pages/widget/components/prediction-widget/prediction-widget.component';
import { RainFloodWidgetComponent } from './pages/widget/components/rain-flood-widget/rain-flood-widget.component';
import { RealTimeWidgetComponent } from './pages/widget/components/real-time-widget/real-time-widget.component';
import { WidgetWrapperComponent } from './pages/widget/components/widget-wrapper/widget-wrapper.component';
import { WindRoseWidgetComponent } from './pages/widget/components/wind-rose-widget/wind-rose-widget.component';
import { WidgetComponent } from './pages/widget/widget.component';

@NgModule({
  declarations: [
    DashboardComponent,
    TableComponent,
    WidgetComponent,
    WidgetFormComponent,
    WidgetWrapperComponent,
    AqiTrendsWidgetComponent,
    HourlyDataWidgetComponent,
    LiveDataWidgetComponent,
    LocationWidgetComponent,
    NoiseLevelWidgetComponent,
    PollutionRoseWidgetComponent,
    RainFloodWidgetComponent,
    RealTimeWidgetComponent,
    WindRoseWidgetComponent,
    PredictionWidgetComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    HighchartsChartModule,
    GridsterModule,
  ],
})
export class DashboardModule {}
