import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  modules = [
    {
      name: 'Overview',
      icon: 'assets/preview-icons/overview.svg',
    },
    {
      name: 'Dashboard',
      icon: 'assets/preview-icons/dashboard.svg',
    },
    {
      name: 'Cluster',
      icon: 'assets/preview-icons/cluster.svg',
    },
    {
      name: 'Analytics',
      icon: 'assets/preview-icons/analytics.svg',
    },
    {
      name: 'Alerts',
      icon: 'assets/preview-icons/alerts.svg',
    },
    {
      name: 'Devices',
      icon: 'assets/preview-icons/devices.svg',
    },
    {
      name: 'Reports',
      icon: 'assets/preview-icons/reports.svg',
    },
    {
      name: 'User',
      icon: 'assets/preview-icons/users.svg',
    },
  ];

  currentIndex: number = 0;
  currentRotation: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.rotateEarth();
  }

  rotateEarth() {
    let element = document.getElementById('earth-container');
    setInterval(() => {
      if (this.currentIndex == this.modules.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }

      this.currentRotation = this.currentRotation + 360 / this.modules.length;

      (element as HTMLElement).style.transform =
        'rotate(' + this.currentRotation + 'deg)';
    }, 2000);
  }
}
