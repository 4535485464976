import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceService } from '../services/device.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetailsResolver {
  constructor(private deviceService: DeviceService) {}

  resolve(): Observable<boolean> {
    return this.deviceService.setupDeviceDetails(true);
  }
}
