import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule } from './layouts/auth/auth.module';
import { TerminalModule } from './layouts/terminal/terminal.module';
import { DeviceDetailsResolver } from './shared/resolvers/device-details.resolver';
import { loginValidationGuard } from 'src/app/shared/auth-guard/login-validation.guard';
const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },
  {
    path: 'login',
    loadChildren: () => AuthModule,
    canActivate: [loginValidationGuard],
  },
  {
    path: '',
    loadChildren: () => TerminalModule,
    canActivate: [loginValidationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}