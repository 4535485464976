<app-form-container [tableTitle]="formTitle" [submitBtn]="'Update'" (submit)="submitForm()" (cancelled)="closeForm()"
  (close)="closeForm()" [isValid]="limitForm.valid">
  <div>
    <form [formGroup]="limitForm" class="row gy-3">
      <div class="col-12 col-lg-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Unit</mat-label>
          <mat-select formControlName="unit">
            <mat-option *ngFor="let unitOfGas of unitsOfGas" [value]="unitOfGas.id">
              {{ unitOfGas.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngFor="
          let limit of asFormArray(limitForm.get('limits')!).controls;
          let i = index
        ">
        <div class="col-12 col-lg-12" [formGroup]="asFormGroup(limit)">
          <mat-form-field class="w-100" appearance="outline">
            <div class="d-flex flex-row gap-3 align-items-center">
              <span class="aqi-color-circle" [style.background]="limitColors[i]"></span>
              <span> > </span>
              <input matInput [placeholder]="'Limit ' + i" type="number" formControlName="limitValue" />
            </div>
          </mat-form-field>
        </div>
      </ng-container>
      <mat-error *ngIf="isNegativeLimit">Only positive number allowed</mat-error>
      <mat-error *ngIf="!isAscendingLimit">Limits must be in Ascending Order</mat-error>
    </form>
  </div>
</app-form-container>