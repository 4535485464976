<app-form-container
  [tableTitle]="'Sensor Information (' + data.deviceId + ')'"
  [hintText]="'Note: Expiry Date is 13 months from the date of sensor placement in the device, as per warranty policy.'"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [cancelBtn]="'Cancel'"
>
  <div class="py-5" *ngIf="!loadTable">
    <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
  </div>
  <div
    class="d-flex w-100 h-100 justify-content-center align-items-center"
    *ngIf="loadTable && dataSource.length === 0"
  >
    <p>No Data Found</p>
  </div>
  <div
    *ngIf="loadTable && dataSource.length > 0"
    class="overflow-auto w-100 h-90"
  >
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>Sensor Name</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          {{ element?.key }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sensorId">
        <th mat-header-cell *matHeaderCellDef>Sensor ID</th>
        <td mat-cell *matCellDef="let element">{{ element?.sensorId }}</td>
      </ng-container>

      <ng-container matColumnDef="updated_on">
        <th mat-header-cell *matHeaderCellDef>Updated On</th>
        <td mat-cell *matCellDef="let element">{{ element?.updated_on }}</td>
      </ng-container>

      <ng-container matColumnDef="expiry">
        <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
        <td mat-cell *matCellDef="let element" class="text-danger">
          {{ element?.expiry }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-form-container>
