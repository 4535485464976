import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-aqi-color-legend',
  templateUrl: './aqi-color-legend.component.html',
  styleUrls: ['./aqi-color-legend.component.scss']
})
export class AqiColorLegendComponent {
  @Input() aqiIndexColor!: {
    labels: string[];
    color: string[];
    range: number[];
  };
}
