import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';

import { Observable, Subject, map, tap } from 'rxjs';
import {
  DisplayDetails,
  DisplayScreen,
} from '../models/display/display-details';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisplayService extends BaseAPIService<DisplayDetails> {
  // public displayBaseUrl: string = 'https://terminal.oizom.com'; // window.location.origin;
  public displayBaseUrl = environment?.TERMINAL_URL;
  displays: DisplayDetails[] = [];
  refreshDisplayList = new Subject<boolean>();
  refreshDisplayList$ = this.refreshDisplayList.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {
    super(http, cookieService);
  }

  public getUserDisplays(): Observable<DisplayDetails[]> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const shareToken = this.localStorageService.getValue(
      LocalStorageConstants.OZ_SHARE_TOKEN
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<DisplayDetails>>(
      APIConstants.DISPLAY_DETAILS_LIST.replace('{userId}', userId),
      headers
    ).pipe(
      map((displays: DisplayDetails[]) =>
        displays.reverse().map((display: DisplayDetails) => ({
          ...display,
          share_url: `${this.displayBaseUrl}/#/plugin/iframe/${display.token}/${shareToken}`,
          screens: display.screens.map(
            (screen: DisplayScreen, index: number) => ({
              ...screen,
              screenId: index + 1,
            })
          ),
        }))
      ),
      tap((res) => {
        this.displays = res;
      })
    );
  }

  public getDisplayById(displayId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<DisplayDetails>(
      APIConstants.DISPLAY_DETAILS.replace('{userId}', userId).replace(
        '{displayId}',
        displayId.toString()
      ),
      headers
    ).pipe(
      map((display: DisplayDetails) => ({
        ...display,
        screens: display.screens.map(
          (screen: DisplayScreen, index: number) => ({
            ...screen,
            screenId: index + 1,
          })
        ),
      }))
    );
  }

  public addDisplay(payload: { data: DisplayDetails }) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.post<string>(
      APIConstants.ADD_DISPLAY(userId),
      payload,
      headers
    ).pipe(tap(() => this.displayListUpdated()));
  }

  public updateDisplay(
    displayId: number,
    payload: {
      update: Partial<DisplayDetails>;
    }
  ) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.patch<string>(
      APIConstants.UPDATE_DISPLAY(displayId, userId),
      payload,
      headers
    ).pipe(tap(() => this.displayListUpdated()));
  }

  public deleteDisplay(displayId: number) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.delete<string>(
      APIConstants.DELETE_DISPLAY.replace('{userId}', userId).replace(
        '{displayId}',
        displayId.toString()
      ),
      headers
    ).pipe(tap(() => this.displayListUpdated()));
  }

  private displayListUpdated() {
    this.refreshDisplayList.next(true);
  }
}
