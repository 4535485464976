<ng-container
  *ngIf="
    deviceData?.length && deviceData[0]?.payload?.d
      | gasLevel : fields : limits : deviceData[0]?.deviceType;
    let fields;
    else: noDataTemplate
  "
>
  <ng-container *ngIf="fields?.length; else noDataTemplate">
    <div class="row mx-0 mt-4">
      <ng-container *ngFor="let field of fields">
        <div class="col-12">
          <div class="row mx-0 mb-2">
            <div class="col" [innerHTML]="field?.label"></div>
            <div class="col-auto">
              {{ field?.value | number : "1.2-2" }}
              <small class="px-2">{{ field?.unit }}</small>
            </div>
            <div class="col-12">
              <app-field-progress-bar
                [value]="(field?.progress || 0) * 100"
                [color]="field?.color"
              ></app-field-progress-bar>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noDataTemplate>
  <div
    class="position-relative d-flex align-items-center justify-content-center m-0 w-100 h-100"
  >
    <div>
      <app-content-unavailable
        [majorText]="noData.majorText"
        [svgImage]="noData.svgImage"
        [minorText]="noData.minorText"
      ></app-content-unavailable>
    </div>
  </div>
</ng-template>
