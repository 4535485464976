import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  distinctUntilChanged,
  map,
  of,
  Subscription,
  switchMap,
  timer,
} from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageConstants } from '../../constants/local-storage.constant';
import { AqiIndexColorArray } from '../../models/aqi-index/aqi-index-color-array';
import { DeviceField } from '../../models/device/device-field';
import { FieldLimit } from '../../models/device/field-limit';
import { FilterColumn } from '../../models/filter-column';
import { FilterOptions } from '../../models/filter-options';
import { FilterValue } from '../../models/filter-value';
import { ContentUnavailable } from '../../models/internal-use-front-end/content-unavailable';
import { LocalStorageService } from '../../services/local-storage.service';
import { ProjectsService } from '../../services/projects.service';
import { CommonUtil } from '../../utils/common-utils';
import { DeviceUtil } from '../../utils/device-utils';
import { ContentUnavailableComponent } from '../content-unavailable/content-unavailable.component';
import { ImageViewPopupComponent } from '../image-view-popup/image-view-popup.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent<T> implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filter') filterElement!: ElementRef;
  @ViewChild('noData', { read: ViewContainerRef })
  noDataContainer!: ViewContainerRef;
  @Input() forceSearchOpen: boolean = false;
  private savedData!: Array<T & { cellValues?: any }>;
  private _data!: Array<T & { cellValues?: any }>;
  isTopLevelAdminSearch: boolean = false;
  user: any;
  public get data(): Array<T & { cellValues?: any }> {
    return this._data;
  }
  @Input()
  public set data(v: Array<T & { cellValues?: any }>) {
    this.savedData = JSON.parse(JSON.stringify(v));
    this._data = this.transformData(v, this.columns, this.selectedColumns);
    this.applyFilters();
  }

  // @Input() data!: Array<T & { cellValues?: any }>;
  private _columns!: any[];
  public get columns(): any[] {
    return this._columns;
  }
  @Input()
  public set columns(v: any[]) {
    this._columns = v;
    this._data = this.transformData(this.savedData, v, this.selectedColumns);
  }

  // @Input() data!: Array<T>;
  // @Input() columns!: any[];
  @Input() displayedColumns!: string[];
  @Input() stickyColumns: Array<any> = [];
  @Input() showPaginator: boolean = true;
  @Input() showTableHeader: boolean = true;
  @Input() showDeleteInHeader: boolean = true;
  @Input() showDownloadInHeader: boolean = true;
  @Input() showFilterInHeader: boolean = true;
  @Input() tableTitle!: string;
  @Input() showSearchInHeader: boolean = true;
  @Input() pageSize: number = 30;
  @Input() categories!: any[];
  @Input() noDataViewConfig!: ContentUnavailable;
  @Input() isChartVisible: boolean = false;
  @Output() onDownload: EventEmitter<Array<T & { cellValues?: any }>> =
    new EventEmitter<Array<T & { cellValues?: any }>>();
  @Output() onSelect: EventEmitter<Array<T & { cellValues?: any }>> =
    new EventEmitter<Array<T & { cellValues?: any }>>();
  @Output() dateSelected = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<Array<T & { cellValues?: any }>> =
    new EventEmitter<Array<T & { cellValues?: any }>>();
  @Output() onRowSelect = new EventEmitter<any>();
  @Output() onCellSelect = new EventEmitter<any>();
  @Output() onColumnSelect = new EventEmitter<any>();
  @Output() clearSelection = new EventEmitter<any>();
  dataSource!: MatTableDataSource<T & { cellValues?: any }>;

  // @Input() selection = new SelectionModel<T & { cellValues?: any }>(true, []);
  private _selection: SelectionModel<T & { cellValues?: any }> =
    new SelectionModel<T & { cellValues?: any }>(true, []);
  public get selection(): SelectionModel<T & { cellValues?: any }> {
    return this._selection;
  }

  @Input() public set selection(v: SelectionModel<T & { cellValues?: any }>) {
    this._selection = v;
    this._data = this.transformData(
      this.savedData,
      this.columns,
      this.selectedColumns
    );
  }
  whiteColor: string = '#FFFFFF';
  borderRadius: string = '50%';
  statuses = ['Pending', 'In Progress', 'Resolved'];
  showPopup = false;
  popupImageUrl: string = '';
  searchTerm: FormControl = new FormControl('');
  searchText: string = '';
  tableFilters: any = {};
  selectedFilters: Array<FilterValue> = [];
  fileteredData!: Array<T & { cellValues?: any }>;
  // selection = new SelectionModel<any>(true, []);
  selectedData: any[] = [];
  isDataSelected: boolean = false;
  @ViewChild(MatMenuTrigger) filterMenu!: MatMenuTrigger;
  isAccordionOpen: boolean = false;
  filterOptions!: FilterOptions;
  columnValues: { [key: string]: any[] } = {};
  deviceData: any;
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  aqiIndexColor!: AqiIndexColorArray;
  selectedColumns: any[] = [];
  isColumnSelected!: boolean;
  imageViewOverlayRef!: OverlayRef;
  imageViewOverlayComponentRef!: ComponentRef<ImageViewPopupComponent>;
  overlayRef!: OverlayRef;
  public subscriptions: Subscription[] = [];

  private transformData(
    data: Array<T & { cellValues?: any }>,
    columns: any[],
    selectedColumns: any[]
  ): Array<T & { cellValues?: any }> {
    if (data?.length && columns?.length) {
      let selectedRows = JSON.parse(JSON.stringify(this.selection.selected));
      this.selection.clear(true);
      for (let i = 0; i < data.length; i++) {
        data[i].cellValues = {} as T;
        for (let j = 0; j < columns.length; j++) {
          let cellValues = {} as any;
          cellValues.cell = columns[j]?.cell?.(data[i]);
          cellValues.color = columns[j]?.color?.(data[i]);
          cellValues.msg = columns[j]?.msg?.(data[i]);
          cellValues.backgroundColor = columns[j]?.backgroundColor?.(
            data[i],
            selectedColumns.includes(columns[j].columnDef)
          );
          try {
            cellValues.icon = columns[j]?.icon?.(data[i]);
          } catch (err) {
            cellValues.icon = columns[j]?.icon;
          }
          cellValues.chargingOn = columns[j]?.chargingOn?.(data[i]);
          cellValues.isOutOfNetwork = columns[j]?.isOutOfNetwork?.(data[i]);
          cellValues.hide = columns[j]?.hide?.(data[i]);
          cellValues.disable = columns[j]?.disable?.(data[i]);
          cellValues.isConfigDesc = columns[j]?.isConfigDesc?.(data[i]);
          cellValues.label = columns[j]?.label?.(data[i]);
          cellValues.visible = columns[j]?.visible?.(data[i]);

          cellValues.options = columns[j]?.options?.map?.((option: any) => {
            let newOption = { ...option };
            try {
              newOption.label = option?.label?.(data[i]);
            } catch (err) {
              newOption.label = option?.label;
            }
            try {
              newOption.disable = option?.disable?.(data[i]);
            } catch (err) {
              newOption.disable = option?.disable;
            }
            return newOption;
          });

          data[i].cellValues[columns[j].columnDef] = Object.fromEntries(
            Object.entries(cellValues).filter(
              ([key, value]) => value != undefined
            )
          );
        }
        for (let j = 0; j < selectedRows?.length; j++) {
          if (this.selection.compareWith?.(selectedRows[j], data[i])) {
            this.selection.select(data[i]);
          }
        }
      }
    }

    return data;
  }
  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private overlay: Overlay,
    public projectsService: ProjectsService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.clearSelection.emit();
    this.fileteredData = this.data;
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;

    this.user = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    let deviceTypes = this.commonService.getAllDeviceTypes();
    let allAqi = this.commonService.getAllAQI();
    let allAqis = this.commonService.getAllAQIs();
    this.aqiIndexColor = DeviceUtil.aqiColorArray(deviceTypes, allAqi, allAqis);
    this.columns.forEach(
      (column) => (this.tableFilters[column.columnDef] = [])
    );
    // this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(this.fileteredData);
    this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
      this.fileteredData
    );
    this.dataSource.filterPredicate = (
      data: T & { cellValues?: any },

      filter: string
    ): boolean => {
      let objec = JSON.parse(JSON.stringify(data.cellValues));
      delete objec.actions;
      objec = Object.values(objec)
        .map((value: any) => value.cell)
        .join(',');
      return objec.toLowerCase().includes(filter.toLowerCase());
    };
    this.dataSource.paginator = this.paginator;

    this.deviceData = this.fileteredData;
    //gives searched data

    this.searchTerm.valueChanges
      .pipe(
        switchMap((term) =>
          this.isTopLevelAdminSearch
            ? timer(800).pipe(map(() => term))
            : of(term)
        ),
        distinctUntilChanged()
      )
      .subscribe((term) => {
        this.searchText = term;
        this.isTopLevelAdminSearch
          ? this.getFilteredDataFromApi(term)
          : this.searchData(term);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => this.showNoData());

    let shared = this.projectsService.sharedAllOrgData$.subscribe(
      (res: any) => {
        if (res != undefined) {
          this.isTopLevelAdminSearch = res;
        }
      }
    );
    this.subscriptions.push(shared);
  }

  ngOnChanges() {
    // setTimeout is used to delay this code's execution after the pagination element is in the view.
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });
  }

  showNoData() {
    let noDataComponentRef = this.noDataContainer?.createComponent(
      ContentUnavailableComponent
    );
    Object.assign(noDataComponentRef.instance, this.noDataViewConfig);
  }

  // displayPhoto(data: any) {
  //   this.showPopup = true;
  //   this.popupImageUrl = data;
  // }

  // closePopup(): void {
  //   this.showPopup = false;
  // }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.onSelect.emit(this.selection.selected);
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.onSelect.emit(this.selection.selected);
  }

  selectData(event: MatCheckboxChange, row: any): void {
    if (event) {
      this.selection.toggle(row);
      this.onSelect.emit(row);
    }
  }

  sortData(sort: any): void {
    const data: any = this.fileteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.fileteredData = data;
    } else {
      this.fileteredData = this.fileteredData.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'deviceId':
            return this.compare(a.deviceId, b.deviceId, isAsc);
          case 'deviceName':
            return this.compare(a.label, b.label, isAsc);
          case 'location':
            return this.compare(a.loc, b.loc, isAsc);
          case 'status':
            return this.compare(a.status.index, b.status.index, isAsc);
          case 'time':
            return this.compare(a.lastUpdated, b.lastUpdated, isAsc);
          case 'message':
            return this.compare(a.message, b.message, isAsc);
          case 'title':
            return this.compare(a.title, b.title, isAsc);
          case 'categoryId':
            return this.compare(
              this.categories[a.categoryId].label,
              this.categories[b.categoryId].label,
              isAsc
            );
          case 'longitude':
            return this.compare(a.longitude, b.longitude, isAsc);
          case 'latitude':
            return this.compare(a.latitude, b.latitude, isAsc);
          case 'sources':
            return this.compare(a.sources, b.sources, isAsc);
          case 'name':
            return this.compare(a.name, b.name, isAsc);
          case 'status':
            return this.compare(
              this.statuses[a.status],
              this.statuses[b.status],
              isAsc
            );
          case 'priority':
            return this.compare(a.priority, b.priority, isAsc);
          case 'email':
            return this.compare(a.email, b.email, isAsc);
          case 'phoneNo':
            return this.compare(a.phoneNo, b.phoneNo, isAsc);
          case 'end_date':
            return this.compare(a.end_date, b.end_date, isAsc);
          case 'assignedUserId':
            return this.compare(
              a.assignedUserEmail,
              b.assignedUserEmail,
              isAsc
            );
          case 'notes':
            return this.compare(a.note.message, b.note.message, isAsc);
          case 'installation':
            return this.compare(
              a.note?.d?.['install'] || 0,
              b.note?.d?.['install'] || 0,
              isAsc
            );
          case 'calib':
            return this.compare(
              a.note?.d?.['calib'] || 0,
              b.note?.d?.['calib'] || 0,
              isAsc
            );
          case 'cd':
            return this.compare(
              a.note?.d?.['cd'] || 0,
              b.note?.d?.['cd'] || 0,
              isAsc
            );
          case 'onm':
            return this.compare(
              a.note?.d?.['onm'] || 0,
              b.note?.d?.['onm'] || 0,
              isAsc
            );
          case 'createdOn':
            return this.compare(a.created_on, b.created_on, isAsc);
          case 'createdBy':
            return this.compare(a.email, b.email, isAsc);
          default: {
            const column = this.columns.find(
              (col) => col.columnDef === sort.active
            );
            // Aqi table have different structure
            if (column?.sortStructure === 'aqiParameters') {
              return this.compare(
                a.payload[0]?.parameters[sort.active]?.value,
                b.payload[0]?.parameters[sort.active]?.value,
                isAsc
              );
            } else if (
              a.payload &&
              a.payload.d &&
              a.payload.d[sort.active] &&
              b.payload &&
              b.payload.d &&
              b.payload.d[sort.active]
            ) {
              return this.compare(
                a.payload.d[sort.active],
                b.payload.d[sort.active],
                isAsc
              );
            }
            return this.compare(a[sort.active], b[sort.active], isAsc);
          }
        }
      });
    }

    this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
      this.fileteredData
    );
    this.dataSource.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    const valueA =
      typeof a === 'string' ? this.convertCamelCaseToReadable(a) : a;
    const valueB =
      typeof b === 'string' ? this.convertCamelCaseToReadable(b) : b;
    return (valueA < valueB ? -1 : 1) * (isAsc ? 1 : -1);
  }

  convertCamelCaseToReadable(value: string): string {
    return value.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  }

  downloadReport(): void {
    this.onDownload.emit(this.selection.selected);
    this.selection.clear();
  }

  deleteComplain(): void {
    this.onDelete.emit(this.selection.selected);
    this.selection.clear();
  }

  openFilterOptions(): void {
    const data: FilterOptions = {
      columns: this.columns.map((col) => {
        const header = col.header
          .replace(/<br\s*\/?>|<small>.*?<\/small>/g, '')
          .trim();
        const htmlContent =
          col.header
            .match(/<small>.*?<\/small>/g)
            ?.map((tag: any) => tag.replace(/<\/?small>/g, ''))
            .join(' ') || '';
        const units = htmlContent.replace(/ /g, '&nbsp;');
        const column: FilterColumn = {
          key: col.columnDef,
          value: `${header} (${units})`,
          parameter: col.parameter,
          selected: col.selected,
          filter: col.filter,
        };
        return column;
      }),
    };
    this.filterOptions = data;

    // return;

    // const dialogOptions = {
    //   data: data,
    //   autoFocus: false,
    //   hasBackdrop: true,
    //   disableClose: true,
    // };

    // this.dialog
    //   .open(FilterOptionsComponent, dialogOptions)
    //   .afterClosed()
    //   .subscribe((filter: FilterValue) => {
    //     this.tableFilters[filter.key].push(filter.value);
    //     this.selectedFilters.push(filter);
    //     this.columns.find(
    //       (column) => column.columnDef === filter.key
    //     ).selected = true;
    //     this.applyFilters();
    //   });
  }

  closeFilterOptions(filter: FilterValue) {
    this.tableFilters[filter.key].push(filter.value);
    this.selectedFilters.push(filter);
    this.columns.find((column) => column.columnDef === filter.key).selected =
      true;
    this.applyFilters();
    this.filterMenu.closeMenu();
  }

  applyFilters(): void {
    let data: Array<any> = [...this.data];
    data = data.map((device) => {
      if (
        device.payload &&
        Array.isArray(device.payload) &&
        device.payload.length > 0 &&
        device.payload[0].payload?.d
      ) {
        return {
          ...device,
          ...device.payload[0],
        };
      }
      return device;
    });
    const query: any = CommonUtil.buildFilter(this.tableFilters);
    this.fileteredData = data.filter(
      (device) =>
        CommonUtil.deviceFilter(device, query, this.selectedFilters) === true
    );
    this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
      this.fileteredData
    );
    this.dataSource.paginator = this.paginator;
  }

  removeFilter(filter: FilterValue): void {
    this.selectedFilters.splice(
      this.selectedFilters.findIndex((fl) => fl.key === filter.key),
      1
    );
    this.tableFilters[filter.key] = this.tableFilters[filter.key].filter(
      (val: any) => val !== filter.value
    );
    this.columns.find((column) => column.columnDef === filter.key).selected =
      false;
    this.applyFilters();
  }

  searchData(searchTerm: string): void {
    this.dataSource.filter = searchTerm.trim().toLowerCase();
  }

  getFilteredDataFromApi(searchTerm: string) {
    this.projectsService
      .getAllOrgOrgInfo(this.user.userId, searchTerm)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.fileteredData = this.transformData(
              res,
              this.columns,
              this.selectedColumns
            );
            this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
              this.fileteredData
            );
          }
        },
        error: (err) => {
          console.error('Error fetching data:', err);
        },
      });
  }

  scrollLeft(): void {
    const div = this.filterElement.nativeElement as HTMLDivElement;
    div.scrollLeft -= 30;
  }

  scrollRight(): void {
    const div = this.filterElement.nativeElement as HTMLDivElement;
    div.scrollLeft += 30;
  }

  onDateSelected(
    event: MatDatepickerInputEvent<moment.Moment>,
    row: any
  ): void {
    this.dateSelected.emit({ changedDate: event.value!, row: row });
  }

  openComponentOverlay(link: any) {
    this.imageViewOverlayRef = this.overlay.create({
      hasBackdrop: true,
      width: '100%',
      height: '100%',
    });

    const popupComponentPortal = new ComponentPortal(ImageViewPopupComponent);
    this.imageViewOverlayComponentRef =
      this.imageViewOverlayRef.attach(popupComponentPortal);

    this.imageViewOverlayComponentRef.instance.imageUrl = link;

    this.imageViewOverlayRef.backdropClick().subscribe(() => {
      this.imageViewOverlayRef.dispose();
    });

    this.closeForm();
  }

  closeForm(): void {
    this.imageViewOverlayComponentRef.instance.closeEvent.subscribe(() => {
      this.imageViewOverlayRef.detach();
    });
  }

  handleRowClick(row: any, rowIndex: number) {
    this.onRowSelect.emit(row);
    const updatedFilteredData: ((typeof this.dataSource.filteredData)[0] & {
      isRowSelected?: boolean;
      selectedCells?: string[];
    })[] = [...this.dataSource.filteredData] as any;
    updatedFilteredData[rowIndex] = {
      ...updatedFilteredData[rowIndex],
      isRowSelected: !updatedFilteredData[rowIndex].isRowSelected,
      selectedCells: [],
    };
    this.fileteredData = this.transformData(
      updatedFilteredData,
      this.columns,
      this.selectedColumns
    );
    this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
      this.fileteredData
    );
    this.dataSource.paginator = this.paginator;
  }

  handleCellClick(
    row: any,
    columnKey: any,
    rowIndex: any,
    forceAction: 'select' | 'deSelect' | null = null
  ) {
    if (!row.isRowSelected) {
      const cellData = { row, columnKey };
      this.onCellSelect.emit(cellData);
      const updatedFilteredData: ((typeof this.dataSource.filteredData)[0] & {
        selectedCells?: string[];
      })[] = [...this.dataSource.filteredData] as any;
      let selectedCells = updatedFilteredData[rowIndex].selectedCells || [];
      if (selectedCells.includes(columnKey.columnDef)) {
        selectedCells = selectedCells.filter(
          (cell) => cell !== columnKey.columnDef
        );
      } else {
        selectedCells.push(columnKey.columnDef);
      }
      updatedFilteredData[rowIndex] = {
        ...updatedFilteredData[rowIndex],
        selectedCells,
      };
      this.fileteredData = this.transformData(
        updatedFilteredData,
        this.columns,
        this.selectedColumns
      );
      this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
        this.fileteredData
      );
      this.dataSource.paginator = this.paginator;
    }
  }

  handleColumnClick(columnKey: any) {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPageData = this.dataSource.filteredData.slice(
      startIndex,
      endIndex
    ) as ((typeof this.dataSource.filteredData)[0] & {
      selectedCells?: string[];
    })[];

    for (let rowIndex = 0; rowIndex < currentPageData.length; rowIndex++) {
      const row = currentPageData[rowIndex];
      this.handleCellClick(row, columnKey, rowIndex);
    }
  }

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
    this.fileteredData = this.data;

    // setTimeout is used to delay this code's execution after the pagination element is in the view.
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });

    this.dataSource = new MatTableDataSource<T & { cellValues?: any }>(
      this.fileteredData
    );
    if (!this.isAccordionOpen) {
      this.clearSelection.emit();
    }
    this.applyFilters();
  }

  stripHtml(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
}
