import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import { TimeZoneInputComponent } from 'src/app/shared/components/time-zone-input/time-zone-input.component';
import { MENU_ITEMS } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { LoginData } from 'src/app/shared/models/login-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit, AfterViewInit {
  itemMenu = MENU_ITEMS;
  skeletonIcons: number[] = [];

  loader: boolean = true;
  fetchedData: any;
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    public deviceService: DeviceService,
    private userService: UserService,
    private menuService: NbMenuService,
    public loadrService: LoadrService,
    public localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    public dialog: MatDialog
  ) {
    this.getSkeletonLength();
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loader = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    const userSetting = this.commonService.getUserSettings();
    //check if user has selected a timezone or not
    //if not timezone or any field of timezone is blank than show popup
    if (
      !userSetting?.timezone ||
      !userSetting.timezone?.name ||
      !userSetting.timezone?.gmtOffset
    ) {
      this.openDialog();
    }
    this.setupLockedModules();
    this.validateEnvizomSubscription();
    this.userService.setupUserDetails();
  }

  ngAfterViewInit(): void {
    this.menuService.onItemSelect().subscribe((res: any) => {});
  }

  //to decide the number of icons for skeleton dynamically based on menu items
  getSkeletonLength() {
    let count = 0;
    while (count < this.itemMenu.length / 2) {
      this.skeletonIcons.push(count);
      count++;
    }
  }

  validateEnvizomSubscription(): void {
    if (!localStorage.getItem(LocalStorageConstants.OZ_PAYMENT_DUE)) {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.notificationService.showNotification(
        'Something went wrong',
        'Close',
        'bottom',
        'right',
        'error'
      );
    }
  }

  setupLockedModules() {
    const user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    let currentTime = this.customMomentService.moment().unix();
    let modulesExpiry = user.moduleExpiry;
    let modulesWithUser = user.modules;

    this.itemMenu.forEach((item) => {
      let currentUrl = item.link?.replace('/', '')!;
      let isModuleValid = CommonUtil.isModuleValid(
        currentUrl,
        currentTime,
        modulesExpiry,
        modulesWithUser,
        user.role
      );

      if (!isModuleValid) item.ariaRole = 'showLock';
      else item.ariaRole = 'hideLock';
    });
  }

  openDialog() {
    const config: MatDialogConfig = {
      backdropClass: 'backDrop',
      disableClose: true,
    };

    //decide the size of popup based on screen size
    if (window.innerWidth >= 1024) {
      config.height = '40%';
      config.width = '30%';
    } else if (window.innerWidth >= 768) {
      config.height = '40%';
      config.width = '50%';
    } else if (window.innerWidth >= 576) {
      config.height = '80%';
      config.width = '80%';
    } else {
      config.height = '50%';
      config.width = '90%';
    }

    this.dialog.open(TimeZoneInputComponent, config);
  }
}
