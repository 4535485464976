import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { VibrationService } from 'src/app/shared/services/vibration.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-vibration',
  templateUrl: './vibration.component.html',
  styleUrls: ['./vibration.component.scss'],
})
export class VibrationComponent {
  headerForm!: FormGroup;
  deviceTypes!: Array<DeviceType>;
  devices: DeviceDetails[] = [];
  maxDate: moment.Moment = this.customMomentService.moment();
  minDate!: moment.Moment;
  userChangedTheDate: boolean = false;
  userChangedTheDevice: boolean = false;
  currentlySelectedDevice!: DeviceDetails;
  moduleAccess: any;
  public deviceTags: string[] = [];

  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private customMomentService: CustomMomentService,
    private router: Router,
    private vibrationService: VibrationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.deviceTypes = this.commonService
      .getUserDeviceTypesWithVibration()
      .filter((dt) => dt.key === 'OMNIDOTS' || dt.key === 'BEANAIR');
    this.devices = this.deviceService.registeredDevicesWithVibration!.filter(
      (d) => d.deviceType === 'OMNIDOTS' || d.deviceType === 'BEANAIR'
    );
    this.deviceTags = this.deviceService.getUniqueDeviceTags(this.devices);

    let startDate = this.customMomentService.moment().startOf('day').unix();
    this.currentlySelectedDevice = this.devices[0];

    this.headerForm = this.formBuilder.group({
      deviceSearch: [''],
      device: [''],
      startDate: [
        this.customMomentService.moment.unix(startDate),
        Validators.required,
      ],
    });

    this.headerForm.get('startDate')?.valueChanges.subscribe(() => {
      //updating the date only if user has not changed the device
      //if user has changed the device than no need to run the below code
      if (!this.userChangedTheDevice) this.userChangedTheDate = true;
    });

    this.headerForm.get('deviceSearch')?.valueChanges.subscribe((res) => {
      //if user has changed the deviceId than changing the conditions
      if (this.currentlySelectedDevice.deviceId !== res.deviceId) {
        this.userChangedTheDevice = true;
      } else {
        this.userChangedTheDevice = false;
      }
      if (res) this.minDate = this.deviceService.getMinDateOfDevice(res);
    });

    let deviceId = this.devices[0].deviceId.toString();
    let sD = this.headerForm.value.startDate.unix();
    let tempState = { deviceId, startDate: sD };
    this.router.navigate(['vibration', deviceId], {
      state: tempState,
    });

    if (this.currentlySelectedDevice) {
      this.headerForm.patchValue({
        deviceSearch: this.currentlySelectedDevice,
      });
    }
    this.moduleAccess = this.commonService.moduleAccessibility(1021);
  }

  // Sets the selected device globally when the user clicks on a device in the header dropdown
  onDeviceSelectionInVibration(device: DeviceDetails) {
    if (device && device?.deviceId) {
      this.headerForm.get('deviceSearch')?.setValue(device);
    }
  }

  // Emits the updated device list when the user selects or deselects tags from the header dropdown
  onTagSelection(devices: DeviceDetails[]) {
    this.vibrationService.onHeaderTagChange.next(devices);
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  getVibrationModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  onApply() {
    let sD = this.headerForm.value.startDate.unix();
    let deviceId = this.headerForm.value.deviceSearch.deviceId.toString();

    //if user has changed the deviceId than flagging the date variable to false
    if (this.userChangedTheDevice) {
      this.userChangedTheDate = false;
    }

    if (this.userChangedTheDate) {
      this.vibrationService.selectedDate.next(sD);
    } else {
      let tempState = {
        deviceId,
        startDate: sD,
      };
      this.currentlySelectedDevice = this.headerForm.value.deviceSearch;
      this.userChangedTheDevice = false;
      this.router.navigate(['vibration', deviceId], {
        state: tempState,
      });
    }
  }

  exportTableDataToCSV(): void {
    if (!this.vibrationService.getVibrationData()) {
      this.notificationService.showSnackBar('No data to download!', 'info');
      return;
    }

    //getting device type id
    const deviceTypeId = this.vibrationService.getDeviceTypeId();
    const deviceTypeKey = DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
      this.commonService.getUserDeviceTypesWithVibration(),
      deviceTypeId
    );

    //getting deviceid
    const deviceId = this.vibrationService.getVibrationData()[0].deviceId;

    //units and fields
    const allUnits = this.commonService.getAllUnits();
    const fields = this.deviceService.fetchFields(
      deviceTypeId,
      allUnits,
      false
    );

    const csvRows = [];
    const headers = ['Device Id', 'Device Type', 'Time'];

    //adding header row
    this.vibrationService
      .getKeysOfDevice()
      .map((field) => headers.push(DeviceUtil.getFieldName(field, fields)));
    csvRows.push(headers.join(','));

    let data: string[] = [];

    //adding other rows which contains data
    this.vibrationService.getVibrationData().forEach((vibrationData: any) => {
      const date = this.customMomentService.formatDate({
        epoch: vibrationData.payload.d?.['t'],
        format: this.commonService.getDateTimeFormat(true),
      });
      data = [];
      data.push(deviceId);
      data.push(deviceTypeKey);
      data.push(`${date}`);

      this.vibrationService.getKeysOfDevice().map((field) => {
        vibrationData.payload.d?.[field]
          ? data.push(String(vibrationData.payload.d?.[field]))
          : data.push('');
      });
      csvRows.push(data.join(','));
    });

    const date = this.customMomentService.formatDatetime({
      format: 'DD-MM-YYYY',
    });
    const fileName = `${deviceId}_ ${deviceTypeKey}_${date}`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }
}
