import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from 'src/app/shared/shared.module';
import { ComplainRoutingModule } from './complain-routing.module';
import { ComplainComponent } from './complain.component';
import { ComplainFormComponent } from './components/complain-form/complain-form.component';
import { ListComponent } from './pages/list/list.component';
import { MapComponent } from './pages/map/map.component';

@NgModule({
  declarations: [
    ComplainComponent,
    ListComponent,
    MapComponent,
    ComplainFormComponent,
  ],
  imports: [
    CommonModule,
    ComplainRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class ComplainModule {}
