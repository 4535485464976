import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { filter, indexOf } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { UserMobileNumbers } from 'src/app/shared/models/internal-use-front-end/country-wise-ph-code';
import { LoginData } from 'src/app/shared/models/login-data';
import { ModuleCheckbox } from 'src/app/shared/models/module-checkbox';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-sub-user-form',
  templateUrl: './sub-user-form.component.html',
  styleUrls: ['./sub-user-form.component.scss'],
})
export class SubUserFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  deviceSelector = new SelectionModel<any>(true, []);
  moduleSelector = new SelectionModel<any>(true, []);
  transformedOZUserData: any;
  public isFormVisible: boolean | undefined = undefined;
  public isEditMode: boolean = false;
  public isReplicateMode: boolean = false;
  public readonly userRole: number = 2;
  public userDetailForm!: FormGroup;
  public devicesForm!: FormGroup;
  public modulesForm!: FormGroup;
  public devices!: DeviceDetails[];
  public isNextStep: boolean = false;
  public submitBtn: string = 'Next';
  public modules!: ModuleCheckbox[];
  public isConfirmPasswordCorrect: boolean = false;
  public isNewPasswordCorrect: boolean = false;
  public isEmailCorrect: boolean = false;
  public isUserNameCorrect: boolean = false;
  public showPassword: boolean = false;
  public defaultModules: number[] = [1022, 1001, 1002, 1003, 1008];
  public displayedColumnsDevices: string[] = [];
  public displayedColumnsModules: string[] = [];
  public currentStepIndex!: number;
  public backBtn: string = 'Cancel';
  public tableTitle!: string;
  public subscriptions: Subscription[] = [];
  subIndex: boolean = false;
  rawData: boolean = false;
  public defaultColumnsDevices: any[] = [
    {
      columnDef: 'label',
      header: 'Label',
      cell: (element: DeviceDetails) => `${element.label}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'device',
      header: 'Device Id',
      cell: (element: DeviceDetails) => `${element.deviceId}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'location',
      header: 'Location',
      cell: (element: DeviceDetails) => `${element.loc}`,
      parameter: false,
      selected: false,
    },
  ];

  public defaultColumnsModules: any[] = [
    {
      columnDef: 'module',
      header: 'Module',
      cell: (element: ModuleCheckbox) => `${element.name}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'iconToggles',
      header: 'Access',
      headerIcon: 'info',
      toolTip:
        'Customise Module by selecting the feature rights of Add, Edit, Download and Delete buttons for the respective users.',
      cell: (element: ModuleCheckbox) => {
        const options = this.getOptions(element);
        const accessOptions = [
          {
            id: 'add',
            title: 'Add',
            icon: 'add',
            active: options.add,
            disableStatus:
              this.commonService.moduleAccessibility(element.moduleId).add ===
              false,
          },
          {
            id: 'edit',
            title: 'Edit',
            icon: 'edit',
            active: options.edit,
            disableStatus:
              this.commonService.moduleAccessibility(element.moduleId).edit ===
              false,
          },
          {
            id: 'download',
            title: 'Download',
            icon: 'download',
            active: options.download,
            disableStatus:
              this.commonService.moduleAccessibility(element.moduleId)
                .download === false,
          },
          {
            id: 'delete',
            title: 'Delete',
            icon: 'delete',
            active: options.delete,
            disableStatus:
              this.commonService.moduleAccessibility(element.moduleId)
                .delete === false,
          },
        ];
        return accessOptions;
      },
      onToggle: (element: ModuleCheckbox, accessItem: any) => {
        accessItem.active = !accessItem.active;
        if (!element.options) {
          element.options = {};
        }
        element.options = {
          ...element?.options,
          [accessItem.id]: accessItem.active,
        };
        const currentModuleIndex = this.modules?.findIndex(
          (module) => module?.moduleId === element?.moduleId
        );
        const updatedModules = [...this.modules];
        updatedModules[currentModuleIndex] = { ...element };
        this.modules = updatedModules;
      },
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'dateSelector',
      header: 'Set Expiry',
      cell: (element: ModuleCheckbox) => `${element.expiryDate}`,
      parameter: false,
      selected: false,
    },
  ];
  public existingMobileNumbers: string[] = [];
  currentUserOverviewAccess: Record<string, boolean> = {};

  constructor(
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private loadrService: LoadrService,
    private loginService: LoginService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    @Inject(MTX_DRAWER_DATA) public data: LoginData | undefined
  ) {
    this.moduleSelector.compareWith = (a: ModuleCheckbox, b: ModuleCheckbox) =>
      a && b && a.moduleId === b.moduleId;
    this.deviceSelector.compareWith = (a: DeviceDetails, b: DeviceDetails) =>
      a && b && a.deviceId === b.deviceId;
  }

  ngOnInit(): void {
    this.buildForm();
    this.currentUserOverviewAccess =
      this.commonService.moduleAccessibility(1001);
    this.tableTitle = `Edit User (${this.data?.email})`;
    this.devices = this.deviceService.registeredDevices!;
    this.isEditMode = this.data?.isEditMode! && !!this.data;
    this.isReplicateMode = !this.data?.isEditMode! && !!this.data;
    this.getModulesData();
    if (this.isEditMode || this.isReplicateMode) {
      this.patchDevicesAndModules();
      this.isFormVisible = true;
    } else if (!this.isEditMode && !this.isReplicateMode) {
      this.isFormVisible = true;
    }

    this.setRawAndSubIndexValue();

    this.displayedColumnsDevices = [
      'select',
      ...this.defaultColumnsDevices.map((c) => c.columnDef),
    ];
    this.displayedColumnsModules = [
      'select',
      ...this.defaultColumnsModules.map((c) => c.columnDef),
    ];

    this.onChangeUserForm();
    this.onChangeNewPassword();
    this.onChangeConfirmPassword();
    this.onChangeEmail();
    this.onChangeUserName();
  }

  setRawAndSubIndexValue() {
    if (this.data) {
      this.data.moduleExpiry.forEach((module: any) => {
        if (module.moduleId == 1001) {
          this.rawData = module.options?.raw === false ? false : true;
          this.subIndex = module.options?.sub_index === false ? false : true;
        }
      });
    }
  }

  getOptions(row: any) {
    const module = this.modules.find(
      (module) => module.moduleId === row.moduleId
    );
    return (
      module?.options || {
        add: false,
        edit: false,
        download: false,
        delete: false,
      }
    );
  }

  buildForm() {
    this.userDetailForm = this.formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      contact: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    this.devicesForm = this.formBuilder.group({
      numberOfDevices: ['', Validators.required],
    });

    this.modulesForm = this.formBuilder.group({
      numberOfModules: [''],
    });
  }

  //executes when user clicks on cross button of the form
  closeForm(data: boolean = false) {
    this.formsService.closeForm(data);
    this.deviceSelector.clear();
    this.moduleSelector.clear();
  }
  onStepChange(event: any) {
    this.currentStepIndex = event.selectedIndex;
    if (event.selectedIndex === 0) {
      this.submitBtn = 'Next';
      this.backBtn = 'Cancel';
    } else if (event.selectedIndex === 1) {
      this.submitBtn = 'Next';
      this.backBtn = 'Back';
    } else {
      this.submitBtn = 'Submit';
      this.backBtn = 'Back';
    }
  }

  onToggleChange() {
    this.rawData = !this.rawData;
  }

  onSubIndexToggleChange() {
    this.subIndex = !this.subIndex;
  }

  //it executes when user click on next/submit button of the form
  submitAndNext(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (stepperLength === 3) {
      if (this.stepper.selectedIndex === 0 && this.userDetailForm.valid) {
        if (event) this.stepper.next();
        this.isNextStep = this.devicesForm.valid;
      } else if (this.stepper.selectedIndex === 1 && this.devicesForm.valid) {
        if (event) this.stepper.next();
        this.submitBtn = 'Submit';
        this.isNextStep = this.modulesForm.valid;
        this.moduleSelector.select(
          ...this.modules.filter((module: ModuleCheckbox) =>
            this.defaultModules.includes(module.moduleId!)
          )
        );
        this.onModuleSelection();
        // this.moduleSelector.select(...this.modules);
      } else if (this.stepper.selectedIndex === 2 && this.modulesForm.valid) {
        if (event) this.submitForm();
      }
    } else if (stepperLength === 2) {
      if (this.stepper.selectedIndex === 0 && this.devicesForm.valid) {
        if (event) this.stepper.next();
        this.submitBtn = 'Update';
        this.isNextStep = this.modulesForm.valid;
      } else if (this.stepper.selectedIndex === 1 && this.modulesForm.valid) {
        if (event) this.submitForm();
      }
    }
  }
  //it executes when user click on back button of the form
  previous(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (stepperLength === 3) {
      if (this.stepper.selectedIndex === 1) {
        if (event) this.stepper.previous();
        this.isNextStep = this.userDetailForm.valid;
      } else if (this.stepper.selectedIndex === 2) {
        this.submitBtn = 'Next';
        if (event) this.stepper.previous();
        this.isNextStep = this.devicesForm.valid;
      }
    } else if (stepperLength === 2) {
      if (this.stepper.selectedIndex === 1) {
        if (event) this.stepper.previous();
        this.isNextStep = this.devicesForm.valid;
        this.submitBtn = 'Next';
      }
    }
  }

  onNumberAdd(numbers: UserMobileNumbers) {
    if (numbers.validNumbers.length) {
      this.userDetailForm.get('contact')?.setValue(numbers.validNumbers[0]);
    } else {
      this.userDetailForm.get('contact')?.setValue(undefined);
    }
  }

  // to convert epoch time to date format using moment js
  epochTimeToDateMoment(value: number): any {
    const momentObj = this.customMomentService.moment.unix(value);
    return momentObj;
  }

  // to convert date format to epoch time using moment js
  dateToEpochMoment(date: moment.Moment): number {
    return this.customMomentService.moment(date).unix();
  }

  onDeviceSelection() {
    if (this.deviceSelector.selected.length > 0) {
      this.isNextStep = true;
      this.devicesForm
        .get('numberOfDevices')
        ?.setValue(this.deviceSelector.selected.length);
    } else {
      this.isNextStep = false;
      this.devicesForm.get('numberOfDevices')?.setValue('');
    }
  }

  //Users can choose any number of modules, or 0 modules, no compulsion to select atleast 1 module.
  onModuleSelection() {
    let selectedModules = this.modules
      .filter((module: ModuleCheckbox) => {
        return this.moduleSelector.selected.some(
          (resModule: ModuleCheckbox) => module.moduleId === resModule.moduleId
        );
      })
      .map((m) => m.moduleId);

    this.modules = [
      ...this.modules.map((module: ModuleCheckbox) => {
        module.enabled = selectedModules.includes(module.moduleId);
        //set the options

        if (module.enabled) {
          if (!module?.options) {
            let options = {
              add: true,
              edit: true,
              download: true,
              delete: true,
            };
            // Set default values if options don't exist
            // options = {
            //   add: options.add ?? true,
            //   download: options.download ?? true,
            //   edit: options.edit ?? true,
            //   delete: options.delete ?? true,
            //   ...options,
            // };
            const adminUserModuleOptions =
              this.commonService.moduleAccessibility(module.moduleId);

            options = {
              ...options,
              ...adminUserModuleOptions,
            };
            module.options = options;
          }
        } else {
          delete module?.options;
        }
        return module;
      }),
    ];
  }

  //executes when user changes date in the add user form while selecting module
  onDateSelection(value: any) {
    this.moduleSelector.selected.forEach((obj: any) => {
      if (obj.moduleId === value.row.moduleId) {
        obj.expiryDate = value.changedDate;
      }
    });
  }

  generatePayload() {
    const ozUser = this.commonService.getUser();

    const selectedDevices: RequestData.SetDevice[] =
      this.deviceSelector.selected.map((device: DeviceDetails) => {
        return {
          deviceId: device.deviceId,
          dataFrom: device?.dataFrom ? device?.dataFrom : null,
        };
      });

    //creating an array of modules other than default modules
    let modulesWithoutDefaultModules = this.moduleSelector.selected;

    // let modulesWithoutDefaultModules = this.moduleSelector.selected.filter(
    //   (module: any) => !this.defaultModules.includes(module.moduleId)
    // );

    //adding the expiry date for the modules which are selected by the user, it has key as the module id and its value as the expiry date in epoch
    const moduleWithExpiryDate: RequestData.SetModuleExpiry[] =
      modulesWithoutDefaultModules.map((item: ModuleCheckbox) => {
        const otherOptions: Record<string, boolean> = {};
        if (item.moduleId == 1001) {
          otherOptions['raw'] = this.rawData;
          otherOptions['sub_index'] = this.subIndex;
        }
        const accessControlOptions = { ...item?.options, ...otherOptions };
        let expDate = this.dateToEpochMoment(item.expiryDate);
        if (this.defaultModules.includes(item.moduleId)) {
          expDate = this.dateToEpochMoment(this.customMomentService.moment());
        }
        return {
          moduleId: item.moduleId!,
          expiry_date: expDate,
          options: accessControlOptions,
        };
      });

    if (this.isEditMode) {
      let updateUser: RequestData.UpdateUser = {
        update: {
          modules: this.moduleSelector.selected.map((res) => res.moduleId),
        },
        setDevices: selectedDevices,
        updateModuleExpiry: moduleWithExpiryDate,
        resetUnits: false,
      };
      return updateUser;
    } else {
      let newUser: RequestData.NewUser = {
        data: {
          email: this.userDetailForm.value.email.toLowerCase().trim(),
          password: this.loginService.getEncryptedPassword(
            this.userDetailForm.value.password,
            this.localStorageService.getValue(
              LocalStorageConstants.ENCRYPTION_KEY
            )
          ),
          role: 2,
          org: ozUser.org,
          name: this.userDetailForm.value.name,
          company: ozUser.company,
          contact: this.userDetailForm.value.contact,
          location: null,
          city: null,
          state: null,
          country: null,
          modules: this.moduleSelector.selected.map((res) => res.moduleId),
          thumb: null,
          // units: ozUser.units,
          // aqi_index_id: aqiIndexId,
          settings: {
            time_format: ozUser.settings.time_format,
            timezone: ozUser.settings.timezone,
          },
        },
        setDevices: selectedDevices,
        setModuleExpiry: moduleWithExpiryDate,
      };
      return newUser;
    }
  }

  onChangeUserForm() {
    let form = this.userDetailForm.valueChanges.subscribe((res) => {
      if (res) {
        if (
          this.isConfirmPasswordCorrect &&
          this.isEmailCorrect &&
          this.isNewPasswordCorrect &&
          this.isUserNameCorrect &&
          this.userDetailForm.valid
        ) {
          this.isNextStep = true;
        } else {
          this.isNextStep = false;
        }
      }
    });
    this.subscriptions.push(form);
  }

  //when user types in new password input, this function checks whether it matches the expected pattern or not
  onChangeNewPassword() {
    let pswd = this.userDetailForm
      .get('password')
      ?.valueChanges.subscribe((res) => {
        if (this.checkPasswordPattern(res)) {
          this.isNewPasswordCorrect = true;
        } else {
          this.isNewPasswordCorrect = false;
        }

        if (this.userDetailForm.get('confirmPassword')?.touched) {
          if (this.userDetailForm.get('confirmPassword')?.value === res) {
            this.isConfirmPasswordCorrect = true;
          } else {
            this.isConfirmPasswordCorrect = false;
          }
        }
      })!;
    this.subscriptions.push(pswd);
  }

  //when user types in confirm password input, this function checks whether the confirm password is equal to the new password
  onChangeConfirmPassword() {
    let cnfmPswd = this.userDetailForm
      .get('confirmPassword')
      ?.valueChanges.subscribe((res) => {
        if (this.userDetailForm.get('password')?.value === res) {
          this.isConfirmPasswordCorrect = true;
        } else {
          this.isConfirmPasswordCorrect = false;
        }
      })!;
    this.subscriptions.push(cnfmPswd);
  }

  //when user types email id it checks whether the email id is valid or not
  onChangeEmail() {
    let email = this.userDetailForm
      .get('email')
      ?.valueChanges.subscribe((res) => {
        if (this.checkEmailPattern(res)) {
          this.isEmailCorrect = true;
        } else {
          this.isEmailCorrect = false;
        }
      })!;
    this.subscriptions.push(email);
  }

  //when user types user name it checks whether the username is in the form of valid identifier or not
  onChangeUserName() {
    let userName = this.userDetailForm
      .get('name')
      ?.valueChanges.subscribe((res) => {
        if (this.checkUserName(res)) {
          this.isUserNameCorrect = true;
        } else {
          this.isUserNameCorrect = false;
        }
      })!;
    this.subscriptions.push(userName);
  }

  // it checks the password pattern that it has atleast one special character, number, capital letter, small letter and minimum length is 8
  checkPasswordPattern(password: string): boolean {
    let passwordPattern = AppConstants.PASSWORD_REGEX;
    if (passwordPattern.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  //it checks the whether the email id is in email format or not
  checkEmailPattern(email: string): boolean {
    let emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailPattern.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  //it checks the whether the user name is in valid format or not
  checkUserName(userName: string): boolean {
    let userNamePattern = /^[a-zA-Z][a-zA-Z ]*$/;
    if (userNamePattern.test(userName)) {
      return true;
    } else {
      return false;
    }
  }

  //to change the password visibility (show password or hide password)
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  getModulesData() {
    this.modules = filter(
      this.localStorageService.getParsedValue(LocalStorageConstants.MODULES),
      (module) => {
        if (indexOf([1012], module.moduleId) < 0) {
          if (
            indexOf(
              this.localStorageService.getParsedValue(
                LocalStorageConstants.MODULE_ACCESS
              ),
              module.moduleId
            ) > -1
          ) {
            if (
              indexOf(this.commonService.getUser().modules, module.moduleId) >
              -1
            ) {
              return module;
            }
          }
        }
      }
    );

    //if editing user or replicating user than taking the expiry dates to patch
    let moduleExpiry: { [key: string]: any } = {};
    if (this.isEditMode || this.isReplicateMode) {
      this.data?.moduleExpiry.map((expiry: any) => {
        moduleExpiry[expiry.moduleId] = this.customMomentService.moment.unix(
          expiry.expiry_date
        );
      });
    }

    this.modules.forEach((module) => {
      if (this.commonService.getUser().moduleExpiry) {
        if (this.defaultModules.includes(module.moduleId)) {
          module['disableRow'] = true;
        }
        let date =
          this.commonService.getUser().moduleExpiry[module['moduleId']!];
        if (moduleExpiry[module.moduleId]) {
          module['expiryDate'] = moduleExpiry[module.moduleId];
        } else {
          module['expiryDate'] = this.epochTimeToDateMoment(date);
        }
        module['maxDate'] = this.epochTimeToDateMoment(date);
        module['enabled'] = false;
      }

      const userDataModule = this.data?.moduleExpiry?.find(
        (userModule: any) => {
          return userModule.moduleId === module.moduleId;
        }
      );

      if (userDataModule || this.defaultModules.includes(module.moduleId)) {
        let options: any = {};
        const adminUserModuleOptions = this.commonService.moduleAccessibility(
          module.moduleId
        );

        options = {
          ...adminUserModuleOptions,
          ...userDataModule?.options,
        };

        module['options'] = options;
      }
      return module;
    }),
      CommonUtil.sortModules(this.modules, this.defaultModules);
  }

  // sortModules(modules: ModuleCheckbox[]) {
  //   return modules.sort((a, b) => {
  //     const indexA = this.defaultModules.indexOf(a.moduleId);
  //     const indexB = this.defaultModules.indexOf(b.moduleId);

  //     // If both are priority modules, sort by their index
  //     if (indexA !== -1 && indexB !== -1) {
  //       return indexA - indexB;
  //     }

  //     // If only a is a priority module, it comes first
  //     if (indexA !== -1) return -1;
  //     // If only b is a priority module, it comes first
  //     if (indexB !== -1) return 1;

  //     // If neither are priority modules, maintain original order
  //     return 0;
  //   });
  // }

  //executes and do api call when user click on submit form
  submitForm() {
    this.loadrService.showLoader();
    if (this.isEditMode) {
      this.userService
        .updateSubUser(
          this.generatePayload() as RequestData.UpdateUser,
          this.data?.userId!
        )
        .subscribe({
          next: (res) => {
            this.loadrService.removeLoader();
            this.formsService.closeForm();
            this.userService.refreshUserData(true);
            this.notificationService.showSnackBar(res, 'success');
          },
          error: (err) => {
            console.info('Error:', err);
            this.loadrService.removeLoader();
            this.notificationService.showSnackBar(err.error, 'error');
          },
        });
    } else {
      this.userService
        .addSubUser(this.generatePayload() as RequestData.NewUser)
        .subscribe({
          next: (res) => {
            this.loadrService.removeLoader();
            this.formsService.closeForm();
            this.userService.refreshUserData(true);
            this.notificationService.showSnackBar(res, 'success');
          },
          error: (err) => {
            console.info('Error:', err);
            this.loadrService.removeLoader();
            this.notificationService.showSnackBar(err.error, 'error');
          },
        });
    }
  }

  patchDevicesAndModules() {
    let selectedDevices = this.devices.filter((device: DeviceDetails) => {
      return this.data?.devices!.some(
        (resDevice: DeviceDetails) => device.deviceId === resDevice.deviceId
      );
    });
    this.deviceSelector.select(...selectedDevices);
    this.onDeviceSelection();
    if (this.isReplicateMode) {
      this.isNextStep = false;
    }

    let selectedModules = this.modules.filter((module: ModuleCheckbox) =>
      this.data?.modules.includes(module.moduleId!)
    );
    this.moduleSelector.select(...selectedModules);
    this.onModuleSelection();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
