import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { LoginData } from 'src/app/shared/models/login-data';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SubUserFormComponent } from './components/sub-user-form/sub-user-form.component';

@Component({
  selector: 'app-sub-users',
  templateUrl: './sub-users.component.html',
  styleUrls: ['./sub-users.component.scss'],
})
export class SubUsersComponent implements OnInit {
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public subUsers!: LoginData[];
  public displayedColumns: string[] = [];
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public dialogCompInstance!: ConfirmationPopupComponent;
  public tableTitle!: string;
  public subscriptions: Subscription[] = [];
  public defaultColumns: any[] = [
    {
      columnDef: 'actions',
      header: '',
      cell: (element: LoginData) => `${element.userId}`,
      parameter: false,
      selected: false,
      icon: 'more_vert',
      hide: (element: LoginData) => element.email === this.currentUserEmail,
      options: [
        {
          label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
          action: (element: LoginData) => this.updateSubUser(element),
          disable: (element: LoginData) =>
            element.email === this.currentUserEmail ? true : false,
        },
        {
          label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
          action: (element: LoginData) => this.openDialog(element),
        },
        {
          
          label: `<span class="material-symbols-outlined">tab_duplicate</span>&nbsp Replicate User`,
          action: (element: LoginData) => this.replicateSubUser(element),
        },
      ],
    },
    {
      columnDef: 'userName',
      header: 'User Name',
      cell: (element: LoginData) => `${element.name}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: LoginData) => `${element.email}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'contact',
      header: 'Contact',
      cell: (element: LoginData) => `${element.contact ? element.contact : ''}`,
      parameter: false,
      selected: false,
    },
  ];

  constructor(
    private userService: UserService,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private confirmationPopupService: ConfirmationPopupService,
    private projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.getSubUserData();
    this.addSubscriptions();
  }

  updateSubUser(userData: LoginData) {
    this.formsService.openForm(
      SubUserFormComponent,
      { ...userData, isEditMode: true },
      'xl'
    );
  }

  replicateSubUser(userData: LoginData) {
    this.formsService.openForm(
      SubUserFormComponent,
      { ...userData, isEditMode: false },
      'xl'
    );
  }

  getSubUserData() {
    this.loadTable.next(false);
    let subUser = this.userService.getSubUsers().subscribe((res) => {
      this.subUsers = this.formatSubUsers(res);
      this.tableTitle = `Sub Users (${this.subUsers.length})`;
      this.loadTable.next(true);
    });
    this.subscriptions.push(subUser);
  }

  deleteSubUser(userData: LoginData) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.userService.deleteSubUser(userData.userId).subscribe({
      next: (res) => {
        if (res) {
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Sub User Deleted Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.loadTable.next(false);
            this.userService.refreshUserData(true);
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info("Error:", err);
        console.info("Error:", err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Deleting Sub User. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  openDialog(userData: LoginData): void {
    const dynamicData = {
      title: 'Delete Sub-User',
      message: 'Are you sure you want to delete this Sub User?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteSubUser(userData);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  formatSubUsers(users: LoginData[]): LoginData[] {
    let s1 = users.filter((user) => user.role === 1);
    let s2 = users.filter((user) => user.role !== 1);
    return s1.concat(s2);
  }

  addSubscriptions() {
    let refresh = this.userService.refreshData$.subscribe((res) => {
      if (res) {
        this.loadTable.next(false);
        this.getSubUserData();
      }
    });
    this.subscriptions.push(refresh);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
