<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <app-data-table
    [data]="modules"
    [columns]="defaultColumnsModules"
    [displayedColumns]="displayedColumnsModules"
    [showFilterInHeader]="false"
    [showTableHeader]="false"
    [isChartVisible]="false"
  ></app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
