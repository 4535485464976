import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DisplayFormComponent } from './components/display-form/display-form.component';
import { DisplayRoutingModule } from './display-routing.module';
import { DisplayComponent } from './display.component';
import { DisplayListComponent } from './pages/display-list/display-list.component';

@NgModule({
  declarations: [DisplayListComponent, DisplayComponent, DisplayFormComponent],
  imports: [
    CommonModule,
    DisplayRoutingModule,
    TranslateModule.forChild({}),
    SharedModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    GoogleMapsModule,
    MatStepperModule,
  ],
})
export class DisplayModule {}
