<app-form-container
  [tableTitle]="isEdit ? 'Edit Heatmap' : 'Add Heatmap'"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  (submit)="onSubmit()"
  [submitBtn]="submitBtn"
  [cancelBtn]="backBtn"
  [isValid]="mapDetailForm.valid"
>
  <div>
    <ng-container>
      <form [formGroup]="mapDetailForm">
        <div  class="row py-2 gy-4">
          <div class="col-6">
            <mat-form-field class="w-100 mb-3">
              <mat-label>Device Type </mat-label>
              <mat-select
                formControlName="deviceType"
                (selectionChange)="onDeviceTypeChange($event)"
                [value]="mapDetailForm.get('deviceType')?.value"
              >
                <mat-option
                  *ngFor="let deviceType of deviceTypes"
                  [value]="deviceType.deviceTypeId"
                >
                  {{ deviceType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label>Heatmap Name</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="heatmapName"
                  required
                />
              </mat-form-field>
          </div>
        </div>
        <div
          class="col-12"
          [ngClass]="{
            'pe-none disabled': !(mapDetailForm.get('deviceType')?.value > 0)
          }"
        >
          <mat-radio-group
            aria-label="Select an option"
            formControlName="deviceOption"
            [disabled]="!mapDetailForm.get('deviceType')?.value"
            (change)="formTypeChange($event.value)"
            [value]="openedForm"
          >
          <mat-radio-button
            [disabled]="!mapDetailForm.get('deviceType')?.value"
            value="2"
            [color]="'primary'"
          >
            Select Area
          </mat-radio-button>
            <mat-radio-button
              [disabled]="!mapDetailForm.get('deviceType')?.value"
              value="1"
              [color]="'primary'"
            >
              Choose GeoJSON File
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="mapDetailForm.get('deviceType')?.value && !openedForm"
          >
            Please select an option.
          </mat-error>
        </div>
        <div
          class="col-12"
          *ngIf="openedForm == 1"
          [ngClass]="{
            'pe-none disabled': !(mapDetailForm.get('deviceType')?.value > 0)
          }"
        >
          <!-- <input
            type="file"
            formControlName="geojsonFileInput"
            [multiple]="false"
            class="file-input"
            id="geojson_file_input"
            (change)="onFileSelection($event)"
          /> -->
        </div>
        <div class="col-12" *ngIf="openedForm == 1" [ngClass]="{'pe-none disabled': !(mapDetailForm.get('deviceType')?.value > 0)}">
          <div class="custom-file-upload" color="primary">
            <label for="geojson_file_input" class="file-upload-label" >
              <mat-icon>upload</mat-icon> Choose GeoJSON File
            </label>
            <input
              type="file"
              formControlName="geojsonFileInput"
              [multiple]="false"
              id="geojson_file_input"
              class="file-input"
              accept=".geojson, .json, .GeoJSON, .Geojson"
              (change)="onFileSelection($event)"
            />
            <!-- <div *ngIf="selectedFileName" class="file-name-display">
              {{ selectedFileName }}
            </div> -->
          </div>
          <mat-error *ngIf="fileError">{{ fileError }}</mat-error>
        </div>
        <div
          class="col-12"
          [ngClass]="{
            'pe-none disabled': !(mapDetailForm.get('deviceType')?.value > 0)
          }"
        >
          <mat-error class="errors" *ngIf="selectedDevices?.length === 0 && openedForm == 2">
            Please select the area.
          </mat-error>
          <mat-error class="errors" *ngIf="errorMsgForSelectedDev">
            More than five devices are required.
          </mat-error>
        </div>
        <div
          class="col-12 modal-map"
          [ngClass]="{
            'pe-none disabled': !(mapDetailForm.get('deviceType')?.value > 0 && openedForm)
          }"
        >
        <hr>
          <ng-container *ngIf="googleMapsService.isApiLoaded | async">
            <div class="row mx-0 h-100">
              <google-map
                class="col-12 px-0"
                width="100%"
                height="100%"
                [options]="options"
              >
                <map-marker
                  *ngFor="let device of allDevices; index as i"
                  #marker1="mapMarker"
                  [label]="device?.labelOptions"
                  [position]="{
                    lat: device?.latitude,
                    lng: device?.longitude
                  }"
                  [options]="markerOptions(device)"
                  (mapClick)="openInfo(marker1, i)"
                >
                  <map-info-window
                    #deviceInfoWindows2="mapInfoWindow"
                    class="text-center text-black"
                  >
                    <div class="justify-content-center text-center p-2">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span class="infoWindowLabel">
                          <b>Device :</b> {{ device.label }}
                        </span>
                        <!-- Close button -->
                        <span
                          class="cursor-pointer"
                          [style]="{ lineHeight: '0' }"
                          (click)="closeInfoWindows()"
                        >
                          <mat-icon>close</mat-icon>
                        </span>
                      </div>
                      <div
                        *ngIf="
                              selectedDevices?.indexOf?.(device.deviceId) > -1
                            "
                        class="addToHeatmap"
                        (click)="
                          removeDeviceFromHeatmap(device.deviceId);
                          closeInfoWindows()
                        "
                      >
                        <strong>Remove from heatmap</strong>
                      </div>
                    </div>
                  </map-info-window>
                </map-marker>
              </google-map>
            </div>
            <app-common-map-controls
              [googleMap]="googleMap"
              [options]="options"
              (mapOptionsUpdated)="onMapOptionsUpdated($event)"
              [showComplainsLayerOption]="false"
            ></app-common-map-controls>
          </ng-container>
          <div id="drawing-control" class="drawing-control">
            <div class="d-flex">
              <mat-icon
                matTooltip="Stop Drawing"
                class="material-symbols-outlined text-basic-color"
                (click)="stopDrawing()"
              >
                pan_tool
              </mat-icon>
              <div class="control-divider"></div>
              <mat-icon
                matTooltip="Draw Polygon"
                class="material-symbols-outlined text-basic-color"
                (click)="startDrawingPolygon()"
              >
                pentagon
              </mat-icon>
              <div class="control-divider"></div>
              <mat-icon
                matTooltip="Reset"
                class="material-symbols-outlined text-basic-color"
                (click)="resetForm('clicked')"
              >
                remove_selection
              </mat-icon>
            </div>
          </div>
        </div>
        <div
          class="col-12 mt-4"
          [ngClass]="{
            'pe-none disabled': !(mapDetailForm.get('deviceType')?.value > 0)
          }"
        >
          <div class="devList">
            <div style="font-size: 70%; padding: 3px" class="selectedDevHeader">
              <b>Selected Devices :</b>
            </div>
            <div style="overflow: auto">
              <span
                *ngFor="let device of selectedDevicesLabels"
                class="device-chip"
              >
                <span class="label">{{ device?.label }}</span>
              </span>
              <div
                *ngIf="selectedDevicesLabels.length == 0"
                style="text-align: center"
              >
                <strong>No Device added</strong>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 mt-3"
          [ngClass]="{
            'pe-none disabled': !(selectedDevices?.length > 0)
          }"
        >
          <!-- <mat-form-field class="w-100">
            <mat-label>Select Parameters</mat-label>
            <mat-select multiple formControlName="parameters">
              <mat-option
                *ngFor="
                  let parameter of parameters | gasLevel : fields : limits
                "
                [value]="parameter.name"
              >
                {{ parameter?.label || "Shittt" }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </form>
    </ng-container>
  </div>
</app-form-container>
