import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import * as Highcharts from 'highcharts';
import * as Highstocks from 'highcharts/highstock';
import {
  Observable,
  Subject,
  Subscription,
  forkJoin,
  map,
  takeUntil,
} from 'rxjs';
import { Socket } from 'socket.io-client';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { NotificationComponent } from 'src/app/shared/components/notification/notification.component';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { AnnouncementComponent } from '../components/announcement/announcement.component';
import { DownloadCsvReportFormComponent } from '../components/download-csv-report-form/download-csv-report-form.component';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { LoginData } from '../models/login-data';
import { Project } from '../models/project/project';
import { CommonService } from '../services/common.service';
import { LayoutService } from '../services/layout.service';
import { LocalStorageService } from '../services/local-storage.service';
import { MqttSocketService } from '../services/mqtt-socket.service';
import { NotificationService } from '../services/notification.service';
import { OverviewService } from '../services/overview.service';
import { ProjectsService } from '../services/projects.service';
import { RippleService } from '../services/ripple.service';
import { TranslationService } from '../services/translation.service';
import { AppNotification } from '../models/app-notification';
import { Notifications } from '../models/notifications/notification';
import { environment } from 'src/environments/environment';
type Socket = typeof Socket;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  dialogComponentInstanceforLogout!: ConfirmationPopupComponent;
  width!: number;
  moduleAccess: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }
  subscriptions: Subscription[] = [];
  headerLogo!: string;

  title!: string;

  themes = AppConstants.APP_THEMES;
  static_theme_option = environment?.config?.SHOW_THEME_CHANGE_OPTION;
  currentTheme = 'default';
  currentLang = 'en';

  highcharts: typeof Highcharts = Highcharts;
  highstocks: typeof Highstocks = Highstocks;
  isNotificationDisabled: boolean = true;
  incomingSocket?: Socket;
  tooltipText?: string;
  public planInfo!: Record<string, any>;
  public isPartner!: boolean;
  public isNewNotificationAvailable: boolean = false;
  public languages: { key: string; label: string }[] = AppConstants.LANGUAGES;
  public componentRef!: AnnouncementComponent;
  aevisVisible = false;
  private get languagesMap() {
    return Object.fromEntries(
      this.languages.map(({ key, label }) => [key, { key, label }])
    );
  }

  public selectedLanguage: { key: string; label: string } = this.languages[0];
  routingCompleted: boolean = true;
  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private rippleService: RippleService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private formsService: FormsService,
    private userService: UserService,
    private cookieService: CookieService,
    public router: Router,
    public deviceService: DeviceService,
    private translationService: TranslationService,
    private mqttSocketService: MqttSocketService,
    private projectService: ProjectsService,
    private dialog: MatDialog,
    private commonservice: CommonService,
    private overviewService: OverviewService,
    private cdr: ChangeDetectorRef
  ) {
    this.materialTheme$ = this.themeService.onThemeChange().pipe(
      map((theme) => {
        this.getHeaderLogo();
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      })
    );
  }

  ngOnInit() {
    this.moduleAccess = this.commonservice.moduleAccessibility(1022);
    this.width = window.innerWidth;
    this.getHeaderLogo();
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.routingCompleted = false;
          setTimeout(() => (this.routingCompleted = true), 100);
        }
      },
    });
    this.addSubscriptions();

    forkJoin([
      this.userService.getNotificationLastSeen([
        AppConstants.NOTIFICATION_TOPICS['header-notification'],
      ]),
      this.userService.getUserNotifications(),
    ]).subscribe(([allLastSeen, notification]) => {
      this.modifyNotificationResponse(notification);
    });

    this.deviceService.getAllAQI().subscribe({ next: (allAQILoaded) => {} });

    this.currentTheme = this.themeService.currentTheme;
    localStorage.setItem('current-theme', this.currentTheme);
    try {
      if (this.currentTheme == 'material-light') {
        this.changeHighchartTheme('material-light');
      } else {
        this.changeHighchartTheme('material-dark');
      }
    } catch (e) {}

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
        try {
          const metaTag = document.querySelector('meta[name=color-scheme]');
          if (this.currentTheme == 'material-light') {
            metaTag?.setAttribute('content', 'light');
          } else {
            metaTag?.setAttribute('content', 'dark');
          }
        } catch (e) {}
      });

    this.menuService.onItemSelect().subscribe((item) => {
      this.title = item.item.title;
    });

    //plan info should only be shown to admin of organization(Partner) account
    let user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    let modulesAccess = user.modules;
    this.isPartner = modulesAccess.includes(1012);
    if (this.isPartner) {
      this.setCurrentPlanInfo(user?.info);
    }
    // this.subscribeToUserNotifications();

    this.selectedLanguage = this.languagesMap[user?.settings?.language ?? 'en'];
  }

  ngAfterViewInit(): void {
    if (this.width <= 991) {
      this.sidebarService.collapse('menu-sidebar');
    }
  }

  modifyNotificationResponse(notifications: AppNotification[]) {
    let lastVisit = this.commonservice.getNotifLastVisit(
      AppConstants.NOTIFICATION_TOPICS['header-notification'],
      true
    );
    this.isNotificationDisabled = false;
    this.notificationService.notifications.push(
      ...notifications.map((notification) => {
        return {
          deviceId: notification.deviceId,
          type: notification.type,
          label: DeviceUtil.getDeviceLabel(
            this.deviceService.registeredDevices!,
            notification.deviceId
          ),
          message: notification.message,
          key: notification.key,
          keyLabel: DeviceUtil.getFieldName(
            notification.key,
            this.deviceService.fields
          ),
          unit: DeviceUtil.getFieldUnit(
            notification.key,
            this.deviceService.fields
          ),
          value: DeviceUtil.getCFactoreData(
            notification.key,
            notification.value.toString(),
            this.deviceService.fields
          ),
          t: notification.t,
          timestamp: CommonUtil.getDisplayTime(notification.t),
        };
      })
    );
    this.notificationService.notifications.forEach((notification) => {
      notification.isVisited = true;
      if (
        lastVisit &&
        notification.t > lastVisit &&
        !this.isNewNotificationAvailable
      ) {
        this.isNewNotificationAvailable = true;
      }
    });
    this.notificationService.userNotifications.next(true);
  }

  subscribeToUserNotifications(): void {
    const userNotifications: Subscription =
      this.notificationService.userNotification$.subscribe((_notifier: any) => {
        let notificationsCount = this.notificationService.notifications.filter(
          (notification) => notification.isVisited === false
        ).length;

        notificationsCount = notificationsCount > 99 ? 99 : notificationsCount;

        this.notificationService.notificationGroups.device =
          this.notificationService.notifications.filter(
            (notification) => notification.type == 0
          );

        const modules = (
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_USER
          ) as LoginData
        ).modules;
        if (modules.includes(1007)) {
          this.notificationService.notificationGroups.alert =
            this.notificationService.notifications.filter(
              (notification) => notification.type > 0 && notification.type <= 3
            );
        }
      });
    this.subscriptions.push(userNotifications);
  }

  updateLanguage() {
    if (this.selectedLanguage?.key) {
      this.translationService.switchLanguage(this.selectedLanguage.key ?? 'en');
    }
  }

  getLanguageLabel(language: { key: string; label: string }): string {
    return language?.label ?? '';
  }

  getHeaderModuleOptions(key: any) {
    return this.commonservice.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  changeTheme(themeName: string) {
    localStorage.setItem('current-theme', themeName);
    this.themeService.changeTheme(themeName);
    try {
      if (themeName == 'material-light') {
        this.changeHighchartTheme('material-light');
      } else {
        this.changeHighchartTheme('material-dark');
      }
    } catch (e) {}
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeHighchartTheme(themeName: string) {
    if (themeName == 'material-light') {
      this.highcharts.theme = {
        colors: [
          '#058DC7',
          '#50B432',
          '#ED561B',
          '#DDDF00',
          '#24CBE5',
          '#64E572',
          '#FF9655',
          '#FFF263',
          '#6AF9C4',
        ],
        chart: {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
            stops: [
              [0, 'rgb(255, 255, 255)'],
              [1, 'rgb(255, 255, 255)'],
            ],
          },
        },
        title: {
          style: {
            color: '#000',
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          },
        },
        subtitle: {
          style: {
            color: '#666666',
            font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
          },
        },
        xAxis: {
          lineColor: '#666666',
          gridLineColor: '#e6e6e6',
          minorGridLineColor: '#e6e6e6',
          tickColor: '#e6e6e6',
          lineWidth: 1,
          labels: {
            style: {
              color: '#666',
              cursor: 'default',
              fontsize: '11px',
              lineheight: '14px',
            },
          },
          title: {
            style: {
              color: '#4d759e',
              fontweight: 'bold',
            },
          },
        },
        yAxis: {
          minorTickInterval: null as any,
          lineColor: '#e6e6e6',
          gridLineColor: '#e6e6e6',
          minorGridLineColor: '#e6e6e6',
          tickColor: '#e6e6e6',
          tickWidth: 0,
          // lineWidth: 1,
          // tickWidth: 1,
          // tickColor: '#c0d0e0',
          labels: {
            style: {
              color: '#666',
              cursor: 'default',
              fontsize: '11px',
              lineheight: '14px',
            },
          },
        },
        legend: {
          backgroundColor: 'transparent',
          itemStyle: {
            font: '9pt Trebuchet MS, Verdana, sans-serif',
            color: 'black',
          },
          itemHoverStyle: {
            color: 'gray',
          },
        },
      };

      this.highstocks.theme = this.highcharts.theme;

      // this.highcharts.theme = {
      //   colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970',
      //     '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
      //   chart: {
      //     backgroundColor: '#fff',
      //     borderWidth: 0,
      //     plotBackgroundColor: '#fff',
      //     plotShadow: false,
      //     plotBorderWidth: 0
      //   },
      //   title: {
      //     style: {
      //       color: '#274b6d',//#3e576f',
      //       fontsize: '16px'
      //     }
      //   },
      //   subtitle: {
      //     style: {
      //       color: '#4d759e'
      //     }
      //   },
      //   xAxis: {
      //     gridLineWidth: 0,
      //     lineColor: '#c0d0e0',
      //     tickColor: '#c0d0e0',
      //     labels: {
      //       style: {
      //         color: '#666',
      //         cursor: 'default',
      //         fontsize: '11px',
      //         lineheight: '14px'
      //       }
      //     },
      //     title: {
      //       style: {
      //         color: '#4d759e',
      //         fontweight: 'bold'
      //       }
      //     }
      //   },
      //   yAxis: {
      //     minorTickInterval: null,
      //     lineColor: '#c0d0e0',
      //     lineWidth: 1,
      //     tickWidth: 1,
      //     tickColor: '#c0d0e0',
      //     labels: {
      //       style: {
      //         color: '#666',
      //         cursor: 'default',
      //         fontsize: '11px',
      //         lineheight: '14px'
      //       }
      //     },
      //     title: {
      //       style: {
      //         color: '#4d759e',
      //         fontweight: 'bold'
      //       }
      //     }
      //   },
      //   legend: {
      //     backgroundColor: 'rgba(255, 255, 255)',
      //     itemStyle: {
      //       color: '#274b6d',
      //       fontsize: '12px'
      //     },
      //     itemHoverStyle: {
      //       color: '#000'
      //     },
      //     itemHiddenStyle: {
      //       color: '#ccc'
      //     }
      //   },
      //   // labels: {
      //   //   style: {
      //   //     color: '#3e576f'
      //   //   }
      //   // },

      //   navigation: {
      //     buttonOptions: {
      //       theme: {
      //         stroke: '#cccccc'
      //       }
      //     }
      //   }
      // }
    } else {
      this.highcharts.theme = {
        // colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
        //   '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
        chart: {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
            stops: [
              // [0, '#2a2a2b'],
              // [1, '#3e3e40']
              [0, '#2e2e2e'],
              [1, '#2e2e2e'],
            ],
          },
          style: {
            fontFamily: "'Unica One', sans-serif",
          },
          plotBorderColor: '#606063',
        },
        title: {
          style: {
            color: '#E0E0E3',
            textTransform: 'uppercase',
            fontSize: '20px',
          },
        },
        subtitle: {
          style: {
            color: '#E0E0E3',
            textTransform: 'uppercase',
          },
        },
        xAxis: {
          gridLineColor: '#707073',
          labels: {
            style: {
              color: '#E0E0E3',
            },
          },
          lineColor: '#707073',
          minorGridLineColor: '#505053',
          tickColor: '#707073',
          title: {
            style: {
              color: '#A0A0A3',
            },
          },
        },
        yAxis: {
          gridLineColor: '#707073',
          labels: {
            style: {
              color: '#E0E0E3',
            },
          },
          lineColor: '#707073',
          minorGridLineColor: '#505053',
          tickColor: '#707073',
          tickWidth: 1,
          title: {
            style: {
              color: '#A0A0A3',
            },
          },
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          style: {
            color: '#F0F0F0',
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              color: '#F0F0F3',
              style: {
                fontSize: '13px',
              },
            },
            marker: {
              lineColor: '#333',
            },
          },
          boxplot: {
            fillColor: '#505053',
          },
          candlestick: {
            lineColor: 'white',
          },
          errorbar: {
            color: 'white',
          },
        },
        legend: {
          // backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backgroundColor: '#2e2e2e',
          itemStyle: {
            color: '#E0E0E3',
          },
          itemHoverStyle: {
            color: '#FFF',
          },
          itemHiddenStyle: {
            color: '#606063',
          },
          title: {
            style: {
              color: '#C0C0C0',
            },
          },
        },
        credits: {
          style: {
            color: '#666',
          },
        },
        // labels: {
        //     style: {
        //         color: '#707073'
        //     }
        // },
        drilldown: {
          activeAxisLabelStyle: {
            color: '#F0F0F3',
          },
          activeDataLabelStyle: {
            color: '#F0F0F3',
          },
        },
        navigation: {
          buttonOptions: {
            symbolStroke: '#DDDDDD',
            theme: {
              fill: '#505053',
            },
          },
        },
        // scroll charts
        rangeSelector: {
          buttonTheme: {
            fill: '#505053',
            stroke: '#000000',
            style: {
              color: '#CCC',
            },
            states: {
              hover: {
                fill: '#707073',
                stroke: '#000000',
                style: {
                  color: 'white',
                },
              },
              select: {
                fill: '#000003',
                stroke: '#000000',
                style: {
                  color: 'white',
                },
              },
            },
          },
          inputBoxBorderColor: '#505053',
          inputStyle: {
            backgroundColor: '#333',
            color: 'silver',
          },
          labelStyle: {
            color: 'silver',
          },
        },
        navigator: {
          handles: {
            backgroundColor: '#666',
            borderColor: '#AAA',
          },
          outlineColor: '#CCC',
          maskFill: 'rgba(255,255,255,0.1)',
          series: {
            color: '#7798BF',
            lineColor: '#A6C7ED',
          },
          xAxis: {
            gridLineColor: '#505053',
          },
        },
        scrollbar: {
          barBackgroundColor: '#808083',
          barBorderColor: '#808083',
          buttonArrowColor: '#CCC',
          buttonBackgroundColor: '#606063',
          buttonBorderColor: '#606063',
          rifleColor: '#FFF',
          trackBackgroundColor: '#404043',
          trackBorderColor: '#404043',
        },
      };

      this.highstocks.theme = this.highcharts.theme;
    }
    // Apply the theme
    this.highcharts.setOptions(this.highcharts.theme);
    this.highstocks.setOptions(this.highstocks.theme);
  }

  openNotification() {
    this.formsService
      .openForm(NotificationComponent, '', 'md')
      .subscribe(() => {
        this.isNewNotificationAvailable = false;
      });
  }

  openLogoutDialog(): void {
    const dynamicData = {
      title: 'Logout',
      message: 'Are you sure you want to logout from this user?',
      icon: 'logout',
      buttonconfig: [
        {
          text: 'No',
          closeDialog: true,
        },
        {
          text: 'Yes',
          onClickCallback: () => {
            this.logout();
          },
          closeDialog: false,
          color: 'primary',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.projectService.getDefaultDialogConfig,
      { data: dynamicData }
    );
    const dialogReffordeleteuser = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogComponentInstanceforLogout =
      dialogReffordeleteuser.componentInstance;
    this.projectService.getDialogRef(dialogReffordeleteuser);
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.mqttSocketService.disconnect(this.deviceService.incomingSocket!);
    this.router.navigate(['/login']);
    this.cookieService.clearCookies();
    this.localStorageService.setupDefaultConfig().subscribe({
      next: (response) => {
        const userLoggedIn = localStorage?.getItem(
          LocalStorageConstants.USER_ID
        )
          ? true
          : false;
        const moduleAccess =
          JSON.parse(
            this.localStorageService.getValue(
              LocalStorageConstants.MODULE_ACCESS
            )
          ) &&
          JSON.parse(
            this.localStorageService.getValue(
              LocalStorageConstants.MODULE_ACCESS
            )
          ).includes(1009)
            ? true
            : false;
        (window as any).userLoggedIn = userLoggedIn;
        (window as any).moduleAccess = moduleAccess;
        (window as any)?.initializeIntercom();
        (window as any)?.initAevisSettings();

        //set the website theme to default theme
        this.changeTheme(AppConstants.DEFAULT_THEME);
      },
      error: (err) => {
        console.info('Error:', err);
        this.notificationService.showNotification(
          AppConstants.CONFIG_ERROR_MESSAGE,
          AppConstants.CLOSE,
          'top',
          'center',
          'error'
        );
      },
    });
  }

  //sets up the current plan info
  setCurrentPlanInfo(userInfo: Project.Info) {
    this.planInfo = userInfo;
    if (this.planInfo) {
      this.planInfo.plan_name = AppConstants.planNames[this.planInfo.plan];
    }
  }

  getHeaderLogo() {
    const user: LoginData = this.commonservice.getUser();
    const info: Project.Info = user.organization.info!;

    if (Boolean(info.dark_mode_logo) || Boolean(info.logo_url)) {
      if (this.currentTheme === 'material-light') {
        this.headerLogo = info?.logo_url || info?.dark_mode_logo!;
        this.tooltipText = info?.logo_url
          ? ''
          : 'Please update logo as per your theme.';
      } else {
        this.headerLogo = info?.dark_mode_logo || info?.logo_url!;
        this.tooltipText = info?.dark_mode_logo
          ? ''
          : 'Please update logo as per your theme.';
      }
    }
    //if logo is not available than use default oizom logo
    else {
      if (this.currentTheme === 'material-dark')
        this.headerLogo = AppConstants.DARK_MODE_HEADER_LOGO;
      else {
        this.headerLogo = AppConstants.DEFAULT_MODE_HEADER_LOGO;
      }
    }
  }

  addSubscriptions() {
    let socketNotif = this.userService.alertNotificationSnapshot$.subscribe(
      (res) => {
        this.isNewNotificationAvailable = true;
      }
    );

    let profile = this.userService.userProfileUpdate$.subscribe((res) => {
      if (res) {
        this.getHeaderLogo();
      } else {
        console.info('Error while fetching logo');
      }
    });

    this.subscriptions.push(socketNotif, profile);
  }

  downloadCsvAndPdfReport() {
    this.formsService
      .openForm(DownloadCsvReportFormComponent, undefined, 'md')
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  showWhatsNewPopUp(view?: string) {
    let config = {};
    if (window.innerWidth < 768) {
      config = {
        data: [],
        width: '80%',
        height: '75%',
      };
    } else if (window.innerWidth <= 1024) {
      config = {
        data: [],
        width: '75%',
        height: '75%',
      };
    } else if (window.innerWidth <= 1280) {
      config = {
        data: [],
        width: '65%',
        height: '75%',
      };
    } else {
      config = {
        data: [],
        width: '50%',
        height: '75%',
      };
    }

    let d = this.dialog.open(AnnouncementComponent, config);

    this.componentRef = d.componentInstance;

    this.overviewService.newFeatures$.subscribe((res) => {
      //if data is available than show data
      if (res.length > 0) {
        this.componentRef.data = res;
      } else {
        //if data is not available than call api
        this.overviewService.getNewFeaturesInformation().subscribe();
      }
    });
  }

  openIntercom() {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      console.info('Intercom is not initialized.');
    }
  }

  openAevis() {
    this.aevisVisible = !this.aevisVisible;
    this.triggerAevisFunctionality();
  }

  private triggerAevisFunctionality() {
    if (
      window &&
      window.Aevis &&
      typeof window.Aevis.openConsole === 'function'
    ) {
      window.Aevis.openConsole();
      console.log('Aevis functionality triggered.');
    } else {
      console.error(
        'Aevis is not loaded yet or init function is not available.'
      );
    }
  }

  homepageNavigation() {
    this.router.navigate(['/overview/map']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
