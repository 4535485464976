<div class="row h-100 align-items-center gx-5 gy-5 gy-lg-0 mx-0">
  <div class="col-12">
    <mat-accordion>
      <mat-expansion-panel #expansionPanel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "Select Parameter" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-form-field class="col-12 col-lg-2">
            <mat-label>{{ "Select Parameter" | translate }}</mat-label>
            <input
              type="text"
              #trigger="matAutocompleteTrigger"
              [placeholder]="'Select Parameter' | translate"
              matInput
              [formControl]="parameterControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let column of filterOptionList"
                [value]="column"
              >
                {{ column.header }}
              </mat-option>
            </mat-autocomplete>
            <button
              (click)="selectionMade($event, trigger)"
              mat-icon-button
              matSuffix
              tabindex="-1"
            >
              <mat-icon class="material-symbols-outlined"
                >arrow_drop_down</mat-icon
              >
            </button>
          </mat-form-field>

          <button
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
            [disabled]="!parameterControl.value"
            (click)="setupDataPoints(parameterControl.value)"
            class="viewChart"
          >
            View Chart
          </button>
          <highcharts-chart
            *ngIf="show"
            [Highcharts]="highcharts"
            [options]="chartOptions"
            style="width: 100%; height: 100%; display: block"
          ></highcharts-chart>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
