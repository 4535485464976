import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { USER_MENU } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AQIIndex } from 'src/app/shared/models/aqi-index/aqi-index';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { LoginData } from 'src/app/shared/models/login-data';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { Unit } from 'src/app/shared/models/units/unit';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SubUserFormComponent } from './pages/sub-users/components/sub-user-form/sub-user-form.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public userRole: number = this.userService.getLocaluser().role;
  public currentSubModule!: string;
  public menu!: SubMenu[];
  public headerForm!: FormGroup;
  public unitForm!: FormGroup;
  public isSeatsFull: boolean = false;
  public userDeviceTypes!: Array<DeviceType>;
  public deviceTypesWithAQI!: Array<DeviceType>;
  public allAQI!: Array<any>;
  public allAQIOfSelectedDeviceType!: Array<any>;
  public selectedDeviceType!: any;
  public selectedDeviceTypeForUnits!: number;
  public selectedAQIOfSelectedDeviceType!: AQIIndex | undefined;
  public showAQIOptionList: boolean = false;
  public currentUrl!: string;
  public isUpdateAndCancelButtonDisabled: boolean = true;
  public isApiCall: boolean = false;
  public subscriptions: Subscription[] = [];
  public dialogCompInstance!: ConfirmationPopupComponent;

  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private router: Router,
    private location: Location,
    private commonService: CommonService,
    private loadrService: LoadrService,
    private deviceService: DeviceService,
    private notificationService: NotificationService,
    private projectService: ProjectsService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog
  ) {
    this.currentUrl = this.location.path();
  }

  ngOnInit() {
    this.userDeviceTypes = this.commonService.getUserDeviceTypes();
    let refresh = this.userService.refreshData$.subscribe((res) => {
      if (res) {
        let user = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        );
        let org = this.projectService.getMasterOrgInfo(user.userId).subscribe({
          next: (res) => {
            let updatedOrg = JSON.parse(JSON.stringify(user));
            updatedOrg.info = res.info;
            this.localStorageService.saveValue(
              LocalStorageConstants.OZ_USER,
              JSON.stringify(updatedOrg)
            );
            this.checkAvailableSeats();
          },
          error: (err) => {
            console.info('Error:', err);
          },
        });
        this.subscriptions.push(org);
      }
    });
    this.subscriptions.push(refresh);

    this.checkAvailableSeats();
    this.fetchAllParamUnits();
    this.checkUserRole();
    this.getCurrentUrl();
    this.setUpDeviceAndAQI();
    this.getSelectedAQI();
    this.buildForm();
    this.onChangeDeviceType();
    this.onChangeAQIType();
  }

  buildForm() {
    let device: DeviceType | undefined = this.deviceService.currentDeviceType;

    let isValidDeviceType = this.userDeviceTypes.find(
      (dt) => dt.deviceTypeId === device?.deviceTypeId
    );
    if (isValidDeviceType) {
      device = isValidDeviceType;
    } else if (!isValidDeviceType) {
      device = this.userDeviceTypes[0];

      const allAqis: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).aqiIndex;
      const allUnits: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_UNITS
      );
      const deviceTypes = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER_DEVICE_TYPE
      );
      this.deviceService.setUpDeviceTypeGlobally(
        device?.key,
        deviceTypes,
        allAqis,
        allUnits
      );
    } else {
      console.info('Error in patching device in units management');
    }

    this.selectedDeviceTypeForUnits = device?.deviceTypeId!;
    this.userService.onChangeDeviceTypeForUnits(
      this.selectedDeviceTypeForUnits
    );

    this.unitForm = this.formBuilder.group({
      deviceType: device,
    });

    this.headerForm = this.formBuilder.group({
      deviceType: this.selectedDeviceType,
      aqiType: [],
    });

    if (this.allAQIOfSelectedDeviceType) {
      this.headerForm.patchValue({
        aqiType: Object.values(this.allAQIOfSelectedDeviceType).find(
          (allkey) => allkey.id == this.selectedAQIOfSelectedDeviceType?.id
        ),
      });
    }
  }

  onChangeDeviceType() {
    let deviceType = this.headerForm
      .get('deviceType')
      ?.valueChanges.subscribe((res) => {
        this.selectedDeviceType = res;
        this.userService.onChangeDeviceOrAQI(this.selectedDeviceType);
        this.allAQIOfSelectedDeviceType = this.allAQI[res];
        if (this.selectedAQIOfSelectedDeviceType !== undefined) {
          this.headerForm.patchValue({
            aqiType: Object.values(this.allAQIOfSelectedDeviceType).find(
              (allkey) => allkey.id == this.selectedAQIOfSelectedDeviceType?.id
            ),
          });
        }
      })!;
    this.subscriptions.push(deviceType);

    let dt = this.unitForm.get('deviceType')?.valueChanges.subscribe((res) => {
      this.selectedDeviceTypeForUnits = res.deviceTypeId;

      let deviceTypes = this.commonService.getUserDeviceTypes();

      let deviceTypeKey = deviceTypes.find(
        (dt) => dt.deviceTypeId === res.deviceTypeId
      )?.key;

      let devices = this.deviceService.registeredDevices;

      let device = devices?.find((d) => d.deviceType === deviceTypeKey);

      this.deviceService.setupDeviceIdGlobally(device?.deviceId!);
      this.userService.onChangeDeviceTypeForUnits(
        this.selectedDeviceTypeForUnits
      );
    })!;

    this.subscriptions.push(dt);
  }

  onChangeAQIType() {
    let aqi = this.headerForm.get('aqiType')?.valueChanges.subscribe((res) => {
      this.selectedAQIOfSelectedDeviceType = res;
      if (this.selectedAQIOfSelectedDeviceType === undefined) {
        this.showAQIOptionList = false;
      } else if (this.selectedAQIOfSelectedDeviceType != undefined) {
        this.showAQIOptionList = true;
        this.userService.onChangeDeviceOrAQI(
          this.selectedAQIOfSelectedDeviceType,
          false
        );
      }
      this.updateButtonVisbilityCheck();
    })!;
    this.subscriptions.push(aqi);
  }

  addUser() {
    this.formsService.openForm(SubUserFormComponent, undefined, 'xl');
  }

  checkUserRole() {
    if (this.userRole === 1) {
      this.menu = USER_MENU;
    } else if (this.userRole === 2) {
      this.menu = USER_MENU.filter((item) => item.url !== 'sub-users');
    }
  }

  getCurrentUrl() {
    let router = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    this.subscriptions.push(router);
  }

  getSelectedAQI() {
    let aqi = this.userService.selectedAQI$.subscribe((res) => {
      this.selectedAQIOfSelectedDeviceType = res;
      if (this.selectedAQIOfSelectedDeviceType) {
        this.showAQIOptionList = true;
      } else if (!this.selectedAQIOfSelectedDeviceType) {
        this.showAQIOptionList = false;
      }
    });
    this.subscriptions.push(aqi);
  }

  setUpDeviceAndAQI() {
    this.deviceTypesWithAQI = this.commonService.getUserDeviceTypes();
    this.selectedDeviceType =
      this.deviceTypesWithAQI.length > 0
        ? this.deviceTypesWithAQI[0].deviceTypeId
        : null;
    this.userService.onChangeDeviceOrAQI(this.selectedDeviceType);

    this.allAQI = this.commonService.getAllAQI();

    if (this.allAQI[this.selectedDeviceType]) {
      this.allAQIOfSelectedDeviceType = Object.values(
        this.allAQI[this.selectedDeviceType]
      );
    }
  }

  updateButtonVisbilityCheck() {
    let currentAQIId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).aqiIndex[this.selectedDeviceType].id;

    this.isUpdateAndCancelButtonDisabled =
      currentAQIId === this.selectedAQIOfSelectedDeviceType?.id;
  }

  onCancel() {
    this.selectedAQIOfSelectedDeviceType =
      this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).aqiIndex[this.selectedDeviceType];
    this.userService.onChangeDeviceOrAQI(
      this.selectedAQIOfSelectedDeviceType!,
      false
    );
    this.headerForm.patchValue({
      aqiType: Object.values(this.allAQIOfSelectedDeviceType).find(
        (allkey) => allkey.id == this.selectedAQIOfSelectedDeviceType?.id
      ),
    });
  }

  onUpdate() {
    const { aqiIndex, ...payload } = this.generatePayload();

    this.isUpdateAndCancelButtonDisabled = true;
    this.isApiCall = true;
    let update = this.userService.updateAQI(payload).subscribe({
      next: (res: any) => {
        if (res) {
          let user = this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_USER
          );
          user.aqiIndex = aqiIndex;

          this.localStorageService.saveValue(
            LocalStorageConstants.OZ_USER,
            JSON.stringify(user)
          );

          this.userService.getUserProfile().subscribe(() => {
            this.deviceService.callOverview(); //call overview to recalculate subindex key values as per latest aqi
            this.fetchAllParamUnits();
          });
          this.isApiCall = false;
          this.updateButtonVisbilityCheck();
          this.notificationService.showSnackBar(
            'AQI updated successfully',
            'success'
          );
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.isApiCall = false;
        this.notificationService.showSnackBar(err.error, 'error');
      },
    });
    this.subscriptions.push(update);
  }

  generatePayload() {
    let userAqis: Record<string, AQIIndex> =
      this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).aqiIndex;

    if (this.selectedAQIOfSelectedDeviceType) {
      userAqis[this.selectedDeviceType] = this.selectedAQIOfSelectedDeviceType;
    }

    let payload: RequestData.UpdateUser & { aqiIndex: any } = {
      aqiIndex: userAqis,
      update: {
        aqi_index_id: Object.values(userAqis).map((aqi: any) => {
          return aqi.id;
        }),
      },
      resetUnits: true,
    };
    return payload;
  }

  fetchAllParamUnits() {
    let deviceTypes: DeviceType[] = this.commonService.getUserDeviceTypes();

    const allParams = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_PARAMS
    );
    const allAqis = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).aqiIndex;

    let finalPayload: Unit.Post = {
      keys: deviceTypes.map((deviceType) => {
        let deviceTypeId = deviceType.deviceTypeId;
        let payloadItem: any = {};
        if (allParams?.[deviceTypeId]) {
          payloadItem['unitsKeys'] = allParams[deviceTypeId];
        }
        if (allAqis?.[deviceTypeId]?.id) {
          payloadItem['aqiIndex'] =
            allAqis[deviceTypeId].id || allAqis[deviceTypeId].aqiIndexId;
        }
        return { ...payloadItem, deviceTypeId };
      }),
    };

    this.userService.getUnitsForAllParameters(finalPayload, deviceTypes);
  }

  resetUnits() {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;

    let userAqis: Record<string, AQIIndex> =
      this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).aqiIndex;

    if (this.selectedAQIOfSelectedDeviceType) {
      userAqis[this.selectedDeviceType] = this.selectedAQIOfSelectedDeviceType;
    }
    let payload: RequestData.UpdateUser = {
      update: {
        aqi_index_id: Object.values(userAqis).map((aqi: any) => {
          return aqi.id;
        }),
      },
      resetUnits: true,
    };
    // this.loadrService.showLoader();
    let reset = this.userService.resetUnits(payload).subscribe({
      next: (res) => {
        let profile = this.userService.getUserProfile().subscribe({
          next: (res) => {
            // this.loadrService.removeLoader();
            this.dialogCompInstance.dialogConfig.image =
              AppConstants.DELETE_SUCCESSFUL;
            this.dialogCompInstance.dialogConfig.message =
              'Units Reset Successfully';

            setTimeout(() => {
              this.dialog.closeAll();
            }, AppConstants.POPUP_TIMEOUT);
          },
          error: (err) => {
            console.info('Error:', err);
          },
        });
        this.subscriptions.push(profile);
      },
      error: (err) => {
        console.info('Error:', err);
        // this.loadrService.removeLoader();
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Reseting Units. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
    this.subscriptions.push(reset);
  }

  checkAvailableSeats() {
    let user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    if (user.info) {
      if ((user.info?.used_seats ?? 0) >= user.info.max_seats) {
        this.isSeatsFull = true;
      } else {
        this.isSeatsFull = false;
      }
    }
  }

  openDialog(): void {
    const dynamicData = {
      title: 'Reset Units',
      message:
        'All Units and Limits settings will be reset to default, are you sure you want to perform this action?',
      icon: 'restart_alt',
      buttonconfig: [
        {
          text: 'RESET',
          onClickCallback: () => {
            this.resetUnits();
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
