<ng-container *ngIf="noContentAvailable">
  <div class="h-100 d-flex justify-content-center align-items-center">
    <app-content-unavailable
      [isConfiguration]="noData.isConfiguration!"
      [majorText]="noData.majorText"
      [svgImage]="noData.svgImage"
      [minorText]="noData.minorText"
      [createFunc]="noData.createFunc!"
      [linkText]="noData.linkText!"
    >
    </app-content-unavailable>
  </div>
</ng-container>
<ng-container *ngIf="!noContentAvailable">
  <mat-sidenav-container class="h-100" autosize *ngIf="themeChange === 0">
    <mat-sidenav
      [position]="'end'"
      [mode]="'side'"
      [opened]="chartLoading || deviceData?.length"
      style="max-width: 600px; width: 40%; min-width: 300px"
    >
      <div class="device">
        <div class="d-flex align-items-center p-3">
          <div class="col">Parameter Information</div>
          <div class="col-auto">
            <mat-icon
              class="material-symbols-outlined cursor-pointer"
              (click)="closeChartsView()"
            >
              close
            </mat-icon>
          </div>
        </div>
        <div
          class="p-3"
          style="background-color: var(--layout-background-color)"
          *ngIf="chartLoading === 0 && deviceData?.length"
        >
          <ng-container *ngFor="let param of parameters; let i = index">
            <div class="card-container">
              <nb-card
                id="1001"
                style="max-height: 100%; height: 350px; min-height: 250px"
              >
                <nb-card-header>
                  <span class="title" [innerHTML]="param?.flabel ?? ''"></span>
                  <span
                    class="ps-1 small fw-normal"
                    [innerHTML]="param?.label ?? ''"
                  ></span>
                </nb-card-header>
                <nb-card-body>
                  <app-heatmap-chart-widget
                    [selectedField]="param"
                    [deviceInfo]="device"
                    [fields]="fields"
                    [limits]="limits"
                    [chartType]="'line'"
                    [aqiIndexColor]="aqiIndexColor"
                    [show]="true"
                    [deviceData]="deviceData"
                  >
                  </app-heatmap-chart-widget>
                </nb-card-body>
              </nb-card>
            </div>
          </ng-container>
        </div>

        <div class="py-5" *ngIf="chartLoading !== 0">
          <mat-spinner
            class="mx-auto"
            [diameter]="50"
            color="primary"
          ></mat-spinner>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="d-flex flex-column h-100">
        <div class="p-2 m-1">
          <div class="d-flex align-items-center">
            <button
              mat-raised-button
              [matMenuTriggerFor]="parameterMenu"
              class="d-flex flex-row-reverse align-items-center justify-content-around p-2"
              matTooltip="Select Parameter"
              [color]="'primary'"
              [disabled]="!selectedParameter"
            >
              <div class="text-center">
                {{ selectedParameter?.flabel ?? "" }}
              </div>
              <mat-icon class="m-0">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #parameterMenu="matMenu" style="padding: 0" class="">
              <button
                mat-menu-item
                *ngFor="let parameter of parameters; let i = index"
                (click)="selectParameter(parameter)"
                [value]="parameter"
              >
                {{ parameter.flabel }}
              </button>
            </mat-menu>
            <div
              class="mx-4 flex-grow-1 d-flex align-items-center"
              (mouseenter)="showSelectedTime()"
              (mouseleave)="hideSelectedTime()"
            >
              <button
                mat-icon-button
                [disabled]="!selectedParameter"
                (click)="playPauseHeatMap()"
                class="me-4"
              >
                <mat-icon [color]="'primary'">
                  {{ isPlaying ? "pause" : "play_arrow" }}
                </mat-icon>
              </button>
              <div class="flex-grow-1 position-relative">
                <mat-slider
                  class="w-100 mx-0"
                  [disabled]="sliderConfig.disabled"
                  [max]="sliderConfig.max"
                  [min]="sliderConfig.min"
                  [step]="1"
                  [discrete]="false"
                  [showTickMarks]="true"
                  size="small"
                >
                  <input
                    matSliderThumb
                    [(ngModel)]="currentHeatmapDataIndex"
                    (input)="updateHeatmap()"
                  />
                </mat-slider>
                <span
                  class="selected-time-container position-absolute text-center p-2 mt-3 rounded-2 border border-1"
                  [ngClass]="{
                    'd-none': (!selectedTime.length || !shouldShowSelectedTime) && removeSelectedTime,
                    show: !(!selectedTime.length || !shouldShowSelectedTime),
                    hide: !selectedTime.length || !shouldShowSelectedTime,
                  }"
                  [ngStyle]="selectedTimeStyles"
                  [innerHTML]="selectedTime"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="googleMapsService.isApiLoaded | async">
          <div
            class="row mx-0 flex-grow-1"
            [ngClass]="{ 'd-none': loading !== 0 }"
          >
            <google-map
              class="col-12 px-0"
              width="100%"
              height="100%"
              [options]="options"
            >
            </google-map>
          </div>
          <app-common-map-controls
            [googleMap]="googleMap"
            [options]="options"
            (mapOptionsUpdated)="onMapOptionsUpdated($event)"
            [showComplainsLayerOption]="false"
            [showWindLayerOption]="false"
          ></app-common-map-controls>
        </ng-container>

        <div class="py-5" *ngIf="loading !== 0">
          <mat-spinner
            class="mx-auto"
            [diameter]="50"
            color="primary"
          ></mat-spinner>
          <div class="text-center mt-3">
            <b>Heatmap is Loading, please wait...</b>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
