import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { AutomationData } from 'src/app/shared/models/automation/automation-data';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AutomationService } from 'src/app/shared/services/automation.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { AutomationFormComponent } from '../../components/automation-form/automation-form.component';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-automation-list',
  templateUrl: './automation-list.component.html',
  styleUrls: ['./automation-list.component.scss'],
})
export class AutomationListComponent implements OnInit {
  @Input() addConfigFunc!: () => void;
  public currentDeviceType: number = 0;
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = [];
  public automationData!: AutomationData.Get;
  public configData!: AutomationData.ConfigAutomationAlert[];
  updatedConfigData!: any[];
  public isRelayOutputAvailable: boolean = true;
  public relayUnavailableImage: string = AppConstants.RELAY_NOT_AVAILABLE;
  public dialogCompInstance!: ConfirmationPopupComponent;

  defaultColumns: any[] = [];

  public noData: ContentUnavailable = {
    isConfiguration: true,
    majorText: 'You Have No Automation Configured.',
    linkText: 'Configure Automation',
    minorText: 'and Proceed',
    svgImage: AppConstants.NO_DATA,
    createFunc: () => this.addConfigFunc(),
  };
  moduleAccess: any;

  constructor(
    private automationService: AutomationService,
    private deviceService: DeviceService,
    private formsService: FormsService,
    private loadrService: LoadrService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.defaultColumns = this.generateDefaultColumns();
  }

  ngOnInit() {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];

    this.automationService.isAutomationData$.subscribe((data: boolean) => {
      if (data) {
        this.automationData = this.automationService.automationData;
        if (this.automationData.config.automation) {
          this.isRelayOutputAvailable = true;
          this.configData = this.automationData.config.automation.alert;

          this.automationService.currentDeviceType$.subscribe((data) => {
            this.currentDeviceType = data;
          });

          this.updatedConfigData = this.configData.map((data, index) => {
            let copyOfData = { ...data };
            copyOfData.index = index;
            return copyOfData;
          });
          this.loadData();
        } else {
          this.isRelayOutputAvailable = false;
          this.loadData();
        }
      } else {
        this.loadTable.next(false);
      }
    });
    this.moduleAccess = this.commonService.moduleAccessibility(1014);
    this.defaultColumns = this.generateDefaultColumns();
  }

  loadData() {
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  deleteConfig(config: any) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;

    let temp = JSON.parse(JSON.stringify(this.updatedConfigData)).filter(
      (data: any) => {
        return data.index != config.index;
      }
    );

    this.automationData.config.automation.alert = [];
    this.automationData.config.automation.alert = temp;

    let finalPayload = this.automationService.generateFinalPayload();

    this.automationService
      .addAutomationData(finalPayload, this.automationData.deviceId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.dialogCompInstance.dialogConfig.image =
              AppConstants.DELETE_SUCCESSFUL;
            this.dialogCompInstance.dialogConfig.message =
              'Automation Deleted Successfully';
            setTimeout(() => {
              this.dialog.closeAll();
              this.automationService.getAutomationData(
                this.automationData.deviceId
              );
              this.loadTable.next(false);
            }, AppConstants.POPUP_TIMEOUT);
          }
        },
        error: (err) => {
          console.info('Error:', err);
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.QUERIED_DATA_NOT_FOUND;
          this.dialogCompInstance.dialogConfig.message =
            'Error While Deleting Automation. Try Again';
          setTimeout(() => {
            this.dialog.closeAll();
          }, AppConstants.POPUP_TIMEOUT);
        },
      });
  }

  editConfigForm(config: any) {
    this.formsService.openForm(AutomationFormComponent, config);
  }

  channelToOutputPreview: Record<number, String> = {
    0: 'Output 1',
    1: 'Output 2',
    2: 'Buzzer',
    3: 'Email',
  };

  defaultToStatusPreview: Record<number, String> = {
    0: 'OFF',
    1: 'ON',
  };

  basedOnToLabelPreview(key: string) {
    return this.deviceService.getFieldName(key);
  }

  generateDefaultColumns() {
    return [
      {
        columnDef: 'actions',
        header: '',
        cell: (element: AutomationData.ConfigAutomationAlert) => `${element}`,
        parameter: false,
        selected: false,
        icon: 'more_vert',
        options: [
          ...['edit', 'delete']
            .filter((permission) =>
              this.commonService.getModuleAccessOptionsOnRoute(
                permission,
                this.moduleAccess
              )
            )
            .map((permission) => {
              switch (permission) {
                case 'edit':
                  return {
                    label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
                    action: (element: AutomationData.ConfigAutomationAlert) =>
                      this.editConfigForm(element),
                  };
                case 'delete':
                  return {
                    label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
                    action: (element: AutomationData.ConfigAutomationAlert) =>
                      this.openDialog(element),
                  };
                default:
                  return null;
              }
            })
            .filter((option) => option !== null),
        ],
      },
      {
        columnDef: 'output',
        header: 'Output',
        cell: (element: AutomationData.ConfigAutomationAlert) =>
          `${this.channelToOutputPreview[element.channel]}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'basedOn',
        header: 'Based On',
        cell: (element: AutomationData.ConfigAutomationAlert) =>
          `${this.basedOnToLabelPreview(element.key)}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'defaultState',
        header: 'Default State',
        cell: (element: AutomationData.ConfigAutomationAlert) =>
          `${this.defaultToStatusPreview[element.default]}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'lowerValue',
        header: 'Lower Value',
        cell: (element: AutomationData.ConfigAutomationAlert) =>
          `${element.lowerThreshold}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'greaterValue',
        header: 'Greater Value',
        cell: (element: AutomationData.ConfigAutomationAlert) =>
          `${element.upperThreshold}`,
        parameter: false,
        selected: false,
      },
    ];
  }

  getAutomationModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(key, this.moduleAccess);
  }

  openDialog(config: any): void {
    const dynamicData = {
      title: 'Delete Automation',
      message: 'Are you sure you want to delete this Automation?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteConfig(config);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }
}
