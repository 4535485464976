<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <app-data-table
    tableTitle="Sensor List"
    [data]="configListToVisible"
    [columns]="defaultColumns"
    [displayedColumns]="displayedColumns"
    [showFilterInHeader]="false"
    [isChartVisible]="false"
    [noDataViewConfig]="noData"
    [showDownloadInHeader]="getConfigSensorOptions('download')"
  >
  </app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
