import { Component, OnInit } from '@angular/core';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-api-info',
  templateUrl: './api-info.component.html',
  styleUrls: ['./api-info.component.scss'],
})
export class ApiInfoComponent implements OnInit {
  postman_url: string = environment.integration.swagger_json_url;
  swagger_url: string = environment.integration.swagger_json_url;
  try_it_url: string = environment.integration.try_it_url;

  clientId: string = '';
  clientSecret: string = '';

  public httpsReqTypes: Record<string, string>[] = [
    {
      key: 'GET',
      value: 'Get a resource or list of resources',
    },
    {
      key: 'POST',
      value: 'Create resource',
    },
    {
      key: 'PATCH',
      value: 'Update a resource',
    },
    {
      key: 'PUT',
      value: 'Set a resource',
    },
    {
      key: 'DELETE',
      value: 'Delete a resource',
    },
  ];

  constructor(
    private notificationService: NotificationService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    let client_id = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    if (client_id === 'null' || !Boolean(client_id)) {
      client_id = ' --- ';
    }
    let client_secret = this.commonService.getUser().clientSecret;
    if (!Boolean(client_secret)) {
      client_secret = ' --- ';
    }

    this.clientId = client_id;
    this.clientSecret = client_secret;
  }

  openLink(URL: string) {
    window.open(URL, '_blank');
  }

  copyText(value: any) {
    try {
      let copyText = document.createElement('textarea');
      copyText.value = value.toString();
      document.body.appendChild(copyText);
      copyText.select();
      document.execCommand('copy');
      document.body.removeChild(copyText);
      this.notificationService.showSnackBar('Copied to Clipboard', 'info');
    } catch (e) {
      console.info('failed to copy');
    }
  }
}
