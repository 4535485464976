<div class="announcement-popup-header">
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title class="text-primary flex-shrink-1">New Features And Improvements</h1>
    <div class="d-flex align-items-center">
      <button
        tabindex="-1"
        mat-icon-button
        aria-label="clear"
        (click)="close()"
        matTooltip="Close"
      >
        <mat-icon class="material-symbols-outlined">close</mat-icon>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-content class="announcement-popup-content">
  <ng-container *ngFor="let items of data[0]?.features | keyvalue">
    <ul style="list-style-type: square">
      <div>
        <li class="announcement-message__details__header">
          {{ items.key }}
        </li>
      </div>
      <ul class="mt-3" style="list-style-type: disc">
        <ng-container *ngFor="let item of items?.value! | keyvalue">
          <li class="announcement-message__details__value">
            {{ item.value }}
          </li>
        </ng-container>
      </ul>
    </ul>
  </ng-container>
</div>
<!-- <div id="announcements" class="carousel slide" data-bs-ride="true"> 
    <div class="carousel-indicators">
      <button
        tabindex="-1"
        data-bs-target="#announcements"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Announcement 1"
      ></button>
      <button
        tabindex="-1"
        data-bs-target="#announcements"
        data-bs-slide-to="1"
        aria-label="Announcement 2"
      ></button>
      <button
        tabindex="-1"
        data-bs-target="#announcements"
        data-bs-slide-to="2"
        aria-label="Announcement 3"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="announcement-message">
          <div class="announcement-message__details">
            <div class="announcement-message__details__header">
              On Device Software
            </div>
            <div class="announcement-message__details__value">
              Updated version of on-Device Software. Users will now find the
              option to set MQTT and TCP configurations within the Settings
              module of the On-Device Software.
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="announcement-message">
          <div class="announcement-message__details">
            <div class="announcement-message__details__header">Language</div>
            <div class="announcement-message__details__value">
              The language preferences will not be stored as per the User's
              setting, Envizom will always load in the specified language.
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="announcement-message">
          <div class="announcement-message__details">
            <div class="announcement-message__details__header">Reports</div>
            <div class="announcement-message__details__value">
              Reports can be downloaded in selected language.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
