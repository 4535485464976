import { Component } from '@angular/core';
import { FormsService } from 'src/app/shared/services/forms.service';
import { DisplayFormComponent } from './components/display-form/display-form.component';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent {
  constructor(private formsService: FormsService) {}

  addDisplay() {
    this.formsService
      .openForm(DisplayFormComponent, undefined, 'xl')
      .subscribe({
        next: (res) => {
          // console.log(res);
        },
        error: (err: any) => {
          console.info('Error: ', err);
        },
      });
  }
}
