import { NgModule } from '@angular/core';
import { OverviewComponent } from './overview.component';
import { MapComponent } from './pages/map/map.component';
import { OverviewRoutingModule } from './overview-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableComponent } from './pages/table/table.component';
import { AqiComponent } from './pages/aqi/aqi.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [OverviewComponent, MapComponent, TableComponent, AqiComponent],
  imports: [
    TranslateModule.forChild({}),
    SharedModule,
    OverviewRoutingModule,
    ReactiveFormsModule,
    GoogleMapsModule,
  ],
})
export class OverviewModule {}
