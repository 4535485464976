import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private widgetDataUpdateSubject: Subject<string> = new Subject();
  public widgetDataUpdated$: Observable<string> =
    this.widgetDataUpdateSubject.asObservable();

  private redrawWidgetSubject: Subject<number> = new Subject();
  public redrawWidget$: Observable<number> =
    this.redrawWidgetSubject.asObservable();

  private _device!: DeviceDetails;
  public get device(): DeviceDetails {
    return this._device;
  }
  public set device(v: DeviceDetails) {
    let changed = JSON.stringify(this._device) !== JSON.stringify(v);
    this._device = v;
    if (changed) this.widgetDataUpdateSubject.next('device');
  }

  private _realTimeDeviceData: DeviceDetails[] = [];
  public get realTimeDeviceData(): DeviceDetails[] {
    return this._realTimeDeviceData;
  }
  public set realTimeDeviceData(v: DeviceDetails[]) {
    let changed =
      JSON.stringify(this._realTimeDeviceData) !== JSON.stringify(v);
    this._realTimeDeviceData = v;
    if (changed) this.widgetDataUpdateSubject.next('realTimeDeviceData');
  }

  private _liveDeviceData: DeviceDetails[] = [];
  public get liveDeviceData(): DeviceDetails[] {
    return this._liveDeviceData;
  }
  public set liveDeviceData(v: DeviceDetails[]) {
    let changed = JSON.stringify(this._liveDeviceData) !== JSON.stringify(v);
    this._liveDeviceData = v;
    if (changed) this.widgetDataUpdateSubject.next('liveDeviceData');
  }

  private _hourlyDeviceData: DeviceDetails[] = [];
  public get hourlyDeviceData(): DeviceDetails[] {
    return this._hourlyDeviceData;
  }
  public set hourlyDeviceData(v: DeviceDetails[]) {
    let changed = JSON.stringify(this._hourlyDeviceData) !== JSON.stringify(v);
    this._hourlyDeviceData = v;
    if (changed) this.widgetDataUpdateSubject.next('hourlyDeviceData');
  }

  private _windRoseData: {
    to: Record<string, any[]>;
    from: Record<string, any[]>;
  } = {
    to: {},
    from: {},
  };
  public get windRoseData(): typeof this._windRoseData {
    return this._windRoseData;
  }
  public set windRoseData(v: typeof this._windRoseData) {
    let changed = JSON.stringify(this._windRoseData) !== JSON.stringify(v);
    this._windRoseData.from = v.from;
    this._windRoseData.to = v.to;
    if (changed) this.widgetDataUpdateSubject.next('windRoseData');
  }

  private _aqiTrendsData: DeviceDetails[] = [];
  public get aqiTrendsData(): DeviceDetails[] {
    return this._aqiTrendsData;
  }
  public set aqiTrendsData(v: DeviceDetails[]) {
    let changed = JSON.stringify(this._aqiTrendsData) !== JSON.stringify(v);
    this._aqiTrendsData = v;
    if (changed) this.widgetDataUpdateSubject.next('aqiTrendsData');
  }

  private _predictionData: DeviceDetails[] = [];
  public get predictionData(): DeviceDetails[] {
    return this._predictionData;
  }
  public set predictionData(v: DeviceDetails[]) {
    let changed = JSON.stringify(this._predictionData) !== JSON.stringify(v);
    this._predictionData = v;
    if (changed) this.widgetDataUpdateSubject.next('predictionData');
  }

  private _aqiIndexColor!: AqiIndexColorArray;
  public get aqiIndexColor(): AqiIndexColorArray {
    return this._aqiIndexColor;
  }
  public set aqiIndexColor(v: AqiIndexColorArray) {
    let changed = JSON.stringify(this._aqiIndexColor) !== JSON.stringify(v);
    this._aqiIndexColor = v;
    if (changed) this.widgetDataUpdateSubject.next('aqiIndexColor');
  }

  private _fields: DeviceField[] = [];
  public get fields(): DeviceField[] {
    return this._fields;
  }
  public set fields(v: DeviceField[]) {
    let changed = JSON.stringify(this._fields) !== JSON.stringify(v);
    this._fields = v;
    if (changed) this.widgetDataUpdateSubject.next('fields');
  }

  private _limits: FieldLimit[] = [];
  public get limits(): FieldLimit[] {
    return this._limits;
  }
  public set limits(v: FieldLimit[]) {
    let changed = JSON.stringify(this._limits) !== JSON.stringify(v);
    this._limits = v;
    if (changed) this.widgetDataUpdateSubject.next('limits');
  }

  public redrawWidget(widgetId: number) {
    this.redrawWidgetSubject.next(widgetId);
  }

  constructor() {}
}
