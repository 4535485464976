export class AlertConstant {
  public static timeInterval = [
    {
      label: '1 hour',
      value: 'hr',
    },
    {
      label: '8 hour',
      value: '8hr',
    },
    {
      label: '24 hour',
      value: 'daily',
    },
    {
      label: 'Raw',
      value: 'raw',
    },
  ];

  public static conditionalStatements = [
    {
      label: 'Greater than or Equal to',
      value: '>',
    },
    {
      label: 'Less Than',
      value: '<',
    },
  ];

  public static hourForDisplay = {
    hr: '1 hour',
    daily: '24 hours',
    '8hr': '8 hours',
    raw: 'Raw',
  };
}
