import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { DeviceType } from '../../models/device-type/device-type';
import { DeviceDetails } from '../../models/device/device-details';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-tag-and-devicetype-dropwdown',
  templateUrl: './tag-and-devicetype-dropwdown.component.html',
  styleUrls: ['./tag-and-devicetype-dropwdown.component.scss'],
})
export class TagAndDevicetypeDropwdownComponent {
  @Input() isMultipleDeviceSelection: boolean = false; //Is multiple Device Selection or not
  @Input() deviceTypes!: DeviceType[]; // List of all available device types
  @Input() devices!: DeviceDetails[]; // List of all devices
  @Input() patchDevice!: DeviceDetails | DeviceDetails[] | undefined; // Device to be patched in the form
  @Input() tags!: string[]; // List of all available tags
  @Input() isMultipleTag: boolean = true; // Indicates if multiple tag selection is allowed
  @Input() infoMessage: string = '';
  //below emitter emits object of device or array of object of device
  @Output() selectedDevice: EventEmitter<any> = new EventEmitter(); // Emits the selected device
  @Output() filteredDeviceList: EventEmitter<DeviceDetails[]> =
    new EventEmitter(); // Emits filtered list of devices based on tag selection
  @Input() showButton: boolean = true;

  public inputForm!: FormGroup;
  public copyOfDevices!: DeviceDetails[];
  public subscriptions: Subscription[] = [];
  public listOfDeviceIds: string[] = [];

  constructor(
    private deviceService: DeviceService,
    private formBuilder: FormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.copyOfDevices = this.devices;
    if (this.isMultipleDeviceSelection) {
      this.listOfDeviceIds = this.devices.map((res) => res.deviceId);
    }
  }

  get paramsControl(): FormControl {
    return this.inputForm.get('deviceSearch') as FormControl;
  }

  //if user click direcly on the device pins on the map then listen to the changes
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patchDevice']) {
      if (changes['patchDevice'].currentValue) {
        if (typeof changes['patchDevice'].currentValue?.deviceId === 'string') {
          const device = this.devices.find(
            (device) =>
              device.deviceId === changes['patchDevice'].currentValue.deviceId
          );
          if (device) {
            this.inputForm.patchValue({
              deviceSearch: device,
            });
          }
        } else {
          const devices = this.devices
            .filter((device) =>
              changes['patchDevice'].currentValue.some(
                (d: DeviceDetails) => (d.deviceId = device.deviceId)
              )
            )
            .map((dd) => dd.deviceId);
          this.inputForm.patchValue({
            deviceSearch: devices,
          });
        }
      }
      //means user has deselected the device
      else {
        this.inputForm.patchValue({
          deviceSearch: '',
        });
      }
    }
  }

  buildForm() {
    this.inputForm = this.formBuilder.group({
      deviceSearch: '',
      tag: '',
    });
    this.addSubscriptions();
  }

  addSubscriptions() {
    const tagSubs = this.inputForm.get('tag')?.valueChanges.subscribe((res) => {
      this.devices = this.getDevicesWithTag(res);
      //emit the devices which contains the selected tags
      this.filteredDeviceList.next(this.devices);

      //if multiple device selection than emit the device list on tag selection
      if (this.isMultipleDeviceSelection) {
        this.selectedDevice.next(this.devices);
      }
    });

    const deviceSubs = this.inputForm
      .get('deviceSearch')
      ?.valueChanges.subscribe((device) => {
        //when user selects device from the dropdown

        //if multiple device ids than send array of deviceDetails
        if (this.isMultipleDeviceSelection) {
          this.selectedDevice.next(
            this.devices.filter((d) => device.includes(d.deviceId))
          );
        }
        //if single device id than send deviceDetails
        else {
          this.selectedDevice.next(device);
        }
      });

    if (tagSubs) this.subscriptions.push(tagSubs);
    if (deviceSubs) this.subscriptions.push(deviceSubs);
  }

  getDevicesWithTag(tags: string[]): DeviceDetails[] {
    if (!tags.length) {
      return this.copyOfDevices; //if no tag selected than return all devices
    }
    return this.copyOfDevices.filter((device) =>
      device?.options?.deviceTags?.some((tag) => tags.includes(tag))
    );
  }

  clearDeviceName(): void {
    this.inputForm.controls.deviceSearch.setValue(undefined);
    this.deviceService.removeDeviceGlobally();
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
