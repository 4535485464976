<app-terminal-page-layout>
  <app-terminal-page-header [configureButton]="true">
    <div class="row h-100 justify-content-end gy-2 gy-lg-2 mx-2">
      <div
        class="col-lg-auto col-12 d-flex justify-content-center align-items-center"
      >
        <div class="col-lg-12 d-flex flex-direction-row gap-2">
          <span class="h6 archived">Archived</span>
          <mat-slide-toggle
            class="pt-2"
            color="primary"
            (change)="onToggleChange($event)"
            [matTooltip]="
              showArchived ? 'View Archived Projects' : 'View Active Projects'
            "
          ></mat-slide-toggle>
          <ng-container *ngIf="user.topLevel">
            <span class="h6 archived">All Organizations</span>
            <mat-slide-toggle
              [checked]="showAllOrg"
              color="primary"
              class="pt-2"
              (change)="getAllOrg($event)"
              [matTooltip]="showAllOrg ? 'View All Master Org' : 'View All Org'"
            ></mat-slide-toggle>
          </ng-container>
        </div>
      </div>
      <div class="col-lg-auto col-12">
        <button
          *ngIf="getProjectModuleOptions('add')"
          mat-raised-button
          color="primary"
          class="pt-1 w-100"
          (click)="addUser()"
          [disabled]="
            isProjectEditRoute
              ? isProjectEditRoute
              : !(
                  (projectsService.masterOrgInfo?.max_seats ?? 0) -
                    (projectsService.masterOrgInfo?.used_seats ?? 0) >
                  0
                )
          "
          matTooltip="Add New Project"
        >
          Add New Project
          <mat-icon class="material-symbols-outlined">add</mat-icon>
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
