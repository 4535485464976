import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { SubUsersComponent } from './pages/sub-users/sub-users.component';
import { IndexManagementComponent } from './pages/index-management/index-management.component';
import { UnitsManagementComponent } from './pages/units-management/units-management.component';
import { ModulesComponent } from './pages/modules/modules.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './pages/profile/profile.component';
import { PasswordFormComponent } from './pages/profile/components/password-form/password-form.component';
import { ProfileEditFormComponent } from './pages/profile/components/profile-edit-form/profile-edit-form.component';
import {
  MatProgressSpinnerModule,
  MatSpinner,
} from '@angular/material/progress-spinner';
import { SubUserFormComponent } from './pages/sub-users/components/sub-user-form/sub-user-form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { LimitFormComponent } from './pages/units-management/components/limit-form/limit-form.component';

@NgModule({
  declarations: [
    UserComponent,
    SubUsersComponent,
    IndexManagementComponent,
    UnitsManagementComponent,
    ModulesComponent,
    ProfileComponent,
    PasswordFormComponent,
    ProfileEditFormComponent,
    SubUserFormComponent,
    LimitFormComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatDividerModule,
  ],
})
export class UserModule {}
