import { Pipe, PipeTransform } from '@angular/core';
import { DeviceConstants } from '../constants/device-constants';
import { DeviceField } from '../models/device/device-field';
import { FieldLimit } from '../models/device/field-limit';
import { CommonService } from '../services/common.service';
import { DeviceService } from '../services/device.service';
import { DeviceUtil } from '../utils/device-utils';

@Pipe({
  name: 'gasLevel',
})
export class GasLevelPipe implements PipeTransform {
  limits: FieldLimit[] = [];

  constructor(
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {}

  transform(
    items: any,
    externalFields?: DeviceField[],
    externalLimits?: FieldLimit[],
    deviceType?: string | number
  ): {
    name: string;
    value: any;
    label: string;
    unit: string;
    progress: number;
    color: any;
  }[] {
    let fieldsDetails = [];
    if (!externalLimits) {
      this.limits = this.deviceService.limits;
    } else {
      this.limits = externalLimits!;
    }

    if (!externalFields) {
      externalFields = this.deviceService.fields;
    }

    let externalFieldsObj = externalFields
      ? Object.fromEntries(
          externalFields.map((field: DeviceField) => [field.fkey, field])
        )
      : {};

    let allFields = Object.keys(items);
    if (items instanceof Array) {
      allFields = items;
    }

    let headerFields = DeviceConstants.HEADER_FIELDS;
    for (let key of allFields) {
      if (!headerFields.includes(key)) {
        headerFields.push(key);
      }
    }

    for (let key of headerFields) {
      if (
        key !== 't' &&
        allFields.includes(key) &&
        externalFieldsObj?.[key]?.isVisible
      ) {
        fieldsDetails.push({
          name: key,
          value: items[key],
          label: this.deviceService.getFieldName(key, externalFields),
          unit: this.deviceService.getFieldUnit(key, externalFields),
          progress: this.provideLimitToProgressbar(key, items[key]),
          color: this.getParameterColor(key, items[key], deviceType),
        });
      }
    }

    return fieldsDetails;
  }

  provideLimitToProgressbar(field: string, value: number): number {
    if (!this.limits?.length) {
      return 0;
    }

    let ri: number = this.limits
      .map((x: any) => {
        return x?.fkey;
      })
      .indexOf(field);
    if (ri < 0) {
      return 0;
    }

    let max: number = this.limits[ri].highestLimit;
    if (field === 'g1') {
      const l_limit = 400;
      return value >= max ? 1 : (value - l_limit) / max;
    }

    return value >= max ? 1 : value / max;
  }

  getParameterColor(
    field: string,
    value: number,
    deviceType?: string | number
  ) {
    let parameterColor = DeviceUtil.getParamColor(
      DeviceUtil.getLimitClass(field, value, this.deviceService.limits),
      this.commonService.getAllDeviceTypes(),
      this.commonService.getAllAQI(),
      this.commonService.getAllAQIs(),
      undefined,
      deviceType
    );
    return parameterColor;
  }
}
