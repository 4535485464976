<app-terminal-page-layout [menu]="[]">
  <app-terminal-page-header [menu]="[]" [configureButton]="false">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-2">
        <mat-label>Device Type</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Device Type'"
          matInput
          formControlName="deviceType"
          [matAutocomplete]="deviceType"
        />
        <mat-autocomplete
          #deviceType="matAutocomplete"
          [displayWith]="getDeviceType"
        >
          <mat-option
            *ngFor="
              let deviceType of deviceTypes
                | search : headerForm.get('deviceType')?.value : 'label'
            "
            [value]="deviceType"
          >
            {{ deviceType.label }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          matSuffix
          mat-icon-button
          tabindex="-1"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
