import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, concatMap, map, Subject } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AverageHour } from '../models/average-hour';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDataResponse } from '../models/device/device-data';
import { DeviceDetails } from '../models/device/device-details';
import { SelectionTile } from '../models/internal-use-front-end/selection-tile';
import { Widget } from '../models/widget/widget';
import { sortList } from '../utils/array-utils';
import { CommonUtil } from '../utils/common-utils';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';

type FetchDataArgs = {
  device:
    | {
        deviceId: string;
        deviceType?: string;
      }
    | DeviceDetails;
  startDate?: {
    moment?: moment.Moment;
    epoch?: number;
    epochMS?: number;
  };
  endDate?: {
    moment?: moment.Moment;
    epoch?: number;
    epochMS?: number;
  };
  average?: AverageHour;
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseAPIService<DeviceDetails> {
  private widgetsViewEditMode = new BehaviorSubject<string>('closed');
  public widgetsViewEditMode$ = this.widgetsViewEditMode.asObservable();

  public uiLoaded = new Subject<boolean>();
  public uiLoaded$ = this.uiLoaded.asObservable();

  private newWidgetsAdded = new Subject<SelectionTile[]>();
  public newWidgetsAdded$ = this.newWidgetsAdded.asObservable();

  public dashboardTourStatus = new Subject<boolean>();
  public dashboardTourStatus$ = this.dashboardTourStatus.asObservable();

  public resizeWidget = new Subject<boolean>();
  public resizeWidget$ = this.resizeWidget.asObservable();

  public swapWidgets = new Subject<boolean>();
  public swapWidgets$ = this.swapWidgets.asObservable();

  public tableFilters: any;
  private tableFiltersUpdated = new Subject<any>();
  public tableFiltersUpdated$ = this.tableFiltersUpdated.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private userService: UserService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
  }

  public fetchTableData({
    device,
    startDate,
    endDate,
    average,
  }: FetchDataArgs) {
    return this.fetchData({ device, startDate, endDate, average });
  }

  public fetchWidgets() {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Widget[]>(APIConstants.fetchWidgets(), headers);
  }

  public fetchLiveData({ device, startDate, endDate, average }: FetchDataArgs) {
    endDate = {
      moment: this.customMomentService.moment(),
    };
    startDate = {
      moment: endDate.moment?.clone(),
    };
    startDate.moment?.subtract(2, 'hour');
    return this.fetchData({
      device,
      startDate,
      endDate,
      average,
    }).pipe(
      map((deviceDetails: DeviceDetails[]) => {
        deviceDetails = deviceDetails?.slice(0, 30);
        deviceDetails = sortList(deviceDetails, 'ASC', 'payload.d.t');
        return deviceDetails;
      })
    );
  }

  public fetchHourlyData({
    device,
    startDate,
    endDate,
    average,
  }: FetchDataArgs) {
    endDate = {
      moment: this.customMomentService.moment().startOf('hour'),
    };
    startDate = {
      moment: endDate.moment?.clone(),
    };
    startDate.moment?.subtract(1, 'day');
    return this.fetchData({ device, startDate, endDate, average });
  }

  public fetchAqiTrendData({
    device,
    startDate,
    endDate,
    average,
  }: FetchDataArgs) {
    endDate = {
      moment: this.customMomentService.moment().startOf('hour'),
    };
    startDate = {
      moment: endDate.moment?.clone().startOf('day'),
    };
    startDate.moment?.subtract(6, 'day');
    return this.fetchData({ device, startDate, endDate, average });
  }

  public fetchPredictionData({ device }: FetchDataArgs) {
    if (
      !device?.deviceId?.length ||
      !(
        typeof device?.deviceType === 'number' ||
        (typeof device?.deviceType === 'string' && device?.deviceType?.length)
      )
    ) {
      let temp = new Subject<DeviceDetails[]>();
      setTimeout(() => {
        temp.next([]);
        temp.complete();
      }, 500);
      return temp.asObservable();
    }

    if (typeof device?.deviceType === 'number') {
      let deviceTypes = this.commonService.getUserDeviceTypes();
      device.deviceType = DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
        deviceTypes,
        device?.deviceType
      );
    }

    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let timezone = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.settings?.timezone?.name;

    if (!timezone?.length) {
      timezone = CommonUtil.getDefaultTimezone();
    }

    let payload = {
      deviceId: device.deviceId, //payload.get("deviceId")
      deviceType: device.deviceType, //payload.get("deviceType")
      timestamp: this.customMomentService.moment().startOf('hour').unix(), //payload.get("timestamp")
      timezone, //payload.get("timezone")
    };

    return this.post<Array<DeviceDataResponse>>(
      APIConstants.HOURLY_DATA_PREDICTION,
      payload,
      headers
    ).pipe(
      map((deviceDetailRes: Array<any>) => {
        deviceDetailRes = deviceDetailRes.map((dp) => {
          let d = dp.d;
          delete dp.d;
          return {
            ...dp,
            payload: { d },
          };
        });
        deviceDetailRes = [
          { deviceId: deviceDetailRes[0].deviceId, payload: deviceDetailRes },
        ];
        return deviceDetailRes;
      }),
      map((deviceDetailRes: Array<DeviceDataResponse>) => {
        let deviceDetails: any = deviceDetailRes[0].payload;
        deviceDetails = this.convertUnitsAndCalculateAqi(deviceDetails, true);
        return deviceDetails;
      }),
      catchError((error) => {
        return [];
      })
    );
  }

  private fetchData({ device, startDate, endDate, average }: FetchDataArgs) {
    if (
      !device?.deviceId?.length ||
      !average?.id?.length ||
      !(
        (startDate?.epoch || startDate?.epochMS || startDate?.moment) &&
        (endDate?.epoch || endDate?.epochMS || endDate?.moment)
      )
    ) {
      let temp = new Subject<DeviceDetails[]>();
      setTimeout(() => {
        temp.next([]);
        temp.complete();
      }, 500);
      return temp.asObservable();
    }

    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const isMovingAverage = average.isMoving;
    let avg: number | undefined = average.value;
    if (!avg) {
      avg = undefined;
    }

    let gteMoment = startDate.moment;
    if (!gteMoment) {
      gteMoment = startDate.epoch
        ? this.customMomentService.moment.unix(startDate.epoch)
        : this.customMomentService.moment(startDate.epochMS);
    }
    let gte = gteMoment.unix();

    let lteMoment = endDate.moment;
    if (!lteMoment) {
      lteMoment = endDate.epoch
        ? this.customMomentService.moment.unix(endDate.epoch)
        : this.customMomentService.moment(endDate.epochMS);
    }
    let lte = lteMoment.endOf('day').unix();

    if (this.customMomentService.moment().endOf('day').unix() === lte) {
      lte = this.customMomentService.moment().unix();
    }
    lte = CommonUtil.modifyLteTimestamp(
      gte,
      lte,
      isMovingAverage ? 3600 : avg ?? 0
    );

    let p: { [key: string]: any } = {
      'deviceIds[]': device.deviceId,
      userId: userId,
      gte,
      lte,
      processType:
        average.value === 0 ? 'raw' : isMovingAverage ? 'moving-avg' : 'avg',
    };

    if (avg) {
      p.avg = avg;
    }

    const params: HttpParams = new HttpParams().appendAll(p);

    return this.get<Array<DeviceDataResponse>>(
      APIConstants.LAST_24_HOURS_DEVICE_AVG,
      headers,
      params
    ).pipe(
      map((deviceDetailRes: Array<DeviceDataResponse>) => {
        let deviceDetails: any = deviceDetailRes[0].payload;
        deviceDetails = this.convertUnitsAndCalculateAqi(
          deviceDetails,
          !!average?.value
        );
        return deviceDetails;
      }),
      catchError((error) => {
        return [];
      })
    );
  }

  private convertUnitsAndCalculateAqi(
    deviceDetails: DeviceDetails[],
    calculateAQI: boolean = true
  ): DeviceDetails[] {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const units: any =
      this.commonService.getAllUnits()[
        this.deviceService.currentDeviceType.deviceTypeId
      ];

    deviceDetails.forEach((deviceDetail) => {
      if (DeviceUtil.hasValidIndexForDeviceType(deviceDetail, deviceTypes)) {
        const aqiIndex = DeviceUtil.calculateAQI(
          deviceDetail.payload,
          DeviceUtil.getBreakpoints(
            undefined,
            deviceDetail.deviceType,
            deviceTypes,
            allAqi,
            allAqis,
            aqi
          )
        );

        if (calculateAQI && deviceDetail.payload !== undefined) {
          deviceDetail.aqi = aqiIndex.aqi;
          deviceDetail.aqiKey = aqiIndex.aqiKey;
          if (aqiIndex.aqi !== null && aqiIndex.aqi !== undefined) {
            deviceDetail.payload.d['aqi'] = aqiIndex.aqi;
            deviceDetail.payload.d['aqiMessage'] = aqiIndex.aqiMessage;
          }
        }
      }
      deviceDetail.payload = DeviceUtil.convertUnits(
        deviceDetail.payload,
        units!
      );
    });

    return deviceDetails;
  }

  public saveWidgetsConfig(widgetsConfig: any) {
    let userSettings = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).settings;
    if (!userSettings) {
      userSettings = {};
    }
    userSettings = { ...userSettings, widgetsConfig };
    return this.userService
      .updateProfile({ update: { settings: userSettings } })
      .pipe(
        concatMap((res) => {
          return this.userService.getUserProfile();
        })
      );
  }

  public addNewWidgets(newWidgets: any) {
    this.newWidgetsAdded.next(newWidgets);
  }

  public updateTableFilters(filters: any) {
    this.tableFilters = filters;
    this.tableFiltersUpdated.next(filters);
  }

  enableDisableWidgetsViewEditMode(action: string) {
    this.widgetsViewEditMode.next(action);
  }
}
