import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LockScreen } from 'src/app/shared/models/internal-use-front-end/lock-screen-data';
import { EmailService } from 'src/app/shared/services/email.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-module-lock-screen',
  templateUrl: './module-lock-screen.component.html',
  styleUrls: ['./module-lock-screen.component.scss'],
})
export class ModuleLockScreenComponent {
  constructor(
    private router: Router,
    private location: Location,
    private localStorageService: LocalStorageService,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private emailService: EmailService
  ) {}

  moduleId: number = -1;
  moduleName: string = '';
  public subscriptions: Subscription[] = [];
  public testImage: string = AppConstants.CLUSTER_LS;
  public lockScreenData: LockScreen.Details = AppConstants.LOCK_SCREEN_DATA;
  public currentModuleDetails!: LockScreen.Data;
  public showDetails: boolean = false;
  public subMessage: string =
    'This module is not included in your subscription. Please contact us to access the module.';
  public toolTipMessage: string = 'Copy to clipboard';
  public primaryContact: string = '+91 9856217855';
  public secondaryContact: string = '+91 9054357457';
  public emailAddress: string = 'hello@oizom.com';
  ngOnInit() {
    //to get moduleid and modulename
    if (this.location.getState()) {
      let stateInfo: any = this.location.getState();
      this.currentModuleDetails = this.lockScreenData[stateInfo.moduleId];
      this.showDetails = true;
    }

    //to listen to route change every time user goes from one locked module to another locked module
    let router = this.router.events.subscribe({
      next: (eve) => {
        if (eve instanceof NavigationStart) {
          this.showDetails = false;
          let moduleDetails = CommonUtil.getModuleIdByUrl(eve.url);
          this.currentModuleDetails =
            this.lockScreenData[moduleDetails.moduleId];
          setTimeout(() => {
            this.showDetails = true;
          }, 1500);
        }
        return true;
      },
    });
    this.subscriptions.push(router);
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this.notificationService.showSnackBar('Copied to clipboard','info');
  }

  sendEmail() {
    const to = 'contact@oizom.com';
    const cc = 'hello@oizom.com';
    const bcc = '';
    const subject = 'Module Access';
    const body =
      'Hello Oizom Team,\n \nWe want to have a demo and procure the Oizom' +
      'Module for our account.\n\nPlease let us know how we can avail it.';

    const gmailUrl = this.emailService.generateEmailUrl(
      to,
      cc,
      subject,
      body,
      bcc
    );
    window.open(gmailUrl, '_blank');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
