import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationPopup } from 'src/app/shared/models/confirmation-popup';
@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent {
  defaultDialogConfig: {} | undefined;
  checkBox!: FormControl;
  isChecked: boolean = true;
  @Output() checkBoxTrigger = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogConfig: ConfirmationPopup
  ) {}

  ngOnInit() {
    this.checkBox = new FormControl(true);
    this.checkBox.valueChanges.subscribe({
      next: (res) => {
        this.isChecked = res;
        this.checkBoxTrigger.emit(res);
      },
      error: (err) => {
        console.info("Error:", err);
      },
    });
  }

  handlebuttonclick(eve: any, onClickCallback: any, closeDialog: boolean) {
    if (onClickCallback) {
      onClickCallback(eve);
    }
    if (closeDialog) {
      this.dialogRef.close('yes');
    }
  }
}
