import { Pipe, PipeTransform } from '@angular/core';
import { AnalyticsInternal } from '../models/internal-use-front-end/analytics-data';
import { CommonService } from '../services/common.service';
import { DeviceService } from '../services/device.service';
import { DeviceUtil } from '../utils/device-utils';

@Pipe({
  name: 'deviceTypeWiseAnalytic',
})
export class DeviceTypeWiseAnalyticPipe implements PipeTransform {
  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService
  ) {}
  transform(
    analyticsList: AnalyticsInternal[],
    deviceTypeId: number
  ): AnalyticsInternal[] | [] {
    let filteredAnalyticList = new Set<any>();
    analyticsList.forEach((analytic: AnalyticsInternal) => {
      analytic.deviceId.forEach((deviceId: string) => {
        const dts = this.commonService.getUserDeviceTypes();
        const dvcs = this.deviceService.registeredDevices!;
        const deviceType = DeviceUtil.getDeviceTypeIdByDeviceId(
          dts,
          dvcs,
          deviceId
        );
        if (deviceType === deviceTypeId) {
          filteredAnalyticList.add(analytic);
        }
      });
    });
    return Array.from(filteredAnalyticList.values());
  }
}
