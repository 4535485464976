<div class="m-3">
  <span class="h5"> Edit & Update project</span>
</div>
<div class="m-3 mt-0 mb-0">
  <div class="row">
    <div class="col-12 gx-0 d-flex justify-content-end">
      <button mat-icon-button matTooltip="Refresh" (click)="refreshOrg()">
        <mat-icon class="material-symbols-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Close" (click)="closeEditOrg()">
        <mat-icon class="material-symbols-outlined">cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf="loadTable | async">
  <div class="w-100 p-2">
    <div class="table-border mb-3">
      <app-data-table
        class="edit-table"
        tableTitle="Project Admin"
        [data]="adminUserData"
        [columns]="defaultColumns"
        [displayedColumns]="displayedColumnsAdmin"
        [showDeleteInHeader]="false"
        [showDownloadInHeader]="false"
        [showFilterInHeader]="false"
        [showSearchInHeader]="false"
        [showPaginator]="false"
        style="padding-bottom: 30px; padding-left: 20px; padding-right: 20px"
      >
      </app-data-table>
    </div>
    <div *ngIf="nonAdminUserData.length" class="table-border">
      <app-data-table
        class="edit-table"
        tableTitle="Project Users"
        [data]="nonAdminUserData"
        [columns]="defaultColumns"
        [displayedColumns]="displayedColumnsAdmin"
        [showDeleteInHeader]="false"
        [showDownloadInHeader]="false"
        [showFilterInHeader]="false"
        [showSearchInHeader]="false"
        [showPaginator]="false"
        style="padding-bottom: 30px; padding-left: 20px; padding-right: 20px"
      >
      </app-data-table>
    </div>
  </div>
</ng-container>

<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
