import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComplainResolver } from 'src/app/shared/resolvers/complain.resolver';
import { ComplainComponent } from './complain.component';
import { ListComponent } from './pages/list/list.component';
import { MapComponent } from './pages/map/map.component';

const routes: Routes = [
  {
    path: '',
    component: ComplainComponent,
    resolve: { complainDataLoaded: () => inject(ComplainResolver).resolve() },

    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: ListComponent,
      },
      {
        path: 'map',
        component: MapComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComplainRoutingModule {}
