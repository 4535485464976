import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AlertData } from '../models/alerts/alert-data';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsService extends BaseAPIService<any> {
  public allAlerts!: AlertData.Get[];
  public currentDeviceType!: DeviceType;
  public dataAlerts!: AlertData.DataAlert[];

  public datesForDataAlerts = new BehaviorSubject<any>({});
  datesForDataAlerts$ = this.datesForDataAlerts.asObservable();

  public selectedDeviceType = new BehaviorSubject<DeviceType>(
    this.currentDeviceType
  );
  selectedDeviceType$ = this.selectedDeviceType.asObservable();

  public alertsList = new BehaviorSubject<AlertData.Get[] | null>(null);
  alertsList$ = this.alertsList.asObservable();

  public isResponse = new BehaviorSubject<boolean>(false);
  isResponse$ = this.isResponse.asObservable();

  public loadDataAlerts = new BehaviorSubject<boolean>(false);
  loadDataAlerts$ = this.loadDataAlerts.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
  }

  getAlertList() {
    this.isResponse.next(false);
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<AlertData.Get>>(
      APIConstants.GET_ALERT_LIST(userId),
      headers
    ).pipe(
      tap((res) => {
        this.allAlerts = res;

        this.allAlerts.forEach((alert: AlertData.Get) => {
          const tempDeviceLabels: string[] = [];
          const devices = alert.deviceIds;
          devices.forEach((device: string) => {
            tempDeviceLabels.push(
              DeviceUtil.getDeviceLabel(
                this.deviceService.registeredDevices!,
                device
              )
            );
          });
          alert.deviceIds = tempDeviceLabels;
        });
      })
    );
  }

  getSelectedDeviceType(deviceType: DeviceType) {
    if (deviceType) {
      this.currentDeviceType = deviceType;
      let filteredAlertList = this.allAlerts.filter(
        (alert) => alert.deviceTypeId === this.currentDeviceType.deviceTypeId
      );
      this.selectedDeviceType.next(this.currentDeviceType);
      this.alertsList.next(filteredAlertList);
    }
  }

  addAlert(payload: AlertData.Post) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<string>(
      APIConstants.ADD_ALERT.replace('{userId}', userId),
      payload,
      headers
    );
  }

  updateAlert(payload: AlertData.Patch, alertId: number) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<string>(
      APIConstants.UPDATE_ALERT.replace('{userId}', userId).replace(
        '{alertId}',
        String(alertId)
      ),
      payload,
      headers
    );
  }

  deleteAlert(alertId: number) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.delete<AlertData.Patch>(
      APIConstants.UPDATE_ALERT.replace('{userId}', userId).replace(
        '{alertId}',
        String(alertId)
      ),
      headers
    );
  }

  //to migrate existing alerts from terminal into Envizom
  migrateTerminalAlerts(payload: AlertData.Migrate) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<any>(
      APIConstants.MIGRATE_ALERT.replace('{userId}', userId),
      payload,
      headers
    );
  }

  getDataAlerts(gte?: number, lte?: number): Observable<any> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    let params: HttpParams = new HttpParams();
    let defaultStartDate;
    let defaultEndDate;
    if (!gte && !lte) {
      defaultStartDate = this.customMomentService
        ?.moment()
        ?.subtract(1, 'day')
        ?.startOf('day')
        .unix();
      defaultEndDate = this.customMomentService?.moment()?.endOf('day').unix();
    } else {
      defaultStartDate = gte;
      defaultEndDate = lte;
    }
    params = params.append('gte', defaultStartDate!);
    params = params.append('lte', defaultEndDate!);

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.get<any>(
      APIConstants.USER_NOTIFICATIONS.replace('{userId}', userId),
      headers,
      params
    ).pipe(
      map((res: AlertData.DataAlert[]) => {
        this.dataAlerts = res;
        return this.dataAlerts;
      })
    );
  }

  setIsResponse() {
    this.isResponse.next(false);
  }
}
