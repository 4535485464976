import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AverageHour } from '../models/average-hour';
import { CountryWisePhoneCode } from '../models/internal-use-front-end/country-wise-ph-code';
import { LockScreen } from '../models/internal-use-front-end/lock-screen-data';
import { sortList } from '../utils/array-utils';
import { environment } from 'src/environments/environment';

export class AppConstants {
  public static CLOSE: string = 'Close';
  public static UNSOLVED_CAPTCHA_MESSAGE: string =
    'Please solve Captcha to continue!';
  public static CONFIG_ERROR_MESSAGE: string =
    'Configuration Error, Contact Developers!';
  public static PLEASE_TRY_AGAIN_MESSAGE: string = 'Please try again !';
  public static APIS: string = 'DBURL';
  public static API_VERSION: string = 'DBURL_VERSION';
  public static WEB_SOCKET: string = 'WSURL';
  public static ORG_URL: string = 'ORG_URL';
  public static M_URL: string = 'M_URL';
  public static FAVICON: string = 'FAVICON';
  public static LOCAL: string = 'LOCAL';
  public static THEME: string = 'THEME';
  public static LOGO_URL: string = 'LOGO_URL';
  public static LOGO_URL_WEB: string = 'LOGO_URL_WEB';
  public static WEB_URL: string = 'WEB_URL';
  public static APP_TITLE: string = 'APP_TITLE';
  public static PARTICLEAPI: string = 'PARTICLEAPI';
  public static PARTICLETOKEN: string = 'PARTICLETOKEN';
  public static ENCRYPTION_KEY: string = 'ENCRYPTION_KEY';
  public static ENVIZOM: string = 'Envizom';
  public static AUTHORIZATION_HEADER: string = 'x-access-token';
  public static BEARER: string = 'Bearer';
  public static IBM_CLIENT: string = 'x-ibm-client-id';
  public static RAW_AQI_LABEL: string = 'RAQI';
  public static DEFAULT_APP_COLOR: string = '#3db79c';
  public static SHOW_RAW_DATA_AQI: boolean = false;
  public static SVG_PREFIX: string = 'data:image/svg+xml;charset=UTF-8,';
  public static DEFAULT_DATE_FORMAT: string = 'DD/MM/YY';
  public static DEFAULT_THEME: string = environment?.config?.USE_THEAM ?? 'material-dark' ;
  public static ALERT_GREATER_THAN_EQUAL_TO: string =
    ' : value greater or equal than limit.  The latest reading is: ';
  public static ALERT_LESS_THAN: string =
    ' : value less than limit.  The latest reading is: ';
  public static EMPTY_REPORT_LIST: string =
    'assets/images/reports/noreports.svg';
  public static EMPTY_ANALYTICS_LIST: string =
    'assets/images/analytics/no-analytics.svg';
  public static NO_DATA: string = 'assets/images/common/no-data.svg';
  public static RELAY_NOT_AVAILABLE: string =
    'assets/images/common/relay-not-available.svg';
  public static QUERIED_DATA_NOT_FOUND =
    'assets/images/common/queried-data-not-found.svg';
  public static DELETE_SUCCESSFUL: string =
    'assets/images/common/Animation - 1726811843513.gif';
  public static LOADING: string =
    'assets/images/common/Animation - 1726811669156.gif';
  public static CLUSTER_LS: string =
    'assets/images/module-lock-screen/cluster.png';

  public static DARK_MODE_HEADER_LOGO: string =
    'assets/images/header-logo/Dark-Mode.png';
  public static DEFAULT_MODE_HEADER_LOGO: string =
    'assets/images/header-logo/Default-Mode.png';

  public static NOTIFICATION_TOPICS: Record<string, string> = {
    'header-notification': 'header-notification',
  };

  public static POPUP_TIMEOUT: number = 2000;
  public static OZ_WIDGET_IDS = new Map<number, Array<number>>([
    [1001, [1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009]],
    [1002, [1001, 1002, 1003, 1004, 1005, 1007]],
    [1003, [1001, 1002, 1003, 1004]],
    [1004, [1001, 1002, 1003, 1004]],
    [1005, [1001, 1002, 1003, 1004]],
    [1006, [1001, 1002, 1003, 1004]],
  ]);

  public static SNACKBAR_CONFIG = {
    actionLabel: 'Close',
    verticalPosition: 'bottom' as MatSnackBarVerticalPosition,
    horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
    duration: 4000,
  };

  public static PHONE_NUMBER_REGEX: RegExp = /^\+\d{7,}$/;
  public static LOCATION_ADDRESS_REGEX: RegExp = /^[a-zA-Z0-9\s,.'/-]+$/;
  public static NAME_REGEX: RegExp =
    /^(?=.*[a-zA-Z])(?!.*\s{2,})(?=.*\S.*\S)[a-zA-Z\s]*$/; //minimum two non space characters, minimum length 2, only alphabets and spaces allowed
  public static USERNAME_REGEX: RegExp = /^[a-zA-Z0-9\s]+$/; //it can contain spaces, alphabets and numbers as well, min length not required
  public static NUMBER_REGEX: RegExp = /^[0-9]+$/; //only nuumber characters allowed
  public static NUMBER_REGEX_WITH_DECIMAL: RegExp = /^[0-9]+(\.[0-9]+)?$/;
  public static NEGATIVE_POSITIVE_NUMBER_REGEX: RegExp = /^-?\d+$/; //only positive and negative number allowed
  public static NEGATIVE_POSITIVE_NUMBER_REGEX_WITH_DECIMAL: RegExp =
    /^-?\d+(\.\d+)?$/;
  public static NUM_ALPH_REGEX: RegExp = /^[a-zA-Z0-9]+$/;
  public static URL_REGEX: RegExp =
    /^(https?:\/\/)?([\w.-]+)(:[0-9]+)?(\/[^\s]*)?$/i;
  public static PASSWORD_REGEX: RegExp =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&_~=(`)#^+-])([a-zA-Z0-9@$!%*?&_~=(`)#^+-]{8,})$/;

  public static OZ_MAP_PIN = new Map<number, string>([
    [
      1001,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1002,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1003,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1004,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1005,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1006,
      '<?xml version="1.0" encoding="utf-8"?><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><defs/><g id="surface1" transform="matrix(1.851794, 0, 0, 1.851794, 7.050135, 4.19298)" style=""><path style=" stroke:none;fill-rule:nonzero;fill:rgb(19.215686%,21.568627%,25.490196%);fill-opacity:1;" d="M 6.988281 18 C 6.546875 17.53125 6.101562 17.078125 5.675781 16.613281 C 4.097656 14.886719 2.628906 13.082031 1.449219 11.066406 C 0.867188 10.070312 0.355469 9.039062 0.128906 7.902344 C -0.226562 6.136719 0.164062 4.496094 1.195312 3.035156 C 2.710938 0.882812 4.847656 -0.132812 7.511719 0.015625 C 10.816406 0.199219 13.554688 2.785156 13.957031 6.003906 C 14.128906 7.390625 13.777344 8.664062 13.195312 9.898438 C 12.410156 11.566406 11.367188 13.078125 10.179688 14.488281 C 9.210938 15.640625 8.167969 16.730469 7.152344 17.851562 C 7.109375 17.898438 7.058594 17.9375 6.988281 18 Z M 6.996094 3.015625 C 4.894531 3.019531 3.203125 4.675781 3.203125 6.730469 C 3.203125 8.792969 4.910156 10.464844 7.011719 10.457031 C 9.113281 10.449219 10.8125 8.773438 10.808594 6.710938 C 10.804688 4.671875 9.09375 3.011719 6.996094 3.015625 Z M 6.996094 3.015625 "/> <path style=" stroke:none;fill-rule:nonzero;fill:rgb(20%,71.764706%,61.176471%);fill-opacity:1;" d="M 6.996094 3.015625 C 9.09375 3.011719 10.804688 4.671875 10.8125 6.710938 C 10.816406 8.773438 9.113281 10.449219 7.011719 10.457031 C 4.910156 10.464844 3.203125 8.792969 3.203125 6.730469 C 3.203125 4.675781 4.894531 3.019531 6.996094 3.015625 Z M 6.996094 3.015625 "/> </g></svg>',
    ],
  ]);

  public static OZ_MAP_PIN_SELECTED = new Map<number, string>([
    [
      1001,
      '<svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1002,
      '<svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1003,
      '<svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1004,
      '<svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1005,
      '<svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1006,
      '<?xml version="1.0" encoding="utf-8"?><svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"> <g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}"/></g><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/> <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558"/> </filter></defs><g id="surface1" transform="matrix(1, 0, 0, 1, 173.653824, 24.952179)"><path style="stroke: none; fill-rule: nonzero; fill: rgb(49, 55, 65); fill-opacity: 1;" d="M -69.489 103.284 C -70.673 102.028 -71.867 100.813 -73.01 99.565 C -77.244 94.931 -81.185 90.091 -84.351 84.682 C -85.913 82.01 -87.284 79.243 -87.893 76.193 C -88.845 71.454 -87.799 67.054 -85.033 63.134 C -80.965 57.357 -75.231 54.634 -68.083 55.031 C -59.216 55.524 -51.869 62.463 -50.791 71.097 C -50.329 74.819 -51.274 78.237 -52.835 81.548 C -54.942 86.023 -57.74 90.08 -60.925 93.864 C -63.525 96.955 -66.323 99.879 -69.049 102.887 C -69.162 103.013 -69.299 103.118 -69.489 103.284 Z M -69.468 63.08 C -75.105 63.091 -79.645 67.534 -79.645 73.048 C -79.645 78.581 -75.065 83.067 -69.425 83.048 C -63.788 83.026 -59.226 78.53 -59.237 72.994 C -59.248 67.523 -63.839 63.069 -69.468 63.08 Z M -69.468 63.08"/> <path style="stroke: none; fill-rule: nonzero; fill: rgb(51, 183, 156); fill-opacity: 1;" d="M -69.468 63.08 C -63.839 63.069 -59.248 67.523 -59.226 72.994 C -59.216 78.53 -63.788 83.026 -69.425 83.048 C -75.065 83.067 -79.645 78.581 -79.645 73.048 C -79.645 67.534 -75.105 63.091 -69.468 63.08 Z M -69.468 63.08"/> </g></svg>',
    ],
  ]);

  public static DEFAULT_DEVICE_ICON: string =
    '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>';
  public static DEFAULT_DEVICE_ICON_SELECTED: string =
    '<svg width="178" height="178" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>';

  public static OZ_CLUSTER_MAP_PIN = new Map<number, string>([
    [
      1001,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1002,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1003,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1004,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1005,
      '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 40 40" fill="none"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>',
    ],
    [
      1006,
      '<?xml version="1.0" encoding="utf-8"?><svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><defs/><g id="surface1" transform="matrix(1.851794, 0, 0, 1.851794, 7.050135, 4.19298)" style=""><path style=" stroke:none;fill-rule:nonzero;fill:rgb(19.215686%,21.568627%,25.490196%);fill-opacity:1;" d="M 6.988281 18 C 6.546875 17.53125 6.101562 17.078125 5.675781 16.613281 C 4.097656 14.886719 2.628906 13.082031 1.449219 11.066406 C 0.867188 10.070312 0.355469 9.039062 0.128906 7.902344 C -0.226562 6.136719 0.164062 4.496094 1.195312 3.035156 C 2.710938 0.882812 4.847656 -0.132812 7.511719 0.015625 C 10.816406 0.199219 13.554688 2.785156 13.957031 6.003906 C 14.128906 7.390625 13.777344 8.664062 13.195312 9.898438 C 12.410156 11.566406 11.367188 13.078125 10.179688 14.488281 C 9.210938 15.640625 8.167969 16.730469 7.152344 17.851562 C 7.109375 17.898438 7.058594 17.9375 6.988281 18 Z M 6.996094 3.015625 C 4.894531 3.019531 3.203125 4.675781 3.203125 6.730469 C 3.203125 8.792969 4.910156 10.464844 7.011719 10.457031 C 9.113281 10.449219 10.8125 8.773438 10.808594 6.710938 C 10.804688 4.671875 9.09375 3.011719 6.996094 3.015625 Z M 6.996094 3.015625 "/> <path style=" stroke:none;fill-rule:nonzero;fill:rgb(20%,71.764706%,61.176471%);fill-opacity:1;" d="M 6.996094 3.015625 C 9.09375 3.011719 10.804688 4.671875 10.8125 6.710938 C 10.816406 8.773438 9.113281 10.449219 7.011719 10.457031 C 4.910156 10.464844 3.203125 8.792969 3.203125 6.730469 C 3.203125 4.675781 4.894531 3.019531 6.996094 3.015625 Z M 6.996094 3.015625 "/> </g></svg>',
    ],
  ]);

  public static OZ_CLUSTER_MAP_PIN_SELECTED = new Map<number, string>([
    [
      1001,
      '<svg width="150" height="150" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1002,
      '<svg width="150" height="150" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1003,
      '<svg width="150" height="150" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1004,
      '<svg width="150" height="150" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1005,
      '<svg width="150" height="150" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>',
    ],
    [
      1006,
      '<?xml version="1.0" encoding="utf-8"?><svg width="150" height="150" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg"> <g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}"/></g><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/> <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558"/> </filter></defs><g id="surface1" transform="matrix(1, 0, 0, 1, 173.653824, 24.952179)"><path style="stroke: none; fill-rule: nonzero; fill: rgb(49, 55, 65); fill-opacity: 1;" d="M -69.489 103.284 C -70.673 102.028 -71.867 100.813 -73.01 99.565 C -77.244 94.931 -81.185 90.091 -84.351 84.682 C -85.913 82.01 -87.284 79.243 -87.893 76.193 C -88.845 71.454 -87.799 67.054 -85.033 63.134 C -80.965 57.357 -75.231 54.634 -68.083 55.031 C -59.216 55.524 -51.869 62.463 -50.791 71.097 C -50.329 74.819 -51.274 78.237 -52.835 81.548 C -54.942 86.023 -57.74 90.08 -60.925 93.864 C -63.525 96.955 -66.323 99.879 -69.049 102.887 C -69.162 103.013 -69.299 103.118 -69.489 103.284 Z M -69.468 63.08 C -75.105 63.091 -79.645 67.534 -79.645 73.048 C -79.645 78.581 -75.065 83.067 -69.425 83.048 C -63.788 83.026 -59.226 78.53 -59.237 72.994 C -59.248 67.523 -63.839 63.069 -69.468 63.08 Z M -69.468 63.08"/> <path style="stroke: none; fill-rule: nonzero; fill: rgb(51, 183, 156); fill-opacity: 1;" d="M -69.468 63.08 C -63.839 63.069 -59.248 67.523 -59.226 72.994 C -59.216 78.53 -63.788 83.026 -69.425 83.048 C -75.065 83.067 -79.645 78.581 -79.645 73.048 C -79.645 67.534 -75.105 63.091 -69.468 63.08 Z M -69.468 63.08"/> </g></svg>',
    ],
  ]);

  public static DEFAULT_CLUSTER_DEVICE_ICON: string =
    '<svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0007 4.19531C13.5507 4.19531 8.33398 9.41198 8.33398 15.862C8.33398 24.612 20.0007 37.5286 20.0007 37.5286C20.0007 37.5286 31.6673 24.612 31.6673 15.862C31.6673 9.41198 26.4507 4.19531 20.0007 4.19531ZM20.0007 20.0286C17.7007 20.0286 15.834 18.162 15.834 15.862C15.834 13.562 17.7007 11.6953 20.0007 11.6953C22.3007 11.6953 24.1673 13.562 24.1673 15.862C24.1673 18.162 22.3007 20.0286 20.0007 20.0286Z" fill="{{oz_color}}"/></svg>';
  public static DEFAULT_CLUSTER_DEVICE_ICON_SELECTED: string =
    '<svg width="150" height="150" viewBox="0 0 178 178" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.3" filter="url(#filter0_f_10513_670558)"><circle cx="104" cy="104" r="89" fill="{{oz_color}}" /></g><path d="M104.001 79.8335C94.6482 79.8335 87.084 87.3977 87.084 96.7502C87.084 109.438 104.001 128.167 104.001 128.167C104.001 128.167 120.917 109.438 120.917 96.7502C120.917 87.3977 113.353 79.8335 104.001 79.8335ZM104.001 102.792C100.666 102.792 97.959 100.085 97.959 96.7502C97.959 93.4152 100.666 90.7085 104.001 90.7085C107.336 90.7085 110.042 93.4152 110.042 96.7502C110.042 100.085 107.336 102.792 104.001 102.792Z" fill="{{oz_color}}" /><defs><filter id="filter0_f_10513_670558" x="0" y="0" width="208" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix" /><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" /><feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_10513_670558" /></filter></defs></svg>';

  public static APP_THEMES = [
    // {
    //     value: 'default',
    //     name: 'Light',
    // },
    // {
    //     value: 'dark',
    //     name: 'Dark',
    // },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
    // {
    //   value: 'material-light-trojena',
    //   name: 'Material Light Local Deploy'
    // }
  ];

  public static LIGHT_GOOGLE_MAPS_STYLES: google.maps.MapTypeStyle[] = [
    {
      stylers: [
        {
          hue: '#4fc9e8',
        },
        {
          saturation: -20,
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 100,
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.station.bus',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];

  public static DARK_GOOGLE_MAPS_STYLES: google.maps.MapTypeStyle[] = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#212121',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#212121',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#181818',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1b1b1b',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#2c2c2c',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8a8a8a',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#373737',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#3c3c3c',
        },
      ],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [
        {
          color: '#4e4e4e',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#3d3d3d',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.station.bus',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];

  public static DARK_MODE_GOOGLE_MAPS_CIRCLE_OPTIONS: google.maps.CircleOptions =
    {
      fillColor: '#ffffff',
      fillOpacity: 0.3,
      strokeColor: '#ffffff',
    };

  public static DARK_MODE_GOOGLE_MAPS_POLYGON_OPTIONS: google.maps.PolygonOptions =
    {
      fillColor: '#ffffff',
      fillOpacity: 0.3,
      strokeColor: '#ffffff',
    };

  public static GOOGLE_MAPS_OPTIONS: google.maps.MapOptions = {
    keyboardShortcuts: true,
    panControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControlOptions: {
      position: 2,
    },
    zoomControlOptions: {
      position: 5,
    },
    scrollwheel: true,
    minZoom: 2.5,
    maxZoom: 18,
    // mapId: '24777ba60dee2a37',
    // tilt: 45,
    styles: AppConstants.LIGHT_GOOGLE_MAPS_STYLES,
    // styles: AppConstants.LIGHT_GOOGLE_MAPS_STYLES,
  };

  public static MAP_WEATHER_LAYERS = [
    {
      shape_name: 'Wind Speed',
      layer_option: {
        layer_value: 'wind_new',
        legendMin: '0 m/s',
        legendAvg: '100 m/s',
        legendMax: '200 m/s',
        legendTitle: 'Wind Speed',
        scaleClass: 'weather-wind',
      },
    },
  ];

  public static SVG_ICONS = [
    { name: 'overview', svg: 'assets/images/modules/overview.svg' },
    { name: 'dashboard', svg: 'assets/images/modules/dashboard.svg' },
    { name: 'cluster', svg: 'assets/images/modules/cluster.svg' },
    { name: 'reports', svg: 'assets/images/modules/reports.svg' },
    { name: 'analytics', svg: 'assets/images/modules/analytics.svg' },
    { name: 'alerts', svg: 'assets/images/modules/alerts.svg' },
    { name: 'display', svg: 'assets/images/modules/display.svg' },
    { name: 'api', svg: 'assets/images/modules/api.svg' },
    { name: 'automation', svg: 'assets/images/modules/automation.svg' },
    { name: 'complain', svg: 'assets/images/modules/complaint.svg' },
    { name: 'configuration', svg: 'assets/images/modules/configuration.svg' },
    { name: 'dataFlagging', svg: 'assets/images/modules/dataFlagging.svg' },
    { name: 'devices', svg: 'assets/images/modules/devices.svg' },
    { name: 'user', svg: 'assets/images/modules/user.svg' },
    { name: 'postman_icon', svg: 'assets/images/api/postman.svg' },
    { name: 'swagger_icon', svg: 'assets/images/api/swagger.svg' },
    { name: 'cloud_icon', svg: 'assets/images/api/cloud.svg' },
    { name: 'projects', svg: 'assets/images/modules/projects.svg' },
    { name: 'vibration', svg: 'assets/images/modules/vibration.svg' },
    { name: 'heatmap', svg: 'assets/images/modules/heatmap.svg' },
  ];

  public static NOTIFICATION_LIST = [
    {
      title: 'Device Alerts',
      header: 'Device Alerts',
      name: 'device_alerts',
      reportType: 0,
    },
    {
      title: 'Data Alerts',
      header: 'Data Alerts',
      name: 'data_alerts',
      reportType: 1,
    },
    {
      title: 'General Alerts',
      header: 'General Alerts',
      name: 'generat_alerts',
      reportType: -1,
    },
  ];
  public static OZ_ROLE = {
    /** for all access */
    OZ_SUPER_ADMIN: 1,
    /** for all child admin access*/
    OZ_ADMIN: 2,
  };
  public static MODULE_DETAILS = {
    3009: 'Data',
    3008: 'Evaluation',
    3007: 'Production',
    3006: 'Experiment',
    3005: 'Models',
    3004: 'Cluster',
    3003: 'Temperature Compensation',
    3002: 'Config',
    3001: 'Dashboard',
    2022: 'Apps',
    2019: 'Aikaan',
    2018: 'User',
    2017: 'Pricing',
    2016: 'Products',
    2015: 'Complain',
    2014: 'QC',
    2013: 'Device Console',
    2012: 'Data',
    2011: 'Maintenance',
    2010: 'Support',
    2009: 'Status',
    2008: 'Allocation',
    2007: 'Inventory',
    2006: 'Organization',
    2005: 'Alerts',
    2003: 'Config',
    2002: 'Dashboard',
    2001: 'Real Time',
    1100: 'Licence',
    1019: 'Aviation',
    1020: 'Data Flagging',
    1017: 'Configuration',
    1016: 'Settings',
    1015: 'QC Test',
    1014: 'Automation',
    1013: 'Calibration',
    1012: 'Projects',
    1011: 'Integration',
    1010: 'Heatmap',
    1009: 'Cluster',
    1008: 'Devices',
    1007: 'Alerts',
    1006: 'Display',
    1005: 'Analytics',
    1004: 'Reports',
    1003: 'User',
    1002: 'Dashboard',
    1001: 'Overview',
  };
  public static DEVICETYPES_WITH_AQI = [1001, 1006, 1004];
  public static TIME_FORMATS = [
    {
      label: '24 hours',
      value: 24,
    },
    {
      label: '12 hours',
      value: 12,
    },
  ];
  public static DEVICE_STATUS = [
    {
      label: 'Enable',
      value: true,
    },
    {
      label: 'Disable',
      value: false,
    },
  ];
  public static TIME_INTERVALS = [
    { key: '120', lab: '2 Mins', value: '120000' },
    { key: '300', lab: '5 Mins', value: '300000' },
    { key: '600', lab: '10 Mins', value: '600000' },
    { key: '900', lab: '15 Mins', value: '900000' },
    { key: '1800', lab: '30 Mins', value: '1800000' },
    { key: '3600', lab: '60 Mins', value: '3600000' },
  ];

  public static AVERAGE_HOURS: AverageHour[] = [
    {
      id: '0',
      label: 'Raw data',
      value: 0,
      isMoving: false,
    },
    {
      id: '0.25',
      label: '15 minute avg',
      value: 0.25,
      isMoving: false,
    },
    {
      id: '0.5',
      label: '30 minute avg',
      value: 0.5,
      isMoving: false,
    },
    {
      id: '1',
      label: '1 hour avg',
      value: 1,
      isMoving: false,
    },
    {
      id: '8',
      label: '8 hour avg',
      value: 8,
      isMoving: false,
    },
    {
      id: '8m',
      label: '8 hour moving avg',
      value: 8,
      isMoving: true,
    },
    {
      id: '24',
      label: '24 hour avg',
      value: 24,
      isMoving: false,
    },
    {
      id: '24m',
      label: '24 hour moving avg',
      value: 24,
      isMoving: true,
    },
  ];

  public static planNames: Record<number, string> = {
    0: 'lite',
    1: 'smart',
    2: 'smart',
    3: 'smart',
    4: 'pro',
  };

  public static TRANSLATION_KEY_MAP = '/assets/translation-map.json';

  public static LANGUAGES = sortList(
    [
      { key: 'en', label: 'English' },
      { key: 'ua', label: 'Ukrainian' },
      { key: 'cr', label: 'Croatian' },
      { key: 'es', label: 'Spanish' },
      { key: 'ar', label: 'Arabic' },
      { key: 'id', label: 'Indonesian' },
      { key: 'ro', label: 'Romanian' },
      { key: 'mn', label: 'Mongolian' },
      { key: 'pl', label: 'Polish' },
    ],
    'ASC',
    'label'
  );

  public static formatPeriodForList = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  };

  public static formatPeriod: { [key: number]: string } = {
    0: 'daily',
    1: 'weekly',
    2: 'monthly',
  };

  public static reverseFormatPeriod: { [key: string]: number } = {
    daily: 0,
    weekly: 1,
    monthly: 2,
  };

  public static REPORT_FREQUENCIES: Array<Record<string, string>> = [
    {
      key: 'Daily',
      value: 'daily',
    },
    { key: 'Weekly', value: 'weekly' },
    {
      key: 'Monthly',
      value: 'monthly',
    },
  ];

  // public static REPORT_TYPE_FOR_MULTI_DEVICE: Array<Record<string, string>> = [
  //   {
  //     key: 'Uptime Report',
  //     value: 'uptime',
  //   },
  //   {
  //     key: 'Data Report',
  //     value: 'data',
  //   },
  // ];

  public static DEFAULT_MODULES_FOR_TERMINAL = [1001, 1002, 1003, 1008];

  public static chartTypes = [
    {
      label: 'Line Chart',
      value: 'line',
      url: 'assets/images/analytics/chart-types/line-chart.svg',
    },
    {
      label: 'Area Chart',
      value: 'area',
      url: 'assets/images/analytics/chart-types/area-chart.png',
    },
    {
      label: 'Column Chart',
      value: 'column',
      url: 'assets/images/analytics/chart-types/bar-chart.svg',
    },
    {
      label: 'Areaspline Chart',
      value: 'areaspline',
      url: 'assets/images/analytics/chart-types/wave-chart.svg',
    },
  ];

  public static directionsType = [
    {
      label: 'Direction From',
      value: false,
    },
    {
      label: 'Direction To',
      value: true,
    },
  ];

  public static LOCK_SCREEN_DATA: LockScreen.Details = {
    1009: {
      description: [
        'Groups devices based on geographic location or area type for data analysis. Provides both a tabular format and an eye-catching map view to display aggregated data.',
        'Enables users to analyse data over specific clusters to understand the environmental status of selected areas by applying a different Index to each Cluster.',
        'Useful for monitoring air quality in different zones such as industrial, residential, and airport areas.',
        'Allows users to create and manage multiple clusters based on their requirements.Facilitates the download of historical data for any cluster with a single click.',
      ],
      moduleName: 'Cluster',
      primaryImage: 'assets/images/module-lock-screen/Cluster_1.png',
    },
    1004: {
      description: [
        'Envizom  provides Automated  & Quick reports as per the industry standards.',
        'These reports can be generated on a Daily, Weekly and Monthly.',
        'Automated reports can be emailed to multiple recipients at predefined time intervals.',
        'The reports are generated in print-ready PDF formats with interactive charts, analysis and colour coding as per the AQI standards.',
      ],
      moduleName: 'Reports',
      primaryImage: 'assets/images/module-lock-screen/Reports_1.png',
      verticalImage: 'assets/images/module-lock-screen/Reports_2.png',
    },
    1005: {
      description: [
        'Analytics module enables you to review comprehensive analysis of environmental data.',
        'The Analytics module provides three major analytics  types- Device Comparison, Parameter comparison and Pollution rose.',
        'The Device Comparison allows you to compare the performance of two or more installed devices over a selected time period.',
        'The Parameter Comparison allows you to compare the multiple parameters of one device over a selected time period.',
        'The Pollution Rose allows you to get the Pollution data of a device for various parameters.',
      ],
      moduleName: 'Analytics',
      primaryImage: 'assets/images/module-lock-screen/Analytics_1.png',
      secondaryImage: 'assets/images/module-lock-screen/Analytics_2.png',
    },
    1007: {
      description: [
        'Thresholds based on pollution concentration can be set on the alerts module.',
        'When the threshold is breached the user is notified in the form of push notifications, email notifications and SMS notifications.',
        'Alerts module drastically reduces the problem-to-solution time by taking data-driven actions.',
        'Alerts can be set for 1 hour, 8 hours and 24 hours based on the requirements.',
        'Multiple alerts configurations can be set up as per the requirements. ',
      ],
      moduleName: 'Alerts',
      primaryImage: 'assets/images/module-lock-screen/Alerts_1.png',
      verticalImage: 'assets/images/module-lock-screen/Alerts_2.png',
    },
    1006: {
      description: [
        'Display module is used to create customizable screen displays for all types of screens.',
        'Using various data publishing mediums, environmental insights can be served to the mass audience at once.',
        'The data publishing is possible through outdoor LED Display Boards, TV Screens and integrable i-frame Web Widgets.',
        'The data publishing can spread environmental awareness among citizens and enable them to take data-driven health actions.',
      ],
      moduleName: 'Display',
      primaryImage: 'assets/images/module-lock-screen/Display_1.png',
      secondaryImage: 'assets/images/module-lock-screen/Display_2.png',
    },
    1011: {
      description: [
        'API Integration is used for seamless communication with third-party tools.',
        'All the APIs are protected using an SSL certificate. MQTT and HTTP protocols are followed in Oizom APIs.',
        'API enables interaction between data, applications, and devices.',
        'All Oizom API related information is provided in this module.',
        'Client ID and Client secret for token generation for every unique user.',
      ],
      moduleName: 'Integration',
      primaryImage: 'assets/images/module-lock-screen/Integration.png',
    },
    1014: {
      description: [
        'The Automation module allows users to automate the device on the basis of various trigger configurations.',
        'The automation module can be used to toggle a relay-based switch, of external equipment.',
        'The device will be automated on the basis of the threshold values set in the configuration.',
        'These configurations can be customized based on the use case and application.',
      ],
      moduleName: 'Automation',
      primaryImage: 'assets/images/module-lock-screen/Automation_1.png',
    },
    2015: {
      description: [
        'To onboard complaints from 3rd party platforms.',
        'Complain APIs to get data from other platforms.',
        'The complaints can be edited and assigned to the desired user',
        'Categorization of complaints based on Resolved , In progress and Pending.',
      ],
      moduleName: 'Complain',
      primaryImage: 'assets/images/module-lock-screen/Complain_1.png',
    },
    1012: {
      description: [
        'Projects Module is partner exclusive, it is only available for Oizom Partners.',
        'Useful to add, delete, update and manage Projects under your Organization.',
        'Multiple projects can be added with custom selection of Devices and Software Modules.',
        'Manage all your project’s unique users and credentials under this module.',
      ],
      moduleName: 'Projects',
      primaryImage: 'assets/images/module-lock-screen/Project_1.png',
      secondaryImage: 'assets/images/module-lock-screen/Project_2.png',
    },
    1020: {
      description: [
        ' To flag your device data as per the colour coding.',
        'Flags such as Invalid, Failure, Maintenance, Out Of Range & Calibration are available.',
        'Generate reports of Flagged data.',
        'Create colour coded charts for data comparison of the flagged data.',
      ],
      moduleName: 'Data flagging',
      primaryImage: 'assets/images/module-lock-screen/DataFlagging_1.png',
    },
    1021: {
      description: [
        'Smart Vibration sensors integration',
        'Realtime & Historical Dataset',
        'Key Monitoring of Vibration parameters via secure APIs or a direct wired connection.',
        'Measure Structural health and Equipment performance',
        'Get Vibration Parameters with Peak vibration velocity/time, Peak vibration acceleration/ time & frequency/time in units of mm/s & g depending on your vibration sensors',
      ],
      moduleName: 'Vibration',
      primaryImage: 'assets/images/module-lock-screen/Vibration_1.png',
    },
    1017: {
      description: [
        'Remotely activate or deactivate sensors on the device to manage data collection and operational efficiency.',
        'Execute restart commands on devices and apply configuration updates remotely.',
        'Access on-device software for online devices remotely.',
        'Configure your device remotely and make updates over the air for Online devices.',
      ],
      moduleName: 'Configuration',
      primaryImage: 'assets/images/module-lock-screen/Configuration_1.png',
      secondaryImage: 'assets/images/module-lock-screen/Configuration_2.png',
    },
    1010: {
      description: [
        'Heatmaps are used to visualise patterns related to environmental factors such as temperature, air quality, precipitation, and pollution levels.',
        'We have added a play button functionality to visualise the data on hourly basis , the colour on the heatmap updates according to the pollution in that area indicated by various pollutants  (e.g., NO₂, PM₂.₅).',
        'A heatmap of air quality index (AQI) across a city, highlights areas with higher pollution levels. Heatmaps use colour gradients to represent varying levels of air pollution, with different colours indicating different AQI values. For example, red might represent high pollution levels (unhealthy), while green represents low pollution levels (good).',
        'We use device data to generate heatmaps that show trends and changes in air quality over time, allowing users to see how pollution levels have fluctuated.',
        'Zooming in and out provides a detailed view of air quality across various scales, while clicking on specific locations generates additional data about pollution levels for that particular area.',
      ],
      moduleName: 'Heatmap',
      primaryImage: 'assets/images/module-lock-screen/Heatmap_1.png',
    },
  };

  public static BAUDRATE_LIST = [
    4800, 9600, 19200, 38400, 57600, 115200, 230400, 460800, 921600,
  ];

  public static MODBUS_MODE = [
    {
      label: 'Modbus RTU',
      value: 1,
    },
    {
      label: 'Modbus TCP',
      value: 2,
    },
  ];

  public static PARITY_LIST = [
    {
      label: 'None',
      value: 'N',
    },
    {
      label: 'Even',
      value: 'E',
    },
    {
      label: 'Odd',
      value: 'O',
    },
  ];

  public static CLASS_LIST = [
    {
      label: 'Class A',
      value: 1,
    },
    {
      label: 'Class C',
      value: 0,
    },
  ];

  public static NETWORK_LIST = [
    {
      label: 'OTAA mode',
      value: 1,
    },
    {
      label: 'ABP mode',
      value: 0,
    },
  ];

  public static TCP_MQTT_MODE = [
    {
      label: 'TCP',
      value: 0,
    },
    {
      label: 'MQTT',
      value: 1,
    },
  ];

  public static SNACKBAR_TYPES = {
    info: ['snackbar-info'],
    success: ['snackbar-success'],
    error: ['snackbar-error'],
    warning: ['snackbar-warning'],
  };

  public static COUNTRY_WISE_PHONE_CODE: CountryWisePhoneCode[] = [
    {
      country_code: 'AL',
      country_en: 'Albania',
      country_cn: '阿尔巴尼亚',
      phone_code: 355,
    },
    {
      country_code: 'DZ',
      country_en: 'Algeria',
      country_cn: '阿尔及利亚',
      phone_code: 213,
    },
    {
      country_code: 'AF',
      country_en: 'Afghanistan',
      country_cn: '阿富汗',
      phone_code: 93,
    },
    {
      country_code: 'AR',
      country_en: 'Argentina',
      country_cn: '阿根廷',
      phone_code: 54,
    },
    {
      country_code: 'AE',
      country_en: 'United Arab Emirates',
      country_cn: '阿联酋',
      phone_code: 971,
    },
    {
      country_code: 'AW',
      country_en: 'Aruba',
      country_cn: '阿鲁巴',
      phone_code: 297,
    },
    {
      country_code: 'OM',
      country_en: 'Oman',
      country_cn: '阿曼',
      phone_code: 968,
    },
    {
      country_code: 'AZ',
      country_en: 'Azerbaijan',
      country_cn: '阿塞拜疆',
      phone_code: 994,
    },
    {
      country_code: 'EG',
      country_en: 'Egypt',
      country_cn: '埃及',
      phone_code: 20,
    },
    {
      country_code: 'ET',
      country_en: 'Ethiopia',
      country_cn: '埃塞俄比亚',
      phone_code: 251,
    },
    {
      country_code: 'IE',
      country_en: 'Ireland',
      country_cn: '爱尔兰',
      phone_code: 353,
    },
    {
      country_code: 'EE',
      country_en: 'Estonia',
      country_cn: '爱沙尼亚',
      phone_code: 372,
    },
    {
      country_code: 'AD',
      country_en: 'Andorra',
      country_cn: '安道尔',
      phone_code: 376,
    },
    {
      country_code: 'AO',
      country_en: 'Angola',
      country_cn: '安哥拉',
      phone_code: 244,
    },
    {
      country_code: 'AI',
      country_en: 'Anguilla',
      country_cn: '安圭拉',
      phone_code: 1264,
    },
    {
      country_code: 'AG',
      country_en: 'Antigua',
      country_cn: '安提瓜岛',
      phone_code: 1268,
    },
    {
      country_code: 'AT',
      country_en: 'Austria',
      country_cn: '奥地利',
      phone_code: 43,
    },
    {
      country_code: 'AU',
      country_en: 'Australia',
      country_cn: '澳大利亚',
      phone_code: 61,
    },
    {
      country_code: 'MO',
      country_en: 'Macau',
      country_cn: '中国澳门特别行政区',
      phone_code: 853,
    },
    {
      country_code: 'BB',
      country_en: 'Barbados',
      country_cn: '巴巴多斯',
      phone_code: 1246,
    },
    {
      country_code: 'PG',
      country_en: 'Papua New Guinea',
      country_cn: '巴布亚新几内亚',
      phone_code: 675,
    },
    {
      country_code: 'BS',
      country_en: 'The Bahamas',
      country_cn: '巴哈马',
      phone_code: 1242,
    },
    {
      country_code: 'PK',
      country_en: 'Pakistan',
      country_cn: '巴基斯坦',
      phone_code: 92,
    },
    {
      country_code: 'PY',
      country_en: 'Paraguay',
      country_cn: '巴拉圭',
      phone_code: 595,
    },
    {
      country_code: 'PS',
      country_en: 'Palestine',
      country_cn: '巴勒斯坦',
      phone_code: 970,
    },
    {
      country_code: 'BH',
      country_en: 'Bahrain',
      country_cn: '巴林',
      phone_code: 973,
    },
    {
      country_code: 'PA',
      country_en: 'Panama',
      country_cn: '巴拿马',
      phone_code: 507,
    },
    {
      country_code: 'BR',
      country_en: 'Brazil',
      country_cn: '巴西',
      phone_code: 55,
    },
    {
      country_code: 'BY',
      country_en: 'Belarus',
      country_cn: '白俄罗斯',
      phone_code: 375,
    },
    {
      country_code: 'BM',
      country_en: 'Bermuda',
      country_cn: '百慕大',
      phone_code: 1441,
    },
    {
      country_code: 'BG',
      country_en: 'Bulgaria',
      country_cn: '保加利亚',
      phone_code: 359,
    },
    {
      country_code: 'MP',
      country_en: 'Northern Mariana Islands',
      country_cn: '北马里亚纳群岛',
      phone_code: 1670,
    },
    {
      country_code: 'BJ',
      country_en: 'Benin',
      country_cn: '贝宁',
      phone_code: 229,
    },
    {
      country_code: 'BE',
      country_en: 'Belgium',
      country_cn: '比利时',
      phone_code: 32,
    },
    {
      country_code: 'IS',
      country_en: 'Iceland',
      country_cn: '冰岛',
      phone_code: 354,
    },
    {
      country_code: 'PR',
      country_en: 'Puerto Rico',
      country_cn: '波多黎各',
      phone_code: 1,
    },
    {
      country_code: 'PL',
      country_en: 'Poland',
      country_cn: '波兰',
      phone_code: 48,
    },
    {
      country_code: 'BA',
      country_en: 'Bosnia and Herzegovina',
      country_cn: '波斯尼亚和黑塞哥维那',
      phone_code: 387,
    },
    {
      country_code: 'BO',
      country_en: 'Bolivia',
      country_cn: '玻利维亚',
      phone_code: 591,
    },
    {
      country_code: 'BZ',
      country_en: 'Belize',
      country_cn: '伯利兹',
      phone_code: 501,
    },
    {
      country_code: 'PW',
      country_en: 'Palau',
      country_cn: '帛琉',
      phone_code: 680,
    },
    {
      country_code: 'BW',
      country_en: 'Botswana',
      country_cn: '博茨瓦纳',
      phone_code: 267,
    },
    {
      country_code: 'BT',
      country_en: 'Bhutan',
      country_cn: '不丹',
      phone_code: 975,
    },
    {
      country_code: 'BF',
      country_en: 'Burkina Faso',
      country_cn: '布基纳法索',
      phone_code: 226,
    },
    {
      country_code: 'BI',
      country_en: 'Burundi',
      country_cn: '布隆迪',
      phone_code: 257,
    },
    {
      country_code: 'KP',
      country_en: 'North Korea',
      country_cn: '朝鲜',
      phone_code: 850,
    },
    {
      country_code: 'GQ',
      country_en: 'Equatorial Guinea',
      country_cn: '赤道几内亚',
      phone_code: 240,
    },
    {
      country_code: 'DK',
      country_en: 'Denmark',
      country_cn: '丹麦',
      phone_code: 45,
    },
    {
      country_code: 'DE',
      country_en: 'Germany',
      country_cn: '德国',
      phone_code: 49,
    },
    {
      country_code: 'TL',
      country_en: 'Timor-Leste',
      country_cn: '东帝汶',
      phone_code: 670,
    },
    {
      country_code: 'TG',
      country_en: 'Togo',
      country_cn: '多哥',
      phone_code: 228,
    },
    {
      country_code: 'DM',
      country_en: 'Dominica',
      country_cn: '多米尼加',
      phone_code: 1767,
    },
    {
      country_code: 'DO',
      country_en: 'Dominican Republic',
      country_cn: '多米尼加共和国',
      phone_code: 1809,
    },
    {
      country_code: 'RU',
      country_en: 'Russia',
      country_cn: '俄罗斯',
      phone_code: 7,
    },
    {
      country_code: 'EC',
      country_en: 'Ecuador',
      country_cn: '厄瓜多尔',
      phone_code: 593,
    },
    {
      country_code: 'ER',
      country_en: 'Eritrea',
      country_cn: '厄立特里亚',
      phone_code: 291,
    },
    {
      country_code: 'FR',
      country_en: 'France',
      country_cn: '法国',
      phone_code: 33,
    },
    {
      country_code: 'FO',
      country_en: 'Faroe Islands',
      country_cn: '法罗群岛',
      phone_code: 298,
    },
    {
      country_code: 'PF',
      country_en: 'French Polynesia',
      country_cn: '法属波利尼西亚',
      phone_code: 689,
    },
    {
      country_code: 'GF',
      country_en: 'French Guiana',
      country_cn: '法属圭亚那',
      phone_code: 594,
    },
    {
      country_code: 'PM',
      country_en: 'Saint Pierre and Miquelon',
      country_cn: '法属圣皮埃尔和密克隆岛',
      phone_code: 508,
    },
    {
      country_code: 'VA',
      country_en: 'Vatican City',
      country_cn: '梵蒂冈城',
      phone_code: 39,
    },
    {
      country_code: 'PH',
      country_en: 'Philippines',
      country_cn: '菲律宾',
      phone_code: 63,
    },
    {
      country_code: 'FJ',
      country_en: 'Fiji',
      country_cn: '斐济',
      phone_code: 679,
    },
    {
      country_code: 'FI',
      country_en: 'Finland',
      country_cn: '芬兰',
      phone_code: 358,
    },
    {
      country_code: 'CV',
      country_en: 'Cape Verde',
      country_cn: '佛得角',
      phone_code: 238,
    },
    {
      country_code: 'FK',
      country_en: 'Falkland Islands',
      country_cn: '福克兰群岛',
      phone_code: 500,
    },
    {
      country_code: 'GM',
      country_en: 'The Gambia',
      country_cn: '冈比亚',
      phone_code: 220,
    },
    {
      country_code: 'CG',
      country_en: 'Republic of the Congo',
      country_cn: '刚果共和国',
      phone_code: 242,
    },
    {
      country_code: 'CD',
      country_en: 'Democratic Republic of the Congo',
      country_cn: '刚果民主共和国',
      phone_code: 243,
    },
    {
      country_code: 'CO',
      country_en: 'Colombia',
      country_cn: '哥伦比亚',
      phone_code: 57,
    },
    {
      country_code: 'CR',
      country_en: 'Costa Rica',
      country_cn: '哥斯达黎加',
      phone_code: 506,
    },
    {
      country_code: 'GD',
      country_en: 'Grenada',
      country_cn: '格林纳达岛',
      phone_code: 1473,
    },
    {
      country_code: 'GL',
      country_en: 'Greenland',
      country_cn: '格陵兰',
      phone_code: 299,
    },
    {
      country_code: 'GE',
      country_en: 'Georgia',
      country_cn: '格鲁吉亚',
      phone_code: 995,
    },
    {
      country_code: 'GG',
      country_en: 'Guernsey',
      country_cn: '根西岛',
      phone_code: 44,
    },
    {
      country_code: 'CU',
      country_en: 'Cuba',
      country_cn: '古巴',
      phone_code: 53,
    },
    {
      country_code: 'GP',
      country_en: 'Guadeloupe',
      country_cn: '瓜德罗普岛',
      phone_code: 590,
    },
    {
      country_code: 'GU',
      country_en: 'Guam',
      country_cn: '关岛',
      phone_code: 1671,
    },
    {
      country_code: 'GY',
      country_en: 'Guyana',
      country_cn: '圭亚那',
      phone_code: 592,
    },
    {
      country_code: 'KZ',
      country_en: 'Kazakhstan',
      country_cn: '哈萨克斯坦',
      phone_code: 7,
    },
    {
      country_code: 'HT',
      country_en: 'Haiti',
      country_cn: '海地',
      phone_code: 509,
    },
    {
      country_code: 'KR',
      country_en: 'South Korea',
      country_cn: '韩国',
      phone_code: 82,
    },
    {
      country_code: 'NL',
      country_en: 'Netherlands',
      country_cn: '荷兰',
      phone_code: 31,
    },
    {
      country_code: 'BQ',
      country_en: 'Bonaire, Sint Eustatius and Saba',
      country_cn: '荷兰加勒比区',
      phone_code: 599,
    },
    {
      country_code: 'ME',
      country_en: 'Montenegro',
      country_cn: '黑山共和国',
      phone_code: 382,
    },
    {
      country_code: 'HN',
      country_en: 'Honduras',
      country_cn: '洪都拉斯',
      phone_code: 504,
    },
    {
      country_code: 'KI',
      country_en: 'Kiribati',
      country_cn: '基里巴斯',
      phone_code: 686,
    },
    {
      country_code: 'DJ',
      country_en: 'Djibouti',
      country_cn: '吉布提',
      phone_code: 253,
    },
    {
      country_code: 'KG',
      country_en: 'Kyrgyzstan',
      country_cn: '吉尔吉斯斯坦',
      phone_code: 996,
    },
    {
      country_code: 'GN',
      country_en: 'Guinea',
      country_cn: '几内亚',
      phone_code: 224,
    },
    {
      country_code: 'GW',
      country_en: 'Guinea-Bissau',
      country_cn: '几内亚比绍共和国',
      phone_code: 245,
    },
    {
      country_code: 'CA',
      country_en: 'Canada',
      country_cn: '加拿大',
      phone_code: 1,
    },
    {
      country_code: 'GH',
      country_en: 'Ghana',
      country_cn: '加纳',
      phone_code: 233,
    },
    {
      country_code: 'GA',
      country_en: 'Gabon',
      country_cn: '加蓬共和国',
      phone_code: 241,
    },
    {
      country_code: 'KH',
      country_en: 'Cambodia',
      country_cn: '柬埔寨',
      phone_code: 855,
    },
    {
      country_code: 'CZ',
      country_en: 'Czech Republic',
      country_cn: '捷克',
      phone_code: 420,
    },
    {
      country_code: 'ZW',
      country_en: 'Zimbabwe',
      country_cn: '津巴布韦',
      phone_code: 263,
    },
    {
      country_code: 'CM',
      country_en: 'Cameroon',
      country_cn: '喀麦隆',
      phone_code: 237,
    },
    {
      country_code: 'QA',
      country_en: 'Qatar',
      country_cn: '卡塔尔',
      phone_code: 974,
    },
    {
      country_code: 'KY',
      country_en: 'Cayman Islands',
      country_cn: '开曼群岛',
      phone_code: 1345,
    },
    {
      country_code: 'KM',
      country_en: 'Comoros',
      country_cn: '科摩罗',
      phone_code: 269,
    },
    {
      country_code: 'XK',
      country_en: 'Kosovo',
      country_cn: '科索沃',
      phone_code: 381,
    },
    {
      country_code: 'CI',
      country_en: "Côte d'Ivoire",
      country_cn: '科特迪瓦',
      phone_code: 225,
    },
    {
      country_code: 'KW',
      country_en: 'Kuwait',
      country_cn: '科威特',
      phone_code: 965,
    },
    {
      country_code: 'HR',
      country_en: 'Croatia',
      country_cn: '克罗地亚',
      phone_code: 385,
    },
    {
      country_code: 'KE',
      country_en: 'Kenya',
      country_cn: '肯尼亚',
      phone_code: 254,
    },
    {
      country_code: 'CK',
      country_en: 'Cook Islands',
      country_cn: '库克群岛',
      phone_code: 682,
    },
    {
      country_code: 'CW',
      country_en: 'Curaçao',
      country_cn: '库拉索',
      phone_code: 599,
    },
    {
      country_code: 'LV',
      country_en: 'Latvia',
      country_cn: '拉脱维亚',
      phone_code: 371,
    },
    {
      country_code: 'LS',
      country_en: 'Lesotho',
      country_cn: '莱索托',
      phone_code: 266,
    },
    {
      country_code: 'LA',
      country_en: 'Laos',
      country_cn: '老挝',
      phone_code: 856,
    },
    {
      country_code: 'LB',
      country_en: 'Lebanon',
      country_cn: '黎巴嫩',
      phone_code: 961,
    },
    {
      country_code: 'LT',
      country_en: 'Lithuania',
      country_cn: '立陶宛',
      phone_code: 370,
    },
    {
      country_code: 'LR',
      country_en: 'Liberia',
      country_cn: '利比里亚',
      phone_code: 231,
    },
    {
      country_code: 'LY',
      country_en: 'Libya',
      country_cn: '利比亞',
      phone_code: 218,
    },
    {
      country_code: 'LI',
      country_en: 'Liechtenstein',
      country_cn: '列支敦士登',
      phone_code: 423,
    },
    {
      country_code: 'RE',
      country_en: 'Réunion',
      country_cn: '留尼汪',
      phone_code: 262,
    },
    {
      country_code: 'LU',
      country_en: 'Luxembourg',
      country_cn: '卢森堡',
      phone_code: 352,
    },
    {
      country_code: 'RW',
      country_en: 'Rwanda',
      country_cn: '卢旺达',
      phone_code: 250,
    },
    {
      country_code: 'RO',
      country_en: 'Romania',
      country_cn: '罗马尼亚',
      phone_code: 40,
    },
    {
      country_code: 'MG',
      country_en: 'Madagascar',
      country_cn: '马达加斯加',
      phone_code: 261,
    },
    {
      country_code: 'IM',
      country_en: 'Isle Of Man',
      country_cn: '马恩岛',
      phone_code: 44,
    },
    {
      country_code: 'MV',
      country_en: 'Maldives',
      country_cn: '马尔代夫',
      phone_code: 960,
    },
    {
      country_code: 'MT',
      country_en: 'Malta',
      country_cn: '马耳他',
      phone_code: 356,
    },
    {
      country_code: 'MW',
      country_en: 'Malawi',
      country_cn: '马拉维',
      phone_code: 265,
    },
    {
      country_code: 'MY',
      country_en: 'Malaysia',
      country_cn: '马来西亚',
      phone_code: 60,
    },
    {
      country_code: 'ML',
      country_en: 'Mali',
      country_cn: '马里',
      phone_code: 223,
    },
    {
      country_code: 'MK',
      country_en: 'Macedonia',
      country_cn: '马其顿',
      phone_code: 389,
    },
    {
      country_code: 'MH',
      country_en: 'Marshall Islands',
      country_cn: '马绍尔群岛',
      phone_code: 692,
    },
    {
      country_code: 'MQ',
      country_en: 'Martinique',
      country_cn: '马提尼克',
      phone_code: 596,
    },
    {
      country_code: 'YT',
      country_en: 'Mayotte',
      country_cn: '马约特',
      phone_code: 262,
    },
    {
      country_code: 'MU',
      country_en: 'Mauritius',
      country_cn: '毛里求斯',
      phone_code: 230,
    },
    {
      country_code: 'MR',
      country_en: 'Mauritania',
      country_cn: '毛里塔尼亚',
      phone_code: 222,
    },
    {
      country_code: 'US',
      country_en: 'United States',
      country_cn: '美国',
      phone_code: 1,
    },
    {
      country_code: 'AS',
      country_en: 'American Samoa',
      country_cn: '美属萨摩亚',
      phone_code: 1684,
    },
    {
      country_code: 'VI',
      country_en: 'US Virgin Islands',
      country_cn: '美属维京群岛',
      phone_code: 1340,
    },
    {
      country_code: 'MN',
      country_en: 'Mongolia',
      country_cn: '蒙古',
      phone_code: 976,
    },
    {
      country_code: 'MS',
      country_en: 'Montserrat',
      country_cn: '蒙特塞拉特',
      phone_code: 1664,
    },
    {
      country_code: 'BD',
      country_en: 'Bangladesh',
      country_cn: '孟加拉国',
      phone_code: 880,
    },
    {
      country_code: 'PE',
      country_en: 'Peru',
      country_cn: '秘鲁',
      phone_code: 51,
    },
    {
      country_code: 'FM',
      country_en: 'Federated States of Micronesia',
      country_cn: '密克罗尼西亚联邦',
      phone_code: 691,
    },
    {
      country_code: 'MM',
      country_en: 'Myanmar',
      country_cn: '缅甸',
      phone_code: 95,
    },
    {
      country_code: 'MD',
      country_en: 'Moldova',
      country_cn: '摩尔多瓦',
      phone_code: 373,
    },
    {
      country_code: 'MA',
      country_en: 'Morocco',
      country_cn: '摩洛哥',
      phone_code: 212,
    },
    {
      country_code: 'MC',
      country_en: 'Monaco',
      country_cn: '摩纳哥',
      phone_code: 377,
    },
    {
      country_code: 'MZ',
      country_en: 'Mozambique',
      country_cn: '莫桑比克',
      phone_code: 258,
    },
    {
      country_code: 'MX',
      country_en: 'Mexico',
      country_cn: '墨西哥',
      phone_code: 52,
    },
    {
      country_code: 'NA',
      country_en: 'Namibia',
      country_cn: '纳米比亚',
      phone_code: 264,
    },
    {
      country_code: 'ZA',
      country_en: 'South Africa',
      country_cn: '南非',
      phone_code: 27,
    },
    {
      country_code: 'SS',
      country_en: 'South Sudan',
      country_cn: '南苏丹',
      phone_code: 211,
    },
    {
      country_code: 'NR',
      country_en: 'Nauru',
      country_cn: '瑙鲁',
      phone_code: 674,
    },
    {
      country_code: 'NI',
      country_en: 'Nicaragua',
      country_cn: '尼加拉瓜',
      phone_code: 505,
    },
    {
      country_code: 'NP',
      country_en: 'Nepal',
      country_cn: '尼泊尔',
      phone_code: 977,
    },
    {
      country_code: 'NE',
      country_en: 'Niger',
      country_cn: '尼日尔',
      phone_code: 227,
    },
    {
      country_code: 'NG',
      country_en: 'Nigeria',
      country_cn: '尼日利亚',
      phone_code: 234,
    },
    {
      country_code: 'NU',
      country_en: 'Niue',
      country_cn: '纽埃',
      phone_code: 683,
    },
    {
      country_code: 'NO',
      country_en: 'Norway',
      country_cn: '挪威',
      phone_code: 47,
    },
    {
      country_code: 'NF',
      country_en: 'Norfolk Island',
      country_cn: '诺福克群岛',
      phone_code: 672,
    },
    {
      country_code: 'PT',
      country_en: 'Portugal',
      country_cn: '葡萄牙',
      phone_code: 351,
    },
    {
      country_code: 'JP',
      country_en: 'Japan',
      country_cn: '日本',
      phone_code: 81,
    },
    {
      country_code: 'SE',
      country_en: 'Sweden',
      country_cn: '瑞典',
      phone_code: 46,
    },
    {
      country_code: 'CH',
      country_en: 'Switzerland',
      country_cn: '瑞士',
      phone_code: 41,
    },
    {
      country_code: 'SV',
      country_en: 'El Salvador',
      country_cn: '萨尔瓦多',
      phone_code: 503,
    },
    {
      country_code: 'WS',
      country_en: 'Samoa',
      country_cn: '萨摩亚',
      phone_code: 685,
    },
    {
      country_code: 'RS',
      country_en: 'Serbia',
      country_cn: '塞尔维亚',
      phone_code: 381,
    },
    {
      country_code: 'SL',
      country_en: 'Sierra Leone',
      country_cn: '塞拉利昂',
      phone_code: 232,
    },
    {
      country_code: 'SN',
      country_en: 'Senegal',
      country_cn: '塞内加尔',
      phone_code: 221,
    },
    {
      country_code: 'CY',
      country_en: 'Cyprus',
      country_cn: '塞浦路斯',
      phone_code: 357,
    },
    {
      country_code: 'SC',
      country_en: 'Seychelles',
      country_cn: '塞舌尔',
      phone_code: 248,
    },
    {
      country_code: 'SA',
      country_en: 'Saudi Arabia',
      country_cn: '沙特阿拉伯',
      phone_code: 966,
    },
    {
      country_code: 'BL',
      country_en: 'Saint Barthélemy',
      country_cn: '圣巴泰勒米',
      phone_code: 590,
    },
    {
      country_code: 'ST',
      country_en: 'Sao Tome and Principe',
      country_cn: '圣多美和普林西比民主共和国',
      phone_code: 239,
    },
    {
      country_code: 'SH',
      country_en: 'Saint Helena',
      country_cn: '圣赫勒拿',
      phone_code: 290,
    },
    {
      country_code: 'KN',
      country_en: 'Saint Kitts and Nevis',
      country_cn: '圣基茨和尼维斯',
      phone_code: 1869,
    },
    {
      country_code: 'LC',
      country_en: 'Saint Lucia',
      country_cn: '圣卢西亚',
      phone_code: 1758,
    },
    {
      country_code: 'MF',
      country_en: 'Saint Martin',
      country_cn: '法属圣马丁',
      phone_code: 590,
    },
    {
      country_code: 'SX',
      country_en: 'Sint Maarten',
      country_cn: '荷属圣马丁',
      phone_code: 599,
    },
    {
      country_code: 'SM',
      country_en: 'San Marino',
      country_cn: '圣马力诺',
      phone_code: 378,
    },
    {
      country_code: 'VC',
      country_en: 'Saint Vincent and the Grenadines',
      country_cn: '圣文森特和格林纳丁斯',
      phone_code: 1784,
    },
    {
      country_code: 'LK',
      country_en: 'Sri Lanka',
      country_cn: '斯里兰卡',
      phone_code: 94,
    },
    {
      country_code: 'SK',
      country_en: 'Slovakia',
      country_cn: '斯洛伐克',
      phone_code: 421,
    },
    {
      country_code: 'SI',
      country_en: 'Slovenia',
      country_cn: '斯洛文尼亚',
      phone_code: 386,
    },
    {
      country_code: 'SZ',
      country_en: 'Swaziland',
      country_cn: '斯威士兰',
      phone_code: 268,
    },
    {
      country_code: 'SD',
      country_en: 'Sudan',
      country_cn: '苏丹',
      phone_code: 249,
    },
    {
      country_code: 'SR',
      country_en: 'Suriname',
      country_cn: '苏里南',
      phone_code: 597,
    },
    {
      country_code: 'SB',
      country_en: 'Solomon Islands',
      country_cn: '所罗门群岛',
      phone_code: 677,
    },
    {
      country_code: 'SO',
      country_en: 'Somalia',
      country_cn: '索马里',
      phone_code: 252,
    },
    {
      country_code: 'TJ',
      country_en: 'Tajikistan',
      country_cn: '塔吉克斯坦',
      phone_code: 992,
    },
    {
      country_code: 'TW',
      country_en: 'Taiwan',
      country_cn: '台湾',
      phone_code: 886,
    },
    {
      country_code: 'TH',
      country_en: 'Thailand',
      country_cn: '泰国',
      phone_code: 66,
    },
    {
      country_code: 'TZ',
      country_en: 'Tanzania',
      country_cn: '坦桑尼亚',
      phone_code: 255,
    },
    {
      country_code: 'TO',
      country_en: 'Tonga',
      country_cn: '汤加',
      phone_code: 676,
    },
    {
      country_code: 'TC',
      country_en: 'Turks and Caicos Islands',
      country_cn: '特克斯和凯科斯群岛',
      phone_code: 1649,
    },
    {
      country_code: 'TT',
      country_en: 'Trinidad and Tobago',
      country_cn: '特立尼达和多巴哥',
      phone_code: 1868,
    },
    {
      country_code: 'TN',
      country_en: 'Tunisia',
      country_cn: '突尼斯',
      phone_code: 216,
    },
    {
      country_code: 'TV',
      country_en: 'Tuvalu',
      country_cn: '图瓦卢',
      phone_code: 688,
    },
    {
      country_code: 'TR',
      country_en: 'Turkey',
      country_cn: '土耳其',
      phone_code: 90,
    },
    {
      country_code: 'TM',
      country_en: 'Turkmenistan',
      country_cn: '土库曼斯坦',
      phone_code: 993,
    },
    {
      country_code: 'TK',
      country_en: 'Tokelau',
      country_cn: '托克劳',
      phone_code: 690,
    },
    {
      country_code: 'WF',
      country_en: 'Wallis and Futuna',
      country_cn: '瓦利斯和富图纳',
      phone_code: 681,
    },
    {
      country_code: 'VU',
      country_en: 'Vanuatu',
      country_cn: '瓦努阿图',
      phone_code: 678,
    },
    {
      country_code: 'GT',
      country_en: 'Guatemala',
      country_cn: '危地马拉',
      phone_code: 502,
    },
    {
      country_code: 'VE',
      country_en: 'Venezuela',
      country_cn: '委内瑞拉',
      phone_code: 58,
    },
    {
      country_code: 'BN',
      country_en: 'Brunei',
      country_cn: '文莱',
      phone_code: 673,
    },
    {
      country_code: 'UG',
      country_en: 'Uganda',
      country_cn: '乌干达',
      phone_code: 256,
    },
    {
      country_code: 'UA',
      country_en: 'Ukraine',
      country_cn: '乌克兰',
      phone_code: 380,
    },
    {
      country_code: 'UY',
      country_en: 'Uruguay',
      country_cn: '乌拉圭',
      phone_code: 598,
    },
    {
      country_code: 'UZ',
      country_en: 'Uzbekistan',
      country_cn: '乌兹别克斯坦',
      phone_code: 998,
    },
    {
      country_code: 'GR',
      country_en: 'Greece',
      country_cn: '希腊',
      phone_code: 30,
    },
    {
      country_code: 'ES',
      country_en: 'Spain',
      country_cn: '西班牙',
      phone_code: 34,
    },
    {
      country_code: 'EH',
      country_en: 'Western Sahara',
      country_cn: '西撒哈拉',
      phone_code: 212,
    },
    {
      country_code: 'HK',
      country_en: 'Hong Kong',
      country_cn: '中国香港特别行政区',
      phone_code: 852,
    },
    {
      country_code: 'SG',
      country_en: 'Singapore',
      country_cn: '新加坡',
      phone_code: 65,
    },
    {
      country_code: 'NC',
      country_en: 'New Caledonia',
      country_cn: '新喀里多尼亚',
      phone_code: 687,
    },
    {
      country_code: 'NZ',
      country_en: 'New Zealand',
      country_cn: '新西兰',
      phone_code: 64,
    },
    {
      country_code: 'HU',
      country_en: 'Hungary',
      country_cn: '匈牙利',
      phone_code: 36,
    },
    {
      country_code: 'SY',
      country_en: 'Syria',
      country_cn: '叙利亚',
      phone_code: 963,
    },
    {
      country_code: 'JM',
      country_en: 'Jamaica',
      country_cn: '牙买加',
      phone_code: 1876,
    },
    {
      country_code: 'AM',
      country_en: 'Armenia',
      country_cn: '亚美尼亚',
      phone_code: 374,
    },
    {
      country_code: 'YE',
      country_en: 'Yemen',
      country_cn: '也门',
      phone_code: 967,
    },
    {
      country_code: 'IQ',
      country_en: 'Iraq',
      country_cn: '伊拉克',
      phone_code: 964,
    },
    {
      country_code: 'IR',
      country_en: 'Iran',
      country_cn: '伊朗',
      phone_code: 98,
    },
    {
      country_code: 'IL',
      country_en: 'Israel',
      country_cn: '以色列',
      phone_code: 972,
    },
    {
      country_code: 'IT',
      country_en: 'Italy',
      country_cn: '意大利',
      phone_code: 39,
    },
    {
      country_code: 'IN',
      country_en: 'India',
      country_cn: '印度',
      phone_code: 91,
    },
    {
      country_code: 'ID',
      country_en: 'Indonesia',
      country_cn: '印度尼西亚',
      phone_code: 62,
    },
    {
      country_code: 'GB',
      country_en: 'United Kingdom',
      country_cn: '英国',
      phone_code: 44,
    },
    {
      country_code: 'VG',
      country_en: 'British Virgin Islands',
      country_cn: '英属维京群岛',
      phone_code: 1284,
    },
    {
      country_code: 'IO',
      country_en: 'British Indian Ocean Territory',
      country_cn: '英属印度洋领地',
      phone_code: 246,
    },
    {
      country_code: 'JO',
      country_en: 'Jordan',
      country_cn: '约旦',
      phone_code: 962,
    },
    {
      country_code: 'VN',
      country_en: 'Vietnam',
      country_cn: '越南',
      phone_code: 84,
    },
    {
      country_code: 'ZM',
      country_en: 'Zambia',
      country_cn: '赞比亚',
      phone_code: 260,
    },
    {
      country_code: 'JE',
      country_en: 'Jersey',
      country_cn: '泽西岛',
      phone_code: 44,
    },
    {
      country_code: 'TD',
      country_en: 'Chad',
      country_cn: '乍得',
      phone_code: 235,
    },
    {
      country_code: 'GI',
      country_en: 'Gibraltar',
      country_cn: '直布罗陀',
      phone_code: 350,
    },
    {
      country_code: 'CL',
      country_en: 'Chile',
      country_cn: '智利',
      phone_code: 56,
    },
    {
      country_code: 'CF',
      country_en: 'Central African Republic',
      country_cn: '中非共和国',
      phone_code: 236,
    },
    {
      country_code: 'CN',
      country_en: 'China',
      country_cn: '中国',
      phone_code: 86,
    },
  ];

  public static ROSE_CHART_RESULTANT_LINE_CONFIG = {
    color: 'var(--color-primary-default)', // Color of the line
    width: 1.5, // Width of the line
    zIndex: 5, // Layering of the line
  };

  public static LOWER_STATUS = [
    {
      label: 'On',
      value: 1,
    },
    {
      label: 'Off',
      value: 0,
    },
  ];

  public static UPPER_STATUS = [
    {
      label: 'On',
      value: 1,
    },
    {
      label: 'Off',
      value: 0,
    },
  ];

  public static DEVICE_LOG_TYPES = [
    { value: 'install', label: 'Installation' },
    {
      value: 'calib',
      label: 'Calibration',
    },
    { value: 'cd', label: 'Calibration Due Date' },
    { value: 'onm', label: 'Operations & Maintenance' },
  ];
}
