<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu" (tabChange)="onTabChange($event)">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-2 gy-2 gy-lg-0 mx-0"
    >
      <ng-container  *ngIf="isMapView()">
        <mat-form-field class="col-12 col-lg-3" >
          <mat-label>Heatmap Name</mat-label>
          <mat-select formControlName="heatmap">
            <mat-option
              *ngFor="let heatmap of heatmaps; let i = index"
              [value]="heatmap.heatmapId"
            >
              {{ heatmap.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-3">
          <mat-label>Select Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" [max]="maxDate"
          [min]="minDate">
            <input
              matStartDate
              formControlName="startDate"
              [placeholder]="'Start Date'"
            />
            <input
              matEndDate
              formControlName="endDate"
              [placeholder]="'End Date'"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="
              headerForm?.controls?.startDate?.hasError('matStartDateInvalid')
            "
          >
            Invalid start date
          </mat-error>
          <mat-error
            *ngIf="headerForm?.controls?.endDate?.hasError('matEndDateInvalid')"
          >
            Invalid end date
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field class="col-12 col-lg-3" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [max]="maxDate"
            formControlName="startDate"
            [matDatepicker]="startDatePicker"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="startDatePicker"
            tabindex="-1"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-3" appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [min]="headerForm.controls['startDate'].value ?? maxDate"
            [max]="maxDate"
            formControlName="endDate"
            [matDatepicker]="endDatePicker"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="endDatePicker"
            tabindex="-1"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field> -->
        <div class="col col-lg-auto col-12 col-lg-2 apply">
          <button
            matTooltip="Apply"
            (click)="onApply()"
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
          >
            Apply
          </button>
        </div>
      </ng-container>
      <div class="col text-lg-end"  *ngIf="isMapView() && getHeatmapModuleOptions('add')">
        <button
          (click)="addHeatmap()"
          mat-raised-button
          color="primary"
          class="w-100 w-lg-auto ms-auto"
          matTooltip="Add Heatmap"
        >
          Add Heatmap
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto" *ngIf="!loaderStates">
      <router-outlet></router-outlet>
    </div>
    <div class="py-5" *ngIf="loaderStates">
      <mat-spinner
        class="mx-auto"
        [diameter]="50"
        color="primary"
      ></mat-spinner>
      <div class="text-center mt-3">
        <b>Heatmap is Loading, please wait...</b>
      </div>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
