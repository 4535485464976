<div class="parameters-info">
  <div class="scales" *ngFor="let scale of scales">
    <div
      class="d-flex flex-row align-self-center justify-content-between p-2 mt-1"
      [style.background]="scale.color || '#0000001f'"
      [ngStyle]="{
        color:
          scale.color === '#000000' ||
          scale.color === 'black' ||
          scale.color === 'rgb(0, 0, 0)'
            ? 'white'
            : 'inherit',
        'font-weight': scale?.inScale ? 'bolder' : 'normal',
        border: scale?.inScale ? '2px solid black' : 'none'
      }"
    >
      <span>{{ scale?.name }}</span>
      <span class="d-flex flex-row align-self-center">
        {{ scale?.limit[0] + "-" + scale?.limit[1] }}
        <mat-icon
          matTooltip="The Parameter Comparison allows you to compare the multiple parameters of one device over a selected time period."
          style="font-size: 19px; width: 19px; height: 19px; margin-top: 1px"
          class="material-symbols-outlined cursor-pointer ms-2"
          *ngIf="scale?.inScale && isVisible"
        >
          info
        </mat-icon>
      </span>
    </div>
  </div>
</div>
