import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NbIconLibraries } from '@nebular/theme';
import { interval, Subscription } from 'rxjs';
import { AppConstants } from './shared/constants/app-constants';
import { LocalStorageConstants } from './shared/constants/local-storage.constant';
import { ConfirmDialogOptions } from './shared/models/confirm-dialog-options';
import { ConfirmDialogService } from './shared/services/confirm-dialog.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { NotificationService } from './shared/services/notification.service';
import { TranslationService } from './shared/services/translation.service';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from './shared/services/cookie.service';
import { MqttSocketService } from './shared/services/mqtt-socket.service';
import { DeviceService } from './shared/services/device.service';
import { LoginService } from './shared/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  svgIcons = AppConstants.SVG_ICONS;
  public subscriptions: Subscription[] = [];
  constructor(
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private nbIconLibraries: NbIconLibraries,
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private confirmDialogService: ConfirmDialogService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private router: Router,
    private loginService: LoginService,
  ) {
    this.notificationService.requestPermission();
    this.localStorageService.setupDefaultConfig().subscribe({
      next: (response) => {},
      error: (err) => {
        console.info('Error:', err);
        this.notificationService.showNotification(
          AppConstants.CONFIG_ERROR_MESSAGE,
          AppConstants.CLOSE,
          'top',
          'center',
          'error'
        );
      },
    });
    this.checkForAppUpdates();

    let currentModule: string | null = null;
    let chRouter: Subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        const user = JSON.parse(this.localStorageService.getValue('oz-user'));
        const userEmail = user ? user?.email : 'anonymous';
      
        // Extract module name and submodule
        const moduleName = url.split('/')[2] || 'unknown_module';
        const subModule = url.split('/').slice(3).join('/') || 'unknown_submodule';
      
        // Track module exit (if applicable)
        if (currentModule) {
          (window as any).gtag('event', 'page_end', {
            event_category: 'Module Navigation',
            event_label: currentModule,
            user_email_username: userEmail.split('@')[0],
            user_email_provider: userEmail.split('@')[1],
          });
        }
      
        // Track module entry
        (window as any).gtag('event', 'page_view', {
          event_category: 'Module Navigation',
          page_title: moduleName,
          page_location: subModule,
          page_path: url,
          user_email_username: userEmail.split('@')[0],
          user_email_provider: userEmail.split('@')[1],
        });

        currentModule = moduleName;
      }
      
      window.scrollTo(0, 0);
    });
    this.subscriptions.push(chRouter);
  }

  ngOnInit(): void {
    const userLoggedIn = localStorage?.getItem(LocalStorageConstants.USER_ID)
      ? true
      : false;
    const moduleAccess =
      JSON.parse(
        this.localStorageService.getValue(LocalStorageConstants.MODULE_ACCESS)
      ) &&
      JSON.parse(
        this.localStorageService.getValue(LocalStorageConstants.MODULE_ACCESS)
      ).includes(1009)
        ? true
        : false;
    (window as any).userLoggedIn = userLoggedIn;
    (window as any).moduleAccess = moduleAccess;
    this.updateApplication();
    this.registerIcons();
  }

  ngAfterViewInit() {
    let language =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.language ?? 'en';
    this.translationService.switchLanguage(language);
  }

  registerAppIcons(): void {}

  registerIcons() {
    let svgPack: any = {};
    let index = 0;
    this.iconRegistry.registerFontClassAlias(
      'material-symbols',
      'material-symbols-outlined'
    );
    for (let icon of this.svgIcons) {
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.svg)
      );
      this.httpClient
        .get(icon.svg, { responseType: 'text' })
        .subscribe((value) => {
          value = value
            .replaceAll('stroke="white"', 'stroke="currentColor"')
            .replaceAll('fill="#fff"', 'fill="currentColor"')
            .replaceAll('stroke="#fff"', 'stroke="currentColor"')
            .replaceAll('fill="none"', 'fill="currentColor"')
            .replaceAll('fill="white"', 'fill="currentColor"');
          let sanitizedValue: any =
            this.sanitizer.bypassSecurityTrustHtml(value);
          svgPack[icon.name] =
            sanitizedValue['changingThisBreaksApplicationSecurity'];
          if (index == this.svgIcons.length - 1) {
            this.registerSvgPack(svgPack);
          } else {
            index++;
          }
        });
    }
  }

  registerSvgPack(svgPack: any) {
    this.nbIconLibraries.registerSvgPack('envizom-module', svgPack);
  }

  checkForAppUpdates(): void {
    this.applicationRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(24 * 60 * 60 * 1000);
        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => {});
        });
      }
    });
  }

  updateApplication(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          const verisonEvent = event as VersionReadyEvent;

          const options: ConfirmDialogOptions = {
            title: 'Update Available',
            message:
              'A new version of the application is available. Load new version?',
            cancelText: 'Cancel',
            confirmText: 'Ok',
          };

          this.confirmDialogService.open(options);
          this.confirmDialogService.confirmed().subscribe((confirmed) => {
            if (confirmed) {
              this.swUpdate
                .activateUpdate()
                .then(() => {
                  this.loginService.logout(() => {
                    window.location.reload()
                  });
                });
            }
          });
        }
      });
    }
  }
}
