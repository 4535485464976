<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-2 gy-2 gy-lg-0 mx-0"
    >
      <div class="col-12 col-lg-10 d-flex flex-wrap gap-2 align-items-center">
        <div
          class="col-12 col-md-10 device-form-field" [ngClass]="!deviceTags.length ? 'col-lg-3' : 'col-lg-5'"
          >
          <app-tag-and-devicetype-dropwdown
            [tags]="deviceTags"
            [deviceTypes]="deviceTypes"
            [devices]="devices"
            [patchDevice]="currentActiveDevice"
            [showButton]="false"
            (selectedDevice)="onDeviceSelectionInDataFlagging($event)"
            (filteredDeviceList)="onTagSelection($event)"
          >
          </app-tag-and-devicetype-dropwdown>
        </div>
        <mat-form-field class=" col-12 col-lg-2" style="margin-left: 8px;" appearance="outline">
          <mat-label>Select Date</mat-label>
          <input
            matInput
            [min]="minDate"
            [max]="maxDate"
            formControlName="startDate"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
            tabindex="-1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="col-12 col-lg-2 apply gap-2">
          <button (click)="tableFormChange()" mat-raised-button color="primary" class="apply">
            Apply
          </button>
          <button
            (click)="flagTheData()"
            mat-raised-button
            color="primary"
            [disabled]="isFlagDisabled"
            class="apply"
          >
            Flag
          </button>
        </div>
      </div>

      <div class="col-12 col-lg-2">
        <div class="legend-container justify-content-end">
            <div class="legend-item">
              <span class="legend-color" style="background-color: #6ecc58"></span>
              <span>Corrected</span>
            </div>
            <div class="legend-item">
              <span class="legend-color" style="background-color: #eac736"></span>
              <span>Failure</span>
            </div>
            <!-- <div class="legend-item">
              <span class="legend-color" style="background-color: #ed9a2e"></span>
              <span>Invalid</span>
            </div> -->
        </div>
        <div class="legend-container justify-content-end">
          <div class="legend-item">
            <span class="legend-color" style="background-color: #ed9a2e"></span>
            <span>Invalid</span>
          </div>
          <div class="legend-item">
            <span class="legend-color" style="background-color: #d63636"></span>
            <span>Maintenance</span>
          </div>
        </div>
        <div class="legend-container justify-content-end">
          <!-- <div class="legend-item">
            <span class="legend-color" style="background-color: #d63636"></span>
            <span>Maintenance</span>
          </div> -->
          <div class="legend-item">
            <span class="legend-color" style="background-color: #8f3f97"></span>
            <span>Out Of Range</span>
          </div>
          <div class="legend-item">
            <span class="legend-color" style="background-color: #7e0023"></span>
            <span>Calibration</span>
          </div>
        </div>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
