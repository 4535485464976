<app-form-container
  [tableTitle]="'Add New Complain'"
  (cancelled)="previous()"
  (close)="closeForm()"
  (submit)="submitAndNext()"
  [submitBtn]="submitBtn"
  [cancelBtn]="cancelBtn"
  [isValid]="
    submitBtn == 'Next'
      ? registercomplainForm.valid
      : complainassignToForm.valid
  "
>
  <mat-stepper
    #stepper
    class="h-100 custom-stepper"
    (selectionChange)="onStepChange($event)"
    [linear]="true"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step
      [stepControl]="registercomplainForm"
      class="firststepper"
      label="Add Complain"
    >
      <div class="row gy-4 mt-2" [formGroup]="registercomplainForm">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Category</mat-label>
            <mat-select formControlName="categoryId">
              <mat-option
                *ngFor="let category of categories"
                [value]="category.categoryId"
                >{{ category.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 w-100">
          <div><mat-label>Select Priority</mat-label></div>
          <mat-radio-group formControlName="priority">
            <mat-radio-button
              *ngFor="let priority of priorities"
              [color]="'primary'"
              [value]="priority"
              >{{ priority }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Complain Name</mat-label>
            <input type="text" matInput formControlName="title" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Complain Description</mat-label>
            <textarea type="text" matInput formControlName="message"></textarea>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Possible Source</mat-label>
            <input type="text" matInput formControlName="sources" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Due Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="endDate"
            />
            <mat-datepicker-toggle matIconSuffix [for]="picker">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Latitude</mat-label>
            <input type="number" matInput formControlName="latitude" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Longitude</mat-label>
            <input type="number" matInput formControlName="longitude" />
          </mat-form-field>
        </div>
      </div>
    </mat-step>
    <mat-step
      [stepControl]="complainassignToForm"
      class="secondstepper"
      label="Add Complain"
    >
      <form class="row gy-4 mt-2" [formGroup]="complainassignToForm">
        <div class="col-12 w-100">
          <div><mat-label>Select Status</mat-label></div>
          <mat-radio-group formControlName="status">
            <mat-radio-button
              *ngFor="let status of statuses; let i = index"
              [value]="i"
              [color]="'primary'"
              >{{ status }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Assign To</mat-label>
            <mat-select formControlName="assignedUserId">
              <mat-option
                *ngFor="let user of selectedOrg"
                [value]="user.assignedUserId"
              >
                {{ user.assignedUserEmail }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Person Name</mat-label>
            <input type="text" matInput formControlName="name" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Email ID</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              [class.invalid-email]="
                complainassignToForm.get('email')?.hasError('customError')
              "
            />
            <mat-error
              *ngIf="complainassignToForm.get('email')?.hasError('customError')"
            >
              Please enter a valid email address.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Contact Number</mat-label>
            <input type="tel" matInput formControlName="phoneNo" />
            <mat-error
              *ngIf="
                complainassignToForm.get('phoneNo')?.hasError('customError')
              "
            >
              Please enter a valid phone Number.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-12">
          <app-upload-image
            [isDisabled]="isEditMode"
            [isEditMode]="isEditMode"
            [imageUrl]="img_url"
            [form]="complainassignToForm"
            (imageSelected)="handleImageSelected($event)"
            (imageRemoved)="removeFile()"
            (imageUrlUpdated)="img_url = $event"
          ></app-upload-image>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</app-form-container>
