import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  deviceTypes!: Array<DeviceType>;
  headerForm!: FormGroup;
  public subscriptions: Subscription[] = [];

  constructor(
    private formsService: FormsService,
    private commonService: CommonService,
    private fb: FormBuilder,
    public deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.deviceTypes = this.commonService.getUserDeviceTypesWithVibration();
    this.headerForm = this.fb.group({
      deviceType: [this.deviceService?.currentDeviceType || ''],
    });
    this.subscribeToFormControls();
  }

  subscribeToFormControls() {
    let devicetype = this.headerForm.controls.deviceType.valueChanges.subscribe(
      (type) => {
        if (type) {
          let devices =
            this.deviceService.registeredDevicesWithVibration?.filter(
              (device) => device.deviceType === type.key
            ) || [];
          if (devices && devices.length) {
            this.deviceService.setupDeviceIdGlobally(devices[0]?.deviceId);
          }
        }
      }
    );

    this.subscriptions.push(devicetype);
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  getDeviceType(deviceType: DeviceType): string {
    return deviceType ? deviceType?.label?.trim() : '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
