import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeatmapRoutingModule } from './heatmap-routing.module';
import { HeatmapComponent } from './heatmap.component';
import { HeatmapChartComponent } from './pages/map/components/heatmap-chart-widget/heatmap-chart-widget.component';
import { MapComponent } from './pages/map/map.component';

@NgModule({
  declarations: [HeatmapComponent, MapComponent, HeatmapChartComponent],
  imports: [
    TranslateModule.forChild({}),
    SharedModule,
    HeatmapRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    MatSliderModule,
  ],
})
export class HeatmapModule {}
