import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { AnalyticsComponent } from './analytics.component';
import { AnalyticFormComponent } from './components/analytic-form/analytic-form.component';
import { AnalyticsListComponent } from './pages/analytics-list/analytics-list.component';
import { TabularAnalyticComponent } from './pages/graph-view/components/tabular-analytic/tabular-analytic.component';
import { GraphViewComponent } from './pages/graph-view/graph-view.component';
import { MapComponent } from './pages/graph-view/components/map/map.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    AnalyticsComponent,
    AnalyticFormComponent,
    AnalyticsListComponent,
    GraphViewComponent,
    TabularAnalyticComponent,
    MapComponent,
  ],
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    MatStepperModule,
    GoogleMapsModule,
    FormsModule
  ],
})
export class AnalyticsModule {}
