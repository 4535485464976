import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceDetails } from '../models/device/device-details';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class VibrationService extends BaseAPIService<DeviceDetails> {
  public selectedDate = new BehaviorSubject<number>(0);
  public selectedDate$ = this.selectedDate.asObservable();

  private vibrationData: any;
  private allKeysOfDevice: string[] = [];
  private deviceTypeId!: number;

  public onHeaderTagChange = new Subject<any>();
    public onHeaderTagChange$ = this.onHeaderTagChange.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {
    super(http, cookieService);
  }

  getDeviceData(payload: any, deviceId: string | string[]) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let paramObj: { [key: string]: any } = {
      'deviceIds[]': deviceId,
      gte: payload.gte!,
      lte: payload.lte!,
      userId: userId,
      processType: payload.processType,
    };

    let params = new HttpParams().appendAll(paramObj);

    return this.get<Array<any>>(
      APIConstants.PARAMETER_COMPARISON,
      headers,
      params
    );
  }

  getVibrationData() {
    return this.vibrationData;
  }

  setVibrationData(data: any) {
    this.vibrationData = data;
  }

  getKeysOfDevice() {
    return this.allKeysOfDevice;
  }

  setKeysOfDevice(data: string[]) {
    this.allKeysOfDevice = data;
  }

  getDeviceTypeId() {
    return this.deviceTypeId;
  }

  setDeviceTypeId(id: number) {
    this.deviceTypeId = id;
  }
}
