<div
  class="h-100 d-flex flex-column overflow-hidden align-items-center justify-content-center"
>
  <div
    class="d-flex flex-column rounded"
    style="border: 2px solid rgba(0, 0, 0, 0.285); width: 98%; height: 95%"
  >
    <!--Parameter dropdown-->
    <ng-container *ngIf="(loadTable | async) && !noContentAvailable">
      <div
        class="col-lg-8 d-flex flex-row justify-content-around align-items-center ps-lg-5 py-2"
      >
        <form [formGroup]="form" class="d-flex col-lg-12 ps-lg-3">
          <mat-form-field class="col-lg-2 h-75">
            <mat-label>Select Parameter</mat-label>
            <mat-select formControlName="selectedKey">
              <mat-option
                *ngFor="let availableKey of availableKeys"
                [value]="availableKey.value"
              >
                {{ availableKey.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </ng-container>

    <div
      [class]="
        (loadTable | async) && !noContentAvailable
          ? 'd-flex flex-row h-full ps-2 ps-lg-3'
          : 'd-none'
      "
    >
      <!--Y axis slider-->
      <input
        type="range"
        #yAxisZoomSlider
        (input)="updateYAxisZoomLevel($event, highChartsObject)"
        style="writing-mode: vertical-lr; transform: scaleY(-1)"
        class="mb-5"
        orient="vertical"
        [step]="0.01"
        [min]="0"
        [max]="10000"
        [ngStyle]="{
          display:
            !(loadTable | async) && !noContentAvailable ? 'none' : 'block'
        }"
      />

      <!--Chart-->
      <ng-container *ngIf="loadTable | async">
        <div
          class="highcharts-dashboards-dark"
          style="flex: 1"
          *ngIf="redrawChart"
        >
          <highcharts-chart
            *ngIf="show"
            [Highcharts]="highcharts"
            [options]="chartOptions"
            style="width: 100%; display: block; height: 100%"
          >
          </highcharts-chart>
        </div>
      </ng-container>
    </div>

    <!--if content is not available-->
    <ng-container *ngIf="noContentAvailable">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <app-content-unavailable
          [majorText]="noData.majorText"
          [svgImage]="noData.svgImage"
          [minorText]="noData.minorText"
        >
        </app-content-unavailable>
      </div>
    </ng-container>

    <!--Loading spinner-->
    <div class="py-5" *ngIf="(loadTable | async) == false">
      <mat-spinner
        class="mx-auto"
        [diameter]="50"
        color="primary"
      ></mat-spinner>
    </div>
  </div>
</div>
