<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <ng-container
    *ngIf="
      updatedDevices
        | search : deviceService.currentDeviceType?.key : 'deviceType' : true;
      let filteredDevices
    "
  >
    <app-data-table
      [tableTitle]="deviceTitle"
      [data]="filteredDevices"
      [columns]="defaultColumns"
      [displayedColumns]="displayedColumns"
      (onDownload)="exportTableDataToCSV(filteredDevices)"
      [showFilterInHeader]="false"
      [isChartVisible]="false"
    >
    </app-data-table>
  </ng-container>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
