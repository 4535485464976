import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Heatmap } from 'src/app/shared/models/heatmap/heatmap';
import { DEFAULT_COLUMN } from 'src/app/shared/models/internal-use-front-end/table-column';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { HeatmapService } from 'src/app/shared/services/heatmap.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { sortList } from 'src/app/shared/utils/array-utils';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { HeatmapFormComponent } from '../../components/heatmap-form/heatmap-form.component';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  heatmaps: Heatmap[] = [];
  updatedHeatmaps: Heatmap[] = [];
  displayedColumns: string[] = [];
  heatmapTitle: string = '';
  updatingHeatmapStatus: string = '';
  isAdmin: boolean = false;
  private subscriptions: Subscription[] = [];
  heatmapTypeWithFavouriteHeatmap: string[] = [];

  defaultColumns: DEFAULT_COLUMN[] = [];

  public noData: ContentUnavailable = {
    majorText: 'No Heatmap Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Try adding a Heatmap for any Device Type',
  };
  moduleAccess: any;

  constructor(
    public dialog: MatDialog,
    public heatmapService: HeatmapService,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private router: Router,
    private notificationService : NotificationService,
  ) {
    this.defaultColumns = this.generateDefaultColumns();
  }

  ngOnInit(): void {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.heatmapService.heatmaps$.subscribe({
      next: (res) => {
        this.sortHeatmaps(res);
        this.heatmapTitle = `Heatmaps List (${this.heatmaps.length})`;
        this.updateHeatmapsInfo();
      },
    });
    this.loadData();
    this.moduleAccess = this.commonService.moduleAccessibility(1010);
    this.defaultColumns = this.generateDefaultColumns();
  }

  loadData(): void {
    this.loadTable.next(false);
    this.heatmapService.getUserHeatmaps().subscribe({
      next: (res: Heatmap[]) => {
        // this.sortHeatmaps(res);
        // this.heatmapTitle = `Heatmaps List (${this.heatmaps.length})`;
        // this.updateHeatmapsInfo();
        this.loadTable.next(true);
      },
      error: (err) => {
        console.info('Error:', err);
        this.loadTable.next(true);
      },
    });
  }

  //sort the heatmap in ascending order
  sortHeatmaps(res: Heatmap[]) {
    const sortHeatmaps = sortList(res, 'ASC', 'heatmapId');

    //filter out favourite heatmaps after sorting
    this.heatmaps = [...sortHeatmaps];
  }

  private showDeleteHeatmapPopup(heatmap: Heatmap) {
    this.deleteHeatmap(heatmap.heatmapId);
  }

  private deleteHeatmap(heatmapId: string) {
    this.loadTable.next(false);
    this.heatmapService.deleteHeatmap(heatmapId).subscribe({
      next: (res) => {
        try {
          this.notificationService.showSnackBar(res, 'success');
          this.loadData();
        } catch (error) {
          this.notificationService.showSnackBar(res, 'success');
          this.loadTable.next(true);
        }
      },
    });
  }

  //it gets trigger when user click on update button
  updateHeatmap(heatmap: Heatmap): void {
    let form = this.formsService
      .openForm(HeatmapFormComponent, heatmap, 'lg')
      .subscribe((result) => {
        if (result) {
          // this.tableDataUpdate(result);
        }
      });
    this.subscriptions.push(form);
  }

  generateDefaultColumns() {
    return [
      {
        columnDef: 'actions',
        header: '',
        cell: (element: Heatmap) => `${element.heatmapId}`,
        parameter: false,
        selected: false,
        icon: 'more_vert',
        options: [
          // {
          //   label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
          //   action: (heatmap: Heatmap) => this.updateHeatmap(heatmap),
          // },
          {
            label: `<span class="material-symbols-outlined">map</span>&nbsp View on Map`,
            action: (heatmap: Heatmap) =>
              this.router.navigateByUrl(`/heatmap/map/${heatmap.heatmapId}`),
          },
          ...(this.commonService.getModuleAccessOptionsOnRoute(
            'delete',
            this.moduleAccess
          )
            ? [
                {
                  label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
                  action: (heatmap: Heatmap) =>
                    this.showDeleteHeatmapPopup(heatmap),
                },
              ]
            : []),
        ],
      },
      {
        columnDef: 'heatmapName',
        header: 'Heatmap Name',
        cell: (element: Heatmap) => {
          let htmlCode = `${element.label ? element.label : '-'}`; // if no heatmap of a heatmaptype is marked as favourite
          return htmlCode;
        },

        parameter: false,
        selected: false,
      },
      {
        columnDef: 'heatmapId',
        header: 'Heatmap ID',
        cell: (element: Heatmap) => {
          let htmlCode = `${element.heatmapId}`; // if no heatmap of a heatmaptype is marked as favourite
          return htmlCode;
        },

        parameter: false,
        selected: false,
      },
      {
        columnDef: 'devices',
        header: 'Device Name',
        cell: (element: Heatmap) => {
          return (
            element?.devices
              ?.toString()
              .split(',')
              .map((deviceId: string) => {
                let deviceName = DeviceUtil.getDeviceLabel(
                  this.deviceService.registeredDevicesWithVibration!,
                  deviceId
                );
                if (deviceName === '') {
                  deviceName = deviceId;
                }
                return deviceName;
              }) || []
          );
        },
        parameter: false,
        selected: false,
        columnType: 'chip',
      },
      {
        columnDef: 'parameters',
        header: 'Parameters',
        cell: (element: Heatmap) => {
          let fields = this.deviceService.fetchFields(
            element.deviceTypeId,
            this.commonService.getAllUnits(),
            false
          );

          let keys = this.heatmapService.getKeysOfSelectedDevice(element);
          const keysArray = keys.split(',').map((key) => key.trim());
          let filteredFields: any = fields.filter((field) =>
            keysArray.includes(field.fkey)
          );
          const labels = filteredFields.map((field: any) => field.label);
          return labels;
        },
        parameter: false,
        selected: false,
        columnType: 'chip',
      },
    ];
  }
  updateHeatmapsInfo() {
    this.updatedHeatmaps = JSON.parse(JSON.stringify(this.heatmaps));
    this.updatedHeatmaps = this.updatedHeatmaps.map((heatmap) => {
      let tempHeatmap = heatmap;
      return tempHeatmap;
    });
  }

  updateHeatmapType(value: string | number): string {
    switch (value) {
      case 'POLLUDRON_PRO':
        return 'POLLUDRONE';
      case 'CPCB':
        return '3rd PARTY';
      default:
        return String(value);
    }
  }

  exportTableDataToCSV(filteredHeatmaps: any): void {
    if (!filteredHeatmaps || !filteredHeatmaps.length) {
      console.info('No data available to export');
      return;
    }
    const csvRows = [];
    const headers = this.defaultColumns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));
    filteredHeatmaps.forEach((heatmap: any) => {
      const values = this.defaultColumns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;
        if (columnKey === 'heatmapStatus') {
          cellValue = heatmap?.isOnline ? 'Enabled' : 'Disabled';
        } else {
          cellValue = heatmap[columnKey];
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });
      csvRows.push(values.join(','));
    });

    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}.csv`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
