<div class="w-100 h-100 d-flex flex-column">
  <div
    class="form-container-header px-2 pt-3 pb-2 border-bottom"
    *ngIf="tableTitle || showCloseBtn"
  >
    <div class="row mx-0 mb-1">
      <h6 class="col mb-0 align-self-center form-title">
        <b>{{ tableTitle }}</b>
        <span *ngIf="infoMsg"
          ><mat-icon
            class="material-symbols-outlined small-icon"
            matTooltip="{{ infoMsg }}"
            >info</mat-icon
          ></span
        >
      </h6>
      <div class="col-auto align-self-center">
        <mat-icon
          class="material-symbols-outlined cursor-pointer"
          (click)="close.emit()"
          >close</mat-icon
        >
      </div>
      <small
        ><mat-hint *ngIf="hintText" class="mt-1 hint">{{
          hintText
        }}</mat-hint></small
      >
    </div>
  </div>

  <div class="form-container-body px-2 py-3 border-bottom overflow-auto h-100">
    <div class="row mx-0">
      <div class="col-12 w-100">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="form-container-footer px-2 py-2">
    <div class="row mx-0">
      <div class="col">
        <button
          *ngIf="submitBtn"
          mat-raised-button
          [innerHTML]="submitBtn"
          color="primary"
          [disabled]="!isValid"
          (click)="submitForm()"
          style="margin-right: 1rem"
          [matTooltip]="submitBtnTooltip ? submitBtnTooltip : submitBtn"
        ></button>
        <button
          mat-flat-button
          [innerHTML]="cancelBtn"
          (click)="cancelled.emit()"
          [matTooltip]="cancelBtn"
        ></button>
      </div>
    </div>
  </div>
</div>
