import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { Device } from 'src/app/shared/models/device/device-info';
import { DEFAULT_COLUMN } from 'src/app/shared/models/internal-use-front-end/table-column';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { DeviceFormComponent } from '../../components/device-form/device-form.component';
import { SensorInfoComponent } from '../../components/sensor-info-form/sensor-info.component';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent implements OnInit {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  devices: Device.Info[] = [];
  updatedDevices: Device.Info[] = [];
  displayedColumns: string[] = [];
  deviceTitle: string = '';
  updatingDeviceStatus: string = '';
  isAdmin: boolean = false;
  private subscriptions: Subscription[] = [];
  deviceTypeWithFavouriteDevice: string[] = [];

  defaultColumns: DEFAULT_COLUMN[] = [];
  moduleAccess: any;

  constructor(
    public dialog: MatDialog,
    public deviceService: DeviceService,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    private notificationService: NotificationService
  ) {
    this.defaultColumns = this.generateDefaultColumns();
  }

  ngOnInit(): void {
    this.moduleAccess = this.commonService.moduleAccessibility(1008);
    this.defaultColumns = this.generateDefaultColumns();
    const user = this.commonService.getUser();

    //if user role is 1 than only show sensor information and device status
    if (user.role === 1 && user.modules.includes(1012)) {
      const statusColumn = {
        columnDef: 'deviceStatus',
        header: 'Status',
        cell: (element: DeviceDetails) => {
          return element?.isOnline ? 'Enabled' : 'Disabled';
        },
        color: (element: DeviceDetails) => {
          return element?.isOnline ? '#008000' : '#FF0000';
        },
        parameter: false,
        selected: false,
      };

      const sensorInfoAction = {
        label: `<span class="material-symbols-outlined">info</span>&nbsp Sensor Information`,
        action: (device: DeviceDetails) => this.openSensorInfoDialog(device),
      };

      this.defaultColumns.push(statusColumn);
      if (this.defaultColumns[0]?.options)
        this.defaultColumns[0].options.push(sensorInfoAction);
    }
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.loadData();
    this.addSubscriptions();
  }

  loadData(): void {
    this.loadTable.next(false);
    this.deviceService.getAdminDevices().subscribe({
      next: (res: Device.Info[]) => {
        // this.devices = res;
        this.devices = DeviceUtil.sortDevices(res);
        this.deviceTitle = `Total Device List (${this.devices.length})`;
        this.countTotalDevices();
        this.updateDevicesInfo();
        this.loadTable.next(true);
      },
      error: (err) => {
        console.info('Error:', err);
        this.loadTable.next(true);
      },
    });
  }

  generateDefaultColumns() {
    return [
      {
        columnDef: 'actions',
        header: '',
        cell: (element: DeviceDetails) => `${element.deviceId}`,
        parameter: false,
        selected: false,
        icon: 'more_vert',
        options: [
          ...(this.commonService.getModuleAccessOptionsOnRoute(
            'edit',
            this.moduleAccess
          )
            ? [
                {
                  label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
                  action: (device: DeviceDetails) => this.updateDevice(device),
                },
              ]
            : []),
          {
            label: (device: DeviceDetails) => {
              let output = `<span class="material-symbols-outlined">star</span>&nbsp Mark As Favourite`;
              if (device?.options?.markedAsFavourite) {
                output = `<span class="material-symbols-outlined material-symbols-filled">star</span>&nbsp Remove From Favourite`;
              }
              return output;
            },
            action: (device: DeviceDetails) => this.markDevice(device),
          },
        ],
      },
      {
        columnDef: 'deviceId',
        header: 'Device ID',
        cell: (element: DeviceDetails) => {
          let htmlCode = `${element.deviceId}`; // if no device of a devicetype is marked as favourite
          const isDeviceTypeFav = this.deviceTypeWithFavouriteDevice.includes(
            String(element.deviceType)
          );

          //if any device of a deviceType is marked as favourite
          // if (isDeviceTypeFav) {
          if (element?.options?.markedAsFavourite) {
            //if devicetype is marked fav than show filled star icon
            htmlCode = `<span class="d-flex align-items-center gap-2"> <div> ${element.deviceId} </div>  <span class="material-symbols-outlined material-symbols-filled icon-with-label">star</span> </span>`;
          } else {
            //if devicetype is not marked fav than show transparent icon
            htmlCode = `<span class="d-flex align-items-center gap-2"> <div> ${element.deviceId} </div> </span>`;
          }
          // }
          return htmlCode;
        },

        parameter: false,
        selected: false,
      },
      {
        columnDef: 'deviceType',
        header: 'Device Type',
        cell: (element: DeviceDetails) => `${element.updatedDeviceType}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'label',
        header: 'Label',
        cell: (element: DeviceDetails) => `${element.label}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'loc',
        header: 'Location',
        cell: (element: DeviceDetails) => element.loc !== null ? `${element.loc}` : '-',
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'tags',
        header: 'Device Tags',
        cell: (element: DeviceDetails) => {
          return element?.options?.deviceTags?.length
            ? element?.options?.deviceTags
            : [];
        },
        parameter: false,
        selected: false,
        columnType: 'chip',
      },
    ];
  }

  //get device type in which any of the device is marked favourite
  getDeviceTypeWithFav(favDevice: Device.Info[]) {
    let devSet = new Set<string>();
    favDevice.forEach((res) => devSet.add(res.deviceType));
    this.deviceTypeWithFavouriteDevice = Array.from(devSet);
  }

  //it gets trigger when user click on update button
  updateDevice(device: DeviceDetails): void {
    let form = this.formsService
      .openForm(DeviceFormComponent, device, 'lg')
      .subscribe((result) => {
        if (result) {
          // this.tableDataUpdate(result);
        }
      });
    this.subscriptions.push(form);
  }

  updateDevicesInfo() {
    this.updatedDevices = JSON.parse(JSON.stringify(this.devices));
    this.updatedDevices = this.updatedDevices.map((device) => {
      let tempDevice = device;
      tempDevice.updatedDeviceType = this.updateDeviceType(device.deviceType);
      return tempDevice;
    });
  }

  updateDeviceType(value: string | number): string {
    switch (value) {
      case 'POLLUDRON_PRO':
        return 'POLLUDRONE';
      case 'CPCB':
        return '3rd PARTY';
      default:
        return String(value);
    }
  }

  getDeviceModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  openSensorInfoDialog(data: DeviceDetails): void {
    let sensor = this.formsService
      .openForm(SensorInfoComponent, data, 'lg')
      .subscribe((result) => {
        if (result) {
        }
      });
    this.subscriptions.push(sensor);
  }

  exportTableDataToCSV(filteredDevices: any): void {
    if (!filteredDevices || !filteredDevices.length) {
      console.info('No data available to export');
      return;
    }
    const csvRows = [];
    const headers = this.defaultColumns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));
    filteredDevices.forEach((device: any) => {
      const values = this.defaultColumns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;
        if (columnKey === 'deviceStatus') {
          cellValue = device?.isOnline ? 'Enabled' : 'Disabled';
        } else if (columnKey === 'tags') {
          cellValue = device?.options?.deviceTags?.length
            ? device?.options?.deviceTags
            : '-';
        } else {
          cellValue = device[columnKey];
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });
      csvRows.push(values.join(','));
    });

    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}.csv`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  countTotalDevices() {
    let deviceObj: Record<string, number> = {};
    this.devices.forEach((device: Device.Info) => {
      if (deviceObj[device.deviceType]) {
        deviceObj[device.deviceType]++;
      } else {
        deviceObj[device.deviceType] = 1;
      }
    });
    this.deviceService.deviceCountOfEachDeviceType.next(deviceObj);
  }

  addSubscriptions() {
    let refresh = this.deviceService.refreshDeviceList$.subscribe({
      next: (res) => {
        if (res) this.loadData();
      },
      error: (err) => {
        console.info(
          err,
          'Error in device list component while refreshing device list'
        );
      },
    });
    this.subscriptions.push(refresh);
  }

  markDevice(device: DeviceDetails) {
    this.loadTable.next(false);
    let deviceDetails: Device.Update = this.generatePayload(device);
    this.deviceService.updateDevice(device?.deviceId, deviceDetails).subscribe({
      next: (res: string) => {
        this.deviceService.refreshDeviceList.next(true);
        this.notificationService.showSnackBar(res, 'success');
        //overview call to get updated device info
        this.deviceService.callOverview();
      },
      error: (err) => {
        console.info('Error:', err);
        this.notificationService.showSnackBar('Error', 'error');
        this.loadTable.next(true);
      },
    });
  }

  generatePayload(device: DeviceDetails) {
    let newOptions = {
      markedAsFavourite: !device?.options?.markedAsFavourite,
    };
    if (device.options) {
      newOptions = { ...device.options, ...newOptions };
    }

    let payload: Device.Update = {
      data: {
        deviceType: String(device.deviceType),
        latitude: device.latitude,
        longitude: device.longitude,
        loc: device.loc,
        label: device.label,
        city: device.city,
        country: device.country,
        url: device.url,
        options: newOptions,
      },
    };
    return payload;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
