import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsComponent } from './projects.component';
import { ProjectsListComponent } from './pages/projects-list/projects-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectEditComponent } from './pages/project-edit/project-edit.component';
@NgModule({
  declarations: [
    ProjectsComponent,
    ProjectsListComponent,
    ProjectFormComponent,
    ProjectEditComponent,
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    SharedModule,
    TranslateModule.forChild({}),
    MatStepperModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class ProjectsModule {}
