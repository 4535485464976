import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceLogBook } from 'src/app/shared/models/device/device-log-book';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-log-book-form',
  templateUrl: './log-book-form.component.html',
  styleUrls: ['./log-book-form.component.scss'],
})
export class LogBookFormComponent {
  public deviceTypes = this.commonService.getUserDeviceTypes();
  public devices = this.deviceService.registeredDevices!;
  public selectedTopics = [];
  public availableTopics = AppConstants.DEVICE_LOG_TYPES;
  public logBookForm!: FormGroup;
  public maxDate: moment.Moment = this.customMomentService.moment();
  public minDate!: moment.Moment;

  constructor(
    private formsService: FormsService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private formbuilder: FormBuilder,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    private loadrService: LoadrService
  ) {}

  ngOnInit() {
    this.logBookForm = this.formbuilder.group({
      deviceIds: ['', Validators.required],
      topics: ['', Validators.required],
      selectedDate: ['', Validators.required],
      note: '',
    });
    this.addSubscriptions();
  }

  addSubscriptions() {
    this.logBookForm.get('topics')?.valueChanges.subscribe((res) => {
      // this.selectedTopics = res;
    });

    this.logBookForm.get('deviceIds')?.valueChanges.subscribe((res) => {
      if (res.length) {
        this.minDate = this.deviceService.getMinDateOfDevice(res[0]);
      }
    });
  }

  closeForm() {
    this.formsService.closeForm();
  }

  submit() {
    this.loadrService.showLoader();
    const finalPayload = this.generatePayloadForLog();
    this.deviceService.addDeviceLog(finalPayload).subscribe({
      next: (res) => {
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar(
          'Device Log Added Successfully',
          'success'
        );
        this.deviceService.fetchDeviceLogs.next(true);
        this.closeForm();
      },
      error: (err) => {
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar(
          'Error While Adding Log',
          'error'
        );
        console.error(err);
      },
    });
  }

  generatePayloadForLog(): DeviceLogBook.Post {
    let payload: DeviceLogBook.Post = {
      data: [],
    };
    const topic = this.logBookForm.get('topics')?.value;

    payload.data = this.logBookForm.value.deviceIds.map((device: string) => {
      return {
        topic: topic,
        deviceId: device,
        note: {
          message: this.logBookForm.get('note')?.value || null,
          d: {
            [topic]: this.getEpochTime()?.unix(),
          },
        },
      };
    });
    return payload;
  }

  getEpochTime(): moment.Moment {
    const dateDiff = this.customMomentService
      .moment()
      .diff(
        this.customMomentService.moment(
          this.logBookForm.get('selectedDate')?.value
        ),
        'days'
      );
    if (dateDiff > 0) {
      return this.customMomentService.moment(
        this.logBookForm.get('selectedDate')?.value
      );
    } else {
      return this.customMomentService.moment();
    }
  }

  // getTopicsMapping(value: string) {
  //   const mapping: Record<string, string> = {
  //     calibration: 'calib',
  //     installation: 'install',
  //     'calibration-due': 'cd',
  //     'op&maint': 'onm',
  //   };
  //   return mapping[value];
  // }
}
