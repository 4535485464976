<div class="radioButton p-1" [formGroup]="tileForm" *ngIf="!isMultiSelect">
  <mat-radio-group formControlName="selectedTile" class="card-container">
    <ng-container
      *ngFor="
        let tileDetail of tileDetails;
        trackBy: tileTrackBy;
        let i = index
      "
    >
      <div class="card-tile">
        <mat-radio-button class="d-block w-100" [value]="tileDetail">
          <mat-card
            [ngStyle]="{
              border:
                tileDetail.id === tileForm.get('selectedTile')?.value?.id
                  ? '2px solid var(--text-primary-focus-color)'
                  : '2px solid transparent'
            }"
            [ngClass]="{
              'card-selected mat-elevation-z4':
                tileDetail.id === tileForm.get('selectedTile')?.value?.id
            }"
          >
            <mat-card-header
              class="d-flex flex-direction-column justify-content-between align-items-center p-0 border-rad"
            >
              <mat-card-subtitle class="py-1 px-2 text-truncate">
                {{ tileDetail.title }}
              </mat-card-subtitle>
              <mat-icon
                *ngIf="tileDetail.icon"
                class="material-symbols-outlined title-icon top-right"
                [matTooltip]="tileDetail.iconTooltip ?? ''"
                >{{ tileDetail.icon }}</mat-icon
              >
            </mat-card-header>
            <mat-card-content class="card-content">
              <img mat-card-image [src]="imageUrl[i]" class="card-image" />
            </mat-card-content>
          </mat-card>
        </mat-radio-button>
      </div>
    </ng-container>
  </mat-radio-group>
</div>
<div class="checkboxButton p-1" [formGroup]="tileForm" *ngIf="isMultiSelect">
  <div class="card-container">
    <ng-container
      *ngFor="
        let tileDetail of tileDetails;
        trackBy: tileTrackBy;
        let i = index
      "
    >
      <div class="card-tile">
        <mat-card
          [ngStyle]="{
            border: isTileSelected(tileDetail)
              ? '2px solid var(--text-primary-focus-color)'
              : '2px solid transparent'
          }"
          [ngClass]="{
            'card-selected mat-elevation-z4': isTileSelected(tileDetail)
          }"
        >
          <mat-card-header
            class="d-flex flex-direction-column justify-content-between align-items-center p-0 border-rad"
          >
            <mat-card-subtitle class="py-1 px-2 text-truncate">
              {{ tileDetail.title }}
            </mat-card-subtitle>

            <mat-checkbox
              class="d-block"
              color="primary"
              [checked]="
                tileDetail.isDefaultSelected || isTileSelected(tileDetail)
              "
              [disabled]="tileDetail.disabled"
              (change)="toggleTileSelection(tileDetail)"
            ></mat-checkbox>

            <mat-icon
              *ngIf="tileDetail.icon"
              class="material-symbols-outlined title-icon top-right"
              [matTooltip]="tileDetail.iconTooltip ?? ''"
              >{{ tileDetail.icon }}</mat-icon
            >
          </mat-card-header>
          <mat-card-content class="card-content">
            <img mat-card-image [src]="imageUrl[i]" class="card-image" />
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
