import { Component, OnInit } from '@angular/core';
import { get, pick } from 'lodash';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AqiDetails } from 'src/app/shared/models/aqi-index/aqi-details';
import { AQIIndex } from 'src/app/shared/models/aqi-index/aqi-index';
import { IndexData } from 'src/app/shared/models/aqi-index/index-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { LoginData } from 'src/app/shared/models/login-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-index-management',
  templateUrl: './index-management.component.html',
  styleUrls: ['./index-management.component.scss'],
})
export class IndexManagementComponent implements OnInit {
  public deviceTypes: DeviceType[] = this.commonService.getAllDeviceTypes();
  public allAqi: Record<string, Record<number, AQIIndex>> =
    this.commonService.getAllAQI();
  public allAqis: Record<string, AQIIndex> = this.commonService.getAllAQIs();

  public selectedDeviceType!: number;
  public selectedAQI!: any;

  public fields!: any;
  public limits: any;

  public showTable: boolean = false;

  public unitOfSelectedDeviceType: any;
  public allUnits: any;
  public indexLabel: any;
  public aqiData!: IndexData;
  public headers: any;

  public paramList: any;
  public paramsWithLimits: any;
  public nonAqiHeaders: any;
  public nonAqiRange: any;
  public nonAqiData!: IndexData;
  public nonAqiDetails: any;
  public defaultDeviceType: number = 1001;

  public unitsOfSelectedDeviceType: any;
  public subscriptions: Subscription[] = [];

  public aqiDetails: AqiDetails = {
    backColor: {},
    rangeName: {},
    limit: {},
  };

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.limits = this.deviceService.limits;
    this.allUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    this.addSubscriptions();
  }

  setUpData() {
    this.indexLabel = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    )[this.selectedDeviceType][this.selectedAQI.id].label;

    this.unitsOfSelectedDeviceType =
      this.commonService.getAllUnits()[this.selectedDeviceType];

    let unitHeaders = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_PARAMS
    )[this.selectedDeviceType];

    let unitLimits: Array<number> = [];

    let aqiReqParams: any;

    let breakpoints: Array<any> = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    )[this.selectedDeviceType][this.selectedAQI.id].breakpoints;

    if (Object.keys(breakpoints).length > 0) {
      aqiReqParams = Object.keys(breakpoints);
    }

    let units: any;
    units = {};
    for (let b in breakpoints) {
      units[b] = {};
      units[b]['limit'] = [];
      if (breakpoints[b].ranges) {
        for (let r in breakpoints[b].ranges) {
          if (parseInt(r, 10) === 0) {
            units[b].limit.push(
              Math.round(
                100 *
                  (breakpoints[b].ranges[r].breakPoint.low *
                    (this.unitsOfSelectedDeviceType[b].c_factore /
                      breakpoints[b].c_factore))
              ) / 100
            );
            units[b].limit.push(
              Math.round(
                100 *
                  (breakpoints[b].ranges[r].breakPoint.hig *
                    (this.unitsOfSelectedDeviceType[b].c_factore /
                      breakpoints[b].c_factore))
              ) / 100
            );
          } else if (parseInt(r, 10) === breakpoints[b].ranges.length - 1) {
          } else {
            units[b].limit.push(
              Math.round(
                100 *
                  (breakpoints[b].ranges[r].breakPoint.hig *
                    (this.unitsOfSelectedDeviceType[b].c_factore /
                      breakpoints[b].c_factore))
              ) / 100
            );
          }
        }
      }
    }

    this.unitsOfSelectedDeviceType = units;

    this.unitsOfSelectedDeviceType = pick(
      this.unitsOfSelectedDeviceType,
      aqiReqParams
    );

    for (let gas of unitHeaders) {
      if (this.unitsOfSelectedDeviceType[gas]) {
        this.unitsOfSelectedDeviceType[gas] = unitLimits[gas] =
          this.unitsOfSelectedDeviceType[gas].limit;
      }
    }
    unitHeaders = Object.keys(unitLimits);

    this.headers = unitHeaders.map((unitHeader: any) => {
      return {
        key: unitHeader,
        field: this.getFieldName(unitHeader),
        unit: this.getFieldUnit(unitHeader),
      };
    });

    if (this.selectedAQI) {
      this.aqiData = DeviceUtil.getAQIDetails(
        this.selectedAQI.id,
        this.selectedDeviceType,
        this.deviceTypes,
        this.allAqi,
        this.allAqis,
        this.selectedAQI
      )!;
    } else {
      console.info('Something went wrong while getting aqiData');
    }
    let i = 0;

    this.aqiDetails = {
      backColor: {},
      rangeName: {},
      limit: {},
    };
    for (let row of this.aqiData.names) {
      this.aqiDetails.backColor[i] = this.getBackColor(i);
      this.aqiDetails.rangeName[i] = this.getAQIRange(i);
      for (let gas of unitHeaders) {
        if (!this.aqiDetails.limit[i]) {
          this.aqiDetails.limit[i] = {};
        }
        this.aqiDetails.limit[i][gas] = this.getTransFormParam(
          unitLimits[gas],
          i
        );
      }
      i++;
    }
  }

  getTransFormParam(data: any, no: any) {
    try {
      if (data === undefined || no === undefined || data[no] === undefined) {
        return '';
      }
      if (data[no + 1] === undefined) {
        return data[no] + '+';
      }
      return data[no] + ' - ' + data[no + 1];
    } catch (e) {
      return '';
    }
  }

  getFieldName(field: string): string {
    return DeviceUtil.getFieldName(field, this.deviceService.fields);
  }

  getFieldUnit(field: string): string {
    return DeviceUtil.getFieldUnit(
      field,
      undefined,
      this.unitOfSelectedDeviceType
    );
  }

  getBackColor(no: any, aqi?: any, deviceTypeId?: number) {
    if (!aqi) {
      aqi = this.selectedAQI;
    }
    if (!deviceTypeId) {
      deviceTypeId = this.selectedDeviceType;
    }
    if (no === undefined) {
      return;
    }
    try {
      if (
        DeviceUtil.aqiColorArray(this.deviceTypes, this.allAqi, this.allAqis)
          .color
      ) {
        return DeviceUtil.aqiColorArray(
          this.deviceTypes,
          this.allAqi,
          this.allAqis,
          aqi.id,
          deviceTypeId
        ).color[no];
      }
    } catch (e) {
      console.info('error getting color');
    }
  }

  getAQIRange(no: any, aqi?: any, deviceTypeId?: number) {
    if (!aqi) {
      aqi = this.selectedAQI;
    }
    if (!deviceTypeId) {
      deviceTypeId = this.selectedDeviceType;
    }
    try {
      if (
        DeviceUtil.getAQIDetails(
          aqi.id,
          deviceTypeId,
          this.deviceTypes,
          this.allAqi,
          this.allAqis,
          aqi
        )!.colors[no + 1] === undefined
      ) {
        return (
          DeviceUtil.getAQIDetails(
            aqi.id,
            deviceTypeId,
            this.deviceTypes,
            this.allAqi,
            this.allAqis,
            aqi
          )!.names[no] +
          ' (' +
          DeviceUtil.getAQIDetails(
            aqi.id,
            deviceTypeId,
            this.deviceTypes,
            this.allAqi,
            this.allAqis,
            aqi
          )!.range[no] +
          '+)'
        );
      }
      return (
        DeviceUtil.getAQIDetails(
          aqi.id,
          deviceTypeId,
          this.deviceTypes,
          this.allAqi,
          this.allAqis,
          aqi
        )!.names[no] +
        ' (' +
        DeviceUtil.getAQIDetails(
          aqi.id,
          deviceTypeId,
          this.deviceTypes,
          this.allAqi,
          this.allAqis,
          aqi
        )!.range[no] +
        ' - ' +
        DeviceUtil.getAQIDetails(
          aqi.id,
          deviceTypeId,
          this.deviceTypes,
          this.allAqi,
          this.allAqis,
          aqi
        )!.range[no + 1] +
        ')'
      );
    } catch (e) {
      console.info('error in getAQIRange function');
      return '';
    }
  }

  setUpNonAqiData(deviceTypeId: number) {
    this.paramsWithLimits = [];
    let aqiDeviceType: number;

    if (AppConstants.DEVICETYPES_WITH_AQI.includes(deviceTypeId)) {
      aqiDeviceType = deviceTypeId;
    } else {
      aqiDeviceType = this.defaultDeviceType;
    }

    if (this.paramList[deviceTypeId]) {
      this.paramsWithLimits = this.paramList[deviceTypeId]
        .filter((unit: any) => {
          return (
            this.allUnits[deviceTypeId]?.[unit.name] &&
            this.allUnits[deviceTypeId]?.[unit.name].isVisible &&
            this.allUnits[deviceTypeId]?.[unit.name].aqiIndex == null &&
            this.allUnits[deviceTypeId][unit.name].limit &&
            this.allUnits[deviceTypeId][unit.name].limit.length &&
            this.allUnits[deviceTypeId][unit.name].limit[0] != null
          );
        })
        .map((unit: any) => this.allUnits[deviceTypeId][unit.name]);
    }

    if (this.paramsWithLimits.length > 0) {
      this.getNonAqiData(aqiDeviceType);
      this.getNonAqiHeaders();
      this.getNonAqiDetails(aqiDeviceType);
    }
  }

  getAPIUnitId(unit: any, deviceTypeId: number) {
    if (!deviceTypeId) {
      deviceTypeId = this.defaultDeviceType;
    }

    if (
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.units?.[deviceTypeId]
    ) {
      if (
        get(
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            .units?.[deviceTypeId],
          unit
        )
      ) {
        return get(
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            .units?.[deviceTypeId],
          unit
        ).id;
      }
    }
  }

  //to get non aqi parameters where user has set limits
  getParams(): any {
    if (
      this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_PARAMS
      )
    ) {
      let allPara = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_PARAMS
      );
      let para: any = {};
      for (let type of this.commonService.getUserDeviceTypes()) {
        let units;
        if (
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            ?.units?.[type.deviceTypeId]
        ) {
          units = this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_USER
          )?.units?.[type.deviceTypeId];
        } else {
          units = {};
        }
        if (!para[type.deviceTypeId]) {
          para[type.deviceTypeId] = [];
        }

        if (allPara[type.deviceTypeId]) {
          for (let key of allPara[type.deviceTypeId]) {
            if (units[key] && units[key].isVisible) {
              let obj = {
                name: key,
                label: this.getFieldName(key),
                checked: this.getAPIUnitId(key, type.deviceTypeId),
              };
              para[type.deviceTypeId].push(obj);
            }
          }
        }
      }
      return para;
    } else {
      return {};
    }
  }

  //to get aqi information of non aqi parameter
  getNonAqiData(aqiDeviceType: number): void {
    try {
      const user: LoginData = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      );
      this.nonAqiData = DeviceUtil.getAQIDetails(
        user.aqiIndex?.[aqiDeviceType].aqiIndexId,
        aqiDeviceType,
        this.deviceTypes,
        this.allAqi,
        this.allAqis,
        user.aqiIndex?.[aqiDeviceType]
      )!;
    } catch {
      console.info('Error in getNonAqiData()');
    }
  }

  //to get header detials of non aqi parameter
  getNonAqiHeaders() {
    try {
      this.nonAqiHeaders = this.paramsWithLimits.map((param: any) => {
        return {
          key: param.key,
          field: param.flabel,
          unit: param.label,
        };
      });
    } catch {
      console.info('Error in getNonAqiHeaders()');
    }
  }

  //to get detials of non aqi parameter
  getNonAqiDetails(aqiDeviceType: number) {
    try {
      const user: LoginData = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      );

      let nonAqiHeaders = this.nonAqiHeaders.map(
        (aqiHeader: any) => aqiHeader.key
      );

      let nonAqiUnitLimit: Record<string, any> = {};
      this.paramsWithLimits.forEach(
        (param: any) => (nonAqiUnitLimit[param.key] = param.limit)
      );

      this.nonAqiDetails = {
        backColor: {},
        rangeName: {},
        limit: {},
      };
      let i = 0;

      for (let row of this.nonAqiData.names) {
        this.nonAqiDetails.backColor[i] = this.getBackColor(
          i,
          user.aqiIndex?.[aqiDeviceType],
          aqiDeviceType
        );
        this.nonAqiDetails.rangeName[i] = this.getAQIRange(
          i,
          user.aqiIndex[aqiDeviceType],
          aqiDeviceType
        );
        for (let gas of nonAqiHeaders) {
          if (!this.nonAqiDetails.limit[i]) {
            this.nonAqiDetails.limit[i] = {};
          }
          this.nonAqiDetails.limit[i][gas] = this.getTransFormParam(
            nonAqiUnitLimit[gas],
            i
          );
        }
        i++;
      }
    } catch {}
  }

  addSubscriptions() {
    let deviceType = this.userService.selectedDeviceType$.subscribe((res) => {
      this.selectedDeviceType = res;
      this.unitOfSelectedDeviceType = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).units?.[this.selectedDeviceType];

      this.deviceService.fetchFields(
        this.selectedDeviceType,
        this.unitOfSelectedDeviceType
      );
      this.limits = this.deviceService.limits;
    });

    let aqi = this.userService.selectedAQI$.subscribe((res) => {
      if (res === undefined) {
        this.showTable = false;
        this.paramList = this.getParams();
        this.setUpNonAqiData(this.selectedDeviceType);
      } else if (res != undefined) {
        this.showTable = true;
        this.selectedAQI = res;
        this.setUpData();
        this.paramList = this.getParams();
        this.setUpNonAqiData(this.selectedDeviceType);
      }
    });

    //when user updates aqi than change the data of nonaqi table
    let userProfile = this.userService.userProfileUpdate.subscribe((res) => {
      if (res) this.setUpNonAqiData(this.selectedDeviceType);
    });

    this.subscriptions.push(deviceType);
    this.subscriptions.push(aqi);
    this.subscriptions.push(userProfile);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
