import { Component } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceLogBook } from 'src/app/shared/models/device/device-log-book';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-log-book',
  templateUrl: './log-book.component.html',
  styleUrls: ['./log-book.component.scss'],
})
export class LogBookComponent {
  public availableTopics = AppConstants.DEVICE_LOG_TYPES;
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = [];
  public deviceLogs: any = [];
  public defaultColumns: any[] = [];
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.NO_DATA,
    minorText: 'No Logs Found.',
  };
  private queryParams: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService
  ) {}

  ngOnInit() {
    this.addSubscriptions();
    this.defaultColumns = this.getDefaultColumns();
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
  }

  private addSubscriptions() {
    const formD = this.deviceService.formData$.subscribe((res) => {
      this.queryParams = res;
      this.getDeviceLogs(this.queryParams);
    });

    const fetchL = this.deviceService.fetchDeviceLogs$.subscribe((res) => {
      if (res) {
        this.getDeviceLogs(this.queryParams);
      }
    });

    this.subscriptions.push(formD, fetchL);
  }

  getDeviceLogs(value: any) {
    this.loadTable.next(false);
    this.deviceService.getDeviceLog(value).subscribe({
      next: (res) => {
        this.deviceLogs = res;
        this.loadTable.next(true);
      },
      error: (Error) => {
        console.error('Error while getting Device Logs', Error);
      },
    });
  }

  private getDefaultColumns() {
    let defaultCols = [
      {
        columnDef: 'deviceId',
        header: 'Device Id',
        cell: (element: DeviceLogBook.Get) => `${element.deviceId}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'deviceName',
        header: 'Device Name',
        cell: (element: DeviceLogBook.Get) =>
          `${DeviceUtil.getDeviceLabel(
            this.deviceService.registeredDevices!,
            element.deviceId
          )}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'notes',
        header: 'Notes',
        cell: (element: DeviceLogBook.Get) => `${element.note.message ?? '-'}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'installation',
        header: 'Installation Date',
        cell: (element: DeviceLogBook.Get) =>
          element.note.d['install']
            ? `${this.customMomentService.formatDate({
                epoch: element.note.d['install'],
              })}`
            : '-',
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'calib',
        header: 'Calibration Date',
        cell: (element: DeviceLogBook.Get) =>
          element.note.d['calib']
            ? `${this.customMomentService.formatDate({
                epoch: element.note.d['calib'],
              })}`
            : '-',
        parameter: false,
        selected: false,
      },

      {
        columnDef: 'cd',
        header: 'Calibration Due Date',
        cell: (element: DeviceLogBook.Get) =>
          element.note.d['cd']
            ? `${this.customMomentService.formatDate({
                epoch: element.note.d['cd'],
              })}`
            : '-',
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'onm',
        header: 'O&M Date',
        cell: (element: DeviceLogBook.Get) =>
          element.note.d['onm']
            ? `${this.customMomentService.formatDate({
                epoch: element.note.d['onm'],
              })}`
            : '-',
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'createdOn',
        header: 'Log Created On',
        cell: (element: DeviceLogBook.Get) =>
          `${this.customMomentService.formatDate({
            epoch: element.created_on,
          })}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'createdBy',
        header: 'Log Created By',
        cell: (element: DeviceLogBook.Get) => [element.email],
        parameter: false,
        selected: false,
        columnType: 'chip',
      },
    ];
    return defaultCols;
  }

  // private generateDynamicColumns(res: string[]) {
  //   res.forEach((column: string) => {
  //     const colHeader = this.getColumnName(column);

  //     this.defaultColumns.push({
  //       columnDef: colHeader?.value,
  //       header: colHeader?.label,
  //       cell: (element: DeviceLogBook.Get) =>
  //         element.note.d[column]
  //           ? `${this.customMomentService.formatDate({
  //               epoch: element.note.d[column],
  //             })}`
  //           : '-',
  //       parameter: false,
  //       selected: false,
  //     });
  //   });
  // }

  // private findUniqueRows(res: DeviceLogBook.Get[]): string[] {
  //   const uniqueKeys = new Set<string>();
  //   res.forEach((log) => {
  //     Object.keys(log.note.d).forEach((key: string) => uniqueKeys.add(key));
  //   });

  //   const uniqueKeyArray: string[] = Array.from(uniqueKeys);

  //   return uniqueKeyArray;
  // }

  modifyResponse(res: DeviceLogBook.Get[]) {
    this.deviceLogs = res;
  }

  // private getColumnName(col: any) {
  //   return this.availableTopics.find((res) => res.value === col);
  // }

  ngOnDestroy() {
    this.deviceService.fetchDeviceLogs.next(false);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
