import { Injectable } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WindMapType } from '../components/map-components/wind-map-type';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceDetails } from '../models/device/device-details';
import { LocalStorageService } from './local-storage.service';

type GMap = google.maps.Map;
type MapOptions = google.maps.MapOptions;

// @Injectable()
@Injectable({
  providedIn: 'root',
})
export class CommonMapService {
  fullScreenMode: string = 'zoom_out_map';
  currentMapType: string = 'roadmap';

  mapLayers!: number[];
  hasLayer5000: boolean = false;
  hasLayer5001: boolean = false;
  hasLayer5002: boolean = false;

  complainLayerEnabled: boolean = false;
  isComplainModuleIncluded: boolean = false;

  industryLayerEnabled: boolean = false;

  trafficLayerEnabled: boolean = false;
  trafficLayer?: google.maps.TrafficLayer;

  windLayerEnabled: boolean = false;
  selectedWeatherLayer: any[] = [];
  weatherOption: any;
  weatherApiKey: string = environment.openWeatherAPI;

  tiltMode: string = '3d_rotation';

  private optionsUpdateSource = new Subject<MapOptions>();
  optionsUpdate$ = this.optionsUpdateSource.asObservable();

  private layerToggledSource = new Subject<{
    state: boolean;
    name: string;
  }>();
  mapLayerToggled$ = this.layerToggledSource.asObservable();

  showMoreOptions: boolean = false;

  mapOptions = {
    showLabels: true,
    showLandmarks: true,
    showTerrain: true,
  };

  orgMapLayers: any[] = [];
  private layersMap = new Map();

  constructor(private localStorageService: LocalStorageService) {
    try {
      this.orgMapLayers =
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .info?.map_layers ?? [];
    } catch (err) {
      console.info('Error:', err);
    }
  }

  onMapLoaded(googleMap: GoogleMap) {
    this.mapUpdated(googleMap);
  }

  onMapReload(googleMap: GoogleMap) {
    this.mapUpdated(googleMap);
  }

  mapUpdated(googleMap: GoogleMap) {
    googleMap = googleMap;

    if (googleMap.googleMap) {
      this.addMapLayers(googleMap.googleMap);
    }

    this.mapLayers = this.localStorageService.getParsedValue(
      LocalStorageConstants.MAP_LAYER
    );
    this.hasLayer5000 = this.mapLayers.includes(5000);
    this.hasLayer5001 = this.mapLayers.includes(5001);
    this.hasLayer5002 = this.mapLayers.includes(5002);
  }

  private addMapLayers(map: GMap) {
    // Iterate over each layer object in the array and add to map using index as key
    this.orgMapLayers.forEach((layerData: any, index: number) => {
      if (!layerData.dataUrl || !layerData.layerType) {
        console.info('Error:', 'Invalid layer data at index:', index);
        return; // Skip this layer if data is invalid
      }

      let layer;

      if (layerData.layerType === 'kmz') {
        // For KMZ file
        layer = new google.maps.KmlLayer({
          suppressInfoWindows: false,
          screenOverlays: true,
          preserveViewport: true,
          url: layerData.dataUrl, // KMZ file URL
          zIndex: 9999999999999,
        });
      } else if (layerData.layerType === 'geojson') {
        // For GeoJSON file
        layer = new google.maps.Data({
          map: map,
        });

        // Load GeoJSON from URL
        layer.loadGeoJson(layerData.dataUrl);

        let propertiesKeyMap = new Map<string, string>();
        propertiesKeyMap.set('fill', 'fillColor');
        propertiesKeyMap.set('fill-opacity', 'fillOpacity');
        propertiesKeyMap.set('stroke', 'strokeColor');
        propertiesKeyMap.set('stroke-opacity', 'strokeOpacity');
        propertiesKeyMap.set('stroke-width', 'strokeWeight');
        propertiesKeyMap.set('icon', 'icon');
        propertiesKeyMap.set('label-opacity', 'labelOpacity');
        propertiesKeyMap.set('label-color', 'labelColor');
        propertiesKeyMap.set('icon-offset', 'iconOffset');
        propertiesKeyMap.set('icon-offset-units', 'iconOffsetUnits');
        propertiesKeyMap.set('name', 'name');

        layer.setStyle((feature) => {
          const props = {} as any;
          feature.forEachProperty((value, key) => {
            props[propertiesKeyMap.get(key) ?? ''] = value;
          });

          const icon: google.maps.Icon = {
            url: props.icon,
            anchor: new google.maps.Point(
              props.iconOffset?.[0],
              props.iconOffset?.[1]
            ),
          };

          // Return style object
          return {
            fillColor: props.fillColor,
            fillOpacity: props.fillOpacity,
            strokeColor: props.strokeColor,
            strokeOpacity: props.strokeOpacity,
            strokeWeight: props.strokeWeight,
            icon: props.icon ? icon : undefined,
            label: props.name
              ? {
                  text: props.name,
                  color: props.labelColor,
                  opacity: props.labelOpacity,
                }
              : undefined,
          };
        });

        layer.addListener('click', (event: any) => {
          // Extract the description value from the feature's properties
          const descriptionHtml =
            event.feature.getProperty('description')?.value || '';

          if (descriptionHtml === '') {
            return;
          }
          // Create an InfoWindow
          const infoWindow = new google.maps.InfoWindow({
            content: descriptionHtml, // Use the HTML content as the InfoWindow's content
          });

          // Position the InfoWindow at the clicked feature's position
          infoWindow.setPosition(event.latLng);

          // Open the InfoWindow on the map
          infoWindow.open(map);
        });
      }

      if (layer) {
        // Store the layer in the map using the array index as key
        this.layersMap.set(index, layer);

        // Initially set the layer on the map
        if (layerData.isVisible) {
          layer.setMap(map);
        }
      }
    });
  }

  switchToFullScreen(googleMap: GoogleMap): void {
    let mapElement = googleMap?.googleMap?.getDiv();
    if (mapElement) {
      if (this.isFullscreen(mapElement)) {
        this.exitFullscreen();
      } else {
        this.requestFullscreen(mapElement);
      }
    }
  }

  isFullscreen(element: HTMLElement): boolean {
    return document.fullscreenElement == element;
  }

  requestFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      this.fullScreenMode = 'zoom_in_map';
      element.requestFullscreen();
    }
  }

  exitFullscreen(): void {
    if (document.exitFullscreen) {
      this.fullScreenMode = 'zoom_out_map';
      document.exitFullscreen();
    }
  }

  toggleTilt(options: MapOptions, googleMap: GoogleMap): void {
    options = {
      ...options,
      center: googleMap.getCenter(),
      tilt: options.tilt === 0 ? 45 : 0,
    };

    this.optionsUpdateSource.next(options);
  }

  zoomIn(options: MapOptions, googleMap: GoogleMap): void {
    options = {
      ...options,
      center: googleMap.getCenter(),
      zoom:
        googleMap.getZoom() && googleMap.getZoom()! + 1 <= options.maxZoom!
          ? googleMap.getZoom()! + 1
          : options.maxZoom,
    };

    this.optionsUpdateSource.next(options);
  }

  zoomOut(options: MapOptions, googleMap: GoogleMap): void {
    options = {
      ...options,
      center: googleMap.getCenter(),
      zoom:
        googleMap.getZoom() && googleMap.getZoom()! - 1 >= options.minZoom!
          ? googleMap.getZoom()! - 1
          : options.minZoom,
    };

    this.optionsUpdateSource.next(options);
  }

  changeMapType(options: MapOptions, googleMap: GoogleMap): void {
    if (this.currentMapType === 'roadmap') {
      this.currentMapType = 'hybrid';
    } else {
      this.currentMapType = 'roadmap';
    }

    options = {
      ...options,
      mapTypeId: this.currentMapType,
      center: googleMap.getCenter(),
      zoom: googleMap.getZoom(),
    };

    this.optionsUpdateSource.next(options);
  }

  toggleComplainLayer(devices: DeviceDetails[], googleMap: GoogleMap): void {
    this.complainLayerEnabled = !this.complainLayerEnabled;
    this.layerToggledSource.next({
      state: this.complainLayerEnabled,
      name: 'complain',
    });
  }

  toggleIndustryLayer(googleMap: GoogleMap) {
    this.industryLayerEnabled = !this.industryLayerEnabled;
    this.layerToggledSource.next({
      state: this.industryLayerEnabled,
      name: 'industry',
    });
  }

  toggleTrafficLayer(googleMap: GoogleMap): void {
    this.trafficLayerEnabled = !this.trafficLayerEnabled;

    if (
      this.trafficLayer === undefined ||
      this.trafficLayer.getMap() == undefined ||
      this.trafficLayer.getMap() === null
    ) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(googleMap.googleMap!);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  toggleWindLayer(googleMap: GoogleMap): void {
    this.windLayerEnabled = !this.windLayerEnabled;
    this.layerToggledSource.next({
      state: this.windLayerEnabled,
      name: 'wind',
    });
    console.log("Toggle wind layer", this.windLayerEnabled);

    // if (this.windLayerEnabled) {
    //   // this.selectedWeatherLayer.push(AppConstants.MAP_WEATHER_LAYERS[0]);
    //   this.addWindLayer(googleMap);
    // } else {
    //   this.removeWindLayer(googleMap);
    // }
  }

  addWindLayer(googleMap: GoogleMap) {
    const weatherMapProvider = `https://tile.openweathermap.org/map/${this.selectedWeatherLayer[0]['layer_option']['layer_value']}/`;
    this.weatherOption = this.selectedWeatherLayer[0]['layer_option'];
    googleMap.overlayMapTypes.clear();
    googleMap.overlayMapTypes.insertAt(
      0,
      new WindMapType(
        new google.maps.Size(256, 256, 'px', 'px'),
        weatherMapProvider,
        this.weatherApiKey
      )
    );
  }

  removeWindLayer(googleMap: GoogleMap) {
    googleMap.overlayMapTypes.clear();
    this.selectedWeatherLayer = [];
  }

  toggleMoreOptions(): void {
    this.showMoreOptions = !this.showMoreOptions;
  }

  public toggleOrgMapLayer(orgMapLayerIndex: number, googleMap: GoogleMap) {
    try {
      const layer = this.layersMap.get(orgMapLayerIndex);

      if (!layer) {
        console.info('Error:', '2Layer not found at index:', orgMapLayerIndex);
        return;
      }

      this.orgMapLayers[orgMapLayerIndex].isVisible =
        !this.orgMapLayers[orgMapLayerIndex].isVisible;

      if (
        this.orgMapLayers[orgMapLayerIndex].isVisible &&
        googleMap?.googleMap
      ) {
        layer.setMap(googleMap?.googleMap);
      } else {
        layer.setMap(null);
      }

      this.orgMapLayers = [...this.orgMapLayers];
    } catch (err) {
      console.info('Error:', err);
    }
  }

  updateMapOption(
    option: 'showLabels' | 'showLandmarks',
    options: MapOptions,
    googleMap: GoogleMap
  ): void {
    this.mapOptions[option] = !this.mapOptions[option];

    // Preserve current center, zoom, and theme styles
    const currentCenter = googleMap.getCenter();
    const currentZoom = googleMap.getZoom();
    const currentStyles = options.styles;

    // Update map styles based on options
    options = {
      ...options,
      styles: this.getUpdatedMapStyles(currentStyles ?? []),
      center: currentCenter,
      zoom: currentZoom,
    };

    this.optionsUpdateSource.next(options);
  }

  private getUpdatedMapStyles(
    currentStyles: google.maps.MapTypeStyle[]
  ): google.maps.MapTypeStyle[] {
    let styles = [...currentStyles];

    if (!this.mapOptions.showLabels) {
      styles.push({
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      });
    } else {
      styles = styles.filter((style) => style.elementType !== 'labels');
    }

    if (!this.mapOptions.showLandmarks) {
      styles.push({
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      });
    } else {
      styles = styles.filter((style) => style.featureType !== 'poi');
    }

    return styles;
  }

  fitBounds(map: GMap, devices: DeviceDetails[]): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers = devices.map((device) => {
      if (
        device.payload.d.lat &&
        device.payload.d.lon &&
        device.payload.d.lat !== 0 &&
        device.payload.d.lon !== 0
      ) {
        return {
          lat: device.payload.d.lat,
          lng: device.payload.d.lon,
        };
      } else {
        return {
          lat: device.latitude,
          lng: device.longitude,
        };
      }
    });

    markers.forEach((marker) => {
      latLngBounds.extend(marker);
    });

    map.fitBounds(latLngBounds);
  }

  displayCustomInfoWindow(googleMap: GoogleMap, location: google.maps.LatLng, content: string){
    const infoWindowElement = document.createElement('div');
    infoWindowElement.innerHTML = content;
    // append the infoWindowElement to the map without using the google.maps.InfoWindow
    googleMap.googleMap?.getDiv().appendChild(infoWindowElement);
    const projection = googleMap.googleMap?.getProjection();
    const pixel = projection?.fromLatLngToPoint(location)!;
    console.log('location', location, 'pixel', pixel);
    const offset = 10;
    const x = pixel?.x - offset;
    const y = pixel?.y - offset;
    infoWindowElement.style.position = 'absolute';
    infoWindowElement.style.left = x + 'px';
    infoWindowElement.style.top = y + 'px';
    return infoWindowElement;
  }

  destroyCustomInfoWindow(googleMap: GoogleMap, infoWindowElement: HTMLElement){
    googleMap.googleMap?.getDiv().removeChild(infoWindowElement); 
  }

  // destroy() {
  //   // clear all the things
  //   this.fullScreenMode = 'zoom_out_map';
  //   this.currentMapType = 'roadmap';
  //   this.mapLayers = [];
  //   this.hasLayer5000 = false;
  //   this.hasLayer5001 = false;
  //   this.hasLayer5002 = false;
  //   this.complainLayerEnabled = false;
  //   this.isComplainModuleIncluded = false;
  //   this.industryLayerEnabled = false;
  //   this.trafficLayerEnabled = false;
  //   this.trafficLayer?.setMap(null);
  //   this.trafficLayer = undefined;
  //   this.windLayerEnabled = false;
  //   this.selectedWeatherLayer = [];
  //   this.weatherOption = undefined;
  //   this.showMoreOptions = false;
  //   this.mapOptions = {
  //     showLabels: true,
  //     showLandmarks: true,
  //     showTerrain: true,
  //   };
  //   this.orgMapLayers = [];
  //   this.layersMap.clear();
  // }
}
