export class DeviceConstants {
  public static OFFLINE_STATUS: string = 'Offline';
  public static ONLINE_STATUS: string = 'Online';
  public static NOT_CONNECTED_STATUS: string = 'Not Connected';

  public static CPCB_DEVICE_TYPE: string = 'CPCB';

  public static THIRD_PARTY_DEVICE_PIN_ASSET_URL =
    '/assets/images/pins/3rd_party_device_pin.svg';
  public static THIRD_PARTY_DEVICE_PIN_ASSET_URL_MODAL =
    '/assets/images/pins/3rd_party_device_pin_modal.svg';
  public static COMMON_PIN_NEUTRAL =
    'assets/images/pins/complain-pin-neutral.png';

  public static AQI_DEFAULT_LIMTIS: number[] = [0, 50, 100, 200, 300, 400];
  public static AQI_DEFAULT_COLORS: string[] = [
    '#6ecc58',
    '#bbcf4c',
    '#eac736',
    '#ed9a2e',
    '#e8633a',
  ];
  public static AQI_DEFAULT_LABELS: string[] = [
    'GOOD',
    'SATISFACTORY',
    'MODERATE',
    'POOR',
    'VERY POOR',
  ];
  public static AQI_DEFAULT_OUT_OF_RANGE_COLOR: string = '#d63636';
  public static AQI_DEFAULT_OUT_OF_RANGE_MESSAGE: string = 'SEVERE';

  public static MAP_FIELD_ARRAY: string[] = [
    'aqi',
    'p3',
    'p1',
    'p2',
    'p4',
    'p5',
    'g1',
    'g2',
    'g3',
    'g4',
    'g5',
    'g6',
    'g7',
    'g8',
    'g9',
    'v1',
    'v2',
    'v3',
    'v4',
    'v5',
    'temp',
    'hum',
    'light',
    'uv',
    'ir',
    'pr',
    'rain',
    'flood',
    'wd',
    'ws',
    't',
  ];

  public static HEADER_FIELDS = [
    'aqiMessage',
    'aqi',
    'p3',
    'p1',
    'p2',
    'p4',
    'p5',
    'g1',
    'g2',
    'g3',
    'g4',
    'g5',
    'g6',
    'g7',
    'g8',
    'g9',
    't',
    'v1',
    'v2',
    'v3',
    'v4',
    'v5',
    'temp',
    'hum',
    'leq',
    'lmin',
    'lmax',
    'light',
    'uv',
    'ir',
    'pr',
    'rain',
    'flood',
    'wd',
    'ws',
    'bs',
  ];

  public static MAP_MARKER_SIZE = { width: 40, height: 40 };
  public static MAP_MARKER_SIZE_CLUSTER = { width: 24, height: 25 };
  public static MAP_SELECTED_MARKER_SIZE = { width: 178, height: 178 };

  public static MAP_MARKER_ANCHOR = { x: 20, y: 40 };
  public static MAP_SELECTED_MARKER_ANCHOR = { x: 89, y: 108.67 };

  public static KEYS_TO_IGNORE: string[] = [
    'temp',
    't1',
    'atop_x',
    'atop_y',
    'atop_z',
    'vtop_x',
    'vtop_y',
    'vtop_z',
    'fdom_x',
    'fdom_y',
    'fdom_z',
    'Ch_x',
    'Ch_y',
    'Ch_z',
    'ppv_x',
    'ppv_y',
    'ppv_z',
  ];
}
