import { Component } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as saveAs from 'file-saver';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';
import { AnalyticsConstant } from '../../constants/analytics-constant';
import { AppConstants } from '../../constants/app-constants';
import { LocalStorageConstants } from '../../constants/local-storage.constant';
import { DeviceType } from '../../models/device-type/device-type';
import { DeviceDetails } from '../../models/device/device-details';
import { DeviceField } from '../../models/device/device-field';
import { FieldLimit } from '../../models/device/field-limit';
import { LoginData } from '../../models/login-data';
import { ReportData } from '../../models/reports/report-data';
import { CommonService } from '../../services/common.service';
import { CustomMomentService } from '../../services/custom-moment.service';
import { DeviceService } from '../../services/device.service';
import { FormsService } from '../../services/forms.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { NotificationService } from '../../services/notification.service';
import { ReportsService } from '../../services/reports.service';
import { DeviceUtil } from '../../utils/device-utils';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { LoadrService } from '../../services/loadr.service';

export interface EmailList {
  name: string;
}
@Component({
  selector: 'app-download-csv-report-form',
  templateUrl: './download-csv-report-form.component.html',
  styleUrls: ['./download-csv-report-form.component.scss'],
})
export class DownloadCsvReportFormComponent {
  public devices: DeviceDetails[] = [];
  public deviceTypes!: Array<DeviceType>;
  public downloadForm!: FormGroup;
  public fieldsOfCurrentDeviceType!: DeviceField[];
  public limitsOfFields!: FieldLimit[];
  public keysOfSelectedDevice!: Record<any, any>;
  public areKeysAvailable!: boolean;
  // public allAverages = AnalyticsConstant.allAverages;
  public allAverages = this.commonService.getAverageHours({
    valueInSeconds: true,
  });
  public isCSV: boolean = true;
  public usersTimeFormat: number = this.commonService.getTimeFormat();
  public clockTheme!: NgxMaterialTimepickerTheme;
  public isReport: boolean = false;
  public reportFrequencies = AppConstants.REPORT_FREQUENCIES;
  public minDate!: moment.Moment;
  public minTime!: string;
  public maxDate: moment.Moment;
  public maxTime!: string;
  public buttonValue!: string;
  public radioValue!: string;
  public subscriptions: Subscription[] = [];
  public formTitle!: string;
  selectedDevices: any;
  maxDevices = 10;
  showEmailField: boolean = false;
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  public emails: EmailList[] = [{ name: this.currentUserEmail }];
  public countEmail: number = 1;
  public isEmailValid: boolean = true;
  public addOnBlur = true;
  // public reportTypes = AppConstants.REPORT_TYPE_FOR_MULTI_DEVICE;

  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private reportsService: ReportsService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    private loadrService: LoadrService
  ) {
    const fallbackDate = this.customMomentService.moment('2015-01-01');
    this.minDate = this.customMomentService.moment.unix(fallbackDate.unix());
    this.maxDate = this.customMomentService.moment().startOf('day');
    // .subtract(1, 'day');
  }

  ngOnInit() {
    this.buttonValue = this.showEmailField ? 'Email' : 'Download';
    this.formTitle = this.isCSV
      ? this.showEmailField
        ? 'Email'
        : 'Download'
      : 'Uptime/Data Report';
    this.devices = this.deviceService.registeredDevices || [];
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.buildForm();
    this.downloadForm.valueChanges.subscribe(() => {
      if (this.isCSV) {
        this.minDate = this.deviceService.getMinDateOfDevice(
          this.downloadForm.value?.deviceSearch
        );
      } else {
        this.minDate = this.deviceService.getMinDateOfDevice(
          this.downloadForm.value?.deviceSearch[0]
        );
      }
      const startDate = this.downloadForm.get('startDate')?.value;
      if(this.downloadForm.get('endDate')?.value){
        const endDate = this.downloadForm.get('endDate')?.value?.endOf('day');
        if (endDate) {
          const start = this.customMomentService.moment(startDate);
          const end = this.customMomentService.moment(endDate);
          const diffMonths = end.diff(start, 'months', true);
          this.showEmailField = diffMonths > 4;
          this.buttonValue = this.showEmailField ? 'Email' : 'Download';
          this.formTitle = this.isCSV
            ? this.showEmailField
              ? 'Email'
              : 'Download'
            : 'Uptime/Data Report';
          if (this.showEmailField) {
          } else {
            this.downloadForm.get('email')?.clearValidators();
          }
        } else {
          this.showEmailField = false;
          this.downloadForm.get('email')?.clearValidators();
        }
      }
    });

    this.addSubscriptions();
  }

  buildForm() {
    this.downloadForm = this.formBuilder.group({
      selectedType: ['csv'],
      deviceSearch: ['', Validators.required],
      deviceName: [''],
      parameters: ['', Validators.required],
      average: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      // startTime: ['', Validators.required],
      // endTime: ['', Validators.required],
      reportFrequency: [''],
      reportType: [''],
      email: this.formBuilder.array(
        [],
        this.showEmailField ? Validators.required : null
      ),
    });
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  get itemsControl(): FormControl {
    return this.downloadForm.get('deviceSearch') as FormControl;
  }

  get paramsControl(): FormControl {
    return this.downloadForm.get('parameters') as FormControl;
  }

  clearDeviceName(): void {
    this.downloadForm.controls.deviceSearch.setValue(undefined);
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  submitForm() {
    const user = JSON.parse(this.localStorageService.getValue('oz-user'));
    const userEmail = user ? user?.email : 'anonymous';

    if (typeof (window as any).gtag === 'function') {
      try {
        (window as any).gtag('event', 'header_download_click', {
          event_label: this.showEmailField ? 'Email CSV' : 'Download',
          user_email_username: userEmail.split('@')[0],
          user_email_provider: userEmail.split('@')[1],
        });
      } catch (e) {
        console.error('Error tracking event with gtag:', e);
      }
    }
    this.downloadForm.setErrors({ error: true });
    this.isCSV
      ? this.showEmailField
        ? this.sendEmail()
        : this.downloadCSV()
      : this.downloadSLAReportPdf();
  }

  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  countLTE(date: moment.Moment, reportDuration: number) {
    if (reportDuration === 0) {
      const dateIs = this.customMomentService
        .moment(date)
        .add(0, 'day')
        .endOf('day');

      return dateIs.unix();
    } else if (reportDuration === 1) {
      const dateIs = this.customMomentService
        .moment(date)
        .add(6, 'days')
        .endOf('day');

      return dateIs.unix();
    } else if (reportDuration === 2) {
      const dateIs = this.customMomentService
        .moment(date)
        .add(29, 'days')
        .endOf('day');
      return dateIs.unix();
    }
    return null;
  }

  //when clicked on submit button this function will be called if user wants to generate sla report
  downloadSLAReportPdf() {
    let reportDetails = this.generatePayloadSLAReport();
    const todayDate = this.customMomentService.formatDate({
      epoch: reportDetails.gte,
      format: 'D-M-YYYY',
    });
    this.reportsService.downloadSLAReport(reportDetails).subscribe({
      next: (blobData: Blob) => {
        const pdfUrl = URL.createObjectURL(blobData);
        window.open(pdfUrl, '_blank');
        saveAs(blobData, `Data Report_${todayDate}` + '.pdf');
        this.notificationService.showSnackBar(
          'Report downloaded successfully',
          'success'
        );
        this.closeForm();
      },
      error: (err: Error) => {
        this.downloadForm.setErrors(null);
        console.info('Error:', err);
        this.notificationService.showSnackBar(
          'Error downloading SLA report, Try Again!',
          'error'
        );
      },
    });
  }

  //when clicked on submit button this function will be called if user wants to download csv report
  // downloadCSV() {
  //   this.reportsService.downloadCSV(this.generatePayloadForCSV()).subscribe({
  //     next: (blobData: Blob) => {
  //       saveAs(
  //         blobData,
  //         `${
  //           this.downloadForm.controls.deviceSearch.value.label +
  //           ' ' +
  //           this.downloadForm.controls.average.value.label
  //         }.csv`
  //       );
  //       this.notificationService.showSnackBar(
  //         'CSV downloaded successfully',
  //         'success'
  //       );
  //       this.downloadForm.setErrors(null);
  //       this.closeForm();
  //     },
  //     error: (err: Error) => {
  //       this.downloadForm.setErrors(null);
  //       console.info('Error:', err);
  //       this.notificationService.showSnackBar(
  //         'Error downloading report, Try Again!',
  //         'error'
  //       );
  //     },
  //   });
  // }
  downloadCSV() {
    this.reportsService.downloadCSV(this.generatePayloadForCSV()).subscribe({
      next: (blobData: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const csvContent = (reader.result as string).normalize('NFC');

          const headers = this.downloadForm.value.parameters
            .map((param: any) => param.name)
            .join(',');

          const fileName = `${this.downloadForm.controls.deviceSearch.value.label} ${this.downloadForm.controls.average.value.label}.csv`;
          const utf8BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
          const blobWithBOM = new Blob([utf8BOM, csvContent], {
            type: 'text/csv;charset=utf-8;',
          });
          saveAs(blobWithBOM, fileName);
          this.notificationService.showSnackBar(
            'CSV downloaded successfully',
            'success'
          );
          this.downloadForm.setErrors(null);
          this.closeForm();
        };
        reader.readAsText(blobData, 'utf-8');
      },
      error: () => {
        this.notificationService.showSnackBar(
          'Error downloading report, Try Again!',
          'error'
        );
        this.downloadForm.setErrors(null);
      },
    });
  }

  sendEmail() {
    this.loadrService.showLoader();
    let reportDetails: any = this.generatePayloadForEmailReport();
    this.reportsService.emailCsv(reportDetails).subscribe({
      next: (res: any) => {
        if (res) {
          this.loadrService.removeLoader();
          this.formsService.closeForm();
          this.notificationService.showSnackBar(res, 'success');
        }
      },
      error: (err) => {
        this.loadrService.removeLoader();
        console.info('Error:', err);
        this.notificationService.showSnackBar(err.message, 'error');
      },
    });
  }

  generatePayloadSLAReport() {
    let user: LoginData = this.commonService.getUser();
    let tempForm = this.downloadForm.value;
    let date = this.customMomentService
      .moment(tempForm.startDate)
      .startOf('day');

    let deviceArray = tempForm.deviceSearch.map((device: DeviceDetails) => {
      return device.deviceId;
    });
    let slaReportPayload: ReportData.SLAReport = {
      userId: user.userId,
      gte: date.unix(),
      lte: this.countLTE(
        tempForm?.startDate,
        AppConstants.reverseFormatPeriod[tempForm.reportFrequency.value]
      )!,
      deviceIds: deviceArray,
    };

    return slaReportPayload;
  }

  generatePayloadForEmailReport() {
    let user: LoginData = this.commonService.getUser();
    let tempForm = this.downloadForm.value;
    let start_date = this.customMomentService.moment(tempForm.startDate);
    let end_date = this.customMomentService.moment(tempForm?.endDate?.endOf('day'));
    let deviceArray = this.showEmailField
      ? tempForm?.deviceSearch.deviceId
      : tempForm?.deviceSearch?.map((device: DeviceDetails) => {
          return device.deviceId;
        });
    let keys = tempForm.parameters.map((param:any) => param.name);
    let processTypeValue: any;
    let averageValue;
    if (tempForm?.average?.ravg) {
      processTypeValue = 'moving-avg';
      averageValue = tempForm?.average.value;
    } else if (tempForm?.average.value === 0) {
      processTypeValue = 'raw';
      averageValue = tempForm?.average.value;
    } else {
      processTypeValue = 'avg';
      averageValue = tempForm?.average.value;
    }
    if (this.showEmailField && this.isCSV) {
      let emailReportPayload:
        | ReportData.SLAReport
        | ReportData.EmailQuickReport = {
        userId: user.userId,
        gte: start_date.unix(),
        lte: end_date.unix(),
        deviceIds: deviceArray,
        processType: processTypeValue,
        keys:keys,
        ...(processTypeValue == 'raw' ? {} : { avg: averageValue }),
      };
      let emails = this.emails.map((email) => {
        return email.name;
      });
      let tempPayload = {
        ...emailReportPayload,
        email: emails,
      };
      return tempPayload;
    } else {
      return this.generatePayloadForCSV();
    }
  }

  addSubscriptions() {
    let deviceSearch = this.downloadForm
      .get('deviceSearch')
      ?.valueChanges.subscribe((res) => {
        if (res && this.isCSV) {
          let device = this.devices.find(
            (device) => device.deviceId === res.deviceId
          );

          let deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
            this.deviceTypes,
            this.devices,
            device?.deviceId!
          );

          this.setFieldsAndLimits(deviceTypeId ?? 1001);

          if (Object.keys(device!.payload.d)?.length > 0) {
            this.keysOfSelectedDevice = device?.payload?.d!;
            this.areKeysAvailable = true;
          } else {
            this.areKeysAvailable = false;
          }
        }
      })!;

    this.subscriptions.push(deviceSearch);
    let type = this.downloadForm.controls.selectedType.valueChanges.subscribe({
      next: (res) => {
        if (res === 'csv') {
          this.isCSV = true;
          this.isReport = false;
          this.formTitle = this.isCSV
            ? this.showEmailField
              ? 'Email'
              : 'Download'
            : 'Uptime/Data Report';
        } else {
          this.isCSV = false;
          this.isReport = true;
          this.formTitle = 'Uptime/Data Report';
        }
        this.resetFormAndValues();
        this.setFormValidations();
      },
      error: (err) => {
        console.info('Error:', err);
      },
    });

    this.subscriptions.push(type);

    let duration = this.downloadForm
      .get('reportFrequency')
      ?.valueChanges.subscribe((res) => {
        let response = null;
        let date = this.customMomentService.moment().startOf('day');
        this.maxDate = date;
        response = res.value;
        // switch (response) {
        //   case 'daily':
        //     this.maxDate.subtract(1, 'day');
        //     break;
        //   case 'weekly':
        //     this.maxDate.subtract(7, 'days');
        //     break;
        //   case 'monthly':
        //     this.maxDate.subtract(30, 'days');
        //     break;
        // }
      })!;

    this.subscriptions.push(duration);
  }

  resetFormAndValues() {
    let fieldsToChange = [
      'deviceSearch',
      'deviceName',
      'parameters',
      'average',
      'startDate',
      'endDate',
      'reportFrequency',
      'reportType',
    ];

    fieldsToChange.forEach((field) => {
      this.downloadForm.get(field)?.setValue('');
      this.downloadForm.get(field)?.setErrors(null);
      this.downloadForm.get(field)?.markAsUntouched();
      this.downloadForm.get(field)?.setValidators(null);
    });
  }

  setFormValidations() {
    let fieldsforValidation = ['deviceSearch', 'startDate'];
    if (this.isCSV) {
      fieldsforValidation.push('parameters', 'average', 'endDate');
    } else {
      fieldsforValidation.push('reportFrequency', 'reportType');
    }
    fieldsforValidation.forEach((field) => {
      this.downloadForm.get(field)?.setValidators(Validators.required);
    });
  }

  setFieldsAndLimits(deviceTypeId: number) {
    let units = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    this.fieldsOfCurrentDeviceType = this.deviceService.fetchFields(
      deviceTypeId,
      units,
      false
    );

    this.limitsOfFields = this.deviceService.fetchLimits(
      units[deviceTypeId],
      false
    );
  }

  generatePayloadForCSV(): Record<string, any> {
    let params = this.downloadForm.value.parameters.map(
      (param: any) => param.name
    );

    let payload: Record<string, any> = {};
    payload['deviceId'] = this.downloadForm.value.deviceSearch.deviceId;
    payload['params'] = params;
    let startDatetime = this.customMomentService.moment(
      this.downloadForm.value.startDate
    );
    // startDatetime.set({
    //   hour: parseInt(this.downloadForm.value.startTime.split(':')[0]),
    //   minute: parseInt(this.downloadForm.value.startTime.split(':')[1]),
    // });
    let endDatetime = this.customMomentService.moment(
      this.downloadForm.value.endDate.endOf('day')
    );
    // endDatetime.set({
    //   hour: parseInt(this.downloadForm.value.endTime.split(':')[0]),
    //   minute: parseInt(this.downloadForm.value.endTime.split(':')[1]),
    // });
    payload['gte'] = startDatetime.unix();
    payload['lte'] = endDatetime.unix();

    if (this.downloadForm.value.average) {
      if (this.downloadForm.value.average?.ravg) {
        payload['processType'] = 'moving-avg';
        payload['average'] = this.downloadForm.value.average.value;
      } else if (this.downloadForm.value.average.value === 0) {
        payload['processType'] = 'raw';
      } else {
        payload['processType'] = 'avg';
        payload['average'] = this.downloadForm.value.average.value;
      }
    }
    return payload;
  }

  isDeviceSelected(device: any) {
    this.selectedDevices = this.downloadForm.value.deviceSearch;
    return (
      this.selectedDevices.length >= 10 &&
      !this.selectedDevices.includes(device)
    );
  }

  addEmail(input: MatChipInputEvent | string): void {
    if (typeof input === 'object') {
      const value = (input.value || '').trim();

      if (input.value !== '')
        this.isEmailValid = this.validateEmail((input.value || '').trim());

      if (this.isEmailValid) {
        if (value && this.countEmail < 6) {
          (<FormArray>this.downloadForm.get('email'))?.push(
            new FormControl(value, [Validators.email])
          );
          this.emails.push({ name: value });
          this.countEmail++;
          this.isEmailValid = true;
        }
        input.chipInput!.clear();
      }
    } else if (typeof input === 'string') {
      if (input !== '')
        this.isEmailValid = this.validateEmail((input || '').trim());
      if (this.isEmailValid) {
        if (input && this.countEmail < 6) {
          (<FormArray>this.downloadForm.get('email'))?.push(
            new FormControl(input, [Validators.email])
          );
          this.emails.push({ name: input });
          this.countEmail++;
          this.isEmailValid = true;
        }
      }
    }
  }

  removeEmail(email: EmailList): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      const emailIdControlArray = this.downloadForm.get('email') as FormArray;
      emailIdControlArray?.removeAt(index);
      this.emails.splice(index, 1);
      this.countEmail--;
    }
  }

  editEmail(email: EmailList, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.removeEmail(email);
      return;
    }

    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails[index].name = value;
    }
  }

  clearEmailArray() {
    while (this.emails.length > 0) {
      this.emails.pop();
    }
    this.countEmail = 0;
  }

  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
      ? true
      : false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
