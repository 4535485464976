import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/config/material.module';
import { TermsAndConditionsComponent } from './pages/modal/terms-and-conditions/terms-and-conditions.component';
import { NbDialogService } from '@nebular/theme';

@NgModule({
  declarations: [LoginComponent, AuthComponent, TermsAndConditionsComponent],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents: [TermsAndConditionsComponent],
  providers: [NbDialogService],
})
export class AuthModule {}
