<nb-layout [windowMode]="false" [withScroll]="false">
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive (click)="closeSidebar()">
    <div class="scrollable-content">
      <ng-content select="nb-menu"></ng-content>
    </div>
    <div class="fixed-footer">
      <div class="poweredByDetails">
        <div class="poweredBy">Powered By</div>
        <img class="oizom-logo" src="{{ oizomLogoUrl }}" (click)="openUrl()" />
      </div>
      <span
        *ngIf="currentPackage && currentPackage.version"
        class="versionDetail"
      >
        Version {{ currentPackage?.version }}
      </span>
    </div>
  </nb-sidebar>

  <nb-layout-column class="p-0">
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>
