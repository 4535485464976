import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NbThemeService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { SelectionTile } from '../../models/internal-use-front-end/selection-tile';

@Component({
  selector: 'app-selection-tile',
  templateUrl: './selection-tile.component.html',
  styleUrls: ['./selection-tile.component.scss'],
})
export class SelectionTileComponent implements OnDestroy {
  @Input() tileDetails!: SelectionTile[];
  @Input() isMultiSelect: boolean = false;
  @Output() onSelection: EventEmitter<SelectionTile> = new EventEmitter();
  public tileForm!: FormGroup;
  public currentTheme: string = '';
  private subscriptions: Subscription[] = [];
  public imageUrl: string[] = [];

  private _initialSelection: number[] | number | undefined = undefined;
  public get initialSelection(): number[] | number | undefined {
    return this._initialSelection;
  }
  @Input() public set initialSelection(v: number[] | number | undefined) {
    this._initialSelection = v;
    this.setInitialSelection();
  }

  constructor(
    private formBuilder: FormBuilder,
    private themeService: NbThemeService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    if (!this.currentTheme) this.currentTheme = 'material-dark';
    this.setImages();
    this.tileForm = this.formBuilder.group({
      selectedTile: [null],
    });
    if (this.isMultiSelect) {
      this.tileForm = this.formBuilder.group({
        selectedTile: this.formBuilder.array([]),
      });
    }
    this.subscriptions.push(
      this.tileForm.valueChanges.subscribe((res) => {
        this.onSelection.emit(res.selectedTile);
      })
    );
    this.setInitialSelection();
  }

  private setInitialSelection() {
    let currentSelection: SelectionTile[] | SelectionTile | undefined =
      undefined;
    if (this.isMultiSelect) {
      let initialSelection = this.initialSelection as number[];
      currentSelection = this.tileDetails.filter((tile) =>
        initialSelection?.includes(tile.id)
      );
      if (currentSelection) {
        this.toggleTileSelection(currentSelection);
      }
    } else {
      currentSelection = this.tileDetails.find(
        (tile) => tile.id === this.initialSelection
      );
      if (currentSelection) {
        this.tileForm?.get?.('selectedTile')?.setValue?.(currentSelection);
      }
    }
  }

  public tileTrackBy(index: number, option: any) {
    return option.id;
  }

  setImages() {
    if (this.currentTheme === 'material-dark') {
      this.tileDetails.map((tile) => {
        if (tile.darkModeImageUrl) {
          this.imageUrl.push(tile.darkModeImageUrl);
        } else if (tile.lightModeImageUrl) {
          this.imageUrl.push(tile.lightModeImageUrl);
        } else {
          this.imageUrl.push(tile.imageUrl ?? '');
        }
      });
    } else if (this.currentTheme === 'material-light') {
      this.tileDetails.map((tile) => {
        if (tile.lightModeImageUrl) {
          this.imageUrl.push(tile.lightModeImageUrl);
        } else if (tile.darkModeImageUrl) {
          this.imageUrl.push(tile.darkModeImageUrl);
        } else {
          this.imageUrl.push(tile.imageUrl ?? '');
        }
      });
    }
  }

  // Helper to check if a tile is selected
  isTileSelected(tileDetail: any): boolean {
    return this.tileForm
      .get('selectedTile')
      ?.value?.some?.((selected: any) => selected.id === tileDetail.id);
  }

  // Toggle selection for a tile
  toggleTileSelection(tileDetail: any) {
    const selectedTiles = this.tileForm?.get('selectedTile') as FormArray;
    const index = selectedTiles?.value?.findIndex(
      (selected: any) => selected.id === tileDetail.id
    );

    if (index >= 0) {
      selectedTiles?.removeAt?.(index); // Deselect tile
    } else {
      selectedTiles?.push?.(this.formBuilder.control(tileDetail)); // Select tile
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}
