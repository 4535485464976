<div class="radioButton p-1" [formGroup]="tileForm" *ngIf="!isMultiSelect">
  <mat-radio-group formControlName="selectedTile" class="card-container">
    <ng-container
      *ngFor="
        let tileDetail of tileDetails;
        trackBy: tileTrackBy;
        let i = index
      "
    >
      <div class="card-tile">
        <mat-radio-button class="d-block w-100" [value]="tileDetail">
          <mat-card
            [ngStyle]="{
              border:
                tileDetail.id === tileForm.get('selectedTile')?.value?.id
                  ? (tileDetail.isPremium ? '2px solid var(--premium-gold-color)' : '2px solid var(--text-primary-focus-color)')
                  : '2px solid transparent',
              position: 'relative'
            }"
            [ngClass]="{
              'card-selected mat-elevation-z4':
                tileDetail.id === tileForm.get('selectedTile')?.value?.id
            }"
          >
            <mat-card-header
              class="d-flex flex-direction-column justify-content-between align-items-center p-0 border-rad"
            >
              <mat-card-subtitle class="py-1 px-2 text-truncate">
                {{ tileDetail.title }}
              </mat-card-subtitle>
              <div class="d-flex gap-2">
                <span *ngIf="tileDetail.isPremium" class="crown-icon" [matTooltip]="tileDetail?.premiumTooltip ?? ''">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--premium-gold-color)"><path d="M200-160v-80h560v80H200Zm0-140-51-321q-2 0-4.5.5t-4.5.5q-25 0-42.5-17.5T80-680q0-25 17.5-42.5T140-740q25 0 42.5 17.5T200-680q0 7-1.5 13t-3.5 11l125 56 125-171q-11-8-18-21t-7-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820q0 15-7 28t-18 21l125 171 125-56q-2-5-3.5-11t-1.5-13q0-25 17.5-42.5T820-740q25 0 42.5 17.5T880-680q0 25-17.5 42.5T820-620q-2 0-4.5-.5t-4.5-.5l-51 321H200Z"/></svg>
                </span>

                <mat-icon
                  *ngIf="tileDetail.icon"
                  class="material-symbols-outlined title-icon top-right"
                  [matTooltip]="tileDetail.iconTooltip ?? ''"
                  >{{ tileDetail.icon }}</mat-icon
                >
              </div>
            </mat-card-header>
            <mat-icon *ngIf="tileDetail.isPremium && tileDetail.isLocked" class="lock-overlay">lock</mat-icon>
            <mat-card-content class="card-content" [ngClass]="[(tileDetail.isPremium && tileDetail.isLocked) ? 'locked-card' : '']">
              <img mat-card-image [src]="imageUrl[i]" class="card-image" />
            </mat-card-content>
          </mat-card>
        </mat-radio-button>
      </div>
    </ng-container>
  </mat-radio-group>
</div>
<div class="checkboxButton p-1" [formGroup]="tileForm" *ngIf="isMultiSelect">
  <div class="card-container">
    <ng-container
      *ngFor="
        let tileDetail of tileDetails;
        trackBy: tileTrackBy;
        let i = index
      "
    >
      <div class="card-tile">
        <mat-card
          [ngStyle]="{
            border: isTileSelected(tileDetail)
              ? '2px solid var(--text-primary-focus-color)'
              : '2px solid transparent'
          }"
          [ngClass]="{
            'card-selected mat-elevation-z4': isTileSelected(tileDetail)
          }"
        >
          <mat-card-header
            class="d-flex flex-direction-column justify-content-between align-items-center p-0 border-rad"
          >
            <mat-card-subtitle class="py-1 px-2 text-truncate">
              {{ tileDetail.title }}
            </mat-card-subtitle>

            <mat-checkbox
              class="d-block"
              color="primary"
              [checked]="
                tileDetail.isDefaultSelected || isTileSelected(tileDetail)
              "
              [disabled]="tileDetail.disabled"
              (change)="toggleTileSelection(tileDetail)"
            ></mat-checkbox>

            <mat-icon
              *ngIf="tileDetail.icon"
              class="material-symbols-outlined title-icon top-right"
              [matTooltip]="tileDetail.iconTooltip ?? ''"
              >{{ tileDetail.icon }}</mat-icon
            >
          </mat-card-header>
          <mat-card-content class="card-content">
            <img mat-card-image [src]="imageUrl[i]" class="card-image" />
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
