import { Component, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { ConfigurationData } from 'src/app/shared/models/configuration/configuration-data';
import { FormsService } from 'src/app/shared/services/forms.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UnitsInternal } from 'src/app/shared/models/internal-use-front-end/units-internal';

@Component({
  selector: 'app-lan-form',
  templateUrl: './LAN-form.component.html',
  styleUrls: ['./LAN-form.component.scss'],
})
export class LANFormComponent {
  public configurationForm!: FormGroup;
  formattedHexValue: string = '';
  formattedDeviceAdd: string = '';
  formattedNetworkKey: string = '';
  formattedAppKey: string = '';
  baudrateList = AppConstants.BAUDRATE_LIST;
  modbus_mode = AppConstants.MODBUS_MODE;
  parity_list = AppConstants.PARITY_LIST;
  class_list = AppConstants.CLASS_LIST;
  network_list = AppConstants.NETWORK_LIST;
  tcp_mqtt_mode = AppConstants.TCP_MQTT_MODE;
  isEdit!: boolean;
  isNextStep: boolean = false;
  devEUIDecimalTOHex: any;
  appKeyDecimalTOHex: any;
  deviceAddDecimalToHex: any;
  networkKeyDecimalToHex: any;
  isMqqtFieldsVisible!: boolean;
  public allLANConfigData!: any;
  configJsonData: any;
  public allParamUnits!: UnitsInternal.DeviceTypeWiseUnitInfoForTable;
  public allUnits!: UnitsInternal.DeviceTypeAndUnits;
  public fieldUnits: UnitsInternal.FieldUnits = [];
  flabels: any;
  parameters: any;
  unitList: any;

  selectedParameter!: any;
  selectedUnit!: any;
  constructor(
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    @Inject(MTX_DRAWER_DATA) public data: ConfigurationData.Get | any
  ) {}
  ngOnInit() {
    this.parameters = this.data?.paramData;
    const filteredParameters = Object.keys(this.parameters)
      .filter((key) => key !== 'lat' && key !== 'lon')
      .reduce((obj, key) => {
        obj[key] = this.parameters[key];
        return obj;
      }, {} as any);

    this.flabels = Object.values(filteredParameters).map((array: any) => {
      return array[0]?.flabel;
    });
    this.configurationService.isConfigData$.subscribe((data: boolean) => {
      this.allLANConfigData = this.configurationService.configurationData;
      this.configJsonData = this.allLANConfigData.config;
    });
    if (this.data?.label === 'LoRa') {
      this.devEUIDecimalTOHex =
        this.data.deveui.length > 0
          ? this.data.deveui
              .map((byte: any) => byte.toString(16).padStart(2, '0'))
              .join(' ')
              .toUpperCase()
          : null;

      this.appKeyDecimalTOHex =
        this.data.appkey.length > 0
          ? this.data.appkey
              .map((byte: any) => byte.toString(16).padStart(2, '0'))
              .join(' ')
              .toUpperCase()
          : null;

      this.deviceAddDecimalToHex =
        this.data?.dev?.length > 0
          ? this.data?.dev
              .map((byte: any) => byte.toString(16).padStart(2, '0'))
              .join(' ')
              .toUpperCase()
          : null;

      this.networkKeyDecimalToHex =
        this.data?.nwkey?.length > 0
          ? this.data?.nwkey
              .map((byte: any) => byte.toString(16).padStart(2, '0'))
              .join(' ')
              .toUpperCase()
          : null;
    }

    this.isEdit = !!this.data;
    if (this.data?.label == 'Display' || this.data?.label === '4-20 MA') {
      this.initializeFormValues();
    }

    this.buildForm(this.data.label);
    this.isMqqtFieldsVisible =
      !!this.configurationForm.get('tcpMqttMode')?.value;

    if (this.isMqqtFieldsVisible) {
      this.configurationForm.get('user')?.setValidators(Validators.required);
      this.configurationForm
        .get('password')
        ?.setValidators(Validators.required);
      this.configurationForm.get('topic')?.setValidators(Validators.required);
      this.configurationForm
        .get('user')
        ?.updateValueAndValidity({ emitEvent: false });
      this.configurationForm
        .get('password')
        ?.updateValueAndValidity({ emitEvent: false });
      this.configurationForm
        .get('topic')
        ?.updateValueAndValidity({ emitEvent: false });
    } else {
      this.configurationForm.get('user')?.clearValidators();
      this.configurationForm.get('password')?.clearValidators();
      this.configurationForm.get('topic')?.clearValidators();
      this.configurationForm
        .get('user')
        ?.updateValueAndValidity({ emitEvent: false });
      this.configurationForm
        .get('password')
        ?.updateValueAndValidity({ emitEvent: false });
      this.configurationForm
        .get('topic')
        ?.updateValueAndValidity({ emitEvent: false });
    }
    if (this.data?.label == 'TCP / MQTT') {
      this.configurationForm.valueChanges.subscribe(() => {
        this.isMqqtFieldsVisible =
          !!this.configurationForm.get('tcpMqttMode')?.value;
        if (this.isMqqtFieldsVisible) {
          this.configurationForm
            .get('user')
            ?.setValidators(Validators.required);
          this.configurationForm
            .get('password')
            ?.setValidators(Validators.required);
          this.configurationForm
            .get('topic')
            ?.setValidators(Validators.required);
          this.configurationForm
            .get('user')
            ?.updateValueAndValidity({ emitEvent: false });
          this.configurationForm
            .get('password')
            ?.updateValueAndValidity({ emitEvent: false });
          this.configurationForm
            .get('topic')
            ?.updateValueAndValidity({ emitEvent: false });
        } else {
          this.configurationForm?.get('user')?.clearValidators();
          this.configurationForm?.get('password')?.clearValidators();
          this.configurationForm?.get('topic')?.clearValidators();

          this.configurationForm
            .get('user')
            ?.updateValueAndValidity({ emitEvent: false });
          this.configurationForm
            .get('password')
            ?.updateValueAndValidity({ emitEvent: false });
          this.configurationForm
            .get('topic')
            ?.updateValueAndValidity({ emitEvent: false });
        }
      });
    }
    this.configurationForm.markAllAsTouched();
  }

  buildForm(label: string) {
    this.configurationForm = this.formBuilder.group({
      ...(label === 'Modbus' && {
        baudrate: [
          this.isEdit ? this.data?.gpio?.baud : '',
          Validators.required,
        ],
        modbusMode: [
          this.isEdit
            ? this.getModbusModeValue(this.data?.modconfig?.mode)
            : '',
          Validators.required,
        ],
        parity: [
          this.data.gpio?.parity
            ? this.getParityValue(this.data.gpio?.parity)
            : this.parity_list[0]?.value,
          Validators.required,
        ],
        slaveid: [
          this.isEdit ? this.data?.modconfig?.slaveid : '',
          [Validators.min(1), Validators.max(255), Validators.required],
        ],
      }),
      ...(label === 'LoRa' && {
        devEUI: [
          this.devEUIDecimalTOHex,
          [
            Validators.required,
            Validators.pattern(/^[A-Fa-f0-9\s]+$/),
            Validators.minLength(23),
            Validators.maxLength(23),
          ],
        ],
        deviceAddress: [
          this.deviceAddDecimalToHex,
          [
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(11),
            Validators.pattern(/^[A-Fa-f0-9\s]+$/),
          ],
        ],
        networkSessionKey: [
          this.networkKeyDecimalToHex,
          [
            Validators.required,
            Validators.minLength(47),
            Validators.maxLength(47),
            Validators.pattern(/^[A-Fa-f0-9\s]+$/),
          ],
        ],
        appSessionKey: [
          this.appKeyDecimalTOHex,
          [
            Validators.required,
            Validators.minLength(47),
            Validators.maxLength(47),
            Validators.pattern(/^[A-Fa-f0-9\s]+$/),
          ],
        ],
        loraClass: [
          this.isEdit ? this.getLoRaClassValue(this.data?.loraclass) : '',
          Validators.required,
        ],
        loraMode: [
          this.isEdit ? this.getNetworkModeValue(this.data?.loramode) : '',
          Validators.required,
        ],
      }),
      ...(label === 'TCP / MQTT' && {
        tcpMqttMode: [
          this.data?.mqtt ? this.getMqqtModeValue(1) : 0,
          Validators.required,
        ],
        host: [this.isEdit ? this.data.host : '', Validators.required],
        port: [this.isEdit ? this.data.port : '', Validators.required],
        user: [this.isEdit ? this.data?.user : ''],
        password: [this.isEdit ? this.data.password : ''],
        topic: [this.isEdit ? this.data.topic : ''],
      }),
      ...(label === 'Display' && {
        //parameter: [this.selectedParameter || '', Validators.required],
        unit: [this.selectedUnit || '', Validators.required],
      }),
      ...((label === 'Display' || label === '4-20 MA') && {
        parameter: [this.selectedParameter || '', Validators.required],
      }),
      ...(label === '4-20 MA' && {
        // parameter: [this.selectedParameter || '', Validators.required],
        max: [this.isEdit ? this.data?.max : null, Validators.required],
        min: [this.isEdit ? this.data?.min : null, Validators.required],
        addr: [this.isEdit ? this.data?.addr : null, Validators.required],
        debug: [this.isEdit ? this.data?.debug : null, Validators.required],
        dacmax: [this.isEdit ? this.data?.dacmax : null, Validators.required],
        dacmin: [this.isEdit ? this.data?.dacmin : null, Validators.required],
      }),
      lanStatus: [this.data?.en],
    });
  }

  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  generatePayload() {
    const selectedParameter = this.configurationForm.get('parameter')?.value;
    const selectedUnit = this.configurationForm.get('unit')?.value;
    const paramKey = Object.keys(this.parameters).find((key) =>
      this.parameters[key].some(
        (param: any) => param.flabel === selectedParameter
      )
    );
    if (this.configurationForm.valid) {
      let updatedPayload: any = {};
      if (this.data?.label === 'LoRa') {
        updatedPayload = {
          lora: {
            en: this.configurationForm.get('lanStatus')?.value ? 1 : 0,
            deveui: this.hexToDecimal(
              this.configurationForm.get('devEUI')?.value
            ),
            dev: this.hexToDecimal(
              this.configurationForm.get('deviceAddress')?.value
            ),
            nwkey: this.hexToDecimal(
              this.configurationForm.get('networkSessionKey')?.value
            ),
            appkey: this.hexToDecimal(
              this.configurationForm.get('appSessionKey')?.value
            ),
            loramode: this.configurationForm.get('loraMode')?.value,
            loraclass: this.configurationForm.get('loraClass')?.value,
          },
        };
      } else if (this.data?.label === 'Modbus') {
        updatedPayload = {
          modbus: {
            en: this.configurationForm.get('lanStatus')?.value ? 1 : 0,
            gpio: {
              ...this.configJsonData.modbus.gpio,
              baud: this.configurationForm.get('baudrate')?.value,
              parity: this.configurationForm.get('parity')?.value,
            },
            hostconfig: { ...this.configJsonData.modbus.hostconfig },
            modconfig: {
              mode: this.configurationForm.get('modbusMode')?.value,
              size: { ...this.configJsonData.modbus.modconfig.size },
              slaveid: this.configurationForm.get('slaveid')?.value,
            },
          },
        };
      } else if (this.data?.label === 'TCP / MQTT') {
        updatedPayload = {
          tcp: {
            en: this.configurationForm.get('lanStatus')?.value ? 1 : 0,
            host: this.configurationForm.get('host')?.value,
            port: this.configurationForm.get('port')?.value,
            ...(this.isMqqtFieldsVisible
              ? {
                  mqtt: 1,
                  user: this.configurationForm.get('user')?.value,
                  password: this.configurationForm.get('password')?.value,
                  topic: this.configurationForm.get('topic')?.value,
                }
              : {}),
          },
        };
      } else if (this.data?.label === 'Display') {
        updatedPayload = {
          display: {
            en: this.configurationForm.get('lanStatus')?.value ? 1 : 0,
            sTime: this.data.sTime,
            parameters: [
              {
                ...this.data.parameters[0],
                sc: paramKey,
                un: selectedUnit,
              },
            ],
          },
        };
      } else if (this.data?.label === '4-20 MA') {
        updatedPayload = {
          current: {
            addr: this.configurationForm.get('addr')?.value,
            dacmax: this.configurationForm.get('dacmax')?.value,
            dacmin: this.configurationForm.get('dacmin')?.value,
            debug: this.configurationForm.get('debug')?.value,
            en: this.configurationForm.get('lanStatus')?.value ? 1 : 0,
            max: this.configurationForm.get('max')?.value,
            min: this.configurationForm.get('min')?.value,
            sc: paramKey,
          },
        };
      }
      const mergedPayload = {
        ...this.allLANConfigData,
        config: {
          ...this.allLANConfigData.config,
          ...updatedPayload,
        },
      };
      this.submitForm({
        userId: this.localStorageService.getValue(
          LocalStorageConstants.USER_ID
        ),
        update_note: '(From Envizom) Configuration updated',
        data: { ...mergedPayload },
      });
    } else {
      this.notificationService.showSnackBar(
        'All the fields in the form is mandatory.',
        'error'
      );
    }
  }

  submitForm(payload: any) {
    this.loadrService.showLoader();
    this.configurationService
      .updateLanForm(payload, this.allLANConfigData.deviceId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.loadrService.removeLoader();
            this.configurationService.getConfigurationData(
              this.allLANConfigData.deviceId
            );
            this.notificationService.showSnackBar(res, 'success');
            this.formsService.closeForm();
          }
        },
        error: (err) => {
          console.info("Error:", err);
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar(err, 'error');
        },
      });
  }

  onBaudrateChange(event: any) {}

  onDevEUIHexInput(event: any): void {
    let input = event.target.value.replace(/\s+/g, '');
    if (input.length > 16) {
      input = input.slice(0, 16);
    }
    this.formattedHexValue = input.match(/.{1,2}/g)?.join(' ') || input;
    const decimalValues =
      input.match(/.{1,2}/g)?.map((hex: any) => parseInt(hex, 16).toString()) ||
      [];
    this.configurationForm
      .get('devEUI')
      ?.setValue(this.formattedHexValue, { emitEvent: false });

    this.validateHexField(input, 'devEUI');
  }

  deviceAddHexInput(event: any): void {
    let input = event.target.value.replace(/\s+/g, '');
    if (input.length > 8) {
      input = input.slice(0, 8);
    }
    this.formattedDeviceAdd = input.match(/.{1,2}/g)?.join(' ') || input;
    const decimalValues =
      input.match(/.{1,2}/g)?.map((hex: any) => parseInt(hex, 8).toString()) ||
      [];
    this.configurationForm
      .get('deviceAddress')
      ?.setValue(this.formattedDeviceAdd, { emitEvent: false });

    this.validateHexField(input, 'deviceAddress');
  }

  networkKeyHexInput(event: any): void {
    let input = event.target.value.replace(/\s+/g, '');
    if (input.length > 32) {
      input = input.slice(0, 32);
    }
    const decimalValues =
      input.match(/.{1,2}/g)?.map((hex: any) => parseInt(hex, 16).toString()) ||
      [];
    this.formattedNetworkKey = input.match(/.{1,2}/g)?.join(' ') || input;
    this.configurationForm
      .get('networkSessionKey')
      ?.setValue(this.formattedNetworkKey, { emitEvent: false });

    this.validateHexField(input, 'networkSessionKey');
  }

  appKeyHexInput(event: any): void {
    let input = event.target.value.replace(/\s+/g, '');
    if (input.length > 32) {
      input = input.slice(0, 32);
    }
    const decimalValues =
      input.match(/.{1,2}/g)?.map((hex: any) => parseInt(hex, 16).toString()) ||
      [];
    this.formattedAppKey = input.match(/.{1,2}/g)?.join(' ') || input;
    this.configurationForm
      .get('appSessionKey')
      ?.setValue(this.formattedAppKey, { emitEvent: false });

    this.validateHexField(input, 'appSessionKey');
  }

  validateHexField(value: string, name: string): void {
    const hexPattern = /^[A-Fa-f0-9]*$/;
    if (name == 'devEUI') {
      if (hexPattern.test(value)) {
        this.configurationForm.get('devEUI')?.setErrors(null);
      } else {
        this.configurationForm.get('devEUI')?.setErrors({ pattern: true });
      }
    }

    if (name == 'deviceAddress') {
      if (hexPattern.test(value)) {
        this.configurationForm.get('deviceAddress')?.setErrors(null);
      } else {
        this.configurationForm
          .get('deviceAddress')
          ?.setErrors({ pattern: true });
      }
    }
    if (name == 'networkSessionKey') {
      if (hexPattern.test(value)) {
        this.configurationForm.get('networkSessionKey')?.setErrors(null);
      } else {
        this.configurationForm
          .get('networkSessionKey')
          ?.setErrors({ pattern: true });
      }
    }
    if (name == 'appSessionKey') {
      if (hexPattern.test(value)) {
        this.configurationForm.get('appSessionKey')?.setErrors(null);
      } else {
        this.configurationForm
          .get('appSessionKey')
          ?.setErrors({ pattern: true });
      }
    }
  }

  restrictHexInput(event: KeyboardEvent): void {
    const allowedKeys = /^[0-9A-Fa-f]$/;
    const key = event.key;
    if (
      key === 'Backspace' ||
      key === 'Delete' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      key === 'Tab'
    ) {
      return;
    }
    if (!allowedKeys.test(key)) {
      event.preventDefault();
    }
  }

  getModbusModeValue(mode: string): any {
    const selectedMode = this.modbus_mode.find(
      (item: any) => item?.value === mode
    );
    return selectedMode ? selectedMode.value : null;
  }

  getLoRaClassValue(value: string): any {
    const selectedClass = this.class_list.find(
      (item: any) => item?.value === value
    );
    return selectedClass ? selectedClass.value : null;
  }

  getParityValue(value: string): any {
    const selectedClass = this.parity_list.find(
      (item: any) => item?.value === value
    );
    return selectedClass ? selectedClass.value : null;
  }

  getMqqtModeValue(value: any): any {
    const selectedClass = this.tcp_mqtt_mode.find(
      (item: any) => item?.value === value
    );
    return selectedClass ? selectedClass.value : null;
  }

  getNetworkModeValue(value: any): any {
    const selectedClass = this.network_list.find(
      (item: any) => item?.value === value
    );
    return selectedClass ? selectedClass.value : null;
  }

  onBlur(fieldName: string): void {
    const control = this.configurationForm.get(fieldName);
    if (control) {
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  }

  hexToDecimal(hex: string): number[] {
    const cleanedHex = hex.replace(/\s+/g, '');
    const decimalValues = [];
    for (let i = 0; i < cleanedHex.length; i += 2) {
      const byte = cleanedHex.substring(i, i + 2);
      decimalValues.push(parseInt(byte, 16));
    }
    return decimalValues;
  }

  initializeFormValues(): void {
    const selectedParam = this.data?.parameters
      ? this.data.parameters[0]?.sc
      : this.data?.sc;
    const selectedUnit = this.data?.parameters
      ? this.data.parameters[0]?.un
      : null;
    const paramKey = Object.keys(this.parameters).find(
      (key) => key === selectedParam
    );
    if (paramKey) {
      const selectedParamFlabel = this.parameters[paramKey][0]?.flabel;
      this.selectedParameter = selectedParamFlabel;
      this.unitList = this.parameters[paramKey].map((unit: any) => unit.label);
      this.selectedUnit = selectedUnit;
    } else {
      this.selectedParameter = null;
      this.selectedUnit = null;
    }
    // this.configurationForm.patchValue({
    //   parameter: this.selectedParameter,
    //   unit: this.selectedUnit
    // });
  }

  onParameterChange(selectedFlabel: string): void {
    const selectedParamKey = Object.keys(this.parameters).find((key) => {
      const paramArray = this.parameters[key];
      return paramArray.some((item: any) => item.flabel === selectedFlabel);
    });

    if (selectedParamKey) {
      const correspondingUnits = this.parameters[selectedParamKey];
      this.unitList = correspondingUnits.map((unit: any) => unit.label);
      this.selectedUnit = null;
    } else {
      this.unitList = [];
    }
    this.configurationForm.get('unit')?.reset();
  }
}
