<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-3" appearance="outline">
        <mat-label>Start Date</mat-label>
        <input
          matInput
          [max]="maxDate"
          formControlName="startDate"
          [matDatepicker]="startDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="startDatePicker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-3" appearance="outline">
        <mat-label>End Date</mat-label>
        <input
          matInput
          [min]="headerForm.controls['startDate'].value ?? maxDate"
          [max]="maxDate"
          formControlName="endDate"
          [matDatepicker]="endDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="endDatePicker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
      <div class="col col-lg-auto col-12 col-lg-2 apply">
        <button
          matTooltip="Apply"
          (click)="onApply()"
          mat-raised-button
          color="primary"
          class="w-100 w-lg-auto"
        >
          Apply
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
