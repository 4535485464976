<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-2">
        <mat-label>{{ "deviceName" | translate }}</mat-label>
        <input
          type="text"
          [placeholder]="'deviceName' | translate"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : headerForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <button mat-icon-button matSuffix tabindex="-1">
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-2" appearance="outline">
        <mat-label>Select Date</mat-label>
        <input
          matInput
          [min]="minDate"
          [max]="maxDate"
          formControlName="startDate"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div class="col col-lg-2 col-12 d-flex gap-3">
        <button (click)="tableFormChange()" mat-raised-button color="primary">
          Apply
        </button>
        <button
          (click)="flagTheData()"
          mat-raised-button
          color="primary"
          [disabled]="isFlagDisabled"
        >
          Flag
        </button>
      </div>
      <div class="col-12 col-lg-6">
        <div class="legend-container justify-content-end">
            <div class="legend-item">
              <span class="legend-color" style="background-color: #6ecc58"></span>
              <span>Corrected</span>
            </div>
            <div class="legend-item">
              <span class="legend-color" style="background-color: #eac736"></span>
              <span>Failure</span>
            </div>
        </div>
        <div class="legend-container justify-content-end">
          <div class="legend-item">
            <span class="legend-color" style="background-color: #ed9a2e"></span>
            <span>Invalid</span>
          </div>
          <div class="legend-item">
            <span class="legend-color" style="background-color: #d63636"></span>
            <span>Maintenance</span>
          </div>
        </div>
        <div class="legend-container justify-content-end">
          <div class="legend-item">
            <span class="legend-color" style="background-color: #8f3f97"></span>
            <span>Out Of Range</span>
          </div>
          <div class="legend-item">
            <span class="legend-color" style="background-color: #7e0023"></span>
            <span>Calibration</span>
          </div>
        </div>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
