import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { LoginData } from 'src/app/shared/models/login-data';
import { Project } from 'src/app/shared/models/project/project';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { ProjectExpiryFormComponent } from '../../components/project-expiry-form/project-expiry-form.component';
@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit, AfterViewInit {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  isArchive?: boolean;
  remainingSeats?: number;
  orgId!: string;
  userId!: number;
  user!: LoginData;
  projects: Project.Details[] = [];
  filterProjects: Project.Details[] = [];
  displayedColumns: string[] = [];
  projectOrgInfo: any;
  dialogComponentInstanceforSendMail!: ConfirmationPopupComponent;
  confirmationPopupBody: { [key: string]: string } = {
    plan: 'Are you sure you want to upgrade the plan?',
    seats: 'Are you sure you want to buy more seats?',
    sms: 'Are you sure you want to Buy 1000 SMS?',
    api: 'Are you sure you want to upgrade the API limit?',
  };

  public subscriptions: Subscription[] = [];

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    // minorText: 'Your device may be offline',
  };
  defaultColumns: any[] = [];
  moduleAccess: any;
  isAllOrg: boolean = false;

  constructor(
    private commonService: CommonService,
    public projectsService: ProjectsService,
    private localStorageService: LocalStorageService,
    private route: Router,
    private dialog: MatDialog,
    private loadrService: LoadrService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    private formservice: FormsService
  ) {
    this.defaultColumns = this.generateDefaultColumns();
  }

  ngOnInit(): void {
    this.user = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    this.defaultColumns = this.generateDefaultColumns();
    this.userId = this.user.userId;
    this.orgId = this.user.org;

    let allOrg = this.projectsService.sharedAllOrgData$.subscribe(
      (res: any) => {
        if (res != null) {
          this.isAllOrg = res;
          this.isAllOrg ? this.getAllOrgInfo() : this.loadData();
        } else {
          this.loadData();
        }
      }
    );
    this.subscriptions.push(allOrg);
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];

    let refresh = this.projectsService.refreshProjects$.subscribe((res) => {
      if (res === true) {
        this.loadData();
      }
    });
    this.subscriptions.push(refresh);

    this.moduleAccess = this.commonService.moduleAccessibility(1012);
  }

  ngAfterViewInit(): void {
    if (this.projects!.length > 0) {
      this.projectOrgInfo = this.projects![0];
      this.projectOrgInfo.expiry = this.customMomentService.formatDatetime({
        epoch: this.projectOrgInfo.expiry,
        format: 'DD/MM/YYYY',
      });
    }
    let shared = this.projectsService.sharedArchiveData$.subscribe(
      (res: any) => {
        if (res != undefined) {
          this.isArchive = res;
          this.getArchivedProjects();
        }
      }
    );
    this.subscriptions.push(shared);
  }

  generateDefaultColumns() {
    return [
      {
        columnDef: 'actions',
        header: 'View',
        cell: (element: Project.Details) => `${element.orgId}`,
        parameter: false,
        selected: false,
        filter: false,
        icon: 'more_vert',
        options: [
          {
            label: (element: Project.Details) =>
              `<span class="material-symbols-outlined">visibility</span>&nbsp View`,
            action: (element: Project.Details) => this.updateProject(element),
          },
          ...(this.user?.topLevel
            ? [
                {
                  label: (element: Project.Details) =>
                    `<span class="material-symbols-outlined">event</span>&nbsp Set Expiry`,
                  action: (element: Project.Details) => {
                    if (!this.isSetExpiryDisabled(element)) {
                      this.setProjectExpiry(element);
                    }
                  },
                  disable: (element: Project.Users) =>
                    this.isSetExpiryDisabled(element),
                },
              ]
            : []),
        ],
      },
      {
        columnDef: 'orgId',
        header: 'Project ID',
        cell: (element: Project.Details) => `${element.orgId}`,
        parameter: false,
        selected: false,
        filter: false,
      },
      {
        columnDef: 'orgName',
        header: 'Project Name',
        cell: (element: Project.Details) => `${element.orgName}`,
        parameter: false,
        selected: false,
        filter: false,
      },
      {
        columnDef: 'email',
        header: 'Email',
        cell: (element: Project.Details) => `${element.email}`,
        parameter: false,
        selected: false,
        filter: false,
      },
      {
        columnDef: 'contactName',
        header: 'Contact Name',
        cell: (element: Project.Details) => `${element.contactName}`,
        parameter: false,
        selected: false,
        filter: false,
      },
      {
        columnDef: 'seats',
        header: 'Seats',
        cell: (element: Project.Details) =>
          !element.archived
            ? `<div class="ms-1">${element.info.used_seats} / ${element.info.max_seats}</div>`
            : '<div class="ms-1">-</div>',
        parameter: false,
        selected: false,
        filter: false,
      },
      ...(this.user?.topLevel
        ? [
            {
              columnDef: 'expiry',
              header: 'Expiry Date',
              cell: (element: Project.Details) =>
                `${
                  element.info.expiry
                    ? this.customMomentService.formatDatetime({
                        epoch: element.info.expiry,
                        format: 'DD/MM/YY',
                      })
                    : '--'
                }`,
              parameter: false,
              selected: false,
              filter: false,
            },
          ]
        : []),
      {
        columnDef: 'dynamicIconAction',
        header: 'Archive',
        cell: (element: Project.Details) =>
          `<div class="gap-1">${element.archived}</div>`,
        icon: (element: Project.Details) =>
          this.setArchiveIcon(element.archived),
        parameter: false,
        selected: false,
        filter: false,
        disable: (element: Project.Details) => this.disableArchiveIcon(element),
        action: (element: Project.Details) => {
          if (!this.disableArchiveIcon(element)) {
            this.updateArchiveIcon(element);
          }
        },
      },
    ];
  }
  loadData(): void {
    this.loadTable.next(false);
    this.projectsService
      .getUsersAllOrgsInfo(this.userId, '', this.isAllOrg)
      .subscribe({
        next: (res: any) => {
          this.projects = this.isArchive
            ? res.filter((org: any) => org.archived)
            : res.filter((org: any) => !org.archived);
          this.filterProjects = res;

          if (res) {
            this.setProjectInfo();
          }
          this.loadTable.next(true);
        },
        error: (err) => {
          this.loadTable.next(true);
        },
      });
  }

  isSetExpiryDisabled(element: any) {
    return element.masterOrgId !== this.user.org;
  }

  getAllOrgInfo() {
    this.loadTable.next(false);
    this.projectsService.getAllOrgOrgInfo(this.userId).subscribe((res) => {
      this.loadTable.next(true);
      this.filterProjects = res;

      if (!this.projectOrgInfo) {
        this.setProjectInfo();
      }
      this.projects = this.isArchive
        ? res.filter((org: any) => org.archived)
        : res.filter((org: any) => !org.archived);
      this.addMasterOrgColumn();

      this.loadTable.next(true);
    });
  }

  getArchivedProjects() {
    if (this.isArchive) {
      this.projects = this.filterProjects?.filter(
        (project) => project.archived
      );
    } else {
      this.projects = this.filterProjects?.filter(
        (project) => !project.archived
      );
    }
  }

  addMasterOrgColumn() {
    const masterOrgColumn = {
      columnDef: 'masterOrgId',
      header: 'Master Org',
      cell: (element: Project.Details) => `${element.masterOrgId ?? '-'}`,
      parameter: false,
      selected: false,
      filter: false,
    };

    if (!this.defaultColumns.some((col) => col.columnDef === 'masterOrgId')) {
      this.defaultColumns.splice(
        this.defaultColumns.length - 1,
        0,
        masterOrgColumn
      );
    }
    this.displayedColumns = this.defaultColumns.map((c) => c.columnDef);
  }

  setProjectInfo() {
    if (this.isAllOrg) {
      this.addMasterOrgColumn();
    } else {
      this.defaultColumns = this.generateDefaultColumns();
      this.displayedColumns = this.defaultColumns.map((c) => c.columnDef);
    }

    this.projectsService.getMasterOrgInfo(this.userId).subscribe((res) => {
      this.projectOrgInfo = JSON.parse(JSON.stringify(res.info));
      // this.projectOrgInfo = res.info
      this.projectOrgInfo.expiry = this.customMomentService.formatDatetime({
        epoch: this.projectOrgInfo.expiry,
        format: 'DD/MM/YYYY',
      });
      this.projectsService.updateSeats(res.info);
      //update the info in local storage
      let user: LoginData = this.commonService.getUser();
      user.info = res.info;
      user.organization.info = res.info;
      this.localStorageService.saveValue(
        LocalStorageConstants.OZ_USER,
        JSON.stringify(user)
      );
    });
  }

  setArchiveIcon(res: any) {
    let icon;
    if (res) {
      icon = 'unarchive';
    } else {
      icon = 'archive';
    }
    return icon;
  }

  updateArchiveIcon(res: any): void {
    this.projectsService
      .setOrgArchive(this.userId, res.orgId, {
        archive: !res.archived,
        email: res.email,
      })
      .subscribe({
        next: (res: any) => {
          if (res) this.loadData();
          this.notificationService.showSnackBar(res, 'success');
        },
        error: (err: any) => {
          this.notificationService.showSnackBar(err, 'error');
          console.info('Error:', err);
        },
      });
  }

  disableArchiveIcon(res: any): any {
    let value;
    if (res.info.seats < res.info.seats && res.archived) {
      value = true;
    } else if (res.masterOrgId !== this.user.org) {
      return true;
    } else {
      value = false;
    }
    return value;
  }

  updateProject(res: any) {
    let updatedRes = JSON.parse(JSON.stringify(res));
    delete updatedRes.cellValues;
    if (updatedRes) {
      // this.route.navigateByUrl('/projects/project-edit/' + updatedRes.orgId+'?projectName='+updatedRes.orgName, {
      //   state: updatedRes,
      // });
      this.route.navigateByUrl('/projects/project-edit/' + updatedRes.orgId, {
        state: updatedRes,
      });
    } else {
    }
  }

  setProjectExpiry(res: any) {
    let updatedRes = JSON.parse(JSON.stringify(res));
    let form = this.formservice
      .openForm(ProjectExpiryFormComponent, { userData: updatedRes }, 'sm')
      .subscribe((res) => {});
    this.subscriptions.push(form);
  }

  openDialog(buttonName: string): void {
    const dynamicData = {
      message: this.confirmationPopupBody[buttonName],
      icon: 'delete',
      buttonconfig: [
        {
          text: 'No',
          closeDialog: true,
        },
        {
          text: 'Yes',
          onClickCallback: () => {
            this.sendMail(buttonName);
          },
          closeDialog: true,
          color: 'primary',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.projectsService.getDefaultDialogConfig,
      { data: dynamicData }
    );
    const dialogReffoSendMail = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogComponentInstanceforSendMail =
      dialogReffoSendMail.componentInstance;
    this.projectsService.getDialogRef(dialogReffoSendMail);
  }

  sendMail(buttonName: string) {
    let payload = {
      plan: this.user.info.plan,
      request: buttonName,
    };
    this.loadrService.showLoader();
    this.projectsService
      .sendUpgradeEmail(this.userId, buttonName, payload)
      .subscribe((res: any) => {
        this.loadrService.removeLoader();
        this.notificationService.showSnackBar(res, 'success');
      });
  }

  getProjectModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  exportProjectsDataToCSV(): void {
    if (!this.projects || !this.projects.length) {
      return;
    }
    const filteredColumns = this.defaultColumns.filter(
      (column) => column.columnDef !== 'actions'
    );

    const csvRows = [];
    const headers = filteredColumns.map((column) =>
      column.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));
    this.projects.forEach((project) => {
      const data = filteredColumns.map((column) => {
        const columnKey = column.columnDef;
        let cellValue;
        if (columnKey === 'seats') {
          cellValue = !project.archived
            ? `'${project.info.used_seats} / ${project.info.max_seats}'`
            : '-';
        } else if (columnKey === 'dynamicIconAction') {
          cellValue = project.archived ? 'Archived' : 'Active';
        } else {
          cellValue = column.cell(project);
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });

      csvRows.push(data.join(','));
    });

    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}.csv`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }
}
