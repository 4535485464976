import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
} from 'rxjs';
import { Socket } from 'socket.io-client';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AQIIndex } from '../models/aqi-index/aqi-index';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceField } from '../models/device/device-field';
import { Device } from '../models/device/device-info';
import { FieldLimit } from '../models/device/field-limit';
import { SensorExpiryDetails } from '../models/device/sensor-expiry-details';
import { Unit } from '../models/unit';
import { CommonUtil } from '../utils/common-utils';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { LocalStorageService } from './local-storage.service';
import { MqttSocketService } from './mqtt-socket.service';
import { UserService } from './user.service';

type Socket = typeof Socket;

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends BaseAPIService<DeviceDetails> {
  public currentDevice?: DeviceDetails;
  public currentDeviceType: any = {};
  readonly showRawAQIData: boolean = false;

  mqttDocs: any = {};
  rawCopyOfregisteredDevices: any = [];
  registeredDevices?: DeviceDetails[];
  registeredDevicesWithVibration?: DeviceDetails[];
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  incomingSocket?: Socket;
  isLocalSocketConnection: boolean = false;
  allAQIs!: Record<string, Record<number, AQIIndex>>;
  devicesHourlyData: Record<string, Array<any>> = {};
  devicesHourlyDataUpdates: Record<string, number> = {};
  private mQTTDocsSnapshot = new Subject<DeviceDetails>();
  mqttDocsSnapshot$ = this.mQTTDocsSnapshot.asObservable();

  private localStorageDeviceUpdate = new Subject<any>();
  localStorageDeviceUpdate$ = this.localStorageDeviceUpdate.asObservable();

  selectedDeviceId = new Subject<string>();
  selectedDeviceId$ = this.selectedDeviceId.asObservable();

  selectedDevice = new Subject<DeviceDetails | undefined>();
  selectedDevice$ = this.selectedDevice.asObservable();

  getFields = new Subject<void>();
  getFields$ = this.getFields.asObservable();

  getLimits = new Subject<void>();
  getLimits$ = this.getLimits.asObservable();

  getDevices = new Subject<void>();
  getDevices$ = this.getDevices.asObservable();

  private deviceDetailsSubject = new BehaviorSubject<Array<DeviceDetails>>([]);
  deviceDetails$ = this.deviceDetailsSubject.asObservable();

  refreshDeviceList = new Subject<boolean>();
  refreshDeviceList$ = this.refreshDeviceList.asObservable();

  public globalLoader: boolean = false;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private mqttSocketService: MqttSocketService,
    private userService: UserService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
  }

  setupDeviceDetails(
    showLoader: boolean,
    setupDeviceTypeGlobally: boolean = true
  ): Observable<boolean> {
    if (showLoader) {
      this.globalLoader = true;
    }

    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<DeviceDetails>>(
      APIConstants.DEVICE_DETAILS.replace('{userId}', userId),
      headers
    ).pipe(
      map((deviceDetails) => {
        // raw copy of device details
        this.rawCopyOfregisteredDevices = JSON.parse(
          JSON.stringify(deviceDetails)
        );

        return this.modifyDeviceDetails(
          deviceDetails,
          showLoader,
          setupDeviceTypeGlobally
        );
      }),

      catchError((err) => {
        if (showLoader) {
          this.globalLoader = false;
        }
        return of(false);
      })
    );
  }

  //to modify the device details (removing extra keys, unit conversion, etc...)
  modifyDeviceDetails(
    deviceDetails: Array<DeviceDetails>,
    showLoader: Boolean = false,
    setupDeviceTypeGlobally: boolean = true
  ) {
    this.registeredDevices = [];
    let allDeviceTypes: Array<number> = [];
    const deviceTypes: DeviceType[] =
      this.commonService.getUserDeviceTypesWithVibration();

    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const allUnits: any = this.commonService.getAllUnits();

    deviceDetails.forEach((deviceDetail) => {
      allDeviceTypes.push(deviceDetail.deviceType as number);

      if (
        CommonUtil.isEmpty(this.currentDeviceType) &&
        setupDeviceTypeGlobally
      ) {
        this.currentDeviceType = DeviceUtil.findDeviceType(
          deviceDetail.deviceType,
          deviceTypes
        );
      }

      if (deviceDetail?.data?.payload) {
        Object.keys(deviceDetail.data.payload.d).forEach((key) => {
          if (
            ['temp', 't1', 'current', 'sg'].includes(key) &&
            deviceDetail.data!.payload.d[key] < 0
          ) {
            deviceDetail.data!.payload.d[key] = 0;
          }
        });

        try {
          deviceDetail.time = deviceDetail.data.payload.d.t;
          if (
            DeviceUtil.hasValidIndexForDeviceType(deviceDetail, deviceTypes)
          ) {
            const aqiData = DeviceUtil.calculateAQI(
              deviceDetail.data.payload,
              DeviceUtil.getBreakpoints(
                undefined,
                deviceDetail.deviceType,
                deviceTypes,
                allAqi,
                allAqis,
                aqi
              )
            );
            deviceDetail.aqi = aqiData.aqi;
            deviceDetail.aqiKey = aqiData.aqiKey;

            if (
              aqiData.aqi !== undefined &&
              aqiData.aqi !== null &&
              this.showRawAQIData
            ) {
              deviceDetail.data.payload.d.aqi = aqiData.aqi;
            }
          }
          deviceDetail.payload = DeviceUtil.convertUnits(
            deviceDetail.data.payload,
            allUnits[
              DeviceUtil.getDeviceTypeId(deviceDetail.deviceType, deviceTypes)!
            ]
          );
        } catch (e) {
          console.info('Error while parsing device details.', e);
        }
      }
      delete deviceDetail.data;
      if (deviceDetail.deviceId) {
        this.mqttDocs[deviceDetail.deviceId] = deviceDetail;
      }
      this.registeredDevices!.push(deviceDetail);
    });

    this.registeredDevicesWithVibration = this.registeredDevices;
    this.registeredDevices = this.registeredDevices
      .filter(
        (device) =>
          device.deviceType !== 'OMNIDOTS' && device.deviceType !== 'BEANAIR'
      )
      .sort(CommonUtil.sortAlphaNumDeviceDetailsAsc);

    const deviceTypeIndex = allDeviceTypes.findIndex(
      (type) => type === this.currentDeviceType.deviceTypeId
    );
    const allUserDeviceTypes: any[] = [];

    if (deviceTypeIndex < 0 && deviceTypes && setupDeviceTypeGlobally) {
      this.currentDeviceType = deviceTypes.find(
        (type) =>
          type.deviceTypeId ===
          DeviceUtil.getDeviceTypeId(allDeviceTypes[0], deviceTypes)
      );
    }

    allDeviceTypes = [...new Set(allDeviceTypes)];
    allDeviceTypes.forEach((deviceType) =>
      allUserDeviceTypes.push(
        DeviceUtil.findDeviceType(deviceType, deviceTypes)
      )
    );

    this.localStorageService.saveValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE,
      JSON.stringify(allUserDeviceTypes)
    );

    if (setupDeviceTypeGlobally) {
      this.setUpDeviceTypeGlobally(
        this.currentDeviceType.deviceTypeId,
        deviceTypes,
        allAqis,
        allUnits
      );
    }
    this.localStorageService.saveValue(
      LocalStorageConstants.OZ_ALL_PARAMS,
      JSON.stringify(
        DeviceUtil.getAllParams(this.registeredDevices, deviceTypes)
      )
    );

    deviceDetails.map((deviceDetail: DeviceDetails) => {
      let detail = { ...deviceDetail };
      detail.loc = deviceDetail.loc = deviceDetail.loc ?? '{got_undefined}';
      detail.latitude = deviceDetail.latitude = deviceDetail.latitude ?? 0;
      detail.longitude = deviceDetail.longitude ?? 0;
      return detail;
    });

    this.connectMQTTSocket();
    if (showLoader) {
      setTimeout(() => {
        this.globalLoader = false;
      }, 1500);
    }

    this.getDevices.next();
    this.deviceDetailsSubject.next(deviceDetails);
    return true;
  }

  getAllAQI(): Observable<boolean> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<AQIIndex>>(
      APIConstants.AQI_ALL.replace('{userId}', userId),
      headers
    ).pipe(
      map((aqis) => {
        const formattedAQIs: Record<string, Record<number, AQIIndex>> = {};
        aqis
          .map((aqi) => {
            let id = aqi.aqiIndexId;
            return { ...aqi, id };
          })
          .forEach((aqi) => {
            if (!formattedAQIs[aqi.deviceTypeId]) {
              formattedAQIs[aqi.deviceTypeId] = {};
            }
            formattedAQIs[aqi.deviceTypeId][aqi.id] = aqi;
          });
        this.allAQIs = formattedAQIs;
        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_ALL_AQI,
          JSON.stringify(formattedAQIs)
        );
        return true;
      })
    );
  }

  setupDeviceIdGlobally(deviceId: string) {
    const currentDevice = this.registeredDevicesWithVibration!.find(
      (d) => d.deviceId === deviceId
    );
    if (currentDevice) {
      this.setupDeviceGlobally(currentDevice);
    }
  }

  setupDeviceGlobally(device: DeviceDetails) {
    const currentDevice = this.registeredDevicesWithVibration!.find(
      (d) => d.deviceId === device.deviceId
    );
    const deviceTypes: DeviceType[] = this.commonService.getAllDeviceTypes();
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.commonService.getAllAQIs();
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const allUnits: any = this.commonService.getAllUnits();

    let deviceType;

    this.currentDevice = currentDevice;

    try {
      if (currentDevice) {
        deviceType = DeviceUtil.getDeviceTypeByKeyOrId(
          currentDevice.deviceType,
          deviceTypes
        );
        if (!deviceType) {
          deviceType = deviceTypes[0];
        }
        DeviceUtil.getColorForPin(
          currentDevice,
          deviceType,
          deviceType ? deviceType.index : false,
          this.mQTTDocsSnapshot,
          this.customMomentService.moment().unix(),
          AppConstants.SHOW_RAW_DATA_AQI,
          AppConstants.DEFAULT_APP_COLOR,
          false,
          deviceTypes,
          allAqi,
          allAqis,
          aqi,
          false
        );
      }
    } catch (err) {
      console.info('Error occurred when trying to get device status', err);
    }

    this.selectedDevice.next(this.currentDevice);

    this.setUpDeviceTypeGlobally(
      device.deviceType,
      deviceTypes,
      allAqis,
      allUnits
    );
  }

  removeDeviceGlobally() {
    this.currentDevice = undefined;
    this.localStorageDeviceUpdate.next(this.currentDeviceType);
  }

  setUpDeviceTypeGlobally(
    deviceType: any,
    deviceTypes: Array<DeviceType>,
    allAqis: any,
    allUnits: any
  ): void {
    const deviceTypeId = DeviceUtil.getDeviceTypeId(deviceType, deviceTypes)!;
    deviceType = DeviceUtil.findDeviceType(
      deviceTypeId,
      this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER_DEVICE_TYPE
      )
    );

    const aqi = allAqis ? allAqis[deviceTypeId] || {} : {};
    this.localStorageService.saveValue(
      LocalStorageConstants.OZ_AQI,
      JSON.stringify(aqi)
    );
    const unit = allUnits ? allUnits[deviceTypeId] || {} : {};

    this.currentDeviceType = deviceType;
    this.fetchFields(deviceTypeId, allUnits);
    this.fetchLimits(this.commonService.getAllUnits()[deviceTypeId]);

    this.localStorageService.saveValue(
      LocalStorageConstants.OZ_SELECTED_DEVICE_TYPE,
      JSON.stringify(this.currentDeviceType)
    );
    this.localStorageDeviceUpdate.next(this.currentDeviceType);
  }

  fetchFields(
    deviceTypeId: number,
    allUnits: any,
    setUpGlobally: boolean = true
  ): DeviceField[] {
    let fields: DeviceField[] = [];
    if (!deviceTypeId && this.currentDeviceType) {
      deviceTypeId = this.currentDeviceType.deviceTypeId;
    }

    if (allUnits && allUnits[deviceTypeId]) {
      fields = DeviceUtil.getFieldsByDeviceType(deviceTypeId, allUnits);

      if (setUpGlobally) {
        this.fields = JSON.parse(JSON.stringify(fields));
        this.getFields.next();
        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_FIELDS,
          JSON.stringify(this.fields)
        );
      }
    }
    return fields;
  }

  fetchLimits(units: any, setUpGlobally: boolean = true): FieldLimit[] {
    let limits = [];
    if (units) {
      for (let unit of Object.values(units) as Array<Unit>) {
        limits.push({
          fkey: unit.key,
          range: unit.limit,
          lowestLimit: unit.l_limit,
          highestLimit: unit.h_limit,
        });
      }

      if (setUpGlobally) {
        this.limits = JSON.parse(JSON.stringify(limits));
        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_ADMIN_LIMITS,
          JSON.stringify(this.limits)
        );
        this.getLimits.next();
      }
    }
    return limits;
  }

  connectMQTTSocket(): void {
    this.mqttSocketService.init(this.incomingSocket, this.registeredDevices);
    this.mqttSocketService.validateAndUpdateExistingSocketDevices(
      this.registeredDevices
    );

    if (this.isLocalSocketConnection) {
    } else {
      this.mqttSocketService.connect(
        this.incomingSocket!,
        this.registeredDevices!,
        this.showRawAQIData!,
        this.mqttDocs,
        this.fields,
        this.mQTTDocsSnapshot,
        this.userService.newNotificationSubject
      );
    }
  }

  getIndexData(
    gte: number,
    lte: number,
    avg: number,
    deviceIds: Array<string>,
    deviceType: any
  ): Observable<boolean> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const devices = deviceIds.toString();
    const indexRequest = {
      gte: parseInt(gte.toString()),
      lte: parseInt(lte.toString()),
      avg: avg,
      deviceIds: deviceIds,
    };

    const params: HttpParams = new HttpParams().appendAll({
      'deviceIds[]': devices?.split(','),
      gte: parseInt(gte.toString()),
      lte: parseInt(lte.toString()),
      avg: avg,
      userId: userId,
      processType: 'avg',
    });
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const allUnits: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_UNITS
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<DeviceDetails>>(
      APIConstants.LAST_24_HOURS_DEVICES_AVG,
      headers,
      params
    ).pipe(
      map((deviceData) => {
        if (deviceData) {
          deviceData.forEach((deviceDetail) => {
            try {
              if (Array.isArray(deviceDetail.payload)) {
                deviceDetail.payload.forEach((payloadDetail) => {
                  const aqiData = DeviceUtil.calculateAQI(
                    payloadDetail.payload,
                    DeviceUtil.getBreakpoints(
                      undefined,
                      payloadDetail.deviceType,
                      deviceTypes,
                      allAqi,
                      allAqis,
                      aqi
                    )
                  );
                  payloadDetail.payload = DeviceUtil.convertUnits(
                    payloadDetail.payload,
                    allUnits[
                      DeviceUtil.getDeviceTypeId(
                        payloadDetail.deviceType,
                        deviceTypes
                      )!
                    ]
                  );
                  payloadDetail.time = payloadDetail.payload.d.t;
                  payloadDetail.aqi = aqiData.aqi;
                  payloadDetail.aqiKey = aqiData.aqiKey;

                  if (aqiData.aqi !== undefined && aqiData.aqi !== null) {
                    payloadDetail.payload.d.aqi = aqiData.aqi;
                  }
                });
              }
            } catch (exception) {
              console.info(
                'Error while parsing device data. Error: ',
                exception
              );
            }

            if (!this.devicesHourlyData[deviceType.key]) {
              this.devicesHourlyData[deviceType.key] = [];
            }

            const deviceIndex = this.devicesHourlyData[
              deviceType.key
            ].findIndex((dev) => {
              return dev.deviceId === deviceDetail.deviceId;
            });

            if (deviceIndex > -1) {
              this.devicesHourlyData[deviceType.key].splice(deviceIndex, 1);
            }

            this.devicesHourlyData[deviceType.key].push(deviceDetail);
          });
        }
        return true;
      })
    );
  }

  getFieldName(field: any, allFields?: DeviceField[]) {
    if (allFields) {
      let index = allFields
        .map((x: any) => {
          return x.fkey;
        })
        .indexOf(field);
      if (index < 0) {
        return '' + field.toUpperCase();
      }
      return allFields[index].label;
    } else {
      let index = this.fields
        .map((x: any) => {
          return x.fkey;
        })
        .indexOf(field);
      if (index < 0) {
        return '' + field.toUpperCase();
      }
      return this.fields[index].label;
    }
  }

  getFieldUnit(field: any, allFields?: DeviceField[]) {
    if (!allFields) {
      allFields = this.fields;
    }
    let index = allFields
      .map((x: any) => {
        return x.fkey;
      })
      .indexOf(field);
    if (index < 0) {
      return '' + field.toUpperCase();
    }
    return allFields[index].unit;
  }

  provideLimitCss(field: string, value: number) {
    if (!this.limits?.length) {
      return '';
    }

    let limitIndex: number = this.limits
      .map((x: any) => {
        return x?.fkey;
      })
      .indexOf(field);
    if (limitIndex < 0) {
      return '';
    }
    if (this.limits[limitIndex].range.length == 0) {
      return '';
    }
    let rangeIndex: number;
    for (
      rangeIndex = 1;
      rangeIndex < this.limits[limitIndex].range.length;
      rangeIndex++
    ) {
      if (Math.min(value, this.limits[limitIndex].range[rangeIndex]) == value) {
        break;
      }
    }
    rangeIndex--;
    return 'l' + rangeIndex;
  }

  changeDeviceStatus(deviceId: string, status: 'on' | 'off') {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<any>>(
      APIConstants.UPDATE_DEVICE_STATUS(deviceId, status),
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAdminDevices(): Observable<Device.Info[]> {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<Device.Info>>(
      APIConstants.GET_SUB_USER_DEVICES(userId),
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addDevice(deviceId: string, deviceInfo: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<Array<DeviceDetails>>(
      APIConstants.ADD_DEVICE(deviceId),
      deviceInfo,
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateDevice(deviceId?: any, deviceInfo?: Device.Update) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.put<string>(
      APIConstants.UPDATE_DEVICE(deviceId, userId),
      deviceInfo,
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateImageURL(payload: File | null) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let formData: FormData = new FormData();
    formData.append('thumb', payload as Blob, payload?.name);

    return this.post(
      APIConstants.UPDATE_DEVICE_IMAGE().replace('{userId}', userId),
      formData,
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  generateLogoURL(payload: File | null) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let formData: FormData = new FormData();
    formData.append('thumb', payload as Blob, payload?.name);

    return this.post(
      APIConstants.UPLOAD_LOGO_IMAGE().replace('{userId}', userId),
      formData,
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateInterval(deviceId: string, intervalValue: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get(
      APIConstants.UPDATE_INTERVAL(deviceId, intervalValue),
      headers
    );
  }

  getSensorExpiryDetails(deviceId: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<SensorExpiryDetails>>(
      APIConstants.GET_SENSOR_EXPIRY_DETAILS(deviceId),
      headers
    );
  }

  setUpDeviceTypeForModule(
    deviceTypes: DeviceType[],
    devices: DeviceDetails[]
  ): DeviceType | undefined {
    if (deviceTypes.length < 1 || devices.length < 1) {
      return undefined;
    }
    const isGlobalDeviceTypeValid = deviceTypes.find(
      (deviceType) =>
        deviceType.deviceTypeId === this.currentDeviceType.deviceTypeId
    );

    if (!isGlobalDeviceTypeValid) {
      let device: DeviceDetails = devices.find(
        (device) => device.deviceType === deviceTypes[0].key
      )!;
      if (device) {
        this.setupDeviceIdGlobally(device.deviceId);
      }
      return deviceTypes[0];
    }
    return isGlobalDeviceTypeValid;
  }

  getMinDateOfDevice(device: DeviceDetails): moment.Moment {
    let minDate: moment.Moment;
    //setting min date as data from
    if (device?.dataFrom) {
      minDate = this.customMomentService.moment.unix(device.dataFrom);
    }
    //if datafrom not found than, setting min date as created_on
    else if (device?.created_on) {
      minDate = this.customMomentService.moment.unix(device.created_on);
    }
    //if both not found than min date as 01/01/2015
    else {
      minDate = this.customMomentService.moment('2015-01-01');
    }
    return minDate;
  }
}
