import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AnalyticsDetails } from '../models/analytics/analytics-details';
import { AnalyticsPayload } from '../models/analytics/analytics-payload';
import { DeviceDetails } from '../models/device/device-details';
import { CommonUtil } from '../utils/common-utils';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends BaseAPIService<DeviceDetails> {
  public isAnalyticsView = new BehaviorSubject<boolean>(true);
  isAnalyticsView$ = this.isAnalyticsView.asObservable();
  private analyticsFormDetails = new Subject<any>();
  analyticFormDetail$ = this.analyticsFormDetails.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private deviceService: DeviceService
  ) {
    super(http, cookieService);
  }

  changeValue(shouldRedirect: boolean) {
    if (this.isAnalyticsView.getValue() === true) {
      this.isAnalyticsView.next(false);
    } else if (this.isAnalyticsView.getValue() === false && shouldRedirect) {
      this.isAnalyticsView.next(true);
    } else if (this.isAnalyticsView.getValue() === false && !shouldRedirect) {
      this.isAnalyticsView.next(false);
    }
  }

  getFormDetails(importForm: any) {
    this.analyticsFormDetails.next(importForm);
  }

  getAnalyticList(userId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<any>>(
      APIConstants.GET_ANALYTICS_LIST(userId),
      headers
    ).pipe(
      map((res: AnalyticsPayload[]) =>
        res.map((obj: AnalyticsPayload) => {
          const analytic = {
            average: {
              value: obj.params.avg,
            },
            deviceId: obj.devices,
            endDate: obj.end_time,
            parameter: obj.params.fields,
            startDate: obj.start_time,
            analyticName: obj.title,
            updatedOn: obj.updated_on,
            userId: obj.userId,
            analyticsId: obj.analyticsId,
            analyticType: obj.analytics_type,
            ravg: obj.params.ravg,
            chart: obj.params?.chart
              ? obj.params.chart
              : obj.analyticsId === 4
              ? 'column'
              : 'line', // Use the value of obj.params.chart if it exists; otherwise, default to 'line' and 'column' for analytictype 4 .
            defaultView: obj.params.defaultView ?? true, // Use the value of obj.params.defaultView if it exists; otherwise, default to true.
          };

          //if analytic type device comparison
          if (obj.analytics_type == 2) {
            let deviceTypes = this.commonService.getUserDeviceTypes();
            let devices = this.deviceService.registeredDevices;
            let deviceType!: number;
            if (devices) {
              //than check all devices are of same device type in that analytic
              for (const d of obj.devices) {
                let dt = DeviceUtil.getDeviceTypeIdByDeviceId(
                  deviceTypes,
                  devices!,
                  d
                )!;

                if (!deviceType) deviceType = dt; //set the device type of first device

                //if devices are of different device type than change the analytic type
                //to Inter device comparison
                if (deviceType !== dt) {
                  analytic.analyticType = 5;
                  break;
                }
              }
            }
          }
          //above condition is checked because old terminal treats device comparison and
          //inter device comparison as one
          //Envizom treats both analytic type as different analytic type

          return analytic;
        })
      )
    );
  }

  getParamComparisonData(
    payload: AnalyticsDetails,
    deviceId: string | string[]
  ) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let paramObj: { [key: string]: any } = {
      'keys[]': payload.key.split(','),
      'deviceIds[]': deviceId,
      gte: payload.gte!,
      lte: payload.lte!,
      avg: payload.avg,
      userId: userId,
      processType:
        payload.avg == 0 ? 'raw' : payload?.isMovingAvg ? 'moving-avg' : 'avg',
    };

    if (payload.avg == 0) {
      delete paramObj.avg;
    }

    //deleting keys[] from params if user has selected aqi as parameter
    if (payload.key.includes('aqi')) {
      delete paramObj['keys[]'];
    }
    let params = new HttpParams().appendAll(paramObj);

    return this.get<Array<any>>(
      APIConstants.PARAMETER_COMPARISON,
      headers,
      params
    );
  }

  saveAnalyticsData(payload: AnalyticsPayload) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let updatedPayload = { data: {} };
    updatedPayload.data = CommonUtil.deleteKeysFromObject(
      ['deviceTypeId', 'userId'],
      payload
    );

    return this.post(
      APIConstants.ADD_NEW_ANALYTICS(userId),
      updatedPayload,
      headers
    );
  }

  updateAnalyticsDetails(payload: AnalyticsPayload) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const analyticsId = payload.analyticsId!;
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let updatedPayload = { update: {} };
    updatedPayload.update = CommonUtil.deleteKeysFromObject(
      ['analyticsId', 'deviceTypeId', 'userId'],
      payload
    );

    return this.patch(
      APIConstants.UPDATE_EXISTING_ANALYTICS(userId, analyticsId),
      updatedPayload,
      headers
    );
  }

  getDeviceTypeIdByDeviceId(deviceId: string) {
    const allUserDevices: DeviceDetails[] =
      this.deviceService.registeredDevices!;
    const deviceTypesOfUser = this.commonService.getUserDeviceTypes();
    let selectedDeviceData = allUserDevices.find(
      (allUserDevice) => allUserDevice.deviceId === deviceId
    );

    let deviceType = deviceTypesOfUser.find(
      (deviceTypeOfUser: any) =>
        deviceTypeOfUser.key === selectedDeviceData?.deviceType
    );

    //returns 0 if deviceType not found
    return deviceType ? deviceType.deviceTypeId : 0;
  }

  getDeviceTypeIdByDeviceLabel(deviceLabel: string) {
    const deviceTypesOfUser = this.commonService.getUserDeviceTypes();
    let deviceType = deviceTypesOfUser.find(
      (deviceTypeOfUser: any) => deviceTypeOfUser.key === deviceLabel
    )!;
    return deviceType.deviceTypeId;
  }

  getDeviceNameByDeviceId(deviceId: string) {
    const allUserDevices: DeviceDetails[] =
      this.deviceService.registeredDevices!;
    let deviceObject = allUserDevices.find(
      (allUserDevices: any) => allUserDevices.deviceId === deviceId
    );
    return deviceObject!.label;
  }

  getDeviceTypeByDeviceId(deviceId: string) {
    const deviceTypesOfUser = this.commonService.getUserDeviceTypes();
    let deviceTypeId = this.getDeviceTypeIdByDeviceId(deviceId);
    let device = deviceTypesOfUser.find(
      (device: any) => device.deviceTypeId === deviceTypeId
    )!;
    return device.key;
  }

  getLabelFromKey(param: Array<string>) {
    let fields = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_FIELDS
    );

    let tempArray = [];
    for (let i = 0; i < param.length; i++) {
      for (let k = 0; k < fields.length; k++) {
        if (param[i] === fields[k].fkey) {
          tempArray.push(fields[k].label);
        }
      }
    }
    return tempArray;
  }

  deleteAnalyticsData(analyticId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.delete(
      APIConstants.DELETE_ANALYTIC(userId, analyticId),
      headers
    );
  }

  resetValueToTrue() {
    this.isAnalyticsView.next(true);
  }

  //it is use to decide 'type of analytic' based on the value and show it in the analytic list
  getAnalyticsType(value: number): string {
    if (value === 1) {
      return 'Parameter Comparison Chart';
    } else if (value === 2) {
      return 'Device Comparison Chart';
    } else if (value === 4) {
      return 'Pollution Rose Chart';
    } else if (value === 5) {
      return 'Inter Device Comparison Chart';
    }
    return '';
  }
}
