<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      class="row h-100 align-items-center justify-content-between gx-2 gy-2 gy-lg-0 mx-0"
      [formGroup]="headerForm"
    >
    <!-- <div class="col-12"> -->
      <div class="col-12 d-flex flex-wrap align-items-center gap-2">
        <mat-form-field class="col-lg-2">
          <mat-label>Device Type</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Device Type'"
            matInput
            formControlName="deviceType"
            [matAutocomplete]="deviceType"
          />
          <mat-autocomplete
            #deviceType="matAutocomplete"
            [displayWith]="getDeviceType"
          >
            <mat-option
              *ngFor="
                let deviceType of deviceTypes
                  | search : headerForm.get('deviceType')?.value : 'label'
              "
              [value]="deviceType"
            >
              {{ deviceType.label }}
            </mat-option>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            mat-icon-button
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
          </button>
        </mat-form-field>
        <div class="ms-auto d-flex align-items-center gap-2">
          <button *ngIf="getAnalyticsModuleOptions('add')"
            mat-raised-button
            color="primary"
            (click)="addAnalytic()"
            class="w-100 w-lg-auto"
            matTooltip="Add Analytics"
          >
            Add Analytics
            <mat-icon matSuffix class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    <!-- </div> -->
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <ng-container>
      <div *ngIf="loadTable | async" class="d-flex flex-column h-full">
        <app-data-table
          tableTitle="Analytics List"
          [data]="
            analyticsList | deviceTypeWiseAnalytic : this.currentDeviceType
          "
          [columns]="defaultColumns"
          [displayedColumns]="displayedColumns"
          [showDeleteInHeader]="false"
          [showDownloadInHeader]="false"
          [noDataViewConfig]="noData"
          [showFilterInHeader]="false"
          [isChartVisible]="false"
        >
        </app-data-table>
      </div>
    </ng-container>
    <div class="py-5" *ngIf="(loadTable | async) == false">
      <mat-spinner
        class="mx-auto"
        [diameter]="50"
        color="primary"
      ></mat-spinner>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
