import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AppConstants } from '../constants/app-constants';
import { APIConstants } from '../constants/api-constants';
import { WindData } from 'src/app/shared/types/wind';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { LocalStorageService } from './local-storage.service';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root'
})
export class WindDataService extends BaseAPIService<any> {

  private fetchWindData: WindData[] = [];

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private deviceService: DeviceService,
  ) {
    super(http, cookieService);
  }

  /**
   * Retrieves dynamic wind data from the API.
   * 
   * @param heatmapId The heatmap identifier.
   * @param lte The upper bound timestamp.
   * @param gte The lower bound timestamp.
   * @param processType The type of process to apply ('raw', 'avg', or 'moving-avg'). Defaults to 'raw'.
   * @param needCtx Whether context information is needed. Defaults to false.
   * @param needImg Whether image data is needed. Defaults to true.
   * @returns An Observable stream of WindData array.
   */
  getWindDataForHeatmap(
    heatmapId: string,
    lte: number,
    gte: number,
    processType: string = 'raw',
    needCtx: boolean = true,
    needImg: boolean = false
  ): Observable<WindData[]> {
    const token = this.localStorageService.getValue(LocalStorageConstants.TOKEN);
    const clientId = this.localStorageService.getValue(LocalStorageConstants.CLIENT_ID);
    const userId = this.localStorageService.getValue(LocalStorageConstants.USER_ID);

    // Build query parameters
    let params: HttpParams = new HttpParams();
    params = params.append('gte', gte.toString());
    params = params.append('lte', lte.toString());
    params = params.append('processType', processType);
    params = params.append('needCtx', needCtx.toString());
    params = params.append('needImg', needImg.toString());

    // Build request headers
    const headers: HttpHeaders = new HttpHeaders()
      .append(AppConstants.AUTHORIZATION_HEADER, `${AppConstants.BEARER} ${token}`)
      .append(AppConstants.IBM_CLIENT, clientId);

    // Construct the URL using APIConstants (assumes APIConstants.HEATMAP_IMAGES is defined as "/users/{userId}/heatmap/{heatmapId}/images")
    const url = APIConstants.GET_HEATMAP_IMAGE_LAYERS
      .replace('{userId}', userId)
      .replace('{heatmapId}', heatmapId);

    return this.get<WindData[]>(url, headers, params).pipe(
      map(res => res),
      tap(data => {
        this.fetchWindData = data;
      })
    );
  }



  getWindDataForOverview(){
    const deviceData = this.deviceService.registeredDevicesWithVibration;
    const windData = deviceData?.map((device) => {
        return {
            lat: device.latitude,
            lon: device.longitude,
            direction: device?.payload?.d?.wd,
            magnitude: device.payload.d.ws,
        };
    });
    return windData;
  }


  getWindData = (points: number): WindData[] => {
    // generate 20 random wind data points
    // const windData = [];
    // for (let i = 0; i < points; i++) {
    //   windData.push({
    //     lat: Math.random() * 180 - 90,
    //     lon: Math.random() * 360 - 180,
    //     direction: Math.random() * 360,
    //     magnitude: Math.random() * 10,
    //   });
    // }
    const windData = [
      { "lat": 37.7749, "lon": -122.4194, "direction": 270, "magnitude": 5.5 },  // San Francisco, USA
      { "lat": 40.7128, "lon": -74.0060, "direction": 180, "magnitude": 7.2 },  // New York, USA
      { "lat": 34.0522, "lon": -118.2437, "direction": 90, "magnitude": 4.1 },  // Los Angeles, USA
      { "lat": 51.5074, "lon": -0.1278, "direction": 60, "magnitude": 3.8 },  // London, UK
      { "lat": 48.8566, "lon": 2.3522, "direction": 270, "magnitude": 6.0 },  // Paris, France
      { "lat": 35.6762, "lon": 139.6503, "direction": 0, "magnitude": 8.0 },  // Tokyo, Japan
      { "lat": -33.8688, "lon": 151.2093, "direction": 90, "magnitude": 5.3 },  // Sydney, Australia
      { "lat": -34.6037, "lon": -58.3816, "direction": 180, "magnitude": 7.5 },  // Buenos Aires, Argentina
      { "lat": 39.9042, "lon": 116.4074, "direction": 270, "magnitude": 4.7 },  // Beijing, China
      { "lat": 55.7558, "lon": 37.6173, "direction": 45, "magnitude": 5.2 },  // Moscow, Russia
      { "lat": 37.9838, "lon": 23.7275, "direction": 180, "magnitude": 3.6 },  // Athens, Greece
      { "lat": 40.7306, "lon": -73.9352, "direction": 90, "magnitude": 6.1 },  // Brooklyn, USA
      { "lat": 28.6139, "lon": 77.2090, "direction": 120, "magnitude": 6.8 },  // New Delhi, India
      { "lat": 19.4326, "lon": -99.1332, "direction": 270, "magnitude": 5.0 },  // Mexico City, Mexico
      { "lat": -22.9068, "lon": -43.1729, "direction": 0, "magnitude": 7.0 },  // Rio de Janeiro, Brazil
      { "lat": 40.7306, "lon": -73.9352, "direction": 90, "magnitude": 6.1 },  // Brooklyn, USA
      { "lat": 34.0522, "lon": -118.2437, "direction": 180, "magnitude": 4.4 },  // Los Angeles, USA
      { "lat": -34.4208, "lon": -58.4436, "direction": 45, "magnitude": 3.5 },  // Mendoza, Argentina
      { "lat": 41.9028, "lon": 12.4964, "direction": 300, "magnitude": 5.6 },  // Rome, Italy
      { "lat": 1.3521, "lon": 103.8198, "direction": 30, "magnitude": 7.8 }   // Singapore
    ]  
    return windData;
  };


  getDummyWindData(){
    const windData = [
      { "lat": 22.939161875869416, "lon": 72.63831354418747, "direction": 0, "magnitude": 15.5 },
      { "lat": 23.129727134776687, "lon": 72.52161418435978, "direction": 45, "magnitude": 15.5 },
      { "lat": 23.105517700599535, "lon": 72.68043060638064, "direction": 90, "magnitude": 15.5 },
      { "lat": 22.959361624663416, "lon": 72.47160017300496, "direction": 135, "magnitude": 15.5 },
    ]
    return windData;
  };

}
