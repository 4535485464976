<div class="h-100 pb-3" *ngIf="loading === 0" [tourAnchor]="'dashboard-tour-4'">
  <gridster [options]="options" class="bg-transparent">
    <ng-container *ngFor="let item of widgets; let i = index">
      <ng-container *ngIf="widgetsConfig?.[item.widgetId]">
        <gridster-item
          [item]="widgetsConfig[item.widgetId]"
          class="rounded-3 overflow-hidden"
        >
          <div
            class="position-relative w-100 h-100"
            [tourAnchor]="'dashboard-tour-2' + i"
          >
            <button
              [tourAnchor]="
                'dashboard-tour-3' +
                widgetsConfig[item.widgetId].x +
                widgetsConfig[item.widgetId].y
              "
              (click)="removeWidget(item)"
              mat-mini-fab
              matSuffix
              color="primary"
              tabindex="-1"
              class="position-absolute top-0 end-0 p-2 m-2 remove-widget-button"
              *ngIf="
                !!(options?.draggable?.enabled && options?.resizable?.enabled)
              "
            >
              <mat-icon class="material-symbols-outlined"> close </mat-icon>
            </button>
            <div
              *ngIf="
                !!(options?.draggable?.enabled && options?.resizable?.enabled)
              "
              class="position-absolute top-0 bottom-0 start-0 end-0 edit-bg-overlay"
            >
              <mat-icon
                class="position-absolute top-0 start-0 resize-icon resize-icon-top-left material-symbols-outlined"
              >
                arrows_outward
              </mat-icon>
              <mat-icon
                class="position-absolute bottom-0 start-0 resize-icon resize-icon-bottom-left material-symbols-outlined"
              >
                arrows_outward
              </mat-icon>
              <mat-icon
                class="position-absolute bottom-0 end-0 resize-icon resize-icon-bottom-right material-symbols-outlined"
              >
                arrows_outward
              </mat-icon>
              <mat-icon
                class="position-absolute top-0 end-0 resize-icon resize-icon-top-right material-symbols-outlined"
              >
                arrows_outward
              </mat-icon>
            </div>
            <app-widget-wrapper
              class="d-block h-100 w-100"
              [ngClass]="{ 'pe-none': this.options.draggable.enabled }"
              [widgetInfo]="widgetsConfig[item.widgetId].widgetInfo"
              [widgetComponent]="widgetComponentsMapping[item.widgetId]"
              (noDataTrigger)="hideChart(item.widgetId)"
            ></app-widget-wrapper>
          </div>
        </gridster-item>
      </ng-container>
    </ng-container>
    <!-- <div class="button-holder">
        <div class="gridster-item-content" *ngIf="item.hasContent">
          <div class="stuff">
            <span
              >Some content to select and click without dragging the
              widget</span
            >
            <a href="https://www.google.com" target="_blank">Link to Google</a>
          </div>
        </div>
        <div class="item-buttons" *ngIf="item.hasContent">
          <button class="drag-handler" mat-icon-button>
            <mat-icon>open_with</mat-icon>
          </button>
          <button
            mat-icon-button
            class="remove-button"
            (mousedown)="removeItem($event, item)"
            (touchstart)="removeItem($event, item)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <label *ngIf="!item.hasContent">{{ item.label }}</label>
        <button
          mat-mini-fab
          *ngIf="!item.hasContent"
          (mousedown)="removeItem($event, item)"
          (touchstart)="removeItem($event, item)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div> -->
  </gridster>
</div>

<div class="py-5" *ngIf="loading !== 0">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
