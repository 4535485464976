<div
  class="position-relative row m-0 w-100 h-100"
  *ngIf="
    deviceData?.length &&
      (deviceData[0].payload.d.rain >= 0 || deviceData[0].payload.d.flood >= 0);
    else noDataTemplate
  "
>
  <div
    class="col d-flex flex-column align-items-center justify-content-center h-100 rain-info-container"
    *ngIf="deviceData[0].payload.d.rain >= 0"
    #rainIconContainer
  >
    <ng-container>
      <div class="w-100 rain-icon">
        <ng-container *ngIf="deviceData[0].payload.d.rain === 0">
          <mat-icon fontSet="material-symbols">clear_day</mat-icon>
        </ng-container>
        <ng-container
          *ngIf="
            deviceData[0].payload.d.rain !== 0 &&
            deviceData[0].payload.d.rain <= heavyRainThreshold
          "
        >
          <mat-icon fontSet="material-symbols">partly_cloudy_day</mat-icon>
        </ng-container>
        <ng-container *ngIf="deviceData[0].payload.d.rain > heavyRainThreshold">
          <mat-icon fontSet="material-symbols">rainy</mat-icon>
        </ng-container>
      </div>
      <div class="rain-value">
        <span>
          {{ deviceData[0].payload.d.rain }}
        </span>
        <span>
          {{ rainField?.unit }}
        </span>
      </div>
    </ng-container>
  </div>
  <div
    class="col d-flex flex-column align-items-center justify-content-center h-100 flood-info-container"
    *ngIf="deviceData[0].payload.d.flood"
    #floodIconContainer
  >
    <ng-container>
      <div class="w-100 flood-icon">
        <mat-icon fontSet="material-symbols">flood</mat-icon>
      </div>
      <div class="flood-value">
        <span>
          {{ deviceData[0].payload.d.flood }}
        </span>
        <span>
          {{ floodField?.unit }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noDataTemplate>
  <div
    class="position-relative d-flex align-items-center justify-content-center m-0 w-100 h-100"
  >
    <div>
      <app-content-unavailable
        [majorText]="noData.majorText"
        [svgImage]="noData.svgImage"
        [minorText]="noData.minorText"
      ></app-content-unavailable>
    </div>
  </div>
</ng-template>
