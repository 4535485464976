import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService implements OnDestroy {
  private translationMap: { [key: string]: string } = {};
  private observer!: MutationObserver;
  private mutationQueue: MutationRecord[] = [];
  private isProcessingMutations = false;
  private translatedNodes = new WeakSet<Node>();

  private translatedMapping: any = {};
  private languageChangeSubscriptionIndex: number;
  private subscriptions: Subscription[];

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.subscriptions = [];
    this.loadTranslationMap();
    this.languageChangeSubscriptionIndex = -1;

    this.subscriptions.push(this.localStorageService.localStorageChange$.subscribe({
      next: ({ key, value }) => {
        if (key === LocalStorageConstants.OZ_USER) {
          let language = this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)?.settings?.language ?? "en";
          this.switchLanguage(language);
        }
      }
    }));
  }

  private loadTranslationMap() {
    this.http
      .get<{ [key: string]: string }>('/assets/translation-map.json')
      .pipe(catchError(() => of({})))
      .subscribe((map) => {
        this.translationMap = map;
        this.translatePage();
      });
  }

  switchLanguage(lang: string) {
    // Check if there's an existing language change subscription
    if (this.languageChangeSubscriptionIndex !== -1) {
      // If so, unsubscribe from the existing subscription to avoid memory leaks
      this.subscriptions[this.languageChangeSubscriptionIndex]?.unsubscribe();
    } else {
      // If no existing subscription, set the index to the current length of subscriptions array
      this.languageChangeSubscriptionIndex = this.subscriptions.length;
      // Push a new empty Subscription object to the subscriptions array
      this.subscriptions.push({} as Subscription);
    }

    // Subscribe to the language change observable
    this.subscriptions[this.languageChangeSubscriptionIndex] = this.translateService.use(lang.length ? lang ?? 'en' : 'en' ).subscribe(() => {
      // Call translatePage method to apply the new language changes
      this.translatePage();
    });
  }

  translatePage() {
    this.translateElement(document.body);
    this.observeMutations();
  }

  private translateElement(element: HTMLElement) {
    if (this.shouldIgnoreElement(element)) {
      this.translateAttributes(element); // Still translate attributes for ignored elements
      return;
    }
    if (this.shouldCompletelyIgnoreElement(element)) {
      return; // Completely ignore these elements
    }
    if (element.hasChildNodes()) {
      element.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          const textNode = child as Text;
          if (!textNode.nodeValue?.trim()) {
            return;
          }
          const originalText = textNode.nodeValue;
          const translatedText = this.preserveWhitespace(originalText, this.translateText(
            originalText?.trim() || ''
          ));
          if (translatedText && originalText !== translatedText) {
            textNode.nodeValue = translatedText;
          }
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          this.translateElement(child as HTMLElement);
        }
      });
    }
    this.translateAttributes(element);
  }

  private translateAttributes(element: HTMLElement) {
    const attributesToTranslate = ['matTooltip'];
    attributesToTranslate.forEach((attr) => {
      if (element.hasAttribute(attr)) {
        const attrValue = element.getAttribute(attr);
        if (attrValue) {
          const originalAttrValue = attrValue;
          const translatedAttrValue = this.preserveWhitespace(originalAttrValue, this.translateText(attrValue.trim()));
          if (translatedAttrValue && originalAttrValue !== translatedAttrValue) {
            element.setAttribute(attr, translatedAttrValue);
          }
        }
      }
    });
  }

  private shouldIgnoreElement(element: HTMLElement): boolean {
    const tagName = element.tagName.toLowerCase();
    const ignoredTags = ['mat-icon'];
    return ignoredTags.includes(tagName);
  }

  private shouldCompletelyIgnoreElement(element: HTMLElement): boolean {
    const ignoredClasses = ['no-translate'];
    return ignoredClasses.some((className) => element?.classList?.contains?.(className));
  }

  private translateText(text: string): string {
    const key = this.translationMap[text.trim()] || this.translatedMapping[text.trim()] || text.trim();
    let translatedText = key ? this.translateService.instant(key) : text;
    this.translatedMapping[translatedText] = key;

    if (translatedText === key) {
      translatedText = text;
    }
    return translatedText;
  }

  private preserveWhitespace(original: string, translated: string): string {
    const leadingWhitespace = original.match(/^\s*/)?.[0] || '';
    const trailingWhitespace = original.match(/\s*$/)?.[0] || '';
    return leadingWhitespace + translated + trailingWhitespace;
  }

  private observeMutations() {
    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new MutationObserver((mutations) => {
      const filteredMutations = mutations.filter((mutation, i) => {
        // Ignore comment nodes
        if (mutation.type === 'childList') {
          return Array.from(mutation.addedNodes).every(node => 
            node.nodeType !== Node.COMMENT_NODE && !this.shouldCompletelyIgnoreElement(node as HTMLElement)
          );
        }
        if (mutation.type === 'characterData' || mutation.type === 'attributes') {
          return mutation.target.nodeType !== Node.COMMENT_NODE && !this.shouldCompletelyIgnoreElement(mutation.target as HTMLElement);
        }
        return true;
      });

      if (filteredMutations.length > 0) {
        this.mutationQueue.push(...filteredMutations);
        this.processMutations();
      }
    });

    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
      characterData: true,
      attributes: true,
      attributeFilter: ['matTooltip'],
    });
  }

  private processMutations() {
    if (this.isProcessingMutations) return;
    this.isProcessingMutations = true;

    setTimeout(() => {
      const uniqueNodes = new Set<Node>();
      this.mutationQueue.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE && !this.translatedNodes.has(node)) {
              uniqueNodes.add(node);
            }
          });
        }
        if (mutation.type === 'characterData' || mutation.type === 'attributes') {
          uniqueNodes.add(mutation.target);
        }
      });

      uniqueNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const textNode = node as Text;
          if (!textNode.nodeValue?.trim()) {
            return;
          }
          const originalText = textNode.nodeValue;
          const translatedText = this.preserveWhitespace(originalText, this.translateText(
            originalText?.trim() || ''
          ));
          if (translatedText && originalText !== translatedText) {
            textNode.nodeValue = translatedText;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          this.translateElement(element);
          this.translateAttributes(element);
          this.translatedNodes.add(node); // Mark node as translated
        }
      });

      this.mutationQueue = [];
      this.isProcessingMutations = false;
    }, 50);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}
