<div
  [id]="'fullscreen-control-' + componentInstanceId"
  #fullscreenControl
  *ngIf="showFullscreenControl"
  class="fullscreen-control"
  (click)="googleMap && mapService.switchToFullScreen(googleMap)"
  [ngClass]="{ 'd-none': !controlsAttachedToMap }"
>
  <mat-icon class="material-symbols-outlined">
    {{ mapService.fullScreenMode }}
  </mat-icon>
</div>

<!-- show tilt toggle -->
<div
  [id]="'tilt-control-' + componentInstanceId"
  #tiltControl
  *ngIf="showTiltControl"
  class="tilt-control"
  (click)="googleMap && mapService.toggleTilt(options, googleMap)"
  [ngClass]="{ 'd-none': !controlsAttachedToMap }"
>
  <mat-icon class="material-symbols-outlined">
    {{ mapService.tiltMode }}
  </mat-icon>
</div>

<div
  [id]="'zoom-control-' + componentInstanceId"
  #zoomControl
  *ngIf="showZoomControl"
  class="zoom-control"
  [ngClass]="{ 'd-none': !controlsAttachedToMap }"
>
  <div class="d-flex flex-column">
    <mat-icon
      class="material-symbols-outlined text-basic-color"
      (click)="googleMap && mapService.zoomIn(options, googleMap)"
    >
      zoom_in
    </mat-icon>
    <hr class="control-divider" />
    <mat-icon
      class="material-symbols-outlined text-basic-color"
      (click)="googleMap && mapService.zoomOut(options, googleMap)"
    >
      zoom_out
    </mat-icon>
  </div>
</div>

<div
  *ngIf="showMapTypeControl"
  class="map-controls"
  [ngClass]="{ 'd-none': !controlsAttachedToMap }"
>
  <div
    #mapTypesControl
    [id]="'map-types-control-' + componentInstanceId"
    class="map-types-control"
    [class.expanded-menu]="mapService.showMoreOptions"
  >
    <!-- Show this when menu is not expanded -->
    <ng-container *ngIf="!mapService.showMoreOptions">
      <div
        class="map-types cursor-pointer"
        (click)="googleMap && mapService.changeMapType(options, googleMap)"
      >
        <div class="map-type">
          <img
            *ngIf="mapService.currentMapType === 'roadmap'"
            src="./../../../../../../../assets/images/map/default-map-type.png"
          />
          <img
            *ngIf="mapService.currentMapType === 'hybrid'"
            src="./../../../../../../../assets/images/map/satellite-map-type.png"
          />
        </div>
        <div
          class="text-center text-truncate"
          [title]="
            mapService.currentMapType === 'roadmap' ? 'Default' : 'Satellite'
          "
        >
          {{
            mapService.currentMapType === "roadmap" ? "Default" : "Satellite"
          }}
        </div>
      </div>
      <div class="map-layers">
        <!-- Existing layer toggles -->
        <div
          *ngIf="showComplainsLayerOption"
          id="complain-layer"
          class="cursor-pointer"
          (click)="
            googleMap && mapService.toggleComplainLayer(devices, googleMap)
          "
        >
          <div
            [ngClass]="
              mapService.complainLayerEnabled
                ? 'map-layer layer-selected'
                : 'map-layer'
            "
          >
            <img
              src="./../../../../../../../assets/images/map/default-map-type.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Complain'">
            Complain
          </div>
        </div>

        <div
          id="industry-layer"
          *ngIf="mapService.hasLayer5000"
          class="cursor-pointer"
          (click)="googleMap && mapService.toggleIndustryLayer(googleMap)"
        >
          <div
            [ngClass]="
              mapService.industryLayerEnabled
                ? 'map-layer layer-selected'
                : 'map-layer'
            "
          >
            <img
              src="./../../../../../../../assets/images/map/industry-layer.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Industry'">
            Industry
          </div>
        </div>

        <div
          id="traffic-layer"
          *ngIf="mapService.hasLayer5001"
          class="cursor-pointer"
          (click)="googleMap && mapService.toggleTrafficLayer(googleMap)"
        >
          <div
            [ngClass]="
              mapService.trafficLayerEnabled
                ? 'map-layer layer-selected'
                : 'map-layer'
            "
          >
            <img
              src="./../../../../../../../assets/images/map/traffic-layer.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Traffic'">
            Traffic
          </div>
        </div>

        <!-- <div
          id="wind-layer"
          class="cursor-pointer justify-content-center"
          *ngIf="mapService.hasLayer5002"
          (click)="googleMap && mapService.toggleWindLayer(googleMap)"
        > -->
        <div
          id="wind-layer"
          class="cursor-pointer justify-content-center"
          *ngIf="showWindLayerOption"
          (click)="googleMap && mapService.toggleWindLayer(googleMap)"
        >
          <!-- <div
            [ngClass]="
              mapService.windLayerEnabled
                ? 'map-layer layer-selected'
                : 'map-layer'
            "
          > -->
          <div
            [ngClass]="
              showWindLayerOption
                ? 'map-layer layer-selected'
                : 'map-layer'
            "
          >
            <img
              style="max-width: 48px; max-height: 48px"
              src="./../../../../../../../assets/images/map/wind-layer.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Wind'">Wind</div>
        </div>

        <div
          [id]="'more-options-layer-' + componentInstanceId"
          class="more-options-layer cursor-pointer"
          (click)="mapService.toggleMoreOptions()"
        >
          <div
            [ngClass]="
              mapService.showMoreOptions
                ? 'map-layer layer-selected'
                : 'map-layer'
            "
          >
            <mat-icon class="material-symbols-outlined layer-icon"
              >tune</mat-icon
            >
          </div>
          <div class="text-center text-truncate" [title]="'More'">More</div>
        </div>
      </div>
    </ng-container>
    <div class="menu-close-button-container" *ngIf="mapService.showMoreOptions">
      <button
        mat-icon-button
        class="close-button"
        (click)="mapService.toggleMoreOptions()"
      >
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>

    <!-- Expanded Menu -->
    <div class="expanded-options-menu" *ngIf="mapService.showMoreOptions">
      <div class="menu-section">
        <div class="section-title">Map details</div>
        <div class="options-grid">
          <div
            class="map-option"
            (click)="
              googleMap &&
                mapService.updateMapOption('showLabels', options, googleMap)
            "
            [ngClass]="
              mapService.mapOptions.showLabels ? 'option-selected' : ''
            "
          >
            <div class="option-icon">
              <mat-icon>label</mat-icon>
            </div>
            <span class="text-truncate" [title]="'Labels'">Labels</span>
          </div>
          <div
            *ngIf="showComplainsLayerOption"
            class="map-option"
            (click)="
              googleMap && mapService.toggleComplainLayer(devices, googleMap)
            "
            [ngClass]="mapService.complainLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/default-map-type.png"
            />
            <span class="text-truncate" [title]="'Complain'">Complain</span>
          </div>
          <div
            class="map-option"
            *ngIf="mapService.hasLayer5000"
            (click)="googleMap && mapService.toggleIndustryLayer(googleMap)"
            [ngClass]="mapService.industryLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/industry-layer.png"
            />
            <span class="text-truncate" [title]="'Industry'">Industry</span>
          </div>
          <div
            class="map-option"
            *ngIf="mapService.hasLayer5001"
            (click)="googleMap && mapService.toggleTrafficLayer(googleMap)"
            [ngClass]="mapService.trafficLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/traffic-layer.png"
            />
            <span class="text-truncate" [title]="'Traffic'">Traffic</span>
          </div>

          <div
            class="map-option"
            *ngIf="showWindLayerOption"
            (click)="googleMap && mapService.toggleWindLayer(googleMap)"
            [ngClass]="mapService.windLayerEnabled ? 'option-selected' : ''"
          >
          <!-- <div
            class="map-option"
            *ngIf="mapService.hasLayer5002"
            (click)="googleMap && mapService.toggleWindLayer(googleMap)"
            [ngClass]="mapService.windLayerEnabled ? 'option-selected' : ''"
          > -->
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/wind-layer.png"
            />
            <span class="text-truncate" [title]="'Wind'">Wind</span>
          </div>
        </div>
      </div>

      <div class="menu-section">
        <div class="section-title">Map type</div>
        <div class="map-types-grid">
          <div
            class="map-type-option"
            [class.selected]="mapService.currentMapType === 'roadmap'"
            (click)="
              mapService.currentMapType === 'roadmap'
                ? null
                : googleMap && mapService.changeMapType(options, googleMap)
            "
          >
            <img
              src="./../../../../../../../assets/images/map/default-map-type.png"
            />
            <span class="text-truncate" [title]="'Default'">Default</span>
          </div>
          <div
            class="map-type-option"
            [class.selected]="mapService.currentMapType === 'hybrid'"
            (click)="
              mapService.currentMapType === 'hybrid'
                ? null
                : googleMap && mapService.changeMapType(options, googleMap)
            "
          >
            <img
              src="./../../../../../../../assets/images/map/satellite-map-type.png"
            />
            <span class="text-truncate" [title]="'Satellite'">Satellite</span>
          </div>
        </div>
      </div>

      <div
        class="menu-section additional-layers"
        *ngIf="mapService.orgMapLayers.length > 0"
      >
        <div class="section-title">Additional layers</div>
        <div class="options-grid">
          <div
            *ngFor="let orgMapLayer of mapService.orgMapLayers; let i = index"
            class="map-option"
            [ngClass]="orgMapLayer.isVisible ? 'option-selected' : ''"
            (click)="googleMap && mapService.toggleOrgMapLayer(i, googleMap)"
          >
            <img [src]="orgMapLayer.iconUrl" class="layer-icon" />
            <span class="text-truncate" [title]="orgMapLayer.layerName">{{
              orgMapLayer.layerName
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="flex column"
  *ngIf="mapService.windLayerEnabled"
  [ngClass]="{ 'd-none': !controlsAttachedToMap }"
>
  <!-- <div style="margin: auto">
    <div class="legend-container">
      <div class="legend-title">
        {{ mapService.weatherOption.legendTitle }}
      </div>
      <div class="legend-body">
        <div class="legend-min-scale">
          {{ mapService.weatherOption.legendMin }}
        </div>
        <div class="legend-avg-scale">
          {{ mapService.weatherOption.legendAvg }}
        </div>
        <div class="legend-max-scale">
          {{ mapService.weatherOption.legendMax }}
        </div>
        <div
          [ngClass]="['legend-scale', mapService.weatherOption.scaleClass]"
        ></div>
      </div>
    </div>
  </div> -->
  <app-wind-layer
    [windData]="windData"
    [width]="width"
    [height]="height"
    [cellSize]="10"
    [particleCount]="500"
    [particleLifetime]="70"
    [particleColor]="'rgba(255, 255, 255, 0.8)'"
    [curlFactor]="0"
    [interval]="50"
    [googleMap]="googleMap"
  ></app-wind-layer>
</div>
