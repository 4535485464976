import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Complain } from 'src/app/shared/models/complain';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ComplainFormComponent } from '../../components/complain-form/complain-form.component';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  categories: any = {};
  statuses = ['Pending', 'In Progress', 'Resolved'];
  displayedColumns: string[] = [];
  complains: Complain.Get[] = [];
  selectedOrg: any = [];
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  assignedEmailId: any;
  gte!: number;
  lte!: number;
  public dialogCompInstance!: ConfirmationPopupComponent;

  defaultColumns: any[] = [];
  orgId = this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
    .org;

  public noData: ContentUnavailable = {
    majorText: 'No Complains Found',
    minorText: 'Try selecting a different Date Range',
    svgImage: AppConstants.NO_DATA,
  };
  moduleAccess: any;

  constructor(
    private complainsService: ComplainsService,
    private localStorageService: LocalStorageService,
    private formsService: FormsService,
    public loadrService: LoadrService,
    private complainservice: ComplainsService,
    private customMomentService: CustomMomentService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.defaultColumns = this.generateDefaultColumnsConfig();
  }

  ngOnInit() {
    this.gte = this.complainsService.getGte();
    this.lte = this.complainsService.getLte();
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    forkJoin({
      category: this.complainservice.getCategories(),
      complain: this.complainsService.getComplainsByOrganization(
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .org,
        this.gte,
        this.lte
      ),
    }).subscribe(({ category, complain }) => {
      this.categories = category.reduce((prevValue: any, currValue: any) => {
        prevValue[currValue.categoryId] = currValue;
        return prevValue;
      }, {});
      this.complains = complain;
      this.loadData();
    });
    this.complainservice.isGetData$.subscribe((res) => {
      if (res) {
        this.complains = this.complainservice.complains;
        this.loadData();
      }
    });
    this.moduleAccess = this.commonService.moduleAccessibility(2015);
    this.defaultColumns = this.generateDefaultColumnsConfig();
  }

  private generateDefaultColumnsConfig() {
    return [
      {
        columnDef: 'actions',
        header: '',
        cell: (element: Complain.Get) => `${element.orgId}`,
        parameter: false,
        selected: false,
        icon: 'more_vert',
        options: [
          ...['edit', 'delete']
            .filter((permission: any) =>
              this.commonService.getModuleAccessOptionsOnRoute(
                permission,
                this.moduleAccess
              )
            )
            .map((permission: any) => {
              switch (permission) {
                case 'edit':
                  return {
                    label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
                    action: (element: Complain.Get) =>
                      this.updateComplain(element),
                  };
                case 'delete':
                  return {
                    label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
                    action: (element: Complain.Get) =>
                      this.openDialog(element.complainId),
                  };
                default:
                  return null;
              }
            })
            .filter((option) => option !== null),
        ],
      },
      {
        columnDef: 'slno',
        header: 'SL.NO',
        cell: (element: Complain.Get) => `${''}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'categoryId',
        header: ' Category',
        cell: (element: Complain.Get) =>
          `${this.categories[element.categoryId]?.label}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'priority',
        header: 'Priority',
        cell: (element: Complain.Get) => `${element.priority}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'title',
        header: 'Complain Name',
        cell: (element: Complain.Get) => `${element.title}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'message',
        header: 'Complain Description',
        cell: (element: Complain.Get) => `${element.message}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'sources',
        header: 'Possible Source',
        cell: (element: Complain.Get) => `${element.sources}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'end_date',
        header: 'Due Date',
        cell: (element: Complain.Get) =>
          `${this.customMomentService.formatDate({ epoch: element.end_date })}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'Status',
        header: 'Status',
        cell: (element: Complain.Get) => `${element.status}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'assignedUserEmail',
        header: 'Assign To',
        cell: (element: Complain.Get) => `${element.assignedUserEmail}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'name',
        header: 'Person Name',
        cell: (element: Complain.Get) => `${element.name}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'email',
        header: 'Email ID',
        cell: (element: Complain.Get) => `${element.email}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'phoneNo',
        header: 'Contact Number',
        cell: (element: Complain.Get) => `${element.phoneNo}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'image_url',
        header: 'Photo',
        cell: (element: Complain.Get) => `${element.image_url}`,
        parameter: false,
        selected: false,
      },
    ];
  }

  loadData() {
    this.loadTable.next(false);
    let complainList = this.complains;
    this.complains = complainList.map((data, srNo) => {
      return { ...data };
    });
    setTimeout(() => {
      this.loadTable.next(true);
    }, 500);
  }

  deleteComplain(complainId: any): void {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.complainsService.deleteComplain(complainId).subscribe({
      next: (result) => {
        if (result) {
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Complain Deleted Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.tableDataUpdate(result);
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Deleting Complain. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  downloadReport(): void {
    let timezone = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).settings.timezone.name;
    let timeDiff: any = 19800;
    this.complainservice
      .downloadComplainReport(
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .org,
        this.gte,
        this.lte,
        timezone,
        false
      )
      .subscribe((res) => {
        saveAs(res, 'complain report.pdf');
      });
  }

  updateComplain(complain: Complain.Get): void {
    this.complainsService.getSubUsers().subscribe(() => {
      this.formsService
        .openForm(ComplainFormComponent, {
          data: complain,
          selectedOrg: this.complainsService.userSelectedOrg,
          categories: Object.values(this.categories),
          imageUrl: complain.image_url,
        })
        .subscribe((result) => {
          if (result) {
          }
        });
    });
  }

  tableDataUpdate(result: any) {
    this.loadTable.next(false);
    if (result) {
      this.complainsService
        .getComplainsByOrganization(
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            .org,
          this.gte,
          this.lte
        )
        .subscribe((data) => {
          this.complains = data;
          this.loadData();
          this.loadTable.next(true);
        });
    }
  }

  getComplainModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  openDialog(complainId: any): void {
    const dynamicData = {
      title: 'Delete Complain',
      message: 'Are you sure you want to delete this Complain?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteComplain(complainId);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }
}
