import { Injectable } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import * as Highcharts from 'highcharts';
import { Observable, Subject } from 'rxjs';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceType } from '../models/device-type/device-type';
import { LocalStorageService } from './local-storage.service';
import { CommonService } from './common.service';
import { DeviceService } from './device.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { BaseAPIService } from './base-service';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceUtil } from '../utils/device-utils';
import { MapChartUtils } from '../utils/map-chart-utils';
import { isNumber, isUndefined } from 'lodash';
import { HotspotsPayload } from '../models/analytics/hotspots-payload';
import { NotificationService } from './notification.service';
import { CDK_DRAG_HANDLE } from '@angular/cdk/drag-drop';
import { CommonMapService } from './common-map.service';

@Injectable({
  providedIn: 'root',
})
export class MapChartsService extends BaseAPIService<any> {
  private dateRangeUpdated: Subject<any> = new Subject();
  public dateRangeUpdated$: Observable<any> =
    this.dateRangeUpdated.asObservable();
  private chart: Highcharts.Chart | undefined = undefined;
  private infoWindow: google.maps.InfoWindow | null = null;
  private customInfoWindow: HTMLElement | null = null;
  private markers: google.maps.Marker[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private commonMapService: CommonMapService,
    private deviceService: DeviceService,
    private notificationService: NotificationService,
    http: HttpClient,
    cookieService: CookieService
  ) {
    super(http, cookieService);
  }

  public updateDateRange(dateRange: any) {
    this.dateRangeUpdated.next(dateRange);
  }

  getWindRoseData() {
    return [
      {
        name: 'Tokyo',
        data: [8, 7, 6, 5, 4, 3, 2, 1, 0, 0, 1, 2, 3, 4, 5, 6],
      },
    ];
  }

  updateDeviceDetails(
    deviceDetails: Array<DeviceDetails>,
    calculateAQI: boolean = true
  ) {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.commonService.getAllAQIs();
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const units: any =
      this.commonService.getAllUnits()[
        this.deviceService.currentDeviceType.deviceTypeId
      ];

    deviceDetails.forEach((deviceDetail) => {
      if (DeviceUtil.hasValidIndexForDeviceType(deviceDetail, deviceTypes)) {
        const aqiIndex = DeviceUtil.calculateAQI(
          deviceDetail.payload,
          DeviceUtil.getBreakpoints(
            undefined,
            deviceDetail.deviceType,
            deviceTypes,
            allAqi,
            allAqis,
            aqi
          )
        );

        if (calculateAQI && deviceDetail.payload !== undefined) {
          deviceDetail.aqi = aqiIndex.aqi;
          deviceDetail.aqiKey = aqiIndex.aqiKey;
          if (aqiIndex.aqi !== null && aqiIndex.aqi !== undefined) {
            deviceDetail.payload.d['aqi'] = aqiIndex.aqi;
            deviceDetail.payload.d['aqiMessage'] = aqiIndex.aqiMessage;
          }
        }
      }
      deviceDetail.payload = DeviceUtil.convertUnits(
        deviceDetail.payload,
        units!
      );
    });

    return deviceDetails;
  }

  setChart(chart: Highcharts.Chart) {
    this.chart = chart;
  }
  getChart() {
    return this.chart;
  }

  createChartOptions(
    analyticType: string | number = 102,
    colors: string[] = []
  ) {
    // Removed data from createChartOptions
    const options: Highcharts.Options[] = [
      {
        chart: {
          type: 'column',
          polar: true,
          reflow: true,
          animation: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
        colors: colors,
        credits: { enabled: false },
        title: {
          text: '',
        },
        xAxis: {
          categories: [
            'N',
            'NNE',
            'NE',
            'ENE',
            'E',
            'ESE',
            'SE',
            'SSE',
            'S',
            'SSW',
            'SW',
            'WSW',
            'W',
            'WNW',
            'NW',
            'NNW',
          ],
          tickmarkPlacement: 'on',
          lineWidth: 0,
          gridLineColor: 'transparent',
          gridLineWidth: 0,
          labels: {
            style: {
              color: 'transparent',
              opacity: 0,
              display: 'none',
            },
          },
        },
        yAxis: {
          tickmarkPlacement: 'on',
          type: 'category',
          gridLineColor: 'var(--chart-axis-line-color)',
          labels: {
            style: {
              color: 'var(--chart-axis-text-color)',
            },
            formatter: function () {
              return this.value + '%';
            },
          },
          gridLineDashStyle: 'Dot',
          gridLineWidth: 2,
        },
        tooltip: {
          valueSuffix: '%',
          followPointer: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            shadow: false,
            pointPlacement: 'on',
            dataLabels: {
              enabled: false,
              format: '{point.y:.0f}%',
            },
          },
          column: {
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
          },
        },
        exporting: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        series: [], // Initial series array is empty
      },
    ];
    if (analyticType === 102) {
      return options[0];
    } else if (analyticType === 103) {
      return options[0];
    } else {
      return null;
    }
  }
  showInfoWindow(
    marker: google.maps.Marker,
    deviceLabel: string,
    gmapInstance: GoogleMap
  ): void {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow();
    }
    this.infoWindow.setContent(`Error in Device: ${deviceLabel}`);
    this.infoWindow.open(gmapInstance?.googleMap, marker);
  }
  // private setLocationMarker(gmapInstance: GoogleMap, lat: number, lng: number) {
  //   const deviceTypeId = this.selectedHeatmap?.deviceTypeId ?? 1001;
  //   this.iconDataUrl = '';
  //   if (deviceTypeId === 1006) {
  //     this.iconDataUrl = 'assets/images/pins/3rd_party_device_pin.svg';
  //   } else {
  //     const deviceType = DeviceUtil.getDeviceTypeByKeyOrId(
  //       deviceTypeId,
  //       this.commonService.getAllDeviceTypes()
  //     );
  //     this.iconDataUrl = DeviceUtil.getIconUrlForHeatmapPin(deviceType!);
  //   }
  //   this.selectedLatLngMarker = new google.maps.Marker({
  //     position: { lat, lng },
  //     icon: this.iconDataUrl,
  //   });
  //   if (this.googleMap?.googleMap) {
  //     this.selectedLatLngMarker.setMap(this.googleMap.googleMap);
  //   }
  // }

  // how to add an eventlister to a html div attatched to a map and display a popup on event
  toggleCustomInfoWindow(event: any, content: string) {
    if (!this.customInfoWindow) {
      this.customInfoWindow = document.createElement('div');
      this.customInfoWindow.style.position = 'absolute';
      this.customInfoWindow.style.backgroundColor = 'white';
      this.customInfoWindow.style.padding = '10px';
      this.customInfoWindow.style.borderRadius = '5px';
      this.customInfoWindow.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.2)';
      this.customInfoWindow.style.zIndex = '1000';
      this.customInfoWindow.style.display = 'none';
      document.body.appendChild(this.customInfoWindow);
    }

    const isVisible = this.customInfoWindow?.style.display === 'block';
    this.customInfoWindow.style.display = isVisible ? 'none' : 'block';
    if (!isVisible) {
      this.customInfoWindow.style.left = `${event.clientX + 10}px`;
      this.customInfoWindow.style.top = `${event.clientY + 10}px`;
      this.customInfoWindow.innerHTML = content;
    }
  }

  displayWindRoseChart(
    lat: number,
    lon: number,
    directions: string[],
    data: Highcharts.SeriesOptionsType[],
    avgParamValue: number = 0,
    deviceLabel: string,
    selectedRoseChartSize: number = 300,
    analyticType: string | number,
    colors: string[],
    gmapInstance: GoogleMap
  ): [Highcharts.Chart | undefined, google.maps.OverlayView] | any {
    if (!gmapInstance) {
      // console.log('gmapInstance not found');
      return;
    }
    if (!lat || !lon) {
      console.log('Invalid lat, lon');
      return;
    }
    if (
      !data ||
      // !analyticType ||
      !colors
    ) {
      // const marker = new google.maps.Marker({
      //   position: new google.maps.LatLng(lat, lon),
      //   map: gmapInstance.googleMap,
      //   title: deviceLabel,
      // });
      // marker.addListener('click', () => {
      //   this.showInfoWindow(marker, deviceLabel, gmapInstance);
      // });
      // this.markers.push(marker);
      // return;
    }
    // Create a div element to hold the wind rose chart
    const windRoseChartDiv: HTMLDivElement = document.createElement('div');
    windRoseChartDiv.id = 'wind-rose-chart';
    windRoseChartDiv.style.width = `${selectedRoseChartSize}px`;
    windRoseChartDiv.style.height = `${selectedRoseChartSize}px`;
    windRoseChartDiv.style.backgroundColor = 'transparent';
    windRoseChartDiv.style.position = 'absolute';
    windRoseChartDiv.style.top = '50%';
    windRoseChartDiv.style.left = '50%';
    windRoseChartDiv.style.transform = 'translate(-50%, -50%)';
    const valueOverlayDiv: HTMLDivElement = document.createElement('div');
    valueOverlayDiv.style.width = `${selectedRoseChartSize / 10}px`;
    valueOverlayDiv.style.height = `${selectedRoseChartSize / 10}px`;
    valueOverlayDiv.style.backgroundColor =
      'var(--chart-data-bubble-background-color)';
    valueOverlayDiv.style.color = 'var(--chart-data-bubble-text-color)';
    valueOverlayDiv.style.borderRadius = '50%';
    valueOverlayDiv.style.display = 'flex';
    valueOverlayDiv.style.alignItems = 'center';
    valueOverlayDiv.style.justifyContent = 'center';
    valueOverlayDiv.style.position = 'absolute';
    valueOverlayDiv.style.top = '50%';
    valueOverlayDiv.style.left = '50%';
    valueOverlayDiv.style.transform = 'translate(-50%, -50%)';
    valueOverlayDiv.style.zIndex = '1000';
    valueOverlayDiv.style.fontWeight = 'bold';
    valueOverlayDiv.style.fontSize = `${selectedRoseChartSize / 40}px`;
    valueOverlayDiv.addEventListener('hover', (e) => {
      this.toggleCustomInfoWindow(e, deviceLabel);
    });

    // Create the overlay
    const overlay = new google.maps.OverlayView();
    overlay.onAdd = () => {
      const panes = overlay.getPanes();
      panes && panes.overlayLayer.appendChild(windRoseChartDiv); // Use the existing div

      // Create the chart after the div is added to the overlay
      const chartOptions: any = this.createChartOptions(analyticType, colors); // Create options without data initially
      if (!chartOptions) {
        // console.log('Invalid chart options');
        return;
      }
      chartOptions.xAxis.labels = {
        useHTML: true,
        style: {
          fontWeight: 'bold', // Make labels bold
          // color: 'var(--chart-axis-text-color)', // Apply color class
          color: 'transparent',
          // backgroundColor: 'rgba(196, 196, 196, 0.5)',
        },
        // formatter: function () {
        //   return `<span style="font-weight: bold; background-color: rgba(196, 196, 196, 0.5);">${this.value}</span>`;
        // },
      };
      const chart = Highcharts.chart(windRoseChartDiv, chartOptions);

      this.setChart(chart);

      // Add series data after chart creation
      data.forEach((seriesData) => {
        chart.addSeries(seriesData, false, false); // Add each series individually
      });
      chart.redraw(); // Redraw after adding all series
    };
    overlay.draw = () => {
      const projection = overlay.getProjection();
      const position = projection.fromLatLngToDivPixel(
        new google.maps.LatLng(lat, lon)
      );

      if (position) {
        windRoseChartDiv.style.left = position.x + 'px';
        windRoseChartDiv.style.top = position.y + 'px';
        valueOverlayDiv.innerText = '' + (isNaN(Number(avgParamValue)) ? 0 : avgParamValue);
        windRoseChartDiv.appendChild(valueOverlayDiv);
      } else {
        // console.log('waiting for position');
      }
    };
    overlay.onRemove = () => {
      windRoseChartDiv.remove(); // Remove the div when the overlay is removed
    };

    // Add the overlay to the map
    gmapInstance?.googleMap && overlay.setMap(gmapInstance?.googleMap);
    return [this.chart, overlay];
  }

  // not being used
  updateWindRoseChart(
    chart: Highcharts.Chart | undefined,
    newData: Highcharts.SeriesOptionsType[] | any,
    overlay: google.maps.OverlayView | any
  ) {
    if (!chart) {
      console.warn('Chart not found');
      return;
    }

    // Add new series
    chart.series[0]?.setData(newData[0].data, false, false);
    chart.redraw(); // Redraw after adding all new series
    overlay.draw(); // Redraw overlay

    return chart;
  }

  //   displayHotspots(lat: number, lon: number, gmapInstance: GoogleMap): google.maps.OverlayView | any {
  //     console.log('Displaying hotspot chart', lat, lon, gmapInstance);

  //     // Ensure that the map is available
  //     const map = gmapInstance?.googleMap;
  //     if (!map) return; // Return if map is not available

  //     // Define static bounds (same as initMap)
  //     const bounds = new google.maps.LatLngBounds(
  //         new google.maps.LatLng(62.281819, -150.287132), // Southwest corner
  //         new google.maps.LatLng(62.400471, -150.005608)  // Northeast corner
  //     );

  //     // Define a custom overlay for the hotspot
  //     class HotspotOverlay extends google.maps.OverlayView {
  //         private lat: number;
  //         private lon: number;
  //         private canvasImgEl: HTMLImageElement;
  //         private canvas: HTMLCanvasElement;
  //         private hotspotDiv: HTMLDivElement;

  //         constructor(lat: number, lon: number) {
  //             super();
  //             this.lat = lat;
  //             this.lon = lon;
  //             this.canvasImgEl = document.createElement('img'); // To hold the canvas image
  //             this.canvas = document.createElement('canvas');  // The canvas element
  //             this.hotspotDiv = document.createElement('div');
  //         }

  //         override onAdd() {
  //             // Set up the canvas
  //             const ctx = this.canvas.getContext('2d');
  //             if (ctx) {
  //                 this.canvas.width = 100;
  //                 this.canvas.height = 100;
  //                 ctx.fillStyle = 'red';
  //                 ctx.fillRect(0, 0, 100, 100);
  //             }

  //             // Convert canvas to an image
  //             const canvasImg = this.canvas.toDataURL();
  //             this.canvasImgEl.src = canvasImg;  // Set the img src to the canvas image data
  //             this.hotspotDiv.appendChild(this.canvasImgEl);

  //             // Style the div container
  //             this.hotspotDiv.style.position = 'absolute';
  //             this.hotspotDiv.style.width = '100px';  // Match the canvas width
  //             this.hotspotDiv.style.height = '100px';  // Match the canvas height
  //             this.hotspotDiv.style.pointerEvents = 'none'; // Ensure it doesn't block interactions on the map

  //             // Add the div to the overlayLayer
  //             const panes = this.getPanes();
  //             panes && panes.overlayLayer.appendChild(this.hotspotDiv);

  //             // Set position on the map using bounds
  //             const projection = this.getProjection();
  //             if (projection) {
  //                 // Using bounds to position the hotspot within the bounds
  //                 const position = projection.fromLatLngToDivPixel(new google.maps.LatLng(this.lat, this.lon));
  //                 if (position) {
  //                     this.hotspotDiv.style.left = position.x + 'px';
  //                     this.hotspotDiv.style.top = position.y + 'px';
  //                 } else {
  //                     console.log('waiting for position');
  //                 }
  //             }
  //         }

  //         override draw() {
  //             // Nothing to draw here since the position is already handled in onAdd
  //         }

  //         override onRemove() {
  //             // Remove the div when overlay is removed
  //             if (this.hotspotDiv) {
  //                 this.hotspotDiv.parentNode?.removeChild(this.hotspotDiv);
  //             }
  //         }

  //         // Methods to control visibility
  //         hide() {
  //             this.hotspotDiv.style.visibility = 'hidden';
  //         }

  //         show() {
  //             this.hotspotDiv.style.visibility = 'visible';
  //         }

  //         toggle() {
  //             if (this.hotspotDiv.style.visibility === 'hidden') {
  //                 this.show();
  //             } else {
  //                 this.hide();
  //             }
  //         }

  //         toggleDOM(map: google.maps.Map) {
  //             if (this.getMap()) {
  //                 this.setMap(null);
  //             } else {
  //                 this.setMap(map);
  //             }
  //         }
  //     }

  //     // Create the overlay instance
  //     const hotspotOverlay = new HotspotOverlay(lat, lon);
  //     hotspotOverlay.setMap(map);

  //     // Optional: create buttons to toggle visibility or DOM attachment
  //     const toggleButton = document.createElement("button");
  //     toggleButton.textContent = "Toggle Hotspot";
  //     toggleButton.classList.add("custom-map-control-button");

  //     const toggleDOMButton = document.createElement("button");
  //     toggleDOMButton.textContent = "Toggle DOM Attachment";
  //     toggleDOMButton.classList.add("custom-map-control-button");

  //     toggleButton.addEventListener("click", () => {
  //         hotspotOverlay.toggle();
  //     });

  //     toggleDOMButton.addEventListener("click", () => {
  //         hotspotOverlay.toggleDOM(map);
  //     });

  //     map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleDOMButton);
  //     map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleButton);

  //     return hotspotOverlay;
  // }

  displayHotspots(
    lat: number,
    lon: number,
    value: number,
    maxColor: string,
    minColor: string,
    colors: string[],
    gaussianPdfAttributes: any,
    radius: number,
    gmapInstance: GoogleMap,
    pixelsPerKM: number
  ): google.maps.OverlayView | any {
    // Ensure that the map is available
    const map = gmapInstance?.googleMap;
    if (!map) return; // Return if map is not available

    // Define static bounds (same as initMap)
    // const bounds = new google.maps.LatLngBounds(
    //   new google.maps.LatLng(62.281819, -150.287132), // Southwest corner
    //   new google.maps.LatLng(62.400471, -150.005608) // Northeast corner
    // );

    // calculate new bounds based on lat, lon, and radius
    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(lat - radius, lon - radius),
      new google.maps.LatLng(lat + radius, lon + radius)
    );

    const widthDistanceKM = MapChartUtils.getDistanceBetweenPoints(
      lat,
      lon,
      lat,
      lon + radius
    );
    const width = widthDistanceKM * pixelsPerKM;
    // console.log('widthDistanceKM', widthDistanceKM, 'width', width);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
      canvas.width = width;
      canvas.height = width;
      // ctx.fillStyle = 'red';
      // ctx.fillRect(0, 0, width, width);
      // plot a circular heatmap using gaussian distribution starting from the center and color gradient should be based on the value
      const center = [width / 2, width / 2];
      for (let i = 0; i < width; i++) {
        for (let j = 0; j < width; j++) {
          const distance = Math.sqrt(
            Math.pow(i - center[0], 2) + Math.pow(j - center[1], 2)
          );
          const colorFactor = MapChartUtils.exponentialDecay(
            radius,
            distance,
            1
          );
          const newColor = MapChartUtils.interpolateColor(
            maxColor,
            minColor,
            colorFactor
          );
          ctx.fillStyle = newColor;
          ctx.fillRect(i, j, 1, 1);
        }
      }
    }
    const image = canvas.toDataURL();

    // Define a custom overlay for the hotspot
    class HotspotOverlay extends google.maps.OverlayView {
      private hotspotDiv?: HTMLDivElement;
      private bounds: google.maps.LatLngBounds;
      private image: string;

      constructor(bounds: google.maps.LatLngBounds, image: string) {
        super();
        this.bounds = bounds;
        this.image = image;
      }

      override onAdd() {
        this.hotspotDiv = document.createElement('div');

        // Style the div container
        this.hotspotDiv.style.position = 'absolute';
        this.hotspotDiv.style.pointerEvents = 'none';

        const img = document.createElement('img');
        img.src = this.image;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        this.hotspotDiv.appendChild(img);

        // Add the div to the overlayLayer
        const panes = this.getPanes();
        panes && panes.overlayLayer.appendChild(this.hotspotDiv);
      }

      override draw() {
        const projection = this.getProjection();
        const sw = projection.fromLatLngToDivPixel(this.bounds.getSouthWest());
        const ne = projection.fromLatLngToDivPixel(this.bounds.getNorthEast());

        if (!sw || !ne) {
          // console.log('Bounds not found');
          return;
        }

        // Use bounds to position the hotspot within the bounds
        if (this.hotspotDiv) {
          this.hotspotDiv.style.left = sw.x + 'px';
          this.hotspotDiv.style.top = ne.y + 'px';
          this.hotspotDiv.style.width = ne.x - sw.x + 'px';
          this.hotspotDiv.style.height = sw.y - ne.y + 'px';
        }
      }

      override onRemove() {
        // Remove the div when overlay is removed
        if (this.hotspotDiv) {
          this.hotspotDiv.parentNode?.removeChild(this.hotspotDiv);
        }
      }

      // Methods to control visibility
      hide() {
        if (this.hotspotDiv) this.hotspotDiv.style.visibility = 'hidden';
      }

      show() {
        if (this.hotspotDiv) this.hotspotDiv.style.visibility = 'visible';
      }

      toggle() {
        if (this.hotspotDiv)
          if (this.hotspotDiv.style.visibility === 'hidden') {
            this.show();
          } else {
            this.hide();
          }
      }

      toggleDOM(map: google.maps.Map) {
        if (this.getMap()) {
          this.setMap(null);
        } else {
          this.setMap(map);
        }
      }
    }

    // Create the overlay instance
    const hotspotOverlay = new HotspotOverlay(bounds, image);
    hotspotOverlay.setMap(map);

    return hotspotOverlay;
  }

  // displayHotspotsHeatmap(payload: HotspotsPayload, hotspotOpacity: number, hotspotRadius: number, gmapInstance: GoogleMap) {
  //   const map = gmapInstance?.googleMap;
  //   if (!map) return;

  //   const weights = payload.data.map(d => d.weight)
  //   console.log('data: ', weights, 'colors: ', payload.colors)
  //   const heatmap = new google.maps.visualization.HeatmapLayer({
  //     data: payload.data,
  //     map: map,
  //     radius: hotspotRadius,
  //     maxIntensity: payload?.maxLimit,
  //     dissipating: true,
  //     gradient: payload.colors,
  //     opacity: hotspotOpacity,
  //   });
  //   const colors = [
  //     'rgba(110, 204, 88, 1)',
  //     'rgba(234, 199, 54, 1)',
  //     'rgba(237, 154, 46, 1)',
  //     'rgba(214, 54, 54, 1)',
  //     'rgba(143, 63, 151, 1)',
  //     'rgba(126, 0, 35, 1)'
  //   ];
  //   gmapInstance?.googleMap && heatmap.setMap(gmapInstance?.googleMap);
  //   return heatmap;
  // }
  displayHotspotsHeatmap(
    payload: HotspotsPayload,
    hotspotOpacity: number,
    hotspotRadius: number,
    gmapInstance: GoogleMap
  ) {
    const map = gmapInstance?.googleMap;
    if (!map) return;

    const weights = payload.data.map((d) => d.weight);
    // console.log(
    //   'data: ',
    //   weights,
    //   'colors: ',
    //   payload.colors,
    //   'payload: ',
    //   payload
    // );

    // Create the heatmap layer
    const infoWindow = new google.maps.InfoWindow();
    payload.data.forEach((d) => {
      const marker = new google.maps.Marker({
        position: d.location,
        map: map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3,
          fillColor: '#999',
          fillOpacity: 1,
          strokeColor: 'white',
          strokeWeight: 1,
        },
      });
      marker.addListener('mouseover', () => {
        const content = `<div class="custom-box"><span><strong>${d.name}</strong></span><br></div>`;
        // <span>Location: <strong>${d.location.lat()}, ${d.location.lng()}</strong></span>
        infoWindow.setContent(content);
        infoWindow.setPosition(d.location);
        infoWindow.open(map);
        // this.customInfoWindow = this.commonMapService.displayCustomInfoWindow(gmapInstance, d.location, d.name);
      });
      marker.addListener('mouseout', () => {
        setTimeout(() => {
          // this.customInfoWindow && this.commonMapService.destroyCustomInfoWindow(gmapInstance, this.customInfoWindow);
          infoWindow.close();
        }, 300);
      });
    });
    payload.data = payload.data.filter((d) => d.weight > 0);
    const heatmap = new google.maps.visualization.HeatmapLayer({
      data: payload.data,
      map: map,
      radius: hotspotRadius,
      maxIntensity: payload?.maxLimit,
      dissipating: true,
      gradient: payload.colors,
      opacity: hotspotOpacity,
    });

    // Set the heatmap on the map
    gmapInstance?.googleMap && heatmap.setMap(gmapInstance?.googleMap);
    return heatmap;
  }

  initMap(gmapInstance: GoogleMap): google.maps.OverlayView | any {
    const map = gmapInstance?.googleMap;
    if (!map) return;

    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(62.281819, -150.287132),
      new google.maps.LatLng(62.400471, -150.005608)
    );

    let image = 'https://developers.google.com/maps/documentation/javascript/';
    image += 'examples/full/images/talkeetna.png';

    // The custom USGSOverlay object contains the USGS image, the bounds of the image, and a reference to the map.
    class USGSOverlay extends google.maps.OverlayView {
      private bounds: google.maps.LatLngBounds;
      private image: string;
      private div?: HTMLElement;

      constructor(bounds: google.maps.LatLngBounds, image: string) {
        super();
        this.bounds = bounds;
        this.image = image;
      }

      // onAdd is called when the map's panes are ready and the overlay has been added to the map.
      override onAdd() {
        this.div = document.createElement('div');
        this.div.style.borderStyle = 'none';
        this.div.style.borderWidth = '0px';
        this.div.style.position = 'absolute';

        const img = document.createElement('img');
        img.src = this.image;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        this.div.appendChild(img);

        // Add the element to the "overlayLayer" pane.
        const panes = this.getPanes();
        panes && panes.overlayLayer.appendChild(this.div);
      }

      override draw() {
        const overlayProjection = this.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getSouthWest()
        )!;
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getNorthEast()
        )!;

        if (this.div) {
          this.div.style.left = sw.x + 'px';
          this.div.style.top = ne.y + 'px';
          this.div.style.width = ne.x - sw.x + 'px';
          this.div.style.height = sw.y - ne.y + 'px';
        }
      }

      override onRemove() {
        if (this.div) {
          (this.div.parentNode as HTMLElement).removeChild(this.div);
          delete this.div;
        }
      }

      hide() {
        if (this.div) {
          this.div.style.visibility = 'hidden';
        }
      }

      show() {
        if (this.div) {
          this.div.style.visibility = 'visible';
        }
      }

      toggle() {
        if (this.div) {
          if (this.div.style.visibility === 'hidden') {
            this.show();
          } else {
            this.hide();
          }
        }
      }

      toggleDOM(map: google.maps.Map) {
        if (this.getMap()) {
          this.setMap(null);
        } else {
          this.setMap(map);
        }
      }
    }

    const overlay: USGSOverlay = new USGSOverlay(bounds, image);
    overlay.setMap(map);

    // Optional: Create buttons to toggle visibility or DOM attachment
    const toggleButton = document.createElement('button');
    toggleButton.textContent = 'Toggle';
    toggleButton.classList.add('custom-map-control-button');

    const toggleDOMButton = document.createElement('button');
    toggleDOMButton.textContent = 'Toggle DOM Attachment';
    toggleDOMButton.classList.add('custom-map-control-button');

    toggleButton.addEventListener('click', () => {
      overlay.toggle();
    });

    toggleDOMButton.addEventListener('click', () => {
      overlay.toggleDOM(map);
    });

    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleDOMButton);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleButton);
    return overlay;
  }

  // not being used
  updateHeatmapChart(
    chart: Highcharts.Chart | undefined,
    newData: Highcharts.SeriesOptionsType[] | any,
    overlay: google.maps.OverlayView | any
  ) {
    if (!chart) {
      console.warn('Chart not found');
      return;
    }

    // Add new series
    chart.series[0]?.setData(newData[0].data, false, false);
    chart.redraw(); // Redraw after adding all new series
    overlay.draw(); // Redraw overlay

    return chart;
  }

  customMapImageLayerFactory() {
    return class extends google?.maps?.OverlayView {
      private _baseFrameInterval: number = 500;
      private get baseFrameInterval(): number {
        return this._baseFrameInterval;
      }
      private set baseFrameInterval(v: number) {
        this._baseFrameInterval = v;
        this.transitionDuration = this.baseFrameInterval * 0.3;
      }

      private transitionDuration = this.baseFrameInterval * 0.3; // Duration of transition in ms
      private layerOpacity = 0.4; // Opacity of the overlay layer

      private image: HTMLImageElement;
      private div: HTMLDivElement | null = null;
      private currentImg: HTMLImageElement | null = null;
      private nextImg: HTMLImageElement | null = null;
      private bounds: google.maps.LatLngBounds;
      private isTransitioning: boolean = false;

      constructor(
        bounds: google.maps.LatLngBounds,
        image: HTMLImageElement,
        baseFrameInterval: number = 500
      ) {
        super();
        this.bounds = bounds;
        this.image = image;
        this.baseFrameInterval = baseFrameInterval;
      }

      override onAdd() {
        this.div = document.createElement('div');
        this.div.style.borderStyle = 'none';
        this.div.style.borderWidth = '0px';
        this.div.style.position = 'absolute';

        // Create and set up the current image
        this.currentImg = document.createElement('img');
        this.currentImg.src = this.image.src;
        this.setupImageStyles(this.currentImg);
        this.currentImg.style.opacity = this.layerOpacity.toString();

        // Create and set up the next image (initially hidden)
        this.nextImg = document.createElement('img');
        this.setupImageStyles(this.nextImg);
        this.nextImg.style.opacity = '0';

        // Add both images to the container
        this.div.appendChild(this.currentImg);
        this.div.appendChild(this.nextImg);

        const panes = this.getPanes();
        panes?.overlayLayer.appendChild(this.div);
      }

      private setupImageStyles(img: HTMLImageElement) {
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        img.style.left = '0';
        img.style.top = '0';
        img.style.transition = `opacity ${this.transitionDuration}ms`;
      }

      override draw() {
        if (!this.div) return;

        const overlayProjection = this.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getNorthEast()
        );

        if (sw && ne) {
          this.div.style.left = sw.x + 'px';
          this.div.style.top = ne.y + 'px';
          this.div.style.width = ne.x - sw.x + 'px';
          this.div.style.height = sw.y - ne.y + 'px';
        }
      }

      override onRemove() {
        if (this.div) {
          this.div.parentNode?.removeChild(this.div);
          this.div = null;
          this.currentImg = null;
          this.nextImg = null;
        }
      }

      updateImage(newImage: HTMLImageElement) {
        if (
          !this.div ||
          !this.currentImg ||
          !this.nextImg ||
          this.isTransitioning
        )
          return;

        this.isTransitioning = true;

        // Set the new image to the next image element (currently hidden)
        this.nextImg.src = newImage.src;
        this.nextImg.style.transition = `opacity ${this.transitionDuration}ms`;
        this.currentImg.style.transition = `opacity ${
          this.transitionDuration * 1.2
        }ms`;

        // Start transition after a brief delay to ensure the new image is loaded
        setTimeout(() => {
          if (!this.currentImg || !this.nextImg) return;

          // Fade in the next image
          this.nextImg.style.opacity = this.layerOpacity.toString();
          // Fade out the current image
          this.currentImg.style.opacity = '0';

          // After transition completes, swap the images and reset
          setTimeout(() => {
            if (!this.currentImg || !this.nextImg) return;

            // Swap the references
            const temp = this.currentImg;
            this.currentImg = this.nextImg;
            this.nextImg = temp;

            // Reset the next image
            this.nextImg.style.opacity = '0';
            this.isTransitioning = false;
          }, this.transitionDuration);
        }, 50);
      }
    };
  }
}
