import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { CommonService } from 'src/app/shared/services/common.service';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  userId!: number;
  projectsOrgInfo: any;
  remainingSeats?: number;
  showArchived: boolean = true;
  isProjectEditRoute: any;
  public subscriptions: Subscription[] = [];
  moduleAccess: any;
  user: any;
  showAllOrg: any;

  constructor(
    public projectsService: ProjectsService,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.isProjectEditRoute = this.router.url.includes(
        '/projects/project-edit/'
      );
    });
    this.user = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    this.userId = this.user.userId;
    this.moduleAccess = this.commonService.moduleAccessibility(1012);
  }

  onToggleChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.showArchived = false;
      this.projectsService.updateArchiveData(event.checked);
    } else {
      this.showArchived = true;
      this.projectsService.updateArchiveData(event.checked);
    }
  }

  getAllOrg(event: any) {
  this.showAllOrg = event.checked;
  this.projectsService.updateAllOrgData(event.checked);
  }

  addUser() {
    let form = this.formsService
      .openForm(ProjectFormComponent, false, 'xl')
      .subscribe({
        next: (result) => {
          // if (result) {
          //   this.projectsService.getUsersAllOrgsInfo(this.userId).subscribe();
          // }
        },
        error: (err: any) => {
          console.info('Error:', err);
        },
      });
    this.subscriptions.push(form);
  }

  addSubscriptions() {
    // let shared = this.projectsService.sharedSeats$.subscribe((res: any) => {
    //   if (res) {
    //     this.projectsOrgInfo = res;
    //     this.remainingSeats =
    //       this.projectsOrgInfo.max_seats - this.projectsOrgInfo.used_seats;
    //       this.loadData();
    //   }
    // });
    // this.subscriptions.push(shared);
  }

  // loadData(): void {
  //   let userInfoSub = this.projectsService.getUsersAllOrgsInfo(this.userId).subscribe({
  //     next: (res: any) => {
  //       if (res) {
  //         // this.setProjectInfo();
  //       }
  //     },
  //     error: (err) => {
  //       console.info(`Error: ${err}`);
  //     },
  //   });
  //   this.subscriptions.push(userInfoSub);
  // }

  // setProjectInfo() {
  //   let projectOrgInfo;
  //     this.projectsService.getMasterOrgInfo(this.userId).subscribe((res) => {
  //       this.addSubscriptions();
  //       projectOrgInfo = res.info;
  //       projectOrgInfo.expiry = this.customMomentService.formatDatetime({
  //         epoch: projectOrgInfo.expiry,
  //         format: 'DD/MM/YYYY',
  //       });
  //     });
  //     this.projectsService.updateSeats(projectOrgInfo);
  // }

  getProjectModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.showArchived = false;
    this.projectsService.updateArchiveData(false);
  }
}
