<app-form-container
  (close)="closeForm()"
  [isValid]="isNextStep"
  (submit)="submitAndNext()"
  (cancelled)="backBtn === 'Back' ? previous() : closeForm()"
  [cancelBtn]="backBtn"
  [tableTitle]="isEditMode ? 'Edit Alert' : 'Add Alert'"
  [submitBtn]="submitBtn"
  [submitBtnTooltip]="submitButtonTooltip"
>
  <div>
    <mat-stepper
      [linear]="true"
      #stepper
      class="h-100 custom-stepper"
      (selectionChange)="onStepChange($event)"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="configurationForm" label="Configuration">
        <div [formGroup]="configurationForm" class="row py-2 gy-4">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Device Type</mat-label>
              <mat-select formControlName="deviceType">
                <mat-option
                  *ngFor="let deviceTypes of deviceTypes"
                  [value]="deviceTypes.deviceTypeId"
                >
                  {{ deviceTypes.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Config Name</mat-label>
              <input
                type="text"
                [placeholder]="'Config Name'"
                matInput
                formControlName="configurationName"
              />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Select Devices</mat-label>
              <mat-select multiple formControlName="devices">
                <mat-option
                  *ngFor="
                    let device of devices | deviceType : currentDeviceType
                  "
                  [value]="device.deviceId"
                >
                  {{ device.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Configuration Description</mat-label>
              <input
                type="text"
                [placeholder]="'Configuration Description'"
                matInput
                formControlName="configurationDescription"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="parameterForm" label="Parameters">
        <div [formGroup]="parameterForm">
          <div class="d-flex flex-column h-100 overflow-auto">
            <div class="pb-2">
              Add Parameter , Select the condition and Set the threshold
            </div>

            <ng-container
              *ngFor="
                let allCondition of asFormArray(
                  parameterForm.get('allconditions')!
                ).controls;
                let i = index
              "
            >
              <div class="col-12">
                <div
                  class="w-100"
                  style="
                    border: 1px;
                    border-style: solid;
                    border-color: rgba(0, 0, 0, 0.25) !important;
                    padding: 10px;
                    border-radius: 10px;
                  "
                >
                  <div
                    class="d-flex flex-row"
                    [formGroup]="asFormGroup(allCondition)"
                  >
                    <ng-container
                      *ngIf="
                        uniqueKeys ?? {}
                          | gasLevel
                            : fieldsOfCurrentDeviceType
                            : limitsOfFields;
                        let keysOfSelectedDeviceTransformed
                      "
                    >
                      <div class="col-4 alert-box">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-select formControlName="parameter">
                            <mat-option
                              *ngFor="
                                let keysOfSelectedDeviceT of keysOfSelectedDeviceTransformed;
                                let i = index
                              "
                              [value]="keysOfSelectedDeviceT?.name"
                              [disabled]="
                                keyStatus &&
                                keyStatus[keysOfSelectedDeviceT.name] &&
                                keyStatus[keysOfSelectedDeviceT.name]['gt'] &&
                                keyStatus[keysOfSelectedDeviceT.name]['lt']
                              "
                            >
                              {{ keysOfSelectedDeviceT?.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-4 alert-box">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-select formControlName="condition">
                            <mat-option
                              *ngFor="let statement of conditionalStatements"
                              [value]="statement.value"
                              [disabled]="
                                (parameterForm.get('allconditions') &&
                                  parameterForm.get('allconditions')!.value[i]
                                    .parameter === '') ||
                                (statement.value === '>'
                                  ? keyStatus &&
                                    keyStatus[
                                      parameterForm.get('allconditions') &&
                                        parameterForm.get('allconditions')!
                                          .value[i].parameter
                                    ] &&
                                    keyStatus[
                                      parameterForm.get('allconditions') &&
                                        parameterForm.get('allconditions')!
                                          .value[i].parameter
                                    ]['gt']
                                  : keyStatus &&
                                    keyStatus[
                                      parameterForm.get('allconditions') &&
                                        parameterForm.get('allconditions')!
                                          .value[i].parameter
                                    ] &&
                                    keyStatus[
                                      parameterForm.get('allconditions') &&
                                        parameterForm.get('allconditions')!
                                          .value[i].parameter
                                    ]['lt'])
                              "
                            >
                              {{ statement.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </ng-container>
                    <div class="col-2 alert-box">
                      <mat-form-field class="w-100" appearance="outline">
                        <input matInput formControlName="value" />
                      </mat-form-field>
                    </div>

                    <div
                      class="col-2 d-flex align-items-center justify-content-center"
                    >
                      <div class="col-6">
                        <mat-label
                          class="w-100 d-flex align-items-center justify-content-center"
                          >{{ unitsOfDeviceType
                          && unitsOfDeviceType?.[allCondition.get('parameter')!.value]?.label || 'unit' }}</mat-label
                        >
                      </div>
                      <ng-container *ngIf="isSelected === true">
                        <div
                          class="col-6 d-flex align-items-center justify-content-center"
                        >
                          <button
                            mat-icon-button
                            matTooltip="Cancel"
                            (click)="removeParameterField(i)"
                            [ngStyle]="{
                              display:
                                parametersList.length > 1 ? 'inline' : 'none'
                            }"
                          >
                            <mat-icon class="material-symbols-outlined"
                              >cancel</mat-icon
                            >
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3">
                <span
                  class="justify-content-center align-items-center d-flex"
                  >{{ isSelected ? "or" : "and" }}</span
                >
              </div>
            </ng-container>

            <div *ngIf="!isValueValid">
              <mat-error>Try entering a number value.</mat-error>
            </div>
          </div>

          <div class="col-12">
            <button
              mat-stroked-button
              color="primary"
              matTooltip="Add"
              class="w-100"
              (click)="addParameterField()"
            >
              <mat-icon class="material-symbols-outlined">add</mat-icon> Add
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step label="Information">
        <form [formGroup]="informationForm" class="row py-2 gy-4">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Time Interval</mat-label>
              <mat-select formControlName="timeInterval">
                <mat-option
                  *ngFor="let interval of timeInterval"
                  [value]="interval.value"
                >
                  {{ interval.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12" *ngIf="isRawSelected">
            <mat-form-field class="w-100">
              <mat-label>Send Email Interval</mat-label>
              <input
                type="text"
                [placeholder]="'Send Email Interval'"
                matInput
                (input)="onInput($event)"
                formControlName="emailSmsInterval"
              />
              <mat-hint
                >Interval must be greater than equal to 900 seconds</mat-hint
              >
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Enter upto 10 Emails</mat-label>
              <mat-chip-grid
                #emailChipGrid
                aria-label="Enter Email"
                class="example-chip-list"
              >
                <mat-chip-row
                  *ngFor="let email of emails"
                  (removed)="remove(email, 0)"
                  [editable]="true"
                  (edited)="edit(email, $event, 0)"
                >
                  {{ email.name }}
                  <button matChipRemove matTooltip="Cancel">
                    <mat-icon class="material-symbols-outlined"
                      >cancel</mat-icon
                    >
                  </button>
                </mat-chip-row>
                <input
                  *ngIf="countEmail < 10"
                  placeholder="Add Email..."
                  [matChipInputFor]="emailChipGrid"
                  [matChipInputSeparatorKeyCodes]="seperatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event, 0)"
                  formArrayName="email"
                />
              </mat-chip-grid>
            </mat-form-field>
            <mat-error class="text-center" *ngIf="!isEmailValid"
              >Please enter a valid email</mat-error
            >
            <!-- <mat-error class="text-center" *ngIf="enteringOwnEmail" -->
            <!-- <mat-error class="text-center"
              >You will receive alerts on your Email Id without adding
              it!!</mat-error
            > -->
          </div>
          <!-- <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Enter upto 5 Numbers</mat-label>
              <mat-chip-grid
                #numberChipGrid
                aria-label="Enter number"
                class="example-chip-list"
              >
                <mat-chip-row
                  *ngFor="let number of phNumbers"
                  (removed)="remove(number, 1)"
                  [editable]="true"
                  (edited)="edit(number, $event, 1)"
                >
                  {{ number.name }}
                  <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + number.name"
                    matTooltip="Cancel"
                  >
                    <mat-icon class="material-symbols-outlined"
                      >cancel</mat-icon
                    >
                  </button>
                </mat-chip-row>
                <input
                  *ngIf="countPhNumber < 5"
                  placeholder="New Number..."
                  [matChipInputFor]="numberChipGrid"
                  [matChipInputSeparatorKeyCodes]="seperatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event, 1)"
                  formArrayName="phoneNumber"
                />
              </mat-chip-grid>
              <mat-hint
                >Add your respective Country Code before the number , for ex +91
                for India</mat-hint
              >
            </mat-form-field>
            <mat-error class="text-center" *ngIf="!isPhNumberValid"
              >Please enter a valid number</mat-error
            >
          </div> -->
          <app-mobile-number-input
            (phoneNumberAction)="onMobileNumberChange($event)"
            [patchNumbers]="existingMobileNumbers"
          >
          </app-mobile-number-input>

          <ng-container *ngIf="informationForm.hasError('emailPhoneEmpty')">
            <mat-error class="mt-1">
              Email or Mobile Number Required
            </mat-error>
          </ng-container>

          <ng-container *ngIf="isEditMode">
            <div class="col-12 d-flex justify-content-start">
              <mat-label class="alert-status">Status</mat-label>
              <mat-slide-toggle
                color="primary"
                formControlName="alertStatus"
                [matTooltip]="
                  informationForm.get('alertStatus')?.value
                    ? 'Enabled'
                    : 'Disabled'
                "
              ></mat-slide-toggle>
            </div>
          </ng-container>
        </form>
      </mat-step>
    </mat-stepper>
  </div>
</app-form-container>
