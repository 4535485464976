import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { TableComponent } from './pages/table/table.component';
import { WidgetComponent } from './pages/widget/widget.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'widget',
        component: WidgetComponent,
      },
      {
        path: 'table',
        component: TableComponent,
      },
      {
        path: 'widget/:deviceId',
        component: WidgetComponent,
      },
      {
        path: 'table/:deviceId',
        component: TableComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
