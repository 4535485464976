<div class="w-100 h-100 d-flex flex-column justify-content-start align-items-center"
    style="background: transparent; background-color: #000000bf;" (click)="closeOverlay()">
    <div style="width: 100%; height: 7%;" class="d-flex justify-content-between align-items-center">
        <button mat-icon-button color="primary" matTooltip="Back" (click)="closeOverlay()">
            <mat-icon class="material-symbols-outlined">arrow_back</mat-icon>
        </button>

        <!-- <button mat-icon-button color="primary" matTooltip="Download Image">
            <mat-icon class="material-symbols-outlined">get_app</mat-icon>
        </button> -->
    </div>

    <div style="width: 75%; height: 93%;" class="d-flex align-items-center justify-content-center"
        (click)="closeOverlay()">
        <img [src]="imageUrl" alt="Image Not Found" style="max-height: 85%; max-width: 100%"
            (click)="$event.stopPropagation();">
    </div>
</div>