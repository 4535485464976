export class APIConstants {
  public static SECURE_LOGIN: string = 'users/login';
  public static FORGOT_PASSWORD: string = 'users/forgot-password';
  public static MODULE: string = 'module';
  public static CONFIG_PATH: string = 'assets/config.json';
  public static MODULE_ACCESS_CONFIG_PATH: string = 'assets/modulesAccess.json';
  public static DEVICE_DETAILS: string = 'users/{userId}/overview';
  public static DISPLAY_DETAILS_LIST: string = 'users/{userId}/display';
  public static DISPLAY_DETAILS: string = 'users/{userId}/display/{displayId}';
  public static DELETE_DISPLAY: string = 'users/{userId}/display/{displayId}';
  public static AQI_ALL: string = 'users/{userId}/aqi';
  public static USER_NOTIFICATIONS: string = 'users/{userId}/notifications';
  public static LAST_24_HOURS_DEVICES_AVG: string = 'devices/data';
  public static REALTIME_DEVICE_DATA: string = 'devices/data';
  public static LAST_24_HOURS_DEVICE_AVG: string = 'devices/data';
  public static HOURLY_DATA_PREDICTION: string = 'emission/predict';
  public static UPDATE_PASSWORD: string = 'users/update-password';
  public static ADD_SUB_USER: string = 'users/{userId}/users';
  public static ADD_ALERT: string = 'users/{userId}/alerts';
  public static UPDATE_ALERT: string = 'users/{userId}/alerts/{alertId}';
  public static MIGRATE_ALERT: string = 'users/{userId}/migration/alerts';
  public static MIGRATE_REPORT: string = 'users/{userId}/migration/reports';
  public static PARAMETER_COMPARISON: any = 'devices/data';
  public static HOURLY_DEVICE_DATA: any = 'devices/data';
  public static EMAIL_QUICK_REPORT_PDF: string = 'devices/data/email';
  public static UPDATE_UNITS_LIMITS: string =
    'users/{userId}/users/{targetUserId}';
  public static RESET_UNITS: string = 'users/{userId}/users/{targetUserId}';
  public static UPDATE_USER_PROFILE: string = 'users/{userId}/profile';
  public static DATA_FLAGGING_LIST: any = 'devices/data';
  public static NEW_FEATURES_INFO: string = 'feature/latest-version';
  //api endpoint to download csv
  public static DOWNLOAD_CSV_FILE: string = 'devices/{deviceId}/csv';
  //api endpoint to download sla report
  public static GET_SLA_REPORT: string = 'devices/pdf/sla';

  public static UPDATE_DEVICE_STATUS(
    deviceId: string,
    status: 'on' | 'off'
  ): string {
    return 'devices/' + deviceId + '/status/' + status;
  }
  public static GET_CLUSTERS(): string {
    return 'users/{userId}/cluster';
  }
  public static ADD_CLUSTER(): string {
    return 'users/{userId}/cluster';
  }
  public static UPDATE_CLUSTER(clusterId: String): string {
    return 'users/{userId}/cluster/' + clusterId;
  }
  public static DELETE_CLUSTER(clusterId: any): string {
    return 'users/{userId}/cluster/' + clusterId;
  }
  public static GET_ADMIN_DEVICES(userId: string): string {
    return 'admin/users/' + userId + '/devices';
  }

  public static GET_COMPLAINS(pageNo?: number, limit?: number, filter?: any) {
    return 'orgs/{orgId}/complain';
  }

  //api endpoint to get reports list of user
  public static GET_REPORTS(userId: number): string {
    return 'users/' + userId + '/reports';
  }

  //api endpoint to download quick reports
  public static GET_QUICK_REPORT() {
    return 'devices/{deviceId}/pdf';
  }

  //api endpoint to add a new automated report
  public static ADD_AUTOMATED_REPORT(userId: number): string {
    return 'users/' + userId + '/reports';
  }

  //api endpoint to update a automated report
  public static UPDATE_OR_DELETE_REPORT(
    userId: number,
    reportId: number
  ): string {
    return 'users/' + userId + '/reports/' + reportId;
  }

  //api endpoint to get the alerts list
  public static GET_ALERT_LIST(userId: string): string {
    return 'users/' + userId + '/alerts';
  }
  //api endpoint for adding new complain
  public static ADD_COMPLAIN(OrgId: number): string {
    // return 'complain/org/' + OrgId;
    return 'orgs/' + OrgId + '/complain';
  }

  //api endpoint for deleting complain
  public static DELETE_COMPLAIN(complainId: any): string {
    return 'orgs/{orgId}/complain/' + complainId;
  }
  //api endpoint for updating the complain info
  public static UPDATE_COMPLAIN(complainId: number): string {
    // return 'complain/' + complainId;
    return 'orgs/{orgId}/complain/' + complainId;
  }

  public static DOWNLOAD_COMPLAIN(): any {
    return 'orgs/{orgId}/complain/pdf';
  }

  //api endpoint to get all units of all parameter
  public static GET_ANALYTICS_LIST(userId: number): string {
    return 'users/' + userId + '/analytics';
  }

  //api endpoint to add new analytics
  public static ADD_NEW_ANALYTICS(userId: number): string {
    return 'users/' + userId + '/analytics';
  }

  //api endpoint to update existing analytic
  public static UPDATE_EXISTING_ANALYTICS(
    userId: number,
    analyticsId: number
  ): string {
    return 'users/' + userId + '/analytics/' + analyticsId;
  }

  //api endpoint for deleting an analytic
  public static DELETE_ANALYTIC(userId: number, analyticsId: number): string {
    return 'users/' + userId + '/analytics/' + analyticsId;
  }
  //api endpoint for adding new device
  public static ADD_DEVICE(deviceId: string): string {
    return 'devices/' + deviceId;
  }

  //api endpoint for updating the device info
  public static UPDATE_DEVICE(deviceId: string, userId: string): string {
    return 'users/' + userId + '/devices/' + deviceId;
  }

  //api endpoint for updating time interval
  public static UPDATE_INTERVAL(deviceId: string, interval: number): string {
    return 'devices/' + deviceId + '/interval/' + interval;
  }

  //api endpoint for updating image
  public static UPDATE_DEVICE_IMAGE(): string {
    return 'util/upload/devices-{userId}';
  }

  //api endpoint for uploading logo image
  public static UPLOAD_LOGO_IMAGE(): string {
    return 'util/upload/logo-{userId}';
  }

  //api endpoint for adding new display
  public static ADD_DISPLAY(userId: string): string {
    return 'users/' + userId + '/display';
  }

  //api endpoint for updating the display info
  public static UPDATE_DISPLAY(displayId: number, userId: string): string {
    return 'users/' + userId + '/display/' + displayId;
  }

  public static DELETE_SUB_USER: string = 'users/{userId}/users/{targetUserId}';

  public static GET_ADMIN_DETAILS(orgId: string): string {
    return 'org/user/org/' + orgId;
  }

  //api enpdoint to get automation data of a device
  public static GET_AUTOMATION_DATA(deviceId: string): string {
    return 'devices/' + deviceId + '/config';
  }

  //api endpoint to add a new config
  public static ADD_NEW_CONFIG(deviceId: string) {
    return 'devices/' + deviceId + '/config';
  }

  public static UPDATE_CONFIG_DATA(deviceId: string) {
    return 'devices/' + deviceId + '/config';
  }

  public static REBOOT_DEVICE(deviceId: string, template: string) {
    return 'devices/' + deviceId + '/run/' + template;
  }

  public static ON_DEVICE_TERMINAL(deviceId: string) {
    // return 'devices/{deviceId}/run/{template}'
    return 'devices/' + deviceId + '/profile';
  }

  //api endpoint for getting sensor expiry info details
  public static GET_SENSOR_EXPIRY_DETAILS(deviceId: string) {
    return 'devices/' + deviceId + '/sensor/expiry';
  }

  //api endpoint for updating user profile
  public static UPDATE_PROFILE(userId: number): string {
    let targetUserId: number = userId;
    return `users/${userId}/users/${targetUserId}`;
  }

  //api endpoint for getting subusers of organization
  public static GET_SUB_USERS(userId: string, targetOrgId: string): any {
    return `users/${userId}/orgs/${targetOrgId}/users`;
  }

  //api endpoint for getting devices of a subuser
  public static GET_SUB_USER_DEVICES(userId: number) {
    return 'users/' + userId + '/devices';
  }

  //api endpoint to update aqi of user
  public static UPDATE_AQI(userId: number) {
    let targetUserId: number = userId;
    return `users/${userId}/users/${targetUserId}`;
  }

  public static GET_ALL_UNITS: string = 'units/keys';

  //api endpoint for updating sub user information
  public static UPDATE_SUB_USER_INFO(userId: number, targetUserId: number) {
    return 'users/' + userId + '/users/' + targetUserId;
  }

  public static getComplainsByOrg(
    organizationId: string,
    pageNumber?: number,
    limit?: number,
    filter?: string
  ): string {
    return 'orgs/' + organizationId + '/complain';
  }

  public static fetchWidgets() {
    return 'widgets';
  }
  public static getCategories() {
    return 'complain/category';
  }

  public static GET_ORG_BY_ID(orgId: any) {
    return 'orgs/' + orgId + '/complain';
  }
  public static GET_USERS_ALLORGS(userId: any): string {
    return 'users/' + userId + '/orgs';
  }

  public static SET_ORG_ARCHIVE(userId: number, orgId: string) {
    return 'users/' + userId + '/orgs/' + orgId + '/archive';
  }

  public static GET_MASTER_ORG(userId: number): string {
    return 'users/' + userId + '/org';
  }

  public static ADD_PROJECT(userId: number): string {
    return 'users/' + userId + '/orgs';
  }

  public static GET_ORG_USERS(userId: number, targetOrgId: string) {
    return 'users/' + userId + '/orgs/' + targetOrgId + '/users';
  }

  public static DELETE_USER(userId: number, targetUserId: number) {
    return 'users/' + userId + '/users/' + targetUserId;
  }

  public static FORGET_PASSWORD: string = 'users/forgot-password';
  public static SEND_EMAIL: string = 'users/forgot-password';

  public static UPDATE_PROJECT(userId: any, targetOrgId: any) {
    return 'users/' + userId + '/orgs/' + targetOrgId;
  }

  public static UPGRADE_PLAN(userId: any) {
    return 'user/' + userId + '/buy';
  }
  public static FLAG_THE_DATA(): string {
    return 'devices/data';
  }
  public static DOWNLOAD_DATAFLAG(): any {
    return 'devices/{deviceId}/data-flag/pdf';
  }

  // Heatmap API endpoints
  public static GET_BOUNDING_BOX(projectId: string) {
    return `emission/getGeoJson`;
  }
  public static GET_LAYERS(projectId: string) {
    return `emission/layer`;
  }
  public static GET_POLYGON_DATA(projectId: string) {
    return `emission/polygonData`;
  }
}
