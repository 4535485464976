<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <ng-container *ngIf="currentUrl === '/user/aqi'">
      <div
        [formGroup]="headerForm"
        class="row h-100 align-items-center gx-2 gy-2 gy-lg-0 mx-0"
      >
        <mat-form-field class="col-12 col-lg-3">
          <mat-label>Device Type</mat-label>
          <mat-select formControlName="deviceType">
            <mat-option
              *ngFor="let device of deviceTypesWithAQI"
              [value]="device.deviceTypeId"
            >
              {{ device.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="showAQIOptionList">
          <mat-form-field class="col-12 col-lg-3">
            <mat-label>AQI Type</mat-label>
            <mat-select formControlName="aqiType">
              <ng-container *ngIf="allAQIOfSelectedDeviceType !== undefined">
                <mat-option
                  *ngFor="let aqi of allAQIOfSelectedDeviceType | keyvalue"
                  [value]="aqi.value"
                >
                  {{ aqi.value.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <div class="d-flex gap-2 col-lg-auto col-12 col-lg-3">
            <button
              mat-raised-button
              color="primary"
              class="w-100 w-lg-auto"
              [disabled]="isUpdateAndCancelButtonDisabled"
              (click)="onUpdate()"
              matTooltip="Update"
            >
              <mat-spinner
                *ngIf="isApiCall"
                class="mx-auto"
                [diameter]="30"
              ></mat-spinner>
              <ng-container *ngIf="!isApiCall">Update</ng-container>
            </button>
            <button
              *ngIf="!isUpdateAndCancelButtonDisabled"
              mat-mini-fab
              color="primary"
              matTooltip="Cancel Selection"
              (click)="onCancel()"
              matTooltip="Cancel"
              class="cancel"
            >
              <mat-icon class="material-symbols-outlined">Cancel</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="userRole === 1 && currentUrl === '/user/sub-users'">
      <div class="row justify-content-end">
        <div
          class="col-12 col-lg-auto"
          [matTooltip]="isSeatsFull ? 'Buy More Seats' : 'Add User'"
        >
          <button
            [disabled]="isSeatsFull"
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
            (click)="addUser()"
          >
            Add User<mat-icon class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentUrl === '/user/units'">
      <div
        [formGroup]="unitForm"
        class="row h-100 align-items-center justify-content-between gx-2 gy-2 gy-lg-0 mx-0 gap-2"
      >
        <mat-form-field class="col-12 col-lg-3">
          <mat-label>Device Type</mat-label>
          <mat-select formControlName="deviceType">
            <mat-option *ngFor="let device of userDeviceTypes" [value]="device">
              {{ device.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="d-flex col-12 col-lg-4 justify-content-end">
          <div class="col-12 col-lg-auto">
            <button
              class="refresh"
              mat-mini-fab
              color="primary"
              (click)="openDialog()"
              matTooltip="Reset Limits & Units"
            >
              <mat-icon matSuffix class="material-symbols-outlined"
                >restart_alt</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
