import { Component, Input } from '@angular/core';
import { FilterOptions } from '../../models/filter-options';
import { FilterColumn } from '../../models/filter-column';
import { FormControl } from '@angular/forms';
import { DeviceUtil } from '../../utils/device-utils';
import { DeviceField } from '../../models/device/device-field';
import { FieldLimit } from '../../models/device/field-limit';
import { CustomMomentService } from '../../services/custom-moment.service';
import { LocalStorageConstants } from '../../constants/local-storage.constant';
import { LocalStorageService } from '../../services/local-storage.service';
import * as Highcharts from 'highcharts';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceService } from 'src/app/shared/services/device.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-graph-accordion',
  templateUrl: './graph-accordion.component.html',
  styleUrls: ['./graph-accordion.component.scss'],
})
export class GraphAccordionComponent {
  @Input() columns!: any[];
  @Input() filterOptions!: FilterOptions;
  @Input() autoRange: boolean = true;
  @Input() deviceData!: { [key: string]: any[] };
  parameterControl = new FormControl();
  highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  selectedParams: any[] = [];
  deviceDataList: any;
  graphType: any = 'line';
  selectedField: string = '';
  userTimeFormat: number;
  show!: boolean;
  defaultColor: string = '#00c4b4';
  deviceFields: DeviceField[] = [];
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  filterOptionList: any;
  aqiIndexColor!: AqiIndexColorArray;
  constructor(
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
  }
  ngOnInit() {
    this.updateFilterOptionList();
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;
    let deviceTypes = this.commonService.getAllDeviceTypes();
    let allAqi = this.commonService.getAllAQI();
    let allAqis = this.commonService.getAllAQIs();
    this.aqiIndexColor = DeviceUtil.aqiColorArray(deviceTypes, allAqi, allAqis);
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.deviceDataList = this.deviceData;
  }
  setupDataPoints(value: any) {
    let deviceFields = [];
    deviceFields = Object.keys(this.deviceDataList[4]?.payload?.d);
    for (let df of deviceFields) {
      let field: DeviceField = this.fields.find((f) => f?.fkey === df)!;
      if (field && field.fkey !== 't') {
        this.deviceFields.push(field!);
      }
    }
    this.deviceFields = CommonUtil.setParameterSequence(this.deviceFields);
    this.selectedField = value.columnDef;
    this.show = false;
    let dataPoints = [];
    let rangeIndex = this.limits
      .map((x: any) => {
        return x.fkey;
      })
      .indexOf(this.selectedField);

    const that = this;
    let xAxisOptions: Highcharts.XAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatTime({
            epochMS: parseInt('' + this.value),
            format: that.commonService.getDateTimeFormatForGraph(),
          });
        },
      },
    };

    let yAxisOptions: Highcharts.YAxisOptions = {
      // title: { text: `Chart of ${this.stripHTMLTags(selectedColumn.header)}` }
      title: { text: null },
    };

    if (!this.autoRange) {
      yAxisOptions['tickPositions'] =
        this.limits[rangeIndex].range?.length > 0
          ? this.limits[rangeIndex].range!
          : [
              this.limits[rangeIndex].lowestLimit,
              this.limits[rangeIndex].highestLimit,
            ]!;
    }

    let maxValue: number = 0;

    for (let data of this.deviceDataList) {
      let value: number = parseFloat(data?.payload?.d[this.selectedField!]);
      if (value > maxValue) {
        maxValue = value;
      }

      let dataPoint: Highcharts.PointOptionsObject = {
        x: +this.customMomentService.moment.unix(data?.payload?.d?.t),
        y: parseFloat(data?.payload?.d[this.selectedField!]),
        color: '',
      };

      if (this.limits.length && this.limits[rangeIndex]) {
        let i: number;
        for (i = 0; i < this.limits[rangeIndex].range?.length; i++) {
          if (Math.min(value, this.limits[rangeIndex].range[i]) === value) {
            break;
          }
        }

        if (this.aqiIndexColor.limits.length > --i) {
          dataPoint.color = this.aqiIndexColor.color[i] || this.defaultColor;
        } else {
          dataPoint.color =
            this.aqiIndexColor.outOfRangeColor || this.defaultColor;
        }
      } else {
        dataPoint.color = this.defaultColor;
      }
      dataPoints.push(dataPoint);
    }
    this.chartOptions.title = null!;
    this.chartOptions.subtitle = null!;
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.legend = { enabled: false };

    this.chartOptions.tooltip = {
      shared: true,
      formatter: function () {
        return (
          that.customMomentService.formatDatetime({
            epochMS: parseInt('' + this.x),
            format: that.commonService.getDateTimeFormatForGraph(),
          }) +
          '</br>' +
          `<span style="color: ${this.point.color}">\u25CF</span>` +
          '<b>' +
          this.series.name +
          '</b>' +
          ' : ' +
          this.y
        );
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOptions;
    this.chartOptions.series = [];
    let fieldName = DeviceUtil.getFieldName(this.selectedField, this.fields);
    let fieldUnit = DeviceUtil.getFieldUnit(this.selectedField, this.fields);
    fieldUnit = fieldUnit.replaceAll(' ', '');
    this.chartOptions.series[0] = {
      name: fieldName + (fieldUnit ? ' (' + fieldUnit + ')' : ''),
      data: dataPoints,
    } as Highcharts.SeriesOptionsType;

    setTimeout(() => {
      this.show = true;
    });
  }

  displayFn(column: any): string {
    return column ? column.header : '';
  }
  openFilterOptions(): void {
    const data: FilterOptions = {
      columns: this.columns.map((col) => {
        const column: FilterColumn = {
          key: col.columnDef,
          value: col.header,
          parameter: col.parameter,
          selected: col.selected,
          filter: col.filter,
        };
        return column;
      }),
    };
    this.filterOptions = data;
  }
  selectionMade(event: Event, trigger: any): void {
    trigger.openPanel();
  }
  get columnsToDisplay() {
    return this.columns.filter((column) => {
      return column.parameter;
    });
  }
  updateFilterOptionList(): void {
    this.filterOptionList = this.columnsToDisplay.map((column) => ({
      ...column,
      header: this.stripHTMLTags(column.header),
    }));
  }

  stripHTMLTags(html: string): any {
    html = html.replace(/<small[^>]*>(.*?)<\/small>/g, '').trim();
    return html.replace(/<[^>]*>/g, '').trim();
  }
  getFieldName(param: any): string {
    return param;
  }
}
