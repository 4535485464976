<app-form-container
  [submitBtn]="submitBtn"
  [tableTitle]="displayTitle"
  [isValid]="isNextStep"
  (submit)="submitAndNext()"
  (cancelled)="previous()"
  (close)="closeForm()"
  [cancelBtn]="backBtn"
>
  <mat-stepper
    [linear]="true"
    #stepper
    class="h-100 custom-stepper"
    (selectionChange)="onStepChange($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step
      [stepControl]="displayDetailsForm"
      [label]="isEditMode ? 'Screen Info' : 'Select Display Label'"
    >
      <form
        class="row gy-3 mt-1"
        [formGroup]="displayDetailsForm"
        *ngIf="!isEditMode"
      >
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Display Name</mat-label>
            <input
              type="text"
              [placeholder]="'Display Name'"
              matInput
              trimValueAccessor
              formControlName="display_label"
            />
          </mat-form-field>
        </div>
      </form>
      <form
        class="row gy-3 mt-1"
        [formGroup]="displayDetailsForm"
        *ngIf="isEditMode"
      >
        <div *ngIf="loadTable | async" class="d-flex flex-column h-100">
          <ng-container *ngIf="data?.screens">
            <app-data-table
              [tableTitle]="'Screen List'"
              [data]="data?.screens ?? []"
              [columns]="defaultColumns"
              [displayedColumns]="displayedColumns"
              [showTableHeader]="false"
            >
            </app-data-table>
          </ng-container>
        </div>
        <div class="py-5" *ngIf="(loadTable | async) == false">
          <mat-spinner
            class="mx-auto"
            [diameter]="50"
            color="primary"
          ></mat-spinner>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="screenTypeForm" [label]="'Select Screen Type'">
      <form class="row gy-3 p-1" [formGroup]="screenTypeForm">
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Device Type</mat-label>
            <mat-select formControlName="deviceType">
              <mat-option
                *ngFor="let deviceType of deviceTypes"
                [value]="deviceType"
              >
                {{ deviceType.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="w-100"
          *ngIf="screenTypeForm.value.deviceType?.deviceTypeId"
        >
          <app-selection-tile
            [tileDetails]="screenTypeOptions"
            (onSelection)="screenTypeForm.get('screenType')?.setValue($event)"
            [initialSelection]="screenTypeForm.value.screenType?.id"
          >
          </app-selection-tile>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="screenDetailsForm" [label]="'Fill Screen info'">
      <form class="row gy-3 p-1" [formGroup]="screenDetailsForm">
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 0">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-left of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-left of the screen'"
                matInput
                trimValueAccessor
                formControlName="topLeft"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-right of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-right of the screen'"
                matInput
                trimValueAccessor
                formControlName="topRight"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Devices</mat-label>
                <mat-select multiple formControlName="devices">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Average Time</mat-label>
                <mat-select formControlName="avg_time">
                  <mat-option
                    *ngFor="let averageTimeOption of averageTimeOptions"
                    [value]="averageTimeOption.value"
                  >
                    {{ averageTimeOption.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 1">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-left of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-left of the screen'"
                matInput
                trimValueAccessor
                formControlName="topLeft"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-right of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-right of the screen'"
                matInput
                trimValueAccessor
                formControlName="topRight"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Devices</mat-label>
                <mat-select multiple formControlName="devices">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Parameters</mat-label>
                <mat-select multiple formControlName="parameter">
                  <mat-option
                    *ngFor="
                      let parameter of parameters
                        | gasLevel
                          : selectedDeviceTypeFields
                          : selectedDeviceTypeLimits
                    "
                    [value]="parameter.name"
                  >
                    {{ parameter.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Average Time</mat-label>
                <mat-select formControlName="avg_time">
                  <mat-option
                    *ngFor="let averageTimeOption of averageTimeOptions"
                    [value]="averageTimeOption.value"
                  >
                    {{ averageTimeOption.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 2">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-left of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-left of the screen'"
                matInput
                trimValueAccessor
                formControlName="topLeft"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-right of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-right of the screen'"
                matInput
                trimValueAccessor
                formControlName="topRight"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <ng-container formGroupName="body">
            <ng-container formArrayName="data">
              <div [formGroupName]="0">
                <mat-form-field class="w-100">
                  <mat-label>Header title of 1st AQI Indicator</mat-label>
                  <input
                    type="text"
                    [placeholder]="'Header title of 1st AQI Indicator'"
                    matInput
                    trimValueAccessor
                    formControlName="title"
                  />
                </mat-form-field>
              </div>
              <div [formGroupName]="1">
                <mat-form-field class="w-100">
                  <mat-label>Header title of 2nd AQI Indicator</mat-label>
                  <input
                    type="text"
                    [placeholder]="'Header title of 2nd AQI Indicator'"
                    matInput
                    trimValueAccessor
                    formControlName="title"
                  />
                </mat-form-field>
              </div>
            </ng-container>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Devices</mat-label>
                <mat-select multiple formControlName="devices">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Average Time</mat-label>
                <mat-select formControlName="avg_time">
                  <mat-option
                    *ngFor="let averageTimeOption of averageTimeOptions"
                    [value]="averageTimeOption.value"
                  >
                    {{ averageTimeOption.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 3">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Clusters</mat-label>
                <mat-select multiple formControlName="devices">
                  <mat-option
                    *ngFor="let cluster of clusters"
                    [value]="cluster.clusterId"
                  >
                    {{ cluster.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 4">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Devices</mat-label>
                <mat-select multiple formControlName="devices">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 5">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-left of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-left of the screen'"
                matInput
                trimValueAccessor
                formControlName="topLeft"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-right of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-right of the screen'"
                matInput
                trimValueAccessor
                formControlName="topRight"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <ng-container formGroupName="footer">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Subtitle Of Screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Subtitle Of Screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Logo Url</mat-label>
              <input
                type="text"
                [placeholder]="'Logo Url'"
                matInput
                trimValueAccessor
                formControlName="logoUrl"
              />
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 6">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-left of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-left of the screen'"
                matInput
                trimValueAccessor
                formControlName="topLeft"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-right of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-right of the screen'"
                matInput
                trimValueAccessor
                formControlName="topRight"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100" formArrayName="devices">
                <mat-label>Select Device</mat-label>
                <mat-select [formControlName]="0">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Parameter</mat-label>
                <mat-select formControlName="parameter">
                  <mat-option
                    *ngFor="
                      let parameter of parameters
                        | gasLevel
                          : selectedDeviceTypeFields
                          : selectedDeviceTypeLimits
                    "
                    [value]="parameter.name"
                  >
                    {{ parameter.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 7">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100" formArrayName="devices">
                <mat-label>Select Device</mat-label>
                <mat-select [formControlName]="0">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 8">
          <ng-container formGroupName="header">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Header title of screen</mat-label>
                <input
                  type="text"
                  [placeholder]="'Header title of screen'"
                  matInput
                  trimValueAccessor
                  formControlName="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-left of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-left of the screen'"
                matInput
                trimValueAccessor
                formControlName="topLeft"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div>
            <mat-form-field class="w-100">
              <mat-label>Top-right of the screen</mat-label>
              <input
                type="text"
                [placeholder]="'Top-right of the screen'"
                matInput
                trimValueAccessor
                formControlName="topRight"
              />
            </mat-form-field>
            <small>
              <mat-hint class="hint">Text or hosted Logo URL</mat-hint>
            </small>
          </div>
          <div formArrayName="infoScreen">
            <div
              *ngFor="
                let section of getInfoScreenArray().controls;
                let sectionIndex = index
              "
              [formGroupName]="sectionIndex"
              class="mb-3"
            >
              <div class="d-flex align-items-center gap-2">
                <mat-form-field class="w-100">
                  <mat-label>Sub Header</mat-label>
                  <input
                    matInput
                    trimValueAccessor
                    formControlName="subHeader"
                    placeholder="Enter sub header"
                  />
                </mat-form-field>

                <button
                  mat-icon-button
                  color="primary"
                  (click)="addNewSubHeaderSectionInInfoScreen()"
                  type="button"
                  class="add-sub-header-button"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="warn"
                  *ngIf="getInfoScreenArray().length > 1"
                  (click)="removeSubHeaderSection(sectionIndex)"
                  type="button"
                  class="remove-sub-header-button"
                >
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </div>

              <div
                formArrayName="points"
                class="d-flex flex-column gap-3 mt-3 ms-2"
              >
                <div
                  *ngFor="
                    let point of getPointsArray(sectionIndex).controls;
                    let pointIndex = index
                  "
                  class="d-flex align-items-center gap-2"
                >
                  <mat-form-field class="w-100">
                    <mat-label>Point {{ pointIndex + 1 }}</mat-label>
                    <textarea
                      type="text"
                      matInput
                      trimValueAccessor
                      [formControlName]="pointIndex"
                    ></textarea>
                  </mat-form-field>

                  <button
                    mat-icon-button
                    color="primary"
                    (click)="
                      addNewPointInSubHeaderSectionInInfoScreen(sectionIndex)
                    "
                    type="button"
                    class="add-point-button"
                  >
                    <mat-icon>add_circle</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    *ngIf="getPointsArray(sectionIndex).length > 1"
                    (click)="removePoint(sectionIndex, pointIndex)"
                    type="button"
                    class="remove-point-button"
                  >
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="screenTypeForm.value.screenType?.id === 9">
          <ng-container formGroupName="body">
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Devices</mat-label>
                <mat-select multiple formControlName="devices">
                  <mat-option
                    *ngFor="
                      let device of devices
                        | deviceType : screenTypeForm.value.deviceType?.key
                    "
                    [value]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Select Parameters</mat-label>
                <mat-select multiple formControlName="parameter">
                  <mat-option
                    *ngFor="
                      let parameter of parameters
                        | gasLevel
                          : selectedDeviceTypeFields
                          : selectedDeviceTypeLimits
                    "
                    [value]="parameter.name"
                  >
                    {{ parameter.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-100">
                <mat-label>Average Time</mat-label>
                <mat-select formControlName="avg_time">
                  <mat-option
                    *ngFor="let averageTimeOption of averageTimeOptions"
                    [value]="averageTimeOption.value"
                  >
                    {{ averageTimeOption.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
      </form>
    </mat-step>
  </mat-stepper>
</app-form-container>
