<app-form-container
  [tableTitle]="title"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [isValid]="false"
>
  <ng-container *ngFor="let notification of notificationList; let id = index">
    <mat-accordion
      class="flex row center oz-report-accordion"
      *ngIf="hasAlertsForNotification(notification)"
    >
      <mat-expansion-panel
        [expanded]="hasAlertsForNotification(notification)"
        class="oz-report-expansion-panel"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ notification.title }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let info of alertList; let i = index">
          <div class="container" *ngIf="info.type === notification.reportType">
            <div class="row border-top">
              <div
                [ngClass]="{
                  'col-12': true,
                  newAlert: info.epochTime > notificationLastVisited
                }"
              >
                <span class="notification">
                  <a (click)="navigateToDashBoard(info?.deviceId)">
                    <span class="label-color fs-14 fw-bold clickable">{{
                      info.label
                    }}</span>
                  </a>
                  <span
                    [ngClass]="{
                      'fs-14': true,
                      'fw-bold': info.epochTime > notificationLastVisited
                    }"
                  >
                    {{ info.keyLabel }} {{ info.message }} {{ info.value }}
                    {{ info.unit }}
                  </span>
                </span>
                <br />
                <small
                  [ngClass]="{
                    'fw-bold': info.epochTime > notificationLastVisited
                  }"
                  >{{ info.timestamp }}</small
                >
              </div>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</app-form-container>
