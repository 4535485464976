import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserComponent } from './user.component';
import { SubUsersComponent } from './pages/sub-users/sub-users.component';
import { IndexManagementComponent } from './pages/index-management/index-management.component';
import { UnitsManagementComponent } from './pages/units-management/units-management.component';
import { ModulesComponent } from './pages/modules/modules.component';
import { ProfileComponent } from './pages/profile/profile.component';

const routes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
      },
      {
        path: 'sub-users',
        component: SubUsersComponent,
      },
      {
        path: 'aqi',
        component: IndexManagementComponent,
      },
      {
        path: 'units',
        component: UnitsManagementComponent,
      },
      {
        path: 'modules',
        component: ModulesComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
