<app-form-container
  [tableTitle]="formTitle"
  [submitBtn]="buttonValue"
  [isValid]="downloadForm.valid"
  (submit)="submitForm()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [cancelBtn]="'Cancel'"
>
  <form class="row gy-4" [formGroup]="downloadForm">
    <!--options-->
    <div class="col-12">
      <mat-radio-group
        aria-label="Select an option"
        class="d-flex justify-content-around"
        formControlName="selectedType"
      >
        <mat-radio-button color="primary" value="csv"
          >Download CSV</mat-radio-button
        >
        <mat-radio-button color="primary" value="report"
          >Download Report</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <!--select devices-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Device Name</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          placeholder="Device Name"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : downloadForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>

        <button
          type="button"
          *ngIf="downloadForm.get('deviceSearch')?.value"
          mat-icon-button
          matSuffix
          aria-label="clear"
          style="margin-right: -18px; z-index: 1"
          (click)="clearDeviceName(); $event.stopPropagation()"
          matTooltip="Close"
        >
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
        <button
          type="button"
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
          class=""
          style="z-index: 0"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!--select parameters-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Select Parameter</mat-label>
        <mat-select formControlName="parameters" multiple>
          <ng-container
            *ngIf="
              keysOfSelectedDevice ?? {}
                | gasLevel : fieldsOfCurrentDeviceType : limitsOfFields;
              let keysOfSelectedDeviceTransformed
            "
          >
            <app-multiple-select-dropdown
              [options]="keysOfSelectedDeviceTransformed"
              [control]="paramsControl"
            ></app-multiple-select-dropdown>
            <ng-container *ngIf="areKeysAvailable">
              <mat-option
                *ngFor="
                  let keyOfSelectedDeviceT of keysOfSelectedDeviceTransformed
                "
                [value]="keyOfSelectedDeviceT"
              >
                {{ keyOfSelectedDeviceT?.label }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <!--select average-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Select Average</mat-label>
        <mat-select formControlName="average">
          <mat-option
            *ngFor="let allAverage of allAverages"
            [value]="allAverage"
            >{{ allAverage.label }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <!--Date-->
    <div class="col-12" *ngIf="isCSV">
      <mat-form-field class="w-100">
        <mat-label>Select Dates</mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
          <input
            matStartDate
            formControlName="startDate"
            [placeholder]="'Start Date'"
          />
          <input
            matEndDate
            formControlName="endDate"
            [placeholder]="'End Date'"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error
          *ngIf="
            downloadForm?.controls?.startDate?.hasError('matStartDateInvalid')
          "
        >
          Invalid start date
        </mat-error>
        <mat-error
          *ngIf="downloadForm?.controls?.endDate?.hasError('matEndDateInvalid')"
        >
          Invalid end date
        </mat-error>
      </mat-form-field>
    </div>

    <!--select multiple devices-->
    <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Device ({{selectedDevices?.length }} / {{maxDevices}})</mat-label>
        <mat-select formControlName="deviceSearch" multiple>
          <!-- <app-multiple-select-dropdown
            [options]="devices"
            [control]="itemsControl"
          ></app-multiple-select-dropdown> -->
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="let device of devices | deviceType : deviceType.key"
              [value]="device"
              [disabled]="isDeviceSelected(device)"
              >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <small class="grey"><mat-hint>You can select maximum 10 devices.</mat-hint></small>
    </div>

    <!--select report type-->
    <!-- <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Report Type</mat-label>
        <mat-select formControlName="reportType">
          <mat-option
            *ngFor="let reportType of reportTypes"
            [value]="reportType"
            >{{ reportType.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div> -->

    <!--select report frequency-->
    <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Type</mat-label>
        <mat-select formControlName="reportFrequency">
          <mat-option
            *ngFor="let reportFrequency of reportFrequencies"
            [value]="reportFrequency"
            >{{ reportFrequency.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12" *ngIf="isReport">
      <mat-form-field class="w-100">
        <mat-label>Select Start Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="startDate"
          [max]="maxDate"
          [min]="minDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</app-form-container>
