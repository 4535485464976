<app-form-container
  tableTitle="Log"
  (cancelled)="closeForm()"
  submitBtn="Add"
  [isValid]="logBookForm.valid"
  (close)="closeForm()"
  cancelBtn="Cancel"
  (submit)="submit()"
>
  <ng-template matStepperIcon="edit">
    <mat-icon>check</mat-icon>
  </ng-template>
  <div class="row py-2 gy-4" [formGroup]="logBookForm">
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Select Device</mat-label>
        <mat-select formControlName="deviceIds" multiple>
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="let device of devices ?? [] | deviceType : deviceType.key"
              [value]="device.deviceId"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Select Activity</mat-label>
        <mat-select formControlName="topics">
          <mat-option
            *ngFor="let topic of availableTopics"
            [value]="topic.value"
          >
            {{ topic.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [min]="minDate"
          formControlName="selectedDate"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Note</mat-label>
        <input matInput formControlName="note" />
      </mat-form-field>
    </div>
  </div>
</app-form-container>
