import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { NbThemeModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ToastrModule } from 'ngx-toastr';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { MATERIAL_DARK_THEME } from 'src/styles/material/theme.material-dark';
import { MATERIAL_LIGHT_THEME } from 'src/styles/material/theme.material-light';
import { MATERIAL_LIGHT_TROJENA } from 'src/styles/material/theme.material-local-deploy';
import { DARK_THEME } from 'src/styles/theme.dark';
import { DEFAULT_THEME } from 'src/styles/theme.default';
import { AqiGuageComponent } from './aqi-guage/aqi-guage.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { AqiColorLegendComponent } from './components/aqi-color-legend/aqi-color-legend.component';
import { AqiTableComponent } from './components/aqi-table/aqi-table.component';
import { CommonMapControlsComponent } from './components/common-map-controls/common-map-controls.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { ContentUnavailableComponent } from './components/content-unavailable/content-unavailable.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DeviceInfoWindowComponent } from './components/device-info-window/device-info-window.component';
import { DownloadCsvReportFormComponent } from './components/download-csv-report-form/download-csv-report-form.component';
import { FieldProgressBarComponent } from './components/field-progress-bar/field-progress-bar.component';
import { FilterOptionsComponent } from './components/filter-options/filter-options.component';
import { GraphAccordionComponent } from './components/graph-accordion/graph-accordion.component';
import { ImageViewPopupComponent } from './components/image-view-popup/image-view-popup.component';
import { MobileNumberInputComponent } from './components/mobile-number-input/mobile-number-input.component';
import ModuleListAdminComponent from './components/module-list-admin/module-list-admin.component';
import { MultipleSelectDropdownComponent } from './components/multiple-select-dropdown/multiple-select-dropdown.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ParameterDetailsComponent } from './components/parameter-details/parameter-details.component';
import { PopupTableComponent } from './components/popup-table/popup-table/popup-table.component';
import { SelectionTileComponent } from './components/selection-tile/selection-tile.component';
import { TagAndDevicetypeDropwdownComponent } from './components/tag-and-devicetype-dropwdown/tag-and-devicetype-dropwdown.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TimeZoneInputComponent } from './components/time-zone-input/time-zone-input.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { MaterialModule } from './config/material.module';
import { NebularModule } from './config/nebular.module';
import { NgMateroModule } from './config/ng-matero.module';
import { AppConstants } from './constants/app-constants';
import { CoreModule } from './core/core.module';
import { TrimValueAccessorDirective } from './directives/trim-value-accessor.directive';
import { HeaderComponent } from './header/header.component';
import { FormContainerComponent } from './layouts/form-container/form-container.component';
import { OneColumnComponent } from './layouts/one-column/one-column.component';
import { TerminalPageBodyComponent } from './layouts/terminal-page-body/terminal-page-body.component';
import { TerminalPageHeaderComponent } from './layouts/terminal-page-header/terminal-page-header.component';
import { TerminalPageLayoutComponent } from './layouts/terminal-page-layout/terminal-page-layout.component';
import { ClusterColorPipe } from './pipes/cluster-color.pipe';
import { ClusterDevicesPipe } from './pipes/cluster-devices.pipe';
import { DeviceTypeWiseAnalyticPipe } from './pipes/device-type-wise-analytic.pipe';
import { DeviceTypeWiseReportPipe } from './pipes/device-type-wise-report.pipe';
import { DeviceTypePipe } from './pipes/device-type.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { GasLevelPipe } from './pipes/gas-level.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { CookieService } from './services/cookie.service';
import { ErrorService } from './services/error.service';
import { LayoutService } from './services/layout.service';
import { LocalStorageService } from './services/local-storage.service';
import { LoginService } from './services/login.service';
import { NotificationService } from './services/notification.service';
import { RippleService } from './services/ripple.service';
import { WindLayerComponent } from './components/map-components/wind-layer/wind-layer.component';

@NgModule({
  declarations: [
    HeaderComponent,
    OneColumnComponent,
    TerminalPageLayoutComponent,
    TerminalPageHeaderComponent,
    TerminalPageBodyComponent,
    ConfirmDialogComponent,
    AnnouncementComponent,
    ParameterDetailsComponent,
    FilterOptionsComponent,
    DeviceTypePipe,
    DataTableComponent,
    SearchPipe,
    GasLevelPipe,
    FieldProgressBarComponent,
    DeviceInfoWindowComponent,
    FormContainerComponent,
    AqiTableComponent,
    FormatDatePipe,
    PopupTableComponent,
    ConfirmationPopupComponent,
    ClusterDevicesPipe,
    ClusterColorPipe,
    AqiGuageComponent,
    PopupTableComponent,
    NotificationComponent,
    UploadImageComponent,
    DeviceTypeWiseReportPipe,
    DeviceTypeWiseAnalyticPipe,
    ContentUnavailableComponent,
    ImageViewPopupComponent,
    AqiColorLegendComponent,
    GraphAccordionComponent,
    DownloadCsvReportFormComponent,
    MultipleSelectDropdownComponent,
    TrimValueAccessorDirective,
    TimeZoneInputComponent,
    SelectionTileComponent,
    MobileNumberInputComponent,
    TagInputComponent,
    TagAndDevicetypeDropwdownComponent,
    // WindLayerComponent,
    ModuleListAdminComponent,
  ],
  imports: [
    NgxGaugeModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NebularModule,
    MaterialModule,
    MatDividerModule,
    TranslateModule.forChild({}),
    HighchartsChartModule,
    NgxMaterialTimepickerModule,
    MatBadgeModule,
    TourMatMenuModule,
    ToastrModule,
    CommonMapControlsComponent,
    MatSliderModule,
  ],
  exports: [
    HeaderComponent,
    OneColumnComponent,
    TerminalPageLayoutComponent,
    TerminalPageHeaderComponent,
    TerminalPageBodyComponent,
    ParameterDetailsComponent,
    CommonModule,
    NebularModule,
    MaterialModule,
    MatDividerModule,
    NgMateroModule,
    DeviceTypePipe,
    DataTableComponent,
    SearchPipe,
    GasLevelPipe,
    FieldProgressBarComponent,
    DeviceInfoWindowComponent,
    FormContainerComponent,
    ConfirmDialogComponent,
    FormatDatePipe,
    ClusterDevicesPipe,
    ClusterColorPipe,
    AqiTableComponent,
    UploadImageComponent,
    AqiGuageComponent,
    DeviceTypeWiseReportPipe,
    DeviceTypeWiseAnalyticPipe,
    ContentUnavailableComponent,
    AqiColorLegendComponent,
    HighchartsChartModule,
    TrimValueAccessorDirective,
    NgxMaterialTimepickerModule,
    SelectionTileComponent,
    MobileNumberInputComponent,
    MatBadgeModule,
    TourMatMenuModule,
    MultipleSelectDropdownComponent,
    ToastrModule,
    TagInputComponent,
    TagAndDevicetypeDropwdownComponent,
    CommonMapControlsComponent,
    // WindLayerComponent,
    MatSliderModule,
    ModuleListAdminComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    AnnouncementComponent,
    FilterOptionsComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...(NbThemeModule.forRoot(
          {
            // name: 'material-light',
            name:
              localStorage.getItem('current-theme') ||
              AppConstants.DEFAULT_THEME,
          },
          [DEFAULT_THEME, DARK_THEME, MATERIAL_LIGHT_THEME, MATERIAL_DARK_THEME,MATERIAL_LIGHT_TROJENA]
        ).providers || []),
        LayoutService,
        RippleService,
        ErrorService,
        LoginService,
        CookieService,
        LocalStorageService,
        NotificationService,
        DeviceTypePipe,
        SearchPipe,
        GasLevelPipe,
        DeviceTypeWiseReportPipe,
        DeviceTypeWiseAnalyticPipe,
      ],
    };
  }
}
