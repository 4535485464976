import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-unavailable',
  templateUrl: './content-unavailable.component.html',
  styleUrls: ['./content-unavailable.component.scss'],
})
export class ContentUnavailableComponent {
  @Input() isConfiguration: boolean = false;
  @Input() svgImage!: string;
  @Input() majorText!: string | undefined;
  @Input() minorText!: string | undefined;
  @Input() linkText!: string;
  @Input() createFunc!: () => void;
}
