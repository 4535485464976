<mat-sidenav-container class="h-100" autosize>
  <mat-sidenav
    [position]="'end'"
    [mode]="width > 991 ? 'side' : 'over'"
    [opened]="openPanel ? true : false"
    *ngIf="newComplain"
  >
    <div class="complain p-3">
      <div class="row">
        <div class="col">Complain Information</div>
        <div class="col-auto">
          <mat-icon
            class="material-symbols-outlined cursor-pointer"
            (click)="closeInfoWindows()"
            >close</mat-icon
          >
        </div>
      </div>
      <mat-card class="mt-2 mb-4 p-3 pb-0 card-info">
        <div *ngIf="newComplain.image_url" class="complain__actual-image">
          <img height="131" [src]="newComplain.image_url" />
        </div>
        <div class="complain__details mt-3">
          <div class="d-flex justify-content-between py-1">
            <span>Complain Title</span>
            <span class="text-end">{{ newComplain.title }}</span>
          </div>
          <hr class="complain__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Raised By</span>
            <span class="text-end ps-2 ps-lg-3">{{ newComplain.name }}</span>
          </div>
          <hr class="complain__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Raised On</span>
            <span class="text-end ps-2 ps-lg-3">
              {{ formatDate(newComplain.created_on) }}
            </span>
          </div>
          <hr class="complain__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Due Date</span>
            <span class="text-end ps-2 ps-lg-3">
              {{ formatDate(newComplain.end_date) }}
            </span>
          </div>
          <hr class="complain__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Priority</span>
            <span
              class="text-end ps-2 ps-lg-3"
              *ngIf="newComplain.priority == 'LOW'"
              [style.color]="'#0076CE'"
              >Low</span
            >
            <span
              class="text-end ps-2 ps-lg-3"
              *ngIf="newComplain.priority == 'MEDIUM'"
              [style.color]="'#ff6347'"
              >Medium</span
            >
            <span
              class="text-end ps-2 ps-lg-3"
              *ngIf="newComplain.priority == 'HIGH'"
              [style.color]="'#FF0000'"
              >High</span
            >
          </div>
          <hr class="complain__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Category</span>
            <span class="text-end ps-2 ps-lg-3">
              {{ newComplain.categoryId }}
            </span>
          </div>
          <hr class="complain__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Status</span>
            <span
              class="text-center"
              *ngIf="newComplain.status == '0'"
              [ngStyle]="{
                color: '#FFFFFF',
                background: '#008000',
                'border-radius': '12px',
                'padding-top': '3px',
                'padding-left': '5px',
                'padding-bottom': '3px',
                'padding-right': '5px'
              }"
              >Resolved</span
            >
            <span
              class="text-center"
              *ngIf="newComplain.status == '1'"
              [ngStyle]="{
                color: '#FFFFFF',
                background: '#daa520',
                'border-radius': '12px',
                'padding-top': '3px',
                'padding-left': '5px',
                'padding-bottom': '3px',
                'padding-right': '5px'
              }"
              >In progress</span
            >
            <span
              class="text-center"
              *ngIf="newComplain.status == '2'"
              [ngStyle]="{
                color: '#FFFFFF',
                background: '#666a6d',
                'border-radius': '12px',
                'padding-top': '3px',
                'padding-left': '5px',
                'padding-bottom': '3px',
                'padding-right': '5px'
              }"
              >Pending</span
            >
          </div>
        </div>
      </mat-card>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="row mx-0 h-100">
      <ng-container *ngIf="googleMapsService.isApiLoaded | async">
        <google-map
          class="col-12 px-0"
          width="100%"
          height="100%"
          [options]="options"
        >
          <map-marker
            *ngFor="let complain of complains; index as i"
            #marker="mapMarker"
            [options]="{
              icon: complain?.iconUrl,
              zIndex: complain?.complainId == newComplain?.complainId ? 0 : 1
            }"
            [position]="{ lat: complain.latitude, lng: complain.longitude }"
            (mapClick)="openInfoWindow(marker, i, complain)"
          >
          </map-marker>
        </google-map>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div id="fullscreen-control" (click)="switchToFullScreen()">
  <mat-icon class="material-symbols-outlined">{{ fullScreenMode }}</mat-icon>
</div>

<div id="zoom-control">
  <div class="d-flex flex-column">
    <mat-icon
      class="material-symbols-outlined text-basic-color"
      (click)="zoomIn()"
      >zoom_in</mat-icon
    >
    <hr class="control-divider" />
    <mat-icon
      class="material-symbols-outlined text-basic-color"
      (click)="zoomOut()"
      >zoom_out</mat-icon
    >
  </div>
</div>
<div id="map-types-control">
  <div class="map-types">
    <div class="map-type" (click)="changeMapType()">
      <img
        *ngIf="currentMapType === 'roadmap'"
        src="./../../../../../../../assets/images/map/default-map-type.png"
      />
      <img
        *ngIf="currentMapType === 'hybrid'"
        src="./../../../../../../../assets/images/map/satellite-map-type.png"
      />
    </div>
    <div class="text-center">
      {{ currentMapType === "roadmap" ? "Default" : "Satellite" }}
    </div>
  </div>
  <div class="map-layers">
    <div id="industry-layer" *ngIf="hasLayer5000">
      <div
        [ngClass]="
          industryLayerEnabled ? 'map-layer layer-selected' : 'map-layer'
        "
        (click)="toggleIndustryLayer()"
      >
        <img src="./../../../../../../../assets/images/map/traffic-layer.png" />
      </div>
      <div class="text-center">Industry</div>
    </div>

    <div id="traffic-layer" *ngIf="hasLayer5001">
      <div
        [ngClass]="
          trafficLayerEnabled ? 'map-layer layer-selected' : 'map-layer'
        "
        (click)="toggleTrafficLayer()"
      >
        <img src="./../../../../../../../assets/images/map/traffic-layer.png" />
      </div>
      <div class="text-center">Traffic</div>
    </div>

    <div id="wind-layer" class="justify-content-center" *ngIf="hasLayer5002">
      <div
        [ngClass]="windLayerEnabled ? 'map-layer layer-selected' : 'map-layer'"
        (click)="toggleWindLayer()"
      >
        <img
          style="max-width: 48px; max-height: 48px"
          src="./../../../../../../../assets/images/map/wind-layer.png"
        />
      </div>
      <div class="text-center">Wind</div>
    </div>
  </div>
</div>
