import { Inject, Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { CustomMomentService } from '../services/custom-moment.service';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  constructor(
    @Inject(CustomMomentService)
    private customMomentService: CustomMomentService
  ) {
    super('en-US'); // Use your desired locale here
  }

  override format(date: moment.Moment, displayFormat: string): string {
    return this.customMomentService.formatDate({ date });
  }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
