<app-form-container
  [submitBtn]="submitBtn"
  [tableTitle]="'Edit Device Details'"
  [isValid]="isNextStep"
  (submit)="submitAndNext()"
  (cancelled)="previous()"
  (close)="closeForm()"
  [cancelBtn]="backBtn"
>
  <mat-stepper
    [linear]="true"
    #stepper
    class="h-100 custom-stepper"
    (selectionChange)="onStepChange($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [stepControl]="deviceDetailform" label="Device Details">
      <form class="row gy-3" [formGroup]="deviceDetailform">
        <div style="height: 263px !important">
          <ng-container *ngIf="googleMapsService.isApiLoaded | async">
            <google-map
              class="col-12 px-0"
              width="100%"
              height="100%"
              [options]="options"
            >
              <map-marker
                #marker="mapMarker"
                [position]="{
                  lat: currentLatitude,
                  lng: currentLongitude
                }"
                [options]="{
                  icon: 'assets/images/pins/complain-pin-neutral.png'
                }"
              >
              </map-marker>
            </google-map>
          </ng-container>
        </div>
        <div id="zoom-control">
          <div class="d-flex flex-column">
            <mat-icon
              class="material-symbols-outlined text-basic-color"
              (click)="zoomIn()"
            >
              zoom_in
            </mat-icon>
            <hr class="control-divider" />
            <mat-icon
              class="material-symbols-outlined text-basic-color"
              (click)="zoomOut()"
            >
              zoom_out
            </mat-icon>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Device ID</mat-label>
            <input
              type="text"
              [placeholder]="'Device ID'"
              matInput
              formControlName="deviceId"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Device Name</mat-label>
            <input
              type="text"
              [placeholder]="'Label'"
              matInput
              formControlName="label"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Device Location</mat-label>
            <input
              type="text"
              [placeholder]="'Location'"
              matInput
              formControlName="loc"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Latitude</mat-label>
            <input
              type="text"
              [placeholder]="'Latitude'"
              matInput
              formControlName="latitude"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Longitude</mat-label>
            <input
              type="text"
              [placeholder]="'Longitude'"
              matInput
              formControlName="longitude"
            />
          </mat-form-field>
        </div>
        <ng-container *ngIf="isAdmin">
          <div class="col-12 col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let status of deviceStatus"
                  [value]="status.value"
                >
                  {{ status.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </form>
    </mat-step>
    <mat-step [stepControl]="windAndIntervalform" label="Device Settings">
      <div class="col-12">
        <app-upload-image
          [isDisabled]="false"
          [isEditMode]="false"
          [imageUrl]="img_url"
          [form]="windAndIntervalform"
          (imageSelected)="handleImageSelected($event)"
          (imageRemoved)="removeFile()"
          (imageUrlUpdated)="img_url = $event"
        ></app-upload-image>
      </div>

      <ng-container >
        <form class="row gy-3 p-1 pt-3" [formGroup]="windAndIntervalform">
          <div class="col-12" *ngIf="isMasterOrgUser">
            <mat-form-field class="w-100">
              <mat-label>Select Data Interval</mat-label>
              <mat-select formControlName="interval">
                <mat-option
                  *ngFor="let interval of timeIntervals"
                  [value]="interval.value"
                >
                  {{ interval.lab }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="isMasterOrgUser">
            <section>
              <p>
                <mat-checkbox
                  [color]="'primary'"
                  formControlName="windDataOutside"
                  [checked]="windAndIntervalform.value.windDataOutside"
                >
                  Fetch wind data from online source
                </mat-checkbox>
              </p>
            </section>
          </div>
          <div class="col-12 m-0" *ngIf="isAdmin">
            <!-- <section class="d-flex justify-content-center"> -->
            <!-- <p class="position-sticky"> -->
            <section>
              <p>
                <mat-checkbox
                  [color]="'primary'"
                  formControlName="updateForSubUser"
                >
                  Update for all Sub Users
                </mat-checkbox>
              </p>
            </section>
          </div>
        </form>
      </ng-container>
    </mat-step>

    <div style="height: 395px !important">
      <ng-container *ngIf="googleMapsService.isApiLoaded | async">
        <google-map
          class="col-12 px-0"
          width="100%"
          height="100%"
          [options]="options"
        >
          <map-marker
            #marker="mapMarker"
            [position]="{
              lat: currentLatitude,
              lng: currentLongitude
            }"
            [options]="{ icon: 'assets/images/pins/complain-pin-neutral.png' }"
          >
          </map-marker>
        </google-map>
      </ng-container>
    </div>
    <div id="zoom-control">
      <div class="d-flex flex-column">
        <mat-icon
          class="material-symbols-outlined text-basic-color"
          (click)="zoomIn()"
        >
          zoom_in
        </mat-icon>
        <hr class="control-divider" />
        <mat-icon
          class="material-symbols-outlined text-basic-color"
          (click)="zoomOut()"
        >
          zoom_out
        </mat-icon>
      </div>
    </div>
  </mat-stepper>
</app-form-container>
