import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DataFlaggingService } from 'src/app/shared/services/data-flagging.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-data-flagging-list',
  templateUrl: './data-flagging-list.component.html',
  styleUrls: ['./data-flagging-list.component.scss'],
})
export class DataFlaggingListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() rowSelected = new EventEmitter<any>();
  @Output() columnSelected = new EventEmitter<any>();

  subscriptions: Subscription[] = [];
  device?: DeviceDetails;
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  aqiIndexColor!: AqiIndexColorArray;

  average!: AverageHour | null;
  startDate!: number;
  endDate!: number;

  loadTable: Subject<boolean> = new BehaviorSubject(false);
  deviceData: DeviceDetails[] = [];

  columns: any[] = [];
  displayedColumns: string[] = [];
  defaultColumns: any[];
  selectedRow: any;
  selectedRows: any[] = [];
  selectedCells: any[] = [];
  selectedColumns: any[] = [];
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };

  private userTimeFormat: number;
  moduleAccess: any;

  constructor(
    private deviceService: DeviceService,
    public commonService: CommonService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private activatedRoute: ActivatedRoute,
    private dataFlaggingService: DataFlaggingService
  ) {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.defaultColumns = this.generateDefaultColumnsConfig();
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.moduleAccess = this.commonService.moduleAccessibility(1020);
    this.defaultColumns = this.generateDefaultColumnsConfig();
    this.device = this.deviceService.currentDevice;
    const dataFlagForm: Subscription =
      this.dataFlaggingService.dataFlagForm$.subscribe((res) => {
        if (res) {
          this.device = res.device || null;
          this.startDate = res.startDate || null;
          this.endDate = res.endDate || null;
        }
        if (!this.device) {
          this.setCurrentDevice();
        }
        this.setupData();
      });
    this.subscriptions.push(dataFlagForm);
  }

  ngAfterViewInit(): void {}

  private generateDefaultColumnsConfig() {
    return [
      {
        columnDef: 'time',
        header: 'Time',
        color: (element: DeviceDetails) => `${'inherit'}`,
        cell: (element: DeviceDetails) =>
          `${
            element?.payload?.d?.t
              ? this.customMomentService.formatDatetime({
                  epoch: element?.payload?.d?.t,
                  format:
                    this.userTimeFormat === 12
                      ? 'hh:mm A, DD/MM/YY'
                      : 'HH:mm, DD/MM/YY',
                })
              : '-'
          }`,
        parameter: false,
        selected: false,
        filter: false,
      },
    ];
  }

  setCurrentDevice() {
    if (this.deviceService.currentDevice) {
      this.device = this.deviceService.currentDevice;
    }
  }

  setupData() {
    if (this.device) {
      this.fields = this.deviceService.fields;
      this.limits = this.deviceService.limits;
      const deviceTypes: DeviceType[] = this.commonService.getAllDeviceTypes();
      const allAqi: any = this.commonService.getAllAQI();
      const allAqis: any = this.commonService.getAllAQIs();
      this.loadTable.next(false);
      this.dataFlaggingService
        .fetchDataFlaggingData(
          this.device.deviceId,
          this.startDate,
          this.endDate
        )
        .subscribe({
          next: (deviceDetails: DeviceDetails[]) => {
            this.deviceData = deviceDetails || [];
            if (this.deviceData.length) {
              let allKeys = [];
              let deviceFields: any[] = [];
              this.deviceData.forEach((device) => {
                deviceFields.push(...Object.keys(device?.payload?.d));
              });
              let deviceFieldsArray = [...new Set(deviceFields)];
              for (let df of deviceFieldsArray) {
                let field: DeviceField = this.fields.find(
                  (f) => f?.fkey == df
                )!;
                if (field && field.fkey !== 't') {
                  allKeys.push(field!);
                }
              }
              allKeys = CommonUtil.setParameterSequence(allKeys);
              this.columns = [];
              this.displayedColumns = [];
              this.columns = [...this.defaultColumns];
              this.displayedColumns = [
                'arrow',
                ...this.columns.map((c) => c.columnDef),
              ];

              for (let field of allKeys) {
                if (!field.isVisible) continue;
                this.columns.push({
                  columnDef: field.fkey,
                  header: Boolean(field?.unit?.replaceAll(' ', ''))
                    ? `${field?.label} <br><small>${field?.unit}</small>`
                    : `${field?.label}`,
                  cell: (element: DeviceDetails) =>
                    `${element.payload.d[field.fkey]}`,
                  backgroundColor: function (
                    element: DeviceDetails,
                    isColumnSelected: boolean
                  ) {
                    if (element.payload.d[`${field.fkey}_cf`]) {
                      return DeviceUtil.flaggedColumnColor(field.fkey, element);
                    } else if (
                      element.selectedCells?.includes(field.fkey) ||
                      element.isRowSelected ||
                      isColumnSelected
                    ) {
                      return '#cfcfcf';
                    }
                    return '';
                  },
                  parameter: true,
                  selected: false,
                  filter: true,
                  enableSelect: true,
                });
                this.displayedColumns.push(field.fkey);
              }
            }
            this.loadTable.next(true);
          },
          error: (err) => {
            this.loadTable.next(true);
          },
        });
    } else {
      this.loadTable.next(true);
    }
  }

  downloadReport(event: any) {}

  exportTableDataToCSV(): void {
    if (!this.deviceData || !this.deviceData.length) {
      console.info('No data available to export');
      return;
    }
    const csvRows = [];
    const headers = this.columns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    ); // Remove HTML tags from headers
    csvRows.push(headers.join(','));

    this.deviceData.forEach((device) => {
      const values = this.columns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;
        if (columnKey === 'time') {
          cellValue = device?.payload?.d?.t
            ? this.customMomentService.formatDatetime({
                epoch: device.payload.d.t,
                format:
                  this.userTimeFormat === 12
                    ? 'hh:mm A, DD/MM/YY'
                    : 'HH:mm, DD/MM/YY',
              })
            : '-';
        } else {
          cellValue = device.payload.d[columnKey];
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });
      csvRows.push(values.join(','));
    });

    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}.csv`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  onRowSelect(row: any) {
    const index = this.selectedRows.findIndex(
      (r) => r.payload.d.t === row.payload.d.t
    );
    const updatedSelectedCells = this.selectedCells.filter(
      (cellRow) => cellRow.payload.d.t !== row.payload.d.t
    );
    this.selectedCells = updatedSelectedCells;
    if (index < 0) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(index, 1);
    }
    this.updatePayload();
  }

  onCellSelect(cellData: { row: any; columnKey: any }) {
    const { row, columnKey } = cellData;
    const rowIndex = this.selectedCells.findIndex(
      (r) => r.payload.d.t === row.payload.d.t
    );
    if (rowIndex < 0) {
      const cellPayload = {
        ...row,
        payload: {
          d: {
            t: row.payload.d.t,
            [columnKey.columnDef]: row.payload.d[columnKey.columnDef],
          },
        },
      };
      this.selectedCells.push(cellPayload);
    } else if (
      columnKey.columnDef in this.selectedCells[rowIndex].payload.d &&
      Object.keys(this.selectedCells[rowIndex].payload.d).length === 2
    ) {
      this.selectedCells.splice(rowIndex, 1);
    } else {
      const updatedSelectedCells = [...this.selectedCells];
      if (columnKey.columnDef in updatedSelectedCells[rowIndex].payload.d) {
        delete updatedSelectedCells[rowIndex].payload.d[columnKey.columnDef];
      } else {
        updatedSelectedCells[rowIndex] = {
          ...updatedSelectedCells[rowIndex],
          payload: {
            d: {
              ...updatedSelectedCells[rowIndex].payload.d,
              [columnKey.columnDef]: row.payload.d[columnKey.columnDef],
            },
          },
        };
        this.selectedCells = updatedSelectedCells;
      }
    }
    this.updatePayload();
  }

  onColumnSelect(ColumnData: { row: any; columnKey: any }) {
    const { row, columnKey } = ColumnData;
    const rowIndex = this.selectedCells.findIndex(
      (r) =>
        r.payload.d.t === row.payload.d.t &&
        r.columnKey.columnDef === columnKey.columnDef
    );
    if (rowIndex < 0) {
      const cellPayload = {
        ...row,
        payload: {
          d: {
            t: row.payload.d.t,
            [columnKey.columnDef]: row.payload.d[columnKey.columnDef],
          },
        },
        columnKey,
      };
      this.selectedCells.push(cellPayload);
    } else {
      this.selectedCells.splice(rowIndex, 1);
    }
    this.updatePayload();
  }

  updatePayload() {
    let payload = [];
    payload = [...this.selectedRows, ...this.selectedCells];
    this.dataFlaggingService.rowSelected.emit(payload);
    this.dataFlaggingService.columnSelected.emit(payload);
  }
  clearSelection() {
    this.selectedRows = [];
    this.selectedCells = [];
    this.updatePayload();
  }

  getDataFlaggingModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  downloadFlagReportPDF() {
    let timeZone: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).settings?.timezone?.name;
    this.dataFlaggingService
      .downloadFlagReport(
        this.device?.deviceId,
        this.startDate,
        this.endDate,
        timeZone,
        false,
        false
      )
      .subscribe((res) => {
        saveAs(res, this.device?.deviceId + '_DF_report.pdf');
      });
  }
}
