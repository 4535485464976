import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisplayComponent } from './display.component';
import { DisplayListComponent } from './pages/display-list/display-list.component';

const routes: Routes = [
  {
    path: '',
    component: DisplayComponent,
    children: [
      {
        path: '',
        component: DisplayListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DisplayRoutingModule {}
