<div class="p-3 h-100 w-100 w-lg-75 w-xl-50">
  <div>
    <span class="profile-header">Profile Information</span>
  </div>

  <!--Logo Section-->
  <ng-container *ngIf="isLogoAvailable">
    <div class="mt-3 img-body col-12 d-flex flex-wrap gap-3 back-color">
      <ng-container *ngIf="defaultModeLogoUrl">
        <div
          class="p-3 d-flex flex-column align-content-center justify-content-center gap-2"
        >
          <img class="uploaded-image" [src]="defaultModeLogoUrl" id="myImage" />
          <span class="fw-bold font">Light Mode Logo</span>
        </div>
      </ng-container>
      <ng-container *ngIf="darkModeLogoUrl">
        <div
          class="p-3 d-flex flex-column align-content-center justify-content-center gap-2"
        >
          <img class="uploaded-image" [src]="darkModeLogoUrl" id="myImage" />
          <span class="fw-bold font">Dark Mode Logo</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!--Info Section -->
  <div class="mt-3 w-100 d-flex flex-wrap justify-content-between gap-2">
    <ng-container *ngFor="let data of userData">
      <div
        class="col-12 col-lg-5 readOnlyBox d-flex flex-row align-items-center"
      >
        <div class="ms-2">
          <span>{{ data.key }}</span> : <span>{{ data.value }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <!--password and edit buttons-->
  <div
    class="d-flex flex-wrap align-items-center justify-content-between w-100 mt-3"
  >
    <div class="col-12 col-md-4 col-lg-4 col-xl-3 d-flex align-items-end">
      <p (click)="changePassword()" class="mb-auto p-0 text-primary medium">
        <a class="cursor-pointer text-decoration-none">Change Password</a>
      </p>
    </div>
    <div class="col-12 col-md-5 col-lg-4 col-xl-3 d-flex justify-content-end">
      <button
        mat-raised-button
        color="primary"
        class="w-100 w-lg-auto"
        (click)="editUserInformation()"
        matTooltip="Edit User"
      >
        Edit
      </button>
    </div>
  </div>
</div>
