import { NgModule } from '@angular/core';
import { NbMenuModule } from '@nebular/theme';
import { SharedModule } from 'src/app/shared/shared.module';
import { OverviewModule } from './pages/overview/overview.module';
import { TerminalRoutingModule } from './terminal-routing.module';
import { TerminalComponent } from './terminal.component';
// import { MapComponent } from './pages/analytics/pages/graph-view/components/map/map.component';

@NgModule({
  // declarations: [TerminalComponent, MapComponent],
  declarations: [TerminalComponent],
  imports: [TerminalRoutingModule, SharedModule, NbMenuModule, OverviewModule],
})
export class TerminalModule {}
