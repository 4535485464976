import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { Complain } from '../models/complain';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';
import { CustomMomentService } from './custom-moment.service';
@Injectable({
  providedIn: 'root',
})
export class ComplainsService extends BaseAPIService<Complain.Get> {
  complains: any;
  categories: any = [];
  selectedOrg: any = [];

  private gte!: number;
  private lte!: number;

  setGte(gte: number) {
    this.gte = gte;
  }

  getGte(): number {
    return this.gte;
  }

  setLte(lte: number) {
    this.lte = lte;
  }

  getLte(): number {
    return this.lte;
  }
  private isGetData = new BehaviorSubject<boolean>(false);
  isGetData$ = this.isGetData.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
  }

  getCategories(): Observable<any> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );

    const headers: HttpHeaders = new HttpHeaders().append(
      AppConstants.AUTHORIZATION_HEADER,
      `${AppConstants.BEARER} ${token}`
    );
    return this.get<any>(APIConstants.getCategories(), headers).pipe(
      tap((data) => {
        this.categories = data;
      })
    );
  }

  getOrgById(orgId: any, gte: any, lte: any): Observable<any> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );

    const headers: HttpHeaders = new HttpHeaders().append(
      AppConstants.AUTHORIZATION_HEADER,
      `${AppConstants.BEARER} ${token}`
    );
    let params: HttpParams = new HttpParams();
    if (gte !== undefined) {
      params = params.append('gte', gte);
    } else {
      const defaultStartDate = this.customMomentService
        ?.moment()
        ?.subtract(1, 'years')
        ?.startOf('day')
        .unix();
      params = params.append('gte', defaultStartDate);
    }
    if (lte !== undefined) {
      params = params.append('lte', lte);
    } else {
      const defaultEndDate = this.customMomentService
        ?.moment()
        ?.endOf('day')
        .unix();
      params = params.append('lte', defaultEndDate);
    }
    return this.get<any>(
      APIConstants.GET_ORG_BY_ID(orgId),
      headers,
      params
    ).pipe(
      tap((data) => {
        this.selectedOrg = data;
      })
    );
  }

  getComplainsByOrganization(
    organizationId: string,
    gte: any,
    lte: any
  ): Observable<Array<Complain.Get>> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );

    const headers: HttpHeaders = new HttpHeaders().append(
      AppConstants.AUTHORIZATION_HEADER,
      `${AppConstants.BEARER} ${token}`
    );

    let params: HttpParams = new HttpParams();
    if (gte !== undefined) {
      params = params.append('gte', gte);
    } else {
      const defaultStartDate = this.customMomentService
        ?.moment()
        ?.subtract(1, 'years')
        ?.startOf('day')
        .unix();
      params = params.append('gte', defaultStartDate);
    }
    if (lte !== undefined) {
      params = params.append('lte', lte);
    } else {
      const defaultEndDate = this.customMomentService
        ?.moment()
        ?.endOf('day')
        .unix();
      params = params.append('lte', defaultEndDate);
    }
    return this.get<Array<Complain.Get>>(
      APIConstants.getComplainsByOrg(organizationId),
      headers,
      params
    ).pipe(
      tap((data) => {
        this.complains = data;
      })
    );
  }

  getComplains(): Observable<any> {
    const orgId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).org;
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    let params: HttpParams = new HttpParams();
    const defaultStartDate = this.customMomentService
      ?.moment()
      ?.subtract(1, 'years')
      ?.startOf('day')
      .unix();
    params = params.append('gte', defaultStartDate);
    const defaultEndDate = this.customMomentService
      ?.moment()
      ?.endOf('day')
      .unix();
    params = params.append('lte', defaultEndDate);
    return this.get<Array<any>>(
      APIConstants.GET_COMPLAINS().replace('{orgId}', orgId),
      headers,
      params
    ).pipe(
      tap((data) => {
        this.complains = data;
      })
    );
  }

  addComplain(OrgId: number, complainDetails: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<Array<Complain.Get>>(
      APIConstants.ADD_COMPLAIN(OrgId),
      complainDetails,
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateComplain(complainId?: any, complainDetails?: any) {
    const orgId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).org;
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<Array<any>>(
      APIConstants.UPDATE_COMPLAIN(complainId).replace('{orgId}', orgId),
      complainDetails,
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteComplain(complainId?: number) {
    const orgId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).org;
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.delete<Array<any>>(
      APIConstants.DELETE_COMPLAIN(complainId!).replace('{orgId}', orgId),
      headers
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  emitData() {
    this.isGetData.next(true);
  }
  downloadComplainReport(
    orgId: string,
    gte: number,
    lte: number,
    timezone: string,
    isTwelveHourFormat: boolean
  ): Observable<any> {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    let params: HttpParams = new HttpParams().appendAll({
      gte: gte,
      lte: lte,
      timezone: timezone,
      isTwelveHourFormat: isTwelveHourFormat,
      userId: userId,
    });
    return this.get<Array<any>>(
      APIConstants.DOWNLOAD_COMPLAIN().replace('{orgId}', orgId),
      headers,
      params,
      'blob'
    ).pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    );
  }
}
