<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      class="d-flex flex-wrap h-100 justify-content-between align-items-center col-12 gap-2 gap-lg-0"
    >
      <div class="row h-100 align-items-center col-12 col-lg-7 gx-3 gy-3 mx-0">
        <div
          [matTooltip]="readOnlyData.analyticName"
          class="readOnlyBox d-flex flex-column col-md-2 col-12 mx-md-1 py-1 gap-2"
        >
          <div class="readOnlyBoxText"><b>Label</b></div>
          <div class="readOnlyBoxText">
            {{ readOnlyData.analyticName }}
          </div>
        </div>
        <div
          [matTooltip]="
            analyticDetail.analyticType !== 2 ? readOnlyData.parameter : null
          "
          [matTooltipDisabled]="analyticDetail.analyticType === 2"
          class="readOnlyBox d-flex flex-column col-md-3 col-12 mx-md-1 py-1 gap-2"
        >
          <div class="readOnlyBoxText"><b>Parameter(s)</b></div>

          <div class="readOnlyBoxText">
            {{ readOnlyData.parameter }}
          </div>
        </div>
        <div
          [matTooltip]="
            analyticDetail.analyticType !== 1 ? readOnlyData.deviceId : null
          "
          [matTooltipDisabled]="
            analyticDetail.analyticType === 1 ||
            analyticDetail.analyticType === 4
          "
          class="readOnlyBox d-flex flex-column col-md-3 col-12 mx-md-1 py-1 gap-2"
        >
          <div class="readOnlyBoxText"><b>Device Name(s)</b></div>
          <div class="readOnlyBoxText">
            {{ readOnlyData.deviceId }}
          </div>
        </div>
        <div
          [matTooltip]="readOnlyData.date"
          class="readOnlyBox d-flex flex-column col-md-3 col-12 mx-md-1 py-1 gap-2"
        >
          <div class="readOnlyBoxText"><b>Date Range</b></div>
          <div class="readOnlyBoxText">
            {{ readOnlyData.date }}
          </div>
        </div>
      </div>

      <div
        class="row h-100 align-items-center justify-content-end gx-3 gy-3 gy-lg-0 mx-0 col-12 col-lg-4"
      >
        <div class="col-12 col-lg-auto">
          <button
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
            matTooltip="Edit"
            (click)="editAnalyticForm()"
          >
            Edit
            <mat-icon matSuffix class="material-symbols-outlined"
              >edit</mat-icon
            >
          </button>
        </div>
        <div class="col-12 col-lg-auto">
          <button
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
            matTooltip="Save"
            (click)="saveAnalyticsDetail()"
          >
            Save
            <mat-icon matSuffix class="material-symbols-outlined"
              >save</mat-icon
            >
          </button>
        </div>
        <div class="col-12 col-lg-auto d-flex justify-content-center">
          <button
            mat-mini-fab
            color="primary"
            matTooltip="Cancel"
            class="cancel"
            (click)="cancelGraphView()"
          >
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="d-flex flex-column h-full">
      <div
        class="h-100 d-flex flex-column overflow-hidden align-items-center justify-content-center"
      >
        <div
          class="d-flex flex-column rounded"
          style="
            /* background-color: var(--component-background-color); */
            border: 2px solid rgba(0, 0, 0, 0.285);
            width: 98%;
            height: 95%;
          "
        >
          <ng-container *ngIf="!noContentAvailable">
            <ng-container *ngIf="show && redrawChart && graphLoaded">
              <div
                class="w-100 d-flex flex-row justify-content-between"
                style="height: fit-content + 10px; overflow-x: auto"
              >
                <div
                  class="col-lg-8 d-flex flex-row justify-content-around align-items-center ps-1 py-2"
                >
                  <form [formGroup]="form" class="d-flex col-lg-12 gap-3">
                    <mat-form-field class="col-lg-3 h-75 ms-lg-5 ms-0">
                      <mat-label>Select Time Average</mat-label>
                      <mat-select formControlName="average">
                        <mat-option
                          *ngFor="let reqAvg of requiredAverages"
                          [value]="reqAvg"
                        >
                          {{ reqAvg.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="!isRoseChart">
                      <mat-form-field class="col-lg-3 h-75">
                        <mat-label>Select Chart Type</mat-label>
                        <mat-select formControlName="chartType">
                          <mat-option
                            *ngFor="let chartType of chartTypes"
                            [value]="chartType"
                          >
                            <img class="card-image" [src]="chartType.url" />
                            {{ chartType.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="isRoseChart">
                      <mat-form-field class="col-lg-5 h-75">
                        <mat-label>Select Direction</mat-label>
                        <mat-select formControlName="directionType">
                          <mat-option
                            *ngFor="let directionType of directionTypes"
                            [value]="directionType"
                          >
                            {{ directionType.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </form>
                </div>
                <ng-container *ngIf="analyticDetail.analyticType !== 4">
                  <mat-slide-toggle
                    (change)="toggleChartView()"
                    color="primary"
                    style="padding-top: 20px; padding-right: 20px"
                    [checked]="!isDefaultChartView"
                    [matTooltip]="
                      isDefaultChartView
                        ? 'View color as per limits'
                        : 'Default View'
                    "
                  ></mat-slide-toggle>
                </ng-container>
              </div>
              <div class="highcharts-dashboards-dark" style="flex: 1">
                <highcharts-chart
                  [Highcharts]="highcharts"
                  [options]="chartOptions"
                  class="d-block w-100 h-100"
                >
                </highcharts-chart>
              </div>
            </ng-container>
            <div class="py-5" *ngIf="!show || !redrawChart || !graphLoaded">
              <mat-spinner
                class="mx-auto"
                [diameter]="50"
                color="primary"
              ></mat-spinner>
            </div>
          </ng-container>
          <ng-container *ngIf="noContentAvailable">
            <div class="h-100 d-flex justify-content-center align-items-center">
              <app-content-unavailable
                *ngIf="graphLoaded"
                [majorText]="noData.majorText"
                [svgImage]="noData.svgImage"
                [minorText]="noData.minorText"
              >
              </app-content-unavailable>
              <div class="py-5" *ngIf="!graphLoaded">
                <mat-spinner
                  class="mx-auto"
                  [diameter]="50"
                  color="primary"
                ></mat-spinner>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
