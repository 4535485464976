<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center justify-content-between gx-2 gy-2 gy-lg-0 mx-0"
    >
      <!-- <mat-form-field class="col-12 col-lg-2 device-form-field">
        <mat-label>Device</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Devices'"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : headerForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
          class=""
          style="z-index: 0"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field> -->
        <div
          class="col-12 col-md-10 device-form-field"
          [ngClass]="!deviceTags.length ? 'col-lg-3' : 'col-lg-5'"
        >
          <app-tag-and-devicetype-dropwdown
            [tags]="deviceTags"
            [deviceTypes]="deviceTypes"
            [devices]="devices"
            [patchDevice]="currentActiveDevice"
            [showButton]="false"
            (selectedDevice)="onDeviceSelectionInAutomation($event)"
            (filteredDeviceList)="onTagSelection($event)"
          >
          </app-tag-and-devicetype-dropwdown>
        </div>
  
        <div class="col col-lg-auto">
          <button *ngIf="getAutomationModuleOptions('add')"
            mat-raised-button
            color="primary"
            [disabled]="!isRelayOutputAvailable"
            [hidden]="isAddConfigButtonHidden"
            (click)="openConfigForm()"
            matTooltip="Add Automation"
          >
            Add Automation
            <mat-icon class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
