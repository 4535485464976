<div class="px-3 mt-2 mb-3">
  <h6 class="px-0 my-0" mat-dialog-title>Filter Options</h6>
</div>
<div
  mat-dialog-content
  class="d-flex flex-column px-3"
  [formGroup]="filterForm"
>
  <mat-form-field appearance="outline">
    <mat-label>Select Filter</mat-label>
    <mat-select formControlName="filterKey">
      <mat-option
        *ngFor="let column of data?.columns"
        [value]="column"
        [disabled]="column?.selected"
        [ngClass]="{ 'd-none': !isColumnFilterValid(column) }"
      >
        <span [innerHTML]="column?.value"></span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    class="mt-3"
    appearance="outline"
    *ngIf="isParameter() || isTime()"
  >
    <mat-label>Filter by</mat-label>
    <mat-select formControlName="filterBy">
      <mat-option value="greaterThan"> Greater Than </mat-option>
      <mat-option value="lessThan"> Less Than </mat-option>
      <mat-option value="equalsTo"> Equals To </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="mt-3"
    *ngIf="
      !isStatus() && !isTime() && this.filterForm.get('filterKey')?.value?.key
    "
  >
    <mat-label>Value</mat-label>
    <ng-container [ngSwitch]="this.filterForm.get('filterKey')?.value?.key">
      <mat-select *ngSwitchCase="'end_date'" formControlName="filterValue">
        <mat-option *ngFor="let type of createdFilterDd" [value]="type.value">
          <span>{{ type.label }}</span>
        </mat-option>
      </mat-select>
      <mat-select *ngSwitchCase="'Status'" formControlName="filterValue">
        <mat-option
          *ngFor="let status of statuses; let i = index"
          [value]="i"
          >{{ status }}</mat-option
        >
      </mat-select>
      <mat-select *ngSwitchCase="'priority'" formControlName="filterValue">
        <mat-option
          *ngFor="let priority of priorites; let i = index"
          [value]="priority"
          >{{ priority }}</mat-option
        >
      </mat-select>
      <mat-select *ngSwitchCase="'categoryId'" formControlName="filterValue">
        <mat-option *ngFor="let category of categories" [value]="category.id">{{
          category.label
        }}</mat-option>
      </mat-select>
      <input
        type="value"
        matInput
        formControlName="filterValue"
        *ngSwitchDefault
      />
    </ng-container>
  </mat-form-field>
  <mat-form-field class="mt-3" appearance="outline" *ngIf="isStatus()">
    <mat-label>Select Status</mat-label>
    <mat-select formControlName="status">
      <mat-option value="Online"> Online </mat-option>
      <mat-option value="Offline"> Offline </mat-option>
      <mat-option value="Not Connected"> Not Connected </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="mt-3" *ngIf="isTime()">
    <mat-label>Select Date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="time" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="picker"
      tabindex="-1"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions class="px-3 mt-3 mb-2">
  <button
    mat-raised-button
    color="primary"
    [disabled]="filterForm.invalid"
    (click)="applyFilter()"
    class="w-100"
    matTooltip="Apply"
  >
    APPLY
  </button>
</div>
