import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Observable, of, switchMap } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { ForgotPassword } from '../models/forgot-password/forgot-password';
import { LoginData } from '../models/login-data';
import { Module } from '../models/module';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';
import { MqttSocketService } from './mqtt-socket.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseAPIService<LoginData> {
  private cookie: CookieService;
  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private mqttSocketService: MqttSocketService,
    private deviceService: DeviceService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService
  ) {
    super(http, cookieService);

    this.cookie = cookieService;
  }

  login(
    username: any,
    password: any,
    isSecureLogin: boolean,
    captchaValue?: string
  ): Observable<LoginData> {
    const loginRequest: Observable<LoginData> = this.getSecureLoginData(
      username,
      password,
      captchaValue
    );
    return loginRequest;
  }

  getSecureLoginData(
    email: string,
    password: string,
    recaptcha?: string
  ): Observable<LoginData> {
    const loginRequest = recaptcha
      ? { email, password, recaptcha }
      : { email, password };
    return this.post<LoginData>(APIConstants.SECURE_LOGIN, loginRequest);
  }

  getModules(token: string): Observable<Array<Module>> {
    const headers = new HttpHeaders().append(
      AppConstants.AUTHORIZATION_HEADER,
      `${AppConstants.BEARER} ${token}`
    );
    return this.get<Array<Module>>(APIConstants.MODULE, headers);
  }

  getEncryptedPassword(password: string, key: string): string {
    try {
      const keyBytes = CryptoJS.enc.Utf8.parse(key.padEnd(32, '\0')); // Ensure the key is 32 bytes
      const iv = CryptoJS.lib.WordArray.random(128 / 8); // Generates a random 128-bit IV
      const encrypted = CryptoJS.AES.encrypt(password, keyBytes, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Concatenate IV and encrypted data, then encode in Base64
      const combined = CryptoJS.lib.WordArray.create(
        iv.words.concat(encrypted.ciphertext.words)
      );
      return CryptoJS.enc.Base64.stringify(combined);
    } catch (exception) {
      console.info(exception);
      return '';
    }
  }

  loginUsingToken(share_token?: string): Observable<LoginData> {
    const loginRequestWithToken: Observable<LoginData> =
      this.getSecureLoginUsingToken(share_token);
    return loginRequestWithToken;
  }

  getSecureLoginUsingToken(share_token?: string): Observable<LoginData> {
    const loginRequestWithToken = { share_token: share_token };
    return of({ loginRequestWithToken }).pipe(
      switchMap((request) =>
        this.post<LoginData>(
          APIConstants.SECURE_LOGIN,
          request.loginRequestWithToken
        )
      )
    );
  }

  logout(afterLogoutCallback: any) {
    localStorage.clear();
    sessionStorage.clear();
    this.mqttSocketService.disconnect(this.deviceService.incomingSocket!);
    if (!afterLogoutCallback) {
      this.router.navigate(['/login']);
    }
    this.cookie.clearCookies();
    this.localStorageService.setupDefaultConfig().subscribe({
      next: (response) => {
        const userLoggedIn = localStorage?.getItem(
          LocalStorageConstants.USER_ID
        )
          ? true
          : false;
        const moduleAccess =
          JSON.parse(
            this.localStorageService.getValue(
              LocalStorageConstants.MODULE_ACCESS
            )
          ) &&
          JSON.parse(
            this.localStorageService.getValue(
              LocalStorageConstants.MODULE_ACCESS
            )
          ).includes(1009)
            ? true
            : false;
        (window as any).userLoggedIn = userLoggedIn;
        (window as any).moduleAccess = moduleAccess;
        (window as any)?.initializeIntercom();
        afterLogoutCallback?.();
      },
      error: (err) => {
        console.info("Error:", err);
        this.notificationService.showNotification(
          AppConstants.CONFIG_ERROR_MESSAGE,
          AppConstants.CLOSE,
          'top',
          'center',
          'error'
        );
      },
    });
  }

  forgotPassword(payload: ForgotPassword) {
    return this.post<string>(APIConstants.FORGOT_PASSWORD, payload);
  }
}
