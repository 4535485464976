<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <app-data-table
    tableTitle="Dashboard Table"
    [data]="deviceData"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    [stickyColumns]="['time']"
    (onDownload)="exportTableDataToCSV()"
    [noDataViewConfig]="noData"
    [isChartVisible]="false"
    [showDownloadInHeader]="getDashboardModuleOptions('download')"
  >
  </app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
