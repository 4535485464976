import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { GraphViewComponent } from './pages/graph-view/graph-view.component';
import { VibrationRoutingModule } from './vibration-routing.module';
import { VibrationComponent } from './vibration.component';

@NgModule({
  declarations: [VibrationComponent, GraphViewComponent],
  imports: [
    CommonModule,
    VibrationRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class VibrationModule {}
