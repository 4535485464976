<div class="m-3">
  <ng-container *ngIf="userDetails.topLevel">
    <div class="row g-2 mb-3" *ngIf="orgData?.info && (loadTable | async)">
      <div class="col-12 col-sm-6 col-md-3">
        <mat-card class="w-100 h-100">
          <mat-card-content class="flex-content">
            <div>
              <span>Next Billing Date : </span>
              <span>{{ projectExpiry }}</span>
            </div>
            <div class="upgrade-button">
              <button mat-button color="primary" matTooltip="Upgrade Plan">
                Upgrade Plan
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <mat-card class="w-100 h-100">
          <mat-card-content class="flex-content">
            <div>
              <span>Remaining SMS : </span>
              <span
                >{{
                  (orgData.info.max_sms_count || 0) -
                    (orgData.info.used_sms_count || 0)
                }}/{{ orgData.info.max_sms_count }}</span
              >
            </div>
            <div class="upgrade-button">
              <button mat-button color="primary" matTooltip="Buy 1000 SMS">
                Buy 1000 SMS
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <mat-card class="w-100 h-100">
          <mat-card-content class="flex-content">
            <div>
              <span>Remaining Seats : </span>
              <span
                >{{
                  (orgData.info.max_seats || 0) -
                    (orgData.info.used_seats || 0)
                }}/{{ orgData.info.max_seats }}</span
              >
            </div>
            <div class="upgrade-button">
              <button mat-button color="primary" matTooltip="Buy More Seats">
                Buy More Seats
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <mat-card class="w-100 h-100">
          <mat-card-content class="flex-content">
            <div>
              <span>Remaining API Calls : </span>
              <span
                >{{
                  (orgData.info.max_api_calls || 0) -
                    (orgData.info.used_api_calls || 0)
                }}/{{ orgData.info.max_api_calls }} calls per month</span
              >
            </div>
            <div class="upgrade-button">
              <button
                mat-button
                color="primary"
                matTooltip="Upgrade Monthly Limit"
              >
                Upgrade Monthly Limit
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>

  <span class="h5">{{
    (userDetails.topLevel && (orgData.masterOrgId === userDetails.org)) ? "Edit & Update Master" : "Edit & Update project"
  }}</span>
</div>
<div class="m-3 mt-0 mb-0">
  <div class="row">
    <div class="col-12 gx-0 d-flex justify-content-end">
      <button mat-icon-button matTooltip="Refresh" (click)="refreshOrg()">
        <mat-icon class="material-symbols-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Close" (click)="closeEditOrg()">
        <mat-icon class="material-symbols-outlined">cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf="loadTable | async">
  <div class="w-100 p-2">
    <div class="table-border mb-3">
      <app-data-table
        class="edit-table"
        tableTitle="{{
          (userDetails.topLevel && (orgData.masterOrgId === userDetails.org)) ? 'Master Admin' : 'Project Admin'
        }}"
        [data]="adminUserData"
        [columns]="defaultColumns"
        [displayedColumns]="displayedColumnsAdmin"
        [showDeleteInHeader]="false"
        [showDownloadInHeader]="false"
        [showFilterInHeader]="false"
        [showSearchInHeader]="false"
        [showPaginator]="false"
        style="padding-bottom: 30px; padding-left: 20px; padding-right: 20px"
      >
      </app-data-table>
    </div>
    <div *ngIf="nonAdminUserData.length" class="table-border">
      <app-data-table
        class="edit-table"
        tableTitle="Project Users"
        [data]="nonAdminUserData"
        [columns]="defaultColumns"
        [displayedColumns]="displayedColumnsAdmin"
        [showDeleteInHeader]="false"
        [showDownloadInHeader]="false"
        [showFilterInHeader]="false"
        [showSearchInHeader]="false"
        [showPaginator]="false"
        style="padding-bottom: 30px; padding-left: 20px; padding-right: 20px"
      >
      </app-data-table>
    </div>
  </div>
</ng-container>

<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
