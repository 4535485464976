<ng-container *ngIf="noContentAvailable">
  <div class="h-100 d-flex justify-content-center align-items-center">
    <app-content-unavailable
      [isConfiguration]="noData.isConfiguration!"
      [majorText]="noData.majorText"
      [svgImage]="noData.svgImage"
      [minorText]="noData.minorText"
      [createFunc]="noData.createFunc!"
      [linkText]="noData.linkText!"
    >
    </app-content-unavailable>
  </div>
</ng-container>

<ng-container *ngIf="!noContentAvailable">
  <mat-sidenav-container class="h-100" autosize>
    <mat-sidenav
      [position]="'end'"
      [mode]="width > 991 ? 'side' : 'over'"
      [opened]="selectedCluster ? true : false"
    >
      <div class="device p-3">
        <div class="row">
          <div class="col">Cluster Information</div>
          <div class="col-auto">
            <mat-icon
              class="material-symbols-outlined cursor-pointer"
              (click)="clearCluster()"
              >close</mat-icon
            >
          </div>
          <div class="last-updated">
            Last Updated:
            {{ getFormattedDate(selectedCluster?.data?.payload?.d?.t) }}
          </div>
        </div>

        <mat-card class="mt-2 mb-4 p-3 pb-0 card-info">
          <div *ngIf="selectedCluster?.aqi_index_id" class="align-items-center">
            <div class="aqiGaugeHeader">
              {{ selectedCluster | clusterColor : "label" : aqiData }}
            </div>
            <app-aqi-guage
              class="align-items-center"
              [aqi]="selectedCluster | clusterColor : 'aqidata' : aqiData"
              [aqiIndex]="selectedCluster?.aqi_index_id"
              [deviceType]="selectedCluster.config.deviceType"
              class="aqiGuageblock"
            ></app-aqi-guage>
          </div>
          <div class="device__details mt-3">
            <div class="d-flex justify-content-between py-1">
              <span class="clusterName">Cluster Name </span>
              <span
                class="text-end clusterLabel"
                matTooltip="{{ selectedCluster?.label }}"
                >{{ selectedCluster?.label }}</span
              >
            </div>
            <hr class="device__details__divider" />
            <div class="d-flex justify-content-between py-1">
              <span>Cluster Type</span>
              <span class="text-end ps-2 ps-lg-3">{{
                selectedCluster?.deviceTypeLabel
              }}</span>
            </div>
            <hr class="device__details__divider" />
            <div class="d-flex justify-content-between py-1">
              <div>
                <ng-container *ngIf="selectedCluster">
                  <ng-container *ngIf="showFullText; else shortText">
                    <p
                      class="show-more-text"
                      [innerHTML]="
                        selectedCluster | clusterColor : 'description' : aqiData
                      "
                    ></p>
                    <span (click)="toggleFullText()" class="show-more-link"
                      >Show less</span
                    >
                  </ng-container>
                  <ng-template #shortText>
                    <p
                      class="show-more-text"
                      [innerHTML]="
                        getTruncatedText(
                          selectedCluster
                            | clusterColor : 'description' : aqiData
                        )
                      "
                    ></p>

                    <span
                      (click)="toggleFullText()"
                      *ngIf="
                        selectedCluster | clusterColor : 'description' : aqiData
                      "
                      class="show-more-link"
                      >Show more</span
                    >
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card class="aqi-details p-3 card-info">
          <div class="mb-2">Parameters</div>
          <mat-accordion>
            <ng-container *ngFor="let gasLevel of gasLevels; let index = index">
              <mat-expansion-panel
                #parameterPanel="matExpansionPanel"
                class="parameters-aqi-india mat-elevation-z0 m-0"
                [hideToggle]="false"
                (click)="
                  parameterPanel.hideToggle
                    ? (parameterPanel.expanded = false)
                    : null
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="align-self-center">
                    <div class="d-flex justify-content-between">
                      <span>{{
                        gasLevel?.name === "aqi" ? rawAqiLabel : gasLevel?.label
                      }}</span>

                      <div
                        [style.color]="
                          paramColor[gasLevel?.name]
                            ? paramColor[gasLevel?.name]
                            : 'inherit'
                        "
                      >
                        <span>{{ gasLevel?.value }}</span>
                        <span> {{ gasLevel?.unit }}</span>
                      </div>
                    </div>
                  </mat-panel-title>
                  <hr />
                </mat-expansion-panel-header>
                <app-parameter-details
                  *ngIf="selectedCluster"
                  [isRawAqi]="gasLevel?.name === 'aqi'"
                  [parameter]="gasLevel?.name"
                  [parameterValue]="gasLevel?.value"
                  [aqiIndex]="selectedCluster?.aqi_index_id"
                  [deviceType]="selectedCluster.config.deviceType!"
                  (noScaleFound)="parameterPanel.hideToggle = true"
                  [units]="selectedCluster.units"
                  [isVisible]="false"
                ></app-parameter-details>
                <div class="mb-4"></div>
              </mat-expansion-panel>
              <hr *ngIf="index + 1 !== gasLevels.length" class="my-0" />
            </ng-container>
          </mat-accordion>
        </mat-card>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-container *ngIf="googleMapsService.isApiLoaded | async">
        <div class="row mx-0 h-100">
          <google-map
            class="col-12 px-0"
            width="100%"
            height="100%"
            [options]="options"
          >
            <div *ngIf="clusters">
              <ng-container *ngFor="let cluster of clusters; let i = index">
                <div *ngIf="cluster && cluster.config">
                  <map-circle
                    *ngIf="!cluster.config.geojson"
                    [radius]="cluster.config.radius"
                    [center]="{
                      lat: cluster.config.latitude,
                      lng: cluster.config.longitude
                    }"
                    [options]="{
                      fillColor: cluster | clusterColor : 'color' : aqiData,
                      strokePosition: 0,
                      strokeOpacity: 1,
                      strokeColor: cluster | clusterColor : 'color' : aqiData,
                      strokeWeight: 1
                    }"
                  >
                  </map-circle>
                </div>
                <div *ngIf="cluster && cluster.config && aqiData">
                  <map-polygon
                    *ngIf="cluster.config.geojson"
                    [paths]="
                      convertToLatLngFormat(
                        cluster.config.geojson.geometry.coordinates
                      )
                    "
                    [options]="{
                      fillColor: cluster | clusterColor : 'color' : aqiData,
                      strokeOpacity: 0.4,
                      strokeColor: cluster | clusterColor : 'color' : aqiData,
                      strokeWeight: 1
                    }"
                  >
                  </map-polygon>
                </div>
                <div *ngIf="cluster.config && cluster.config">
                  <map-marker
                    #marker="mapMarker"
                    [options]="{
                      icon: 'assets/images/pins/3rd_party_device_pin.svg'
                    }"
                    [position]="{
                      lat: cluster.config.latitude,
                      lng: cluster.config.longitude
                    }"
                    (mapClick)="onMarkerClick(cluster, true)"
                  >
                  </map-marker>
                  <map-info-window
                    #ClusterInfoWindow="mapInfoWindow"
                    (closeclick)="closeInfoWindow(ClusterInfoWindow)"
                  >
                    <div class="mapDesc">
                      <div class="mapDescHead label-text-color">
                        <strong>{{ cluster.label }}</strong>
                        <hr />
                      </div>
                    </div>
                  </map-info-window>
                </div>
                <div *ngIf="cluster === selectedCluster">
                  <div
                    *ngFor="
                      let device of cluster.config?.devices ?? []
                        | clusterDevices : device_detail
                    "
                  >
                    <map-marker
                      #marker="mapMarker"
                      [options]="{
                        icon: device.iconUrl
                      }"
                      [position]="{
                        lat: device.latitude,
                        lng: device.longitude
                      }"
                      (mapClick)="openInfoWindow2(marker, infoWindow)"
                    ></map-marker>
                    <map-info-window
                      #infoWindow="mapInfoWindow"
                      (closeclick)="closeInfoWindow(infoWindow)"
                    >
                      <div class="mapDesc">
                        <div class="mapDescHead">
                          {{ device.label }}
                          <hr />
                        </div>
                      </div>
                    </map-info-window>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="industryLayerEnabled === true">
              <map-marker
                *ngFor="let industry of industries; index as i"
                #marker="mapMarker"
                [options]="{ icon: 'assets/images/pins/industry.png' }"
                [position]="{
                  lat: industry.geometry.location.lat(),
                  lng: industry.geometry.location.lng()
                }"
                (mapClick)="openIndustryInfoWindow(marker, i)"
              >
                <map-info-window
                  #industryInfoWindow="mapInfoWindow"
                  (closeclick)="closeInfoWindows()"
                >
                  <div class="industry">
                    <div class="industry__close">
                      <mat-icon
                        class="material-symbols-outlined icon"
                        (click)="closeInfoWindows()"
                      >
                        close
                      </mat-icon>
                    </div>
                    <div class="industry__name">{{ industry?.name }}</div>
                    <div class="industry__image">
                      <img src="{{ industry?.photo }}" />
                    </div>
                    <div class="industry__details">
                      <span>
                        <span class="industry__details__label">Location: </span>
                        {{ " " + industry?.address }}
                      </span>
                      <span>
                        <span class="industry__details__label">Rating: </span>
                        {{ " " + industry?.rating }}
                      </span>
                    </div>
                  </div>
                </map-info-window>
              </map-marker>
            </div>
          </google-map>
        </div>

        <app-common-map-controls
          [googleMap]="googleMap"
          [options]="options"
          (mapOptionsUpdated)="onMapOptionsUpdated($event)"
          [showComplainsLayerOption]="false"
        ></app-common-map-controls>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
