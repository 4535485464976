import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor() {}

  //generate url to send email via gmail
  generateEmailUrl(
    to: string,
    cc: string = '',
    subject: string = '',
    body: string = '',
    bcc: string = ''
  ): string {
    const encodedBody = encodeURIComponent(body);
    const encodedSubject = encodeURIComponent(subject);
    const encodedTo = encodeURIComponent(to);
    const encodedCc = encodeURIComponent(cc);
    const encodedBcc = encodeURIComponent(bcc);

    return `https://mail.google.com/mail/?view=cm&fs=1&to=${encodedTo}&cc=${encodedCc}&bcc=${encodedBcc}&su=${encodedSubject}&body=${encodedBody}`;
  }
}
