<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <app-data-table
    tableTitle="Hourly Cluster Data"
    [data]="AVG_DATA"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    (onDownload)="exportTableDataToCSV()"
    [noDataViewConfig]="noData"
    [isChartVisible]="false"
  >
  </app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
