import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Input,
} from '@angular/core';
import { WindParticleSystem } from 'src/app/shared/services/wind-particle-system.service';
import { VectorField } from 'src/app/shared/utils/wind-utils';
import { WindData } from 'src/app/shared/types/wind';
import { CommonMapService } from 'src/app/shared/services/common-map.service';
import { GoogleMap } from '@angular/google-maps';
import { Subscription } from 'rxjs';
import { set } from 'lodash';

@Component({
  selector: 'app-wind-layer',
  templateUrl: './wind-layer.component.html',
  styleUrls: ['./wind-layer.component.scss'],
  standalone: true,
})
export class WindLayerComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() windData: WindData[] = [];
  @Input() windLayerEnabled: boolean = false;
  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() cellSize: number = 0;
  @Input() particleCount: number = 0;
  @Input() particleLifetime: number = 150;
  @Input() curlFactor: number = 0.2;
  @Input() particleColor: string = 'rgba(255, 255, 255, 0.5)';
  @Input() interval: number = 1000;

  private markers: google.maps.Marker[] = [];
  private infoWindow: google.maps.InfoWindow | null = null;

  private _googleMap: GoogleMap | undefined;
  public get googleMap(): GoogleMap | undefined {
    return this._googleMap;
  }

  @Input() public set googleMap(v: GoogleMap | undefined) {
    if (v) {
      this._googleMap = v;
      this.mapService.onMapLoaded(v);
      // Listen to map events to update the overlay.
      v.googleMap?.addListener('idle', () => {
        this.windLayerAnimationPipeline();
      });
      // Create the overlay if it doesn't exist yet.
      if (!this.overlayRef) {
        this.createCanvasOverlay();
      }
    }
  }

  private canvasRef: HTMLCanvasElement | null = null;
  // Our overlay instance (created from the CanvasOverlay class defined below)
  private overlayRef: any = null;
  private animationIntervalId: any;

  private subscriptions: Subscription[] = [];

  constructor(public mapService: CommonMapService) {}

  ngOnInit(): void {
    // Create the canvas element that will host the wind layer.
    this.canvasRef = document.createElement('canvas');
    this.canvasRef.style.position = 'absolute';
    this.canvasRef.style.top = '0';
    this.canvasRef.style.left = '0';
    this.canvasRef.style.pointerEvents = 'none';
    this.subscriptions.push(
      this.mapService.mapLayerToggled$.subscribe((layerUpdate) => {
        this.windLayerEnabled = layerUpdate.state;
      })
    );
    this.googleMap?.googleMap?.addListener('dragstart', () => {
      this.overlayRef?.setMap(null);
    })
    this.googleMap?.googleMap?.addListener('dragend', () => {
      this.overlayRef?.setMap(this.googleMap?.googleMap);
    })
    // this.googleMap?.googleMap?.addListener('zoom_changed', () => {
    //   this.overlayRef?.setMap(null);
    //   const timeOutInstance = setTimeout(() => {
    //     this.overlayRef?.setMap(this.googleMap?.googleMap);
    //   }, 1000);
    //   clearTimeout(timeOutInstance);
    // });
  }

  ngAfterViewInit(): void {
    // Start animation if canvas and map are ready.
    if (this.canvasRef && this.googleMap) {
      this.windLayerAnimationPipeline();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update the overlay if windData or dimensions change.
    if (
      this.googleMap &&
      (changes.windData || changes.width || changes.height || changes.googleMap)
    ) {
      this.windLayerAnimationPipeline();
    }
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.setMap(null);
      this.windData = [];
      this.overlayRef = null;
    }
    if (this.animationIntervalId) {
      clearInterval(this.animationIntervalId);
    }
  }

  // Updated pipeline: create the overlay if needed or update it,
  // but do not remove it from the map.
  private windLayerAnimationPipeline(): void {
    if (!this.overlayRef) {
      this.createCanvasOverlay();
    } else {
      this.updateOverlay();
    }
  }

  // Create the overlay and attach it to the map.
  private createCanvasOverlay(): void {
    if (!this.canvasRef || !this.googleMap) return;
    this.overlayRef = new this.CanvasOverlay(
      this.canvasRef,
      this.windData,
      this.googleMap.googleMap!,
      this.cellSize,
      this.particleCount,
      this.particleLifetime,
      this.curlFactor,
      this.windLayerEnabled
    );
    this.overlayRef.setMap(this.googleMap.googleMap!);
  }

  // Update overlay data and restart animation.
  private updateOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.update(this.windData);
      // Force a redraw if needed.
      this.overlayRef.draw();
      this.startAnimation();
    }
  }

  // Animation loop to continuously update the overlay.
  private async startAnimation() {
    // if (this.animationIntervalId) {
    //   clearInterval(this.animationIntervalId);
    // }
    // this.animationIntervalId = setInterval(() => {
    //   if (this.overlayRef) {
    //     this.overlayRef.draw();
    //   }
    // }, this.interval);
    // we're using the following code to avoid memory leaks
    if(this.animationIntervalId) {
      clearTimeout(this.animationIntervalId);
    }
    if(this.overlayRef){
      await this.overlayRef.draw();
      this.animationIntervalId = setTimeout(() => this.startAnimation(), this.interval);
      
    }

  }

  private addMarkers(): void {
    this.clearMarkers();
    if (this.googleMap) {
      this.windData.forEach((dataPoint) => {
        const position = new google.maps.LatLng(dataPoint.lat, dataPoint.lon);
        const marker = new google.maps.Marker({
          position,
          map: this.googleMap?.googleMap,
          title: `Wind Speed: ${dataPoint.magnitude} m/s, Direction: ${dataPoint.direction}°`,
        });
        marker.addListener('click', () => {
          this.showInfoWindow(marker, dataPoint);
        });
        this.markers.push(marker);
      });
    }
  }

  private clearMarkers(): void {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
  }

  private showInfoWindow(
    marker: google.maps.Marker,
    dataPoint: WindData
  ): void {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow();
    }
    this.infoWindow.setContent(
      `Wind Speed: ${dataPoint.magnitude} m/s<br>Direction: ${dataPoint.direction}°`
    );
    this.infoWindow.open(this.googleMap?.googleMap, marker);
  }

  // Inline definition of the CanvasOverlay class.
  public CanvasOverlay = class extends google.maps.OverlayView {
    private canvas: HTMLCanvasElement;
    private windData: WindData[];
    private map: google.maps.Map;
    private projection: google.maps.MapCanvasProjection | null = null;
    private windLayerEnabled: boolean;

    private cellSize: number;
    private particleCount: number;
    private particleLifetime: number;
    private curlFactor: number;

    private vectorField: VectorField | null = null;
    private particleSystem: WindParticleSystem | null = null;

    constructor(
      canvas: HTMLCanvasElement,
      windData: WindData[],
      map: google.maps.Map,
      cellSize: number,
      particleCount: number,
      particleLifetime: number,
      curlFactor: number,
      windLayerEnabled: boolean = true
    ) {
      super();
      this.canvas = canvas;
      this.windData = windData;
      this.map = map;
      this.cellSize = cellSize;
      this.particleCount = particleCount;
      this.particleLifetime = particleLifetime;
      this.curlFactor = curlFactor;
      this.windLayerEnabled = windLayerEnabled;
    }

    override onAdd(): void {
      const panes = this.getPanes();
      panes?.overlayLayer?.appendChild(this.canvas);
    }

    override draw(): void {
      // Always get the current projection.
      this.projection = this.getProjection();
      if (!this.projection) return;
      const bounds = this.map.getBounds();
      if (!bounds) return;
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const topRight = this.projection.fromLatLngToDivPixel(ne);
      const bottomLeft = this.projection.fromLatLngToDivPixel(sw);
      if (!topRight || !bottomLeft) return;

      // Update canvas size and position.
      this.canvas.width = topRight.x - bottomLeft.x;
      this.canvas.height = bottomLeft.y - topRight.y;
      this.canvas.style.left = `${bottomLeft.x}px`;
      this.canvas.style.top = `${topRight.y}px`;

      // Lazily initialize vector field and particle system when needed.
      if (!this.vectorField || !this.particleSystem) {
        this.initializeSystems();
      }

      const ctx = this.canvas.getContext('2d');
      if (!ctx) return;
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.particleSystem?.update();
      this.particleSystem?.draw(ctx);
    }

    override onRemove(): void {
      this.canvas.parentElement?.removeChild(this.canvas);
    }

    private initializeSystems(): void {
      if (!this.projection) return;
      this.vectorField = new VectorField(
        this.canvas.width,
        this.canvas.height,
        this.cellSize,
        this.windData,
        this.projection
      );
      this.particleSystem = new WindParticleSystem({
        width: this.canvas.width,
        height: this.canvas.height,
        particleCount: this.particleCount,
        vectorField: this.vectorField,
        particleLifetime: this.particleLifetime,
        curlFactor: this.curlFactor,
      });
    }

    // Update wind data and reinitialize the systems.
    update(newWindData: WindData[]): void {
      this.windData = newWindData;
      if (this.projection) {
        this.vectorField = new VectorField(
          this.canvas.width,
          this.canvas.height,
          this.cellSize,
          this.windData,
          this.projection
        );
        this.particleSystem = new WindParticleSystem({
          width: this.canvas.width,
          height: this.canvas.height,
          particleCount: this.particleCount,
          vectorField: this.vectorField,
          particleLifetime: this.particleLifetime,
          curlFactor: this.curlFactor,
        });
      }
    }
  };
}
