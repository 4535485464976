import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ClusterService } from '../services/cluster.services';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ClusterDetailsResolver {
  constructor(private clusterService: ClusterService) {}
  resolve(): Observable<any> {
    this.clusterService.showLoader(true);
    const cachedData = this.clusterService.getCachedCluster();
    if (cachedData) {
      this.clusterService.showLoader(false);
      return of(cachedData);
    } else {
      return this.clusterService.getCluster().pipe(
        tap(() => {
          this.clusterService.showLoader(true);
        }),
        switchMap((data) => {
          this.clusterService.showLoader(false);
          return of(data);
        }),
        catchError((error) => {
          this.clusterService.showLoader(false);
          return of([]);
        })
      );
    }
  }
}
