import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { DataFlaggingService } from 'src/app/shared/services/data-flagging.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-flag-data-form',
  templateUrl: './flag-data-form.component.html',
  styleUrls: ['./flag-data-form.component.scss'],
})
export class FlagDataFormComponent {
  flagCategories = [
    { label: 'Corrected', value: -1, color: '#6ecc58' },
    { label: 'Failure', value: -2, color: '#eac736' },
    { label: 'Invalid', value: -3, color: '#ed9a2e' },
    { label: 'Maintenance', value: -4, color: '#d63636' },
    { label: 'Out Of Range', value: -5, color: '#8f3f97' },
    { label: 'Calibration', value: -6, color: '#7e0023' },
  ];
  form!: FormGroup;
  receivedData: any;
  constructor(
    private formsService: FormsService,
    private fb: FormBuilder,
    private dataFlaggingService: DataFlaggingService,
    private notificationService: NotificationService,
    @Inject(MTX_DRAWER_DATA) public data: any
  ) {
  }
  ngOnInit() {
    this.form = this.fb.group({
      selectedCat: [this.flagCategories[0].value],
    });
  }
  closeForm(data: boolean = false) {
    this.formsService.closeForm(data);
  }

  onSubmit() {
    const selectedCategory: [] = this.form.get('selectedCat')?.value;
    let allSelectedPayload: [] = this.data.selectedData.map((data: any) => {
      const payloadData = data.payload.d;
      const updatedPayload: any = { t: payloadData?.t };

      Object.keys(payloadData).forEach((key) => {
        if (key !== 't') {
          updatedPayload[key + '_cf'] = selectedCategory;
        }
      });
      return {
        deviceId: data.deviceId,
        deviceType: data.deviceType,
        payload: { d: updatedPayload },
      };
    });
    this.dataFlaggingService.flagTheData(allSelectedPayload).subscribe({
      next: (res: any) => {
        this.dataFlaggingService.dataFlagForm.next(this.data);
        this.dataFlaggingService.rowSelected.emit(this.data);
        allSelectedPayload = [];
        this.data.selectData = [];
        this.closeForm(true);
        this.notificationService.showSnackBar(
          res === 'Done!' ? 'Data flagged successfully.' : res,
          'success'
        );
      },
      error: (err: any) => {
        console.info("Error:", err);
        this.notificationService.showSnackBar(err, 'error');
      },
    });
  }
}
