import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeatmapData } from '@angular/google-maps';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, catchError, map, Observable, of, Subject } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AverageHour } from '../models/average-hour';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { Heatmap, HeatmapAdapter } from '../models/heatmap/heatmap';
import { CommonUtil } from '../utils/common-utils';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';

type FetchDataArgs = {
  heatmapId: string;
  startDate?: {
    moment?: moment.Moment;
    epoch?: number;
    epochMS?: number;
  };
  endDate?: {
    moment?: moment.Moment;
    epoch?: number;
    epochMS?: number;
  };
  average?: AverageHour;
  position?: {
    lat: number;
    lng: number;
  };
  keys?: any[];
};

@Injectable({
  providedIn: 'root',
})
export class HeatmapService extends BaseAPIService<any> {
  public heatmapFilters: {
    // Partial<Heatmap>;
    heatmap?: any;
    startDate?: Moment;
    endDate?: Moment;
    average?: AverageHour;
    keys?: any;
  } = {};
  private heatmapFiltersSubject: Subject<typeof this.heatmapFilters> =
    new Subject();
  public heatmapFilters$ = this.heatmapFiltersSubject.asObservable();

  public heatmaps: Heatmap[] = [];

  private heatmapsSubject: Subject<Heatmap[]> = new Subject();
  public heatmaps$: Observable<Heatmap[]> = this.heatmapsSubject.asObservable();
  private loaderSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService,
    http: HttpClient,
    cookieService: CookieService
  ) {
    super(http, cookieService);
  }
  showLoader(value: boolean) {
    this.loaderSubject.next(value);
  }

  getLoaderState(): Observable<boolean> {
    return this.loaderSubject.asObservable();
  }
  public updateHeatmapFilters({
    heatmap,
    startDate,
    endDate,
    average,
    keys,
  }: typeof this.heatmapFilters) {
    if (heatmap) {
      var heatmapObj = this.heatmaps.find(
        (hmap) => hmap.heatmapId === heatmap.heatmapId
      );
      this.heatmapFilters.heatmap = heatmapObj ?? heatmap;
    }
    if (startDate) {
      this.heatmapFilters.startDate = startDate;
    }
    if (endDate) {
      this.heatmapFilters.endDate = endDate;
    }
    if (average) {
      this.heatmapFilters.average = average;
    }
    // if (keys) {
    //   this.heatmapFilters.keys = keys;
    // } else {
    //   this.heatmapFilters.keys = this.getKeysOfSelectedDevice(heatmapObj);
    // }

    if (!this.heatmapFilters.startDate) {
    }

    this.heatmapFiltersSubject.next(this.heatmapFilters);
  }

  public getKeysOfSelectedDevice(selectedHeatmapObj: any) {
    let units =
      this.commonService.getAllUnits()[selectedHeatmapObj?.deviceTypeId];
    let limitsObj = Object.fromEntries(
      this.deviceService
        .fetchLimits(units, false)
        .map((field) => [field.fkey, field])
    );
    let devices = this.deviceService.registeredDevices
    ? [...this.deviceService.registeredDevices!]
    : [];
    let parameters = CommonUtil.getCommonDeviceParametersKeysForHeatmap(
      devices!.filter((d: any) =>
        selectedHeatmapObj?.devices?.includes(d.deviceId)
      )
    );
    let keys = parameters
      .filter(
        (param) =>
          !['t', 'temp', 'ws', 'wd', 'bs', 'hum'].includes(param) &&
          !param.endsWith('aqi') &&
          limitsObj[param]?.range?.length > 0
      )
      .join(',');
      return keys;
      
  }
  public getDefaultValuesForHeatmapFilters() {
    return {
      startDate: this.customMomentService
        .moment()
        // .endOf('hour')
        .startOf('day')
        .subtract(1, 'day'),
      endDate: this.customMomentService.moment().endOf('day'),
      // endDate: this.customMomentService.moment().startOf('day'),
      average: CommonUtil.getAverageHours({ valueInSeconds: true }).find(
        (avgHour) => avgHour.id === '1'
      ),
    };
  }

  public clearHeatmapFilters(defaultValues: typeof this.heatmapFilters = {}) {
    this.heatmapFilters = defaultValues;
  }

  public getUserHeatmaps(): Observable<Heatmap[]> {
    // this.showLoader(true);
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Heatmap[]>(
      APIConstants.GET_ALL_HEATMAPS.replace('{userId}', userId),
      headers
    ).pipe(
      map((res) => {
        res = res.map(HeatmapAdapter.adapt);
        this.heatmaps = res;
        this.heatmapsSubject.next(res ?? true);
        return res;
      }),
    );
  }

  public fetchHeatmapImageLayers({
    heatmapId,
    startDate,
    endDate,
    average,
    keys,
  }: FetchDataArgs): Observable<HeatmapData[]> {
    if (
      !heatmapId?.length ||
      !average?.id?.length ||
      !(
        (startDate?.epoch || startDate?.epochMS || startDate?.moment) &&
        (endDate?.epoch || endDate?.epochMS || endDate?.moment)
      )
    ) {
      let temp = new Subject<HeatmapData[]>();
      setTimeout(() => {
        temp.next([]);
        temp.complete();
      }, 500);
      return temp.asObservable();
    }

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const isMovingAverage = average.isMoving;
    let avg: number | undefined = average.value;
    if (!avg) {
      avg = undefined;
    }

    let gteMoment = startDate.moment;
    if (!gteMoment) {
      gteMoment = startDate.epoch
        ? this.customMomentService.moment.unix(startDate.epoch)
        : this.customMomentService.moment(startDate.epochMS);
    }
    let gte = gteMoment.unix();

    let lteMoment = endDate.moment;
    if (!lteMoment) {
      lteMoment = endDate.epoch
        ? this.customMomentService.moment.unix(endDate.epoch)
        : this.customMomentService.moment(endDate.epochMS);
    }
    let lte = lteMoment.endOf('day').unix();

    if (this.customMomentService.moment().endOf('day').unix() === lte) {
      lte = this.customMomentService.moment().unix();
    }
    lte = CommonUtil.modifyLteTimestamp(
      gte,
      lte,
      isMovingAverage ? 3600 : avg ?? 0
    );
    let p: { [key: string]: any } = {
      gte,
      lte,
      processType:
        average.value === 0 ? 'raw' : isMovingAverage ? 'moving-avg' : 'avg',
    };
    if (avg) {
      p.avg = avg;
    }
    if (keys) {
      p.keys = keys;
    }
    const params: HttpParams = new HttpParams().appendAll(p);

    return this.get<HeatmapData[]>(
      APIConstants.GET_HEATMAP_IMAGE_LAYERS.replace('{userId}', userId).replace(
        '{heatmapId}',
        heatmapId
      ),
      headers,
      params
    );
  }

  public addHeatmap(payload: { data: Heatmap }) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<string>(
      APIConstants.ADD_HEATMAP.replace('{userId}', userId),
      payload,
      headers
    );
  }

  public updateHeatmap(payload: { update: Heatmap }) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<string>(
      APIConstants.UPDATE_HEATMAP.replace('{userId}', userId).replace(
        '{heatmapId}',
        payload.update.heatmapId
      ),
      payload,
      headers
    );
  }

  public deleteHeatmap(heatmapId: string) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.delete<string>(
      APIConstants.DELETE_HEATMAP.replace('{userId}', userId).replace(
        '{heatmapId}',
        heatmapId
      ),
      headers
    );
  }

  public fetchHeatmapDataByLatLng(
    { heatmapId, startDate, endDate, position, average, keys }: FetchDataArgs,
    deviceType: string
  ): Observable<HeatmapData[]> {
    if (
      !heatmapId?.length ||
      !average?.id?.length ||
      !(
        (startDate?.epoch || startDate?.epochMS || startDate?.moment) &&
        (endDate?.epoch || endDate?.epochMS || endDate?.moment)
      )
    ) {
      let temp = new Subject<HeatmapData[]>();
      setTimeout(() => {
        temp.next([]);
        temp.complete();
      }, 500);
      return temp.asObservable();
    }

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const isMovingAverage = average.isMoving;
    let avg: number | undefined = average.value;
    if (!avg) {
      avg = undefined;
    }

    let gteMoment = startDate.moment;
    if (!gteMoment) {
      gteMoment = startDate.epoch
        ? this.customMomentService.moment.unix(startDate.epoch)
        : this.customMomentService.moment(startDate.epochMS);
    }
    let gte = gteMoment.unix();

    let lteMoment = endDate.moment;
    if (!lteMoment) {
      lteMoment = endDate.epoch
        ? this.customMomentService.moment.unix(endDate.epoch)
        : this.customMomentService.moment(endDate.epochMS);
    }
    let lte = lteMoment.endOf('day').unix();

    if (this.customMomentService.moment().endOf('day').unix() === lte) {
      lte = this.customMomentService.moment().unix();
    }
    lte = CommonUtil.modifyLteTimestamp(
      gte,
      lte,
      isMovingAverage ? 3600 : avg ?? 0
    );

    let p: { [key: string]: any } = {
      gte,
      lte,
      processType:
        average.value === 0 ? 'raw' : isMovingAverage ? 'moving-avg' : 'avg',
    };

    if (avg) {
      p.avg = avg;
    }
    if (keys) {
      p.keys = keys;
    }
    p.lat = position?.lat;
    p.lon = position?.lng;

    const params: HttpParams = new HttpParams().appendAll(p);

    return this.get<HeatmapData[]>(
      APIConstants.GET_HEATMAP_VALUES.replace('{userId}', userId).replace(
        '{heatmapId}',
        heatmapId
      ),
      headers,
      params
    ).pipe(
      map((deviceDetailRes: Array<any>) => {
        deviceDetailRes = deviceDetailRes.map((dp) => {
          return {
            payload: { d: dp },
          };
        });
        deviceDetailRes = [
          {
            deviceId: deviceDetailRes[0].deviceId ?? 'heatmap',
            deviceType,
            payload: [...deviceDetailRes],
          },
        ];
        let deviceDetails = deviceDetailRes[0].payload;
        deviceDetails = this.updateDeviceDetails(
          deviceDetails,
          true,
          deviceType
        );

        return deviceDetails;
      })
    );
  }

  public updateDeviceDetails(
    deviceDetails: Array<DeviceDetails>,
    calculateAQI: boolean = true,
    deviceType: string
  ) {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.commonService.getAllAQI();
    const allAqis: any = this.commonService.getAllAQIs();
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    const units: any =
      this.commonService.getAllUnits()[
        DeviceUtil.getDeviceTypeId(
          deviceType,
          this.commonService.getAllDeviceTypes()
        ) ?? 1001
      ];

    deviceDetails.forEach((deviceDetail) => {
      if (DeviceUtil.hasValidIndexForDeviceType(deviceDetail, deviceTypes)) {
        const aqiIndex = DeviceUtil.calculateAQI(
          deviceDetail.payload,
          DeviceUtil.getBreakpoints(
            undefined,
            deviceType,
            deviceTypes,
            allAqi,
            allAqis,
            aqi
          )
        );

        if (calculateAQI && deviceDetail.payload !== undefined) {
          deviceDetail.aqi = aqiIndex.aqi;
          deviceDetail.aqiKey = aqiIndex.aqiKey;
          if (aqiIndex.aqi !== null && aqiIndex.aqi !== undefined) {
            deviceDetail.payload.d['aqi'] = aqiIndex.aqi;
            deviceDetail.payload.d['aqiMessage'] = aqiIndex.aqiMessage;
          }
        }
      }
      deviceDetail.payload = DeviceUtil.convertUnits(
        deviceDetail.payload,
        units!
      );
    });

    return deviceDetails;
  }

  public getMaskedImage(boundary: any, base64Jpeg: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Decode the base64 JPEG string
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const width = img.width; // Adjust as needed
        const height = img.height; // Adjust as needed
        canvas.width = width;
        canvas.height = height;
        if (!ctx) {
          return;
        }
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Create a clipping path based on the polygon
        ctx.beginPath();
        const [firstPoint, ...polygonPoints] =
          boundary.features[0].geometry.coordinates[0];
        if (!boundary.bounds) {
          boundary.bounds = boundary.features[0].geometry.coordinates[0].reduce(
            (acc: any, [lon, lat]: any) => ({
              minLon: Math.min(acc.minLon, lon),
              maxLon: Math.max(acc.maxLon, lon),
              minLat: Math.min(acc.minLat, lat),
              maxLat: Math.max(acc.maxLat, lat),
            }),
            {
              minLon: Infinity,
              maxLon: -Infinity,
              minLat: Infinity,
              maxLat: -Infinity,
            }
          );
        }
        const polygonBounds = boundary.bounds;
        const scaleX = width / (polygonBounds.maxLon - polygonBounds.minLon);
        const scaleY = height / (polygonBounds.maxLat - polygonBounds.minLat);

        // Move to the first point
        const startX = (firstPoint[0] - polygonBounds.minLon) * scaleX;
        const startY = (polygonBounds.maxLat - firstPoint[1]) * scaleY;
        ctx.moveTo(startX, startY);

        // Draw lines to the rest of the points
        polygonPoints.forEach(([lon, lat]: any) => {
          const x = (lon - polygonBounds.minLon) * scaleX;
          const y = (polygonBounds.maxLat - lat) * scaleY;
          ctx.lineTo(x, y);
        });
        ctx.closePath();

        // Clip the image to the polygon
        ctx.globalCompositeOperation = 'destination-in'; // Retain only the clipping region
        ctx.fill();

        // Export the masked image as a Base64 URL
        const maskedImageUrl = canvas.toDataURL('image/png');
        canvas.remove();

        // Update the heatmap overlay
        resolve(maskedImageUrl);
      };

      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };

      // Load the image from the base64 string
      img.src = base64Jpeg;
    });
  }
}
