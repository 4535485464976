import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Cluster } from 'src/app/shared/models/cluster.ts/cluster';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { ClusterService } from 'src/app/shared/services/cluster.services';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent {
  clusters: Cluster[] = [];
  startDate!: Moment | null;
  endDate!: Moment | null;
  AVG_DATA: any;
  limits: any;
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = [];
  fields: any;
  allParameters: any[] = [];
  selectedCluster: any;
  columns: any[] = [];
  avgVal!: number;
  private userTimeFormat: number;
  defaultColumns: any[];

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };

  constructor(
    private clusterService: ClusterService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private router: Router,
    private route: ActivatedRoute,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
  ) {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.defaultColumns = this.generateDefaultColumnsConfig();
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.defaultColumns = this.generateDefaultColumnsConfig();
    this.clusters = this.clusterService.getAllClusterUnitData(
      this.clusterService?.clusterData
    );
    this.route.paramMap.subscribe((params) => {
      let id: any = params.get('selectedClusterID');
      if (id) {
        let cluster = this.clusters.find((cluster) => cluster.clusterId === id);
        this.clusterService.fetchedClusterId(id);
        this.clusterService.setSelectedCluster(cluster);
      } else {
        let selectedCluster = this.clusters[0];
        this.clusterService.fetchedClusterId(selectedCluster.clusterId);
        this.clusterService.setSelectedCluster(selectedCluster);
        this.router.navigate([selectedCluster.clusterId], {
          relativeTo: this.route,
        });
      }
    });
    this.addSubscription();
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;

    this.deviceService.getFields$.subscribe({
      next: () => {
        this.fields = this.deviceService.fields;
      },
    });
    this.deviceService.getLimits$.subscribe({
      next: () => {
        this.limits = this.deviceService.limits;
      },
    });
  }

  generateDefaultColumnsConfig() {
    return [
      {
        columnDef: 'time',
        header: 'Time',
        cell: (element: any) =>
          `${
            element.payload.d
              ? this.customMomentService.formatDatetime({
                  epoch: element?.payload?.d?.t,
                  format:
                    this.userTimeFormat === 12
                      ? 'hh:mm A, DD/MM/YY'
                      : 'HH:mm, DD/MM/YY',
                })
              : 'Device Offline'
          }`,
        parameter: false,
        selected: false,
        filter: false,
      },
    ];
  }

  addSubscription() {
    this.clusterService.getSelectedCluster().subscribe((selectedCluster) => {
      this.selectedCluster = selectedCluster
        ? selectedCluster
        : this.clusters[0];
    });

    this.clusterService.getStartDate().subscribe((startDate: Moment) => {
      this.startDate = startDate;
    });

    this.clusterService.getEndDate().subscribe((EndDate: Moment) => {
      this.endDate = EndDate;
    });

    this.clusterService.getAvg().subscribe((avgVal: number) => {
      this.avgVal = avgVal;
    });

    this.clusterService.clusterFilterData.next({
      selectedCluster: this.selectedCluster,
      startDate: this.startDate,
      endDate: this.endDate,
      average: this.avgVal,
    });
    this.clusterService.clusterFilterData$.subscribe((res) => {
      if (Object.keys?.(res)?.length > 0) {
        this.selectedCluster = res.selectedCluster
          ? res.selectedCluster
          : this.clusters[0];
        this.startDate = res.startDate ? res.startDate : this.startDate;
        this.endDate = res.endDate ? res.endDate : this.endDate;
        this.avgVal = res.average ? res.average : this.avgVal;
        this.DataChange();
      }
    });
  }

  DataChange() {
    this.loadTable.next(false);
    if (this.endDate && this.startDate && this.selectedCluster && this.avgVal) {
      this.AVG_DATA = this.clusterService
        .getHourlyData(
          this.selectedCluster.clusterId,
          this.startDate.startOf('day').unix(),
          this.endDate.endOf('day').unix(),
          this.avgVal
        )
        .subscribe({
          next: (data) => {
            this.AVG_DATA = data;
            this.latestDocumentSnapshot();
          },
          error: (err) => {
            console.info('Error fetching hourly data:', err);
          },
        });
    }
  }

  latestDocumentSnapshot() {
    this.allParameters = [];

    if (this.AVG_DATA.length === 0) {
      this.loadTable.next(true);
      return;
    }
    this.AVG_DATA.forEach(
      (data: {
        parameters: {
          [x: string]: {
            key: string;
            label: string;
            units: any;
            color: any;
            value: any;
          };
        };
        payload: { d: { [x: string]: any } };
      }) => {
        const parameters: any[] = [];
        const filteredArray = [];
        const deviceTypes: DeviceType[] =
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_DEV_TYPE
          );
        data.parameters = {};
        data.parameters['aqi'] = this.calculateAndStoreAQI(data);
        data.payload.d['aqi'] = data.parameters['aqi']?.value;
        const allAqi: any = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_ALL_AQI
        );
        
        const allAqis: any = this.commonService.getUserAQI();
        if (data.payload && data.payload.d) {
          let selectedClusterDeviceType:any = this.clusters?.find((id) => id?.clusterId === this.selectedCluster?.clusterId);
          const allKeys = Object.keys(data.payload.d);
          const allUnits: any = this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_UNITS
          );
          let convertedValue = DeviceUtil.convertUnits(
            data.payload,
            allUnits[selectedClusterDeviceType],
            undefined,
            false
          );
          DeviceConstants.MAP_FIELD_ARRAY.forEach((field) => {
            const fieldIndex = this.allParameters.findIndex(
              (param) => param.name === field
            );
            const isValidField = allKeys.find((key) => key === field);
            const availableField = this.fields
              .filter((field: { isVisible: any }) => field.isVisible)
              .find((f: { fkey: string }) => f.fkey === field);

            if (
              availableField?.isVisible === true &&
              isValidField &&
              fieldIndex < 0
            ) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                deviceTypes
              );
              this.allParameters.push({
                name: field,
                label:
                  field === 'aqi'
                    ? 'AQI'
                    : DeviceUtil.getFieldName(field, this.fields),
                unit: this.selectedCluster?.units[field]?.label,
              });
            }
          });

          allKeys.forEach((key) => {
            const fieldIndex = this.allParameters.findIndex(
              (param) => param.name === key
            );
            const field = this.fields
              .filter((field: { isVisible: any }) => field.isVisible)
              .find((field: { fkey: any }) => field.fkey === key);
            if (field?.isVisible === true && fieldIndex < 0) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                deviceTypes
              );
              this.allParameters.push({
                name: key,
                label:
                  key === 'aqi'
                    ? 'AQI'
                    : DeviceUtil.getFieldName(key, this.fields),
                unit:
                  this.selectedCluster?.units[key]?.label ||
                  DeviceUtil.getFieldUnit(
                    field,
                    undefined,
                    allUnits[deviceTypeId!]
                  ),
              });
              filteredArray.push(key);
            }
            data.parameters[key] = {
              key: key,
              label: DeviceUtil.getFieldName(key, this.fields),
              units: this.selectedCluster?.units[key]?.label,
              color:
                this.selectedCluster?.aqi_index_id && // if selected cluster has aqi added than only adding color
                DeviceUtil.getParamColor(
                  DeviceUtil.getLimitClass(
                    key,
                    data?.payload?.d?.[key],
                    this.deviceService.fetchLimits(this.selectedCluster?.units,false),
                    // this.limits,
                    // this.selectedCluster?.units
                  ),
                  deviceTypes,
                  allAqi,
                  allAqis,
                  this.selectedCluster?.aqi_index_id,
                  this.selectedCluster?.deviceTypeId,
                  'Cluster'
                ),
              value: Number(convertedValue.d[key]?.toFixed(2)),
            };
          });
        }
      }
    );

    this.columns = [];
    this.displayedColumns = [];
    this.columns = [...this.defaultColumns];
    this.displayedColumns = [...this.columns.map((c) => c.columnDef)];

    this.generateCustomColumns();

    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  calculateAndStoreAQI(cluster: any) {
    const devices = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );
    const all_aqis = this.commonService.getUserAQI();
    const all_aqi = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const aqi = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    let label;
    let description;
    const deviceTypeId: number | undefined = DeviceUtil.getDeviceTypeId(
      cluster.deviceType,
      devices
    );

    if (deviceTypeId != undefined && cluster?.aqi_index_id) {
      label = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      )[deviceTypeId]?.[cluster?.aqi_index_id]?.label;
      description = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      )[deviceTypeId][cluster?.aqi_index_id]?.index?.description;
    }

    const clusterId = cluster.deviceId;
    let aqiInfo = DeviceUtil.calculateAQI(
      cluster.payload,
      DeviceUtil.getBreakpoints(
        this.selectedCluster?.aqi_index_id ?? '',
        cluster.deviceType,
        devices,
        all_aqi,
        all_aqis,
        aqi
      )
    );
    let color = '#6ecc58';
    let color_arr;
    if (aqiInfo && aqiInfo.aqi) {
      color_arr = DeviceUtil.aqiColorArray(
        devices,
        all_aqi,
        all_aqis,
        this.selectedCluster?.aqi_index_id ?? '',
        cluster.deviceType,
        'Cluster'
      );
      if (aqiInfo.aqi < color_arr.limits[color_arr.limits.length - 1]) {
        for (let i = 0; i < color_arr.limits.length - 1; i++) {
          if (
            color_arr.limits[i] <= aqiInfo.aqi &&
            aqiInfo.aqi < color_arr.limits[i + 1]
          ) {
            color = color_arr.color[i];
          }
        }
      } else {
        color = color_arr.color[color_arr.color.length - 1];
      }
    }

    const data = {
      key: 'aqi',
      units: label,
      color: color,
      value: aqiInfo.aqi,
      label: DeviceUtil.getFieldName('aqi', this.fields),
    };
    return data;
  }

  generateCustomColumns(): void {
    this.allParameters.forEach((param) => {
      this.columns.push({
        columnDef: param.name,
        header: Boolean(param.unit?.replaceAll(' ', ''))
          ? `${param.label} <br><small>${param.unit}</small>`
          : `${param.label}`,
        cell: (element: any) =>
          param.label === 'AQI' ? `${element.parameters[param.name]?.value || '-'}`: `${element.parameters[param.name]?.value?.toFixed(2) || '-'}`,
        color: (element: any) =>
          element.parameters[param.name]?.value ? `${element.parameters[param.name]?.color || 'inherit'}` : '',
        parameter: true,
        selected: false,
        filter: true,
      });
      this.displayedColumns.push(param.name);
    });
  }

  exportTableDataToCSV(): void {
    const csvRows = [];
    // const headers = this.columns.map((field) =>
    //   field.header.replace('<small>', '').replace('</small>', '')
    // );
    const headers = this.columns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );
    csvRows.push(headers.join(','));
    let data: string[] = [];
    this.AVG_DATA.forEach((device: any) => {
      const values = this.columns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;
        if (columnKey === 'time') {
          cellValue = device?.payload?.d?.t
            ? this.customMomentService.formatDatetime({
                epoch: device.payload.d.t,
                format:
                  this.userTimeFormat === 12
                    ? 'hh:mm A, DD/MM/YY'
                    : 'HH:mm, DD/MM/YY',
              })
            : '-';
        } else {
          cellValue = device.payload.d[columnKey];
        }

        if (typeof cellValue === 'number') {
          cellValue = cellValue.toFixed(2);
        } else if (cellValue === undefined || cellValue === null) {
          cellValue = '';
        } else {
          cellValue = `${cellValue}`;
        }

        return `"${cellValue}"`;
      });
      csvRows.push(values.join(','));
    });
    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }
}
