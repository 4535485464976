import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LatestVersion } from '../../models/latest-version';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<LatestVersion.Info>,
    private mdDialogRef: MatDialogRef<AnnouncementComponent>
  ) {}

  close(): void {
    this.mdDialogRef.close();
  }
}
