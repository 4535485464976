<app-form-container
  [tableTitle]="isEdit ? 'Edit Cluster' : 'Add Cluster'"
  (cancelled)="backBtn === 'Back' ? previous() : closeForm()"
  (close)="closeForm()"
  (submit)="submitBtn == 'Next' ? submitAndNext() : onSubmit()"
  [submitBtn]="submitBtn"
  [cancelBtn]="backBtn"
  [isValid]="submitBtn == 'Next' ? deviceDetailForm.valid : mapDetailForm.valid"
>
  <div>
    <ng-container>
      <mat-stepper
        [linear]="true"
        #stepper
        class="h-100 custom-stepper"
        (selectionChange)="onStepperSelectionChange($event)"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
        <ng-container>
          <mat-step [stepControl]="deviceDetailForm">
            <div [formGroup]="deviceDetailForm" class="row py-2 gy-4">
              <ng-template matStepLabel>Device Details</ng-template>
              <div class="col-6">
                <mat-form-field class="w-100">
                  <mat-label>Device Type </mat-label>
                  <mat-select
                    formControlName="deviceType"
                    (selectionChange)="onDeviceTypeChange($event)"
                    [value]="deviceDetailForm.get('deviceType')?.value"
                  >
                    <mat-option
                      *ngFor="let deviceType of deviceTypes"
                      [value]="deviceType.deviceTypeId"
                    >
                      {{ deviceType.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <div>
                  <mat-form-field class="w-100">
                    <mat-label>Cluster Name</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="clusterName"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-6">
                <div *ngIf="filteredAllDevType?.index">
                  <mat-form-field class="w-100">
                    <mat-label>Select Index</mat-label>
                    <mat-select
                      formControlName="selectAqi"
                      [value]="selectedAqi"
                      (selectionChange)="addAqi($event.value)"
                    >
                      <ng-container
                        *ngIf="deviceDetailForm.get('deviceType')?.value"
                      >
                        <mat-option
                          style="padding-right: 0 !important"
                          *ngFor="let aqi of AqiKeys"
                          [value]="
                            allAqiList[
                              deviceDetailForm.get('deviceType')?.value
                            ][aqi].aqiIndexId
                          "
                        >
                          <div class="flex row">
                            <span>
                              {{
                                allAqiList[
                                  deviceDetailForm.get("deviceType")?.value
                                ][aqi].label
                              }}
                            </span>
                          </div>
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-step>
        </ng-container>
        <mat-step
          [stepControl]="mapDetailForm"
          label="Map Details"
          class="h-100"
          #disbledStepper
        >
          <form [formGroup]="mapDetailForm">
            <div class="col-12">
              <mat-radio-group
                aria-label="Select an option"
                formControlName="deviceOption"
                [disabled]="!deviceDetailForm.get('deviceType')?.value"
                (change)="formTypeChange($event.value)"
                [value]="openedForm"
              >
                <mat-radio-button
                  [disabled]="!deviceDetailForm.get('deviceType')?.value"
                  value="1"
                  [color]="'primary'"
                >
                  Select Devices
                </mat-radio-button>
                <mat-radio-button
                  [disabled]="!deviceDetailForm.get('deviceType')?.value"
                  value="2"
                  [color]="'primary'"
                >
                  Select Area
                </mat-radio-button>
              </mat-radio-group>
              <mat-error
                *ngIf="deviceDetailForm.get('deviceType')?.value && !openedForm"
              >
                Please select an option.
              </mat-error>
            </div>
            <div class="col-6 margin">
              <mat-form-field class="w-100" *ngIf="openedForm == 1">
                <mat-label>Select Devices</mat-label>
                <mat-select
                  multiple
                  [(value)]="selectedDevices"
                  formControlName="selectedDevicesControl"
                  (selectionChange)="addDevice($event)"
                >
                  <mat-checkbox
                    [color]="'primary'"
                    formControlName="selectAll"
                    (change)="selectAllDevices($event, allDevices)"
                  >
                    Select All
                  </mat-checkbox>
                  <mat-option
                    *ngFor="let deviceType of allDevices"
                    [value]="deviceType.deviceId"
                  >
                    <span>{{ deviceType.label }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <div class="devList" [ngClass]="{ disabled: isEdit }">
                <div
                  style="font-size: 70%; padding: 3px"
                  class="selectedDevHeader"
                >
                  <b>Selected Devices :</b>
                </div>
                <div style="overflow: auto">
                  <span
                    *ngFor="let device of selectedDevicesLabels"
                    class="device-chip"
                  >
                    <span class="label">{{ device?.label }}</span>
                    <span
                      class="removeDev"
                      [ngClass]="{ 'disable-click': isEdit }"
                      (click)="removeDeviceFromCluster(device?.deviceId)"
                    ></span>
                  </span>
                  <div
                    *ngIf="selectedDevicesLabels.length == 0"
                    style="text-align: center"
                  >
                    <strong>No Device added</strong>
                  </div>
                </div>
              </div>
              <mat-error
                class="errors"
                *ngIf="openedForm == 2 && selectedDevices.length == 0"
              >
                Please select the area.
              </mat-error>
              <mat-error class="errors" *ngIf="errorMsgForSelectedDev">
                More than two devices are required.
              </mat-error>
            </div>
            <div class="col-12 modal-map">
              <ng-container *ngIf="googleMapsService.isApiLoaded | async">
                <div
                  class="row mx-0 h-100"
                  [ngClass]="{ disabled: !openedForm || isEdit }"
                >
                  <google-map
                    class="col-12 px-0"
                    width="100%"
                    height="100%"
                    [options]="options"
                  >
                    <map-marker
                      *ngFor="let device of allDevices; index as i"
                      #marker1="mapMarker"
                      [label]="device?.labelOptions"
                      [position]="{
                        lat: device?.latitude,
                        lng: device?.longitude
                      }"
                      [options]="markerOptions(device)"
                      (mapClick)="openInfo(marker1, i)"
                    >
                      <map-info-window
                        #deviceInfoWindows2="mapInfoWindow"
                        class="text-center"
                      >
                        <div class="justify-content-center text-center p-2">
                          <span class="infoWindowLabel">
                            <b>Device :</b> {{ device.label }}
                          </span>
                          <div
                            *ngIf="
                              selectedDevices?.indexOf?.(device?.deviceId) < 0 &&
                              openedForm == 1 &&
                              deviceDetailForm.get('deviceType')?.value
                            "
                            class="addToCluster"
                            (click)="
                              addDeviceToCluster(device.deviceId);
                              deviceInfoWindows2.close()
                            "
                          >
                            <strong>Add to Cluster</strong>
                          </div>
                          <div
                            *ngIf="
                              selectedDevices?.indexOf?.(device.deviceId) > -1
                            "
                            class="addToCluster"
                            (click)="
                              removeDeviceFromCluster(device.deviceId);
                              deviceInfoWindows2.close()
                            "
                          >
                            <strong>Remove from cluster</strong>
                          </div>
                        </div>
                      </map-info-window>
                    </map-marker>
                    <map-circle
                      *ngIf="openedForm == 1"
                      [options]="circleOptions"
                      [center]="latLngNumber"
                      [radius]="circle.radius"
                    />
                  </google-map>
                </div>
                <app-common-map-controls
                  [googleMap]="googleMap"
                  [options]="options"
                  (mapOptionsUpdated)="onMapOptionsUpdated($event)"
                  [showComplainsLayerOption]="false"
                ></app-common-map-controls>
              </ng-container>
              <div id="map-reset">
                <button
                  matTooltip="Reset"
                  type="button"
                  class="btn btn-success"
                  (click)="resetForm('clicked')"
                >
                  Reset
                </button>
              </div>
            </div>

            <div class="col-12 margin">
              <mat-form-field class="w-100 description">
                <mat-label>Description</mat-label>
                <input
                  type="text"
                  pattern="^[a-zA-Z ]+$"
                  matInput
                  formControlName="description"
                />
              </mat-form-field>
            </div>
          </form>
        </mat-step>
      </mat-stepper>
    </ng-container>
  </div>
</app-form-container>
