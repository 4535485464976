import {
  Component,
  HostListener,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NbThemeService } from '@nebular/theme';
import {
  Subject,
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Cluster } from 'src/app/shared/models/cluster.ts/cluster';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { ClusterDevicesPipe } from 'src/app/shared/pipes/cluster-devices.pipe';
import { ClusterService } from 'src/app/shared/services/cluster.services';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { environment } from 'src/environments/environment';
import { ClusterFormComponent } from '../../componets/cluster-form/cluster-form.component';
import GMap = google.maps.Map;
import MapOptions = google.maps.MapOptions;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  @ViewChild(GoogleMap) public googleMap!: GoogleMap;
  @ViewChildren(MapMarker) public mapMarkers!: QueryList<MapMarker>;
  @ViewChildren('ClusterInfoWindow')
  public clusterInfoWindows!: QueryList<MapInfoWindow>;
  @ViewChildren('industryInfoWindow')
  public industryInfoWindows!: QueryList<MapInfoWindow>;

  mapTypes: string[] = ['roadmap', 'satellite', 'hybrid', 'terrain'];
  currentMapType: string = 'roadmap';
  fullScreenMode: string = 'zoom_out_map';
  trafficLayerEnabled: boolean = false;
  industryLayerEnabled: boolean = false;
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;
  hasLayer5000: boolean = false;
  hasLayer5001: boolean = false;
  hasLayer5002: boolean = false;
  industries: any[] = [];
  orgMapLayers: any[] = [];
  private layersMap = new Map();
  mapLayers!: number[];
  clusters: Cluster[] = [];
  // clusterData?: Cluster[] = [];
  trafficLayer?: google.maps.TrafficLayer;
  device_detail: DeviceDetails[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  selectedWeatherLayer: any[] = [];
  weatherApiKey: string = environment.openWeatherAPI;
  weatherOption: any;
  deviceInfo: any;
  selectedCluster: any;
  aqi: any;
  color: any;
  color2: any;
  limits: any;
  aqiData: any[] = [];
  width!: number;
  temperature?: number;
  humidity?: number;
  windSpeed?: number;
  batteryStatus?: number;
  noiseVal: number[] = [0, 0, 0, 0, 0];
  gasLevels: any[] = [];
  paramColor: any = {};
  fields: DeviceField[] = [];
  rawAqiLabel: string = AppConstants.RAW_AQI_LABEL;
  aqiInfo: any;
  showFullText = false;
  selectedClusterMaxLength = 100;
  map: any;
  openInfoWindows: MapInfoWindow[] = [];
  deviceTypes!: Array<DeviceType>;
  public noContentAvailable: boolean = false;
  public noData: ContentUnavailable = {
    isConfiguration: true,
    svgImage: AppConstants.EMPTY_REPORT_LIST,
    majorText: 'You have No Clusters Configured.',
    linkText: 'Add Cluster',
    minorText: 'and Proceed',
    createFunc: () => this.addCluster(),
  };

  showMoreOptions: boolean = false;
  mapOptions = {
    showLabels: true,
    showLandmarks: true,
    showTerrain: true,
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }

  constructor(
    public googleMapsService: GoogleMapsService,
    private themeService: NbThemeService,
    private localStorageService: LocalStorageService,
    private clusterService: ClusterService,
    private deviceService: DeviceService,
    private formsService: FormsService,
    private commonService: CommonService,
    private customMomentService: CustomMomentService,
    private clusterDevicesPipe: ClusterDevicesPipe
  ) {}

  ngOnInit(): void {
    this.clusterService.clearSelectedCluster();
    this.device_detail = this.deviceService.registeredDevices ?? [];
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.clusters = this.clusterService.getAllClusterUnitData(
      this.clusterService?.clusterData
    );

    if (this.clusters.length < 1) {
      this.noContentAvailable = true;
      return;
    } else {
      this.noContentAvailable = false;
    }

    if (this.clusters) {
      this.calculateAndStoreAQI(this.clusters);
    }
    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() => this.googleMap!.idle),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as unknown as GMap)),
        switchMap(() => this.googleMap!.zoomChanged)
      )
      .subscribe(() => {});

    try {
      this.orgMapLayers =
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .info?.map_layers ?? [];
    } catch (err) {
      console.info('Error:', err);
    }

    this.mapLayers = this.localStorageService.getParsedValue(
      LocalStorageConstants.MAP_LAYER
    );
    this.hasLayer5000 = this.mapLayers.includes(5000);
    this.hasLayer5001 = this.mapLayers.includes(5001);
    this.hasLayer5002 = this.mapLayers.includes(5002);

    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;

    setTimeout(() => {
      this.addSubscription();
    }, 1000);
  }

  addCluster() {
    this.formsService.openForm(ClusterFormComponent, '', 'lg');
  }

  addSubscription() {
    this.selectedCluster = [];

    this.clusterService.getSelectedCluster().subscribe((selectedCluster) => {
      this.selectedCluster = selectedCluster;
      let deviceTypeFiltered;
      if (this.selectedCluster) {
        deviceTypeFiltered = this.deviceTypes.find(
          (deviceType) =>
            deviceType.key === this.selectedCluster.config.deviceType
        );
      }
      if (deviceTypeFiltered) {
        this.selectedCluster.deviceTypeLabel = deviceTypeFiltered.label;
      }
      if (!selectedCluster) {
        this.fitBoundsForClusters(
          this.googleMap?.googleMap as unknown as GMap,
          this.clusters
        );
      }
      this.onMarkerClick(selectedCluster);
    });

    this.deviceService.getFields$.subscribe(() => {
      this.fields = this.deviceService.fields;
    });
  }

  zoomIn(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! + 1 <= this.options.maxZoom!
          ? this.googleMap.getZoom()! + 1
          : this.options.maxZoom,
    };
  }

  zoomOut(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! - 1 >= this.options.minZoom!
          ? this.googleMap.getZoom()! - 1
          : this.options.minZoom,
    };
  }

  ngAfterViewInit(): void {
    this.addSubscription();
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName == 'material-dark') {
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };
        if (firstTimeLoaded) {
          setTimeout(() => {
            this.fitBoundsForClusters(this.googleMap.googleMap!, this.clusters);
          });
        }
        firstTimeLoaded = true;
      });
    // this.addSubscription;
  }

  private addMapLayers(map: GMap) {
    // Iterate over each layer object in the array and add to map using index as key
    this.orgMapLayers.forEach((layerData: any, index: number) => {
      if (!layerData.dataUrl || !layerData.layerType) {
        console.info('Error:', 'Invalid layer data at index:', index);
        return; // Skip this layer if data is invalid
      }

      let layer;

      if (layerData.layerType === 'kmz') {
        // For KMZ file
        layer = new google.maps.KmlLayer({
          suppressInfoWindows: false,
          screenOverlays: true,
          preserveViewport: true,
          url: layerData.dataUrl, // KMZ file URL
          zIndex: 9999999999999,
        });
      } else if (layerData.layerType === 'geojson') {
        // For GeoJSON file
        layer = new google.maps.Data({
          map: map,
          style: {
            zIndex: 9999999999999,
          },
        });

        // Load GeoJSON from URL
        layer.loadGeoJson(layerData.dataUrl);
      }

      if (layer) {
        // Store the layer in the map using the array index as key
        this.layersMap.set(index, layer);

        // Initially set the layer on the map
        if (layerData.isVisible) {
          layer.setMap(map);
        }
      }
    });
  }

  mapReady(map: GMap): void {
    this.map = map;
    this.addMapLayers(map);

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('zoom-control')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('fullscreen-control')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      document.getElementById('map-types-control')!
    );
    this.fitBoundsForClusters(map, this.clusters);
  }

  switchToFullScreen(): void {
    const map = this.googleMap.googleMap?.getDiv().firstChild as HTMLElement;
    if (this.isFullscreen(map)) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen(map);
    }
  }

  isFullscreen(element: HTMLElement): boolean {
    return document.fullscreenElement == element;
  }

  requestFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      this.fullScreenMode = 'zoom_in_map';
      element.requestFullscreen();
    }
  }

  exitFullscreen(): void {
    if (document.exitFullscreen) {
      this.fullScreenMode = 'zoom_out_map';
      document.exitFullscreen();
    }
  }

  changeMapType(): void {
    if (this.currentMapType === 'roadmap') {
      this.currentMapType = 'hybrid';
    } else {
      this.currentMapType = 'roadmap';
    }

    this.options = {
      ...this.options,
      mapTypeId: this.currentMapType,
      center: this.googleMap.getCenter(),
      zoom: this.googleMap.getZoom(),
    };
  }

  public toggleOrgMapLayer(orgMapLayerIndex: number) {
    try {
      const layer = this.layersMap.get(orgMapLayerIndex);

      if (!layer) {
        console.info('Error:', '2Layer not found at index:', orgMapLayerIndex);
        return;
      }

      this.orgMapLayers[orgMapLayerIndex].isVisible =
        !this.orgMapLayers[orgMapLayerIndex].isVisible;

      if (
        this.orgMapLayers[orgMapLayerIndex].isVisible &&
        this.googleMap?.googleMap
      ) {
        layer.setMap(this.googleMap?.googleMap);
      } else {
        layer.setMap(null);
      }

      this.orgMapLayers = [...this.orgMapLayers];
    } catch (err) {
      console.info('Error:', err);
    }
  }

  toggleTrafficLayer(): void {
    this.trafficLayerEnabled = !this.trafficLayerEnabled;

    if (
      this.trafficLayer === undefined ||
      this.trafficLayer.getMap() == undefined ||
      this.trafficLayer.getMap() === null
    ) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.googleMap.googleMap!);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  toggleIndustryLayer() {
    this.industryLayerEnabled = !this.industryLayerEnabled;

    if (this.industryLayerEnabled) {
      const request = {
        location: { lat: 23.0225, lng: 72.5714 },
        query: 'find industries near me',
        keyword: 'industry',
        types: ['name', 'geometry'],
        radius: 5000000,
      };

      const service = new google.maps.places.PlacesService(
        this.googleMap.googleMap!
      );

      if (this.industries.length <= 0) {
        service.nearbySearch(request, (results, status, pagination) => {
          this.industryLayerEnabled = false;
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (pagination?.hasNextPage) {
              pagination.nextPage();
            }
            results?.forEach((result) => {
              const place = {
                name: result.name,
                geometry: result.geometry,
                address: result.vicinity,
                rating: result.rating,
                photo: this.getIndustryImage(result),
              };
              this.industries.push(place);
            });
          } else if (
            status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
          ) {
            console.info('API limit exceeded. Please contact developer.');
          }
        });
      }
    }
  }

  getIndustryImage(result: google.maps.places.PlaceResult): string | undefined {
    return typeof result.photos !== 'undefined'
      ? result.photos[0].getUrl({ maxWidth: 150, maxHeight: 150 })
      : result.icon;
  }

  closeInfoWindows(): void {
    this.industryInfoWindows.toArray().forEach((iw) => iw.close());
    this.clusterInfoWindows.toArray().forEach((iw) => iw.close());
  }

  convertToLatLngFormat(originalData: number[][][]): { lat: any; lng: any }[] {
    const flattenedData: number[] = originalData.flat(2);

    const convertedData: { lat: any; lng: any }[] = [];

    for (let i = 0; i < flattenedData.length; i += 2) {
      convertedData.push({
        lat: flattenedData[i + 1],
        lng: flattenedData[i],
      });
    }

    return convertedData;
  }

  onMarkerClick(cluster: any, fromUI: boolean = false): void {
    if (fromUI) {
      this.clusterService.setSelectedCluster(cluster);
    }
    this.setMapBound(cluster as any);
    this.latestDocumentSnapshot(cluster);

    let clusterIndex = this.clusters?.findIndex(
      (arrCluster) => cluster?.clusterId === arrCluster?.clusterId
    );
    if (clusterIndex >= 0) this.openInfoWindow(clusterIndex);
  }

  latestDocumentSnapshot(cluster: any) {
    const parameters: any[] = [];
    const filteredArray = [];
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );

    if (cluster?.data.payload && cluster?.data.payload.d) {
      this.temperature = cluster.data.payload.d.temp
        ? Math.round(cluster.data.payload.d.temp)
        : undefined;
      this.humidity =
        cluster.data.payload.d.hum || cluster.data.payload.d.hum >= 0
          ? Math.round(cluster.data.payload.d.hum)
          : undefined;
      this.windSpeed =
        cluster.data.payload.d.ws || cluster.data.payload.d.ws >= 0
          ? Math.round(cluster.data.payload.d.ws)
          : undefined;
      this.noiseVal = cluster.data.payload.d.noise
        ? cluster.data.payload.d.noise
        : cluster.data.payload.d.leq
        ? [
            cluster.data.payload.d.leq,
            cluster.data.payload.d.lmax,
            cluster.data.payload.d.lmin,
            cluster.data.payload.d.l90,
            cluster.data.payload.d.l10,
          ]
        : undefined;
      const allKeys = Object.keys(cluster.data.payload.d);
      const allUnits: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_UNITS
      );

      allKeys.forEach((key) => {
        const fieldIndex = parameters.findIndex((param) => param.name === key);
        const field = this.fields
          .filter((field) => field.isVisible)
          .find((field) => field.fkey === key);

        if (field?.isVisible === true && fieldIndex < 0 && key !== 't') {
          const deviceTypeId = DeviceUtil.getDeviceTypeId(
            this.deviceService.currentDeviceType.key,
            deviceTypes
          );
          parameters.push({
            name: key,
            value: cluster.data.payload.d[key],
            label: DeviceUtil.getFieldName(key, this.fields),
            unit:
              cluster.units[field.fkey]?.label ||
              allUnits[cluster.units]?.[key]?.label,
          });
          filteredArray.push(key);
        }
      });

      this.gasLevels = [...new Set(parameters)];
      this.paramColor = {};

      const allAqi: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      );
      const allAqis: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQIS
      );

      this.gasLevels.forEach((gasLevel) => {
        this.paramColor[gasLevel.name] = DeviceUtil.getParamColor(
          DeviceUtil.getLimitClass2(
            gasLevel.name,
            gasLevel.value,
            cluster.units
          ),
          deviceTypes,
          allAqi,
          allAqis
        );
      });

      this.gasLevels.forEach((gasLevel) => {
        this.paramColor[gasLevel.name] = DeviceUtil.getParamColor(
          DeviceUtil.getLimitClass(
            gasLevel.name,
            gasLevel.value,
            this.deviceService.limits
          ),
          deviceTypes,
          allAqi,
          allAqis
        );
      });
    }
  }

  findDataByDeviceTypeIdAndIndex(devices: any, deviceTypeId: number) {
    return devices.filter(
      (item: { deviceTypeId: number; index: boolean }) =>
        item.deviceTypeId === deviceTypeId && item.index === true
    );
  }
  calculateAndStoreAQI(clusters: any) {
    const devices = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );
    const all_aqis = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const all_aqi = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const aqi = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    let label;
    let description;
    for (let cluster of clusters) {
      const deviceTypeId: number | undefined = DeviceUtil.getDeviceTypeId(
        cluster?.config.deviceType,
        devices
      );

      if (
        deviceTypeId != undefined &&
        cluster.aqi_index_id &&
        this.findDataByDeviceTypeIdAndIndex(devices, deviceTypeId).length
      ) {
        label = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_ALL_AQI
        )[deviceTypeId][cluster.aqi_index_id]?.label;
        description = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_ALL_AQI
        )[deviceTypeId][cluster.aqi_index_id]?.index?.description;
      }

      const clusterId = cluster.clusterId;
      let aqiInfo = DeviceUtil.calculateAQI(
        cluster?.data?.payload,
        DeviceUtil.getBreakpoints(
          cluster?.aqi_index_id,
          cluster?.config?.deviceType,
          devices,
          all_aqi,
          all_aqis,
          aqi
        )
      );
      this.aqiInfo = aqiInfo;
      let color = '#6ecc58';
      let color_arr;
      if (aqiInfo && aqiInfo.aqi) {
        color_arr = DeviceUtil.aqiColorArray(
          devices,
          all_aqi,
          all_aqis,
          cluster.aqi_index_id,
          cluster?.config?.deviceType
        );
        if (aqiInfo.aqi < color_arr.limits[color_arr.limits.length - 1]) {
          for (let i = 0; i < color_arr.limits.length - 1; i++) {
            if (
              color_arr.limits[i] <= aqiInfo.aqi &&
              aqiInfo.aqi < color_arr.limits[i + 1]
            ) {
              color = color_arr.color[i];
            }
          }
        } else {
          color = color_arr.color[color_arr.color.length - 1];
        }
      }

      const data = {
        id: clusterId,
        aqidata: aqiInfo,
        color: color,
        aqi_arr: color_arr,
        label: label,
        description: description,
      };
      this.aqiData.push(data);
    }
  }

  colorfinder(cluster: any): string {
    const matchedCluster = this.aqiData.find(
      (cluster) => cluster.clusterId === cluster.clusterId
    );
    if (matchedCluster) {
      return matchedCluster.color;
    } else {
      return '';
    }
  }

  toggleFullText(): void {
    this.showFullText = !this.showFullText;
  }

  openInfoWindow(index: number) {
    this.clusterInfoWindows.toArray().forEach((iw) => iw.close());
    setTimeout(
      () =>
        this.clusterInfoWindows
          .toArray()
          [index].open(this.mapMarkers.toArray()[index], false),
      50
    );
  }

  openInfoWindow2(marker: MapMarker, infoWindow: MapInfoWindow) {
    this.openInfoWindows.push(infoWindow);
    infoWindow.open(marker);
  }

  closeInfoWindow(infoWindow: MapInfoWindow) {
    infoWindow.close();
    this.clearCluster();
  }

  openIndustryInfoWindow(marker: MapMarker, index: number): void {
    this.closeInfoWindows();
    this.industryInfoWindows.toArray()[index].open(marker, false);
  }

  getMapCenter(): { lat: any; lng: any } {
    if (this.clusters.length > 0) {
      const sumLat = this.clusters.reduce(
        (acc, cluster) => acc + cluster?.config?.latitude,
        0
      );
      const sumLng = this.clusters.reduce(
        (acc, cluster) => acc + cluster?.config?.longitude,
        0
      );
      const averageLat = sumLat / this.clusters.length;
      const averageLng = sumLng / this.clusters.length;
      return { lat: averageLat, lng: averageLng };
    } else {
      return { lat: 0, lng: 0 };
    }
  }

  clearCluster() {
    this.clusterService.clearSelectedCluster();
  }

  getTruncatedText(text: string): string {
    const firstFullStopIndex = text.indexOf('.');
    return firstFullStopIndex !== -1
      ? text.slice(0, firstFullStopIndex + 1)
      : text;
  }

  fitBoundsForClusters(map?: google.maps.Map, clusters?: Cluster[]): void {
    const latLngBounds = new google.maps.LatLngBounds();

    clusters?.forEach((cluster) => {
      let cluster_devices = this.clusterDevicesPipe.transform(
        cluster.config?.devices ?? [],
        this.device_detail
      );

      const deviceTypes = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
      cluster_devices.forEach((device: DeviceDetails) => {
        let deviceType = DeviceUtil.getDeviceTypeByKeyOrId(
          device.deviceType,
          deviceTypes
        );
        if (device && deviceType) {
          this.setClusterDevicesPin(device, deviceType, deviceTypes);
        }
      });

      if (cluster?.config && cluster?.config.geojson) {
        const coordinates = cluster?.config.geojson.geometry.coordinates;
        this.extendBoundsWithGeoJSON(latLngBounds, coordinates);
      } else if (
        cluster?.config &&
        cluster?.config.latitude &&
        cluster?.config.longitude
      ) {
        const center = {
          lat: cluster?.config.latitude,
          lng: cluster?.config.longitude,
        };
        this.extendBoundsWithCenter(latLngBounds, center);
      }
    });

    map?.fitBounds(latLngBounds);
  }

  private extendBoundsWithGeoJSON(
    bounds: google.maps.LatLngBounds,
    coordinates: number[][][]
  ): void {
    coordinates.forEach((coordinate) => {
      coordinate.forEach((point) => {
        if (point[1] && point[0]) {
          bounds.extend({ lat: point[1], lng: point[0] });
        }
      });
    });
  }

  private extendBoundsWithCenter(
    bounds: google.maps.LatLngBounds,
    center: { lat: number; lng: number }
  ): void {
    bounds.extend(center);
  }

  setMapBound(cluster: any = undefined) {
    if (!cluster) return;
    try {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      if (cluster?.config && cluster?.config.geojson) {
        for (const mm of cluster?.config.geojson.geometry.coordinates[0]) {
          bounds.extend(new google.maps.LatLng(mm[1], mm[0]));
        }
      } else if (cluster.config.radius) {
        const center = new google.maps.LatLng(
          cluster?.config.latitude,
          cluster?.config.longitude
        );
        const circleBounds = new google.maps.Circle({
          center,
          radius: cluster?.config.radius,
        }).getBounds();
        if (circleBounds) {
          bounds.union(circleBounds);
        }
      }
      if (this.map && bounds.getNorthEast() && bounds.getSouthWest()) {
        this.map.fitBounds(bounds);
      }
    } catch (e) {
      console.info('Error:', e);
    }
  }

  private setClusterDevicesPin(
    device: DeviceDetails,
    deviceType: DeviceType,
    deviceTypes: DeviceType[] | undefined = undefined
  ) {
    let devTypes;
    if (!deviceTypes || !deviceTypes.length) {
      devTypes = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
    } else {
      devTypes = deviceTypes;
    }
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    DeviceUtil.getColorForPin(
      device,
      deviceType,
      deviceType ? deviceType.index : false,
      this.deviceService.mqttDocs,
      this.customMomentService.moment().unix(),
      AppConstants.SHOW_RAW_DATA_AQI,
      AppConstants.DEFAULT_APP_COLOR,
      false,
      devTypes,
      allAqi,
      allAqis,
      aqi,
      false
    );
  }

  getFormattedDate(time: number | undefined): string {
    let date = '';
    date = this.customMomentService.formatDatetime({
      epoch: time,
      format: 'DD/MM/YYYY, hh:mm A',
    });
    return date;
  }

  toggleMoreOptions(): void {
    this.showMoreOptions = !this.showMoreOptions;
  }

  updateMapOption(option: 'showLabels' | 'showLandmarks'): void {
    this.mapOptions[option] = !this.mapOptions[option];

    // Preserve current center, zoom, and theme styles
    const currentCenter = this.googleMap.getCenter();
    const currentZoom = this.googleMap.getZoom();
    const currentStyles = this.options.styles;

    // Update map styles based on options
    this.options = {
      ...this.options,
      styles: this.getUpdatedMapStyles(currentStyles ?? []),
      center: currentCenter,
      zoom: currentZoom,
    };
  }

  private getUpdatedMapStyles(
    currentStyles: google.maps.MapTypeStyle[]
  ): google.maps.MapTypeStyle[] {
    let styles = [...currentStyles];
    console.log(styles);

    if (!this.mapOptions.showLabels) {
      styles.push({
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      });
    } else {
      styles = styles.filter((style) => style.elementType !== 'labels');
    }

    if (!this.mapOptions.showLandmarks) {
      styles.push({
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      });
    } else {
      styles = styles.filter((style) => style.featureType !== 'poi');
    }

    return styles;
  }
}
