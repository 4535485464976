import { Injectable } from '@angular/core';
import { CustomMomentService } from './custom-moment.service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  toket: any;
  constructor(private customMomentService: CustomMomentService) {}

  getCookie(cookieName: string): string {
    return (
      document.cookie
        ?.split(';')
        .find((cookie) => cookie.includes(cookieName))
        ?.split('=')[1]
        .trim() || ''
    );
  }

  setCookie(cookieName: string, cookieValue: string, expiryTime: number): void {
    const date = this.customMomentService.moment().add(expiryTime, 'days');
    const expires = 'expires=' + date.utc();
    document.cookie =
      cookieName + '=' + cookieValue + ';' + expires + ';path=/';
  }

  deleteCookie = (toket: any) => {
    document.cookie =
      toket + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // document.cookie = toket;
  };

  clearCookies() {
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }
}
