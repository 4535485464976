import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  public readonly isApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(private httpClient: HttpClient) {
    const apiKey = 'AIzaSyCplBHwaFe8_S9eB0viV5_xHcOSRekrYB0';
    this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,drawing,geometry,visualization`,
        'callback'
      )
      .subscribe({
        next: () => this.isApiLoaded.next(true),
        error: (_error) => this.isApiLoaded.next(false),
      });
  }
}
