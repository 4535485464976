import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { HeatmapService } from 'src/app/shared/services/heatmap.service';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnInit {
  headerForm!: FormGroup;
  maxDate = this.customMomentService.moment();

  constructor(
    private formBuilder: FormBuilder,
    private heatmapService: HeatmapService,
    private customMomentService: CustomMomentService
  ) {}

  ngOnInit(): void {
    this.headerForm = this.buildHeaderForm();
    setTimeout(() => {
      this.onApply();
    });
  }

  private buildHeaderForm(): FormGroup {
    return this.formBuilder.group({
      startDate: [
        this.customMomentService.moment().endOf('hour').subtract(1, 'day'),
      ],
      endDate: [this.customMomentService.moment().startOf('day')],
    });
  }

  onApply() {
    if (this.headerForm.valid) {
      let dateRange = this.headerForm.value;
      dateRange.startDate = dateRange.startDate.startOf('day');
      if (
        dateRange.endDate.startOf('day').unix() ===
        this.customMomentService.moment().startOf('day').unix()
      ) {
        dateRange.endDate = this.customMomentService.moment().startOf('hour');
      } else {
        dateRange.endDate = dateRange.endDate.endOf('day');
      }
      this.heatmapService.updateDateRange(dateRange);
    }
  }
}
