<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center justify-content-between gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-3">
        <mat-label>Select Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
          <input
            matStartDate
            formControlName="startDate"
            placeholder="Start Date"
          />
          <input matEndDate formControlName="endDate" placeholder="End Date" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error
          *ngIf="
            headerForm?.controls?.startDate?.hasError('matStartDateInvalid')
          "
        >
          Invalid start date
        </mat-error>
        <mat-error
          *ngIf="headerForm?.controls?.endDate?.hasError('matEndDateInvalid')"
        >
          Invalid end date
        </mat-error>
      </mat-form-field>
      <div class="col col-lg-auto" *ngIf="isListView()">
        <button
          matTooltip="Add Complain"
          mat-raised-button
          class="w-100 w-lg-auto"
          [routerLinkActive]="'mat-button-checked'"
          color="primary"
          (click)="addComplain()"
        >
          Add Complain
          <mat-icon class="material-symbols-outlined">add</mat-icon>
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
