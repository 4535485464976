import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationComponent } from './configuration.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SensorListComponent } from './pages/sensor-list/sensor-list.component';
import { LANListComponent } from './pages/lan-list/LAN-list.component';
import { LANFormComponent } from './componet/lan-form/LAN-form.component';
@NgModule({
  declarations: [ConfigurationComponent, SensorListComponent, LANListComponent, LANFormComponent],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    TranslateModule.forChild({}),
    SharedModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
  ]
})
export class ConfigurationModule { }