<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-2 gy-2"
      [ngClass]="isDATAView() ? '' : 'justify-content-between'"
    >
      <div class="col-12 d-flex flex-wrap gap-2 align-items-center col-lg-12">
        <mat-form-field class="col-12 col-lg-3" *ngIf="isMapView()">
          <mat-label>Cluster</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Cluster'"
            matInput
            formControlName="clusterSearch"
            [matAutocomplete]="clusterSearch"
          />
          <mat-autocomplete
            #clusterSearch="matAutocomplete"
            [displayWith]="getClusterLabel"
          >
            <mat-optgroup
              *ngFor="let deviceType of deviceTypes"
              [label]="deviceType.label"
            >
              <mat-option
                *ngFor="
                  let cluster of clusterData
                    | deviceType : deviceType.deviceTypeId : true : deviceTypesObj
                    | search : headerForm.get('clusterSearch')?.value : 'label'
                "
                [value]="cluster"
              >
                {{ cluster.label }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-3" *ngIf="isDATAView()">
          <mat-label>{{ "Cluster" }}</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Cluster'"
            matInput
            formControlName="clusterName"
            [matAutocomplete]="clusterName"
          />
          <mat-autocomplete
            #clusterName="matAutocomplete"
            [displayWith]="getClusterLabel"
          >
            <mat-optgroup
              *ngFor="let deviceType of deviceTypes"
              [label]="deviceType.label"
            >
              <mat-option
                *ngFor="
                  let cluster of clusterData
                    | deviceType : deviceType.deviceTypeId : true : deviceTypesObj
                    | search : headerForm.get('clusterName')?.value : 'label'
                "
                [value]="cluster"
              >
                {{ cluster.label }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-12  col-md-2 col-lg-3" *ngIf="isDATAView()">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
            <input
              matStartDate
              formControlName="startDate"
              [placeholder]="'startDate'"
            />
            <input
              matEndDate
              formControlName="endDate"
              [placeholder]="'endDate'"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="
              headerForm?.controls?.startDate?.hasError('matStartDateInvalid')
            "
          >
            Invalid start date
          </mat-error>
          <mat-error
            *ngIf="headerForm?.controls?.endDate?.hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="col-12  col-md-2 col-lg-2"
          *ngIf="isDATAView()"
        >
          <mat-label>Interval</mat-label>
          <mat-select name="selectedInterval" formControlName="interval">
            <mat-option
              *ngFor="let interval of intervalArray"
              [value]="interval.val"
            >
              {{ interval.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-3" *ngIf="isTableView()">
          <mat-label>{{ "Device Type" }}</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Device Type'"
            matInput
            formControlName="deviceType"
            [matAutocomplete]="deviceType"
          />
          <mat-autocomplete
            #deviceType="matAutocomplete"
            [displayWith]="getDeviceType"
          >
            <mat-option
              *ngFor="
                let deviceType of deviceTypes
                  | search : headerForm.get('deviceType')?.value : 'label'
              "
              [value]="deviceType"
            >
              {{ deviceType.label }}
            </mat-option>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
          </button>
        </mat-form-field>
        <div class="col-12 col-lg-1 apply" *ngIf="isDATAView()">
          <button
            mat-raised-button
            color="primary"
            (click)="applyFilter()"
            matTooltip="Apply"
          >
            Apply
          </button>
        </div>
        <div class="ms-auto d-flex align-items-center" *ngIf="isClusterView() && getClusterModuleOptions('add')">
          <button
            (click)="addCluster()"
            mat-raised-button
            color="primary"
            class="w-100"
            matTooltip="Add Cluster"
          >
            Add Cluster
          </button>
        </div>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto" *ngIf="!loaderStates">
      <router-outlet></router-outlet>
    </div>
    <div class="py-5" *ngIf="loaderStates">
      <mat-spinner
        class="mx-auto"
        [diameter]="50"
        color="primary"
      ></mat-spinner>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
