import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { AppConstants } from '../constants/app-constants';
import { Permission } from '../constants/permission-type';
import { PushNotificationOptions } from '../models/push-notification-options';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  permission: Permission;
  notifications: Array<any> = [];
  notificationGroups: Record<string, Array<any>> = {
    device: [],
    alert: [],
  };

  userNotifications = new Subject<boolean>();
  userNotification$ = this.userNotifications.asObservable();

  constructor(private snackBar: MatSnackBar) {
    this.permission = this.isSupported() ? 'default' : 'denied';
  }

  requestPermission(): void {
    const self = this;
    if ('Notification' in window) {
      Notification.requestPermission(function (status) {
        return (self.permission = status);
      });
    }
  }

  showNotification(
    message: any,
    actionLabel: string,
    verticalPosition: MatSnackBarVerticalPosition = 'bottom',
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    type: keyof typeof AppConstants.SNACKBAR_TYPES = 'info',
    duration?: number,
    announcementMessage?: string
  ): void {
    // '✖' + '✘' + ' X ' ;
    if(type == 'error'){
      if(typeof(message) == 'object'){
        message = message?.error;
      }else{
        message = message;
      }
    }
    if (!duration) {
      this.snackBar.open(message, '✕', {
        duration: AppConstants.SNACKBAR_CONFIG.duration,
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        panelClass: AppConstants.SNACKBAR_TYPES[type],
        announcementMessage: announcementMessage,
      });
    } else {
      this.snackBar.open(message, '✕', {
        duration: duration,
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        panelClass: AppConstants.SNACKBAR_TYPES[type],
        announcementMessage: announcementMessage,
      });
    }
  }

  generateBrowserNotifications(notifications: Array<any>): void {
    notifications.forEach((notification) => {
      let options = {
        body: notification.alertContent,
        icon: '../../../assets/favicon/oizom-fav.ico',
      };
      this.notify(notification.title, options).subscribe();
    });
  }

  notify(title: string, options?: PushNotificationOptions): any {
    const self = this;
    return new Observable((obs) => {
      if (!('Notification' in window)) {
        obs.complete();
      }
      if (self.permission !== 'granted') {
        obs.complete();
      }
      const notify = new Notification(title, options);
      notify.onshow = (e) => {
        return obs.next({
          notification: notify,
          event: e,
        });
      };
      notify.onclick = (e) => {
        parent.focus();
        window.focus();
        notify.close();
        return obs.next({
          notification: notify,
          event: e,
        });
      };
      notify.onerror = (e) => {
        return obs.error({
          notification: notify,
          event: e,
        });
      };
      notify.onclose = () => {
        return obs.complete();
      };
    });
  }

  private isSupported(): boolean {
    return 'Notification' in window;
  }

  showSnackBar(message: string,type:any): void {
    let snackBarConfig = AppConstants.SNACKBAR_CONFIG;
    this.showNotification(
      message,
      snackBarConfig.actionLabel,
      snackBarConfig.verticalPosition,
      snackBarConfig.horizontalPosition,
      type
    );
  }
}
