import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../constants/app-constants';
import { LocalStorageConstants } from '../../constants/local-storage.constant';
import { DeviceType } from '../../models/device-type/device-type';
import { CommonService } from '../../services/common.service';
import { DeviceService } from '../../services/device.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { CommonUtil } from '../../utils/common-utils';
import { DeviceUtil } from '../../utils/device-utils';

@Component({
  selector: 'app-parameter-details',
  templateUrl: './parameter-details.component.html',
  styleUrls: ['./parameter-details.component.scss'],
})
export class ParameterDetailsComponent implements OnInit {
  @Input() deviceType!: string | number;
  @Input() isRawAqi!: boolean;
  @Input() parameter!: string;
  @Input() parameterValue!: string;
  @Input() aqiIndex?: any;
  @Input() units?: any;
  @Input() isVisible: boolean = true;

  @Output() noScaleFound: EventEmitter<any> = new EventEmitter();

  rawAqiLabel: string = AppConstants.RAW_AQI_LABEL;
  deviceTypeId?: number;
  parameterName?: string;
  allParameters?: any;
  scales!: any[];

  constructor(
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allUnits: any = this.commonService.getAllUnits();
    const allAqi: any = this.commonService.getAllAQI();
    // const aqi: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_AQI
    // );

    this.deviceTypeId = DeviceUtil.getDeviceTypeId(
      this.deviceType,
      deviceTypes
    );

    this.parameterName = DeviceUtil.getFieldName(
      this.parameter,
      this.deviceService.fields
    );

    if (this.units && !CommonUtil.isEmpty(this.units)) {
      this.allParameters = this.units;
      this.parameterName = DeviceUtil.getFieldName(
        this.parameter,
        [],
        this.allParameters
      );
    } else {
      this.allParameters = allUnits[this.deviceTypeId!];
    }

    // if (CommonUtil.isEmpty(this.allParameters)) {
    //   console.log('Without2');
    //   this.allParameters = allUnits[this.deviceTypeId!];
    // }

    if (this.parameter === 'aqi' && this.isRawAqi === true) {
      this.parameterName = this.rawAqiLabel;
    } else if (this.parameter !== 'aqi') {
      this.parameterName = DeviceUtil.getFieldName(
        this.parameter,
        this.deviceService.fields
      );
    } else {
      this.parameterName = allAqi[this.deviceTypeId!][this.aqiIndex].label;
    }

    let aqiData;
    const deviceWithAqi = this.commonService.getAllAQIs();
    
    try {
      if (
        this.aqiIndex !== undefined &&
        this.aqiIndex !== null &&
        allAqi &&
        allAqi[this.deviceTypeId!] &&
        allAqi[this.deviceTypeId!][this.aqiIndex]
      ) {
        aqiData = allAqi[this.deviceTypeId!][this.aqiIndex];
      } else {
        //if devicetype has aqi than assign aqi
        if (Object.keys(deviceWithAqi).includes(String(this.deviceTypeId))) {
          aqiData = deviceWithAqi[this.deviceTypeId!];
        } else {
          //if device type doesnot have aqi than assign aqi of Polludrone [1001]
          aqiData = deviceWithAqi[1001];
        }
      }
    } catch (exception) {
      //if error than assign aqi of Polludrone [1001]
      aqiData = deviceWithAqi[1001];
      console.info('Error in parameter details component', exception);
    }
    this.getParameterScales(aqiData);
  }

  getParameterScales(aqi: any): void {
    if (
      this.allParameters[this.parameter] &&
      this.allParameters[this.parameter].limit &&
      this.allParameters[this.parameter].limit.length > 0 &&
      this.allParameters[this.parameter].limit[0] !== null &&
      aqi.index &&
      aqi.index.colors &&
      aqi.index.names
    ) {
      this.scales = [];

      for (
        let index = 0;
        index < this.allParameters[this.parameter].limit.length;
        index++
      ) {
        if (index === this.allParameters[this.parameter].limit.length - 1) {
          if (
            this.allParameters[this.parameter].limit[index] <=
              this.parameterValue &&
            this.parameterValue < this.allParameters[this.parameter].h_limit
          ) {
            this.scales.push({
              name: aqi.index.names[index],
              color: aqi.index.colors[index],
              limit: [
                Math.round(
                  100 * this.allParameters[this.parameter].limit[index]
                ) / 100,
                Math.round(100 * this.allParameters[this.parameter].h_limit) /
                  100,
              ],
              inScale: true,
            });
          } else {
            this.scales.push({
              name: aqi.index.names[index],
              color: aqi.index.colors[index],
              limit: [
                Math.round(
                  100 * this.allParameters[this.parameter].limit[index]
                ) / 100,
                Math.round(100 * this.allParameters[this.parameter].h_limit) /
                  100,
              ],
              inScale: false,
            });
          }
        } else {
          if (
            this.allParameters[this.parameter].limit[index] <=
              this.parameterValue &&
            this.parameterValue <
              this.allParameters[this.parameter].limit[index + 1]
          ) {
            this.scales.push({
              name: aqi.index.names[index],
              color: aqi.index.colors[index],
              limit: [
                Math.round(
                  100 * this.allParameters[this.parameter].limit[index]
                ) / 100,
                Math.round(
                  100 * this.allParameters[this.parameter].limit[index + 1]
                ) / 100,
              ],
              inScale: true,
            });
          } else {
            this.scales.push({
              name: aqi.index.names[index],
              color: aqi.index.colors[index],
              limit: [
                Math.round(
                  100 * this.allParameters[this.parameter].limit[index]
                ) / 100,
                Math.round(
                  100 * this.allParameters[this.parameter].limit[index + 1]
                ) / 100,
              ],
              inScale: false,
            });
          }
        }
      }
    }
    if (!this.scales || !this.scales.length) {
      this.noScaleFound.emit(true);
    }
  }
}
