import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Project } from 'src/app/shared/models/project/project';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ProjectFormComponent } from '../../components/project-form/project-form.component';
@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss'],
})
export class ProjectEditComponent implements OnInit {
  orgData!: Project.Details;
  openedOrgData?: Project.Users[] = [];
  orgId: string = '';
  shareUrl: string = '';
  shareBool: boolean = false;
  isLocal!: boolean;
  domain: string = '';
  userId!: number;
  isEmailSent: boolean = false;
  dialogComponentInstanceforDeleteUser!: ConfirmationPopupComponent;
  dialogComponentInstanceforResetPassword!: ConfirmationPopupComponent;

  public adminUserData: Project.Users[] = [];
  public nonAdminUserData: Project.Users[] = [];
  public subscriptions: Subscription[] = [];

  public displayedColumnsAdmin: string[] = [];
  public loadTable: Subject<boolean> = new BehaviorSubject(false);

  defaultColumns: any[] = [];
  moduleAccess: any;
  userDetails: any;
  projectExpiry: any;

  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private formservice: FormsService,
    private dialog: MatDialog,
    private localstorageService: LocalStorageService,
    private userService: UserService,
    private location: Location,
    private commonService: CommonService,
    private customMomentService: CustomMomentService
  ) {
    if (this.location.getState()) {
      const data: any = this.location.getState();
      this.orgData = data;
    }
    this.defaultColumns = this.generateDefaultColumns();
  }

  ngOnInit(): void {
    this.displayedColumnsAdmin = [
      ...this.defaultColumns.map((c) => c.columnDef),
    ];
    this.moduleAccess = this.commonService.moduleAccessibility(1012);
    this.userDetails = this.commonService.getUser();
    this.userId = this.localstorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;
    if (this.orgData) {
      this.orgId = this.orgData.orgId;
      this.projectService
        .getOrgUsers(this.userId, this.orgId, this.userDetails.topLevel)
        .subscribe((res) => {
          this.openedOrgData = res;
          this.orgData = this.openedOrgData[0].orgData;
          this.filterAdminAndNonAdminUsers();
          this.loadData();
        });
    }
    this.shareUrl = '/integration/';
    this.isLocal = JSON.parse(
      this.localstorageService.getValue(
        LocalStorageConstants.OZ_LOCAL_DEPLOYMENT
      )
    );
    this.domain = window.location.hostname;

    let refresh = this.projectService.refreshProjects$.subscribe((res) => {
      if (res === true) {
        // this.orgData = this.location.;
        this.refreshOrg();
      }
    });

    this.subscriptions.push(refresh);
    this.defaultColumns = this.generateDefaultColumns();
    this.projectExpiry = this.orgData.info.expiry
      ? this.customMomentService.formatDatetime({
          epoch: this.orgData.info.expiry,
          format: 'DD/MM/YYYY',
        })
      : '--';
  }

  isDeleteDisabled(element: Project.Users): boolean {
    if (this.userDetails.topLevel) {
      if (element.role === 1 || element.role === 2) {
        return true;
      }
    } else {
      return element.role === 1;
    }
    return false;
  }

  isEditDisabled(element: Project.Users): boolean {
    if (this.userDetails.topLevel) {
      return !(
        this.orgData.masterOrgId === this.userDetails.org && element.role === 1
      );
    } else {
      return element.role !== 1;
    }
  }

  closeEditOrg() {
    this.router.navigate(['/projects']);
  }

  refreshOrg() {
    this.loadTable.next(false);
    this.projectService
      .getOrgUsers(this.userId, this.orgId)
      .subscribe((res) => {
        this.openedOrgData = res;
        this.orgData = this.openedOrgData[0].orgData;
        this.filterAdminAndNonAdminUsers();
        this.loadData();
      });
  }

  loadData(): void {
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  generateDefaultColumns() {
    return [
      {
        columnDef: 'actions',
        header: 'View',
        cell: (element: Project.Users) => `${element.org}`,
        parameter: false,
        selected: false,
        icon: 'more_vert',
        options: [
          ...['edit', 'delete']
            .filter((permission) =>
              this.commonService.getModuleAccessOptionsOnRoute(
                permission,
                this.moduleAccess
              )
            )
            .map((permission) => {
              switch (permission) {
                case 'edit':
                  return {
                    label: (element: Project.Users) =>
                      `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
                    action: (element: Project.Users) => {
                      if (!this.isEditDisabled(element)) {
                        this.openEditForm(element);
                      }
                    },
                    disable: (element: Project.Users) =>
                      this.isEditDisabled(element),
                  };
                case 'delete':
                  return {
                    label: (element: Project.Users) =>
                      `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
                    action: (element: Project.Users) => {
                      if (!this.isDeleteDisabled(element)) {
                        this.openDialog(element);
                      }
                    },
                    disable: (element: Project.Users) =>
                      this.isDeleteDisabled(element),
                  };
                default:
                  return null;
              }
            }),
          {
            label: (element: Project.Users) =>
              `<span class="material-symbols-outlined">login</span>&nbsp Login as this user`,
            action: (element: Project.Users) => this.loginASUserDialog(element),
          },
          {
            label: (element: Project.Users) =>
              `<span class="material-symbols-outlined">lock_reset</span>&nbsp Reset Password`,
            action: (element: Project.Users) =>
              this.resetPassword(element.email!),
          },
        ],
      },
      {
        columnDef: 'adminName',
        header: 'Contact Name',
        cell: (element: Project.Users) => `${element.name}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'mail',
        header: 'Email',
        cell: (element: Project.Users) => element?.email,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'projectName',
        header: 'Project Name',
        cell: (element: Project.Users) => element?.orgName,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'projectId',
        header: 'Project ID',
        cell: (element: Project.Users) => element?.org,
        parameter: false,
        selected: false,
      },
    ];
  }
  openEditForm(org: Project.Users) {
    this.projectService.sharedSeatsSubject.next(this.orgData.info);
    let form = this.formservice
      .openForm(
        ProjectFormComponent,
        { userData: org, orgData: this.orgData },
        'xl'
      )
      .subscribe((res) => {});

    this.subscriptions.push(form);
  }

  openNewLoginWindow(token: any) {
    this.shareBool = true;
    this.shareUrl = this.shareUrl + token;
    this.router.navigate([this.shareUrl]);
  }

  loginASUserDialog(element: any): void {
    const dynamicData = {
      title: 'Login As this User',
      message: `<b>Are you sure you want to log-in as this user ?</b><br/><br/><i>You will be logged out of the current account</i>`,
      icon: 'login',
      buttonconfig: [
        {
          text: 'Cancel',
          closeDialog: true,
        },
        {
          text: 'Redirect Me',
          icon: 'north_east',
          onClickCallback: () => {
            this.openNewLoginWindow(element.share_token);
          },
          closeDialog: false,
          color: 'primary',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.projectService.getDefaultDialogConfig,
      { data: dynamicData }
    );
    const dialogReffordeleteuser = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogComponentInstanceforDeleteUser =
      dialogReffordeleteuser.componentInstance;
    this.projectService.getDialogRef(dialogReffordeleteuser);
  }

  openDialog(orgIndex: any): void {
    const dynamicData = {
      title: 'Delete User',
      message: 'Are you sure you want to delete?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'No',
          closeDialog: true,
        },
        {
          text: 'Yes',
          onClickCallback: () => {
            this.deleteUser(orgIndex);
          },
          closeDialog: false,
          color: 'primary',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.projectService.getDefaultDialogConfig,
      { data: dynamicData }
    );
    const dialogReffordeleteuser = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogComponentInstanceforDeleteUser =
      dialogReffordeleteuser.componentInstance;
    this.projectService.getDialogRef(dialogReffordeleteuser);
  }

  deleteUser(orgIndex: any) {
    const targetUserId = orgIndex?.userId;
    this.projectService.deleteUser(this.userId, targetUserId).subscribe({
      next: (res: any) => {
        this.dialogComponentInstanceforDeleteUser.dialogConfig.message =
          'User Deleted succesfully';
        this.dialogComponentInstanceforDeleteUser.dialogConfig.buttonconfig = [
          {
            text: 'Ok',
            onClickCallback: () => this.refreshOrg(),
            closeDialog: true,
          },
        ];
      },
      error: (err: any) => {
        console.info('Error:', err);
      },
    });
  }

  resetPassword(email: string) {
    let orgemail = email;
    sessionStorage.setItem('reset-email', orgemail!);
    const data = {
      title: 'Reset Password',
      message: ` Are you sure you want to reset password for ${orgemail}?`,
      icon: 'lock_reset',
      buttonconfig: [
        {
          text: 'Cancel',
          closeDialog: true,
        },
        {
          text: 'Send Mail',
          onClickCallback: () => {
            this.emailLink(orgemail);
          },
          closeDialog: false,
          color: 'primary',
        },
      ],
    };

    const dialogConfigforresetpassword = Object.assign(
      {},
      this.projectService.getDefaultDialogConfig,
      { data: data }
    );
    const dialogRefforresetpassword = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfigforresetpassword
    );

    this.dialogComponentInstanceforResetPassword =
      dialogRefforresetpassword.componentInstance;
    this.projectService.getDialogRef(dialogRefforresetpassword);
  }

  emailLink(email: string) {
    if (this.domain === 'localhost') {
      this.domain = 'localhost:' + window.location.port;
    }
    let value: any = {
      email: email,
      domain: this.domain,
    };

    if (this.isLocal === false) {
      delete value.domain;
    }
    if (!this.isEmailSent) {
      this.isEmailSent = true;
      this.userService.getForgotPasswordToken(value).subscribe({
        next: (res: any) => {
          if (res) {
            this.dialogComponentInstanceforResetPassword.dialogConfig.message += `<br/><br/>Kindly check your mail to get link to reset your password.`;
            this.dialogComponentInstanceforResetPassword.dialogConfig.buttonconfig =
              [
                {
                  text: 'Ok',
                  onClickCallback: () => console.log(''),
                  closeDialog: true,
                  color: 'primary',
                },
              ];
          } else {
            this.dialogComponentInstanceforResetPassword.dialogConfig.message += `<br/>Something went wrong`;
            this.dialogComponentInstanceforResetPassword.dialogConfig.buttonconfig =
              [
                {
                  text: 'Cancel',
                  onClickCallback: () => console.log('No clicked'),
                  closeDialog: true,
                },
              ];
          }
        },
        error: (err) => {
          console.info('Error:', err);
        },
        complete: () => {
          this.isEmailSent = false;
        },
      });
    }
  }

  filterAdminAndNonAdminUsers() {
    if (this.openedOrgData) {
      this.adminUserData = this.openedOrgData?.filter((org) => org.role === 1);
    }
    if (this.openedOrgData) {
      this.nonAdminUserData = this.openedOrgData?.filter(
        (org) => org.role !== 1
      );
    }

    this.openedOrgData?.forEach((res) => {
      res.modules = res.moduleExpiry?.map((m: any) => m.moduleId);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
