import { Injectable } from '@angular/core';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceType } from '../models/device-type/device-type';
import { Settings } from '../models/settings/settings';
import { LocalStorageService } from './local-storage.service';
import { CommonUtil } from '../utils/common-utils';
import { AverageHour } from '../models/average-hour';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private localStorageService: LocalStorageService) {}

  getUserDeviceTypes(): Array<DeviceType> {
    return this.getDeviceTypes();
  }

  getUserDeviceTypesWithVibration(): Array<DeviceType> {
    return this.getDeviceTypes({
      includeVibrationTypes: true,
    });
  }

  getAllDeviceTypes(): Array<DeviceType> {
    return this.getDeviceTypes({
      includeVibrationTypes: true,
      getAllDeviceTypes: true,
    });
  }

  getDeviceTypes({
    includeVibrationTypes,
    getAllDeviceTypes,
  }: {
    includeVibrationTypes?: boolean;
    getAllDeviceTypes?: boolean;
  } = {}): DeviceType[] {
    let deviceTypes = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );
    if (!deviceTypes || getAllDeviceTypes) {
      deviceTypes = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
    }
    if (!includeVibrationTypes) {
      deviceTypes = deviceTypes?.filter?.(
        (deviceType: DeviceType) =>
          deviceType.key !== 'OMNIDOTS' && deviceType.key !== 'BEANAIR'
      );
    }
    return deviceTypes ?? [];
  }

  getAllAQIs() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).aqiIndex;
  }

  getAllUnits() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
  }

  getAllAQI() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
  }

  getFields() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_FIELDS
    );
  }

  getLimits() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ADMIN_LIMITS
    );
  }

  getUser() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
  }

  getUserAQI() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).aqiIndex;
  }

  getUserSettings(): Settings {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).settings;
  }

  getNotifLastVisit(): number {
    const notifValueLocalStorage = this.localStorageService.getValue(
      LocalStorageConstants.OZ_NOTIFICATION_LAST_VISIT
    );
    const notifValueSettings = this.getUserSettings().lastNotificationVisit;
    const value = notifValueLocalStorage ?? notifValueSettings;

    //if value is not available in local storage but available in user object than update local storage
    if (!notifValueLocalStorage && notifValueSettings) {
      this.localStorageService.saveValue(
        LocalStorageConstants.OZ_NOTIFICATION_LAST_VISIT,
        notifValueSettings
      );
    }
    if (value) {
      return Number(value);
    } else return 0;
  }

  getCurrentTheme(): string {
    return this.localStorageService.getValue(
      LocalStorageConstants.CURRENT_THEME
    );
  }

  // Get the user's time format from local storage, default is 24 hours if not set
  public getTimeFormat(): number {
    let settings = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.settings;
    return settings?.time_format ? settings.time_format : 24;
  }

  public getDateTimeFormat(isVibration: boolean = false): string {
    if (isVibration) {
      return this.getTimeFormat() === 12
        ? 'hh:mm:ss A | DD-MM-YY'
        : 'HH:mm:ss | DD-MM-YY';
    }
    return this.getTimeFormat() === 12
      ? 'hh:mm A, DD/MM/YY'
      : 'HH:mm , DD/MM/YY';
  }

  public getDateTimeFormatForGraph(): string {
    return this.getTimeFormat() === 12 ? 'DD/MM hh:mm A' : 'DD/MM HH:mm';
  }

  public getDateTimeFormatForToolTip(isVibration: boolean = false): string {
    if (isVibration) {
      return this.getTimeFormat() === 12
        ? 'DD MMM YYYY, hh:mm:ss.SSS A'
        : 'DD MMM YYYY, HH:mm:ss.SSS';
    }
    return this.getTimeFormat() === 12
      ? 'dddd, MMM D, YYYY - hh:mm a'
      : 'dddd, MMM D, YYYY - HH:mm';
  }

  // pass through function to get average hours
  public getAverageHours({
    includeRaw = true,
    includeMovingAvg = true,
    valueInSeconds = false,
  }: {
    includeRaw?: boolean;
    includeMovingAvg?: boolean;
    valueInSeconds?: boolean;
  }): AverageHour[] {
    const userSettings = this.getUserSettings();
    //check if user has access to raw data

    includeRaw = Boolean(userSettings.raw) && includeRaw;

    return CommonUtil.getAverageHours({
      includeRaw,
      includeMovingAvg,
      valueInSeconds,
    });
  }

  public IsMasterOrgUser(): boolean {
    const user = this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER);
    return user?.role === 1 && user?.modules?.includes(1012) ? true : false;
  }
}
