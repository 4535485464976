<div class="row p-1 p-md-3 mx-0">
  <div class="col-12">
    <div class="row">
      <div class="col mb-5">
        <h2 class="fs-5 text-primary my-auto">Credentials</h2>
        <mat-divider></mat-divider>
        <div
          class="p-4 rounded border table-responsive mt-2 w-100 mx-auto table-color"
        >
          <div class="container">
            <table class="text-overflow-ellipsis whitespace-nowrap mx-auto">
              <tr class="mx-3">
                <th class="fw-bold text-end p-3">Client Id :</th>
                <td class="py-3 px-1">{{ clientId }}</td>
                <td (click)="copyText(clientId)">
                  <mat-icon
                    matTooltip="Copy to clipboard"
                    class="material-symbols-outlined cursor-pointer me-5"
                  >
                    filter_none
                  </mat-icon>
                </td>
              </tr>
              <tr>
                <th class="p-3">Client Secret :</th>
                <td class="py-3 px-1">{{ clientSecret }}</td>
                <td (click)="copyText(clientSecret)">
                  <mat-icon
                    matTooltip="Copy to clipboard"
                    class="material-symbols-outlined cursor-pointer"
                  >
                    filter_none
                  </mat-icon>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-4 flex-lg-column col-sm-12 flex-sm-row">
        <div class="text-center mb-lg-0 mb-3">
          <a
            [href]="postman_url"
            target="_blank"
            class="cursor-pointer text-center"
          >
            <mat-icon
              style="height: 30px; width: 30px; vertical-align: middle"
              svgIcon="{{ 'postman_icon' }}"
              matTooltip="Postman"
            />
            <span class="linkText ms-2">Download Postman Collection</span>
          </a>
        </div>
      </div>
      <div class="col-lg-4 flex-lg-column col-sm-12 flex-sm-row">
        <div class="text-center mb-lg-0 mb-3">
          <a
            [href]="swagger_url"
            target="_blank"
            class="cursor-pointer text-center p-2"
          >
            <mat-icon
              style="height: 30px; width: 30px; vertical-align: middle"
              svgIcon="{{ 'swagger_icon' }}"
              matTooltip="Swagger"
            />
            <span class="linkText ms-2">Download Swagger Collection</span>
          </a>
        </div>
      </div>
      <div class="col-lg-4 flex-lg-column col-sm-12 flex-sm-row">
        <div class="text-center">
          <a
            [href]="try_it_url"
            target="_blank"
            class="cursor-pointer text-center p-2"
          >
            <mat-icon
              style="height: 30px; width: 30px; vertical-align: middle"
              svgIcon="{{ 'cloud_icon' }}"
              matTooltip="Cloud"
            ></mat-icon>
            <span class="linkText ms-2">Try it in the cloud</span>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col mb-5">
        <h2 class="fs-5 text-primary my-auto">REST APIs</h2>
        <mat-divider></mat-divider>
        <div class="mt-2">
          <ul class="mb-0 ps-4">
            <li>
              Oizom Restful APIs provide access to devices data
              resource-oriented URLs that enable to read data for devices.
            </li>
            <li>All Restful APIs <strong>secure with a token</strong>.</li>
            <li>
              First, register as a developer organization on the oizom. Then
              register your application on the
              <strong>oizom developer portal</strong>. When you register an
              application, the application is assigned a unique
              <strong>client ID</strong> and <strong>client secret</strong>. You
              must use the client ID when you call an API in header that
              requires you to identify you.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col mb-5">
        <h2 class="fs-5 text-primary my-auto">Allowed HTTPs requests</h2>
        <mat-divider></mat-divider>
        <div class="mt-2">
          <ul class="mb-0 ps-4">
            <ng-container *ngFor="let req of httpsReqTypes">
              <li>
                <strong>{{ req.key }}</strong
                ><span>: {{ req.value }}</span>
              </li>
            </ng-container>
            <!-- <li>
              <strong>GET</strong
              ><span>: Get a resource or list of resources</span>
            </li>
            <li><strong>PUT</strong><span>: Create/Update a resource</span></li>
            <li><strong>DELETE</strong><span>: Delete a resource</span></li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="fs-5 text-primary my-auto">API Rate Limiting</h2>
        <mat-divider></mat-divider>
        <div>
          <!-- <div class="pt-2 ps-3">
            <div class="fw-bold small text-primary">
              Following are the API call limits
            </div>
          </div>
          <div class="ps-3 pt-2">
            <ul class="mb-2 ps-3">
              <li>
                The number of HTTPS requests per second: <strong>4</strong>
              </li>
              <li>
                The number of HTTPS requests per day: <strong>1000</strong>
              </li>
            </ul>
          </div> -->

          <div class="ps-3 pt-2">
            <div class="fw-bold small text-primary">
              You will find API remaining limit information in each API response
              header.
            </div>
          </div>
          <div class="ps-3 pt-2">
            <ul class="ps-3">
              <li>x-rate-limit:<strong>50000 </strong></li>
              <li>x-rate-limit-remaining: <strong>49972 </strong></li>
              <li>x-rate-limit-usage: <strong>28 </strong></li>
              <li>x-rate-limit-validity-for: <strong>2024-10</strong></li>
            </ul>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  </div>
</div>
