<div
  *ngIf="showTable || paramsWithLimits.length"
  class="px-2 py-2 w-100"
  style="overflow: visible"
>
  <app-aqi-table
    *ngIf="showTable"
    [aqiData]="aqiData"
    [aqiDetails]="aqiDetails"
    [headers]="headers"
    [indexLabel]="indexLabel"
    tableTitle="AQI Parameters"
  >
  </app-aqi-table>

  <app-aqi-table
    *ngIf="paramsWithLimits.length"
    [aqiData]="nonAqiData"
    [aqiDetails]="nonAqiDetails"
    [headers]="nonAqiHeaders"
    [indexLabel]="'Limits'"
    tableTitle="Non AQI Parameters"
    [isNonAqiParams]="true"
  >
  </app-aqi-table>
</div>

<div
  class="h-100 d-flex justify-content-center align-items-center"
  *ngIf="!showTable && !paramsWithLimits.length"
>
  <b>Configure limits of parameters from Units tab.</b>
</div>
