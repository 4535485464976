import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataFlaggingComponent } from './data-flagging.component';
import { DataFlaggingListComponent } from './pages/list/data-flagging-list.component';
const routes: Routes = [
  {
    path: '',
    component: DataFlaggingComponent,
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: DataFlaggingListComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DataFlaggingRoutingModule {}
