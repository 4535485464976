import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFlaggingRoutingModule } from './data-flagging-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { DataFlaggingComponent } from './data-flagging.component';
import { DataFlaggingListComponent } from './pages/list/data-flagging-list.component';
import { FlagDataFormComponent } from './components/flag-data-form/flag-data-form.component';
import { HighchartsChartModule } from 'highcharts-angular';
@NgModule({
  declarations: [
    DataFlaggingComponent,
    DataFlaggingListComponent,
    FlagDataFormComponent,
  ],
  imports: [
    CommonModule,
    DataFlaggingRoutingModule,
    TranslateModule.forChild({}),
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    HighchartsChartModule
  ],
})
export class DataFlaggingModule {}
