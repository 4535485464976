import { Pipe, PipeTransform } from '@angular/core';
import { Cluster } from '../models/cluster.ts/cluster';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';

@Pipe({
  name: 'deviceType',
})
export class DeviceTypePipe implements PipeTransform {
  transform(
    devices: Array<DeviceDetails | Cluster>,
    deviceType: string | number,
    isCluster: boolean = false,
    deviceTypesObj: { [key: string]: DeviceType } = {}
  ): Array<DeviceDetails | Cluster> {
    if (!isCluster)
      return (
        devices?.filter(
          (device) =>
            device.deviceType === deviceType ||
            parseInt(deviceType?.toString()) ===
              deviceTypesObj?.[device.deviceType]?.deviceTypeId
        ) || []
      );
    else {
      return (
        devices?.filter(
          (device) =>
            (device as Cluster).deviceTypeId === Number(deviceType) ||
            (device as Cluster).deviceTypeId ===
              deviceTypesObj?.[deviceType]?.deviceTypeId // or condition is added because of requirement in Display module
        ) || []
      );
    }
  }
}
