import { Component, OnInit } from '@angular/core';
import { filter, sortBy } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AQIIndex } from 'src/app/shared/models/aqi-index/aqi-index';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { UnitsInternal } from 'src/app/shared/models/internal-use-front-end/units-internal';
import { Unit } from 'src/app/shared/models/units/unit';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { LimitFormComponent } from './components/limit-form/limit-form.component';

@Component({
  selector: 'app-units-management',
  templateUrl: './units-management.component.html',
  styleUrls: ['./units-management.component.scss'],
})
export class UnitsManagementComponent implements OnInit {
  public loadTable: Subject<boolean> = new BehaviorSubject(true);
  public allUnits!: UnitsInternal.DeviceTypeAndUnits;
  public fieldUnits: UnitsInternal.FieldUnits = [];
  public allData!: UnitsInternal.DeviceTypeWiseUnitInfoForTable;
  public selectedDeviceData!: UnitsInternal.UnitsInfoForTable[];
  public selectedDevice!: number;
  public allParamUnits!: UnitsInternal.DeviceTypeWiseUnitInfoForTable;
  public displayedColumns: string[] = [];
  public aqiIndexColor!: AqiIndexColorArray;
  public limits!: any;
  public subscriptions: Subscription[] = [];

  public defaultColumns: any[] = [
    {
      columnDef: 'parameter',
      header: 'Parameters',
      cell: (element: UnitsInternal.UnitsInfoForTable) => `${element.keyLabel}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'button',
      header: 'Limits',
      cell: (element: UnitsInternal.UnitsInfoForTable) => `${element.keyLabel}`,
      label: (element: UnitsInternal.UnitsInfoForTable) =>
        `${element.buttonLabel}`,
      color: (element: UnitsInternal.UnitsInfoForTable) =>
        element.buttonLabel === 'Add' ? 'primary' : undefined,
      action: (element: UnitsInternal.UnitsInfoForTable) =>
        this.openForm({
          ...element,
          availableUnits: this.fieldUnits?.[this.selectedDevice]?.[element.key],
          deviceTypeId: this.selectedDevice,
        }),
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'unit',
      header: 'Units',
      cell: (element: UnitsInternal.UnitsInfoForTable) =>
        `${element.checkedUnitName}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'colorLegend',
      header: 'Limits Info',
      cell: (element: UnitsInternal.UnitsInfoForTable) => {
        let aqiAndLimitInfo;
        const key = element.key as string;
        const limitInfo = this.limits ? this.limits[key] : undefined;
        return (aqiAndLimitInfo = {
          color: this.aqiIndexColor.color,
          labels: this.aqiIndexColor.labels,
          range: limitInfo?.range,
        });
      },
      parameter: false,
      selected: false,
      width: '60%',
      visible: (element: UnitsInternal.UnitsInfoForTable) =>
        this.limits?.[element?.key as any]?.range?.length,
    },
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private formsService: FormsService,
    private userService: UserService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.loadTable.next(false);

    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.allUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
    this.allData = this.getParams();

    this.addSubscriptions();
  }

  loadData() {
    setTimeout(() => {
      this.loadTable.next(true);
    }, 1000);
  }

  getFieldUnits() {
    const allParams = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_PARAMS
    );
    const deviceTypes = this.commonService.getUserDeviceTypes();

    if (!allParams) {
      return;
    }

    const fieldUnits: { [key: string]: { [key: string]: any } } = {};

    deviceTypes.forEach((deviceType) => {
      if (allParams[deviceType.deviceTypeId]) {
        allParams[deviceType.deviceTypeId].forEach((param: string) => {
          if (!fieldUnits[deviceType.deviceTypeId]) {
            fieldUnits[deviceType.deviceTypeId] = {};
          }
          if (!fieldUnits[deviceType.deviceTypeId][param]) {
            fieldUnits[deviceType.deviceTypeId][param] = sortBy(
              filter(this.allParamUnits[deviceType.deviceTypeId], {
                key: param,
              }),
              ['label']
            );

            //updating the limits as per the limits set by the user in units
            for (const parameter of fieldUnits[deviceType.deviceTypeId][
              param
            ]) {
              let param: string = parameter.key;
              let dtId: string = parameter.deviceTypeId;
              let limits = this.allUnits?.[dtId as any]?.[param as any];

              //setting the default limit
              if (parameter.c_factore === 1 && !parameter['default_limit']) {
                parameter['default_limit'] = parameter.limit;
              }

              //setting the limit set by user
              if (parameter.id == limits?.id && limits?.['limit']?.length) {
                parameter.limit = limits?.['limit'];
              } else {
                //setting the limit null where user has not set the limit
                parameter.limit = null;
              }
            }
          }
        });
      }
    });
    this.fieldUnits = fieldUnits;
  }

  getParams(): any {
    const allParams = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_PARAMS
    );
    const userDeviceTypes: DeviceType[] =
      this.commonService.getUserDeviceTypes();
    const allUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    if (!allParams) {
      return null;
    }

    const para: any = {};

    for (const type of userDeviceTypes) {
      const units = allUnits[type.deviceTypeId];

      if (!units) {
        continue; // Skip if units not found
      }

      para[type.deviceTypeId] = allParams[type.deviceTypeId]
        .filter((key: string) => units[key] && units[key].isVisible)
        .map((key: string) => ({
          key,
          keyLabel: this.getFieldName(key, type.deviceTypeId),
          checkedUnit: this.getUnitId(key, type.deviceTypeId),
          checkedUnitName: this.getUnitName(
            units,
            this.getUnitId(key, type.deviceTypeId)
          ),
          buttonLabel: this.getButtonLabel(key, type.deviceTypeId),
          buttonColor:
            this.getButtonLabel(key, type.deviceTypeId) === 'Add'
              ? 'primary'
              : '',
        }));
    }
    return para;
  }

  getFieldName(field: string, deviceTypeId: number): string {
    const units = this.commonService.getAllUnits(); // get all units
    //get fields according to the device type
    const fieldsOfDeviceType = this.deviceService.fetchFields(
      deviceTypeId,
      units,
      false
    );
    return DeviceUtil.getFieldName(field, fieldsOfDeviceType);
    // return DeviceUtil.getFieldName(field, this.deviceService.fields);
  }

  getUnitId(key: string, deviceTypeId: number): number {
    const allUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
    if (allUnits && allUnits[deviceTypeId] && allUnits[deviceTypeId][key]) {
      return allUnits[deviceTypeId][key].id;
    } else {
      return 0;
    }
  }

  //to find out the name of the unit which is selected for a particular gas
  getUnitName(units: Unit.Data[], checked: number): string {
    return Object.values(units).find((unit: Unit.Data) => unit.id === checked)!
      .label;
  }

  //to get the label for the button
  getButtonLabel(key: string, deviceTypeId: number): string {
    const allUnitOfDeviceType = this.allUnits[deviceTypeId];

    if (allUnitOfDeviceType) {
      const keyLimit = (allUnitOfDeviceType as any)[key]?.limit;

      if (keyLimit && keyLimit.length > 0) {
        return 'Edit';
      }
    }

    return 'Add';
  }

  openForm(data: any) {
    this.formsService.openForm(LimitFormComponent, data, 'sm');
  }

  addSubscriptions() {
    let unit = this.userService.unitsForAllParameters$.subscribe((res) => {
      if (res !== undefined) {
        this.allParamUnits = res;
        this.getFieldUnits();
        this.loadTable.next(true);
      }
    });

    let deviceType = this.userService.selectedDeviceTypeForUnits$.subscribe(
      (res) => {
        this.loadTable.next(false);
        this.selectedDevice = res;
        this.getAQIIndexColor();
        this.updateAndFormatLimits();
        this.selectedDeviceData = this.allData[this.selectedDevice];

        this.loadData();
      }
    );

    let unitsLimits = this.userService.isUnitOrLimitUpdated$.subscribe(
      (res) => {
        if (res) {
          this.loadTable.next(false);
          this.allUnits = this.commonService.getAllUnits();
          this.allData = this.getParams();
          this.getFieldUnits();
          this.selectedDeviceData = this.allData[this.selectedDevice];
          this.updateAndFormatLimits(this.selectedDevice);
          this.loadData();
        }
      }
    );

    this.subscriptions.push(deviceType);
    this.subscriptions.push(unit);
    this.subscriptions.push(unitsLimits);
  }

  getAQIIndexColor(deviceTypeId?: number) {
    if (!deviceTypeId) {
      deviceTypeId = this.selectedDevice;
    }
    let deviceTypes = this.commonService.getAllDeviceTypes();
    let allAqi = this.commonService.getAllAQI();
    let allAqis = this.commonService.getAllAQIs();
    let aqiIndex: AQIIndex = allAqis?.[deviceTypeId];

    //in case aqi Index is not available for device type
    if (!aqiIndex) {
      this.aqiIndexColor = {
        limits: [],
        color: [],
        outOfRangeColor: '',
        labels: [],
        outOfRangeMessage: '',
      };
    } else {
      this.aqiIndexColor = DeviceUtil.aqiColorArray(
        deviceTypes,
        allAqi,
        allAqis,
        aqiIndex.id,
        deviceTypeId
      );
    }
  }

  updateAndFormatLimits(deviceTypeId?: number) {
    if (!deviceTypeId) {
      deviceTypeId = this.selectedDevice;
    }
    let units = this.commonService.getAllUnits();

    this.limits = this.deviceService.fetchLimits(units[deviceTypeId], false);

    this.limits = Object.fromEntries([
      ...this.limits.map((limit: FieldLimit) => [limit.fkey, limit]),
    ]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
