<mat-card class="device-info-window">
  <div class="d-flex justify-content-between px-3 py-2 gap-2">
    <div class="device-info-window__header__label">{{ device?.label }}</div>
    <div class="d-flex">
      <mat-icon
        *ngIf="device?.status?.msg === 'Online'"
        class="device-info-window__header__icon material-symbols-outlined"
      >
        signal_cellular_alt
      </mat-icon>
      <mat-icon
        *ngIf="device?.status?.msg !== 'Online'"
        class="device-info-window__header__icon material-symbols-outlined"
      >
        signal_cellular_nodata
      </mat-icon>
      <mat-icon
        *ngIf="deviceData?.[0]?.payload?.d?.bs || device?.payload?.d?.bs"
        class="device-info-window__header__icon material-symbols-outlined"
      >
        {{ getBatteryIcon((deviceData?.[0]?.payload?.d?.bs ?? device?.payload?.d?.bs)!.toString()) }}
      </mat-icon>
      <span
        class="device-status-circle"
        [style.background]="device?.status?.color"
      ></span>
    </div>
  </div>
  <div class="d-flex flex-column device-info-window__body">
    <div class="device-info-window__body__text">
      <span>Device Id:</span>
      <span>{{ " " + device?.deviceId }}</span>
    </div>
    <div class="device-info-window__body__text">
      <span>Location:</span>
      <span>{{ " " + device?.loc }}</span>
    </div>
    <div
      *ngIf="device?.url && showImage"
      class="device-info-window__body__actual-image mt-2"
    >
      <img height="131" [src]="device?.url" />
    </div>
    <div
      *ngIf="!device?.url && showImage"
      class="device-info-window__body__image mt-2"
    >
      <img
        height="131"
        src="./../../../../../../../assets/images/device/default-device-image.jpg"
      />
    </div>
  </div>
  <div
    class="d-flex justify-content-between align-items-center device-info-window__footer"
  >
    <span class="pe-1">More Insights:</span>
    <div class="device-info-window__footer__more-actions">
      <button
        mat-icon-button
        (click)="onGoToButton('alerts')"
        matTooltip="Alerts"
      >
        <nb-icon
          class="w-auto h-auto"
          icon="alerts"
          pack="envizom-module"
        ></nb-icon>
      </button>
      <button
        mat-icon-button
        (click)="onGoToButton('analytics')"
        matTooltip="Analytics"
      >
        <nb-icon
          class="w-auto h-auto"
          icon="analytics"
          pack="envizom-module"
        ></nb-icon>
      </button>
      <button
        mat-icon-button
        (click)="onGoToButton('reports')"
        matTooltip="Reports"
      >
        <nb-icon
          class="w-auto h-auto"
          icon="reports"
          pack="envizom-module"
        ></nb-icon>
      </button>
    </div>
  </div>
</mat-card>
