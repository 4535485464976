<app-form-container
  [tableTitle]="isEditMode ? tableTitle : 'Add User'"
  (cancelled)="backBtn == 'Back' ? previous() : closeForm()"
  (close)="closeForm()"
  (submit)="submitAndNext()"
  [submitBtn]="submitBtn"
  [cancelBtn]="backBtn"
  [isValid]="isNextStep"
>
  <div>
    <ng-container *ngIf="isFormVisible">
      <mat-stepper
        [linear]="true"
        #stepper
        class="h-100 custom-stepper"
        (selectionChange)="onStepChange($event)"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
        <ng-container *ngIf="!isEditMode">
          <mat-step [stepControl]="userDetailForm">
            <div [formGroup]="userDetailForm" class="row py-2 gy-4">
              <ng-template matStepLabel>User Details</ng-template>
              <div class="col-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Email</mat-label>
                  <input
                    type="text"
                    [placeholder]="'Email'"
                    matInput
                    formControlName="email"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    userDetailForm.get('email')?.touched && !isEmailCorrect
                  "
                  >Enter a valid email id.</mat-error
                >
              </div>
              <div class="col-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Name</mat-label>
                  <input
                    type="text"
                    [placeholder]="'Name'"
                    matInput
                    formControlName="name"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    userDetailForm.get('name')?.touched && !isUserNameCorrect
                  "
                  >Enter a valid user name.</mat-error
                >
              </div>
              <div class="col-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Contact</mat-label>
                  <input
                    type="text"
                    [placeholder]="'Contact'"
                    matInput
                    formControlName="contact"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    userDetailForm.get('contact')?.touched &&
                    userDetailForm.get('contact')?.invalid
                  "
                >
                  Invalid Contact Number
                </mat-error>
              </div>
              <div class="col-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Password</mat-label>
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    [placeholder]="'Password'"
                    matInput
                    formControlName="password"
                    autocomplete="new-password"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    tabindex="-1"
                    (click)="togglePasswordVisibility()"
                    matTooltip="{{ showPassword ? 'View' : 'Hide' }}"
                  >
                    <mat-icon class="material-symbols-outlined">{{
                      showPassword ? "visibility" : "visibility_off"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    userDetailForm.controls['password'].touched &&
                    !isNewPasswordCorrect
                  "
                  >Password must contain at least one uppercase, lowercase,
                  special character and number</mat-error
                >
              </div>
              <div class="col-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Confirm Password</mat-label>
                  <input
                    type="password"
                    [placeholder]="'Confirm Password'"
                    matInput
                    formControlName="confirmPassword"
                    autocomplete="new-password"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    userDetailForm.controls['confirmPassword'].touched &&
                    !isConfirmPasswordCorrect
                  "
                  >Confirm Password does not match with New Password</mat-error
                >
              </div>
            </div>
          </mat-step>
        </ng-container>
        <mat-step
          [stepControl]="devicesForm"
          label="Select Devices"
          class="h-100"
        >
          <form [formGroup]="devicesForm">
            <div class="d-flex flex-column h-100 overflow-auto">
              <app-data-table
                [tableTitle]="''"
                [data]="devices"
                [columns]="defaultColumnsDevices"
                [displayedColumns]="displayedColumnsDevices"
                (onSelect)="onDeviceSelection()"
                [showTableHeader]="false"
                [showPaginator]="false"
                [selection]="deviceSelector"
                [isChartVisible]="false"
              >
              </app-data-table>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Select Modules</ng-template>
          <form [formGroup]="modulesForm">
            <div class="d-flex flex-column h-100 overflow-auto">
              <app-data-table
                [tableTitle]="''"
                [data]="modules"
                [columns]="defaultColumnsModules"
                [displayedColumns]="displayedColumnsModules"
                (onSelect)="onModuleSelection()"
                (dateSelected)="onDateSelection($event)"
                [showTableHeader]="false"
                [showPaginator]="false"
                [selection]="moduleSelector"
                [isChartVisible]="false"
              >
              </app-data-table>
            </div>
          </form>
        </mat-step>
      </mat-stepper>
    </ng-container>
  </div>
</app-form-container>
