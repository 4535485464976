<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-2 gy-2 gy-lg-0 mx-0"
    >
      <mat-form-field
        class="col-12 col-md-3 col-lg-3"
        *ngIf="isTableView() || isAQIView()"
      >
        <mat-label>Device Type</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Device Type'"
          matInput
          formControlName="deviceType"
          [matAutocomplete]="deviceType"
        />
        <mat-autocomplete
          #deviceType="matAutocomplete"
          [displayWith]="getDeviceType"
        >
          <mat-option
            *ngFor="
              let deviceType of deviceTypes
                | search : headerForm.get('deviceType')?.value : 'label'
            "
            [value]="deviceType"
          >
            {{ deviceType.label }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>

      <div class="col-12 col-md-10 device-form-field" *ngIf="isMapView()"
      [ngClass]="!deviceTags.length ? 'col-lg-3' : 'col-lg-5'">
        <app-tag-and-devicetype-dropwdown
          [tags]="deviceTags"
          [deviceTypes]="deviceTypes"
          [devices]="devices"
          [patchDevice]="deviceForMap"
          infoMessage="Select a device to view parameter details."
          (selectedDevice)="onDeviceSelectionInMap($event)"
          (filteredDeviceList)="onTagSelection($event)"
        >
        </app-tag-and-devicetype-dropwdown>
      </div>
      <mat-form-field
        class="col-12 col-md-3 col-lg-2"
        appearance="outline"
        *ngIf="isAQIView()"
      >
        <mat-label>Select Date</mat-label>
        <input
          matInput
          [max]="maxDate"
          formControlName="startDate"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
          tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        class="col-12 col-md-2 col-lg-2"
        appearance="outline"
        *ngIf="isAQIView()"
      >
        <mat-label>Select Time</mat-label>
        <div class="d-flex">
          <input
            [ngxTimepicker]="toggleTimepicker"
            [disableClick]="true"
            readonly
            matInput
            formControlName="selectedTime"
            [format]="usersTimeFormat"
            [max]="maxTime"
          />
          <ngx-material-timepicker
            #toggleTimepicker
            [theme]="clockTheme"
            [minutesGap]="30"
          ></ngx-material-timepicker>
          <mat-icon (click)="toggleTimepicker.open()">schedule</mat-icon>
        </div>
      </mat-form-field>
      <div class="col-12 col-lg-2 apply" *ngIf="isAQIView()">
        <button
          matTooltip="Apply"
          (click)="onApply()"
          mat-raised-button
          color="primary"
          class="w-100 w-lg-auto"
        >
          Apply
        </button>
      </div>
      <div
        class="col-12 col-lg d-flex gx-2 gy-2 gy-lg-0"
        [ngClass]="
          isAQIView()
            ? 'justify-content-lg-end col-lg-9'
            : 'justify-content-lg-end align-items-lg-end'
        "
        *ngIf="isMapView()"
      >
        <button
          class="refresh"
          mat-mini-fab
          color="primary"
          (click)="onRefresh()"
          matTooltip="Refresh"
        >
          <mat-icon matSuffix class="material-symbols-outlined">loop</mat-icon>
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
