import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AlertConstant } from 'src/app/shared/constants/alert-constants';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AlertData } from 'src/app/shared/models/alerts/alert-data';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import {
  DrawerSize,
  FormsService,
} from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AlertConfigurationFormComponent } from '../../components/alert-configuration-form/alert-configuration-form.component';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss'],
})
export class ConfigurationListComponent implements OnInit {
  @Input() addConfigFunc!: () => void;
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = [];
  public alertsList!: AlertData.Get[];
  public filteredAlertList!: AlertData.Get[];
  public subscriptions: Subscription[] = [];
  public dialogCompInstance!: ConfirmationPopupComponent;
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public hourForDisplay = AlertConstant.hourForDisplay;

  constructor(
    private alertsService: AlertsService,
    private formsService: FormsService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService
  ) {}

  defaultColumns: any[] = [
    {
      columnDef: 'actions',
      header: '',
      cell: (element: AlertData.Get) => `${element.alertId}`,
      parameter: false,
      selected: false,
      icon: 'more_vert',
      options: [
        {
          label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
          action: (element: AlertData.Get) => this.editAlert(element),
        },
        {
          label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
          action: (element: AlertData.Get) => this.openDialog(element),
        },
        {
          label: (element: AlertData.Get) => {
            return `<span class="material-symbols-outlined">${
              element.enable ? 'disabled_by_default' : 'check_box'
            }</span>&nbsp
          ${element.enable ? 'Disable' : 'Enable'}`;
          },
          action: (element: AlertData.Get) => this.changeAlertStatus(element),
        },
      ],
    },
    {
      columnDef: 'configNameDesc',
      header: 'Config Name',
      cell: (element: AlertData.Get) => `${element.label}`,
      isConfigDesc: (element: AlertData.Get) => `${element.description}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'devices',
      header: 'Device Name',
      cell: (element: AlertData.Get) => {
        return element?.deviceIds?.toString().split(',') || [];
      },
      parameter: false,
      selected: false,
      columnType: 'chip',
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: AlertData.Get) => {
        return element?.email !== null ? `${element?.email}`.split(',') : [];
      },
      parameter: false,
      selected: false,
      columnType: 'chip',
    },
    {
      columnDef: 'sms',
      header: 'SMS',
      cell: (element: AlertData.Get) => {
        return element?.phone?.split(',') || [];
      },
      parameter: false,
      selected: false,
      columnType: 'chip',
    },
    {
      columnDef: 'timeInterval',
      header: 'Time Interval',
      cell: (element: AlertData.Get) =>
        this.hourForDisplay[
          element?.period as keyof typeof this.hourForDisplay
        ],
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'alertStatus',
      header: 'Status',
      cell: (element: AlertData.Get) => {
        return element?.enable ? 'Enabled' : 'Disabled';
      },
      color: (element: AlertData.Get) => {
        return element?.enable ? '#008000' : '#FF0000';
      },
      parameter: false,
      selected: false,
    },
  ];

  public noData: ContentUnavailable = {
    isConfiguration: true,
    majorText: 'Start with your first Alert Configuration.',
    linkText: 'Configure Alert',
    minorText: 'and Proceed',
    createFunc: () => this.addConfigFunc(),
    svgImage: AppConstants.EMPTY_ANALYTICS_LIST,
  };

  ngOnInit(): void {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.loadTable.next(false);
    this.alertsService.getAlertList().subscribe({
      next: (res) => {
        if (res) this.alertsService.isResponse.next(true);
      },
    });
    const alertList = this.alertsService.alertsList$.subscribe((list) => {
      if (list?.length! > 0) {
        this.loadTable.next(false);
        this.filteredAlertList = list as AlertData.Get[];
        this.loadData();
      } else if (list?.length === 0) {
        this.filteredAlertList = [];
        this.loadTable.next(true);
        console.info('NO DATA FOUND IN ALERT LIST');
      } else {
        this.loadTable.next(false);
        console.info('ISSUE IN ALERT LIST COMPONENT');
      }
    });

    this.subscriptions.push(alertList);

    const isResponse = this.alertsService.isResponse$.subscribe((res) => {
      if (res) {
        this.loadTable.next(false);
        this.alertsList = this.alertsService.allAlerts;
        this.loadData();
      }
    });

    this.subscriptions.push(isResponse);
  }

  changeAlertStatus(data: AlertData.Get) {
    this.loadTable.next(false);

    let status: boolean;
    status = !data.enable;
    let payload!: AlertData.Patch;
    payload = {
      update: {
        enable: status,
      },
    };
    this.alertsService.updateAlert(payload, data.alertId).subscribe({
      next: (res) => {
        if (res) {
          this.alertsService.getAlertList().subscribe({
            next: (res) => {
              if (res) this.alertsService.isResponse.next(true);
            },
          });
          this.notificationService.showSnackBar(
            'Alert status updated successfully!',
            'success'
          );
        }
      },
      error: (err) => {
        this.notificationService.showSnackBar(
          'Error while updating alert status!',
          'error'
        );
        console.info('Error:', err);
      },
    });
  }

  loadData(): void {
    setTimeout(() => {
      this.loadTable.next(true);
    }, 2000);
  }

  editAlert(device: any) {
    let formSize: DrawerSize = 'lg';
    if (window.innerWidth > 1700) {
      formSize = 'md';
    } else if (window.innerWidth > 1165) {
      formSize = 'lg';
    } else {
      formSize = 'xl';
    }
    this.formsService.openForm(
      AlertConfigurationFormComponent,
      device,
      formSize
    );
  }

  deleteAlert(data: AlertData.Get) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.alertsService.deleteAlert(data.alertId).subscribe({
      next: (res) => {
        if (res) {
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Alert Deleted Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.alertsService.getAlertList().subscribe({
              next: (res) => {
                if (res) this.alertsService.isResponse.next(true);
              },
            });
          }, AppConstants.POPUP_TIMEOUT);
          this.loadData();
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Deleting Alert. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  openDialog(data: AlertData.Get, isMigration: boolean = false): void {
    const dynamicData = {
      title: 'Delete Alert',
      message: 'Are you sure you want to delete this Alert?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteAlert(data);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  ngOnDestroy(): void {
    this.alertsService.alertsList.next(null);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
