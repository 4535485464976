import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, map } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { LoginData } from '../models/login-data';
import { CookieService } from './cookie.service';
import { NotificationService } from './notification.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  filteredAqiList: any;
  filteredModuleExpiryList: any;

  private localStorageChange: Subject<{ key: string; value: any }>;
  public localStorageChange$: Observable<{ key: string; value: any }>;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private notificationService: NotificationService
  ) {
    this.localStorageChange = new Subject();
    this.localStorageChange$ = this.localStorageChange.asObservable();
  }

  getValue(key: string): any {
    return localStorage.getItem(key);
  }

  getParsedValue(key: string): any {
    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key)!)
      : undefined;
  }

  getDefaultConfig(): Observable<any> {
    return this.http.get(APIConstants.CONFIG_PATH);
  }

  getDefaultModuleAccessConfig(): Observable<any> {
    return this.http.get(APIConstants.MODULE_ACCESS_CONFIG_PATH);
  }

  saveValue(key: string, value: any): any {
    let result = localStorage.setItem(key, value);
    this.localStorageChange.next({ key, value });
    return result;
  }

  setupDefaultConfig(): Observable<boolean> {
    const defaultConfig: any = environment.config;

    if (this.getValue(LocalStorageConstants.OZ_DATABASE)) {
      if (
        this.getValue(LocalStorageConstants.OZ_DATABASE) !== defaultConfig.DBURL
      ) {
        this.saveValue(LocalStorageConstants.OZ_DATABASE, defaultConfig.DBURL);
      }
    } else {
      this.saveValue(LocalStorageConstants.OZ_DATABASE, defaultConfig.DBURL);
    }

    this.saveValue(LocalStorageConstants.OZ_DATABASE, defaultConfig.DBURL);
    this.saveValue(
      LocalStorageConstants.OZ_DATABASE_VERSION,
      defaultConfig.DBURL_VERSION
    );
    this.saveValue(LocalStorageConstants.OZ_SOCKET_URL, defaultConfig.WSURL);
    this.saveValue(
      LocalStorageConstants.OZ_LOCAL_DEPLOYMENT,
      defaultConfig.LOCAL
    );
    this.saveValue(LocalStorageConstants.OZ_ORG_URL, defaultConfig.ORG_URL);
    this.saveValue(LocalStorageConstants.OZ_LOGO_URL, defaultConfig.LOGO_URL);

    if (defaultConfig.LOGO_URL_WEB !== undefined) {
      this.saveValue(
        LocalStorageConstants.LOGO_URL_WEB,
        defaultConfig.LOGO_URL_WEB
      );
    }

    if (defaultConfig.WEB_URL !== undefined) {
      this.saveValue(LocalStorageConstants.WEB_URL, defaultConfig.WEB_URL);
    }

    if (defaultConfig.GUIDE_URL !== undefined) {
      this.saveValue(LocalStorageConstants.GUIDE_URL, defaultConfig.GUIDE_URL);
    }

    if (defaultConfig.APP_TITLE !== undefined) {
      this.saveValue(LocalStorageConstants.APP_TITLE, defaultConfig.APP_TITLE);
    }

    if (defaultConfig.WMS_URL !== undefined) {
      this.saveValue(LocalStorageConstants.WMS_URL, defaultConfig.WMS_URL);
    }

    if (defaultConfig.SUPPORT_URL !== undefined) {
      this.saveValue(
        LocalStorageConstants.OZ_SUPPORT_URL,
        defaultConfig.SUPPORT_URL
      );
    } else {
      this.saveValue(LocalStorageConstants.OZ_SUPPORT_URL, '');
    }

    if (defaultConfig.OZ_RESTRICTED_USER !== undefined) {
      this.saveValue(
        LocalStorageConstants.OZ_RESTRICTED_USER,
        JSON.stringify(defaultConfig.OZ_RESTRICTED_USER)
      );
    } else {
      this.saveValue(LocalStorageConstants.OZ_RESTRICTED_USER, '[]');
    }

    if (this.cookieService.getCookie(AppConstants.APIS)) {
      if (
        this.cookieService.getCookie(AppConstants.APIS) !==
        defaultConfig[AppConstants.APIS]
      ) {
        this.cookieService.setCookie(
          AppConstants.APIS,
          defaultConfig[AppConstants.APIS],
          100
        );
        this.cookieService.setCookie(
          AppConstants.WEB_SOCKET,
          defaultConfig[AppConstants.WEB_SOCKET],
          100
        );
      }
    } else {
      this.cookieService.setCookie(
        AppConstants.APIS,
        defaultConfig[AppConstants.APIS],
        100
      );
      this.cookieService.setCookie(
        AppConstants.WEB_SOCKET,
        defaultConfig[AppConstants.WEB_SOCKET],
        100
      );
    }

    if (defaultConfig.MURL) {
      this.cookieService.setCookie(AppConstants.M_URL, defaultConfig.MURL, 100);
    }

    if (defaultConfig[AppConstants.LOGO_URL] !== undefined) {
      this.cookieService.setCookie(
        AppConstants.LOGO_URL,
        defaultConfig[AppConstants.LOGO_URL],
        100
      );
    }

    if (defaultConfig.THEME) {
      this.saveValue(LocalStorageConstants.OZ_THEME, defaultConfig.THEME);
    }

    if (defaultConfig.ULTRA_ADMIN) {
      this.saveValue(
        LocalStorageConstants.OZ_ULTRA_ADMIN,
        JSON.stringify(defaultConfig.ULTRA_ADMIN)
      );
    } else {
      this.saveValue(LocalStorageConstants.OZ_ULTRA_ADMIN, '[]');
    }

    if (defaultConfig[AppConstants.ORG_URL] !== undefined) {
      this.cookieService.setCookie(
        AppConstants.ORG_URL,
        defaultConfig[AppConstants.ORG_URL],
        100
      );
    }

    if (defaultConfig[AppConstants.FAVICON] !== undefined) {
      this.saveValue(
        LocalStorageConstants.FAVICON,
        defaultConfig[AppConstants.FAVICON]
      );
    } else {
      this.saveValue(
        LocalStorageConstants.FAVICON,
        defaultConfig[AppConstants.FAVICON]
      );
    }

    if (defaultConfig[AppConstants.PARTICLEAPI] !== undefined) {
      this.saveValue(
        LocalStorageConstants.PARTICLEAPI,
        defaultConfig[AppConstants.PARTICLEAPI]
      );
    }

    if (defaultConfig.MODULE_ACCESS) {
      this.saveValue(
        LocalStorageConstants.MODULE_ACCESS,
        JSON.stringify(defaultConfig.MODULE_ACCESS)
      );
    } else {
      this.saveValue(LocalStorageConstants.MODULE_ACCESS, '[]');
    }

    if (defaultConfig.XUSERS) {
      this.saveValue(
        LocalStorageConstants.XUSERS,
        JSON.stringify(defaultConfig.XUSERS)
      );
    } else {
      this.saveValue(LocalStorageConstants.XUSERS, '[]');
    }

    if (defaultConfig.ENCRYPTION_KEY) {
      this.saveValue(
        LocalStorageConstants.ENCRYPTION_KEY,
        defaultConfig.ENCRYPTION_KEY
      );
    }

    if (defaultConfig.TOOLBAR_ACCESS) {
      this.saveValue(
        LocalStorageConstants.TOOLBAR,
        JSON.stringify(defaultConfig.TOOLBAR_ACCESS)
      );
    } else {
      this.saveValue(LocalStorageConstants.TOOLBAR, '[]');
    }

    if (defaultConfig.CAPTCHASITEKEY) {
      this.saveValue(
        LocalStorageConstants.CAPTCHASITEKEY,
        defaultConfig.CAPTCHASITEKEY
      );
    } else {
      this.saveValue(
        LocalStorageConstants.CAPTCHASITEKEY,
        '6LcalsIZAAAAABYIZoNzG9G-pjCmR3oap7cR_Qsy'
      );
    }

    if (defaultConfig.SHOWCAPTCHA) {
      this.saveValue(
        LocalStorageConstants.SHOWCAPTCHA,
        defaultConfig.SHOWCAPTCHA
      );
    } else {
      this.saveValue(LocalStorageConstants.SHOWCAPTCHA, 'false');
    }

    if (defaultConfig.ISSECURE) {
      this.saveValue(LocalStorageConstants.ISSECURE, defaultConfig.ISSECURE);
    } else {
      this.saveValue(LocalStorageConstants.ISSECURE, 'false');
    }

    if (defaultConfig.IS_KYIV) {
      this.saveValue(LocalStorageConstants.IS_KYIV, defaultConfig.IS_KYIV);
    }

    if (defaultConfig.MAP_LAYER) {
      this.saveValue(
        LocalStorageConstants.MAP_LAYER,
        JSON.stringify(defaultConfig.MAP_LAYER)
      );
    } else {
      this.saveValue(LocalStorageConstants.MAP_LAYER, '[]');
    }

    if (defaultConfig.POPUP_TYPES) {
      this.saveValue(
        LocalStorageConstants.POPUP_TYPES,
        JSON.stringify(defaultConfig.POPUP_TYPES)
      );
    } else {
      this.saveValue(LocalStorageConstants.POPUP_TYPES, '[]');
    }

    if (defaultConfig.IS_MULTI_LANG) {
      this.saveValue(
        LocalStorageConstants.OZ_MULTI_LANG,
        JSON.stringify(defaultConfig.IS_MULTI_LANG)
      );
    } else {
      this.saveValue(LocalStorageConstants.OZ_MULTI_LANG, 'false');
    }

    if (defaultConfig.GA_MEASURE_ID) {
      this.saveValue(
        LocalStorageConstants.OZ_GA_MEASURE_ID,
        JSON.parse(JSON.stringify(defaultConfig.GA_MEASURE_ID))
      );
    } else {
      this.saveValue(
        LocalStorageConstants.OZ_GA_MEASURE_ID,
        JSON.parse(JSON.stringify('G-QK7SKFJWLT'))
      );
    }

    if (defaultConfig.IS_LITE) {
      this.saveValue(
        LocalStorageConstants.OZ_LITE,
        JSON.stringify(defaultConfig.IS_LITE)
      );
    } else {
      this.saveValue(LocalStorageConstants.OZ_LITE, 'false');
    }

    return this.getDefaultModuleAccessConfig().pipe(
      map((data) => {
        const defaultModuleAccessConfig = data;

        this.saveValue(
          LocalStorageConstants.OZ_MODULES,
          JSON.stringify(defaultModuleAccessConfig)
        );

        return true;
      })
    );
  }

  setupCredentials(response: LoginData) {
    this.saveValue(LocalStorageConstants.USER_ID, response.userId);
    this.saveValue(LocalStorageConstants.TOKEN, response.token?.access_token);
    this.saveValue(LocalStorageConstants.CLIENT_ID, response.clientId);
    this.saveValue(
      LocalStorageConstants.SIDE_NAV_LOGO,
      response.organization &&
        response.organization.info &&
        response.organization.info.logo_url
        ? response.organization.info.logo_url
        : this.getValue(LocalStorageConstants.OZ_LOGO_URL)
    );
    this.saveValue(
      LocalStorageConstants.ORG_NAME,
      response.organization && response.organization.orgName
        ? response.organization.orgName
        : AppConstants.ENVIZOM
    );
    this.saveValue(
      LocalStorageConstants.ORG_TIME_DIFF,
      response.settings.timediff &&
        response.settings.timediff.offset !== undefined
        ? response.settings.timediff.offset
        : 19800
    );
    this.saveValue(
      LocalStorageConstants.ORG_TIMEZONE,
      response.settings.timediff && response.settings.timediff.utc
        ? response.settings.timediff.utc[0]
        : null
    );
    this.saveValue(LocalStorageConstants.OZ_PAYMENT_DUE, response.expireOn);
    this.saveValue(LocalStorageConstants.OZ_SHARE_TOKEN, response.share_token);

    for (let type of response.deviceType) {
      if (!type.widgets) {
        type.widgets = AppConstants.OZ_WIDGET_IDS.get(type.deviceTypeId) || [];
      } else {
        if (
          response.settings &&
          response.settings.widgets &&
          response.settings.widgets.length > 0
        ) {
          type.widgets = type.widgets.filter((widget) =>
            response.settings.widgets.includes(widget)
          );
        }
      }

      if (!type.icon) {
        if (AppConstants.OZ_MAP_PIN.get(type.deviceTypeId)) {
          type.icon = AppConstants.OZ_MAP_PIN.get(type.deviceTypeId);
        } else {
          type.icon = AppConstants.DEFAULT_DEVICE_ICON;
        }
      }
    }

    this.saveValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE,
      JSON.stringify(response.deviceType)
    );
    this.saveValue(
      LocalStorageConstants.OZ_ALL_UNITS,
      JSON.stringify(response.units)
    );

    let moduleArr = [];
    if (!this.getParsedValue(LocalStorageConstants.MODULE_ACCESS)) {
      this.notificationService.showNotification(
        AppConstants.PLEASE_TRY_AGAIN_MESSAGE,
        AppConstants.CLOSE,
        'bottom',
        'center',
        'error'
      );
      setTimeout(() => {
        localStorage.clear();
        document.location.reload();
      }, 3000);
    } else {
      moduleArr = this.getParsedValue(LocalStorageConstants.MODULE_ACCESS);
    }

    if (moduleArr.indexOf(2001) >= 0) {
      if (response.units) {
        let finalUnitList: any = {};
        let finalFieldList: any = [];
        for (const deviceTypeId in response.units) {
          if (response.units.hasOwnProperty(deviceTypeId)) {
            for (const unitKey in response.units[deviceTypeId]) {
              if (response.units[deviceTypeId]?.hasOwnProperty(unitKey)) {
                if (!finalUnitList.hasOwnProperty(unitKey)) {
                  finalUnitList[unitKey] =
                    response.units[deviceTypeId][unitKey];
                  finalFieldList.push({
                    fkey: response.units[deviceTypeId][unitKey]?.key,
                    label: response.units[deviceTypeId][unitKey]?.flabel,
                    unit: response.units[deviceTypeId][unitKey]?.label,
                    c_factore: response.units[deviceTypeId][unitKey]?.c_factore,
                    isVisible: response.units[deviceTypeId][unitKey]?.isVisible,
                  });
                }
              }
            }
          }
        }
        this.saveValue(
          LocalStorageConstants.OZ_FIELDS,
          JSON.stringify(finalFieldList)
        );
      }
    }
    if (response.aqiIndex) {
      response.aqiIndex = (response.aqiIndex as any).reduce(
        (item: any, value: any) => {
          item[value.deviceTypeId] = {
            id: value.aqiIndexId,
            breakpoints: value.breakpoints,
            created_on: value.created_on,
            deviceTypeId: value.deviceTypeId,
            index: value.index,
            label: value.label,
            name: value.name,
            updated_on: value.updated_on,
          };
          return item;
        },
        {}
      );
      this.saveValue(
        LocalStorageConstants.OZ_ALL_AQIS,
        JSON.stringify(response.aqiIndex)
      );
    }
    if (response.settings && response.settings.cityAqi) {
      this.saveValue(
        LocalStorageConstants.OZ_AVG_VIEW,
        response.settings.cityAqi
      );
    } else {
      this.saveValue(LocalStorageConstants.OZ_AVG_VIEW, 'false');
    }
    if (response.token?.access_token) {
      this.saveValue(
        LocalStorageConstants.TOKEN_EXPIRES_IN,
        response.token.expires_in
      );
    }

    const user = { ...response };
    delete user.apicounter;
    delete user.token;
    delete user.clientId;
    delete user.expireOn;
    delete user.share_token;

    if (user?.organization?.info) {
      user.info = user?.organization?.info;
    }

    const updatedUser = { ...user };
    updatedUser.moduleExpiry = (updatedUser.moduleExpiry as any).reduce(
      (item: any, value: any) => {
        item[value.moduleId] = value.expiry_date;
        return item;
      },
      {}
    );
    this.saveValue(LocalStorageConstants.OZ_USER, JSON.stringify(updatedUser));
  }
}
