import { CommonUtil } from '../../utils/common-utils';

export interface Heatmap {
  heatmapId: string;
  userId: string;
  boundary: [
    {
      lat: number;
      lng: number;
    }
  ];
  geojson?: any;
  devices: string[];
  label:any;
  keyColorLimits: {
    [key: string]: {
      color: string;
      limit: number;
    };
  };
  options: {
    [key: string]: string;
  };
  deviceTypeId: any;
  created_on: number;
  updated_on: number;
}

export type HeatmapData = {
  t: number;
  ctx: Array<{
    deviceId: string;
    ws: number;
    wd: number;
    lat: number;
    lon: number;
  }>;
} & {
  [key: string]: string;
};

export class HeatmapAdapter {
  static adapt(data: any): Heatmap {
    let boundary = data.boundary.map((b: any) => ({ lat: b.lat, lng: b.lon }));
    return {
      heatmapId: data.heatmapId,
      userId: data.userId,
      boundary,
      geojson: CommonUtil.createGeoJSONPolygon([{ bounds: boundary }]),
      devices: data.devices,
      label: data.label,
      keyColorLimits: data.keyColorLimits,
      options: data.options,
      deviceTypeId:data.deviceTypeId,
      created_on: data.created_on,
      updated_on: data.updated_on,
    };
  }
}
