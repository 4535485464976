import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceField } from '../models/device/device-field';
import { SocketConnectionOptions } from '../models/socket-connection-options';
import { CommonUtil } from '../utils/common-utils';
import { DeviceUtil } from '../utils/device-utils';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';
import { NotificationService } from './notification.service';

type Socket = typeof Socket;

@Injectable({
  providedIn: 'root',
})
export class MqttSocketService {
  socketConnectionOptions: SocketConnectionOptions = {
    query: {
      token: '',
      devices: [],
    },
    forceNew: true,
  };

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private notificationService: NotificationService
  ) {}

  init(incomingSocket?: Socket, devices?: Array<DeviceDetails>): void {
    if (!incomingSocket) {
      this.socketConnectionOptions.query.token =
        this.localStorageService.getValue(LocalStorageConstants.TOKEN);
      this.socketConnectionOptions.query.devices = [];

      if (devices === undefined) {
        return;
      }

      this.socketConnectionOptions.query.devices.push(
        ...new Set(devices.map((device) => device.deviceId))
      );
    } else if (incomingSocket.connected) {
      console.info('Socket Connected!');
    }
  }

  validateAndUpdateExistingSocketDevices(devices?: Array<DeviceDetails>): void {
    if (
      CommonUtil.isStringArrayEqual(
        this.socketConnectionOptions.query.devices,
        devices!.map((device) => device.deviceId)
      ) === false
    ) {
      this.socketConnectionOptions.query.devices.push(
        ...new Set(devices!.map((device) => device.deviceId))
      );
    }
  }

  connect(
    incomingSocket: Socket,
    devices: Array<DeviceDetails>,
    showRawAQIData: boolean,
    mqttDocs: any,
    fields: DeviceField[],
    mQTTDocsSnapshot: Subject<DeviceDetails>,
    newNotificationSubject: Subject<any>
  ) {
    incomingSocket = io.connect(
      this.cookieService.getCookie(AppConstants.WEB_SOCKET),
      this.socketConnectionOptions
    );

    incomingSocket.on('auth', (auth: string) => {
      incomingSocket.disconnect();
    });

    incomingSocket.on('error', (error: string) => {
      console.info(error);
    });

    const userId: number = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;

    incomingSocket.on('connect', () => {
      devices.forEach((device) =>
        incomingSocket.emit(
          'subscribe',
          device.mfgId ? device.mfgId : device.deviceId
        )
      );
      //subscribe to device notifications
      incomingSocket.emit('subscribe_notification_v2', userId);
    });

    incomingSocket.on('docs', (doc: DeviceDetails) => {
      const device = devices.find((device) => device.mfgId === doc.deviceId);
      doc.deviceId = device?.deviceId!;
      doc.time = doc.payload.d.t;

      const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
      const allAqi: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      );
      const allAqis: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQIS
      );
      const aqi: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_AQI
      );

      try {
        if (DeviceUtil.hasValidIndexForDeviceType(doc, deviceTypes)) {
          const aqiData = DeviceUtil.calculateAQI(
            doc.payload,
            DeviceUtil.getBreakpoints(
              undefined,
              doc.deviceType,
              deviceTypes,
              allAqi,
              allAqis,
              aqi
            )
          );
          if (doc.payload !== undefined) {
            doc.aqi = aqiData.aqi;
            doc.aqiKey = aqiData.aqiKey;
            if (
              aqiData.aqi !== null &&
              aqiData.aqi !== undefined &&
              showRawAQIData
            ) {
              doc.payload.d.aqi = aqiData.aqi;
            }
          }
        }
        DeviceUtil.convertUnits(
          doc.payload,
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            .units[DeviceUtil.getDeviceTypeId(doc.deviceType, deviceTypes)!]
        );
        mqttDocs[doc.deviceId] = doc;
      } catch (e) {
        console.info('Error while parsing: ', e);
      }

      mQTTDocsSnapshot.next(doc);
    });

    incomingSocket.on('notification_v2', (notification: any) => {
      if (notification.type > -1) {
        newNotificationSubject.next(JSON.parse(JSON.stringify(notification)));
        const deviceTypes: DeviceType[] =
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_DEV_TYPE
          );

        const allUnits = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        ).units;

        let deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
          deviceTypes,
          devices,
          notification.deviceId
        );
        if (deviceTypeId) {
          fields = DeviceUtil.getFieldsByDeviceType(
            deviceTypeId,
            allUnits,
            deviceTypes
          );
        }
        notification.isVisited = false;
        notification.label = DeviceUtil.getDeviceLabel(
          devices,
          notification.deviceId
        );
        notification.keyLabel = DeviceUtil.getFieldName(
          notification.key,
          fields
        );
        notification.unit = DeviceUtil.getFieldUnit(notification.key, fields);
        notification.value = DeviceUtil.getCFactoreData(
          notification.key,
          notification.value,
          fields
        );
        notification.timestamp = CommonUtil.getDisplayTime(notification.t);
        const subMessage: string =
          notification.operation === '>='
            ? AppConstants.ALERT_GREATER_THAN_EQUAL_TO
            : AppConstants.ALERT_LESS_THAN;

        const data: Array<any> = [];
        data.push({
          title: notification.label,
          alertContent: `${notification.keyLabel} ${subMessage} ${notification.value} ${notification.unit}`,
        });
        this.notificationService.generateBrowserNotifications(data);

        const message = `${notification.label} ${notification.keyLabel} ${subMessage} ${notification.value} ${notification.timestamp}`;

        this.notificationService.showNotification(
          message,
          'Close',
          'bottom',
          'right',
          'warning',
          5000
        );

        this.notificationService.notifications = [
          notification,
          ...this.notificationService.notifications,
        ];
        this.notificationService.userNotifications.next(true);
      }
    });
  }
  disconnect(incomingSocket: Socket) {
    if (incomingSocket) {
      incomingSocket.disconnect();
    }
  }
}
