<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-2 gy-2 gy-lg-0 mx-0"
    >
      <div class="col-12 col-md-10 d-flex gap-2 align-items-center">
        <!-- <mat-form-field class="col-lg-3">
          <mat-label>Device</mat-label>
          <input
            type="text"
            [placeholder]="'Devices'"
            matInput
            formControlName="deviceSearch"
            [matAutocomplete]="deviceName"
          />
          <mat-autocomplete
            #deviceName="matAutocomplete"
            [displayWith]="getDeviceName"
          >
            <mat-optgroup
              *ngFor="let deviceType of deviceTypes"
              [label]="deviceType.label"
            >
              <mat-option
                *ngFor="
                  let device of devices
                    | deviceType : deviceType.key
                    | search : headerForm.get('deviceSearch')?.value
                "
                [value]="device"
              >
                {{ device.label }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <button mat-icon-button matSuffix tabindex="-1">
            <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
          </button>
        </mat-form-field> -->
        
        <div
        class="col-12 col-md-10 device-form-field" [ngClass]="!deviceTags.length ? 'col-lg-3' : 'col-lg-5'"
        >
          <app-tag-and-devicetype-dropwdown
            [tags]="deviceTags"
            [deviceTypes]="deviceTypes"
            [devices]="devices"
            [patchDevice]="currentlySelectedDevice"
            [showButton]="false"
            (selectedDevice)="onDeviceSelectionInVibration($event)"
            (filteredDeviceList)="onTagSelection($event)"
          >
          </app-tag-and-devicetype-dropwdown>
        </div>

        <mat-form-field class="col-12 col-md-3 col-lg-2" style="margin-left: 8px;" appearance="outline">
          <mat-label>Select Date</mat-label>
          <input
            matInput
            [min]="minDate"
            [max]="maxDate"
            formControlName="startDate"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
            tabindex="-1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="col col-lg-auto apply">
          <button
            class="w-100 w-lg-auto"
            mat-raised-button
            color="primary"
            matTooltip="Apply"
            (click)="onApply()"
          >
            Apply
          </button>
        </div>
        <div class="col col-lg-auto apply">
          <button *ngIf="getVibrationModuleOptions('download')"
            class="w-100 w-lg-auto"
            mat-raised-button
            color="primary"
            matTooltip="Download Data"
            (click)="exportTableDataToCSV()"
          >
            <mat-icon class="material-symbols-outlined m-0">download</mat-icon>
          </button>
        </div>
      </div>
      </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
