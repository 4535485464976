import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { saveAs } from 'file-saver';
import * as moment from 'moment-timezone';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ReportData } from 'src/app/shared/models/reports/report-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { Cluster } from 'src/app/shared/models/cluster.ts/cluster';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
export interface EmailList {
  name: string;
}
@Component({
  selector: 'app-cluster-report',
  templateUrl: './cluster-report.component.html',
  styleUrls: ['./cluster-report.component.scss'],
})
export class ClusterReportComponent {
  public deviceTypes!: Array<DeviceType>;
  public quickForm!: FormGroup;
  public selectedReportType: string = '';
  public countEmail: number = 1;
  public devices: DeviceDetails[] = [];
  public isQuick: boolean = true;
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public emails: EmailList[] = [{ name: this.currentUserEmail }];
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public isEmailValid: boolean = true;
  public buttonValue: string = '';
  public isEditMode: boolean = false;
  public isNextStep: boolean = false;

  constructor(
    private commonService: CommonService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private reportsService: ReportsService,
    private loadrService: LoadrService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    @Inject(MTX_DRAWER_DATA) public data: Cluster | undefined
  ) {
    this.minDate = this.customMomentService
      .moment()
      .subtract(20, 'years')
      .startOf('year');
    this.maxDate = this.customMomentService
      .moment()
      .startOf('day')
      .subtract(1, 'day');
  }

  ngOnInit() {
    this.isEditMode = !!this.data;
    this.buildForm();
    if (this.isQuick === true) {
      this.isQuick = true;
      this.buttonValue = this.quickForm.value.receivingStyle === 'downloadpdf' ? 'Generate Report' : 'Email Report';
      this.quickForm.patchValue({
        reportType: 'quick',
      });
      this.clearEmailArray();
    }

    this.quickForm.get('receivingStyle')?.valueChanges.subscribe((res) => {
      if (res === 'downloadpdf') {
        this.quickForm.removeControl('emailId');
      } else if (res === 'emailpdf') {
        this.quickForm.addControl('emailId', this.formBuilder.array([]));
      }
      this.buttonValue = this.quickForm.value.receivingStyle === 'downloadpdf' ? 'Generate Report' : 'Email Report';

    });

    this.quickForm.get('duration')?.valueChanges.subscribe((res) => {
      let response = null;
      for (let i = 0; i < res.length; i++) {
        if (res[i] === true) {
          let date = this.customMomentService.moment().startOf('day');
          this.maxDate = date;
          response = i;
          switch (response) {
            case 0:
              this.maxDate.subtract(1, 'day');
              break;
            case 1:
              this.maxDate.subtract(7, 'days');
              break;
            case 2:
              this.maxDate.subtract(30, 'days');
              break;
          }
        }
      }
    });

    this.quickForm.valueChanges.subscribe((res) => {
      if (this.isQuick) {
        this.isNextStep = this.quickForm.valid;
      }
    });
  }

  buildForm() {
    this.devices = this.deviceService.registeredDevices || [];
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.quickForm = this.formBuilder.group({
      reportType: ['quick'],
      dateSelected: ['', Validators.required],
      receivingStyle: ['downloadpdf'],
      duration: this.formBuilder.array([
        new FormControl(true),
        new FormControl(false),
        new FormControl(false),
      ]),
    });
  }

  addEmail(input: MatChipInputEvent | string): void {
    if (typeof input === 'object') {
      const value = (input.value || '').trim();

      if (input.value !== '')
        this.isEmailValid = this.validateEmail((input.value || '').trim());

      if (this.isEmailValid) {
        if (value && this.countEmail < 6) {
          (<FormArray>this.quickForm.get('emailId')).push(
            new FormControl(value, [Validators.email])
          );
          this.emails.push({ name: value });
          this.countEmail++;
          this.isEmailValid = true;
        }
        input.chipInput!.clear();
      }
    } else if (typeof input === 'string') {
      if (input !== '')
        this.isEmailValid = this.validateEmail((input || '').trim());
      if (this.isEmailValid) {
        if (input && this.countEmail < 6) {
          (<FormArray>this.quickForm.get('emailId')).push(
            new FormControl(input, [Validators.email])
          );
          this.emails.push({ name: input });
          this.countEmail++;
          this.isEmailValid = true;
        }
      }
    }
  }

  removeEmail(email: EmailList): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      const emailIdControlArray = this.quickForm.get('emailId') as FormArray;
      emailIdControlArray.removeAt(index);
      this.emails.splice(index, 1);
      this.countEmail--;
    }
  }

  editEmail(email: EmailList, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.removeEmail(email);
      return;
    }

    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails[index].name = value;
    }
  }

  clearEmailArray() {
    while (this.emails.length > 0) {
      this.emails.pop();
    }
    this.countEmail = 0;
  }

  submitForm() {
    this.quickForm.value.receivingStyle === 'downloadpdf'
      ? this.downloadQuickReportPdf(
          this.generatePayloadQuickReport() as ReportData.DownloadQuickReport
        )
      : this.emailQuickReportPdf(
          this.generatePayloadQuickReport() as ReportData.EmailQuickReport
        );
  }

  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  customValidatorForSingleCheckBox(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let count = (control as FormArray).value.reduce(
        (prev: number, curr: boolean) => prev + Number(curr),
        0
      );
      return count <= 0 ? { required: true } : null;
    };
  }

  selectedReport(index: number, selectedForm: FormGroup) {
    let form = selectedForm.get('duration') as FormArray;
    form.controls.map((con, i) => {
      con.setValue(index === i);
    });
  }

  customEmailValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.quickForm.controls['receivingStyle'].value === 'emailpdf') {
        let isEmailEmpty = this.countEmail <= 0 ? true : false;
        return isEmailEmpty ? { requiredEmail: true } : null;
      }
      return null;
    };
  }

  countLTE(date: moment.Moment, reportDuration: number) {
    if (reportDuration === 0) {
      const dateIs = this.customMomentService.moment(date).add(1, 'day');

      return dateIs.valueOf();
    } else if (reportDuration === 1) {
      const dateIs = this.customMomentService.moment(date).add(7, 'days');

      return dateIs.valueOf();
    } else if (reportDuration === 2) {
      const dateIs = this.customMomentService.moment(date).add(30, 'days');
      return dateIs.valueOf();
    }
    return null;
  }

  validateEmail(emailId: string) {
    return String(emailId)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
      ? true
      : false;
  }

  //when clicked on submit button this function will be called if user wants to generate quick report
  downloadQuickReportPdf(reportDetails: ReportData.DownloadQuickReport) {
    this.isNextStep = false;
    this.reportsService.downloadPDF(reportDetails).subscribe({
      next: (blobData: Blob) => {
        this.formsService.closeForm();
        this.notificationService.showSnackBar('Report Downloaded Successfully','success');
        const pdfUrl = URL.createObjectURL(blobData);
        window.open(pdfUrl, '_blank');
        saveAs(blobData, reportDetails.devices?.[0].label + '.pdf');
        this.loadrService.removeLoader();
      },
      error: (err: Error) => {
        console.info("Error:", err);
        this.notificationService.showSnackBar('Error Downloading Report, Try Again!','error');
      },
    });
  }

  emailQuickReportPdf(reportDetails: ReportData.EmailQuickReport) {
    this.loadrService.showLoader();
    this.reportsService.emailPdf(reportDetails).subscribe({
      next: (res) => {
        if (res) {
          this.loadrService.removeLoader();
          this.formsService.closeForm();
          this.notificationService.showSnackBar('Email Sent Successfully','success');
        }
      },
      error: (err) => {
        this.loadrService.removeLoader();
        console.info("Error:", err);
        this.notificationService.showSnackBar(err.message,'error');
      },
    });
  }

  generatePayloadQuickReport() {
    let tempForm = this.quickForm.value;
    let date = this.customMomentService
      .moment(tempForm.dateSelected)
      .startOf('day');

    let deviceIds = [];
    deviceIds.push(this.data?.clusterId!);

    let quickReportPayload:
      | ReportData.DownloadQuickReport
      | ReportData.EmailQuickReport = {
      devices: [
        {
          deviceId: this.data?.clusterId!,
          label: this.data?.label!,
          loc: '',
        },
      ],
      userId: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).userId,
      reportName: tempForm.duration[0]
        ? String(0)
        : tempForm.duration[1]
        ? String(1)
        : String(2),
      org: this.localStorageService.getValue(LocalStorageConstants.ORG_NAME),
      gte: date.unix(),
      lte:
        this.countLTE(
          tempForm.dateSelected,
          tempForm.duration[0] ? 0 : tempForm.duration[1] ? 1 : 2
        )! /
          1000 -
        60,
      logo_url: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).organization.logoUrl,
      isTwelveHourFormat:
        this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          .settings.time_format === 12
          ? true
          : false,
      timezone: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).settings.timezone.name,
      processType: 'avg',
      avg: tempForm.duration[0] ? 3600 : 86400,
      deviceIds: deviceIds,
    };

    if (tempForm.receivingStyle === 'downloadpdf') {
      return quickReportPayload;
    } else {
      let emails = this.emails.map((email) => {
        return email.name;
      });
      let tempPayload: ReportData.EmailQuickReport = {
        ...quickReportPayload,
        email: emails,
      };
      quickReportPayload = tempPayload;
    }
    return quickReportPayload;
  }
}
