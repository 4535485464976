import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clusterColor',
})
export class ClusterColorPipe implements PipeTransform {
  transform(cluster: any, property: string, aqiData: any[]): string {
    const matchedCluster = aqiData.find((item) => item.id == cluster.clusterId);
    if (matchedCluster && matchedCluster[property]) {
      return matchedCluster[property];
    }

    return '';
  }
}
