<app-form-container
  [tableTitle]="'Reports'"
  [isValid]="isNextStep"
  [submitBtn]="buttonValue"
  (submit)="submitForm()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [cancelBtn]="'Cancel'"
>
  <ng-container *ngIf="isAutomated">
    <form class="row gy-4" [formGroup]="reportForm">
      <div class="col-12">
        <mat-radio-group
          aria-label="Select an option"
          class="d-flex justify-content-around"
          formControlName="reportType"
        >
          <mat-radio-button color="primary" value="automated"
            >Automated Report</mat-radio-button
          >
          <mat-radio-button *ngIf="!isEditMode" color="primary" value="quick"
            >Quick Report</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Enter upto 5 Emails*</mat-label>
          <mat-chip-grid
            #chipGrid
            aria-label="Enter Email"
            class="example-chip-list"
          >
            <mat-chip-row
              *ngFor="let email of emails"
              (removed)="removeEmail(email)"
              [editable]="true"
              [removable]="true"
              (edited)="editEmail(email, $event)"
            >
              {{ email.name }}
              <button matChipRemove matTooltip="Cancel">
                <mat-icon class="material-symbols-outlined">cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input
              *ngIf="countEmail < 6"
              placeholder="Add Email..."
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addEmail($event)"
              formArrayName="emailId"
            />
          </mat-chip-grid>
        </mat-form-field>
        <mat-error class="text-center" *ngIf="countEmail === 6"
          >Only 6 Emails Allowed</mat-error
        >
        <mat-error class="text-center" *ngIf="!isEmailValid"
          >Please Enter a Valid Email</mat-error
        >
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Device Name</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            placeholder="Device Name"
            matInput
            formControlName="deviceSearch"
            [matAutocomplete]="deviceName"
          />
          <mat-autocomplete
            #deviceName="matAutocomplete"
            [displayWith]="getDeviceName"
          >
            <mat-optgroup
              *ngFor="let deviceType of deviceTypes"
              [label]="deviceType.label"
            >
              <mat-option
                *ngFor="
                  let device of devices
                    | deviceType : deviceType.key
                    | search : reportForm.get('deviceSearch')?.value
                "
                [value]="device"
              >
                {{ device.label }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>

          <button
            type="button"
            *ngIf="reportForm.get('deviceSearch')?.value"
            mat-icon-button
            matSuffix
            aria-label="clear"
            style="margin-right: -18px; z-index: 1"
            (click)="clearDeviceName(); $event.stopPropagation()"
            [hidden]="isEditMode"
            matTooltip="Close"
          >
            <mat-icon class="material-symbols-outlined">close</mat-icon>
          </button>
          <button
            type="button"
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
            class=""
            style="z-index: 0"
            [disabled]="isEditMode"
          >
            <mat-icon class="material-symbols-outlined"
              >arrow_drop_down</mat-icon
            >
          </button>
        </mat-form-field>
      </div>

      <!--Duration Input-->
      <div class="col-12" formArrayName="duration">
        <mat-form-field floatPlaceholder="always" class="w-100 example2">
          <input matInput placeholder="Input" style="display: none" />
          <section class="d-flex flex-wrap justify-content-around">
            <mat-checkbox color="primary" formControlName="0"
              >Daily</mat-checkbox
            >
            <mat-checkbox color="primary" formControlName="1"
              >Weekly</mat-checkbox
            >
            <mat-checkbox color="primary" formControlName="2"
              >Monthly</mat-checkbox
            >
          </section>
        </mat-form-field>
      </div>

      <ng-container *ngIf="isEditMode">
        <div class="col-12 d-flex justify-content-between">
          <mat-label class="testClass">Status</mat-label>
          <mat-slide-toggle
            color="primary"
            formControlName="status"
            [matTooltip]="
              reportForm.get('status')?.value ? 'Enabled' : 'Disabled'
            "
          ></mat-slide-toggle>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <ng-container *ngIf="isQuick">
    <form class="row gy-4" [formGroup]="quickForm">
      <div class="col-12">
        <mat-radio-group
          aria-label="Select an option"
          class="d-flex justify-content-around"
          formControlName="reportType"
        >
          <mat-radio-button color="primary" value="automated"
            >Automated Report</mat-radio-button
          >
          <mat-radio-button color="primary" value="quick"
            >Quick Report</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Device Name</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            placeholder="Device Name"
            matInput
            formControlName="deviceSearch"
            [matAutocomplete]="deviceName"
          />
          <mat-autocomplete
            #deviceName="matAutocomplete"
            [displayWith]="getDeviceName"
          >
            <mat-optgroup
              *ngFor="let deviceType of deviceTypes"
              [label]="deviceType.label"
            >
              <mat-option
                *ngFor="
                  let device of devices
                    | deviceType : deviceType.key
                    | search : reportForm.get('deviceSearch')?.value
                "
                [value]="device"
              >
                {{ device.label }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <button
            type="button"
            *ngIf="reportForm.get('deviceSearch')?.value"
            mat-icon-button
            matSuffix
            aria-label="clear"
            style="margin-right: -18px; z-index: 1"
            (click)="clearDeviceName(); $event.stopPropagation()"
            matTooltip="Close"
          >
            <mat-icon class="material-symbols-outlined">close</mat-icon>
          </button>
          <button
            type="button"
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
            class=""
            style="z-index: 0"
          >
            <mat-icon class="material-symbols-outlined"
              >arrow_drop_down</mat-icon
            >
          </button>
        </mat-form-field>
      </div>
      <div class="col-12" formArrayName="duration">
        <mat-form-field floatPlaceholder="always" class="w-100 example2">
          <input matInput placeholder="Input" style="display: none" />
          <section class="d-flex justify-content-around">
            <mat-checkbox
              color="primary"
              formControlName="0"
              value="Daily"
              (change)="selectedReport(0, this.quickForm)"
              >Daily</mat-checkbox
            >
            <mat-checkbox
              color="primary"
              formControlName="1"
              value="Weekly"
              (change)="selectedReport(1, this.quickForm)"
              >Weekly</mat-checkbox
            >
            <mat-checkbox
              color="primary"
              formControlName="2"
              value="Monthly"
              (change)="selectedReport(2, this.quickForm)"
              >Monthly</mat-checkbox
            >
          </section>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="dp3"
            formControlName="dateSelected"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="dp3"
          ></mat-datepicker-toggle>
          <mat-datepicker #dp3 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-radio-group
          aria-label="Select an option"
          class="d-flex justify-content-around"
          formControlName="receivingStyle"
        >
          <mat-radio-button color="primary" value="downloadpdf"
            >Download PDF File</mat-radio-button
          >
          <mat-radio-button color="primary" value="emailpdf"
            >Email PDF File</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="col-12" *ngIf="quickForm.controls['emailId']">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Enter upto 5 Emails</mat-label>
          <mat-chip-grid
            #chipGrid
            aria-label="Enter Email"
            class="example-chip-list"
          >
            <mat-chip-row
              *ngFor="let email of emails"
              (removed)="removeEmail(email)"
              [editable]="true"
              [removable]="true"
              (edited)="editEmail(email, $event)"
            >
              {{ email.name }}
              <button matChipRemove matTooltip="Cancel">
                <mat-icon class="material-symbols-outlined">cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input
              *ngIf="countEmail < 5"
              placeholder="Add Email..."
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addEmail($event)"
              formArrayName="emailId"
            />
          </mat-chip-grid>
        </mat-form-field>
        <mat-error class="text-center" *ngIf="countEmail === 5"
          >Only 5 Emails Allowed</mat-error
        >
        <mat-error class="text-center" *ngIf="!isEmailValid"
          >Please Enter a Valid Email</mat-error
        >
      </div>
    </form>
  </ng-container>
</app-form-container>
