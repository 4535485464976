<div class="h-100 w-100 overflow-hidden">
  <div *ngIf="loadTable | async" class="d-flex flex-column h-100">
    <app-data-table
      tableTitle="Automated Report"
      [data]="allReports | deviceTypeWiseReport : currentDeviceType"
      [columns]="defaultColumns"
      [displayedColumns]="displayedColumns"
      [showDeleteInHeader]="false"
      [showDownloadInHeader]="false"
      [noDataViewConfig]="noData"
      [showFilterInHeader]="false"
      [isChartVisible]="false"
    >
    </app-data-table>
  </div>
  <div class="py-5" *ngIf="(loadTable | async) == false">
    <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
  </div>
</div>
