import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { ReportListComponent } from './pages/report-list/report-list.component';

const routes: Routes = [
  { path: '', 
  component: ReportsComponent, 
  children: [
    {
      path: '',
      component: ReportListComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
