import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { AutomationData } from 'src/app/shared/models/automation/automation-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { AutomationService } from 'src/app/shared/services/automation.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { AutomationFormComponent } from './components/automation-form/automation-form.component';
import { AutomationListComponent } from './pages/automation-list/automation-list.component';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss'],
})
export class AutomationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private deviceSearchSubscription!: Subscription;
  public deviceTypes!: Array<DeviceType>;
  public devices: DeviceDetails[] = [];
  public headerForm!: FormGroup;
  public currentActiveDevice!: DeviceDetails;
  public isRelayOutputAvailable: boolean = false;
  public automationData!: AutomationData.Get;
  public isAddConfigButtonHidden: boolean = false;

  constructor(
    private deviceService: DeviceService,
    private automationService: AutomationService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private formsService: FormsService
  ) {}

  ngOnInit(): void {
    this.devices = this.deviceService.registeredDevices || [];
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.currentActiveDevice = this.deviceService?.currentDevice
      ? this.deviceService?.currentDevice
      : this.devices[0];

    this.automationService.isAutomationData$.subscribe((res) => {
      this.isAddConfigButtonHidden = !res;
    });

    this.automationService.getAutomationData(this.currentActiveDevice.deviceId);
    this.buildForm();
    this.addSubscriptions();
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  onChangeDeviceId() {
    this.deviceSearchSubscription = this.headerForm
      .get('deviceSearch')
      ?.valueChanges.subscribe((device) => {
        this.automationService.getAutomationData(device.deviceId);
      })!;
    this.subscriptions.push(this.deviceSearchSubscription);
  }

  buildForm() {
    this.headerForm = this.formBuilder.group({
      deviceSearch: [this.currentActiveDevice || ''],
    });
  }

  openConfigForm() {
    this.formsService.openForm(AutomationFormComponent).subscribe(() => {});
  }

  onOutletLoaded(component: AutomationListComponent) {
    if (component instanceof AutomationListComponent) {
      component.addConfigFunc = () => this.openConfigForm();
    }
  }

  addSubscriptions(): void {
    const isAutomation = this.automationService.isAutomationData$.subscribe(
      (res) => {
        if (res === true) {
          this.automationData = this.automationService.automationData;
          if (this.automationData.config.automation) {
            this.isRelayOutputAvailable = true;
          } else {
            this.isRelayOutputAvailable = false;
          }
        }
      }
    );
    this.subscriptions.push(isAutomation);

    this.onChangeDeviceId();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
