import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { AppConstants } from '../constants/app-constants';
import { CommonUtil } from '../utils/common-utils';

type DateFormatInput = {
  date?: moment.Moment;
  epoch?: number;
  epochMS?: number;
  format?: string;
};

@Injectable({
  providedIn: 'root',
})
export class CustomMomentService {
  private _timezone: string;
  public set timezone(v: string) {
    if (v.length) {
      this._timezone = v;
    } else {
      this._timezone = CommonUtil.getDefaultTimezone();
    }
    // set default timezone in the moment library.
    moment.tz.setDefault(this._timezone);
  }
  public get timezone(): string {
    return this._timezone;
  }

  private _userTimeFormat: number = 24;
  public get userTimeFormat(): number {
    return this._userTimeFormat;
  }
  public set userTimeFormat(v: number) {
    this._userTimeFormat = v;
  }

  public get moment(): typeof moment {
    return moment;
  }

  constructor() {
    // As initial value set the default timezone.
    this._timezone = CommonUtil.getDefaultTimezone();
  }

  /**
   * Takes moment object or epoch and gives date as formatted string
   * @param { date?: moment.Moment, epoch?: number, format: string }
   * @returns date in the supplied format
   */
  public formatDate({ date, epoch, epochMS, format }: DateFormatInput): string {
    let momentObj = moment();
    if (date) {
      momentObj = moment(date);
    } else if (epoch) {
      momentObj = moment.unix(epoch);
    } else if (epochMS) {
      momentObj = moment(epochMS);
    }

    return momentObj.format(format ?? AppConstants.DEFAULT_DATE_FORMAT);
  }

  /**
   * Takes moment object or epoch and gives time as formatted string
   * @param { date?: moment.Moment, epoch?: number, format: string }
   * @returns time in the supplied format
   */
  public formatTime({ date, epoch, epochMS, format }: DateFormatInput): string {
    let momentObj = moment();
    if (date) {
      momentObj = moment(date);
    } else if (epoch) {
      momentObj = moment.unix(epoch);
    } else if (epochMS) {
      momentObj = moment(epochMS);
    }

    format = format ?? (this.userTimeFormat === 12 ? 'hh:mm:ss a' : 'HH:mm:ss');

    return momentObj.format(format);
  }

  /**
   * Takes moment object or epoch and gives datetime as formatted string
   * @param { date?: moment.Moment, epoch?: number, format: string }
   * @returns datetime in the supplied format
   */
  public formatDatetime({
    date,
    epoch,
    epochMS,
    format,
  }: DateFormatInput): string {
    let momentObj = moment();
    if (date) {
      momentObj = moment(date);
    } else if (epoch) {
      momentObj = moment.unix(epoch);
    } else if (epochMS) {
      momentObj = moment(epochMS);
    }

    return format
      ? momentObj.format(format)
      : `${this.formatDate({
          date: momentObj,
          format,
        })}, ${this.formatTime({ date: momentObj, format })}`;
  }
}
