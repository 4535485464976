import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { WidgetService } from '../../services/widget.service';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';

@Component({
  selector: 'app-rain-flood-widget',
  templateUrl: './rain-flood-widget.component.html',
  styleUrls: ['./rain-flood-widget.component.scss'],
})
export class RainFloodWidgetComponent
  implements IWidgetComponent, OnInit, OnDestroy
{
  @Input() public widgetInfo: WidgetInfo = {} as WidgetInfo;

  deviceData: DeviceDetails[] = [];
  private _fields: DeviceField[] = [];
  public get fields(): DeviceField[] {
    return this._fields;
  }
  public set fields(v: DeviceField[]) {
    this._fields = v;
    this.rainField = v.find((field: DeviceField) => field.fkey === 'rain');
    let c_factor = this.rainField?.cFactore ?? 1;
    this.heavyRainThreshold *= c_factor;
    this.floodField = v.find((field: DeviceField) => field.fkey === 'flood');
  }

  heavyRainThreshold: number = 10;
  rainField!: DeviceField | undefined;
  floodField!: DeviceField | undefined;

  rainFloodValueStyles: {
    height: string;
    fontSize: string;
    lineHeight: string;
  } = {
    height: '32px',
    fontSize: '26px',
    lineHeight: 'normal',
  };

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.deviceData = this.widgetService.realTimeDeviceData;
    this.fields = this.widgetService.fields;
    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'realTimeDeviceData': {
              this.deviceData = this.widgetService.realTimeDeviceData;
              break;
            }
            case 'fields': {
              this.fields = this.widgetService.fields;
              break;
            }
            default: {
              // console.info('real-time-widget -> key for changed value:', res);
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
