<div
  class="d-flex flex-column align-items-center justify-content-center w-100 h-100"
>
  <img src="{{ svgImage }}" />
  <h3 class="text-center m-0 mt-3 majorText" *ngIf="majorText">
    {{ majorText }}
  </h3>
  <div *ngIf="isConfiguration" class="mt-3">
    <strong>
      <a
        href="javascript:void(0)"
        (click)="createFunc()"
        class="text-primary pe-auto"
        *ngIf="linkText"
      >
        {{ linkText }}
      </a>
      {{ minorText }}
    </strong>
  </div>
  <div
    *ngIf="!isConfiguration && minorText"
    class="mt-3"
    [innerHTML]="minorText"
  ></div>
</div>
