<app-form-container
  [submitBtn]="submitBtn"
  [tableTitle]="displayTitle"
  [isValid]="isNextStep"
  (submit)="submitAndNext()"
  (cancelled)="previous()"
  (close)="closeForm()"
  [cancelBtn]="backBtn"
>
  <div class="w-100" *ngIf="loadCardSelection | async">
    <app-selection-tile
      [tileDetails]="cardDetails"
      [isMultiSelect]="true"
      (onSelection)="onWidgetSelection($event)"
    >
    </app-selection-tile>
  </div>
  <div class="py-5" *ngIf="(loadCardSelection | async) == false">
    <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
  </div>
</app-form-container>
