<app-form-container
  [tableTitle]="'Flag Data'"
  (close)="closeForm()"
  (cancelled)="closeForm()"
  [submitBtn]="'Submit'"
  [cancelBtn]="'Cancel'"
  [isValid]="form.valid"
  (submit)="onSubmit()"
>
  <form [formGroup]="form">
    <div class="flex row" style="justify-content: center">
      <mat-radio-group formControlName="selectedCat">
        <ng-container *ngFor="let cat of flagCategories; index as i">
          <mat-radio-button [value]="cat?.value" class="custom-radio-button" [color]="'primary'"> 
            <span class="label-container" [style.backgroundColor]="cat?.color">
              {{ cat?.label }}
            </span>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </form>
</app-form-container>
