export type RefLine = {
  currentTheme?: string;
  value: number;
  labelText: string;
};

export class HighchartUtils {
  public static getRefLineConfig({
    currentTheme = 'material-dark',
    value,
    labelText,
  }: RefLine): Highcharts.XAxisPlotLinesOptions {
    const lineColor =
      currentTheme === 'material-dark'
        ? 'rgb(255, 255, 255, 1)'
        : 'rgb(0, 0, 0, 1)';

    const lineLabelColor =
      currentTheme === 'material-dark'
        ? 'rgb(255, 255, 255, 1)'
        : 'rgb(0, 0, 0, 1)';
    return {
      value: value,
      color: lineColor,
      width: 2,
      zIndex: 5,
      dashStyle: 'Dash',
      label: {
        text: labelText,
        align: 'right',
        verticalAlign: 'bottom',
        y: 15,
        style: {
          color: lineLabelColor,
          fontWeight: 'bolder',
        },
      },
    };
  }
}
