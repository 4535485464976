<div>
  <div class="gas-info d-flex fw-bold">
    <ng-container *ngFor="let _ of aqiIndexColor.labels; let index = index">
      <div
        class="text-center text-white px-1"
        [style.width.%]="100 / aqiIndexColor.labels.length"
        [style.backgroundColor]="aqiIndexColor.color[index]"
      >
        <span
          [title]="aqiIndexColor.labels[index]"
          class="d-block w-100 text-truncate text-white px-1"
          [matTooltip]="aqiIndexColor.labels[index]"
        >
          {{ aqiIndexColor.labels[index] }}
        </span>
      </div>
    </ng-container>
  </div>
  <div class="d-flex">
    <ng-container *ngFor="let _ of aqiIndexColor.labels; let index = index">
      <div [style.width.%]="100 / aqiIndexColor.labels.length">
        {{ aqiIndexColor.range[index] }}
      </div>
    </ng-container>
  </div>
</div>
