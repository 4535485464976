<h1 mat-dialog-title class="text-primary">Terms and Conditions</h1>

<mat-dialog-content>
  <b>1. Introduction</b>
  <p>
    These Terms and Conditions ("Terms") govern your access to and use of the
    Oizom Envizom software application ("Software") and any associated services
    offered by Oizom ("Oizom", "we", "us", or "our"). Oizom Envizom is an air
    quality monitoring software application designed to provide real-time data
    acquisition, visualization, and analytics for various environmental
    parameters from the Oizom product line and external third-party devices &
    integrations. By accessing or using the Software, you agree to be bound by
    these Terms.
  </p>
  <b>2. Definitions</b>
  <p>
    "Licensee" means you, the individual or entity accessing or using the
    Software under these Terms.
  </p>
  <p>
    "Software" means the Oizom Envizom software application, including all
    related documentation.
  </p>
  <b>3. License Grant</b>
  <p>
    Subject to these Terms, Oizom grants you a non-exclusive, non-transferable,
    limited license to access and use the Software for your internal business
    purposes only. The Software is designed to work with Oizom's environmental
    monitoring stations and third-party devices, which collect data on various
    parameters such as dust, toxic gases, odorants, noise, and weather
    conditions.
  </p>
  <p>You may not, without Oizom approval:</p>
  <ul>
    <li>Sub-license, rent, lease, or otherwise distribute the Software.</li>
    <li>Modify, decompile, and reverse engineer the Software.</li>
    <li>Use the Software for any illegal or unauthorized purpose.</li>
  </ul>

  <b> 4. Ownership </b>
  <p>
    The Software and all intellectual property rights therein are and shall
    remain the exclusive property of Oizom.
  </p>

  <b>5. Term and Termination</b>
  <p>
    These Terms shall commence on the date you first access the Software and
    shall continue until terminated by you or Oizom. Oizom may terminate these
    Terms at any time for any reason, with or without notice. Upon termination,
    you shall cease all use of the Software.
  </p>
  <b> 6. Disclaimer of Warranties </b>
  <p>
    The software is provided "as is" and without warranty of any kind, express
    or implied. Oizom makes no promises about the software, including whether
    it’s good for a specific use, works as expected, or doesn’t violate any
    rights. Oizom does not warrant that the software will always function
    uninterrupted, error-free, or virus-free.
  </p>
  <b> 7. Limitation of Liability </b>
  <p>
    In no event shall Oizom be liable for any direct, indirect, incidental,
    special, consequential, or punitive damages arising out of or in connection
    with the use of the software, even if Oizom has been advised of the
    possibility of such damages.
  </p>
  <b> 8. Indemnification </b>
  <p>
    You agree to indemnify, defend, and hold harmless Oizom, its officers,
    directors, employees, agents, and licensors from and against any and all
    claims, losses, damages, liabilities, costs, and expenses (including
    attorneys' fees) arising out of or in connection with your use of the
    Software.
  </p>
  <b> 9. Governing Law and Dispute Resolution </b>
  <p>
    These Terms shall be governed by and construed in accordance with the laws
    of India, without regard to its conflict of laws principles. Any dispute
    arising out of or relating to these Terms shall be subject to the exclusive
    jurisdiction of the courts located in India.
  </p>
  <b> 10. Entire Agreement </b>
  <p>
    These Terms constitute the entire agreement between you and Oizom with
    respect to the use of the Software and supersede all prior or
    contemporaneous communications and proposals, whether oral or written.
  </p>
  <b> 11. Severability </b>
  <p>
    If any provision of these Terms is held to be invalid or unenforceable, such
    provision shall be struck and the remaining provisions shall remain in full
    force and effect.
  </p>

  <b> 12. Waiver </b>
  <p>
    No waiver of any breach of any provision of these Terms shall be construed
    as a waiver of any other or subsequent breach.
  </p>
  <b> 13. Updates </b>
  <p>
    Oizom may from time to time release updates to the Software. These updates
    may be automatically downloaded and installed on the Software - Envizom as
    well as on Oizom Hardware Products.
  </p>
  <b>14. Contact Us</b>
  <p>
    If you have any questions about these Terms, please contact us at
    hello&#64;oizom.com
  </p>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between px-4 pb-4">
  <button
    mat-raised-button
    color="primary"
    (click)="agree()"
    matTooltip="Agree"
  >
    AGREE
  </button>
  <button mat-button (click)="dismiss()" matTooltip="Cancel">CANCEL</button>
</mat-dialog-actions>
