import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { MatStepper } from '@angular/material/stepper';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { Subscription } from 'rxjs';
import { AlertConstant } from 'src/app/shared/constants/alert-constants';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AlertData } from 'src/app/shared/models/alerts/alert-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { DevicePayload } from 'src/app/shared/models/device/device-payload';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { UserMobileNumbers } from 'src/app/shared/models/internal-use-front-end/country-wise-ph-code';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { EmailList } from '../../../reports/components/report-form/report-form.component';

export interface PhoneNumberList {
  name: string;
}

@Component({
  selector: 'app-alert-configuration-form',
  templateUrl: './alert-configuration-form.component.html',
  styleUrls: ['./alert-configuration-form.component.scss'],
})
export class AlertConfigurationFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @ViewChild('stepper') stepper!: MatStepper;
  public deviceTypes!: Array<DeviceType>;
  public isSelected: boolean = true;
  public timeInterval: any = AlertConstant.timeInterval;
  public conditionalStatements: any = AlertConstant.conditionalStatements;
  public parametersList: Array<number> = [];
  public currentCondition: string = 'or';
  public currentDeviceType: string = '';
  public devices!: DeviceDetails[];
  public isEmailValid = true;
  public submitBtn: string = 'Next';
  public isNextStep: boolean = false;
  public currentUserEmail: string = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER
  ).email;
  public emails: EmailList[] = [];
  public countEmail: number = 1;
  public readonly seperatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  public addOnBlur = true;
  // public phNumbers: PhoneNumberList[] = [];
  // public isPhNumberValid: boolean = true;
  // public countPhNumber: number = 0;
  public isValueValid: boolean = true;
  public readonly allUnits: any = this.commonService.getAllUnits();
  public unitsOfDeviceType: any = undefined;
  public configurationForm!: FormGroup;
  public parameterForm!: FormGroup;
  public informationForm!: FormGroup;
  public selectedDevices: DeviceDetails[] = [];
  public uniqueKeys: { [key: string]: number | string } = {};
  public isEditMode: boolean = false;
  public submitButtonTooltip!: string;
  public currentDeviceTypeId!: number;
  public isRawSelected: boolean = false;
  public fieldsOfCurrentDeviceType!: DeviceField[];
  public limitsOfFields!: FieldLimit[];
  backBtn: string = 'Cancel';
  public keyStatus: { [key: string]: Record<string, boolean> } = {};
  public mobileNumbers!: UserMobileNumbers;
  public existingMobileNumbers: string[] = [];

  constructor(
    private formsService: FormsService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private alertsService: AlertsService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    @Inject(MTX_DRAWER_DATA) public data: AlertData.Get
  ) {}

  ngOnInit() {
    this.isEditMode = !!this.data;
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.devices = this.deviceService.registeredDevices!;
    this.buildForm(this.isEditMode);
    this.onChangeDeviceType();
    this.onChangeDevices();

    //in edit mode than enable the next button if the config form is valid
    if (this.isEditMode) {
      setTimeout(() => {
        this.isNextStep = this.configurationForm.valid;
      });
    }
    // this.formSubscriptions();
  }
  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }
  buildForm(isEditMode: boolean) {
    this.configurationForm = this.formBuilder.group({
      deviceType: [
        this.deviceService?.currentDeviceType || '',
        Validators.required,
      ],
      configurationName: ['', Validators.required],
      configurationDescription: [''],
      devices: ['', Validators.required],
    });
    this.parameterForm = this.formBuilder.group({
      allconditions: this.formBuilder.array([]),
    });

    this.informationForm = this.formBuilder.group(
      {
        timeInterval: ['', Validators.required],
        email: this.formBuilder.array([]),
        // phoneNumber: this.formBuilder.array([]),
      },
      {
        validators: [this.validateIfEmailOrPhone()], //to validate if user has added atleast a phone number or an email
      }
    );

    if (!this.isEditMode) {
      const paramsList = this.parameterForm.get('allconditions') as FormArray;
      paramsList.push(this.createFormGroup());
      this.parameterForm.get('allconditions')?.updateValueAndValidity();

      //if it is not edit mode than pushing current user's email address in form and emails array
      (<FormArray>this.informationForm.get('email')).push(
        new FormControl(this.currentUserEmail, [Validators.email])
      );
      this.emails.push({ name: this.currentUserEmail });
    }

    this.formSubscriptions();

    if (isEditMode) {
      let devices = this.devices
        .filter((device) => this.data.deviceIds.includes(device.label))
        .map((device) => device.deviceId);

      let timeInterval = this.timeInterval.find(
        (interval: any) => interval.value === this.data.period
      );

      if (this.data?.email) {
        for (let i = 0; i < this.data?.email!.split(',').length!; i++) {
          this.add(this.data?.email!.split(',')[i]!, 0);
        }
      }

      if (this.data?.phone) {
        for (let i = 0; i < this.data?.phone!.split(',').length!; i++) {
          // this.add(this.data?.phone!.split(',')[i]!, 1);
          this.existingMobileNumbers.push(this.data?.phone!.split(',')[i]!);
        }
      }

      this.configurationForm.patchValue({
        deviceType: this.data.deviceTypeId,
        configurationName: this.data.label,
        configurationDescription: this.data.description || '',
        devices: devices,
      });
      this.onChangeDeviceType();
      this.configurationForm.get('deviceType')?.updateValueAndValidity();
      this.configurationForm.get('deviceType')?.disable();
      this.onChangeDevices();
      this.configurationForm.get('devices')?.updateValueAndValidity();

      this.addParameterFieldInEdit();
      this.informationForm.patchValue({
        timeInterval: timeInterval?.value,
      });

      this.informationForm.get('timeInterval')?.disable();

      this.informationForm.addControl(
        'alertStatus',
        new FormControl(this.data.enable)
      );

      if (this.data.conditions.intervalInSec) {
        this.addFormControl(String(this.data.conditions.intervalInSec));
      }
    }
  }

  createFormGroup(): FormGroup {
    this.parametersList.push(1);
    return new FormGroup({
      parameter: new FormControl('', Validators.required),
      condition: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
    });
  }

  //it adds the parameter field for and/or condition
  addParameterField() {
    const limits = this.parameterForm.get('allconditions') as FormArray;
    limits.push(this.createFormGroup());
  }

  addParameterFieldInEdit() {
    const limits = this.parameterForm.get('allconditions') as FormArray;
    let uniqueKey = Object.keys(this.uniqueKeys);

    if (Object.entries(this.data.conditions?.gt!).length > 0) {
      Object.entries(this.data.conditions?.gt!).map(([key, value]) => {
        if (uniqueKey.includes(key)) {
          let payload: DevicePayload = {
            d: {},
          };

          payload.d[key] = value;

          let convertedValue = DeviceUtil.convertUnits(
            payload,
            this.allUnits[this.currentDeviceTypeId],
            undefined,
            false
          );

          let finalValue = convertedValue.d[key].toFixed(2);
          if (finalValue.split('.')[1] == '00') {
            finalValue = Math.round(finalValue);
          }

          this.parametersList.push(1);
          limits.push(
            new FormGroup({
              parameter: new FormControl(key, Validators.required),
              condition: new FormControl('>', Validators.required),
              value: new FormControl(finalValue, Validators.required),
            })
          );
        }
      });
    }
    if (Object.entries(this.data.conditions?.lt!).length > 0) {
      Object.entries(this.data.conditions?.lt!).map(([key, value]) => {
        if (uniqueKey.includes(key)) {
          let payload: DevicePayload = {
            d: {},
          };

          payload.d[key] = value;

          let convertedValue = DeviceUtil.convertUnits(
            payload,
            this.allUnits[this.currentDeviceTypeId],
            undefined,
            false
          );
          let finalValue = convertedValue.d[key].toFixed(2);
          if (finalValue.split('.')[1] == '00') {
            finalValue = Math.round(finalValue);
          }

          this.parametersList.push(1);
          limits.push(
            new FormGroup({
              parameter: new FormControl(key, Validators.required),
              condition: new FormControl('<', Validators.required),
              value: new FormControl(finalValue, Validators.required),
            })
          );
        }
      });
    }
  }

  asFormArray(input: AbstractControl) {
    return input as FormArray;
  }
  asFormGroup(input: AbstractControl) {
    return input as FormGroup;
  }

  //it removes the parameter field for and/or condition
  removeParameterField(index: any) {
    let limits = this.parameterForm.get('allconditions') as FormArray;

    const halfBeforeTheUnwantedElement = this.parametersList.slice(0, index);
    const halfAfterTheUnwantedElement = this.parametersList.slice(index + 1);
    const copyWithoutUnwantedElement = halfBeforeTheUnwantedElement.concat(
      halfAfterTheUnwantedElement
    );
    // Update the original array with the modified one
    this.parametersList = copyWithoutUnwantedElement;
    limits.removeAt(index);
  }

  //to submit the form
  submitForm() {
    this.loadrService.showLoader();
    if (!this.isEditMode) {
      this.alertsService
        .addAlert(this.generatePayload() as AlertData.Post)
        .subscribe({
          next: (res) => {
            if (res) {
              this.loadrService.removeLoader();
              this.formsService.closeForm();
              this.notificationService.showSnackBar(res, 'success');
              this.alertsService.getAlertList().subscribe({
                next: (res) => {
                  if (res) this.alertsService.isResponse.next(true);
                },
              });
            }
          },
          error: (err) => {
            this.loadrService.removeLoader();
            this.notificationService.showSnackBar(err.error, 'error');
            console.info('Error:', err);
          },
        });
    } else if (this.isEditMode) {
      this.alertsService
        .updateAlert(
          this.generatePayload() as AlertData.Patch,
          this.data.alertId
        )
        .subscribe({
          next: (res) => {
            if (res) {
              this.loadrService.removeLoader();
              this.formsService.closeForm();
              this.notificationService.showSnackBar(res, 'success');
              this.alertsService.getAlertList().subscribe({
                next: (res) => {
                  if (res) this.alertsService.isResponse.next(true);
                },
              });
            }
          },
          error: (err) => {
            this.loadrService.removeLoader();
            this.notificationService.showSnackBar(err, 'error');
            console.info('Error:', err);
          },
        });
    }
  }

  //when user change the device type this function updates the current device type
  onChangeDeviceType() {
    let units = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
    const deviceTypeSubscription = this.configurationForm
      .get('deviceType')
      ?.valueChanges.subscribe((res) => {
        this.currentDeviceTypeId = res;
        this.currentDeviceType =
          this.deviceTypes.find((deviceType) => deviceType.deviceTypeId === res)
            ?.key ?? '';

        this.fieldsOfCurrentDeviceType = this.deviceService.fetchFields(
          res,
          units,
          false
        );
        this.limitsOfFields = this.deviceService.fetchLimits(units[res], false);
      })!;

    this.subscriptions.push(deviceTypeSubscription);
  }

  checkEmail(event: any) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (validRegex.test(event.target.value)) {
      this.isEmailValid = true;
    } else {
      this.isEmailValid = false;
    }
  }

  onStepChange(event: any) {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      this.previous(event);
    } else if (event.selectedIndex > event.previouslySelectedIndex) {
      this.submitAndNext(event);
    }
  }

  previous(event?: any) {
    event = !event;
    // let stepperLength = this.stepper.steps.length;
    // if (stepperLength === 3) {
    if (this.stepper.selectedIndex === 1) {
      if (event) this.stepper.previous();
      this.backBtn = 'Cancel';
      this.isNextStep = this.configurationForm.valid;
    } else if (this.stepper.selectedIndex === 2) {
      this.submitBtn = 'Next';
      this.submitButtonTooltip = this.submitBtn;
      if (event) this.stepper.previous();
      this.isNextStep = this.parameterForm.valid;
    }
    // }
  }

  submitAndNext(event?: any) {
    event = !event;
    // let stepperLength = this.stepper.steps.length;
    // if (stepperLength === 3) {
    if (this.stepper.selectedIndex === 0 && this.configurationForm.valid) {
      if (event) this.stepper.next();
      this.isNextStep = this.parameterForm.valid;
      this.backBtn = 'Back';
    } else if (this.stepper.selectedIndex === 1 && this.parameterForm.valid) {
      if (event) this.stepper.next();
      this.submitBtn = this.isEditMode ? 'Update' : 'Submit';
      this.submitButtonTooltip = 'Add New Configuration';
      if (this.isEditMode) {
        this.submitButtonTooltip = this.submitBtn;
      }
      this.backBtn = 'Back';
      this.isNextStep =
        this.informationForm.valid && //form should be valid and
        !this.mobileNumbers?.invalidNumbers.length; //there should be no invalid number present
    } else if (this.stepper.selectedIndex === 2 && this.informationForm.valid) {
      if (event) this.submitForm();
    }
    // }
  }

  //this function is called when user removes Email from the chip input
  remove(currentValue: EmailList | PhoneNumberList, emailOrSms: 0 | 1): void {
    let array: FormArray;
    let list: EmailList[] | PhoneNumberList[];
    let count: number;

    if (emailOrSms === 0) {
      array = this.informationForm.get('email') as FormArray;
      list = this.emails;
      count = this.countEmail;
    }

    // else if (emailOrSms === 1) {
    //   array = this.informationForm.get('phoneNumber') as FormArray;
    //   list = this.phNumbers;
    //   count = this.countPhNumber;
    // }
    else {
      // Handle invalid emailOrSms value
      return;
    }

    const index = list.indexOf(currentValue);
    if (index >= 0) {
      array.removeAt(index);
      list.splice(index, 1);
      count--;
      if (emailOrSms === 0) {
        this.countEmail = count;
      }
      // else {
      //   this.countPhNumber = count;
      // }
    }
  }

  //this function is called when user edits an email in the matchip input
  edit(
    currentValue: EmailList | PhoneNumberList,
    event: MatChipEditedEvent,
    emailOrSms: 0 | 1
  ) {
    const value = event.value.trim();
    if (emailOrSms === 0) {
      // Removes email if it no longer has a name
      if (!value) {
        this.remove(currentValue, 0);
        return;
      }
      let array = this.informationForm.get('email') as FormArray;

      // Edits existing email
      const index = this.emails.indexOf(currentValue);
      // if (index >= 0 && value !== this.currentUserEmail) {
      if (index >= 0) {
        this.emails[index].name = value;
        array.controls[index].patchValue(value);
        // this.enteringOwnEmail = false;
      } else {
        // this.enteringOwnEmail = true;
      }
    }

    // else {
    //   // Removes phone number if it no longer has a name
    //   if (!value) {
    //     this.remove(currentValue, 1);
    //     return;
    //   }

    //   // Edits existing phone number
    //   const index = this.phNumbers.indexOf(currentValue);
    //   if (index >= 0) {
    //     this.isPhNumberValid = this.validatePhoneNumber(value);
    //     if (this.isPhNumberValid) this.phNumbers[index].name = value;
    //   }
    // }
  }

  //this function adds the email in the matchip
  // it is called when user adds a new email or when user clicks on edits report details
  add(input: MatChipInputEvent | string, emailOrSms: 0 | 1): void {
    if (emailOrSms === 0) {
      if (typeof input === 'object') {
        const value = (input.value || '').trim();

        //check if email id is valid or not
        if (input.value !== '')
          this.isEmailValid = this.validateEmail((input.value || '').trim());

        // Add our email
        // if (this.isEmailValid && input.value !== this.currentUserEmail) {
        if (this.isEmailValid) {
          if (value && this.countEmail < 10) {
            (<FormArray>this.informationForm.get('email')).push(
              new FormControl(value, [Validators.email])
            );
            this.emails.push({ name: value });
            this.countEmail++;
            this.isEmailValid = true;
            // this.enteringOwnEmail = false;
          }
          // Clear the input value
          input.chipInput!.clear();
        }
        // else if (input.value === this.currentUserEmail) {
        // this.enteringOwnEmail = true;
        // }
      } else if (typeof input === 'string') {
        if (input !== '')
          this.isEmailValid = this.validateEmail((input || '').trim());
        if (this.isEmailValid) {
          if (input && this.countEmail < 10) {
            (<FormArray>this.informationForm.get('email')).push(
              new FormControl(input, [Validators.email])
            );
            this.emails.push({ name: input });
            this.countEmail++;
            this.isEmailValid = true;
          }
        }
      }
    }
    //for adding phone number in chip
    // else if (emailOrSms === 1) {
    //   if (typeof input === 'object') {
    //     const value = (input.value || '').trim();

    //     //check if email id is valid or not
    //     if (input.value !== '')
    //       // this.isEmailValid = this.validateEmail((input.value || '').trim());
    //       this.isPhNumberValid = this.validatePhoneNumber(input.value);
    //     // Add our email
    //     if (this.isPhNumberValid) {
    //       // if (value && this.countEmail < 5) {
    //       if (value) {
    //         (<FormArray>this.informationForm.get('phoneNumber')).push(
    //           new FormControl(value, [Validators.pattern(/^(\+?\d{10,15})$/)])
    //         );
    //         this.phNumbers.push({ name: value });
    //         this.countPhNumber++;
    //         this.isPhNumberValid = true;
    //       }
    //       // Clear the input value
    //       input.chipInput!.clear();
    //     }
    //   } else if (typeof input === 'string') {
    //     if (input !== '')
    //       // this.isEmailValid = this.validateEmail((input || '').trim());
    //       this.isPhNumberValid = this.validatePhoneNumber(input.trim());
    //     if (this.isPhNumberValid) {
    //       // if (input && this.countEmail < 5) {
    //       (<FormArray>this.informationForm.get('phoneNumber')).push(
    //         new FormControl(input, [Validators.pattern(/^(\+?\d{10,15})$/)])
    //       );
    //       this.phNumbers.push({ name: input });
    //       this.countPhNumber++;
    //       this.isPhNumberValid = true;
    //     }
    //     // }
    //   }
    // }
  }

  //when user is adding input in the matchip input, this function validates the email and than only it is added in the input element
  validateEmail(emailId: string) {
    return String(emailId)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
      ? true
      : false;
  }

  validatePhoneNumber(number: string): boolean {
    return AppConstants.PHONE_NUMBER_REGEX.test(String(number));
  }

  //executes when a change event is triggered in 'deviceId' in form
  onChangeDevices() {
    const deviceSubscription = this.configurationForm
      .get('devices')
      ?.valueChanges.subscribe((res) => {
        this.selectedDevices = [];
        if (res) {
          // if (typeof res === 'string') {
          //   console.log('res', res);
          //   this.realTimeDeviceData.push(this.deviceService.mqttDocs[res]);
          // } else {
          let uniqueKeysArray: string[] = [];
          this.selectedDevices = [];
          let tempArray: string[] = [];

          for (let i = 0; i < res.length; i++) {
            let device = this.deviceService.registeredDevices?.find(
              (device) => device.deviceId == res[i]
            );

            if (device) {
              this.selectedDevices.push(device);

              let aqiIndex =
                this.commonService.getUser().aqiIndex[this.currentDeviceTypeId];

              if (aqiIndex) {
                let aqi = DeviceUtil.calculateAQI(
                  this.selectedDevices[i].payload,
                  aqiIndex.breakpoints
                );
                if (aqi.aqi) {
                  this.selectedDevices[i].payload.d.aqi = aqi.aqi;
                }
              }

              tempArray.push(
                ...(Object.keys(this.selectedDevices[i].payload.d) as string[])
              );
            }
          }
          uniqueKeysArray = this.findUniqueKeys(tempArray);
          // }

          this.uniqueKeys = Object.fromEntries(
            uniqueKeysArray.map((key) => [key, 0])
          );
        }
      })!;

    this.subscriptions.push(deviceSubscription);
  }

  //this is used to find common keys of device when user selects device comparison
  // findCommomKeys(arrays: string[][]): string[] {
  //   if (arrays.length === 0) {
  //     return [];
  //   }

  //   return arrays.reduce<string[]>((accumulator, currentArray) => {
  //     return accumulator.filter((value) => currentArray.includes(value));
  //   }, arrays[0]);
  // }

  //this is used to find unique keys of device when user selects device comparison
  findUniqueKeys(arrays: string[]): string[] {
    if (arrays.length === 0) {
      return [];
    }

    const uniqueKeys = new Set<string>();
    arrays.forEach((res) => {
      uniqueKeys.add(res);
    });
    const uniqueKeyArray = Array.from(uniqueKeys);
    return uniqueKeyArray;
  }

  formSubscriptions() {
    const configForm = this.configurationForm.valueChanges.subscribe((res) => {
      if (res) this.isNextStep = this.configurationForm.valid;

      this.unitsOfDeviceType =
        this.allUnits[this.configurationForm.get('deviceType')?.value];
    });

    const paramForm = this.parameterForm.valueChanges.subscribe((res) => {
      this.checkKeyStatus();
      this.isValueValid = true;

      for (let condition of res.allconditions) {
        if (isNaN(condition.value)) {
          this.isValueValid = false;
          break;
        }
      }
      if (res) this.isNextStep = this.parameterForm.valid;
    });

    const infoForm = this.informationForm.valueChanges.subscribe((res) => {
      if (res) {
        this.isNextStep =
          this.informationForm.valid && //form should be valid and
          !this.mobileNumbers?.invalidNumbers.length; //there should be no invalid number present
      }
    });

    this.subscriptions.push(configForm, paramForm, infoForm);
    let subscription: Subscription;
    const intervalSelection = this.informationForm
      .get('timeInterval')!
      .valueChanges.subscribe((tInterval) => {
        if (tInterval === 'raw') {
          this.addFormControl('');
        } else {
          this.isRawSelected = false;
          this.informationForm.removeControl('emailSmsInterval');
        }
      });
    this.subscriptions.push(intervalSelection);
  }

  addFormControl(value: string) {
    this.informationForm.addControl(
      'emailSmsInterval',
      new FormControl('', [
        Validators.required,
        Validators.min(900),
        Validators.pattern(AppConstants.NUMBER_REGEX),
      ])
    );

    if (value) {
      value = String(value);
      this.informationForm.patchValue({
        emailSmsInterval: value,
      });
    }
    this.isRawSelected = true;
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    const filteredValue = value.replace(/[^0-9]/g, ''); // Removes all non-numeric characters
    input.value = filteredValue;
    this.informationForm.get('emailSmsInterval')?.setValue(filteredValue);
  }

  checkKeyStatus() {
    this.keyStatus = {};
    this.parameterForm.value.allconditions.forEach(
      (allcondition: { [key: string]: string | number }) => {
        const { condition, parameter } = allcondition;
        const param = String(parameter);

        if (!Boolean(condition)) {
          return;
        }

        if (!this.keyStatus[param]) {
          this.keyStatus[param] = {};
        }

        switch (condition) {
          case '>':
            this.keyStatus[param]['gt'] = Boolean(condition);
            break;
          case '<':
            this.keyStatus[param]['lt'] = Boolean(condition);
            break;
          default:
            console.info('ERROR WHILE GENERATING PAYLOAD FOR ERROR');
        }
      }
    );
  }

  generatePayload() {
    let payload!: AlertData.Post | AlertData.Patch;
    let conditions: {
      gt: Record<string, number>;
      lt: Record<string, number>;
    } = { gt: {}, lt: {} };

    this.parameterForm.value.allconditions.forEach(
      (allcondition: { [key: string]: string | number }) => {
        const { condition, parameter, value } = allcondition;
        const param = String(parameter);
        const val = Number(Number(value).toFixed(2)); //consider only 2 digits after the decimal for user input
        const paramObj = this.unitsOfDeviceType[allcondition.parameter];
        const currentConditions = conditions[condition === '>' ? 'gt' : 'lt'];

        switch (condition) {
          case '>':
            conditions.gt[param] = val;
            break;
          case '<':
            conditions.lt[param] = val;
            break;
          default:
            console.info('ERROR WHILE GENERATING PAYLOAD FOR ERROR');
        }

        //transforming units
        if (paramObj.c_factore) {
          // check for fehrenheit
          if (paramObj.c_facotore == -1) {
            currentConditions[param] = Number(
              (((currentConditions[param] - 32) * 0.55 * 1e2) / 1e2).toFixed(2) //after conversion consider only 2 digits
            );
          } else {
            currentConditions[param] = Number(
              (
                ((currentConditions[param] / paramObj.c_factore) * 1e2) /
                1e2
              ).toFixed(2)
            );
          }
        }
      }
    );

    // let email = this.emails.slice(1).map((res) => res.name);
    let email = this.emails.map((res) => res.name.trim());

    if (!this.isEditMode) {
      payload = {
        data: {
          description: this.configurationForm.value.configurationDescription,
          conditions: {
            gt: conditions.gt,
            lt: conditions.lt,
          },
          deviceIds: this.configurationForm.value.devices,
          deviceTypeId: this.configurationForm.value.deviceType,
          email:
            this.informationForm.value.email.length > 0
              ? email.toString()
              : null,
          label: this.configurationForm.value.configurationName,
          phone:
            this.mobileNumbers?.validNumbers.length > 0
              ? this.mobileNumbers.validNumbers.toString()
              : null,
          period: this.informationForm.value.timeInterval,
        },
      };

      if (this.isRawSelected) {
        if (!payload.data.conditions['intervalInSec']) {
          payload.data.conditions['intervalInSec'] = Number(
            this.informationForm.value.emailSmsInterval
          );
        }
      }
    } else if (this.isEditMode) {
      payload = {
        update: {
          conditions: {
            gt: conditions.gt,
            lt: conditions.lt,
          },
          description: this.configurationForm.value.configurationDescription,
          deviceIds: this.configurationForm.value.devices,
          email:
            this.informationForm.value.email.length > 0
              ? email.toString()
              : null,
          enable: this.informationForm.value.alertStatus,
          label: this.configurationForm.value.configurationName,
          phone:
            this.mobileNumbers?.validNumbers.length > 0
              ? this.mobileNumbers.validNumbers.toString()
              : null,
        },
      };

      if (this.isRawSelected) {
        if (!payload.update?.conditions?.['intervalInSec']) {
          payload.update.conditions!['intervalInSec'] = Number(
            this.informationForm.value.emailSmsInterval
          );
        } else {
          payload.update.conditions.intervalInSec = Number(
            this.informationForm.value.emailSmsInterval
          );
        }
      }
    }

    return payload;
  }

  validateIfEmailOrPhone(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const controls = (form as any).controls;

      const isEmailOrPhoneAdded =
        controls.email.value.length > 0 ||
        this.mobileNumbers?.validNumbers.length > 0;

      return isEmailOrPhoneAdded || //if phone or email is added
        Boolean(this.mobileNumbers?.invalidNumbers.length) //if invalid number is present (if we dont consider this scenario than it will show two errors in UI)
        ? null
        : { emailPhoneEmpty: true };
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription: Subscription) =>
        !subscription.closed && subscription.unsubscribe()
    );
  }

  onMobileNumberChange(numbers: UserMobileNumbers) {
    this.mobileNumbers = JSON.parse(JSON.stringify(numbers));
    this.informationForm.setErrors(this.validateIfEmailOrPhone());
    this.informationForm.updateValueAndValidity();
  }
}
