import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceField } from '../models/device/device-field';
import { DeviceUtil } from '../utils/device-utils';
import { LocalStorageService } from './local-storage.service';
import { CommonService } from './common.service';
import { SseConnectionOptions } from '../models/sse-connection-options';
import { APIConstants } from '../constants/api-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { NotificationService } from './notification.service';
import { AppConstants } from '../constants/app-constants';
import { environment } from 'src/environments/environment';
import { CommonUtil } from '../utils/common-utils';

@Injectable({
  providedIn: 'root',
})
export class SSEConnectionService {
  private sseConnectionOptions: SseConnectionOptions = {
    token: '',
    userId: '',
    resetInterval: 0,
    sseConnectionString: '',
  };
  private sseConnectionTypes = {
    disconnecting: 'disconnecting',
    connecting: 'connecting',
    opened: 'opened',
    message: 'message',
    parsingError: 'parsing-error',
    error: 'error',
  };
  private eventSource: any = null;
  private requestId: string | null = null;
  private lastHeartbeat: string | null = null;
  private subscribedDevices: string[] = [];
  private connectionData: any = null;

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private notificationService: NotificationService
  ) {}

  init(): void {
    this.sseConnectionOptions.token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    this.sseConnectionOptions.userId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;
  }

  // Validates and updates the existing SSE connection
  validateAndUpdateExistingSseConnection(): void {
    this.sseConnectionOptions.token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    this.sseConnectionOptions.userId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;
  }

  // Connects to the SSE endpoint and listens for messages
  connect(
    devices: DeviceDetails[],
    showRawAQIData: boolean,
    mqttDocs: any,
    fields: DeviceField[],
    mQTTDocsSnapshot: Subject<DeviceDetails>,
    newNotificationSubject: Subject<any>
  ) {
    const sseUrl = `${environment.URL}real-time/users/${this.sseConnectionOptions.userId}?token=${this.sseConnectionOptions.token}`;
    this.eventSource = new EventSource(sseUrl);
    this.connectionData = {
      devices: devices,
      showRawAQIData: showRawAQIData,
      mqttDocs: mqttDocs,
      fields: fields,
      mQTTDocsSnapshot: mQTTDocsSnapshot,
      newNotificationSubject: newNotificationSubject,
    }
    this.eventSource.onopen = () => {
    };

    this.eventSource.onmessage = (e: any) => {
      try {
        const parsed = this.parseIncomingMessage(e.data); // Parse the message
        if (!parsed) {
          console.error('Invalid or empty message received.');
          return;
        }

        switch (parsed.type) {
          case 'request-id':
            this.updateRequestId(parsed.data);
            break;

          case 'heartbeat':
            this.updateLastHeartbeat(parsed.data);
            break;

          case 'subscribed-to':
            this.saveSubscribedDevices(parsed.data);
            break;

          case 'device-data':
            this.handleDeviceData(
              parsed.data,
              showRawAQIData,
              mqttDocs,
              mQTTDocsSnapshot
            );
            break;
          case 'data-alerts':
            this.handleAlerts(
              parsed.data,
              devices,
              showRawAQIData,
              mqttDocs,
              mQTTDocsSnapshot,
              fields,
              newNotificationSubject
            );
            break;
          case 'error':
            this.handleError(parsed.data, this.connectionData);
            break;

          default:
            console.warn(`Unknown message type: ${parsed.type}`);
            break;
        }
      } catch (error) {
        console.error('Error processing SSE message: ', error);
        this.handleError(error, this.connectionData);
      }
    };

    this.eventSource.onerror = (e: any) => {
      console.error('SSE connection error: ', e);
      this.handleError('Error connecting to event source', this.connectionData);
    }
  }

  private parseIncomingMessage(message: string) {
    try {
      return JSON.parse(message);
    } catch (error) {
      console.error('Error while parsing the SSE message: ', error);
      return null;
    }
  }

  private updateRequestId(newRequestId: string): void {
    this.requestId = newRequestId;
  }

  private updateLastHeartbeat(timestamp: string): void {
    this.lastHeartbeat = timestamp;
  }

  private saveSubscribedDevices(devices: string[]): void {
    this.subscribedDevices = devices;
  }

  private handleError(errorData: any, connectionData: any): void {
    console.error('Received error message: ', errorData, "Request ID: ", this.requestId);
    this.notificationService.showNotification(
      errorData,
      AppConstants.CLOSE,
      'bottom',
      'right',
      'error'
    );
    this.eventSource.close();
    setTimeout(()=>{
      this.connect(connectionData.devices, connectionData.showRawAQIData, connectionData.mqttDocs, connectionData.fields, connectionData.mQTTDocsSnapshot, connectionData.newNotificationSubject);
    }, 5000)
  }

  private handleAlerts(
    alerts: any,
    devices: DeviceDetails[],
    showRawAQIData: boolean,
    mqttDocs: any,
    mQTTDocsSnapshot: Subject<DeviceDetails>,
    fields: DeviceField[],
    newNotificationSubject: Subject<any>,
  ): void {
    alerts.forEach((notification: any) => {
      if (notification.type > -1) {
        newNotificationSubject.next(JSON.parse(JSON.stringify(notification)));
        const deviceTypes: DeviceType[] =
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_DEV_TYPE
          );

        const allUnits = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        ).units;

        let deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
          deviceTypes,
          devices,
          notification.deviceId
        );
        if (deviceTypeId) {
          fields = DeviceUtil.getFieldsByDeviceType(
            deviceTypeId,
            allUnits,
            deviceTypes
          );
        }
        notification.isVisited = false;
        notification.label = DeviceUtil.getDeviceLabel(
          devices,
          notification.deviceId
        );
        notification.keyLabel = DeviceUtil.getFieldName(
          notification.key,
          fields
        );
        notification.unit = DeviceUtil.getFieldUnit(notification.key, fields);
        notification.value = DeviceUtil.getCFactoreData(
          notification.key,
          notification.value,
          fields
        );
        notification.timestamp = CommonUtil.getDisplayTime(notification.t);
        const subMessage: string =
          notification.operation === '>='
            ? AppConstants.ALERT_GREATER_THAN_EQUAL_TO
            : AppConstants.ALERT_LESS_THAN;

        const data: Array<any> = [];
        data.push({
          title: notification.label,
          alertContent: `${notification.keyLabel} ${subMessage} ${notification.value} ${notification.unit}`,
        });
        this.notificationService.generateBrowserNotifications(data);

        const message = `${notification.label} ${notification.keyLabel} ${subMessage} ${notification.value} ${notification.timestamp}`;

        this.notificationService.showNotification(
          message,
          'Close',
          'bottom',
          'right',
          'warning',
          5000
        );

        this.notificationService.notifications = [
          notification,
          ...this.notificationService.notifications,
        ];
        this.notificationService.userNotifications.next(true);
      }
    });
  }

  private handleDeviceData(
    parsed: any,
    showRawAQIData: boolean,
    mqttDocs: any,
    mQTTDocsSnapshot: Subject<DeviceDetails>
  ): void {
    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.commonService.getAllAQI();
    const allAqis: any = this.commonService.getAllAQIs();
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );

    if (DeviceUtil.hasValidIndexForDeviceType(parsed, deviceTypes)) {
      const aqiData = DeviceUtil.calculateAQI(
        parsed.payload,
        DeviceUtil.getBreakpoints(
          undefined,
          parsed.deviceType,
          deviceTypes,
          allAqi,
          allAqis,
          aqi
        )
      );
      if (parsed.payload !== undefined) {
        parsed.aqi = aqiData.aqi;
        parsed.aqiKey = aqiData.aqiKey;
        parsed.time = parsed.payload.d.t;
        if (
          aqiData.aqi !== null &&
          aqiData.aqi !== undefined &&
          showRawAQIData
        ) {
          parsed.payload.d.aqi = aqiData.aqi;
        }
      }
    }

    const userUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
    DeviceUtil.convertUnits(
      parsed.payload,
      userUnits[DeviceUtil.getDeviceTypeId(parsed.deviceType, deviceTypes)!]
    );

    mqttDocs[parsed.deviceId] = parsed;
    mQTTDocsSnapshot.next(parsed);
  }

  disconnect(eventSource: EventSource): void {
    if (!eventSource) return;
    eventSource.close();
  }
}
