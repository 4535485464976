import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import {
  CompactType,
  DisplayGrid,
  Draggable,
  GridsterComponentInterface,
  GridsterConfig,
  GridsterItem,
  GridType,
  PushDirections,
  Resizable,
} from 'angular-gridster2';
import { map, Subject, Subscription, takeUntil } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { Widget } from 'src/app/shared/models/widget/widget';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { sortList } from 'src/app/shared/utils/array-utils';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { AqiTrendsWidgetComponent } from './components/aqi-trends-widget/aqi-trends-widget.component';
import { HourlyDataWidgetComponent } from './components/hourly-data-widget/hourly-data-widget.component';
import { LiveDataWidgetComponent } from './components/live-data-widget/live-data-widget.component';
import { LocationWidgetComponent } from './components/location-widget/location-widget.component';
import { NoiseLevelWidgetComponent } from './components/noise-level-widget/noise-level-widget.component';
import { PollutionRoseWidgetComponent } from './components/pollution-rose-widget/pollution-rose-widget.component';
import { PredictionWidgetComponent } from './components/prediction-widget/prediction-widget.component';
import { RainFloodWidgetComponent } from './components/rain-flood-widget/rain-flood-widget.component';
import { RealTimeWidgetComponent } from './components/real-time-widget/real-time-widget.component';
import { WindRoseWidgetComponent } from './components/wind-rose-widget/wind-rose-widget.component';
import { WidgetService } from './services/widget.service';

const MIN_ROWS = 4;
const MAX_ROWS = 1000000;
const MIN_COLS = 4;
const MAX_COLS = 50;
const MIN_ITEM_ROWS = 5;
const MAX_ITEM_ROWS = MAX_ROWS;
const MIN_ITEM_COLS = 5;
const MAX_ITEM_COLS = MAX_COLS;
const DEFAULT_ITEM_ROWS = 14;
const DEFAULT_ITEM_COLS = 25;

interface Safe extends GridsterConfig {
  draggable: Draggable;
  resizable: Resizable;
  pushDirections: PushDirections;
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  public intervalTracker!: NodeJS.Timer;
  public countResize: number = 0;
  public gridsterComp!: GridsterComponentInterface;
  private destroy$: Subject<void> = new Subject<void>();
  public isTourMode: boolean = false;

  public widgets!: Array<Widget>;
  private subscriptions: Subscription[] = [];

  private redrawChart: boolean = false;

  private _loading: number = 0;
  public get loading(): number {
    return this._loading;
  }
  public set loading(v: number) {
    this._loading = v;
    this.loadingState$.next(v);
  }

  private loadingState$: Subject<number> = new Subject<number>();

  private resizeObserver?: ResizeObserver;

  public options!: Safe;
  public widgetsConfig: { [key: number]: GridsterItem } = {};
  public widgetsConfigBackupBeforeEdit:
    | { [key: number]: GridsterItem }
    | undefined = undefined;

  public widgetComponentsMapping: Record<number, any> = {};

  private averageHours: AverageHour[] = CommonUtil.getAverageHours({
    valueInSeconds: true,
    includeMovingAvg: false,
    includeRaw: true,
  });

  constructor(
    private dashboardService: DashboardService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private themeService: NbThemeService,
    private loadrService: LoadrService,
    private customMomentService: CustomMomentService,
    private elementRef: ElementRef,
    private localStorageService: LocalStorageService,
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.themeService
        .onThemeChange()
        .pipe(
          map(({ name }) => name),
          takeUntil(this.destroy$)
        )
        .subscribe((themeName) => {
          try {
            this.loading++;
            setTimeout(() => {
              this.loading--;
            });
          } catch (e) {}
        })
    );

    this.widgetComponentsMapping = {
      1001: RealTimeWidgetComponent,
      1002: LocationWidgetComponent,
      1003: LiveDataWidgetComponent,
      1004: HourlyDataWidgetComponent,
      1005: WindRoseWidgetComponent,
      1006: PollutionRoseWidgetComponent,
      1007: RainFloodWidgetComponent,
      1008: NoiseLevelWidgetComponent,
      1009: AqiTrendsWidgetComponent,
      1010: PredictionWidgetComponent,
    };

    this.initializeGridster();

    this.subscriptions.push(
      this.dashboardService.dashboardTourStatus$.subscribe((res) => {
        this.isTourMode = res;
      })
    );

    this.subscriptions.push(
      this.dashboardService.resizeWidget$.subscribe((res) => {
        if (res) {
          this.countResize = 0;
          let allWidgetId = Object.keys(this.widgetsConfig);
          let widgetIdToManipulate = 0;

          //finding the widget with x and y equals to 0
          allWidgetId.forEach((id: any) => {
            if (
              this.widgetsConfig[id].x == 0 &&
              this.widgetsConfig[id].y == 0
            ) {
              widgetIdToManipulate = id;
            }
          });

          //changing the row and col of the widget to show resize effect
          this.intervalTracker = setInterval(() => {
            this.options.compactType = 'none';
            if (this.countResize < 4) {
              this.widgetsConfig[widgetIdToManipulate].cols -= 1;
              this.widgetsConfig[widgetIdToManipulate].rows -= 1;
              this.options.api?.optionsChanged?.();
              this.countResize++;
            } else if (this.countResize >= 4 && this.countResize <= 7) {
              this.widgetsConfig[widgetIdToManipulate].cols += 1;
              this.widgetsConfig[widgetIdToManipulate].rows += 1;
              this.changedOptions();
              this.countResize++;
            } else if (this.countResize >= 8) {
              if (this.intervalTracker!) clearInterval(this.intervalTracker);
              this.countResize = 0;
            }
          }, 150);
        } else {
          if (this.intervalTracker!) clearInterval(this.intervalTracker);
          this.revertWidgetsConfig();
          this.options = this.defaultGridsterOptions();
          this.changedOptions();
        }
      })
    );

    this.subscriptions.push(
      this.dashboardService.swapWidgets$.subscribe((res) => {
        let timeOut1;
        let timeOut2;
        let timeOut3;
        if (res) {
          this.options.compactType = 'none';
          let allWidgetId = Object.keys(this.widgetsConfig);
          let x = 0;
          let widgetToManipulate: number;

          allWidgetId.forEach((id: any) => {
            if (
              this.widgetsConfig[id].x === 0 &&
              this.widgetsConfig[id].y === 0
            ) {
              x = this.widgetsConfig[id].rows;
              widgetToManipulate = id;
            }
          });

          timeOut1 = setTimeout(() => {
            //adding the rows of the first widget to y of all other widgets to shift other widgets
            allWidgetId.forEach((id: any) => {
              if (
                this.widgetsConfig[id].x !== 0 ||
                this.widgetsConfig[id].y !== 0
              ) {
                this.widgetsConfig[id].y += x;
              }
            });
            this.changedOptions();

            timeOut2 = setTimeout(() => {
              //shifting the first widget to right to show moving widget effect
              if (widgetToManipulate) {
                this.widgetsConfig[widgetToManipulate].x =
                  this.gridsterComp.columns -
                  this.widgetsConfig[widgetToManipulate].cols;
                this.changedOptions();
                //moving other widgets to the available space
                timeOut3 = setTimeout(() => {
                  this.options.compactType = CompactType.CompactUpAndLeft;
                  this.changedOptions();
                }, 200);
              }
            }, 500);
          }, 500);
        } else {
          if (timeOut1) clearTimeout(timeOut1);
          if (timeOut2) clearTimeout(timeOut2);
          if (timeOut3) clearTimeout(timeOut3);
          this.revertWidgetsConfig();
          this.dashboardService.enableDisableWidgetsViewEditMode('edit');
          this.options = this.defaultGridsterOptions();
          this.changedOptions();
        }
      })
    );

    this.subscriptions.push(
      this.dashboardService.widgetsViewEditMode$.subscribe({
        next: (enabled: string) => {
          switch (enabled) {
            case 'edit': {
              this.widgetsConfigBackupBeforeEdit = JSON.parse(
                JSON.stringify(this.widgetsConfig)
              );
              this.options.draggable.enabled = true;
              this.options.resizable.enabled = true;
              break;
            }
            case 'save': {
              this.saveWidgetsConfig();
              this.options.draggable.enabled = false;
              this.options.resizable.enabled = false;
              break;
            }
            case 'reset_default': {
              this.resetWidgetsConfigToDefault();
              this.options.draggable.enabled = false;
              this.options.resizable.enabled = false;
              break;
            }
            case 'cancel': {
              this.revertWidgetsConfig();
              this.options.draggable.enabled = false;
              this.options.resizable.enabled = false;
              break;
            }
            default: {
              this.revertWidgetsConfig();
              this.options.draggable.enabled = false;
              this.options.resizable.enabled = false;
              break;
            }
          }
          this.changedOptions();
        },
      })
    );

    this.subscriptions.push(
      this.dashboardService.newWidgetsAdded$.subscribe({
        next: (newWidgets) => {
          let startY = Object.values(this.widgetsConfig).reduce(
            (prev, widgetConfig) => {
              return Math.max(widgetConfig.y + widgetConfig.rows, prev);
            },
            0
          );

          Object.assign(
            this.widgetsConfig,
            Object.fromEntries(
              sortList(newWidgets, 'ASC', 'id').map((widgetInfo, i: number) => {
                let widgetConfig = {
                  rows: DEFAULT_ITEM_ROWS,
                  cols: DEFAULT_ITEM_COLS,
                  x: (i % 2) * DEFAULT_ITEM_COLS,
                  y: startY,
                  widgetInfo: {
                    widgetId: widgetInfo.id,
                    widgetTitle: widgetInfo.title,
                  },
                };
                startY += (i % 2) * DEFAULT_ITEM_ROWS;
                return [widgetInfo.id, widgetConfig];
              })
            )
          );
          this.saveWidgetsConfig();
        },
      })
    );

    this.subscriptions.push(
      this.deviceService.mqttDocsSnapshot$.subscribe({
        next: (res: DeviceDetails) => {
          if (this.widgetService.device?.deviceId === res?.deviceId) {
            let currentTimestamp =
              this.customMomentService.moment.unix(res.payload.d.t).unix() -
              2 * 3600;
            this.widgetService.liveDeviceData.push(res);
            this.widgetService.liveDeviceData =
              this.widgetService.liveDeviceData.filter(
                (d) => currentTimestamp <= d.payload.d.t
              );
            this.updateLiveDataWidget(this.widgetService.liveDeviceData);
            this.widgetService.realTimeDeviceData = [res];
            this._cdr.detectChanges();
          }
        },
        error: (err) => {
          console.info('Errorrr', err);
        },
      })
    );
  }

  initializeGridster(fetchData?: boolean) {
    this.options = this.defaultGridsterOptions();
    this.dashboardService.enableDisableWidgetsViewEditMode('cancel');

    this.widgetsConfig = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.settings?.widgetsConfig?.[
      this.deviceService.currentDeviceType.deviceTypeId
    ];

    if (!this.widgetsConfig) {
      this.widgetsConfig = this.defaultWidgetsConfig();
    }

    Object.entries(this.widgetComponentsMapping).forEach(
      ([widgetId, widgetComponent]: any) => {
        this.widgetsConfig[widgetId] &&
          (this.widgetsConfig[widgetId].widgetComponent = widgetComponent);
      }
    );

    this.changedOptions();

    this.loading++;
    this.subscriptions.push(
      this.dashboardService.fetchWidgets().subscribe((widgets: Widget[]) => {
        this.loading--;
        this.widgets = widgets;
        this.widgetsConfig = Object.fromEntries(
          Object.entries(this.widgetsConfig).map(
            ([widgetId, widgetConfig], i: number) => {
              let widget = widgets.find(
                (wid) => '' + wid.widgetId === widgetId
              );
              if (widget && widgetConfig) {
                this.widgetsConfig[widget.widgetId].widgetInfo = {};
                this.widgetsConfig[widget.widgetId].widgetInfo.widgetTitle =
                  widget.label;
                this.widgetsConfig[widget.widgetId].widgetInfo.widgetSubTitle =
                  widget.config.subTitle;
                this.widgetsConfig[widget.widgetId].widgetInfo.widgetId =
                  widget.widgetId;
                this.widgetsConfig[widget.widgetId].widgetInfo.show = false;

                switch (widget.widgetId) {
                  case 1003:
                  case 1004:
                  case 1010: {
                    this.widgetsConfig[
                      widget.widgetId
                    ].widgetInfo.chartTypeConfig = {
                      selectedValue: 'line',
                      options: [
                        {
                          label: 'Line Chart',
                          icon: 'show_chart',
                          value: 'line',
                        },
                        {
                          label: 'Column Chart',
                          icon: 'bar_chart',
                          value: 'column',
                        },
                      ],
                    };
                    this.widgetsConfig[widget.widgetId].widgetInfo.optionsMenu =
                      {
                        options: [
                          {
                            label: 'Static Y-axis',
                            icon: 'show_chart',
                            value: 'static_y_axis',
                            showCheckbox: true,
                            state: false,
                          },
                        ],
                      };
                    this.widgetsConfig[
                      widget.widgetId
                    ].widgetInfo.chartTypeConfig.selectedValue = 'column';
                    if (widget.widgetId === 1003) {
                      this.widgetsConfig[
                        widget.widgetId
                      ].widgetInfo.chartTypeConfig.selectedValue = 'line';
                    }
                    break;
                  }
                  case 1005:
                  case 1006: {
                    this.widgetsConfig[
                      widget.widgetId
                    ].widgetInfo.directionConfig = {
                      selectedValue: 'from',
                      options: [
                        {
                          label: 'Direction To',
                          value: 'to',
                        },
                        {
                          label: 'Direction From',
                          value: 'from',
                        },
                      ],
                    };
                  }
                }
              }
              return [widgetId, widgetConfig];
            }
          )
        );
        if (fetchData) {
          this.setupData(
            Object.keys(this.widgetsConfig).map((widgetId: string) =>
              parseInt(widgetId)
            )
          );
        }
      })
    );
  }

  defaultGridsterOptions(): Safe {
    return {
      gridType: GridType.ScrollVertical,
      rowHeightRatio: 1,
      compactType: CompactType.CompactUpAndLeft,
      margin: 8,
      outerMargin: true,
      outerMarginTop: 16,
      outerMarginRight: 16,
      outerMarginBottom: 16,
      outerMarginLeft: 16,
      useTransformPositioning: true,
      mobileBreakpoint: 1024,
      useBodyForBreakpoint: true,
      minCols: MIN_COLS,
      maxCols: MAX_COLS,
      minRows: MIN_ROWS,
      maxRows: MAX_ROWS,
      minItemCols: MIN_ITEM_COLS,
      maxItemCols: MAX_ITEM_COLS,
      minItemRows: MIN_ITEM_ROWS,
      maxItemRows: MAX_ITEM_ROWS,
      maxItemArea: MAX_ITEM_COLS * MAX_ITEM_ROWS,
      minItemArea: MIN_ITEM_COLS * MIN_ITEM_ROWS,
      defaultItemCols: DEFAULT_ITEM_ROWS,
      defaultItemRows: DEFAULT_ITEM_COLS,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 0,
      emptyCellDragMaxRows: 0,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: true,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
      itemInitCallback: this.onItemInit(),
      itemResizeCallback: this.onItemResize(),
      itemChangeCallback: this.onGridChange.bind(this),
      gridSizeChangedCallback: this.gridSizeChange.bind(this),
    };
  }

  gridSizeChange(gridster: GridsterComponentInterface) {
    this.gridsterComp = gridster;
  }

  defaultWidgetsConfig() {
    let config = {
      1001: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 0,
        y: 0,
        widgetInfo: {
          widgetId: 1001,
          widgetTitle: '',
          widgetSubTitle: '',
        },
        widgetComponent: RealTimeWidgetComponent,
      },
      1002: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 1,
        y: 0,
        widgetInfo: {
          widgetId: 1002,
          widgetTitle: '',
          widgetSubTitle: '',
        },
        widgetComponent: LocationWidgetComponent,
      },
      1003: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 0,
        y: 1,
        widgetInfo: {
          widgetId: 1003,
          widgetTitle: '',
          widgetSubTitle: '',
          chartTypeConfig: {
            selectedValue: 'line',
            options: [
              {
                label: 'Line Chart',
                icon: 'show_chart',
                value: 'line',
              },
              {
                label: 'Column Chart',
                icon: 'bar_chart',
                value: 'column',
              },
            ],
          },
          show: this.redrawChart,
        },
        widgetComponent: LiveDataWidgetComponent,
      },
      1004: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 1,
        y: 1,
        widgetInfo: {
          widgetId: 1004,
          widgetTitle: '',
          widgetSubTitle: '',
          chartTypeConfig: {
            selectedValue: 'column',
            options: [
              {
                label: 'Line Chart',
                icon: 'show_chart',
                value: 'line',
              },
              {
                label: 'Column Chart',
                icon: 'bar_chart',
                value: 'column',
              },
            ],
          },
          show: this.redrawChart,
        },
        widgetComponent: HourlyDataWidgetComponent,
      },
      1005: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 0,
        y: 2,
        widgetInfo: {
          widgetId: 1005,
          widgetTitle: '',
          widgetSubTitle: '',
          directionConfig: {
            selectedValue: 'from',
            options: [
              {
                label: 'Direction To',
                value: 'to',
              },
              {
                label: 'Direction From',
                value: 'from',
              },
            ],
          },
          show: this.redrawChart,
        },
        widgetComponent: WindRoseWidgetComponent,
      },
      1006: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 1,
        y: 2,
        widgetInfo: {
          widgetId: 1006,
          widgetTitle: '',
          widgetSubTitle: '',
          directionConfig: {
            selectedValue: 'from',
            options: [
              {
                label: 'Direction To',
                value: 'to',
              },
              {
                label: 'Direction From',
                value: 'from',
              },
            ],
          },
          show: this.redrawChart,
        },
        widgetComponent: PollutionRoseWidgetComponent,
      },
      1007: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 0,
        y: 3,
        widgetInfo: {
          widgetId: 1007,
          widgetTitle: '',
          widgetSubTitle: '',
          show: this.redrawChart,
        },
        widgetComponent: RainFloodWidgetComponent,
      },
      1008: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 0,
        y: 3,
        widgetInfo: {
          widgetId: 1008,
          widgetTitle: '',
          widgetSubTitle: '',
          show: this.redrawChart,
        },
        widgetComponent: NoiseLevelWidgetComponent,
      },
      1009: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 1,
        y: 3,
        widgetInfo: {
          widgetId: 1009,
          widgetTitle: '',
          widgetSubTitle: '',
          show: this.redrawChart,
        },
        widgetComponent: AqiTrendsWidgetComponent,
      },
      1010: {
        cols: DEFAULT_ITEM_COLS,
        rows: DEFAULT_ITEM_ROWS,
        x: 0,
        y: 4,
        widgetInfo: {
          widgetId: 1010,
          widgetTitle: '',
          widgetSubTitle: '',
          chartTypeConfig: {
            selectedValue: 'column',
            options: [
              {
                label: 'Line Chart',
                icon: 'show_chart',
                value: 'line',
              },
              {
                label: 'Column Chart',
                icon: 'bar_chart',
                value: 'column',
              },
            ],
          },
          show: this.redrawChart,
        },
        widgetComponent: PredictionWidgetComponent,
      },
    };

    return this.setDefaultWidgetsPlacement(
      Object.fromEntries(
        sortList(Object.entries(config), 'ASC', '0').filter(
          ([widgetId], i: number) =>
            this.deviceService.currentDeviceType.widgets.includes(
              parseInt(widgetId)
            )
        )
      )
    );
  }

  setDefaultWidgetsPlacement(
    widgetsConfig: Record<string, GridsterItem>,
    setDefaultHeightWidth: boolean = false
  ): Record<string, GridsterItem> {
    return Object.fromEntries(
      sortList(Object.entries(widgetsConfig), 'ASC', '0').map(
        ([widgetId, widgetConfig], i: number) => {
          if (setDefaultHeightWidth) {
            widgetConfig.rows = DEFAULT_ITEM_ROWS;
            widgetConfig.cols = DEFAULT_ITEM_COLS;
          }
          widgetConfig.x = (i % 2) * DEFAULT_ITEM_COLS;
          widgetConfig.y = Math.floor(i / 2) * DEFAULT_ITEM_ROWS;
          return [widgetId, widgetConfig];
        }
      )
    );
  }

  changedOptions(): void {
    this.options.api?.optionsChanged?.();
  }

  onItemInit() {
    let that = this;
    return (widgetConfig: any) => {
      if (
        !that.widgetsConfig[widgetConfig.widgetInfo.widgetId].widgetInfo.show
      ) {
        that.widgetsConfig[widgetConfig.widgetInfo.widgetId].widgetInfo.show =
          true;
      }
    };
  }

  onItemResize() {
    let that = this;
    return (widgetConfig: any) => {
      that.onResize(widgetConfig.widgetInfo.widgetId);
    };
  }

  removeWidget(item: any): void {
    !this.isTourMode && delete this.widgetsConfig[item.widgetId];
  }

  addWidget(widgetConfig: any): void {
    this.widgetsConfig[widgetConfig.widgetInfo.widgetId] = widgetConfig;
  }

  onGridChange(item: GridsterItem, itemComponent: any) {
    console.info('onGridChange', item, itemComponent);
  }

  saveWidgetsConfig(reloadView: boolean = true) {
    let widgetsConfig =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.widgetsConfig ?? {};
    widgetsConfig[this.deviceService.currentDeviceType.deviceTypeId] =
      this.widgetsConfig;
    if (JSON.stringify(this.widgetsConfig) === '{}') {
      delete widgetsConfig[this.deviceService.currentDeviceType.deviceTypeId];
    }
    this.loading++;
    this.dashboardService.saveWidgetsConfig(widgetsConfig).subscribe({
      next: (res) => {
        if (reloadView) {
          this.initializeGridster(true);
        }
        this.loading--;
        this.loadrService.removeLoader();
      },
    });
  }

  resetWidgetsConfigToDefault() {
    // delete this.widgetsConfig[
    //   this.deviceService.currentDeviceType.deviceTypeId
    // ];
    this.widgetsConfig = {};
    // this.widgetsConfig = this.setDefaultWidgetsPlacement(
    //   this.widgetsConfig,
    //   true
    // );
    this.saveWidgetsConfig();
  }

  revertWidgetsConfig() {
    let widgetsConfig =
      this.widgetsConfigBackupBeforeEdit ??
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.widgetsConfig?.[
        this.deviceService.currentDeviceType.deviceTypeId
      ];

    if (widgetsConfig)
      this.widgetsConfig = JSON.parse(JSON.stringify(widgetsConfig));
    else this.widgetsConfig = this.defaultWidgetsConfig();
    this.widgetsConfigBackupBeforeEdit = undefined;
    this.changedOptions();
  }

  private redrawWidget(widgetId: number) {
    // if (!this.widgetsConfig?.[widgetId]?.widgetInfo?.hasOwnProperty?.('show')) {
    //   return;
    // }
    // this.widgetsConfig[widgetId].widgetInfo.show = false;
    // this._cdr.detectChanges();
    // setTimeout(() => {
    //   this.widgetsConfig[widgetId].widgetInfo.show = true;
    //   this._cdr.detectChanges();
    // });
    this.widgetService.redrawWidget(widgetId);
  }

  count: number = 0;

  onResize(widgetId?: number) {
    this.count++;
    try {
      if (widgetId) {
        this.redrawWidget(widgetId);
      } else {
        this.redrawWidget(1003);
        this.redrawWidget(1004);
        this.redrawWidget(1005);
        this.redrawWidget(1006);
        this.redrawWidget(1008);
        this.redrawWidget(1009);
        this.redrawWidget(1010);
      }
    } catch (error) {
      console.info('Widget redraw error', error);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.deviceService.localStorageDeviceUpdate$.subscribe((res) => {
        this.setCurrentDevice();
      })
    );
    this.setCurrentDevice();
    this._cdr.detectChanges();
  }

  setCurrentDevice() {
    this.initializeGridster();
    this.getWidgets();
    if (this.deviceService.currentDevice) {
      this.widgetService.device = this.deviceService.currentDevice;
      this._cdr.detectChanges();

      this.setupData(
        Object.entries(this.widgetsConfig)
          .filter(
            ([widgetId, widgetConfig]: [string, GridsterItem]) =>
              widgetId && widgetConfig?.widgetInfo
          )
          .map(([widgetId]: [string, GridsterItem]) => parseInt(widgetId))
      );
    }
  }

  getWidgets() {
    if (this.deviceService.currentDeviceType?.widgets?.length) {
      this.widgetsConfig = Object.fromEntries(
        Object.entries(this.widgetsConfig).filter(([widgetId]) =>
          this.deviceService?.currentDeviceType?.widgets?.includes?.(
            parseInt(widgetId)
          )
        )
      );
    }
  }

  setupData(widgetIds: number[]) {
    let uiLoad = this.loadingState$.subscribe((res) => {
      if (res === 0) {
        this.dashboardService.uiLoaded.next(true);
        uiLoad.unsubscribe();
      }
    });

    this.saveWidgetsConfig(false);

    this.widgetService.fields = this.deviceService.fields;
    this.widgetService.limits = this.deviceService.limits;

    let deviceTypes = this.commonService.getAllDeviceTypes();
    let allAqi = this.commonService.getAllAQI();
    let allAqis = this.commonService.getAllAQIs();

    this.widgetService.aqiIndexColor = DeviceUtil.aqiColorArray(
      deviceTypes,
      allAqi,
      allAqis,
      undefined,
      this.widgetService.device!.deviceType
    );
    this._cdr.detectChanges();

    if (
      this.deviceService.mqttDocs &&
      this.deviceService.mqttDocs[this.widgetService.device?.deviceId!]
    ) {
      this.widgetService.realTimeDeviceData = [
        this.deviceService.mqttDocs[this.widgetService.device?.deviceId!],
      ];
    }

    if (
      widgetIds.includes(1001) ||
      widgetIds.includes(1003) ||
      widgetIds.includes(1008)
    ) {
      this.loading++;
      this.dashboardService
        .fetchLiveData({
          device: this.deviceService.currentDevice!,
          average: this.averageHours.find(
            (averageHour: AverageHour) => averageHour.value === 0
          ),
        })
        .subscribe({
          next: (deviceDetails) => {
            this.updateLiveDataWidget(deviceDetails);
          },
          error: (err) => {
            this.updateLiveDataWidget([]);
            console.info('API Response Error: ', err);
          },
          complete: () => {
            this.loading--;
          },
        });
    }

    if (
      widgetIds.includes(1004) ||
      widgetIds.includes(1005) ||
      widgetIds.includes(1006) ||
      widgetIds.includes(1007)
    ) {
      this.loading++;
      this.dashboardService
        .fetchHourlyData({
          device: this.deviceService.currentDevice!,
          average: this.averageHours.find(
            (averageHour: AverageHour) => averageHour.value === 3600
          ),
        })
        .subscribe({
          next: (deviceDetails) => {
            this.updateHourlyDataWidget(deviceDetails);
            this.updateWindRoseWidget(deviceDetails);
          },
          error: (err) => {
            this.updateHourlyDataWidget([]);
            this.updateWindRoseWidget([]);
            console.info('API Response Error: ', err);
          },
          complete: () => {
            this.loading--;
          },
        });
    }

    if (widgetIds.includes(1009)) {
      this.loading++;
      this.dashboardService
        .fetchAqiTrendData({
          device: this.deviceService.currentDevice!,
          average: this.averageHours.find(
            (averageHour: AverageHour) => averageHour.value === 3600
          ),
        })
        .subscribe({
          next: (deviceDetails) => {
            this.updateAQITrendWidget(deviceDetails);
          },
          error: (err) => {
            this.updateAQITrendWidget([]);
            console.info('API Response Error: ', err);
          },
          complete: () => {
            this.loading--;
          },
        });
    }

    if (widgetIds.includes(1010)) {
      this.loading++;
      this.dashboardService
        .fetchPredictionData({
          device: this.widgetService.device,
        })
        .subscribe({
          next: (deviceDetails) => {
            this.updatePredictionWidget(deviceDetails);
          },
          error: (err) => {
            this.updatePredictionWidget([]);
            console.info('API Response Error: ', err);
          },
          complete: () => {
            this.loading--;
          },
        });
    }
  }

  updateLiveDataWidget(deviceDetails: DeviceDetails[]) {
    this.widgetService.liveDeviceData = deviceDetails;
    this._cdr.detectChanges();
  }

  updateHourlyDataWidget(deviceDetails: DeviceDetails[]) {
    this.widgetService.hourlyDeviceData = deviceDetails;
  }

  updateWindRoseWidget(deviceDetails: DeviceDetails[]) {
    const units =
      this.commonService.getAllUnits()[
        this.deviceService.currentDeviceType.deviceTypeId
      ];
    this.widgetService.windRoseData.to = DeviceUtil.roseConverter(
      deviceDetails,
      units,
      undefined,
      true
    );
    this.widgetService.windRoseData.from = DeviceUtil.roseConverter(
      deviceDetails,
      units,
      undefined,
      false
    );
    this._cdr.detectChanges();
  }

  updateAQITrendWidget(deviceDetails: DeviceDetails[]) {
    this.widgetService.aqiTrendsData = deviceDetails;
    this._cdr.detectChanges();
  }

  updatePredictionWidget(deviceDetails: DeviceDetails[]) {
    this.widgetService.predictionData = deviceDetails;
    this._cdr.detectChanges();
  }

  setDirection(id: number, value: 'to' | 'from') {
    setTimeout(() => {
      this.widgetsConfig[id].widgetInfo = {
        ...this.widgetsConfig[id].widgetInfo,
        directionConfig: {
          selectedValue: value ?? 'from',
          options: [
            {
              label: 'Direction To',
              value: 'to',
            },
            {
              label: 'Direction From',
              value: 'from',
            },
          ],
        },
      };
      this._cdr.detectChanges();
    });
  }

  hideChart(chart: number) {
    delete this.widgetsConfig[chart];
    this.widgetsConfig = { ...this.widgetsConfig };
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.elementRef.nativeElement);
      this.resizeObserver.disconnect();
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
