import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import {
  DisplayConstants,
  ScreenTypeOption,
} from 'src/app/shared/constants/display-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { Cluster } from 'src/app/shared/models/cluster.ts/cluster';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import {
  DisplayDetails,
  DisplayScreen,
} from 'src/app/shared/models/display/display-details';
import { ClusterService } from 'src/app/shared/services/cluster.services';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DisplayService } from 'src/app/shared/services/display.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-display-form',
  templateUrl: './display-form.component.html',
  styleUrls: ['./display-form.component.scss'],
})
export class DisplayFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  private _deviceTypes: DeviceType[] = [];
  public get deviceTypes(): DeviceType[] {
    return this._deviceTypes;
  }
  public get deviceTypesObj(): { [key: string]: DeviceType } {
    return Object.fromEntries(this._deviceTypes.map((dt) => [dt.key, dt]));
  }
  public set deviceTypes(v: DeviceType[]) {
    this._deviceTypes = v;
  }

  public selectedIconSize: any = null;
  public displayDetailsForm!: FormGroup;
  public screenTypeForm!: FormGroup;
  public screenDetailsForm!: FormGroup;

  public isEditMode: boolean;

  displayTitle: string = 'Add Display';
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  defaultColumns: any[] = [
    {
      columnDef: 'actions',
      header: '',
      cell: (element: DisplayScreen) => `${element.screenId}`,
      parameter: false,
      selected: false,
      icon: 'more_vert',
      options: [
        {
          label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
          action: (screen: DisplayScreen) => this.editScreen(screen),
        },
        {
          label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
          action: (screen: DisplayScreen) =>
            this.openDialog(screen, () => this.deleteScreen(screen)),
          disable: (element: DisplayScreen) =>
            (this.data?.screens?.length ?? 0) <= 1,
        },
      ],
    },
    {
      columnDef: 'screenId',
      header: 'S.no',
      cell: (element: DisplayScreen) => `${element.screenId}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'screen_label',
      header: 'Screen',
      cell: (element: DisplayScreen) => `${element.label}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'devices',
      header: 'Devices',
      cell: (element: DisplayScreen) => `${element.body.devices}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'slide_interval',
      header: 'Slide Interval',
      cell: (element: DisplayScreen) =>
        `${
          Math.round(
            ((this.data?.view_time ?? 60) / (this.data?.screens?.length ?? 1)) *
              100
          ) / 100
        }`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'api_interval',
      header: 'API Interval',
      cell: (element: DisplayScreen) => `${this.data?.api_time ?? 900}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'avg_time',
      header: 'Average Time',
      cell: (element: DisplayScreen) => `${element.body.avg_time}`,
      parameter: false,
      selected: false,
    },
  ];
  displayedColumns: string[] = [];

  public showLoader: boolean;
  public submitBtn: string = 'Next';
  public backBtn: string = 'Cancel';
  public isNextStep: boolean = false;
  public devices: DeviceDetails[] = [];

  private _clusters: Cluster[] = [];
  public get clusters(): Cluster[] {
    return this._clusters;
  }
  public set clusters(v: Cluster[]) {
    if (v) {
      v = v.map((cluster) => ({ ...cluster, devcieId: cluster.clusterId }));
    }
    this._clusters = v;
  }

  public parameters: string[] = [];
  public averageTimeOptions: AverageHour[] = [];
  public subscriptions: Subscription[] = [];

  public selectedDeviceTypeFields: DeviceField[] = [];
  public selectedDeviceTypeLimits: FieldLimit[] = [];

  private userInfo: any;
  private user = this.commonService.getUser();

  private _screenTypeOptions: ScreenTypeOption[] =
    DisplayConstants.SCREEN_TYPES;
  private _screenTypeOptionsObj!: Record<number, ScreenTypeOption>;
  public get screenTypeOptions(): ScreenTypeOption[] {
    return this._screenTypeOptions;
  }
  public get screenTypeOptionsObj(): Record<number, ScreenTypeOption> {
    return this._screenTypeOptionsObj;
  }

  public set screenTypeOptions(v: ScreenTypeOption[]) {
    this._screenTypeOptions = v.filter((screenType: ScreenTypeOption) => {
      return screenType.id !== 3 || this.user.modules.includes(1009);
    });
    this._screenTypeOptionsObj = Object.fromEntries(
      this._screenTypeOptions.map((screenTypeOption: ScreenTypeOption) => [
        screenTypeOption.id,
        screenTypeOption,
      ])
    );
  }

  public dialogCompInstance!: ConfirmationPopupComponent;

  public selectedScreenType: ScreenTypeOption | undefined = undefined;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private displayService: DisplayService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private clusterService: ClusterService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private confirmationPopupService: ConfirmationPopupService,
    @Inject(MTX_DRAWER_DATA) public data: DisplayDetails | undefined
  ) {
    this.isEditMode = false;
    this.showLoader = false;
  }

  ngOnInit(): void {
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.devices = this.deviceService.registeredDevices!;

    this.averageTimeOptions = this.commonService.getAverageHours({
      valueInSeconds: true,
      includeMovingAvg: false,
      includeRaw: false,
    });

    this.userInfo = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    this.isEditMode = !!this.data;
    if (this.isEditMode) {
      this.displayTitle = 'Edit Display';
      this.submitBtn = 'Add Screen';
    }
    this.buildForm(this.isEditMode);
    this.addFormSubscription();

    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.patchForm(this.data);
    this.loadData();
  }

  //this function builds the form based on condition:- 1) user is editing device or 2) user is adding device
  buildForm(isEditMode: boolean = false) {
    this.displayDetailsForm = this.formBuilder.group({
      api_time: [900, [Validators.required]],
      enable: [true, [Validators.required]],
      plugin: [false, [Validators.required]],
      userId: [this.userInfo.userId, [Validators.required]],
      display_label: [null, [Validators.required, Validators.minLength(1)]], // User input
      view_time: [60, [Validators.required]],
    });

    if (!isEditMode) {
      this.displayDetailsForm
        .get('display_label')
        ?.addValidators([
          Validators.pattern(
            this.createRegexFromArray(
              this.displayService.displays.map(
                (display) => display.display_label
              ) ?? []
            )
          ),
        ]);
    }

    this.screenTypeForm = this.formBuilder.group({
      deviceType: [null, [Validators.required]],
      screenType: [null, [Validators.required]],
      screenId: [null],
    });

    this.screenDetailsForm = this.formBuilder.group({});
  }

  patchForm(data: DisplayDetails | undefined) {
    if (data) {
      this.displayDetailsForm.patchValue(data);
    }
  }

  private patchScreenDetailsForm(screen: DisplayScreen) {
    if (!screen) return;

    // Clear existing infoScreen form array
    const infoScreenFormArray = this.screenDetailsForm.get(
      'infoScreen'
    ) as FormArray;
    infoScreenFormArray.clear();

    // Patch the form values
    this.screenDetailsForm.patchValue({
      ...screen,
      type: screen.type,
      label: screen.label,
      deviceType: screen.deviceType,
      header: screen.header,
      topLeft: screen.topLeft,
      topRight: screen.topRight,
      logoUrl: screen.logoUrl,
      footer: screen.footer,
      body: screen.body,
      screen: screen.screenId,
    });

    // Patch infoScreen data if it exists
    if (screen.infoScreen && screen.infoScreen.length > 0) {
      screen.infoScreen.forEach((section: any) => {
        const sectionGroup = this.formBuilder.group({
          subHeader: [section.subHeader, [Validators.required]],
          points: this.formBuilder.array(
            section.points.map((point: any) =>
              this.formBuilder.control(point, [Validators.required])
            )
          ),
        });
        infoScreenFormArray.push(sectionGroup);
      });
    }
  }

  private buildScreenDetailsForm(
    screenType: ScreenTypeOption,
    deviceType: string,
    screenNumber: number
  ) {
    this.screenDetailsForm = this.formBuilder.group({});
    if (screenType.id === 0) {
      // For screen type 0
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [null], // User input
        topRight: [null], // User input
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [
            null,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(5),
            ],
          ], // User input
          avg: [true, [Validators.required]],
          avg_time: [null, [Validators.required]], // User input
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 1) {
      // For screen type 1
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [null], // User input
        topRight: [null], // User input
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [
            null,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(5),
            ],
          ], // User input
          avg: [true, [Validators.required]],
          avg_time: [null, [Validators.required]], // User input
          parameter: [''], // User input
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 2) {
      // For screen type 2
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [null], // User input
        topRight: [null], // User input
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [null, [Validators.required, Validators.minLength(1)]], // User input
              payload: this.formBuilder.group({}),
            }),
            this.formBuilder.group({
              title: [null, [Validators.required, Validators.minLength(1)]], // User input
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(2),
            ],
          ], // User input
          avg: [false, [Validators.required]],
          avg_time: [null, [Validators.required]], // User input
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 3) {
      // For screen type 3
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [''],
        }),
        topLeft: [''],
        topRight: [''],
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [null, [Validators.required]], // User input
          avg: [false, [Validators.required]],
          avg_time: [3600],
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 4) {
      // For screen type 4
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [''],
        topRight: [''],
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [null, [Validators.required]], // User input
          avg: [false, [Validators.required]],
          avg_time: [3600, [Validators.required]],
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 5) {
      // For screen type 5
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [''], // User input
        topRight: [''], // User input
        logoUrl: [null, [Validators.required, Validators.minLength(1)]], // User input
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [[]],
          avg: [true, [Validators.required]],
          avg_time: [3600, [Validators.required]],
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 6) {
      // For screen type 6
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [''], // User input
        topRight: [''], // User input
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: this.formBuilder.array(
            [this.formBuilder.control(null, [Validators.required])],
            [Validators.minLength(1), Validators.maxLength(1)]
          ), // User input
          avg: [true, [Validators.required]],
          avg_time: [3600, [Validators.required]],
          parameter: ['', [Validators.required]], // User input
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 7) {
      // For screen type 7
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [''],
        topRight: [''],
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: this.formBuilder.array(
            [this.formBuilder.control(null, [Validators.required])],
            [Validators.minLength(1), Validators.maxLength(1)]
          ), // User input
          avg: [true, [Validators.required]],
          avg_time: [3600, [Validators.required]],
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    } else if (screenType.id === 8) {
      // For screen type 8 (Information Screen)
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [null, [Validators.required, Validators.minLength(1)]], // User input
        }),
        topLeft: [''],
        topRight: [''],
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [[]],
          avg: [true, [Validators.required]],
          avg_time: [3600, [Validators.required]],
          parameter: [''],
        }),
        infoScreen: this.formBuilder.array([]), // Will be populated with sections
        screen: [screenNumber, [Validators.required]],
      });

      // Add default section with sub-header and points
      this.addNewSubHeaderSectionInInfoScreen();
    } else if (screenType.id === 9) {
      // For screen type 9
      this.screenDetailsForm = this.formBuilder.group({
        type: [screenType.id, [Validators.required]],
        label: [screenType.title, [Validators.required]],
        deviceType: [deviceType, [Validators.required]],
        header: this.formBuilder.group({
          text: [''],
        }),
        topLeft: [''],
        topRight: [''],
        logoUrl: [''],
        footer: this.formBuilder.group({
          scroll: [true, [Validators.required]],
          text: [''],
        }),
        body: this.formBuilder.group({
          data: this.formBuilder.array([
            this.formBuilder.group({
              title: [''],
              payload: this.formBuilder.group({}),
            }),
          ]),
          devices: [
            null,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(5),
            ],
          ], // User input
          avg: [true, [Validators.required]],
          avg_time: [null, [Validators.required]], // User input
          parameter: [''], // User input
        }),
        infoScreen: this.formBuilder.array([]),
        screen: [screenNumber, [Validators.required]],
      });
    }
  }

  addNewSubHeaderSectionInInfoScreen() {
    const infoScreenFormArray = this.screenDetailsForm.get(
      'infoScreen'
    ) as FormArray;
    const newSection = this.formBuilder.group({
      subHeader: [null, [Validators.required]], // Sub-header text input
      points: this.formBuilder.array([]), // Points array for this section
    });

    infoScreenFormArray.push(newSection);

    // Add at least one point by default
    this.addNewPointInSubHeaderSectionInInfoScreen(
      infoScreenFormArray.length - 1
    );
  }

  addNewPointInSubHeaderSectionInInfoScreen(subHeaderIndex: number) {
    const pointsArray = (this.screenDetailsForm.get('infoScreen') as FormArray)
      .at(subHeaderIndex)
      .get('points') as FormArray;

    pointsArray.push(this.formBuilder.control(null, [Validators.required]));
  }

  removeSubHeaderSection(sectionIndex: number) {
    const infoScreenArray = this.screenDetailsForm.get(
      'infoScreen'
    ) as FormArray;
    infoScreenArray.removeAt(sectionIndex);
  }

  removePoint(sectionIndex: number, pointIndex: number) {
    const pointsArray = (this.screenDetailsForm.get('infoScreen') as FormArray)
      .at(sectionIndex)
      .get('points') as FormArray;

    pointsArray.removeAt(pointIndex);
  }

  private getDisplayDetails(displayId: number) {
    this.loadTable.next(false);
    this.displayService.getDisplayById(displayId).subscribe({
      next: (res: DisplayDetails) => {
        this.data = res;
        this.isNextStep =
          this.displayDetailsForm.valid && this.data?.screens?.[0]?.type !== 3;
        this.loadTable.next(true);
      },
    });
  }

  private editScreen(screen: DisplayScreen) {
    this.screenTypeForm
      .get('deviceType')
      ?.setValue(this.deviceTypesObj[screen.deviceType]);
    this.screenTypeForm
      .get('screenType')
      ?.setValue(this.screenTypeOptionsObj[screen.type]);

    // Build the form first
    this.buildScreenDetailsForm(
      this.screenTypeOptionsObj[screen.type],
      screen.deviceType,
      screen.screenId || 0
    );

    // Then patch the values
    setTimeout(() => {
      this.patchScreenDetailsForm(screen);
      this.submitAndNext(false, screen.screenId);
    });

  }

  private deleteScreen(screen: DisplayScreen) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;

    if (this.data?.displayId) {
      let payload = {
        update: {
          screens: this.data?.screens
            .filter((scr: DisplayScreen) => scr.screenId !== screen.screenId)
            .map((screen) => {
              let updatedScreen = { ...screen };
              delete updatedScreen.screenId;
              return updatedScreen;
            }),
        },
      };
      this.displayService
        .updateDisplay(this.data?.displayId, payload)
        .subscribe({
          next: (res) => {
            if (this.data?.displayId) {
              this.getDisplayDetails(this.data?.displayId);
            }
            if (res) {
              this.dialogCompInstance.dialogConfig.image =
                AppConstants.DELETE_SUCCESSFUL;
              this.dialogCompInstance.dialogConfig.message =
                'Screen deleted successfully';
              setTimeout(() => {
                this.dialog.closeAll();
              }, AppConstants.POPUP_TIMEOUT);
            }
          },
          error: (err) => {
            console.info('Error:', err);
            this.dialogCompInstance.dialogConfig.image =
              AppConstants.QUERIED_DATA_NOT_FOUND;
            this.dialogCompInstance.dialogConfig.message =
              'Error While Deleting screen. Try Again';
            setTimeout(() => {
              this.dialog.closeAll();
            }, AppConstants.POPUP_TIMEOUT);
          },
        });
    }
  }

  openDialog(screen: DisplayScreen, onConfirmation: () => void): void {
    const dynamicData = {
      title: 'Delete Screen',
      message: `Are you sure you want to delete this screen?`,
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: onConfirmation,
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  private loadData(): void {
    this.loadTable.next(false);
    this.loadTable.next(true);
  }

  private getAddDisplayPayloadFromForms(): {
    data: DisplayDetails;
  } {
    let displayDetails: {
      data: DisplayDetails;
    } = { ...this.displayDetailsForm.value };
    displayDetails = {
      data: {
        ...this.displayDetailsForm.value,
        screens: [this.screenDetailsForm.value],
      },
    };
    return displayDetails;
  }

  private getEditDisplayPayloadFromForms(screenId: number | undefined): {
    update: Partial<DisplayDetails>;
  } {
    let data: any = { ...this.data };
    delete data.cellValues;
    let displayDetails: {
      update: Partial<DisplayDetails>;
    } = {
      update: {
        ...data,
        screens: data?.screens?.map?.((screen: DisplayScreen) => {
          let updatedScreen = {
            ...(screenId && screen.screenId === screenId
              ? this.screenDetailsForm.value
              : screen),
          };
          delete updatedScreen.screenId;
          return updatedScreen;
        }),
      },
    };
    if (!screenId) {
      displayDetails.update.screens?.push(this.screenDetailsForm.value);
    }
    return displayDetails;
  }

  private saveDisplay(
    displayId: number | undefined = undefined,
    screenId: number | undefined = undefined
  ) {
    let payload: {
      update?: Partial<DisplayDetails>;
      data?: DisplayDetails;
    } = {
      update: {},
    };
    this.stepper.next();
    if (!displayId) {
      payload = this.getAddDisplayPayloadFromForms();
    } else {
      payload = this.getEditDisplayPayloadFromForms(screenId);
    }

    if (this.isEditMode && displayId) {
      this.displayService
        .updateDisplay(displayId, {
          update: payload.update ?? {},
        })
        .subscribe({
          next: (res) => {
            this.notificationService.showSnackBar(res, 'success');
            this.stepper.previous();
            this.stepper.previous();
            if (displayId) {
              this.getDisplayDetails(displayId);
            }
          },
        });
    } else {
      if (payload.data) {
        this.displayService
          .addDisplay({
            data: payload.data,
          })
          .subscribe({
            next: (res) => {
              this.notificationService.showSnackBar(res, 'success');
              this.closeForm(true);
            },
          });
      }
    }
    return;
  }

  //executes when user clicks on cancel button
  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  onStepChange(event: any) {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      this.previous(event);
    } else if (event.selectedIndex > event.previouslySelectedIndex) {
      this.submitAndNext(event);
    }
  }

  submitAndNext(event?: any, screenId: number | undefined = undefined) {
    event = !event;
    if (this.stepper.selectedIndex === 0 && this.displayDetailsForm.valid) {
      if (event) this.stepper.next();
      this.submitBtn = 'Next';
      this.backBtn = 'Back';
      this.screenTypeForm.get('screenId')?.setValue(screenId ?? null);
      this.isNextStep = this.screenTypeForm.valid;
    } else if (this.stepper.selectedIndex === 1 && this.screenTypeForm.valid) {
      if (event) this.stepper.next();
      this.submitBtn = 'Submit';
      this.backBtn = 'Back';
      this.isNextStep = this.screenDetailsForm.valid;
    } else if (
      this.stepper.selectedIndex === 2 &&
      this.screenDetailsForm.valid
    ) {
      this.saveDisplay(
        this.data?.displayId,
        this.screenTypeForm.value.screenId
      );
    }
  }

  //it executes when user click on back button of the form
  previous(event?: any) {
    event = !event;
    if (this.stepper.selectedIndex === 2) {
      if (event) this.stepper.previous();
      this.submitBtn = 'Next';
      this.backBtn = 'Back';
      this.isNextStep = this.screenTypeForm.valid;
    } else if (this.stepper.selectedIndex === 1) {
      if (event) this.stepper.previous();
      this.submitBtn = this.isEditMode ? 'Add Screen' : 'Next';
      this.backBtn = 'Cancel';
      this.isNextStep =
        this.displayDetailsForm.valid && this.data?.screens?.[0]?.type !== 3;
    } else if (this.stepper.selectedIndex === 0) {
      this.formsService.closeForm();
    }
  }

  private addFormSubscription() {
    let displayDetailFormSub = this.displayDetailsForm.valueChanges.subscribe(
      (res) => {
        this.isNextStep =
          this.displayDetailsForm.valid && this.data?.screens?.[0]?.type !== 3;
      }
    );

    let screenTypeFormSub = this.screenTypeForm.valueChanges.subscribe(
      (res) => {
        if (
          res.screenType?.deviceTypes?.includes?.(res.deviceType?.deviceTypeId)
        ) {
        } else if (res.screenType) {
          this.screenTypeForm.setValue({
            deviceType: res.deviceType,
            screenType: null,
          });
        }
        this.isNextStep = this.screenTypeForm.valid;
      }
    );

    let screenTypeFormScreenTypeSub = this.screenTypeForm
      .get('screenType')
      ?.valueChanges?.subscribe?.((res: ScreenTypeOption | undefined) => {
        if (res) {
          // Check if the screen type if cluster comparison
          if (res.id === 3) {
            this.clusterService.getCluster().subscribe({
              next: (res: Cluster[]) => {
                this.clusters = this.clusterService.clusterData;
              },
            });
          }
          this.buildScreenDetailsForm(
            res,
            this.screenTypeForm.value.deviceType.key,
            0
          ); // Only for adding the first screen
          this.subscriptions.push(
            this.screenDetailsForm.valueChanges.subscribe((res) => {
              this.isNextStep = this.screenDetailsForm.valid;
            })
          );
          let devicesChangeSubscription = this.screenDetailsForm
            .get('body')
            ?.get('devices')
            ?.valueChanges.subscribe({
              next: (res) => {
                this.parameters = CommonUtil.getCommonDeviceParameters(
                  this.devices.filter((device) => res.includes(device.deviceId))
                );

                this.screenDetailsForm
                  .get('body')
                  ?.get('parameter')
                  ?.setValue(
                    typeof this.screenDetailsForm.value.body?.parameter ===
                      'string'
                      ? this.parameters.includes(
                          this.screenDetailsForm.value.body?.parameter
                        )
                        ? this.screenDetailsForm.value.body?.parameter
                        : null
                      : this.screenDetailsForm.value.body?.parameter?.filter?.(
                          (p: string) => this.parameters.includes(p)
                        )
                  );
              },
            });
          if (devicesChangeSubscription) {
            this.subscriptions.push(devicesChangeSubscription);
          }
        }
      });

    let screenTypeFormDeviceTypeSub = this.screenTypeForm
      .get('deviceType')
      ?.valueChanges?.subscribe?.((res) => {
        if (res) {
          this.setFieldsAndLimits(res.deviceTypeId);
        }

        this.screenTypeOptions = DisplayConstants.SCREEN_TYPES.filter(
          (screenTypeOption: ScreenTypeOption) =>
            screenTypeOption.deviceTypes
              ? screenTypeOption.deviceTypes?.includes(res.deviceTypeId)
              : true
        );
        if (
          (this.selectedScreenType &&
            (this.selectedScreenType.deviceTypes ? false : true)) ||
          !this.selectedScreenType?.deviceTypes?.includes(res.deviceTypeId)
        ) {
          this.selectedScreenType = undefined;
        }
      });

    let screenDetailsFormSub = this.screenDetailsForm.valueChanges.subscribe(
      (res) => {
        this.isNextStep = this.screenDetailsForm.valid;
      }
    );

    this.subscriptions.push(displayDetailFormSub);
    this.subscriptions.push(screenTypeFormSub);
    if (screenTypeFormScreenTypeSub) {
      this.subscriptions.push(screenTypeFormScreenTypeSub);
    }
    if (screenTypeFormDeviceTypeSub) {
      this.subscriptions.push(screenTypeFormDeviceTypeSub);
    }
    this.subscriptions.push(screenDetailsFormSub);
  }

  private setFieldsAndLimits(deviceTypeId: number) {
    let units = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    this.selectedDeviceTypeFields = this.deviceService.fetchFields(
      deviceTypeId,
      units,
      false
    );

    this.selectedDeviceTypeLimits = this.deviceService.fetchLimits(
      units[deviceTypeId],
      false
    );
  }

  public selectScreenType(screenTypeId: number) {
    this.selectedScreenType = this.screenTypeOptionsObj[screenTypeId];
  }

  public screenTypeOptionTrackby(index: number, option: ScreenTypeOption) {
    return option.id;
  }

  private createRegexFromArray(strings: string[]) {
    // Escape special regex characters in each string and join them with the '|' operator
    const escapedStrings = strings.map((str) =>
      str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    );
    const regexPattern = `^\\s*(?!(${escapedStrings.join('|')})\\s*$).*$`;

    // Create a new RegExp object with the constructed pattern
    return new RegExp(regexPattern);
  }

  getInfoScreenArray(): FormArray {
    return this.screenDetailsForm.get('infoScreen') as FormArray;
  }

  getPointsArray(sectionIndex: number): FormArray {
    return this.getInfoScreenArray()
      .at(sectionIndex)
      .get('points') as FormArray;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
