<mat-sidenav-container class="h-100" autosize>
  <mat-sidenav
    [position]="'end'"
    [mode]="width > 991 ? 'side' : 'over'"
    [opened]="selectedDevice ? true : false"
  >
    <div class="device p-3">
      <div class="row">
        <div class="col">Device Information</div>
        <div class="col-auto">
          <mat-icon
            class="material-symbols-outlined cursor-pointer"
            (click)="clearDevice()"
            >close</mat-icon
          >
        </div>
        <div class="last-updated">
          Last Updated: {{ getFormattedDate(selectedDevice?.lastUpdated) }}
        </div>
      </div>
      <mat-card class="mt-2 mb-4 p-3 pb-0 card-info">
        <div *ngIf="selectedDevice?.url" class="device__actual-image">
          <img [src]="selectedDevice?.url" />
        </div>
        <div *ngIf="!selectedDevice?.url" class="device__image">
          <img
            height="131"
            src="./../../../../../../../assets/images/device/default-device-image.jpg"
          />
        </div>
        <div class="device__details mt-3">
          <div class="d-flex justify-content-between py-1">
            <span>Device ID</span>
            <span class="text-end">{{ selectedDevice?.deviceId }}</span>
          </div>
          <hr class="device__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Location</span>
            <span class="text-end ps-2 ps-lg-3">{{ selectedDevice?.loc }}</span>
          </div>
          <hr class="device__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Battery</span>
            <div class="text-end">
              <span *ngIf="selectedDevice?.payload?.d?.bs">{{
                selectedDevice?.payload?.d?.bs + "%"
              }}</span>
            </div>
          </div>
          <hr class="device__details__divider" />
          <div class="d-flex justify-content-between py-1">
            <span>Device Status</span>
            <span
              class="text-end"
              [style.color]="selectedDevice?.status?.color"
              >{{ selectedDevice?.status?.msg }}</span
            >
          </div>
          <hr class="device__details__divider" />
          <div class="mb-2 d-flex justify-content-between align-items-center">
            <span class="text-primary more-insights">More Insights:</span>
            <div class="device__details__more-actions row">
              <button
                mat-icon-button
                tabindex="-1"
                class="col"
                matTooltip="Dashboard"
                (click)="
                  navigateToAnotherModule('dashboard', selectedDevice?.deviceId)
                "
              >
                <nb-icon
                  class="w-auto h-auto"
                  icon="dashboard"
                  pack="envizom-module"
                ></nb-icon>
              </button>
              <button
                mat-icon-button
                tabindex="-1"
                class="col"
                matTooltip="Analytics"
                (click)="navigateToAnotherModule('analytics')"
              >
                <nb-icon
                  class="w-auto h-auto"
                  icon="analytics"
                  pack="envizom-module"
                ></nb-icon>
              </button>
              <button
                mat-icon-button
                tabindex="-1"
                class="col"
                matTooltip="Reports"
                (click)="navigateToAnotherModule('reports')"
              >
                <nb-icon
                  class="w-auto h-auto"
                  icon="reports"
                  pack="envizom-module"
                ></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="aqi-details p-3 card-info">
        <div class="mb-2">Parameters</div>
        <mat-accordion>
          <ng-container *ngFor="let gasLevel of gasLevels; let index = index">
            <mat-expansion-panel
              #parameterPanel="matExpansionPanel"
              class="parameters-aqi-india mat-elevation-z0 m-0"
              [hideToggle]="false"
              (click)="
                parameterPanel.hideToggle
                  ? (parameterPanel.expanded = false)
                  : null
              "
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="align-self-center">
                  <div class="d-flex justify-content-between">
                    <span>
                      {{
                        gasLevel?.name === "aqi" ? rawAqiLabel : gasLevel?.label
                      }}
                    </span>
                    <div
                      [style.color]="
                        paramColor[gasLevel?.name]
                          ? paramColor[gasLevel?.name]
                          : 'inherit'
                      "
                    >
                      <span>{{ gasLevel?.value }}</span>
                      <span> {{ gasLevel?.unit }}</span>
                    </div>
                  </div>
                </mat-panel-title>
                <hr />
              </mat-expansion-panel-header>
              <app-parameter-details
                *ngIf="selectedDevice"
                [isRawAqi]="gasLevel?.name === 'aqi'"
                [parameter]="gasLevel?.name"
                [parameterValue]="gasLevel?.value"
                [isVisible]="false"
                [deviceType]="selectedDevice.deviceType!"
                (noScaleFound)="parameterPanel.hideToggle = true"
              ></app-parameter-details>
              <div class="mb-4"></div>
            </mat-expansion-panel>
            <hr *ngIf="index + 1 !== gasLevels.length" class="my-0" />
          </ng-container>
        </mat-accordion>
      </mat-card>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="row mx-0 h-100">
      <ng-container *ngIf="googleMapsService.isApiLoaded | async">
        <google-map
          class="col-12 px-0"
          width="100%"
          height="100%"
          [options]="options"
        >
          <map-marker
            *ngFor="let device of devices; index as i"
            #marker="mapMarker"
            [options]="{
              zIndex: device?.deviceId == selectedDevice?.deviceId ? 0 : 1,
              icon: {
                url: device?.iconUrl!,
                anchor:
                  device?.deviceId == selectedDevice?.deviceId
                    ? selectedAnchor
                    : anchor,
                size:
                  device?.deviceId == selectedDevice?.deviceId
                    ? selectedIconSize
                    : iconSize
              }
            }"
            [position]="{
              lat: device.payload.d.lat
                ? device.payload.d.lat!
                : device.latitude,
              lng: device.payload.d.lon
                ? device.payload.d.lon!
                : device.longitude
            }"
            (mapClick)="openInfoWindow(marker, i, 'device', device)"
          >
            <map-info-window
              #deviceInfoWindow="mapInfoWindow"
              (closeclick)="closeInfoWindows()"
            >
              <app-device-info-window
                [device]="device"
              ></app-device-info-window>
            </map-info-window>
          </map-marker>

          <div *ngIf="hasComplainsAccess && complainLayerEnabled">
            <map-marker
              *ngFor="let complain of complains; index as i"
              #marker="mapMarker"
              [options]="{ icon: complain?.iconUrl }"
              [position]="{ lat: complain.latitude, lng: complain.longitude }"
              (mapClick)="
                openInfoWindow(marker, i, 'complain', undefined, complain)
              "
            >
              <map-info-window
                #complainInfoWindow="mapInfoWindow"
                (closeclick)="closeInfoWindows()"
              >
                <div class="complain">
                  <div class="complain__name">{{ complain?.title }}</div>
                  <div class="complain__image">
                    <img src="{{ complain?.image_url }}" />
                  </div>
                  <div class="complain__details">
                    <span>
                      <span class="complain__details__label">Complain: </span>
                      {{ " " + complain?.complainId }}
                    </span>
                    <span>
                      <span class="complain__details__label">Location: </span>
                      {{ " " + complain?.location }}
                    </span>
                    <span>
                      <span class="complain__details__label">Priority: </span>
                      {{ " " + complain?.priority }}
                    </span>
                  </div>
                </div>
              </map-info-window>
            </map-marker>
          </div>

          <div *ngIf="industryLayerEnabled === true">
            <map-marker
              *ngFor="let industry of industries; index as i"
              #marker="mapMarker"
              [options]="{ icon: 'assets/images/pins/industry.png' }"
              [position]="{
                lat: industry.geometry.location.lat(),
                lng: industry.geometry.location.lng()
              }"
              (mapClick)="
                openInfoWindow(marker, i, 'industry', undefined, undefined)
              "
            >
              <map-info-window
                #industryInfoWindow="mapInfoWindow"
                (closeclick)="closeInfoWindows()"
              >
                <div class="industry">
                  <div class="industry__close">
                    <mat-icon
                      class="material-symbols-outlined icon"
                      (click)="closeInfoWindows()"
                    >
                      close
                    </mat-icon>
                  </div>
                  <div class="industry__name">{{ industry?.name }}</div>
                  <div class="industry__image">
                    <img src="{{ industry?.photo }}" />
                  </div>
                  <div class="industry__details">
                    <span>
                      <span class="industry__details__label">Location: </span>
                      {{ " " + industry?.address }}
                    </span>
                    <span>
                      <span class="industry__details__label">Rating: </span>
                      {{ " " + industry?.rating }}
                    </span>
                  </div>
                </div>
              </map-info-window>
            </map-marker>
          </div>
        </google-map>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div id="fullscreen-control" (click)="switchToFullScreen()">
  <mat-icon class="material-symbols-outlined">{{ fullScreenMode }}</mat-icon>
</div>

<div id="zoom-control">
  <div class="d-flex flex-column">
    <mat-icon
      class="material-symbols-outlined text-basic-color"
      (click)="zoomIn()"
    >
      zoom_in
    </mat-icon>
    <hr class="control-divider" />
    <mat-icon
      class="material-symbols-outlined text-basic-color"
      (click)="zoomOut()"
    >
      zoom_out
    </mat-icon>
  </div>
</div>

<div class="map-controls">
  <div id="map-types-control" [class.expanded-menu]="showMoreOptions">
    <!-- Show this when menu is not expanded -->
    <ng-container *ngIf="!showMoreOptions">
      <div class="map-types cursor-pointer" (click)="changeMapType()">
        <div class="map-type">
          <img
            *ngIf="currentMapType === 'roadmap'"
            src="./../../../../../../../assets/images/map/default-map-type.png"
          />
          <img
            *ngIf="currentMapType === 'hybrid'"
            src="./../../../../../../../assets/images/map/satellite-map-type.png"
          />
        </div>
        <div
          class="text-center text-truncate"
          [title]="currentMapType === 'roadmap' ? 'Default' : 'Satellite'"
        >
          {{ currentMapType === "roadmap" ? "Default" : "Satellite" }}
        </div>
      </div>
      <div class="map-layers">
        <!-- Existing layer toggles -->
        <div
          id="complain-layer"
          class="cursor-pointer"
          (click)="toggleComplainLayer()"
        >
          <div
            [ngClass]="
              complainLayerEnabled ? 'map-layer layer-selected' : 'map-layer'
            "
          >
            <img
              src="./../../../../../../../assets/images/map/default-map-type.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Complain'">
            Complain
          </div>
        </div>

        <div
          id="industry-layer"
          *ngIf="hasLayer5000"
          class="cursor-pointer"
          (click)="toggleIndustryLayer()"
        >
          <div
            [ngClass]="
              industryLayerEnabled ? 'map-layer layer-selected' : 'map-layer'
            "
          >
            <img
              src="./../../../../../../../assets/images/map/industry-layer.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Industry'">
            Industry
          </div>
        </div>

        <div
          id="traffic-layer"
          *ngIf="hasLayer5001"
          class="cursor-pointer"
          (click)="toggleTrafficLayer()"
        >
          <div
            [ngClass]="
              trafficLayerEnabled ? 'map-layer layer-selected' : 'map-layer'
            "
          >
            <img
              src="./../../../../../../../assets/images/map/traffic-layer.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Traffic'">
            Traffic
          </div>
        </div>

        <div
          id="wind-layer"
          class="cursor-pointer justify-content-center"
          *ngIf="hasLayer5002"
          (click)="toggleWindLayer()"
        >
          <div
            [ngClass]="
              windLayerEnabled ? 'map-layer layer-selected' : 'map-layer'
            "
          >
            <img
              style="max-width: 48px; max-height: 48px"
              src="./../../../../../../../assets/images/map/wind-layer.png"
            />
          </div>
          <div class="text-center text-truncate" [title]="'Wind'">Wind</div>
        </div>

        <div
          id="more-options-layer"
          class="cursor-pointer"
          (click)="toggleMoreOptions()"
        >
          <div
            [ngClass]="
              showMoreOptions ? 'map-layer layer-selected' : 'map-layer'
            "
          >
            <mat-icon class="material-symbols-outlined layer-icon"
              >tune</mat-icon
            >
          </div>
          <div class="text-center text-truncate" [title]="'More'">More</div>
        </div>
      </div>
    </ng-container>
    <div class="menu-close-button-container" *ngIf="showMoreOptions">
      <button
        mat-icon-button
        class="close-button"
        (click)="toggleMoreOptions()"
      >
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>

    <!-- Expanded Menu -->
    <div class="expanded-options-menu" *ngIf="showMoreOptions">
      <div class="menu-section">
        <div class="section-title">Map details</div>
        <div class="options-grid">
          <div
            class="map-option"
            (click)="updateMapOption('showLabels')"
            [ngClass]="mapOptions.showLabels ? 'option-selected' : ''"
          >
            <div class="option-icon">
              <mat-icon>label</mat-icon>
            </div>
            <span class="text-truncate" [title]="'Labels'">Labels</span>
          </div>
          <div
            class="map-option"
            (click)="toggleComplainLayer()"
            [ngClass]="complainLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/default-map-type.png"
            />
            <span class="text-truncate" [title]="'Complain'">Complain</span>
          </div>
          <div
            class="map-option"
            *ngIf="hasLayer5000"
            (click)="toggleIndustryLayer()"
            [ngClass]="industryLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/industry-layer.png"
            />
            <span class="text-truncate" [title]="'Industry'">Industry</span>
          </div>
          <div
            class="map-option"
            *ngIf="hasLayer5001"
            (click)="toggleTrafficLayer()"
            [ngClass]="trafficLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/traffic-layer.png"
            />
            <span class="text-truncate" [title]="'Traffic'">Traffic</span>
          </div>

          <div
            class="map-option"
            *ngIf="hasLayer5002"
            (click)="toggleWindLayer()"
            [ngClass]="windLayerEnabled ? 'option-selected' : ''"
          >
            <img
              class="option-icon"
              src="./../../../../../../../assets/images/map/wind-layer.png"
            />
            <span class="text-truncate" [title]="'Wind'">Wind</span>
          </div>
        </div>
      </div>

      <div class="menu-section">
        <div class="section-title">Map type</div>
        <div class="map-types-grid">
          <div
            class="map-type-option"
            [class.selected]="currentMapType === 'roadmap'"
            (click)="currentMapType === 'roadmap' ? null : changeMapType()"
          >
            <img
              src="./../../../../../../../assets/images/map/default-map-type.png"
            />
            <span class="text-truncate" [title]="'Default'">Default</span>
          </div>
          <div
            class="map-type-option"
            [class.selected]="currentMapType === 'hybrid'"
            (click)="currentMapType === 'hybrid' ? null : changeMapType()"
          >
            <img
              src="./../../../../../../../assets/images/map/satellite-map-type.png"
            />
            <span class="text-truncate" [title]="'Satellite'">Satellite</span>
          </div>
        </div>
      </div>

      <div
        class="menu-section additional-layers"
        *ngIf="orgMapLayers.length > 0"
      >
        <div class="section-title">Additional layers</div>
        <div class="options-grid">
          <div
            *ngFor="let orgMapLayer of orgMapLayers; let i = index"
            class="map-option"
            [ngClass]="orgMapLayer.isVisible ? 'option-selected' : ''"
            (click)="toggleOrgMapLayer(i)"
          >
            <img [src]="orgMapLayer.iconUrl" class="layer-icon" />
            <span class="text-truncate" [title]="orgMapLayer.layerName">{{
              orgMapLayer.layerName
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex column">
  <div style="margin: auto">
    <div class="legend-container" *ngIf="windLayerEnabled">
      <div class="legend-title">{{ weatherOption.legendTitle }}</div>
      <div class="legend-body">
        <div class="legend-min-scale">{{ weatherOption.legendMin }}</div>
        <div class="legend-avg-scale">{{ weatherOption.legendAvg }}</div>
        <div class="legend-max-scale">{{ weatherOption.legendMax }}</div>
        <div [ngClass]="['legend-scale', weatherOption.scaleClass]"></div>
      </div>
    </div>
  </div>
</div>
