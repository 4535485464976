import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Complain } from 'src/app/shared/models/complain';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-complain-form',
  templateUrl: './complain-form.component.html',
  styleUrls: ['./complain-form.component.scss'],
})
export class ComplainFormComponent {
  @ViewChild('stepper') stepper!: MatStepper;
  public registercomplainForm!: FormGroup;
  public complainassignToForm!: FormGroup;
  public isEditMode: boolean;
  categories: any[];
  public showLoader: boolean;
  public isAdmin: boolean;
  public for_all: boolean = false;
  public data: Complain.Get | undefined;
  public isNextStep: boolean = false;
  public cancelBtn: string = 'Cancel';
  public submitBtn: string = 'Next';
  statuses = ['Resolved', 'In Progress', 'Pending'];
  priorities = ['HIGH', 'MEDIUM', 'LOW'];
  selectedOrg: any = [];
  public file_store!: File | null;
  public isImageSelected!: boolean;
  public updateImage: any;

  orgId = this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
    .org;
  public img_url!: any;

  constructor(
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private complainservice: ComplainsService,
    public localStorageService: LocalStorageService,
    public loadrService: LoadrService,
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService,
    @Inject(MTX_DRAWER_DATA)
    {
      data,
      selectedOrg,
      categories,
      imageUrl,
    }: {
      data: Complain.Get;
      selectedOrg: any[];
      categories: any;
      imageUrl: any;
    }
  ) {
    this.data = data;
    this.selectedOrg = selectedOrg;
    this.categories = categories;
    this.isEditMode = false;
    this.isImageSelected = imageUrl ? true : false;
    this.showLoader = false;
    this.isAdmin =
      JSON.parse(this.localStorageService.getValue('oz-user')).role === 1
        ? true
        : false;
    this.img_url = imageUrl;
  }

  ngOnInit(): void {
    this.isEditMode = !!this.data;
    this.buildForm(this.isEditMode);
    this.registercomplainForm.valueChanges.subscribe(() => {
      this.isNextStep = this.registercomplainForm.valid;
    });
    this.complainassignToForm.valueChanges.subscribe(() => {
      this.isNextStep = this.complainassignToForm.valid;
    });
    if (this.isEditMode) {
      this.data!.endDate = this.customMomentService.moment.unix(
        this.data?.end_date!
      );
      this.registercomplainForm.patchValue({ ...this.data! });
      this.complainassignToForm.patchValue({ ...this.data! });
      this.img_url = this.data?.image_url;
    }

    this.complainassignToForm.get('email')?.valueChanges.subscribe((result) => {
      this.emailpatterncheck(result);
    });
    this.complainassignToForm
      .get('phoneNo')
      ?.valueChanges.subscribe((result) => {
        this.phonepatterncheck(result);
      });
  }
  getImageUrl() {
    this.img_url = this.data?.image_url;
    if (this.img_url !== undefined) {
      this.isImageSelected = true;
      return this.img_url;
    } else {
      this.isImageSelected = false;
      return undefined;
    }
  }
  buildForm(isEditMode: boolean = false) {
    this.registercomplainForm = this.formBuilder.group({
      categoryId: [{ value: '', disabled: isEditMode }, Validators.required],
      priority: [{ value: 'HIGH', disabled: isEditMode }, Validators.required],
      title: [{ value: '', disabled: isEditMode }, Validators.required],
      message: [{ value: '', disabled: isEditMode }, Validators.required],
      sources: [{ value: '', disabled: isEditMode }, Validators.required],
      endDate: ['', Validators.required],
      latitude: [{ value: '', disabled: isEditMode }, Validators.required],
      longitude: [{ value: '', disabled: isEditMode }, Validators.required],
    });

    this.complainassignToForm = this.formBuilder.group({
      status: [0, Validators.required],
      assignedUserId: ['', Validators.required],
      name: [{ value: '', disabled: isEditMode }, Validators.required],
      email: [{ value: '', disabled: isEditMode }, Validators.required],
      phoneNo: [{ value: '', disabled: isEditMode }],
      image_url: [
        {
          value: this.getImageUrl() ? this.getImageUrl() : '',
          disabled: isEditMode,
        },
        // Validators.required,
      ],
    });
  }

  // This function is called when new complain form is submitted.,
  submitForm() {
    let registerComplainFormDetails = this.registercomplainForm.value;
    let complainassignFormDetails = this.complainassignToForm.value;

    let complainDetails: Complain.Post = {
      data: {
        categoryId: registerComplainFormDetails.categoryId,
        title: registerComplainFormDetails.title,
        message: registerComplainFormDetails.message,
        latitude: registerComplainFormDetails.latitude,
        longitude: registerComplainFormDetails.longitude,
        sources: registerComplainFormDetails.sources,
        email: complainassignFormDetails.email,
        name: complainassignFormDetails.name,
        priority: registerComplainFormDetails.priority,
        assignedUserId: complainassignFormDetails.assignedUserId,
        status: complainassignFormDetails.status,
        phoneNo: complainassignFormDetails.phoneNo,
        end_date: registerComplainFormDetails.endDate,
        image_url: complainassignFormDetails.image_url,
      },
    };

    this.loadrService.showLoader();
    if (this.file_store) {
      this.deviceService.updateImageURL(this.file_store).subscribe({
        next: (res: any) => {
          complainDetails.data = {
            ...complainDetails.data,
            image_url: res.url,
          };
          this.complainservice
            .addComplain(this.orgId, complainDetails)
            .subscribe({
              next: (res: any) => {
                if (res) {
                  this.complainservice
                    .getComplainsByOrganization(
                      this.localStorageService.getParsedValue(
                        LocalStorageConstants.OZ_USER
                      ).org,
                      this.complainservice.getGte(),
                      this.complainservice.getLte()
                    )
                    .subscribe({
                      next: (res) => {
                        this.complainservice.emitData();
                      },
                    });
                }
                this.loadrService.removeLoader();
                this.formsService.closeForm();
              },
              error: (err) => {
                console.info("Error:", err);
              },
            });
        },
      });
    } else {
      this.complainservice.addComplain(this.orgId, complainDetails).subscribe({
        next: (res: any) => {
          if (res) {
            this.complainservice
              .getComplainsByOrganization(
                this.localStorageService.getParsedValue(
                  LocalStorageConstants.OZ_USER
                ).org,
                this.complainservice.getGte(),
                this.complainservice.getLte()
              )
              .subscribe({
                next: (res) => {
                  this.complainservice.emitData();
                },
              });
          }
          this.loadrService.removeLoader();
          this.formsService.closeForm();
        },
        error: (err) => {
          console.info("Error:", err);
        },
      });
    }
  }

  emailpatterncheck(result: any) {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (pattern.test(result)) {
      this.complainassignToForm.get('email')?.setErrors(null);
    } else {
      this.complainassignToForm.get('email')?.setErrors({ customError: true });
    }
  }

  phonepatterncheck(result: any) {
    const pattern = /^\+[0-9]{1,15}$/;
    if (pattern.test(result)) {
      this.complainassignToForm.get('phoneNo')?.setErrors(null);
    } else {
      this.complainassignToForm
        .get('phoneNo')
        ?.setErrors({ customError: true });
    }
  }

  // This function is called when old complain is editted.
  updateComplain() {
    let registerComplainFormDetails = this.registercomplainForm.value;
    let complainassignFormDetails = this.complainassignToForm.value;
    let complainDetails: Complain.Patch = {
      update: {
        categoryId: registerComplainFormDetails.categoryId,
        title: registerComplainFormDetails.title,
        message: registerComplainFormDetails.message,
        latitude: registerComplainFormDetails.latitude,
        longitude: registerComplainFormDetails.longitude,
        sources: registerComplainFormDetails.sources,
        email: complainassignFormDetails.email,
        name: complainassignFormDetails.name,
        priority: registerComplainFormDetails.priority,
        assignedUserId: complainassignFormDetails.assignedUserId,
        status: complainassignFormDetails.status,
        phoneNo: complainassignFormDetails.phoneNo,
        end_date: registerComplainFormDetails.endDate,
        image_url: complainassignFormDetails.image_url,
      },
    };

    this.loadrService.showLoader();
    this.complainservice
      .updateComplain(this.data?.complainId, complainDetails)
      .subscribe({
        next: (res: any) => {
          this.loadrService.removeLoader();
          this.complainservice
            .getComplainsByOrganization(
              this.localStorageService.getParsedValue(
                LocalStorageConstants.OZ_USER
              ).org,
              this.complainservice.getGte(),
              this.complainservice.getLte()
            )
            .subscribe({
              next: (res) => {
                this.complainservice.emitData();
              },
            });
          this.loadrService.removeLoader();
          this.closeForm();
        },
        error: (err) => {
          console.info("Error:", err);
          this.loadrService.removeLoader();
        },
      });
  }

  onStepChange(event: any) {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      this.previous(event);
    } else if (event.selectedIndex > event.previouslySelectedIndex) {
      this.submitAndNext(event);
    }
  }

  previous(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (stepperLength === 2) {
      if (this.stepper.selectedIndex === 0) {
        this.closeForm();
      } else if (this.stepper.selectedIndex === 1) {
        if (event) this.stepper.previous();
        this.isNextStep = this.registercomplainForm.valid;
        this.submitBtn = 'Next';
        this.cancelBtn = 'Cancel';
      }
    }
  }

  submitAndNext(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (this.isEditMode) {
      if (stepperLength === 2) {
        if (
          this.stepper.selectedIndex === 0 &&
          this.registercomplainForm.valid
        ) {
          if (event) this.stepper.next();
          this.isNextStep = this.registercomplainForm.valid;
          this.submitBtn = 'Update Complain';
          this.cancelBtn = 'Back';
        } else if (
          this.stepper.selectedIndex === 1 &&
          this.complainassignToForm.valid
        ) {
          if (event) this.updateComplain();
        }
      }
    } else {
      if (stepperLength === 2) {
        if (
          this.stepper.selectedIndex === 0 &&
          this.registercomplainForm.valid
        ) {
          if (event) this.stepper.next();
          this.isNextStep = this.registercomplainForm.valid;
          this.submitBtn = 'Submit Complain';
          this.cancelBtn = 'Back';
        } else if (
          this.stepper.selectedIndex === 1 &&
          this.complainassignToForm.valid
        ) {
          if (event) this.submitForm();
        }
      }
    }
  }

  // the form will close
  closeForm() {
    this.formsService.closeForm();
  }

  handleImageSelected(files: FileList | File): void {
    if (files instanceof File) {
      this.file_store = files;
      this.complainassignToForm.controls.image_url.patchValue(files.name);
      this.isImageSelected = true;
      this.onUpdateImg(files);
    }
  }
  removeFile() {
    this.file_store = null;
    this.complainassignToForm.controls.image_url.patchValue(' ');
    this.img_url = '';
    this.isImageSelected = false;
  }

  onUpdateImg(image: any) {
    var reader = new FileReader();
    try {
      let formData: FormData = new FormData();
      this.updateImage = null;
      this.updateImage = <Array<File>>image;
      reader.readAsDataURL(this.updateImage);
      reader.onload = (e) => {
        this.img_url = null;
        this.img_url = reader.result;
      };
    } catch (e) {
      console.info(e);
    }
  }
}
