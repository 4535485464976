import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';
import { RealTimeWidgetComponent } from '../real-time-widget/real-time-widget.component';

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: ['./widget-wrapper.component.scss'],
})
export class WidgetWrapperComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this._widgetInfo = v;
    this._cdr.detectChanges();
  }
  public widgetSubTitle = '';

  private _chartType: 'line' | 'column' = 'line';
  public get chartType(): 'line' | 'column' {
    return this._chartType;
  }
  public set chartType(v: 'line' | 'column') {
    this._chartType = v ?? 'line';
    if (this.widgetComponentInstance) {
      if (this.widgetComponentInstance.instance.widgetInfo?.chartTypeConfig) {
        this.widgetComponentInstance.instance.widgetInfo.chartTypeConfig.selectedValue =
          this._chartType;

        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetComponentInstance.instance.widgetInfo,
        };
      }
    }
  }

  private _direction: 'to' | 'from' = 'to';
  public get direction(): 'to' | 'from' {
    return this._direction;
  }
  public set direction(v: 'to' | 'from') {
    this._direction = v ?? undefined;
    if (this.widgetComponentInstance) {
      if (this.widgetComponentInstance.instance.widgetInfo?.directionConfig) {
        this.widgetComponentInstance.instance.widgetInfo.directionConfig.selectedValue =
          this._direction;

        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetComponentInstance.instance.widgetInfo,
        };
      }
    }
  }

  @Input() widgetComponent: Type<IWidgetComponent> = RealTimeWidgetComponent;

  @Output() noDataTrigger: EventEmitter<any> = new EventEmitter();
  @Output() graphDataEmpty: EventEmitter<any> = new EventEmitter();

  @ViewChild('container', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  private widgetComponentInstance: ComponentRef<IWidgetComponent> | undefined;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private _cdr: ChangeDetectorRef,
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.widgetComponent && this.widgetInfo) {
      this.widgetComponentInstance = this.viewContainerRef.createComponent(
        this.widgetComponent
      );
      if (this.widgetComponentInstance) {
        //to disable the buttons if graph is not available
        const subs =
          this.widgetComponentInstance.instance?.graphDataEmpty?.subscribe(
            (res: any) => {
              this.graphDataEmpty.emit(res);
            }
          );

        if (subs) {
          this.subscriptions.push(subs);
        }

        this.subscriptions.push(
          this.widgetComponentInstance.instance.noDataTrigger?.subscribe?.({
            next: () => {
              this.noDataTrigger.emit(this.widgetInfo.widgetId);
            },
          })
        );
        this.widgetComponentInstance.instance.styles = [
          `
          display: 'block',
          width: '100%',
          height: '100%',
        `,
        ];
        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetInfo,
        };
        // let widgetSubTitle = this.widgetInfo.widgetSubTitle;
        this.widgetComponentInstance.instance.parameterSelectionChange?.subscribe?.(
          (param: any) => {
            //set title of the widget
            let unitOfSelectedParam = this.deviceService.fields.find(
              (field) => field.label === param
            )?.unit;

            if (Boolean(unitOfSelectedParam))
              unitOfSelectedParam = unitOfSelectedParam + ' | ';
            else {
              unitOfSelectedParam = '';
            }
            //add unit to subtitle
            // this.widgetInfo.widgetSubTitle =
            //   unitOfSelectedParam + widgetSubTitle;

            this.widgetSubTitle =
              unitOfSelectedParam + this.widgetInfo.widgetSubTitle;
            //set title as per current selected parameter
            this.widgetInfo.widgetTitle = param;

            //set the yaxis status
            this.setYaxisState(param);
          }
        );
        this.chartType =
          this.widgetInfo?.chartTypeConfig?.selectedValue ?? 'column';
        this.direction =
          this.widgetInfo?.directionConfig?.selectedValue ?? 'from';
      }
    }
  }

  public setYaxisState(param: string) {
    try {
      const deviceTypeId = this.deviceService?.currentDeviceType?.deviceTypeId;

      if (deviceTypeId) {
        const units = this.commonService.getAllUnits();
        const dtUnits = units[deviceTypeId];
        const key = this.deviceService.fields.find(
          (field) => field.label === param
        )?.fkey;

        if (key) {
          const param = dtUnits[key];
          const options = param.options?.refLineLimits;
          const yAxisOption =
            this.widgetComponentInstance?.instance.widgetInfo.optionsMenu?.options.find(
              (op) => op.value === 'static_y_axis'
            );

          if (yAxisOption) {
            if (!options || !options['hr']) {
              yAxisOption.state = false;
            } else {
              yAxisOption.state = true;
            }
          }

          if (this.widgetComponentInstance) {
            this.widgetComponentInstance.instance.widgetInfo = {
              ...this.widgetComponentInstance.instance.widgetInfo,
            };
          }
        }
      }
    } catch {
      console.error('Error while setting Yaxis state in Widget');
    }
  }

  public selectChartType(option: any) {
    this.chartType = option.value;
  }

  public selectDirection(option: any) {
    this.direction = option.value;
  }

  public onOptionClick(option: any, optionIndex: number) {
    //code should only run for managing y axis state
    //currently other options doesnot have state key
    if (Object.keys(option).includes('state')) {
      option.state = !option.state;
      if (this.widgetComponentInstance) {
        this.widgetComponentInstance.instance.widgetInfo = {
          ...this.widgetComponentInstance.instance.widgetInfo,
        };
      }
    } else {
      let chart: Highcharts.Chart =
        this.widgetComponentInstance?.instance?.highChartsRef;
      if (option?.value === 'png') {
        chart?.exportChartLocal({ type: 'image/png' }, {});
      } else if (option?.value === 'jpeg') {
        chart.exportChartLocal({ type: 'image/jpeg' }, {});
      } else if (option.value === 'svg') {
        chart.exportChartLocal({ type: 'image/svg+xml' }, {});
      }

      let showExist = this.widgetComponentInstance?.instance?.show;
      if (showExist) {
        this.widgetComponentInstance!.instance.show = false;
      }
      setTimeout(() => {
        this.widgetComponentInstance!.instance.show = true;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription?.closed) {
        subscription?.unsubscribe();
      }
    });
  }
}
