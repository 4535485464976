<app-one-column>
  <nb-menu tag="menu" [items]="itemMenu" [autoCollapse]="true"></nb-menu>
  <router-outlet></router-outlet>
</app-one-column>
<ng-container *ngIf="deviceService?.globalLoader">
  <div class="row mx-0 global-loader">
    <div class="col-12 global-loader-header">
      <div class="row mx-0 w-100 h-100 header-container">
        <div class="col col-lg-auto col-xl-4 logo-container align-self-center">
          <span class="header-logo d-none d-md-block skeleton"> </span>
        </div>
        <div class="col-auto col-lg col-xl align-self-center skeleton-icons-container">
          <div class="skeleton-icons-container rectangle-skeleton"></div>
          <ng-container *ngFor="let header of headerIcons">
            <div class="py-2">
              <div class="global-loader-sidebar-list skeleton">&nbsp;</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="table-header-skeleton"></div>
    <div class="d-flex flex-row px-0 global-loader-content">
      <div class="global-loader-sidebar">
        <ng-container *ngFor="let item of skeletonIcons">
          <div class="py-3">
            <div class="global-loader-sidebar-list skeleton">&nbsp;</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<div
  *ngIf="loadrService.loading"
  class="text-center d-flex justify-content-center align-items-center"
  style="
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999999999999999999999;
    background-color: rgba(0, 0, 0, 0.26);
    backdrop-filter: blur(12px);
  "
>
  <mat-spinner [diameter]="50" color="primary"></mat-spinner>
</div>
