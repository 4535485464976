<app-form-container>
  <div class="overflow-hidden h-100">
    <div
      class="header d-flex flex-row justify-content-between align-items-center overflow-hidden"
    >
      <p class="m-2"><b>Sensor Information</b></p>
      <mat-icon
        mat-dialog-close
        class="material-symbols-outlined cursor-pointer align-self-center m-2"
        >close</mat-icon
      >
    </div>

    <div class="py-5" *ngIf="!loadTable">
      <mat-spinner
        class="mx-auto"
        [diameter]="50"
        color="primary"
      ></mat-spinner>
    </div>

    <div
      class="d-flex w-100 h-100 justify-content-center align-items-center"
      *ngIf="loadTable && dataSource.length === 0"
    >
      <p>No Data Found</p>
    </div>

    <div
      *ngIf="loadTable && dataSource.length > 0"
      class="overflow-auto w-100 h-90"
    >
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>Sensor Name</th>
          <td mat-cell *matCellDef="let element">{{ element.key }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Sensor ID</th>
          <td mat-cell *matCellDef="let element">{{ element.sensorId }}</td>
        </ng-container>

        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Updated On</th>
          <td mat-cell *matCellDef="let element">{{ element.updatedOn }}</td>
        </ng-container>

        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
          <td mat-cell *matCellDef="let element">{{ element.expiry }}</td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</app-form-container>
