<div class="img-body col-12" [formGroup]="form" *ngIf="isEditMode">
  <label class="file-uploader-wrapper">
    <div class="file-uploader">
      <div class="image-placeholder" *ngIf="!isImageSelected">
        <mat-icon class="material-symbols-outlined img">insert_photo</mat-icon>
        <div class="placeholder-text">
          <span>{{ noImageMessage }}</span>
          <small class="small"> Max image size is 2 MB.</small>
        </div>
      </div>
      <!--when image is selected and submit type is false-->
      <ng-container *ngIf="isImageSelected && !isSubmit">
        <div class="image-container" [ngClass]="{ disabled: isDisabled }">
          <img
            class="uploaded-image"
            [src]="imageUrl"
            id="myImage"
            (click)="
              zoomImage(); $event.preventDefault(); $event.stopPropagation()
            "
          />
        </div>
      </ng-container>

      <!--when image is selected and submit type is false and show remove icon on image-->
      <ng-container *ngIf="isImageSelected && isSubmit">
        <div class="content" [ngClass]="{ disabled: isDisabled }">
          <img
            class="uploaded-image"
            [src]="imageUrl"
            id="myImage"
            (click)="
              zoomImage(); $event.preventDefault(); $event.stopPropagation()
            "
          />
        </div>
      </ng-container>

      <!-- to show upload/replace and submit buttons - user tab-->
      <div class="actions" *ngIf="isSubmit">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="
            f_input.click(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip=" Change Logo"
        >
          Change Logo
        </button>

        <button
          type="button"
          mat-flat-button
          class="delete-btn"
          *ngIf="isImageSelected && isSubmit"
          (click)="
            removeLogo(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip="Remove Logo"
        >
          Remove Logo
        </button>

        <input
          type="file"
          class="d-none"
          #f_input
          (change)="handleFileInputChange(f_input.files!)"
          accept=".jpg, .jpeg, .png"
          max-size="524288"
          [disabled]="isDisabled"
        />
      </div>

      <div class="actions" *ngIf="!isSubmit">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="
            f_input.click(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip=" {{ isImageSelected ? 'Replace Image' : 'Upload Image' }}"
        >
          {{ isImageSelected ? "Replace" : "Upload" }}
        </button>
        <button
          type="button"
          mat-flat-button
          class="delete-btn"
          *ngIf="isImageSelected && !isSubmit"
          (click)="
            removeFile(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip="Delete Image"
        >
          Delete Image
        </button>
        <input
          type="file"
          class="d-none"
          #f_input
          (change)="handleFileInputChange(f_input.files!)"
          accept=".jpg, .jpeg, .png"
          max-size="524288"
          [disabled]="isDisabled"
        />
      </div>
    </div>
  </label>
</div>

<div class="img-body col-12" [formGroup]="form" *ngIf="!isEditMode">
  <label class="file-uploader-wrapper">
    <div class="file-uploader">
      <!--when image is not selected -->
      <div class="image-placeholder" *ngIf="!isImageSelected">
        <mat-icon class="material-symbols-outlined img">insert_photo</mat-icon>
        <div class="placeholder-text">
          <span>{{ noImageMessage }}</span>
          <small class="small"> Max image size is 2 MB.</small>
        </div>
      </div>

      <!--when image is selected and submit type is false-->
      <ng-container *ngIf="isImageSelected && !isSubmit">
        <div class="image-container" [ngClass]="{ disabled: isDisabled }">
          <img
            class="uploaded-image"
            [src]="imageUrl"
            id="myImage"
            (click)="
              zoomImage(); $event.preventDefault(); $event.stopPropagation()
            "
          />
        </div>
      </ng-container>

      <!--when image is selected and submit type is true and show remove icon on image-->
      <ng-container *ngIf="isImageSelected && isSubmit">
        <div class="content" [ngClass]="{ disabled: isDisabled }">
          <img
            class="uploaded-image"
            [src]="imageUrl"
            id="myImage"
            (click)="
              zoomImage(); $event.preventDefault(); $event.stopPropagation()
            "
          />
          <a
            href="#"
            matTooltip="Delete Image"
            (click)="
              removeFile(); $event.preventDefault(); $event.stopPropagation()
            "
            class="close"
          ></a>
        </div>
      </ng-container>

      <!-- to show upload/replace and submit buttons-->
      <div class="actions">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="
            f_input.click(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip=" {{ isImageSelected ? 'Replace Image' : 'Upload Image' }}"
        >
          {{ isImageSelected ? "Replace" : "Upload" }}
        </button>
        <button
          type="button"
          mat-flat-button
          class="delete-btn"
          *ngIf="isImageSelected && !isSubmit"
          (click)="
            removeFile(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip="Delete Image"
        >
          Delete Image
        </button>

        <button
          type="button"
          mat-raised-button
          color="primary"
          *ngIf="isImageSelected && isSubmit"
          (click)="
            submitFile(); $event.preventDefault(); $event.stopPropagation()
          "
          [ngClass]="{ disabled: isDisabled }"
          matTooltip="Submit Image"
        >
          Submit
        </button>

        <input
          type="file"
          class="d-none"
          #f_input
          (change)="handleFileInputChange(f_input.files!)"
          accept=".jpg, .jpeg, .png"
          max-size="524288"
          [disabled]="isDisabled"
        />
      </div>
    </div>
  </label>
</div>
