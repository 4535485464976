import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DataFlaggingService } from 'src/app/shared/services/data-flagging.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { FlagDataFormComponent } from './components/flag-data-form/flag-data-form.component';
@Component({
  selector: 'app-data-flagging',
  templateUrl: './data-flagging.component.html',
  styleUrls: ['./data-flagging.component.scss'],
})
export class DataFlaggingComponent {
  public deviceTypes!: Array<DeviceType>;
  public headerForm!: FormGroup;
  devices: DeviceDetails[] = [];
  minDate!: moment.Moment;
  maxDate: moment.Moment = this.customMomentService.moment();
  subscriptions: Subscription[] = [];
  formValues: any;
  selectedRow: any = [];
  selectedColumns: any = [];
  isFlagDisabled: boolean = true;
  constructor(
    private fb: FormBuilder,
    private deviceService: DeviceService,
    private commonService: CommonService,
    public router: Router,
    private formsService: FormsService,
    private customMomentService: CustomMomentService,
    private dataFlaggingService: DataFlaggingService
  ) {}
  ngOnInit() {
    this.dataFlaggingService.rowSelected.subscribe((row) => {
      this.selectedRow = row;
      if (this.selectedRow?.length) {
        this.isFlagDisabled = false;
      } else {
        this.isFlagDisabled = true;
      }
    });
    this.dataFlaggingService.columnSelected.subscribe((columns) => {
      this.selectedColumns = columns;
      if (this.selectedColumns?.length) {
        this.isFlagDisabled = false;
      } else {
        this.isFlagDisabled = true;
      }
    });
    this.maxDate = this.customMomentService.moment();
    this.headerForm = this.fb.group({
      deviceSearch: [''],
      device: [''],
      startDate: [this.customMomentService.moment().startOf('day')],
    });

    if (
      this.deviceService.currentDevice?.deviceType === 'BEANAIR' ||
      this.deviceService.currentDevice?.deviceType === 'OMNIDOTS'
    ) {
      this.deviceService.removeDeviceGlobally();
    }
    this.addSubscriptions();
    this.tableFormChange();

    this.devices = [...this.deviceService.registeredDevices!]!
      .sort(CommonUtil.sortAlphaNumDeviceDetailsAsc)
      .filter(
        (device) =>
          device.deviceType !== 'BEANAIR' && device.deviceType !== 'OMNIDOTS'
      );

    this.deviceTypes = this.commonService
      .getUserDeviceTypes()
      .filter(
        (deviceType) =>
          deviceType.key !== 'BEANAIR' && deviceType.key !== 'OMNIDOTS'
      );

    if (
      !this.deviceService.currentDevice ||
      !this.deviceService.currentDevice?.deviceId
    ) {
      this.headerForm.controls.deviceSearch.setValue(this.devices[0]);
    } else {
      let index = this.devices.findIndex(
        (device) =>
          device?.deviceId == this.deviceService.currentDevice?.deviceId
      );
      if (index > -1) {
        this.headerForm.controls.deviceSearch.setValue(this.devices[index]);
      }
    }
  }
  addSubscriptions(): void {
    const localStorageDeviceUpdate: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe((res) => {
        this.setupData();
      });
    this.subscriptions.push(localStorageDeviceUpdate);
  }

  setupData() {
    const device = this.deviceService.currentDevice;
    if (device) {
      this.headerForm.controls.device.setValue(device);
    }
  }
  tableFormChange() {
    this.headerForm.controls.deviceSearch.valueChanges.subscribe((device) => {
      this.deviceService.setupDeviceIdGlobally(device?.deviceId);
      if (device) {
        this.minDate = this.deviceService.getMinDateOfDevice(device);
      }
    });
    let gte = this.headerForm.get('startDate')?.value.unix();
    let lte = this.customMomentService.moment().endOf('day').unix();
    this.deviceService.currentDevice = undefined;
    this.headerForm.controls.deviceSearch.setValue(
      this.headerForm.get('deviceSearch')?.value
    );
    const formValue = {
      device: this.headerForm.get('deviceSearch')?.value,
      startDate: gte,
      endDate: lte,
    };
    this.dataFlaggingService.dataFlagForm.next(formValue);
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  flagTheData() {
    let gte = this.headerForm.get('startDate')?.value.unix();
    let lte = this.customMomentService.moment().endOf('day').unix();
    let formValue = {};
    formValue = {
      selectedData: this.selectedRow,
      startDate: gte,
      endDate: lte,
    };
    this.formsService
      .openForm(FlagDataFormComponent, formValue, 'sm')
      .subscribe({
        next: (result) => {
          this.selectedRow = [];
        },
      });
  }
}
