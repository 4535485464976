<ng-container *ngIf="dataForAnalytics?.length">
  <mat-sidenav-container class="h-100" autosize>
    <div class="d-flex flex-column h-100">
      <div class="p-2 m-1">
        <div
          class="d-flex align-items-center flex-wrap gap-2"
          id="map-chart-controls"
        >
          <div class="dropdowns d-flex align-items-center">
            <button
              mat-raised-button
              [matMenuTriggerFor]="parameterMenu"
              class="d-flex flex-row-reverse align-items-center justify-content-around p-2"
              matTooltip="Select Parameter"
              [color]="'primary'"
              [disabled]="!selectedParameter"
            >
              <div class="text-center">
                {{ !selectedParameter?.fkey?.endsWith('_aqi') ? ('' + selectedParameter?.label + ' (' + selectedParameter?.unit + ')') : (selectedParameter?.label) }}
              </div>
              <mat-icon class="m-0">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #parameterMenu="matMenu" style="padding: 0" class="">
              <button
                mat-menu-item
                *ngFor="let parameter of parameters; let i = index"
                (click)="selectParameter(parameter)"
                [value]="parameter"
              >
                {{ !parameter.fkey.endsWith('_aqi') ? ('' + parameter.label + ' (' + parameter.unit + ')') : (parameter.label)}}
              </button>
            </mat-menu>

            <!-- Integrated Rose Chart Size Dropdown -->
            <div *ngIf="analyticDetail.analyticType === 102">
              <button
                mat-raised-button
                [matMenuTriggerFor]="roseChartSizeMenu"
                class="d-flex flex-row-reverse align-items-center justify-content-around p-2 ms-3"
                matTooltip="Select Rose Chart Size"
                [color]="'primary'"
              >
                <div class="text-center">
                  {{ selectedRoseChartSize + " px" }}
                </div>
                <mat-icon class="m-0">arrow_drop_down</mat-icon>
              </button>

              <mat-menu
                #roseChartSizeMenu="matMenu"
                style="padding: 0"
                class=""
              >
                <button
                  mat-menu-item
                  *ngFor="let size of roseChartSizes; let i = index"
                  (click)="selectRoseChartSize(size)"
                  [value]="size"
                >
                  {{ size + " px" }}
                </button>
              </mat-menu>
            </div>
            <!-- End Integrated Rose Chart Size Dropdown -->

            <!-- Integrated Rose Chart Direction Dropdown -->
            <div *ngIf="analyticDetail.analyticType === 102">
              <button
                mat-raised-button
                [matMenuTriggerFor]="roseChartDirectionMenu"
                class="d-flex flex-row-reverse align-items-center justify-content-around p-2 ms-3"
                matTooltip="Select Direction Type"
                [color]="'primary'"
              >
                <div class="text-center">
                  {{ selectedRoseChartDirectionType ?? "Direction" }}
                </div>
                <mat-icon class="m-0">arrow_drop_down</mat-icon>
              </button>

              <mat-menu
                #roseChartDirectionMenu="matMenu"
                style="padding: 0"
                class=""
              >
                <button
                  mat-menu-item
                  *ngFor="
                    let directionType of roseChartDirectionTypes;
                    let i = index
                  "
                  (click)="selectRoseChartDirectionType(directionType)"
                  [value]="directionType"
                >
                  {{ directionType }}
                </button>
              </mat-menu>
            </div>
            <!-- End Integrated Rose Chart Direction Dropdown -->

            <!-- Hotspots Opacity Dropdown -->
            <div *ngIf="analyticDetail.analyticType === 103">
              <button
                mat-raised-button
                [matMenuTriggerFor]="hotspotsOpacityMenu"
                class="d-flex flex-row-reverse align-items-center justify-content-around p-2 ms-3"
                matTooltip="Select Hotspots Opacity"
                [color]="'primary'"
              >
                <div class="text-center">
                  {{ selectedHotspotsOpacity * 100 + " %" }}
                </div>
                <mat-icon class="m-0">arrow_drop_down</mat-icon>
              </button>

              <mat-menu
                #hotspotsOpacityMenu="matMenu"
                style="padding: 0"
                class=""
              >
                <button
                  mat-menu-item
                  *ngFor="let opacity of hotspotsOpacities; let i = index"
                  (click)="selectHotspotsOpacity(opacity)"
                  [value]="opacity"
                >
                  {{ opacity * 100 + " %" }}
                </button>
              </mat-menu>
            </div>
            <!-- End Heatmap Opacity Dropdown -->
            <!-- Hotspots Radius Dropdown -->
            <div *ngIf="analyticDetail.analyticType === 103">
              <button
                mat-raised-button
                [matMenuTriggerFor]="hotspotsRadiusMenu"
                class="d-flex flex-row-reverse align-items-center justify-content-around p-2 ms-3"
                matTooltip="Select Hotspots Radius"
                [color]="'primary'"
              >
                <div class="text-center">
                  {{ selectedHotspotRadius + " px" }}
                </div>
                <mat-icon class="m-0">arrow_drop_down</mat-icon>
              </button>

              <mat-menu
                #hotspotsRadiusMenu="matMenu"
                style="padding: 0"
                class=""
              >
                <button
                  mat-menu-item
                  *ngFor="let radius of hotspotRadiuses; let i = index"
                  (click)="selectHotspotRadius(radius)"
                  [value]="radius"
                >
                  {{ radius + " px" }}
                </button>
              </mat-menu>
            </div>
            <!-- End Heatmap Radius Dropdown -->
          </div>

          <div
            class="mx-4 flex-grow-1 d-flex align-items-center"
            (mouseenter)="showSelectedTime()"
            (mouseleave)="hideSelectedTime()"
          >
            <button
              mat-icon-button
              [disabled]="!selectedParameter"
              (click)="playPauseRoseMap()"
              class="me-4"
            >
              <mat-icon [color]="'primary'">
                {{ isPlaying ? "pause" : "play_arrow" }}
              </mat-icon>
            </button>

            <div class="flex-grow-1 position-relative">
              <mat-slider
                class="w-100 mx-0"
                [disabled]="sliderConfig.disabled"
                [max]="sliderConfig.max"
                [min]="sliderConfig.min"
                [step]="1"
                [discrete]="false"
                [showTickMarks]="true"
                size="small"
              >
                <input
                  matSliderThumb
                  [(ngModel)]="currentMapChartDataIndex"
                  (input)="updateRosechartV2()"
                  *ngIf="analyticDetail.analyticType === 102"
                />
                <input
                  matSliderThumb
                  [(ngModel)]="currentMapChartDataIndex"
                  (input)="updateHotspots()"
                  *ngIf="analyticDetail.analyticType === 103"
                />
              </mat-slider>

              <span
                class="selected-time-container position-absolute text-center p-2 mt-3 rounded-2 border border-1"
                [ngClass]="{
                  'd-none':
                    (!selectedTime.length || !shouldShowSelectedTime) &&
                    removeSelectedTime,
                  show: !(!selectedTime.length || !shouldShowSelectedTime),
                  hide: !selectedTime.length || !shouldShowSelectedTime
                }"
                [ngStyle]="selectedTimeStyles"
                [innerHTML]="selectedTime"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="googleMapsService.isApiLoaded | async">
        <div
          class="row mx-0 flex-grow-1"
          [ngClass]="{ 'd-none': loading !== 0 }"
        >
          <google-map
            class="col-12 px-0"
            width="100%"
            [options]="options"
            
          >
          </google-map>
          <div
            id="rose-chart-legend"
            *ngIf="analyticDetail.analyticType === 102 && roseChartLegendData"
          >
            <div
              id="rose-chart-legend-item"
              *ngFor="let legend of roseChartLegendData; let i = index"
            >
              <!-- <span>{{legend}}</span> -->
              <span
                id="legend-item-color"
                [style.backgroundColor]="legend.color"
                [style.color]="legend.color"
              ></span>
              <span class="legend-item-text" style="display: block">{{
                legend.name
              }}</span>
            </div>
          </div>
          <div
            id="rose-chart-legend"
            *ngIf="analyticDetail.analyticType === 103 && hotspotsLegendData"
          >
            <div
              id="rose-chart-legend-item"
              *ngFor="let legend of hotspotsLegendData; let i = index"
            >
              <!-- <span>{{legend}}</span> -->
              <span
                id="legend-item-color"
                [style.backgroundColor]="legend.color"
                [style.color]="legend.color"
              ></span>
              <span class="legend-item-text" style="display: block">{{
                legend.label
              }}</span>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 155px !important;">
          <app-common-map-controls
            [googleMap]="googleMap"
            [options]="options"
            (mapOptionsUpdated)="onMapOptionsUpdated($event)"
            [showComplainsLayerOption]="false"
            [showWindLayerOption]="false"
          ></app-common-map-controls>
        </div>
      </ng-container>

      <div class="py-5" *ngIf="loading !== 0">
        <mat-spinner
          class="mx-auto"
          [diameter]="50"
          color="primary"
        ></mat-spinner>
      </div>
    </div>
  </mat-sidenav-container>
</ng-container>
