<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <div class="col col-lg-auto col-12 col-lg-2 apply"></div>
  <app-data-table
    tableTitle="Data Flagging Table"
    [data]="deviceData"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    [stickyColumns]="['time']"
    (onDownload)="downloadFlagReportPDF()"
    [noDataViewConfig]="noData"
    (onRowSelect)="onRowSelect($event)"
    (onCellSelect)="onCellSelect($event)"
    (clearSelection)="clearSelection()"
    [isChartVisible]="true"
    (onColumnSelect)="onColumnSelect($event)"
    [showDownloadInHeader]="getDataFlaggingModuleOptions('download')"
  >
  </app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
