import { Line, Particle, Vector } from '../types/wind';
import { VectorField } from '../utils/wind-utils';
import { WindData } from '../types/wind';

interface ParticleSystemConfig {
  width: number;
  height: number;
  particleCount: number;
  particleLifetime: number;
  vectorField: VectorField;
  // particleColor: string;
  curlFactor: number; // Controls the intensity of curling
}

// export const getWindData: WindData[] = (bounds: any, points: any) => {
export const getWindData = (points: number): WindData[] => {
  // generate 20 random wind data points
  // const windData = [];
  // for (let i = 0; i < points; i++) {
  //   windData.push({
  //     lat: Math.random() * 180 - 90,
  //     lon: Math.random() * 360 - 180,
  //     direction: Math.random() * 360,
  //     magnitude: Math.random() * 10,
  //   });
  // }
  const windData = [
    { lat: 37.7749, lon: -122.4194, direction: 270, magnitude: 5.5 }, // San Francisco, USA
    { lat: 40.7128, lon: -74.006, direction: 180, magnitude: 7.2 }, // New York, USA
    { lat: 34.0522, lon: -118.2437, direction: 90, magnitude: 4.1 }, // Los Angeles, USA
    { lat: 51.5074, lon: -0.1278, direction: 60, magnitude: 3.8 }, // London, UK
    { lat: 48.8566, lon: 2.3522, direction: 270, magnitude: 6.0 }, // Paris, France
    { lat: 35.6762, lon: 139.6503, direction: 0, magnitude: 8.0 }, // Tokyo, Japan
    { lat: -33.8688, lon: 151.2093, direction: 90, magnitude: 5.3 }, // Sydney, Australia
    { lat: -34.6037, lon: -58.3816, direction: 180, magnitude: 7.5 }, // Buenos Aires, Argentina
    { lat: 39.9042, lon: 116.4074, direction: 270, magnitude: 4.7 }, // Beijing, China
    { lat: 55.7558, lon: 37.6173, direction: 45, magnitude: 5.2 }, // Moscow, Russia
    { lat: 37.9838, lon: 23.7275, direction: 180, magnitude: 3.6 }, // Athens, Greece
    { lat: 40.7306, lon: -73.9352, direction: 90, magnitude: 6.1 }, // Brooklyn, USA
    { lat: 28.6139, lon: 77.209, direction: 120, magnitude: 6.8 }, // New Delhi, India
    { lat: 19.4326, lon: -99.1332, direction: 270, magnitude: 5.0 }, // Mexico City, Mexico
    { lat: -22.9068, lon: -43.1729, direction: 0, magnitude: 7.0 }, // Rio de Janeiro, Brazil
    { lat: 40.7306, lon: -73.9352, direction: 90, magnitude: 6.1 }, // Brooklyn, USA
    { lat: 34.0522, lon: -118.2437, direction: 180, magnitude: 4.4 }, // Los Angeles, USA
    { lat: -34.4208, lon: -58.4436, direction: 45, magnitude: 3.5 }, // Mendoza, Argentina
    { lat: 41.9028, lon: 12.4964, direction: 300, magnitude: 5.6 }, // Rome, Italy
    { lat: 1.3521, lon: 103.8198, direction: 30, magnitude: 7.8 }, // Singapore
  ];
  return windData;
};

export class WindParticleSystem {
  public particles: Particle[];
  public config: ParticleSystemConfig;
  constructor(config: ParticleSystemConfig) {
    this.config = {
      ...config,
      curlFactor: config.curlFactor || 0.2,
    };
    this.particles = this.initializeParticles();
  }

  private initializeParticles(): Particle[] {
    return Array.from({ length: this.config.particleCount }, () =>
      this.createParticle()
    );
  }

  private createParticle(x?: number, y?: number): Particle {
    return {
      id: Math.random(),
      x: x ?? Math.random() * this.config.width,
      y: y ?? Math.random() * this.config.height,
      age: Math.random() * this.config.particleLifetime,
      curl: 0, // Initial curl value
      lines: [],
      trailLength: 1,
    };
  }

  private calculateCurl(x: number, y: number): number {
    const h = 1; // Small delta for numerical derivatives
    const vector = this.config.vectorField.getVector(x, y);
    const dxp = this.config.vectorField.getVector(x + h, y);
    const dxm = this.config.vectorField.getVector(x - h, y);
    const dyp = this.config.vectorField.getVector(x, y + h);
    const dym = this.config.vectorField.getVector(x, y - h);

    // Calculate partial derivatives
    const dvxdy = (dyp.x - dym.x) / (2 * h);
    const dvydx = (dxp.y - dxm.y) / (2 * h);

    // Curl in 2D is dvy/dx - dvx/dy
    return dvydx - dvxdy;
  }

  setDimensions(width: number, height: number): void {
    this.config.width = width;
    this.config.height = height;
  }

  update(): void {
    this.particles.forEach((particle, index) => {
      const vector = this.config.vectorField.getVector(particle.x, particle.y);
      const curl = this.calculateCurl(particle.x, particle.y);

      // Apply curl effect
      particle.curl =
        (particle.curl || 0) * 0.9 + curl * this.config.curlFactor;
      const curlEffect = particle.curl;

      // Update position with curl influence
      particle.x += vector.x + curlEffect * vector.y;
      particle.y += vector.y - curlEffect * vector.x;
      particle.age += 1;

      // Reset particles that are too old or out of bounds
      if (
        particle.age >= this.config.particleLifetime ||
        particle.x < 0 ||
        particle.x > this.config.width ||
        particle.y < 0 ||
        particle.y > this.config.height
      ) {
        this.particles[index] = this.createParticle();
      }
    });
  }

  draw(ctx: CanvasRenderingContext2D): void {
    // ctx.strokeStyle = this.config.particleColor;

    ctx.lineWidth = 2;

    ctx.clearRect(0, 0, this.config.width, this.config.height);

    this.particles.forEach((particle) => {
      const vector = this.config.vectorField.getVector(particle.x, particle.y);
      const normalizedAge = particle.age / this.config.particleLifetime;
      const alpha = Math.sin(Math.PI * normalizedAge);
      const intensity = Math.min(Math.abs(particle.curl || 0) * 2, 1);
      particle.trailLength = particle.trailLength + intensity * 200 / particle.age;

      const [x1, y1, x2, y2] = [
        particle.x,
        particle.y,
        particle.x + vector.x,
        particle.y + vector.y,
      ];
      const lightness = 50 + intensity * 20; // Adjust lightness based on intensity
      ctx.strokeStyle = `hsla(0, 0%, ${lightness}%, ${alpha})`;

      particle.trailLength = Math.min(
        particle.trailLength,
        this.config.particleLifetime - particle.age
      );
      const line: Line = { x1, y1, x2, y2, lightness, alpha };
      if (!Array.isArray(particle.lines)) {
        particle.lines = [];
      }
      particle.lines.push(line);
      while (particle.lines.length > particle.trailLength) {
        particle.lines.shift();
      }

      // this.lines.push([x1, y1, x2, y2, hue, alpha]);
      // this.particleLines[particle.id] = this.particleLines[particle.id] ?? { line: [], traillength: 10 };
      // if (this.particleLines[particle.id].line.length > this.particleLines[particle.id].traillength) {
      //   this.particleLines[particle.id].line.shift();
      // }
      // this.particleLines[particle.id].line.push([x1, y1, x2, y2, hue, alpha]);
      // if(particle.age > this.config.particleLifetime) {
      //   this.particleLines[particle.id] = null;
      //   console.log(this.particleLines[particle.id])
      // }
      // this.addLine(ctx, x1, y1, x2, y2, hue, alpha);

      // Color varies based on curl intensity
      // ctx.strokeStyle = `hsla(${hue}, 80%, 50%, ${alpha})`;
      // ctx.stroke();
      // await new Promise((resolve) => {setTimeout(resolve, 1000)});
      // this.removeLine(ctx, x1, y1, x2, y2);
    });
    // Object.values(this.particleLines).forEach((particleState) => {
    //   particleState.line.forEach(([x1, y1, x2, y2, hue, alpha]: any) => {
    //     this.addLine(ctx, x1, y1, x2, y2, hue, alpha);
    //   });
    // });

    // this.particles.forEach((particle) => {
    //   particle.lines.forEach(([x1, y1, x2, y2, hue, alpha]: any) => {
    //     const line: Line = { x1, y1, x2, y2, hue, alpha };
    //     this.addLine(ctx, line);
    //   });
    // });

    this.particles.forEach((particle) => {
      // Ensure particle.lines is an array before iterating
      if (Array.isArray(particle.lines)) {
        particle.lines.forEach((line) => {
          this.addLine(ctx, line);
        });
      }
    });
    ctx.stroke();
    // await new Promise((resolve) => {setTimeout(resolve, 1000)});
  }
  addLine(ctx: CanvasRenderingContext2D, line: Line) {
    ctx.beginPath();
    ctx.lineWidth = 0;
    ctx.moveTo(line.x1, line.y1);
    ctx.lineTo(line.x2, line.y2);
    // ctx.strokeStyle = `hsla(${line.hue}, 80%, 50%, ${line.alpha})`;
    ctx.strokeStyle = `hsla(0, 0%, ${line.lightness}%, ${line.alpha})`;


    ctx.stroke();
    ctx.closePath();
  }
  removeLine(ctx: CanvasRenderingContext2D, line: Line) {
    ctx.save();
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = `hsla(${0}, 80%, 50%, ${1})`;
    ctx.moveTo(line.x1, line.y1);
    ctx.lineTo(line.x2, line.y2);
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }
}
