import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { ClusterFormComponent } from 'src/app/layouts/terminal/pages/cluster/componets/cluster-form/cluster-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClusterRoutingModule } from './cluster-routing.module';
import { ClusterComponent } from './cluster.component';
import { DataComponent } from './pages/data/data.component';
import { MapComponent } from './pages/map/map.component';
import { TableComponent } from './pages/table/table.component';
import { ClusterReportComponent } from './componets/cluster-report/cluster-report.component';
import { ClusterDevicesPipe } from 'src/app/shared/pipes/cluster-devices.pipe';

@NgModule({
  declarations: [
    ClusterComponent,
    TableComponent,
    DataComponent,
    MapComponent,
    ClusterFormComponent,
    ClusterReportComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    ClusterRoutingModule,
    SharedModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [ClusterDevicesPipe],
})
export class ClusterModule {}
