import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent implements OnInit {
  public form!: FormGroup;
  public isNewPasswordCorrect: boolean = false;
  public isConfirmPasswordCorrect: boolean = false;
  public showPassword: boolean = false;
  public subscriptions: Subscription[] = [];

  constructor(
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private loginService: LoginService,
    private loadrService: LoadrService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.onChangeNewPassword();
    this.onChangeConfirmPassword();
  }

  //creates form for taking password inputs
  buildForm() {
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  //when user clicks on close form
  closeForm() {
    this.formsService.closeForm();
  }

  //when user clicks on submit button, this function does the api call and updates the password
  submitForm() {
    this.loadrService.showLoader();
    this.userService
      .updatePassword(this.generatePayload(this.form.value))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.loadrService.removeLoader();
            this.formsService.closeForm();
            this.notificationService.showSnackBar(res,'success');
          }
        },
        error: (err) => {
          this.loadrService.removeLoader();
          console.log(err);
          this.notificationService.showSnackBar(err.error,'error');
        },
      });
  }

  //when user types in new password input, this function checks whether it matches the expected pattern or not
  onChangeNewPassword() {
    let pswd = this.form.get('newPassword')?.valueChanges.subscribe((res) => {
      if (this.checkPasswordPattern(res)) {
        this.isNewPasswordCorrect = true;
      } else {
        this.isNewPasswordCorrect = false;
      }
    })!;
    this.subscriptions.push(pswd);
  }

  //when user types in confirm password input, this function checks whether the confirm password is equal to the new password
  onChangeConfirmPassword() {
    let cnfmPaswd = this.form
      .get('confirmPassword')
      ?.valueChanges.subscribe((res) => {
        if (this.form.get('newPassword')?.value === res) {
          this.isConfirmPasswordCorrect = true;
        } else {
          this.isConfirmPasswordCorrect = false;
        }
      })!;
    this.subscriptions.push(cnfmPaswd);
  }

  // it checks the password pattern that it has atleast one special character, number, capital letter, small letter and minimum length is 8
  checkPasswordPattern(password: string) {
    let passwordPattern = AppConstants.PASSWORD_REGEX;
    if (passwordPattern.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  //to change the password visibility (show password or hide password)
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  //to generate the payload for updating password
  generatePayload(data: any) {
    let key: string = this.localStorageService.getValue(
      LocalStorageConstants.ENCRYPTION_KEY
    )!;
    data.password = this.loginService.getEncryptedPassword(
      this.form.get('password')!.value,
      key
    );
    data.newPassword = this.loginService.getEncryptedPassword(
      this.form.get('newPassword')!.value,
      key
    );
    data.confirmPassword = this.loginService.getEncryptedPassword(
      this.form.get('confirmPassword')!.value,
      key
    );

    let payload = {
      ...data,
      email: this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).email,
    };
    return payload;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
