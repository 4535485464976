<app-terminal-page-layout [menu]="[]">
  <app-terminal-page-header [menu]="[]">
    <div
      [formGroup]="headerForm"
      class="d-flex flex-wrap h-100 align-items-center justify-content-between gap-2 gap-lg-0"
    >
      <div class="d-flex flex-wrap col-12 col-lg-auto gap-2">
        <mat-form-field class="col-12 col-lg-auto">
          <mat-label>Device Type</mat-label>
          <input
            type="text"
            #trigger="matAutocompleteTrigger"
            [placeholder]="'Device Type'"
            matInput
            formControlName="deviceType"
            [matAutocomplete]="deviceType"
          />
          <mat-autocomplete
            #deviceType="matAutocomplete"
            [displayWith]="getDeviceType"
          >
            <mat-option
              *ngFor="
                let deviceType of deviceTypes
                  | search : headerForm.get('deviceType')?.value : 'label'
              "
              [value]="deviceType"
            >
              {{ deviceType.label }}
            </mat-option>
          </mat-autocomplete>
          <button
            (click)="selectionMade($event, trigger)"
            mat-icon-button
            matSuffix
            tabindex="-1"
          >
            <mat-icon class="material-symbols-outlined">
              arrow_drop_down
            </mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div
        class="row col-12 col-lg-auto justify-content-end gap-2 gap-lg-0 p-0"
      >
        <div
          class="col-12 col-lg-auto"
          *ngIf="!userInfo.settings?.isReportMigrated"
        >
          <button
            mat-raised-button
            color="primary"
            class="w-100 w-lg-auto"
            matTooltip="Migrate Report"
            (click)="openDialog()"
          >
            Migrate Report
            <mat-icon matSuffix class="material-symbols-outlined"
              >trending_down</mat-icon
            >
          </button>
        </div>

        <div class="col-12 col-lg-auto">
          <button
            mat-raised-button
            color="primary"
            (click)="addReport()"
            class="w-100 w-lg-auto"
            matTooltip="Add Reports"
          >
            Add Reports
            <mat-icon matSuffix class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
