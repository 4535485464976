import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import parsePhoneNumber, {
  CountryCode,
  findPhoneNumbersInText,
  PhoneNumber,
} from 'libphonenumber-js';
import { AppConstants } from '../../constants/app-constants';
import {
  CountryWisePhoneCode,
  UserMobileNumbers,
} from '../../models/internal-use-front-end/country-wise-ph-code';
import { LoginData } from '../../models/login-data';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-mobile-number-input',
  templateUrl: './mobile-number-input.component.html',
  styleUrls: ['./mobile-number-input.component.scss'],
})
export class MobileNumberInputComponent implements OnInit {
  @Input() patchNumbers: string[] | [] = [];
  @Input() isMultiple: boolean = true;
  public placeHolder: string = '(+xx)';
  public numberList: CountryWisePhoneCode[] =
    AppConstants.COUNTRY_WISE_PHONE_CODE;
  public numberForm!: FormGroup;
  public allMobileNumbers: UserMobileNumbers = {
    validNumbers: [],
    invalidNumbers: [],
  };
  public currentIndexForEdit!: number | undefined;
  public filteredListOfNumbers!: CountryWisePhoneCode[];
  public selectedCountryCode!: CountryCode;
  @Output() phoneNumberAction: EventEmitter<UserMobileNumbers> =
    new EventEmitter();

  @ViewChild('countrySelect') countrySelect!: MatSelect;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.numberForm = this.formBuilder.group({
      mainInput: ['This form control is just made for using mat form field'],
      code: [''],
      number: [''],
      mobileNumberChipList: [
        'This form control is just made for using mat form field',
      ],
      autocompleteControl: [''],
    });
    this.numberForm.get('number')?.disable();
    this.numberList.forEach((res) => {
      (res as any).image = `assets/images/flags/${res.country_code}.svg`;
    });
    this.filteredListOfNumbers = this.numberList;

    this.numberForm.get('code')?.valueChanges.subscribe({
      next: (res) => {
        //manage placeholder
        if (res === '') this.placeHolder = '(+xx)';
        else this.placeHolder = 'Phone Number';

        if (typeof res !== 'string') {
          this.selectedCountryCode = res.country_code;
          if (!this.isMultiple && this.numberForm.get('number')?.enabled) {
            setTimeout(() => {
              // Trigger 'valueChanges' for 'number' by setting its value again
              const currentNumberValue = this.numberForm.get('number')?.value;
              this.numberForm
                .get('number')
                ?.setValue(currentNumberValue, { emitEvent: true });
            });
          } else {
            this.numberForm.get('number')?.enable();
          }
        } else {
          this.numberForm.get('number')?.disable();
          if (!this.isMultiple) {
            if (this.allMobileNumbers.validNumbers.length > 0) {
              this.allMobileNumbers.validNumbers.length = 0;
            }
            this.allMobileNumbers.invalidNumbers.length = 0;
            this.allMobileNumbers.invalidNumbers.push(
              this.numberForm.get('number')?.value
            );
            this.phoneNumberAction.emit(this.allMobileNumbers);
          }
        }

        this.filteredListOfNumbers = this.filterNumberList(res);
      },
    })!;

    if (!this.isMultiple) {
      //if no mobile number than try to patch country code of user
      if (!this.patchNumbers.length) {
        this.patchCountryCode();
      }

      if (this.patchNumbers.length) {
        this.patchNumbers.forEach((numb) => {
          let isValid = this.checkPhoneNumValidity(numb);

          if (isValid) {
            this.allMobileNumbers.validNumbers.push(numb);
          } else {
            this.allMobileNumbers.invalidNumbers.push(numb);
          }
        });

        //if number is valid than patch code and number in the form
        if (this.allMobileNumbers.validNumbers.length) {
          let result = findPhoneNumbersInText(
            this.allMobileNumbers.validNumbers[0]
          );

          if (result) {
            let countryCode = result[0].number.countryCallingCode as string;
            let mobNunber = result[0].number.nationalNumber;

            let code = this.numberList.find(
              (res) => String(res.phone_code) == countryCode
            );

            this.numberForm.patchValue({
              code: code,
              number: mobNunber,
            });
          }
        }
        //if number is invalid than patch number and show error
        else {
          this.patchCountryCode();

          this.numberForm.patchValue({
            number: this.allMobileNumbers.invalidNumbers[0].replace('+', ''),
          });

          this.numberForm.get('number')?.setErrors({
            invalidNumber: true,
          });
        }

        this.phoneNumberAction.emit(this.allMobileNumbers);
      }

      this.numberForm.get('number')?.valueChanges.subscribe((res) => {
        if (res) {
          let phone = this.checkPhoneNumValidity(res, this.selectedCountryCode);

          if (phone) {
            let userMobileNumber = phone.formatInternational();
            if (this.allMobileNumbers.invalidNumbers.length > 0) {
              this.allMobileNumbers.invalidNumbers.length = 0;
            }
            this.allMobileNumbers.validNumbers.length = 0;
            this.allMobileNumbers.validNumbers.push(userMobileNumber);
            this.phoneNumberAction.emit(this.allMobileNumbers);
            this.numberForm.get('number')?.setErrors({});
          } else {
            if (this.allMobileNumbers.validNumbers.length > 0) {
              this.allMobileNumbers.validNumbers.length = 0;
            }
            this.allMobileNumbers.invalidNumbers.length = 0;
            this.allMobileNumbers.invalidNumbers.push(res);
            this.phoneNumberAction.emit(this.allMobileNumbers);
            this.numberForm.get('number')?.setErrors({
              invalidNumber: true,
            });
          }
        }
      });
    }

    if (this.isMultiple && this.patchNumbers.length) {
      this.patchNumbers.forEach((numb) => {
        let isValid = this.checkPhoneNumValidity(numb);

        if (isValid) {
          this.allMobileNumbers.validNumbers.push(numb);
        } else {
          this.allMobileNumbers.invalidNumbers.push(numb);
          this.numberForm.get('mobileNumberChipList')?.updateValueAndValidity();
        }
      });

      this.phoneNumberAction.emit(this.allMobileNumbers);
    }
  }

  private filterNumberList(value: string): CountryWisePhoneCode[] {
    const filterValue = value
      .toLowerCase?.()
      .replace('(', '')
      .replace(')', '')
      .replace('+', '');

    // Check if value matches either country_en or phone_code
    return filterValue
      ? this.numberList.filter((country) => {
          return (
            country.country_en.toLowerCase().includes(filterValue) ||
            country.phone_code.toString().includes(filterValue)
          );
        })
      : this.numberList;
  }

  private patchCountryCode(): void {
    let defaultCountry = this.numberList[0]; //set first element as default country code
    let user: LoginData = this.commonService.getUser();
    if (user.country) {
      let country = user.country.trimStart().trimEnd().toLowerCase();

      let userCountryFound = this.numberList.find(
        (num) => num.country_en.trimStart().trimEnd().toLowerCase() === country
      );

      if (userCountryFound) {
        defaultCountry = userCountryFound; //if user country found than patch that
      }
    }

    this.numberForm.get('number')?.enable();
    this.numberForm.patchValue({
      code: defaultCountry,
    });
  }

  displayFn(country: CountryWisePhoneCode): string {
    return country ? `(+${country.phone_code})` : '';
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  addMobileNumber() {
    if (
      !this.numberForm.value.number ||
      !this.numberForm.value.code.country_code
    ) {
      this.numberForm.get('number')?.setErrors({
        invalidNumber: true,
      });
      return;
    }

    let phone = this.checkPhoneNumValidity(
      this.numberForm.value.number,
      this.numberForm.value.code.country_code
    );

    if (phone) {
      let userMobileNumber = phone.formatInternational();
      if (!this.allMobileNumbers.validNumbers.includes(userMobileNumber)) {
        this.numberForm.patchValue({
          number: [''],
        });
        this.allMobileNumbers.validNumbers.push(userMobileNumber);
        this.phoneNumberAction.emit(this.allMobileNumbers);
      } else {
        this.numberForm.get('number')?.setErrors({
          numberExist: true,
        });
      }
    } else {
      this.numberForm.get('number')?.setErrors({
        invalidNumber: true,
      });
    }
  }

  editButton() {
    let phone = this.checkPhoneNumValidity(
      this.numberForm.value.number,
      this.numberForm.value.code.country_code
    );

    if (phone) {
      let userMobileNumber = phone.formatInternational();
      this.numberForm.patchValue({
        number: [''],
      });
      this.allMobileNumbers.validNumbers[this.currentIndexForEdit! - 1] =
        userMobileNumber;
      this.currentIndexForEdit = undefined;
      this.phoneNumberAction.next(this.allMobileNumbers);
    } else {
      this.numberForm.get('number')?.setErrors({
        invalidNumber: true,
      });
    }
  }

  editMobileNumber(number: string, i: number) {
    this.currentIndexForEdit = i + 1;
    let result = findPhoneNumbersInText(number);
    let countryCode = result[0].number.countryCallingCode as string;
    let mobNunber = result[0].number.nationalNumber;

    let code = this.numberList.find(
      (res) => String(res.phone_code) == countryCode
    );

    this.numberForm.patchValue({
      code: code,
      number: mobNunber,
    });
  }

  removeNumber(removingValidNumber: boolean, i: number) {
    if (removingValidNumber) {
      this.allMobileNumbers.validNumbers.splice(i, 1);
      this.numberForm.patchValue({
        number: [''],
      });
      this.currentIndexForEdit = undefined;
    } else {
      this.allMobileNumbers.invalidNumbers.splice(i, 1);
    }

    this.phoneNumberAction.next(this.allMobileNumbers);
  }

  checkPhoneNumValidity(
    number: string,
    countryCode?: CountryCode
  ): PhoneNumber | undefined {
    const phoneNumber = parsePhoneNumber(number, countryCode);

    if (phoneNumber && phoneNumber?.isValid() && phoneNumber.isPossible()) {
      return phoneNumber;
    } else {
      return undefined;
    }
  }

  openCountryCodeDropdown() {
    // Manually trigger the mat-select dropdown to open
    if (this.countrySelect) {
      this.countrySelect.open();
    }
  }
}
