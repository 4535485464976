import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
})
export class FormContainerComponent {
  @Input() tableTitle: any = '';
  @Input() hintText?: string;
  @Input() infoMsg?: string;
  @Input() submitBtn: string = '';
  @Input() submitBtnTooltip: string = '';
  @Input() cancelBtn: string = 'Cancel';
  @Input() showCloseBtn: boolean = true;
  @Input() isValid: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();
  isSubmitting: boolean = false;
  submitForm() {
    if (this.isValid) {
      this.submit.emit();
      // this.isValid = false;
    }
  }
}
