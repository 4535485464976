export class MapChartUtils {
    public static prepareGaussianPdfAttributes(values: number[]): {mean: number, standardDeviation: number} {
        values  = values.filter((v: any) => !isNaN(v));
        const mean = values.reduce((a: number, b: number) => a + b) / values.length; // calculating arithmatic mean instead of median
        const variance = values.reduce((a: number, b: number) => a + Math.pow(b - mean, 2)/ values.length - 1, 0)
        const standardDeviation = Math.sqrt(variance);
        return {mean, standardDeviation};
      }
    public static getGaussianDistribution(value: number, mean: number, standardDeviation: number): number {
        return (1 / (standardDeviation * Math.sqrt(2 * Math.PI))) * Math.exp(-Math.pow(value - mean, 2) / (2 * Math.pow(standardDeviation, 2)));
    }
    public static getDistanceBetweenPoints(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        const d = R * c; // Distance in km
        return d;
    }
    public static interpolateColor(color1: string, color2: string, factor: number): string {
        if (arguments.length < 3) { factor = 0.5; }
        const colorSegment1 = color1.slice(1).match(/.{2}/g);
        const colorSegment2 = color2.slice(1).match(/.{2}/g); 
        if (!colorSegment1 || !colorSegment2) {
            return 'Incorrect color format, expected hex';
        }
        const result = colorSegment1.map((v: string, i: number) => {
            return Math.ceil(parseInt(v, 16) + factor * (parseInt(colorSegment2[i], 16) - parseInt(v, 16))).toString(16);
        });
        return `#${result.join('')}`;
    }
    public static deg2rad(deg: number): number {
        return deg * (Math.PI/180)
    }
    public static exponentialDecay(radius: number, distance: number, decayRate: number): number {
        return radius / Math.pow((1 + decayRate), distance);
    }

}