import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-progress-bar',
  templateUrl: './field-progress-bar.component.html',
  styleUrls: ['./field-progress-bar.component.scss'],
})
export class FieldProgressBarComponent {
  @Input() value: number = 0;
  @Input() color: string = '#fff';
}
