<div
  class="w-100"
  [formGroup]="inputForm"
  class="d-flex flex-column flex-lg-row gap-2"
>
  <ng-container *ngIf="tags.length">
    <mat-form-field class="col-12 col-md-6">
      <mat-label>Device Tags</mat-label>
      <mat-select
        formControlName="tag"
        [multiple]="isMultipleTag"
        placeholder="No Tags Selected"
      >
        <mat-option
          class="option-icon-with-label"
          *ngFor="let tag of tags"
          [value]="tag"
        >
          <span class="badge">
            {{ tag }}
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!isMultipleDeviceSelection">
    <mat-form-field
      class="col-12"
      [ngClass]="tags.length ? 'col-md-6' : 'col-md-12'"
    >
      <mat-label>Devices</mat-label>
      <input
        type="text"
        #trigger="matAutocompleteTrigger"
        [placeholder]="'Device'"
        matInput
        formControlName="deviceSearch"
        [matAutocomplete]="deviceName"
      />
      <mat-autocomplete
        #deviceName="matAutocomplete"
        [displayWith]="getDeviceName"
      >
        <mat-optgroup
          *ngFor="let deviceType of deviceTypes"
          [label]="deviceType.label"
        >
          <mat-option
            *ngFor="
              let device of devices
                | deviceType : deviceType.key
                | search : inputForm.get('deviceSearch')?.value
            "
            [value]="device"
            class="option-icon-with-label"
          >
            <span>
              {{ device.label }}
            </span>
            <ng-container *ngIf="device?.options?.markedAsFavourite">
              <span
                class="material-symbols-outlined material-symbols-filled icon-with-label"
              >
                star
              </span>
            </ng-container>
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <button
        *ngIf="inputForm.get('deviceSearch')?.value && showButton"
        mat-icon-button
        matSuffix
        matTooltip="Close"
        aria-label="clear"
        style="margin-right: -18px; z-index: 1"
        (click)="clearDeviceName(); $event.stopPropagation()"
      >
        <mat-icon class="material-symbols-outlined">close</mat-icon>
      </button>
      <button
        (click)="selectionMade($event, trigger)"
        mat-icon-button
        matSuffix
        tabindex="-1"
        class=""
        style="z-index: 0"
      >
        <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isMultipleDeviceSelection">
    <mat-form-field
      class="col-12"
      [ngClass]="tags.length ? 'col-md-6' : 'col-md-12'"
    >
      <mat-label>Select Device</mat-label>
      <mat-select formControlName="deviceSearch" multiple>
        <app-multiple-select-dropdown
          [options]="listOfDeviceIds"
          [control]="paramsControl"
        ></app-multiple-select-dropdown>
        <mat-optgroup
          *ngFor="let deviceType of deviceTypes"
          [label]="deviceType.label"
        >
          <mat-option
            *ngFor="let device of devices ?? [] | deviceType : deviceType.key"
            [value]="device.deviceId"
          >
            {{ device.label }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <div
    class="col-12 d-flex lex-shrink-1"
    [ngClass]="tags.length ? 'col-md-6' : 'col-md-auto'"
    *ngIf="!inputForm.get('deviceSearch')?.value && infoMessage.length"
  >
    <div class="flex-grow-0 flex-shrink-1 d-flex align-items-center">
      {{ infoMessage }}
    </div>
  </div>
</div>
