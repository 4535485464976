import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { DeviceDetails } from '../models/device/device-details';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable, finalize, map, tap } from 'rxjs';
import { Cluster } from '../models/cluster.ts/cluster';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDataResponse } from '../models/device/device-data';
import { CommonUtil } from '../utils/common-utils';
import { CommonService } from './common.service';
import { CustomMomentService } from './custom-moment.service';
import { DeviceService } from './device.service';
import { Moment } from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class ClusterService extends BaseAPIService<any> {
  public clusterFilterData = new BehaviorSubject<any>({});
  clusterFilterData$ = this.clusterFilterData.asObservable();

  public isGetData = new BehaviorSubject<boolean>(true);
  isGetData$ = this.isGetData.asObservable();

  clusterData!: Array<Cluster>;
  hourlyData: any[] = [];
  public currentDeviceType: DeviceType =
    this.commonService.getUserDeviceTypes()[0];

  private selectedClusterSubject = new BehaviorSubject<any>(null);
  selectedCluster$ = this.selectedClusterSubject.asObservable();

  private selectedDeviceTypeSubject = new BehaviorSubject<any>(null);
  selectedDeviceType$: Observable<DeviceType> =
    this.selectedDeviceTypeSubject.asObservable();

  private selectedStartDateSubject = new BehaviorSubject<any>(null);
  selectedStartDate$: Observable<Moment> =
    this.selectedStartDateSubject.asObservable();

  private selectedEndDateSubject = new BehaviorSubject<any>(null);
  selectedEndDate$: Observable<Moment> =
    this.selectedEndDateSubject.asObservable();

  private avgDataValSubject = new BehaviorSubject<any>(null);
  avgDataVal$: Observable<number> =
    this.selectedStartDateSubject.asObservable();

  private loaderSubject = new BehaviorSubject<boolean>(false);

  private idSource = new BehaviorSubject<string>('');
  fetchedCurrentId = this.idSource.asObservable();

  public filterClusterlabel: any;
  public currentDeviceName: any;

  private selectedDeviceNameSubject = new BehaviorSubject<any>(null);
  selectedDeviceName$: Observable<DeviceType> =
    this.selectedDeviceNameSubject.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    public deviceService: DeviceService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
  }

  showLoader(value: boolean) {
    this.loaderSubject.next(value);
  }
  getLoaderState(): Observable<boolean> {
    return this.loaderSubject.asObservable();
  }
  getCluster(): Observable<any> {
    this.showLoader(true);
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.get<Array<any>>(
      APIConstants.GET_CLUSTERS().replace('{userId}', userId),
      headers
    ).pipe(
      tap((data) => {
        this.clusterData = data.map((item) => {
          if (item.data && item.data.payload) {
            const payload = item.data.payload;
            return { ...item, payload };
          }
          // return item;
          return this.clusterData;
        });
      }),
      finalize(() => {
        this.showLoader(false);
      })
    );
  }
  getCachedCluster(): any {
    return this.clusterData;
  }
  getHourlyData(
    clusterId: string,
    gte: number,
    lte: number,
    average: number
  ): Observable<DeviceDetails[]> {
    let startTimestamp = this.customMomentService.moment
      .unix(gte)
      .startOf('day')
      .unix();
    const endDate = this.customMomentService.moment.unix(lte);
    const today = this.customMomentService.moment();
    let endTimestamp = endDate.unix();

    if (endDate.isSame(today, 'day')) {
      endTimestamp = today.startOf('hour').unix();
    }

    let avg = average * 3600;

    endTimestamp = CommonUtil.modifyLteTimestamp(
      startTimestamp,
      endTimestamp,
      avg
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll({
      'deviceIds[]': clusterId,
      gte: startTimestamp,
      lte: endTimestamp,
      avg: avg,
      processType: 'avg',
      userId: userId,
    });
    return this.get<Array<DeviceDataResponse>>(
      APIConstants.HOURLY_DEVICE_DATA,
      headers,
      params
    ).pipe(
      map((deviceDetailRes: Array<DeviceDataResponse>) => {
        let deviceDetails: any = deviceDetailRes[0].payload;
        this.hourlyData = deviceDetails;
        return deviceDetails;
      })
    );
  }
  getAllClusterUnitData(clusters: Array<Cluster>): Array<Cluster> {
    const allUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_UNITS
    );

    return clusters.map((cluster) => {
      if (typeof cluster.units === 'number') {
        cluster.units = allUnits[cluster.units];
      }
      return cluster;
    });
  }

  addNewCluster(payload: any) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.post<Array<any>>(
      APIConstants.ADD_CLUSTER().replace('{userId}', userId),
      payload,
      headers
    ).pipe(
      tap((data) => {
        this.clusterData = data;
      })
    );
  }

  updateCluster(payload: any, clusterId: any) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.patch<Array<any>>(
      APIConstants.UPDATE_CLUSTER(clusterId).replace('{userId}', userId),
      payload,
      headers
    ).pipe(
      tap((data) => {
        this.clusterData = data;
      })
    );
  }

  setSelectedCluster(cluster?: Cluster): void {
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const allUnits: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_UNITS
    );
    const devices = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );
    if (cluster?.config?.deviceType != null) {
      this.deviceService.setUpDeviceTypeGlobally(
        cluster?.config?.deviceType,
        devices,
        allAqis,
        allUnits
      );
    }
    this.selectedClusterSubject.next(cluster);
  }

  getSelectedCluster(): Observable<any> {
    return this.selectedClusterSubject.asObservable();
  }
  clearSelectedCluster(): void {
    this.selectedClusterSubject.next(null);
  }
  getSelectedDeviceType(): Observable<any> {
    return this.selectedDeviceTypeSubject.asObservable();
  }
  setStartDate(date: Moment): void {
    this.selectedStartDateSubject.next(date);
  }
  getStartDate(): Observable<Moment> {
    return this.selectedStartDateSubject.asObservable();
  }
  setEndDate(date: Moment): void {
    this.selectedEndDateSubject.next(date);
  }
  getEndDate(): Observable<Moment> {
    return this.selectedEndDateSubject.asObservable();
  }
  setAvg(avg: number) {
    this.avgDataValSubject.next(avg);
  }
  getAvg(): Observable<number> {
    return this.avgDataValSubject.asObservable();
  }

  setSelectedDeviceName(deviceName: any): void {
    this.filterClusterlabel = this.clusterData.filter(
      (cluster) => cluster.clusterId === deviceName
    );
    this.currentDeviceName = this.filterClusterlabel[0];
    this.selectedDeviceNameSubject.next(this.currentDeviceName);
  }

  deleteCluster(clusterId: any): any {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.delete<Array<any>>(
      APIConstants.DELETE_CLUSTER(clusterId).replace('{userId}', userId),
      headers
    );
  }

  fetchedClusterId(id: string) {
    this.idSource.next(id);
  }
}
