import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { LoginData } from '../models/login-data';
import { CustomMomentService } from '../services/custom-moment.service';
import { LocalStorageService } from '../services/local-storage.service';
import { CommonUtil } from '../utils/common-utils';

export const canAccessModule = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  let localStorageService: LocalStorageService = inject(LocalStorageService);
  let customMomentService: CustomMomentService = inject(CustomMomentService);
  let router: Router = inject(Router);
  const user: LoginData = localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER
  );
  let modulesWithUser = user.modules;

  let modulesExpiry = user.moduleExpiry;

  //the url user is trying to access
  let currentUrl = state.url;

  let currentModulePath = state.url.split('/');

  //to get module details from module id
  let moduleDetails = CommonUtil.getModuleIdByUrl(currentUrl);

  let currentTime: number = customMomentService.moment().unix();

  if (
    CommonUtil.isModuleValid(
      currentUrl,
      currentTime,
      modulesExpiry,
      modulesWithUser,
      user.role
    )
  )
    return true;
  else {
    router.navigateByUrl(`/lock-screen/${currentModulePath[1]}`, {
      state: { moduleId: moduleDetails.moduleId },
    });
    return false;
  }
};
