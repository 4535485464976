import { Component, Input } from '@angular/core';
import { SubMenu } from '../../models/menu/sub-menu';

@Component({
  selector: 'app-terminal-page-layout',
  templateUrl: './terminal-page-layout.component.html',
  styleUrls: ['./terminal-page-layout.component.scss']
})
export class TerminalPageLayoutComponent {
  @Input() menu!: SubMenu[];

}
