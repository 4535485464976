import { SelectionTile } from '../models/internal-use-front-end/selection-tile';

export class AnalyticsConstant {
  public static cardDetails: SelectionTile[] = [
    {
      id: 1,
      title: 'Parameter Comparison',
      darkModeImageUrl:
        'assets/images/analytics/analytic-types/Parameter_Comparison.png',
      lightModeImageUrl:
        'assets/images/analytics/analytic-types/Parameter_Comparison_Light.png',
      icon: 'info',
      iconTooltip:
        'You will be able to compare multiple parameters for a single device for a particular time period. You can also check hourly, daily and weekly phenomena. You can choose up to Ten Parameters at a time.',
    },
    {
      id: 2,
      title: 'Device Comparison',
      darkModeImageUrl:
        'assets/images/analytics/analytic-types/Device_Comparison.png',
      lightModeImageUrl:
        'assets/images/analytics/analytic-types/Device_Comparison_Light.png',
      icon: 'info',
      iconTooltip:
        'Compare the same parameter of different devices . This analytics will allow you to evaluate devices based on their readings over the same period, providing more insights.'
    },

    {
      id: 4,
      title: 'Pollution Rose',
      darkModeImageUrl:
        'assets/images/analytics/analytic-types/Pollution_Rose.png',
      lightModeImageUrl:
        'assets/images/analytics/analytic-types/Pollution_Rose_Light.png',
      icon: 'info',
      iconTooltip:
        'Visualize pollution levels in relation to wind direction using the Pollution Rose chart. This feature lets you analyze the intensity and spread of pollutants, helping you understand how wind patterns influence air quality.'
    },

    {
      id: 5,
      title: 'Inter Device Comparison',
      darkModeImageUrl:
        'assets/images/analytics/analytic-types/Inter_Devicetype_Comparison.png',
      lightModeImageUrl:
        'assets/images/analytics/analytic-types/Inter_Devicetype_Comparison_Light.png',
      icon: 'info',
      iconTooltip:
        'You can compare multiple devices for a single parameter across a specific time period. Analyze hourly, daily, and weekly variations, and compare multiple devices simultaneously.'
    },
  ];
  public static allAverages = [
    {
      label: 'Raw Data',
      value: 0,
    },
    {
      label: '15 min avg',
      value: 900,
    },
    {
      label: '30 min avg',
      value: 1800,
    },
    {
      label: '1 hour avg',
      value: 3600,
    },
    {
      label: '8 hour avg',
      value: 28800,
    },
    {
      label: '8 hour moving avg',
      value: 28800,
      ravg: true,
    },
    {
      label: '24 hour avg',
      value: 86400,
    },
    {
      label: '24 hour moving avg',
      value: 86400,
      ravg: true,
    },
  ];

  public static lineStyles = [
    'Solid',
    'Dash',
    'DashDot',
    'Dot',
    'LongDash',
    'LongDashDot',
    'LongDashDotDot',
    'ShortDash',
    'ShortDashDot',
    'ShortDashDotDot',
    'ShortDot',
  ];
}
