import { Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ConfigurationData } from 'src/app/shared/models/configuration/configuration-data';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss'],
})
export class SensorListComponent {
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = [];
  updatedConfigData!: any[];
  public configListData!: ConfigurationData.Get;
  public configListToVisible: any;
  public currentDeviceType: number = 0;
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };

  defaultColumns: any[] = [
    {
      columnDef: 'actions',
      header: '',
      cell: (element: ConfigurationData.Get) => `${element}`,
      parameter: false,
      icon: 'more_vert',
      options: [
        {
          label: (element: any) =>
            `<span class="material-symbols-outlined">${
              element?.en === 1 ? 'disabled_by_default' : 'check_box'
            }</span>&nbsp;${element?.en === 1 ? 'Disable' : 'Enable'}`,
          action: (element: any) => {
            this.updateEnValue(element);
          },
        },
      ],
    },
    {
      columnDef: 'label',
      header: 'Label',
      cell: (element: any) =>
        `${element.label.charAt(0).toUpperCase()}${element.label.slice(1)}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'sensorId',
      header: 'Sensor ID',
      cell: (element: any) => `${element.sensorId ? element.sensorId : '-'}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'lan-status',
      header: 'Status',
      cell: (element: any) => {
        let statusText = '';
        if (element?.en === 0) {
          statusText = 'Disabled';
        } else if (element?.en === 1) {
          statusText = 'Enabled';
        }
        return `${statusText}`;
      },
      color: (element: any) => {
        let statusClass = '';
        if (element?.en === 0) {
          statusClass = 'red';
        } else if (element?.en === 1) {
          statusClass = 'green';
        }
        return `${statusClass}`;
      },
      parameter: false,
      selected: false,
    },
  ];

  constructor(
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.configurationService.isConfigData$.subscribe((data: boolean) => {
      if (data) {
        this.configListData = this.configurationService.configurationData;
        if (this.configListData.config) {
          const configListToVisibleKeys = [
            'samd',
            'co2',
            'dust',
            'uvlight',
            'temp',
            'ogs',
          ];
          const ignoreConfigList = ['watchdog'];
          let globalIndex = 0;

          this.configListToVisible = configListToVisibleKeys.reduce(
            (returnConfigListToVisible: any, configKey: any) => {
              let configList: any = this.configListData.config;
              const configKeyData = configList[configKey];
              if (configKeyData) {
                let returnVisibleData: any = [];
                if (Array.isArray(configKeyData) && configKeyData.length) {
                  configKeyData.forEach((eachConfigKeyData, index) => {
                    let label = eachConfigKeyData.lb
                      ? eachConfigKeyData.lb
                      : configKey;

                    if (!ignoreConfigList.includes(eachConfigKeyData.lb)) {
                      returnVisibleData.push({
                        ...eachConfigKeyData,
                        label: eachConfigKeyData.lb
                          ? eachConfigKeyData.lb
                          : label,
                        itemKey: configKey,
                        index: globalIndex,
                      });
                      globalIndex++;
                    }
                  });
                } else {
                  Object.entries(configKeyData).forEach(
                    ([labelKey, labelData]: [string, any]) => {
                      if (
                        labelData?.length &&
                        !ignoreConfigList.includes(labelKey)
                      ) {
                        returnVisibleData.push({
                          ...labelData[0],
                          label: labelData[0].lb
                            ? labelData[0].lb
                            : labelKey
                            ? labelKey
                            : configKey,
                          itemKey: configKey,
                          index: globalIndex,
                        });
                        globalIndex++;
                      }
                    }
                  );
                }
                return [...returnConfigListToVisible, ...returnVisibleData];
              }
              return returnConfigListToVisible;
            },
            []
          );
          if (this.checkIfSensorIdExists()) {
            const sensorIdIndex = 2;
            if (!this.displayedColumns.includes('sensorId')) {
              this.displayedColumns.splice(sensorIdIndex, 0, 'sensorId');
            }
          } else {
            this.displayedColumns = this.displayedColumns.filter(
              (col) => col !== 'sensorId'
            );
          }
          this.configurationService.currentDeviceType$.subscribe((data) => {
            this.currentDeviceType = data;
          });
          this.loadData();
        } else {
          this.loadData();
        }
      } else {
        this.loadTable.next(false);
      }
    });
  }

  checkIfSensorIdExists(): boolean {
    return this.configListToVisible.some((item: any) => !!item.sensorId);
  }

  loadData() {
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  updateEnValue(element: any) {
    const updatedValue = element.en === 1 ? 0 : 1;

    const index = this.configListToVisible.findIndex(
      (item: any) => item.index === element.index
    );

    if (index !== -1) {
      this.configListToVisible[index].en = updatedValue;
      this.updateMainJsonWithSelectedElement({ ...element, en: updatedValue });
    }
    this.generatePayload();
  }

  updateMainJsonWithSelectedElement(selectedElement: any) {
    const selectedParameters = selectedElement.parameters;
    const updatedConfigListData = JSON.parse(
      JSON.stringify(this.configListData)
    );
    const item = this.configListToVisible[selectedElement.index];
    const configSection = updatedConfigListData.config?.[item.itemKey];

    const getLabel = (element: any, fallbackLabel: string) => {
      return element.lb ? element.lb : fallbackLabel;
    };

    if (Array.isArray(configSection)) {
      configSection.forEach((eachConfigKeyData: any) => {
        const label = getLabel(eachConfigKeyData, item.itemKey);
        if (label === selectedElement.label) {
          const match = this.isParametersEqual(
            eachConfigKeyData.parameters,
            selectedParameters
          );
          if (match) {
            eachConfigKeyData.en = selectedElement.en;
          }
        }
      });
    } else if (configSection && typeof configSection === 'object') {
      Object.entries(configSection).forEach(
        ([labelKey, labelData]: [string, any]) => {
          if (Array.isArray(labelData)) {
            labelData.forEach((subItem: any) => {
              const label = getLabel(subItem, labelKey);
              if (label === selectedElement.label) {
                const match = this.isParametersEqual(
                  subItem.parameters,
                  selectedParameters
                );
                if (match) {
                  subItem.en = selectedElement.en;
                }
              }
            });
          }
        }
      );
    } else {
    }
    this.configListData = updatedConfigListData;
  }

  generatePayload() {
    const updatedConfigListData = JSON.parse(
      JSON.stringify(this.configListData)
    );
    this.changeConfigStatus({
      userId: this.localStorageService.getValue(LocalStorageConstants.USER_ID),
      update_note: '(From Envizom) Configuration updated',
      data: { ...updatedConfigListData },
    });
  }

  isParametersEqual(param1: any, param2: any): boolean {
    if (!param1 || !param2) return false;
    const keys1 = Object.keys(param1);
    const keys2 = Object.keys(param2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (typeof param1[key] === 'object' && typeof param2[key] === 'object') {
        if (!this.isParametersEqual(param1[key], param2[key])) {
          return false;
        }
      } else if (param1[key] !== param2[key]) {
        return false;
      }
    }
    return true;
  }

  changeConfigStatus(payload: any) {
    this.loadTable.next(false);
    this.configurationService
      .updateConfigurationData(payload, this.configListData.deviceId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.configurationService.getConfigurationData(
              this.configListData.deviceId
            );
            this.notificationService.showSnackBar(
              'Configuration status updated successfully!',
              'success'
            );
          }
        },
        error: (err) => {
          this.notificationService.showSnackBar(
            'Error while updating configuration status!',
            'error'
          );
          console.info("Error:", err);
        },
      });
  }
}
