import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbCardModule,
  NbTimepickerModule,
} from '@nebular/theme';

const MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
];

@NgModule({
  declarations: [],
  imports: [
    NbTimepickerModule.forRoot({
      localization: {
        hoursText: 'Hr',
        minutesText: 'Min',
        secondsText: 'Sec',
        ampmText: 'Am/Pm',
      },
    }),
    ...MODULES,
  ],
  exports: [NbTimepickerModule, ...MODULES],
})
export class NebularModule {}
