import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: Date): any {
    return value.toLocaleString('en-IN').split(',')[0];
  }
}
