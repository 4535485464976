import * as moment from 'moment';
import { ModuleCheckbox } from 'src/app/shared/models/module-checkbox';
import { AppConstants } from '../constants/app-constants';
import { AverageHour } from '../models/average-hour';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceField } from '../models/device/device-field';
import { FieldLimit } from '../models/device/field-limit';
import { FilterValue } from '../models/filter-value';

export class CommonUtil {
  public static isStringArrayEqual(
    x: Array<string>,
    y: Array<string>
  ): boolean {
    let isEqual = true;

    y.forEach((el) => {
      if (!x.includes(el)) {
        isEqual = false;
      }
    });

    return isEqual;
  }

  public static getDisplayTime(time: number): string {
    return time
      ? moment.unix(time).format('hh:mm A, DD/MM/YY')
      : 'No data available';
  }

  public static sortAlphaNumDeviceDetailsAsc(
    a: DeviceDetails,
    b: DeviceDetails
  ) {
    const reA = /[^a-zA-Z]/g;
    const reN = /[^0-9]/g;
    const aA = a.label.replace(reA, '');
    const bA = b.label.replace(reA, '');
    if (aA === bA) {
      const aN = a?.loc !== null ? parseInt(a.loc.replace(reN, ''), 10) : '';
      const bN = b?.loc !== null? parseInt(b.loc.replace(reN, ''), 10) : '';
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  public static isEmpty(object: any): boolean {
    return object && Object.keys(object).length === 0;
  }

  public static downloadFile(data: string, fileName: string): void {
    const csvData = new Blob(['\ufeff' + data], {
      type: 'text/csv;charset=utf-8;',
    });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(csvData);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', fileName + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  public static buildFilter(filter: any): any {
    let query: any = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  }

  public static deviceFilter(
    device: any,
    query: any,
    selectedFilters: Array<FilterValue>
  ): boolean {
    let isValid: boolean = true;
    const hasActiveFilters = selectedFilters.length > 0;
    if (hasActiveFilters && (!device.payload || device.payload.length === 0)) {
      return false;
    }
    for (let key of Object.keys(query)) {
      const filter = selectedFilters.find((sf) => sf.key === key);
      const value = query[key][0];

      if (key === 'status') {
        if (!device[key]['msg'].includes(value)) {
          isValid = false;
        }
      } else if (key === 'time') {
        if (
          device['lastUpdated'] === undefined ||
          !moment(device['lastUpdated'])
            .format('hh:mm A, DD/MM/YYYY')
            .includes(value)
        ) {
          isValid = false;
        }
      } else if (filter?.parameter) {
        if (device?.payload && device?.payload?.d) {
          if (device?.payload?.d[key!] == undefined) {
            isValid = false;
          } else {
            if (filter.filterBy === 'equalsTo') {
              isValid =
                parseFloat(device?.payload?.d[key!]) === parseFloat(value);
            } else if (filter.filterBy === 'greaterThan') {
              isValid =
                parseFloat(device?.payload?.d[key!]) > parseFloat(value);
            } else if (filter.filterBy === 'lessThan') {
              isValid =
                parseFloat(device?.payload?.d[key!]) < parseFloat(value);
            }
          }
        } else if (device?.parameters) {
          if (device?.parameters[key] === undefined) {
            isValid = false;
          } else {
            if (filter.filterBy === 'equalsTo') {
              isValid =
                parseFloat(device.parameters[key].value) === parseFloat(value);
            } else if (filter.filterBy === 'greaterThan') {
              isValid =
                parseFloat(device.parameters[key].value) > parseFloat(value);
            } else if (filter.filterBy === 'lessThan') {
              isValid =
                parseFloat(device.parameters[key].value) < parseFloat(value);
            }
          }
        }
      } else if (device[key] === undefined || !device[key].includes(value)) {
        isValid = false;
      }

      if (!isValid) {
        break;
      }
    }

    return isValid;
  }

  public static complainFilter(
    complain: any,
    query: any,
    selectedFilters: Array<FilterValue>
  ): boolean {
    let isValid: boolean = true;

    for (let key of Object.keys(query)) {
      const filter = selectedFilters.find((sf) => sf.key === key);
      const value = query[key][0];
      if (key == 'Status') {
        key = 'status';
      }
      if (complain[key] === undefined || complain[key] != value) {
        isValid = false;
      }
      if (!isValid) {
        break;
      }
    }
    return isValid;
  }

  public static getBatteryIcon(battery: string): string {
    const batteryPercentage: number = +battery;
    let batteryIcon: string = 'battery_full';

    if (batteryPercentage === 0) {
      batteryIcon = 'battery_0_bar';
    } else if (batteryPercentage > 0 && batteryPercentage <= 16) {
      batteryIcon = 'battery_1_bar';
    } else if (batteryPercentage > 16 && batteryPercentage <= 32) {
      batteryIcon = 'battery_2_bar';
    } else if (batteryPercentage > 32 && batteryPercentage <= 48) {
      batteryIcon = 'battery_3_bar';
    } else if (batteryPercentage > 48 && batteryPercentage <= 64) {
      batteryIcon = 'battery_4_bar';
    } else if (batteryPercentage > 64 && batteryPercentage <= 80) {
      batteryIcon = 'battery_5_bar';
    } else if (batteryPercentage > 80 && batteryPercentage <= 99) {
      batteryIcon = 'battery_6_bar';
    }

    return batteryIcon;
  }

  public static setParameterSequence(fields: Array<DeviceField>) {
    let lastParameters = [
      'bs',
      'hum',
      'temp',
      'leq',
      'lmin',
      'lmax',
      'lat',
      'lon',
      'ws',
      'wd',
    ];
    let firsthalfParameters: Array<DeviceField> = [];
    let secondhalfParameters: Array<DeviceField> = [];

    fields.forEach((element) => {
      if (lastParameters.indexOf(element.fkey) > -1) {
        secondhalfParameters.push(element);
      } else {
        if (element.fkey === 'aqi') {
          firsthalfParameters.unshift(element);
        } else if (element.fkey === 'aqiMessage') {
          // Ensure aqi is already inserted before aqiMessage
          if (firsthalfParameters.some((el) => el.fkey === 'aqi')) {
            firsthalfParameters.splice(1, 0, element); // Insert aqiMessage at index 1
          }
        } else {
          firsthalfParameters.push(element);
        }
      }
    });

    fields = firsthalfParameters.concat(secondhalfParameters);
    return fields;
  }

  public static deleteKeysFromObject(keys: Array<string>, object: any) {
    const updatedObj = { ...object };
    keys.forEach((key: string) => {
      if (updatedObj.hasOwnProperty(key)) {
        delete updatedObj[key];
      }
    });
    return updatedObj;
  }

  public static getDeviceFields(
    data: DeviceDetails[],
    fields: DeviceField[],
    limits: FieldLimit[],
    onlyFieldsWithLimits: boolean = false
    // getLabelWithUnitForAQIParam: boolean = false
  ) {
    let limitsObj = Object.fromEntries([
      ...limits.map((limit) => [limit.fkey, limit]),
    ]);
    let deviceFields: any[] = [];
    data.forEach((device) => {
      deviceFields.push(...Object.keys(device?.payload?.d));
    });

    let deviceFieldsArray = [...new Set(deviceFields)];
    deviceFields = [];
    for (let df of deviceFieldsArray) {
      let field: DeviceField = fields.find((f) => f?.fkey == df)!;
      if (
        field &&
        (!onlyFieldsWithLimits || limitsObj?.[field.fkey]?.range?.length)
      ) {
        deviceFields.push(field!);
      }
    }

    let finalFields = CommonUtil.setParameterSequence(deviceFields);
    //updating the label of parameters like p1aqi, p2aqi => PM2.5 (AQI)
    // if (getLabelWithUnitForAQIParam) {
    //   let df = JSON.parse(
    //     JSON.stringify(CommonUtil.setParameterSequence(deviceFields))
    //   );
    //   df.forEach((d: DeviceField) => {
    //     if (d.fkey.endsWith('aqi') && d.fkey.replace('aqi', '') !== '') {
    //       d.label = d.label + ' (AQI)';
    //     }
    //   });
    //   finalFields = JSON.parse(JSON.stringify(df));
    // }
    return finalFields;
  }

  public static getDefaultTimezone = (): string => {
    let timezoneName = 'Asia/Kolkata';
    try {
      timezoneName = moment.tz.guess(true);
    } catch (err) {
      console.info('Error:', err);
    }
    return timezoneName;
  };

  public static modifyLteTimestamp = (
    startTimestamp: number,
    endTimestamp: number,
    avg: number
  ): number => {
    if (avg) {
      return endTimestamp - ((endTimestamp - startTimestamp) % avg);
    } else {
      return endTimestamp;
    }
  };

  //this function should not be accessed directly. use the pass through function from CommonService
  public static getAverageHours = ({
    includeRaw = true,
    includeMovingAvg = true,
    valueInSeconds = false,
  }: {
    includeRaw?: boolean;
    includeMovingAvg?: boolean;
    valueInSeconds?: boolean;
  } = {}): AverageHour[] => {
    let avgHours = JSON.parse(JSON.stringify(AppConstants.AVERAGE_HOURS));
    if (!includeRaw) {
      avgHours = avgHours.filter((avgHour: AverageHour) => avgHour.value !== 0);
    }
    if (!includeMovingAvg) {
      avgHours = avgHours.filter((avgHour: AverageHour) => !avgHour.isMoving);
    }
    if (valueInSeconds) {
      avgHours = avgHours.map((avgHour: AverageHour) => ({
        ...avgHour,
        value: avgHour.value * 3600,
      }));
    }
    console.log('avgHours', avgHours);

    return avgHours;
  };

  public static getModuleIdByUrl(currentUrl: string): Record<string, any> {
    if (currentUrl.includes('api')) {
      return { moduleId: 1011, moduleName: 'Integration' };
    } else if (currentUrl.includes('projects')) {
      return { moduleId: 1012, moduleName: 'Projects' };
    } else if (currentUrl.includes('automation')) {
      return { moduleId: 1014, moduleName: 'Automation' };
    } else if (currentUrl.includes('complain')) {
      return { moduleId: 2015, moduleName: 'Complain' };
    } else if (currentUrl.includes('alerts')) {
      return { moduleId: 1007, moduleName: 'Alerts' };
    } else if (currentUrl.includes('analytics')) {
      return { moduleId: 1005, moduleName: 'Analytics' };
    } else if (currentUrl.includes('reports')) {
      return { moduleId: 1004, moduleName: 'Reports' };
    } else if (currentUrl.includes('cluster')) {
      return { moduleId: 1009, moduleName: 'Cluster' };
    } else if (currentUrl.includes('display')) {
      return { moduleId: 1006, moduleName: 'Display' };
    } else if (currentUrl.includes('overview')) {
      return { moduleId: 1001, moduleName: 'Overview' };
    } else if (currentUrl.includes('dashboard')) {
      return { moduleId: 1002, moduleName: 'Dashboard' };
    } else if (currentUrl.includes('user')) {
      return { moduleId: 1003, moduleName: 'User' };
    } else if (currentUrl.includes('devices')) {
      return { moduleId: 1008, moduleName: 'Devices' };
    } else if (currentUrl.includes('data-flagging')) {
      return { moduleId: 1020, moduleName: 'Data Flagging' };
    } else if (currentUrl.includes('vibration')) {
      return { moduleId: 1021, moduleName: 'Vibration' };
    } else if (currentUrl.includes('configuration')) {
      return { moduleId: 1017, moduleName: 'Configuration' };
    } else if (currentUrl.includes('heatmap')) {
      return { moduleId: 1010, moduleName: 'Heatmap' };
    }
    return { moduleId: 0, moduleName: 'Error' };
  }

  public static isModuleValid(
    currentUrl: string,
    currentTime: number,
    modulesExpiry: any,
    modulesWithUser: any,
    role: number
  ) {
    let moduleDetails = CommonUtil.getModuleIdByUrl(currentUrl);
    let moduleId = moduleDetails.moduleId;

    let defaultModules = AppConstants.DEFAULT_MODULES_FOR_TERMINAL;

    //if default modules than return true
    if (defaultModules.includes(moduleDetails.moduleId)) {
      return true;
    }

    //if role is 1 and user has projects module access than no need to check expiry
    if (role == 1 && moduleId == '1012' && modulesWithUser.includes(moduleId)) {
      return true;
    }

    //check if current expiry is lower than module expiry
    let isTimeValid = currentTime < modulesExpiry?.[moduleId];

    if (modulesWithUser.includes(moduleId) && isTimeValid) {
      return true;
    } else {
      return false;
    }
  }

  public static getCommonDeviceParameters(devices: DeviceDetails[]) {
    return CommonUtil.findCommomKeys(
      devices.map((device: DeviceDetails) => Object.keys(device.payload.d))
    );
  }

  public static getCommonDeviceParametersKeysForHeatmap(
    devices: DeviceDetails[]
  ) {
    //this will filters out keys that appear in fewer than two devices.
    const allKeys = devices.map((device: DeviceDetails) =>
      Object.keys(device.payload.d)
    );
    const keyCount = new Map<string, number>();
    let temp: any = [];
    allKeys.forEach((keys) => {
      keys.forEach((key) => {
        temp.push(key);
        keyCount.set(key, (keyCount.get(key) || 0) + 1);
      });
    });
    const commonKeys = Array.from(keyCount.entries())
      .filter(([key, count]) => count >= 2)
      .map(([key]) => key);
    return commonKeys;
  }

  //this is used to find common keys of device when user selects device comparison
  public static findCommomKeys(arrays: string[][]): string[] {
    if (arrays.length === 0) {
      return [];
    }

    const result = arrays.reduce<string[]>((accumulator, currentArray) => {
      return accumulator.filter((value) => currentArray.includes(value));
    }, arrays[0]);

    return [...new Set(result)]; // Remove duplicates
  }

  public static sortModules(modules: ModuleCheckbox[], defaultModules: any[]) {
    return modules.sort((a, b) => {
      const indexA = defaultModules.indexOf(a.moduleId);
      const indexB = defaultModules.indexOf(b.moduleId);

      // If both are priority modules, sort by their index
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // If only a is a priority module, it comes first
      if (indexA !== -1) return -1;
      // If only b is a priority module, it comes first
      if (indexB !== -1) return 1;

      // If neither are priority modules, maintain original order
      return 0;
    });
  }

  public static createGeoJSONPolygon(
    features: Array<{
      bounds: {
        lat: number;
        lng: number;
      }[];
      properties?: Record<string, any>;
    }>
  ) {
    const geoJSON = {
      type: 'FeatureCollection',
      features: features.map((feature) => {
        const bounds = [...feature.bounds];
        // Ensure the polygon is closed by repeating the first coordinate
        if (
          bounds[0].lat !== bounds[bounds.length - 1].lat ||
          bounds[0].lng !== bounds[bounds.length - 1].lng
        ) {
          bounds.push(bounds[0]);
        }

        // Format the bounds for GeoJSON
        const coordinates = bounds.map((coord) => [coord.lng, coord.lat]);

        return {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates],
          },
          properties: feature.properties || {},
        };
      }),
    };

    return geoJSON;
  }

  public static getBoundaryCoordinates(
    geojson: any
  ): { lat: number; lng: number }[] {
    let coordinates: { lat: number; lng: number }[] = [];

    if (geojson.type === 'FeatureCollection' && geojson.features.length > 0) {
      const feature = geojson.features[0];
      if (feature.geometry.type === 'Polygon') {
        coordinates = feature.geometry.coordinates
          .flat()
          .map(([lng, lat]: any) => ({ lat, lng }));
      }
    } else if (
      geojson.type === 'Feature' &&
      geojson.geometry.type === 'Polygon'
    ) {
      coordinates = geojson.geometry.coordinates
        .flat()
        .map(([lng, lat]: any) => ({ lat, lng }));
    }

    return coordinates;
  }

  public static isSinglePolygon(geojson: any): boolean {
    if (geojson.type === 'Feature') {
      return geojson.geometry.type === 'Polygon';
    }

    if (geojson.type === 'FeatureCollection') {
      return (
        geojson.features.length === 1 &&
        geojson.features[0].geometry.type === 'Polygon'
      );
    }

    return false;
  }
}
