import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { NbThemeService } from '@nebular/theme';
import { floor, isNumber, max } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { Device } from 'src/app/shared/models/device/device-info';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { MapChartsService } from 'src/app/shared/services/map-charts.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { MapChartUtils } from 'src/app/shared/utils/map-chart-utils';
import GMap = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import { HotspotsPayload } from 'src/app/shared/models/analytics/hotspots-payload';
import { sortList } from 'src/app/shared/utils/array-utils';
import { CommonMapService } from 'src/app/shared/services/common-map.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
interface AnalyticsPlayer {
  isPlaying: boolean;
  currentIndex: number;
  speed: number;
  animationFrameId?: number;
}

@Component({
  selector: 'app-map-chart',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
// implements OnInit, OnDestroy, OnChanges, AfterViewInit
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() dataForAnalytics: any;
  @Input() segmentationFactor: number = 24;
  @Input() analyticDetail: any;
  @Input() readonlyData: any;
  @Input() formData: any;
  @ViewChild(GoogleMap) public googleMap!: GoogleMap;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }
  private destroy$: Subject<void> = new Subject<void>();
  public currentTheme: string = '';
  constructor(
    public googleMapsService: GoogleMapsService,
    public customMomentService: CustomMomentService,
    private mapChartsService: MapChartsService,
    private commonService: CommonService,
    private commonMapService: CommonMapService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private deviceService: DeviceService,
    private themeService: NbThemeService,
    private cd: ChangeDetectorRef
  ) {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }
  private resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      this.width = entry.contentRect.width;
      this.height = entry.contentRect.height;
    }
  });
  private hotspotOverlayLayers:
    | google.maps.visualization.HeatmapLayer[]
    | null = null;
  private roseChartOverlayLayers: google.maps.OverlayView[] | null = null;
  private roseChartLegendOverlayLayer: google.maps.OverlayView | null = null;
  public currentDeviceTypeId!: number;
  private currentDeviceType: any;
  public deviceInfo!: Device.Info;
  private readonly BASE_FRAME_INTERVAL = 1000;
  private timestamps: number[] = [];
  private discardedParameters: string[] = ['t', 'wd', 'ws', 'bs', 'hum'];
  public selectedDeviceTypeFields: DeviceField[] = [];
  public selectedDeviceTypeLimits: FieldLimit[] = [];
  private dataForRoseChart: any;
  private segmentedDataForAnalytics: any = [];
  private allUnits = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER
  ).units;
  // public isDirectionFromRoseChart: boolean = false;
  public allUserDevices: DeviceDetails[] =
    this.deviceService.registeredDevices!;
  public deviceTypesOfUser = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER_DEVICE_TYPE
  );
  private googleMapSubject = new Subject<GoogleMap | undefined>();
  private dataForAnalyticsSubject = new BehaviorSubject<any>([]);
  private analyticDetailSubject = new BehaviorSubject<any>(null);
  private readOnlyDataSubject = new BehaviorSubject<any>(null);
  private formDataSubject = new BehaviorSubject<any>(null);
  public roseChartSizes: number[] = [200, 300, 400, 500];
  public selectedRoseChartSize: number | undefined = 300;
  public roseChartDirectionTypes: string[] = ['Direction From', 'Direction To'];
  public selectedRoseChartDirectionType: string | undefined = 'Direction From';
  public aqiIndexColor: any;
  public roseChartLegendData: any;
  public hotspotsLegendData: any;
  public hotspotsOpacities: number[] = [0.2, 0.4, 0.6, 0.8, 1];
  public selectedHotspotsOpacity: number = 1;
  public hotspotRadiuses: number[] = [20, 30, 40, 50, 60];
  public selectedHotspotRadius: number = 20;
  public get currentMapChartDataIndex(): number {
    return this.player.currentIndex;
  }
  public set currentMapChartDataIndex(value: number) {
    this.player.currentIndex = value;
  }
  private transparentColor: string = '#00ffff00';
  public allAqi: any;
  public allAqis: any;
  public currentAqiIndex: number = 0;
  private unitsExistForSelectedParameter: boolean = true;
  selectedDateRange: any;
  windData: any;
  chartLoading: number = 0;
  width: number;
  height!: number;
  selectedPolygonLayer: google.maps.Data | undefined;
  selectedTime: string = '';
  shouldShowSelectedTime: boolean = false;
  removeSelectedTime: boolean = false;
  hiddingSelectedTime: NodeJS.Timeout | null = null;
  removingSelectedTime: NodeJS.Timeout | null = null;
  selectedParameter: DeviceField | undefined;
  parameters: DeviceField[] | undefined = [];
  chartSizes: number[] = [100, 200, 300, 400, 500];
  selectedDevice?: DeviceDetails;
  selectedDevices?: DeviceDetails[];
  userTimeformat: number = 24;
  currentMapType: string = 'roadmap';
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;
  currentLatitude: number = 12.9716;
  currentLongitude: number = 77.5946;
  selectedIconSize: google.maps.Size | undefined;
  selectedAnchor: google.maps.Point | undefined;
  currentRoseChart: Highcharts.Chart | undefined;
  currentRoseCharts: Highcharts.Chart[] | any = [];
  subscriptions: Subscription[] = [];
  mapInitialized: boolean = false;
  fields: any = [];
  limits: any = [];
  deviceData: any;
  deviceTypeId: number = 1001;

  loading: number = 0;
  themeChange: number = 0;

  sliderConfig: any = {
    min: 0,
    max: 0,
    disabled: true,
  };
  selectedTimeStyles: any = {
    maxWidth: '70px',
    top: '100%',
    left: '0',
    zIndex: 9999999,
    fontSize: '12px',
    lineHeight: '1',
    borderColor: 'var(--border-basic-color-4) !important',
    boxShadow: 'var(--shadow)',
    backgroundColor: 'var(--background-basic-color-1)',
    transform: 'translate(-50%, -50%)',
  };

  ngOnInit(): void {
    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        switchMap(() => this.ensureGoogleMapLoaded()),
        tap(() => this.mapReady(this.googleMap?.googleMap as GMap)),
        switchMap(() =>
          this.googleMap!.zoomChanged ? of(this.googleMap!.zoomChanged) : EMPTY
        ),
        catchError((err: any) => {
          console.error('Error in loading Google Map:', err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.configureMapOptions();
      });
    let userInfo = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    this.userTimeformat = userInfo?.settings?.time_format ?? 24;
    this.allUnits = this.commonService.getAllUnits();
    this.allAqi = this.commonService.getAllAQI();
    this.allAqis = this.commonService.getAllAQIs();

    this.currentDeviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      this.commonService.getUserDeviceTypesWithVibration(),
      this.deviceService.registeredDevicesWithVibration!,
      this.deviceInfo?.deviceId!
    )!;
    this.currentLatitude = this.deviceInfo?.latitude;
    this.currentLongitude = this.deviceInfo?.longitude;

    this.mapChartsService.dateRangeUpdated$.subscribe((res) => {
      this.selectedDateRange = res;
    });

    combineLatest([
      this.googleMapSubject.asObservable(),
      this.dataForAnalyticsSubject.asObservable(),
      this.analyticDetailSubject.asObservable(),
      this.readOnlyDataSubject.asObservable(),
      this.formDataSubject.asObservable(),
    ])
      .subscribe(() => {
        this.allAqi = this.commonService.getAllAQI();
        this.allAqis = this.commonService.getAllAQIs();
        this.allUnits = this.commonService.getAllUnits();
        this.currentDeviceTypeId = this.getDeviceTypeIdByDeviceId(
          this.analyticDetail?.deviceId[0]
        );
        this.currentDeviceType = this.deviceTypesOfUser.find(
          (deviceType: any) =>
            deviceType.deviceTypeId === this.currentDeviceTypeId
        );
        this.setUpLimitConfig();
        this.aqiIndexColor = DeviceUtil.aqiColorArray(
          this.deviceTypesOfUser,
          this.allAqi,
          this.allAqis,
          undefined,
          this.currentDeviceTypeId
        );
        if (this.analyticDetail?.analyticType === 102) {
          this.prepareDataSegments();
          // this.triggerRoseChartPipeline();
          this.triggerRoseChartPipelineV2();
        }
        if (this.analyticDetail?.analyticType === 103) {
          this.triggerHotspotsPipeline();
        }
      });
  }

  ngAfterViewInit(): void {
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName == 'material-dark') {
          this.currentTheme = themeName;
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          this.currentTheme = themeName;
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };
        if (this.selectedDevice) {
          setTimeout(() => {
            this.focusAllSelectedDevices();
          });
        }
        // else {
        //   setTimeout(() => {
        //     this.fitBounds(this.googleMap.googleMap!);
        //   });
        // }
      });
  }
  ensureGoogleMapLoaded(): any {
    return new Observable((observer: any) => {
      const interval = setInterval(() => {
        if (this.googleMap) {
          clearInterval(interval);
          observer.next(this.googleMap);
          observer.complete();
        }
      }, 100);
    });
  }

  ngOnDestroy(): void {
    this.googleMapSubject.complete();
    this.dataForAnalyticsSubject.complete();
    this.resizeObserver.disconnect();
  }

  get isPlaying(): boolean {
    return this.player.isPlaying;
  }

  public closeChartsView() {
    this.deviceData = undefined;
  }
  public onMapOptionsUpdated(options: MapOptions) {
    this.options = { ...options };
  }
  private configureMapOptions() {
    //hiding labels
    this.commonMapService.updateMapOption(
      'showLabels',
      this.options,
      this.googleMap
    );
  }
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    // minorText: 'Your device may be offline',
  };

  private player: AnalyticsPlayer = {
    isPlaying: true,
    currentIndex: 0,
    speed: 1,
  };
  private setUpSegmentationFactor() {
    const segmentationFactor = this.analyticDetail?.average?.value / 3600;
    this.segmentationFactor = segmentationFactor;
  }
  private setUpLimitConfig() {
    const units = this.allUnits;
    this.fields = this.deviceService.fetchFields(
      this.currentDeviceTypeId,
      units,
      false
    );
    this.limits = this.deviceService.fetchLimits(
      units[this.currentDeviceTypeId]
    );
  }

  public selectParameter(param: DeviceField): void {
    this.selectedParameter = param;
    if (this.isPlaying) {
      this.stopAnimation();
    }
    if (
      !!this.allUnits?.[this.currentDeviceTypeId] &&
      !this.allUnits[this.currentDeviceTypeId][this.selectedParameter?.fkey]
        ?.limit
    ) {
      this.notificationService.showNotification(
        'No limit found ,Please add limits for the chosen device type and parameter from User module',
        'error',
        'bottom',
        'right',
        'error',
        5000
      );
      this.unitsExistForSelectedParameter = false;
    }
    else {
      this.unitsExistForSelectedParameter = true;
    }

    if (this.analyticDetail?.analyticType === 102) {
      this.updateRosechartV2();
    } else if (this.analyticDetail?.analyticType === 103) {
      this.updateHotspots();
    }
    this.player.currentIndex = 0;
  }
  public selectRoseChartSize(size: number): void {
    this.selectedRoseChartSize = size;
    this.updateRosechartV2();
  }
  public selectRoseChartDirectionType(directionType: string): void {
    this.selectedRoseChartDirectionType = directionType;
    this.updateRosechartV2();
  }

  public selectHotspotsOpacity(opacity: number): void {
    this.selectedHotspotsOpacity = opacity;
    this.updateHotspots();
  }
  public selectHotspotRadius(radius: number): void {
    this.selectedHotspotRadius = radius;
    this.updateHotspots();
  }

  private getDeviceParameters(): DeviceField[] | any {
    let params: string[] = (
      Array.from(
        [this.selectedDevice!].reduce((prev: Set<string>, curr: any) => {
          for (let key of Object.keys(curr?.payload.d)) {
            prev.add(key);
          }
          return prev;
        }, new Set<string>()) ?? []
      ) as string[]
    ).filter((p: string) => !this.discardedParameters.includes(p));

    return this.fields.filter((field: any) => params.includes(field.fkey));
  }
  private getSelectedParameters(): DeviceField[] | any {
    const params = this.analyticDetail?.parameter.filter(
      (p: string) => !this.discardedParameters.includes(p)
    );
    return this.fields.filter((field: any) => params.includes(field.fkey));
  }
  public playPauseRoseMap(): void {
    this.player.isPlaying = !this.player.isPlaying;

    if (this.player.isPlaying) {
      this.startAnimation();
    } else {
      this.stopAnimation();
    }
  }
  private updateSliderConfig() {
    this.sliderConfig.min = 0;
    if (
      this.analyticDetail?.analyticType === 102 ||
      this.analyticDetail?.analyticType === 103
    ) {
      this.sliderConfig.max = floor(
        // (this.dataForAnalytics[0].payload?.length ?? 1) /
        //   this.segmentationFactor -
        //   1
        // (this.dataForAnalytics[0].payload?.length ?? 1) /
        //   this.segmentationFactor -
        //   1
        this.timestamps.length - 1
      );
    }
    // else if (this.analyticDetail?.analyticType === 103) {
    //   this.sliderConfig.max = floor(
    //     this.dataForAnalytics[0].payload?.length - 1
    //   );
    // }
    // this.sliderConfig.disabled = !this.dataForAnalytics[0].payload?.length;
    this.sliderConfig.disabled = !this.timestamps.length;
    this.sliderConfig = { ...this.sliderConfig };
  }
  private updateSliderConfigV2() {
    this.sliderConfig.min = 0;
    if (
      this.analyticDetail?.analyticType === 102 ||
      this.analyticDetail?.analyticType === 103
    ) {
      this.sliderConfig.max = floor(this.timestamps?.length);
    }
    this.sliderConfig.disabled = !this.segmentedDataForAnalytics?.length;
    this.sliderConfig = { ...this.sliderConfig };
  }
  private setSelectedDevice(deviceId: string): void {
    this.selectedDevice = this.selectedDevices?.find(
      (d) => d.deviceId === deviceId
    );
  }
  private setSelectedDevices(deviceIds: string[]): void {
    this.selectedDevices = this.deviceService.registeredDevices?.filter((d) =>
      deviceIds.includes(d.deviceId)
    );
    this.focusAllSelectedDevices();
  }
  private setSelectedTime(epoch: number) {
    let format = 'DD/MM/YY<br/>HH:mm';
    if (
      (this.analyticDetail.analyticType !== 102 &&
        this.analyticDetail?.average?.value < 86400) ||
      this.analyticDetail?.isMovingAvg ||
      this.analyticDetail?.dataDistributionType === 0
    ) {
      format =
        this.userTimeformat === 12
          ? 'DD/MM/YY<br/>hh:mm A'
          : 'DD/MM/YY<br/>HH:mm';
    } else {
      format = 'DD/MM/YY';
    }
    this.selectedTime = this.customMomentService.formatDatetime({
      epoch,
      format: format,
    });

    this.selectedTimeStyles.left = `${
      (this.currentMapChartDataIndex / this.sliderConfig.max) * 100
    }%`;
  }
  private startAnimation(): void {
    this.player.isPlaying = true;
    let lastFrameTime = performance.now();

    const animate = (currentTime: number) => {
      if (!this.player.isPlaying) return;

      const elapsed = currentTime - lastFrameTime;
      const frameInterval = this.BASE_FRAME_INTERVAL / this.player.speed;

      if (elapsed >= frameInterval) {
        lastFrameTime = currentTime;
        this.player.currentIndex =
          (this.player.currentIndex + 1) % (this.sliderConfig.max + 1);
        if (this.analyticDetail?.analyticType === 102) {
          // this.updateRosechart();
          this.updateRosechartV2();
        } else if (this.analyticDetail?.analyticType === 103) {
          this.updateHotspots();
        }
      }
    };
    const animationInstance = setInterval(() => {
      if (!this.player.isPlaying) {
        clearInterval(animationInstance);
      }
      animate(performance.now());
    }, this.BASE_FRAME_INTERVAL);
  }

  private stopAnimation(): void {
    this.player.isPlaying = false;
  }
  mapReady(map: GMap): void {
    this.mapInitialized = true;
    // if (this.currentLatitude && this.currentLatitude) {
    // this.fitBounds(map);
    map.setZoom(10);
    // }
    this.googleMap?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('zoom-control')!
    );

    this.selectedIconSize = new google.maps.Size(
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.width,
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.height
    );

    this.selectedAnchor = new google.maps.Point(
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.x,
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.y
    );
    this.googleMapSubject.next(this.googleMap);
  }
  onMapClick(latLng: any): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        if (results?.[0]) {
          this.currentLatitude = latLng.lat();
          this.currentLongitude = latLng.lng();
          this.cd.detectChanges();
        } else {
          console.info('No results found');
        }
      } else {
        console.info('Geocoder failed due to: ' + status);
      }
    });
  }

  fitBounds(map: GMap): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers = {
      lat: this.currentLatitude,
      lng: this.currentLongitude,
    };
    latLngBounds.extend(markers);
    map.fitBounds(latLngBounds);
  }
  getDeviceTypeIdByDeviceId(deviceId: string | string[]) {
    if (typeof deviceId === 'string') {
      let selectedDeviceData = this.allUserDevices.find(
        (allUserDevice) => allUserDevice.deviceId === deviceId
      );
      let deviceType = this.deviceTypesOfUser.find(
        (deviceTypeOfUser: any) =>
          deviceTypeOfUser.key === selectedDeviceData?.deviceType
      );
      return deviceType.deviceTypeId;
    } else if (typeof deviceId === 'object') {
      deviceId = deviceId[0];
      let selectedDeviceData = this.allUserDevices.find(
        (allUserDevice) => allUserDevice.deviceId === deviceId
      );
      let deviceType = this.deviceTypesOfUser.find(
        (deviceTypeOfUser: any) =>
          deviceTypeOfUser.key === selectedDeviceData?.deviceType
      );
      return deviceType.deviceTypeId;
    }
  }
  focusSelectedDevice() {
    let map: GMap = this.googleMap?.googleMap!;
    if (map) {
      const latLngBounds = new google.maps.LatLngBounds();
      const markers = [this.selectedDevice!].map((device) => {
        if (
          device.payload.d.lat &&
          device.payload.d.lon &&
          device.payload.d.lat !== 0 &&
          device.payload.d.lon !== 0
        ) {
          return {
            lat: device.payload.d.lat,
            lng: device.payload.d.lon,
          };
        } else {
          return {
            lat: device.latitude,
            lng: device.longitude,
          };
        }
      });

      markers.forEach((marker) => {
        latLngBounds.extend(marker);
      });

      map.fitBounds(latLngBounds);
    }
  }

  focusAllSelectedDevices() {
    let map: GMap = this.googleMap?.googleMap!;
    if (map && this.selectedDevices && this.selectedDevices.length > 0) {
      const latLngBounds = new google.maps.LatLngBounds();
      this.selectedDevices?.forEach((device) => {
        const lat = device.payload.d.lat || device.latitude;
        const lon = device.payload.d.lon || device.longitude;

        if (lat && lon && lat !== 0 && lon !== 0) {
          latLngBounds.extend(new google.maps.LatLng(lat, lon));
        }
      });

      latLngBounds.extend(
        new google.maps.LatLng(
          latLngBounds.getNorthEast().lat() + 0.01,
          latLngBounds.getNorthEast().lng() + 0.01
        )
      );

      map.fitBounds(latLngBounds);
    }
  }

  public showSelectedTime() {
    if (this.hiddingSelectedTime) {
      clearTimeout(this.hiddingSelectedTime);
      if (this.removingSelectedTime) {
        clearTimeout(this.removingSelectedTime);
        this.removingSelectedTime = null;
      }
      this.hiddingSelectedTime = null;
    }
    this.shouldShowSelectedTime = true;
    this.removeSelectedTime = false;
  }

  public hideSelectedTime() {
    if (this.hiddingSelectedTime) {
      clearTimeout(this.hiddingSelectedTime);
      this.hiddingSelectedTime = null;
    }
    this.shouldShowSelectedTime = true;
    this.hiddingSelectedTime = setTimeout(() => {
      this.shouldShowSelectedTime = false;

      this.removeSelectedTime = false;
      if (this.removingSelectedTime) {
        clearTimeout(this.removingSelectedTime);
        this.removingSelectedTime = null;
      }
      this.removingSelectedTime = setTimeout(() => {
        this.removeSelectedTime = true;

        this.removingSelectedTime = null;
      }, 1000);

      this.hiddingSelectedTime = null;
    }, 500);
  }
  private prepareDataSegments(): any {
    const dataDistributionType = this.analyticDetail?.dataDistributionType;
    this.segmentedDataForAnalytics = {};
    this.dataForAnalytics.map((deviceData: any) => {
      let groupedData: any;
      if (dataDistributionType === 0) {
        groupedData = this.commonService.groupDataByHour(deviceData.payload);
      } else if (dataDistributionType === 3600) {
        groupedData = this.commonService.groupDataByDate(deviceData.payload);
      } else {
        groupedData = this.commonService.groupDataByDate(deviceData.payload);
      }
      this.segmentedDataForAnalytics[deviceData.deviceId] = groupedData;
      this.segmentedDataForAnalytics.length = groupedData.length;
    });
  }
  private triggerRoseChartPipeline(): void {
    this.updateSliderConfig();
    const deviceIds = this.dataForAnalytics?.map((data: any) => data.deviceId);
    this.setSelectedDevices(deviceIds);
    this.setSelectedDevice(deviceIds[0]);
    this.parameters = this.getSelectedParameters();
    this.parameters && this.selectParameter(this.parameters[0]);
  }
  private triggerRoseChartPipelineV2(): void {
    this.prepareTimeStamps();
    // this.updateSliderConfigV2();
    this.updateSliderConfig();
    const deviceIds = this.dataForAnalytics?.map((data: any) => data.deviceId);
    this.setSelectedDevices(deviceIds);
    this.setSelectedDevice(deviceIds[0]);
    this.parameters = this.getSelectedParameters();
    this.parameters && this.selectParameter(this.parameters[0]);
  }

  private triggerHotspotsPipeline(): void {
    const deviceIds = this.dataForAnalytics?.map((data: any) => data.deviceId);
    this.prepareTimeStamps();
    this.updateSliderConfig();
    this.setSelectedDevices(deviceIds);
    this.setSelectedDevice(deviceIds[0]);
    this.parameters = this.getSelectedParameters();
    this.parameters?.length && this.selectParameter(this.parameters[0]);
    this.focusAllSelectedDevices();
  }
  private generatePayloadForWindRoseChart(
    device: any,
    dataForRoseChart: any,
    avgParamValue: number
  ): any {
    const directions: string[] = [];
    const paramKey = this.selectedParameter?.fkey;
    const deviceLabel = device?.label;
    const data =
      paramKey &&
      (paramKey?.endsWith('aqi')
        ? dataForRoseChart.aqi
        : dataForRoseChart[paramKey]);
    const lat = device.latitude;
    const lon = device.longitude;

    if (dataForRoseChart?.ws?.[0]?.data) {
      dataForRoseChart.ws[0].data.forEach((element: any) => {
        directions.push(element[0]);
      });
    } else {
      console.warn(`No wind speed data found for device: ${device.deviceId}`);
    }

    return { lat, lon, directions, data, avgParamValue, deviceLabel };
  }

  // not using following function since hotspots are now using google maps heatmap layer
  private generatePayloadForHotspots(
    device: any,
    dataForHeatmapChart: any
  ): any {
    const paramKey = this.selectedParameter?.fkey;
    const value = dataForHeatmapChart.payload.d[paramKey!];
    const lat = device.latitude;
    const lon = device.longitude;
    const deviceUnits =
      this.allUnits[this.getDeviceTypeIdByDeviceId(device.deviceId)];
    const limits = this.deviceService.fetchLimits(deviceUnits);
    const maxColorIndex = this.deviceService.provideLimitCss(
      paramKey!,
      value,
      limits
    );
    // const minColorIndex = this.deviceService.provideLimitCss(paramKey, limits[0].lowestLimit, limits);
    // if (!isNumber(minColorIndex) || !isNumber(maxColorIndex)) return;
    // const maxColor = this.aqiIndexColor?.color[maxColorIndex];
    // const minColor = this.aqiIndexColor?.color[minColorIndex];
    const colors = this.aqiIndexColor?.color.slice(0, maxColorIndex);
    return { lat, lon, value, colors };
  }
  private getAdjustedColorsForHotspotsHeatmap(
    colors: string[],
    paramLimits: number[],
    maxWeight: number
  ): string[] {
    const maxWeightIndex =
      maxWeight > paramLimits[paramLimits?.length - 1]
        ? paramLimits?.length - 1
        : paramLimits?.findIndex((l: number) => l >= maxWeight);
    const slicedParamLimits = paramLimits.slice(0, maxWeightIndex + 1);
    const minLimitWindow = slicedParamLimits.reduce(
      (minDiff: number, current: number, index: number, array: number[]) => {
        if (index === 0) return minDiff;
        const difference = current - array[index - 1];
        return Math.min(minDiff, difference);
      },
      Infinity
    );
    const minLimit = slicedParamLimits[0];
    const maxLimit = slicedParamLimits[slicedParamLimits.length - 1];
    const newLimits = [minLimit];
    let currentLimit = minLimit;
    while (currentLimit < maxLimit) {
      currentLimit = Math.min(currentLimit + minLimitWindow, maxLimit);
      newLimits.push(currentLimit);
    }
    const newColors = newLimits.map((limit: number) => {
      const index = paramLimits.findIndex((l: number) => l >= limit);
      return colors[index];
    });
    this.updateHotspotsLegend(colors, paramLimits);
    return newColors;
  }
  private updateHotspotsLegend(colors: string[], limits: number[]) {
    const legendData = [];
    for (let i = 1; i < limits.length; i++) {
      legendData.push({
        color: colors[i - 1],
        label: `${limits[i - 1]} - ${limits[i]}`,
        // label: `${limits[i - 1]} - ${limits[i]} ${
        //   this.selectedParameter?.unit
        // }`,
      });
    }
    this.hotspotsLegendData = legendData;
  }
  private prepareTimeStamps() {
    const timestamps: number[] = [];
    let currentDate =
      this.dataForAnalytics[0]?.payload[0]?.payload?.d?.t ??
      Number.POSITIVE_INFINITY;
    let endDate =
      this.dataForAnalytics[0]?.payload[
        this.dataForAnalytics[0]?.payload?.length - 1
      ]?.payload?.d?.t ?? 0;
    this.dataForAnalytics.forEach((deviceData: any) => {
      const firstTimestamp = deviceData.payload[0]?.payload?.d?.t;
      const lastTimestamp =
        deviceData.payload[deviceData.payload.length - 1]?.payload?.d?.t;
      if (firstTimestamp < currentDate) currentDate = firstTimestamp;
      if (lastTimestamp > endDate) endDate = lastTimestamp;
    });
    if (this.analyticDetail.analyticType === 102) {
      if (this.analyticDetail?.dataDistributionType === 0) {
        while (currentDate <= endDate) {
          timestamps.push(currentDate);
          currentDate += 3600;
        }
      } else if (this.analyticDetail?.dataDistributionType === 3600) {
        while (currentDate <= endDate) {
          timestamps.push(currentDate);
          currentDate += 86400;
        }
      } else {
        while (currentDate <= endDate) {
          timestamps.push(currentDate);
          currentDate += 86400;
        }
      }
    } else if (this.analyticDetail.analyticType === 103) {
      while (currentDate <= endDate) {
        timestamps.push(currentDate);
        // if (this.analyticDetail?.isMovingAvg) currentDate += 3600;
        // else currentDate += this.analyticDetail?.average?.value;
        currentDate += this.analyticDetail?.average?.value;
      }
    }
    // this.timestamps = timestamps.slice(1, timestamps.length - 1);
    this.timestamps = timestamps;
  }
  private generatePayloadForHotspotsHeatmap(): HotspotsPayload | undefined {
    const paramLimits = this.limits.find(
      (l: any) => l.fkey === this.selectedParameter?.fkey
    );
    // this.prepareTimeStamps();
    const data = this.dataForAnalytics.map((deviceData: any, index: number) => {
      const device = this.selectedDevices?.find(
        (d: any) => d.deviceId === deviceData.deviceId
      );
      return {
        location: new google.maps.LatLng(device?.latitude!, device?.longitude!),
        weight:
          this.selectedParameter &&
          deviceData.payload[this.player.currentIndex]?.payload?.d[
            this.selectedParameter?.fkey
          ],
        name: this.readonlyData?.deviceId[index],
      };
    });
    const colors = this.aqiIndexColor?.color;
    const maxWeight = max(data.map((d: any) => d.weight));
    if (!isNumber(maxWeight)) return;

    const newcolors = this.getAdjustedColorsForHotspotsHeatmap(
      colors,
      paramLimits.range,
      maxWeight
    );
    newcolors[0] !== this.transparentColor &&
      newcolors.unshift(this.transparentColor);
    const payload = {
      data: data,
      maxLimit: maxWeight,
      minLimit: paramLimits?.lowestLimit,
      colors: newcolors,
      timestamps: this.timestamps,
    };
    return payload;
  }

  public updateRosechart(): void {
    if (
      !this.dataForAnalytics.length ||
      !this.selectedDevices?.length ||
      !this.selectedParameter ||
      !this.allUnits ||
      !this.aqiIndexColor?.color
    ) {
      setTimeout(() => {
        this.triggerRoseChartPipeline();
      }, 1000);
      return;
    }
    let timestamps: number[] = [];
    let endDate = this.analyticDetail?.endDate;
    let currentDate = this.analyticDetail?.startDate;
    while (currentDate <= endDate) {
      timestamps.push(currentDate);
      if (this.analyticDetail?.isMovingAvg) currentDate += 3600;
      else currentDate += this.analyticDetail?.average?.value;
    }
    this.timestamps = timestamps;
    this.clearExistingRoseCharts();
    this.selectedDevices?.forEach((device) => {
      const startIndex = this.player.currentIndex * this.segmentationFactor;
      const endIndex = (this.player.currentIndex + 1) * this.segmentationFactor;

      const groupedDataForRoseConversion = this.dataForAnalytics
        .find((d: DeviceDetails) => d.deviceId === device.deviceId)
        ?.payload.slice(startIndex, endIndex);

      if (!groupedDataForRoseConversion) {
        console.warn(`No data found for device: ${device.deviceId}`);
      }

      const currentDataFrameForRoseChart: any = DeviceUtil.roseConverter(
        groupedDataForRoseConversion,
        this.allUnits &&
          this.allUnits[this.getDeviceTypeIdByDeviceId(device?.deviceId)],
        this.selectedParameter?.fkey,
        this.selectedRoseChartDirectionType?.toLowerCase() ===
          this.roseChartDirectionTypes[0]?.toLowerCase()
      );
      this.setSelectedTime(this.timestamps[this.player?.currentIndex]!);

      let avgParamValue = groupedDataForRoseConversion?.reduce(
        (acc: number, curr: any) => {
          if (!this.selectedParameter?.fkey) return acc;
          if (!curr.payload.d[this.selectedParameter?.fkey]) return acc;
          return acc + curr.payload.d[this.selectedParameter?.fkey];
        },
        0
      );
      avgParamValue = (
        avgParamValue / groupedDataForRoseConversion?.length
      )?.toFixed(2);
      if(!isNumber(avgParamValue)) avgParamValue = 0;
      const payload = this.generatePayloadForWindRoseChart(
        device,
        currentDataFrameForRoseChart,
        avgParamValue ?? 0
      );
      // this.roseChartLegendData = {
      //   labels: payload.data.map((d: any) => d.name),
      //   colors: payload.data.map((d: any) => this.aqiIndexColor?.color[d?.colorIndex]),
      // }
      this.roseChartLegendData = [
        ...payload?.data?.map((d: any) => {
          return {
            name: d?.name,
            color: this.aqiIndexColor?.color[d?.colorIndex],
          };
        }),
      ];
      const response = this.mapChartsService.displayWindRoseChart(
        payload?.lat,
        payload?.lon,
        payload?.directions,
        payload?.data,
        payload?.avgParamValue,
        payload?.deviceLabel,
        this.selectedRoseChartSize || 300,
        this.analyticDetail?.analyticType,
        this.aqiIndexColor?.color,
        this.googleMap
      );
      const [roseChart, overlayLayer] = response;

      this.currentRoseCharts.push(roseChart);
      this.roseChartOverlayLayers?.push(overlayLayer);
    });
  }
  public updateRosechartV2(): void {
    if (
      !this.dataForAnalytics.length ||
      !this.selectedDevices?.length ||
      !this.selectedParameter ||
      !this.allUnits ||
      !this.aqiIndexColor?.color ||
      !this.selectedDevice ||
      !this.selectedParameter ||
      !this.selectedDevices
    ) {
      console.log('Something went wrong in updateRosechartV2: ');
      return;
    }
    // this.prepareTimeStamps();
    this.clearExistingRoseCharts();
    this.selectedDevices?.forEach((device) => {
      try {
        this.setSelectedTime(this.timestamps[this.player.currentIndex]!);
        const date = this.selectedTime.split('<br/>')[0].trim();
        let groupedDataForRoseConversion;

        if (this.analyticDetail?.dataDistributionType === 0) {
          const hour = this.selectedTime.split('<br/>')[1].split(':')[0].trim();
          groupedDataForRoseConversion =
            this.segmentedDataForAnalytics[device.deviceId].data[date][hour];
        } else if (this.analyticDetail?.dataDistributionType === 3600) {
          groupedDataForRoseConversion =
            this.segmentedDataForAnalytics[device.deviceId].data[date];
        } else {
          groupedDataForRoseConversion =
            this.segmentedDataForAnalytics[device.deviceId].data[date];
        }
        const currentDataFrameForRoseChart: any = DeviceUtil.roseConverter(
          groupedDataForRoseConversion,
          this.allUnits &&
            this.allUnits[this.getDeviceTypeIdByDeviceId(device.deviceId)],
          this.selectedParameter?.fkey,
          this.selectedRoseChartDirectionType?.toLowerCase() ===
            this.roseChartDirectionTypes[0].toLowerCase()
        );
        let avgParamValue = groupedDataForRoseConversion?.reduce(
          (acc: number, curr: any) => {
            if (!this.selectedParameter?.fkey) return acc;
            if (!curr?.payload?.d[this.selectedParameter?.fkey]) return acc;
            return acc + curr?.payload?.d[this.selectedParameter?.fkey];
          },
          0
        );
        avgParamValue = !this.unitsExistForSelectedParameter
          ? 0
          : (avgParamValue / groupedDataForRoseConversion?.length).toFixed(2);

        const payload = this.generatePayloadForWindRoseChart(
          device,
          currentDataFrameForRoseChart,
          avgParamValue
        );
        // this.roseChartLegendData = {
        //   labels: payload.data.map((d: any) => d.name),
        //   colors: payload.data.map((d: any) => this.aqiIndexColor?.color[d?.colorIndex]),
        // }
        this.roseChartLegendData = [
          ...payload.data.map((d: any) => {
            const name = d.name.split(this.selectedParameter?.unit)[0];
            return {
              name: name,
              color: this.aqiIndexColor?.color[d?.colorIndex],
            };
          }),
        ];
        const response = this.mapChartsService.displayWindRoseChart(
          payload.lat,
          payload.lon,
          payload.directions,
          payload.data,
          payload.avgParamValue,
          payload.deviceLabel,
          this.selectedRoseChartSize || 300,
          this.analyticDetail?.analyticType,
          this.aqiIndexColor?.color,
          this.googleMap
        );
        const [roseChart, overlayLayer] = response;
        
        this.currentRoseCharts.push(roseChart);
        this.roseChartOverlayLayers?.push(overlayLayer);
      } catch (error) {
        // console.error('error', error);
      }
    });
  }

  public updateHotspots() {
    if (
      !this.dataForAnalytics.length ||
      !this.selectedDevice ||
      !this.selectedParameter ||
      !this.allUnits
    ) {
      console.log('Something went wrong in updateHotspots: ');
      return;
    }
    this.clearExistingHotspots();
    // if(this.hotspotOverlayLayers?.length) {
    //   const payload = this.generatePayloadForHotspotsHeatmap();
    //   this.setSelectedTime(this.timestamps[this.player.currentIndex]!);
    //   this.hotspotOverlayLayers[0].setData(payload!.data);
    // }
    // else{
    //   const payload = this.generatePayloadForHotspotsHeatmap();
    //   this.setSelectedTime(this.timestamps[this.player.currentIndex]!);
    //   const overlayLayer = this.mapChartsService.displayHotspotsHeatmap(
    //     payload!,
    //     this.selectedHotspotsOpacity,
    //     this.selectedHotspotRadius,
    //     this.googleMap
    //   );
    //   overlayLayer && this.hotspotOverlayLayers?.push(overlayLayer);
    // }
      const payload = this.generatePayloadForHotspotsHeatmap();
      this.setSelectedTime(this.timestamps[this.player.currentIndex]!);
      const overlayLayer = this.mapChartsService.displayHotspotsHeatmap(
        payload!,
        this.selectedHotspotsOpacity,
        this.selectedHotspotRadius,
        this.googleMap
      );
      overlayLayer && this.hotspotOverlayLayers?.push(overlayLayer);
  }
  private clearExistingRoseCharts(): void {
    this.roseChartOverlayLayers?.forEach((overlay) => overlay.setMap(null));
    this.roseChartOverlayLayers = [];
    this.roseChartLegendOverlayLayer?.setMap(null);
    this.roseChartLegendOverlayLayer = null;
  }

  private clearExistingHotspots(): void {
    this.hotspotOverlayLayers?.forEach((overlay) => overlay.setMap(null));
    this.hotspotOverlayLayers = [];
  }

  // public updateRoseChartWithTransition(): any {
  //   if (!this.selectedParameter || !this.dataForAnalytics[0]?.length) return;

  //   const currentFrame = this.dataForAnalytics[0][this.player.currentIndex];
  //   if (!currentFrame) return;

  //   this.setSelectedTime(currentFrame.payload.d['t']);

  // }
}
