<div class="row mx-0 align-items-center">
  <div class="col" *ngIf="width > 991 || !configureButton">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
  <div class="col" *ngIf="width <= 991 && configureButton">
    <button
      class="w-100"
      mat-raised-button
      color="primary"
      [matMenuTriggerFor]="testmenu"
      matTooltip="Configure"
    >
      Configure
    </button>
    <mat-menu #testmenu="matMenu" [hasBackdrop]="false" class="form-menu">
      <div
        class="row mx-0"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <div class="row mx-0 px-3 mt-4 mb-2">
        <button
          class="col-12 w-100"
          mat-stroked-button
          color="primary"
          matTooltip="Close"
        >
          Close
        </button>
      </div>
    </mat-menu>
  </div>
  <div class="col-auto d-none d-lg-block" *ngIf="menu?.length">
    <mat-button-toggle-group
      class="terminal-sub-menu-toggle-group"
      (change)="onTabChange($event)"
    >
      <ng-container *ngFor="let page of menu">
        <mat-button-toggle
          [routerLink]="page?.url"
          [routerLinkActive]="'mat-button-toggle-checked'"
          [value]="page"
        >
          <span>{{ page?.value }}</span>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
