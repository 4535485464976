import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';

@Component({
  selector: 'app-interval-form',
  templateUrl: './interval-form.component.html',
  styleUrls: ['./interval-form.component.scss'],
})
export class IntervalFormComponent implements OnInit {
  public form!: FormGroup;
  public intervals: any = [
    { key: '120', lab: '2 Mins', value: '120000' },
    { key: '300', lab: '5 Mins', value: '300000' },
    { key: '600', lab: '10 Mins', value: '600000' },
    { key: '900', lab: '15 Mins', value: '900000' },
    { key: '1800', lab: '30 Mins', value: '1800000' },
    { key: '3600', lab: '60 Mins', value: '3600000' },
  ];
  public selectedInterval: string = '';

  constructor(
    public deviceService: DeviceService,
    private formsService: FormsService,
    public loadrService: LoadrService,
    @Inject(MTX_DRAWER_DATA) public data: DeviceDetails | undefined
  ) {}

  ngOnInit(): void {
    switch (this.data?.interval! / 60000) {
      case 2:
        this.selectedInterval = this.intervals[0].lab;
        break;
      case 5:
        this.selectedInterval = this.intervals[1].lab;
        break;
      case 10:
        this.selectedInterval = this.intervals[2].lab;
        break;
      case 15:
        this.selectedInterval = this.intervals[3].lab;
        break;
      case 30:
        this.selectedInterval = this.intervals[4].lab;
        break;
      case 60:
        this.selectedInterval = this.intervals[5].lab;
        break;
    }
    this.form = new FormGroup({
      interval: new FormControl(this.selectedInterval),
    });
  }

  closeForm(data: any = false) {
    this.formsService.closeForm(data);
  }

  // updates the time interval of device
  updateInterval() {
    const deviceId = this.data?.deviceId;
    const intervalValue =
      this.form.controls.interval.value.split(' ')[0] * 60000;
    this.loadrService.setLoading(true);
    this.loadrService.showLoader();
    this.deviceService.updateInterval(deviceId!, intervalValue).subscribe({
      next: (result: any) => {
        if (deviceId) {
          this.closeForm({ ...this.data, interval: intervalValue });
        } else {
          this.closeForm({ ...this.data });
          console.log('Device not found in the array');
        }
        this.loadrService.setLoading(false);
        this.loadrService.removeLoader();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
