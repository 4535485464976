import { Component, Inject, OnInit } from '@angular/core';
import { CustomMomentService } from '../../services/custom-moment.service';
import { map, Observable, startWith } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { RequestData } from '../../models/new-user-data';
import { Settings } from 'luxon';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppConstants } from '../../constants/app-constants';

@Component({
  selector: 'app-time-zone-input',
  templateUrl: './time-zone-input.component.html',
  styleUrls: ['./time-zone-input.component.scss'],
})
export class TimeZoneInputComponent implements OnInit {
  public timezonesWithOffsets!: {
    name: string;
    offSet: string;
    nameOffSet: string;
  }[];
  filteredOptions!: Observable<any>;
  public form!: FormGroup;

  constructor(
    private customMomentService: CustomMomentService,
    private fromBuilder: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      image: string;
      message: string;
      disableUpdateButton: boolean;
    }
  ) {
    this.data = {
      image: '',
      message: '',
      disableUpdateButton: false,
    };
  }

  ngOnInit(): void {
    this.getTimeZones();
    this.form = this.fromBuilder.group({
      timeZone: ['', Validators.required],
    });
    this.onChangeTimeZone();
  }

  onChangeTimeZone() {
    this.filteredOptions = this.form.get('timeZone')?.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    )!;
  }

  //it is used to filter out the timezones which matches with the user's search
  filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.timezonesWithOffsets.filter((option: any) =>
      option.nameOffSet.toLowerCase().includes(filterValue)
    );
  }

  getTimeZones() {
    const timezones = this.customMomentService.moment.tz.names();
    this.timezonesWithOffsets = timezones.map((zone) => {
      const offset = this.customMomentService.moment.tz(zone).format('Z');
      return {
        name: zone,
        offSet: offset,
        nameOffSet: '(UTC' + offset + ')' + ' ' + zone,
      };
    });
    this.timezonesWithOffsets.sort((a: any, b: any) => {
      return this.parseOffset(a.offSet) - this.parseOffset(b.offSet);
    });
  }

  parseOffset(offset: string): number {
    const sign = offset[0] === '+' ? 1 : -1;
    const [hours, minutes] = offset.substring(1).split(':').map(Number);
    return sign * (hours * 60 + minutes);
  }

  //use to trigger dropdown open and close in timezone dropdown
  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  //when user clicks on update button
  updateUserSettings() {
    this.data.image = AppConstants.LOADING;
    this.data.message = 'Please Wait...';
    this.data.disableUpdateButton = true;
    this.userService.updateProfile(this.generatePayload()).subscribe({
      next: (res) => {
        if (res) {
          this.data.image = AppConstants.DELETE_SUCCESSFUL;
          this.data.message = 'Timezone updated successfully';
          this.userService.getUserProfile().subscribe(() => {});
        }
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
      error: (err) => {
        console.info("Error:", err);
        this.data.image = AppConstants.QUERIED_DATA_NOT_FOUND;
        this.data.message = 'Error While Updating Timezone. Try Again';
        //if api call fails or some error, show form
        setTimeout(() => {
          this.data.image = '';
          this.data.message = '';
          this.data.disableUpdateButton = false;
        }, 1000);
      },
    });
  }

  //this function is used to generate payload
  generatePayload(): RequestData.UpdateUser {
    let settings: Settings = this.commonService.getUserSettings();

    let additionlSettings: {
      [key: string]: string | { [key: string]: string };
    } = {
      timezone: {
        name: this.form.value.timeZone.split(' ')[1],
        gmtOffset: this.form.value.timeZone
          .split(' ')[0]
          .replace('(UTC', '')
          .replace(')', ''),
      },
    };

    settings = { ...settings, ...additionlSettings };

    let payload: RequestData.UpdateUser = {
      update: {
        settings: settings,
      },
    };
    return payload;
  }
}
