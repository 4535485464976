import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Project } from 'src/app/shared/models/project/project';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';

@Component({
  selector: 'app-project-expiry-form',
  templateUrl: './project-expiry-form.component.html',
  styleUrls: ['./project-expiry-form.component.scss'],
})
export class ProjectExpiryFormComponent {
  projectExpiryDate: any = new FormControl();
  userData: any;
  userId: any;
  openedOrgData: any;
  defaultModules: any[] = [1022, 1001, 1002, 1003, 1008, 1012];
  loadTable: Subject<boolean> = new BehaviorSubject(false);

  constructor(
    private formservice: FormsService,
    private projectService: ProjectsService,
    private localstorageService: LocalStorageService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    private customMomentService: CustomMomentService,

    @Inject(MTX_DRAWER_DATA)
    { userData }: { userData: Project.Details }
  ) {
    this.userData = userData;
  }
  ngOnInit() {
    this.userId = this.localstorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;
    this.getOrgData();
    this.userData.info.expiry
      ? this.projectExpiryDate.setValue(
        this.customMomentService.moment.unix(this.userData.info.expiry).toDate()
        )
      : '';
  }

  closeForm(data: any = false) {
    this.formservice.closeForm(data);
  }

  getOrgData() {
    this.loadTable.next(false);
    this.projectService
      .getOrgUsers(this.userId, this.userData.orgId)
      .subscribe({
        next: (res) => {
          this.openedOrgData = res.find((org) => org.role === 1);
          this.loadTable.next(true);
        },
        error: (err: any) => {
          this.loadTable.next(true);
          this.notificationService.showSnackBar(err, 'error');
          console.info('Error:', err);
        },
      });
  }
  getExpiry() {
    return this.projectExpiryDate.value
      ? this.customMomentService.moment(this.projectExpiryDate.value).unix()
      : this.userData.info.expiry
      ? this.userData.info.expiry
      : null;
  }

  submit() {
    let payload = {
      targetUserId: this.openedOrgData.userId,
      update: {
        info: {
          ...this.openedOrgData.orgData.info,
          expiry: this.getExpiry(),
        },
      },
      updateModuleExpiry: this.openedOrgData?.moduleExpiry?.map((module: any) =>
        !this.defaultModules.includes(module.moduleId)
          ? { ...module, expiry_date: this.getExpiry() }
          : module
      ),
      updateUser: {},
    };
    this.loadTable.next(false);
    this.projectService
      .updateProject(this.userId!, this.openedOrgData?.org!, payload)
      .subscribe({
        next: (res: any) => {
          this.loadTable.next(true);
          this.formservice.closeForm();
          this.notificationService.showSnackBar(
            'Project expiry date updated successfully',
            'success'
          );
          this.projectService.refreshProject(true);
        },
        error: (err: any) => {
          this.loadTable.next(true);
          this.notificationService.showSnackBar(err, 'error');
          console.info('Error:', err);
        },
      });
  }
}
