import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { AnalyticsConstant } from 'src/app/shared/constants/analytics-constant';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AnalyticsPayload } from 'src/app/shared/models/analytics/analytics-payload';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { SelectionTile } from 'src/app/shared/models/internal-use-front-end/selection-tile';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-analytic-form',
  templateUrl: './analytic-form.component.html',
  styleUrls: ['./analytic-form.component.scss'],
})
export class AnalyticFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  public realTimeDeviceData: DeviceDetails[] = [];
  public devices: DeviceDetails[] = [];
  public deviceTypes!: Array<DeviceType>;
  public form!: FormGroup;
  public typeForm!: FormGroup;
  public maxDate: moment.Moment = this.customMomentService.moment();
  public currentDeviceType: string = '';
  public cancelButtonTitle: string = 'Cancel';
  public cardDetails: SelectionTile[] = AnalyticsConstant.cardDetails;
  public allAverages: AverageHour[] = this.commonService.getAverageHours({
    valueInSeconds: true,
  });
  public requiredAverages: AverageHour[] = this.allAverages;
  public chartTypes = AppConstants.chartTypes;
  public selectedAnalyticType: number = 0;
  public commonKeysArray: string[] = [];
  public commonKeys: { [key: string]: number | string } = {};
  public isEditMode!: boolean;
  public isNextStep: boolean = false;
  public submitBtn: string = 'Next';
  public isMultipleDevice: boolean = false;
  public isMultipleParameter: boolean = false;
  public isSelected: boolean = false;
  public formTitle: string = 'Add New Analytics';
  public fieldsOfCurrentDeviceType!: DeviceField[];
  public limitsOfFields!: FieldLimit[];

  constructor(
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private customMomentService: CustomMomentService,
    @Inject(MTX_DRAWER_DATA) public data: AnalyticsPayload | undefined | any
  ) {}

  ngOnInit() {
    this.isEditMode = Boolean(this.data);
    if (this.data) {
      this.isSelected = true;
      if (this.data.analyticType) {
        switch (this.data.analyticType) {
          case 1:
            this.selectedAnalyticType = 1;
            break;
          case 2:
            this.selectedAnalyticType = 2;
            break;
          case 4:
            this.selectedAnalyticType = 4;
            break;
          case 5:
            this.selectedAnalyticType = 5;
            break;
        }
        this.formTitle = this.analyticsService.getAnalyticsType(
          this.selectedAnalyticType
        );

        setTimeout(() => {
          if (this.stepper.selected) this.stepper.selected.completed = true;
          this.stepper.selectedIndex = 1;
          this.isNextStep = this.form.valid;
          this.submitBtn = 'Run Analytics';
          this.cancelButtonTitle = 'Cancel';
        });
      }
    } else if (!this.data) {
      // this.cardDetails.forEach((cardDetail) => {
      //   cardDetail.isActive = false;
      // });
    }
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.devices = this.deviceService.registeredDevices!;
    this.typeForm = this.formBuilder.group({
      type: ['', Validators.required],
    });

    if (this.isEditMode) {
      this.formAndSubscriptions();
    }
  }

  onAnalyticSelection(cardDetail: SelectionTile) {
    if (cardDetail) {
      this.typeForm.patchValue({
        type: cardDetail.id,
      });
      this.selectedAnalyticType = cardDetail.id;
      this.isNextStep = true;
    } else {
      this.selectedAnalyticType = 0;
      this.isNextStep = false;
      this.typeForm.patchValue({
        type: '',
      });
    }
  }

  //to build the form for taking the data
  buildForm(isParameter: boolean = false) {
    this.form = this.formBuilder.group({
      analyticName: ['', Validators.required],
      deviceId: ['', Validators.required],
      parameter: ['', Validators.required],
      average: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      isMovingAvg: false,
    });

    if (this.selectedAnalyticType === 2) {
      this.form.addControl(
        'deviceType',
        this.formBuilder.control('', Validators.required)
      );
    }

    //will be used when edit form
    if (isParameter) {
      if (isParameter && this.data.analyticsId) {
        this.form.addControl(
          'analyticsId',
          this.formBuilder.control(this.data.analyticsId)
        );
      }
      this.doValidations();
      try {
        let tempDev: string | string[] = [];
        let deviceId: string = '';
        let tempAverage: number = 0;
        tempDev = this.devices
          .filter((device) => this.data?.deviceId.includes(device?.deviceId))
          .map((device) => device?.deviceId);

        if (this.data.analyticType === 1 || this.data.analyticType === 4) {
          tempDev = tempDev[0];
        }

        if (typeof tempDev === 'object') {
          deviceId = tempDev[0];
          this.currentDeviceType =
            this.analyticsService.getDeviceTypeByDeviceId(deviceId);
        }

        let formValue = {
          analyticName: this.data.analyticName,
          deviceId: tempDev,
          startDate: this.customMomentService.moment.unix(this.data.startDate),
          endDate: this.customMomentService.moment.unix(this.data.endDate),
        };

        if (this.data.analyticType === 2) {
          this.form.patchValue({
            deviceType: this.deviceTypes.find(
              (deviceType: any) =>
                deviceType.key ===
                this.analyticsService.getDeviceTypeByDeviceId(deviceId)
            )!.key,
          });
          this.form.get('deviceType')?.disable();
        }
        this.form.patchValue(formValue);

        this.onChangeDeviceType();
        this.form.get('deviceType')?.updateValueAndValidity();
        this.onChangeDeviceId();
        this.form.get('deviceId')?.updateValueAndValidity();

        let tempParameter: string | string[] = [];
        tempParameter = Object.keys(
          this.realTimeDeviceData?.[0]?.payload?.d!
        ).filter((param) => this.data.parameter?.includes(param));

        if (
          this.data.analyticType === 2 ||
          this.data.analyticType === 4 ||
          this.data.analyticType === 5
        ) {
          tempParameter = tempParameter[0];
        }
        this.form.patchValue({
          parameter: tempParameter,
        });
      } catch (e) {
        console.info("Error:", e);
      }
    }
  }

  //to close the form when cross button is clicked
  closeForm() {
    try {
      this.formsService.closeForm();
      // if (this.cardDetails.some((cardDetail) => cardDetail.isActive === true)) {
      //   this.cardDetails.map((cardDetail, index) => {
      //     cardDetail.isActive = false;
      //   });
      // }
    } catch (error) {
      console.info("Error:", error);
    }
  }

  //to get the key from the field
  getKeyFromField(param: string | Array<string>): string {
    if (typeof param === 'string') {
      const foundValue = this.localStorageService
        .getParsedValue(LocalStorageConstants.OZ_FIELDS)
        .find(
          (field: any) => param.toLowerCase() === field.label.toLowerCase()
        );
      if (foundValue) {
        return foundValue.fkey;
      }
    } else if (typeof param === 'object') {
      let fields = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_FIELDS
      );
      let tempArray = [];

      for (let i = 0; i < param.length; i++) {
        for (let k = 0; k < fields.length; k++) {
          if (param[i].toLowerCase() === fields[k].label.toLowerCase()) {
            tempArray.push(fields[k].fkey);
          }
        }
      }
      return tempArray.toString();
    }
    return '';
  }

  //executes when we click on submit form button
  submitForm() {
    this.form.value.startDate = this.form.value.startDate.unix();
    this.form.value.endDate = this.form.value.endDate.endOf('day').unix();
    this.exportFormDetails({
      ...this.form.value,
      analyticType: this.selectedAnalyticType,
    });
    this.formsService.closeForm();
    if (!this.data?.analyticsId) {
      this.router.navigateByUrl('analytics/' + this.getUrl(), {
        state: {
          ...this.form.value,
          analyticType: this.selectedAnalyticType,
        },
      });
    }

    setTimeout(() => {
      this.analyticsService.changeValue(false);
    });
  }

  //to send data to analytics service
  exportFormDetails(exportForm: FormGroup) {
    this.analyticsService.getFormDetails(exportForm);
  }

  //executes when a change event is triggered in 'deviceType' in form
  onChangeDeviceType() {
    this.form.get('deviceType')?.valueChanges.subscribe((res) => {
      const dt = this.deviceTypes.find((deviceType) => deviceType.key === res);
      this.currentDeviceType = dt?.key ?? '';
    });
  }

  //executes when a change event is triggered in 'deviceId' in form
  onChangeDeviceId() {
    this.form.get('deviceId')?.valueChanges.subscribe((res) => {
      this.realTimeDeviceData = [];
      if (res) {
        if (typeof res === 'string') {
          this.handleSingleDevice(res);
        } else {
          this.handleMultipleDevices(res);
        }
        this.commonKeys = Object.fromEntries(
          this.commonKeysArray.map((key) => [key, 0])
        );
        this.form.get('parameter')?.enable();
        this.form.get('parameter')?.setValue('');
      }
    });
  }

  //executes when a change event is triggered in 'average' in form
  onChangeAverage() {
    this.form.get('average')?.valueChanges.subscribe((res) => {
      if (res) {
        if (res?.isMoving) {
          this.form.get('isMovingAvg')?.setValue(true);
        } else {
          this.form.get('isMovingAvg')?.setValue(false);
        }
      }
    });
  }

  // removes control based on the type of chart comparison
  doValidations() {
    if (!this.isEditMode) {
      if (
        this.selectedAnalyticType === 1 ||
        this.selectedAnalyticType === 2 ||
        this.selectedAnalyticType === 5
      ) {
        this.form.addControl(
          'chartType',
          this.formBuilder.control('', Validators.required)
        );
        if (this.form?.get('deviceType')) {
          this.form.removeControl('deviceType');
        }
      }
      if (this.selectedAnalyticType === 2) {
        this.form.addControl(
          'deviceType',
          this.formBuilder.control('', Validators.required)
        );
        this.onChangeDeviceType();
      }
      if (this.selectedAnalyticType === 4) {
        if (this.form?.get('chartType')) this.form.removeControl('chartType');
      }
    }

    if (this.isEditMode) {
      if (this.form?.get('chartType')) this.form.removeControl('chartType');
      if (this.form?.get('average')) this.form.removeControl('average');
      if (this.form?.get('isMovingAvg')) this.form.removeControl('isMovingAvg');
    }
  }

  onStepChange(event: any) {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      this.previous(event);
    } else if (event.selectedIndex > event.previouslySelectedIndex) {
      this.submitAndNext(event);
    }
  }

  //it executes when user click on next/submit button of the form
  submitAndNext(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (stepperLength === 2) {
      if (this.stepper.selectedIndex === 0 && this.selectedAnalyticType != 0) {
        if (event) this.stepper.next();
        if (!this.isSelected) this.formAndSubscriptions();
        this.isSelected = true;
        this.doValidations();
        this.isNextStep = this.form.valid;
        this.formTitle = this.analyticsService.getAnalyticsType(
          this.selectedAnalyticType
        );
        this.submitBtn = 'Run Analytics';
        this.cancelButtonTitle = 'Back';
      } else if (this.stepper.selectedIndex === 1 && this.form.valid) {
        if (event) this.submitForm();
      }
    }
  }

  //it executes when user click on back button of the form
  previous(event?: any) {
    event = !event;
    let stepperLength = this.stepper.steps.length;
    if (!this.isEditMode) {
      if (stepperLength === 2) {
        if (this.stepper.selectedIndex === 1) {
          if (event) this.stepper.previous();
          this.isSelected = false;
          this.submitBtn = 'Next';
          this.cancelButtonTitle = 'Cancel';
          this.formTitle = 'Add New Analytic';
          this.isNextStep = this.selectedAnalyticType !== 0;
        } else if (this.stepper.selectedIndex === 0) {
          this.closeForm();
        }
      }
    } else if (this.isEditMode) {
      this.closeForm();
    }
  }

  isMultipleDeviceAndParameter() {
    if (this.selectedAnalyticType === 1) {
      this.isMultipleDevice = false;
      this.isMultipleParameter = true;
    } else if (this.selectedAnalyticType === 2) {
      this.isMultipleDevice = true;
      this.isMultipleParameter = false;
    } else if (this.selectedAnalyticType === 4) {
      this.isMultipleDevice = false;
      this.isMultipleParameter = false;
    }
  }

  onFormChange() {
    this.form.valueChanges.subscribe((res) => {
      if (res) this.isNextStep = this.form.valid;
    });
  }

  getUrl() {
    if (this.selectedAnalyticType === 1) {
      return 'parameter-comparison-chart';
    } else if (this.selectedAnalyticType === 2) {
      return 'device-comparison-chart';
    } else if (this.selectedAnalyticType === 4) {
      return 'pollution-rose-chart';
    } else if (this.selectedAnalyticType === 5) {
      return 'inter-device-comparison-chart';
    } else {
      return '';
    }
  }

  formAndSubscriptions() {
    this.isMultipleDeviceAndParameter();
    this.buildForm(Boolean(this.data));
    this.onChangeDeviceType();
    this.onChangeDeviceId();
    this.onChangeAverage();
    this.onFormChange();
    this.onChangeParameter();
  }

  setFieldsAndLimits(deviceTypeId: number) {
    let units = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    this.fieldsOfCurrentDeviceType = this.deviceService.fetchFields(
      deviceTypeId,
      units,
      false
    );

    this.limitsOfFields = this.deviceService.fetchLimits(
      units[deviceTypeId],
      false
    );
  }

  calculateAndSetAQI(payload: any, deviceTypeOfDevice: number) {
    const aqiIndex = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).aqiIndex[deviceTypeOfDevice];

    if (aqiIndex) {
      const aqi = DeviceUtil.calculateAQI(payload, aqiIndex.breakpoints);
      if (aqi.aqi) {
        payload.d.aqi = aqi.aqi;
      }
    }
  }

  handleSingleDevice(deviceId: string) {
    this.realTimeDeviceData.push(this.deviceService.mqttDocs[deviceId]);
    const deviceTypeOfDevice: number | undefined =
      DeviceUtil.getDeviceTypeIdByDeviceId(
        this.deviceTypes,
        this.devices,
        deviceId
      );

    this.setFieldsAndLimits(deviceTypeOfDevice!);
    this.calculateAndSetAQI(
      this.realTimeDeviceData[0].payload,
      deviceTypeOfDevice!
    );
  }

  handleMultipleDevices(deviceIds: string[]) {
    let tempArray: string[][] = [];
    let deviceType: number | undefined;
    let areAllDevicesOfSameDeviceType: boolean = true;

    for (let i = 0; i < deviceIds.length; i++) {
      this.realTimeDeviceData.push(this.deviceService.mqttDocs[deviceIds[i]]);
      const deviceTypeOfDevice: number | undefined =
        DeviceUtil.getDeviceTypeIdByDeviceId(
          this.deviceTypes,
          this.devices,
          deviceIds[i]
        );
      this.setFieldsAndLimits(deviceTypeOfDevice!);

      //storing the deviceTypeId of first device of all selected devices
      if (!deviceType) {
        deviceType = deviceTypeOfDevice;
      }

      //if deviceType of any device is different than setting the flag to false
      if (deviceType !== deviceTypeOfDevice) {
        areAllDevicesOfSameDeviceType = false;
      }

      //calculating the aqi option if all the devices are of same type, aqi option not to
      //be shown if devices have different device type
      if (areAllDevicesOfSameDeviceType) {
        this.calculateAndSetAQI(
          this.realTimeDeviceData[i].payload,
          deviceTypeOfDevice!
        );
      }

      tempArray.push(
        Object.keys(this.realTimeDeviceData[i].payload.d) as string[]
      );
    }
    this.commonKeysArray = CommonUtil.findCommomKeys(tempArray);
  }

  //remove raw data option from Average if 'aqi' is selected in Parameter
  onChangeParameter() {
    this.form.get('parameter')?.valueChanges.subscribe((res) => {
      let selectedParams = [];
      if (res) {
        if (typeof res === 'string') {
          selectedParams.push(res);
        } else {
          selectedParams.push(res);
          selectedParams = selectedParams.flat();
        }

        if (selectedParams.includes('aqi')) {
          this.requiredAverages = this.allAverages.filter(
            (avg) => avg.value !== 0
          );
        } else {
          this.requiredAverages = this.allAverages;
        }
      }
    });
  }
}
