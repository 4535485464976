<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <app-data-table
    tableTitle="Analytics"
    [data]="dataForAnalytics"
    [columns]="defaultColumns"
    [displayedColumns]="displayedColumns"
    [showDeleteInHeader]="false"
    [showDownloadInHeader]="false"
    [showFilterInHeader]="false"
    [isChartVisible]="false"
  >
  </app-data-table>
</div>
