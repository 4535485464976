import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, take, map } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogOptions } from '../models/confirm-dialog-options';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  dialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(private dialog: MatDialog) {}

  open(options: ConfirmDialogOptions): void {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
      },
    });
  }

  confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1));
  }
}
