import {
  ChangeDetectorRef,
  Component,
  HostListener
} from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-one-column',
  templateUrl: './one-column.component.html',
  styleUrls: ['./one-column.component.scss'],
})
export class OneColumnComponent {
  constructor(
    private sidebarService: NbSidebarService,
    private cdr: ChangeDetectorRef
  ) {}
  width!: number;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }
  WEBURL = 'https://oizom.com/';
  show_version = environment?.config?.SHOW_VERSION;
  custom_header = environment?.config?.CUSTOM_HEADER;
  currentPackage = require('package.json');
  oizomLogoUrl: string = 'assets/oizom-logo-web.png';
  openUrl() {
    window.open(this.WEBURL, '_blank');
  }
  ngOnInit() {
    this.width = window.innerWidth;
  }
  closeSidebar() {
    if (this.width <= 991) {
      // this.sidebarService.compact('menu-sidebar');
      this.sidebarService.collapse('menu-sidebar');
      setTimeout(() => {
        this.cdr.detectChanges();
      });
    }
  }

  getHeaderBackground() {
    return this.custom_header
      ? 'url("./../../../assets/images/header-bg/local-deploy-header.jpg")'
      : '';
  }
}
