import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import * as Highcharts from 'highcharts';
import { map, Subject, takeUntil } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { AnalyticsConstant } from 'src/app/shared/constants/analytics-constant';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AnalyticsDetails } from 'src/app/shared/models/analytics/analytics-details';
import { AnalyticsPayload } from 'src/app/shared/models/analytics/analytics-payload';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { Device } from 'src/app/shared/models/device/device-info';
import { DevicePayload } from 'src/app/shared/models/device/device-payload';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { sortList } from 'src/app/shared/utils/array-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { HighchartUtils } from 'src/app/shared/utils/highchart-utils';
import { AnalyticFormComponent } from '../../components/analytic-form/analytic-form.component';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);

@Component({
  selector: 'app-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss'],
})
export class GraphViewComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  defaultColor: string = '#00c4b4';
  isDefaultChartView: boolean = true;
  private subscriptions: Subscription[] = [];
  public highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = {};
  public show = false;
  public dataFromAPI: any;
  public seriesOfDataPoints: any = [];
  public form!: FormGroup;
  public graphLoaded: boolean = false;
  public units: string[] = [];
  public fieldsArray: string[] = [];
  public deviceTypesOfUser = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER_DEVICE_TYPE
  );
  public allUserDevices: DeviceDetails[] =
    this.deviceService.registeredDevices!;
  public allUnits = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_USER
  ).units;
  public fields: any;
  public limits: any;
  public analyticDetail!: any;
  public allAverages = this.commonService.getAverageHours({
    valueInSeconds: true,
  });
  public requiredAverages: AverageHour[] = [];
  public chartTypes = AppConstants.chartTypes;
  public directionTypes = AppConstants.directionsType;
  public lineStyles = AnalyticsConstant.lineStyles;
  public isRoseChart: boolean = false;
  public apiDataForRoseChart: any;
  public dataForRoseChart: Record<string, Array<any>> = {};
  public keyForRoseChart: string = '';
  public isDirectionFromRoseChart: boolean = false;
  public readOnlyData: any = {};
  public noContentAvailable: boolean = false;
  public aqiIndexColor: any;
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };
  public deviceTypeForInterDeviceTypeComparison!: number;

  public averageSubscription!: Subscription;
  public deviceData: any;

  public redrawChart: boolean = false;
  redrawChartTimeoutId: { inner?: NodeJS.Timeout; outer?: NodeJS.Timeout } = {};
  private resizeObserver?: ResizeObserver;
  public segmentationFactor: number = 1;
  autoRange: boolean = true;

  constructor(
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    private cdr: ChangeDetectorRef,
    private loadrService: LoadrService,
    private commonService: CommonService,
    private location: Location,
    private router: Router,
    private customMomentService: CustomMomentService,
    private themeService: NbThemeService,
    private elementRef: ElementRef,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    if (this.location.getState()) {
      this.getAnalyticsDetails(this.location.getState(), true);
      // this.setAllAverages();
    }

    const subscription = this.analyticsService.analyticFormDetail$.subscribe(
      (res) => {
        if (res !== undefined) {
          this.getAnalyticsDetails(res);
          // this.setAllAverages();
        }
      }
    );

    this.subscriptions.push(subscription);

    this.resizeObserver = new ResizeObserver(() => {
      this.onResize();
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);

    // Listen for global resize events
    window.addEventListener('resize', this.onResize.bind(this));
  }

  //setting averages based on selected parameter
  setAllAverages() {
    //if parameter list contains 'aqi' than remove raw data option
    if (this.analyticDetail.parameter?.includes('aqi')) {
      this.requiredAverages = this.allAverages.filter((av) => av.value != 0);
    } else {
      this.requiredAverages = this.allAverages;
    }
  }

  onResize() {
    this.show = false;

    this.redrawChartTimeoutId.outer = setTimeout(() => {
      this.redrawChart = true; // Trigger redraw logic here
      this.show = true;
    }, 100);
  }

  validateParameters(): boolean {
    const userUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    if (this.analyticDetail?.parameter?.length === 1) {
      for (const deviceId of this.analyticDetail.deviceId) {
        const deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
          this.commonService.getUserDeviceTypes(),
          this.deviceService.registeredDevices!,
          deviceId
        );

        const deviceUnits = userUnits[deviceTypeId!];
        const paramExists =
          deviceUnits &&
          deviceUnits[this.analyticDetail.parameter[0]] !== undefined;

        if (!paramExists) {
          this.noContentAvailable = true;
          this.noData.minorText =
            'Selected parameter is not available for this device';
          return false;
        }
      }
      return true;
    } else if (this.analyticDetail?.parameter?.length > 1) {
      const validParams = this.analyticDetail.parameter.filter(
        (param: string) => {
          for (const deviceId of this.analyticDetail.deviceId) {
            const deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
              this.commonService.getUserDeviceTypes(),
              this.deviceService.registeredDevices!,
              deviceId
            );

            const deviceUnits = userUnits[deviceTypeId!];
            if (deviceUnits && deviceUnits[param] !== undefined) {
              return true;
            }
          }
          return false;
        }
      );

      this.analyticDetail.parameter = validParams;

      if (this.analyticDetail?.parameter?.length === 0) {
        this.noContentAvailable = true;
        this.noData.minorText =
          'Selected parameters are not available for this device';
        return false;
      }
      return true;
    }

    this.noContentAvailable = true;
    this.noData.minorText = 'No parameters selected';
    return false;
  }

  getAnalyticsDetails(analyticDetail: any, shouldBuildForm: boolean = false) {
    this.analyticDetail = analyticDetail;

    if (typeof this.analyticDetail.parameter === 'string') {
      let tempArray = [];
      tempArray.push(this.analyticDetail.parameter);
      this.analyticDetail.parameter = tempArray;
    }
    if (typeof this.analyticDetail.deviceId === 'string') {
      let tempArray = [];
      tempArray.push(this.analyticDetail.deviceId);
      this.analyticDetail.deviceId = tempArray;
    }

    try {
      // Remove any deviceId from the analytics that the user does not have access to.
      //iterating the loop in reverse to manage index in better way
      for (let i = this.analyticDetail.deviceId.length - 1; i >= 0; i--) {
        const d = this.analyticDetail.deviceId[i];
        let dt = DeviceUtil.getDeviceTypeIdByDeviceId(
          this.commonService.getUserDeviceTypes(),
          this.deviceService.registeredDevices!,
          d
        );
        if (!dt) {
          this.analyticDetail.deviceId.splice(i, 1);
        }
      }
    } catch {
      console.error('Error while removing devices');
    }

    this.setAllAverages();

    //check user selection for default view
    this.isDefaultChartView = this.analyticDetail.defaultView;
    this.autoRange = !this.analyticDetail.staticYAxis;

    // if (typeof this.analyticDetail.parameter === 'string') {
    //   let tempArray = [];
    //   tempArray.push(this.analyticDetail.parameter);
    //   this.analyticDetail.parameter = tempArray;
    // }
    // if (typeof this.analyticDetail.deviceId === 'string') {
    //   let tempArray = [];
    //   tempArray.push(this.analyticDetail.deviceId);
    //   this.analyticDetail.deviceId = tempArray;
    // }

    //getting fields of deviceType
    this.analyticDetail.deviceId.sort().reverse();
    if (
      this.analyticDetail.analyticType === 5 &&
      !this.deviceTypeForInterDeviceTypeComparison
    ) {
      this.deviceTypeForInterDeviceTypeComparison =
        this.getDeviceTypeIdByDeviceId(this.analyticDetail.deviceId[0]);

      this.fields = this.deviceService.fetchFields(
        this.deviceTypeForInterDeviceTypeComparison,
        this.allUnits,
        false
      );

      this.limits = this.deviceService.fetchLimits(
        this.allUnits[this.deviceTypeForInterDeviceTypeComparison],
        false
      );
    }

    this.fields = this.deviceService.fetchFields(
      this.getDeviceTypeIdByDeviceId(this.analyticDetail.deviceId[0]),
      this.allUnits,
      false
    );

    this.limits = this.deviceService.fetchLimits(
      this.allUnits[
        this.getDeviceTypeIdByDeviceId(this.analyticDetail.deviceId[0])
      ],
      false
    );

    this.generateReadOnlyData();

    if (shouldBuildForm) {
      this.buildForm();
      this.formSubscriptions();
    }

    if (!this.validateParameters()) {
      this.graphLoaded = true;
      return;
    }

    //changing the average to 15 mins if user has selected parameter as aqi
    if (
      this.analyticDetail.analyticType !== 101 &&
      this.analyticDetail.parameter.includes('aqi') &&
      this.form?.value?.average?.value == 0
    ) {
      //unsubscribing to do changes in average otherwise it will lead to multiple api calls
      this.averageSubscription.unsubscribe();

      const avrg: any = this.requiredAverages[1];

      this.form.patchValue({
        average: avrg,
      });

      //again subscribing after making changes
      this.avgSubscription();
    }
    if (this.analyticDetail.deviceId) {
      this.getDataForAnalytics();
      this.getParamCompData(
        this.generatePayload(),
        this.analyticDetail.deviceId
      );
    } else if (this.analyticDetail.devices) {
      this.getParamCompData(
        this.generatePayload(),
        this.analyticDetail.devices
      );
    }

    if (
      this.analyticDetail.analyticType === 4 ||
      this.analyticDetail.analyticType === 102
    ) {
      this.isRoseChart = true;
      if (this.analyticDetail.deviceId) {
        this.keyForRoseChart = this.analyticDetail.parameter.toString();
      } else if (this.analyticDetail.devices) {
        this.keyForRoseChart = this.analyticDetail.params.fields.toString();
      }
    }
  }

  //this will run for 101 analytics
  getDataForAnalytics() {
    this.show = false;
    this.analyticsService
      .getDeviceData(this.generatePayload(), this.analyticDetail.deviceId)
      .subscribe((res) => {
        this.deviceData = res;
      });
  }

  getDeviceTypeIdByDeviceId(deviceId: string | string[]) {
    if (typeof deviceId === 'string') {
      let selectedDeviceData = this.allUserDevices.find(
        (allUserDevice) => allUserDevice.deviceId === deviceId
      );
      let deviceType = this.deviceTypesOfUser.find(
        (deviceTypeOfUser: any) =>
          deviceTypeOfUser.key === selectedDeviceData?.deviceType
      );
      return deviceType.deviceTypeId;
    } else if (typeof deviceId === 'object') {
      deviceId = deviceId[0];
      let selectedDeviceData = this.allUserDevices.find(
        (allUserDevice) => allUserDevice.deviceId === deviceId
      );
      let deviceType = this.deviceTypesOfUser.find(
        (deviceTypeOfUser: any) =>
          deviceTypeOfUser.key === selectedDeviceData?.deviceType
      );
      return deviceType.deviceTypeId;
    }
  }

  getUnitsByDeviceTypeId(deviceTypeId: number | string) {
    return this.allUnits[deviceTypeId];
  }

  //to generate the payload
  generatePayload(fromFormChange: boolean = false) {
    let payload: AnalyticsDetails;

    // {
    //   gte: number;
    //   lte: number;
    //   avg: number;
    //   key: string;
    //   isMovingAvg?: boolean;
    // } = {
    //   gte: 0,
    //   lte: 0,
    //   avg: 0,
    //   key: '',
    // };

    // if (this.analyticDetail.deviceId) {
    payload = {
      gte: this.countGteLte(this.analyticDetail.startDate, false)!,
      lte: this.countGteLte(this.analyticDetail.endDate, true)!,
      avg:
        this.analyticDetail?.average?.value ?? this.form?.value?.average?.value,
      key: this.analyticDetail.parameter.toString(),
    };

    if (this.analyticDetail.average?.isMoving === true) {
      payload.isMovingAvg = true;
    }
    // }

    if (this.analyticDetail.analyticType === 4) {
      let t = new Set(payload.key.split(','));
      t.add('ws').add('wd');
      payload = {
        ...payload,
        key: Array.from(t).join(','),
      };
    }
    if (this.analyticDetail.analyticType === 102) {
      let t = new Set(payload.key.split(','));
      t.add('ws').add('wd');
      payload = {
        ...payload,
        key: Array.from(t).join(','),
      };
      // in order to display segmented pollution rose chart we have to devide the avg by an arbitrary factor
      // this division factor should be exactly same in map.component while preparing payload for pollution rose chart
      // payload.avg = payload.avg / this.segmentationFactor;
      payload.avg = payload.avg;
    }
    return payload;
  }

  //to count the gte and lte
  countGteLte(epoch: number, isLTE: boolean) {
    if (epoch) {
      if (isLTE) {
        let lte = this.customMomentService.formatDate({ epoch });
        let todayTime = this.customMomentService.formatDate({});
        if (lte === todayTime) {
          if (
            this.analyticDetail?.average?.value == 0 ||
            this.form?.value?.average?.value == 0
          ) {
            return epoch;
          }
          let lte = this.customMomentService.moment().unix();
          let gte = this.analyticDetail.startDate;
          let finalLTE =
            lte -
            ((lte - gte) %
              (this.analyticDetail?.average?.value ??
                this.form?.value?.average?.value));
          return finalLTE;
        } else {
          return epoch;
        }
      } else {
        return epoch;
      }
    }
    console.info('error in countGteLte() in Analytics Module in Graph View');
    return undefined;
  }

  //it does the API call to get the data to create the graph
  getParamCompData(
    payload: AnalyticsDetails,
    deviceId: string | Array<string>
  ) {
    this.graphLoaded = false;
    this.show = false;
    if (
      this.analyticDetail.analyticType === 1 ||
      this.analyticDetail.analyticType === 4
      // this.analyticDetail.analyticType === 102 ||
      // this.analyticDetail.analyticType === 103
    ) {
      this.analyticsService
        .getParamComparisonData(payload, deviceId[0])
        .subscribe({
          next: (res) => {
            if (this.analyticDetail.analyticType === 1) {
              const updatedRes = res.map((x) => x.payload);
              this.dataFromAPI = updatedRes.flat(1);
              // this.dataFromAPI.reverse();
              this.dataFromAPI = sortList(
                this.dataFromAPI,
                'ASC',
                'payload.d.t'
              );
              const rangeIndex = this.limits.findIndex(
                (x: any) => this.analyticDetail.parameter[0] === x.fkey
              );
              this.generateDataPoints(this.dataFromAPI, 'paramc', rangeIndex);
              this.setUpDataPoints();
            } else if (this.analyticDetail.analyticType === 4) {
              this.apiDataForRoseChart = res;
              this.apiDataForRoseChart = sortList(
                this.apiDataForRoseChart,
                'ASC',
                'payload.d.t'
              );
              this.generateDataPointsForWindRoseChart(
                this.apiDataForRoseChart,
                this.isDirectionFromRoseChart
              );
              this.setUpDataPointsForWindRoseChart(this.dataForRoseChart);
            } 
            // else if (this.analyticDetail.analyticType === 102 || this.analyticDetail.analyticType === 103) {
            //   // this.apiDataForRoseChart = res;
            //   this.dataFromAPI = res;
            //   // this.apiDataForRoseChart = sortList(
            //   this.dataFromAPI = sortList(
            //     this.dataFromAPI,
            //     'ASC',
            //     'payload.d.t'
            //   );
            //   // this.generateDataPointsForWindRoseChart(
            //   //   this.apiDataForRoseChart,
            //   //   this.isDirectionFromRoseChart
            //   // );
            //   // this.setUpDataPointsForWindRoseChart(this.dataForRoseChart);
            // }
            this.cdr.detectChanges();
            this.graphLoaded = true;
          },
          error: (err: string) => {
            console.info('Error:', err);
          },
        });
    } else if (
      (typeof deviceId === 'object' &&
        this.analyticDetail.analyticType === 2) ||
      this.analyticDetail.analyticType === 5 ||
      this.analyticDetail.analyticType === 102 ||
      this.analyticDetail.analyticType === 103
    ) {
      this.dataFromAPI = [];
      this.analyticsService
        .getParamComparisonData(payload, deviceId)
        .subscribe({
          next: (results) => {
            if(this.analyticDetail?.analyticType === 2 || this.analyticDetail?.analyticType === 5) {
              const updatedResults = results.map((x) => x.payload);
              this.dataFromAPI = updatedResults.flat(1);
              this.dataFromAPI = sortList(this.dataFromAPI, 'ASC', 'payload.d.t');
              const rangeIndex = this.limits.findIndex(
                (x: any) => this.analyticDetail.parameter[0] === x.fkey
              );
              this.generateDataPoints(this.dataFromAPI, 'devicec', rangeIndex);
              this.setUpDataPoints();
              this.graphLoaded = true;
            }
            else if(this.analyticDetail?.analyticType === 102 || this.analyticDetail?.analyticType === 103) {
              const updatedResults = results.map((x) => x.payload);
              this.dataFromAPI = updatedResults.flat(1);
              this.dataFromAPI = sortList(this.dataFromAPI, 'ASC', 'payload.d.t');
              const uniqueDevices = [...new Set(this.dataFromAPI.map((item: any) => item.deviceId))];
              const groupedData = uniqueDevices.map((deviceId: string | any) => {
                return {
                  deviceId: deviceId,
                  payload: this.dataFromAPI.filter((item: any) => item.deviceId === deviceId)
                }
              });
              this.graphLoaded = true;
              this.dataFromAPI = groupedData;
            }
          },
          error: (err: string) => {
            console.info('Error:', err);
          },
        });
    } else {
      console.info('fault in getParamCompData() function');
    }
  }

  onStaticYAxisToggle() {
    this.autoRange = !this.form.get('staticYaxis')?.value;
    this.updateYAxisOptions();
    this.setUpDataPoints();
  }

  updateYAxisOptions() {
    this.show = false;
    const rangeIndex = this.limits.findIndex(
      (x: any) => this.analyticDetail.parameter[0] === x.fkey
    );
    this.generateDataPoints(this.dataFromAPI, 'devicec', rangeIndex);
    this.chartOptions.yAxis = this.getYaxisOptions(
      this.chartOptions.yAxis as Highcharts.YAxisOptions
    );

    setTimeout(() => {
      this.show = true;
    });
  }

  setUpDataPoints() {
    if (
      this.analyticDetail.analyticType === 1 ||
      this.analyticDetail.analyticType === 2 ||
      this.analyticDetail.analyticType === 5
    ) {
      if (!this.show) this.show = false;

      let zones: any[] = [];
      if (!this.isDefaultChartView) {
        let deviceTypes = this.commonService.getAllDeviceTypes();
        let allAqi = this.commonService.getAllAQI();
        let allAqis = this.commonService.getAllAQIs();

        //to get the colors of current aqi
        this.aqiIndexColor = DeviceUtil.aqiColorArray(
          deviceTypes,
          allAqi,
          allAqis,
          undefined,
          this.deviceTypeForInterDeviceTypeComparison
        );

        //showing color of chart lines as per set range
        let isLimitSet;
        if (
          this.analyticDetail.analyticType === 2 ||
          this.analyticDetail.analyticType === 5
        ) {
          isLimitSet = this.limits.find(
            (x: any) => this.analyticDetail.parameter[0] === x.fkey
          );
          if (isLimitSet.range) {
            for (let i = 0; i < isLimitSet.range.length; i++) {
              let zone = {
                color: this.aqiIndexColor.color[i],
                value: isLimitSet.range[i],
              };
              zones.push(zone);
            }
          }
        } else {
          for (let i = 0; i < this.analyticDetail.parameter.length; i++) {
            isLimitSet = this.limits.find(
              (x: any) => this.analyticDetail.parameter[i] === x.fkey
            );
            let z = [];
            if (isLimitSet.range) {
              for (let i = 0; i < isLimitSet.range.length; i++) {
                let zone = {
                  color: this.aqiIndexColor.color[i],
                  value: isLimitSet.range[i],
                };
                z.push(zone);
              }
              zones.push(z);
            } else {
              let zone = {
                color: this.defaultColor,
                value: Number.POSITIVE_INFINITY,
              };
              z.push(zone);
              zones.push(z);
            }
          }
        }
      }

      let that = this;
      let xAxisOptions: Highcharts.XAxisOptions = {
        type: 'datetime',
        labels: {
          formatter: function () {
            return that.customMomentService.formatDatetime({
              epoch: parseInt(this.value + '') / 1000,
              format: that.commonService.getDateTimeFormatForGraph(),
            });
          },
        },
        tickmarkPlacement: 'between',
        crosshair: true,
      };

      //customized tooltip
      const tooltipOptions: any = {
        shared: true,
        formatter: function () {
          return (
            that.customMomentService.formatDate({
              epoch: Number(this.x) / 1000,
              format: that.commonService.getDateTimeFormatForToolTip(),
            }) +
            '</br>' +
            `<span style="color: ${this.point.color}">\u25CF</span>` +
            '<b>' +
            this.series.name +
            '</b>' +
            ' : ' +
            this.point.realY
          );
        },
      };

      //setting the title of the chart to null
      this.chartOptions.title = null!;

      //to remove the highcharts credit
      this.chartOptions.credits = { enabled: false };

      //getting the field name [name of device or name of parameter]
      let fieldName = this.getField();

      //getting the field unit in case it is parameter comparisson
      let fieldUnit: string[] = [];
      if (this.analyticDetail.deviceId) {
        fieldUnit = this.getUnit(this.analyticDetail.deviceId[0]);
      } else if (this.analyticDetail.devices) {
        fieldUnit = this.getUnit(this.analyticDetail.devices[0]);
      }

      this.chartOptions.series = [];
      this.chartOptions.xAxis = xAxisOptions;
      this.chartOptions.tooltip = tooltipOptions;

      //y axis config for parameter comparison
      if (this.analyticDetail.analyticType === 1) {
        let yAxisOptions = [];
        for (let i = 0; i < this.seriesOfDataPoints.length; i++) {
          this.chartOptions.series[i] = {
            yAxis: i,
          } as Highcharts.SeriesOptionsType;
          let yaxisOption: Highcharts.YAxisOptions = {
            labels: {
              format: '{value}',
            },
            title: {
              text:
                fieldName[i] + (fieldUnit[i] ? ' (' + fieldUnit[i] + ')' : ''),
            },
            opposite: i % 2 === 1 ? false : true,
          };
          yAxisOptions.push(yaxisOption);
        }
        this.chartOptions.yAxis = yAxisOptions;
      }

      //y axis config for device and inter device comparison
      if (
        this.analyticDetail.analyticType === 5 ||
        this.analyticDetail.analyticType === 2
      ) {
        let yAxisOption: Highcharts.YAxisOptions = {
          labels: {
            format: '{value}',
          },
          title: {
            text:
              DeviceUtil.getFieldName(
                this.analyticDetail.parameter[0],
                this.fields
              ) + (fieldUnit[0] ? ' (' + fieldUnit[0] + ')' : ''),
          },
        };

        this.chartOptions.yAxis = this.getYaxisOptions(yAxisOption);
      }

      //reset zoom button for chart
      this.chartOptions.chart = {
        type:
          this.analyticDetail?.chartType?.value ??
          this.form?.value?.chartType?.value,
        zooming: {
          resetButton: {
            position: { align: 'right', verticalAlign: 'top', x: -50 },
          },
          singleTouch: true,
          type: 'xy',
        },
      };

      //to set the download button of the chart
      // this.chartOptions.exporting = {
      //   buttons: {
      //     contextButton: {
      //       verticalAlign: 'bottom',
      //     },
      //   },
      // };

      //to set the download button of the chart
      this.chartOptions.exporting = {
        chartOptions: {
          title: {
            text: 'Pollution Rose Chart',
          },
          subtitle: {
            align: 'center',
          },
        },
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],

            align: 'left',
            verticalAlign: 'bottom',
          },
        },
      };

      this.chartOptions.plotOptions = {
        column: { pointPlacement: 'between' },
      };

      for (let i = 0; i < this.seriesOfDataPoints.length; i++) {
        this.chartOptions.series[i] = {
          ...this.chartOptions.series[i],
          name:
            this.analyticDetail.analyticType === 2 ||
            this.analyticDetail.analyticType === 5
              ? DeviceUtil.getDeviceLabel(
                  this.allUserDevices,
                  this.seriesOfDataPoints[i].deviceId
                )
              : fieldName[i] + (fieldUnit[i] ? ' (' + fieldUnit[i] + ')' : ''),
          data: this.seriesOfDataPoints[i].data,
          dashStyle: this.lineStyles[i],
          zones: this.analyticDetail.analyticType === 1 ? zones[i] : zones,
        } as Highcharts.SeriesOptionsType;
      }

      this.chartOptions.plotOptions = {
        series: {
          states: {
            inactive: {
              enabled: false,
            },
          },
        },
      };

      this.chartOptions.plotOptions.series!.turboThreshold =
        Number.POSITIVE_INFINITY;
      this.chartOptions.plotOptions.series!.cropThreshold =
        Number.POSITIVE_INFINITY;

      //to show message to user about which unit is selected for this analytic
      if (this.analyticDetail.analyticType === 5) {
        let labelName = DeviceUtil.getFieldName(
          this.analyticDetail.parameter[0],
          this.fields
        );
        this.notificationService.showNotification(
          `The unit for ${labelName} is ${fieldUnit} in the analytics`,
          'Close',
          'bottom',
          'center',
          'info',
          10000
        );
      }
    }

    setTimeout(() => {
      this.show = true;
      this.onThemeChange();
    });
  }

  getYaxisOptions(existingOptions: Highcharts.YAxisOptions) {
    const rangeIndex = this.limits.findIndex(
      (x: any) => this.analyticDetail.parameter[0] === x.fkey
    );
    existingOptions.plotLines = [this.getYaxisReferenceLine(rangeIndex)];
    let yAxisRange: number[] = [];
    if (!this.autoRange) {
      yAxisRange = this.getRangeForStaticYAxis(rangeIndex);
      existingOptions['tickPositions'] =
        this.getTickPositionForStaticYAxis(yAxisRange);

      let i = -1;
      existingOptions['labels'] = {
        formatter: function () {
          i++;
          if (i >= yAxisRange.length) {
            // Ensure we don't go out of bounds
            i = 0;
          }
          return String(yAxisRange[i]);
        },
      };
    }
    return existingOptions;
  }

  getYaxisReferenceLine(rangeIndex: number): Highcharts.XAxisPlotLinesOptions {
    const paramName = DeviceUtil.getFieldName(
      this.analyticDetail.parameter[0],
      this.fields
    );
    const paramUnit = this.getUnit(this.analyticDetail.deviceId[0]);
    const paramKey = this.analyticDetail.parameter[0];
    let value;
    let refLine: Highcharts.XAxisPlotLinesOptions = {};

    value = this.getValueForRefLine(paramKey);

    if (value === undefined) {
      return refLine;
    }

    let normalizedValue = value;
    if (!this.autoRange) {
      normalizedValue = this.normalizeRefLineValue(value, rangeIndex);
    }

    const currentTheme = this.localStorageService.getValue(
      LocalStorageConstants.CURRENT_THEME
    );

    let selectedAverage = this.form?.get('average')?.value.value;
    let hourValue = '';

    if (selectedAverage == '3600') hourValue = '1';
    else if (selectedAverage == '28800') hourValue = '8';
    else if (selectedAverage == '86400') hourValue = '24';

    const labelText = `${paramName} ${hourValue} Hr : ${value}${paramUnit}`;

    refLine = HighchartUtils.getRefLineConfig({
      currentTheme,
      value: normalizedValue,
      labelText,
    });

    return refLine;
  }

  getValueForRefLine(parmeterKey: string): undefined | number {
    const deviceTypes = this.commonService.getUserDeviceTypes();
    const devices = this.deviceService.registeredDevices;
    const deviceId = this.analyticDetail.deviceId[0];

    if (!devices?.length) {
      return undefined;
    }

    const selectedDeviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      deviceTypes,
      devices,
      deviceId
    );

    if (!selectedDeviceTypeId) {
      return undefined;
    }

    let allUnits = this.commonService.getAllUnits();
    let unitsOfSelectedDeviceType = allUnits[selectedDeviceTypeId];
    let selectedParam = unitsOfSelectedDeviceType[parmeterKey];

    if (!selectedParam?.options?.refLineLimits) return undefined;

    let selectedAverage = this.form?.get('average')?.value.value;
    let refKeyValue = '';

    if (selectedAverage == '3600') refKeyValue = 'hr';
    else if (selectedAverage == '28800') refKeyValue = '8hr';
    else if (selectedAverage == '86400') refKeyValue = 'daily';

    if (
      refKeyValue !== '' &&
      selectedParam?.options?.refLineLimits[refKeyValue] !== null
    ) {
      return selectedParam?.options?.refLineLimits[refKeyValue];
    }

    return undefined;
  }

  // calculateAqiForDevice(deviceDetails: any) {
  //   const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
  //     LocalStorageConstants.OZ_ALL_DEV_TYPE
  //   );

  //   const deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
  //     this.deviceTypesOfUser,
  //     this.allUserDevices,
  //     this.analyticDetail.deviceId[0]
  //   );

  //   const deviceTypesWithAqi = Object.keys(this.commonService.getAllAQIs());

  //   //calculating aqi only for devicetype with aqi
  //   if (deviceTypeId && deviceTypesWithAqi.includes(String(deviceTypeId))) {
  //     const allAqi: any = this.localStorageService.getParsedValue(
  //       LocalStorageConstants.OZ_ALL_AQI
  //     );
  //     const allAqis: any = this.localStorageService.getParsedValue(
  //       LocalStorageConstants.OZ_ALL_AQIS
  //     );

  //     //to get the colors of current aqi
  //     this.aqiIndexColor = DeviceUtil.aqiColorArray(
  //       deviceTypes,
  //       allAqi,
  //       allAqis
  //     );

  //     //calculating aqi
  //     deviceDetails.forEach((device: any) => {
  //       let currentDeviceTypeId = this.getDeviceTypeIdByDeviceId(
  //         device.deviceId
  //       );

  //       const aqi: any = this.localStorageService.getParsedValue(
  //         LocalStorageConstants.OZ_USER
  //       ).aqiIndex?.[currentDeviceTypeId];

  //       //if aqi available for device than adding it
  //       if (aqi) {
  //         let breakpointssss = DeviceUtil.getBreakpoints(
  //           aqi.id,
  //           device.deviceType,
  //           deviceTypes,
  //           allAqi,
  //           allAqis,
  //           aqi
  //         );

  //         const aqiData = DeviceUtil.calculateAQI(
  //           device.payload,
  //           breakpointssss
  //         );
  //         device.payload.d['aqi'] = aqiData.aqi;
  //       }
  //     });
  //   }
  // }

  convertValuesToUserUnits() {
    let convertedValues: DevicePayload[] = [];

    if (!this.deviceTypeForInterDeviceTypeComparison) {
      this.deviceTypeForInterDeviceTypeComparison =
        this.getDeviceTypeIdByDeviceId(this.analyticDetail.deviceId[0]);
    }

    this.dataFromAPI.forEach((data: Device.DataPoints) => {
      let unitsOfDeviceType: any;

      if (this.analyticDetail.analyticType !== 5) {
        let deviceTypeId = this.getDeviceTypeIdByDeviceId(data.deviceId);
        unitsOfDeviceType = this.getUnitsByDeviceTypeId(deviceTypeId);
      } else {
        unitsOfDeviceType = this.getUnitsByDeviceTypeId(
          this.deviceTypeForInterDeviceTypeComparison
        );
      }

      // const convertedValue = DeviceUtil.convertUnits(
      //   data.payload,
      //   unitsOfDeviceType
      // );

      convertedValues.push(data.payload);
    });

    return convertedValues;
  }

  generateDataPoints(
    deviceDetails: any,
    comparisonType: string,
    rangeIndex: any
  ) {
    if (this.dataFromAPI.length < 1) {
      this.noContentAvailable = true;
      return;
    } else this.noContentAvailable = false;

    // this.calculateAqiForDevice(deviceDetails);

    const convertedValues: DevicePayload[] = this.convertValuesToUserUnits();

    if (comparisonType === 'paramc') {
      if (this.analyticDetail.deviceId) {
        this.seriesOfDataPoints = this.analyticDetail.parameter.map(
          (parameter: string) => {
            let dataPoints = this.dataFromAPI.map(
              (data: any, index: number) => {
                // let tempPayload: DevicePayload = {
                //   d: {},
                // };
                // tempPayload.d[parameter] = data.payload.d[parameter];
                // tempPayload.d['t'] = data.payload.d['t'];

                // data = DeviceUtil.convertUnits(
                //   tempPayload,
                //   this.getUnitsByDeviceTypeId(
                //     this.getDeviceTypeIdByDeviceId(
                //       this.analyticDetail.deviceId[0]
                //     )
                //   )
                // );

                let dataPoint: any = {};
                dataPoint.x = this.customMomentService.moment
                  .unix(convertedValues[index].d['t'])
                  .toDate();

                dataPoint.y = convertedValues[index].d[parameter];
                dataPoint.realY = convertedValues[index].d[parameter];
                return dataPoint;
              }
            );
            return { parameter: parameter, data: dataPoints };
          }
        );
      }
      // else if (this.analyticDetail.devices) {
      //   this.seriesOfDataPoints = this.analyticDetail.params.fields.map(
      //     (parameter: string) => {
      //       let dataPoints = this.dataFromAPI.map((data: any) => {
      //         let dataPoint: any = {};
      //         data = DeviceUtil.convertUnits(
      //           data.payload,
      //           this.getUnitsByDeviceTypeId(
      //             this.getDeviceTypeIdByDeviceId(this.analyticDetail.devices[0])
      //           )
      //         );
      //         dataPoint.x = this.customMomentService.moment
      //           .unix(data.d['t'])
      //           .toDate();
      //         dataPoint.y = data.d[parameter];
      //         return dataPoint;
      //       });
      //       return { parameter: parameter, data: dataPoints };
      //     }
      //   );
      // }
    } else if (comparisonType === 'devicec') {
      // if (this.analyticDetail.deviceId) {
      this.seriesOfDataPoints = Object.values(
        this.dataFromAPI.reduce((all: any, data: any, index: number) => {
          if (all[data.deviceId] === undefined) {
            all[data.deviceId] = { deviceId: data.deviceId, data: [] };
          }
          let dataPoint: any = {};
          // let dataPayload = data.payload;
          // if (this.analyticDetail.analyticType !== 5) {
          //   dataPayload = DeviceUtil.convertUnits(
          //     data.payload,
          //     this.getUnitsByDeviceTypeId(
          //       this.getDeviceTypeIdByDeviceId(data.deviceId)
          //     )
          //   );
          // } else {
          //   dataPayload = this.dataPointsForInterDeviceComparison(
          //     data.payload
          //   );
          // }
          dataPoint.x = this.customMomentService.moment
            .unix(convertedValues[index].d['t'])
            .toDate();

          let value: number = parseFloat(
            data?.payload?.d[this.analyticDetail.parameter[0] ?? 'aqi']
          );

          let y = value;

          //for static yAxis
          if (!this.autoRange) {
            let limitsExmp: any =
              this.getRangeAndTickObjectForNormalisation(rangeIndex);

            y = this.getNormalisedValue(limitsExmp, value);
          }

          dataPoint = {
            x: +this.customMomentService.moment.unix(data?.payload?.d?.t),
            y: y,
            realY: value,
            color: '',
          };

          all[data.deviceId].data.push(dataPoint);
          return all;
        }, {})
      );
      // else if (this.analyticDetail.devices) {
      //   this.seriesOfDataPoints = Object.values(
      //     this.dataFromAPI.reduce((all: any, data: any) => {
      //       if (all[data.deviceId] === undefined) {
      //         all[data.deviceId] = { deviceId: data.deviceId, data: [] };
      //       }
      //       let dataPoint: any = {};
      //       let dataPayload = data.payload;
      //       if (this.analyticDetail.analyticType !== 5) {
      //         dataPayload = DeviceUtil.convertUnits(
      //           data.payload,
      //           this.getUnitsByDeviceTypeId(
      //             this.getDeviceTypeIdByDeviceId(data.deviceId)
      //           )
      //         );
      //       } else {
      //         dataPayload = this.dataPointsForInterDeviceComparison(
      //           data.payload
      //         );
      //       }
      //       dataPoint.x = this.customMomentService.moment
      //         .unix(dataPayload.d['t'])
      //         .toDate();
      //       dataPoint.y = dataPayload.d[this.analyticDetail.params.fields[0]];
      //       all[data.deviceId].data.push(dataPoint);
      //       return all;
      //     }, {})
      //   );
      // }
    }
  }

  normalizeRefLineValue(value: number, rangeIndex: number): number {
    let limitsExmp: any =
      this.getRangeAndTickObjectForNormalisation(rangeIndex);
    let normalizedRefLineValue = this.getNormalisedValue(limitsExmp, value);
    return normalizedRefLineValue;
  }

  getNormalisedValue(limits: any, val: number) {
    if (val <= limits[0].x) {
      let { x: x1, y: y1 } = limits[0];
      let { x: x2, y: y2 } = limits[1];
      return y1 + ((val - x1) / (x2 - x1)) * (y2 - y1);
    }

    for (let i = 0; i < limits.length - 1; i++) {
      let { x: x1, y: y1 } = limits[i];
      let { x: x2, y: y2 } = limits[i + 1];

      if (val >= x1 && val <= x2) {
        return y1 + ((val - x1) / (x2 - x1)) * (y2 - y1);
      }
    }

    let { x: x1, y: y1 } = limits[limits.length - 2];
    let { x: x2, y: y2 } = limits[limits.length - 1];
    return y1 + ((val - x1) / (x2 - x1)) * (y2 - y1);
  }

  getRangeAndTickObjectForNormalisation(rangeIndex: number) {
    let range = this.getRangeForStaticYAxis(rangeIndex);
    let staticRange = this.getTickPositionForStaticYAxis(range);

    return range.map((_: number, i: number) => {
      return { x: range[i], y: staticRange[i] };
    });
  }

  getTickPositionForStaticYAxis(range: number[]) {
    let count = 0;
    return range.map((_) => count++);
  }

  getRangeForStaticYAxis(rangeIndex: any) {
    let range =
      this.limits[rangeIndex].range?.length > 0
        ? this.limits[rangeIndex].range!
        : [
            this.limits[rangeIndex].lowestLimit,
            this.limits[rangeIndex].highestLimit,
          ];

    const refLineValue = this.getValueForRefLine(
      this.analyticDetail.parameter[0] ?? ''
    );
    if (refLineValue !== undefined && refLineValue > range[range.length - 1]) {
      range.push(refLineValue);
    }

    return range;
  }

  dataPointsForInterDeviceComparison(payload: any) {
    // if (!this.deviceTypeForInterDeviceTypeComparison) {
    //   this.deviceTypeForInterDeviceTypeComparison =
    //     this.getDeviceTypeIdByDeviceId(this.analyticDetail.deviceId[0]);
    // }
    // let unitsOfDeviceType = this.getUnitsByDeviceTypeId(
    //   this.deviceTypeForInterDeviceTypeComparison
    // );
    // return DeviceUtil.convertUnits(payload, unitsOfDeviceType);
  }

  editAnalyticForm() {
    this.formsService
      .openForm(AnalyticFormComponent, this.analyticDetail)
      .subscribe(() => {});
  }

  buildForm() {
    if (this.analyticDetail.analyticType === 101) {
      this.form = this.formBuilder.group({
        average: ['', Validators.required],
      });
      let avrg;

      //if this condition satisfies than user is coming by filling form
      if (this.analyticDetail.average?.label) {
        avrg = this.requiredAverages.find(
          (allAverage) => allAverage.label == this.analyticDetail.average.label
        );
      }

      this.form.patchValue({
        average: avrg,
      });
    }

    if (
      this.analyticDetail.analyticType === 1 ||
      this.analyticDetail.analyticType === 2 ||
      this.analyticDetail.analyticType === 5
    ) {
      this.form = this.formBuilder.group({
        average: ['', Validators.required],
        chartType: ['', Validators.required],
        ...(this.analyticDetail.analyticType === 2 && {
          staticYaxis: [this.analyticDetail.staticYAxis || false],
        }),
      });
      let avrg;
      let chart;

      //if this condition satisfies than user is coming by filling form
      if (this.analyticDetail.average?.label) {
        avrg = this.requiredAverages.find(
          (allAverage) => allAverage.label == this.analyticDetail.average.label
        );
        chart = this.chartTypes.find(
          (chartType) => chartType.label == this.analyticDetail.chartType.label
        );
      }
      //if this condition satisfies than user is coming by view option
      else {
        //if analytics generated from old terminal than there is a chance that average might not be available
        let tempAvg: number = 0;
        if (this.analyticDetail.average) {
          tempAvg = this.analyticDetail.average.value ?? 3600;
        }
        avrg = this.requiredAverages.find(
          (allAverage) => allAverage.value == tempAvg
        );
        if (avrg === undefined) {
          avrg = this.requiredAverages.find(
            (allAverage) => allAverage.value === 3600
          );
        }
        chart = this.chartTypes.find(
          (chartType) => chartType.value === this.analyticDetail.chart
        );
      }
      this.form.patchValue({
        average: avrg,
        chartType: chart,
        ...(this.analyticDetail.analyticType === 2 && {
          staticYaxis: this.analyticDetail.staticYAxis,
        }),
      });
    }

    if (this.analyticDetail.analyticType === 4) {
      this.form = this.formBuilder.group({
        average: ['', Validators.required],
        directionType: ['', Validators.required],
      });

      let averageTime;
      let directionType;
      directionType = this.directionTypes.find(
        (directionType) => directionType.label == 'Direction From'
      );

      averageTime = this.requiredAverages.find(
        (allAverage) =>
          allAverage.value ==
          (this.analyticDetail?.average?.value ??
            this.form?.value?.average?.value)
      );

      //if analytics generated from old terminal than there is a chance that average might not be available
      if (averageTime === undefined) {
        averageTime = this.requiredAverages.find(
          (allAverage) => allAverage.value === 3600
        );
      }

      this.form.patchValue({
        average: averageTime,
        directionType: directionType,
      });
    }

    if (
      this.analyticDetail.analyticType === 102 ||
      this.analyticDetail.analyticType === 103
    ) {
      this.form = this.formBuilder.group({
        average: ['', Validators.required],
        chartType: ['', Validators.required],
      });
      let avrg;
      let chart;

      //if this condition satisfies than user is coming by filling form
      if (this.analyticDetail.average?.label) {
        avrg = this.requiredAverages.find(
          (allAverage) => allAverage.label == this.analyticDetail.average.label
        );
        chart = 'premium';
      }
      //if this condition satisfies than user is coming by view option
      else {
        //if analytics generated from old terminal than there is a chance that average might not be available
        let tempAvg: number = 0;
        if (this.analyticDetail.average) {
          tempAvg = this.analyticDetail.average.value ?? 3600;
        }
        avrg = this.requiredAverages.find(
          (allAverage) => allAverage.value == tempAvg
        );
        if (avrg === undefined) {
          avrg = this.requiredAverages.find(
            (allAverage) => allAverage.value === 3600
          );
        }
        chart = 'premium';
      }
      this.form.patchValue({
        average: avrg,
        chartType: chart,
      });
    }
  }

  getUnit(deviceId: string) {
    this.units = [];
    if (this.analyticDetail.deviceId) {
      this.analyticDetail.parameter.map((param: any) => {
        return this.units.push(
          DeviceUtil.getFieldUnit(
            param,
            undefined,
            this.getUnitsByDeviceTypeId(
              this.getDeviceTypeIdByDeviceId(deviceId)
            )
          )
        );
      });
    } else if (this.analyticDetail.devices) {
      this.analyticDetail.params.fields.map((param: any) => {
        return this.units.push(
          DeviceUtil.getFieldUnit(
            param,
            undefined,
            this.getUnitsByDeviceTypeId(
              this.getDeviceTypeIdByDeviceId(deviceId)
            )
          )
        );
      });
    }

    return this.units;
  }

  getField() {
    this.fieldsArray = [];
    if (this.analyticDetail.deviceId) {
      if (this.analyticDetail.analyticType === 1) {
        this.analyticDetail.parameter.map((param: any) => {
          return this.fieldsArray.push(
            DeviceUtil.getFieldName(param, this.fields)
          );
        });
      } else if (
        this.analyticDetail.analyticType === 2 ||
        this.analyticDetail.analyticType === 5
      ) {
        this.analyticDetail.deviceId.map((device: any) => {
          return this.fieldsArray.push(
            this.analyticsService.getDeviceNameByDeviceId(device)
          );
        });
      } else if (this.analyticDetail.analyticType === 4) {
        this.fieldsArray.push(
          DeviceUtil.getFieldName(
            this.analyticDetail.parameter.toString(),
            this.fields
          )
        );
      }
    }
    return this.fieldsArray;
  }

  generatePayloadForSave(): AnalyticsPayload {
    let payloadForSave: any = { data: {} };
    let paramsPayload;
    if(this.analyticDetail.analyticType === 102 || this.analyticDetail.analyticType === 103) {
      paramsPayload = {
        avg: this.analyticDetail.average.value,
        fields: this.analyticDetail.parameter,
        ravg: this.getRavg(),
        dataDistributionType: this.analyticDetail.dataDistributionType,
        chart: this.getSelectedChartType(),
        defaultView: this.isDefaultChartView,
        ...(this.analyticDetail.analyticType === 2 && {
          staticYAxis: this.form.get('staticYaxis')?.value,
        }),
      }
    }
    else {
      paramsPayload = {
        fields: this.analyticDetail.parameter,
        ravg: this.getRavg(),
        dataDistributionType: this.analyticDetail.dataDistributionType,
        chart: this.getSelectedChartType(),
        defaultView: this.isDefaultChartView,
        ...(this.analyticDetail.analyticType === 2 && {
          staticYAxis: this.form.get('staticYaxis')?.value,
        }),
      }
    }
    payloadForSave = {
      analytics_type: this.analyticDetail.analyticType,
      deviceTypeId: this.getDeviceTypeIdByDeviceId(
        this.analyticDetail.deviceId
      ),
      devices: this.getDevicesList(this.analyticDetail.deviceId),
      end_time: this.analyticDetail.endDate,
      params: paramsPayload,
      start_time: this.analyticDetail.startDate,
      title: this.analyticDetail.analyticName,
      userId: this.localStorageService.getValue(LocalStorageConstants.USER_ID),
      description: null,
    };
    if (this.analyticDetail.analyticsId) {
      payloadForSave = {
        ...payloadForSave,
        analyticsId: this.analyticDetail.analyticsId,
      };
    }
    return payloadForSave;
  }

  getSelectedChartType() {
    if (this.analyticDetail.analyticType === 101) {
      return null;
    }

    return this.form.value?.chartType?.value
      ? this.form.value?.chartType?.value
      : this.analyticDetail.analyticType === 4
      ? 'column'
      : 'line';
  }

  saveAnalyticsDetail() {
    this.loadrService.showLoader();
    let payload = this.generatePayloadForSave();
    if (payload.analyticsId) {
      this.analyticsService.updateAnalyticsDetails(payload).subscribe({
        next: (res) => {
          if (res) {
            this.loadrService.removeLoader();
            // this.notificationService.showNotification(
            //   res,
            //   'Close',
            //   'bottom',
            //   'center',
            //   'success',
            //   5000
            // )
            this.cancelGraphView();
          }
        },
        error: (err) => {
          console.info('Error:', err);
          this.loadrService.removeLoader();
          // this.notificationService.showNotification(
          //   err,
          //   'Close',
          //   'bottom',
          //   'center',
          //   'error'
          // )
        },
      });
    } else {
      this.analyticsService
        .saveAnalyticsData(this.generatePayloadForSave())
        .subscribe({
          next: (res) => {
            if (res) {
              this.loadrService.removeLoader();
              // this.notificationService.showNotification(
              //   res,
              //   'Close',
              //   'bottom',
              //   'center',
              //   'success',
              //   5000
              // )
              this.cancelGraphView();
            }
          },
          error: (err) => {
            console.info('Error:', err);
            this.loadrService.removeLoader();
            // this.notificationService.showNotification(
            //   err,
            //   'Close',
            //   'bottom',
            //   'center',
            //   'error'
            // )
          },
        });
    }
  }

  getDevicesList(device: string | Array<string>): Array<string> {
    if (typeof device === 'string') {
      let deviceArray = [];
      deviceArray.push(device);
      return deviceArray;
    } else {
      return device;
    }
  }

  getRavg(): boolean {
    if (this.form.value.average?.isMoving === true) {
      return true;
    } else {
      return false;
    }
  }

  generateDataPointsForWindRoseChart(
    deviceDetails: any,
    directionFlag?: boolean
  ) {
    const units = this.commonService.getAllUnits();

    const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.commonService.getAllAQI();
    const allAqis: any = this.commonService.getAllAQIs();

    //to get the colors of current aqi
    this.aqiIndexColor = DeviceUtil.aqiColorArray(deviceTypes, allAqi, allAqis);

    //if user selects HAQI or USAQI or any AQI as parameter, then calculate aqi
    // if (this.keyForRoseChart === 'aqi') {
    // deviceDetails[0].payload.forEach((device: any) => {
    //   let currentDeviceTypeId = this.getDeviceTypeIdByDeviceId(
    //     device.deviceId
    //   );

    // const aqi: any = this.localStorageService.getParsedValue(
    //   LocalStorageConstants.OZ_USER
    // ).aqiIndex?.[currentDeviceTypeId];

    // let breakpointssss = DeviceUtil.getBreakpoints(
    //   aqi.id,
    //   device.deviceType,
    //   deviceTypes,
    //   allAqi,
    //   allAqis,
    //   aqi
    // );

    // const aqiData = DeviceUtil.calculateAQI(device.payload, breakpointssss);
    // device['aqi'] = aqiData.aqi;
    // });
    // }

    this.dataForRoseChart = DeviceUtil.roseConverter(
      deviceDetails[0].payload,
      units[this.getDeviceTypeIdByDeviceId(deviceDetails[0].deviceId)],
      this.keyForRoseChart,
      directionFlag
    );

    //if wind data is not available than showing message
    if (this.dataForRoseChart?.[this.keyForRoseChart]?.length < 1) {
      this.noContentAvailable = true;
      this.noData.minorText =
        'Wind data is not available for the selected parameter';
    } else {
      this.noContentAvailable = false;
      this.noData.minorText = 'Your device may be offline';
    }
  }

  getWindRoseData(ws: any) {
    if (ws) {
      for (let wd of ws) {
        if (wd) {
          wd.data = this.getWindDirectionData(wd?.data);
        }
      }
      return ws;
    }
    return [];
  }

  getWindDirectionData(wd: any) {
    let windDir = [];
    for (let row of wd) {
      if (row) {
        windDir[0] = ['N', 0];
        switch (row[0]) {
          case 'N':
            windDir[1] = row;
            break;
          case 'NNE':
            windDir[2] = row;
            break;
          case 'NE':
            windDir[3] = row;
            break;
          case 'ENE':
            windDir[4] = row;
            break;
          case 'E':
            windDir[5] = row;
            break;
          case 'ESE':
            windDir[6] = row;
            break;
          case 'SE':
            windDir[7] = row;
            break;
          case 'SSE':
            windDir[8] = row;
            break;
          case 'S':
            windDir[9] = row;
            break;
          case 'SSW':
            windDir[10] = row;
            break;
          case 'SW':
            windDir[11] = row;
            break;
          case 'WSW':
            windDir[12] = row;
            break;
          case 'W':
            windDir[13] = row;
            break;
          case 'WNW':
            windDir[14] = row;
            break;
          case 'NW':
            windDir[15] = row;
            break;
          case 'NNW':
            windDir[16] = row;
            break;
        }
      }
    }
    return windDir;
  }

  setUpDataPointsForWindRoseChart(windRoseData: any) {
    if (!windRoseData || !windRoseData?.ws) {
      return;
    }
    let deviceId = this.analyticDetail.deviceId
      ? this.analyticDetail.deviceId
      : this.analyticDetail.devices;
    deviceId = deviceId.toString();
    // let parameters = this.analyticDetail.parameter
    //   ? this.analyticDetail.parameter
    //   : this.analyticDetail.params.fields;
    // // parameters = parameters.toString();

    let unit: string = this.getUnit(deviceId).toString();
    this.show = false;
    let xAxisOptions: Highcharts.XAxisOptions = {
      tickmarkPlacement: 'on',
      type: 'category',
      grid: {
        enabled: true,
      },
      plotLines: [
        {
          ...AppConstants.ROSE_CHART_RESULTANT_LINE_CONFIG,
          value: windRoseData?.resultant?.ws?.directionCategory ?? 0,
        },
      ],
      gridLineWidth: 1,
      gridLineColor: '#e0e0e0',
    };
    let yAxisOptions: Highcharts.AxisOptions = {
      title: { text: null },
      labels: {
        formatter: function () {
          return this.value + '%';
        },
      },
      grid: {
        enabled: true,
      },
      gridLineWidth: 1,
      gridLineColor: '#e0e0e0',
      reversedStacks: true,
      showLastLabel: true,
      endOnTick: false,
      min: 0,
    };

    this.chartOptions = {
      title: undefined,
      subtitle: undefined,
      colors: this.aqiIndexColor.color,
      pane: { size: '85%' },
      legend: {
        title: {
          text: this.getField().toString(),
        },
        align: 'left',
        verticalAlign: 'top',
        y: 50,
        layout: 'vertical',
      },
      xAxis: {
        ...xAxisOptions,
        plotLines: [
          {
            ...AppConstants.ROSE_CHART_RESULTANT_LINE_CONFIG,
            value:
              windRoseData?.resultant?.[this.keyForRoseChart]
                ?.directionCategory ?? 0,
          },
        ],
      },
      yAxis: yAxisOptions,
      chart: {
        type: 'column',
        polar: true,
        reflow: true,
        animation: true,
      },
      credits: { enabled: false },
      tooltip: {
        followPointer: true,
      },
      exporting: {
        chartOptions: {
          title: {
            text: 'Wind Rose Chart',
          },
          subtitle: {
            align: 'center',
          },
        },
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          shadow: false,
          pointPlacement: 'on',
        },
      },
    };
    this.chartOptions.series = this.getWindRoseData(
      windRoseData?.[this.keyForRoseChart]
    ) as Highcharts.SeriesOptionsType[];

    setTimeout(() => {
      this.show = true;
      this.onThemeChange();
    });
  }

  cancelGraphView() {
    // this.subscriptions.forEach(
    //   (subscription: Subscription) =>
    //     !subscription?.closed && subscription?.unsubscribe()
    // );
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.averageSubscription) this.averageSubscription.unsubscribe();
    this.analyticsService.getFormDetails(undefined);
    this.router.navigateByUrl('analytics/analytics-list');
  }

  generateReadOnlyData() {
    this.readOnlyData['analyticName'] = this.analyticDetail.analyticName;
    this.readOnlyData['date'] =
      this.customMomentService.formatDate({
        epoch: this.analyticDetail.startDate,
      }) +
      ' - ' +
      this.customMomentService.formatDate({
        epoch: this.analyticDetail.endDate,
      });
    this.readOnlyData['parameter'] = [];
    this.readOnlyData['deviceId'] = [];

    const originalParameters = [...this.analyticDetail.parameter];

    if (!this.validateParameters()) {
      this.readOnlyData.parameter = ['-'];
    } else {
      if (this.analyticDetail.parameter.length > 0) {
        this.readOnlyData.parameter = this.analyticDetail.parameter.map(
          (param: string) => DeviceUtil.getFieldName(param, this.fields)
        );
      } else {
        this.readOnlyData.parameter = ['-'];
      }
    }

    if (this.analyticDetail.analyticType === 5) {
      let param = this.readOnlyData.parameter[0];
      let label;
      if (param !== '-') {
        if (this.analyticDetail.deviceId) {
          label = this.getUnit(this.analyticDetail.deviceId[0]);
        } else if (this.analyticDetail.devices) {
          label = this.getUnit(this.analyticDetail.devices[0]);
        }
        param = `${param} (${label})`;
        let paramArray = [param];
        this.readOnlyData.parameter = paramArray;
      }
    }

    this.analyticDetail.deviceId.map((device: any) => {
      return this.readOnlyData.deviceId.push(
        this.analyticsService.getDeviceNameByDeviceId(device)
      );
    });

    this.analyticDetail.parameter = originalParameters;
  }

  toggleChartView() {
    this.isDefaultChartView = !this.isDefaultChartView;
    this.setUpDataPoints();
  }

  formSubscriptions() {
    let chart = this.form.get('chartType')?.valueChanges.subscribe((res) => {
      this.graphLoaded = false;
      this.analyticDetail.chartType = res;
      this.chartOptions!.chart!.type = res.value;
      this.chartOptions = { ...this.chartOptions };
      this.cdr.detectChanges();
      this.graphLoaded = true;
    })!;

    if (chart !== undefined) {
      this.subscriptions.push(chart);
    }

    let direction = this.form
      .get('directionType')
      ?.valueChanges.subscribe((res) => {
        this.graphLoaded = false;
        this.cdr.detectChanges();
        this.isDirectionFromRoseChart = res.value;
        this.generateDataPointsForWindRoseChart(
          this.apiDataForRoseChart,
          this.isDirectionFromRoseChart
        );
        this.setUpDataPointsForWindRoseChart(this.dataForRoseChart);
        this.graphLoaded = true;
      })!;

    if (direction !== undefined) {
      this.subscriptions.push(direction);
    }

    this.avgSubscription();
  }

  avgSubscription() {
    this.averageSubscription = this.form
      .get('average')
      ?.valueChanges.subscribe((res) => {
        this.analyticDetail.average = res;
        if (this.analyticDetail.deviceId) {
          this.getParamCompData(
            this.generatePayload(),
            this.analyticDetail.deviceId
          );
        } else if (this.analyticDetail.devices) {
          this.getParamCompData(
            this.generatePayload(true),
            this.analyticDetail.devices
          );
        }
      })!;
  }

  onThemeChange() {
    let theme = this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        try {
          this.show = false;
          //to update the config of reference line
          this.chartOptions.yAxis = this.getYaxisOptions(
            this.chartOptions.yAxis as Highcharts.YAxisOptions
          );
          setTimeout(() => {
            this.show = true;
          });
        } catch (e) {}
      });
    this.subscriptions.push(theme);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription: Subscription) =>
        !subscription.closed && subscription.unsubscribe()
    );
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.onResize.bind(this));
  }
}
