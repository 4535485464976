import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ReportData } from 'src/app/shared/models/reports/report-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { ReportFormComponent } from './components/report-form/report-form.component';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { Settings } from 'src/app/shared/models/settings/settings';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  public headerForm!: FormGroup;
  public currentDeviceType!: number;
  public deviceTypes!: Array<DeviceType>;
  public allReports!: ReportData.Get[];
  public devices!: DeviceDetails[];
  public subscriptions: Subscription[] = [];
  public migrateReportAndDisable: boolean = true;
  public dialogCompInstance!: ConfirmationPopupComponent;
  public userInfo: RequestData.UserInfo = this.commonService.getUser();

  constructor(
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private reportsService: ReportsService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.devices = this.deviceService.registeredDevices!;
    this.reportsService.triggerCurrentDeviceType(
      this.deviceTypes[0].deviceTypeId
    );
    this.addSubscriptions();
    this.buildForm();
    this.onDeviceTypeChange();
  }

  addReport() {
    this.formsService.openForm(ReportFormComponent, undefined, 'md');
  }

  getDeviceType(deviceType: DeviceType): string {
    return deviceType ? deviceType.label.trim() : '';
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  buildForm() {
    this.headerForm = this.formBuilder.group({
      deviceType: this.deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === this.currentDeviceType
      ),
    });
  }

  onDeviceTypeChange() {
    let change = this.headerForm
      .get('deviceType')
      ?.valueChanges.subscribe((res: DeviceType) => {
        if (res) {
          this.reportsService.triggerCurrentDeviceType(res.deviceTypeId);
        }
      })!;
    this.subscriptions.push(change);
  }

  addSubscriptions() {
    let reports = this.reportsService.isReportListAvailable$.subscribe(
      (res: boolean) => {
        if (res) {
          this.allReports = this.reportsService.reportList;
        }
      }
    );
    let deviceType = this.reportsService.currentDeviceType$.subscribe((res) => {
      this.currentDeviceType = res;
    });

    this.subscriptions.push(reports);
    this.subscriptions.push(deviceType);
  }

  openDialog(): void {
    const dynamicData = {
      title: 'Migrate Report',
      message:
        'By performing this action, your existing Automated Reports will be moved to Envizom 2.0',
      icon: 'trending_up',
      checkBoxTitle:
        'Disable Automated Reports in existing Envizom 1.0/Terminal',
      alertMessage:
        ' If you uncheck, you will consume twice the number of Emails',
      buttonconfig: [
        {
          text: 'MIGRATE',
          onClickCallback: () => {
            this.migrateReport();
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.dialogCompInstance.checkBoxTrigger.subscribe((res) => {
      this.migrateReportAndDisable = res;
    });
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  migrateReport() {
    let payload: ReportData.Migrate = {
      disableOldReports: this.migrateReportAndDisable,
    };

    const user = this.commonService.getUser();

    let settings: Settings = user.settings;
    settings = { ...settings, isReportMigrated: true };

    let userPayload: RequestData.UpdateUser = {
      update: {
        settings: settings,
      },
    };

    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.reportsService.migrateTerminalReports(payload).subscribe({
      next: (res) => {
        if (res) {
          // updating user profile to set 'isReportMigrated' key
          this.userService.updateProfile(userPayload).subscribe({
            next: (response) => {
              if (response) {
                //getting user profile to get updated data
                this.userService.getUserProfile().subscribe({
                  next: () => {
                    this.userInfo = this.commonService.getUser();
                  },
                  error: () => {
                    console.info('Error While Getting User Profile');
                  },
                });
              }
            },
            error: (err) => {
              console.info(err, 'Error While Updating User Profile');
            },
          });

          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Reports Migrated Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.reportsService.getReportDetails();
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info("Error:", err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Migrating Report. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
