import { Injectable ,EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, map, tap } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AverageHour } from '../models/average-hour';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDataResponse } from '../models/device/device-data';
import { DeviceDetails } from '../models/device/device-details';
import { Widget } from '../models/widget/widget';
import { CommonUtil } from '../utils/common-utils';
import { DeviceUtil } from '../utils/device-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class DataFlaggingService  extends BaseAPIService<DeviceDetails> {
  // private rowSelectedSubject = new Subject<any[]>();
  // rowSelected$ = this.rowSelectedSubject.asObservable();
  device!: DeviceDetails;
  rowSelected = new EventEmitter<any>();
  columnSelected = new EventEmitter<any>();
  selectedRows: any[] = [];
  public dataFlagForm = new BehaviorSubject<any>({});
  dataFlagForm$ = this.dataFlagForm.asObservable();

  public onHeaderTagChange = new Subject<any>();
  public onHeaderTagChange$ = this.onHeaderTagChange.asObservable();
  
  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
    //this.selectedRows = [];
  }

  fetchDataFlaggingData(deviceId: string,gte:any,lte:any): Observable<DeviceDetails[]> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const avgTime = '3600';
    const params: HttpParams = new HttpParams().appendAll({
      'deviceIds[]': deviceId,
      userId: userId,
      gte: gte,
      lte: lte,
      processType: 'raw',
      dataFlagging: true
    });

    return this.get<Array<DeviceDataResponse>>(
      APIConstants.DATA_FLAGGING_LIST,
      headers,
      params
    ).pipe(
      map((deviceDetailRes: Array<DeviceDataResponse>) => {
        let deviceDetails = deviceDetailRes[0].payload;
        // deviceDetails = this.updateDeviceDetails(deviceDetails);

        return deviceDetails;
      }),
      catchError((error) => {
        return [];
      })
    );
  }

  flagTheData(payload: any) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.post<Array<any>>(
      APIConstants.FLAG_THE_DATA(),
      payload,
      headers
    )
  }
  downloadFlagReport(deviceId : any,
    gte: number,
    lte: number,
    timezone: any,
    isTwelveHourFormat: boolean,
    xRequestLog: boolean
  ): Observable<any> {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    let params: HttpParams = new HttpParams().appendAll({
      deviceId: deviceId,
      gte: gte,
      lte: lte,
      timezone: timezone,
      userId: userId,
      // isTwelveHourFormat:isTwelveHourFormat,
      'x-request-log': xRequestLog
    });
    return this.get<Array<any>>(
      APIConstants.DOWNLOAD_DATAFLAG().replace('{deviceId}', deviceId),
      headers,
      params,
      'blob'
    ).pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    );
  }
}
