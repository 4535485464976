import { Component } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ConfigurationData } from 'src/app/shared/models/configuration/configuration-data';
import { LANFormComponent } from '../../componet/lan-form/LAN-form.component';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { DeviceService } from 'src/app/shared/services/device.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UnitsInternal } from 'src/app/shared/models/internal-use-front-end/units-internal';
import { filter, sortBy } from 'lodash';
@Component({
  selector: 'app-lan-list',
  templateUrl: './LAN-list.component.html',
  styleUrls: ['./LAN-list.component.scss'],
})
export class LANListComponent {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public allLANConfigData!: any;
  public configLANList!: ConfigurationData.Get;
  public devices: DeviceDetails[] = [];
  public displayedColumns: string[] = [];
  public isAdmin: boolean = false;
  public currentDeviceType!: number;
  public readonly currentUserEmail: string =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      .email;
  public dialogComponentInstanceForDeleteReport!: ConfirmationPopupComponent;
  public subscriptions: Subscription[] = [];
  public configLANToVisible: any;
  public updatedLANData!: any;
  allData: any;
  deviceTypes: any;
  defaultColumns: any[] = [];
  public allParamUnits!: UnitsInternal.DeviceTypeWiseUnitInfoForTable;
  public allUnits!: UnitsInternal.DeviceTypeAndUnits;
  public fieldUnits: UnitsInternal.FieldUnits = [];
  filteredFields: any;
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };
  moduleAccess: any;

  constructor(
    private formsService: FormsService,
    private localStorageService: LocalStorageService,
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private userService: UserService
  ) {
    this.allLANConfigData = [];
    this.defaultColumns = this.generateDefaultColumns();
  }

  ngOnInit(): void {
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.devices = this.deviceService.registeredDevices!;
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.configurationService.isConfigData$.subscribe((data: boolean) => {
      if (data) {
        this.allLANConfigData = this.configurationService.configurationData;
        if (this.allLANConfigData.config) {
          this.configLANToVisible = [];

          if (this.allLANConfigData.config.modbus) {
            if (Array.isArray(this.allLANConfigData.config.modbus)) {
              this.configLANToVisible.push(
                ...this.allLANConfigData.config.modbus.map((item: any) => ({
                  ...item,
                  label: 'Modbus',
                }))
              );
            } else {
              this.configLANToVisible.push({
                ...this.allLANConfigData.config.modbus,
                label: 'Modbus',
              });
            }
          }
          if (this.allLANConfigData.config.tcp) {
            if (Array.isArray(this.allLANConfigData.config.tcp)) {
              this.configLANToVisible.push(
                ...this.allLANConfigData.config.tcp.map((item: any) => ({
                  ...item,
                  label: 'TCP / MQTT',
                }))
              );
            } else {
              this.configLANToVisible.push({
                ...this.allLANConfigData.config.tcp,
                label: 'TCP / MQTT',
              });
            }
          }
          if (this.allLANConfigData.config.lora) {
            if (Array.isArray(this.allLANConfigData.config.lora)) {
              this.configLANToVisible.push(
                ...this.allLANConfigData.config.lora.map((item: any) => ({
                  ...item,
                  label: 'LoRa',
                }))
              );
            } else {
              this.configLANToVisible.push({
                ...this.allLANConfigData.config.lora,
                label: 'LoRa',
              });
            }
          }
          if (this.allLANConfigData.config.display) {
            if (Array.isArray(this.allLANConfigData.config.display)) {
              this.configLANToVisible.push(
                ...this.allLANConfigData.config.display.map((item: any) => ({
                  ...item,
                  label: 'Display',
                }))
              );
            } else {
              this.configLANToVisible.push({
                ...this.allLANConfigData.config.display,
                label: 'Display',
              });
            }
          }
          if (this.allLANConfigData.config.current) {
            if (Array.isArray(this.allLANConfigData.config.current)) {
              this.configLANToVisible.push(
                ...this.allLANConfigData.config.current.map((item: any) => ({
                  ...item,
                  label: '4-20 MA',
                }))
              );
            } else {
              this.configLANToVisible.push({
                ...this.allLANConfigData.config.current,
                label: '4-20 MA',
              });
            }
          }
          this.configurationService.currentDeviceType$.subscribe((data) => {
            this.currentDeviceType = data;
          });
          // this.loadData();
          this.addSubscriptions();
        } else {
          // this.loadData();
          this.addSubscriptions();
        }
      } else {
        this.loadTable.next(false);
      }
    });
    this.moduleAccess = this.commonService.moduleAccessibility(1017);
    this.defaultColumns = this.generateDefaultColumns();
  }

  addSubscriptions() {
    this.loadTable.next(false);
    let unit = this.userService.unitsForAllParameters$.subscribe((res: any) => {
      if (res !== undefined) {
        this.allParamUnits = res;
        // this.getFieldUnits();
        this.loadData();
      }
    });
  }

  loadData() {
    setTimeout(() => {
      this.loadTable.next(true);
      this.getFieldUnits();
    });
  }

  generateDefaultColumns() {
    return [
      {
        columnDef: 'actions',
        header: '',
        cell: (element: DeviceDetails) => `${element.deviceId}`,
        parameter: false,
        selected: false,
        icon: 'more_vert',
        options: [
          ...(this.commonService.getModuleAccessOptionsOnRoute(
            'edit',
            this.moduleAccess
          )
            ? [
                {
                  label: (item: ConfigurationData.Get) =>
                    `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
                  action: (lanData: ConfigurationData.Get) => {
                    let data = {
                      ...lanData,
                      paramData: this.filteredFields,
                    };
                    this.updateLANDetails(data);
                  },
                },
              ]
            : []),
        ],
      },
      {
        columnDef: 'label',
        header: 'Label',
        cell: (element: any) => `${element.label}`,
        parameter: false,
        selected: false,
      },
      {
        columnDef: 'lan-status',
        header: 'Status',
        cell: (element: any) => {
          let statusText = '';
          if (element?.en === 0) {
            statusText = 'Disabled';
          } else if (element?.en === 1) {
            statusText = 'Enabled';
          }
          return `${statusText}`;
        },
        color: (element: any) => {
          let statusClass = '';
          if (element?.en === 0) {
            statusClass = 'red';
          } else if (element?.en === 1) {
            statusClass = 'green';
          }
          return `${statusClass}`;
        },
        parameter: false,
        selected: false,
      },
    ];
  }
  updateLANDetails(data: any): void {
    this.formsService.openForm(LANFormComponent, data, 'md');
  }

  getConfigLANOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(key, this.moduleAccess);
  }

  getFieldUnits() {
    let deviceTypeId: any = DeviceUtil.getDeviceTypeIdByDeviceId(
      this.deviceTypes,
      this.devices,
      this.allLANConfigData.deviceId
    );
    this.allUnits = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;
    const allParams = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_PARAMS
    );
    const deviceTypes = this.commonService.getUserDeviceTypes();
    if (!allParams) {
      return;
    }
    const fieldUnits: { [key: string]: { [key: string]: any } } = {};
    deviceTypes?.forEach((deviceType) => {
      if (allParams[deviceType.deviceTypeId]) {
        allParams[deviceType.deviceTypeId]?.forEach((param: string) => {
          if (!fieldUnits[deviceType.deviceTypeId]) {
            fieldUnits[deviceType.deviceTypeId] = {};
          }
          if (!fieldUnits[deviceType.deviceTypeId][param]) {
            fieldUnits[deviceType.deviceTypeId][param] = sortBy(
              filter(this.allParamUnits[deviceType?.deviceTypeId], {
                key: param,
              }),
              ['label']
            );

            for (const parameter of fieldUnits[deviceType.deviceTypeId][
              param
            ]) {
              let param: string = parameter.key;
              let id: string = parameter.deviceTypeId;
              let limits = this.allUnits?.[id as any]?.[param as any];
              parameter.limit = limits?.['limit']?.length
                ? limits?.['limit']
                : null;
            }
          }
        });
      }
    });
    this.fieldUnits = fieldUnits?.[deviceTypeId];
    this.filteredFields = Object?.entries(this.fieldUnits).reduce(
      (acc: any, [key, value]) => {
        if (
          Array.isArray(value) &&
          value.length > 0 &&
          value[0]?.isVisible === true
        ) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
  }
}
