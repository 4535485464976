<app-terminal-page-layout [menu]="menu">
  <app-terminal-page-header [menu]="menu">
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-3 device-form-field">
        <mat-label>Device</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Device'"
          matInput
          formControlName="deviceSearch"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of devices
                  | deviceType : deviceType.key
                  | search : headerForm.get('deviceSearch')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
          class=""
          style="z-index: 0"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
      <div class="col-12 col-lg-auto d-flex align-items-center gap-2">
        <button
          class="refresh"
          mat-mini-fab
          color="primary"
          (click)="rebootDevice('restart')"
          matTooltip="Restart Service "
        >
          <mat-icon matSuffix class="material-symbols-outlined"
            >rule_settings</mat-icon
          >
        </button>
        <button
          class="refresh"
          mat-mini-fab
          color="primary"
          (click)="rebootDevice('reboot')"
          matTooltip="Reboot Device "
        >
          <mat-icon matSuffix class="material-symbols-outlined"
            >settings_backup_restore</mat-icon
          >
        </button>
      </div>
      <div class="col col-lg-auto col-12 col-lg-3">
        <button
          matTooltip="Go to On Device Software"
          (click)="goToOnDeviceSoftware()"
          mat-raised-button
          color="primary"
          class="w-100 w-lg-auto"
        >
          On Device Software
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </app-terminal-page-body>
</app-terminal-page-layout>
