import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';

import * as Highcharts from 'highcharts';
import exportingOption from 'highcharts/modules/exporting';
import offlineOption from 'highcharts/modules/offline-exporting';
import { debounceTime, filter, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { sortList } from 'src/app/shared/utils/array-utils';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { HighchartUtils } from 'src/app/shared/utils/highchart-utils';
import { WidgetService } from '../../services/widget.service';
import { IWidgetComponent, WidgetInfo } from '../../widget.component.interface';
// require('highcharts/modules/exporting')(Highcharts);
// require('highcharts/modules/offline-exporting')(Highcharts);

exportingOption(Highcharts);
offlineOption(Highcharts);

@Component({
  selector: 'app-hourly-data-widget',
  templateUrl: './hourly-data-widget.component.html',
  styleUrls: ['./hourly-data-widget.component.scss'],
})
export class HourlyDataWidgetComponent
  implements IWidgetComponent, OnInit, OnChanges, OnDestroy
{
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this.chartType = v.chartTypeConfig?.selectedValue ?? 'column';
    this.show = v.show;
    this.autoRange = !(
      Boolean(
        v.optionsMenu?.options.find(
          (option) => option.value === 'static_y_axis'
        )?.state
      ) ?? true
    );
    this._widgetInfo = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  deviceInfo: DeviceDetails | undefined;

  private _deviceData: DeviceDetails[] = [];
  public get deviceData(): DeviceDetails[] {
    return this._deviceData;
  }
  public set deviceData(v: DeviceDetails[]) {
    this._deviceData = v;
    if (v) {
      this.setupConfig();
    }
  }

  fields: DeviceField[] = [];
  highChartsRef: any;
  private _limits: FieldLimit[] = [];
  public limitsObj: { [key: string]: FieldLimit } = {};
  public get limits(): FieldLimit[] {
    return this._limits;
  }
  public set limits(v: FieldLimit[]) {
    this._limits = v;
    this.limitsObj = Object.fromEntries([
      ...v.map((limit) => [limit.fkey, limit]),
    ]);
    this.setupConfig();
  }

  private _aqiIndexColor!: AqiIndexColorArray;
  public get aqiIndexColor(): AqiIndexColorArray {
    return this._aqiIndexColor;
  }
  public set aqiIndexColor(v: AqiIndexColorArray) {
    this._aqiIndexColor = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  autoRange: boolean = true;
  chartType: string = 'column';
  show = false;

  @Output() parameterSelectionChange: EventEmitter<string>;

  highcharts: typeof Highcharts = Highcharts;

  defaultColor: string = '#00c4b4';
  deviceFields: DeviceField[] = [];

  private _selectedField: DeviceField | undefined;
  public get selectedField(): DeviceField | undefined {
    return this._selectedField;
  }
  public set selectedField(v: DeviceField | undefined) {
    if (v) {
      this._selectedField = v;
      this.parameterSelectionChange.emit(v.label);
    }
  }

  chartOptions: Highcharts.Options = {};

  userTimeFormat: number;

  private _noHourlyData: boolean = true;
  @Output() graphDataEmpty: EventEmitter<Record<string, any>> =
    new EventEmitter<Record<string, any>>();
  get noHourlyData(): boolean {
    return this._noHourlyData;
  }
  set noHourlyData(value: boolean) {
    this.graphDataEmpty.emit({
      widgetId: this.widgetInfo.widgetId,
      disableStatus: value,
    });
    this._noHourlyData = value;
  }

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private widgetService: WidgetService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private _cdr: ChangeDetectorRef
  ) {
    this.parameterSelectionChange = new EventEmitter<string>();
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.deviceInfo = this.widgetService.device;
    this.deviceData = this.widgetService.hourlyDeviceData;

    this.fields = this.widgetService.fields;
    this.limits = this.widgetService.limits;
    this.aqiIndexColor = this.widgetService.aqiIndexColor;

    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'device': {
              this.deviceInfo = this.widgetService.device;
              break;
            }
            case 'hourlyDeviceData': {
              this.deviceData = this.widgetService.hourlyDeviceData;
              break;
            }
            case 'fields': {
              this.fields = this.widgetService.fields;
              break;
            }
            case 'limits': {
              this.limits = this.widgetService.limits;
              break;
            }
            case 'aqiIndexColor': {
              this.aqiIndexColor = this.widgetService.aqiIndexColor;
              break;
            }
            default: {
              // console.info('hourly-data-widget -> key for changed value:', res);
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );

    this.subscriptions.push(
      this.widgetService.redrawWidget$
        .pipe(
          filter((widgetId: number) => this.widgetInfo.widgetId === widgetId),
          debounceTime(300)
        )
        .subscribe({
          next: (widgetId: number) => {
            if (this.widgetInfo.widgetId === widgetId) {
              this.show = false;
              setTimeout(() => {
                this.show = true;
              });
            }
          },
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupConfig();
  }

  setupConfig() {
    if (this.deviceData?.length && this.limits?.length && this.aqiIndexColor) {
      this.noHourlyData = false;
      this.deviceFields = CommonUtil.getDeviceFields(
        this.deviceData,
        this.fields,
        this.limits
      ).filter((field) => field.fkey !== 't' && field.fkey !== 'aqiMessage');
      if (this.deviceFields.length) {
        if (
          !this.selectedField ||
          !this.deviceFields.find((df) => df.fkey == this.selectedField?.fkey)
        ) {
          this.selectedField = this.deviceFields[0];
        }
        this.setupDataPoints();
      }
    } else {
      this.noHourlyData = true;
    }
  }

  setupDataPoints() {
    this.show = false;

    let rangeIndex = this.limits.findIndex(
      (x: any) => this.selectedField?.fkey === x.fkey
    );

    const that = this;

    let xAxisOptions: Highcharts.XAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatTime({
            epochMS: parseInt('' + this.value),
            format: that.userTimeFormat === 12 ? 'hh:mm A' : 'HH:mm',
          });
        },
      },
    };

    let yAxisOptions = this.getYaxisOptions(rangeIndex);

    this.chartOptions.title = null!;
    this.chartOptions.subtitle = null!;
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.legend = { enabled: false };

    this.chartOptions.chart = {
      type: this.chartType,
      events: {
        load: function () {
          that.highChartsRef = this as Highcharts.Chart;
        },
      },
    };

    this.chartOptions.exporting = {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      filename: `${
        this.deviceInfo?.label ? `${this.deviceInfo?.label}_` : ''
      }hourly_data`,
      chartOptions: {
        title: {
          text: `${
            this.deviceInfo?.label ? `${this.deviceInfo?.label} device's ` : ''
          }Last 24 Hours Data`,
        },
        legend: {
          enabled: true,
        } as Highcharts.LegendOptions,
        yAxis: {
          title: {
            text: `${this.selectedField?.label}${
              this.selectedField?.unit?.length
                ? ` (${this.selectedField.unit})`
                : ''
            }`,
          },
        },
        xAxis: {
          title: {
            text: 'Time',
          },
        },
      },
    };

    const tooltipOptions: any = {
      shared: true,
      formatter: function () {
        return (
          that.customMomentService.formatDatetime({
            epochMS: parseInt('' + this.x),
            format:
              that.userTimeFormat === 12
                ? 'dddd, MMM DD, hh:mm A'
                : 'dddd, MMM DD, HH:mm',
          }) +
          '</br>' +
          `<span style="color: ${this.point.color}">\u25CF</span>` +
          '<b>' +
          this.series.name +
          '</b>' +
          ' : ' +
          this.point.realY
          // this.y
        );
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOptions;
    this.chartOptions.tooltip = tooltipOptions;

    let zones = [
      {
        color: this.defaultColor,
        value: this.limits[rangeIndex].lowestLimit,
      },
    ];

    let fieldLimits = this.limits?.[rangeIndex];
    if (fieldLimits?.range?.length) {
      for (let i = 0; i < fieldLimits.range.length; i++) {
        let color = this.aqiIndexColor.color?.[i] || this.defaultColor;
        let value = fieldLimits.range[i + 1];
        if (value && color?.length) {
          zones.push({
            value,
            color,
          });
        }
      }
      zones.push({
        color: this.aqiIndexColor.outOfRangeColor,
        value: Number.POSITIVE_INFINITY,
      });
    }

    this.chartOptions.series = [];
    let fieldName = this.selectedField?.label ?? 'AQI';
    let fieldUnit = this.selectedField?.unit ?? '';
    fieldUnit = fieldUnit.replaceAll(' ', '');
    this.chartOptions.series[0] = {
      name: fieldName + (fieldUnit ? ' (' + fieldUnit + ')' : ''),
      data: this.generateDataPoints(rangeIndex),
      zones,
    } as Highcharts.SeriesOptionsType;

    setTimeout(() => {
      this.show = true;
    });
  }

  generateDataPoints(rangeIndex: any) {
    let dataPoints = [];

    for (let data of this.deviceData) {
      let value: number = parseFloat(
        data?.payload?.d[this.selectedField?.fkey ?? 'aqi']
      );
      var y = value;

      //for static yAxis
      if (!this.autoRange) {
        let limitsExmp: any =
          this.getRangeAndTickObjectForNormalisation(rangeIndex);
        y = this.getNormalisedValue(limitsExmp, value);
      }

      let dataPoint = {
        x: +this.customMomentService.moment.unix(data?.payload?.d?.t),
        y: y,
        realY: value,
        color: '',
      };

      if (this.limits.length && this.limits[rangeIndex]) {
        let i: number;
        for (i = 0; i < this.limits[rangeIndex].range?.length; i++) {
          if (Math.min(value, this.limits[rangeIndex].range[i]) === value) {
            break;
          }
        }

        if (this.aqiIndexColor.limits.length > --i) {
          dataPoint.color = this.aqiIndexColor.color[i] || this.defaultColor;
        } else {
          dataPoint.color =
            this.aqiIndexColor.outOfRangeColor || this.defaultColor;
        }
      } else {
        dataPoint.color = this.defaultColor;
      }

      dataPoints.push(dataPoint);
    }

    dataPoints = sortList(dataPoints, 'ASC', 'x');
    return dataPoints;
  }

  getNormalisedValue(limits: any, val: number) {
    if (val <= limits[0].x) {
      let { x: x1, y: y1 } = limits[0];
      let { x: x2, y: y2 } = limits[1];
      return y1 + ((val - x1) / (x2 - x1)) * (y2 - y1);
    }

    for (let i = 0; i < limits.length - 1; i++) {
      let { x: x1, y: y1 } = limits[i];
      let { x: x2, y: y2 } = limits[i + 1];

      if (val >= x1 && val <= x2) {
        return y1 + ((val - x1) / (x2 - x1)) * (y2 - y1);
      }
    }

    let { x: x1, y: y1 } = limits[limits.length - 2];
    let { x: x2, y: y2 } = limits[limits.length - 1];
    return y1 + ((val - x1) / (x2 - x1)) * (y2 - y1);
  }

  getYaxisOptions(rangeIndex: any) {
    let yAxisOptions: Highcharts.AxisOptions = {
      title: { text: null },
      plotLines: [this.getYaxisReferenceLine(rangeIndex)],
    };

    let yAxisRange: number[] = [];
    if (!this.autoRange) {
      yAxisRange = this.getRangeForStaticYAxis(rangeIndex);
      yAxisOptions['tickPositions'] =
        this.getTickPositionForStaticYAxis(yAxisRange);

      let i = -1;
      yAxisOptions['labels'] = {
        formatter: function () {
          i++;
          if (i >= yAxisRange.length) {
            // Ensure we don't go out of bounds
            i = 0;
          }
          return String(yAxisRange[i]);
        },
      };
    }
    return yAxisOptions;
  }

  getYaxisReferenceLine(rangeIndex: number): Highcharts.XAxisPlotLinesOptions {
    const paramName = this.selectedField?.label;
    const paramUnit = this.selectedField?.unit;
    const paramKey = this.selectedField?.fkey;
    let value;
    let refLine: Highcharts.XAxisPlotLinesOptions = {};

    if (!paramKey) {
      return refLine;
    }

    value = this.getValueForRefLine(paramKey);

    if (value === undefined) {
      return refLine;
    }

    let normalizedValue = value;
    if (!this.autoRange) {
      normalizedValue = this.normalizeRefLineValue(value, rangeIndex);
    }

    const currentTheme = this.localStorageService.getValue(
      LocalStorageConstants.CURRENT_THEME
    );
    const labelText = `${paramName} 1 Hr : ${value}${paramUnit}`;

    refLine = HighchartUtils.getRefLineConfig({
      currentTheme,
      value: normalizedValue,
      labelText,
    });

    return refLine;
  }

  normalizeRefLineValue(value: number, rangeIndex: number): number {
    let limitsExmp: any =
      this.getRangeAndTickObjectForNormalisation(rangeIndex);
    let normalizedRefLineValue = this.getNormalisedValue(limitsExmp, value);
    return normalizedRefLineValue;
  }

  getRangeAndTickObjectForNormalisation(rangeIndex: number) {
    let range = this.getRangeForStaticYAxis(rangeIndex);
    let staticRange = this.getTickPositionForStaticYAxis(range);

    return range.map((_, i) => {
      return { x: range[i], y: staticRange[i] };
    });
  }

  getTickPositionForStaticYAxis(range: number[]) {
    let count = 0;
    return range.map((_) => count++);
  }

  getRangeForStaticYAxis(rangeIndex: any) {
    let range =
      this.limits[rangeIndex].range?.length > 0
        ? this.limits[rangeIndex].range!
        : [
            this.limits[rangeIndex].lowestLimit,
            this.limits[rangeIndex].highestLimit,
          ];

    const refLineValue = this.getValueForRefLine(
      this.selectedField?.fkey ?? ''
    );
    if (refLineValue !== undefined && refLineValue > range[range.length - 1]) {
      range.push(refLineValue);
    }

    return range;
  }

  getValueForRefLine(parmeterKey: string): undefined | number {
    const deviceTypes = this.commonService.getUserDeviceTypes();
    const devices = this.deviceService.registeredDevices;
    const deviceId = this.deviceData[0].deviceId;
    if (!devices?.length) {
      return undefined;
    }

    const selectedDeviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      deviceTypes,
      devices,
      deviceId
    );

    if (!selectedDeviceTypeId) {
      return undefined;
    }

    let allUnits = this.commonService.getAllUnits();
    let unitsOfSelectedDeviceType = allUnits[selectedDeviceTypeId];

    let selectedParam = unitsOfSelectedDeviceType[parmeterKey];

    if (!selectedParam?.options?.refLineLimits) return undefined;

    if (selectedParam?.options?.refLineLimits['hr'] !== null) {
      return selectedParam?.options?.refLineLimits['hr'];
    }

    return undefined;
  }

  changeField(field: DeviceField) {
    this.selectedField = field;
    this.setupDataPoints();
  }

  scrollLeft() {
    let element = document.getElementById(
      'widget-fields-chip-container-hourly-data'
    );
    element?.scrollTo({ left: element.scrollLeft - element.clientWidth / 1.8 });
  }

  scrollRight() {
    let element = document.getElementById(
      'widget-fields-chip-container-hourly-data'
    );
    element?.scrollTo({ left: element.scrollLeft + element.clientWidth / 1.8 });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
