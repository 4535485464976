import { Pipe, PipeTransform } from '@angular/core';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { ReportData } from '../models/reports/report-data';
import { CommonService } from '../services/common.service';
import { DeviceService } from '../services/device.service';

@Pipe({
  name: 'deviceTypeWiseReport',
})
export class DeviceTypeWiseReportPipe implements PipeTransform {
  constructor(
    private deviceService: DeviceService,
    private commonService: CommonService
  ) {}
  transform(
    reports: Array<ReportData.Get>,
    deviceType: number
  ): Array<ReportData.Get> | [] {
    let allDevicesOfUser = this.deviceService.registeredDevices!;
    let allDeviceTypesOfUser: DeviceType[] =
      this.commonService.getUserDeviceTypes();
    let currentDeviceTypeKey = allDeviceTypesOfUser.find(
      (device: DeviceType) => device.deviceTypeId === deviceType
    )?.key;
    let tempSet = new Set();
    reports.forEach((data: ReportData.Get) => {
      tempSet.add(data.deviceId);
    });
    let arrayOfUniqueDeviceIdInReport = Array.from(tempSet);
    allDevicesOfUser = allDevicesOfUser.filter(
      (data: DeviceDetails) =>
        arrayOfUniqueDeviceIdInReport.includes(data.deviceId) &&
        currentDeviceTypeKey === data.deviceType
    );
    let reportsOfCurrentDeviceType = reports.filter(
      (report: ReportData.Get) => {
        return (
          allDevicesOfUser.find((device) => device.deviceId === report.deviceId)
            ?.deviceType === currentDeviceTypeKey
        );
      }
    );
    return reportsOfCurrentDeviceType;
  }
}
