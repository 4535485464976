import { NgModule } from '@angular/core';
import { MtxDrawerModule } from '@ng-matero/extensions/drawer';

const MODULES = [MtxDrawerModule];

@NgModule({
  declarations: [],
  imports: [...MODULES],
  exports: [...MODULES],
  providers: [],
})
export class NgMateroModule {}
