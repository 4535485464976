import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutomationRoutingModule } from './automation-routing.module';
import { AutomationComponent } from './automation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutomationListComponent } from './pages/automation-list/automation-list.component';
import { AutomationFormComponent } from './components/automation-form/automation-form.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    AutomationComponent,
    AutomationListComponent,
    AutomationFormComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    CommonModule,
    AutomationRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatRadioModule,
  ],
})
export class AutomationModule {}
