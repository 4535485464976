import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeviceFormComponent } from './components/device-form/device-form.component';
import { IntervalFormComponent } from './components/interval-form/interval-form.component';
import { SensorInfoComponent } from './components/sensor-info-form/sensor-info.component';
import { DevicesRoutingModule } from './devices-routing.module';
import { DevicesComponent } from './devices.component';
import { DeviceListComponent } from './pages/device-list/device-list.component';

@NgModule({
  declarations: [
    DeviceListComponent,
    DevicesComponent,
    DeviceFormComponent,
    IntervalFormComponent,
    SensorInfoComponent,
  ],
  imports: [
    CommonModule,
    DevicesRoutingModule,
    TranslateModule.forChild({}),
    SharedModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    GoogleMapsModule,
    MatStepperModule,
  ],
})
export class DevicesModule {}
