import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CONFIGURATION_MENU } from 'src/app/shared/constants/app-menu';
import { AutomationData } from 'src/app/shared/models/automation/automation-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { AutomationService } from 'src/app/shared/services/automation.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { OverviewService } from 'src/app/shared/services/overview.service';
import { ConfigurationData } from 'src/app/shared/models/configuration/configuration-data';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Unit } from 'src/app/shared/models/units/unit';
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent {
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter();
  private deviceSearchSubscription!: Subscription;
  private subscriptions: Subscription[] = [];
  deviceTypes!: Array<DeviceType>;
  menu: SubMenu[] = CONFIGURATION_MENU;
  headerForm!: FormGroup;
  public currentActiveDevice!: DeviceDetails;
  public configData!: ConfigurationData.Get;
  public isAddConfigButtonHidden: boolean = false;
  public devices: DeviceDetails[] = this.deviceService.registeredDevices ?? [];
  public deviceTags: string[] = this.deviceService.getUniqueDeviceTags(this.devices);

  constructor(
    private commonService: CommonService,
    public router: Router,
    private fb: FormBuilder,
    public deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private overviewService: OverviewService,
    private customMomentService: CustomMomentService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (
      this.deviceService.currentDevice?.deviceType === 'BEANAIR' ||
      this.deviceService.currentDevice?.deviceType === 'OMNIDOTS'
    ) {
      this.deviceService.removeDeviceGlobally();
    }
    this.devices = [...this.deviceService.registeredDevices!];
    this.deviceTypes = this.commonService
      .getUserDeviceTypes()
      .filter(
        (deviceType) =>
          deviceType.key !== 'BEANAIR' && deviceType.key !== 'OMNIDOTS'
      );

    this.currentActiveDevice = this.deviceService?.currentDevice
      ? this.deviceService?.currentDevice
      : this.devices[0];
    this.configurationService.getConfigurationData(
      this.currentActiveDevice.deviceId
    );
    this.headerForm = this.fb.group({
      deviceSearch: [this.currentActiveDevice || ''],
    });
    this.addSubscriptions();
    this.fetchAllParamUnits();
  }

   // Sets the selected device globally when the user clicks on a device in the header dropdown
   onDeviceSelectionInConfiguration(device: DeviceDetails) {
    // this.headerForm.get('device')?.setValue(this.deviceService?.currentDevice)
    if (device && device?.deviceId) {
      this.deviceService.setupDeviceIdGlobally(device?.deviceId);
      this.headerForm.get('deviceSearch')?.setValue(device);
    }
  }

  // Emits the updated device list when the user selects or deselects tags from the header dropdown
  onTagSelection(devices: DeviceDetails[]) {
    this.configurationService.onHeaderTagChange.next(devices);
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }
  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  onActivate(component: any): void {
    if (component.refreshEvent) {
      component.refreshEvent = this.refreshEvent;
    }
  }

  onChangeDeviceId() {
    this.deviceSearchSubscription = this.headerForm
      .get('deviceSearch')
      ?.valueChanges.subscribe((device) => {
        this.configurationService.getConfigurationData(device.deviceId);
      })!;
    this.subscriptions.push(this.deviceSearchSubscription);
  }

  addSubscriptions(): void {
    const isConfig = this.configurationService.isConfigData$.subscribe(
      (res) => {
        if (res === true) {
          this.configData = this.configurationService.configurationData;
        }
      }
    );
    this.subscriptions.push(isConfig);
    this.onChangeDeviceId();
  }

  rebootDevice(value: string) {
    this.configurationService.isConfigData.next(false);
    const template = value == 'reboot' ? 'full-reboot' : 'gateway-restart';
    const selected_deviceID =
      this.headerForm.get('deviceSearch')?.value.deviceId;
    this.configurationService
      .rebootDevice(selected_deviceID, template)
      ?.subscribe({
        next: (res) => {
          this.configurationService.isConfigData.next(true);
          if (res) {
            this.configurationService.getConfigurationData(selected_deviceID);
            this.notificationService.showSnackBar(res, 'success');
          }
        },
        error: (err) => {
          this.configurationService.isConfigData.next(true);
          this.notificationService.showSnackBar(err, 'error');
          console.info(err.msg);
        },
      });
  }

  goToOnDeviceSoftware() {
    this.configurationService.isConfigData.next(false);
    const selected_deviceID =
      this.headerForm.get('deviceSearch')?.value.deviceId;
    this.configurationService.goToDeviceTerminal(selected_deviceID).subscribe({
      next: (res: any) => {
        this.configurationService.isConfigData.next(true);
        if(res) {
          const terminalUrl = res?.terminal;
          if (terminalUrl) {
            window.open(terminalUrl, '_blank');
          }
        }
      },
      error: (err) => {
        this.configurationService.isConfigData.next(true);
        this.notificationService.showSnackBar(err, 'error');
      }
    });
  }

  fetchAllParamUnits() {
    let deviceTypes: DeviceType[] = this.commonService.getUserDeviceTypes();
    const allParams = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_PARAMS
    );
    const allAqis = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).aqiIndex;

    let finalPayload: Unit.Post = {
      keys: deviceTypes.map((deviceType) => {
        let deviceTypeId = deviceType.deviceTypeId;
        let payloadItem: any = {};
        if (allParams?.[deviceTypeId]) {
          payloadItem['unitsKeys'] = allParams[deviceTypeId];
        }
        if (allAqis?.[deviceTypeId]?.id) {
          payloadItem['aqiIndex'] =
            allAqis[deviceTypeId].id || allAqis[deviceTypeId].aqiIndexId;
        }
        return { ...payloadItem, deviceTypeId };
      }),
    };
    this.userService.getUnitsForAllParameters(finalPayload, deviceTypes);
  }
}
