<nb-layout windowMode>
  <nb-layout-column class="px-0 py-0">
    <div class="row mx-0 h-100">
      <div class="col-12 col-lg-6 about-envizom">
        <div
          class="d-flex flex-column h-100 justify-content-center position-relative"
        >
          <div class="logo-container mb-5 w-100 position-absolute top-0">
            <img
              class="logo my-2"
              src="./../../../assets/envizom-logo.png"
              alt="envizom"
            />
          </div>
          <!-- <div class="col-12 mt-4 mb-5 align-self-center"> -->
          <div class="w-100">
            <div class="py-5 text-center">
              <div class="preview-screen pb-lg-5">
                <div class="current-module">
                  <ng-container
                    *ngFor="let module of modules; let index = index"
                  >
                    <ng-container *ngIf="true">
                      <img
                        class="current-preview-icon fade show"
                        [src]="module.icon"
                        [ngClass]="{
                          show:
                            index == modules.length - currentIndex ||
                            (index == 0 && currentIndex == 0)
                        }"
                      />
                    </ng-container>
                  </ng-container>
                </div>
                <div id="earth-container" class="earth-container">
                  <div class="earth-image-container">
                    <ng-container
                      *ngFor="let module of modules; let index = index"
                    >
                      <div
                        class="preview-icons-container"
                        [style.transform]="
                          'rotate(' + (360 * index) / modules.length + 'deg)'
                        "
                      >
                        <img
                          class="preview-icon"
                          [src]="module.icon"
                          [style.opacity]="
                            index == modules.length - currentIndex ||
                            (index == 0 && currentIndex == 0)
                              ? 0
                              : 1
                          "
                        />
                        <h5
                          class="preview-text"
                          [style.opacity]="
                            index == modules.length - currentIndex ||
                            (index == 0 && currentIndex == 0)
                              ? 1
                              : 0
                          "
                        >
                          {{ module.name }}
                        </h5>
                      </div>
                    </ng-container>
                    <img
                      class="earth"
                      src="./../../../assets/preview-icons/earth.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <router-outlet></router-outlet>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
