import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationPopupService {
  constructor() {}

  getDefaultDialogConfig() {
    const defaultDialogConfig: MatDialogConfig = {
      // disableClose: true, // Disable closing by clicking outside
      // autoFocus: true, // Automatically focus on the first input
      // panelClass: 'default-dialog-panel', // Apply a default panel class
      // backdropClass: 'default-backdrop', // Apply a default backdrop class
      backdropClass: 'backDrop',
      disableClose: true,
    };

    // Setting static properties for different screen sizes
    if (window.innerWidth >= 1024) {
      defaultDialogConfig.width = '30%';
    } else if (window.innerWidth >= 768) {
      defaultDialogConfig.width = '50%';
    } else if (window.innerWidth >= 576) {
      defaultDialogConfig.width = '60%';
    } else {
      defaultDialogConfig.width = '100%';
    }
    return defaultDialogConfig;
  }

  getDialogRef(dialogRef: any) {
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
      return result;
    });
  }
}
