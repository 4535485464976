<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <app-data-table
    tableTitle="Hourly Cluster Data"
    [data]="clusters"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    (onDownload)="exportTableDataToCSV()"
    [noDataViewConfig]="noData"
    [isChartVisible]="false"
  >
  </app-data-table>
</div>
