import { FormGroup } from '@angular/forms';
import { SelectionTile } from '../models/internal-use-front-end/selection-tile';

export interface ScreenTypeOption extends SelectionTile {
  id: number;
  title: string;
  deviceTypes: number[] | undefined;
  showAQIIndex: boolean;
  isActive: boolean;
  formGroup?: FormGroup;
}

export class DisplayConstants {
  public static SCREEN_TYPES: ScreenTypeOption[] = [
    {
      id: 0,
      title: 'Average AQI Screen',
      deviceTypes: [1001, 1004, 1006, 1017, 1019],
      showAQIIndex: true,
      isActive: true,
      imageUrl: '/assets/images/display/logo/avg_aqi_screen.png',
    },
    {
      id: 1,
      title: 'Parameter Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1012, 1017, 1018, 1019],
      showAQIIndex: true,
      isActive: true,
      imageUrl: '/assets/images/display/logo/parameter_screen.png',
    },
    {
      id: 2,
      title: 'AQI Comparison Screen',
      deviceTypes: [1001, 1004, 1006, 1017, 1019],
      showAQIIndex: true,
      isActive: true,
      imageUrl: '/assets/images/display/logo/aqi_comparison_screen.png',
    },
    {
      id: 3,
      title: 'Cluster Comparison Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1012, 1017, 1018, 1019],
      showAQIIndex: false,
      isActive: true,
      imageUrl: '/assets/images/display/logo/cluster_comparison_screen.png',
    },
    {
      id: 4,
      title: 'Data Table Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1012, 1017, 1018, 1019],
      showAQIIndex: false,
      isActive: true,
      imageUrl: '/assets/images/display/logo/data_table_screen.png',
    },
    {
      id: 5,
      title: 'Logo Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1007, 1012, 1015, 1016, 1017, 1018, 1019],
      showAQIIndex: false,
      isActive: true,
      imageUrl: '/assets/images/display/logo/logo_screen.png',
    },
    {
      id: 6,
      title: 'Bar Chart Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1012, 1017, 1018, 1019],
      showAQIIndex: false,
      isActive: true,
      imageUrl: '/assets/images/display/logo/bar_chart_screen.png',
    },
    {
      id: 7,
      title: 'Map View Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1012, 1017, 1018, 1019],
      showAQIIndex: false,
      isActive: true,
      imageUrl: '/assets/images/display/logo/map_view_screen.png',
    },
    {
      id: 8,
      title: 'Information Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1007, 1012, 1015, 1016, 1017, 1018, 1019],
      showAQIIndex: false,
      isActive: true,
      imageUrl: '/assets/images/display/logo/info_screen.png',
    },
    {
      id: 9,
      title: 'Mobile Parameter Screen',
      deviceTypes: [1001, 1002, 1003, 1004, 1006, 1012, 1017, 1018, 1019],
      showAQIIndex: true,
      isActive: true,
      imageUrl: '/assets/images/display/logo/mobile_parameter_screen.png',
    },
  ];
}
