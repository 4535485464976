import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ForgotPassword } from 'src/app/shared/models/forgot-password/forgot-password';
import { LabelConfig } from 'src/app/shared/models/internal-use-front-end/label-config';
import { LoginResponse } from 'src/app/shared/models/login-response';
import { Module } from 'src/app/shared/models/module';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { MqttSocketService } from 'src/app/shared/services/mqtt-socket.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { TermsAndConditionsComponent } from '../modal/terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loadTable: Subject<boolean> = new BehaviorSubject(true);
  loginForm!: FormGroup;
  termsAndConditions: boolean = false;
  isCaptchaVerified: boolean = false;
  captchaValue?: string;
  loader: boolean = false;
  hide: boolean = false;
  public currentView: string = 'login';
  public labelConfig: LabelConfig = {
    title: 'Welcome Back!',
    subTitle: 'Log In to your account to continue',
    buttonText: 'LOG IN',
    buttonTooltip: 'Login',
  };
  public isLinkSent: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private mqttSocketService: MqttSocketService,
    private deviceService: DeviceService,
    private cookieService: CookieService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    private router: Router,
    private customMomentService: CustomMomentService,
    private route: ActivatedRoute,
    private loadrService: LoadrService
  ) {}

  ngOnInit(): void {
    let params = this.route.snapshot.params;
    const token = params['token'];
    if (token) {
      this.loginService.logout(() => this.login(token));
    } else {
      this.validateLogin();
    }
    this.loginForm = this.fb.group({
      emailId: ['', Validators.required],
      password: ['', Validators.required],
      termsAndConditions: [false],
    });
    this.subscribeControls();
  }

  subscribeControls() {
    this.loginForm.controls.termsAndConditions.valueChanges.subscribe((val) => {
      this.termsAndConditions = val;
    });
  }

  openTermsAndConditionModal(): void {
    // Setting static properties for different screen sizes
    const defaultDialogConfig: MatDialogConfig = {
      data: {},
      width: '50%',
      height: '75%',
    };

    // adjust width according to screen size
    if (window.innerWidth >= 1024) {
      defaultDialogConfig.width = '30%';
    } else if (window.innerWidth >= 768) {
      defaultDialogConfig.width = '60%';
    } else if (window.innerWidth >= 576) {
      defaultDialogConfig.width = '70%';
    } else {
      defaultDialogConfig.width = '100%';
    }

    const dialogRef = this.dialog.open(
      TermsAndConditionsComponent,
      defaultDialogConfig
    );

    dialogRef.afterClosed().subscribe((agreed) => {
      this.termsAndConditions = agreed;
      this.loginForm.controls.termsAndConditions.setValue(agreed);
    });
  }

  getLoginObs() {
    let username = this.loginForm.controls.emailId.value;
    let password = this.loginForm.controls.password.value;

    if (
      this.localStorageService.getParsedValue(
        LocalStorageConstants.SHOWCAPTCHA
      ) &&
      !this.isCaptchaVerified
    ) {
      this.notificationService.showNotification(
        AppConstants.UNSOLVED_CAPTCHA_MESSAGE,
        AppConstants.CLOSE,
        'top',
        'center',
        'error'
      );
      return;
    }

    if (
      this.localStorageService.getParsedValue(LocalStorageConstants.ISSECURE)
    ) {
      password = this.loginService.getEncryptedPassword(
        password,
        this.localStorageService.getValue(LocalStorageConstants.ENCRYPTION_KEY)
      );
    }
    this.loader = true;
    return this.loginService.login(
      username,
      password,
      this.localStorageService.getParsedValue(LocalStorageConstants.ISSECURE),
      this.captchaValue
    );
  }

  getLoginTokenObs(token: string) {
    this.loadTable.next(false);
    this.loader = true;
    return this.loginService.loginUsingToken(token);
  }

  submit() {
    if (this.currentView === 'login') this.login();
    else this.forgotPassword();
  }

  forgotPassword() {
    this.loadTable.next(false);
    let payload: ForgotPassword = {
      email: this.loginForm.value.emailId,
      baseUrl: window.location.origin,
    };

    this.loginService.forgotPassword(payload).subscribe({
      next: () => {
        this.loadTable.next(true);
        this.isLinkSent = true;
      },
      error: (err) => {
        this.loadTable.next(true);
        this.notificationService.showSnackBar(
          'Error while sending email. Try again',
          'error'
        );
        console.info("Error:", err);
      },
    });
  }

  login(token?: string): void {
    this.loadTable.next(false);
    let loginObs;
    if (token?.length) {
      loginObs = this.getLoginTokenObs(token);
    } else {
      loginObs = this.getLoginObs();
    }
    if (loginObs) {
      loginObs
        .pipe(
          map((response) => {
            this.customMomentService.timezone =
              response?.settings?.timezone?.name ??
              CommonUtil.getDefaultTimezone();
            this.customMomentService.userTimeFormat =
              response?.settings?.time_format ?? 24;
            this.localStorageService.setupCredentials(response);
            const modifiedResponse: LoginResponse = {
              status: 200,
              token: response?.token?.access_token
                ? response?.token?.access_token
                : undefined,
              userId: response.userId ? response.userId : undefined,
            };
            return modifiedResponse;
          })
        )
        .subscribe({
          next: (response) => {
            this.loginService
              .getModules(
                this.localStorageService.getValue(LocalStorageConstants.TOKEN)
              )
              .subscribe({
                next: (modules) => {
                  this.localStorageService.saveValue(
                    LocalStorageConstants.MODULES,
                    JSON.stringify(modules)
                  );
                  this.validateLogin();
                  const userLoggedIn = localStorage?.getItem(
                    LocalStorageConstants.USER_ID
                  )
                    ? true
                    : false;
                  const moduleAccess =
                    JSON.parse(
                      this.localStorageService.getValue(
                        LocalStorageConstants.MODULE_ACCESS
                      )
                    ) &&
                    JSON.parse(
                      this.localStorageService.getValue(
                        LocalStorageConstants.MODULE_ACCESS
                      )
                    ).includes(1009)
                      ? true
                      : false;
                  (window as any).userLoggedIn = userLoggedIn;
                  (window as any).moduleAccess = moduleAccess;
                  (window as any).initializeIntercom();
                },
                error: (err) => {
                  this.loader = false;
                  this.loadTable.next(true);
                  console.info("Error:", err);
                  this.router.navigate(['/login']);
                },
              });
          },
          error: (err) => {
            console.info("Error:", err);
            this.loader = false;
            this.loadTable.next(true);
            this.notificationService.showNotification(
              'Failed to login',
              AppConstants.CLOSE,
              'bottom',
              'center',
              'error'
            );
          },
        });
    } else {
      this.loadTable.next(true);
    }
  }

  validateLogin(): void {
    let isTokenExpired =
      this.localStorageService.getParsedValue(
        LocalStorageConstants.TOKEN_EXPIRES_IN
      ) > this.customMomentService.moment().unix()
        ? false
        : true;
    if (
      this.localStorageService.getValue(LocalStorageConstants.TOKEN) &&
      !isTokenExpired &&
      this.localStorageService.getValue(LocalStorageConstants.OZ_USER)
    ) {
      if (
        this.localStorageService.getValue(
          LocalStorageConstants.MODULE_ACCESS
        ) !== null &&
        this.localStorageService.getValue(LocalStorageConstants.MODULES) !==
          null
      ) {
        const moduleArr = this.localStorageService.getParsedValue(
          LocalStorageConstants.MODULE_ACCESS
        );
        const allModules: Module[] = this.localStorageService.getParsedValue(
          LocalStorageConstants.MODULES
        );

        if (moduleArr.indexOf(1001) >= 0) {
          this.router.navigate(['/overview']);
        } else {
          allModules.forEach((module) => {
            if (module.moduleId === moduleArr[0]) {
              this.router.navigate([module.path.substring(1)]);
            }
          });
        }
      } else {
        this.loader = false;
        this.router.navigate(['/login']);
      }
    } else {
      this.loader = false;
      this.router.navigate(['/login']);
    }
  }

  //when user toggles between login and forgot password view
  toggleLoginAndForgotPswd() {
    if (this.currentView === 'login') {
      this.labelConfig = {
        title: 'Forgot your password?',
        subTitle:
          'Enter the email adrdress associated with your account and we will send you the link to reset your password.',
        buttonText: 'SEND EMAIL',
        buttonTooltip: 'Send Email',
      };
      this.loginForm.removeControl('password');
      this.loginForm.removeControl('termsAndConditions');
      this.termsAndConditions = true;
      this.currentView = 'forgotPswd';
    } else {
      this.labelConfig = {
        title: 'Welcome Back!',
        subTitle: 'Log In to your account to continue',
        buttonText: 'LOG IN',
        buttonTooltip: 'Login',
      };
      this.loginForm.addControl(
        'password',
        new FormControl('', Validators.required)
      );
      this.loginForm.addControl('termsAndConditions', new FormControl(false));
      this.subscribeControls();
      this.termsAndConditions = false;
      this.currentView = 'login';
      this.isLinkSent = false;
    }
  }
}
