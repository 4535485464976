<app-form-container
  [tableTitle]="'Update ' + data?.label"
  [submitBtn]="'Update'"
  (submit)="generatePayload()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [cancelBtn]="'Cancel'"
  [isValid]="configurationForm.valid"
>
  <ng-container>
    <form class="row gy-4" [formGroup]="configurationForm">
      <ng-container *ngIf="data.label == 'Modbus'">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Baudrate</mat-label>
            <mat-select formControlName="baudrate">
              <mat-option
                *ngFor="let baudrate of baudrateList"
                [value]="baudrate"
              >
                {{ baudrate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Mode</mat-label>
            <mat-select formControlName="modbusMode">
              <mat-option
                *ngFor="let mode of modbus_mode"
                [value]="mode?.value"
              >
                {{ mode?.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Parity</mat-label>
            <mat-select formControlName="parity">
              <mat-option
                *ngFor="let parity of parity_list"
                [value]="parity?.value"
              >
                {{ parity?.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Slave ID</mat-label>
            <input type="number" matInput formControlName="slaveid" required />
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="data.label == 'LoRa'">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>devEUI</mat-label>
            <input
              matInput
              formControlName="devEUI"
              maxlength="23"
              (input)="onDevEUIHexInput($event)"
              [value]="formattedHexValue"
              (blur)="onBlur('devEUI')"
            />
          </mat-form-field>
          <small>
            <mat-hint align="start"
              >Allowed: Hexadecimal characters (A-F, 0-9), exactly 16 characters
              required.</mat-hint
            >
            <mat-error
              *ngIf="configurationForm.get('devEUI')?.hasError('pattern')"
            >
              Invalid input! Must be 16 hexadecimal characters (A-F, 0-9).
            </mat-error>
            <mat-error
              *ngIf="configurationForm.get('devEUI')?.hasError('minlength')"
            >
              Exactly 16 characters required.
            </mat-error>
          </small>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Device Address</mat-label>
            <input
              matInput
              formControlName="deviceAddress"
              maxlength="11"
              (input)="deviceAddHexInput($event)"
              [value]="formattedDeviceAdd"
              (blur)="onBlur('deviceAddress')"
            />
          </mat-form-field>
          <small>
            <mat-hint align="start"
              >Allowed: Hexadecimal characters (A-F, 0-9), exactly 8 characters
              required.</mat-hint
            >
            <mat-error
              *ngIf="
                configurationForm.get('deviceAddress')?.hasError('pattern')
              "
            >
              Invalid input! Must be 8 hexadecimal characters (A-F, 0-9).
            </mat-error>
            <mat-error
              *ngIf="
                configurationForm.get('deviceAddress')?.hasError('minlength')
              "
            >
              Exactly 8 characters required.
            </mat-error>
          </small>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Network key</mat-label>
            <input
              matInput
              formControlName="networkSessionKey"
              (input)="networkKeyHexInput($event)"
              [value]="formattedNetworkKey"
              maxlength="47"
              (blur)="onBlur('networkSessionKey')"
            />
          </mat-form-field>
          <small>
            <mat-hint align="start"
              >Allowed: Hexadecimal characters (A-F, 0-9), exactly 32 characters
              required.</mat-hint
            >
            <mat-error
              *ngIf="
                configurationForm.get('networkSessionKey')?.hasError('pattern')
              "
            >
              Invalid input! Must be 32 hexadecimal characters (A-F, 0-9).
            </mat-error>
            <mat-error
              *ngIf="
                configurationForm
                  .get('networkSessionKey')
                  ?.hasError('minlength')
              "
            >
              Exactly 32 characters required.
            </mat-error>
          </small>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>App key</mat-label>
            <input
              matInput
              formControlName="appSessionKey"
              maxlength="47"
              (input)="appKeyHexInput($event)"
              [value]="formattedAppKey"
              (blur)="onBlur('appSessionKey')"
            />
          </mat-form-field>
          <small>
            <mat-hint align="start"
              >Allowed: Hexadecimal characters (A-F, 0-9), exactly 32 characters
              required.</mat-hint
            >
            <mat-error
              *ngIf="
                configurationForm.get('appSessionKey')?.hasError('pattern')
              "
            >
              Invalid input! Must be 32 hexadecimal characters (A-F, 0-9).
            </mat-error>
            <mat-error
              *ngIf="
                configurationForm.get('appSessionKey')?.hasError('minlength')
              "
            >
              Exactly 32 characters required.
            </mat-error>
          </small>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Class</mat-label>
            <mat-select formControlName="loraClass">
              <mat-option
                *ngFor="let class of class_list"
                [value]="class.value"
              >
                {{ class.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Network Mode</mat-label>
            <mat-select formControlName="loraMode">
              <mat-option *ngFor="let nw of network_list" [value]="nw.value">
                {{ nw?.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container class="col-12" *ngIf="data.label == 'TCP / MQTT'">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Mode</mat-label>
            <mat-select formControlName="tcpMqttMode">
              <mat-option
                *ngFor="let mode of tcp_mqtt_mode"
                [value]="mode?.value"
              >
                {{ mode?.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Host</mat-label>
            <input matInput formControlName="host" maxlength="12" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Port</mat-label>
            <input matInput type="number" formControlName="port" />
          </mat-form-field>
        </div>
        <ng-container *ngIf="isMqqtFieldsVisible">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>User</mat-label>
              <input
                matInput
                formControlName="user"
                autocomplete="new-password"
              />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Password</mat-label>
              <input
                type="password"
                matInput
                formControlName="password"
                autocomplete="new-password"
              />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Topic</mat-label>
              <input matInput formControlName="topic" />
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>

      <ng-container class="col-12" *ngIf="data.label == 'Display'">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Parameter</mat-label>
            <mat-select
              formControlName="parameter"
              (selectionChange)="onParameterChange($event.value)"
            >
              <mat-option *ngFor="let param of flabels" [value]="param">
                {{ param }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12" *ngIf="unitList?.length > 0">
          <mat-form-field class="w-100">
            <mat-label>units</mat-label>
            <mat-select formControlName="unit">
              <mat-option *ngFor="let unit of unitList" [value]="unit">
                {{ unit }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container class="col-12" *ngIf="data.label == '4-20 MA'">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Parameter</mat-label>
            <mat-select
              formControlName="parameter"
              (selectionChange)="onParameterChange($event.value)"
            >
              <mat-option *ngFor="let param of flabels" [value]="param">
                {{ param }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Max</mat-label>
            <input matInput formControlName="max" type="number" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Min</mat-label>
            <input matInput formControlName="min" type="number" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Addr</mat-label>
            <input matInput formControlName="addr" type="number" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Debug</mat-label>
            <input matInput formControlName="debug" type="number" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Dacmax</mat-label>
            <input matInput formControlName="dacmax" type="number" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Dacmin</mat-label>
            <input matInput formControlName="dacmin" type="number" />
          </mat-form-field>
        </div>

      </ng-container>

      <ng-container>
        <div class="col-3 d-flex justify-content-between">
          <mat-slide-toggle
            color="primary"
            formControlName="lanStatus"
            [matTooltip]="data.en == 1 ? 'Enabled' : 'Disabled'"
          ></mat-slide-toggle>
          <b
            ><mat-label class="testClass">{{
              configurationForm.get("lanStatus")?.value ? "Enabled" : "Disabled"
            }}</mat-label></b
          >
        </div>
      </ng-container>

    </form>
  </ng-container>
</app-form-container>
