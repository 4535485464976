<div class="px-4 px-sm-4 max-height-with-scroll mt-2">
  <div class="row g-2" *ngIf="projectOrgInfo && (loadTable | async)">
    <div class="col-12 col-sm-6 col-md-3">
      <mat-card class="w-100 h-100">
        <mat-card-content class="flex-content">
          <div>
            <span>Next Billing Date : </span>
            <span>{{ projectOrgInfo.expiry }}</span>
          </div>
          <div class="upgrade-button">
            <button
              mat-button
              color="primary"
              (click)="openDialog('plan')"
              matTooltip="Upgrade Plan"
            >
              Upgrade Plan
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <mat-card class="w-100 h-100">
        <mat-card-content class="flex-content">
          <div>
            <span>Remaining SMS : </span>
            <span
              >{{
                projectOrgInfo.max_sms_count - projectOrgInfo.used_sms_count
              }}/{{ projectOrgInfo.max_sms_count }}</span
            >
          </div>
          <div class="upgrade-button">
            <button
              mat-button
              color="primary"
              (click)="openDialog('sms')"
              matTooltip="Buy 1000 SMS"
            >
              Buy 1000 SMS
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <mat-card class="w-100 h-100">
        <mat-card-content class="flex-content">
          <div>
            <span>Remaining Seats : </span>
            <span
              >{{ projectOrgInfo.max_seats - projectOrgInfo.used_seats }}/{{
                projectOrgInfo.max_seats
              }}</span
            >
          </div>
          <div class="upgrade-button">
            <button
              mat-button
              color="primary"
              (click)="openDialog('seats')"
              matTooltip="Buy More Seats"
            >
              Buy More Seats
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <mat-card class="w-100 h-100">
        <mat-card-content class="flex-content">
          <div>
            <span>Remaining API Calls : </span>
            <span
              >{{
                projectOrgInfo.max_api_calls - projectOrgInfo.used_api_calls
              }}/{{ projectOrgInfo.max_api_calls }} calls per month</span
            >
          </div>
          <div class="upgrade-button">
            <button
              mat-button
              color="primary"
              (click)="openDialog('api')"
              matTooltip="Upgrade Monthly Limit"
            >
              Upgrade Monthly Limit
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div
  *ngIf="projectOrgInfo && (loadTable | async)"
  class="d-flex flex-column overflow-hidden"
>
  <app-data-table
    tableTitle=""
    [data]="projects"
    [columns]="defaultColumns"
    [displayedColumns]="displayedColumns"
    [isChartVisible]="false"
    [showFilterInHeader]="false"
    [noDataViewConfig]="noData"
    (onDownload)="exportProjectsDataToCSV()"
    [showDownloadInHeader]="getProjectModuleOptions('download')"
  >
  </app-data-table>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false || !projectOrgInfo">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
