<app-form-container
  tableTitle="Profile"
  (submit)="submitForm()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  [cancelBtn]="'Cancel'"
  [submitBtn]="'Update'"
  [isValid]="enableUpdateButton || hasValuesChangedLogoForm"
>
  <div [formGroup]="form" class="row gy-4">
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input
          type="text"
          [placeholder]="'Name'"
          matInput
          formControlName="name"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('name')?.invalid">
        {{ specialCharError }}
      </mat-error>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Company Name</mat-label>
        <input
          type="text"
          [placeholder]="'Company Name'"
          matInput
          formControlName="company"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('company')?.invalid">
        {{
          specialCharError.replace(
            "Special characters",
            "Special characters and numbers"
          )
        }}
      </mat-error>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input
          type="text"
          [placeholder]="'Email'"
          matInput
          formControlName="email"
        />
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Organization Name</mat-label>
        <input
          type="text"
          [placeholder]="'Organization Name'"
          matInput
          formControlName="org"
        />
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6">
      <app-mobile-number-input
        [isMultiple]="false"
        [patchNumbers]="existingMobileNumbers"
        (phoneNumberAction)="onNumberAdd($event)"
      ></app-mobile-number-input>
      <!-- <mat-form-field class="w-100">
        <mat-label>Contact</mat-label>
        <input
          type="text"
          [placeholder]="'Contact'"
          matInput
          formControlName="contact"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('contact')?.invalid">
        Invalid Contact Number
      </mat-error> -->
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Location</mat-label>
        <input
          type="text"
          [placeholder]="'Location'"
          matInput
          formControlName="location"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('location')?.invalid">
        {{ specialCharError }}
      </mat-error>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>City</mat-label>
        <input
          type="text"
          [placeholder]="'City'"
          matInput
          formControlName="city"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('city')?.invalid">
        {{
          specialCharError.replace(
            "Special characters",
            "Special characters and numbers"
          )
        }}
      </mat-error>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>State</mat-label>
        <input
          type="text"
          [placeholder]="'State'"
          matInput
          formControlName="state"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('state')?.invalid">
        {{
          specialCharError.replace(
            "Special characters",
            "Special characters and numbers"
          )
        }}
      </mat-error>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Country</mat-label>
        <input
          type="text"
          [placeholder]="'Country'"
          matInput
          formControlName="country"
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('country')?.invalid">
        {{
          specialCharError.replace(
            "Special characters",
            "Special characters and numbers"
          )
        }}
      </mat-error>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Time Zone</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          matInput
          formControlName="timeZone"
          [matAutocomplete]="auto"
          #trigger="matAutocompleteTrigger"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let time of filteredOptions | async"
            [value]="time.nameOffSet"
          >
            {{ time.nameOffSet }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
        >
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Time Format</mat-label>
        <mat-select formControlName="timeFormat">
          <mat-option
            *ngFor="let timeFormat of timeFormats"
            [value]="timeFormat.value"
          >
            {{ timeFormat.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Language</mat-label>
        <input
          type="text"
          #trigger="matAutocompleteTrigger"
          [placeholder]="'Language'"
          matInput
          [matAutocomplete]="language"
          formControlName="language"
        />
        <mat-autocomplete
          #language="matAutocomplete"
          [displayWith]="getLanguageLabel"
        >
          <mat-option *ngFor="let language of languages" [value]="language">
            {{ language.label }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="selectionMade($event, trigger)"
          mat-icon-button
          matSuffix
          tabindex="-1"
        >
          <mat-icon class="material-symbols-outlined">
            arrow_drop_down
          </mat-icon>
        </button>
      </mat-form-field>
    </div>
    <mat-card class="w-100">
      <!-- <mat-card-title class="font">Light Mode Logo</mat-card-title>-->
      <div class="title-container">
        <mat-card-title class="font">Light Mode Logo</mat-card-title>
        <mat-icon
          class="material-symbols-outlined small-icon"
          matTooltip="The logo uploaded here will be shown in top left corner in light mode of the Web application for all the sub users"
          >info</mat-icon
        >
      </div>
      <div class="col-12 mt-2 img-body">
        <app-upload-image
          [noImageMessage]="'No Logo Uploaded!'"
          [isDisabled]="false"
          [imageUrl]="defaultModeUrl"
          [form]="logoForm"
          (imageSelected)="handleImageSelected($event, 'default')"
          (imageRemoved)="removeFile('default')"
          (imageUrlUpdated)="defaultModeUrl = $event"
        ></app-upload-image>
      </div>
    </mat-card>
    <mat-card class="w-100">
      <!-- <mat-card-title class="font">Dark Mode Logo</mat-card-title> -->
      <div class="title-container">
        <mat-card-title class="font">Dark Mode Logo</mat-card-title>
        <mat-icon
          class="material-symbols-outlined small-icon"
          matTooltip="The logo uploaded here will be shown in top left corner in dark mode of the Web application for all the sub users"
          >info</mat-icon
        >
      </div>
      <div class="col-12 mt-2 img-body">
        <app-upload-image
          [noImageMessage]="'No Logo Uploaded!'"
          [isDisabled]="false"
          [imageUrl]="darkModeUrl"
          [form]="logoForm"
          (imageSelected)="handleImageSelected($event, 'dark')"
          (imageRemoved)="removeFile('dark')"
          (imageUrlUpdated)="darkModeUrl = $event"
        ></app-upload-image>
      </div>
    </mat-card>
  </div>
</app-form-container>
