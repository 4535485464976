<ng-container
  *ngIf="
    (googleMapsService?.isApiLoaded | async) &&
      ((deviceData?.[0]?.payload?.d?.lat && deviceData?.[0]?.payload?.d?.lon) ||
        (device?.payload?.d?.lat && device?.payload?.d?.lon) ||
        (device?.latitude && device?.longitude));
    else noDataTemplate
  "
>
  <google-map
    class="col-12 px-0"
    width="100%"
    height="100%"
    [options]="options"
  >
    <map-marker
      #marker="mapMarker"
      [options]="{ icon: device?.iconUrl }"
      [position]="deviceData.length && deviceData[0].payload.d.lat && deviceData[0].payload.d.lon ? {
        lat: deviceData[0].payload.d.lat,
        lng: deviceData[0].payload.d.lon,
      } : (device?.payload?.d?.lat && device?.payload?.d?.lon ? {
        lat: device!.payload!.d.lat,
        lng: device!.payload!.d.lon,
      } : {
        lat: device?.latitude,
        lng: device?.longitude
      })"
    >
      <map-info-window #deviceInfoWindow="mapInfoWindow">
        <app-device-info-window
          [device]="device"
          [deviceData]="deviceData"
          [showImage]="false"
        ></app-device-info-window>
      </map-info-window>
    </map-marker>
  </google-map>

  <app-common-map-controls
    [googleMap]="googleMap"
    [options]="options"
    (mapOptionsUpdated)="onMapOptionsUpdated($event)"
    [showFullscreenControl]="false"
    [showZoomControl]="false"
    [showComplainsLayerOption]="false"
  ></app-common-map-controls>
  <!-- <app-wind-layer
    [windData]="windData"
    [width]="width"
    [height]="height"
    [cellSize]="10"
    [particleCount]="100"
    [particleLifetime]="70"
    [particleColor]="'rgba(255, 255, 255, 0.8)'"
    [curlFactor]="0.2"
    [interval]="50"
    [googleMap]="googleMap"
  ></app-wind-layer> -->
</ng-container>

<ng-template #noDataTemplate>
  <div
    class="position-relative d-flex align-items-center justify-content-center m-0 w-100 h-100"
  >
    <div>
      <app-content-unavailable
        [majorText]="noData.majorText"
        [svgImage]="noData.svgImage"
        [minorText]="noData.minorText"
      ></app-content-unavailable>
    </div>
  </div>
</ng-template>
