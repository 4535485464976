<app-form-container
  [submitBtn]="'Update'"
  [tableTitle]="'Update Interval'"
  [isValid]="form.valid ? true : false"
  (submit)="updateInterval()"
  (cancelled)="closeForm()"
  (close)="closeForm()"
>
  <form class="row gy-4" [formGroup]="form">
    <div class="text-center col-12">
      <mat-form-field class="w-100">
        <mat-label>Select Data Interval</mat-label>
        <mat-select formControlName="interval">
          <mat-option *ngFor="let interval of intervals" [value]="interval.lab">
            {{ interval.lab }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</app-form-container>
