import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-multiple-select-dropdown',
  templateUrl: './multiple-select-dropdown.component.html',
  styleUrls: ['./multiple-select-dropdown.component.scss'],
})
export class MultipleSelectDropdownComponent {
  @Input() options: any;
  @Input() control!: FormControl;
  @Input() label: string = 'Select Options';

  isAllSelected = false;

  isChecked(): boolean {
    return !!(
      this.control.value &&
      this.options.length &&
      this.control.value.length === this.options.length
    );
  }

  isIndeterminate(): boolean {
    return (
      this.control.value &&
      this.options.length &&
      this.control.value.length &&
      this.control.value.length < this.options.length
    );
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.control.setValue(this.options);
    } else {
      this.control.setValue([]);
    }
  }
}
