import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CookieService } from './cookie.service';
import { DeviceService } from './device.service';
import { LocalStorageService } from './local-storage.service';
import { CommonService } from './common.service';
import { BaseAPIService } from './base-service';
import { AutomationData } from '../models/automation/automation-data';
import { DeviceDetails } from '../models/device/device-details';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AppConstants } from '../constants/app-constants';
import { APIConstants } from '../constants/api-constants';
import { DeviceUtil } from '../utils/device-utils';
import { ConfigurationData } from '../models/configuration/configuration-data';
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BaseAPIService<ConfigurationData.Get> {
  tempSubscription: Subscription | undefined;
  public devices: DeviceDetails[] = this.deviceService.registeredDevices!;
  public configurationData!: ConfigurationData.Get;
  // automationData

  public isConfigData = new BehaviorSubject<boolean>(false);
  isConfigData$ = this.isConfigData.asObservable();
  // isAutomationData

  public currentDeviceType = new BehaviorSubject<number>(0);
  currentDeviceType$ = this.currentDeviceType.asObservable();

  public currentDevice = new BehaviorSubject<string>('');
  currentDevice$ = this.currentDevice.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService
  ) {
    super(http, cookieService);
  }

  getConfigurationData(deviceId: string) {
    this.isConfigData.next(false);
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    if (this.tempSubscription && !this.tempSubscription.closed) {
      this.tempSubscription.unsubscribe();
    }
    this.tempSubscription = this.get<ConfigurationData.Get>(
      APIConstants.GET_AUTOMATION_DATA(deviceId),
      headers
    ).subscribe((res: ConfigurationData.Get) => {
      if (res) {
        this.configurationData = res;
        this.currentDevice.next(res.deviceId);
        this.currentDeviceType.next(
          DeviceUtil.getDeviceTypeId(
            res.deviceType,
            this.commonService.getUserDeviceTypes()
          )!
        );
        this.isConfigData.next(true);
      }
    });
  }
  // addAutomationData
  // ConfigurationData.Put
  updateConfigurationData(payload: any, deviceId: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.put<any>(
      APIConstants.UPDATE_CONFIG_DATA(deviceId),
      payload,
      headers
    );
  }

  goToDeviceTerminal(deviceId: string) {
    this.isConfigData.next(false);
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let params: HttpParams = new HttpParams().append('deviceId', `${deviceId}`);
    return this.get<any>(
      APIConstants.ON_DEVICE_TERMINAL(deviceId),
      headers,
      params
    );
  }

  rebootDevice(deviceId: string, template: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const payload = {
      deviceId: deviceId,
      template: template,
    };
    return this.post<any>(
      APIConstants.REBOOT_DEVICE(deviceId, template),
      payload,
      headers
    );
  }

  updateLanForm(payload: any, deviceId: string) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.put<any>(
      APIConstants.UPDATE_CONFIG_DATA(deviceId),
      payload,
      headers
    );
  }
}
