import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import * as Highcharts from 'highcharts/highstock';
import { BehaviorSubject, map, Subject, Subscription, takeUntil } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { VibrationService } from 'src/app/shared/services/vibration.service';
import { sortList } from 'src/app/shared/utils/array-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss'],
})
export class GraphViewComponent {
  @ViewChild('yAxisZoomSlider') yAxisZoomSlider: any;
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public processType: string = 'raw';
  public dataOfCurrentDevice: any;
  public subscriptions: Subscription[] = [];
  public seriesOfDataPoints: any = [];
  public dataFromAPI: any = [];
  public allUnits: any = this.commonService.getAllUnits();
  public chartOptions: Highcharts.Options = {};
  public highcharts: typeof Highcharts = Highcharts;
  public show = false;
  public highChartsObject!: Highcharts.Chart;
  public initialMinOfChart!: number;
  public initialMaxOfChart!: number;
  public form!: FormGroup;
  public selectedDeviceId!: string;
  public selectedDeviceTypeId!: any;
  public selectedKey!: string;
  public keyAndParameters: Record<string, any> = {};
  public availableKeys: Array<Record<string, string>> = [];
  public apiSubscription!: Subscription;
  public noContentAvailable: boolean = false;
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Your device may be offline',
  };
  private destroy$: Subject<void> = new Subject<void>();
  public redrawChart: boolean = false;
  redrawChartTimeoutId: { inner?: NodeJS.Timeout; outer?: NodeJS.Timeout } = {};
  private resizeObserver?: ResizeObserver;
  public resetButtonCss: any;
  public resetButtonLabel: string = 'Reset';
  public positionFromX: number = 0.85;

  constructor(
    private vibrationService: VibrationService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    private themeService: NbThemeService,
    private elementRef: ElementRef,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    //if user directly comes through url with deviceId attached then return the user to /vibration
    if (!history.state.deviceId && !history.state.startDate) {
      this.router.navigate(['/vibration']);
      return;
    }

    this.redrawChartTimeoutId.inner = undefined;
    this.redrawChartTimeoutId.outer = undefined;

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === this.elementRef.nativeElement) {
          this.setResetButtonTheme(undefined);
          this.onResize();
        }
      }
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);

    //listen to changes if user has changed deviceId
    let aRoute = this.activatedRoute.paramMap.subscribe((d) => {
      this.dataOfCurrentDevice = history.state;
      this.setDeviceIdAndDeviceType();
      this.getParametersAndKeys();
      this.selectedKey = this.availableKeys[0].value;
      if (this.form) {
        this.form.patchValue({
          selectedKey: this.availableKeys[0].value,
        });
      }
      this.getVibrationData(this.dataOfCurrentDevice);
    });

    this.subscriptions.push(aRoute);

    //listen to changes if user has not changed deviceID but has changed date
    let date = this.vibrationService.selectedDate$.subscribe((res) => {
      if (res !== 0) {
        this.dataOfCurrentDevice.startDate = res;
        this.getVibrationData(this.dataOfCurrentDevice);
      }
    });
    this.subscriptions.push(date);

    this.buildForm();

    //to get current theme and set reset button styles accordingly
    const currentTheme = this.commonService.getCurrentTheme();
    this.setResetButtonTheme(currentTheme);
  }

  setResetButtonTheme(currentTheme?: string) {
    let fontSize: string = '0.75rem';
    if (window.innerWidth >= 1450) {
      fontSize = '0.75rem';
      this.resetButtonLabel = 'Reset Zoom';
      this.positionFromX = 0.9;
    } else if (window.innerWidth >= 1024) {
      fontSize = '0.75rem';
      this.resetButtonLabel = 'Reset Zoom';
      this.positionFromX = 0.85;
    } else if (window.innerWidth >= 768) {
      fontSize = '0.55rem';
      this.resetButtonLabel = 'Reset Zoom';
      this.positionFromX = 0.85;
    } else {
      fontSize = '0.55rem';
      this.resetButtonLabel = 'Reset';
      this.positionFromX = 0.85;
    }

    if (currentTheme) {
      if (currentTheme == 'material-light')
        this.resetButtonCss = {
          style: {
            position: 'absolute',
            fontSize: fontSize,
            backgroundColor: '#f7f7f7',
            border: '1px solid #cccccc',
            borderRadius: 2,
            color: '#333333',
            top: '16px',
            right: '40px',
            padding: '0.5rem 0.5rem 0.625rem 0.5rem',
          },
        };
      else {
        this.resetButtonCss = {
          'stroke-width': 1, //to set the background color of button in dark theme
          stroke: '#CCCCCC', //to set the background color of button in dark theme
          fill: '#000000', //to set the background color of button in dark theme
          style: {
            position: 'absolute',
            fontSize: fontSize,
            backgroundColor: '#1B1D1B',
            border: '1px solid #cccccc',
            borderRadius: 2,
            color: '#fff',
            top: '16px',
            right: '40px',
            padding: '0.5rem 0.5rem 0.625rem 0.5rem',
          },
        };
      }
    }
  }

  onResize() {
    if (this.redrawChartTimeoutId.outer) {
      clearTimeout(this.redrawChartTimeoutId.outer);
      this.redrawChartTimeoutId.outer = undefined;
    }
    if (this.redrawChartTimeoutId.inner) {
      clearTimeout(this.redrawChartTimeoutId.inner);
      this.redrawChartTimeoutId.inner = undefined;
    }
    this.redrawChartTimeoutId.outer = setTimeout(() => {
      this.redrawChart = false;
      this._cdr.detectChanges();

      this.redrawChartTimeoutId.inner = setTimeout(() => {
        this.redrawChart = true;
        this._cdr.detectChanges();
      });
    }, 100);
  }

  setDeviceIdAndDeviceType() {
    let deviceTypes = this.commonService.getUserDeviceTypesWithVibration();
    let devices = this.deviceService.registeredDevicesWithVibration!;

    this.selectedDeviceId = this.dataOfCurrentDevice.deviceId;
    this.selectedDeviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      deviceTypes,
      devices,
      this.selectedDeviceId
    );

    this.vibrationService.setDeviceTypeId(this.selectedDeviceTypeId);
  }

  getParametersAndKeys() {
    let unitsOfDeviceType = this.allUnits[this.selectedDeviceTypeId];

    this.keyAndParameters = {};
    this.availableKeys = [];

    Object.keys(unitsOfDeviceType).map((key: string) => {
      const unit = unitsOfDeviceType[key];
      if (unit.isVisible) {
        const keyWithoutSuffix: string = unit.key
          .slice(0, -1)
          .replace(/[^a-zA-Z0-9]/g, '');
        if (!this.keyAndParameters[keyWithoutSuffix]) {
          this.keyAndParameters[keyWithoutSuffix] = [unit.key];
          let k = this.capitalizeFirstLetter(unit.flabel);
          this.availableKeys.push({
            key: k.slice(0, -1),
            value: keyWithoutSuffix,
          });
        } else {
          this.keyAndParameters[keyWithoutSuffix].push(unit.key);
        }
      }
    });

    let keysArray: string[] = [];

    Object.keys(this.keyAndParameters).map((kp) =>
      keysArray.push(this.keyAndParameters[kp])
    );

    this.vibrationService.setKeysOfDevice(keysArray.flat(1));
  }

  capitalizeFirstLetter(str: any) {
    if (str.length === 0) return str; // Handle empty string

    const words = str.split(' ');

    const processedWords = words.map((word: any) => {
      if (word.length === 0) return word;
      return word[0].toUpperCase() + word.slice(1);
    });

    return processedWords.join('');
  }

  buildForm() {
    this.form = this.formBuilder.group({
      selectedKey: [this.availableKeys[0].value, Validators.required],
    });

    let k = this.form.get('selectedKey')?.valueChanges.subscribe((res) => {
      this.selectedKey = res;
      this.generateDataPoints();
      this.addSeriesInChart();
      this.updateYAxisZoomLevel(
        this.yAxisZoomSlider.nativeElement.value,
        this.highChartsObject
      );
      this.resetButtonVariable = undefined;
      this.setChartTitle();
    })!;

    this.subscriptions.push(k);
  }

  //generate payload to get vibration data
  generatePayload(data: any) {
    let payload: { [key: string]: any } = {};
    payload = {
      deviceId: data.deviceId,
      gte: data.startDate,
      lte: data.startDate + 86400,
      processType: this.processType,
    };
    return payload;
  }

  //get vibration data from api
  getVibrationData(data: any) {
    this.noContentAvailable = false;
    this.loadTable.next(false);
    let payload = this.generatePayload(data);

    //unsubscribing to api response if user selects another device before api call is completed
    if (this.apiSubscription && !this.apiSubscription.closed) {
      this.apiSubscription.unsubscribe();
    }

    this.apiSubscription = this.vibrationService
      .getDeviceData(payload, data.deviceId)
      .subscribe({
        next: (res) => {
          if (res[0].payload.length < 1) {
            this.noContentAvailable = true;
            this.loadTable.next(true);
            return;
          } else {
            this.noContentAvailable = false;
          }
          this.vibrationService.setVibrationData(
            sortList(res[0].payload, 'ASC', 'payload.d.t')
          );
          this.dataFromAPI = this.vibrationService.getVibrationData();
          this.generateDataPoints();
          this.setUpDataPoints();
          this.loadTable.next(true);
        },
        error: (err) => {
          this.vibrationService.setVibrationData(undefined);
          this.dataFromAPI = this.vibrationService.getVibrationData();
          this.loadTable.next(true);
          this.notificationService.showSnackBar(
            'Error while getting Data, Try Again!',
            'error'
          );
        },
      });

    this.subscriptions.push(this.apiSubscription);
  }

  //generate data points from the data received from api
  generateDataPoints() {
    this.seriesOfDataPoints = this.keyAndParameters[this.selectedKey].map(
      (parameter: string) => {
        let dataPoints = this.dataFromAPI.map((data: any) => {
          let dataPoint: any = {};
          data = DeviceUtil.convertUnits(
            data.payload,
            this.allUnits[this.selectedDeviceTypeId]
          );
          dataPoint.x = this.customMomentService.moment
            .unix(Math.round(data.d['t']))
            .toDate();

          dataPoint.y = data.d[parameter];
          return dataPoint;
        });
        return { parameter: parameter, data: dataPoints };
      }
    );
  }

  //set up the generated data points in chart
  setUpDataPoints() {
    this.show = false;

    let that = this;

    let xAxisOptions: Highcharts.XAxisOptions = {
      events: {
        //this will run every time when min and max of x-axis change
        afterSetExtremes: function (e) {
          // if (!that.initialMinOfChart) {
          //   that.initialMinOfChart = e.min;
          // }
          // if (!that.initialMaxOfChart) {
          //   that.initialMaxOfChart = e.max;
          // }
          //decide whether to show the reset button or not
          // that.handleResetButtonVisibility(e.min, e.max);
        },
      },
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatDatetime({
            epoch: parseInt(this.value + '') / 1000,
            format: that.commonService.getDateTimeFormatForGraph(),
          });
        },
      },
      tickmarkPlacement: 'between',
      crosshair: true,
    };

    let yAxisOption: Highcharts.YAxisOptions = {
      labels: {
        format: '{value}',
      },
    };

    let chartOptions: Highcharts.ChartOptions = {
      events: {
        //this function will run when the user will zoom in on chart
        selection: function (event) {
          // Check if the selection is made
          if (event.xAxis) {
            // Set the extremes for the x-axis based on the selection to trigger the change in x-axis
            this.xAxis[0].setExtremes(
              event.xAxis[0].min,
              event.xAxis[0].max,
              true,
              true
            );
            that.updateYAxisZoomLevel(10000, that.highChartsObject);
          }
          return false; // Prevent default zoom behavior
        },
        //this function will run after the chart is initialized
        load: function () {
          //storing the charts object in variable for future user
          that.highChartsObject = this;
          that.addSeriesInChart();

          that.updateYAxisSlider(
            that.highChartsObject,
            that.yAxisZoomSlider,
            10000
          );
          that.updateYAxisZoomLevel(10000, that.highChartsObject);

          that.handleResetButtonVisibility();

          that.setChartTitle();
        },
      },
      type: 'column',
      zooming: {
        singleTouch: true,
        type: 'x',
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOption;
    this.chartOptions.chart = chartOptions;

    //customized tooltip
    this.chartOptions.tooltip = {
      shared: true,
      formatter: function () {
        return `<b>${that.customMomentService.formatDate({
          epoch: Number(this.x) / 1000,
          format: that.commonService.getDateTimeFormatForToolTip(true),
        })}    
        </b><br />${this.points!.map(function (point) {
          return `<span style="color: ${
            point.series.color
          }">${point.series.name}</span>: <b>${point.y?.toFixed(4)}</b>`;
        }).join('<br />')}`;
      },
    };

    //to remove the highcharts credit
    this.chartOptions.credits = { enabled: false };

    //to set the download button of the chart
    this.chartOptions.exporting = {
      buttons: {
        contextButton: {
          verticalAlign: 'bottom',
        },
      },
    };

    this.chartOptions.plotOptions = {};
    // console.log('this.seriesOfDataPoints', this.seriesOfDataPoints);

    this.chartOptions.plotOptions = {
      column: { pointPlacement: 'between' },
      series: {
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    };

    this.chartOptions.navigator = {
      enabled: true,
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function () {
            return that.customMomentService.formatDatetime({
              epoch: parseInt(this.value + '') / 1000,
              format: that.commonService.getDateTimeFormatForGraph(),
            });
          },
        },
      },
    };

    this.chartOptions.scrollbar = {
      enabled: false,
    };

    this.chartOptions.rangeSelector = {
      selected: 1,
    };

    this.chartOptions.plotOptions.series!.turboThreshold =
      Number.POSITIVE_INFINITY;
    this.chartOptions.plotOptions.series!.cropThreshold =
      Number.POSITIVE_INFINITY;

    setTimeout(() => {
      this.show = true;
      this.onThemeChange();
    });
  }

  addSeriesInChart() {
    if (!this.highChartsObject) {
      return;
    }

    const colorMap: Record<string, string> = {
      x: '#d13e50',
      y: '#7ed321',
      z: '#4a90e2',
    };

    while (this.highChartsObject?.series?.length > 0) {
      this.highChartsObject.series[0].remove();
    }

    for (let i = 0; i < this.seriesOfDataPoints.length; i++) {
      let key: string = this.seriesOfDataPoints[i].parameter;
      key = key.slice(-1);
      let series = {
        // ...this.chartOptions.series[i],
        name: this.allUnits[this.selectedDeviceTypeId][
          this.seriesOfDataPoints[i].parameter
        ]?.flabel,
        data: this.seriesOfDataPoints[i].data,
        color: colorMap[key],
        // pointStart: tempData1[0].payload.d.t,
        dataGrouping: {
          enabled: true,
          anchor: 'middle',
          groupAll: true,
          groupPixelWidth: 20,
          approximation: 'average', // Aggregation method (e.g., 'average', 'sum')
          units: [
            // Define the grouping units
            [
              'millisecond', // unit name
              [1, 2, 5, 10, 20, 25, 50, 100, 200, 500], // allowed multiples
            ],
            ['second', [1, 2, 5, 10, 15, 30]],
            ['minute', [1, 2, 5, 10, 15, 30]],
            ['hour', [1, 2, 3, 4, 6, 8, 12]],
          ],
        },
      } as Highcharts.SeriesOptionsType;

      this.highChartsObject.addSeries(series);
    }
  }

  setChartTitle() {
    if (!this.highChartsObject) {
      return;
    }

    const unit =
      this.allUnits[this.selectedDeviceTypeId][
        this.keyAndParameters[this.selectedKey][0]
      ];

    //set chart title
    if (unit.description) {
      this.highChartsObject.setTitle({
        text: `${unit.description}/time`,
        style: {
          fontSize: '18px',
          fontFamily:
            '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
        },
      });
    } else {
      this.highChartsObject.setTitle({ text: `${unit.label}/time` });
    }

    //set yaxis title
    this.highChartsObject.yAxis[0].setTitle({
      text: unit.label,
    });
  }

  updateYAxisSlider(chart: any, slider: any, value?: any) {
    // console.log('chart', chart.target);
    var yAxis = chart.yAxis[0];
    // console.log('yAxis', yAxis);
    var yMin = yAxis.min!;
    var yMax = yAxis.max!;
    var sliderValue = (yMax - yMin) / (yAxis?.dataMax - yAxis.dataMin);

    // Update the position and size of the slider
    slider.nativeElement.value = value ? value : sliderValue;
  }

  public updateYAxisZoomLevel(zoomLevel: any, chart: any) {
    if (!chart) return true;

    if (isNaN(zoomLevel)) {
      // console.log('goigner insdie', zoomLevel);
      const target = zoomLevel.target as HTMLInputElement;
      zoomLevel = target.valueAsNumber;
    }

    // Calculate the percentage values for the new extremes
    chart.yAxis[0].setExtremes(null, null);
    const currentMin = chart.yAxis[0].getExtremes().min;
    const currentMax = chart.yAxis[0].getExtremes().max;
    const percentage = zoomLevel / 10000; // 20% for example

    const newMin = currentMin * Math.max(0.001, percentage);
    const newMax = currentMax * Math.max(0.001, percentage);

    // Set the new extremes
    chart.yAxis[0].setExtremes(
      newMin ? Math.min(-0.001, newMin) : null,
      Math.max(0.001, newMax)
    );

    return true;
  }

  resetButtonVariable: any;

  //to handle the visibility of reset button
  handleResetButtonVisibility(min?: number, max?: number) {
    if (this.highChartsObject) {
      let that = this;

      //if reset button is available than remove the button
      if (this.resetButtonVariable) {
        if (this.highChartsObject.renderer.box.parentNode) {
          this.resetButtonVariable = undefined;
        }
      }

      //add the reset button in chart
      if (!this.resetButtonVariable) {
        const positionFromX =
          that.positionFromX * this.highChartsObject.chartWidth;
        this.resetButtonVariable = this.highChartsObject.renderer
          .button(
            this.resetButtonLabel,
            positionFromX,
            20,
            function () {
              //to reset x-axis
              that.highChartsObject.xAxis[0].setExtremes(
                undefined,
                undefined,
                true
              );
              //to reset y-axis
              // that.updateYAxisZoomLevel(10000, that.highChartsObject);
            },
            that.resetButtonCss, // to set css style for hover state
            that.resetButtonCss, // to set css style for selected state
            that.resetButtonCss //to set css style for disabled state
          )
          .attr({
            zIndex: 5,
          })
          .add();
      }
      // if (min !== this.initialMinOfChart || max !== this.initialMaxOfChart) {
      // this.resetButtonVariable.show();
      // } else {
      // this.resetButtonVariable.hide();
      // }
    }
  }

  onThemeChange() {
    let theme = this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        try {
          this.setResetButtonTheme(themeName);
          this.show = false;
          setTimeout(() => {
            this.show = true;
          });
        } catch (e) {}
      });
    this.subscriptions.push(theme);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.vibrationService.selectedDate.next(0);
  }
}
