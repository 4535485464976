import { Component, Input, OnInit } from '@angular/core';
import { DeviceUtil } from '../../utils/device-utils';
import { DeviceService } from '../../services/device.service';
import { IndexData } from '../../models/aqi-index/index-data';
import { AqiDetails } from '../../models/aqi-index/aqi-details';

@Component({
  selector: 'app-aqi-table',
  templateUrl: './aqi-table.component.html',
  styleUrls: ['./aqi-table.component.scss'],
})
export class AqiTableComponent {
  @Input() indexLabel!: string;
  @Input() headers!: any;
  @Input() aqiData!: IndexData;
  @Input() aqiDetails!: AqiDetails;
  @Input() tableTitle!: string;
  @Input() isNonAqiParams: boolean = false;
}
