import { HttpParams } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, map, Observable, switchMap, timer } from 'rxjs';
import { CookieService } from './cookie.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../constants/app-constants';

export class BaseAPIService<T> {
  private baseUrl: string = this.cookieService.getCookie(AppConstants.APIS)
    ? this.cookieService.getCookie(AppConstants.APIS)
    : environment.URL
    ? environment.URL
    : 'https://gateway.oizom.com/';

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  protected get<T>(
    url: string,
    headers: HttpHeaders = this.httpHeaders,
    params?: HttpParams,
    responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json'
  ): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${url}`, {
      headers: headers,
      params: params,
      responseType: responseType as any,
    });
  }

  protected post<T>(
    url: string,
    data: any,
    headers: HttpHeaders = this.httpHeaders,
    params?: HttpParams
  ): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}${url}`, data, {
      headers: headers,
      params: params,
    });
  }

  protected put<T>(
    url: string,
    data: any,
    headers: HttpHeaders = this.httpHeaders,
    params?: HttpParams
  ): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}${url}`, data, {
      headers: headers,
      params: params,
    });
  }

  protected delete<T>(
    url: string,
    headers: HttpHeaders = this.httpHeaders,
    params?: HttpParams
  ): Observable<T> {
    /* Ensures a minimum delay of 1 second for the loading animation in the CONFIRMATION POPUP COMPONENT.
   If the API call completes in less than 1 second, the code waits until the 1 second mark before proceeding.
   Example:
   - API call completes in 0.7 seconds: code waits an additional 0.3 seconds.
   - API call takes more than 1 second: code proceeds immediately after completion.
*/
    const start = Date.now();
    const minDelay = 1000;
    return this.http
      .delete<T>(`${this.baseUrl}${url}`, {
        headers: headers,
        params: params,
      })
      .pipe(
        switchMap((response) => {
          const timeTaken = Date.now() - start;
          const reqDelayLeft = Math.max(0, minDelay - timeTaken);
          return from(
            new Promise((r) => setTimeout(r, reqDelayLeft)).then(() => response)
          );
        })
      );
  }

  protected patch<T>(
    url: string,
    data: any,
    headers: HttpHeaders = this.httpHeaders,
    params?: HttpParams
  ): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}${url}`, data, {
      headers: headers,
      params: params,
    });
  }
}
