<h1
  mat-dialog-title
  *ngIf="dialogConfig.title"
  class="confirmation-popup-header icon-material"
>
  <mat-icon class="material-symbols-outlined">{{ dialogConfig.icon }}</mat-icon
  >{{ dialogConfig.title }}
</h1>

<div mat-dialog-content class="d-flex flex-column gap-3 dialog-content">
  <div class="img-dialog-content">
    <img
      *ngIf="dialogConfig.image"
      [src]="dialogConfig.image"
      class="confirmation-img"
    />
    <span class="message-text-color" [innerHTML]="dialogConfig.message"></span>
  </div>

  <ng-container
    *ngIf="dialogConfig.checkBoxTitle && !dialogConfig.hideActionButtons"
  >
    <mat-checkbox [checked]="true" [formControl]="checkBox" [color]="'primary'">
      {{ dialogConfig.checkBoxTitle }}
    </mat-checkbox>
    <p [ngClass]="{ 'opacity-0': isChecked }" class="text-danger">
      {{ dialogConfig.alertMessage }}
    </p>
  </ng-container>
</div>
<mat-dialog-actions
  *ngIf="!dialogConfig.hideActionButtons"
  align="end"
  class="me-2"
>
  <ng-container *ngFor="let buttonConfig of dialogConfig.buttonconfig">
    <button
      *ngIf="buttonConfig.type === 'mat-button'"
      class="me-3"
      mat-button
      [ngStyle]="buttonConfig.styles"
      (click)="
        handlebuttonclick(
          $event,
          buttonConfig.onClickCallback,
          buttonConfig.closeDialog
        )
      "
    >
      {{ buttonConfig.text }}
      <mat-icon *ngIf="buttonConfig?.icon" class="material-symbols-outlined">{{
        buttonConfig?.icon
      }}</mat-icon>
    </button>

    <button
      *ngIf="buttonConfig.type !== 'mat-button'"
      class="me-3"
      mat-raised-button
      [ngStyle]="buttonConfig.styles"
      [color]="buttonConfig.color"
      (click)="
        handlebuttonclick(
          $event,
          buttonConfig.onClickCallback,
          buttonConfig.closeDialog
        )
      "
    >
      {{ buttonConfig.text }}
      <mat-icon *ngIf="buttonConfig?.icon" class="material-symbols-outlined">{{
        buttonConfig?.icon
      }}</mat-icon>
    </button>
  </ng-container>
</mat-dialog-actions>
