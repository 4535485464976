<app-form-container
  [tableTitle]="stepperTitle"
  (cancelled)="previous()"
  (close)="closeForm()"
  (submit)="submitAndNext()"
  [submitBtn]="submitBtn"
  [cancelBtn]="cancelBtn"
  [isValid]="isNextStep"
>
  <mat-stepper
    #stepper
    [linear]="true"
    class="h-100 custom-stepper"
    (selectionChange)="onStepChange($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step
      [stepControl]="stepOneRegisterForm"
      class="firststepper"
      label="{{ isEditMode ? 'Edit' : 'Add' }} Project"
    >
      <form [formGroup]="stepOneRegisterForm">
        <div
          formGroupName="companyRegisterForm"
          *ngIf="projectsOrgInfo?.length !== '0'"
        >
          <div class="gx-4 gy-3 row pt-4">
            <ng-container *ngFor="let details of companyRegisterDetails">
              <ng-container *ngIf="details?.isEdit == 'true'">
                <div [class]="details?.class">
                  <mat-form-field
                    class="w-100"
                    *ngIf="details.controlName !== 'plan'"
                  >
                    <mat-label>{{ details.label }}</mat-label>
                    <input
                      [type]="details.type"
                      matInput
                      [formControlName]="details?.controlName"
                      autocomplete="new-password"
                    />
                    <button
                      *ngIf="
                        details?.controlName === 'clientId' ||
                        details?.controlName === 'clientSecret'
                      "
                      mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="
                        $event.stopPropagation();
                        $event.preventDefault();
                        copyText(
                          stepOneRegisterForm
                            .get('companyRegisterForm')
                            ?.get(details?.controlName)?.value
                        )
                      "
                      matTooltip="Copy to clipboard"
                      matTooltipPosition="below"
                      class="sharebtn"
                      [disabled]="
                        (details?.controlName === 'clientId' &&
                          !this.userData?.clientId) ||
                        (details?.controlName === 'clientSecret' &&
                          !this.userData?.clientSecret)
                      "
                    >
                      <mat-icon class="material-symbols-outlined"
                        >content_copy</mat-icon
                      >
                    </button>
                    <mat-error
                      *ngIf="
                        stepOneRegisterForm
                          .get('companyRegisterForm')
                          ?.get(details.controlName)
                          ?.hasError('min') ||
                        stepOneRegisterForm
                          .get('companyRegisterForm')
                          ?.get(details.controlName)
                          ?.hasError('max') ||
                        stepOneRegisterForm
                          .get('companyRegisterForm')
                          ?.get(details.controlName)
                          ?.hasError('pattern')
                      "
                      >{{ details.error }}</mat-error
                    >
                  </mat-form-field>
                  <ng-container
                    *ngIf="userDetails.topLevel && details.label === 'Plan'"
                  >
                    <mat-form-field class="w-100">
                      <mat-label>Select Plan</mat-label>
                      <mat-select formControlName="plan">
                        <mat-option
                          *ngFor="let plan of plans"
                          [value]="plan.value"
                        >
                          {{ plan.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <small>
                    <mat-hint
                      *ngIf="
                        details.hint && !isControlDisabled(details?.controlName)
                      "
                      class="labelstyle"
                      >{{ details.hint }}</mat-hint
                    >
                  </small>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div formGroupName="adminUserDetailsForm" style="margin-top: 20px">
          <span class="medium">Admin User Details</span>
          <div class="gx-4 gy-3 row pt-3">
            <div
              *ngFor="let details of adminUserDetails"
              class="col-12 col-lg-6"
            >
              <ng-container *ngIf="details.label === 'Contact'; else other">
                <app-mobile-number-input
                  [patchNumbers]="existingMobileNumbers"
                  [isMultiple]="false"
                  (phoneNumberAction)="onNumberAdd($event)"
                >
                </app-mobile-number-input>
              </ng-container>

              <ng-template #other>
                <mat-form-field class="w-100">
                  <mat-label>{{ details.label }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControlName]="details.controlName"
                  />
                  <mat-error
                    *ngIf="
                      stepOneRegisterForm
                        .get('adminUserDetailsForm')
                        ?.get(details.controlName)
                        ?.hasError('pattern')
                    "
                    >{{ details.error }}</mat-error
                  >
                </mat-form-field>
                <small>
                  <mat-hint
                    *ngIf="
                      details.hint && !isControlDisabled(details?.controlName)
                    "
                    class="labelstyle"
                    >{{ details?.hint }}</mat-hint
                  >
                </small>
              </ng-template>
            </div>
          </div>
        </div>

        <div formGroupName="adminUserLoginDetailForm" style="margin-top: 20px">
          <span class="medium">Admin User Login Details</span>
          <div class="gx-4 gy-3 row pt-3">
            <div
              *ngFor="let details of adminUserLoginDetail"
              class="col-12 col-lg-6"
            >
              <div *ngIf="details.isEdit == 'true'">
                <mat-form-field class="w-100">
                  <mat-label>{{ details.label }}</mat-label>
                  <input
                    [type]="
                      details.type
                        ? details.type
                        : showType
                        ? 'password'
                        : 'text'
                    "
                    matInput
                    [formControlName]="details.controlName"
                    autocomplete="new-password"
                  />
                  <mat-icon
                    matSuffix
                    class="material-symbols-outlined"
                    *ngIf="details.controlName === 'password'"
                    (click)="toggleTypeofPassword()"
                    >visibility_off</mat-icon
                  >
                </mat-form-field>
                <small>
                  <mat-error
                    *ngIf="
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('email') ||
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('customError') ||
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('confirmpasswordmismatch') ||
                      stepOneRegisterForm
                        .get('adminUserLoginDetailForm')
                        ?.get(details.controlName)
                        ?.hasError('passwordmismatch')
                    "
                    >{{
                      !isControlDisabled(details?.controlName)
                        ? details.hint
                        : ""
                    }}</mat-error
                  >
                </small>
                <small>
                  <mat-hint
                    class="labelstyle"
                    *ngIf="
                      !(
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('email') ||
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('customError') ||
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('confirmpasswordmismatch') ||
                        stepOneRegisterForm
                          .get('adminUserLoginDetailForm')
                          ?.get(details.controlName)
                          ?.hasError('passwordmismatch')
                      )
                    "
                  >
                    {{
                      !isControlDisabled(details?.controlName)
                        ? details.hint
                        : ""
                    }}
                  </mat-hint>
                </small>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step
      [stepControl]="stepTwoDeviceForm"
      class="secondstepper"
      label="Select Device"
    >
      <form [formGroup]="stepTwoDeviceForm">
        <div class="row align-items-center">
          <div class="col-12 pt-3 pb-3 d-flex flex-column">
            <mat-form-field class="col-lg-3 col-12">
              <mat-label>Select Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="selectedDate"
              />
              <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-hint class="col-lg-auto col-12"
              >Specify a start date from which you want the user to access the
              device data
            </mat-hint>
          </div>
        </div>
        <hr />
        <app-data-table
          [forceSearchOpen]="true"
          [data]="
            userDetails.topLevel ? currentProjectDevices : usersAllDevices
          "
          [columns]="defaultColumnsDevices"
          [displayedColumns]="displayedColumnsDevices"
          [selection]="deviceSelector"
          (onSelect)="onDeviceSelection()"
          [showTableHeader]="true"
          [showPaginator]="false"
          [isChartVisible]="false"
          [showDownloadInHeader]="false"
          [showFilterInHeader]="false"
        ></app-data-table>
      </form>
    </mat-step>
    <mat-step
      [stepControl]="stepThreeModuleForm"
      class="thirdstepper"
      label="Select Module"
    >
      <div class="col-lg-12 d-flex flex-direction-row gap-2 pt-3">
        <ng-container
          *ngIf="
            userDetails?.topLevel ||
            (moduleAccessControl &&
              moduleAccessControl[1001]?.options?.raw !== false)
          "
        >
          <mat-icon
            class="mat-icon-custom material-symbols-outlined d-flex justify-content-center align-items-center"
            matTooltip="Enable/Disable Device's Raw Data access to the respective User"
            >info</mat-icon
          >
          <span>Raw Data:</span>
          <mat-slide-toggle
            color="primary"
            [checked]="rawData"
            (change)="onToggleChange($event)"
          ></mat-slide-toggle>
        </ng-container>
        <ng-container
          *ngIf="
            userDetails?.topLevel ||
            (moduleAccessControl &&
              moduleAccessControl[1001]?.options?.sub_index !== false)
          "
        >
          <mat-icon
            class="mat-icon-custom material-symbols-outlined d-flex justify-content-center align-items-center"
            matTooltip="Enable/Disable Sub Index of each parameter according to the chosen AQI"
            >info</mat-icon
          >
          <span>Sub Index:</span>
          <mat-slide-toggle
            color="primary"
            [checked]="sub_index"
            (change)="onSubIndexToggleChange($event)"
          ></mat-slide-toggle>
        </ng-container>
      </div>
      <hr />

      <ng-container *ngIf="userDetails.topLevel; else appDataTable">
        <app-module-list-admin
          (moduleSelectoreEventEmmiter)="getAdminModules($event)"
        ></app-module-list-admin>
      </ng-container>

      <ng-template #appDataTable>
        <app-data-table
          [data]="modules"
          [columns]="defaultColumnsModules"
          [displayedColumns]="displayedColumnsModules"
          (onSelect)="onModuleSelection()"
          (dateSelected)="onDateSelection($event)"
          [showTableHeader]="false"
          [showPaginator]="false"
          [selection]="moduleSelector"
          [isChartVisible]="false"
        ></app-data-table>
      </ng-template>
    </mat-step>

    <ng-container *ngIf="userDetails.topLevel">
      <mat-step
        [stepControl]="stepFourLayersForm"
        class="thirdstepper"
        label="Layers"
      >
        <form [formGroup]="stepFourLayersForm">
          <div class="col-12">
            <button
              mat-stroked-button
              color="primary"
              matTooltip="Add"
              class="w-100"
              type="button"
              (click)="toggleLayerForm()"
            >
              <mat-icon class="material-symbols-outlined">{{
                showLayerForm ? "clear" : "add"
              }}</mat-icon>
              {{ showLayerForm ? "Remove Layer" : "Add Layer" }}
            </button>
          </div>

          <div class="gx-4 gy-3 row pt-4" *ngIf="showLayerForm">
            <ng-container *ngFor="let layer of layerFormsKeys">
              <div [class]="layer?.class">
                <mat-form-field
                  class="w-100"
                  *ngIf="
                    layer.controlName !== 'layerType' &&
                    layer.controlName !== 'iconUrl' &&
                    layer.controlName !== 'dataUrl' &&
                    layer.controlName !== 'isVisible'
                  "
                >
                  <mat-label>{{ layer.label }}</mat-label>
                  <input
                    [type]="layer.type"
                    matInput
                    [formControlName]="layer?.controlName"
                  />
                </mat-form-field>
                <mat-form-field
                  class="w-100"
                  *ngIf="layer.controlName === 'layerType'"
                >
                  <mat-label>Select Type</mat-label>
                  <mat-select formControlName="layerType">
                    <mat-option
                      *ngFor="let type of layerTypes"
                      [value]="type.val"
                    >
                      {{ type.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-checkbox
                  *ngIf="layer.controlName === 'isVisible'"
                  [color]="'primary'"
                  class="col-12 mb-4 terms"
                  formControlName="isVisible"
                  >Display Layer
                </mat-checkbox>
                <div class="col-12" *ngIf="layer.controlName === 'iconUrl'">
                  <app-upload-image
                    [noImageMessage]="'No Icon Image Uploaded!'"
                    [isDisabled]="false"
                    [isEditMode]="isEditMode"
                    [imageUrl]="stepFourLayersForm.get('iconUrl')?.value"
                    [form]="stepFourLayersForm"
                    (imageSelected)="
                      handleImageSelected($event, layer.controlName)
                    "
                    (imageRemoved)="removeFile(layer.controlName)"
                    (imageUrlUpdated)="updateImageUrl($event, 'iconUrl')"
                  ></app-upload-image>
                </div>
                <div class="col-12" *ngIf="layer.controlName === 'dataUrl'">
                  <app-upload-image
                    [noImageMessage]="'No Kml/GeoJson Uploaded!'"
                    [fileLabel]="'Kml/GeoJson'"
                    [isDisabled]="false"
                    [isEditMode]="isEditMode"
                    [imageUrl]="stepFourLayersForm.get('dataUrl')?.value"
                    [form]="stepFourLayersForm"
                    (imageSelected)="
                      handleImageSelected($event, layer.controlName)
                    "
                    (imageRemoved)="removeFile(layer.controlName)"
                    (imageUrlUpdated)="updateImageUrl($event, 'dataUrl')"
                    [fileAcceptExtensions]="'.kml,.kmz,.geojson'"
                  ></app-upload-image>
                </div>
              </div>
            </ng-container>
            <hr />
          </div>
        </form>
        <ng-container *ngIf="map_layers.length > 0">
          <div class="fs-5 fw-bold my-2">Added Layers</div>
          <app-data-table
            [data]="map_layers"
            [columns]="defaultColumnsMapLayers"
            [displayedColumns]="displayedColumnMapLayers"
            [showTableHeader]="false"
            [showPaginator]="false"
            [isChartVisible]="false"
          >
          </app-data-table>
          <hr />
        </ng-container>
        <div>
          <div class="fs-6 fw-bold my-4">
            Sensor Expiry Email settings<mat-icon
              class="material-symbols-outlined small-icon infoIcon"
              matTooltip="If this field is empty here the email will be sent to the default registered ID"
            >
              info
            </mat-icon>
          </div>
          <div class="mt-2">
            <mat-form-field class="col-12 col-lg-6">
              <mat-label>Add Sensor Expiry EMail</mat-label>
              <input
                type="text"
                matInput
                [(ngModel)]="sensorExpiryEmailMapping"
              />
            </mat-form-field>
            <mat-checkbox
              [checked]="sendSensorExpiryEmail"
              [color]="'primary'"
              class="col-12 col-lg-6"
              [(ngModel)]="sendSensorExpiryEmail"
              >Set sensor expiry email
            </mat-checkbox>
          </div>
        </div>
      </mat-step>
    </ng-container>
  </mat-stepper>
</app-form-container>
