import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SensorExpiryDetails } from 'src/app/shared/models/device/sensor-expiry-details';
import { DeviceService } from 'src/app/shared/services/device.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';

@Component({
  selector: 'app-popup-table',
  templateUrl: './popup-table.component.html',
  styleUrls: ['./popup-table.component.scss'],
})
export class PopupTableComponent implements OnInit {
  loadTable: boolean = false;
  displayedColumns: string[] = ['key', 'sensorId', 'updatedOn', 'expiry'];
  dataSource!: SensorExpiryDetails[];

  constructor(
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.deviceService
      .getSensorExpiryDetails(this.data.deviceId)
      .subscribe((result: SensorExpiryDetails[]) => {
        this.dataSource = result;
        this.updataDataSource();
        this.loadTable = true;
      });
  }

  updataDataSource() {
    this.dataSource.forEach((result) => [
      (result.expiry = this.customMomentService.moment
        .unix(Number(result.expiry))
        .format('DD/MM/YYYY')),
      (result.updated_on = this.customMomentService.moment
        .unix(Number(result.updated_on))
        .format('DD/MM/YYYY')),
    ]);
  }
}
