<app-terminal-page-layout>
  <app-terminal-page-header>
    <div
      class="w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="header my-3">
        <h2 class="text-primary m-0">
          {{ currentModuleDetails.moduleName }} Module
        </h2>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div
      class="w-100 h-100 d-flex flex-column px-1 px-lg-3"
      *ngIf="showDetails"
    >
      <div
        class="w-100 d-flex flex-column justify-content-center align-items-center"
      >
        <!-- <div class="header my-3">
          <h2 class="text-primary m-0">
            {{ currentModuleDetails.moduleName }} Module
          </h2>
        </div>
        <mat-divider class="w-100"></mat-divider> -->
        <div
          class="w-100 my-5 row gap-5 justify-content-center align-items-center text-center"
        >
          <img
            class="h-auto col px-4 px-md-0"
            [src]="currentModuleDetails.primaryImage"
            style="max-width: 600px"
          />
          <img
            *ngIf="currentModuleDetails.secondaryImage"
            class="h-auto col px-4 px-md-0"
            [src]="currentModuleDetails.secondaryImage"
            style="max-width: 600px"
          />
          <img
            *ngIf="currentModuleDetails?.verticalImage"
            class="h-auto w-100 px-4 px-md-0"
            [src]="currentModuleDetails.verticalImage"
            style="max-width: 200px"
          />
        </div>
      </div>
      <mat-divider class="w-100"></mat-divider>
      <div class="p-3 p-md-5 ms-lg-5">
        <ul class="m-0">
          <ng-container *ngFor="let item of currentModuleDetails.description">
            <li class="lh-base fs-6" [innerHTML]="item"></li>
          </ng-container>
        </ul>
      </div>
      <mat-divider class="w-100"></mat-divider>
      <div
        class="my-3 w-100 d-flex flex-column justify-content-center align-items-center fw-bold"
      >
        <h5 class="m-0 text-center">
          {{ subMessage }}
        </h5>
      </div>
      <mat-divider class="w-100"></mat-divider>
      <div
        class="my-3 w-100 d-flex flex-column justify-content-center align-items-center"
      >
        <div class="d-flex flex-row align-items-center gap-3">
          <!-- <mat-icon class="smaller-text dots">fiber_manual_record</mat-icon>
          <mat-icon class="small-text dots">fiber_manual_record</mat-icon>
          <mat-icon class="dots">fiber_manual_record</mat-icon> -->
          <h5 class="m-0 text-primary">Get it now</h5>
          <!-- <mat-icon class="dots">fiber_manual_record</mat-icon>
          <mat-icon class="small-text dots">fiber_manual_record</mat-icon>
          <mat-icon class="smaller-text dots">fiber_manual_record</mat-icon> -->
        </div>
        <div
          class="w-auto d-flex flex-column justify-content-center align-items-start fw-bold"
        >
          <table>
            <tr>
              <td class="p-2"><mat-icon>email</mat-icon></td>
              <td class="p-2">{{ emailAddress }}</td>
              <td class="p-2">
                <mat-icon
                  [matTooltip]="toolTipMessage"
                  (click)="copyToClipboard(emailAddress)"
                  >filter_none</mat-icon
                >
              </td>
              <td class="p-2">
                <mat-icon
                  matTooltip="Send Email via Gmail"
                  (click)="sendEmail()"
                  >send</mat-icon
                >
              </td>
            </tr>
            <tr>
              <td class="p-2"><mat-icon>contacts</mat-icon></td>
              <td class="p-2">{{ primaryContact }}</td>
              <td class="p-2">
                <mat-icon
                  [matTooltip]="toolTipMessage"
                  (click)="copyToClipboard(primaryContact)"
                  >filter_none</mat-icon
                >
              </td>
            </tr>
            <tr>
              <td class="p-2"><mat-icon>contacts</mat-icon></td>
              <td class="p-2">{{ secondaryContact }}</td>
              <td class="p-2">
                <mat-icon
                  [matTooltip]="toolTipMessage"
                  (click)="copyToClipboard(secondaryContact)"
                  >filter_none</mat-icon
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="py-5" *ngIf="showDetails == false">
      <mat-spinner
        class="mx-auto"
        [diameter]="50"
        color="primary"
      ></mat-spinner>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
