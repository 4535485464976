import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.log = () => {};
  console.warn = () => {};
  // console.error = () => {};
  console.info = () => {};
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.info("Error:", err));
