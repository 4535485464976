import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { filter, Subscription } from 'rxjs';
import { DASHBOARD_MENU } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AverageHour } from 'src/app/shared/models/average-hour';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { Settings } from 'src/app/shared/models/settings/settings';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { WidgetFormComponent } from '../dashboard/components/widget-form/widget-form.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public menu: SubMenu[] = DASHBOARD_MENU;
  public headerForm: FormGroup;
  public deviceTypes: Array<DeviceType> = [];
  public averageHours: Array<AverageHour> = [];
  public maxDate: moment.Moment;
  public minDate!: moment.Moment;
  public selectedMenuTab: SubMenu | undefined;
  private forceTableDataFetch: boolean = false;
  private subscriptions: Subscription[] = [];
  public isTourMode: boolean = false;
  public interval!: NodeJS.Timer;
  public uiLoadSubscription!: Subscription;
  public isTourCompleted: boolean = false;
  public editMode: boolean = false;
  public deviceForWidget!: DeviceDetails | undefined;
  public devices: DeviceDetails[] = this.deviceService.registeredDevices ?? [];
  public deviceTags: string[] = this.deviceService.getUniqueDeviceTags(
    this.devices
  );

  private readonly steps: IMdStepOption[] = [
    {
      stepId: '0',
      anchorId: 'dashboard-tour-0',
      title: 'Welcome to new dashboard!',
      content:
        'Follow this guided walkthrough to understand to increase your productivity with the widgets.',
      enableBackdrop: true,
      nextBtnTitle: 'Start Tour',
      stepDimensions: {
        width: '325px',
      },
    },
    {
      stepId: '1',
      anchorId: 'dashboard-tour-1',
      title: 'Customizing Widgets',
      content:
        'Click on the Edit icon to customize the widgets based on your device types.',
      stepDimensions: {
        width: '275px',
      },
    },
    {
      stepId: '2',
      anchorId: 'dashboard-tour-29',
      title: 'Resizing Widgets',
      content:
        'Adjust widget size, Hover over the widget and use the arrows on the edge to resize it.',
    },
    {
      stepId: '3',
      anchorId: 'dashboard-tour-300',
      title: 'Removing Widgets',
      content:
        'Click on the Close in the widget to remove it from the dashboard.',
      delayBeforeStepShow: 700,
    },
    {
      stepId: '4',
      anchorId: 'dashboard-tour-4',
      title: 'Arranging Widgets',
      content:
        'You can arrange the widgets in any sequence you prefer. Simply drag and drop the widget borders to rearrange.',
      stepDimensions: {
        width: '500px',
        maxWidth: '500px',
      },
      placement: {
        yPosition: 'below',
        xPosition: 'before',
      },
      delayBeforeStepShow: 500,
    },
    {
      stepId: '5',
      anchorId: 'dashboard-tour-5',
      title: 'Saving Your Settings',
      content:
        'Once you’ve customized everything, click on the Save icon to lock in your settings.',
    },
  ];
  moduleAccess: any;

  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    private formsService: FormsService,
    public deviceService: DeviceService,
    private dashboardService: DashboardService,
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private _cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tourService: TourService,
    private userService: UserService
  ) {
    this.headerForm = this._fb.group({});
    this.averageHours = this.commonService.getAverageHours({
      valueInSeconds: true,
    });
    this.maxDate = this.customMomentService.moment();
  }

  ngOnInit(): void {
    this.devices = this.deviceService.sortDevices(
      this.deviceService.registeredDevices!,
      'deviceId'
    );
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.isTourCompleted = Boolean(
      this.commonService.getUserSettings()?.dashboardTourCompleted
    );
    const isScreenSizeCompatible = window.innerWidth > 991; //show dashboard tour only if screen size greater than 991

    if (!this.isTourCompleted && isScreenSizeCompatible) {
      this.uiLoadSubscription = this.dashboardService.uiLoaded$.subscribe(
        (res) => {
          //if user goes to table view and than comeback to widget view than check tour status
          if (res && !this.isTourCompleted) {
            this.interval = setTimeout(() => {
              this.startDashboardTour();
            }, 1000);
          }
        }
      );
    }

    if (
      this.deviceService.currentDevice?.deviceType === 'BEANAIR' ||
      this.deviceService.currentDevice?.deviceType === 'OMNIDOTS'
    ) {
      this.deviceService.removeDeviceGlobally();
    }

    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.onTabChange(
      this.menu.find((tab) => this.router?.url?.includes(tab.url)) ??
        this.menu[0]
    );

    this.headerForm = this._fb.group({
      device: [
        this.deviceService.currentDevice ??
          this.deviceService.registeredDevices?.[0] ??
          null,
      ],
      startDate: [
        this.customMomentService.moment().startOf('day').subtract(1, 'day'),
      ],
      endDate: [this.customMomentService.moment().endOf('day')],
      average: [
        this.averageHours.find(
          (averageHour: AverageHour) => averageHour.value === 3600
        ) ?? this.averageHours[0],
      ],
    });

    this.subscriptions.push(
      this.headerForm.controls.device.valueChanges.subscribe({
        next: (device) => {
          if (this.selectedMenuTab?.url === 'widget') {
            this.deviceService.setupDeviceGlobally(device);
            this.onDeviceSelectionChange();
          } else {
            if (this.forceTableDataFetch) {
              this.minDate = this.deviceService.getMinDateOfDevice(
                this.headerForm.value?.device
              );
              // this.tableFormChange();
              this.forceTableDataFetch = false;
            }
          }
        },
      })
    );

    this.subscriptions.push(
      this.deviceService.getDevices$.subscribe({
        next: () => {
          this._cdr.detectChanges();
        },
      })
    );

    this.maxDate = this.customMomentService.moment();
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationEnd || event instanceof NavigationStart
          )
        )
        .subscribe((event) => {
          if (event instanceof NavigationStart) {
            if (event.url.endsWith('widget') || event.url.endsWith('table')) {
              this.router.navigateByUrl(
                `/dashboard/${event.url.split('/').pop()}/${
                  this.deviceService.currentDevice?.deviceId ??
                  this.deviceService.registeredDevices?.[0]?.deviceId
                }`
              );
            } else if (event.url.endsWith('dashboard')) {
              this.router.navigateByUrl(
                `/dashboard/${this.selectedMenuTab?.url ?? 'widget'}/${
                  this.deviceService.currentDevice?.deviceId ??
                  this.deviceService.registeredDevices?.[0]?.deviceId
                }`
              );
            }
          } else if (event instanceof NavigationEnd) {
            if (event.urlAfterRedirects.includes('widget')) {
              this.onTabChange(this.menu.find((tab) => tab.url === 'widget'));
              // use the device id from url
              let urlDeviceId = event.urlAfterRedirects.split('/').pop();
              let urlDevice = this.deviceService.registeredDevices?.find(
                ({ deviceId }: DeviceDetails) => deviceId === urlDeviceId
              );
              if (urlDevice) {
                if (
                  this.headerForm.controls.device.value.deviceId !==
                  urlDevice.deviceId
                ) {
                  this.deviceForWidget = urlDevice;
                  this.headerForm.controls.device.setValue(urlDevice);
                }
              }
            } else if (event.urlAfterRedirects.includes('table')) {
              this.onTabChange(this.menu.find((tab) => tab.url === 'table'));
              // use the device id from url
              let urlDeviceId = event.urlAfterRedirects.split('/').pop();
              let urlDevice = this.deviceService.registeredDevices?.find(
                ({ deviceId }: DeviceDetails) => deviceId === urlDeviceId
              );
              if (urlDevice) {
                if (
                  this.headerForm.controls.device.value.deviceId !==
                    urlDevice.deviceId ||
                  this.deviceService.currentDevice?.deviceId !==
                    urlDevice.deviceId
                ) {
                  this.headerForm.controls.device.setValue(urlDevice);
                }
              }
            }
          }
        })
    );

    if (this.selectedMenuTab) {
      this.onTabChange(this.selectedMenuTab);

      let urlDeviceId =
        this.activatedRoute.firstChild?.snapshot.params.deviceId;

      let urlDevice =
        this.deviceService.registeredDevices?.find(
          (device) => device.deviceId === urlDeviceId
        ) ??
        this.deviceService.currentDevice ??
        this.deviceService.registeredDevices?.[0];

      if (!urlDeviceId?.length) {
        this.router.navigateByUrl(
          `/dashboard/${this.selectedMenuTab.url}/${urlDevice?.deviceId}`
        );
      }

      if (urlDevice) {
        this.forceTableDataFetch = true;
        this.deviceForWidget = urlDevice;
        this.headerForm.controls.device.setValue(urlDevice);
        this.tableFormChange();
      }
    }
    this.moduleAccess = this.commonService.moduleAccessibility(1002);
  }

  public onTabChange(selectedMenuTab: SubMenu | undefined) {
    if (!selectedMenuTab) {
      return;
    }
    this.selectedMenuTab = selectedMenuTab;
  }

  public onDeviceSelectionChange() {
    if (
      this.router.url.split('/').pop() !==
      this.deviceService.currentDevice?.deviceId
    ) {
      this.router.navigateByUrl(
        `/dashboard/${this.selectedMenuTab?.url}/${this.deviceService.currentDevice?.deviceId}`
      );
    }
  }

  public tableFormChange() {
    this.headerForm.get('device')?.setValue(this.deviceService?.currentDevice)
    this.deviceService.setupDeviceGlobally(
      this.headerForm.get('device')?.value
    );
    this.router.navigateByUrl(
      `/dashboard/${this.selectedMenuTab?.url}/${this.deviceService.currentDevice?.deviceId}`
    );
    this.updateTableComponent();
  }

  // Sets the selected device globally when the user clicks on a device in the header dropdown
  onDeviceSelectionInWidgetView(device: DeviceDetails) {
    // this.headerForm.get('device')?.setValue(this.deviceService?.currentDevice)
    if (device && device?.deviceId) {
      this.deviceService.setupDeviceIdGlobally(device?.deviceId);
      this.router.navigateByUrl(
        `/dashboard/${this.selectedMenuTab?.url}/${device?.deviceId}`
      );
    }
  }

  // Emits the updated device list when the user selects or deselects tags from the header dropdown
  onTagSelection(devices: DeviceDetails[]) {
    this.dashboardService.onHeaderTagChange.next(devices);
  }

  updateTableComponent() {
    this.minDate = this.deviceService.getMinDateOfDevice(
      this.headerForm.value?.device
    );
    const formValue = {
      device: this.headerForm.get('device')?.value,
      startDate: { moment: this.headerForm.get('startDate')?.value },
      endDate: { moment: this.headerForm.get('endDate')?.value },
      average: this.headerForm.get('average')?.value,
    };
    this.dashboardService.updateTableFilters(formValue);
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  addWidget() {
    if (this.deviceService.currentDevice?.deviceType) {
      let deviceTypeId = DeviceUtil.getDeviceTypeId(
        this.deviceService.currentDevice?.deviceType,
        this.deviceTypes
      );

      if (deviceTypeId) {
        let selectedWidgets = Object.keys(
          this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
            ?.settings?.widgetsConfig?.[deviceTypeId] ?? {}
        ).map((widgetId: string) => parseInt(widgetId));

        this.formsService
          .openForm(WidgetFormComponent, {
            deviceTypeId: DeviceUtil.getDeviceTypeId(
              this.deviceService.currentDevice?.deviceType,
              this.deviceTypes
            ),
            selectedWidgets,
          })
          .subscribe((result) => {});
      }
    }
  }

  editWidgetsView() {
    this.dashboardService.enableDisableWidgetsViewEditMode('edit');
    this.editMode = true;
  }

  saveWidgetsView() {
    this.dashboardService.enableDisableWidgetsViewEditMode('save');
    this.editMode = false;
  }

  resetWidgetsView() {
    this.dashboardService.enableDisableWidgetsViewEditMode('reset_default');
    this.editMode = false;
  }

  cancelWidgetsViewEdits() {
    if (this.editMode) {
      this.dashboardService.enableDisableWidgetsViewEditMode('cancel');
      this.editMode = false;
    }
  }

  startDashboardTour() {
    const tour = this.dashboardService.dashboardTourStatus$.subscribe((res) => {
      this.isTourMode = res;
    });

    this.subscriptions.push(tour);

    this.tourService.initialize(this.steps, {
      disableScrollToAnchor: true,
      disablePageScrolling: true,
      delayBeforeStepShow: 100,
      enableBackdrop: true,
      backdropConfig: {
        zIndex: '1007',
      },
    });

    this.subscriptions.push(
      this.tourService.stepShow$.subscribe((stepParams) => {
        this.handleStepShow(stepParams);
      })
    );

    this.subscriptions.push(
      this.tourService.stepHide$.subscribe((stepParams) => {
        this.handleStepHide(stepParams);
      })
    );

    this.subscriptions.push(
      this.tourService.end$.subscribe(() => {
        this.handleTourEnd();
      })
    );

    setTimeout(() => {
      this.dashboardService.dashboardTourStatus.next(true);
      this.tourService.start();
    });
  }

  private handleStepShow(stepParams: any): void {
    // Handle logic when a step is shown
    if (stepParams.step.stepId === '2') {
      if (!this.editMode) this.editWidgetsView();
      this.dashboardService.resizeWidget.next(true);
    }
    //when user go from step 2 to step 3
    if (stepParams.step.stepId === '3') {
      if (!this.editMode) this.editWidgetsView();
    }
    //when user go from step 3 to step 4
    if (stepParams.step.stepId === '4') {
      if (!this.editMode) this.editWidgetsView();
      this.dashboardService.swapWidgets.next(true);
    }
    if (stepParams.step.stepId === '5') {
      if (!this.editMode) this.editWidgetsView();
    }
  }

  private handleStepHide(stepParams: any): void {
    if (stepParams.step.stepId === '2') {
      this.dashboardService.resizeWidget.next(false);
      if (stepParams.direction === 1) {
        this.cancelWidgetsViewEdits();
      }
    }

    //when user go from step 3 to step 4
    if (stepParams.step.stepId === '4') {
      if (stepParams.direction === 1) {
        this.dashboardService.swapWidgets.next(false);
      }
    }

    if (stepParams.step.stepId === '5') {
      if (stepParams.direction === 0) {
        this.cancelWidgetsViewEdits();
      } else if (stepParams.direction === 1) {
        this.dashboardService.swapWidgets.next(false);
      }
    }
  }

  private handleTourEnd(): void {
    // Logic to handle the end of the tour
    this.dashboardService.dashboardTourStatus.next(false);
    setTimeout(() => {
      this.cancelWidgetsViewEdits();
      this.updateUserPrfoile();
    }, 100);
  }

  getDashboardModuleOptions(key: any) {
    return this.commonService.getModuleAccessOptionsOnRoute(
      key,
      this.moduleAccess
    );
  }

  updateUserPrfoile() {
    let userSettings: Settings = this.commonService.getUserSettings();
    let settings = { ...userSettings, dashboardTourCompleted: true };

    let payload: RequestData.UpdateUser = {
      update: {
        settings: settings,
      },
    };

    this.userService.updateProfile(payload).subscribe({
      next: () => {
        this.uiLoadSubscription.unsubscribe();
        this.userService.getUserProfile().subscribe({
          next: () => {
            //update tour status from profile
            this.isTourCompleted = Boolean(
              this.commonService.getUserSettings()?.dashboardTourCompleted
            );
          },
        });
      },
      error: () => {
        console.error('Error while updating dashboard tour status');
      },
    });
  }

  ngOnDestroy(): void {
    this.tourService.end();
    clearTimeout(this.interval);
    if (this.uiLoadSubscription) this.uiLoadSubscription.unsubscribe();
    this.subscriptions.forEach(
      (subscription: Subscription) =>
        subscription && !subscription.closed && subscription.unsubscribe()
    );
  }
}
