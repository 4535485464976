import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DisplayDetails } from 'src/app/shared/models/display/display-details';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DisplayService } from 'src/app/shared/services/display.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DisplayFormComponent } from '../../components/display-form/display-form.component';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-display-list',
  templateUrl: './display-list.component.html',
  styleUrls: ['./display-list.component.scss'],
})
export class DisplayListComponent implements OnInit {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  displays: DisplayDetails[] = [];
  displayedColumns: string[] = [];
  displayTitle: string = 'Display List';

  defaultColumns: any[] = [];

  public dialogCompInstance!: ConfirmationPopupComponent;
  private subscriptions: Subscription[] = [];
  public noData: ContentUnavailable = {
    isConfiguration: true,
    majorText: 'Start with your first display.',
    linkText: 'Configure Display',
    minorText: 'and Proceed',
    createFunc: () => this.addDisplay(),
    svgImage: AppConstants.NO_DATA,
  };
  moduleAccess: any;
  constructor(
    private dialog: MatDialog,
    private displayService: DisplayService,
    private formsService: FormsService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService,
    private clipboard: Clipboard,
    private confirmationPopupService: ConfirmationPopupService,
    private commonService: CommonService
  ) {
    this.defaultColumns = this.generateDefaultColumns()
  }

  ngOnInit(): void {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.loadData();

    this.subscriptions.push(
      this.displayService.refreshDisplayList$.subscribe({
        next: (res) => {
          if (res) {
            this.loadData();
          }
        },
      })
    );
    this.moduleAccess = this.commonService.moduleAccessibility(1006);
    this.defaultColumns = this.generateDefaultColumns();
  }

  loadData(): void {
    this.loadTable.next(false);
    this.displayService.getUserDisplays().subscribe({
      next: (res: DisplayDetails[]) => {
        this.displays = res;
        this.loadTable.next(true);
      },
      error: (err) => {
        console.info('Error:', err);
        this.loadTable.next(true);
      },
    });
  }

  generateDefaultColumns () {
    return [{
      columnDef: 'actions',
      header: '',
      cell: (element: DisplayDetails) => `${element.displayId}`,
      parameter: false,
      selected: false,
      icon: 'more_vert',
      options: [
        {
          label: `<span class="material-symbols-outlined">content_copy</span>&nbsp Copy Iframe code`,
          action: (display: DisplayDetails) => this.copyIframeCode(display),
        },
        {
          label: `<span class="material-symbols-outlined">open_in_new</span>&nbsp Open In New Tab`,
          action: (display: DisplayDetails) =>
            this.openDisplayInNewTab(display),
        },
        ...['edit','delete'].filter((permission)=>
          this.commonService.getModuleAccessOptionsOnRoute(permission,this.moduleAccess))
        .map((permission)=>{
          switch (permission) {
            case 'edit':
             return {
                label: `<span class="material-symbols-outlined">edit</span>&nbsp Edit`,
                action: (display: DisplayDetails) => this.editDisplay(display),
              };
              case 'delete':
              return {
                label: `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
                action: (display: DisplayDetails) =>
                  this.openDialog(display, () => {
                    this.deleteDisplay(display);
                  }),
              };
              default:
                return null;
          }
        }).filter((option) => option !== null)
      ],
    },
    {
      columnDef: 'displayId',
      header: 'Display ID',
      cell: (element: DisplayDetails) => `${element.displayId}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'display_label',
      header: 'Display Name',
      cell: (element: DisplayDetails) => `${element.display_label}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'screens',
      header: 'Total Screens',
      cell: (element: DisplayDetails) => `${element.screens.length}`,
      parameter: false,
      selected: false,
    }]
  }
  exportTableDataToCSV(filteredDisplays: any): void {
    if (!filteredDisplays || !filteredDisplays.length) {
      console.info('No data available to export');
      return;
    }
    const csvRows = [];
    const headers = this.defaultColumns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    ); // Remove HTML tags from headers
    csvRows.push(headers.join(','));
    filteredDisplays.forEach((display: any) => {
      const values = this.defaultColumns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;
        if (columnKey === 'screens') {
          cellValue = display.screens.length;
        } else {
          cellValue = display[columnKey];
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });
      csvRows.push(values.join(','));
    });

    const fileName = `Displays_${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  copyIframeCode(display: DisplayDetails) {
    if (display.share_url?.length) {
      this.clipboard.copy(
        `<iframe width='1366' height='768' src='${display.share_url}' frameborder='0' allowfullscreen></iframe>`
      );
      this.notificationService.showSnackBar('Copied to clipboard', 'info');
    } else {
      this.notificationService.showSnackBar(
        'Unable to copy. Retry after refreshing the page...',
        'error'
      );
    }
  }

  openDisplayInNewTab(display: DisplayDetails) {
    if (display.share_url?.length) {
      window.open(display.share_url, '_blank');
    } else {
      this.notificationService.showSnackBar(
        'Unable to open display. Retry after refreshing the page...',
        'error'
      );
    }
  }

  editDisplay(display: DisplayDetails) {
    this.subscriptions.push(
      this.formsService
        .openForm(DisplayFormComponent, display, 'xl')
        .subscribe({
          next: (res) => {
            // console.log(res);
          },
          error: (err: any) => {
            console.info('Error: ', err);
          },
        })
    );
  }

  deleteDisplay(display: DisplayDetails) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.displayService.deleteDisplay(display.displayId).subscribe({
      next: (res) => {
        if (res) {
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message = res?.length
            ? res
            : 'Display deleted successfully';
          setTimeout(() => {
            this.dialog.closeAll();
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Deleting Display. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  openDialog(display: DisplayDetails, onConfirmation: () => void): void {
    const dynamicData = {
      title: 'Delete Display',
      message: `Are you sure you want to delete this display (${display.display_label})?`,
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: onConfirmation,
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  addDisplay() {
    this.formsService
      .openForm(DisplayFormComponent, undefined, 'xl')
      .subscribe({
        next: (res) => {
          // console.log(res);
        },
        error: (err: any) => {
          console.info('Error: ', err);
        },
      });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}
