import { Injectable } from '@angular/core';
import {
  DrawerPosition,
  MtxDrawer,
  MtxDrawerRef,
} from '@ng-matero/extensions/drawer';
import { Subject, map } from 'rxjs';

export type DrawerSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  drawerRef!: MtxDrawerRef;

  constructor(private drawer: MtxDrawer) {}

  openForm(component: any, data: any = undefined, size: DrawerSize = "md") {
    let width: any;
    let position: DrawerPosition = window.innerWidth > 600 ? 'right' : 'bottom';
    let isHorizontal = window.innerWidth > window.innerHeight ? true : false;
    if (size === 'sm') {
      width = isHorizontal ? '20vw' : '40vw';
    } else if (size === 'md') {
      width = isHorizontal ? '35vw' : '50vw';
    } else if (size === 'lg') {
      width = isHorizontal ? '40vw' : '60vw';
    } else if (size === 'xl') {
      width = isHorizontal ? '60vw' : '70vw';
    } else if (size === 'xxl') {
      width = isHorizontal ? '80vw' : '90vw';
    }

    const drawerRef = this.drawer.open(component, {
      width: width,
      maxWidth: '100vw',
      height: '84vh',
      maxHeight: '100vh',
      data: data,
      position: position,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      // panelClass: ['mat-mdc-card','mdc-card'],
      panelClass: ['mat-bottom-sheet-container'],
    });
    this.drawerRef = drawerRef;
    return drawerRef.afterDismissed();
  }
  closeForm(result: any = false) {
    this.drawerRef?.dismiss(result);
  }
}
