import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

export const loginValidationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  if (localStorageService.getValue(LocalStorageConstants.TOKEN)) {
    return true;
  }
  router.navigate(['/login']);
  return false;
};