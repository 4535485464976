import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegrationRoutingModule } from './integration-routing.module';
import { IntegrationComponent } from './integration.component';
import { ApiInfoComponent } from './pages/api-info/api-info.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [IntegrationComponent, ApiInfoComponent],
  imports: [
    CommonModule,
    SharedModule,
    IntegrationRoutingModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class IntegrationModule {}
