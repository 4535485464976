import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { ALERT_MENU } from 'src/app/shared/constants/app-menu';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AlertData } from 'src/app/shared/models/alerts/alert-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { SubMenu } from 'src/app/shared/models/menu/sub-menu';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import {
  DrawerSize,
  FormsService,
} from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AlertConfigurationFormComponent } from './components/alert-configuration-form/alert-configuration-form.component';
import { ConfigurationListComponent } from './pages/configuration-list/configuration-list.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit, OnDestroy {
  menu: SubMenu[] = ALERT_MENU;
  public deviceTypes!: Array<DeviceType>;
  public headerForm!: FormGroup;
  public currentDeviceType!: number;
  public alertsList!: AlertData.Get[];
  public isNoAlertCreated: boolean = false;
  public dialogCompInstance!: ConfirmationPopupComponent;
  public migrateAlertAndDisable: boolean = true;
  public userInfo: RequestData.UserInfo =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER);
  public startDate: any;
  public endDate: any;
  public remainingSms!: number;

  constructor(
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    public deviceService: DeviceService,
    private commonService: CommonService,
    private alertsService: AlertsService,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private customMomentService: CustomMomentService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.alertsService.isResponse$.subscribe((res) => {
      if (res) {
        this.alertsList = this.alertsService.allAlerts;
        this.getDeviceTypeWithAlerts();

        let deviceType = this.deviceService.setUpDeviceTypeForModule(
          this.deviceTypes,
          this.deviceService.registeredDevices!
        );
        if (!deviceType) {
          this.headerForm.get('deviceType')?.setValue(null);
          this.headerForm.get('deviceType')?.disable();
          this.isNoAlertCreated = true;
          this.alertsService.alertsList.next([]);
        } else if (deviceType) {
          this.headerForm.get('deviceType')?.enable();
          this.isNoAlertCreated = false;
          this.alertsService.getSelectedDeviceType(deviceType);
          this.headerForm.patchValue({
            deviceType,
          });
        }
      }
    });

    this.alertsService.selectedDeviceType$.subscribe((type) => {
      if (type) this.currentDeviceType = type.deviceTypeId;
    });

    this.headerForm = this.formBuilder.group({
      deviceType: [{ value: '', disabled: this.isNoAlertCreated }],
      startDate: this.customMomentService.moment().subtract(1, 'day'),
      endDate: this.customMomentService.moment(),
    });
    this.subscribeToFormControls();
    this.getRemainingSmsCount();
  }

  getRemainingSmsCount() {
    let info = this.commonService.getUser().info;
    try {
      this.remainingSms = info.max_sms_count - info.used_sms_count;
    } catch {
      console.error('Error while getting Remaining Sms Count');
      this.remainingSms = 0;
    }
  }

  addAlert() {
    let formSize: DrawerSize = 'lg';
    if (window.innerWidth > 1700) {
      formSize = 'md';
    } else if (window.innerWidth > 1165) {
      formSize = 'lg';
    } else {
      formSize = 'xl';
    }

    this.formsService
      .openForm(AlertConfigurationFormComponent, '', formSize)
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  subscribeToFormControls() {
    this.headerForm.controls.deviceType.valueChanges.subscribe((type) => {
      if (type) {
        this.alertsService.getSelectedDeviceType(type);
        const devices = this.deviceService.registeredDevices;
        const device = devices?.find(
          (device) => device.deviceType === type?.key
        )!;
        this.deviceService.setupDeviceGlobally(device);
      }
    });

    this.headerForm.controls.startDate.valueChanges.subscribe((date) => {
      if (date) {
        this.startDate = date;
        this.endDate = null;
      }
    });

    this.headerForm.controls.endDate.valueChanges.subscribe((date) => {
      if (date) {
        this.endDate = date;
      }
    });

    this.headerForm.valueChanges.subscribe(() => {
      if (
        this.startDate &&
        this.endDate &&
        this.router.url.includes('data-alerts')
      ) {
        this.alertsService.datesForDataAlerts.next({
          start: this.startDate,
          end: this.endDate,
        });

        this.alertsService.loadDataAlerts.next(false);

        this.alertsService
          .getDataAlerts(this.startDate.unix(), this.endDate.unix())
          .subscribe({
            next: () => {
              this.alertsService.getAlertList().subscribe({
                next: () => {
                  this.alertsService.loadDataAlerts.next(true);
                },
              });
            },
            error: (err) => {
              console.info('Error:', err);
              this.notificationService.showSnackBar(err, 'error');
            },
          });
      }
    });
  }

  getDeviceType(deviceType: DeviceType): string {
    return deviceType ? deviceType.label.trim() : '';
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  getDeviceTypeWithAlerts() {
    let tempSet = new Set();
    this.alertsList.forEach((data: AlertData.Get) => {
      tempSet.add(data.deviceTypeId);
    });
    const dt: Array<DeviceType> = this.commonService.getUserDeviceTypes();
    let tempArray = Array.from(tempSet);
    this.deviceTypes = dt.filter((data) =>
      tempArray.includes(data.deviceTypeId)
    );
  }

  onOutletLoaded(component: ConfigurationListComponent) {
    if (component instanceof ConfigurationListComponent) {
      component.addConfigFunc = () => this.addAlert();
    }
  }

  migrateAlert() {
    let payload: AlertData.Migrate = {
      disableOldAlerts: this.migrateAlertAndDisable,
    };

    const user = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    let settings = user.settings;
    settings = { ...settings, isAlertMigrated: true };

    let userPayload: RequestData.UpdateUser = {
      update: {
        settings: settings,
      },
    };

    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.alertsService.migrateTerminalAlerts(payload).subscribe({
      next: (res) => {
        if (res) {
          // updating user profile to set 'isAlertMigrated' key
          this.userService.updateProfile(userPayload).subscribe({
            next: (response) => {
              if (response) {
                //getting user profile to get updated data
                this.userService.getUserProfile().subscribe({
                  next: () => {
                    this.userInfo = this.localStorageService.getParsedValue(
                      LocalStorageConstants.OZ_USER
                    );
                  },
                  error: () => {
                    console.info('Error While Getting User Profile');
                    this.notificationService.showSnackBar(
                      'Error while fetching User Profile',
                      'error'
                    );
                  },
                });
              }
            },
            error: (err) => {
              console.info('Error:', err, 'Error While Updating User Profile');
              this.notificationService.showSnackBar(
                'Error while updating User Profile',
                'error'
              );
            },
          });

          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Alerts Migrated Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.alertsService.getAlertList().subscribe({
              next: (res) => {
                if (res) this.alertsService.isResponse.next(true);
              },
            });
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Migrating Alert. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }

  openDialog(): void {
    const dynamicData = {
      title: 'Migrate Alert',
      message:
        'By performing this action, your existing Alert Configuration & Settings will be moved to Envizom 2.0',
      icon: 'trending_up',
      checkBoxTitle:
        'Disable configuration & settings in existing Envizom 1.0/Terminal',
      alertMessage:
        ' If you uncheck, you will consume twice the number of Emails and Alerts',
      buttonconfig: [
        {
          text: 'MIGRATE',
          onClickCallback: () => {
            this.migrateAlert();
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.dialogCompInstance.checkBoxTrigger.subscribe((res) => {
      this.migrateAlertAndDisable = res;
    });
    this.confirmationPopupService.getDialogRef(dialogRef);
  }

  isDataAlertsView() {
    return this.router.url.includes('data-alerts');
  }

  isConfigurationsView() {
    return this.router.url.includes('alert-list');
  }

  ngOnDestroy() {
    this.alertsService.isResponse.next(false);
  }
}
