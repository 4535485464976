import { Injectable } from '@angular/core';
import { max, of } from 'rxjs';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AverageHour } from '../models/average-hour';
import { DeviceType } from '../models/device-type/device-type';
import { LoginData } from '../models/login-data';
import { Settings } from '../models/settings/settings';
import { CommonUtil } from '../utils/common-utils';
import { LocalStorageService } from './local-storage.service';
import { CustomMomentService } from './custom-moment.service';
import { last } from 'lodash';
import { DeviceData } from '../models/device/device-data';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private localStorageService: LocalStorageService,
    private customMomentService: CustomMomentService
  ) {}

  getUserDeviceTypes(): Array<DeviceType> {
    return this.getDeviceTypes();
  }

  getUserDeviceTypesWithVibration(): Array<DeviceType> {
    return this.getDeviceTypes({
      includeVibrationTypes: true,
    });
  }

  getAllDeviceTypes(): Array<DeviceType> {
    return this.getDeviceTypes({
      includeVibrationTypes: true,
      getAllDeviceTypes: true,
    });
  }

  getDeviceTypes({
    includeVibrationTypes,
    getAllDeviceTypes,
  }: {
    includeVibrationTypes?: boolean;
    getAllDeviceTypes?: boolean;
  } = {}): DeviceType[] {
    let deviceTypes = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );
    if (!deviceTypes || getAllDeviceTypes) {
      deviceTypes = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
    }
    if (!includeVibrationTypes) {
      deviceTypes = deviceTypes?.filter?.(
        (deviceType: DeviceType) =>
          deviceType.key !== 'OMNIDOTS' && deviceType.key !== 'BEANAIR'
      );
    }
    return deviceTypes ?? [];
  }

  getAllAQIs() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.aqiIndex;
  }

  getAllUnits() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.units;
  }

  getAllAQI() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
  }

  getFields() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_FIELDS
    );
  }

  getLimits() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ADMIN_LIMITS
    );
  }

  getUser() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
  }

  getUserRole(): number {
    return this.getUser()?.role;
  }

  getUserAQI() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.aqiIndex;
  }

  getUserSettings(): Settings {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).settings;
  }

  getNotifLastVisit(topic: string, isHeaderNofif: boolean = false): number {
    let value = 0;
    const allLastSeen = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_LAST_SEEN
    );

    //if header notification
    if (isHeaderNofif) {
      const headerNotifValueLocalStorage = this.localStorageService.getValue(
        LocalStorageConstants.OZ_HEADER_NOTIF_LAST_VISIT
      );
      const notifValueSettings = allLastSeen?.[topic];
      value = headerNotifValueLocalStorage ?? notifValueSettings;

      //take the maxmimum value if both values are available
      if (headerNotifValueLocalStorage && notifValueSettings) {
        of(headerNotifValueLocalStorage, notifValueSettings)
          .pipe(max())
          .subscribe((x) => {
            value = x;
          });
      }

      //if value is not available in local storage but available in last seen object than update local storage
      if (!headerNotifValueLocalStorage && notifValueSettings) {
        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_HEADER_NOTIF_LAST_VISIT,
          notifValueSettings
        );
      }
    } else {
      value = allLastSeen?.[topic];
    }

    if (value) {
      return Number(value);
    } else return 0;
  }

  getCurrentTheme(): string {
    return this.localStorageService.getValue(
      LocalStorageConstants.CURRENT_THEME
    );
  }

  // Get the user's time format from local storage, default is 24 hours if not set
  public getTimeFormat(): number {
    let settings = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.settings;
    return settings?.time_format ? settings.time_format : 24;
  }

  public getDateTimeFormat(isVibration: boolean = false): string {
    if (isVibration) {
      return this.getTimeFormat() === 12
        ? 'hh:mm:ss A | DD-MM-YY'
        : 'HH:mm:ss | DD-MM-YY';
    }
    return this.getTimeFormat() === 12
      ? 'hh:mm A, DD/MM/YY'
      : 'HH:mm , DD/MM/YY';
  }

  public getDateTimeFormatForGraph(): string {
    return this.getTimeFormat() === 12 ? 'DD/MM hh:mm A' : 'DD/MM HH:mm';
  }

  public getDateTimeFormatForToolTip(isVibration: boolean = false): string {
    if (isVibration) {
      return this.getTimeFormat() === 12
        ? 'DD MMM YYYY, hh:mm:ss.SSS A'
        : 'DD MMM YYYY, HH:mm:ss.SSS';
    }
    return this.getTimeFormat() === 12
      ? 'dddd, MMM D, YYYY - hh:mm a'
      : 'dddd, MMM D, YYYY - HH:mm';
  }

  // pass through function to get average hours
  public getAverageHours({
    includeRaw = true,
    includeMovingAvg = true,
    valueInSeconds = false,
    moduleId = 1001, //take overview module as default
  }: {
    includeRaw?: boolean;
    includeMovingAvg?: boolean;
    valueInSeconds?: boolean;
    moduleId?: number;
  }): AverageHour[] {
    // const userSettings = this.getUserSettings();

    //check if user has access to raw data
    const moduleOptions = this.moduleAccessibility(moduleId);

    const moduleRawStatus = moduleOptions?.['raw'] == false ? false : true;
    includeRaw = moduleRawStatus && includeRaw;

    return CommonUtil.getAverageHours({
      includeRaw,
      includeMovingAvg,
      valueInSeconds,
    });
  }

  public IsMasterOrgUser(): boolean {
    let user: LoginData = this.getUser();
    return user.role === 1 && user?.modules?.includes(1012) ? true : false;
  }

  getModuleAccessOptionsOnRoute(key: any, moduleAccess: any) {
    if (moduleAccess && key in moduleAccess) {
      return moduleAccess[key];
    }
    return true;
  }

  public getUserDefaultModules(): number[] {
    return this.IsMasterOrgUser()
      ? [1022, 1001, 1002, 1003, 1008] //utility bar, overview, dashboard, user, devices
      : [1022, 1001, 1002, 1003, 1008, 1012]; //project
  }

  moduleAccessibility(moduleId: number) {
    const accessControl = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_MODULE_ACCESS_CONTROL
    );
    const defaultOptions = {
      add: true,
      edit: true,
      download: true,
      delete: true,
    };

    const options = accessControl?.[moduleId]?.options;
    if (options && Object.keys(options)?.length) {
      return { ...defaultOptions, ...options };
    } else {
      return defaultOptions;
    }
  }

  groupDataByHour(data: DeviceData[]) {
    const segmentedData: Record<string, Record<string, DeviceData[]>> = {};
    data.forEach((dataPoint) => {
        const datetime = this.customMomentService.formatDate({epoch: dataPoint.payload.d.t, format: 'DD/MM/YY HH:mm'});
        const date = datetime.split(' ')[0];
        const hour = datetime.split(' ')[1].split(':')[0];
        if (!segmentedData[date]) {
            segmentedData[date] = {};
        }
        if (!segmentedData[date][hour]) {
            segmentedData[date][hour] = [];
        }
        segmentedData[date][hour].push(dataPoint);
    });
    const length = Object.keys(segmentedData).reduce((acc, date) => {
        return acc + Object.keys(segmentedData[date]).length;
    }, 0);
    return {data: segmentedData, length: length};
}


  groupDataByDate(data: DeviceData[]) {
    const segmentedData: Record<string, DeviceData[]> = {};
    
    data.forEach((dataPoint) => {
        const date = this.customMomentService.formatDate({epoch: dataPoint.payload.d.t, format: 'DD/MM/YY'});
        if (!segmentedData[date]) {
            segmentedData[date] = [];
        }
        segmentedData[date].push(dataPoint);
    });

    return {data: segmentedData, length: Object.keys(segmentedData).length};
}

}
