export class LocalStorageConstants {
  public static OZ_LOCAL_DEPLOYMENT: string = 'oz-local';
  public static OZ_RESTRICTED_USER: string = 'oz-restricted-user';
  public static IS_KYIV: string = 'IS_KYIV';
  public static TOKEN: string = 'oz-access-token';
  public static TOKEN_EXPIRES_IN: string = 'oz-token-expires-in';
  public static OZ_ADMIN_FIELDS: string = 'oz-admin-fields';
  public static OZ_ADMIN_LIMITS: string = 'oz-admin-limits';
  public static OZ_ULTRA_ADMIN: string = 'oz-ultra-admin';
  public static MODULE_ACCESS: string = 'oz-module-access';
  public static PARTICLEAPI: string = 'oz-particle-api';
  public static PARTICLETOKEN: string = 'oz-particle-token';
  public static MODULES: string = 'oz-modules';
  public static OZ_THEME: string = 'oz-theme';
  public static OZ_TOKEN_EXPIRE: string = 'oz-token-expire';
  public static USER_ID: string = 'oz-user-id';
  public static CLIENT_ID: string = 'oz-client-id';
  public static ORG_NAME: string = 'u-org-name';
  public static ORG_TIME_DIFF: string = 'u-org-timeDiff';
  public static OZ_USER: string = 'oz-user';
  public static OZ_AQI: string = 'oz-aqi';
  public static OZ_AVG_VIEW: string = 'oz-avg-view';
  public static ENCRYPTION_KEY: string = 'oz-encryption';
  public static TOOLBAR: string = 'oz-toolbar';
  public static CAPTCHASITEKEY: string = 'oz-captcha-key';
  public static SHOWCAPTCHA: string = 'oz-show-captcha';
  public static ISSECURE: string = 'oz-issecure';
  public static MAP_LAYER: string = 'map-layer';
  public static POPUP_TYPES: string = 'popup-types';
  public static POPUP_COUNTER: string = 'popup-counter';
  public static XUSERS: string = 'x-users';
  public static OZ_SELECTED_DEVICE_TYPE: string = 'oz-selected-device-type';
  public static OZ_DATABASE: string = 'oz-db';
  public static OZ_DATABASE_VERSION: string = 'oz-db-version';
  public static OZ_SOCKET_URL: string = 'oz-socket-url';
  public static OZ_ORG_URL: string = 'oz-org-url';
  public static OZ_LOGO_URL: string = 'oz-logo-url';
  public static LOGO_URL_WEB: string = 'oz-logo-url-web';
  public static FAVICON: string = 'oz-favicon';
  public static WEB_URL: string = 'oz-website-url';
  public static GUIDE_URL: string = 'oz-guide-url';
  public static APP_TITLE: string = 'oz-app-title';
  public static WMS_URL: string = 'oz-wms-url';
  public static OZ_SUPPORT_URL: string = 'oz-support-url';
  public static OZ_SHARE_TOKEN: string = 'oz-share';
  public static OZ_FIELDS: string = 'oz-fields';
  public static OZ_ALL_PARAMS: string = 'oz-all-params';
  public static OZ_PAYMENT_DUE: string = 'oz-expire-time';
  public static OZ_MODULES: string = 'oz-module-validate';
  public static OZ_ALL_AQI: string = 'oz-all-aqi';
  public static OZ_MULTI_LANG: string = 'oz-multi-lang';
  public static OZ_GA_MEASURE_ID: string = 'oz-ga-measure-id';
  public static OZ_LITE: string = 'oz-lite';
  public static OZ_ALL_UNITS: string = 'oz-all-units';
  // public static OZ_ALL_AQIS: string = 'oz-all-aqis';
  public static OZ_ALL_DEV_TYPE: string = 'oz-all-dev-type';
  public static OZ_USER_DEVICE_TYPE: string = 'oz-user-device-type';
  public static SIDE_NAV_LOGO: string = 'sidenav-logo';
  public static ORG_TIMEZONE: string = 'u-org-timezone';
  public static CURRENT_THEME: string = 'current-theme';
  public static OZ_NOTIFICATION_LAST_VISIT: string = 'oz-notification-last-visit';
  public static OZ_MODULE_ACCESS_CONTROL: string = 'oz-module-access-control';
  public static OZ_HEADER_NOTIF_LAST_VISIT: string = 'oz-notif-last-visit';
  public static OZ_ALL_LAST_SEEN: string = 'oz-all-last-seen';
}
