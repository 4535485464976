import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlertsRoutingModule } from './alerts-routing.module';
import { AlertsComponent } from './alerts.component';
import { AlertConfigurationFormComponent } from './components/alert-configuration-form/alert-configuration-form.component';
import { ConfigurationListComponent } from './pages/configuration-list/configuration-list.component';
import { DataAlertsComponent } from './pages/data-alerts/data-alerts.component';

@NgModule({
  declarations: [
    AlertsComponent,
    AlertConfigurationFormComponent,
    ConfigurationListComponent,
    DataAlertsComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    CommonModule,
    AlertsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AlertsModule {}
